import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    AppLayout,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../../../../importer';
import styled, { css} from 'styled-components';
import {DOCUMENT_ITEMTYPES, DOCUMENT_ITEMTYPE_TITLES, DOCUMENT_TOKENTYPES} from '../../../../manager/documents';
import DesignSystemComponentsPanel from '../../../../panels/left/components';
import { SearchInput } from '../../../../searchbar';
import DocumentItem from '..';
import { TokenItemBox } from '../../../../panels/left/designsystem/common';
import { DocWidgetIcon_ButtonBox } from '../../widget';

export default class FavoriteDocumentWidgets extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.onSearch = this.onSearch.bind(this);        
        this.CanceSearch = this.CanceSearch.bind(this);        
    }
    
    SelectWidgetTemplate(id, pageId) {
        Globals.ProjectManager.GetDocumentManager().CloneFromWidgetTemplate(id, pageId, this.props.id);
        if (this.props.forLayout)
            this.props.onNewItemAdded();
        else
            this.props.onUpdate();
    }
    onSearch(filterText) {
        this.setState({FilterText : filterText});
    }
    CanceSearch() {
        this.setState({FilterText : null});
    }
    DeleteTemplateWidget(id) {
        Globals.ProjectManager.GetDocumentManager().ToggleFavoriteWidget(id);
        this.RCUpdate();
    }
    renderCustom() {

        const widgets = Globals.ProjectManager.DocumentManager.GetFavoriteWidgeteItemInfos();

        return (
            <SC.FCol style={{
                lineHeight : 'unset',
                ...SC.Styles.Font(),
                ...this.props.style,
                marginTop : '16px'
            }}>
                <SC.FRow alc jsb>
                    <SC.BackClosableTitle title='SELECT WIDGET TEMPLATE' onClose={this.props.onClose} />    
                    {/* <SearchInput
                        FilterText={this.state.FilterText}
                        Search={this.onSearch}
                        CancelSearch={this.CanceSearch}
                    />  */}
                </SC.FRow>
                
                <div style={{
                    display : 'grid',
                    gridTemplateColumns : '1fr',
                    gridAutoRows : 'auto',
                    gridGap : '16px',
                    marginTop : '8px'
                }}>
                    {
                        widgets.map(({id, pageId, title, type}) => {
                            return (
                                <SC.FCol>
                                    <SC.FRow alc jsb style={{
                                        marginBottom : '8px'
                                    }}>
                                        <div>{title} / {DOCUMENT_ITEMTYPE_TITLES[type]}</div>
                                        <DocWidgetIcon_ButtonBox hasFill  onClick={this.DeleteTemplateWidget.bind(this, id)}>
                                            <SC.Icons.Icon_Delete  size={18}/>
                                        </DocWidgetIcon_ButtonBox>
                                    </SC.FRow>
                                    <TokenItemBox 
                                        key={id} 
                                        style={{}}
                                        onClick={this.SelectWidgetTemplate.bind(this, id, pageId)}
                                        title='Click to use this widget'
                                    >                                    
                                        <DocumentItem 
                                            {...this.props}
                                            id={id}
                                            pageId={pageId}
                                            preview
                                            forTemplateSelect
                                            ItemProps={{
                                                ...this.props.ItemProps,
                                                pageId : pageId,
                                                preview : true
                                            }}
                                        />
                                    </TokenItemBox>
                                </SC.FCol>                                
                            )
                        })
                    }
                </div>
            </SC.FCol>
        )
    }
}