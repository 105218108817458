import React from 'react';
import ReactDOM from 'react-dom';

import {
    MetaData,
    Utils,
    UIUtils,
    SC,
    Strings,
    AppLayout
} from '../../../../../../../importer';

import BaseGeneric from '../base';
import {RenderItemIcon} from '../../icon';
import { GenericTabs_SubTypeIds } from '../../../../../../../../toolabs-modules/toolabs-metaui/components/generic/initialize/tabs';

export default class Generic_Tabs extends BaseGeneric {
    constructor(props) {
        super(props);        
        
        if (this.props.GenericDesigner) {
            this.slides = {
                items : [
                    {Id : 1, name : 'Slide 1'},
                    {Id : 2, name : 'Slide 2'}
                ]
            };

            this.state.active = 0;
        }        
    }
    UpdateStyle(props) {
        this.renderData = props.GetComponentManager().GetItemStyle(props.Id);        

        const MetaItem = this.props.GetComponentManager().GetMetaItem(this.props.Id);
        this.renderDataSubTypeStyles = {};
        GenericTabs_SubTypeIds.map((SubType) => {
            const SubTypeId = Utils.JustGet(MetaItem.Generic, null, SubType.Id);         
            this.renderDataSubTypeStyles[SubType.Id] = props.GetComponentManager().GetItemStyle(SubTypeId);
        })

        if (this.props.GenericDesigner) {

        }
        else {
            this.slides = Utils.JustGet(this.props, {items : []}, 'Slides');
            if (!this.slides.items)
                this.slides.items = [];
            const index = Math.max(0, Utils.FindIndex(this.slides.items, (slide) => {return slide.Id === this.slides.activeSlide}));
            this.state.active = index || 0;
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (!this.props.isDropTarget && nextProps.isDropTarget || this.props.isDropTarget && !nextProps.isDropTarget) {                     
            return true;
        }
        if (nextState.active !== this.state.active)
            return true;
        if (Utils.HasAnyChange(this.props, nextProps, 'Slides')) {
            this.slides = Utils.JustGet(nextProps, {items : []}, 'Slides');
            if (!this.slides.items)
                this.slides.items = [];
            const index = Math.max(0, Utils.FindIndex(this.slides.items, (slide) => {return slide.Id === this.slides.activeSlide}));
            this.state.active = index || 0;
            return true;
        }
        return super.shouldComponentUpdate(nextProps, nextState);
    }    
    SelectTab(index) {
        if (this.props.GenericDesigner) {

        }
        else {
            const slide = this.slides.items[index];
            this.props.Slides.activeSlide = slide.Id;
            this.props.GetComponentManager().isSubComponent.GetManager().GetDesignManager().SetItemProp(this.props.GetComponentManager().isSubComponent.ItemId, this.props.Slides, 'Slides');
        }

        this.setState({
            active : index
        })
    }
    renderContent({style, props}) {        

        let textContent = Utils.JustGet(this.renderData.props, '', 'textContent');
        let style_item = {
            ...style,
            overflow : 'hidden'
        };
        
        let vertical = true;

        if (this.props.GenericDesigner) {
            style_item = {
                ...style, flex : 1, height : 'unset', width : 'unset', alignSelf : 'stretch', position : 'relative', overflow : 'hidden'
            }
        }
        else {            
        }

        style_item.display = 'flex';
        
        style_item.alignItems = 'stretch';
        
        const style_headerbar = {
            ...this.renderDataSubTypeStyles.HeaderBar.style,
            display : 'flex'
        };

        const orientaion = Utils.JustGet(this.renderData.props, 'column', 'orientation');

        style_item.flexDirection = orientaion;

        if (Utils.IsFlexRow(orientaion)) {
            style_headerbar.flexDirection = 'column';
        }
        else {
            style_headerbar.flexDirection = 'row';
        }

        let content;

        if (this.state.active >= 0 && this.state.active < this.slides.items.length) {
            const activaTab = this.slides.items[this.state.active];
            content = (
                <TabContent 
                    style={this.renderDataSubTypeStyles.Content.style}
                    slide={activaTab}                       
                    renderSlide={this.props.renderSlide}
                />
            )
            if (this.props.renderSlide) {
                content = this.props.renderSlide(activaTab, this.renderDataSubTypeStyles.Content.style);
            }
        }
        
        return (
            <div {...props} style={style_item}>
                <div
                    style={style_headerbar}
                >
                    {
                        this.slides && this.slides.items && this.slides.items.map((slide, i) => {
                            let selectedStyle;
                            if (this.state.active === i) {
                                selectedStyle = this.renderDataSubTypeStyles.HeaderSelected.style;
                            }
                            return (
                                <div 
                                    key={slide.Id}
                                    style={{
                                        ...this.renderDataSubTypeStyles.Header.style,
                                        ...selectedStyle
                                    }}
                                    onClick={this.SelectTab.bind(this, i)}
                                >
                                    Header {i+1}
                                </div>
                            )
                        })
                    }
                </div>
                {content}
            </div>
        )
    }
}

class TabContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
        
    render() { 
        const style = {
            flex : 1,
            display : 'flex',
            alignItems : 'stretch',
            transition : 'transform 0.4s ease-in-out',
        };
        
      
        return (  
            <div
                style={style}
            >
                {this.props.renderSlide && this.props.renderSlide(this.props.slide, this.props.style)}
            </div>
        );
    }
}
