import { Utils, Events, AppLayout } from "../../../../importer";
import { GetDataApi, GetUserId, GetUserName } from "../../../../appstate/AppState";
import firebase from '@firebase/app';
import { checkFirebaseJson } from "../../../../db/firebasedb";
import Globals from "../../../../appstate/Globals";
import moment from 'moment';
import { first } from "lodash";

const config_firebase_history = {
    apiKey: "AIzaSyAYzCR2XKn-9Ha592WTJAs0Q-v0r5fcb4M",
    authDomain: "toolabs-beta-20-2.firebaseapp.com",
    databaseURL: "https://toolabs-21-history.firebaseio.com",
    projectId: "toolabs-beta-20-2",
    storageBucket: "toolabs-beta-20-2.appspot.com",
    messagingSenderId: "30542139765",
    appId: "1:30542139765:web:09f2b838e4f536e450833c"
};

let firebaseHistoryApp;

export const getDataBaseHistory = () => {
    if (!firebaseHistoryApp)
        firebaseHistoryApp = firebase.initializeApp(config_firebase_history, 'history');
    return firebase.database(firebaseHistoryApp);
}

const set_path = (path, value) => {
    getDataBaseHistory().ref(path).set(checkFirebaseJson(value));
}
const delete_path = (path) => {
    getDataBaseHistory().ref(path).remove();
}
const get_ref_value = (ref) => {
    return new Promise((resolve) => {
        ref.once('value').then(function (snapshot) {
            if (snapshot) {
                resolve(snapshot.val());
            }
        });
    })
}

export default class AuditManager {
    constructor(Manager) {        
        this.Manager = Manager;

        this.pageSize = 4;
        this.rows = [];
        this.Users = {};
        // this.FilterByTokenId = '0hMCkor2vlx';
        // this.FilterByUserId = 'PZfqIHfLGEWtZg0YQnc1oPDakhX2'
    }

    InsertLog({ProjectId, EntityId, Type, UserId = GetUserId(), Data, Editor}) {
        
        return;


        const UserProjectId = ProjectId || Globals.ProjectManager.Id;

        const changeRef = getDataBaseHistory().ref(`projects/${UserProjectId}/changes/`).push(checkFirebaseJson({
            EntityId : EntityId,
            UserId : UserId, 
            Type : Type,
            Editor : Editor || this.Manager.Editor,
            Date : firebase.database.ServerValue.TIMESTAMP,     
            Data : Data      
        }));

        const refEntityChanges = getDataBaseHistory().ref(`projects/${UserProjectId}/entity/${EntityId}`).push(changeRef.key);
        const refUSerChanges = getDataBaseHistory().ref(`projects/${UserProjectId}/user/${UserId}`).push(changeRef.key);
    }
    TokenDeleted({TokenId, Token}) {
        this.InsertLog({
            EntityId : TokenId,
            UserId : GetUserId(),
            Type : 'Token',
            Data : {
                deleted : true,
                token : Token
            }
        })
    }
    TokenChanged({TokenId, Change}) {
        this.InsertLog({
            EntityId : TokenId,
            UserId : GetUserId(),
            Type : 'Token',
            Data : {
                change : Change
            }
        })
    }

    // Listing
    GetListPath() {
        return `projects/${Globals.ProjectManager.Id}/changes`;
    }
    Load(ref) {
        return new Promise((resolve) => {
            let useRef = ref;            
                            
            if (!ref) {
                if (this.FilterByUserId || this.FilterByTokenId)
                    useRef = this.ApplyFilter(getDataBaseHistory().ref(this.GetListPath()));
                else
                    useRef = this.ApplyFilter(getDataBaseHistory().ref(this.GetListPath())).limitToLast(this.pageSize);
            }
            useRef.once('value').then( 
                async (snapshot) => {
                    if (snapshot) {
                        this.rows = [];
                        this.hasNext = false;
                        this.hasPrev = false;
                        const rows = snapshot.val();
                        if (rows) {
                            
                            Utils.ForEach(rows, (change, id) => {

                                let name;

                                if (change.Type === 'Token') {
                                    if (change.Data && change.Data.deleted) {
                                        name = Utils.JustGet(change.Data.token, '', 'name');
                                    }
                                    else {
                                        name = Globals.ProjectManager.Tokens.Name(change.EntityId);
                                    }                                
                                }
                                else if (change.Type === 'System') {
                                    name = change.EntityId;
                                    if (change.Data) {
                                        if (change.Data.prop === 'name')
                                            name = 'Project Name';
                                        else if (change.Data.prop === 'description')
                                            name = 'Project Description';
                                    }
                                }

                                this.rows.push({
                                    id : id,
                                    ...change,
                                    name : name,
                                    index : this.rows.length + 1,
                                    dateValue : change.Date,
                                    date : moment.unix(change.Date/1000).format('lll')
                                });
                                if (!Utils.Has(this.Users, change.UserId))
                                    this.Users[change.UserId] = false;                                
                            });

                            for (let id in this.Users) {
                                if (!this.Users[id]) {
                                    this.Users[id] = await GetDataApi().api.get_path_value(`/user/${id}/username`);
                                }                                
                            }

                            if (this.FilterByUserId || this.FilterByTokenId) {
                                
                            }
                            else {
                                const firstId = this.rows[0].id;
                                const lastId = this.rows[this.rows.length-1].id;
                               
                                const next = await get_ref_value(getDataBaseHistory().ref(this.GetListPath()).orderByKey().startAt(lastId).limitToFirst(2));
                                
                                if (next) {
                                    const keys = Object.keys(next);
                                    if (keys.length > 1) {
                                        this.hasNext = keys[1];
                                    }
                                } 
                                
                                const prev = await get_ref_value(getDataBaseHistory().ref(this.GetListPath()).orderByKey().endAt(firstId).limitToLast(2));
                                
                                if (prev) {
                                    const keys = Object.keys(prev);
                                    if (keys.length > 1) {
                                        this.hasPrev = keys[0];
                                    }
                                } 
    
                                this.rows = Utils.Reverse(this.rows);
                            }                            
                        }

                        resolve();
                    }
                }
            );
        })        
    }
    Prev() {
        if (this.FilterByTokenId || this.FilterByUserId)
            return this.Load();
        let ref;
        if (this.hasPrev) {
            ref = this.ApplyFilter(getDataBaseHistory().ref(this.GetListPath())).endAt(this.hasPrev).limitToLast(this.pageSize);
        }
        return this.Load(ref);
    }
    Next() {
        if (this.FilterByTokenId || this.FilterByUserId)
            return this.Load();
        let ref;
        if (this.hasNext) {
            ref = this.ApplyFilter(getDataBaseHistory().ref(this.GetListPath())).startAt(this.hasNext).limitToFirst(this.pageSize);
        }
        return this.Load(ref);
    }
    ApplyFilter(ref) {        
        if (this.FilterByTokenId) {
            return ref.orderByChild('EntityId').equalTo(this.FilterByTokenId);
        }
        else if (this.FilterByUserId) {
            return ref.orderByChild('UserId').equalTo(this.FilterByUserId);
        }
        return ref.orderByKey();
    }

    GetAllChanges({firstId, lastId, prev, limitTo}) {
        const ref = getDataBaseHistory().ref(`projects/${Globals.ProjectManager.Id}/changes`).orderByKey();
        return this.GetChanges({ref : ref, firstId : firstId, lastId : lastId, prev : prev, limitTo : limitTo});
    }
    GetTokenChanges({tokenId, endAt, limitTo}) {
        const ref = getDataBaseHistory().ref(`projects/${Globals.ProjectManager.Id}/changes`).orderByChild('EntityId').equalTo(tokenId);
        return this.GetChanges({ref : ref, endAt : endAt, lstartAt : startAt, next : next, prev : prev,imitTo : limitTo});
    }
    GetUserChanges({userId, endAt, limitTo}) {
        const ref = getDataBaseHistory().ref(`projects/${Globals.ProjectManager.Id}/changes`).orderByChild('UserId').equalTo(userId);
        return this.GetChanges({ref : ref, endAt : endAt, limitTo : limitTo});
    }
}