import React from 'react';
import ReactDOM from 'react-dom';
import {
    SC,
    ReactBasicComponent,
    Utils
} from '../../importer';

import styled from 'styled-components';
import Slider from './slider';

const InputType = styled.input`
    border : none;
    background-color : transparent;    
    width : ${props => props.small ? 30 : 40}px;
    text-align : right;
    color : ${props => props.theme.font};
`;
const Box_Input = styled.div`
    background-color :  ${props => props.darkInput ? '#28292d' : 'transparent'};    
    color : #A6A6A6;
    font-size : ${props => props.small ? 12 : 16}px;
    display : flex;
    flex-direction : row;
    justify-content : space-around;
    align-items : center;
    box-sizing : border-box;
`
class InputSlider extends ReactBasicComponent
{
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onChanging = this.onChanging.bind(this);
        this.setValue = this.setValue.bind(this);
        this.OnKeyDown = this.OnKeyDown.bind(this);
        this.OnKeyUp = this.OnKeyUp.bind(this);
        this.onChangingValue = this.onChangingValue.bind(this);
        this.ImportValue(this.props.value);
    }
    ImportValue(propvalue) {
        let value = propvalue;        
        this.value = Utils.UseNull(Utils.ToNumber(value), Utils.ToNumber(this.props.default)) ||  0;
        if (this.props.scaled)
            this.value = 100 * (this.value);        
        this.value = Utils.ToNumber(this.value);
    }
    OnKeyDown(e) {
        e.stopPropagation();
        return true;
    }
    OnKeyUp(e) {                
        e.stopPropagation();
        if (Utils.IsDeleteCode(e.keyCode)) {
            this.props.onChange(null);
        }
        return true;
    }
    onChange(e) {
        this.setValue(this.value);
    }
    onChangingValue(e) {
        this.value = e.target.value; 
        if (Utils.IsNotNull(this.props.max) && !this.props.allowOverflow && this.value > this.props.max)
            this.value = this.props.max;
        this.RCUpdate();
    }
    onChanging(value) {
        this.value = value || 0;        
        if (Utils.IsNotNull(this.props.max) && !this.props.allowOverflow && this.value > this.props.max)
            this.value = this.props.max;
        if (this.props.onChanging) {
            let propValue = this.value;
            if (this.props.scaled)
                propValue = Utils.ToNumber(this.value) / 100;
            this.props.onChanging(propValue);    
        }
        this.RCUpdate();
    }
    setValue(value) {
        this.value = value || 0;
        if (Utils.IsNotNull(this.props.max) && !this.props.allowOverflow && this.value > this.props.max)
            this.value = this.props.max;
        this.RCUpdate();
        let propValue = this.value;
        if (this.props.scaled)
            propValue = Utils.ToNumber(this.value) / 100;
        this.props.onChange && this.props.onChange(propValue);
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.value !== this.props.value) {
            this.ImportValue(nextProps.value);
            return true;
        }
        return super.ShouldUpdate(nextProps, nextState);
    }
    renderCustom() {
        const {small, horizontal, darkInput, postFix, preFix} = this.props;
        let useTheme = this.props.theme || SC.CurrentTheme.theme;

        const label = this.props.label ? 
                        <SC.Div_Styled style={{fontSize:'14px'}}>
                            {this.props.label}
                        </SC.Div_Styled> : null;
        let input = null;
        if (!this.props.justSlider)
            input = (
                <InputType 
                    darkInput={darkInput} 
                    theme={useTheme}
                    type='number' 
                    small={small} 
                    value={this.value} 
                    onChange={this.onChangingValue} 
                    onBlur={this.onChange} 
                    onKeyDown={this.OnKeyDown} 
                    onKeyUp={this.OnKeyUp}
                    style={{...this.props.inputStyle, ...SC.Styles.FontStyles.Monospace}}
                />
            );
        if (horizontal) {
            const style = {...this.props.style};
            const style_input = {};

            if (this.props.reverse) {
                style.flexDirection = 'row-reverse';
            }
            else {
                style_input.marginRight = '10px';
            }
            return (
                <SC.FRow f1 alc style={style} onMouseDown={(e) => e.stopPropagation()}>
                    <SC.FRow jsb alc style={style_input} >
                        {label}
                        <Box_Input darkInput={darkInput} small={small}>
                            {preFix && <span style={{paddingLeft : '2px'}}>{preFix}</span>}
                            {input}
                            {postFix && <span style={{marginLeft : '2px'}}>{postFix}</span>}
                        </Box_Input>
                    </SC.FRow>    
                    <SC.FCol fw style={{}}>
                        <Slider 
                            value={this.value} 
                            min={this.props.min} 
                            max={this.props.max} 
                            allowOverflow={this.props.allowOverflow}
                            onChanging={this.onChanging} 
                            onChange={this.setValue} small={small}
                            zoomHandle={this.props.zoomHandle}
                            style={this.props.sliderStyle}
                            themeId={this.props.themeId}
                            theme={useTheme}
                        />
                    </SC.FCol>                                
                </SC.FRow>
            )    
        }
        return (
            <SC.FCol fw onMouseDown={(e) => e.stopPropagation()}>
                <SC.FRow jsb alc pl={6}>
                    {label}
                    {input}
                </SC.FRow>                
                <Slider 
                    value={this.value} 
                    min={this.props.min} 
                    max={this.props.max} 
                    allowOverflow={this.props.allowOverflow}
                    onChanging={this.onChanging} 
                    onChange={this.setValue} small={small}
                    theme={useTheme}
                />
            </SC.FCol>
        )
    }
}

export default InputSlider;