import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    AppLayout,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../../../../../importer';

import { GetFilterTokenList, GetFilterTokenListOfIds } from '../../../../../panels/left/designsystem/filters';


export default class DocumentToken_Image extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {

        };    
    }
    shouldComponentUpdate(nextProps, nextState) {

        if (this.state.ShouldUpdate !== nextState.ShouldUpdate)
            return true;
        
        if (Utils.HasAnyChange(this.props, nextProps, 'GlobalThemeId', 'GlobalState', 'StyleId', 'RenderId', 'LoadId'))
            return true;
        
        return false;
    }
    componentDidUpdate(prevProps, prevState) {
        this.state.ShouldUpdate = false;
    }
    render() {                                
        return (
            <div style={{
                display : 'grid',
                gridGap : '48px',
                gridTemplateColumns : 'auto 200px auto',
                gridAutoRows : 'auto',
                justifyContent : 'start',
                alignItems : 'start'
            }}
            >
                SELECT IMAGE
            </div>
        )  
    }
}