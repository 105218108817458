import React from 'react';
import {
    ReactBaseComponent,
    AppState,
    Events,
    SC,
    Loading,
    Strings,
    Links,
    Utils,
    Globals,
    MetaData
} from '../../../../../../importer';

import styled from 'styled-components';
import Input_Name from '../../../../../../components/editors/input_name';
import { LOG_TYPES } from '../../../../../../appstate/historymanager/base';
import ComponentDesignManager from '../../../../component/manager';
import Layers from '../../layers';

export const TabButton = styled.div`
    cursor : ${props => props.selected ? 'unset' : 'pointer'};
    display : flex;
    align-items : center;
    justify-content : center;
    background-color : ${props => props.selected ? props.theme.back_selected_tab : 'unset'};
    flex : 1;
    border-top-left-radius : 4px;
    border-top-right-radius : 4px;
    color : ${props => props.selected ? props.theme.color_brand_text : 'unset'};
    font-weight : 400;
    &:hover {
        background-color : ${props => props.selected ? props.theme.back_selected_tab : props.theme.back_lighter};
    }
`

export const HeaderTab = (props) => {
    return (
        <SC.FRow style={{minHeight : '30px', boxSizing :'border-box', borderBottom : SC.CurrentTheme.theme.border_ondark, backgroundColor : SC.CurrentTheme.theme.back}}>
            {props.children}              
            {
                props.modes.map((mode) => {
                    return (
                        <TabButton key={mode.id} style={mode.style} onClick={() => props.onMode(mode.id)} selected={props.mode === mode.id}>{mode.title}</TabButton>            
                    )
                })
            }
        </SC.FRow>
    )
}

export default class Header extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.ValidateName = this.ValidateName.bind(this);
        this.onNameChange = this.onNameChange.bind(this);
        this.onSaveName = this.onSaveName.bind(this);    

        this.ComponentName = Globals.ProjectManager.GetComponentName(this.props.Id);
    }    
       
    ValidateName(name) {        
        return Globals.ProjectManager.ValidateComponentName(this.props.Id, name);        
    }
    onNameChange(name) {
        this.ComponentName = name;
        this.RCUpdate();
    }
    onSaveName(e) {
        Globals.ProjectManager.ChangeComponentProp(this.props.Id, 'name', this.ComponentName);
        delete this.ComponentName;
    }
    renderCustom() {
        
        return (
            <SC.FCol>
                <Layers />
                
                <SC.FRow f1 justifyEnd style={{padding : '6px', paddingLeft : '8px', paddingRight : '8px'}}>
                    <Input_Name 
                        ref={(r) => this.Ref_Name = r}
                        InputType={SC.Card_Input}
                        required
                        label='COMPONENT NAME'
                        value={this.ComponentName}
                        styleProps = {{                    
                            placeholder : `Component Name`,
                            onBlur : this.onSaveName,
                            tabIndex : '-1',
                            style : {
                                fontSize : '12px',
                                paddingLeft : 0,
                                paddingBottom : '4px'
                            }
                        }}       
                        onChange={this.onNameChange}
                        onValidate={this.ValidateName}
                    />
                </SC.FRow>
            </SC.FCol>            
        )
    }
}

const Style_Input_Name = styled.input`
        color : ${props => props.theme.font_input};
        font-size : 16px;
        padding-left : 0px;
        padding-right : 0px;
        line-height : 30px;
        letter-spacing : 0.5px;        
        border : none;
        background-color : transparent;        
        border-bottom : 0.5px solid ${props => props.theme.border_onlight};    
        width : 100%;
        box-sizing : border-box;
        font-style : ${props => Utils.IsNotNullOrEmpty(props.value) ? 'normal' : 'italic'};
        &:focus {
            border-bottom : 0.5px solid ${props => props.theme.stylepanel_nameinput_border_color};    
        }
    `;