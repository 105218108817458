import React from 'react';
import { Utils } from '../toolabs-importer';
import styled, { css, keyframes, ThemeProvider, createGlobalStyle } from 'styled-components';
import {Icons, Svg, svgns} from './icons';
import Cleave from 'cleave.js/react';
import ToolbarStyles from './toolbar';
import Scrollbars from 'react-custom-scrollbars';

const COLOR_BRAND_BLUE = '#18A0FB';

const FONT_FAMILY = `"Inter", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`;

const themes = {

    Default: {
        back : '#222326',
        back_darker : '#222326',
        back_lighter : '#2c2d30',
        back_light : '#2c2d30',
        back_lightest : '#35363a',
        panel_border : '1px solid #1b1b1d',
        border_onlight : '#414246',
        border_onlight_line : '1px solid #414246',
        border_ondark : '1px solid #313235',
        border_ondark2 : '2px solid #313235',
        border_dark : '1px solid #222326',
        border_dark2 : '2px solid #222326',
        border_brand : '1px solid #18A0FB',
        back_innerpanel : '#222326',
        temp_dark_hover : '#28292d',
        temp_dark_quite_hover : '#36373a',
        canvas_overlay : '#222326',

        board_back : '#222326',

        back_textarea : '#222326',
        border_textfield : '#4D4E52',
        border : '#3b3c40',
        border_device_dashed : '1px dashed #5d5f65',
        border_device_dashed_active : '1px dashed #5267b1',

        switch_round_back : '#808080',
        switch_round_back_unchecked : '#474747',
        switch_round_thumb : '#a4a4a4',
        switch_round_thumb_checked : '#f5f5f5',

        buttongroup_back : '#2f2f2f',
        buttongroup_border : '#414246',
        buttongroup_back_hover : '#252525',
        buttongroup_border_hover : '#4d4d4d',
        buttongroup_border_hover : '#3e3e3e',

        dropdown_back : '#35363a',
        dropdown_back_dark : '#2c2d30',

        input_back_empty : '#2f2f2f',
        input_back : '#1a1a1a',
        input_back_readonly : '#222326',

        font: '#AFAFAF',
        font_hover : '#d4d4d4',
        icon: '#9e9e9e',
        icon_propbar : '#666666',
        icon_light : '#AEAEAE',
        icon_hover : '#c7c7c7',

        layernode_selected : 'rgba(66, 147, 195, 0.30)',
        layernode_hover : 'rgba(53, 55, 60, 0.4)',

        zindex_rightpanel: 100000,
        zindex_header: 8000,
        zindex_max: 999999,
        header_height: 48,
        searchbar_height: 52,
        header_border_bottom_color: '#252629',
        header_font: '#AFAFAF',
        lefttoolbar_width: 50,
        leftbar_width: 220,
        lefttoolbar_icon: '#8b8c8d',
        leftbar_back: '#2D2E32',
        rightbar_width: 300,
        rightpanel_width_closed: 30,
        rightpanel_width: 270,
        bottompanel_height: 200,

        color_brand: '#18A0FB',
        color_brand_text : '#18A0FB',
        color_back_light: '#35363a',
        color_border_light: '#454545',
        color_content_back: '#27282B',
        color_modal_back: '#1F2022',
        popup_shadow : '1px 4px 14px 1px rgb(32,33,36)',
        popup_shadow_darker : '1px 4px 14px 1px  #141414',
        innerpanel_alternate_back : '#35363a',
        innerpanel_alternate_seperator : '1px solid #28292d',

        font_input: '#D1D1D1',
        card_input_border_color: '#414246',
        card_input_error_border_color: '#AC0909',
        card_editicon_hover: '#18A0FB',

        color_notification: {
            info: '#56B2F7',
            warning: '#F7C330',
            success: '#39B54A',
            error: '#FD4646'
        },
        color_valuesources: {
            style: '#FF5A5A',
            model: '#DA5DFF',
            template: '#76FAD5',
            private: '#FFD751',
            get(type) {
                if (type === 'model')
                    return '#DA5DFF';
                else if (type === 'style')
                    return '#FF5A5A';
                else if (type === 'template')
                    return '#76FAD5';
                else
                    return '#FFD751';
            }
        },
        stylepanel_nameinput_border_color: '#18A0FB',

        sizecard_input_backcolor: '#252629',
        sizecard_input_color: '#D1D1D1',
        sizecard_input_underline: '#18A0FB',
        sizecard_input_fontsize: 18,
        sizecard_input_border_color: '#414246',
        sizecard_unitbutton_color: '#303134',
        sizecard_unit_hovercolor: '#28292B',


        back_dark : '#232323',
        back_dark_quite : '#242424',
        back_dark_1 : '#2a2a2a',
        back_dark_1_quite : '#2f3032',
        back_dark_2 : '#323334',

        card_back_hover : '#3b3b40',
        card_shadow : 'rgba(0,0,0,0.4) 0px 2px 18px 0px',
        panel_back : '#222325',
        
        
        project_card_border : '#c8c8c8',
        text_card_title : '#d1d1d1',
        border : '#414246',
        border_hover : '#585a5f',
        border_selected : '#18A0FB',
        borderline_hover : '1px solid #585a5f',
        borderline_selected : '1px solid #18A0FB',
        border_seperator : '1px solid #3d3e42',
        border_seperator_color : '#3d3e42',
        button_back : 'transparent',
        button_back_onDark : 'transparent',
        button_back_primary : 'transparent',
        button_border : '#48494d',
        button_border_primary : '#18A0FB',
        button_border_width : '1px',
        button_font_color : '#AFAFAF',
        button_font_color_primary : '#AFAFAF',
        button_border_hover : '#48494d',
        button_border_hover_primary : '#18A0FB',
        button_shadow_primary : '#0096ea80',
        button_back_cta : '#18A0FB',
        button_back_hover : '#48494d',
        button_back_hover_primary : '#40b1fc',
        button_font_color_hover : '#fff',

        overlay_back : 'rgba(23, 23, 23, 0.8)',
        
        colorinfo_1 : '#F5BB19',
        colorinfo_2 : '#F37936',
        colorinfo_3 : '#375e97',
    },    
    Light : {
        body : '#fff',
        board_back : '#fff',
        back : '#fff',
        back_darker : '#E4E4E4',
        back_lighter : '#f3f3f3',
        back_light : '#f3f3f3',
        back_lightest : '#fff',
        back_code : '#1f1f1f',
        color_brand: '#18A0FB',
        color_brand_text : '#18A0FB',
        border_brand : '1px solid #18A0FB',
        border_dark : '1px solid #e1e1e1',
        border_dark2 : '1px solid #e1e1e1',
        border_selected : '#18A0FB',
        panel_border : '1px solid #e1e1e1',
        border_onlight : '#e6e6e6',
        border_onlight_line : '1px solid #e6e6e6',
        border_ondark : '1px solid #e5e5e5',
        border_ondark2 : '1px solid #fff',
        border_popup : 'none',
        border_seperator : '1px solid #e5e5e5',
        border_seperator_color : '#e5e5e5',
        border_device_dashed : '1px dashed #5d5f65',
        border_device_dashed_active : '1px dashed #5267b1',
        back_innerpanel : '#fff',
        back_textarea : '#f3f3f3',
        back_selected_tab : '#E4E4E4',

        switch_round_back : '#747474',
        switch_round_back_unchecked : '#cccccc',
        switch_round_thumb : '#fff',
        switch_round_thumb_checked : '#fafafa',

        input_back_empty : '#fff',
        input_back : '#fff',
        input_back_readonly : '#e1e1e1',

        sizecard_input_backcolor: '#252629',
        sizecard_input_color: '#666666',
        sizecard_input_underline: '#18A0FB',
        sizecard_input_fontsize: 18,
        sizecard_input_border_color: '#666666',
        sizecard_unitbutton_color: '#303134',
        sizecard_unit_hovercolor: '#28292B',

        buttongroup_back : '#eaeaea',
        buttongroup_border : '#f0f0f0',
        buttongroup_back_hover : '#fff',
        buttongroup_border_hover : '#f0f0f0',
        buttongroup_border_hover : '#f0f0f0',

        dropdown_back : '#fff',
        dropdown_back_dark : '#fff',
        popup_shadow : '0 2px 14px rgba(0,0,0,.15), 0 0 0 0.5px rgba(0,0,0,.2)',
        popup_shadow_darker : '1px 4px 8px 1px rgba(0, 0, 0, 0.2)',

        
        card_input_error_border_color: '#d7373f',

        font : '#333',
        font_small : '#333',
        font_hover : '#2d2d2d',
        font_weight : 500,
        card_back_hover : '#fff',
        card_shadow : '0px 8px 18px rgba(0,0,0,0.18)',
        font_input: '#2d2d2d',
        icon : '#333',
        icon_hover : '#4b4b4b',
        
        project_card_border : '#fff',
        text_card_title : '#6e6e6e',
        border : '#fff',
        button_border : '#e1e1e1',
        button_back : '#f3f3f3',
        button_back_onDark : '#ffff',
        button_back_primary : '#18A0FB',
        button_border_primary : '#18A0FB',
        button_border_width : '1px',
        button_font_color : '#6e6e6e',
        button_font_color_primary : '#fff',
        button_border_hover : '#6e6e6e',
        button_border_hover_primary : '#18A0FB',
        button_shadow_primary : '#0096ea80',
        button_back_cta : '#18A0FB',
        button_back_hover : '#6e6e6e',
        button_back_hover_primary : '#40b1fc',
        button_font_color_hover : '#4b4b4b',
        isLight : true,

        border_editor_default : '#e4e4e4',
        border_editor_selected : '#18A0FB',
        border_editor_styled : '#b3b3b3',

        canvas_overlay : 'rgba(245, 245, 245, 0.3)'
    },   
    Dark6_1 : {
        back : '#2a2a2a',
        back_darker : '#1f1f1f',
        back_lighter : '#333333',
        back_light : '#242424',
        back_lightest : '#3d3d3d',  
        back_code : '#1f1f1f',
        panel_border : '1px solid #1b1b1d',
        border_onlight : '#414246',
        border_onlight_line : '1px solid #363636',
        border_ondark : '1px solid #3d3d3d',
        border_ondark2 : '1px solid #3d3d3d',
        border_dark : '1px solid #1E1E1E',
        border_dark2 : '2px solid #1E1E1E',
        border_brand : '1px solid #0096ea',
        back_innerpanel : '#1E1E1E',
        temp_dark_hover : '#28292d',
        temp_dark_quite_hover : '#36373a',
        canvas_overlay : 'rgba(32, 32, 32, 0.58)',

        board_back : '#222326',

        back_selected_tab : '#3d3d3d',
        back_textarea : '#1E1E1E',
        border_textfield : '#4D4E52',
        border : '#3b3c40',
        border_device_dashed : '1px dashed #5d5f65',
        border_device_dashed_active : '1px dashed #5267b1',
        border_popup : '1px solid #3d3d3d',
        border_editor_default : '#333333',
        border_editor_selected : '#0096ea',
        border_editor_styled : '#4d4d4d',

        switch_round_back : '#808080',
        switch_round_back_unchecked : '#474747',
        switch_round_thumb : '#a4a4a4',
        switch_round_thumb_checked : '#f5f5f5',

        buttongroup_back : '#2f2f2f',
        buttongroup_border : '#414246',
        buttongroup_back_hover : '#252525',
        buttongroup_border_hover : '#4d4d4d',
        buttongroup_border_hover : '#3e3e3e',

        dropdown_back : '#212121',
        dropdown_back_dark : '#212121',

        input_back_empty : '#2f2f2f',
        input_back : '#212121',
        input_back_readonly : '#222326',

        font : '#afafaf',
        font_reversed : '#e5e5e5',
        font_small : '#afafaf',
        font_hover : '#d4d4d4',
        icon: '#9e9e9e',
        icon_propbar : '#666666',
        icon_light : '#AEAEAE',
        icon_hover : '#c7c7c7',

        layernode_selected : 'rgba(66, 147, 195, 0.30)',
        layernode_hover : 'rgba(53, 55, 60, 0.4)',

        zindex_rightpanel: 100000,
        zindex_header: 8000,
        zindex_max: 999999,
        header_height: 48,
        searchbar_height: 52,
        header_border_bottom_color: '#252629',
        header_font: '#AFAFAF',
        lefttoolbar_width: 50,
        leftbar_width: 220,
        lefttoolbar_icon: '#8b8c8d',
        leftbar_back: '#2D2E32',
        rightbar_width: 300,
        rightpanel_width_closed: 30,
        rightpanel_width: 270,
        bottompanel_height: 200,

        color_brand: '#0096ea',
        color_brand_text : '#50a8dc',
        color_back_light: '#35363a',
        color_border_light: '#454545',
        color_content_back: '#27282B',
        color_modal_back: '#1F2022',
        // popup_shadow : '1px 4px 14px 1px rgb(32,33,36)',
        popup_shadow : '0 2px 14px rgba(0,0,0,.15), 0 0 0 0.5px rgba(0,0,0,.2)',
        popup_shadow_darker : '1px 4px 14px 1px  #141414',
        innerpanel_alternate_back : '#35363a',
        innerpanel_alternate_seperator : '1px solid #28292d',

        font_input: '#D1D1D1',
        card_input_border_color: '#414246',
        card_input_error_border_color: '#AC0909',
        card_editicon_hover: '#0096ea',

        color_notification: {
            info: '#56B2F7',
            warning: '#F7C330',
            success: '#39B54A',
            error: '#FD4646'
        },
        color_valuesources: {
            style: '#FF5A5A',
            model: '#DA5DFF',
            template: '#76FAD5',
            private: '#FFD751',
            get(type) {
                if (type === 'model')
                    return '#DA5DFF';
                else if (type === 'style')
                    return '#FF5A5A';
                else if (type === 'template')
                    return '#76FAD5';
                else
                    return '#FFD751';
            }
        },
        stylepanel_nameinput_border_color: '#18A0FB',

        sizecard_input_backcolor: '#252629',
        sizecard_input_color: '#D1D1D1',
        sizecard_input_underline: '#18A0FB',
        sizecard_input_fontsize: 18,
        sizecard_input_border_color: '#414246',
        sizecard_unitbutton_color: '#303134',
        sizecard_unit_hovercolor: '#28292B',


        back_dark : '#232323',
        back_dark_quite : '#242424',
        back_dark_1 : '#2a2a2a',
        back_dark_1_quite : '#2f3032',
        back_dark_2 : '#323334',

        card_back_hover : '#3b3b40',
        card_shadow : 'rgba(0,0,0,0.4) 0px 2px 18px 0px',
        panel_back : '#222325',
        
        
        project_card_border : '#c8c8c8',
        text_card_title : '#d1d1d1',
        border : '#414246',
        border_hover : '#585a5f',
        border_selected : '#0096ea',
        borderline_hover : '1px solid #585a5f',
        borderline_selected : '1px solid #0096ea',
        border_seperator : '1px solid #3d3d3d',
        border_seperator_color : '#3d3d3d',
        button_back : 'transparent',
        button_back_onDark : 'transparent',
        button_back_primary : 'transparent',
        button_border : '#48494d',
        button_border_primary : '#0096ea',
        button_border_width : '1px',
        button_font_color : '#AFAFAF',
        button_font_color_primary : '#AFAFAF',
        button_border_hover : '#48494d',
        button_border_hover_primary : '#0096ea',
        button_shadow_primary : '#0096ea80',
        button_back_cta : '#0096ea',
        button_back_hover : '#48494d',
        button_back_hover_primary : '#40b1fc',
        button_font_color_hover : '#fff',

        overlay_back : 'rgba(23, 23, 23, 0.8)',
        
        colorinfo_1 : '#F5BB19',
        colorinfo_2 : '#F37936',
        colorinfo_3 : '#375e97',
    },
    Tester : {
        back : '#fff',
        back_lighter : '#fff',
        back_light : '#fff',
        back_lightest : '#fff',
        panel_border : '1px solid #fff',
        border_onlight : '#fff',
        border_onlight_line : '1px solid #fff',
        border_ondark : '1px solid #fff',
        border_ondark2 : '2px solid #fff',
        border_dark : '1px solid #fff',
        border_brand : '1px solid #fff',
        back_innerpanel : '#fff',
        temp_dark_hover : '#fff',
        temp_dark_quite_hover : '#fff',
        canvas_overlay : '#fff',

        board_back : '#fff',

        back_textarea : '#fff',
        border_textfield : '#fff',
        border : '#fff',

        switch_round_back : '#fff',
        switch_round_back_unchecked : '#fff',
        switch_round_thumb : '#fff',
        switch_round_thumb_checked : '#fff',

        buttongroup_back : '#fff',
        buttongroup_border : '#fff',
        buttongroup_back_hover : '#fff',
        buttongroup_border_hover : '#fff',
        buttongroup_border_hover : '#fff',

        dropdown_back : '#fff',
        dropdown_back_dark : '#fff',

        input_back_empty : '#fff',
        input_back : '#fff',
        input_back_readonly : '#fff',

        font: '#fff',
        font_hover : '#fff',
        icon: '#fff',
        icon_propbar : '#fff',
        icon_light : '#fff',
        icon_hover : '#fff',

        layernode_selected : '#fff',
        layernode_hover : '#fff',

        zindex_rightpanel: 100000,
        zindex_header: 8000,
        zindex_max: 999999,
        header_height: 48,
        searchbar_height: 52,
        header_border_bottom_color: '#fff',
        header_font: '#fff',
        lefttoolbar_width: 50,
        leftbar_width: 220,
        lefttoolbar_icon: '#fff',
        leftbar_back: '#fff',
        rightbar_width: 300,
        rightpanel_width_closed: 30,
        rightpanel_width: 270,
        bottompanel_height: 200,

        color_brand: '#fff',
        color_back_light: '#fff',
        color_border_light: '#fff',
        color_content_back: '#fff',
        color_modal_back: '#fff',
        popup_shadow : '1px 4px 14px 1px #fff',
        innerpanel_alternate_back : '#fff',
        innerpanel_alternate_seperator : '1px solid #fff',

        font_input: '#fff',
        card_input_border_color: '#fff',
        card_input_error_border_color: '#fff',
        card_editicon_hover: '#fff',

        color_notification: {
            info: '#fff',
            warning: '#fff',
            success: '#fff',
            error: '#fff'
        },
        color_valuesources: {
            style: '#fff',
            model: '#fff',
            template: '#fff',
            private: '#fff',
            get(type) {
                if (type === 'model')
                    return '#fff';
                else if (type === 'style')
                    return '#fff';
                else if (type === 'template')
                    return '#fff';
                else
                    return '#fff';
            }
        },
        stylepanel_nameinput_border_color: '#fff',

        sizecard_input_backcolor: '#fff',
        sizecard_input_color: '#fff',
        sizecard_input_underline: '#fff',
        sizecard_input_fontsize: 18,
        sizecard_input_border_color: '#fff',
        sizecard_unitbutton_color: '#fff',
        sizecard_unit_hovercolor: '#fff',


        back_dark : '#fff',
        back_dark_quite : '#fff',
        back_dark_1 : '#fff',
        back_dark_1_quite : '#fff',
        back_dark_2 : '#fff',

        card_back_hover : '#fff',
        card_shadow : '#fff 0px 2px 18px 0px',
        panel_back : '#fff',
        
        
        project_card_border : '#fff',
        text_card_title : '#fff',
        border : '#fff',
        border_hover : '#fff',
        border_selected : '#fff',
        borderline_hover : '1px solid #fff',
        borderline_selected : '1px solid #fff',
        border_seperator : '1px solid #fff',
        border_seperator_color : '#fff',
        button_back : 'transparent',
        button_back_primary : 'transparent',
        button_border : '#fff',
        button_border_primary : '#fff',
        button_border_width : '1px',
        button_font_color : '#fff',
        button_font_color_primary : '#fff',
        button_border_hover : '#fff',
        button_border_hover_primary : '#fff',
        button_back_cta : '#fff',
        button_back_hover : '#fff',
        button_back_hover_primary : '#fff',
        button_font_color_hover : '#fff',

        overlay_back : '#fff',
        
        colorinfo_1 : '#fff',
        colorinfo_2 : '#fff',
        colorinfo_3 : '#fff',
    }
}

themes.Default.panel_innerborder = `1px solid ${themes.Default.back_lighter}`;
themes.Light.panel_innerborder = `1px solid ${themes.Light.back_lighter}`;
themes.Dark6_1.panel_innerborder = `1px solid ${themes.Dark6_1.back_lighter}`;


const GlobalStyle = createGlobalStyle`
    body {
        color : ${props => props.theme.font};
        font-weight : ${props => props.theme.font_weight || 400};        
        input, button, textarea {
            font-weight : ${props => props.theme.font_weight || 400};
            color : ${props => props.theme.font};
            letter-spacing : 0.07em;
        }
        input {
            font-family : ${FONT_FAMILY};
            font-weight : ${props => props.theme.font_weight || 400};
            color : ${props => props.theme.font};
        }
        input::-webkit-contacts-auto-fill-button {
            visibility: hidden;
            display: none !important;
            pointer-events: none;
            position: absolute;
            right: 0;
        }
        a {
            &:hover {
                color : ${props => props.theme.font_hover};
            }
        }
    }
`;

const CurrentTheme = {
    theme: themes.Dark6_1
};

const TransitionHoverOn = css`
    transition : all 0.2s ease;
`;
const TransitionHoverOff = css`
    transition : all 0.4s ease;
`;

const Div_Default = styled.div`
font-family : ${FONT_FAMILY};
font-weight : 400;
`;

const Div_Body = styled.div`
font-family : ${FONT_FAMILY};
    user-select: none;
    background-color : ${props => props.theme.back};
    color : ${props => props.theme.font};
    font-weight : ${props => props.theme.font_weight || 400};
    height : 100vh;
    width : 100vw;
    min-width : 100px;
    overflow : hidden;    
`;

const Padding = css`
    ${props => props.pa !== undefined ? css`padding : ${props.pa}px;` : null}
    ${props => props.pb !== undefined ? css`padding-bottom : ${props.pb}px;` : null}
    ${props => props.pt !== undefined ? css`padding-top : ${props.pt}px;` : null}
    ${props => props.pl !== undefined ? css`padding-left : ${props.pl}px;` : null}
    ${props => props.pr !== undefined ? css`padding-right : ${props.pr}px;` : null}
`;

const Margin = css`
    ${props => props.ma !== undefined ? css`margin : ${props.ma}px;` : null}
    ${props => props.mb !== undefined ? css`margin-bottom : ${props.mb}px;` : null}
    ${props => props.mt !== undefined ? css`margin-top : ${props.mt}px;` : null}
    ${props => props.ml !== undefined ? css`margin-left : ${props.ml}px;` : null}
    ${props => props.mr !== undefined ? css`margin-right : ${props.mr}px;` : null}
`;


const Div_Styled = styled.div`
    ${Margin}
    ${Padding}
    ${props => props.fw ? css`width : 100%;` : null}
    ${props => props.fh ? css`height : 100%;` : null}
    ${props => props.w ? css`width : ${props.w};` : null}
    ${props => props.h ? css`height : ${props.h};` : null}
    ${props => props.bsbx ? css`box-sizing : border-box;` : null}
    ${props => props.display ? css`display : ${props.display};` : null}
    ${props => props.flex ? css`flex : ${props.flex};` : null}
    ${props => props.f1 ? css`flex : 1;` : null}
    ${props => props.f2 ? css`flex : 2;` : null}
    ${props => props.f3 ? css`flex : 3;` : null}
    ${props => props.backColor ? css`background-color : ${props.theme[props.backColor]};` : null}
    ${ props => {
        if (props.cursorPointer)
            return css`cursor : pointer;`;
    }}
    ${
    props => {
        if (props.textAbbr) {
            return css`
                    text-overflow : ellipsis;
                    white-space : nowrap;
                    overflow : hidden;
                    min-width : 0;
                `;
        }
    }
    }
    ${
    props => {
        if (props.overflowHidden)
            return css`overflow : hidden`;
    }
    }
`;

const Flex_Justify = css`
${
    (props) => {
        if (props.justifyCenter)
            return css`justify-content : center;`;
        else if (props.jsb)
            return css`justify-content : space-between;`;
        else if (props.ja)
            return css`justify-content : space-around;`;
        else if (props.justifyStart)
            return css`justify-content : flex-start;`;
        else if (props.justifyEnd)
            return css`justify-content : flex-end;`;
        else if (props.justify)
            return css`justify-content : ${props.justify};`;
    }
    }
`;
const Flex_Align = css`
${
    (props) => {
        if (props.alc)
            return css`align-items : center;`;
        else if (props.alignStart)
            return css`align-items : flex-start;`;
        else if (props.alignEnd)
            return css`align-items : flex-end;`;
        else if (props.alignStretch)
            return css`align-items : stretch;`;
        else if (props.align)
            return css`align-items : ${props.align};`;
    }
    }
`;

const Flex_AlignSelf = css`
${
    (props) => {
        if (props.alignSelfCenter)
            return css`align-self : center;`;
        else if (props.alignSelfStart)
            return css`align-self : flex-start;`;
        else if (props.alignSelfEnd)
            return css`align-self : flex-end;`;
        else if (props.alignSelfStretch)
            return css`align-self : stretch;`;
        else if (props.alignSelf)
            return css`align-self : ${props.align};`;
    }
    }
`;

const FRow = styled(Div_Styled) `
    display : flex;
    flex-direction : row;
    ${
    (props) => {
        if (props.wrap)
            return css`flex-wrap : wrap;`
    }
    }
    ${Flex_Justify}
    ${Flex_Align}
    ${Flex_AlignSelf}
`;
const Div_Flex_Cell = styled(Div_Styled) `
    display : flex;
    align-items : center;
    justify-content : center;
`;
const Div_Full_Center = styled(Div_Flex_Cell) `
    width : 100vw;
    height : 100vh;
`;

const Div_Relative = styled.div`
    position : relative;
`;

const FCol = styled(Div_Styled) `
    display : flex;
    flex-direction : column;
    ${Flex_Align}
    ${Flex_Justify}
    ${Flex_AlignSelf}
`;

const AppTheme = (props) => {
    CurrentTheme.theme = Utils.Merge(themes.Default, props.themeName && themes[props.themeName] ? themes[props.themeName] : themes.Default);
    return (
        <ThemeProvider theme={CurrentTheme.theme}>            
            <Div_Body>
                <GlobalStyle />
                {props.children}
            </Div_Body>
        </ThemeProvider>
    );
}

const Loading_Icon = (props) => {
    const style = {...props.style};
    if (props.small) {
        style.width = '28px';
        style.height = '28px';
    }
    return (
        <div className="la-ball-scale-multiple  la-2x" style={{color : CurrentTheme.theme.isLight ? '#c1c1c1' : '#fff', ...style, ...props.style}}>
            <div style={style}></div>
            <div style={style}></div>
            <div style={style}></div>
        </div>
    )
}
const Buttons = {
    RoundButton : styled.button`
        border : ${props => props.theme.button_border_width || '1px'}  solid;
        border-color : ${props => props.primary ? props.theme.button_border_primary : props.theme.button_border};
        background-color : ${props => props.primary ? props.theme.button_back_primary : (props.onDark ? props.theme.button_back_onDark : props.theme.button_back)};
        width : 220px;
        height : ${props => props.xsmall ? 28 : (props.small ? 32 : 40)}px;
        display : flex;
        flex-direction : row;
        justify-content : center;
        align-items : center;        
        font-size : ${props => props.xsmall ? 11 : 14}px;
        word-spacing : 4px;
        letter-spacing : 0.07em;
        color : ${props => props.cta ? 'white' : (props.primary ? props.theme.button_font_color_primary : props.theme.font)};
        fill : ${props => props.cta ? 'white' : (props.primary ? props.theme.button_font_color_primary : props.theme.button_font_color)};
        border-radius : 99px;
        ${TransitionHoverOn}
        cursor : pointer;        
        &:hover {
            background-color : ${props =>
                props.cta ? props.theme.button_back_cta :
                (props.primary ? props.theme.button_back_hover_primary : props.theme.back_lighter)
            };
            color : ${props => props.theme.button_font_color_hover};
            fill : ${props => props.theme.button_font_color_hover};
            border-color : ${props => props.primary ? props.theme.button_border_hover_primary : props.theme.button_border_hover};
            ${TransitionHoverOff}
        }
        &:focus {
            box-shadow : ${props => props.theme.button_shadow_primary + ((props.small || props.xsmall) ? ' 0px 0px 0px 1px' : ' 0px 0px 0px 3px')} ;
            color : ${props => props.theme.button_font_color_hover};
            background-color : ${props =>
                props.cta ? props.theme.button_back_cta :
                (props.primary ? props.theme.button_back_hover_primary : props.theme.back_lighter)
            };
        }
        &:active {
            background-color : ${props => props.primary ? props.theme.button_back_primary : (props.onDark ? props.theme.button_back_onDark : props.theme.button_back)};
        }
        ${
            props => {
                if (props.cta)
                    return css`
                        background-color : ${props => props.theme.button_back_cta};
                    `;
            }
        }
        ${
            props => {
                if (props.auto)
                    return css`
                        width : auto;
                        padding-left : 16px;
                        padding-right : 16px;
                    `;
            }
        }
    `,
};

const Input_Focus = css`
    ${TransitionHoverOn}
    border-bottom : 1px solid ${props => props.error ? props.theme.card_input_error_border_color : props.theme.color_brand};
`;

const Card_Input = styled.input`
    color : ${props => props.theme.font_input};
    font-size : 14px;
    padding : 4px;
    padding-bottom : 8px;
    line-height : 18px;
    letter-spacing : 0.5px;
    border : none;
    background-color : transparent;
    border-bottom : ${props => props.theme.isLight ? props.theme.border_ondark : '1px solid #47494E'};
    width : 100%;
    box-sizing : border-box;
    text-align : left;
    ${TransitionHoverOff}

    &:focus {
        ${Input_Focus}
    }
    ${
    props => {
        if (props.error)
            return Input_Focus;
    }
    }
    font-style : ${props => Utils.IsNotNullOrEmpty(props.value) ? 'normal' : 'italic'};
`;

const Form_Input = styled.input`
    color : ${props => props.theme.font};
    font-size : 12px;
    height : 26px;
    padding : 4px;        
    border-radius : 2px;
    letter-spacing : 0.5px;
    border : ${props => props.active ? props.theme.border_brand : props.theme.border_seperator};
    background-color : ${props => props.theme.dropdown_back};
    width : 100%;
    box-sizing : border-box;
    text-align : left;
    ${TransitionHoverOff}

    &:focus {
        border : ${props => props.theme.border_brand};
    }
    ${
    props => {
        if (props.error)
            return Input_Focus;
    }
    }
    font-style : ${props => Utils.IsNotNullOrEmpty(props.value) ? 'normal' : 'italic'};
`;

const SortAndFilter = {
    StylePanel_Total: styled(FRow) `
        margin-bottom : 24px;
        margin-top : 24px;
        padding-left : 12px;
        padding-right : ${props => props.hasRightPadding ? 12 : 0}px;
    `,
    StylePanel_Total_Border: styled.div`
        height : 20px;
        margin-right : ${props => props.mr || '8px'};
        border-right : 1px solid ${props => props.theme.border_seperator_color};
    `,
    StylePanel_TotalMessage: styled(FRow) `
        font-size : 13px;
        padding-right : 8px;
        letter-spacing : 0.7px;
    `,
}

const Input_Tooltip = styled.div`
    position : absolute;
    left : 0;
    right : 0;
    top : calc(100% + 7px);
    z-index : 999999999;
    display : flex;
    justify-content : center;
`;
const Input_Error_Tooltip = styled.div`
    background-color : ${props => props.theme.card_input_error_border_color};
    padding : 4px;
    padding-left : 8px;
    padding-right : 8px;
    border-radius : 2px;
    font-size : 11px;
    color : #FFFFFF;
    ${TransitionHoverOff}
    &:before {
        content:'';
        display:block;
        width:0;
        height:0;
        position:absolute;
        left : 50%;
        margin-left : -5px;
        top : -5px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid ${props => props.theme.card_input_error_border_color};
    }
`;

const Icons_Preview = styled.svg`
    fill : ${props => props.dark ? '#323232' : '#acacac'};
    ${TransitionHoverOff}
    ${
    props => {
        if (props.backgroundImage) {
            return css`
                    background-image : url(${props.backgroundImage});
                    background-size : contain;
                    background-repeat : no-repeat;
                    background-position : center;
                `;
        }
    }
    }
`;

const RenderValueError = (error, msg) => {
    if (error)
        return (
            <Input_Tooltip>
                <Input_Error_Tooltip>
                    {msg}
                </Input_Error_Tooltip>
            </Input_Tooltip>
        )
}

const Overlay = styled(Div_Flex_Cell)`
    position : absolute;
    top : 0;
    left : 0;
    right : 0;
    bottom : 0;
    opacity : 0;
    background-color : rgba(0,0,0,0.5);
    ${TransitionHoverOn}
    &:hover {
        opacity : 1;
    }
    z-index : 1000
`;

const AbsoluteOverlay = styled.div`
    position : absolute;
    top : 0;
    left : 0;
    right : 0;
    bottom : 0;
`;

const TextSpanAbbr = styled.span`
    text-overflow : ellipsis;
    white-space : nowrap;
    overflow : hidden;
`;
const TextDivAbbr = styled.div`
    text-overflow : ellipsis;
    white-space : nowrap;
    overflow : hidden;
`;

const MultilineAbbr = styled.span`
    display : -webkit-box;
    -webkit-line-clamp : 4;
    -webkit-box-orient : vertical;
    overflow : hidden;
`;

const SelectPanelGroup = styled.div`
    background-color : ${props => props.theme.back};
    display : flex;
    flex-direction : row;
    flex-wrap : wrap;
    padding-bottom : 14px;
    margin : ${props => props.noMargin ? 0 : 12}px;
`;

const GridBack_image = 'linear-gradient(45deg, #e4e4e4 25%, transparent 25%, transparent 75%, #e4e4e4 75%, #e4e4e4 100%),linear-gradient(45deg, #e4e4e4 25%, transparent 25%, transparent 75%, #e4e4e4 75%, #e4e4e4 100%)';
const GridBack_DarkImage = 'linear-gradient(45deg,#363636 25%,transparent 25%,transparent 75%,#363636 75%,#363636 100%),linear-gradient(45deg,#363636 25%,transparent 25%,transparent 75%,#363636 75%,#363636 100%)';

const CssGridBackground = css`
    background-color : ${props => props.dark ? '#222326' : '#fafafa'};    
    background-position : ${props => props.small ? '0px 0px, 5px 5px' : '0px 0px, 10px 10px'};
    background-size: ${props => props.small ? '10px 10px' : '20px 20px'};
    background-repeat: repeat;
    background-image : ${props => props.dark ? GridBack_DarkImage : GridBack_image};
`;
const GetGridBackgroundStyle = (small, dark) => {
    return {
        backgroundColor : dark ? '#222326' : '#fafafa',
        backgroundPosition : small ? '0px 0px, 5px 5px' : '0px 0px, 10px 10px',
        backgroundSize :  small ? '10px 10px' :'20px 20px',
        backgroundRepeat : 'repeat',
        backgroundImage : dark ? GridBack_DarkImage : GridBack_image
    }
}

const GridBackground = styled.div`
    ${CssGridBackground}
`;

const NoteArea = styled.div`
    background-color : ${props => props.theme.back_textarea};
    padding : 8px;
    min-height : ${props => props.minHeight || 100}px;
    font-size : 14px;
    width : 100%;
    border : 1px solid ${props => props.theme.card_input_border_color};
    border-radius : 2px;        
    overflow : hidden;
    display : flex;
    box-sizing : border-box;
`;

class CleaveInput extends React.Component {
    constructor(props) {
        super(props);
        this.onCreditCardInit = this.onCreditCardInit.bind(this);
        this.onChange = this.onChange.bind(this);        
    }
    onCreditCardInit(inst) {
        this.instance = inst;
    }
    onChange(event) {
        const value = Utils.UseNullOrEmpty(event.target.value, '').toString();
        const oldValue = Utils.UseNullOrEmpty(this.props.value, '').toString();
        if (value !== oldValue) {
            this.props.onChange(event);
        }
    }
    //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.value !== this.props.value && this.instance) {
            this.instance.setRawValue(this.props.value);
        }
        if (!prevProps.autoFocus && this.props.autoFocus && this.instance) {
            this.instance.element.focus()
            if (this.props.autoSelect)
                this.instance.element.select()
        }
    }
    SetValue(value) {
        if (this.instance) {
            if (this.instance.getRawValue() !== value) {
                this.instance.setRawValue(value);
            }                
        }            
    }
    Focus() {
        this.instance && this.instance.element.focus()
    }
    render() {
        const { small, xsmall, horizontal, InnerRef, onChange, ...rest } = this.props;

        const style = {
            backgroundColor : this.props.readOnly ? CurrentTheme.theme.input_back_readonly : (Utils.IsNotNullOrEmpty(this.props.value) ? CurrentTheme.theme.input_back : CurrentTheme.theme.input_back_empty),
            width : '100%',
            boxSizing : 'border-box',
            fontSize : `${this.props.xsmall ? 12 : (this.props.small ? 14 : CurrentTheme.theme.sizecard_input_fontsize)}px`,
            border : 'none',
            color : CurrentTheme.theme.sizecard_input_color,
            paddingRight : `${(this.props.xsmall || this.props.small) ? 4 : 8}px`,
            textAlign : 'right',
            ...this.props.style
        };
        
        let key = 'integer';
        if (this.props.options.numeralDecimalScale) {
            key = 'decimal';
        }

        return (
            <Cleave {...rest} key={key}
                onInit={this.onCreditCardInit}
                style={style}
                onChange={this.onChange}
            />
        )
    }
}

const Component_SizeEditor = {
    Container: styled(FRow) `
        position : relative;
        height : ${props => props.xsmall ? 24 : 28}px;        
        border : ${props => props.active ? props.theme.border_brand : props.theme.border_seperator};
        box-sizing : border-box;
        border-radius : 2px;
        width : 100%;
        max-width : ${props => props.fullWidth ? 'unset' : '105px'};        
    `,
    Label: styled.div`
        align-self : flex-start;
        font-size : 12px;
        margin-bottom : ${props => props.noMargin ? 0 : 4}px;
        cursor : ${props => props.hasCursor ? 'pointer' : 'unset'};
        ${
        props => {
            if (props.valueType) {
                return css`
                        color : ${props => props.theme.color_valuesources.get(props.valueType)};
                        cursor : pointer;
                    `;
            }
        }
        }  
    `,
    Input: CleaveInput,
    // styled(CleaveInput) `
    //     background-color : ${props => Utils.IsNotNullOrEmpty(props.value) ? props.theme.back : props.theme.input_back_empty};
    //     width : ${props => props.xsmall ? 30 : 48}px;
    //     box-sizing : border-box;
    //     font-size : ${props => props.xsmall ? 13 : props.small ? 16 : props.theme.sizecard_input_fontsize}px;
    //     border : none;
    //     color : ${props => props.theme.sizecard_input_color};
    //     padding-right : ${props => (props.xsmall || props.small) ? 4 : 8}px;
    //     text-align : right;      
    // `,
    TokenButton : styled.div`
        display : flex;
        flex-direction : column;
        justify-content : center;
        align-self : stretch;
        border-left : ${props => props.theme.border_onlight_line};
    `,
    UnitButton: styled.div`
        box-sizing : border-box;
        position : relative;
        color : ${props => props.theme.font_input};
        font-size : ${props => props.xsmall ? 12 : 14}px;
        fill : #A7A7A8;
        cursor : pointer;
        border-left : ${props => props.theme.border_seperator};
        flex : 1;
        min-width : 44px;
        max-width : 44px;
        &:hover {
            color : ${props => props.theme.font_hover};
            fill : ${props => props.theme.font_hover};
        }
        ${
            props => {
                if (props.fullWidth)
                    return css`
                            width : 100%;
                            min-width : 56px;
                        `;                        
            }
        }
    `,
    Icon_DropDown: (props) => {
        return (
            <Svg width="8" height="5" viewBox="0 0 8 5" style={{ marginTop: '2px' }} >
                <path fill="#A7A7A8" d="M6.91-.014l.752.768-3.83 3.908L0 .754l.754-.768L3.83 3.06" />
            </Svg>
        )
    },
    Icon_ExpandArrow : (props) => {        
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={props.size ||10} viewBox="0 0 13 8" style={{transform : `rotate(${props.rotate || (props.expanded ? 180 : 90)}deg)`, transition : '0.2s ease', transitionProperty : 'transform'}} >
                <path fill={props.fill || "#8B8B8B"} d="M10.82 7.32L12 6.12 6 0 0 6.12l1.18 1.2L6 2.52"/>   
            </svg>
        );
    },    
    UnitPanel: styled(FCol) `
        position : absolute;
        margin-top : 2px;
        z-index : 30000;
        min-width : 100%;       
        box-sizing : border-box;
        padding-left : 1px;
        border : ${props => props.theme.border_popup};
        background-color : ${props => props.theme.dropdown_back_dark};
        font-size : ${props => props.theme.sizecard_input_fontsize - 4}px;
        border-radius : 2px;
        box-shadow : ${props => props.theme.popup_shadow};
        ${
        props => {
            if (props.positionTopUp)
                return css`bottom : 100%;`;
            return css`top : 100%;`
        }
        }
        ${
        props => {
            if (props.unitsToLeft) {
                return css`
                        right : 0;
                    `;
            }
            else {
                return css`
                        left : 0;
                    `;
            }
        }
        }
    `,
    CustomUnitsPanel: styled(FCol) `
        border-left : 1px solid ${props => props.theme.sizecard_input_border_color};
    `,
    UnitHeader: styled(Div_Flex_Cell) `
        padding : 12px;        
        color : #D1D1D1;
    `,
    UnitItem: styled(Div_Flex_Cell) `
        padding : 4px;
        cursor : pointer;
        ${TransitionHoverOff}
        background-color : ${props => props.selected ? props.theme.back : 'unset'};
        border : ${props => props.selected ? '1px solid #6EBFC8' : 'none'};        
        &:hover {
            background-color : ${props => props.theme.back_lighter};
            color : ${props => props.theme.sizecard_input_color};
            ${TransitionHoverOn}
        }
    `
};


const SmallText = styled.div`
    color : ${props => props.theme.font_small};
`;

const LinkText = styled.span`
    cursor : pointer;
    padding : 4px;
    font-weight : 500;
    color : ${props => (props.selected || props.primary) ? props.theme.color_brand : props.theme.font};
    &:hover {
        color : ${props => props.selected ? props.theme.color_brand : props.theme.font_hover};
        background-color : ${props => props.theme.back_lighter};
    }
    ${
    props => {
        if (props.textAbbr) {
            return css`
                        text-overflow : ellipsis;
                        white-space : nowrap;
                        overflow : hidden;
                        min-width : 0;
                    `;
        }
    }
    }
`;

const HeaderTitle = styled.div`
    display : flex;
    align-items : center;
    font-size : 11px;
    letter-spacing : 0.07em;
    padding-right : 4px;
    font-weight : ${props => props.theme.isLight ? 700 : 500};
`;

const GroupLabel = styled.div`
    font-size : ${props => props.subGroup ? '12px' : '11px'};
    font-weight : ${props => props.theme.isLight ? 700 : 500};
    letter-spacing : 0.07em;
    color : ${props => props.theme.font};
`;
const DashedButton = styled.div`
    cursor : pointer;
    border : 1px dashed #636469;
    background-color : unset;
    display : flex;
    align-items : center;
    justify-content : center;
    padding : 8px;
    transition : all 0.4s ease;
    &:hover {
        background-color : #1e1f22;
        border : 1px solid #636469;
        color : #cfcfcf;
    }
`;

const InnerPanel = styled(FCol)`
    position : relative;
    background-color : ${props => props.theme.back_innerpanel};
    border : 1px solid;
    border-color : ${props => props.theme.back_innerpanel};
    border-radius : 4px;
    ${
        props => {
            if (props.theme.isLight) {
                return css`                    
                `;                
            }
            else {
                return css`
                    box-shadow : rgb(29, 30, 32) 0px 0px 4px 0px inset;
                `;
            }
        }
    }
`;

const InnerPanelGroup = styled.div`
    border-radius : 2px;
    border : ${props => props.theme.border_seperator};
    background-color : ${props => props.theme.back_lighter}; 
    padding : 8px;
    display : flex;
    flex-direction : column;
    align-items : stretch;
    flex : 1;
`;

const DragBox = styled.div`
border : ${props => props.theme.border_onlight_line};
display : flex;
justify-content : center;
align-items : center;
cursor : move;
padding-right : 6px;
    ${
    props => {
        if (props.rightBorder) {
            return css`
                    border : none;
                    border-right : ${props => props.theme.border_onlight_line};
                `;
        }
        if (!props.first) {
            return css`border-top : none;`;
        }
    }
    }
`;

const DesignItem_Placeholder = styled.div`
    background : url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' d='M0 0h16v16H0z'/%3E%3Cpath fill='%23fff' d='M0 0h1v1H0z'/%3E%3C/svg%3E");
    background-size : 10px;    
    position : absolute;
    top : 0;
    left : 0;
    right : 0;
    bottom : 0;
`;

const BackClosableTitle = (props) => {
    return (
        <FRow cursorPointer alc onClick={props.onClick || props.onClose} style={{overflow : 'hidden', ...props.style}}>
            <Icons.Icon_Button 
                hasFill fill={props.fill} hoverFill={props.hoverFill}
                style={{transform : props.large ? 'scale(1.5)' : 'none', marginRight : props.large ? '8px' : 0}}
            >
                <Icons.Arrow_Back size={24} />
            </Icons.Icon_Button>
            <HeaderTitle style={{...props.titleStyle, overflow : 'hidden'}}>{props.title}</HeaderTitle>
        </FRow>
    )
}

const scrollstyles = {
    trackvertical : {position : 'absolute', top : '2px', right : '2px', bottom : '2px', width : '2px'},
    thumbvertical : {width : '2px'},
    trackhorizontal : {position : 'absolute', left : '2px', bottom : 0, right : '2px', height : '2px'},
    thumbhorizontal : {height : '2px'}
};
const scrollstyles_big = {
    trackvertical : {position : 'absolute', top : '2px', right : 0, bottom : '2px', width : '4px'},
    thumbvertical : {width : '4px'},
    trackhorizontal : {position : 'absolute', left : '2px', bottom : 0, right : '2px', height : '4px'},
    thumbhorizontal : {height : '4px'}
};

class CustomScrollbars extends React.Component {
    render() {
        const {ScrollRef, color, big, ...rest} = this.props;
        const styles = this.props.big ? scrollstyles_big : scrollstyles;
      return (          
        <Scrollbars
            hideTracksWhenNotNeeded
            autoHide={!this.props.notAutoHide}
            {...rest}
            ref={ScrollRef}
            renderTrackVertical={
                ({ style, ...props }) => <div {...props} style={{...style, ...styles.trackvertical}}/>
            }
            renderThumbVertical={
                ({ style, ...props }) => <div {...props} style={{...style, ...styles.thumbvertical, backgroundColor : color || 'rgba(73, 75, 80, 0.51)'}}/>
            }
            renderTrackHorizontal={
                ({ style, ...props }) => <div {...props} style={{...style, ...styles.trackhorizontal}}/>
            }
            renderThumbHorizontal={
                ({ style, ...props }) => <div {...props} style={{...style, ...styles.thumbhorizontal, backgroundColor : color || 'rgba(73, 75, 80, 0.51)'}}/>
            }
        >
            {this.props.children}
        </Scrollbars>
      );
    }
}

const HoverParent = styled.div`
    ${props => props.defaultStyle};
    &:hover {
        ${props => props.hoverStyle};
    }
`;
const HoverChild = styled.div`    
    opacity : 0;
    transition : opacity 0.2s ease;
    transition-delay : 0.1s;
    ${props => props.defaultStyle};
    ${HoverParent}:hover &{
        opacity : 1;
        ${props => props.hoverStyle};
    }
`;

const NewItemCell = styled.div`
    display : flex;
    flex : 1;
    align-items : center;
    justify-content : center;
    font-size : 44px;
    cursor : pointer;
    border : 1px dashed #636469;
    transition : all 0.2s ease;
    &:hover {
        font-size : 56px;
        border : 1px dashed #aeaeae;
    }
`;

const PopupItem = styled.div`
    padding-left : 8px;
    padding-right : 8px;
    min-height : 30px;
    max-height : 30px;
    display : grid;
    align-items : center;
    white-space : nowrap;
    font-size : ${props => props.largeText ? '12px' : '11px'};
    font-weight : ${props => props.largeText ? 500 : 400};
    box-sizing : border-box;
    ${TransitionHoverOff}
    cursor : pointer;
    &:hover {
        background-color : ${props => props.light ? props.theme.back : props.theme.back_lighter};
        color : ${props => props.theme.sizecard_input_color};
        ${TransitionHoverOn}
    }
    border-top : ${props => props.seperator ? props.theme.border_seperator : 'none'};
    border-left : ${props => props.theme.isLight ? '2px solid' : '1px solid'};
    border-left-color : ${props => props.selected ? props.theme.color_brand : 'rgba(0,0,0,0)'};
    ${
        props => {
            if (props.hover)
            {
                return css`
                    background-color : ${props => props.theme.back};
                    color : ${props => props.theme.sizecard_input_color};
                    ${TransitionHoverOn}
                `;
            }
        }
    }
`;

const NewItemButton = ({size, ...props}) => (
    <NewItemCell {...props}>
        <Icons.Icon_Button hasFill style={{flex : 1, alignSelf : 'stretch'}}>
            <Icons.Icon_Plus size={size || 50} />
        </Icons.Icon_Button>
    </NewItemCell>
)

const Styles = {
    Absolute : {
        position : 'absolute',
        top : 0, left : 0, right : 0, bottom : 0
    },
    Flex : {
        Cell : {
            display : 'flex',
            justifyContent : 'center',
            alignItems : 'center'
        },
        Column : {
            display : 'flex',
            flexDirection : 'column'
        },
        Row : {
            display : 'flex',
            flexDirection : 'row'
        },
        RowAlcJsb : {
            display : 'flex',
            flexDirection : 'row',
            justifyContent : 'space-between',
            alignItems : 'center'
        }
    },
    FontStyles : {
        Monospace : {
            fontFamily : 'monospace'
        }
    },
    TextAbbr : {
        textOverflow : 'ellipsis',
        whiteSpace : 'nowrap',
        overflow : 'hidden'
    },
    MultilineTextAbbr : {
        display : '-webkit-box',
        '-webkit-line-clamp' : 4,
        '-webkit-box-orient' : 'vertical',
        overflow : 'hidden'
    },
    Font : () => {
        return {
            fontFamily : FONT_FAMILY,
            fontSize : '12px',
            fontWeight : 400,
            color : CurrentTheme.theme.font,
            fontWeight : CurrentTheme.theme.font_weight || 400,
            letterSpacing : '0.07em'
        }
    },
    FontFamily : () => { return {fontFamily : FONT_FAMILY}; }
}

export {
    AppTheme,
    CurrentTheme,
    themes,
    Buttons,
    Icons,
    SortAndFilter,
    Svg,
    FRow,
    FCol,
    Div_Flex_Cell,
    Div_Full_Center,
    Div_Relative,
    Div_Styled,
    Div_Default,
    DragBox,
    Loading_Icon,
    TransitionHoverOn,
    TransitionHoverOff,
    Card_Input,
    Form_Input,
    RenderValueError,
    Icons_Preview,
    AbsoluteOverlay,
    Overlay,
    TextSpanAbbr,
    TextDivAbbr,
    MultilineAbbr,
    SelectPanelGroup,
    GridBackground,
    CssGridBackground,
    GetGridBackgroundStyle,
    GridBack_image,
    GridBack_DarkImage,
    NoteArea,
    CleaveInput,
    Component_SizeEditor,
    LinkText,
    SmallText,
    HeaderTitle,
    GroupLabel,
    ToolbarStyles,
    DashedButton,
    DesignItem_Placeholder,
    BackClosableTitle,
    InnerPanel,
    CustomScrollbars,
    HoverParent,
    HoverChild,
    NewItemCell,
    NewItemButton,
    InnerPanelGroup,
    PopupItem,
    Styles
}
