import React from 'react';
import {
    SC,
    Globals,
    Utils,
    AppLayout
} from '../../../../../../../../importer';

import BaseSelectToken from '../../BaseSelectToken';
import DuratioTokenList, { DurationList, GetDurationPatternList, AddNewDurationToken, GetDurationTokenItem } from '../../../../../left/designsystem/motion/patterns';
import DurationTokenEditor from '../../../../../left/designsystem/motion/patterns/item';

export default class SelectDurationToken extends BaseSelectToken
{
    constructor(props) {
        super(props);

        this.titleNewToken = 'NEW DURATION';
        this.moduleName = 'Durations';
        this.tokenType = Globals.ProjectManager.Tokens.Types.Motion;    
        this.SaveToken = this.SaveToken.bind(this);

        this.Ref_ImageEdit = React.createRef();

        this.Load(this.props);
    }
    IsTokenPropChanged(nextProps, nextState) {
        if (Utils.HasAnyChange(this.props, nextProps, 'GlobalState')) {
            this.Load(nextProps);
            return true;
        }        
    }
    GetTokens(props) {
        return GetDurationPatternList();
    }            
    GetNewModel(Id) {
        const Model = Globals.ProjectManager.Tokens.TimePatterns.NewPattern(Id);
        AddNewDurationToken(Model, Id);
        this.FireSelectToken(Id);
        return Model;
    }
    SaveToken() {
        if (this.EditingToken.IsNew) {
            this.props.onUpdate && this.props.onUpdate();
            AppLayout.Refs.DesignSystem.Durations && AppLayout.Refs.DesignSystem.Durations.Reload();
            return GetDurationTokenItem(this.EditingToken.Model, this.EditingToken.Id);
        }        
        else {
        }                  
    }  
    onCloseTokenEditor() {
        if (this.EditingToken && this.EditingToken.IsNew) {
            delete this.EditingToken;            
            this.RCUpdate();
            return;
        }
        super.onCloseTokenEditor();
    }
    onCancelAddToken() {
        const willDeleteId = this.EditingToken.Id;        
        this.FireSelectToken(this.LastBoundTokenId);
        delete this.LastBoundTokenId;
        super.onCancelAddToken();
        Globals.ProjectManager.Tokens.Delete(Globals.ProjectManager.Tokens.Types.Motion, willDeleteId);
    } 
    UpdateSelectedToken() {
        if (this.EditingToken) {
            const SelectedToken = Utils.Find(this.tokens, (token) => {return token.id === this.EditingToken.Id});
            const token = Globals.ProjectManager.Tokens.Token(this.EditingToken.Id);
            const EaseCurve = Globals.ProjectManager.Tokens.ValueOf({model : token, statearray : this.props.StateArray});
        }
        // SelectedToken.value = Utils.GetTransitionCss(transforms, transform.originX, transform.originY, Globals.ProjectManager.Tokens.ValueOfId); 
    }
    GetStyleName() {
        return 'transition';
    }
    renderTokenListHeader() {

    }
    renderTokenList() {
        let content = (
            <DurationList 
                patterns={this.tokens}
                title='Durations'
                notDraggable
                noPadding
                selectedId={this.props.durationId}
                onEdit={(patternId) => {
                    this.SelectToken(patternId)
                }}
                onAdd={this.AddToken}
            />
        )

        return (
            <SC.CustomScrollbars hideTracksWhenNotNeeded autoHide>
                {content}
            </SC.CustomScrollbars>                             
        )       
    }
    renderTokenEditor() {
        const timeScale = Globals.ProjectManager.Tokens.TimeScale.Get();
        return (
            <DurationTokenEditor 
                editing
                compact
                id={this.EditingToken.Id}
                pattern={this.EditingToken.Model}
                GlobalState={Globals.ProjectManager.GlobalState}                      
                onPreviewChange={this.EditingToken.IsNew ? this.PreviewNewToken : null}
                onSave={this.SaveToken}
                baseSize={timeScale.baseSize}
                ratio={timeScale.ratio}
            />                 
        )        
    }
    GetSelectedTokenId() {
        return this.props.transition;
    }
    renderSelectedToken() {
        if (this.GetSelectedTokenId()) {
            return this.renderSelectedTokenHeader();
        }
    }
}
