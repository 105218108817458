import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppLayout,
    Links,
    Events,
    Loading,
    Strings,
    Globals
} from '../../../../../../../importer';

import {TokenItemBox, TokenPanelHeader, TokenItemDragHandle, TokenItemBoxHoveredName} from '../../common';
import { SortableList, SortableListItem } from '../../../../../../../components/SortableContainer';
import TokenTypeEditor from './item';
import BaseTokenList from '../../BaseTokenList';
import {Droppable, Draggable} from 'react-beautiful-dnd';
import { StatefulTokenListItemName } from '../../colors';
import { GRANT_TYPES } from '../../../../../manager';
import { LeftScrollPanel } from '../../../common';
import CustomTokenList from '../tokens';

export default class CusotmTokenDefinitions extends BaseTokenList
{
    constructor(props) {
        super(props);

        this.title = this.props.title || 'CUSTOM TOKEN TYPES';
        this.moduleName = 'CustomTokenTypes';
        this.tokenType = Globals.ProjectManager.Tokens.Types.CustomTokenTypes;
        this.noHeader = true;
        this.hasGroups = true;
        this.noListView = true;
        this.onEditTokenType = this.onEditTokenType.bind(this);
        this.EditTokenType = this.EditTokenType.bind(this);
        this.onDeleteTokenType = this.onDeleteTokenType.bind(this);
        this.onCloneTokenType = this.onCloneTokenType.bind(this);
        this.onCancelAddTokenType = this.onCancelAddTokenType.bind(this);
        this.onSubmitNewTokenType = this.onSubmitNewTokenType.bind(this);

        AppLayout.Refs.DesignSystem.TokenTypes = this;

        super.LoadOptions();
        if (this.expanded || this.props.singleView) {
            this.expanded = true;
            this.Load(this.state.isGroupView);
        }
    }
    componentWillUnmount() {
        super.componentWillUnmount();
        AppLayout.Refs.DesignSystem.TokenTypes = null;
    }
    Load(groupView) {        
        this.tokens = GetTokenTypeTokenList();        
        if (groupView)
            this.groups = super.GetGroupedTokenList(this.tokens)
    }        
    SearchToken(filter) {
        let filterText;
        if (Utils.IsNotNullOrEmpty(filter))
            filterText = filter.toUpperCase();
        Utils.ForEach(this.tokens, (token, i) => {
            delete token.filtered;
            if (filterText) {
                if (token.name.toUpperCase().indexOf(filterText) < 0) {                    
                    token.filtered = true;
                }                
            }
        });
        this.RenderId = Utils.Id();
        this.RCThrottledUpdate_1();
    }  
    AddTokenToGroup(group) {
        if (!Globals.ProjectManager.CheckGrant(GRANT_TYPES.EDIT_TOKEN.ALL)) {
            return;
        }

        this.AddingToGroup = group;
        this.IsNew = true;
        this.EditTokenTypeId = Utils.Id();
        this.EditTokenTypeModel = {
            name : 'New Token Type'
        };
        
        this.props.onPanelOverlay({
            show : true,
            render : (props) => {
                return (
                    <SC.FCol fw fh>
                        <TokenPanelHeader 
                            title='NEW TOKEN TYPE' 
                            notBackClosable
                            hasAddCancel 
                            onClose={this.onCancelAddTokenType} 
                            onCancel={this.onCancelAddTokenType} 
                            onAdd={this.onSubmitNewTokenType} 
                        />
                        <LeftScrollPanel>
                            <TokenTypeEditor 
                                id={this.EditTokenTypeId}
                                newModel={this.EditTokenTypeModel}
                                GlobalState={props.GlobalState}
                                GlobalStateId={props.GlobalStateId}
                                offline
                            />
                        </LeftScrollPanel>                        
                    </SC.FCol>                    
                )
            }
        })
    }
    onEditTokenType(id, e) {
        if (this.props.onSelect) {
            this.props.onSelect(id, e);
            return;
        }
        this.EditTokenType(id);
    }    
    onShowMenu(id, e) {
        this.props.onShowMenu && this.props.onShowMenu(id, e);
    }
    EditTokenType(id) {        
        this.EditTokenTypeId = id;
        this.props.onPanelOverlay({
            show : true,
            render : (props) => {
                return (
                    <SC.FCol fw fh>
                        <TokenPanelHeader 
                            title='EDIT TOKEN TYPE' 
                            hasAddCancel 
                            onClose={this.onSubmitNewTokenType} 
                            onCancel={this.onCancelAddTokenType} 
                            onAdd={this.onSubmitNewTokenType} 
                            onDelete={this.onDeleteTokenType} 
                            onClone={this.onCloneTokenType}
                        />
                        <LeftScrollPanel>
                            <TokenTypeEditor 
                                ref={this.Ref_TokenTypeEdit}
                                id={id}
                                GlobalState={props.GlobalState}
                                GlobalStateId={props.GlobalStateId}
                                onCancel={this.onCancelAddTokenType}
                            />
                        </LeftScrollPanel>                        
                    </SC.FCol>                    
                )
            }
        })
    }    
    onCloneTokenType() {
        const cloneId = Globals.ProjectManager.Tokens.Clone(this.EditTokenTypeId, this.tokenType);
        this.Load(this.props);
        this.EditTokenType(cloneId);        
    }
    onDeleteTokenType() {
        if (Globals.ProjectManager.Tokens.Delete(this.tokenType, this.EditTokenTypeId)) {
            this.Load(this.props);
            this.onCancelAddTokenType();
        }
    }
    onCancelAddTokenType() {
        delete this.IsNew;
        delete this.EditTokenTypeModel;
        delete this.EditTokenTypeId;

        this.props.onPanelOverlay({close : true});
        this.RCUpdate();
    }
    onSubmitNewTokenType() {
        if (this.IsNew) {
            
            const TokenTypeItem = AddNewTokenType(this.EditTokenTypeModel, this.EditTokenTypeId);
            this.tokens.push(TokenTypeItem);

            if (this.AddingToGroup) {
                this.AddingToGroup.tokens.push(TokenTypeItem);
                Globals.ProjectManager.Tokens.AddTokenToGroup({type : this.tokenType, groupid : this.AddingToGroup.id, tokenid : TokenTypeItem.id})
                delete this.AddingToGroup;
            } 

            this.WillScrollTo = this.EditTokenTypeId;
            this.selectedId = this.EditTokenTypeId;
        }        
        else {
            const token = Globals.ProjectManager.Tokens.Token(this.EditTokenTypeId);
            if (token) {
                const localpattern = Utils.Find(this.tokens, (item) => {return item.id === this.EditTokenTypeId});
                localpattern.name = token.name;
                Events.BCE(Events.GLOBAL.TOKEN_VALUE_CHANGING);
            }            
        }
                
        Events.BCE(Events.GLOBAL.TOKENS_CHANGED);
        this.onCancelAddTokenType();
    }    
    renderTokenList(tokens, gorupId) {
        let style;
        if (this.state.isListView) {
            style = {
                marginLeft : 0,
                ...SC.Styles.Flex.Column
            }
        }
        else {
            style = {
                margin : 0,
                ...SC.Styles.Flex.Column
            }
        }

        const transformItems = [];
        Utils.ForEach(tokens, (model, i) => {                   
            !model.filtered && transformItems.push(
                <SortableListItem
                    key={model.id}
                    draggableId={model.id}                    
                    index={i}
                    ItemBoxType={TokenTypeListItem}
                    BoxProps={{
                        id : model.id,
                        name : model.name,
                        value : model.value,
                        valueState : model.valueState,
                        isListView : this.state.isListView,
                        onSelect : this.onEditTokenType.bind(this, model.id),
                        onShowMenu : this.onShowMenu.bind(this, model.id),
                        rest : this.props
                    }}                
                />
            )
        });    
        return (
            <Droppable 
                droppableId={gorupId}
                type={'TOKENS'}                
            >
                {
                    (provided, snapshot) => (
                        <div
                            {...provided.droppableProps} 
                            ref={provided.innerRef} 
                            style={{
                                ...provided.droppableProps.style,
                                ...style,
                                border : '1px dashed',                                                    
                                borderColor : snapshot.isDraggingOver ? SC.CurrentTheme.theme.color_brand : 'transparent'                                
                            }}
                        >
                            {transformItems}
                            {provided.placeholder}
                        </div>
                    )
                }
            </Droppable>
        )  
    }    
}

export const TokenTypeListItem = ({onSelect, id, onShowMenu, onChange, notDraggable, isListView, style, sortableProps, name, value, valueState, onPreview, selected, rest, ...props}) => {
    const style_box = {
        ...style,
        padding : '6px',
        flex : 1,
        marginRight : '8px',
        marginBottom : 0
    }
    if (selected) {
        style_box.borderLeft = '1px solid';
        style_box.borderLeftColor = SC.CurrentTheme.theme.color_brand;
    }
    return (
        <CustomTokenList 
            {...rest}
            singleView={false}
            title={
                <SC.TextDivAbbr style={{textTransform : 'uppercase'}}>{name}</SC.TextDivAbbr>
            }
            onEditDefinition={onSelect}
            tokenType={id}
            justContent
        >

        </CustomTokenList>
    )
    return (        
            <SC.FRow f1 alc jsb overflowHidden style={{marginBottom : '4px'}}>
                <TokenItemBox onClick={onSelect} onContextMenu={onShowMenu} style={style_box} {...onPreview} {...rest} selected={selected}>
                    <StatefulTokenListItemName 
                        name={name}
                        sortableProps={sortableProps}
                        ValueState={valueState}
                    />
                </TokenItemBox>                      
            </SC.FRow>                 
    )
}

export const GetTokenTypeTokenList = (StateArray) => {
    const tokenids = Globals.ProjectManager.Tokens.CustomTokenTypes();
    return GetTokenTypeTokenListOfIds(tokenids, StateArray);
}
export const GetTokenTypeTokenListOfIds = (tokenids, StateArray) => {
    
    const tokens = Globals.ProjectManager.Tokens.TokenList(Globals.ProjectManager.Tokens.Types.CustomTokenTypes);
    const tokenitems = [];

    Utils.ForEach(tokenids, (id, i) => {
        const token = tokens[id];
        if (token) {
            const info = {};
            const tokenvalue = Utils.IsTrue(Globals.ProjectManager.Tokens.ValueOf({model : token, statearray : StateArray, info : info}));
            const tokenitem = {
                id : id,
                name : token.name,
                description : token.description,
                value : tokenvalue,
                valueState : info.ValueState
            }
            tokenitems.push(tokenitem);                
        }
    });


    return tokenitems;
}

export const AddNewTokenType = (model, id) => {
    Globals.ProjectManager.Tokens.Add({
        type : Globals.ProjectManager.Tokens.Types.CustomTokenTypes,
        name : model.name,
        value : model.value,
        id : id,
        fieldIds : model.fieldIds,
        fields : model.fields
    })
    return {
        id : id,
        name : model.name,
        value : model.value
    };
}