import React from 'react';
import styled from 'styled-components';
import {
    SC,
    ReactBaseComponent,
    Loading,
    AppLayout
} from '../importer';

const OverlayFull = styled.div`
    position : fixed;
    top : 0;
    left : 0;
    right : 0;
    bottom : 0;
    background-color : ${props => props.NoBackground ? undefined : ( props.Opaque ? SC.CurrentTheme.theme.back_lighter : 'rgba(0, 0, 0, 0.3)')};
    // opacity : ${props => props.closing ? 0 : (props.opening ? 0.9 : 0)};
    transition : background-color .1s ease;
    z-index : 999999999
`;

const Overlay_Message = styled.div`
    display : flex;
    flex-direction : column;
    align-items : center;
    justify-content: space-around;
    min-width : ${props => props.compact ? 'unset' : '400px'};
    min-height : 200px;
    background-color : ${props => props.theme.color_content_bacj};
`;



export const ModelViewPanel = styled(SC.Div_Styled)`
    width : ${props => props.width || '60vw'};
    height : ${props => props.height || '80vh'};
    border : ${props => props.focusedColor || props.theme.border_popup};
    box-sizing : border-box;
    background-color : ${props => props.light ? props.theme.dropdown_back : props.theme.dropdown_back_dark};
    box-shadow : ${props => props.theme.popup_shadow};    
    display : flex;
    border-radius : 4px;
`;
const ModelView = (props) => {
    return (
        <SC.Div_Full_Center>
            <ModelViewPanel {...props}>
                {props.children}
            </ModelViewPanel>
        </SC.Div_Full_Center>
    )
};

const ModelView_WithHeader = (props) => {
    return (
        <ModelView {...props}>
            <SC.FCol flex={1}>
                {
                    !props.compact &&
                    <SC.FRow alc jsb>
                        <span style={{paddingLeft:'48px', paddingTop:'24px'}}>{props.title}</span>
                        <span onClick={props.onClose} style={{cursor : 'pointer', paddingRight:'24px', paddingTop:'24px'}}>
                            <SC.Icons.Icon_Close fill='#8B8B8B'/>
                        </span>
                    </SC.FRow>
                }                
                <SC.FCol f1 pt={48} pb={48}>
                    <SC.CustomScrollbars hideTracksWhenNotNeeded autoHide>
                        <SC.FRow flex={1} pa={48} pt={0} pb={0}>
                            {props.children}
                        </SC.FRow>
                    </SC.CustomScrollbars>
                </SC.FCol>
            </SC.FCol>
        </ModelView>
    )
}

class Overlay_Full extends React.Component
{
    constructor(props) {
        super(props);
        this.state = {};
    }
    Close(CB) {
        this.setState({closing : true});
        setTimeout(() => {
            CB();
        }, 200);
    }
    componentDidMount() {
        setTimeout(() => {
            this.setState({
                opening : true
            });
        }, 1);
    }
    render() {
        return (
            <OverlayFull
                closing={this.state.closing}
                opening={this.state.opening}
                NoBackground={this.props.Options.NoBackground}
                Opaque={this.props.Options.Opaque}
            >
                {
                    (this.props.compact || this.props.Options && this.props.Options.NoClose) ? null :
                    <div style={{position : 'absolute', right : '10px', top : '10px', cursor:'pointer', padding : '10px'}}
                        onClick={() => {this.props.OnClose()}}
                    >
                        <SC.Icons.Icon_Close size={40}/>
                    </div>
                }
                {this.props.children}
            </OverlayFull>
        )
    }
}

class OverlayManager extends ReactBaseComponent
{
    constructor(props) {
        super(props);
        this.Overlays = [];
        this.OnKeyUp = this.OnKeyUp.bind(this);
    }
    componentDidMount() {
        super.componentDidMount();
        AppState.OverlayManager.Instance = this;
        window.addEventListener('keyup', this.OnKeyUp);
    }
    componentWillUnmount() {
        super.componentWillUnmount();
        delete AppState.OverlayManager.Instance;
        window.removeEventListener('keyup', this.OnKeyUp);
    }
    OnKeyUp(e) {
         if (e.keyCode === 27)
         {
             e.preventDefault();
             e.stopPropagation();
             this.Close();
         }
         else if (e.keyCode === 80 && e.altKey) {
         }
         else if (e.keyCode === 79 && e.altKey) {
         }
         else if (e.keyCode === 40)
         {
             // MoveDown
         }
         else if (e.keyCode === 38)
         {
             // MoveUp
         }
         else if (e.keyCode === 13) {
             // Enter
         }
    }
    ShowVersionNotification() {
        this.HasVersionChange = true;
        this.RCUpdate();
    }
    ReloadPage() {
        window.location.reload(true);
    }
    Show(Proxy, Overlay) {
        Proxy.Index = this.Overlays.length;
        this.Overlays.push({
            Proxy : Proxy,
            Content : Overlay
        });
        this.RCUpdate();
    }
    Close(Proxy, Options) {
        let i = this.Overlays.length - 1;
        if (Proxy && Proxy.Index < this.Overlays.length)
            i = Proxy.Index;
        this.CloseAt(i);
        Options && Options.OnClose && Options.OnClose();
    }
    CloseAt(i) {
        this.Overlays.splice(i, 1);
        if (this.refs["Ref_Overlay_" + i]) {
            this.refs["Ref_Overlay_" + i].Close(() => {
                this.RCUpdate();
            })
        }
    }
    ShowModal(Content, Options) {
        const Proxy = {
            Options : Options || {}
        };
        Proxy.Options.NoClose = true;
        this.Show(Proxy,
            <ModelView_WithHeader compact={this.props.compact} title={Options.title} width={Options.width} onClose={() => this.Close(Proxy, Options)}>
                {Content}
            </ModelView_WithHeader>
        );
        return Proxy;
    }
    ShowMessage(Message, Options) {
        let Proxy = {
            Options : Options
        };
        let Buttons = null;
        if (Options.Confirmation) {
            Buttons = (
                <SC.FRow>
                    <SC.Button fixed onClick={() => {
                                this.Close(Proxy, Options);
                        }}>No</SC.Button>
                    <SC.Button fixed onClick={() => {
                                this.Close(Proxy, Options);
                                Options.OnYes && Options.OnYes();
                        }}>Yes</SC.Button>
                </SC.FRow>
            )
        }
        else {
            SC.Buttons = (
                <SC.FRow>
                    <SC.Button fixed onClick={() => {
                                this.Close(Proxy, Options);
                        }}>OK</SC.Button>
                </SC.FRow>
            )
        }
        this.Show(Proxy,
            <div style={{width:'100%',height:'100%', display:'flex', justifyContent:'center',alignItems:'center'}}>
                <Overlay_Message compact={this.props.compact}>
                    <div style={{fontSize : '16px', letterSpacing : '0.01em', fontWeight : 300, padding:'20px'}}>
                        {Message}
                    </div>
                    {Buttons}
                </Overlay_Message>
            </div>
        );
    }
    ShowLoading(Message) {
        this.LoadingMessage = Message;
        this.LoadingStartTime = new Date();
        this.RCUpdate();
    }
    HideLoading() {
        let elapsed = (new Date()) - this.LoadingStartTime;
        const maxLoadingTime = 200;
        if (elapsed < maxLoadingTime) {
            setTimeout(() => {
                this.LoadingMessage = null;
                this.RCUpdate();
            }, maxLoadingTime - elapsed);
            return;
        }
        this.LoadingMessage = null;
        this.RCUpdate();
    }
    render() {
        this.state.ShouldUpdate = false;

        let versionChange;

        if (this.HasVersionChange && !AppLayout.isDevelopment) {
            versionChange = (
                <SC.FCol alc style={{
                    position : 'fixed', top : '46px', right : '14px',
                    padding : '16px', zIndex : 999999999,
                    borderRadius : '8px',
                    paddingTop : '8px',
                    paddingBottom : '8px',
                    border : SC.CurrentTheme.theme.border_ondark,
                    boxShadow : SC.CurrentTheme.theme.popup_shadow,
                    backgroundColor : SC.CurrentTheme.theme.back_darker,
                    color : SC.CurrentTheme.theme.color_notification.error,
                    fontSize : '12px',
                    lineHeight : '24px',
                    cursor : 'pointer',
                }} onClick={this.ReloadPage.bind(this)}>
                    <div>New Version Released!</div>
                    <div>Click to Reload Page</div>
                </SC.FCol>
            )
        }

        if (this.Overlays.length > 0) {
            return (
                <div>
                    {
                        this.Overlays.map((Overlay, i) => {
                            return (
                                <Overlay_Full ref={"Ref_Overlay_" + Overlay.Proxy.Index} key={i}
                                    OnClose={this.CloseAt.bind(this, i)}
                                    Options={Overlay.Proxy.Options}
                                    compact={this.props.compact}
                                >
                                    {Overlay.Content}
                                </Overlay_Full>
                            );
                        })
                    }
                    {
                        this.LoadingMessage ? <Loading message={this.LoadingMessage}/> : null
                    }
                    {versionChange}
                </div>
            )
        }
        return (
            <React.Fragment>
                {versionChange}
                {this.LoadingMessage && <Loading message={this.LoadingMessage}/>}
            </React.Fragment>                
        );
    }
}

export default OverlayManager;
