import {
    AppState,
    Events,
    MetaData,
    Utils,
    Globals,
    SC
} from '../../../importer';
import Strings from '../../../appstate/Strings';
import {FontLoader} from '../../../toolabs-importer';
import throttle from 'lodash/throttle';
import SystemStateManager from './systemstates';
import TokensManager, {TokenTypes} from './tokens';

import CustomTokenManager from './customtokens';
import MockupManager from './mockupdatamanager';
import TemplatesManager from './templates';
import PublishManager from './publisher';
import RelationManagerClass from './relations';
import DesignSystemHistoryManager from '../../../appstate/historymanager/designsystem';
import { Devices as DefaultDevices, GetDeviceType, SetNewDeviceWidth } from '../panzoom/responsive/devices';
import { Publish_TokenValues, GetTypefaceStyle } from '../../../../toolabs-modules/toolabs-publish/theme';
import DocumentManagerClass from './documents';
import { UpdateComponentLists } from '../panels/left/components/listInstances';
import SessionManager, { TEAM_MESSAGE_TYPES } from './sessionmanager';
import AuditManager from './auditmanager';
import { GetUserId } from '../../../appstate/AppState';
import AppLayout from '../../../appstate/AppLayout';
import FigmaManager from './figma';

export default class ProjectManager {
    constructor() {
        this.Initialize = this.Initialize.bind(this);
        
        this.TokenChangeBroadcasted = this.TokenChangeBroadcasted.bind(this);
        this.onTokenChangeBroadcasted = throttle(this.TokenChangeBroadcasted, 100);

        this.MockupSchemaChangeBroadcasted = this.MockupSchemaChangeBroadcasted.bind(this);
        this.onMockupSchemaChangeBroadcasted = throttle(this.MockupSchemaChangeBroadcasted, 100);

        const that_project = this;

        const GetData = () => {
            return Utils.Get(that_project.Data, {}, 'Data');
        }

        this.DataManager = {
            Storage : {
                Save(Id, File, MetaFile, Progress) {
                    return AppState.GetDataApi().Storage.Save(that_project.Id, Id, File, MetaFile, Progress);
                },
                Delete(Id) {
                    if (AppState.Data.Board.DefaultDemoId === that_project.Id)
                        return;
                    return AppState.GetDataApi().Storage.Delete(that_project.Id, Id);
                },
                SaveFont(Id, FontData, MetaFile, Progress, onDownloadUrl) {
                    return AppState.GetDataApi().Storage.SaveFont(that_project.Id, Id, FontData, MetaFile, Progress, onDownloadUrl);
                }
            },
            Catched: {
                Set(Value, ...Path) {
                    AppState.GetDataApi().update_path_board_catched(that_project.Id, Path, Value);
                },
                Get(...Path) {
                    return AppState.GetDataApi().get_path_board_catched(that_project.Id, Path);
                },
                Delete(...Path) {
                    AppState.GetDataApi().delete_path_board_catched(that_project.Id, Path);                    
                }
            },
            Replace(NewData) {
                that_project.Data.Data = NewData;
                AppState.GetDataApi().update_path_project_data(that_project.Id, '', NewData);           
            },
            Set(Value, ...Path) {
                if (!that_project.Id)
                    return;
                const ChangeId = Utils.Id();
                Utils.Set(GetData(), ChangeId, 'ChangeId')
                Utils.Set(GetData(), Utils.UseUndefined(Value, null), ...Path)
                if (that_project.Offline)
                    return;
                // AppState.GetDataApi().update_path_project_data(that_project.Id, 'ChangeId', ChangeId);
                AppState.GetDataApi().update_path_project_data(that_project.Id, Path, Utils.UseUndefined(Value, null));
            },
            Delete(...Path) {
                const ChangeId = Utils.Id();
                Utils.Set(GetData(), ChangeId, 'ChangeId')
                Utils.UnSet(GetData(), ...Path);
                if (that_project.Offline)
                    return;
                // AppState.GetDataApi().update_path_project_data(that_project.Id, 'ChangeId', ChangeId);
                AppState.GetDataApi().delete_path_project_data(that_project.Id, Path);
            },
            Get(DefValue, ...Path) {
                return Utils.Get(GetData(), DefValue, ...Path);
            },
            GetLive(...Path) {
                return AppState.GetDataApi().get_path_project_data(that_project.Id, Path);
            },
            SetTokensChanged() {
                if (!that_project.Id)
                    return;
                const ChangeId = Utils.Id();

                const sourceEditor = Utils.UseNullOrEmpty(that_project.Editor, 'Web');
                // console.log(`Tokens Changed ` + sourceEditor);
                Utils.Set(GetData(), ChangeId, 'Changes', 'Tokens', sourceEditor)
                // AppState.GetDataApi().update_path_project_data(that_project.Id, ['Changes', 'Tokens', sourceEditor], ChangeId);
            },            
        };

        this.Options = {
            Get(defaultValue, ...path) {
                if (that_project.TeamId && that_project.Grant !== 'owner')
                    return that_project.DataManager.Get(defaultValue, 'Options', GetUserId(), ...path);
                else
                    return that_project.DataManager.Get(defaultValue, 'Options', ...path);
            },
            Set(value, ...path) {
                if (that_project.TeamId && that_project.Grant !== 'owner')
                    that_project.DataManager.Set(value, 'Options', GetUserId(), ...path);
                else
                    that_project.DataManager.Set(value, 'Options', ...path);
            },
            Globals : {
                Get(defaultValue, ...path) {
                    return that_project.DataManager.Get(defaultValue, 'Options', ...path);
                },
                Set(value, ...path) {
                    that_project.DataManager.Set(value, 'Options', ...path);
                },
                Keys : {
                    DisableUniqueTokenNames : {
                        name : 'disableUniqueTokenNames',
                        value : false
                    },
                    PromptBeforeTokenDelete : {
                        name : 'promptBeforeTokenDelete',
                        value : false
                    }
                }                
            }            
        }

        this.Units = {
            System() {
                return [
                    {
                        name: Strings.UNITS.px
                    },
                    {
                        name: Strings.UNITS.em,
                    },
                    {
                        name: Strings.UNITS.rem,
                    },
                    {
                        name: Strings.UNITS.per,
                    },
                    {
                        name: Strings.UNITS.vw,
                    },
                    {
                        name: Strings.UNITS.vh,
                    },
                    {
                        name: Strings.UNITS.vmin,
                    },
                    {
                        name: Strings.UNITS.vmax
                    }
                ];
            },
            Get() {
                return that_project.DataManager.Get([], 'Units') || [];
            },
            Default() {
                return 'px';
            },
            FullSize(value = 100) {
                return {
                    value : value,
                    Unit : '%'
                };
            },
            PixelValue(value) {
                return {
                    value : value,
                    Unit : 'px'
                };
            },
            Add(unit, NewId) {
                const Id = NewId || Utils.Id();
                unit.id = Id;
                const units = this.Get();
                units.push(unit);
                that_project.DataManager.Set(units, 'Units')
            },
            Delete(id) {
                if (this.RelationManager.HasRelation(id)) {
                    Events.AlertSimple(Strings.Error_Delete(Strings.MESSAGE_ASSET_IS_USED), Events.GLOBAL.NOTIFICATION.TYPES.WARNING);
                    return false;
                }
                const units = this.Get();
                Utils.Remove(units, (unit) => {
                    return unit.id === id;
                })
                that_project.DataManager.Set(units, 'Units')
            },
            ToString(unit) {
                if (unit) {
                    if (unit.type === 'Custom') {
                        const customUnit = this.Get_Unit(unit.unit);
                        if (customUnit) {
                            return customUnit.name;
                        }
                    }
                    else if (unit.unit) {
                        return unit.unit;
                    }
                }
                return 'px';
            },
            Get_Unit(Id) {
                const units = this.Get();
                return Utils.Find(units, (unit) => { return unit.id === Id; }) || this.Default();
            },
            SaveName(Id, Name) {
                const units = this.Get();
                const i = Utils.FindIndex(units, (unit) => { return unit.id === Id; })
                if (i > -1) {
                    units[i].name = Name;
                    that_project.DataManager.Set(units[i], 'Units', i);
                }
            },
            SaveUnit(Id, unit) {
                const units = this.Get();
                const i = Utils.FindIndex(units, (unit) => { return unit.id === Id; })
                if (i > -1) {
                    units[i].Unit = unit;
                    that_project.DataManager.Set(units[i], 'Units', i);
                }
            },
            Set_UnitValue(Unit, Value, Prop) {
                const ThemeId = that_project.CurrentState;
                if (ThemeId && ThemeId != Strings.DEFAULT) {
                    Utils.Set(Unit, Value, Strings.THEMES, ThemeId, Prop);
                }
                else
                    Unit[Prop] = Value;
            },
            Get_ItemValue(Unit, Prop) {
                const ThemeId = that_project.CurrentState;
                let Value = Unit[Prop];
                if (ThemeId && ThemeId != Strings.DEFAULT) {
                    return Utils.Get(Unit, Value, Strings.THEMES, ThemeId, Prop);
                }
                return Value;
            },
            Update_UnitValue(Id, Value, Prop) {
                const propName = Prop || 'value';
                const ThemeId = that_project.CurrentState;
                const Units = this.Get();
                const Unit = this.Get_Unit(Id);
                if (ThemeId && ThemeId != Strings.DEFAULT) {
                    Utils.Set(Unit, Utils.Get(Unit, 0, Strings.THEMES, ThemeId, propName), Strings.THEMES, ThemeId, propName);
                }
                else
                    Unit[propName] = Unit[propName] || Value;
                    that_project.DataManager.Set(Units, 'Units');
            }
        };

        this.States = new SystemStateManager(this);
        this.Tokens = TokensManager;
        this.Mockups = MockupManager;
        this.CustomTokens = new CustomTokenManager(this);
        this.Templates = TemplatesManager;
        this.Publisher = PublishManager;
        this.RelationManager = new RelationManagerClass(this);
        this.HistoryManager = new DesignSystemHistoryManager(this);
        this.FigmaManager = new FigmaManager(this);
    }

    Initialize() {        
        this.ReversedStyleState = ['Default'];
        this.CurrentState = 'Default';
        delete this.TeamId;
        
        if (this.SessionManager) {
            this.SessionManager.Clear();
        }
        delete this.SessionManager;

        delete this.FigmaManager.Data;
        
        // this.AuditManager = new AuditManager(this);

        this.Data = {
            UserConfig : {},
            Model : {},
            Data : {}
        };
    }
    TokenChangeBroadcasted() {
        AppState.GetDataApi().project_changedData_tokens(this.Id).then((result) => {
            if (result && result.Data)  {
                Utils.Set(this.Data, result.Data, 'Data', 'tokens');
                Events.BCE(Events.GLOBAL.TOKENS_CHANGED);
                if (this.Editor === 'figma')
                    Events.BroadcastThrottle_50(Events.GLOBAL.TOKENS_CHANGED_FROMWEB);
            }
        })
    }
    MockupSchemaChangeBroadcasted() {
        AppState.GetDataApi().project_changedData_mockup(this.Id).then((result) => {
            if (result && result.Data)  {
                Utils.Set(this.Data, result.Data, 'Data', 'mockupdata');
                Events.BCE(Events.GLOBAL.MOCKUPS_CHANGED);
            }
        })
    }
    Reload() {
        if (this.Id) {
            AppState.GetDataApi().project_data(this.Id).then((result) => {
                this.Data = {
                    UserConfig : result.UserConfig || {},
                    Model : result.Model,
                    Data : result.Data || {}                    
                };   

                // this.SessionManager && this.SessionManager.Load();

                if (this.GetDocumentManager().Id) {
                    this.GetDocumentManager().Load(this.GetDocumentManager().Id).then(() => {
                        this.SessionManager && this.SessionManager.ListenDocumentChanges();
                        Events.BCE(Events.GLOBAL.STATE_CHANGED);
                    })
                }
                else {
                    Events.BCE(Events.GLOBAL.STATE_CHANGED);
                }
            });
        }
    }
    ReloadWithTeam(teamId) {
        this.TeamId = teamId;
        if (!this.SessionManager) {
            this.SessionManager = new SessionManager(this);
            this.SessionManager.Load();
        }                
    }
    Load({ProjectId, Readonly, Editor, DoNotInitialize, SharedDocument}) {
        AppState.CatchedData.ClearAll();
        return new Promise(async (resolve, reject) => {            
            this.SharedDocument = SharedDocument;
            this.Initialize();
            let grant = {granted : 'owner'};
            if (AppLayout.TeamModeEnabled && !SharedDocument) {
                grant = await AppState.GetDataApi().project_has_grant(ProjectId);
            }
            if (grant.granted || SharedDocument) {                                
                this.Grant = grant.granted;
                this.Editor = Utils.UseNullOrEmpty(Editor, 'Web');
                this.Id = ProjectId;
                this.IsReadonly = Readonly;                
                AppState.GetDataApi().project_data(ProjectId).then(async (result) => {
                    if (!result.Model) {
                        resolve(false);
                    }
                    else {
                        AppState.GetDataApi().mark_usersLastProject(ProjectId);

                        this.Data = {
                            UserConfig : result.UserConfig || {},
                            Model : result.Model,
                            Data : result.Data || {}                    
                        };       
                        Utils.ForEach(TokenTypes, (tokenType, key) => {
                            Utils.Get(Globals.ProjectManager.Data.Data, [], 'tokens', tokenType, 'order');
                        }); 

                        if (!SharedDocument) {
                            if (!this.SessionManager) {
                                this.SessionManager = new SessionManager(this);
                            }                            
                            this.TeamId = Utils.JustGet(result.Data, null, 'teamId');
                            if (this.TeamId && !SharedDocument) {
                                this.TeamName = await AppState.GetDataApi().get_team_name(this.TeamId);
                            }
                        }

                        Utils.ForEach(this.Options.Globals.Keys, (option, optionName) => {
                            option.value = this.Options.Globals.Get(option.value, 'Settings', option.name);
                        });
                                                
                        this.States.SetState('Default');
                        const statesData = this.States.Get();
                        const statesOrder = statesData.Order || [];
                        const removeStates = [];
                        statesOrder.map((stateId) => {
                            if (!statesData[stateId]) {
                                removeStates.push(stateId);
                            }
                            else {
                                if (!statesData[stateId].SingleVariation) {
                                    if (statesData[stateId].Variations) {
                                        if (!Array.isArray(statesData[stateId].Variations.Order)) {
                                            const order = [];
                                            Utils.ForEach(statesData[stateId].Variations.Order, (varId, ) => {
                                                order.push(varId);
                                            });
                                            statesData[stateId].Variations.Order = order;
                                            if (order.length > 0)
                                                this.DataManager.Set(order, Strings.STATES, stateId, 'Order');
                                        }
                                        const removeVariations = [];
                                        Utils.ForEach(statesData[stateId].Variations.Order, (varId, ) => {
                                            if (!statesData[stateId].Variations[varId])
                                                removeVariations.push(varId);
                                        });
                                        if (removeVariations.length > 0) {
                                            Utils.ForEach(removeVariations, (varId, ) => {
                                                Utils.RemoveEquals(statesData[stateId].Variations.Order, varId);
                                            });
                                            this.DataManager.Set(statesData[stateId].Variations.Order, Strings.STATES, stateId, 'Variations', 'Order');
                                        }
                                    }  
                                    else {
                                        statesData[stateId].SingleVariation = true;
                                        this.DataManager.Set(true, Strings.STATES, stateId, 'SingleVariation');
                                    }                              
                                }
                                else {
                                }
                            }
                        });
                        if (removeStates.length > 0) {
                            Utils.ForEach(removeStates, (stateId, ) => {
                                Utils.RemoveEquals(statesOrder, stateId);
                            });
                            this.DataManager.Set(statesOrder, Strings.STATES, Strings.ORDER);
                        }

                        const AllStateVariations = Utils.States.GenerateAllVariations({States : this.States.Get(), StateOrders : this.States.Get().Order, Responsive : true});
    
                        // Check Deleted components
                        const groups = this.GetComponentGroups();
                        let isComponentsRemoved = false;
                        Utils.ForEach(groups, (group, ) => {
                            const groupOrder = [];
                            Utils.ForEach(group.order, (cid, i) => {
                                if (!this.Data.Data.Components.List[cid]) {                                
                                    isComponentsRemoved = true;
                                }
                                else {
                                    groupOrder.push(cid);
                                }
                            });
                            group.order = groupOrder;
                        });
                        if (isComponentsRemoved) {
                            this.DataManager.Set(groups, 'Components', 'Groups');
                        }              
                        
                        // Check Tokens
                        if (Globals.ProjectManager.Data.Data.tokens) {
                            ['colors', 'SpacePatterns'].map((tokenType) => {
                                const color_order = [];
                                let isColorOrderChanged = false;                    
                                                        
                                Globals.ProjectManager.Data.Data.tokens[tokenType] && Globals.ProjectManager.Data.Data.tokens[tokenType].order && Globals.ProjectManager.Data.Data.tokens[tokenType].order.map((colorId) => {
                                    if (!Globals.ProjectManager.Data.Data.tokens.list[colorId]) {
                                        console.log(`Deleted Color [${colorId}]`);                        
                                        isColorOrderChanged = true;
                                    }
                                    else {
                                        color_order.push(colorId);
                                    }
                                })
                                for (let colorId in Globals.ProjectManager.Data.Data.tokens.list) {
                                    const token =   Globals.ProjectManager.Data.Data.tokens.list[colorId]; 
                                    if (token && token.type === tokenType && Globals.ProjectManager.Data.Data.tokens[tokenType] && Globals.ProjectManager.Data.Data.tokens[tokenType].order) {
                                        const index = Globals.ProjectManager.Data.Data.tokens[tokenType].order.indexOf(colorId);
                                        if (index < 0) {
                                            isColorOrderChanged = true;
                                            color_order.push(colorId);
                                            console.log(`Deleted Color Id [${colorId}]`);
                                        }
                                    }
                                }
            
                                if (isColorOrderChanged) {
                                    this.DataManager.Set(color_order, 'tokens', tokenType, 'order');
                                }
                            })
                            
                            const checkTokenTypes = ['colors', 'Shadows', 'Borders', 'BorderRadiuses'];
                            checkTokenTypes.map((tokenType) => {
                                if (Globals.ProjectManager.Data.Data.tokens[tokenType] && Globals.ProjectManager.Data.Data.tokens[tokenType].order) {
                                    Globals.ProjectManager.Data.Data.tokens[tokenType].order.map((tokenId) => {
                                        const token = Globals.ProjectManager.Data.Data.tokens.list[tokenId];
                                        if (token && !token.type) {
                                            this.DataManager.Set(tokenType, 'tokens', 'list', tokenId, 'type');
                                        }
                                    })
                                }
                            });

                            const deletedColorAliases = [];
                            Globals.ProjectManager.Data.Data.tokens.aliases && Globals.ProjectManager.Data.Data.tokens.aliases.colors && Globals.ProjectManager.Data.Data.tokens.aliases.colors.order.map((aliaseId) => {
                                const aliase = Globals.ProjectManager.Data.Data.tokens.list[aliaseId];
                                if (!aliase) {
                                    console.log(`Deleted Aliase[${aliaseId}]`);
                                    deletedColorAliases.push(aliaseId);
                                }
                                else {
                                    // if (!aliase.tokenId || !aliase.tokenId.Default) {
                                    //     console.log(`Aliase Token Id Null : [${aliaseId}] ${aliase.name}`);
                                    // }
                                    Utils.ForEach(aliase.tokenId, ({id}, state) => {                                        
                                        if (!id) {
                                            console.log(`Aliase Token Id Null : [${aliaseId}] ${aliase.name}`);
                                        }
                                        else {
                                            if (!Globals.ProjectManager.Data.Data.tokens.list[id]) {
                                                console.log(`Aliase Token Deleted [${id}]`);
                                            }
                                            else {
                                                // if (id === aliaseId) {
                                                //     this.DataManager.Delete('tokens', 'list', aliaseId, 'tokenId', state);
                                                // }                                                
                                                
                                            }
                                        }                                
                                    });
                                }
                            })

                            if (deletedColorAliases.length > 0) {
                                const colorGroups = Utils.JustGet(Globals.ProjectManager.Data.Data, [], 'tokens', 'aliases', 'groups', 'colors');        
                                colorGroups && colorGroups.map((typeGroup) => {
                                    if (typeGroup && typeGroup.order && Array.isArray(typeGroup.order)) {
                                        deletedColorAliases.map((aliaseId) => {
                                            Utils.RemoveEquals(typeGroup.order, aliaseId);
                                        })
                                        
                                    }
                                })
                                this.DataManager.Set(colorGroups, 'tokens', 'aliases', 'groups', 'colors');

                                
                                deletedColorAliases.map((aliaseId) => {
                                    Utils.RemoveEquals(Globals.ProjectManager.Data.Data.tokens.aliases.colors.order, aliaseId);
                                })
                                this.DataManager.Set(Globals.ProjectManager.Data.Data.tokens.aliases.colors.order, 'tokens', 'aliases', 'colors', 'order');                                
                            }
                        }                    
    
                        // Deleted Tokens
                        ['Fonts'].map((tokenType) => {
                            if (Globals.ProjectManager.Data.Data.tokens && Globals.ProjectManager.Data.Data.tokens[tokenType]) {
                                const order = Globals.ProjectManager.Data.Data.tokens[tokenType].order;
                                if (order) {
                                    let hasDeleted;
                                    const newOrder = [];
                                    order.map((tokenId) => {
                                        const token = Globals.ProjectManager.Data.Data.tokens.list[tokenId];
                                        if (!token) {
                                            hasDeleted = true;
                                        }
                                        else {
                                            newOrder.push(tokenId);
                                        }
                                    })
                                    if (hasDeleted) {
                                        this.DataManager.Set(newOrder, 'tokens', tokenType, 'order');                                
                                    }
                                }
                            }                            
                        })

                        // End Check tokens

    
    
                        if (!DoNotInitialize) {
                            this.States.AllVariations = AllStateVariations;
                            this.Tokens.Typescale.Get();
                            this.Tokens.TypePatterns.Get();
                            this.Tokens.SpaceScale.Get();
                            this.Tokens.SpacePatterns.Get();
                            this.Tokens.TimeScale.Get();
                            this.Tokens.TimePatterns.Get();
                            
                            // Temp : convert old image Url name to 'url'
                            const images = this.Tokens.Images();
                            if (images.length > 0) {
                                const image = this.Tokens.Image(images[0]);
                                const oldvalue = Utils.JustGet(image, null, 'value', 'Default', 'value', 'Url');
                                if (oldvalue) {
                                    Utils.ForEach(images, (imageId, i) => {
                                        const imageToken = this.Tokens.Image(imageId);
                                        imageToken.value && Utils.ForEach(imageToken.value, (statevalue, state) => {
                                            const url = Utils.JustGet(statevalue, null, 'value', 'Url');
                                            if (url) {
                                                Utils.UnSet(statevalue, 'value', 'Url');
                                                Utils.Set(statevalue, url, 'value', 'url');                                        
                                            }
                                        });
                                        this.DataManager.Set(imageToken, 'tokens', 'list', imageId);
                                    });                            
                                }
                            }
        
                            const catchedData = await this.DataManager.Catched.Get('');
                            if (catchedData && catchedData.value) {
                                if (catchedData.value.CustomFonts)
                                    AppState.CatchedData.Set(catchedData.value.CustomFonts, AppState.CatchedData.CUSTOMFONTS);
                            }
                                
                            this.LoadFonts();          
                            this.LoadTokenValues();
        
                            if (this.Editor === 'figma') {
                                AppState.GetDataApi().listen_tokens_changes_web(ProjectId, this.onTokenChangeBroadcasted);
                                AppState.GetDataApi().listen_mockup_changes_web(ProjectId, this.onMockupSchemaChangeBroadcasted);                        
    
                            }
                            else {
                                AppState.GetDataApi().listen_tokens_changes(ProjectId, this.onTokenChangeBroadcasted)
                                AppState.GetDataApi().listen_tokens_changes_figma(ProjectId, this.onTokenChangeBroadcasted)
                            }
                        }
                        
                        if (this.SessionManager)
                            this.SessionManager.Load();

                        resolve(true);
                    }                
                })
            }
            else {
                Events.AlertSimpleAsync('You don\'t have grant to see this project!', Events.GLOBAL.NOTIFICATION.TYPES.WARNING).then(() => {
                    resolve({
                        error : true
                    })
                })                
            }            
        })
    }
    GetAPIKey() {
        return this.DataManager.Get(null, 'API', 'Key');
    }
    SetAPIKey(ApiKey) {
        AppState.GetDataApi().update_api_key(this.Id, ApiKey);
        this.DataManager.Set(ApiKey, 'API', 'Key');
    }
    LogTokenChange(Info) {
        Globals.ProjectManager.HistoryManager.Log(Info, this.GetTokenChangeLogData());
    }
    SendTeamMessage_TokenChanged(id, type) {
        this.SessionManager && this.SessionManager.SendChangeMessage({
            type : TEAM_MESSAGE_TYPES.TOKEN_CHANGED,
            id : id
        });
    }
    GetTokenChangeLogData() {
        return {
            TokenChange : true,
            Data : Utils.JustGet(this.Data.Data, {}, 'tokens')
        }
    }
    RestoreTokenChange(Data) {
        const OriginalData = Utils.JustGet(this.Data.Data, {}, 'tokens');
        if (OriginalData && Data) {
            Utils.ForEach(OriginalData, (value, prop) => {
                delete OriginalData[prop];
            });
            Utils.ForEach(Data, (value, prop) => {
                OriginalData[prop] = value;                
            });
                        
            const ChangeId = Utils.Id();
            Utils.Set(this.Data.Data, ChangeId, 'ChangeId')
            Utils.Set(this.Data.Data, Utils.UseUndefined(Data, null), 'tokens')
            if (this.Offline)
                return;
            // AppState.GetDataApi().update_path_project_data(this.Id, 'ChangeId', ChangeId);
            AppState.GetDataApi().update_path_project_data(this.Id, ['tokens'], Utils.UseUndefined(Data, null));
            return;
        }
        this.DataManager.Set(Data, 'tokens');
    }
    LoadTokenValues() {        
        this.TokenValues = {
            Tokens : {
                Default : {
    
                }
            }
        }
        Publish_TokenValues(this.TokenValues, this.Data.Data, {});
        this.States.BuildStateArray();
    }
    UpdateTokenValues() {
        if (!this.TokenValues) {
            this.TokenValues = {
                Tokens : {
                    Default : {
        
                    }
                }
            }
        }
        if (!this.Data) 
            return;
        Publish_TokenValues(this.TokenValues, this.Data.Data, {});       
        
        this.CurrentTheme = this.CalculateThemeValues(this.StyleState, this.ReversedStyleState, this.States.NonMediaState);
    }

    CalculateThemeValues(StyleState, ReversedStyleState, NonMediaState) {        
        const GetStateValue = (token, StartState,  ReversedSystemStateArray) => {
            let value;
            if (!token)
                return null;
            let startIndex = ReversedSystemStateArray.indexOf(StartState);
            let notFound= true;
            while (startIndex >= 0 && notFound && startIndex < ReversedSystemStateArray.length) {
                const state = ReversedSystemStateArray[startIndex];
                value = token[state];            
                if (Utils.IsNotNull(value)) {
                    notFound = false;
                }
                startIndex++;
            }
            // }
            // Utils.ForEach(ReversedSystemStateArray, (state) => {
            //     if (token) {
            //         value = token[state];            
            //         if (Utils.IsNotNull(value))
            //             return false;
            //     }            
            // });
            return value;
        }

        const GetAliaseValue = (Themes, tokenId, StartState, ReversedSystemStateArray) => {
            let value;
            let startIndex = ReversedSystemStateArray.indexOf(StartState);
            let notFound= true;
            while (startIndex >= 0 && notFound && startIndex < ReversedSystemStateArray.length) {
                const state = ReversedSystemStateArray[startIndex];
                value = Utils.JustGet(Themes, null, state, tokenId);
                if (Utils.IsNotNull(value)) {
                    notFound = false;
                }
                startIndex--;
            }
            return value;;
        }

        const CurrentTheme = {
            SystemStateArray : StyleState,
            ReversedSystemStateArray : ReversedStyleState,            
            Theme : {},
            BodyStyle : this.Tokens.GetBodyFontStyle()
        };

        DefaultMediaSizes.map((MediaSizeState) => {        
            let UseState = MediaSizeState;
            if (Utils.IsNotNullOrEmpty(NonMediaState))
                UseState = UseState + ',' + NonMediaState;
            
            const StateInfo = Utils.States.GenerateStateArray({GlobalStateLabel : UseState});
                        
            const ReversedStateArray = Utils.Reverse([...StateInfo.GlobalStateArray]);

            CurrentTheme.Theme[UseState] = {};

            let baseSpaceSize, baseTypeSize, baseTimeSize, baseSpaceRatio, baseTypeRatio, baseTimeRatio;

            Utils.ForEach(StateInfo.GlobalStateArray, (ParentState, i) => {
                const ParentStateTokens = Utils.DeepClone(Utils.JustGet(this.TokenValues, {}, 'Tokens', ParentState));
                CurrentTheme.Theme[UseState] = Utils.Merge(CurrentTheme.Theme[UseState], ParentStateTokens);

                // const ParentStateAliases = Utils.DeepClone(Utils.JustGet(this.TokenValues, {}, 'Aliases', ParentState));
                // Utils.ForEach(ParentStateAliases, (tokenId, aliaseId) => {
                //     const aliaseTokenValue = GetAliaseValue( CurrentTheme.Theme, tokenId, ParentState, ReversedStateArray);
                //     if (Utils.IsNotNull(aliaseTokenValue))
                //         Utils.Set(CurrentTheme.Theme[UseState], aliaseTokenValue, aliaseId);
                // });

                const lineHeightFactor = GetStateValue(Utils.JustGet(this.TokenValues, 1, 'Patterns', 'Text', 'lineHeightFactor'), ParentState, ReversedStateArray);
                const letterSpaceFactor = GetStateValue(Utils.JustGet(this.TokenValues, 1, 'Patterns', 'Text', 'letterSpaceFactor'), ParentState, ReversedStateArray);
                const wordSpacingFactor = GetStateValue(Utils.JustGet(this.TokenValues, 1, 'Patterns', 'Text', 'wordSpacingFactor'), ParentState, ReversedStateArray);
                

                baseSpaceSize = Utils.JustGet(this.TokenValues, null, 'Patterns', 'Space', 'baseSize', ParentState) || baseSpaceSize;
                baseTypeSize = Utils.JustGet(this.TokenValues, null, 'Patterns', 'Text', 'baseSize', ParentState) || baseTypeSize;
                baseTimeSize = Utils.JustGet(this.TokenValues, null, 'Patterns', 'Motion', 'baseSize', ParentState) || baseTimeSize;

                baseSpaceRatio = Utils.JustGet(this.TokenValues, null, 'Patterns', 'Space', 'ratio', ParentState) || baseSpaceRatio;
                baseTypeRatio = Utils.JustGet(this.TokenValues, null, 'Patterns', 'Text', 'ratio', ParentState) || baseTypeRatio;
                baseTimeRatio = Utils.JustGet(this.TokenValues, null, 'Patterns', 'Motion', 'ratio', ParentState) || baseTimeRatio;

                const Patterns = Utils.JustGet(this.TokenValues, null, 'Patterns');
                if (Patterns) {
                    if (Patterns.Text) {                
                        Utils.ForEach(Patterns.Text.Patterns, (pattern, id) => {
                            let style = {};
                            if (pattern.fontid && Utils.IsObject(pattern.fontid)) {
                                const fontid = pattern.fontid[UseState] || GetStateValue(pattern.fontid, ParentState, ReversedStateArray);
                                if (this.PreviewFont && this.PreviewFont.id === fontid) {
                                    style = GetTypefaceStyle(this.PreviewFont.font);
                                }
                                else {
                                    if (fontid === 'DefaultFont') {
                                        style = {
                                            ...CurrentTheme.Theme[UseState].DefaultFont
                                        };
                                    }
                                    else if (fontid === 'SecondaryFont') {
                                        const SecondaryFont = this.Tokens.SecondaryFont();                            
                                        style = {
                                            fontFamily : SecondaryFont.family,
                                            fontWeight : SecondaryFont.weight
                                        };
                                    }
                                    else if (fontid)
                                        style = {...CurrentTheme.Theme[UseState][fontid]} || {};
                                }                        
                            }
                            if (Utils.IsNotNull(pattern.scaleIndex)) {
                                const scaleindex = pattern.scaleIndex[UseState] || GetStateValue(pattern.scaleIndex, ParentState, ReversedStateArray);
                                if (scaleindex !== 'Custom') {
                                    const scaleFactor = Utils.JustGet(GetStateValue(pattern.scaleFactor, ParentState, ReversedStateArray), null, 'value');
                                    const scaleDiff = Utils.JustGet(GetStateValue(pattern.scaleDiff, ParentState, ReversedStateArray), 0, 'value');

                                    style.fontSize = Utils.px(Globals.ProjectManager.Tokens.GetScaleValue({
                                        scaleIndex : scaleindex,
                                        factor : scaleFactor,
                                        diff : scaleDiff,
                                        baseSize : baseTypeSize,
                                        ratio : baseTypeRatio
                                    }));
                                }                                
                            }
                            if (!style.fontSize && pattern.fontSize) {
                                const fontSize = GetStateValue(pattern.fontSize, ParentState, ReversedStateArray);
                                if (fontSize) {
                                    style.fontSize = fontSize;
                                }
                            }

                            ['lineHeight', 'letterSpacing', 'wordSpacing'].map((prop) => {
                                let handled = false;
                                if (pattern[prop]) {
                                    const propvalue = GetStateValue(pattern[prop], ParentState, ReversedStateArray);
                                    if (propvalue) {
                                        style[prop] = propvalue;
                                        handled = true;
                                    }                                    
                                }    
                                if (!handled) {
                                    if (prop === 'lineHeight') {
                                        const fontSize = Utils.parseSize(style.fontSize);
                                        style[prop] = Utils.px(fontSize.value * lineHeightFactor, fontSize.unit);
                                    }
                                    else if (prop === 'letterSpacing' && Utils.IsNotNullOrEmpty(letterSpaceFactor)) {
                                        style[prop] = Utils.px(Math.max(-1, Math.min(2, letterSpaceFactor)), 'em');
                                    }
                                    else if (prop === 'wordSpacing' && Utils.IsNotNullOrEmpty(wordSpacingFactor)) {
                                        style[prop] = Utils.px(Math.max(0, Math.min(2, wordSpacingFactor)), 'em');
                                    }
                                }
                            })            

                            Utils.Set(CurrentTheme.Theme, style, UseState, id)
                        });
                    }

                    if (Patterns.Space) {                
                        Utils.ForEach(Patterns.Space.Patterns, (pattern, id) => {
                            let style = {};                    
                            if (Utils.IsNotNull(pattern.scaleIndex)) {
                                const scaleindex = GetStateValue(pattern.scaleIndex, ParentState, ReversedStateArray);
                                if (scaleindex !== 'Custom') {
                                    const scaleFactor = Utils.JustGet(GetStateValue(pattern.scaleFactor, ParentState, ReversedStateArray), null, 'value');
                                    const scaleDiff = Utils.JustGet(GetStateValue(pattern.scaleDiff, ParentState, ReversedStateArray), 0, 'value');

                                    style.value = Utils.px(Globals.ProjectManager.Tokens.GetScaleValue({
                                        scaleIndex : scaleindex,
                                        factor : scaleFactor,
                                        diff : scaleDiff,
                                        baseSize : baseSpaceSize,
                                        ratio : baseSpaceRatio
                                    }));
                                }                                
                            }
                            if (!style.value && pattern.value) {
                                const value = GetStateValue(pattern.value, ParentState, ReversedStateArray);
                                if (Utils.IsNotNullOrEmpty(value)) {
                                    style.value = value;
                                }
                            } 

                            Utils.Set(CurrentTheme.Theme, style.value, UseState, id);
                            const aliases = this.Tokens.GetAliaseIdsOfTokenId(id);
                            Utils.ForEach(aliases, (aliaseId, ) => {
                                if (this.Tokens.AliaseTokenId(aliaseId) === id) {
                                    Utils.Set(CurrentTheme.Theme, style.value, UseState, aliaseId);
                                }                                
                            });
                        });
                    }
                    if (Patterns.Motion && Patterns.Motion.Durations) {                
                        Utils.ForEach(Patterns.Motion.Durations, (pattern, id) => {
                            let style = {};                    
                            if (Utils.IsNotNull(pattern.scaleIndex)) {
                                const scaleindex = GetStateValue(pattern.scaleIndex, ParentState, ReversedStateArray);
                                if (scaleindex !== 'Custom') {
                                    const scaleFactor = Utils.JustGet(GetStateValue(pattern.scaleFactor, ParentState, ReversedStateArray), null, 'value');
                                    const scaleDiff = Utils.JustGet(GetStateValue(pattern.scaleDiff, ParentState, ReversedStateArray), 0, 'value');

                                    style.value = Globals.ProjectManager.Tokens.GetScaleValue({
                                        scaleIndex : scaleindex,
                                        factor : scaleFactor,
                                        diff : scaleDiff,
                                        baseSize : baseTimeSize,
                                        ratio : baseTimeRatio
                                    });
                                }
                            }
                            if (!style.value && pattern.value) {
                                const value = GetStateValue(pattern.value, ParentState, ReversedStateArray);
                                if (Utils.IsNotNullOrEmpty(value)) {
                                    style.value = Number(value);
                                }
                            } 

                            Utils.Set(CurrentTheme.Theme, style.value, UseState, id)
                        });
                    }
                } 
            });

            Utils.ForEach(StateInfo.GlobalStateArray, (ParentState, i) => {
                const ParentStateAliases = Utils.DeepClone(Utils.JustGet(this.TokenValues, {}, 'Aliases', ParentState));
                Utils.ForEach(ParentStateAliases, (tokenId, aliaseId) => {
                    if (Utils.IsNotNull(CurrentTheme.Theme[UseState][tokenId]))
                        CurrentTheme.Theme[UseState][aliaseId] = CurrentTheme.Theme[UseState][tokenId];
                });

            });
        });

        CurrentTheme.ThemeId = Utils.Id();
        return CurrentTheme;
    }
    
    GetThemeValue(TokenId, DefaultValue) {
        return Utils.JustGet(this.CurrentTheme.Theme, DefaultValue, TokenId);
    }
    GetThemeFontStyle(PatternId) {
        return Utils.JustGet(this.CurrentTheme.Theme, {}, PatternId);
    }
    SetThemePreviewTypeface(fontId, font, preview) {
        if (preview) {
            this.PreviewFont = {
                id : fontId,
                font : font,
                PreviewId : Utils.Id()
            }
        }
        else {
            delete this.PreviewFont;          
        }
        
    }
    GetName() {
        return Utils.JustGet(this.Data, 'Project Name', 'Model', 'Name');
    }
    SaveName(name) {
        const oldName = Utils.JustGet(this.Data, '', 'Model', 'Name');

        if (oldName !== name) {
            Utils.Set(this.Data, name, 'Model', 'Name');
            AppState.Data.Board.ChangeProp(this.Id, 'Name', name);

            this.AuditManager && this.AuditManager.InsertLog({                
                UserId : GetUserId(),
                Type : 'System',
                Data : {
                    prop : 'name',
                    old : oldName,
                    new : name
                }
            })
        }
    }
    GetDescription() {
        return Utils.JustGet(this.Data, 'Project Name', 'Model', 'Description');
    }
    SaveDescription(name) {
        const oldName = Utils.JustGet(this.Data, '', 'Model', 'Description');

        if (oldName !== name) {
            Utils.Set(this.Data, name, 'Model', 'Description');
            AppState.Data.Board.ChangeProp(this.Id, 'Description', name);
    
            this.AuditManager && this.AuditManager.InsertLog({                
                UserId : GetUserId(),
                Type : 'System',
                Data : {
                    prop : 'description',
                    old : oldName,
                    new : name
                }
            })
        }        
    }
    SetLogo(logo) {
        const storageId = Utils.JustGet(this.Data, null, 'Model', 'Logo', 'storageId');
        if (storageId && logo && logo.storageId !== storageId) {
            Globals.ProjectManager.DataManager.Storage.Delete(storageId);
        }        
        Utils.Set(this.Data, logo, 'Model', 'Logo');
        AppState.Data.Board.ChangeProp(this.Id, 'Logo', logo);
    }
    GetLogo() {
        return Utils.JustGet(this.Data, '', 'Model', 'Logo', 'url');
    }
    GetLogoData() {
        return Utils.JustGet(this.Data, {}, 'Model', 'Logo');
    }
    GetDeviceType() {
        return Utils.JustGet(this.States, 'Default', Strings.STATEVARIATION, 'System_MediaSize');
    }
    GetResponsiveValueOf(Owner, PropName, DeviceType, DefaultValue) {
        let value;
        let index = DefaultMediaSizesOrdered.indexOf(DeviceType);
        for (let i=index; i < DefaultMediaSizesOrdered.length; i++) {
            const MediaSize = DefaultMediaSizesOrdered[i];
            value = Utils.JustGet(Owner, null, PropName, MediaSize);
            if (Utils.IsNotNullOrEmpty(value))
                break;
        }
        return Utils.UseNullOrEmpty(value, DefaultValue);
    }
    SetResponsiveValueOf(Owner, PropName, DeviceType, Value) {
        const DefaultSizeValue = Utils.JustGet(Owner, null, PropName, 'Default');        
        if (!DefaultSizeValue)
            Utils.Set(Owner, Value, PropName, 'Default');
        else
            Utils.Set(Owner, Value, PropName, DeviceType);
    }
    LoadFont(font, TargetDocument) {
        if (font && font.value) {
            Utils.ForEach(font.value, (metaFont, state) => {
                if (metaFont && metaFont.value) {
                    if (metaFont.value && metaFont.value.provider === Strings.FONT_GOOGLE) {
                        FontLoader.Load(metaFont.value.family, metaFont.value.variant, metaFont.value.url, TargetDocument);                            
                    }
                    else if (metaFont.value && metaFont.value.provider === Strings.CUSTOM) {
                        Globals.ProjectManager.Tokens.LoadCustomFont(metaFont.value.fontId, TargetDocument);                     
                    }
                }
            });
        }
    }
    LoadFonts(TargetDocument) {                
        const fonts = Globals.ProjectManager.Tokens.TokenList(Globals.ProjectManager.Tokens.Types.Fonts);
        if (fonts) {          
            const DefaultFont = Globals.ProjectManager.Tokens.Font('DefaultFont');
            if (DefaultFont)
                this.LoadFont(DefaultFont, TargetDocument);
            Utils.ForEach(fonts, (font, id) => {
                if (id !== 'DefaultFont')
                    this.LoadFont(font, TargetDocument);
            });
        }                
    }

    SavePubished(Value, ...Path) {
        // AppState.GetDataApi().update_path_board_publish(Path, Utils.UseUndefined(Value, null));
    }
    DeltePublished(...Path) {
        // AppState.GetDataApi().delete_path_board_publish(Path);
    }

    GetNewName({models, seedName = 'New Name'}) {
        const names = [];
        let name = seedName;
        if (models) {
            Utils.ForEach(models, (item, id) => {
                names.push(item.name);
            });
        }
        let found = true;
        Utils.ForEach(names, (existingname, i) => {
            if (names.indexOf(name) < 0)
                return false;
            name = `${seedName} ${i+1}`;
            if (names.indexOf(name) < 0)
                return false;
            else
                found = false;
        });
        if (!found)
            name = `${seedName} ${names.length + 1}`

        return name;
    }

    //#region Components
    GetComponentModels() {
        const result = {};
        const ModelIds = Utils.JustGet(this.Data.Data, [], 'Components', 'Order');
        Utils.ForEach(ModelIds, (id, i) => {
            result[id] = {
                name : Utils.JustGet(this.Data.Data, '', 'Components', 'List', id, 'name')
            }
        });
        return result;
    }
    GetComponentGroups() {
        const groups = Utils.JustGet(this.Data.Data, [], 'Components', 'Groups');        
        if (groups.length === 0) {
            groups.push({
                id : 'Default',
                name : 'Default',
                order : []
            });
            this.DataManager.Set(groups, 'Components', 'Groups');
        }
        return groups;
    }
    AddComponentGroup({type, name}) {
        const id = Utils.Id();
        const groups = this.GetComponentGroups();        
        groups.push({
            id : id,
            name : name
        });
        this.DataManager.Set(groups, 'Components', 'Groups');
        return id;
    }
    AddComponentToGroup({groupid, componentId}) {
        this.AddComponentsToGroup({groupid : groupid, components : [componentId]});
    }
    AddComponentsToGroup({groupid, components}) {
        const groups = this.GetComponentGroups();
        const index = Utils.FindIndex(groups, (item) => {return item.id === groupid});
        if (index > -1) {
            const order = Utils.Get(groups[index], [], 'order');
            order.push(...components);
            this.DataManager.Set(order, 'Components', 'Groups', index, 'order');
        }
    }
    ChangeGroupProp({id, name, value}) {
        const groups = this.GetComponentGroups();
        const index = Utils.FindIndex(groups, (item) => {return item.id === id});
        if (index > -1) {
            groups[index][name] = value;
            this.DataManager.Set(value, 'Components', 'Groups', index, name);
        }
    }
    DeleteGroup({id}) {
        const groups = this.GetComponentGroups();
        const index = Utils.FindIndex(groups, (item) => {return item.id === id});
        if (index > -1) {
            groups.splice(index, 1);
            this.DataManager.Set(groups, 'Components', 'Groups');
        }
    }
    ChangeOrderOfGroups(oldIndex, newIndex) {
        const groups = this.GetComponentGroups();
        Utils.ChangePlace(groups, oldIndex, newIndex);
        this.DataManager.Set(groups, 'Components', 'Groups');
    }
    ChangeFolderComponentOrder(oldFolderId, newFolderId, oldIndex, newIndex) {
        const folders = this.GetComponentGroups();
        if (oldFolderId === newFolderId) {
            const folder = Utils.Find(folders, (item) => {return item.id === oldFolderId});
            if (folder) {
                const pages = Utils.Get(folder, [], 'order');
                Utils.ChangePlace(pages, oldIndex, newIndex);                
            }
        }
        else {
            const sourceFolder = Utils.Find(folders, (item) => {return item.id === oldFolderId});
            const sourcepages = Utils.Get(sourceFolder, [], 'order');
            const sourcePage = sourcepages[oldIndex];
            sourcepages.splice(oldIndex, 1);

            const targetFolder = Utils.Find(folders, (item) => {return item.id === newFolderId});
            const targetPages = Utils.Get(targetFolder, [], 'order');
            targetPages.splice(newIndex, 0, sourcePage);
            
        }
        this.DataManager.Set(folders, 'Components', 'Groups');
    }  
    GetComponentData(Id) {
        return Utils.JustGet(this.Data.Data, null, 'Components', 'List', Id);
    }
    GetComponentStateDefinition(Id) {
        return Utils.JustGet(this.Data.Data, null, 'Components', 'List', Id, Strings.STATES);
    }
    GetNewName_Component(seedName = 'New Component') {
        return new Promise((resolve) => {
            resolve(this.GetNewName({
                models : this.GetComponentModels(),
                seedName : seedName 
            }))
        })
    }
    GetComponentGenericType(Id) {
        return Utils.JustGet(this.Data.Data, null, 'Components', 'List', Id, 'GenericType');
    }
    GetComponentMetaItem(Id, ItemId) {
        return Utils.JustGet(this.Data.Data, null, 'Components', 'List', Id, 'MetaItems', ItemId);
    }
    AddComponent({name, data, RootItem, PreviewSize}) {
        const Id = AppState.GetDataApi().comp_newid();
        const usename = name ||  'New Component';
        const componentModel = data || {           
            Default : {
                Designboard : {
                    type : 'darkgrid'
                }
            },            
        };
        if (PreviewSize) {
            componentModel.PreviewSize = PreviewSize;
        }
        else if (!componentModel.PreviewSize) {
            componentModel.PreviewSize = {
                width : 300,
                height : 300
            }
        }
        if (RootItem) {
            if (!componentModel.RootItem) {
                const RootId = Utils.Id();
                componentModel.RootItem = {Id : RootId};
                Utils.Set(componentModel, RootItem, 'MetaItems', RootId);
            }
        }
        componentModel.name = usename;

        const order = Utils.JustGet(this.Data.Data, [], 'Components', 'Order');
        order.push(Id);
        this.DataManager.Set(order, 'Components', 'Order');
        this.DataManager.Set(componentModel, 'Components', 'List', Id);

        setTimeout(() => {
            UpdateComponentLists();
        }, 0);
        
        return Id;
    }    
    CloneComponent(Id) {
        const ClonedData = Utils.DeepClone(this.GetComponentData(Id));        
        if (ClonedData) {  
            const NewId = this.AddComponent({name : ClonedData.name + ' Clone', data : ClonedData});
            this.RelationManager.CloneOwner(Id, NewId);

            setTimeout(() => {
                UpdateComponentLists();
            }, 0);

            return NewId;
        }
    }
    DeleteComponent(Id) {
        return new Promise((resolve, reject) => {
            if (this.RelationManager.HasRelation(Id)) {
                Events.AlertSimple(Strings.Error_Delete(Strings.MESSAGE_MODEL_IS_USED), Events.GLOBAL.NOTIFICATION.TYPES.WARNING);
                reject();
                return;
            }
            Globals.ProjectManager.HistoryManager.Log({
                Desc : 'Delete Component'
            }, {
                Full : true,
                Data : this.Data.Data
            });

            const order = Utils.JustGet(this.Data.Data, [], 'Components', 'Order');
            Utils.RemoveEquals(order, Id);
            this.DataManager.Set(order, 'Components', 'Order');
            this.DataManager.Delete('Components', 'List', Id);
            
            const groups = this.GetComponentGroups();
            Utils.ForEach(groups, (group, ) => {
                Utils.RemoveEquals(group.order, Id);                
            });
            this.DataManager.Set(groups, 'Components', 'Groups');

            this.RelationManager.DeleteOwner(Id);

            // Delete from Artboards
            let ResponsiveArtboards = Utils.JustGet(this.Data.Data, null, 'ResponsiveArtboards', 'List');
            if (ResponsiveArtboards) {
                Utils.ForEach(ResponsiveArtboards, (Artboard, ArtboardId) => {
                    const instances = Utils.JustGet(Artboard, {}, 'Designer', 'Instances');    
                    if (instances) {                        
                        Utils.ForEach(instances, (DeviceInstances, DeviceId) => {
                            const removeInstanceIds = [];
                            Utils.ForEach(DeviceInstances, (instance, instanceId) => {
                                if (instanceId !== 'Order' && instance.Id === Id) {
                                    removeInstanceIds.push(instanceId);
                                }
                            });
                            if (removeInstanceIds.length > 0) {
                                Utils.ForEach(removeInstanceIds, (instanceId, ) => {
                                    delete DeviceInstances[instanceId];
                                    this.DataManager.Delete('ResponsiveArtboards', 'List', ArtboardId, 'Designer', 'Instances', DeviceId, instanceId);
                                    Utils.RemoveEquals(DeviceInstances.Order, instanceId);
                                });
                                this.DataManager.Set(DeviceInstances.Order, 'ResponsiveArtboards', 'List', ArtboardId, 'Designer', 'Instances', DeviceId, 'Order');
                            }
                        });
                        
                    }
                });
            }

            setTimeout(() => {
                UpdateComponentLists();
            }, 0);

            resolve(true);
        });
    }
    GetComponentRootId(Id) {
        return Utils.JustGet(this.Data.Data, '', 'Components', 'List', Id, 'RootItem', 'Id');
    }
    GetComponentName(Id) {
        return Utils.UseNull(Utils.JustGet(this.Data.Data, '', 'Components', 'List', Id, 'name'), '');
    }
    GetComponentOption(id, defaultValue, ...path) {
        return this.DataManager.Get(defaultValue, 'Components', 'List', id, 'Options', ...path);
    }
    SetComponentOption(id, value, ...path) {
        this.DataManager.Set(value, 'Components', 'List', id, 'Options', ...path);
    }
    GetComponentProp(id, prop, defaultValue) {
        return this.DataManager.Get(defaultValue, 'Components', 'List', id, prop);
    }
    ChangeComponentProp(id, prop, value) {
        this.DataManager.Set(value, 'Components', 'List', id, prop);
    }
    SetComponentPreviewSize(id, size) {
        this.DataManager.Set(size, 'Components', 'List', id, 'PreviewSize');
        AppState.CatchedData.Clear(AppState.CatchedData.COMPONENT_PUBLISHED, id);
    }
    GetComponentPreviewSize(id) {
        return this.DataManager.Get({}, 'Components', 'List', id, 'PreviewSize');
    }
    ValidateComponentName(id, name) {
        const models = Utils.JustGet(this.Data.Data, [], 'Components', 'List');        
        let result = {
            
        };
        if (!Utils.CheckUnique(name, models, 'name', models[id])) {
            result.error = true;
            result.message = `Name must be unique`;
        }
        return result;
    }
    SetComponentPublishedId(Id, ChangeId) {
        this.DataManager.Set(ChangeId, 'Components', 'List', Id, 'PublishedId');
    }
    SetComponentChangeId(Id, ChangeId) {
        this.DataManager.Set(ChangeId, 'Components', 'List', Id, 'ChangeId');
    }    
    ReplaceComponentData(Id, Data) {        
        if (Data) {
            const OriginalData = this.GetComponentData(Id);
            Data.ChangeId = Utils.Id();
            if (OriginalData) {
                Utils.ForEach(OriginalData, (value, prop) => {
                    delete OriginalData[prop];
                });
                Utils.ForEach(Data, (value, prop) => {
                    OriginalData[prop] = value;                
                });
                const ChangeId = Utils.Id();
                Utils.Set(this.Data, ChangeId, 'Data', 'ChangeId');
                AppState.GetDataApi().update_path_project_data(this.Id, ['ChangeId'], ChangeId);
                AppState.GetDataApi().update_path_project_data(this.Id, ['Components','List',Id], Utils.UseUndefined(Data, null));
                return;
            }
            this.DataManager.Set(Data, 'Components', 'List', Id);
        }            
    }
    BatchAddComponent(ComponentList) {
        const order = Utils.JustGet(this.Data.Data, [], 'Components', 'Order');
        Utils.ForEach(ComponentList, (component, i) => {
            const Id = AppState.GetDataApi().comp_newid();
            order.push(Id);      
            Utils.Set(component.data, component.name, 'name');      
            this.DataManager.Set(component.data, 'Components', 'List', Id);
        });
        this.DataManager.Set(order, 'Components', 'Order');        
    }
    GetComponentDesignerOptions(Id, DesignerType) {
        const ComponentData = this.GetComponentData(Id);
        const DesignerOptions = Utils.JustGet(ComponentData, {}, 'Designer', DesignerType || 'Default');

        return DesignerOptions;
    }   
    GetComponentDesignerOption(Id, DesignerType, DefaultValue, ...Path) {
        const ComponentData = this.GetComponentData(Id);
        return Utils.JustGet(ComponentData, DefaultValue, 'Designer', DesignerType || 'Default', ...Path);
    }    
    SetComponentDesignerOption(Id, DesignerType, Value, ...Path) {
        this.DataManager.Set(Value, 'Components', 'List', Id, 'Designer', DesignerType || 'Default', ...Path);
    }    
    DeleteComponentDesignerOption(Id, DesignerType, ...Path) {
        this.DataManager.Delete('Components', 'List', Id, 'Designer', DesignerType || 'Default', ...Path);
    }

    GetComponentDevices(Id) {
        return Utils.JustGet(this.Data.Data, [{width : 1024, Id : 'Default'}], 'Components', 'List', Id, 'Devices', 'List');
    }
    GetComponentDeviceResponsiveInfo(Id, offset) {
        return Utils.JustGet(this.Data.Data, {offset : offset || 400}, 'Components', 'List', Id, 'Devices', 'Canvas', 'Responsive'); 
    }
    SetComponentDeviceResponsiveInfo(Id, offset) {
        this.DataManager.Set(offset, 'Components', 'List', Id, 'Devices', 'Canvas', 'Responsive', 'offset');    
    }
    GetComponentDeviceOption(Id, option, defaultValue) {
        return Utils.JustGet(this.Data.Data, defaultValue, 'Components', 'List', Id, 'Devices', 'Canvas', 'Option', option); 
    }
    SetComponentDeviceOption(Id, option, value) {
        this.DataManager.Set(value, 'Components', 'List', Id, 'Devices', 'Canvas', 'Option', option);    
    }
    SaveComponentDeviceInfo(Id, Info) {
        this.DataManager.Set(Info, 'Components', 'List', Id, 'Devices', 'Canvas', 'Transform');    
    }
    AddComponentDeviceInstance(Id, Variations) {
        const ComponentData = this.GetComponentData(Id);
        const Devices = Utils.JustGet(ComponentData, {}, 'Devices');
        const instances = Utils.Get(Devices, [], 'Instances');
        const instanceId = Utils.Id();
        instances.push(instanceId);
        this.DataManager.Set(instances, 'Components', 'List', Id, 'Devices', 'Instances');
        const instance = {
            Variations : Variations
        }
        this.DataManager.Set(instance, 'Components', 'List', Id, 'Devices', instanceId);            
        return {
            Id : instanceId,
            data : instance
        }
    }
    SaveComponentDeviceInstance(Id, InstanceId, Instance) {
        this.DataManager.Set(Instance, 'Components', 'List', Id, 'Devices', InstanceId);    
    }
    SetComponentDeviceInstanceState(Id, InstanceId, GlobalState, ComponentState) {
        this.DataManager.Set(ComponentState, 'Components', 'List', Id, 'Devices', InstanceId, 'ComponentState');
        this.DataManager.Set(GlobalState, 'Components', 'List', Id, 'Devices', InstanceId, 'GlobalState');
    }
    SetComponentDeviceInstanceOption(Id, InstanceId, Option, DeviceType, Value) {
        this.DataManager.Set(Value, 'Components', 'List', Id, 'Devices', InstanceId, Option, DeviceType);        
    }
    
    DeleteComponentDeviceInstance(Id, InstanceId) {
        const ComponentData = this.GetComponentData(Id);
        const Devices = Utils.JustGet(ComponentData, {}, 'Devices');
        const instances = Utils.Get(Devices, [], 'Instances');
        Utils.RemoveEquals(instances, InstanceId);
        this.DataManager.Set(instances, 'Components', 'List', Id, 'Devices', 'Instances');

        this.DataManager.Delete('Components', 'List', Id, 'Devices', InstanceId);
    }
    SetComponentDeviceProp(Id, index, prop, value) {
        const Devices = this.GetComponentDevices(Id);
        if (Devices && Devices.length > 0 && index < Devices.length) {
            Devices[index][prop] = value;
            this.DataManager.Set(Devices, 'Components', 'List', Id, 'Devices', 'List');  
        }
    }    
    UpdateComponentDeviceSize(Id, index, width) {
        const Devices = this.GetComponentDevices(Id);
        if (Devices && Devices.length > 0 && index < Devices.length) {
            Devices[index].width = width;
            this.DataManager.Set(Devices, 'Components', 'List', Id, 'Devices', 'List');  
        }
    }
    AddComponentDevice(Id, InsertAfter, Device) {
        let index = Math.max(0, InsertAfter + 1);
        const Devices = this.GetComponentDevices(Id);
        SetNewDeviceWidth({Devices : Devices, index : index-1, DeviceModel : Device});
        Devices.splice(index, 0, Device);
        this.DataManager.Set(Devices, 'Components', 'List', Id, 'Devices', 'List');  
    }
    
    DeleteComponentDevice(Id, index) {
        const Devices = this.GetComponentDevices(Id);
        if (Devices && Devices.length > 0 && index < Devices.length) {
            Devices.splice(index, 1);
            this.DataManager.Set(Devices, 'Components', 'List', Id, 'Devices', 'List');  
        }
    }
    // --------------    

    //#endregion

    //#region Artboard    
    AddArtboard({name, data, isDefault}) {
        const UseName = name || 'New Artboard';
        const Id = isDefault ? 'Default' : AppState.GetDataApi().artboard_newid();
        const ArtboardModel = data || {
            name : UseName               
        };
        if (!data) {
        }

        const order = Utils.JustGet(this.Data.Data, [], 'Artboards', 'Order');
        order.push(Id);
        this.DataManager.Set(order, 'Artboards', 'Order');
        this.DataManager.Set(ArtboardModel, 'Artboards', 'List', Id);
        return Id;
    }
    GetArtboardData(Id) {
        let ArtboardData = Utils.JustGet(this.Data.Data, null, 'Artboards', 'List', Id);
        if (!ArtboardData) {
            if (Id === 'Default') {
                this.AddArtboard({name : 'Default', isDefault : true});
                return this.GetArtboardData(Id);
            }
        }
        return ArtboardData || {};
    }  
    GetArtboardItems(Id) {
        return Utils.Get(this.Data.Data, {}, 'Artboards', 'List', Id, 'Items');
    }
    // Responsive Artboard
    GetResponsiveArtboardList() {
        const order = Utils.JustGet(this.Data.Data, [], 'ResponsiveArtboards', 'Order');
        const artboards = [];
        Utils.ForEach(order, (artboardId, i) => {
            const model = this.DataManager.Get(null, 'ResponsiveArtboards', 'List', artboardId);
            if (model) {
                artboards.push({
                    id : artboardId,
                    model : model
                })
            }
        });
        return artboards;
    }
    AddResponsiveArtboard({name, data, isDefault}) {
        const UseName = name || 'New Artboard';
        const Id = isDefault ? 'Default' : AppState.GetDataApi().responsiveartboard_newid();
        const ArtboardModel = data || {
            name : UseName               
        };
        if (!data) {
        }

        const order = Utils.JustGet(this.Data.Data, [], 'ResponsiveArtboards', 'Order');
        order.push(Id);
        this.DataManager.Set(order, 'ResponsiveArtboards', 'Order');
        this.DataManager.Set(ArtboardModel, 'ResponsiveArtboards', 'List', Id);
        return Id;
    }
    GetResponsiveArtboardData(Id) {
        let ArtboardData = Utils.JustGet(this.Data.Data, null, 'ResponsiveArtboards', 'List', Id);
        if (!ArtboardData) {
            if (Id === 'Default') {
                this.AddResponsiveArtboard({name : 'Default', isDefault : true});
                return this.GetResponsiveArtboardData(Id);
            }
        }
        return ArtboardData || {};
    }  
    GetResponsiveArtboardDesignerOptions(Id) {
        const ResponsiveArtboardData = this.GetResponsiveArtboardData(Id);
        const DesignerOptions = Utils.JustGet(ResponsiveArtboardData, {}, 'Designer');

        return DesignerOptions;
    }   
    GetResponsiveArtboardDesignerOption(Id, DefaultValue, ...Path) {
        const ResponsiveArtboardData = this.GetResponsiveArtboardData(Id);
        return Utils.JustGet(ResponsiveArtboardData, DefaultValue, 'Designer', ...Path);
    }    
    SetResponsiveArtboardDesignerOption(Id, Value, ...Path) {
        this.DataManager.Set(Value, 'ResponsiveArtboards', 'List', Id, 'Designer', ...Path);
    }    
    DeleteResponsiveArtboardDesignerOption(Id, ...Path) {
        this.DataManager.Delete('ResponsiveArtboards', 'List', Id, 'Designer', ...Path);
    }
    //#endregion Artboard

    //#region Prototypes
    GetPrototypeModels() {
        const result = {};
        const type = this.IsDocument ? 'Documents' : 'Prototypes';
        const ModelIds = Utils.JustGet(this.Data.Data, [], type, 'Order');
        Utils.ForEach(ModelIds, (id, i) => {
            result[id] = {
                name : Utils.JustGet(this.Data.Data, '', type, 'List', id, 'name')
            }
        });
        return result;
    }
    GetFirstPrototypeId() {
        const type = this.IsDocument ? 'Documents' : 'Prototypes';
        const ModelIds = Utils.JustGet(this.Data.Data, [], type, 'Order');
        if (ModelIds.length > 0) {
            return ModelIds[0];
        }
        return this.AddPrototype({name : 'Default Prototype'});
    }
    AddPrototype({name, data}) {
        const type = this.IsDocument ? 'Documents' : 'Prototypes';

        const UseName = name || 'New Prototype';
        const Id = AppState.GetDataApi().prototype_newid();
        const PrototypeModel = data || {
            name : UseName,
            Device : {
                Size : {
                    Id : 'Medium',
                    width : 1024
                }
            },
            Views :
            {

            }
        };
        if (!data) {
            PrototypeModel.MainViewId = Utils.Id();
            PrototypeModel.Views[PrototypeModel.MainViewId] = this.NewView('Home Page');
        }

        const order = Utils.JustGet(this.Data.Data, [], type, 'Order');
        order.push(Id);
        this.DataManager.Set(order, type, 'Order');
        this.DataManager.Set(PrototypeModel, type, 'List', Id);

        return Id;
    }
    ClonePrototype(Id) {
        return new Promise((resolve, reject) => {
            const ClonedData = Utils.DeepClone(this.GetPrototypeData(Id));        
            if (ClonedData) {  
                const NewId = this.AddPrototype({name : ClonedData.name + ' Clone', data : ClonedData});
                this.RelationManager.CloneOwner(Id, NewId);
                resolve(NewId);
            }
        });
        
    }
    GetNewName_Prototype(seedName = 'New Prototype') {
        return new Promise((resolve) => {
            resolve(this.GetNewName({
                models : this.GetPrototypeModels(),
                seedName : seedName 
            }))
        })
    }
    NewView(name) {
        const RootMetaItem = MetaData.ItemInitializer.FromMetaData({Type : 'Div'});
        RootMetaItem.name = 'Page';
        RootMetaItem.Property.Default.Default.width = Globals.ProjectManager.Units.FullSize();
        RootMetaItem.Property.Default.Default.height = { value : 'auto' };
        RootMetaItem.Property.Default.Default.minHeight = Globals.ProjectManager.Units.FullSize();
        RootMetaItem.Property.Default.Default.flexDirection = { value : 'column' };
        RootMetaItem.Property.Default.Default.justifyContent = { value : 'flexStart' };
        RootMetaItem.Property.Default.Default.alignItems = { value : MetaData.Properties.FlexValues.FLEXSTRETCH };
        const RootId = Utils.Id();
        const view = {
            name : name,
            Design : {
                RootId : RootId,
            }
        };
        Utils.Set(view.Design, RootMetaItem, 'MetaItems', RootId);
        return view;
    }
    AddPrototypePage({PrototypeId, name, view}) {
        let UseView = view;
        if (!view) {
            UseView = this.NewView(name);
        }
        const views = this.GetPrototypeViews(PrototypeId);
        const Id = Utils.Id();
        views[Id] = UseView;        
        this.DataManager.Set(views, 'Prototypes', 'List', PrototypeId, 'Views');
        return Id;
    }
    AddPrototypeView(PrototypeId, view, canvasPosition, canvasSize) {
        const views = this.GetPrototypeViews(PrototypeId);
        const Id = Utils.Id();
        views[Id] = view;        
        view.Canvas = {
            position : canvasPosition || this.GetNewViewPosition(views),
            size : canvasSize
        };
        const type = 'Prototypes';
        this.DataManager.Set(views, type, 'List', PrototypeId, 'Views');
        return Id;
    }
    ClonePrototypeView(prototypeId, id, position, IsDocument) {
        const type = IsDocument ? 'Documents' : 'Prototypes';
        const views = this.GetPrototypeViews(prototypeId);
        const view = views[id];
        if (view) {
            const clonedView = Utils.DeepClone(view);
            const newId = Utils.Id();
            this.RelationManager.CloneOwner(id, newId);
            let usePosition = position;
            if (!usePosition)
                usePosition = this.GetNewViewPosition(views);
            Utils.Set(clonedView, usePosition, 'Canvas', 'position');
            views[newId] = clonedView;
            this.DataManager.Set(views, type, 'List', prototypeId, 'Views');
            return newId;
        }
    }
    DeletePrototypeView(PrototypeId, Id) {
        return new Promise((resolve, reject) => {
            if (this.RelationManager.HasRelation(Id)) {
                Events.AlertSimple(Strings.COMPONENT_IS_USED, Events.GLOBAL.NOTIFICATION.TYPES.WARNING);
                resolve(false);
                return;
            }
            this.RelationManager.DeleteOwner(PrototypeId, Id);
            const views = this.GetPrototypeViews(PrototypeId);
            delete views[Id];
            const type = this.IsDocument ? 'Documents' : 'Prototypes';
            this.DataManager.Set(views, type, 'List', PrototypeId, 'Views');
            resolve(true);
        });
    }
    GetNewViewPosition(views) {
        let y = 999999;
        let x = -999999;
        Utils.ForEach(views, (view, viewid) => {
            const pos = Utils.JustGet(view, {x : 0, y : 0}, 'Canvas', 'position');
            const size = Utils.JustGet(view, {width : 400}, 'Canvas', 'size');
            y = Math.min(y, pos.y);
            x = Math.max(x, pos.x + size.width);
        });
        return {
            x : x + 40,
            y : y
        };
    }
    DeletePrototype(Id) {
        return new Promise((resolve, reject) => {
            const type = this.IsDocument ? 'Documents' : 'Prototypes';
            const order = Utils.JustGet(this.Data.Data, [], type, 'Order');
            Utils.RemoveEquals(order, Id);
            this.DataManager.Set(order, type, 'Order');
            this.DataManager.Delete(type, 'List', Id);
            this.RelationManager.DeleteOwner(Id);
            resolve(true);
        });
    }
    GetPrototypeData(Id) {
        const type = this.IsDocument ? 'Documents' : 'Prototypes';
        return Utils.JustGet(this.Data.Data, {}, type, 'List', Id);
    }
    GetPrototypeMainViewId(Id) {
        const type = this.IsDocument ? 'Documents' : 'Prototypes';
        return Utils.JustGet(this.Data.Data, null, type, 'List', Id, 'MainViewId');
    }
    GetPrototypeViews(Id) {
        return Utils.JustGet(this.Data.Data, {}, 'Prototypes', 'List', Id, 'Views');
    }
    ChangePrototypeProp(id, prop, value) {        
        const type = this.IsDocument ? 'Documents' : 'Prototypes';
        this.DataManager.Set(value, type, 'List', id, prop);
    }
    SetPrototypePublishedId(Id, IsDocument, ChangeId) {
        this.DataManager.Set(ChangeId, IsDocument ? 'Documents' : 'Prototypes', 'List', Id, 'PublishedId');
    }
    ValidatePrototypeName(id, name) {
        const type = this.IsDocument ? 'Documents' : 'Prototypes';
        const models = Utils.JustGet(this.Data.Data, [], type, 'List');        

        let result = {
            
        };
        if (!Utils.CheckUnique(name, models, 'name', models[id])) {
            result.error = true;
            result.message = `Name must be unique`;
        }
        return result;
    }
    GetPrototypeView(Id, ViewId) {
        const type = this.IsDocument ? 'Documents' : 'Prototypes';
        return Utils.JustGet(this.Data.Data, {}, type, 'List', Id, 'Views', ViewId);
    }
    GetPrototypeViewData(Id, ViewId) {
        const type = this.IsDocument ? 'Documents' : 'Prototypes';
        return Utils.JustGet(this.Data.Data, {}, type, 'List', Id, 'Views', ViewId, 'Design');
    }
    GetPrototypeViewName(prototypeId, id) {
        const view = this.GetPrototypeView(prototypeId, id);
        if (view)
            return view.name;
        return 'Page';
    }
    ChangeViewName(prototypeId, id, name) {
        const view = this.GetPrototypeView(prototypeId, id);
        if (view)
            view.name = name;
        
        const type = this.IsDocument ? 'Documents' : 'Prototypes';
        this.DataManager.Set(name, type, 'List', prototypeId, 'Views', id, 'name')
    }
    SetPrototypeValue(prototypeId, value, ...path) {      
        const type = this.IsDocument ? 'Documents' : 'Prototypes';  
        this.DataManager.Set(value, type, 'List', prototypeId,  ...path);
    }
    GetPrototypeValue(prototypeId, ...path) {      
        const type = this.IsDocument ? 'Documents' : 'Prototypes';  
        return this.DataManager.Get(null, type, 'List', prototypeId,  ...path);
    }
    SetPrototypeViewValue(prototypeId, id, value, ...path) {      
        const type = this.IsDocument ? 'Documents' : 'Prototypes';  
        this.DataManager.Set(value, type, 'List', prototypeId, 'Views', id, ...path);
    }
    GetPrototypeDevices(Id) {
        const type = this.IsDocument ? 'Documents' : 'Prototypes';
        return Utils.JustGet(this.Data.Data, [{width : 1024, Id : 'Default'}], type, 'List', Id, 'Canvas', 'Devices');
    }
    AddPrototypeDevice(Id, InsertAfter, Device) {
        let index = Math.max(0, InsertAfter + 1);
        const Devices = this.GetPrototypeDevices(Id);
        SetNewDeviceWidth({Devices : Devices, index : index-1, DeviceModel : Device});        
        Devices.splice(index, 0, Device);
        const type = this.IsDocument ? 'Documents' : 'Prototypes';  
        this.DataManager.Set(Devices, type, 'List', Id, 'Canvas', 'Devices');   
    }
    DeletePrototypeDevice(Id, index) {
        const Devices = this.GetPrototypeDevices(Id);
        if (Devices && Devices.length > 0 && index < Devices.length) {
            Devices.splice(index, 1);
            const type = this.IsDocument ? 'Documents' : 'Prototypes';  
            this.DataManager.Set(Devices, type, 'List', Id, 'Canvas', 'Devices');   
        }
    }
    UpdatePrototypeDeviceSize(Id, index, width) {
        const Devices = this.GetPrototypeDevices(Id);
        if (Devices && Devices.length > 0 && index < Devices.length) {
            Devices[index].width = width;
            const type = this.IsDocument ? 'Documents' : 'Prototypes';  
            this.DataManager.Set(Devices, type, 'List', Id, 'Canvas', 'Devices');  
        }
    }
    UpdatePrototypeDeviceOption(Id, index, value, ...path) {
        const Devices = this.GetPrototypeDevices(Id);
        if (Devices && Devices.length > 0 && index < Devices.length) {
            Utils.Set(Devices[index], value, ...path);
            const type = this.IsDocument ? 'Documents' : 'Prototypes';  
            this.DataManager.Set(Devices[index], type, 'List', Id, 'Canvas', 'Devices', index);  
        }
    }    
    
    GetPrototypeCanvasResponsiveInfo(Id, offset) {
        const type = this.IsDocument ? 'Documents' : 'Prototypes';  
        return Utils.JustGet(this.Data.Data, {offset : offset || 400}, type, 'List', Id, 'Canvas', 'Options'); 
    }
    SavePrototypeCanvasResponsiveOffset(Id, offset) {
        const type = this.IsDocument ? 'Documents' : 'Prototypes';
        this.DataManager.Set(offset, type, 'List', Id, 'Canvas', 'Options', 'offset');   
    }

    ValidateViewName(prototypeId, id, name) {
        const views = this.GetPrototypeViews(prototypeId);
        let result = {
            
        };
        if (!Utils.CheckUnique(name, views, 'name', views[id])) {
            result.error = true;
            result.message = `Name must be unique`;
        }
        return result;
    }
    ReplacePrototypeViewData(Id, ViewId, Data) {
        const OriginalData = this.GetPrototypeViewData(Id, ViewId);
        if (OriginalData && Data) {
            Utils.ForEach(OriginalData, (value, prop) => {
                delete OriginalData[prop];
            });
            Utils.ForEach(Data, (value, prop) => {
                OriginalData[prop] = value;                
            });
            const ChangeId = Utils.Id();
            const type = this.IsDocument ? 'Documents' : 'Prototypes';
            Utils.Set(this.Data, ChangeId, 'Data', 'ChangeId');
            AppState.GetDataApi().update_path_project_data(this.Id, ['ChangeId'], ChangeId);
            AppState.GetDataApi().update_path_project_data(this.Id, [type,'List',Id, ViewId, 'Design'], Utils.UseUndefined(Data, null));
            return;
        }
    }
    GetPrototypeDesignerOptions(Id, DesignerType) {
        return Utils.JustGet(this.Data.Data, {}, 'Prototypes', 'List', Id, 'Designer', DesignerType || 'Default');
    }   
    GetPrototypeDesignerOption(Id, DesignerType, DefaultValue, ...Path) {
        return Utils.JustGet(this.Data.Data, DefaultValue, 'Prototypes', 'List', Id, 'Designer', DesignerType || 'Default', ...Path);
    }    
    SetPrototypeDesignerOption(Id, DesignerType, Value, ...Path) {
        this.DataManager.Set(Value, 'Prototypes', 'List', Id, 'Designer', DesignerType || 'Default', ...Path);
    }    
    DeletePrototypeDesignerOption(Id, DesignerType, ...Path) {
        this.DataManager.Delete('Prototypes', 'List', Id, 'Designer', DesignerType || 'Default', ...Path);
    }


    GetPrototypeCanvasOptions(Id, CanvasType, defaultValue, ...path) {
        const type = this.IsDocument ? 'Documents' : 'Prototypes';
        return this.DataManager.Get(defaultValue, type, 'List', Id, CanvasType || 'FreeCanvas', 'Options', ...path);
    }
    SetPrototypeCanvasOptions(Id, CanvasType, value, ...path) {
        const type = this.IsDocument ? 'Documents' : 'Prototypes';
        this.DataManager.Set(value, type, 'List', Id, CanvasType || 'FreeCanvas', 'Options', ...path);
    }
    GetPrototypeCanvasItems(Id) {
        const type = this.IsDocument ? 'Documents' : 'Prototypes';
        const items = Utils.JustGet(this.Data.Data, {}, type, 'List', Id, 'FreeCanvas', 'Items'); 
        const MainViewId = this.GetPrototypeMainViewId(Id);
        if (!items[MainViewId]) {
            items[MainViewId] = {
                id : MainViewId,
                size : {
                    width : 1024                    
                },
                position : {
                    x : 100,
                    y : 100
                }
            }
            const type = this.IsDocument ? 'Documents' : 'Prototypes';
            this.DataManager.Set(items[MainViewId], type, 'List', Id, 'FreeCanvas', 'Items', MainViewId);   
        }
        return items;
    }
    AddPrototypeCanvasItem(Id, item) {
        const itemid = Utils.Id();
        const type = this.IsDocument ? 'Documents' : 'Prototypes';
        this.DataManager.Set(item, type, 'List', Id, 'FreeCanvas', 'Items', itemid);   
        return itemid;
    }
    SetPrototypeCanvasItemProp(prototypeId, id, prop, value) {
        const type = this.IsDocument ? 'Documents' : 'Prototypes';
        this.DataManager.Set(value, type, 'List', prototypeId, 'FreeCanvas', 'Items', id, prop);
    }
    //#endregion
    
    NewStateValue(value) {
        return {
            Default : {
                Default : {
                    value : value
                }
            }
        }
    }
    CreateTempItem(Item, Position) {
        if (Item) {
            const MetaItem = this.CreateToolbarItem(Item);

            return {
                Position: Position,
                State: 'Boarding',
                Id: Utils.Id(),
                MetaItem: MetaItem
            };
        }
    }
    CreateToolbarItem(SourceItem, Options) {
        let MetaItem = null;
        let TemplateId = SourceItem.ElementTemplate;        
        
        MetaItem = MetaData.ItemInitializer.FromMetaData(SourceItem, TemplateId, Globals.ComponentManager);
        
        if (MetaItem.Type === MetaData.Components.Image.Type) {
            const ImageId = Utils.Get(MetaItem, null, 'Property', 'Default', 'Default', 'MetaImage', 'value');
            if (ImageId) {
            }
            else {
                const Provider = Utils.Get(MetaItem, null, 'Property', 'Default', 'Default', 'Provider', 'value');
                if (Provider) {
                    Utils.Set(MetaItem, 200, Strings.PROPERTY, Strings.DEFAULT, Strings.DEFAULT, 'minHeight', 'value');
                }
            }
        }
        else if (MetaItem.Type === MetaData.Components.Component.Type) {
            // if (Options && Options.SetDefaultSize)
            {
                const ComponentId = Utils.Get(MetaItem, null, 'Property', 'Default', 'Default', Strings.COMPONENT, 'Id', 'value'); 
                if (ComponentId) {
                    const ComponentData = this.GetComponentData(ComponentId);
                    const SetChildComponentSize = (ComponentData, MetaItem) => {
                        const RootId = Utils.JustGet(ComponentData, null, 'RootItem', 'Id');
                        if (RootId) {
                            const RootItem = Utils.JustGet(ComponentData, null, 'MetaItems', RootId);
                            if (RootItem) {
                                const width = Utils.JustGet(RootItem, null, 'Property', 'Default', 'Default', 'width');
                                const height = Utils.JustGet(RootItem, null, 'Property', 'Default', 'Default', 'height');

                                Utils.Set(MetaItem, width, Strings.PROPERTY, Strings.DEFAULT, Strings.DEFAULT, 'width');
                                Utils.Set(MetaItem, height, Strings.PROPERTY, Strings.DEFAULT, Strings.DEFAULT, 'height');

                            }
                        }
                    }
                    if (ComponentData && ComponentData.Data) {
                        SetChildComponentSize(ComponentData.Data, MetaItem);                            
                    }
                    else {
                        
                    }
                }
            }
        }
        return MetaItem;
    }
    DeleteStorageImage(Id) {
        this.DataManager.Storage.Delete(Id);
    }
    UploadImageFile(Id, file, MetaImage, OnEndCallback, OnProgressCallback) {
        const MetaFile = {
            Id : Id,
            Name : file.name
        };
        const that_project = this;
        const ImageId = Utils.Id();
        const progress = (prog) => {
            OnProgressCallback && OnProgressCallback(prog);
            Events.BCE(Id, Events.DESIGNER.BOARD.IMAGE.UPLOADING, true, prog);
        };
    
        this.DataManager.Storage.Save(ImageId, file, MetaFile, progress).then((result) => {
            if (result) {
                result.ref.getDownloadURL().then(function(downloadURL) {
                    var img = document.createElement("img");
                    img.setAttribute("Id", Id);
                    img.onload = function() {
                        that_project.Tokens.SetSubValue({id : Id, prop : 'url', value : downloadURL});
                        that_project.Tokens.SetSubValue({id : Id, prop : 'UrlId', value : ImageId});
                        that_project.Tokens.SetSubValue({id : Id, prop : 'width', value : img.naturalWidth  || img.width});
                        that_project.Tokens.SetSubValue({id : Id, prop : 'height', value : img.naturalHeight || img.height});
                        that_project.Tokens.SetSubValue({id : Id, prop : 'loading', value : false});

                        const el = document.getElementById(Id);
                        el && el.parentNode && el.parentNode.removeChild(el);
                        Events.BCE(Id, Events.DESIGNER.BOARD.IMAGE.UPLOADED);
                        OnEndCallback && OnEndCallback(downloadURL);
                    }
                    img.src = downloadURL;
                });

            }
            else
                Events.BCE(Id, Events.DESIGNER.BOARD.IMAGE.UPLOADING, false);
        });
    }

    // Export Token Options
    GetExportModels() {
        let models = Utils.JustGet(this.Data.Data, null, 'Export', 'Models');
        if (!models) {
            models = [];
            const PushNewDefinition = (name, main, tokenDef) => {
                const id = Utils.Id();
                models.push({
                    id : id,
                    name : name
                });                    

                const NewDefinition = {                        
                    ...main,
                    target : main.target || 'css',
                    tokens : {
                        ...tokenDef
                    }
                };
                this.SetExportDefinition(id, NewDefinition);
            }

            const oldModel = Utils.JustGet(this.Data.Data, null, 'Export', 'Options');   
            if (oldModel) {
                const {
                    TokenTypeOptions,
                    allTargets,
                    target,
                    ...CustomTargets
                } = oldModel;
                

                Utils.ForEach(CustomTargets, (targetDef, targetType) => {
                    PushNewDefinition(Utils.ToPascalCase(targetType), 
                        {...allTargets, ...targetDef, target : targetType || target},
                        {...Utils.JustGet(TokenTypeOptions, {}, 'allTargets'), ...Utils.JustGet(TokenTypeOptions, {}, targetType)}                        
                    )
                });
                
                this.DataManager.Set(models, 'Export', 'Models');
                
            }
            else {
                PushNewDefinition('CSS', {target : 'css'}, {});
                PushNewDefinition('Sass', {target : 'sass'}, {});
                PushNewDefinition('JSON', {target : 'json'}, {});
                this.DataManager.Set(models, 'Export', 'Models');
            }
        }

        return models;
    } 
    GetExportModel(id) {
        const models = this.GetExportModels();
        return {
            model : Utils.Find(models, (item) => {return item.id === id}),
            definition : this.GetExportDefinition(id)
        };
    } 
    SetExportModel(model) {
        if (model) {
            const models = this.GetExportModels();
            const index = Utils.FindIndex(models, (item) => {return item.id === model.id});
            if (index > -1) {
                this.DataManager.Set(model, 'Export', 'Models', index);    
            }
        }        
    }    
    AddExportModel(model) {
        const models = this.GetExportModels();
        models.push(model);
        this.DataManager.Set(models, 'Export', 'Models');
        this.SetExportDefinition(model.id, {
            target : 'css'
        });
    }
    SetExportDefinition(id, definition) {
        this.DataManager.Set(definition, 'Export', 'Definitions', id);
    }
    SetExportDefinitionProp(id, value, ...path) {
        this.DataManager.Set(value, 'Export', 'Definitions', id, ...path);
    }
    SetExportDefinitionGroupProp(id, groupId, value, ...path) {
        this.DataManager.Set(value, 'Export', 'Definitions', id, 'groupData', groupId, ...path);
    }
    GetExportDefinition(id) {
        const definition = Utils.JustGet(this.Data.Data, {}, 'Export', 'Definitions', id);

        if (definition) {
            let groups = Utils.JustGet(definition, null, 'groups');
            if (!groups || Array.isArray(groups) && groups.length === 0) {
                groups = [];
                const groupData = {};

                if (definition.tokens) {
                    [
                        this.Tokens.Types.COLOR,
                        this.Tokens.Types.Gradients,
                        this.Tokens.Types.Shadows,
                        this.Tokens.Types.Borders,
                        this.Tokens.Types.BorderRadiuses,
                        this.Tokens.Types.Fonts,
                        this.Tokens.Types.SpacePatterns,
                        this.Tokens.Types.Transforms,
                        this.Tokens.Types.Filters,
                        this.Tokens.Types.Motion,
                        this.Tokens.Types.Images,
                        this.Tokens.Types.Icons,
                        this.Tokens.Types.ContentTexts
                    ].map((tokenType) => {
                        const tokenData = Utils.JustGet(definition.tokens, {}, tokenType);
                        if (!tokenData.disabled) {
                            groups.push(tokenType);
                            groupData[tokenType] = {                                
                                ...tokenData,
                                tokenType : tokenType
                            }
                        }
                    })
                }
                else {

                }

                this.DataManager.Set(groups, 'Export', 'Definitions', id, 'groups');
                this.DataManager.Set(groupData, 'Export', 'Definitions', id, 'groupData');                
            }
        }

        return definition;
    }
    CloneExportDefinition(id) {
        const cloneId = Utils.Id();
        const source = this.GetExportModel(id);
        const definitions = this.GetExportModels();
        definitions.push({
            id : cloneId,
            name : 'Duplicate of ' + source.model.name
        })        
        this.DataManager.Set(definitions, 'Export', 'Models');
        this.SetExportDefinition(cloneId, Utils.DeepClone(source.definition));
        return cloneId;
    }
    DeleteExportDefinition(id) {
        const definitions = this.GetExportModels();
        Utils.Remove(definitions, (item) => {return item.id === id});
        this.DataManager.Set(definitions, 'Export', 'Models');
        this.DataManager.Delete('Export', 'Definitions', id);
    }
    DuplicateExportTokenBlock(id, blockId) {
        const definition = Utils.JustGet(this.Data.Data, {}, 'Export', 'Definitions', id);
        const groups = Utils.JustGet(definition, [], 'groups');
        const newId = Utils.Id();
        const index = groups.indexOf(blockId);
        groups.splice(index, 0, newId);
        this.DataManager.Set(groups, 'Export', 'Definitions', id, 'groups');
        const groupData = Utils.DeepClone(Utils.JustGet(definition, {}, 'groupData', blockId));
        this.DataManager.Set(groupData, 'Export', 'Definitions', id, 'groupData', newId);
        return newId;
    }
    DeleteExportTokenBlock(id, blockId) {
        const definition = Utils.JustGet(this.Data.Data, {}, 'Export', 'Definitions', id);
        const groups = Utils.JustGet(definition, [], 'groups');
        Utils.RemoveEquals(groups, blockId);
        this.DataManager.Set(groups, 'Export', 'Definitions', id, 'groups');
        this.DataManager.Delete('Export', 'Definitions', id, 'groupData', blockId);
    }
    AddExportTokenBlock(id) { 
        const groupId = Utils.Id();
        const definition = Utils.JustGet(this.Data.Data, {}, 'Export', 'Definitions', id);
        const groups = Utils.JustGet(definition, [], 'groups');
        groups.push(groupId);

        this.DataManager.Set(groups, 'Export', 'Definitions', id, 'groups');
        this.DataManager.Set({
            tokenType : this.Tokens.Types.COLOR
        }, 'Export', 'Definitions', id, 'groupData', groupId);
        return groupId;
    }
    GetExportTokenOptions() {
        return Utils.JustGet(this.Data.Data, { target : 'css'}, 'Export', 'Options');
    }
    SetExportTokenOptions(options) {
        this.DataManager.Set(options, 'Export', 'Options');
    }
    SetExportTokenOption(value, ...path) {
        this.DataManager.Set(value, 'Export', 'Options', ...path);
    }
    TokenMetaData() {
        return {
            SetOption(tokenId, value, ...path) {
                Globals.ProjectManager.DataManager.Set(value, 'MetaData', 'Tokens', tokenId, ...path);
            },
            GetOption(tokenId, defaultValue, ...path) {
                return Utils.JustGet(Globals.ProjectManager.Data.Data, defaultValue, 'MetaData', 'Tokens', tokenId, ...path);
            },
            Delete(tokenId) {
                Globals.ProjectManager.DataManager.Delete('MetaData', 'Tokens', tokenId);
            }
        }
    }

    GetDocumentManager() {
        if (!this.DocumentManager) {
            this.DocumentManager = new DocumentManagerClass(this);
        }
        return this.DocumentManager;
    }

    IsAdmin() {
        return this.Grant === AppState.Team.EditorTypes.owner.id;
    }
    CheckGrant(GrantType, GrantSubType, ...Params) {
        if (this.Grant === AppState.Team.EditorTypes.view.id) {
            return false;
        }
        if (this.Grant === AppState.Team.EditorTypes.documentEditor.id) {
            return false;
        }
        return true;
    }
    CheckGrant_EditSystemStates() {
        if (this.Grant === AppState.Team.EditorTypes.view.id || this.Grant === AppState.Team.EditorTypes.documentEditor.id) {
            return false;
        }
        return true;
    }
    CheckGrant_AddToken(TokenType) {
        if (this.Grant === AppState.Team.EditorTypes.view.id || this.Grant === AppState.Team.EditorTypes.documentEditor.id) {
            return false;
        }
        return true;
    }
    CheckGrant_AddTokenGroup(TokenType) {
        if (this.Grant === AppState.Team.EditorTypes.view.id || this.Grant === AppState.Team.EditorTypes.documentEditor.id) {
            return false;
        }
        return true;
    }
    CheckGrant_DocumentEditor() {
        if (this.Grant === AppState.Team.EditorTypes.view.id) {
            return false;
        }
        return true;
    }
    CheckGrant_TokenExportEditor() {
        if (this.Grant === AppState.Team.EditorTypes.view.id || this.Grant === AppState.Team.EditorTypes.documentEditor.id) {
            return false;
        }
        return true;
    }
}

export const DefaultMediaSizes = ['Default', 'xSmall', 'Small', 'Medium', 'Large'];
export const DefaultMediaSizesOrdered = ['xSmall', 'Small', 'Medium', 'Large', 'Default'];

export const GRANT_TYPES = {
    EDIT_TOKEN : {
        ALL : 'GETALL'
    },
    DELETE_TOKEN : {
        ALL : 'GDTALL'
    }
}