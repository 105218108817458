import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBasicComponent,
    SC,
    Utils,
    AppLayout,
    Strings,
    Globals
} from '../../../../../importer';

import {TokenGroup, TokenPanelHeader, GroupTitle} from './common';

import Colors from './colors';
import Typography from './typography';
import FontStyleEditor from './typography/fonts/item';
import { LeftScrollPanel } from '../common';
import { motion, AnimatePresence  } from 'framer-motion';
import Shadows from './shadows';
import Spacings from './spacing';
import Borders from './borders';
import MotionSystem from './motion';
import Transforms from './transforms';
import Filters from './filters';
import Images from './images';
import Iconography from './icons';
import Sounds from './sounds';
import TextContents from './textContent';
import Booleans from './booleans';
import DefaultStyles from './defultstyles';
import TokenAliases from './aliases';
import SystemStatePanel from '../systemstates';
import DropDownSelect from '../../../../../components/editors/enum_dropdown';
import LeaderLineProxy from '../../../../../components/leaderLine';
import ComponentDesignManager from '../../../component/manager';
import Components from '../components';
import MetadataDefinitions from './metadata';
import CustomTokenDefinitions from './custom/definitions';
import { SearchInput } from '../../../searchbar';
import FigmaAutoLayouts from '../../../../../apps/figmaplugin/designsystem/autolayouts';

class DesignSystemPanel extends ReactBasicComponent {
    constructor(props) {
        super(props);

        if (this.props.isSelectList) {
            this.TokenType = this.props.SelectTokenType;
        }
        else {
            this.TokenType = Globals.ProjectManager.Options.Get('all', 'LeftPanel', 'DesignSystem', 'TokenType');                            

            if (!AppLayout.AppSource.Figma) {
                const tokenids = Globals.ProjectManager.Tokens.FigmaLayouts();
                if (tokenids && Array.isArray(tokenids) && tokenids.length > 0)
                    this.hasFigmaAutoLayouts = true;

                if (this.TokenType === 'FigmaAutoLayout' && !this.hasFigmaAutoLayouts)
                    this.TokenType = 'all';
            }            
        }

        
        this.onChangeTokenType = this.onChangeTokenType.bind(this);
        this.renderTokenHeader = this.renderTokenHeader.bind(this);
        this.renderDropdownItem = this.renderDropdownItem.bind(this);

        this.onPanelOverlay = this.onPanelOverlay.bind(this);        
        this.onShowConsumers = this.onShowConsumers.bind(this);

        this.ToggleSerach = this.ToggleSerach.bind(this);

        this.Ref_Scroll = React.createRef();
        this.Ref_SingleViewEditor = React.createRef();
    }  
    onPanelOverlay(options) {
        if (options.close) {
            delete this.HasPanelOverlay;
            this.RCUpdate();
            return;
        }
        this.HasPanelOverlay = options;
        this.RCUpdate();
    }
    ToggleSerach() {
        this.setState({showSearchBar : !this.state.showSearchBar});
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.disableRender) 
            return false;
        if (this.state.showSearchBar !== nextState.showSearchBar || this.state.filterText !== nextState.filterText) 
            return true;
        return super.ShouldUpdate(nextProps, nextState);
    }
    // shouldComponentUpdate(nextProps, nextState) {
    //     if (Utils.HasAnyChange(this.props, nextProps, 'GlobalState', 'GlobalStateId', 'dragSource'))
    //         return true;
    //     if (super.ShouldUpdate(nextProps, nextState))
    //         return true;
    //     if (this.HasPanelOverlay) {
    //         if (this.props.GlobalState !== nextProps.GlobalState)
    //             return true;
    //         if (this.HasPanelOverlay.visible)
    //             return false;            
    //         this.HasPanelOverlay.visible = true;
    //     }
    //     return false;
    // }
    onChangeTokenType(type) {
        this.TokenType = type;
        this.Ref_Scroll.current && this.Ref_Scroll.current.scrollTop(0);
        Globals.ProjectManager.Options.Set(type, 'LeftPanel', 'DesignSystem', 'TokenType');
        this.RCUpdate();
    }
    renderTokenHeader(label) {
        return (
            <GroupTitle title={label} style={{textTransform : 'uppercase', padding : 0}} />
        )
    }
    renderDropdownItem(item) {        
        return (
            <GroupTitle title={item.label} style={{textTransform : 'uppercase', padding : 0, fontSize : '11px', ...SC.Styles.TextAbbr}} />
        )
    }
    onShowConsumers(id, ref) {        
        this.BindingLines = [];
        this.setState({
            ShouldUpdate : true
        }, () => {
            setTimeout(() => {
                const consumers = Globals.ProjectManager.RelationManager.GetConsumers(id);
                if (consumers) {
                    if (Array.isArray(consumers)) {
                        Utils.ForEach(consumers, (relationId, i) => {
                            const relation = Globals.ProjectManager.RelationManager.GetRelation(relationId);
                            if (relation) {
                                if (relation.ConsumerType === 'MetaItem') {
                                    if (ComponentDesignManager.Active().All[relation.OwnerId]) {
                                        Utils.ForEach(ComponentDesignManager.Active().All[relation.OwnerId].Managers, (manager, ) => {
                                            const ItemDesigner = manager.GetItemDesigner(relation.ConsumerId);
                                            if (ItemDesigner) {
                                                this.BindingLines.push({
                                                    SourceId : id,
                                                    ItemId : relation.ConsumerId,
                                                    SourceNode : ref,
                                                    TargetNode : {current : ReactDOM.findDOMNode(ItemDesigner)}
                                                })
                                            }   
                                        });
                                    }                            
                                }
                            }
                        });
                    } 
                }
                this.RCUpdate();            
            }, 10);
        })
        
    }
    renderCustom() { 

        const props = {
            GlobalState : this.props.GlobalState,
            GlobalStateId : this.props.GlobalStateId,
            GlobalThemeId : this.props.GlobalThemeId,
            themeId : this.props.themeId,
            onPanelOverlay : this.onPanelOverlay,
            onShowConsumers : this.onShowConsumers,
            onUpdate : this.RCUpdate,
            dragSource : this.props.dragSource,
            RefToolbar : this.props.RefToolbar,
            filterText : this.state.filterText,
            showSearchBar : this.state.showSearchBar,
            isSelectList : this.props.isSelectList,
            onSelect : this.props.onSelect
        }

        let panelOverlay;

        if (this.HasPanelOverlay) {
            if (this.HasPanelOverlay.render)
                panelOverlay = this.HasPanelOverlay.render(this.props);
            else {        
                if (this.HasPanelOverlay.type === 'EditFont') {
                    panelOverlay = (
                        <SC.FCol fw fh>
                            <TokenPanelHeader title='EDIT FONT' hasAddCancel onClose={this.HasPanelOverlay.onClose} onCancel={this.HasPanelOverlay.onCancel} onAdd={this.HasPanelOverlay.onAdd} onDelete={this.HasPanelOverlay.onDelete} />
                            <LeftScrollPanel>
                                <FontStyleEditor 
                                    id={this.HasPanelOverlay.id}
                                    onSelectFont={this.HasPanelOverlay.onSelectFont}
                                    isDefaultFont={this.HasPanelOverlay.isDefaultFont}
                                    GlobalStateId={this.props.GlobalStateId}
                                    onClose={this.HasPanelOverlay.onClose}
                                />
                            </LeftScrollPanel>                        
                        </SC.FCol>
                    )
                }
            }
        }

        let consumerLines;

        // if (this.BindingLines && this.BindingLines.length > 0) {
        //     consumerLines = [];
        //     this.BindingLines.map((info ) => {
        //         consumerLines.push(
        //             <LeaderLineProxy
        //                 key={info.ItemId}
        //                 startid={info.SourceId}
        //                 start={info.SourceNode} 
        //                 end={info.TargetNode} 
        //                 endid={info.ItemId}
        //                 // dash            
        //                 endSocket='left'
        //                 startSocket='right'
        //                 endPlug='square'
        //                 delay={1}
        //             />
        //         )
        //     })
        // }

        props.singleView = this.TokenType !== 'all' || this.TokenType === 'DefaultStyles';

        let headerAddon;

        if (this.TokenType === Globals.ProjectManager.Tokens.Types.CustomTokenTypes) {
            headerAddon = (
                <SC.Icons.Icon_ButtonBox hasCursor hasFill  title='Add New Token Type' onClick={() => {
                    if (this.Ref_SingleViewEditor.current) {
                        this.Ref_SingleViewEditor.current.AddToken();
                    }
                }} style={{margin : '8px', marginLeft : 0}}>
                    <SC.Icons.Icon_Plus size={20} />
                </SC.Icons.Icon_ButtonBox>
            )
        }

        let header, searchBox;

        if (this.state.showSearchBar) {
            searchBox = (
                <SearchInput 
                    small full={!this.props.isSelectList} 
                    style={{margin : '8px', minHeight : '32px', marginBottom : this.props.isSelectList ? 0 : '8px', width : this.props.isSelectList ? 'unset' : '100%', borderRadius : '4px', paddingLeft : 0, paddingRight : 0}}
                    styleSearchIcon={{paddingLeft : '4px'}}
                    hasFullClose
                    autoFocus
                    SearchHint='Search tokens...'
                    FilterText={this.state.filterText}
                    CancelSearch={() => {
                        this.setState({showSearchBar : false, filterText : ''})
                    }}
                    Search={(value) => {
                        this.setState({filterText : value})
                    }}
                />
            )
        }
        

        if (this.props.isSelectList) {
            header = (
                <React.Fragment>
                    <TokenPanelHeader title={'TRANSFORMS'} onClose={this.props.onClose} style={{padding : '2px', backgroundColor : SC.CurrentTheme.theme.back_lighter}}>
                        <SC.Icons.Icon_ButtonBox hasFill onClick={this.ToggleSerach} style={{cursor : 'pointer', paddingLeft : '2px', paddingRight : '4px'}} title={'Toggle Search Box'} >
                            <SC.Icons.Icon_Search size={14} />
                        </SC.Icons.Icon_ButtonBox>
                    </TokenPanelHeader>
                    {searchBox}
                </React.Fragment>
                
            )
        }
        else {
            header = (
                <SC.FRow style={{
                    paddingLeft : props.singleView ? 0 : '2px',
                    paddingRight : props.singleView ? 0 : '2px',
                    paddingTop : '2px',
                    position : 'relative'
                }}>
                    {
                        this.state.showSearchBar ? 
                        searchBox : 
                        <React.Fragment>
                            <DropDownSelect 
                                items={[
                                    {id : 'all', label : 'All Tokens'},
                                    {id : Strings.COMPONENTS, label : 'Components'},
                                    {id : 'aliases', label : 'Semantic Token Aliases'},                        
                                    {id : Globals.ProjectManager.Tokens.Types.COLOR, label : 'Colors'},
                                    {id : Globals.ProjectManager.Tokens.Types.Shadows, label : 'Shadows'},
                                    {id : Globals.ProjectManager.Tokens.Types.Fonts, label : 'Typography'},                            
                                    {id : Globals.ProjectManager.Tokens.Types.Spacings, label : 'Spacing'},
                                    {id : Globals.ProjectManager.Tokens.Types.FigmaAutoLayouts, label : 'Auto Layouts', hiddenInPopup : !this.hasFigmaAutoLayouts},
                                    {id : Globals.ProjectManager.Tokens.Types.Borders, label : 'Borders & Radii'},                                
                                    {id : Globals.ProjectManager.Tokens.Types.Icons, label : 'Iconography'},
                                    {id : Globals.ProjectManager.Tokens.Types.Images, label : 'Images'},
                                    {id : Globals.ProjectManager.Tokens.Types.Transitions, label : 'Motion'},
                                    {id : Globals.ProjectManager.Tokens.Types.Transforms, label : 'Transforms'},
                                    {id : Globals.ProjectManager.Tokens.Types.Filters, label : 'Filters'},
                                    {id : Globals.ProjectManager.Tokens.Types.Sounds, label : 'Sounds'},
                                    {id : Globals.ProjectManager.Tokens.Types.Booleans, label : 'Booleans'},
                                    {id : Globals.ProjectManager.Tokens.Types.ContentTexts, label : 'Microcopy (UX Texts)'},                        
                                    {id : Globals.ProjectManager.Tokens.Types.CustomTokenTypes, label : 'Custom Tokens'},
                                    {id : 'DefaultStyles', label : 'Default Element Styles'},
                                    // {id : 'Metadata', label : 'Metadata'},                                    
                                ]}
                                fullwidth
                                popupStyle={{minWidth : 'unset', left : '8px', right : '8px', marginTop : '-4px'}}
                                autoHeight
                                largeText
                                noUpwardPopup
                                popupItemStyle={{fontWeight : 500, padding : '4px', paddingLeft : '8px'}}
                                value={this.TokenType}
                                onChange={this.onChangeTokenType}
                                boxStyle={{paddingLeft : '8px', paddingRight : '4px', borderRadius : '4px', backgroundColor : SC.CurrentTheme.theme.back_lighter, border : SC.CurrentTheme.theme.border_ondark}}
                                boxHoverStyle={{
                                    backgroundColor : SC.CurrentTheme.theme.back_lightest
                                }}
                                style={{padding : '8px', minHeight : '48px', position : 'unset'}}
                                renderLabelItem={this.renderTokenHeader}
                                renderItem={this.renderDropdownItem}
                            />
                            <SC.Icons.Icon_ButtonBox hasFill onClick={this.ToggleSerach} style={{cursor : 'pointer', paddingLeft : '2px', paddingRight : '4px', margin : '8px', marginLeft : 0, marginRight : headerAddon ? 0 : '8px'}} title={'Toggle Search Box'} >
                                <SC.Icons.Icon_Search size={14} />
                            </SC.Icons.Icon_ButtonBox>
                            {headerAddon}
                        </React.Fragment>
                    }
                    
                </SC.FRow>
            );      
        }

          

        let commonHeader;
        if (props.singleView) {
            props.children = header;
        }
        else {
            commonHeader = header;
        }

        // this.RenderIndex = (this.RenderIndex || 0) + 1;

        let content;
        if (this.TokenType === 'DefaultStyles') {
            content = (
                <DefaultStyles {...props} width={this.props.width} />
            )
        }
        else {
            content = (
                <SC.FCol f1         
                    style={{
                        backgroundColor : SC.CurrentTheme.theme.back,
                        paddingTop : props.singleView ? 0 : '4px',                        
                        // height : '100%',
                        boxSizing : 'border-box'
                    }}       
                >
                    {
                        this.TokenType === 'DefaultStyles' &&
                        <DefaultStyles {...props} width={this.props.width} />
                    }
                    {
                        this.TokenType === 'aliases' &&
                        <TokenAliases {...props} width={this.props.width} />
                    }
                    {
                        (!props.singleView || this.TokenType === Globals.ProjectManager.Tokens.Types.COLOR) && 
                        <Colors {...props} />
                    }
                    {
                        (!props.singleView || this.TokenType === Globals.ProjectManager.Tokens.Types.Shadows) && 
                        <Shadows {...props} />
                    }
                    {
                        (!props.singleView || this.TokenType === Globals.ProjectManager.Tokens.Types.Fonts) && 
                        <Typography {...props} />
                    }
                    {
                        (!props.singleView || this.TokenType === Globals.ProjectManager.Tokens.Types.Spacings) && 
                        <Spacings  {...props} />
                    }
                    {
                        (!props.singleView || this.TokenType === Globals.ProjectManager.Tokens.Types.Borders) && 
                        <Borders {...props} />
                    }
                    {
                        (!props.singleView || this.TokenType === Globals.ProjectManager.Tokens.Types.Icons) && 
                        <Iconography {...props} />
                    }
                    {
                        (!props.singleView || this.TokenType === Globals.ProjectManager.Tokens.Types.Images) && 
                        <Images {...props} />
                    }
                    {
                        (!props.singleView || this.TokenType === Globals.ProjectManager.Tokens.Types.Transitions) && 
                        <MotionSystem {...props} />
                    }
                    {
                        (!props.singleView || this.TokenType === Globals.ProjectManager.Tokens.Types.Transforms) && 
                        <Transforms {...props} />
                    }
                    {
                        (!props.singleView || this.TokenType === Globals.ProjectManager.Tokens.Types.Filters) && 
                        <Filters {...props} />
                    }
                    {
                        (!props.singleView || this.TokenType === Globals.ProjectManager.Tokens.Types.Sounds) && 
                        <Sounds {...props} />
                    }
                    {
                        (!props.singleView || this.TokenType === Globals.ProjectManager.Tokens.Types.ContentTexts) && 
                        <TextContents {...props} />
                    }
                    {
                        (!props.singleView || this.TokenType === Globals.ProjectManager.Tokens.Types.Booleans) && 
                        <Booleans last {...props} />
                    }
                    {
                        this.hasFigmaAutoLayouts && (!props.singleView || this.TokenType === Globals.ProjectManager.Tokens.Types.FigmaAutoLayouts) && 
                        <FigmaAutoLayouts 
                            {...props}                             
                        />                        
                    }
                    {
                        (props.singleView && this.TokenType === Globals.ProjectManager.Tokens.Types.CustomTokenTypes) && 
                        <CustomTokenDefinitions {...props} ref={this.Ref_SingleViewEditor} />
                    }
                    {
                        (props.singleView && this.TokenType === 'Metadata') && 
                        <MetadataDefinitions last {...props} />
                    }
                    {
                        (props.singleView && this.TokenType === Strings.COMPONENTS) && 
                        <Components 
                            designsystem
                            {...props}

                        />
                    }
                </SC.FCol>
            )
            if (!props.singleView) {
                content = (
                    <LeftScrollPanel ScrollRef={this.Ref_Scroll}>
                        {content}       
                    </LeftScrollPanel>   
                )
            }            
        }

        return (
            <React.Fragment>
                {!this.props.isSelectList && <SystemStatePanel themeId={this.props.themeId} />}                
                <SC.FCol f1 style={{position : 'relative'}}>          
                    {commonHeader}          
                    {content}     
                    <AnimatePresence>
                        {
                            this.HasPanelOverlay && 
                            <motion.div 
                                style={{...SC.Styles.Absolute, zIndex : 99, backgroundColor : SC.CurrentTheme.theme.back_lighter}}
                                initial={{opacity : 0.7, x : this.props.onRightPanel ? 24 : -24}}
                                animate={{opacity : 1, x : 0}}
                                exit={{opacity : 0, x : this.props.onRightPanel ? 24 : -24}}
                                transition={{duration : 0.2}}
                            >
                                {panelOverlay}
                            </motion.div>
                        }
                    </AnimatePresence>
                </SC.FCol>            
                {consumerLines}
            </React.Fragment>            
        ) 
    }
}
 
export default DesignSystemPanel;