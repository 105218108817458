import React from 'react';
import {
    AppState,
    ReactBaseComponent,
    MetaData,
    SC,
    Utils
} from '../../../../../importer';

import ToolbarIcons from '../../../../../styled/toolbar';

const IconFromType = (Type, GenericType) => {
    switch (Type) {
        case MetaData.Components.Layout.Type : return ToolbarIcons.Layout;        
        case MetaData.Components.Button.Type : return ToolbarIcons.Button;        
        case MetaData.Components.Div.Type : return ToolbarIcons.Container;
        case MetaData.Components.Content.Type : return ToolbarIcons.ExternalContent;
        case MetaData.Components.Repeater.Type : return ToolbarIcons.Repeater;
        case MetaData.Components.Component.Type : return ToolbarIcons.Component;
        case MetaData.Components.Image.Type : return ToolbarIcons.Image;
        case MetaData.Components.Input.Type : return ToolbarIcons.Input;
        case MetaData.Components.SvgIcon.Type : return ToolbarIcons.Icon;
        case MetaData.Components.Text.Type : return ToolbarIcons.Text;
        case MetaData.Components.Widget.Type : return ToolbarIcons.Widget;
        case MetaData.Components.Data.Type : return ToolbarIcons.Data;
        case MetaData.Components.Token.Type : return ToolbarIcons.Token;
        
        case MetaData.Components.Generic.Text.Type : return ToolbarIcons.Text;
        case MetaData.Components.Generic.Button.Type : return ToolbarIcons.Button;
        case MetaData.Components.Generic.Input.Type : return ToolbarIcons.Input;
        case MetaData.Components.Generic.Textarea.Type : return ToolbarIcons.Textarea;
        case MetaData.Components.Generic.DateInput.Type : return ToolbarIcons.DateInput;
        case MetaData.Components.Generic.Checkbox.Type : return ToolbarIcons.Checkbox;
        case MetaData.Components.Generic.Toggle.Type : return ToolbarIcons.Toggle;
        case MetaData.Components.Generic.RangeInput.Type : return ToolbarIcons.RangeInput;
        case MetaData.Components.Generic.ProgressBar.Type : return ToolbarIcons.ProgressBar;
        case MetaData.Components.Generic.RadioGroup.Type : return ToolbarIcons.SelectBox;
        case MetaData.Components.Generic.Dropdown.Type : return ToolbarIcons.Dropdown;
        case MetaData.Components.Generic.Select.Type : return ToolbarIcons.SelectBox;
        case MetaData.Components.Generic.Carousel.Type : return ToolbarIcons.Carousel;
        case MetaData.Components.Generic.Tab.Type : return ToolbarIcons.Tabs;
        case MetaData.Components.Generic.Accordion.Type : return ToolbarIcons.Accordion;
        case MetaData.Components.Generic.Table.Type : return ToolbarIcons.Table;

        case 'TokenValue' : return ToolbarIcons.TokenValue;
        case 'Variables' : return ToolbarIcons.VariableIcon;
    }
    return ToolbarIcons.Text;
}

export const Widget_Chart_Icons = {
    FromType : (props) => {
        return this.Line(props);
    },
    Line : (props) => {
        return (
            <SC.Svg  width={props.size || 24} viewBox='0 0 48 48'>
                <path fill='none' d='M0 0h48v48H0z' />
                <path fill='none' stroke='#707070' strokeWidth='2' d='M8 8v32h32' />
                <path fill='none' stroke='#707070' strokeWidth='3' d='M8 33l8-8 4.49 4.71 5.13-5.67L32 17l8 4.8'
                />
            </SC.Svg>
        )
    },
    LineArea : (props) => {
        return (
            <SC.Svg  width={props.size || 24} viewBox='0 0 48 48'>
                <path fill='none' d='M0 0h48v48H0z' />
                <path fill='none' stroke='#707070' strokeWidth='2' d='M8 8v32h32' />
                <path fill='#707070' d='M16.19 25.05l4.61 4.4L32.62 16.9 40 21.3V39H9v-6.68z' />
            </SC.Svg>
        )
    },
    Column : (props) => {
        return (
            <SC.Svg  width={props.size || 24} viewBox='0 0 48 48' style={{transform : 'rotate(90deg)'}}  >
                <g id='Group_6' data-name='Group 6' transform='translate(-51 -41)' fill='#707070'>
                    <path id='Rectangle_3' d='M0 0h5v12H0z' className='cls-2' data-name='Rectangle 3'
                    transform='translate(59 69)' />
                    <path id='Rectangle_4' d='M0 0h5v20H0z' className='cls-2' data-name='Rectangle 4'
                    transform='translate(67 61)' />
                    <path id='Rectangle_5' d='M0 0h5v32H0z' className='cls-2' data-name='Rectangle 5'
                    transform='translate(75 49)' />
                    <path id='Rectangle_6' d='M0 0h5v24H0z' className='cls-2' data-name='Rectangle 6'
                    transform='translate(83 57)' />
                </g>
            </SC.Svg>
        )
    },
    Bar : (props) => {
        return (
            <SC.Svg  width={props.size || 24} viewBox='0 0 48 48' >
                <g id='Group_6' data-name='Group 6' transform='translate(-51 -41)' fill='#707070'>
                    <path id='Rectangle_3' d='M0 0h5v12H0z' className='cls-2' data-name='Rectangle 3'
                    transform='translate(59 69)' />
                    <path id='Rectangle_4' d='M0 0h5v20H0z' className='cls-2' data-name='Rectangle 4'
                    transform='translate(67 61)' />
                    <path id='Rectangle_5' d='M0 0h5v32H0z' className='cls-2' data-name='Rectangle 5'
                    transform='translate(75 49)' />
                    <path id='Rectangle_6' d='M0 0h5v24H0z' className='cls-2' data-name='Rectangle 6'
                    transform='translate(83 57)' />
                </g>
            </SC.Svg>
        )
    },
    Bubble : (props) => {
        return (
            <SC.Svg  width={props.size || 24} viewBox='0 0 48 48'>
                <g id='Group_7' data-name='Group 7' transform='translate(-49 -39)' fill='#707070'>                    
                    <circle id='Ellipse_1' cx='6' cy='6' r='6' className='cls-2' data-name='Ellipse 1'
                    transform='translate(77 63)' />
                    <circle id='Ellipse_2' cx='4' cy='4' r='4' className='cls-2' data-name='Ellipse 2'
                    transform='translate(69 50)' />
                    <circle id='Ellipse_4' cx='5' cy='5' r='5' className='cls-2' data-name='Ellipse 4'
                    transform='translate(57 71)' />
                    <circle id='Ellipse_3' cx='2.5' cy='2.5' r='2.5' className='cls-2' data-name='Ellipse 3'
                    transform='translate(81 55)' />
                    <circle id='Ellipse_5' cx='3' cy='3' r='3' className='cls-2' data-name='Ellipse 5'
                    transform='translate(65 63)' />
                </g>
            </SC.Svg>
        )
    },
    Scatter : (props) => {
        return (
            <SC.Svg  width={props.size || 24} viewBox='0 0 48 48'>
                 <g transform="translate(-49 -39)" stroke="#707070" fill="#707070">                    
                    <circle cx="2" cy="2" r="2" transform="translate(77 69)"/>
                    <circle cx="2" cy="2" r="2" transform="translate(69 65)"/>
                    <circle cx="2.5" cy="2.5" r="2.5" transform="translate(63 69)"/>
                    <circle cx="2" cy="2" r="2" transform="translate(73 57)"/>
                    <circle cx="1.5" cy="1.5" r="1.5" transform="translate(62 61)"/>
                    <circle cx="1.5" cy="1.5" r="1.5" transform="translate(78 61)"/>
                    <circle cx="1.5" cy="1.5" r="1.5" transform="translate(83 64)"/>
                    <circle cx="1.5" cy="1.5" r="1.5" transform="translate(81 53)"/>
                    <circle cx="1.5" cy="1.5" r="1.5" transform="translate(65 53)"/>
                    <path fill="none" stroke="#707070" stroke-width="2" d="M57 47v32h32"/>
                </g>
            </SC.Svg>
        )
    },
    Heatmap : (props) => {
        return (
            <SC.Svg  width={props.size || 24} viewBox='0 0 48 48'>
                 <defs>
                    <clipPath id="clip-path">
                    <path d="M0 0h35v24H0z" class="cls-1"/>
                    </clipPath>
                </defs>
                <g id="Group_20" data-name="Group 20" transform="translate(-624 -328)">
                    <g id="Repeat_Grid_1" clipPath="url(#clip-path)" data-name="Repeat Grid 1" transform="translate(632 339)">
                    <g transform="translate(-632 -339)">
                        <path id="Path_24" d="M0 0h8v5H0z" fill="#848484" data-name="Path 24" transform="translate(632 339)"/>
                        <path id="Path_24-2" d="M0 0h8v5H0z" fill="#6e6e6e" data-name="Path 24" transform="translate(641 339)"/>
                        <path id="Path_24-3" d="M0 0h8v5H0z" fill="#5E5E5E" data-name="Path 24" transform="translate(650 339)"/>
                        <path id="Path_24-4" d="M0 0h8v5H0z" fill="#848484" data-name="Path 24" transform="translate(659 339)"/>
                        <path id="Path_24-5" d="M0 0h8v5H0z" fill="#b5b1b1" data-name="Path 24" transform="translate(632 345)"/>
                        <path id="Path_24-6" d="M0 0h8v5H0z" fill="#848484" data-name="Path 24" transform="translate(641 345)"/>
                        <path id="Path_24-7" d="M0 0h8v5H0z" fill="#b5b1b1" data-name="Path 24" transform="translate(650 345)"/>
                        <path id="Path_24-8" d="M0 0h8v5H0z" fill="#5E5E5E" data-name="Path 24" transform="translate(659 345)"/>
                        <path id="Path_24-9" d="M0 0h8v5H0z" fill="#5E5E5E" data-name="Path 24" transform="translate(632 351)"/>
                        <path id="Path_24-10" d="M0 0h8v5H0z" fill="#6e6e6e" data-name="Path 24" transform="translate(641 351)"/>
                        <path id="Path_24-11" d="M0 0h8v5H0z" fill="#848484" data-name="Path 24" transform="translate(650 351)"/>
                        <path id="Path_24-12" fill="#5e5e5e" d="M0 0h8v5H0z" data-name="Path 24" transform="translate(659 351)"/>
                        <path id="Path_24-13" d="M0 0h8v5H0z" fill="#848484" data-name="Path 24" transform="translate(632 357)"/>
                        <path id="Path_24-14" d="M0 0h8v5H0z" fill="#848484" data-name="Path 24" transform="translate(641 357)"/>
                        <path id="Path_24-15" d="M0 0h8v5H0z" fill="#5E5E5E" data-name="Path 24" transform="translate(650 357)"/>
                        <path id="Path_24-16" d="M0 0h8v5H0z" fill="#848484" data-name="Path 24" transform="translate(659 357)"/>
                        <path id="Path_24-17" d="M0 0h8v5H0z" fill="#848484" data-name="Path 24" transform="translate(632 363)"/>
                        <path id="Path_24-18" d="M0 0h8v5H0z" fill="#5E5E5E" data-name="Path 24" transform="translate(641 363)"/>
                        <path id="Path_24-19" d="M0 0h8v5H0z" fill="#848484" data-name="Path 24" transform="translate(650 363)"/>
                        <path id="Path_24-20" d="M0 0h8v5H0z" fill="#b5b1b1" data-name="Path 24" transform="translate(659 363)"/>
                    </g>
                    </g>
                </g>
            </SC.Svg>
        )
    },
    Sunburst : (props) => {
        return (
            <SC.Svg  width={props.size || 24} viewBox='0 0 48 48'>
                <g id="Group_22" data-name="Group 22" transform="translate(-32 -32)">
                    <g id="Group_21" data-name="Group 21" transform="translate(-216 -86)">                    
                        <g id="Group_10" data-name="Group 10" transform="translate(255 125)">
                            <path id="Path_15" d="M15.95 6.77V2a15.59 15.59 0 0 0 0 31v-4.77c-4.65-.75-9.3-5.27-9.3-10.73s4.65-9.98 9.3-10.73zm12.35 9.18H33A14.93 14.93 0 0 0 19.05 2v4.77a11.1 11.1 0 0 1 9.25 9.18zm-9.25 12.28V33A14.93 14.93 0 0 0 33 19.05h-4.7a11.1 11.1 0 0 1-9.25 9.18z" fill="#707070" data-name="Path 15"/>
                            <path id="Path_16" d="M22.25 15.95h4.7A14.93 14.93 0 0 0 13 2v4.77a11.1 11.1 0 0 1 9.25 9.18z" fill="#b0b0b0" data-name="Path 16" transform="translate(6.05)"/>
                            <path id="Path_17" d="M13 22.18v4.77A14.93 14.93 0 0 0 26.95 13h-4.7A11.1 11.1 0 0 1 13 22.18z" fill="#eaeaea" data-name="Path 17" transform="translate(6.05 6.05)"/>
                        </g>
                        <g id="Group_10-2" data-name="Group 10" transform="rotate(129 111.75 139.52)">
                            <path id="Path_15-2" d="M11.7 5.3V2a10.76 10.76 0 0 0 0 21.41v-3.3a7.9 7.9 0 0 1-6.46-7.4A7.9 7.9 0 0 1 11.7 5.3zm8.59 6.34h3.26A10.34 10.34 0 0 0 13.85 2v3.3a7.7 7.7 0 0 1 6.43 6.34zm-6.44 8.48v3.3a10.34 10.34 0 0 0 9.7-9.64h-3.27a7.7 7.7 0 0 1-6.43 6.33z" fill="#707070" data-name="Path 15"/>
                            <path id="Path_16-2" d="M19.43 11.64h3.27A10.34 10.34 0 0 0 13 2v3.3a7.7 7.7 0 0 1 6.43 6.34z" fill="#b0b0b0" data-name="Path 16" transform="translate(.85)"/>
                            <path id="Path_17-2" d="M13 19.34v3.3A10.34 10.34 0 0 0 22.7 13h-3.27A7.7 7.7 0 0 1 13 19.34z" fill="#eaeaea" data-name="Path 17" transform="translate(.85 .78)"/>
                        </g>
                        <g id="Group_10-3" data-name="Group 10" transform="rotate(-81 218.88 -78.03)">
                            <path id="Path_15-3" d="M8.73 4.31V2a7.55 7.55 0 0 0 0 15.02V14.7a5.52 5.52 0 0 1-4.49-5.2 5.52 5.52 0 0 1 4.49-5.19zm5.95 4.45h2.27A7.21 7.21 0 0 0 10.22 2v2.31a5.37 5.37 0 0 1 4.46 4.45zm-4.46 5.94v2.31a7.21 7.21 0 0 0 6.73-6.75h-2.27a5.37 5.37 0 0 1-4.46 4.44z" fill="#707070" data-name="Path 15"/>
                            <path id="Path_16-3" d="M17.46 8.76h2.27A7.21 7.21 0 0 0 13 2v2.31a5.37 5.37 0 0 1 4.46 4.45z" fill="#b0b0b0" data-name="Path 16" transform="translate(-2.78)"/>
                            <path id="Path_17-3" d="M13 17.45v2.3A7.21 7.21 0 0 0 19.73 13h-2.27A5.37 5.37 0 0 1 13 17.45z" fill="#eaeaea" data-name="Path 17" transform="translate(-2.78 -2.74)"/>
                        </g>
                    </g>
                    <g id="Ellipse_10" fill="#eaeaea" stroke="#707070" data-name="Ellipse 10" transform="translate(53 53)">
                    <circle cx="3.5" cy="3.5" r="3.5" stroke="none"/>
                    <circle cx="3.5" cy="3.5" r="3" fill="none"/>
                    </g>
                </g>
            </SC.Svg>
        )
    },
    Pie : (props) => {
        return (
            <SC.Svg  width={props.size || 24} viewBox='0 0 48 48'>
                <path fill='#707070' d='M22.12 8.17v31a15.59 15.59 0 0 1 0-31zm3.15 0V22.1h13.9a15.57 15.57 0 0 0-13.9-13.93zm0 17.06v13.94a15.55 15.55 0 0 0 13.9-13.94z'
                    data-name='Path 7' />
            </SC.Svg>
        )
    },
    Doughnut : (props) => {
        return (
            <SC.Svg  width={props.size || 24} viewBox='0 0 48 48'>
                <g data-name='Group 10'>
                    <path fill='#707070' d='M22.95 13.77V9a15.59 15.59 0 0 0 0 31v-4.77c-4.65-.75-9.3-5.27-9.3-10.73s4.65-9.98 9.3-10.73zm12.35 9.18H40A14.93 14.93 0 0 0 26.05 9v4.77a11.1 11.1 0 0 1 9.25 9.18zm-9.25 12.28V40A14.93 14.93 0 0 0 40 26.05h-4.7a11.1 11.1 0 0 1-9.25 9.18z'
                    data-name='Path 15' />
                    <path fill='#b0b0b0' d='M35.3 22.95H40A14.93 14.93 0 0 0 26.05 9v4.77a11.1 11.1 0 0 1 9.25 9.18z'
                    data-name='Path 16' />
                    <path fill='#eaeaea' d='M26.05 35.23V40A14.93 14.93 0 0 0 40 26.05h-4.7a11.1 11.1 0 0 1-9.25 9.18z'
                    data-name='Path 17' />
                </g>
            </SC.Svg>
        )
    },
    Funnel : (props) => {
        return (
            <SC.Svg  width={props.size || 24} viewBox='0 0 48 48'>
                <path fill='#e0e0e0' d='M8 11.45h32l-3 6.05H11z' data-name='Path 11' />
                <path fill='#acacac' d='M10.98 17.45H37l-2.94 6.05-20.09-.02z' data-name='Path 12' />
                <path fill='#7d7d7d' d='M13.96 23.45h20.1l-2.84 6.05H16.78z' data-name='Path 13' />
                <path fill='#555' d='M16.76 29.45h14.46L28.1 35.5h-8.44z' data-name='Path 14' />
            </SC.Svg>
        )
    },
    Gauge : (props) => {
        return (
            <SC.Svg  width={props.size || 24} viewBox='0 0 48 48'>
                  <g data-name='Group 6'>
                    <g data-name='Group 17'>
                        <g data-name='Group 14'>
                            <path fill='#b0b0b0' d='M30.36 18.95l3.38-3.08A15.63 15.63 0 0 0 14 15.55l3.32 3.23a11.67 11.67 0 0 1 13.03.17z'
                            data-name='Path 16' />
                            <path fill='#707070' d='M15.1 20.8l-3.32-3.23c-5.07 5.56-5.2 12.85-.34 18.57l3.38-3.07a9.96 9.96 0 0 1 .28-12.27z'
                            data-name='Path 18' />
                            <path fill='#eaeaea' d='M32.23 33.3l3.32 3.24c5.06-5.56 5.2-12.85.34-18.57l-3.38 3.07a9.96 9.96 0 0 1-.28 12.27z'
                            data-name='Path 17' />
                        </g>
                        <path fill='#ddd' stroke='#6d6d6d' d='M26.56 31.45l.19.65.38 1.34.24.87-3.48 2.62-3.52-2.62 3.52-11.49 2.54 8.2.32 1.09'
                        data-name='Path 19' />
                    </g>
                </g>
            </SC.Svg>
        )
    },
    Video : (props) => {
        return (
            <SC.Svg  width={props.size || 24} viewBox='0 0 48 48'>
                  <g data-name='Group 18'>
                    <g data-name='Group 9'>
                        <g data-name='Group 6'>
                            <path fill='none' d='M0 0h48v48H0z' data-name='Rectangle 2' />
                        </g>
                    </g>
                    <g data-name='baseline-crop_landscape-24px (1)'>
                        <path fill='transparent' d='M0 0h48v48H0z' data-name='Path 20' />
                        <path fill='#707070' d='M38 10H10a4.01 4.01 0 0 0-4 4v20a4.01 4.01 0 0 0 4 4h28a4.01 4.01 0 0 0 4-4V14a4.01 4.01 0 0 0-4-4zm0 24H10V14h28z'
                        data-name='Path 21' />
                    </g>
                    <path fill='#707070' stroke='#707070' d='M21.18 19v9.8l8.48-4.83z' data-name='Path 22'
                    />
                </g>
            </SC.Svg>
        )
    }
};


export const WidgetIcon = (Type, size) => {
    if (Type === MetaData.Components.Widget.WidgetTypes.Video.Type) {
        return <ToolbarIcons.Video size={size} />
    }
    else if (Type === MetaData.Components.Widget.WidgetTypes.Spotify.Type) {
        return (
            <SC.Svg  width={size || 24} viewBox="0 0 126.68 126.61">
                <path fill='#707070' fillRule="evenodd" d="M100.8 56.12C80.4 44 46.7 42.9 27.23 48.8a5.92 5.92 0 1 1-3.44-11.33c22.38-6.79 59.57-5.48 83.07 8.47a5.92 5.92 0 1 1-6.04 10.18zm-.66 17.95a4.94 4.94 0 0 1-6.8 1.63c-17.02-10.46-42.97-13.49-63.1-7.38a4.94 4.94 0 1 1-2.88-9.44c23-6.98 51.6-3.6 71.15 8.4a4.93 4.93 0 0 1 1.63 6.8zm-7.75 17.24a3.95 3.95 0 0 1-5.43 1.31c-14.87-9.09-33.6-11.14-55.64-6.1a3.94 3.94 0 1 1-1.76-7.69c24.13-5.51 44.82-3.14 61.52 7.06a3.94 3.94 0 0 1 1.3 5.42zM63.34 0a63.3 63.3 0 1 0 63.34 63.3A63.32 63.32 0 0 0 63.34 0z"/>
            </SC.Svg>
         );
    }
    else if (Type === MetaData.Components.Widget.WidgetTypes.MapBox.Type) {
         return (
            <SC.Svg  width={size || 24} viewBox='0 0 24 24'>
                <path fill='#707070' d='M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5a2.5 2.5 0 0 1 0-5 2.5 2.5 0 0 1 0 5z' />
                <path fill='none' d='M0 0h24v24H0z' />
            </SC.Svg>
         );
    }
    else if (Type === MetaData.Components.Widget.WidgetTypes.Lottie.Type) {
        return (
            <ToolbarIcons.Lottie size={size} />
        );
   }
    else if (Type === MetaData.Components.Widget.WidgetTypes.Barcode.Type) {
        return (
            <SC.Svg  width={size || 24} viewBox="0 0 100 70">
                <path fill="#707070" d="M0 0h12.5v56.875H0zm18.75 0H25v56.875h-6.25zm12.5 0h6.25v56.875h-6.25zM62.5 0h6.25v56.875H62.5zM75 0h6.25v56.875H75zm12.5 0H100v56.875H87.5zM43.75 0h12.5v56.875h-12.5zM0 61.25h12.5V70H0zm62.5 0H75V70H62.5zm-43.75 0H25V70h-6.25zm62.5 0h6.25V70h-6.25zm12.5 0H100V70h-6.25zm-62.5 0h25V70h-25z" />                
            </SC.Svg>
        );
   }
}

export default IconFromType;