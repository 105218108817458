import * as SC from '../../../../styled';
import { Utils } from '../../../../toolabs-importer';
import Globals from '../../../../appstate/Globals';

export const Devices = {
    Default  :{
        Id : 'Default',
        full : true,
        Default : {
            width : 1920,
            height : 800
        }
    },
    Large : {
        Id : 'Large',
        Default : {
            width : 1440,
            height : 800
        }
    },
    Medium : {
        Id : 'Medium',
        Default : {
            width : 1024,
            height : 800
        }
    },
    Small : {
        Id : 'Small',
        Default : {
            width : 768,
            height : 800
        }
    },
    xSmall : {
        Id : 'xSmall',
        Default : {
            width : 425,
            height : 800
        },
        Preests : [
            { id : 'iPhone X', w : 375, h : 812},
            { id : 'iPhone 6/7/8 Plus', w : 414, h : 736},
            { id : 'iPhone 6/7/8', w : 375, h : 667},
            { id : 'iPhone 5', w : 320, h : 568}
        ]
    }
}

export const DEVICE_BUTTONS = [
    {Id : Devices.xSmall.Id, IconType : SC.Icons.Icon_Device_xSmall, padding : '2px', paddingTop : '2px'},
    {Id : Devices.Small.Id, IconType : SC.Icons.Icon_Device_Small, padding : '2px', paddingTop : '1px'},
    {Id : Devices.Medium.Id, IconType : SC.Icons.Icon_Device_Medium, padding : '4px', paddingTop : 0},
    {Id : Devices.Large.Id, IconType : SC.Icons.Icon_Device_Large, padding : '4px', paddingTop : 0},
    {Id : Devices.Default.Id, IconType : SC.Icons.Icon_Device_All, padding : '4px', paddingTop : 0}                
];

export const GetDeviceIcon =  (deviceType) => {
    const device = Utils.Find(DEVICE_BUTTONS, (button) => {return button.Id === deviceType});
    if (device)
        return device.IconType;
    return SC.Icons.Icon_Device_All;
}

export const GetDeviceType = (width) => {    
    return Globals.ProjectManager.States.GetBreakpointDevice(width);

    if (width <= Devices.xSmall.Default.width)
        return Devices.xSmall.Id;
    else if (width <= Devices.Small.Default.width)
        return Devices.Small.Id;
    else if (width <= Devices.Medium.Default.width)
        return Devices.Medium.Id;
    else if (width <= Devices.Large.Default.width)
        return Devices.Large.Id;    
        
    return Devices.Default.Id;
};

const DefaultDevices = Devices;

export const SetNewDeviceWidth = ({Devices, index, DeviceModel}) => {
    if ((index) >= 0 && (index) < Devices.length) {
        if (Devices[index].responsive) {
            const CurrentDeviceType = GetDeviceType(Devices[index].width);
            if (CurrentDeviceType === DefaultDevices.xSmall.Id)
                DeviceModel.width = DefaultDevices.Small.Default.width;
            else if (CurrentDeviceType === DefaultDevices.Small.Id)
                DeviceModel.width = DefaultDevices.Medium.Default.width;
            else if (CurrentDeviceType === DefaultDevices.Medium.Id)
                DeviceModel.width = DefaultDevices.Large.Default.width;
            DeviceModel.responsive = true;
        }                        
        else {
            DeviceModel.width = Devices[index].width;
        }
    }  
}