import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../../importer';
import TextInput from '../../../../components/editors/textinput';
import TextValue from '../../../../components/editors/textvalue';
import CodeViewer from '../../common/codeviewer';
import DropDownSelect from '../../../../components/editors/enum_dropdown';
import Chroma from 'chroma-js';
import Handlebars  from 'handlebars';
import Clipboard from 'clipboard';

import { PropertyLabel, Seperator, StyleGroupTokenItem, ToggleButton } from '../../panels/right/iteminspector/styleitems/common';
import Switch from '../../../../components/editors/Switch';
import { GroupTitle, PanelLabel, TokenItemBox } from '../../panels/left/designsystem/common';
import { InfoPanel } from '../../../../components/info';
import ExportTokensSelection from './tokenselection';
import { RightInnerPanelContainer } from '../../documents/designer/rightpanel';
import ExportTemplateSchameViewer from './templateSchemaViewer';
import { DraggableEditorBox, DraggableEditorHandle } from '../../../../components/editors/transform/transformItemEditor';
import SystemStatePanel from '../../panels/left/systemstates';
import CusotmHandlerbarsBlock from './handlerbarCustomBlocks';
import { GetTokenTypeTokenList } from '../../panels/left/designsystem/custom/definitions';

const TokenTypes = Globals.ProjectManager.Tokens.Types;

export default class TokenExportGroup extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.ChangeTokenType = this.ChangeTokenType.bind(this);        
        this.ChangeBlockType = this.ChangeBlockType.bind(this);        
        this.SetSpecificState = this.SetSpecificState.bind(this);
        this.UpdateCode = this.UpdateCode.bind(this);        

        this.RenderId = this.props.RenderId;

        const tokenids = Globals.ProjectManager.Tokens.FigmaLayouts();
        if (tokenids && Array.isArray(tokenids) && tokenids.length > 0)
            this.hasFigmaAutoLayouts = true;
    }

    
    ChangeBlockType(type) {
        if (this.props.groupData.blockType !== type) {
            const oldType = this.props.groupData.blockType;
            this.props.groupData.blockType = type;
            let data;
            if (type === 'tokens') {
                data = {
                    fileName : this.props.groupData.fileName,
                    prefix : this.props.groupData.prefix,
                    blockType : type,
                    tokenType : TokenTypes.COLOR
                };
            }
            else if (type === 'code') {
                data = {
                    blockType : type,
                    code : ''
                }
            }
            Globals.ProjectManager.SetExportDefinitionGroupProp(this.props.id, this.props.groupId, data);
            this.UpdateCode();
            this.RCUpdate();
        }                
    }
    ChangeTokenType(type) {
        this.props.groupData.tokenType = type;
        Globals.ProjectManager.SetExportDefinitionGroupProp(this.props.id, this.props.groupId, {
            fileName : this.props.groupData.fileName,
            prefix : this.props.groupData.prefix,
            tokenType : type
        });
        this.UpdateCode();
        this.RCUpdate();
    }
    onChangeOption(prop, value) {
        Globals.ProjectManager.SetExportDefinitionGroupProp(this.props.id, this.props.groupId, value, prop);
        this.UpdateCode();
        this.RCUpdate();
    }
    onSaveHandleBarBlocks(blocks) {
        this.onChangeOption('handlebarBlocks', blocks);
    }
    SetSpecificState(stateId, variationId) {
        Globals.ProjectManager.SetExportDefinitionGroupProp(this.props.id, this.props.groupId, variationId, 'states', stateId);
        this.UpdateCode();
        this.RCUpdate();
    }
    UpdateCode() {
        // const {codeId} = this.props.GenerateCode(this.props.id);
        this.props.onUpdateCodeView(this.props.id);
    }
    onUseCustomTemplate(value) {
        Globals.ProjectManager.SetExportDefinitionGroupProp(this.props.id, this.props.groupId, value, 'customTemplate');        
        if (value) {
            this.setState({willShowCustomTemplate : true})
        }
        else {
            this.RCUpdate();
        }
        this.UpdateCode();
    }
    componentDidUpdate(prevProps, prevState) {
        if (!prevState.willShowTokenSelection && this.state.willShowTokenSelection) {
            const DN = ReactDOM.findDOMNode(this);
            this.props.onScrollTo(DN.getBoundingClientRect().top);            
        }
    }
    renderCustom() {      
        let content, blockContent, subBlockType, codeBlock, customTokenTypeBlock;
        let customTemplate;
        let tokenOptions = this.props.groupData;
        // const tokenOptions = Utils.Get(this.props.options, {}, 'tokens', this.props.type);
        const TokenCode = this.props.GetTokenCode(this.props.groupId);
        this.isActive = !tokenOptions.disabled;

        let selectionItem, themeSelection;

        let noContent = false;

        if (tokenOptions.blockType === 'code') {
            subBlockType = ( <div /> );


            blockContent = (
                <div style={{gridColumnStart : 'span 2', display : 'grid', gridTemplateColumns : '1fr auto'}}>
                    <div style={{
                        display : 'grid',
                        gridTemplateColumns : 'auto 160px',
                        alignItems : 'baseline',
                        gridGap : '8px',
                        alignContent : 'start',
                        gridColumnGap : '16px',
                    }}>
                        
                    </div>
                    <div style={{
                        display : 'grid',
                        gridTemplateColumns : 'auto 160px',
                        alignItems : 'center',
                        gridGap : '8px',
                        alignContent : 'start',
                        gridColumnGap : '16px',
                        marginBottom : TokenCode.isEmpty ? '8px' : 0,
                        gridColumnStart : TokenCode.isEmpty ? 'span 2' : 'unset'
                    }}>
                        <PanelLabel>File Name</PanelLabel>
                        <TextInput 
                            dark
                            value={Utils.JustGet(tokenOptions, Utils.Capitalize(this.props.title), 'fileName')}
                            onChange={this.onChangeOption.bind(this, 'fileName')}
                            style={{minHeight : '30px'}}
                            themeId={this.props.themeId}
                        />
                    </div>
                    
                    {/* <div
                        style={{
                            display : 'grid',
                            gridTemplateColumns : 'auto minMax(300px, 1fr)',
                            gridColumnGap  :'16px',
                            gridRowGap : '8px',
                            marginBottom : '8px',
                            alignItems : 'stretch',
                            marginTop : '16px',
                        }}
                    >
                        <div>DATA SCHEMA</div>
                        <div>TEMPLATE STRING</div>                               
                        <ExportTemplateSchameViewer 
                            schema={{}}
                        />
                        <TextValue 
                            key={this.props.RenderId}
                            dark
                            justEditor
                            multiline 
                            value={tokenOptions.code}
                            boxStyle={{height : '100%'}}
                            style={{color : SC.CurrentTheme.theme.font, fontSize : '13px', lineHeight : '22px', letterSpacing : '0.1em', height : '100%'}}
                            onBlured={this.onChangeOption.bind(this, 'code')}
                        />
                    </div> */}
                </div>
            )

            
            const style = {
                color : SC.CurrentTheme.theme.font, 
                fontSize : '13px', 
                lineHeight : '22px', 
                letterSpacing : '0.1em', 
                height : '100%', 
                backgroundColor : SC.CurrentTheme.theme.back_code,
                border : 'none'
            };
            if (SC.CurrentTheme.theme.isLight) {
                style.backgroundColor = '#E0E0E0';
            }            

            codeBlock = (
                <TextValue 
                    key={this.props.RenderId}
                    dark
                    justEditor
                    multiline 
                    value={tokenOptions.code}
                    boxStyle={{height : '100%', backgroundColor : 'unset', border : 'none'}}
                    style={style}
                    placeholder='Write custom code here'
                    onBlured={this.onChangeOption.bind(this, 'code')}
                />
            )
            
            // const style1 = {
            //     flex : 'unset',
            //     borderBottom : SC.CurrentTheme.theme.border_seperator
            // };
            // const codeStyle = {
            //     padding : '16px',
            // }
            // if (SC.CurrentTheme.theme.isLight) {
            //     codeStyle.filter = 'invert(1)';
            // }

            // codeBlock = (
            //     <div style={{
            //         overflow : 'hidden',
            //         position : 'relative'
            //     }}>
            //         <SC.AbsoluteOverlay>
            //             <CopyExportTokenCodeBox 
            //                 codeClassName={this.props.codeClassName}
            //                 exportedCode={tokenOptions.code}
            //                 style={style1}
            //                 codeStyle={codeStyle}
            //                 boxStyle={{flex : 'unset', height : '100%'}}
            //             />
            //         </SC.AbsoluteOverlay>
            //     </div>
            // )
        }
        else {
            subBlockType = (
                <DropDownSelect 
                    items={[
                        {id : Globals.ProjectManager.Tokens.Types.COLOR, label : 'Colors'},
                        {id : Globals.ProjectManager.Tokens.Types.Gradients, label : 'Gradients'},
                        {id : Globals.ProjectManager.Tokens.Types.Shadows, label : 'Shadows'},
                        {id : Globals.ProjectManager.Tokens.Types.Fonts, label : 'Typography'},                            
                        {id : Globals.ProjectManager.Tokens.Types.SpacePatterns, label : 'Spacing'},
                        {id : Globals.ProjectManager.Tokens.Types.FigmaAutoLayouts, label : 'Auto Layouts', hiddenInPopup : !this.hasFigmaAutoLayouts},
                        {id : Globals.ProjectManager.Tokens.Types.Borders, label : 'Borders'},
                        {id : Globals.ProjectManager.Tokens.Types.BorderRadiuses, label : 'Border Radii'},
                        {id : Globals.ProjectManager.Tokens.Types.Icons, label : 'Iconography'},
                        {id : Globals.ProjectManager.Tokens.Types.Images, label : 'Images'},
                        {id : Globals.ProjectManager.Tokens.Types.Motion, label : 'Motion'},
                        {id : Globals.ProjectManager.Tokens.Types.Transforms, label : 'Transforms'},
                        {id : Globals.ProjectManager.Tokens.Types.Filters, label : 'Filters'},
                        {id : Globals.ProjectManager.Tokens.Types.Sounds, label : 'Sounds'},
                        {id : Globals.ProjectManager.Tokens.Types.Booleans, label : 'Booleans'},
                        {id : Globals.ProjectManager.Tokens.Types.ContentTexts, label : 'Microcopy (UX Texts)'},                        
                        {id : Globals.ProjectManager.Tokens.Types.CustomTokenTypes, label : 'Custom Tokens'},   
                    ]}
                    style={{fontSize : '12px'}}
                    value={tokenOptions.tokenType}
                    onChange={this.ChangeTokenType}
                    hasBorder            
                    autoHeight
                    fitWidth
                    right='unset'
                    popupItemStyle={{maxHeight : '28px', minHeight : '28px'}}
                />                      
            )

            if (tokenOptions.tokenType === Globals.ProjectManager.Tokens.Types.CustomTokenTypes) {
                const customTokenTypes = GetTokenTypeTokenList();
                const customTokenTypeItems = [];
                Utils.ForEach(customTokenTypes, (customTokenType, i) => {
                    customTokenTypeItems.push({
                        id : customTokenType.id,
                        label : customTokenType.name
                    });
                });
                customTokenTypeBlock = (
                    <DropDownSelect 
                        items={customTokenTypeItems}
                        style={{fontSize : '12px'}}
                        value={tokenOptions.customTokenType}
                        onChange={this.onChangeOption.bind(this, 'customTokenType')}
                        hasBorder            
                        autoHeight
                        fitWidth
                        placeholder='Select Token Type'
                        right='unset'
                        popupItemStyle={{maxHeight : '28px', minHeight : '28px'}}
                    /> 
                )
            }
            if (TokenCode.isEmpty && this.isActive) {
                noContent = true;
            }
            else {
                
                const isCustomTempalte = this.props.target === 'custom' ||  Utils.JustGet(tokenOptions, false, 'customTemplate');
                let typeItems ;                
        
                if (this.state.willShowTokenSelection) {
                    selectionItem = (
                        <div style={{
                            gridColumnStart : 'span 2',
                            paddingTop : '8px',
                            minWidth : '300px'
                        }}>
                            <RightInnerPanelContainer 
                                expanded
                                style={{
                                    position : 'relative', top : 'unset', right : 'unset',
                                    minHeight : '70vh', width : 'unset',
                                    zIndex : 99999
                                }}
                            >
                                <SC.FCol 
                                    f1
                                    style={{                                 
                                        boxSizing : 'border-box',
                                        backgroundColor : SC.CurrentTheme.theme.back,
                                    }}
                                >
                                    <ExportTokensSelection 
                                        id={this.props.id}
                                        groupId={this.props.groupId}
                                        tokenType={this.props.type}
                                        subType={this.state.willShowTokenSelection.subType}
                                        options={this.state.willShowTokenSelection.subType ? Utils.Get(tokenOptions, {}, this.state.willShowTokenSelection.subType) : tokenOptions}
                                        onClose={() => this.setState({willShowTokenSelection : false})}
                                        onUpdateCode={this.UpdateCode}
                                    />
                                </SC.FCol>                        
                            </RightInnerPanelContainer>
                        </div>
                    )
                }
                else {
                    
                    const selection = Utils.JustGet(tokenOptions, 'all', 'type');
                    let selectionLabel = 'All Tokens';
                    if (selection === 'selected')
                        selectionLabel = 'Selected Tokens';
                    else if (selection === 'groups')
                        selectionLabel = 'Selected Groups';
                    else if (selection === 'aliases')
                        selectionLabel = 'All Aliase Tokens';
                    else if (selection === 'selectedaliases')
                        selectionLabel = 'Selected Aliases';
                    else if (selection === 'aliaseGroups')
                        selectionLabel = 'Selected Aliase Groups';
    
                    if (this.props.type === TokenTypes.Motion) {
                        const hasDurations = !Utils.Get(tokenOptions, false, 'durations', 'disabled');
                        const hasEase = !Utils.Get(tokenOptions, false, 'ease', 'disabled');
                        const hasTransitions = !Utils.Get(tokenOptions, false, 'transitions', 'disabled');
                        selectionItem = (
                            <React.Fragment>
                                <SC.FRow alc style={{gridColumnStart : 'span 2'}}>
                                    <Switch checkEdit value={hasDurations} onChange={(value) => {
                                        Globals.ProjectManager.SetExportDefinitionGroupProp(this.props.id, this.props.groupId, !value, 'durations', 'disabled');
                                        this.UpdateCode();
                                        this.RCUpdate();
                                    }} />
                                    <ExportTokenSelectionOption disabled={!hasDurations} title='Durations' tokenOptions={Utils.Get(tokenOptions, {}, 'durations')} onSelect={() => this.setState({willShowTokenSelection : {subType : 'durations'}})} style={{flex : 1, marginLeft : '8px'}} />
                                </SC.FRow>
                                <SC.FRow alc style={{gridColumnStart : 'span 2'}}>
                                    <Switch checkEdit value={!Utils.Get(tokenOptions, false, 'ease', 'disabled')} onChange={(value) => {
                                        Globals.ProjectManager.SetExportDefinitionGroupProp(this.props.id, this.props.groupId, !value, 'ease', 'disabled');
                                        this.UpdateCode();
                                        this.RCUpdate();
                                    }} />
                                    <ExportTokenSelectionOption disabled={!hasEase} title='Ease Curves' tokenOptions={Utils.Get(tokenOptions, {}, 'ease')} onSelect={() => this.setState({willShowTokenSelection : {subType : 'ease'}})}  style={{flex : 1, marginLeft : '8px'}}/>
                                </SC.FRow>
                                <SC.FRow alc style={{gridColumnStart : 'span 2'}}>
                                    <Switch checkEdit value={!Utils.Get(tokenOptions, false, 'transitions', 'disabled')} onChange={(value) => {
                                        Globals.ProjectManager.SetExportDefinitionGroupProp(this.props.id, this.props.groupId, !value, 'transitions', 'disabled');
                                        this.UpdateCode();
                                        this.RCUpdate();
                                    }} />
                                    <ExportTokenSelectionOption disabled={!hasTransitions} title='Transitions' tokenOptions={Utils.Get(tokenOptions, {}, 'transitions')} onSelect={() => this.setState({willShowTokenSelection : {subType : 'transitions'}})}  style={{flex : 1, marginLeft : '8px'}} />
                                </SC.FRow>                                                                                                
                            </React.Fragment>   
                        )
                    }
                    else {
                        selectionItem = (
                            <ExportTokenSelectionOption title='Will Export' tokenOptions={tokenOptions} onSelect={() => this.setState({willShowTokenSelection : {}})} />
                        )
                    }
                    
                }
    
    
                let themeContent;
                const themeOption = Utils.JustGet(tokenOptions, 'active', 'theme');

                if (themeOption === 'specific') {
                    const themeStates = Utils.JustGet(tokenOptions, {}, 'states');

                    themeContent = (
                        <SystemStatePanel 
                            boxStyle={{gridColumnStart : 'span 2'}}
                            contentStyle={{margin : 0}}
                            justContent
                            onSetStateVariation={this.SetSpecificState}
                            currentVariations={themeStates}
                        />
                    )
                }

                themeSelection = (
                    <React.Fragment>
                        <PanelLabel>Theme</PanelLabel>
                        <DropDownSelect 
                            items={[
                                {id : 'active', label : 'Active Theme'},
                                {id : 'specific', label : 'Specific Theme'},                                        
                            ]}
                            style={{minHeight : '30px', fontSize : '11px'}}
                            value={themeOption}
                            onChange={this.onChangeOption.bind(this, 'theme')}
                            hasBorder                                     
                            autoHeight
                            xsmall
                            toRight
                        />
                        {themeContent}
                        </React.Fragment>
                )
                
    
                if (this.props.type === TokenTypes.COLOR || this.props.type === TokenTypes.Gradients || this.props.type === TokenTypes.Shadows) {
                    typeItems = (
                        <React.Fragment>                            
                            <PanelLabel>Color Format</PanelLabel>
                            <DropDownSelect 
                                items={COLOR_VALUE_FORMATS}
                                style={{minHeight : '30px', fontSize : '11px'}}
                                value={Utils.JustGet(tokenOptions, 'hex', 'colorFormat')}
                                onChange={this.onChangeOption.bind(this, 'colorFormat')}
                                hasBorder                                     
                                autoHeight
                                xsmall
                                toRight
                            />
                        </React.Fragment>
                    )
                }
                else if (this.props.type === TokenTypes.Icons) {
                    typeItems = (
                        <React.Fragment>                            
                            <PanelLabel>Format</PanelLabel>
                            <DropDownSelect 
                                items={[
                                    {id : 'bgimage', label : 'CSS Background Image'},
                                    {id : 'svg', label : 'SVG'},
                                    // {id : 'react', label : 'React Component'},
                                ]}
                                style={{minHeight : '30px', fontSize : '11px'}}
                                value={Utils.JustGet(tokenOptions, 'bgimage', 'iconFormat')}
                                onChange={this.onChangeOption.bind(this, 'iconFormat')}
                                hasBorder                                     
                                autoHeight
                                xsmall
                                toRight
                            />
                        </React.Fragment>
                    )
                }

                if (isCustomTempalte && this.state.willShowCustomTemplate) {                
                    const codeStyle = {
                    }
                    let CodeId = this.state.codeId;
                    if (SC.CurrentTheme.theme.isLight) {
                        codeStyle.filter = 'invert(1)';
                        CodeId += 'Light';
                    }
    
                    customTemplate = (
                        <div style={{gridColumnStart : 'span 3', padding : '16px', paddingTop : 0}}>
                            <InfoPanel style={{marginTop : 0, padding : '16px', marginBottom : '8px'}}>
                                <Info_Handlebars />
                            </InfoPanel>   
                            <div
                                style={{
                                    display : 'grid',
                                    gridTemplateColumns : 'auto minMax(300px, 1fr)',
                                    gridColumnGap  :'16px',
                                    gridRowGap : '8px',
                                    marginBottom : '8px',
                                    alignItems : 'stretch',
                                    marginTop : '16px',
                                }}
                            >
                                <div>DATA SCHEMA</div>
                                <div>TEMPLATE STRING</div>                               
                                <ExportTemplateSchameViewer 
                                    schema={TokenCode.DataSchema}
                                />
                                <TextValue 
                                    key={this.props.RenderId}
                                    dark
                                    justEditor
                                    multiline 
                                    value={TokenCode.templateString}
                                    boxStyle={{height : '100%'}}
                                    style={{color : SC.CurrentTheme.theme.font, fontSize : '13px', lineHeight : '22px', letterSpacing : '0.1em', height : '100%'}}
                                    onBlured={this.onChangeOption.bind(this, 'template')}
                                />
                                <Seperator style={{gridColumn : 'span 2', marginBottom : 0}} />
                                <CusotmHandlerbarsBlock 
                                    style={{gridColumn : 'span 2'}} 
                                    RenderId={this.props.RenderId}
                                    onSave={this.onSaveHandleBarBlocks.bind(this)}
                                    blocks={Utils.JustGet(tokenOptions, [], 'handlebarBlocks')}
                                />
                            </div>
                        </div>
                    )
                }
                

                content = (
                    <React.Fragment>                        
                        <PanelLabel>File Name</PanelLabel>
                        <TextInput 
                            dark
                            value={Utils.JustGet(tokenOptions, Utils.Capitalize(this.props.title), 'fileName')}
                            onChange={this.onChangeOption.bind(this, 'fileName')}
                            style={{minHeight : '30px'}}
                            themeId={this.props.themeId}
                        />
                        <PanelLabel>Prefix</PanelLabel>
                        <TextInput 
                            dark
                            value={Utils.JustGet(tokenOptions, '', 'prefix')}
                            onChange={this.onChangeOption.bind(this, 'prefix')}
                            style={{minHeight : '30px'}}
                            themeId={this.props.themeId}
                        />
                        {typeItems}                        
                        {
                            this.props.target !== 'custom' && 
                            <React.Fragment>
                                <PanelLabel style={{marginTop : '4px', marginBottom : '4px'}}>Use Custom Template</PanelLabel>                    
                                <SC.FRow alc jsb>
                                    <Switch round value={isCustomTempalte} onChange={this.onUseCustomTemplate.bind(this)} />                        
                                </SC.FRow>
                            </React.Fragment>
                        }                                                                    
                        {
                            isCustomTempalte &&
                            <TokenItemBox style={{gridColumnStart : 'span 2'}} onClick={() => {this.setState({willShowCustomTemplate : !this.state.willShowCustomTemplate})}}>
                                {this.state.willShowCustomTemplate ? 'Close Template Editor' : 'Edit Custom Template'}
                            </TokenItemBox>
                        }                        
                    </React.Fragment>
                )   

                blockContent = (
                    <React.Fragment>
                        {
                            !TokenCode.isEmpty && 
                            <div style={{
                                display : 'grid',
                                gridTemplateColumns : 'auto 160px',
                                alignItems : 'baseline',
                                gridGap : '8px',
                                alignContent : 'start',
                                gridColumnGap : '16px',
                                maxWidth : '290px',
                            }}>
                                {selectionItem}
                                {themeSelection}
                            </div>
                        }                        
                        <div style={{
                            display : 'grid',
                            gridTemplateColumns : 'auto 160px',
                            alignItems : 'center',
                            gridGap : '8px',
                            alignContent : 'start',
                            gridColumnGap : '16px',
                            marginBottom : TokenCode.isEmpty ? '8px' : 0,
                            gridColumnStart : TokenCode.isEmpty ? 'span 2' : 'unset'
                        }}>
                            {content}  
                        </div>
                    </React.Fragment>
                )                
            }

            codeBlock = (
                <ExportTokenGroupCodeViewer                     
                    id={this.props.groupId}
                    GlobalState={this.props.GlobalState}
                    GlobalStateId={this.props.GlobalStateId}
                    GlobalThemeId={this.props.GlobalThemeId}
                    type={this.props.groupData.tokenType}
                    themeId={this.props.themeId}
                    target={this.props.target}
                    nameFormat={this.props.nameFormat}
                    prefix={this.props.prefix}
                    options={this.props.groupData}
                    GetTokenCode={this.props.GetTokenCode}
                    preview={this.props.preview}
                />
            )
        }

        
        const style_box = {
            borderRight : SC.CurrentTheme.theme.border_onlight_line,
        }
        if (this.props.preview) {
            style_box.border = 'none';
        }
        
        return (
            <DraggableEditorBox 
                notFirst={this.props.notFirst} 
                style={style_box}
            >
                <DraggableEditorHandle {...this.props.sortableProps.handleProps} 
                style={{
                    paddingLeft : '6px', paddingRight : '4px',
                    marginBottom : '8px',
                    marginTop : '8px',
                    backgroundColor : SC.CurrentTheme.theme.back_lighter
                }} ><SC.Icons.DragIcon xsmall /></DraggableEditorHandle>
                <div style={{
                    display : 'grid',
                    gridTemplateColumns : this.props.preview ? '1fr' : '3fr 2fr',
                    flex : 1
                }}>
                    {
                        !this.props.preview && 
                        <SC.FCol f1>
                            <div style={{
                                display : 'grid',
                                gridGap : '32px',
                                gridRowGap : '8px',
                                padding : '16px',
                                justifyContent : 'space-between',
                                gridTemplateColumns : 'minMax(260px, auto) auto'
                            }}>
                                <div style={{gridColumnStart : 'span 2', display : 'grid', gridGap : '16px', alignItems : 'center', gridTemplateColumns : 'auto 170px  1fr auto'}}>                            
                                    <DropDownSelect 
                                        items={[
                                            {id : 'code', label : 'Custom Code'},
                                            {id : 'tokens', label : 'Token Code'},
                                        ]}
                                        value={Utils.UseNullOrEmpty(tokenOptions.blockType, 'tokens')}
                                        onChange={this.ChangeBlockType}
                                        hasBorder            
                                        autoHeight
                                        fitWidth
                                    />
                                    {subBlockType}
                                    {customTokenTypeBlock || <div />}                                    
                                    <div style={{
                                        display : 'grid',
                                        gridTemplateColumns : 'auto auto',
                                        gridGap : '8px',
                                    }}>                                  
                                        <TokenItemBox style={{padding : '2px', margin : 0, alignItems : 'center'}} onClick={() => {
                                            Globals.ProjectManager.DuplicateExportTokenBlock(this.props.id, this.props.groupId);
                                            this.props.onUpdateCodeView(this.props.id);
                                        }} title='Duplicate block'>
                                            <SC.Icons.Icon_Button hasFill hasCursor>
                                                <SC.Icons.Icon_Clone size={18} />
                                            </SC.Icons.Icon_Button>   
                                        </TokenItemBox>
                                        <TokenItemBox style={{padding : '2px', margin : 0, alignItems : 'center'}} onClick={() => {
                                            Globals.ProjectManager.DeleteExportTokenBlock(this.props.id, this.props.groupId);
                                            this.props.onUpdate();
                                        }} title='Delete block'>
                                            <SC.Icons.Icon_Button hasFill hasCursor>
                                                <SC.Icons.Icon_Delete size={17} />
                                            </SC.Icons.Icon_Button>
                                        </TokenItemBox>
                                    </div>
                                </div>
                                {
                                    !noContent && <Seperator style={{gridColumnStart : 'span 2', marginTop : '6px'}} />
                                }                            
                                {blockContent}
                            </div>
                            {customTemplate}
                        </SC.FCol> 
                    }
                    
                    {codeBlock}
                </div>                        
            </DraggableEditorBox>
        )
    }
}



export const ExpandableHeaderBar = (props) => {
    return (
        <SC.FRow alc jsb style={{
            backgroundColor : SC.CurrentTheme.theme.back_lighter,
            border : SC.CurrentTheme.theme.border_seperator,
            borderRadius : '2px',
            paddingRight : '8px',
            marginBottom : props.expanded ? 0 : '8px',
            ...props.style
        }} onClick={props.onClick}>                    
            <SC.FRow f1 style={{padding : '8px', cursor : 'pointer'}}>
                {
                    props.expandable && 
                    <SC.Icons.Icon_Button hasFill hasCursor>
                        <SC.Icons.Icon_ExpandArrow expanded={props.expanded} />                            
                    </SC.Icons.Icon_Button>
                }                
                <GroupTitle title={props.title} />
            </SC.FRow>                                                                           
            <SC.FRow>
                {props.children}
            </SC.FRow>
        </SC.FRow>
    )
}


const COLOR_VALUE_FORMATS = [
    {id : 'hex', label : 'HEX'},
    {id : 'rgba', label : 'RGBA'},
    {id : 'hsla', label : 'HSLA'},
];


export class ExportTokenGroupCodeViewer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  
            hidden : Utils.JustGet(props.options, false, 'disabled'),
            codeId : ''
        }
    }
    Hide() {
        this.setState({hidden : true});
    }
    Show(codeId) {
        this.setState({codeId : codeId, hidden : false});
    }
    
    render() { 
        if (this.state.hidden)
            return null;
        
        const TokenCode = this.props.GetTokenCode(this.props.id);

        if (TokenCode.isEmpty) {
            return (
                <InfoPanel error style={{
                    margin : '4px',
                    marginLeft : this.props.preview ? '8px' : '4px',
                    display : 'flex',
                    alignItems : 'center'         
                }}>
                    No {this.props.type} tokens to export!
                </InfoPanel>
            )
        }

        const style = {
            flex : 'unset'
        };
        const codeStyle = {
            padding : '16px',
        }
        let CodeId = TokenCode.codeId;
        if (SC.CurrentTheme.theme.isLight) {
            codeStyle.filter = 'invert(1)';
            CodeId += 'Light';
        }

        if (this.props.preview) {
            style.marginLeft = '8px';
            style.borderBottom = SC.CurrentTheme.theme.border_seperator;
        }

        const codeContent = (
            <CopyExportTokenCodeBox 
                RenderId={CodeId}
                codeClassName={this.props.codeClassName}
                exportedCode={TokenCode.exportedCode}
                style={style}
                codeStyle={codeStyle}
                boxStyle={{flex : 'unset', height : '100%'}}
            />
        )

        if (this.props.preview) 
            return codeContent;

        return (  
            <div style={{
                overflow : 'hidden',
                position : 'relative'
            }}>
                <SC.AbsoluteOverlay>
                    {codeContent}
                </SC.AbsoluteOverlay>
            </div>
        );
    }
}


export class CopyExportTokenCodeBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }

        this.CodeId = 'Id'+Utils.Id();
    }
    componentDidMount() {
        this.clipboard = new Clipboard(`#${this.CodeId}`, {
            text: () => {
                this.clipboardCopied = true;
                setTimeout(() => {
                    this.clipboardCopied = false;
                    this.setState({ShouldUpdate : true});
                }, 1000);
                this.setState({ShouldUpdate : true});
                return this.props.exportedCode;
            }
        }); 
    }
    componentWillUnmount() {
        if (this.clipboard)
            this.clipboard.destroy();
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.RenderId !== nextProps.RenderId)
            return true;
        if (this.state.ShouldUpdate !== nextState.ShouldUpdate)
            return true;
        return false;
    }
    componentDidUpdate(prevProps, prevState) {
        this.state.ShouldUpdate = false;
    }
    render() { 
        return (  
            <SC.Div_Relative style={{flex : 1, display : 'grid', gridTemplateColumns : '1fr', ...this.props.boxStyle}}>
                <CodeViewer 
                    darker
                    className={this.props.codeClassName}
                    noClassName={this.props.noClassName}
                    key={this.props.RenderId}
                    style={{
                        lineHeight : '22px',
                        fontSize : '13px',
                        flex : 1,
                        position : 'relative',
                        ...this.props.style                        
                    }}
                    codeStyle={{
                        overflow : 'auto',
                        ...this.props.codeStyle
                    }}
                >
                    {this.props.exportedCode || this.props.children}
                </CodeViewer>
                <div
                    style={{
                        position : 'absolute',
                        top : '8px',
                        right : '8px',
                        ...this.props.toolbarStyle
                    }}
                >
                    <SC.FRow>
                        <SC.Icons.Icon_Button hasCursor hasFill style={{marginRight : '8px'}} title='Copy to clipboard' id={this.CodeId}>
                            {this.clipboardCopied ? 'Copied!' : <SC.Icons.Icon_Clone />}
                        </SC.Icons.Icon_Button>
                        {
                            this.props.onDownload && 
                            <SC.Icons.Icon_Button hasCursor hasFill title='Download' onClick={this.props.onDownload}>
                                <SC.Icons.Download />
                            </SC.Icons.Icon_Button>
                        }
                    </SC.FRow>                                    
                </div>
            </SC.Div_Relative>
        );
    }
}


export class CopyToClipboardBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }

        this.CodeId = 'Id'+Utils.Id();
    }
    componentDidMount() {
        this.clipboard = new Clipboard(`#${this.CodeId}`, {
            text: () => {
                this.clipboardCopied = true;
                this.props.onShowInfo && this.props.onShowInfo(true);
                setTimeout(() => {
                    this.clipboardCopied = false;
                    this.props.onShowInfo && this.props.onShowInfo(false);
                    this.setState({ShouldUpdate : true});
                }, 1000);
                this.setState({ShouldUpdate : true});
                return this.props.text;
            }
        }); 
    }
    componentWillUnmount() {
        if (this.clipboard)
            this.clipboard.destroy();
    }    
    render() { 
        return (  
            <div id={this.CodeId} style={this.props.style}>
                {this.props.children}
            </div>
        );
    }
}


export const Info_Handlebars = () => <div>You can write custom templates with <a target="_blank" href='https://handlebarsjs.com/' style={{textDecoration : 'underline', fontWeight : 500, paddingLeft : '1cr', fontSize : '12px', paddingRight : '1cr'}}>Handlebars</a> templating language.</div>

const ExportTokenSelectionOption = ({title, tokenOptions, onSelect, style, disabled}) => {
    const selection = Utils.JustGet(tokenOptions, 'all', 'type');
    let selectionLabel = 'All Tokens';
    if (selection === 'selected')
        selectionLabel = 'Selected Tokens';
    else if (selection === 'groups')
        selectionLabel = 'Selected Groups';
    else if (selection === 'aliases')
        selectionLabel = 'All Aliase Tokens';
    else if (selection === 'selectedaliases')
        selectionLabel = 'Selected Aliases';
    else if (selection === 'aliaseGroups')
        selectionLabel = 'Selected Aliase Groups';

    return (
        <React.Fragment>
            <TokenItemBox style={{margin : 0, gridColumnStart : 'span 2', ...style}} onClick={disabled ? null : onSelect}>
                <SC.HeaderTitle style={{...SC.Styles.Flex.RowAlcJsb}}>
                {title} {!disabled && <span style={{marginLeft : '1cr', color : SC.CurrentTheme.theme.color_brand_text}}>{selectionLabel}</span>}
                </SC.HeaderTitle>                        
            </TokenItemBox>
        </React.Fragment>
    )
}