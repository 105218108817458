import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Strings,
    MetaData,
    Globals
} from '../../../../../../importer';
import { ItemNameEditor, TokenPanelHeader } from '../../designsystem/common';

import styled from 'styled-components';
import ItemIcons from '../../meta_item_sources/itemicon';
import ToolbarItem, {DraggableToolbarItem} from '../../toolbar/UIElements/element';
import { LeftScrollPanel } from '../../common';
import ComponentRenderManager from '../../../../component/manager/renderManager';


export default class NewComponentWizardPanel extends ReactBaseComponent
{
    constructor(props) {
        super(props);
    }
    SaveName(name) {
        this.setState({name : name});
    }
    GetItems() {
        return Globals.ProjectManager.GetComponentModels();
    }
    SelectItem(Item, options) {
        const Id = CreateComponentFromType({
            name : this.state.name,
            Item : Item,
            options : options,
            width : this.props.width
        });
        
        this.props.onAdded(Id);
    }
    renderCustom() {
        const LayoutIcon = ItemIcons(MetaData.Components.Layout.Type);

        const isDraggable = false;

        const content = (
            <React.Fragment>
                <SC.FCol style={{alignSelf : 'center', maxWidth : '300px'}}>
                    <ItemNameEditor 
                        noMargin
                        autoFocus
                        fontSize='12px'                        
                        name={this.state.name}
                        onChange={this.SaveName.bind(this)}
                        onSaveName={this.SaveName.bind(this)}
                        onGetItems={this.GetItems.bind(this)}
                    />
                    <CategoryName name='COMPOSITE' />
                    <SC.FCol style={{alignSelf : 'stretch', fontSize : '11px', textAlign : 'center', lineHeight : '16px'}}>
                        <SC.FRow f1 alc justifyCenter>
                            For making component containing multiple elements
                        </SC.FRow>
                        <div style={{
                            display : 'grid',
                            gridTemplateColumns : 'repeat( auto-fit, minmax(126px, 1fr) )',
                            gridAutoRows : '60px',
                            gridGap : '8px',
                            marginTop : '16px'
                        }}>
                            <DraggableToolbarItem
                                onClick={this.SelectItem.bind(this, MetaData.Components.Div, {Layout : 'Flex'})}
                                title='Flex Box'
                                IconType={ItemIcons(MetaData.Components.Div.Type)}
                                OnDragEnd={this.props.OnDragEnd}
                                style={{cursor : isDraggable ? 'move' : 'pointer'}}
                                DragItem={isDraggable ? {
                                    Type : Strings.TOOLBARITEM,
                                    NewComponent : {
                                        Item : MetaData.Components.Div,
                                        name : this.state.name,
                                        options : {Layout : 'Flex'}
                                    },
                                    Item : {                                            
                                        MetaItem : {
                                            Type : MetaData.Components.Component.Type
                                        }
                                    } 
                                } : null}
                            />
                            <DraggableToolbarItem
                                onClick={this.SelectItem.bind(this, MetaData.Components.Div, {Layout : 'Grid'})}
                                title='Grid'
                                IconType={ItemIcons(MetaData.Components.Layout.Type)}
                                OnDragEnd={this.props.OnDragEnd}
                                style={{cursor : isDraggable ? 'move' : 'pointer'}}
                                DragItem={isDraggable ? {
                                    Type : Strings.TOOLBARITEM,
                                    NewComponent : {
                                        Item : MetaData.Components.Div,
                                        name : this.state.name,
                                        options : {Layout : 'Grid'}
                                    },
                                    Item : {                                            
                                        MetaItem : {
                                            Type : MetaData.Components.Component.Type
                                        }
                                    } 
                                } : null}
                            />
                        </div>                        
                        {/* <SC.Buttons.RoundButton xsmall onClick={this.SelectItem.bind(this, MetaData.Components.Layout.Type)} 
                            style={{width : 'unset', minWidth : '80px', marginTop : '16px', paddingLeft : '8px', paddingRight : '8px', fontSize : '11px'}}
                        >
                            SELECT LAYOUT TEMPLATE
                        </SC.Buttons.RoundButton> */}
                    </SC.FCol>
                </SC.FCol>
                <div style={{textAlign : 'center', marginTop : '16px'}}>OR</div>
                <CategoryName name='ATOMIC' style={{marginTop : '16px'}} />
                <div style={{
                    display : 'grid',
                    gridTemplateColumns : 'repeat( auto-fit, minmax(126px, 1fr) )',
                    gridAutoRows : '60px',
                    gridGap : '8px',
                    marginTop : '8px'
                }}>
                    {
                        [
                            MetaData.Components.Generic.Text,
                            MetaData.Components.Generic.Button,
                            MetaData.Components.Generic.Input,
                            MetaData.Components.Generic.Textarea,
                            MetaData.Components.Generic.Checkbox,
                            MetaData.Components.Generic.Toggle,
                            MetaData.Components.Generic.RangeInput,
                            MetaData.Components.Generic.ProgressBar,
                            MetaData.Components.Generic.RadioGroup,
                            MetaData.Components.Generic.DateInput,
                            MetaData.Components.Generic.Select,
                            MetaData.Components.Generic.Dropdown,                                
                            MetaData.Components.Generic.Carousel,
                            MetaData.Components.Generic.Tab,
                            MetaData.Components.Generic.Accordion,
                            MetaData.Components.Generic.Table,
                            
                        ].map((Item) => {
                            return (
                                <DraggableToolbarItem
                                    key={Item.Type}
                                    onClick={this.SelectItem.bind(this, Item)}
                                    title={Item.Name}
                                    IconType={ItemIcons(Item.Type, Item.Type)}
                                    OnDragEnd={this.props.OnDragEnd}
                                    style={{cursor : isDraggable ? 'move' : 'pointer'}}
                                    DragItem={isDraggable ? {
                                        Type : Strings.TOOLBARITEM,
                                        NewComponent : {
                                            Item : Item,
                                            name : this.state.name
                                        },
                                        Item : {                                            
                                            MetaItem : {
                                                Type : MetaData.Components.Component.Type
                                            }
                                        } 
                                    } : null}
                                />
                            )
                        })
                    }
                </div>
            </React.Fragment>
        );

        if (this.props.onCanvas) {
            return content;
        }
        
        return (
            <React.Fragment>
                <TokenPanelHeader 
                    title={'NEW COMPONENT'} 
                    hasAddCancel
                    onClose={this.props.onClose} 
                    onCancel={this.props.onClose} 
                    onAdd={this.onSubmitAddToken} 
                    style={{padding : '2px'}}
                />
                <SC.FCol fw fh
                    style={{
                        backgroundColor : SC.CurrentTheme.theme.back
                    }}
                >
                    <LeftScrollPanel>
                        <SC.FCol fw fh
                            style={{
                                padding : '8px',
                                boxSizing : 'border-box'
                            }}       
                        >
                            {content}
                        </SC.FCol>
                    </LeftScrollPanel>
                </SC.FCol>                
            </React.Fragment>
        );
    }
}

const CategoryName = ({name, style}) => (
    <SC.FRow justifyCenter alc style={{fontSize : '13px', fontWeight : 400, marginTop : '8px', marginBottom : '8px', ...style}}>
        {name}
    </SC.FRow>
);

export const CreateComponentFromType = ({name, Item, options, width}) => {
    let Type = Item.Type;        
            
    let useName = Globals.ProjectManager.GetNewName({
        models : Globals.ProjectManager.GetComponentModels(),
        seedName : Utils.UseNullOrEmpty(name, (Item.Generic ? Item.Name : 'Component'))
    })

    const MetaItem = Globals.ProjectManager.CreateToolbarItem(
        MetaData.ItemInitializer.FromMetaData({
            Type : Type
        })
    );
    
    let MetaComponent;

    if (Item.Generic) {
        MetaItem.Generic = true;
        MetaComponent = MetaData.Components.Generic.InitializeComponent(MetaItem, Item.Type);
        MetaComponent.GenericType = Item.Type;
    }
    else {         

        if (Type === MetaData.Components.Div.Type) {                
            MetaItem.name = 'Root';
            
            if (options) {
                if (options.Layout === 'Grid') {
                    MetaItem.Property = {
                        Default : {
                            Default : {
                                display : {
                                    value : 'grid'
                                },
                                backgroundColor : {
                                    value : '#fff'
                                },
                                Grid : {
                                    model : {
                                       value : MetaData.ItemInitializer.GetGridLayout().GridModel
                                    },
                                    rowGap : {
                                        value : 20
                                    },
                                    columnGap : {
                                        value : 20
                                    }
                                },
                            }
                        },
                        Grid : {
                            showGridLines : {
                                value : true
                            },
                            initialized : {
                                value : true
                            }
                        }
                    };
                }
            } 
            MetaItem.Property.Default.Default.width = Globals.ProjectManager.Units.FullSize();
            MetaItem.Property.Default.Default.height = Globals.ProjectManager.Units.FullSize();           
        }
    }
    const Id = Globals.ProjectManager.AddComponent({
        name : useName,
        data : MetaComponent,
        RootItem : MetaItem,
        PreviewSize : Utils.JustGet(MetaComponent, null, 'PreviewSize') || {
            width : width || 300,
            height : 300
        }
    });

    if (MetaItem.Type === MetaData.Components.Div.Type) {
        const RenderManager = new ComponentRenderManager();
        const Manager = RenderManager.GetManager(Id);
        const RootId = Globals.ProjectManager.GetComponentRootId(Id);

        if (Manager) {
            Manager.SetItemTemplateStyles(MetaItem, RootId);
            Manager.LoadItemStyles({
                StyleState : {Global : 'Default', Component : 'Default'}, 
                ReverseStyleState : {Global : 'Default', Component : 'Default'}, 
                MetaItem : MetaItem, 
                Id : RootId
            });
        }        
    }

    return Id;
}