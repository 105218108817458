import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Events,
    AppState,
    MetaData,
    Utils,
    Strings,
    ReactPureComponent
} from '../../../../../../../importer';

import { PropertyLabel, StyleGroupTokenItem } from '../common';
import ComponentDesignManager from '../../../../../component/manager';
import Switch from '../../../../../../../components/editors/Switch';
import VariableBinder from '../variable';


export default class HiddenItem extends ReactBaseComponent
{
    constructor(props) {
        super(props);
        
        this.Togglehidden = this.Togglehidden.bind(this);

        this.SelectModel = this.SelectModel.bind(this);
        this.SetModelBinding = this.SetModelBinding.bind(this);
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.StateId !== this.props.StateId)
            return true;
        
        if (Utils.HasAnyChange(this.props, nextProps, 'hidden', 'modelId'))
            return true;

        return super.ShouldUpdate(nextProps, nextState);
    }
    Togglehidden(value) {
        if (this.props.onChange) {
            this.props.onChange(value);
        }
        else {
            this.props.onSetPropertyValues([{
                PropertyName : 'hidden',
                Value : value  
            }]);
            this.RCUpdate();
        }        
    }
    SelectModel() {
        Events.BCE(Events.DESIGNER.BOARD.ITEM.SELECT_MODEL, {
            title : 'HIDDEN',
            dataType : MetaData.DataTypes.boolean,
            onSelect : this.SetModelBinding,
            initialValue : this.FinalValue
        });
    }
    SetModelBinding(modelId) {
        this.props.onBindToModel && this.props.onBindToModel({
            PropertyName : 'hidden',
            ModelId : modelId
        });
        this.RCUpdate();
    }
    renderCustom() {        
        const {MetaItemStyle} = this.props;

        this.FinalValue = this.props.hidden.Value;
        if (Utils.IsNull(this.FinalValue))
            this.FinalValue = false;
            
        const modelId = Utils.JustGet(this.props.modelId, null, 'ModelId');
        if (modelId) {
            const modelValue = this.props.GetComponentManager().GetModelValue({ModelId : modelId});
            this.FinalValue = Utils.IsTrue(modelValue);                
        }

        return (
            <BooleanEditor 
                label='Hidden'
                value={this.FinalValue}
                onChange={this.Togglehidden}
                modelId={modelId}
                onSelectModel={this.SelectModel}
            />
        )     
    }
}

export const BooleanEditor = (props) => {
    let mockupRefresh;
    if (props.onRefreshMockup) {
        mockupRefresh = (
            <SC.Icons.Icon_Button 
                hasFill 
                style={{marginLeft : '8px', cursor : 'pointer'}} 
                title='Generate New Mockup Value'
                onClick={props.onRefreshMockup}
            >
                <SC.Icons.Icon_Refresh 
                    size={20}                            
                />
            </SC.Icons.Icon_Button>
        )
    }
    return (
        <SC.FRow style={{marginTop : '4px'}}>
            <StyleGroupTokenItem style={{flex : 1, padding : '8px', paddingTop : '4px', paddingBottom : '4px'}}>
                <SC.FRow alc jsb>
                    <PropertyLabel 
                        style={{marginRight : '18px'}}
                        hasValue={props.value !== null}
                        menuOptions={{
                            onShowValueMenu : props.onShowValueMenu,
                            propName : 'hidden',
                            isProp : true
                        }}
                    >{props.label}</PropertyLabel>
                    <SC.FRow alc>
                        <Switch round small value={props.value} onChange={props.onChange}/>                        
                    </SC.FRow>                        
                </SC.FRow>
            </StyleGroupTokenItem>   
            <VariableBinder 
                modelId={props.modelId}
                onSelect={props.onSelectModel}
            />
            {mockupRefresh}
        </SC.FRow>
    )      
}
