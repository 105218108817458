import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    Globals
} from '../../../importer';

import styled, {css} from 'styled-components';
import Switch from '../Switch';
import InputSlider from '../input_slider';
import { StyleToken_Color } from '../../../views/project/panels/right/iteminspector/styleitems/common';
import { DraggableEditorBox, DraggableEditorHandle } from '../transform/transformItemEditor';



class ShadowEditor extends ReactBaseComponent
{
    constructor(props) {
        super(props);   
        this.ChangeColor = this.ChangeColor.bind(this);
        this.SaveColor = this.SaveColor.bind(this);
        this.onChangeType = this.onChangeType.bind(this);
        this.SelectTokenColor = this.SelectTokenColor.bind(this);

        this.ChangeLogged = true;
    }
    onChangeType(type) {
        this.props.shadow.type = this.props.shadow.type === 'in' ? 'out' : 'in';
        this.props.onChange(this.props.shadow);
    }
    SelectTokenColor() {
        this.props.onSelectColor(
            (tokenId) => {
                if (this.props.id) {
                    const RelationId = Globals.ProjectManager.Tokens.BindTokenToToken({
                        SourceTokenId : this.props.id,
                        ConsumerId : this.props.shadow.id,
                        OldRelationId : this.props.shadow.ColorRelationId,
                        TargetTokenId : tokenId
                    });
                    this.props.shadow.ColorRelationId = RelationId;
                }
                
                this.props.shadow.colorId = tokenId;                
                delete this.props.shadow.previewColorId;

                this.RCUpdate();
                this.props.onChange(this.props.shadow);
            },
            (tokenId, show) => {
                if (show) {                    
                    this.props.shadow.previewColorId = tokenId;
                }
                else {
                    delete this.props.shadow.previewColorId;
                }
                this.RCUpdate();
                this.props.onChange(this.props.shadow);
            },
            () => {
                if (this.props.shadow.previewColorId) {
                    delete this.props.shadow.previewColorId;
                    this.RCUpdate();
                    this.props.onChange(this.props.shadow);
                }
            },
            this.props.shadow.colorId
        );
    }
        
    ChangeColor(c) {
        if (!this.ChangeLogged) {
            this.ChangeLogged = true;
            this.props.onBeforeChange && this.props.onBeforeChange();
        }
        this.props.shadow.color = c;
        this.RCUpdate();
        this.props.onChanging();
    }
    SaveColor() {
        this.ColorPickerVisible = false;
        this.props.onChange(this.props.shadow);
        this.RCUpdate();
    }
    onChangingCoord(prop, value) {
        if (!this.ChangeLogged) {
            this.ChangeLogged = true;
            this.props.onBeforeChange && this.props.onBeforeChange();
        }
        this.SetCoord(prop, value, false);
    }
    onChangeCoord(prop, value, offline) {       
        this.ChangeLogged = false;
        this.SetCoord(prop, value, offline);
    }
    SetCoord(prop, value, offline) {        
        let coord = Utils.ToNumber(value);
        if (isNaN(coord))
            return;
        const {shadow} = this.props;
        Utils.Set(shadow, coord, prop);
        if (!offline)
            this.props.onChange(shadow);
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.shadow.previewColorId) {
            this.props.onRefreshPreview && this.props.onRefreshPreview();
        }
        return true;
    }
    renderCustom() {
        const {shadow, notFirst} = this.props;
        let {x, y, blur, spread, color} = shadow || {x : 0, y : 0, blur : 0, spread : 0, color : 'black'};
       
        const options = {
            sliderProps : {
                horizontal : true,
                reverse : true,
                inputStyle : {fontSize : '12px', width : '24px'}
            },
            span : {
                width : '60px'
            }
        }

        return (
            <DraggableEditorBox notFirst={notFirst}>
                <DraggableEditorHandle {...this.props.sortableProps.handleProps} style={{paddingLeft : '4px', paddingRight : '4px'}} ><SC.Icons.DragIcon xsmall /></DraggableEditorHandle>
                <SC.FCol f1 style={{padding : '8px', paddingRight : '12px', paddingTop : '4px'}}>
                    <SC.FRow jsb>                                             
                        {
                            this.props.textShadow ? null : 
                            <SC.FRow alc>
                                <div style={{marginRight : '4px', fontSize : '11px'}}>Inset</div>
                                <Switch round value={shadow.type === 'in'} onChange={this.onChangeType} />
                            </SC.FRow>
                        }
                        <SC.FRow justifyEnd style={{marginLeft : '4px', marginRight : '-8px'}}>
                            <SC.Icons.Icon_ButtonBox dark hasCursor>
                                <SC.Icons.Icon_Button hasFill style={{cursor : 'pointer'}} onClick={this.props.onClone} >
                                    <SC.Icons.Icon_Clone size={18}/>
                                </SC.Icons.Icon_Button>
                            </SC.Icons.Icon_ButtonBox>
                            <SC.Icons.Icon_ButtonBox dark hasCursor>
                                <SC.Icons.Icon_Button hasFill style={{cursor : 'pointer', marginLeft : '4px', paddingRight : '4px'}} onClick={this.props.onDelete} >
                                    <SC.Icons.Icon_Delete size={16}/>
                                </SC.Icons.Icon_Button>
                            </SC.Icons.Icon_ButtonBox>
                        </SC.FRow>                    
                    </SC.FRow>
                    <StyleToken_Color 
                        label='Shadow Color'
                        value={this.props.shadow.color}
                        onSelect={this.SelectTokenColor}
                        style={{marginBottom : '4px'}}
                    />
                    <SC.FCol style={{fontSize : '11px'}}>
                        <SC.FRow alc jsb style={{marginBottom : '4px'}}>
                            <span style={options.span}>X</span>    
                            <InputSlider {...options.sliderProps} value={x} min={-30} max={30} allowOverflow onChanging={this.onChangingCoord.bind(this, 'x')} onChange={this.onChangeCoord.bind(this, 'x')} />
                        </SC.FRow>
                        <SC.FRow alc jsb style={{marginBottom : '4px'}}>
                            <span style={options.span}>Y</span>    
                            <InputSlider {...options.sliderProps} value={y} min={-30} max={30} allowOverflow onChanging={this.onChangingCoord.bind(this, 'y')} onChange={this.onChangeCoord.bind(this, 'y')} />
                        </SC.FRow>
                        <SC.FRow alc jsb style={{marginBottom : this.props.textShadow ? 0 : '4px'}}>
                            <span style={options.span}>Blur</span>    
                            <InputSlider {...options.sliderProps} value={blur} min={0} max={40} allowOverflow onChanging={this.onChangingCoord.bind(this, 'blur')} onChange={this.onChangeCoord.bind(this, 'blur')} />
                        </SC.FRow>
                        {
                            this.props.textShadow ? null  :
                            <SC.FRow alc jsb>
                                <span style={options.span}>Spread</span>    
                                <InputSlider {...options.sliderProps} value={spread} min={-30} max={30} allowOverflow onChanging={this.onChangingCoord.bind(this, 'spread')} onChange={this.onChangeCoord.bind(this, 'spread')} />
                            </SC.FRow>
                        }                        
                    </SC.FCol>
                </SC.FCol> 
            </DraggableEditorBox>
        )        
    }
}

export default ShadowEditor;
