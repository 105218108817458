import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    AppLayout,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../../../../importer';
import Loadable from 'react-loadable';
import styled, { css} from 'styled-components';
import {DOCUMENT_ITEMTYPES, DOCUMENT_ITEMTYPE_TITLES, DOCUMENT_TOKENTYPES} from '../../../../manager/documents';
import DesignSystemComponentsPanel from '../../../../panels/left/components';
import { SearchInput } from '../../../../searchbar';
import SelectComponent from './selectComponent';
import FavoriteDocumentWidgets from './favoriteDocumentWidgets';
import ToolbarIcons from '../../../../../../styled/toolbar';
import { WidgetIcon } from '../../../../panels/left/meta_item_sources/itemicon';
import { TokenTypes } from '../../../../manager/tokens';
import FigmaComponents from './figmaComponents';
import { GetTokenTypeTokenList } from '../../../../panels/left/designsystem/custom/definitions';

const StoryBookLogo = Loadable({
    loader: () => import('../../../../../../integrations/storybookLogo'),
    loading: () => <div />,
});

export const DEFAULT_LOTTIE_FILE = 'https://assets1.lottiefiles.com/packages/lf20_3JBIRr.json';

const MARGIN_DEFAULT = 128;

export default class DocumentItemWizard extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.DeleteItem = this.DeleteItem.bind(this);        

        const tokenids = Globals.ProjectManager.Tokens.FigmaLayouts();
        if (tokenids && Array.isArray(tokenids) && tokenids.length > 0)
            this.hasFigmaAutoLayouts = true;
    }
    DeleteItem() {
        this.props.onCancel(this.props.id, this.props.parentId);
    }
    CreateItem(type, data) {
        Globals.ProjectManager.GetDocumentManager().SetItem(this.props.id, {
            type : type,
            data : data || {
                bottom : MARGIN_DEFAULT
            }
        });
        this.props.onNewItemAdded();
    }
    CreateText(tag) {
        Globals.ProjectManager.GetDocumentManager().SetItem(this.props.id, {
            type : DOCUMENT_ITEMTYPES.text,
            data : {
                tag : tag,
                bottom : MARGIN_DEFAULT
            }
        });
        this.props.onNewItemAdded();
    }
    CrateImage() {
        Globals.ProjectManager.GetDocumentManager().SetItem(this.props.id, {
            type : DOCUMENT_ITEMTYPES.image,
            data : {
                bottom : MARGIN_DEFAULT
            }
        });
        this.props.onNewItemAdded();
    }
    CreateToken(tokenType, widgetType, options) {
        Globals.ProjectManager.GetDocumentManager().SetItem(this.props.id, {
            type : DOCUMENT_ITEMTYPES.token,            
            data : {
                type : tokenType,
                widgetType : widgetType,
                ...options,
                bottom : MARGIN_DEFAULT
            }
        });
        this.props.onNewItemAdded();
    }    
    ShowTokenTypes(type) {
        this.showWizard = {
            type : DOCUMENT_ITEMTYPES.token,
            tokenWidgetType : type
        };
        this.RCUpdate();
    }
    ShowComponents() {
        this.showWizard = {
            type : DOCUMENT_ITEMTYPES.component
        };
        this.RCUpdate();
    }    
    ShowFavorites() {
        this.showWizard = {
            type : DOCUMENT_ITEMTYPES.favorites
        };
        this.RCUpdate();
    }
    ShowFigmaMenu() {
        this.showWizard = {
            type : DOCUMENT_ITEMTYPES.figma
        };
        this.RCUpdate();
    }
    renderCustom() {
        if (!this.state.selected && !this.props.active) {
            return (
                <div style={{minHeight : '24px'}} />
            )
        }
        if (this.showWizard) {
            if (this.showWizard.type === DOCUMENT_ITEMTYPES.token) {                   
                if (this.showWizard.tokenWidgetType === 'widget' || this.showWizard.defaultExports || true) {
                    return (
                        <SC.FCol style={{
                            lineHeight : 'unset',
                            ...SC.Styles.Font(),     
                            alignSelf : 'center',
                            marginTop : '16px',
                            width : 'min(822px, 100%)'                   
                        }}>
                            <SC.BackClosableTitle title='SELECT TOKEN TYPE' onClose={() => {delete this.showWizard; this.RCUpdate();}} />
                            <div
                                style={{
                                    display : 'grid',
                                    gridGap : '16px',
                                    justifyContent : 'start',
                                    gridTemplateColumns : 'repeat(auto-fit, minmax(300px, 1fr))',
                                    gridAutoRows : 'minMax(48px, auto)',
                                    marginTop : '8px',
                                    border : SC.CurrentTheme.theme.border_popup,
                                    borderRadius : '2px',
                                    padding : '8px',
                                    boxSizing : 'border-box',
                                    backgroundColor : SC.CurrentTheme.theme.isLight ? SC.CurrentTheme.theme.back : SC.CurrentTheme.theme.back_darker,
                                    boxShadow : SC.CurrentTheme.theme.popup_shadow,  
                                }}
                            >
                                <div
                                    style={{
                                        display : 'grid',
                                        gridTemplateColumns : '1fr 1fr 1fr',
                                        gridGap : '8px',
                                        gridRowStart : 'span 3'
                                    }}
                                >
                                    {
                                        DOCUMENT_TOKENTYPES.map((token) => {
                                            if (token.id === TokenTypes.Fonts || token.id === TokenTypes.Motion || token.id === TokenTypes.Spacings)
                                                return null;
                                            return (
                                                <ElementBox 
                                                    key={token.id}
                                                    onClick={this.CreateToken.bind(this, token.id, this.showWizard.tokenWidgetType, token.options)}
                                                >
                                                    {token.label}
                                                </ElementBox>
                                            )
                                        })
                                    }    
                                    {
                                        this.hasFigmaAutoLayouts && 
                                        <ElementBox 
                                            onClick={this.CreateToken.bind(this, TokenTypes.FigmaAutoLayouts, this.showWizard.tokenWidgetType, {})}
                                        >
                                            Figma Autolayouts
                                        </ElementBox>
                                    }
                                    <ElementBox 
                                            onClick={() => {
                                                this.showWizard = {
                                                    type : DOCUMENT_ITEMTYPES.customToken
                                                };
                                                this.RCUpdate();
                                            }}
                                        >
                                        Custom Tokens
                                    </ElementBox>
                                </div>
                                <div style={{
                                    gridRowStart : 'span 3',
                                    padding : '8px'
                                }}>
                                    <div
                                        style={{
                                            display : 'grid',
                                            gridTemplateColumns : '1fr 1fr 1fr',
                                            gridGap : '8px',
                                            gridRowGap : '4px',
                                            marginBottom : '16px'
                                        }}
                                    >
                                        <SC.FRow alc style={{gridColumnStart : 'span 3'}}>
                                            <div style={{
                                                borderTop : SC.CurrentTheme.theme.border_seperator,                                                
                                                flex : 1,
                                                marginLeft : '16px',
                                                marginRight : '16px',
                                            }}/>
                                            <div>Typography</div>    
                                            <div style={{
                                                borderTop : SC.CurrentTheme.theme.border_seperator,
                                                flex : 1,
                                                marginLeft : '16px',
                                                marginRight : '16px',
                                            }}/>
                                        </SC.FRow>
                                        <ElementBox 
                                            onClick={this.CreateToken.bind(this, TokenTypes.Fonts, this.showWizard.tokenWidgetType, {subType : 'fonts'})}
                                            style={{                    
                                            }}
                                        >
                                            Fonts
                                        </ElementBox>
                                        <ElementBox 
                                            onClick={this.CreateToken.bind(this, TokenTypes.Fonts, this.showWizard.tokenWidgetType, {subType : 'scale'})}
                                            style={{                    
                                            }}
                                        >
                                            Typescale
                                        </ElementBox>
                                        <ElementBox 
                                            onClick={this.CreateToken.bind(this, TokenTypes.Fonts, this.showWizard.tokenWidgetType, {subType : 'patterns'})}
                                            style={{                    
                                            }}
                                        >
                                            Text Styles
                                        </ElementBox>
                                    </div>
                                    
                                    <div
                                        style={{
                                            display : 'grid',
                                            gridTemplateColumns : '1fr 1fr',
                                            gridGap : '8px',
                                            marginBottom : '16px'
                                        }}
                                    >
                                        <SC.FRow alc style={{gridColumnStart : 'span 2'}}>
                                            <div style={{
                                                borderTop : SC.CurrentTheme.theme.border_seperator,                                                
                                                flex : 1,
                                                marginLeft : '16px',
                                                marginRight : '16px',
                                            }}/>
                                            <div>Motion</div>    
                                            <div style={{
                                                borderTop : SC.CurrentTheme.theme.border_seperator,
                                                flex : 1,
                                                marginLeft : '16px',
                                                marginRight : '16px',
                                            }}/>
                                        </SC.FRow>                                    
                                        <ElementBox 
                                            onClick={this.CreateToken.bind(this, TokenTypes.Motion, this.showWizard.tokenWidgetType, {subType : 'scale'})}
                                            style={{                    
                                            }}
                                        >
                                            Time Scale
                                        </ElementBox>
                                        <ElementBox 
                                            onClick={this.CreateToken.bind(this, TokenTypes.Motion, this.showWizard.tokenWidgetType, {subType : 'durations'})}
                                            style={{                    
                                            }}
                                        >
                                            Durations
                                        </ElementBox>
                                        <ElementBox 
                                            onClick={this.CreateToken.bind(this, TokenTypes.Motion, this.showWizard.tokenWidgetType, {subType : 'ease'})}
                                            style={{                    
                                            }}
                                        >
                                            Easeing
                                        </ElementBox>
                                        <ElementBox 
                                            onClick={this.CreateToken.bind(this, TokenTypes.Motion, this.showWizard.tokenWidgetType, {subType : 'transitions'})}
                                            style={{                    
                                            }}
                                        >
                                            Transitions
                                        </ElementBox>
                                    </div>                               
                                    <div
                                        style={{
                                            display : 'grid',
                                            gridTemplateColumns : '1fr 1fr',
                                            gridGap : '8px',
                                            gridRowGap : '4px'
                                        }}
                                    >
                                        <SC.FRow alc style={{gridColumnStart : 'span 3'}}>
                                            <div style={{
                                                borderTop : SC.CurrentTheme.theme.border_seperator,                                                
                                                flex : 1,
                                                marginLeft : '16px',
                                                marginRight : '16px',
                                            }}/>
                                            <div>Spacing</div>    
                                            <div style={{
                                                borderTop : SC.CurrentTheme.theme.border_seperator,
                                                flex : 1,
                                                marginLeft : '16px',
                                                marginRight : '16px',
                                            }}/>
                                        </SC.FRow>                                    
                                        <ElementBox 
                                            onClick={this.CreateToken.bind(this, TokenTypes.Spacings, this.showWizard.tokenWidgetType, {subType : 'scale'})}
                                            style={{                    
                                            }}
                                        >
                                            Scale
                                        </ElementBox>
                                        <ElementBox 
                                            onClick={this.CreateToken.bind(this, TokenTypes.Spacings, this.showWizard.tokenWidgetType, {subType : 'patterns'})}
                                            style={{                    
                                            }}
                                        >
                                            Space Patterns
                                        </ElementBox>
                                    </div>
                                </div>
                            </div>                        
                        </SC.FCol>
                    )
                }    
                else {
                    return (
                        <SC.FCol style={{
                            lineHeight : 'unset',
                            ...SC.Styles.Font(),     
                            alignSelf : 'center',
                            marginTop : '16px',
                            width : 'min(822px, 100%)'                   
                        }}>
                            <SC.BackClosableTitle title='SELECT EXPORT TYPE' onClose={() => {delete this.showWizard; this.RCUpdate();}} />
                            
                        </SC.FCol>
                    )
                }                            
            }
            else if (this.showWizard.type === DOCUMENT_ITEMTYPES.customToken) {       
                const customTokenTypes = GetTokenTypeTokenList();
                const customTokenItems = [];
                Utils.ForEach(customTokenTypes, (customTokenType, i) => {
                    customTokenItems.push(
                        <ElementBox 
                            key={customTokenType.id}
                            onClick={this.CreateToken.bind(this, TokenTypes.CustomTokenTypes, 'widget', {
                                tokenType : customTokenType.id
                            })}
                        >
                            <div>{customTokenType.name}</div>
                            <div>{customTokenType.description}</div>
                        </ElementBox>
                    )
                });
                return (
                    <SC.FCol style={{
                        lineHeight : 'unset',
                        ...SC.Styles.Font(),     
                        alignSelf : 'center',
                        marginTop : '16px',
                        width : 'min(822px, 100%)',
                        display : 'grid',
                        gridTemplateColumns : '1fr 1fr',
                        gridGap : '8px',
                        marginBottom : '16px'        
                    }}>
                        <SC.BackClosableTitle title='SELECT CUSTOM TOKEN TYPE' onClose={() => {delete this.showWizard; this.RCUpdate();}} />
                        <div />
                        {customTokenItems}
                    </SC.FCol>
                )
            }
            else if (this.showWizard.type === DOCUMENT_ITEMTYPES.component) {
                return (
                    <SelectComponent
                        {...this.props}
                        onClose={() => {delete this.showWizard; this.RCUpdate();}}
                        style={{
                            alignSelf : 'center',
                            width : 'min(822px, 100%)',
                        }}
                    />
                );                    
            }  
            else if (this.showWizard.type === DOCUMENT_ITEMTYPES.favorites) {
                return (
                    <FavoriteDocumentWidgets
                        {...this.props}
                        onClose={() => {delete this.showWizard; this.RCUpdate();}}
                        style={{
                            alignSelf : 'center',
                            width : 'min(822px, 100%)',
                        }}
                    />
                );                    
            } 
            else if (this.showWizard.type === DOCUMENT_ITEMTYPES.figma) {
                return (
                    <SC.FCol style={{
                        lineHeight : 'unset',
                        ...SC.Styles.Font(),     
                        alignSelf : 'center',
                        marginTop : '16px',
                        width : 'min(822px, 100%)'                   
                    }}>
                        <SC.BackClosableTitle title='FIGMA ELEMENTS' onClose={() => {delete this.showWizard; this.RCUpdate();}} />
                        <SC.FCol style={{
                            border : SC.CurrentTheme.theme.border_popup,
                            borderRadius : '2px',
                            padding : '8px',
                            boxSizing : 'border-box',
                            backgroundColor : SC.CurrentTheme.theme.isLight ? SC.CurrentTheme.theme.back : SC.CurrentTheme.theme.back_darker,
                            boxShadow : SC.CurrentTheme.theme.popup_shadow,    
                        }}>
                            <ElementBox 
                                onClick={this.CreateItem.bind(this, DOCUMENT_ITEMTYPES.figmaLiveEmbed, {})}
                                style={{                    
                                }}
                            >
                                <div style={{fontStyle : 'bold', fontSize : '14px'}}>Figma Live Embed</div>
                                <div>Add real-time Figma designs and prototypes</div>                                
                            </ElementBox>
                            <FigmaComponents 
                                {...this.props}
                                onClose={() => {delete this.showWizard; this.RCUpdate();}}
                                style={{
                                    alignSelf : 'center',
                                    width : 'min(822px, 100%)',
                                }}
                            />
                        </SC.FCol>
                    </SC.FCol>
                )                 
            }            
        }

        const style_text = {
            lineHeight : '1.5em',
            color : SC.CurrentTheme.theme.font
        }

        return (
            <SC.FCol style={{
                alignSelf : 'center',
                width : 'min(822px, 100%)',
                marginTop : '16px',
                ...SC.Styles.Font(),  
            }}>
                {
                    !this.props.emptyFirst && 
                    <SC.FRow alc jsb>
                        <SC.BackClosableTitle title='SELECT WIDGET' onClose={this.DeleteItem} />                        
                    </SC.FRow>
                }                
                <div
                    style={{
                        marginTop : '8px',
                        display : 'grid',
                        gridGap : '8px',
                        justifyContent : 'start',
                        lineHeight : 'unset',
                        gridTemplateColumns : 'minMax(160px, auto) 1fr',
                        gridAutoRows : 'minMax(48px, auto)',
                        border : SC.CurrentTheme.theme.border_popup,
                        borderRadius : '2px',
                        padding : '8px',
                        boxSizing : 'border-box',
                        backgroundColor : SC.CurrentTheme.theme.isLight ? SC.CurrentTheme.theme.back : SC.CurrentTheme.theme.back_darker,
                        boxShadow : SC.CurrentTheme.theme.popup_shadow,                                                
                    }}
                >
                    <div style={{
                        display : 'grid',
                        gridGap : '8px',
                        gridTemplateColumns : '1fr',
                        gridTemplateRows : 'minMax(30px, auto)',
                        paddingRight : '8px',
                        borderRight : SC.CurrentTheme.theme.border_ondark
                    }}
                    >
                        <ElementBox onClick={this.CreateText.bind(this, 'd1')} style={{
                            fontWeight : 'unset', ...this.props.textStyles.d1, paddingLeft : '8px', paddingRight : '8px', ...style_text
                        }}>
                            TITLE
                        </ElementBox>
                        <ElementBox onClick={this.CreateText.bind(this, 'h1')} style={{
                            fontWeight : 'unset',...this.props.textStyles.h1, ...style_text
                        }}>
                            H1
                        </ElementBox>
                        <ElementBox onClick={this.CreateText.bind(this, 'h2')} style={{
                            fontWeight : 'unset',...this.props.textStyles.h2, ...style_text
                        }}>
                            H2
                        </ElementBox>
                        <ElementBox onClick={this.CreateText.bind(this, 'h3')} style={{
                            fontWeight : 'unset',...this.props.textStyles.h3, ...style_text
                        }}>
                            H3
                        </ElementBox>
                        <ElementBox onClick={this.CreateText.bind(this, 'paragraph')} style={{
                            fontWeight : 'unset', ...this.props.textStyles.paragraph, textTransform : 'unset', ...style_text
                        }}>
                            Paragraph
                        </ElementBox>  
                        {/* <ElementBox onClick={this.CreateText.bind(this, 'code')} style={{
                            fontWeight : 'unset', ...this.props.textStyles.code, textTransform : 'unset', ...style_text
                        }}>
                            Code
                        </ElementBox>                     */}
                        <ElementBox onClick={this.CreateItem.bind(this, DOCUMENT_ITEMTYPES.code, {})} style={{
                            fontWeight : 'unset', ...this.props.textStyles.code, textTransform : 'unset', ...style_text
                        }}>
                            Code Block
                        </ElementBox>
                    </div>
                    <div style={{
                        display : 'grid',
                        gridTemplateColumns : 'repeat( auto-fit, minmax(126px, 1fr) )',
                        gridAutoRows : '1fr',
                        gridGap : '8px'
                    }}>
                        {
                            [
                                {title : 'Image', icon : <ToolbarIcons.Image size={24} />, type : DOCUMENT_ITEMTYPES.image},
                                {title : 'Lottie Animation', icon : <ToolbarIcons.Lottie />, type : DOCUMENT_ITEMTYPES.lottie},
                                {title : 'Video', icon : <ToolbarIcons.Video />, type : DOCUMENT_ITEMTYPES.video},
                                {title : 'Link Block', icon : <ToolbarIcons.Button />, type : DOCUMENT_ITEMTYPES.linkcard},                            
                            ].map((item) => {
                                return (
                                    <ElementBox onClick={this.CreateItem.bind(this, item.type, {bottom : MARGIN_DEFAULT, ...item.props})}>
                                        <SC.Div_Flex_Cell>
                                            {item.icon}
                                        </SC.Div_Flex_Cell>                
                                        {item.title}
                                    </ElementBox>
                                )
                            })
                        }
                        
                        <ElementBox onClick={this.ShowTokenTypes.bind(this, 'widget')}>
                            <SC.Div_Flex_Cell>
                                <SC.Icons.Icon_Button hasFill hasCursor>
                                    <ToolbarIcons.Token size={24} />
                                </SC.Icons.Icon_Button>
                            </SC.Div_Flex_Cell>                
                            Token Widget
                        </ElementBox>
                        <ElementBox onClick={this.ShowTokenTypes.bind(this, 'export')}>
                            <SC.Div_Flex_Cell>
                                <SC.Icons.Icon_Button hasFill hasCursor>
                                    <SC.Icons.Download />
                                </SC.Icons.Icon_Button>
                            </SC.Div_Flex_Cell>                
                            Token Export
                        </ElementBox>
                        {
                            [
                                {title : DOCUMENT_ITEMTYPE_TITLES.accessibility,
                                    icon : <SC.Icons.Icon_Button hasFill hasCursor>
                                        <SC.Icons.Accessibility size={24} />
                                    </SC.Icons.Icon_Button>, type : DOCUMENT_ITEMTYPES.accessibility
                                },
                                {
                                    title : DOCUMENT_ITEMTYPE_TITLES.themeConfigurator,
                                    icon : <SC.Icons.Icon_Button hasFill hasCursor>
                                        <SC.Icons.Icon_SliderSettings size={24} />
                                    </SC.Icons.Icon_Button>, type : DOCUMENT_ITEMTYPES.themeConfigurator
                                },                            
                                {
                                    title : DOCUMENT_ITEMTYPE_TITLES.layout, 
                                    icon : 
                                        <SC.Icons.Icon_Button hasFill hasCursor>
                                            <ToolbarIcons.TwoColumnRow />
                                        </SC.Icons.Icon_Button>, 
                                    type : DOCUMENT_ITEMTYPES.layout
                                },
                                {
                                    title : 'Tabs', 
                                    icon : 
                                        <SC.Icons.Icon_Button hasFill hasCursor>
                                            <ToolbarIcons.Tabs />
                                        </SC.Icons.Icon_Button>, 
                                    type : DOCUMENT_ITEMTYPES.tab,
                                    props : {
                                        items : [ 
                                            {
                                                id : Utils.Id(),
                                                name : 'Tab 1'
                                            },
                                            {
                                                id : Utils.Id(),
                                                name : 'Tab 2'
                                            }
                                        ]
                                    }
                                },
                                {
                                    title : 'Space', 
                                    icon : 
                                        <SC.Icons.Icon_Button hasFill hasCursor>
                                            <SC.Icons.Icon_ScrollArrow />
                                        </SC.Icons.Icon_Button>, 
                                    type : DOCUMENT_ITEMTYPES.space,
                                    props : {
                                        size : 128
                                    }
                                },
                                {
                                    title : 'Divider', 
                                    icon : 
                                        <SC.FCol jsb style={{width : '18px', height : '16px', alignItems : 'stretch', marginTop : '6px'}}>
                                            <div style={{minWidth : '8px', height : '5px', borderRadius : '1px', backgroundColor : '#9e9e9e'}} />
                                            <div style={{minWidth : '14px', height : '1px', borderRadius : '2px', backgroundColor : '#9e9e9e', marginLeft : '-4px', marginRight : '-4px'}} />
                                            <div style={{minWidth : '8px', height : '5px', borderRadius : '1px', backgroundColor : '#9e9e9e'}} />                        
                                        </SC.FCol>,
                                    type : DOCUMENT_ITEMTYPES.divider
                                },
                                {
                                    title : 'IFrame', 
                                    icon : 
                                        <SC.Icons.Icon_Button hasFill hasCursor>
                                            <ToolbarIcons.Web />
                                        </SC.Icons.Icon_Button>, 
                                    type : DOCUMENT_ITEMTYPES.iframe
                                },
                                {
                                    title : 'Figma Live Embed', 
                                    icon : 
                                        <SC.Icons.Icon_Button hasFill hasCursor>
                                            <SC.Icons.Figma size={14}/>
                                        </SC.Icons.Icon_Button>, 
                                    type : DOCUMENT_ITEMTYPES.figmaLiveEmbed
                                }, 
                                {
                                    title : 'Figma', 
                                    icon : 
                                        <SC.Icons.Icon_Button hasFill hasCursor>
                                            <SC.Icons.Figma size={14}/>
                                        </SC.Icons.Icon_Button>, 
                                    type : DOCUMENT_ITEMTYPES.figmaLiveEmbed,
                                    onClick : this.ShowFigmaMenu.bind(this)
                                },                                
                                // {
                                //     title : 'Storybook', 
                                //     icon : 
                                //         <StoryBookLogo style={{width : '24px'}} />,
                                //     type : DOCUMENT_ITEMTYPES.storybookEmbed
                                // },
                                {
                                    title : 
                                        <SC.FRow>
                                            <div>Component</div>
                                            <span style={{fontFamily : 'monospace', position : 'absolute', top : 0, right : '4px', fontSize : '10px'}}>[BETA]</span>
                                        </SC.FRow>, 
                                    icon : 
                                        <SC.Icons.Icon_Button hasFill hasCursor>
                                            <ToolbarIcons.Component />
                                        </SC.Icons.Icon_Button>, 
                                    type : DOCUMENT_ITEMTYPES.component,
                                    onClick : this.ShowComponents.bind(this)
                                },
                                {
                                    title : 'Starred Widgets', 
                                    icon : 
                                        <SC.Icons.Icon_Button hasFill hasCursor>
                                            <SC.Icons.FavoriteStar size={24}/>
                                        </SC.Icons.Icon_Button>, 
                                    type : DOCUMENT_ITEMTYPES.favorites,
                                    onClick : this.ShowFavorites.bind(this)
                                }, 
                            ].map((item) => {
                                return (
                                    <ElementBox onClick={item.onClick || this.CreateItem.bind(this, item.type, {bottom : MARGIN_DEFAULT, ...item.props})}>
                                        <SC.Div_Flex_Cell>
                                            {item.icon}
                                        </SC.Div_Flex_Cell>                
                                        {item.title}
                                    </ElementBox>
                                )
                            })
                        }
                    </div>                               
                </div>        
            </SC.FCol>
        )        
    }
}


const ElementBox = styled.div`
    display : flex;
    flex-direction : column;
    justify-content : space-evenly;
    padding : 4px;
    align-items : center;
    overflow : hidden;       
    transition : all 0.3s ease;
    border-radius : 2px;
    font-size : 12px;
    position : relative;    
    border : ${props => props.theme.border_ondark};
    background-color : ${props => props.theme.isLight ? props.theme.back_lighter : props.theme.back};
    padding : 4px;
    cursor : pointer;
    text-align : center;
    &: hover{
        // border : 1px solid ${props => props.theme.border_hover};
        // color : ${props => props.theme.font_hover};
        background-color : ${props => props.theme.isLight ? props.theme.back : props.theme.back_lightest};
        color : ${props => props.theme.font_hover};        
    }
`;
