import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    Globals
} from '../../../../../importer';

import styled from 'styled-components';
import DragSource from '../../../../../components/dragdrop/HoCDragSource';
import Strings from '../../../../../appstate/Strings';
import { MetaData, Toolabs_Engine } from '../../../../../toolabs-importer';
import VisibilitySensor from 'react-visibility-sensor';
import ToolbarItem, { DraggableToolbarItem } from '../toolbar/UIElements/element';

class ComponentItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }

    }  
    GetComponentMetaData(Id) {
        return Globals.ProjectManager.Publisher.Component(Id);
    }
    render() { 
        const {componentId} = this.props;
        const item_name = Utils.UseNullOrEmpty(Globals.ProjectManager.GetComponentName(componentId), 'Component').toString();

        let nodeNameItem;

        if (this.EditingName) {
            // nodeNameItem = (
            //     <SC.FRow f1 style={{marginRight : '8px'}}>
            //         <NameEditor
            //             onClose={() => {
            //                 delete this.EditingName;
            //                 this.RCUpdate();
            //             }}
            //             onNameChange={this.onNameChange.bind(this)}
            //             onValidate={this.onValidateName.bind(this)}
            //             name={item_name}
            //         />
            //     </SC.FRow>                
            // )
        }
        else {
            nodeNameItem = (
                <SC.TextSpanAbbr style={{flex : 1, fontSize : '11px', textAlign : 'center', marginBottom : '4px'}}>
                    {item_name}
                </SC.TextSpanAbbr>
            )
        }

        let style = {};

        let content;

        if (!this.props.listView) {
            style.height = 'auto';
            style.flexDirection = 'column';
            style.alignItems = 'stretch';
            content = (
                <VisibilitySensor 
                    partialVisibility={true} 
                >
                    {
                        ({isVisible}) => {
                            if (isVisible) {
                                return (
                                    <SC.FCol alc style={{paddingLeft : '4px', paddingRight : '4px', paddingTop : '8px', paddingBottom : '8px', overflow : 'hidden'}}>
                                        <Toolabs_Engine.Component
                                            Id={componentId}
                                            Store={this}
                                            width={200}
                                            height={60}
                                            Preview
                                            Disabled
                                            AutoScale
                                            ThemeId={Globals.ProjectManager.CurrentTheme.ThemeId}
                                            ActiveTheme={{...Globals.ProjectManager.CurrentTheme, Theme : Utils.JustGet(Globals.ProjectManager.CurrentTheme.Theme, {}, Globals.ProjectManager.CurrentState)}}
                                            GlobalVariations={this.props.GlobalState}
                                            PreviewTokens={this.props.PreviewTokens}
                                            backgroundColor={this.props.backgroundColor}
                                        />                                                                                                        
                                    </SC.FCol>
                                )                                             
                            }
                            return (
                                <div style={{minHeight : '40px', flex : 1}} />
                            )
                        }
                    }
                </VisibilitySensor> 
            )
        }
        else {

        }
        if (this.props.draggable) {
            return (
                <DraggableToolbarItem
                    customContent
                    DragItem={{
                        Type: Strings.TOOLBARITEM,
                        Item: {
                            MetaItem : {
                                Type: MetaData.Components.Component.Type,
                                Id: componentId
                            }
                        }
                    }}
                >
                    <SC.FCol f1>
                        {nodeNameItem}
                        {content}
                    </SC.FCol>                
                </DraggableToolbarItem>
            )
        }

        return (
            <ToolbarItem 
                customContent
                onClick={this.props.onSelect} 
                style={{cursor : 'pointer'}}
            >
                <SC.FCol f1>
                    {nodeNameItem}
                    {content}
                </SC.FCol>
            </ToolbarItem>
        )
        

        return (            
            <DraggableNode 
                style={style} 
                onClick={this.props.onSelect} 
                onDoubleClick={this.EditNode}
                selected={this.props.selected}
                OnDragEnd={this.props.OnDragEnd}
                DragItem={{
                    Type: Strings.TOOLBARITEM,
                    Item: {
                        MetaItem : {
                            Type: MetaData.Components.Component.Type,
                            Id: componentId
                        }
                    }
                }}
            >
                <SC.FRow f1 overflowHidden>
                    {nodeNameItem}
                </SC.FRow>
                {content}                                
            </DraggableNode>
        )        
    }
}
 
export default ComponentItem;

const NodeStyle = styled.div`
    position : relative;
    display : flex;
    height : 26px;
    box-sizing : border-box;
    align-items : center;
    padding : 8px;    
    border-left : 1px solid ${props => props.selected ? props.theme.color_brand : 'transparent'};
    background-color : ${props => props.selected ? props.theme.back_lighter : 'initial'};
    transition : 0.2s ease;
    transition-property : background-color;
    border-top : ${props => props.theme.border_ondark};
    &:hover {
        background-color : ${props => props.theme.back_lighter};
    }
`;

const DraggableNode = DragSource(NodeStyle);