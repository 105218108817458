import {
    AppState,
    Utils,
    Globals
} from '../../../../../importer';
import Strings from '../../../../../appstate/Strings';

export const ARTBOARD_ITEMTYPES = {
    COMPONENT : Strings.COMPONENT,
    DEVICE : 'Device',
    COMPONENT_CONTAINTER : 'ComponentContainer',
    ARTBOARD_COMPONENT : 'ArtboardComponent'
}

export default class ArtboardManager {
    constructor() {

    }

    Load(Id) {
        this.Id = Id;
        this.Data = Globals.ProjectManager.GetArtboardData(Id);

        const that_manager = this;

        this.DataManager = {            
            SetPublishedId(Id, ChangeId) {
                if (!ChangeId)
                    return;
                AppState.GetDataApi().update_data_artboard(Globals.ProjectManager.Id, Id, 'PublishedId', ChangeId);
            },
            GetId() {
                return that_manager.Id;
            },
            GetData() {
                return that_manager.Data;
            },
            Set(Value, ...Path) {                    
                const ChangeId = Utils.Id();
                if (!that_manager.Data)
                    return;    
                Utils.Set(that_manager.Data, Utils.UseUndefined(Value, null), ...Path);
                Utils.Set(that_manager.Data, ChangeId, 'ChangeId');
                AppState.GetDataApi().update_data_artboard(Globals.ProjectManager.Id, that_manager.Id, 'ChangeId', ChangeId);
                AppState.GetDataApi().update_data_artboard(Globals.ProjectManager.Id, that_manager.Id, Path, Utils.UseUndefined(Value, null));
            },
            Delete(...Path) {          
                const ChangeId = Utils.Id();  
                if (!that_manager.Data)
                    return;
                Utils.UnSet(that_manager.Data, ...Path);
                Utils.Set(that_manager.Data, ChangeId, 'ChangeId');
                AppState.GetDataApi().update_data_artboard(Globals.ProjectManager.Id,that_manager.Id, 'ChangeId', ChangeId);
                AppState.GetDataApi().delete_data_artboard(Globals.ProjectManager.Id,that_manager.Id, Path);
            },
            Get(DefValue, ...Path) {
                return Utils.Get(that_manager.Data, DefValue, ...Path);         
            },
        };
        

        if (!this.Data.Items) {
            const Items = {};
            const components = Globals.ProjectManager.GetComponentModels();
            let x = 80, y = 80, gap_x = 100, gap_y = 80;
            let maxHeightInRow = 0;
            Utils.ForEach(components, (cmodel, cid) => {
                const ArtboardItem = {
                    type : Strings.COMPONENT,
                    id : cid,
                    position : {
                        x : x,
                        y : y
                    },
                    size : this.GetComponentSize(cid)
                };
                                                        
                Items[Utils.Id()] = ArtboardItem;
                // x += ArtboardItem.size.width + gap_x;
                // maxHeightInRow = Math.max(maxHeightInRow, ArtboardItem.size.height);
                // if (x > 2000) {
                //     x = 0;
                //     y += maxHeightInRow + gap_y;
                //     maxHeightInRow = 0;
                // }
                y += ArtboardItem.size.height + gap_y;
            });
            this.DataManager.Set(Items, 'Items');
        }
    }

    GetOptions(defaultValue, ...path) {
        return this.DataManager.Get(defaultValue, 'Options', ...path);
    }
    SetOptions(value, ...path) {
        this.DataManager.Set(value, 'Options', ...path);
    }

    GetComponentSize(cid) {
        let size = {
            width : 400,
            height : 200
        };
        const componentData = Globals.ProjectManager.GetComponentData(cid);
        if (componentData.PreviewSize) {
            size.width = componentData.PreviewSize.width;
            size.height = componentData.PreviewSize.height;
        }
        else {
            const RootItem = Utils.JustGet(componentData, null, 'MetaItems', Utils.JustGet(componentData, null, 'RootItem', 'Id'));
        
            const defaultdevice = Utils.JustGet(componentData, {width : 400, height : 400}, 'Device', 'Default');
            size = {
                width : Utils.UseNull(defaultdevice.width, 400),
                height : Utils.UseNull(defaultdevice.height, 400)
            }

            if (RootItem) {
                const width_value = Utils.JustGet(RootItem, 400, 'Style', 'Default', 'Default', 'width', 'value');
                const width_unit = Utils.JustGet(RootItem, 'px', 'Style', 'Default', 'Default', 'width', 'Unit');
                if (width_unit === 'px') {
                    size.width = width_value;
                }
                const height_value = Utils.JustGet(RootItem, 400, 'Style', 'Default', 'Default', 'height', 'value');
                const height_unit = Utils.JustGet(RootItem, 'px', 'Style', 'Default', 'Default', 'height', 'Unit');
                if (height_unit === 'px') {
                    size.height = height_value;
                }
            }   

            if (size.height === 'auto')
                size.height = 400;
            if (size.width === 'auto')
                size.width = 400;
        }
        return size;
    }
    GetItems() {
        return Utils.Get(this.Data, {}, 'Items');
    }
    GetItem(Id) {
        return this.GetItems()[Id];
    }
    SetItemProp(id, prop, value) {
        this.DataManager.Set(value, 'Items', id, prop);
    }
    AddItem({type, id, x, y, width, height}) {
        const newid = Utils.Id();
        const item = {
            type : type,
            id : id,
            position : {
                x : x,
                y : y,
            },
            size : {
                width : width,
                height : height
            }
        };        
        this.DataManager.Set(item, 'Items', newid);
        return newid;
    }
    DeleteItem(id) {
        const Item = this.GetItem(id);
        if (Item && Item.type === ARTBOARD_ITEMTYPES.COMPONENT_CONTAINTER) {
            const ContainerItems = Utils.JustGet(Item, [], 'Items');
            Utils.ForEach(ContainerItems, (ChildItem, i) => {
                this.DataManager.Delete('Items', ChildItem.id);        
            });
        }
        this.DataManager.Delete('Items', id);
    }
    GetNewItemPosition(items) {
        let y = 999999;
        let x = -999999;
        Utils.ForEach(items, (item, itemid) => {
            const pos = Utils.JustGet(item, {x : 0, y : 0}, 'position');
            const size = Utils.JustGet(item, {width : 400}, 'size');
            y = Math.min(y, pos.y);
            x = Math.max(x, pos.x + size.width);
        });
        return {
            x : x + 40,
            y : y
        };
    }    
}