import React from 'react';
import {
    ReactBaseComponent,
    SC,
    UIUtils,
    Utils,
    AppState
} from '../../importer';

import styled, {css} from 'styled-components';
import {Editor} from 'slate-react';
import autosize from "autosize";
import { PlainSlateTextParser } from '../../views/project/documents/designer/item/text';

const ValueInput = styled.input`
    color : ${props => props.theme.font_small};
    font-size : 16px;
    padding-left : 0px;
    padding-right : 0px;
    line-height : 30px;
    letter-spacing : 0.5px;        
    border : none;
    background-color : transparent;        
    border-bottom : 0.5px solid #35363A;
    width : 100%;
    box-sizing : border-box;
    font-style : ${props => Utils.IsNotNullOrEmpty(props.value) ? 'normal' : 'italic'};
    &:focus {
        border-bottom : 0.5px solid ${props => props.theme.stylepanel_nameinput_border_color};    
    }
`;

export const NoteArea = styled.div`
    background-color : ${props => props.dark ? props.theme.input_back : props.theme.back};
    padding : 8px;
    min-height : ${props => props.minHeight || 100}px;
    font-size : 12px;
    width : 100%;
    border : ${props => props.focused ? props.theme.border_brand : (props.noBorder ? '1px solid transparent' : props.theme.border_ondark)};
    border-radius : 2px;        
    overflow : hidden;
    display : flex;
    box-sizing : border-box;
    &:hover {
        border : ${props => props.focused ? props.theme.border_brand : props.theme.border_ondark};
    }
`;

class TextValue extends ReactBaseComponent
{
    constructor(props) {
        super(props);
        this.onChange = Utils.Throttle(this.onChange.bind(this), 250);
        this.onChangeNote = this.onChangeNote.bind(this);
        this.onChanging = this.onChanging.bind(this);
        this.onChangingValue = this.onChangingValue.bind(this);
        this.OnKeyUp = this.OnKeyUp.bind(this);
        this.OnKeyDown = this.OnKeyDown.bind(this);
        this.onFocused = this.onFocused.bind(this);
        this.onBlured = this.onBlured.bind(this);

        this.Ref_TextArea = React.createRef();

        this.value = Utils.UseNull(this.props.value, '')
    }
    componentDidMount() {
        super.componentDidMount();

        if (this.props.multiline) {
            autosize(this.Ref_TextArea.current);
        }
        if (this.props.autoFocus) {
            setTimeout(() => {
                this.Ref_Input && this.Ref_Input.focus();
            }, 10);
        }          
    }
    componentWillUnmount() {
        super.componentWillUnmount();
        AppState.Designer.EditingText = false;
    }
    onFocused() {
        this.IsFocused = true;
        AppState.Designer.SetEditingText('text value');;
        this.RCUpdate();
    }
    onBlured() {
        this.IsFocused = false;
        AppState.Designer.EditingText = false;
        this.props.onBlured && this.props.onBlured(this.value);
        this.RCUpdate();
    }
    onChanging(e) {
        this.value = e.target.value;
        if (this.props.onChanging)
            this.props.onChanging(this.value);
        else
            this.onChange();
        this.RCUpdate();        
    }    
    onChangingValue(value) {
        this.value = value;
        if (this.props.onChanging)
            this.props.onChanging(this.value);
        else
            this.onChange();
        this.RCUpdate();     
    }
    onChange() {
        if (this.value !== this.props.value)
            this.props.onChange && this.props.onChange(this.value);
    }
    onChangeNote(s) {
        this.value = s;
        this.IsFocused = false;
        AppState.Designer.EditingText = false;
        this.onChange();
        this.RCUpdate();    
    }
    OnKeyDown(e) {
        e.stopPropagation();
        return true;
    }
    OnKeyUp(e) {                
        e.stopPropagation();
        if (Utils.IsDeleteCode(e.keyCode)) {
            this.value = null;
            this.onChange(null);
        }
        else if (this.props.onCancel && e.keyCode === 27) {
            this.props.onCancel();
        }
        return true;
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (!this.IsFocused && nextProps.value !== this.value)
            this.value = nextProps.value;
        return true;
    }
    renderCustom() {
        let editor = null;
        if (this.props.multiline) {
            editor = (
                <NoteArea minHeight={this.props.minHeight || 30} style={{padding : 0, ...this.props.boxStyle}} focused={this.IsFocused} dark={this.props.dark} noBorder={this.props.noBorder} onMouseDown={this.props.onMouseDown}>
                    <textarea
                        style={Utils.Merge({ height: '100%', width: '100%', padding : '8px', backgroundColor : 'unset', border : 'none', resize : 'none', fontSize : '12px', lineHeight : '18px', color : SC.CurrentTheme.theme.isLight ? 'unset' : '#b5b4b4' },this.props.style)}
                        onBlur={this.onBlured}
                        onFocus={this.onFocused}
                        onChange={this.onChanging}      
                        ref={this.Ref_TextArea}
                        placeholder={this.props.placeholder || "type some text"}
                        rows={1}
                        value={this.value}
                        spellCheck={false}
                    />
                </NoteArea>
                
            )
            // editor = (
            //     <NoteArea minHeight={30} style={{padding : 0}} focused={this.IsFocused}>
            //         <UIUtils.Utils.ContentEditable
            //             html={`<span>${this.value || ''}</span>`}
            //             style={Utils.Merge({ height: '100%', width: '100%', padding : '8px' },this.props.style)}
            //             disabled={false}
            //             onBlur={this.onChangeNote}
            //             onFocus={this.onFocused}
            //             onChange={this.onChanging}                        
            //             plainText
            //         />
            //     </NoteArea>
            // )
            
            // editor = (
            //     <NoteArea minHeight={30} style={{padding : 0}}>
            //         <MultilineTextInput
            //             value={this.value}
            //             style={Utils.Merge({ height: '100%', width: '100%', padding : '8px' },this.props.style)}
            //             onChange={this.onChangeNote}
            //             onFocus={this.onFocused}
            //             onChanging={this.onChangingValue}
            //             plainText
            //         />
            //     </NoteArea>
            // )

            // editor = (
            //     <Editor
            //         placeholder="Enter some plain text..."
            //         defaultValue={this.InitialValue}
            //     />
            // )
            
        }
        else {
            editor = (
                <ValueInput 
                    value={this.value}
                    onFocus={this.onFocused}
                    onBlur={this.onBlured}
                    onChange={this.onChanging}
                    onKeyUp={this.OnKeyUp}
                    onKeyDown={this.OnKeyDown}
                    placeholder={this.props.placeholder}
                    style={this.props.style}
                    ref={(r) => this.Ref_Input = r}
                    spellCheck={false}
                />
            );
        }
        if (this.props.justEditor)
            return editor;
        return (
            <SC.FCol f1={!this.props.notF1}>
                {
                    this.props.label ? 
                    <div hasCursor={this.props.onSelectStyle ? true : false} onClick={this.props.onSelectStyle}>
                        {this.props.label}
                    </div> : null
                }  
                {editor}
            </SC.FCol>     
        );
    }
}

export default TextValue;

