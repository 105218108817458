import React from 'react';
import {
    SC,
    Globals,
    Utils,
    AppState,
    AppLayout
} from '../../../../../../../importer';

import { GroupTitle } from '../../../../left/designsystem/common';
import { ShadowCardItem, ShadowListItem, AddNewShadowToken, GetShadowTokenList, GetShadowTokenItem } from '../../../../left/designsystem/shadows';
import ShadowTokenEditor from '../../../../left/designsystem/shadows/item';
import { ListCardView } from '../../../../../../../components/light_dark_bar';
import BaseSelectToken from '../BaseSelectToken';
import ComponentDesignManager from '../../../../../component/manager';
import { GetTokenAliaseList } from '../../../../left/designsystem/aliases';
import SelectToken from '..';
import { motion } from 'framer-motion';

export default class SelectShadowToken extends BaseSelectToken
{
    constructor(props) {
        super(props);

        this.titleNewToken = 'NEW SHADOW';
        this.moduleName = 'Shadows';
        this.tokenType = Globals.ProjectManager.Tokens.Types.Shadows;        
        this.hasAliases = !this.props.forAliase;
        this.state.isAliaseListView = true;

        this.SelectColor = this.SelectColor.bind(this);
        this.EditColor = this.EditColor.bind(this);

        this.Ref_Editor = React.createRef();

        this.Load(this.props);
    }
    IsTokenPropChanged(nextProps, nextState) {
        if (Utils.HasAnyChange(this.props, nextProps, 'GlobalState')) {
            this.Load(nextProps);
            return true;
        }
        return this.props.textShadow !== nextProps.textShadow || this.props.boxShadow !== nextProps.boxShadow;
    }
    GetTokens(props) {
        const result = GetShadowTokenList(props.StateArray);
        
        this.aliases = [];
        const aliases = GetTokenAliaseList(Globals.ProjectManager.Tokens.Types.Shadows);        
        
        if (this.props.excludeIds) {
            this.props.excludeIds.map((excludeId) => {
                Utils.Remove(aliases, (item) => {return item.id === excludeId});
            })            
        }

        Utils.ForEach(aliases, (aliase, i) => { 
            if (aliase.tokenId) {
                let tokenItem;

                if (aliase.isAbsolute) {
                    tokenItem = GetShadowTokenItem({id : aliase.tokenId, statearray : props.StateArray});
                }
                else {
                    if (this.props.isTextShadow)
                        tokenItem = Utils.Find(result.TextShadows, (item) => {return item.id === aliase.tokenId});
                    else
                        tokenItem = Utils.Find(result.BoxShadows, (item) => {return item.id === aliase.tokenId});
                    
                }                
                
                if (tokenItem) {
                    aliase.tokenItem = tokenItem;
                    this.aliases.push(aliase);
                }
            }
        });

        if (this.props.textAndBox) {
            return [...result.BoxShadows, ...result.TextShadows];
        }
        if (this.props.isTextShadow)
            return result.TextShadows;
        return result.BoxShadows;
    }            
    GetNewModel(Id) {
        const Model = {
            name : 'New Shadow',
            values : [],
            textShadow : this.props.isTextShadow
        }
        AddNewShadowToken(Model, Id);
        this.FireSelectToken(Id);
        return Model;
    }
    SaveToken() {
        if (this.EditingToken.IsNew) {
            const shadowItem = AddNewShadowToken(this.EditingToken.Model, this.EditingToken.Id);
            AppLayout.Refs.DesignSystem.Shadows && AppLayout.Refs.DesignSystem.Shadows.Reload();
            return shadowItem;
        }        
        else {
        }                
    }   
    onCloseTokenEditor() {
        if (this.EditingToken && this.EditingToken.IsNew) {
            delete this.EditingToken;            
            this.RCUpdate();
            return;
        }
        super.onCloseTokenEditor();
    }
    onCancelAddToken() {
        const willDeleteId = this.EditingToken.Id;
        this.FireSelectToken(this.LastBoundTokenId);
        delete this.LastBoundTokenId;
        super.onCancelAddToken();
        Globals.ProjectManager.Tokens.Delete(Globals.ProjectManager.Tokens.Types.Shadows, willDeleteId);
    }
    UpdateSelectedToken() {
        if (this.EditingToken) {
            const SelectedToken = Utils.Find(this.tokens, (token) => {return token.id === this.EditingToken.Id});
            const token = Globals.ProjectManager.Tokens.Token(this.EditingToken.Id);
            const shadows = Utils.JustGet(Globals.ProjectManager.Tokens.ValueOf({model : token, statearray : this.props.StateArray}), [], 'values');
            SelectedToken.value = Utils.GetShadowCss(shadows, token.textShadow, Globals.ProjectManager.Tokens.ValueOfId); 
        }
    }
    GetStyleName() {
        return this.props.isTextShadow ? 'textShadow' : 'boxShadow';
    }
    renderTokenAliaseListHeader() {
        return (
            <GroupTitle 
                title='SEMANTIC TOKENS' 
                hasSearch={{onSearch : this.SearchAliase}}
                hasAdd={{onAdd : this.AddTokenAlias}}
                addOn={(
                    <SC.FRow>
                        <ListCardView 
                            onListView={this.SetAliaseListMode.bind(this, true)}
                            onCardView={this.SetAliaseListMode.bind(this, false)}
                            isListView={this.state.isAliaseListView}
                            isCardView={!this.state.isAliaseListView}
                            style={{marginRight : '4px'}}
                            compact
                        />
                    </SC.FRow>                    
                )}
                style={{padding : 0, paddingLeft : '4px', marginBottom : '4px'}}
            />
        )
    }

    renderTokenAliases() {
        let content;
        const styleName = this.GetStyleName();
        if (this.state.isAliaseListView) {
            const shadows = [];

            Utils.ForEach(this.aliases, (aliase, i) => {
                shadows.push(
                    <ShadowListItem
                        key={aliase.id}
                        notDraggable
                        name={aliase.name}
                        shadow={aliase.tokenItem}
                        textShadow={aliase.tokenItem.textShadow}
                        onSelect={this.SelectToken.bind(this, aliase.id)}
                        selected={aliase.id === this.GetSelectedTokenId()}
                        onPreview={{
                            onMouseEnter : this.PreviewToken.bind(this, true, aliase.id, styleName),
                            onMouseLeave : this.PreviewToken.bind(this, false, aliase.id, styleName)
                        }}
                    />
                )
            });

            content = (
                <SC.FCol>
                    {shadows}
                </SC.FCol>
            )
        }
        else {
            const shadows = [];
            Utils.ForEach(this.aliases, (aliase, i) => {
                shadows.push(
                    <ShadowCardItem
                        shadow={aliase.tokenItem}
                        textShadow={aliase.tokenItem.textShadow}
                        notDraggable
                        key={aliase.id} 
                        onClick={this.SelectToken.bind(this, aliase.id)}
                        selected={aliase.id === this.GetSelectedTokenId()}
                        onPreview={{
                            onMouseEnter : this.PreviewToken.bind(this, true, aliase.id, styleName),
                            onMouseLeave : this.PreviewToken.bind(this, false, aliase.id, styleName)
                        }}
                    />
                )
            });

            content = (
                <SC.FRow style={{flexWrap : 'wrap'}}>
                    {shadows}
                </SC.FRow>
            )
        }

        return content; 
    }
    renderTokenList() {
        let content;
        const styleName = this.GetStyleName();
        if (this.state.isListView) {
            const shadows = [];

            Utils.ForEach(this.tokens, (shadow, i) => {
                shadows.push(
                    <ShadowListItem
                        key={shadow.id}
                        notDraggable
                        shadow={shadow}
                        textShadow={shadow.textShadow}
                        onSelect={this.SelectToken.bind(this, shadow.id)}
                        selected={shadow.id === this.GetSelectedTokenId()}
                        onPreview={{
                            onMouseEnter : this.PreviewToken.bind(this, true, shadow.id, styleName),
                            onMouseLeave : this.PreviewToken.bind(this, false, shadow.id, styleName)
                        }}
                    />
                )
            });

            content = (
                <SC.FCol>
                    {shadows}
                </SC.FCol>
            )
        }
        else {
            const shadows = [];
            Utils.ForEach(this.tokens, (shadow, i) => {
                shadows.push(
                    <ShadowCardItem
                        shadow={shadow}
                        textShadow={shadow.textShadow}
                        notDraggable
                        key={shadow.id} 
                        onClick={this.SelectToken.bind(this, shadow.id)}
                        selected={shadow.id === this.GetSelectedTokenId()}
                        onPreview={{
                            onMouseEnter : this.PreviewToken.bind(this, true, shadow.id, styleName),
                            onMouseLeave : this.PreviewToken.bind(this, false, shadow.id, styleName)
                        }}
                    />
                )
            });

            content = (
                <SC.FRow style={{flexWrap : 'wrap'}}>
                    {shadows}
                </SC.FRow>
            )
        }

        return content; 
    }
    EditColor(shadow, onChange, onClosed) {
        this.showColorSelector = {
            type : AppState.ItemTypes.BOARD.COLOR,
            title : 'SHADOW COLOR',
            noGradient : true,
            justEditor : true,
            itemId : shadow.id,
            color : shadow.color,
            onChange : onChange,            
            onClosed : onClosed
        };            
        this.RCUpdate();
    }
    SelectColor(onSelect, onPreview, onClosed) {
        this.showColorSelector = {
            type : AppState.ItemTypes.BOARD.COLOR,
            title : 'SHADOW COLOR',
            noGradient : true,
            onSelect : (tokenId, isGradient) => {
                onSelect(tokenId)
            },
            onPreviewToken : ({show, tokenId}) => {
                onPreview(tokenId, show);
            },
            onPreviewNewToken : () => {
                if (this.Ref_Editor.current) 
                    this.Ref_Editor.current.RCUpdate();
                ComponentDesignManager.Active().ComponentManager && ComponentDesignManager.Active().ComponentManager.UpdateRendererNewTokenPreview(this.props.Id, this.EditingToken.Id);
            },
            onClosed : onClosed
        };            
        this.RCUpdate();
    }
    renderTokenEditor() {
        return (
            <React.Fragment>
                <ShadowTokenEditor 
                    ref={this.Ref_Editor}
                    id={this.EditingToken.Id}
                    newModel={this.EditingToken.IsNew ?  this.EditingToken.Model : null}  
                    GlobalState={Globals.ProjectManager.GlobalState}                      
                    offline={this.EditingToken.IsNew}
                    onPreviewChange={this.EditingToken.IsNew ? this.PreviewNewToken : null}
                    onSelectColor={this.SelectColor}
                    onEditColor={this.EditColor}
                />              
                {
                    this.showColorSelector &&
                    <motion.div 
                        style={{
                            ...SC.Styles.Absolute, 
                            zIndex : 999999999, 
                            right : '100%',
                            left : 'unset',
                            boxSizing : 'border-box',
                            width : '280px' ,
                            borderRight : SC.CurrentTheme.theme.border_ondark,
                            borderLeft : SC.CurrentTheme.theme.border_ondark,
                            backgroundColor : SC.CurrentTheme.theme.back,
                        }}
                        initial={{opacity : 0.7, x : 24}}
                        animate={{opacity : 1, x : 0}}
                        exit={{opacity : 0, x : 24}}
                        transition={{duration : 0.1}}
                    >
                        <SC.FCol fw fh>
                            <SC.FCol f1 style={{backgroundColor : SC.CurrentTheme.theme.back}}>
                                <SelectToken                                         
                                    onClose={this.CloseTokenSelector}
                                    onPreviewToken={this.PreviewToken}
                                    GlobalState={this.props.GlobalState}
                                    GlobalStateId={this.props.GlobalStateId}
                                    themeId={this.props.themeId}
                                    onSetPropertyValues={this.SetItemPropertyValues}
                                    onBindToModel={this.BindToModel}
                                    GetComponentManager={this.GetComponentManager}
                                    GetMetaItem={this.GetMetaItem}
                                    onClose={() => {
                                        delete this.showColorSelector;
                                        this.RCUpdate();
                                    }}
                                    onClosed={() => {                                            
                                    }}
                                    {...this.showColorSelector}
                                />
                            </SC.FCol>                    
                        </SC.FCol>
                    </motion.div>     
                }
            </React.Fragment>   
        )        
    }
    GetSelectedTokenId() {
        if (this.props.propName === 'tokenId')
            return this.props.tokenId;
        return this.props.isTextShadow ? this.props.textShadow : this.props.boxShadow;
    }
    renderSelectedToken() {
        if (this.GetSelectedTokenId()) {
            return this.renderSelectedTokenHeader();
        }
    }
}
