import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    AppLayout,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../../../../../importer';

import { GetGradientTokenList, GetGradientTokenListOfIds } from '../../../../../panels/left/designsystem/colors';
import {DocumentTableCell} from '../colors';


export default class DocumentToken_Colors extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {

        };    
    }
    shouldComponentUpdate(nextProps, nextState) {

        if (this.state.ShouldUpdate !== nextState.ShouldUpdate)
            return true;
        
        if (Utils.HasAnyChange(this.props, nextProps, 'GlobalThemeId', 'GlobalState', 'StyleId', 'RenderId', 'LoadId', 'themeType', 'states', 'ReversedStyleState'))
            return true;
        
        return false;
    }
    componentDidUpdate(prevProps, prevState) {
        this.state.ShouldUpdate = false;
    }
    render() {        
        const colors = this.props.selection.type === 'selected' ? GetGradientTokenListOfIds(this.props.selection.tokenIds, this.props.ReversedStyleState) : GetGradientTokenList(this.props.ReversedStyleState);
        const colorItems = [];

        const scaleFactor = (this.props.cardSize || 100) / (this.props.viewMode === 'card' ? 100 : 200);
        const cardMinWidth = 100 * scaleFactor;
        const cardMaxWidth = 120 * scaleFactor;
        const dividerMargin = 32 * scaleFactor;        
        
        Utils.ForEach(colors, (color, ) => {
            if (this.props.viewMode === 'card') {
                colorItems.push(
                    <DocumentGradientItem_Card key={color.id} color={color.value} name={color.name} />
                )
            }
            else {
                colorItems.push(
                    DocumentGradientItem_Cell({color : color.value, name : color.name, cardMinWidth : cardMinWidth, dividerMargin : dividerMargin,  dividerColor : this.props.textStyles.dividerColor})
                );
            }            

            if (this.props.forTemplateSelect && colorItems.length === 3) {
                return false;
            }
        });
        

        if (this.props.viewMode === 'card') {
            return (
                <div style={{
                    display : 'grid',
                    gridGap : '16px',
                    gridTemplateColumns : `repeat(auto-fill, minmax(${cardMinWidth}px, 1fr))`,
                    gridAutoRows : Utils.px(cardMaxWidth),
                    alignSelf : this.props.alignment,
                    width : this.props.size ? Utils.px(this.props.size, '%') : 'auto'
                }}
                >
                    {colorItems}
                </div>
            )   
        }
        else {
            return (
                <div                     
                    style={{
                        display : 'grid',
                        gridTemplateColumns : 'auto 1fr',     
                        gridTemplateRows : 'auto',                   
                        width : this.props.size ? Utils.px(this.props.size, '%') : 'auto',
                        justifyContent : this.props.alignment,
                        alignItems : 'center',
                        alignSelf : this.props.alignment,
                    }}
                >
                    {colorItems}
                </div>
            )   
        }

    }
}

export const DocumentGradientItem_Card = ({color, name}) => {
    return (
        <SC.FCol>
            <div style={{background : color, border : SC.CurrentTheme.theme.border_seperator, flex : 1, borderRadius : '4px', marginBottom : '8px'}}>

            </div>
            <SC.TextDivAbbr>{name}</SC.TextDivAbbr>
        </SC.FCol> 
    )
}

export const DocumentGradientItem_Cell = ({color, name, cardMinWidth, dividerMargin, dividerColor}) => {
    return [
        <DocumentTableCell style={{background : color, border : SC.CurrentTheme.theme.border_seperator, flex : 1, borderRadius : '4px', marginBottom : '8px', width : Utils.px(cardMinWidth), minHeight : Utils.px(cardMinWidth)}} />,        
        <div style={{
            display : 'grid',
            gridColumnGap : '16px',
            gridTemplateColumns : `repeat(auto-fill, minmax(120px, 1fr))`,
        }}>
            <DocumentTableCell style={{fontFamily : 'monospace'}}>{name}</DocumentTableCell>
        </div>,
        <div style={{
            gridColumn : 'span 2',
            height : '1px',
            backgroundColor : dividerColor,
            marginTop : Utils.px(dividerMargin),
            marginBottom : Utils.px(dividerMargin)
        }} 
        />
    ]
}