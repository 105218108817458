import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    MetaData,
    Globals
} from '../../../../../../../../importer';
import { StyleGroupTokenItem, SmallButtonGroup, StyleToken_Color, Seperator, StyleGroupTitle, StyleGroup } from '../../common';
import GenericCollections from './collection';
import { PanelHeaderAddButton } from '../../../../../left/designsystem/common';

export default class GenericSubComponent extends ReactBaseComponent
{
    constructor(props) {
        super(props);

    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.Id !== this.props.Id || nextProps.ManagerId !== this.props.ManagerId) {
            this.LoadComponent(nextProps);
            return true;
        }
        if (this.props.component.Id !== nextProps.component.Id) {
            this.LoadComponent(nextProps);
            return true;
        }
        
        if (Utils.HasAnyChange(this.props, nextProps, 'component')) {
            this.LoadComponent(nextProps);
            return true;
        }
            

        if (this.props.GlobalStateId !== nextProps.GlobalStateId)
            return true;        
        return super.ShouldUpdate(nextProps, nextState);
    }    
    LoadComponent(props) {
        
    }
    renderCustom() {
        let content;

        if (this.props.component.Generic.Type === MetaData.Components.Generic.Carousel.Type || 
            this.props.component.Generic.Type === MetaData.Components.Generic.Tab.Type ||
            this.props.component.Generic.Type === MetaData.Components.Generic.Accordion.Type) {
            content = (
                <GenericCollections
                    {...this.props}
                    title='SLIDES'
                />
            )
        }

        return (
            <React.Fragment>
                <Seperator />                    
                <StyleGroup>
                    {content}
                </StyleGroup>
            </React.Fragment>
        )
    }
}