export default {
  json : `  "durations" : [
{{#eachJson durations}}
  "name" : "{{name}}",
      "duration" : "{{duration}}"
{{/eachJson}}
\n  ],
  "easeCurves" : [
{{#eachJson easeCurves}}
  "name" : "{{formattedName}}",
      "curve" : {
        "x1" : {{x1}},
        "y1" : {{y1}},
        "x2" : {{x2}},
        "y2" : {{y2}}
      }
{{/eachJson}}
\n  ],
  "transitions" : [
{{#eachJson transitions}}
  "name" : "{{formattedName}}",      
      "duration" : "{{duration}}",
      "delay" : "{{delay}}",
      "curve" : {
        "x1" : {{x1}},
        "y1" : {{y1}},
        "x2" : {{x2}},
        "y2" : {{y2}}
      }
{{/eachJson}}
\n  ]`,
jsonLookup : `{
  "durations" : {
  {{#each durations}}
    "{{formattedName}}" : {
      "name" : "{{name}}",
      "duration" : "{{duration}}"
    },
  {{/each}}
  },
  "easeCurves" : {
  {{#each easeCurves}}
    "{{formattedName}}" : {
      "name" : "{{name}}",
      "curve" : {
        x1 : {{x1}},
        y1 : {{y1}},
        x2 : {{x2}},
        y2 : {{y2}}
      }
    },
  {{/each}}
  },
  "transitions" : {
  {{#each transitions}}
    "{{formattedName}}" : {
      "name" : "{{name}}",
      "duration" : "{{duration}}",
      "delay" : "{{delay}}",
      "curve" : {
        x1 : {{x1}},
        y1 : {{y1}},
        x2 : {{x2}},
        y2 : {{y2}}
      }      
    },
  {{/each}}
  }
}`,
      css : `/* durations */
:root {
{{#each durations}}
  --{{formattedName}}: {{duration}};
{{/each}}
}
/* ease curves*/
:root {
{{#each easeCurves}}
  --{{formattedName}}: cubic-bezier({{x1}}, {{y1}}, {{x2}}, {{y2}});
{{/each}}
}
/* transitions*/
:root {
{{#each transitions}}
  --{{formattedName}}: {{durationVariable}} {{easeCurveVariable}};
{{/each}}
}
`,
js : `/* durations */
{{#each durations}}
const {{formattedName}} = "{{duration}}";
{{/each}}
/* ease curves*/
{{#each easeCurves}}
const {{formattedName}} = {
  x1 : "{{x1}}",
  y1 : "{{y1}}",
  x2: "{{x2}}",
  y2 : "{{y2}}",
};
{{/each}}
/* transitions */
{{#each transitions}}
const {{formattedName}} = {
  duration : "{{duration}}",
  delay : "{{delay}}",
  easeCurve : "cubic-bezier({{x1}}, {{y1}}, {{x2}}, {{y2}})"
};
{{/each}}
`,
less : `/* durations */
{{#each durations}}
@{{formattedName}}: {{duration}};
{{/each}}
/* ease curves*/
{{#each easeCurves}}
@{{formattedName}}:  cubic-bezier({{x1}}, {{y1}}, {{x2}}, {{y2}});
{{/each}}
/* transitions */
{{#each transitions}}
.{{formattedName}}() {
  transition-timing-function : @{{easeCurveVariable}};
  transition-duration : @{{durationVariable}};  
  transition-delay : @{{delayVariable}};
}
{{/each}}
`,
sass : `/* durations */
{{#each durations}}
\${{formattedName}}: {{{duration}}};
{{/each}}
/* ease curves*/
{{#each easeCurves}}
\${{formattedName}}:  cubic-bezier({{x1}}, {{y1}}, {{x2}}, {{y2}});
{{/each}}
/* transitions */
{{#each transitions}}
@mixin {{formattedName}}() {
  transition-timing-function : \${{easeCurveVariable}};
  transition-duration : \${{durationVariable}};  
  transition-delay : \${{delayVariable}};
}
{{/each}}
`,
yaml : `durations:
{{#each durations}}
{{formattedName}}: "{{duration}}"
{{/each}}
ease-curves:
{{#each easeCurves}}
{{formattedName}}:
  x1: {{x1}}
  y1: {{y1}}
  x2: {{x2}}
  y2: {{y2}}
{{/each}}
transitions:
{{#each transitions}}
{{formattedName}}:
  duration: {{duration}}
  delay: {{delay}}
  x1: {{x1}}
  y1: {{y1}}
  x2: {{x2}}
  y2: {{y2}}
{{/each}}
`
};