import React from 'react';
import styled, {css} from 'styled-components';

export const Transition_ChoosePreset = styled.div`
    display : flex;
    align-items : center;
    cursor : pointer;
    &:hover {
        color : white;
    }
`
export const Transition_CPPath1 = styled.path`
    stroke : #414246;
    transition : stroke 0.4s ease;
    ${Transition_ChoosePreset}:hover &{
        stroke : #6D6E72;
        transition : stroke 0.2s ease;
    }
`
export const Transition_CPPath2 = styled.path`
    fill : #C3C3C3;
    transition : fill 0.4s ease;
    ${Transition_ChoosePreset}:hover &{
        fill : #FFF;
        transition : fill 0.2s ease;
    }
`