import React from 'react';
import ReactDOM from 'react-dom';
import {
    SC,    
    UIUtils,
    Utils,
    AppLayout
} from '../../../../../../importer';
import styled from 'styled-components';
import { DocumentToolbarBox, DocumentToolbarButton } from '../text/toolbar';


export default class DocumentItemSpacer extends React.Component {
    constructor(props) {
        super(props);

        this.StartResizing = this.StartResizing.bind(this);
        this.onResize = this.onResize.bind(this);
        this.StopResizing = this.StopResizing.bind(this);        
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
        this.onChangeSpace = this.onChangeSpace.bind(this);                     
        this.state = {};

        this.Ref_Area = React.createRef();
    }
    StartResizing(e) {
        AppLayout.Designer.SuspendDocumentDesigner = true;
        e.stopPropagation();
        document.addEventListener('mousemove', this.onResize)
        document.addEventListener('mouseup', this.StopResizing);
        const bounds = UIUtils.Utils.GetBounds(this.Ref_Area.current);
        this.setState({
            resizing : true,
            sy : e.clientY,
            height : bounds.height,
            dy : 0
        });
    }
    StopResizing(e) {
        AppLayout.Designer.SuspendDocumentDesigner = false;
        document.removeEventListener('mousemove', this.onResize);
        document.removeEventListener('mouseup', this.StopResizing);                
        const bounds = UIUtils.Utils.GetBounds(this.Ref_Area.current);
        bounds && this.props.onChangeSpace(bounds.height);

        this.setState({
            resizing : false
        });
    }
    onResize(e) {
        const dy = e.clientY - this.state.sy;
        this.setState({
            dy : dy,            
        });
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.visible !== this.props.visible || nextProps.bottom !== this.props.bottom)
            return true;
        if (this.state.showToolbar !== nextState.showToolbar || this.state.resizing !== nextState.resizing || this.state.dy !== nextState.dy)
            return true;
        return false;
    }
    onChangeSpace(height, e) {
        e.stopPropagation();
        this.props.onChangeSpace(height);
    }
    onMouseEnter(e) {
        this.isMouseOver = true;
        setTimeout(() => {
            if (this.isMouseOver)
                this.setState({showToolbar : true});
        }, 200);        
    }
    onMouseLeave(e) {
        this.isMouseOver = false;
        if (this.state.showToolbar)
            this.setState({showToolbar : false});
    }

    render() { 
        let height = this.props.bottom;
        let info;
        if (this.state.resizing) {
            height = Utils.ToInteger(Math.max(8, this.state.height + this.state.dy));
        }
        let toolbar;
        
        if (this.state.showToolbar || this.state.resizing) {
            info = (
                <div>{height}</div>
            )
            const style = {
                position : 'absolute',
                bottom : '100%'
            };
            if (this.props.bottomToolbar) {
                style.marginBottom = '-20px';
            }
            toolbar = (
                <DocumentToolbarBox style={style}>
                    <div style={{display : 'grid', gridGap : '8px', padding : '4px', width : '100%', gridTemplateColumns : 'repeat(6, auto) 1fr', boxSizing : 'border-box'}}>
                        {
                            [8,16,24,32,48,64,128].map((spaceSize) => {
                                return (
                                    <DocumentToolbarButton active={spaceSize === height} onMouseDown={this.onChangeSpace.bind(this, spaceSize)} dark>
                                        <div style={{
                                            paddingLeft : '4px', 
                                            paddingRight : '4px', 
                                            width : Utils.px(spaceSize),                                            
                                            ...SC.Styles.Flex.Cell
                                        }}>{spaceSize}</div>
                                    </DocumentToolbarButton>
                                )
                            })
                        }   
                    </div>
                </DocumentToolbarBox>
            )
        }        

        return (  
            <ResizerArea
                ref={this.Ref_Area} 
                title={this.props.title || 'Change Bottom Margin'}
                style={{                    
                    height : `max(8px, ${Utils.px(Utils.UseNullOrEmpty(height, 24))})`,
                    opacity : this.props.visible ? 1 : 0,
                    backgroundColor : this.state.showToolbar ? 'rgba(255, 55, 221, 0.1)' : 'rgba(255, 55, 221, 0)',
                    ...SC.Styles.Flex.Cell,
                    color : SC.CurrentTheme.theme.font_hover,
                    ...this.props.style
                }}
                onMouseEnter={this.onMouseEnter.bind(this)}
                onMouseLeave={this.onMouseLeave.bind(this)}
                onMouseDown={this.StartResizing.bind(this)}
            >
                {info}
                {toolbar}
            </ResizerArea>
        );
    }
}


const ResizerArea = styled.div`
    z-index : 1;    
    transition : opacity 0.2s ease;
    transition-delay : 0.1s;
    cursor : ns-resize;    
    position : relative;    
`;