import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    AppState,
    Utils,
    Loading,
    Events,
    UIUtils
} from '../../../../../../../importer';
import styled, {css} from 'styled-components';

const PlayButton = styled.button`
    background-color : #212224;
    border-radius : 50%;
    border : 1px solid #575757;
    height : 40px;
    width : 40px;
    cursor : pointer;
    display : flex;
    align-items : center;
    justify-content : center;
`;

const Play = (props) => {
    return (
        <PlayButton empty={props.empty}  onClick={props.onClick} playing={props.playing} style={props.style} >
            <SC.Svg height="16"  viewBox="0 0 18 16" style={{transform:'scale(1.4'}}>
                <path fill= {(props.playing || props.empty) ? "#818181" : '#0096ea'} d="M7 12V4l6 4"/>
            </SC.Svg>            
        </PlayButton>   
    )
}

export default Play;