import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    AppLayout,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../../../../importer';

import DropDownSelect from '../../../../../../components/editors/enum_dropdown';
import { PropertyLabel, Seperator } from '../../../../panels/right/iteminspector/styleitems/common';
import {GroupTitle, EditableName, TokenPanelHeader, TokenItemBox, ItemNameEditor} from '../../../../panels/left/designsystem/common';
import { AnimatePresence, motion } from 'framer-motion';
import {ComponentStateConfigurator} from '../../../../panels/right/iteminspector/styleitems/component/stateConfigurator';
import { GetSubcomponentStateAndModels } from '../../../../panels/right/iteminspector/styleitems/component';
import Switch from '../../../../../../components/editors/Switch';
import ComponentPreviewPropItem from '../../../../panels/right/iteminspector/styleitems/component/previewPropItem';
import ItemThemeOptions from '../themeoptions';
import { SortableList, SortableListItem } from '../../../../../../components/SortableContainer';
import TextInput from '../../../../../../components/editors/textinput';
import { DraggableEditorHandle } from '../../../../../../components/editors/transform/transformItemEditor';

export default class DocumentTabItemSettings extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.UpdateItem = this.UpdateItem.bind(this);            
        this.AddListItem = this.AddListItem.bind(this);
        this.SaveItems = this.SaveItems.bind(this);        
        this.onSortField = this.onSortField.bind(this);
        
        
        this.Load(this.props.id);
    }
    UpdateItem() {
        this.props.onUpdateItem(this.props.showItemSettings.ref);
    }    
    SetItemProp(prop, value) {
        Globals.ProjectManager.GetDocumentManager().SetItemValue(this.props.id, value, prop);        
        this.UpdateItem();
        this.RCUpdate();
    }
    AddListItem() {
        const data = Globals.ProjectManager.GetDocumentManager().GetItem(this.props.id); 
        const items = Utils.Get(data.data, [], 'items');
        items.push({
            id : Utils.Id(),
            name : 'New'
        })
        this.SaveItems();
    }
    DeleteListItem(item) {
        const data = Globals.ProjectManager.GetDocumentManager().GetItem(this.props.id); 
        const items = Utils.Get(data.data, [], 'items');
        Utils.Remove(items, (i) => {return i.id === item.id});
        // TODO : Delete Item Content
        Globals.ProjectManager.GetDocumentManager().DeleteTabItems(Globals.ProjectManager.GetDocumentManager().CurrentPageId, item.id );
        this.SaveItems();
    }
    onSortField(oldIndex, newIndex) {
        const data = Globals.ProjectManager.GetDocumentManager().GetItem(this.props.id); 
        const items = Utils.Get(data.data, [], 'items');
        Utils.ChangePlace(items, oldIndex, newIndex);
        this.SaveItems();
    }
    SaveItems() {
        const data = Globals.ProjectManager.GetDocumentManager().GetItem(this.props.id); 
        const items = Utils.Get(data.data, [], 'items');
        
        Globals.ProjectManager.GetDocumentManager().SetItemValue(this.props.id, items, 'items');        
        this.RCUpdate();
        this.UpdateItem();
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.id !== this.props.id) {
            this.Load(nextProps.id);
        }
        return true;
    }
    Load(itemId) {
        const data = Globals.ProjectManager.GetDocumentManager().GetItem(itemId);        
        
        this.Loaded = true;
    }
    renderCustom() {
        if (!this.Loaded) {
            return null;
        }
        let content, header, overlayContent;

        const data = Globals.ProjectManager.GetDocumentManager().GetItem(this.props.id);
        const viewType = Utils.JustGet(data.data, 'tab', 'viewType');

        header = (
            <TokenPanelHeader 
                title='LIST WIDGET SETTINGS' 
                onClose={this.props.onClose} 
                style={{backgroundColor : SC.CurrentTheme.theme.back_lighter}}
            >                   
            </TokenPanelHeader>
        );
        
        const items = Utils.Get(data.data, [], 'items');

        content = (
            <React.Fragment>                    
                <SC.FCol style={{padding : '8px', paddingBottom  :0}}>                            
                    <SC.FRow alc jsb>
                        <SC.GroupLabel style={{flex : 1}}>LIST VIEW</SC.GroupLabel>
                        <DropDownSelect 
                            style={{flex : 1, fontSize : '11px'}}
                            items={[
                                {id : 'tab', label : 'Tab View'},
                                {id : 'accordion', label : 'Accordion'},
                                // {id : 'carousel', label : 'carousel'}
                            ]}
                            value={viewType}
                            hasBorder            
                            autoHeight
                            xsmall
                            toRight
                            onChange={this.SetItemProp.bind(this, 'viewType')}
                        />
                    </SC.FRow>
                </SC.FCol> 
                <Seperator inner/>                   
                <SC.FCol style={{padding : '8px', paddingTop : 0}}>                 
                    <SC.FRow alc jsb>
                        <SC.GroupLabel>LIST ITEMS</SC.GroupLabel>
                        <SC.Icons.Icon_Button hasCursor onClick={this.AddListItem} hasFill >
                            <SC.Icons.Icon_Plus size={20} />
                        </SC.Icons.Icon_Button>
                    </SC.FRow>       
                    <SortableList
                        style={{
                            marginTop : '4px',                            
                            transition : 'all 0.2s ease',
                            fontSize : '11px'
                        }}
                        onSort={this.onSortField}
                    >
                        {
                            items.map((item, i) => {
                                return (
                                    <SortableListItem
                                        key={item.id}
                                        draggableId={item.id}
                                        index={i}
                                        ItemBoxType={ListItem}
                                        isTranfsormed
                                        BoxProps={{
                                            item : item,
                                            onDelete : this.DeleteListItem.bind(this, item),
                                            onSave : this.SaveItems,
                                            GetFields : () => {
                                                return items;
                                            }
                                        }}                
                                    />
                                )
                            })
                        }
                    </SortableList>                                 
                </SC.FCol> 
            </React.Fragment>
        )

        return (
            <SC.FCol f1 style={{backgroundColor : SC.CurrentTheme.theme.back}}>
                {header}
                <SC.CustomScrollbars>         
                    {content}        
                    <AnimatePresence>
                        {overlayContent}
                    </AnimatePresence>              
                </SC.CustomScrollbars>            
            </SC.FCol>
        )
    }
}

class ListItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        const {item} = this.props;
        return ( 
            <div style={{
                display : 'grid',
                gridGap : '4px',
                paddingRight : '8px',
                gridTemplateColumns : 'auto 1fr auto',
                marginBottom : '8px'
            }}>
                <DraggableEditorHandle {...this.props.sortableProps.handleProps} style={{paddingLeft : '4px', paddingRight : '8px', border : 'none'}} ><SC.Icons.DragIcon xsmall /></DraggableEditorHandle>
                <TextInput
                    value={item.name} 
                    onChange={(value) => {
                        item.name = value;
                        this.props.onSave();
                    }}
                />  
                <SC.Icons.Icon_Button hasFill style={{cursor : 'pointer', marginRight : '-8px'}} onClick={this.props.onDelete} >
                    <SC.Icons.Icon_Delete size={16}/>
                </SC.Icons.Icon_Button>
            </div> 
        );
    }
}
 