import React from 'react';
import ReactDOM from 'react-dom';
import {
    AppState
} from '../../../../../../importer';

import { PlainSlateTextParser } from '../../../../documents/designer/item/text';
import PlainTextEditor from '../../../../../../components/editors/SlatePlainTextEditor';


export default class ItemTextEditor extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {};
        this.onChange = this.onChange.bind(this);      
        // this.Ref_Editor = React.createRef();

        this.textValue = (this.props.value || '').toString();        
        AppState.Designer.SetEditingText('Text_Item');
    }
    componentWillUnmount() {
        AppState.Designer.SetEditingText(false);
        this.props.onChange(this.textValue);
    }
    onChange(value) {
        this.textValue = value;
        if (this.textValue !== this.props.value) {
            this.props.onChanging(this.textValue);
        }
    }
    render() {
        return (
            <PlainTextEditor
                // ref={this.Ref_Editor}
                autoFocus
                text={this.textValue}
                onChange={this.onChange}        
                placeholder={'Start typing...'}             
                readOnly={this.props.readOnly}
                spellCheck={false}
                autoCorrect={false}
                onFocus={(event, change, editor) => {
                    change && change.selectAll && change.selectAll();
                }}
            />
        )
    }
}