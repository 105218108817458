export default {
  json : `  "fonts" : [
{{#eachJson fonts}}
  "name" : "{{formattedName}}",
      "fontFamily" : "{{{fontFamily}}}"   
{{/eachJson}}
\n  ],
  "typeStyles" : [
{{#eachJson typeStyles}}
  "name" : "{{formattedName}}",
      "fontFamily" : "{{{fontFamily}}}",
      "fontSize" : "{{fontSize}}",
      "lineHeight" : "{{lineHeight}}",
      "letterSpacing" : "{{letterSpacing}}"
{{/eachJson}}
\n  ]`,
jsonLookup : `{
  "fonts" : {
{{#each fonts}}
"{{formattedName}}" : {
  "name" : "{{formattedName}}",
  "fontFamily" : "{{{fontFamily}}}"
},
{{/each}}
  },
  "typeStyles" : {
{{#each typeStyles}}
"{{formattedName}}" : {
  "name" : "{{name}}",
  "fontFamily" : "{{{fontFamily}}}",
  "fontSize" : "{{fontSize}}",
  "lineHeight" : "{{lineHeight}}",
  "letterSpacing" : "{{letterSpacing}}"
}
{{/each}}
  }
}`,
  css : `/* fonts */
:root {        
{{#each fonts}}
--{{formattedName}}: {{{fontFamily}}};
{{/each}}
}
/* text styles*/
{{#each typeStyles}}
.{{formattedName}} {
  font-family : {{{fontFamilyVariable}}};
  font-size : {{fontSize}};
  line-height: {{lineHeight}};
  letter-spacing : {{letterSpacing}};
}
{{/each}}
`,
js : `/* fonts */
{{#each fonts}}
const {{formattedName}} = "{{{fontFamily}}}";
{{/each}}
/* text styles*/
{{#each typeStyles}}
const {{formattedName}} = {
  fontFamily : "{{{fontFamily}}}",
  fontSize : "{{fontSize}}",
  lineHeight: "{{lineHeight}}",
  letterSpacing : "{{letterSpacing}}",
};
{{/each}}
`,
less : `/* fonts */
{{#each fonts}}
@{{formattedName}}: {{{fontFamily}}};
{{/each}}
/* text styles*/
{{#each typeStyles}}
.{{formattedName}}() {
  fontFamily : @{{fontFamilyVariable}};
  fontSize : {{fontSize}};
  lineHeight: {{lineHeight}};
  letterSpacing : {{letterSpacing}};
}
{{/each}}
`,
sass : `/* fonts */
{{#each fonts}}
\${{formattedName}}: {{{fontFamily}}};
{{/each}}
/* text styles*/
{{#each typeStyles}}
@mixin {{formattedName}}() {
  fontFamily : \${{fontFamilyVariable}};
  fontSize : {{fontSize}};
  lineHeight: {{lineHeight}};
  letterSpacing : {{letterSpacing}};
}
{{/each}}
`,
yaml : `fonts:
{{#each fonts}}
{{formattedName}}: "{{{fontFamily}}}"
{{/each}}
text-styles:
{{#each typeStyles}}
{{formattedName}}:
  fontFamily: "{{{fontFamily}}}"
  fontSize: {{fontSize}}
  lineHeight: {{lineHeight}}
  letterSpacing: {{letterSpacing}}
{{/each}}
`
};