import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    Strings,
    Globals,
    AppLayout
} from '../../../../../../../../importer';

import { ItemNameEditor, TokenPanelHeader } from '../../../common';
import FontPreview from './preview';
import FontSelectPanel from '../selector';
import { AnimatePresence, motion } from 'framer-motion';
import { SetFontTypeFace } from '../..';
import TextValue from '../../../../../../../../components/editors/textvalue';
import { Seperator } from '../../../../../right/iteminspector/styleitems/common';
import { InfoPanel } from '../../../../../../../../components/info';
import TextInput from '../../../../../../../../components/editors/textinput';
import { FontLoader } from '../../../../../../../../toolabs-importer';

export default class FontItemEditor extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.onSelectFont = this.onSelectFont.bind(this);
        this.SaveName = this.SaveName.bind(this);
        this.SetFontStack = this.SetFontStack.bind(this);
        
        this.RemoveStateOverride = this.RemoveStateOverride.bind(this);        
        this.MoveValueToParentState = this.MoveValueToParentState.bind(this);
        this.SwitchStateToTokensOverriden = this.SwitchStateToTokensOverriden.bind(this);
        this.ApplyValueToCurrentState = this.ApplyValueToCurrentState.bind(this);        

        this.GetItems = this.GetItems.bind(this);
        this.CloseSelectFont = this.CloseSelectFont.bind(this);
        this.Ref_Name = React.createRef();
    }
    RemoveStateOverride() {
        if (this.ValueState.ValueState && !this.ValueState.ValueState.inherited) {
            Globals.ProjectManager.Tokens.DeleteValue({
                id : this.props.id,
                state : this.ValueState.ValueState.state
            })            
            Events.BCE(Events.GLOBAL.TOKENS_CHANGED);
        }        
    }
    MoveValueToParentState() {
        if (this.ValueState.ValueState) {
            Globals.ProjectManager.Tokens.MoveValueToState({
                id : this.props.id,
                fromState : this.ValueState.ValueState.state
            });
            delete this.ValueState.ValueState;            
            this.RCUpdate();
        }
    }
    ApplyValueToCurrentState() {
        if (this.ValueState.ValueState) {
            Globals.ProjectManager.Tokens.MoveValueToState({
                id : this.props.id,
                fromState : this.ValueState.ValueState.state,
                toState : Globals.ProjectManager.CurrentState
            });
            delete this.ValueState.ValueState;
            this.RCUpdate();
        }
    }
    SwitchStateToTokensOverriden() {
        const ToState = this.ValueState.ValueState.state;
        delete this.ValueState.ValueState;
        Globals.ProjectManager.States.SetStateFromLabel(ToState);
    }
    componentWillUnmount() {
        this.props.onClosed && this.props.onClosed();
        super.componentWillUnmount();        
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.GlobalStateId !== nextProps.GlobalStateId) {
            const token = Globals.ProjectManager.Tokens.Token(this.props.id);
            if (!token) {
                this.props.onClose();
                return false;
            }
        }
        return true;
    }
    GetItems() {
        return Globals.ProjectManager.Tokens.Fonts();
    }        
    onSelectFont() {
        // this.props.onSelectFont(this.RCUpdate, this.props.id, this.CloseSelectFont);
        // return;
        this.showFontSelector = true;
        this.RCUpdate();
    }
    CloseSelectFont(close) {
        super.AddCloseCallback(close);
    }
    SaveName(name) {
        Globals.ProjectManager.Tokens.UpdateProp({id : this.props.id, name : 'name', value : name} );
        this.RCUpdate();
    }
    SetFontStack(fontStack) {
        Globals.ProjectManager.Tokens.SetValue({id : this.props.id, name : 'fontStack', value : fontStack} );
        this.RCUpdate();
    }
    SetCustomFontFamily(family) {
        if (this.props.id) {
            const token = Globals.ProjectManager.Tokens.Token(this.props.id) || { name : 'Default Font'};
            const font = this.props.id === 'DefaultFont' ? Globals.ProjectManager.Tokens.DefaultFont() : Globals.ProjectManager.Tokens.ValueOf({model : token}) || {};
            Globals.ProjectManager.Tokens.SetSubValue({id : this.props.id, prop : 'family', value : family});            

            if (font.fontId) {                
                let CustomFont = AppState.CatchedData.Get(AppState.CatchedData.CUSTOMFONTS, font.fontId);
                if (CustomFont) {
                    Globals.ProjectManager.DataManager.Catched.Set(family, 'CustomFonts', font.fontId, 'family');
                    CustomFont.family = family;
                    FontLoader.LoadCustomFont('Style_' + font.fontId, CustomFont.base64);
                }                
            }    
            this.RCUpdate();        
            Events.BCE(Events.GLOBAL.TOKENS_CHANGED);
        }
    }
    SetCustomFontStyle(style) {
        if (this.props.id) {
            const token = Globals.ProjectManager.Tokens.Token(this.props.id) || { name : 'Default Font'};
            const font = this.props.id === 'DefaultFont' ? Globals.ProjectManager.Tokens.DefaultFont() : Globals.ProjectManager.Tokens.ValueOf({model : token}) || {};
            Globals.ProjectManager.Tokens.SetSubValue({id : this.props.id, prop : 'style', value : style});

            if (font.fontId) {                
                let CustomFont = AppState.CatchedData.Get(AppState.CatchedData.CUSTOMFONTS, font.fontId);
                if (CustomFont) {
                    Globals.ProjectManager.DataManager.Catched.Set(Utils.UseNullOrEmpty(style), 'CustomFonts', font.fontId, 'style');
                    CustomFont.style = style;
                    FontLoader.LoadCustomFont('Style_' + font.fontId, CustomFont.base64);
                }                
            }    
            this.RCUpdate();        
            Events.BCE(Events.GLOBAL.TOKENS_CHANGED);
        }
    }
    SetAlternateName(value) {
        Globals.ProjectManager.Tokens.SetValue({id : this.props.id, name : 'alternateName', value : value} );
        this.RCUpdate();
    }
    renderCustom() {

        const token = Globals.ProjectManager.Tokens.Token(this.props.id) || { name : 'Default Font'};
        this.ValueState = {
            onRemoveOverride : this.RemoveStateOverride,
            onMoveToParent : this.MoveValueToParentState,
            onApplyToCurrentState : this.ApplyValueToCurrentState,
            onSwitchToOverridenState : this.SwitchStateToTokensOverriden
        };
        const font = this.props.id === 'DefaultFont' ? Globals.ProjectManager.Tokens.DefaultFont(this.ValueState) : Globals.ProjectManager.Tokens.ValueOf({model : token, info : this.ValueState}) || {};
        const fontStack = Globals.ProjectManager.Tokens.ValueOf({model : token, name : 'fontStack'});
        const alternateName = Globals.ProjectManager.Tokens.ValueOf({model : token, name : 'alternateName'});
        let nameEditor;
        // if (this.props.isDefaultFont) {
        //     nameEditor = (
        //         <SC.FRow>
        //             Default Font
        //         </SC.FRow>
        //     )
        // }
        // else {
        //     nameEditor = (
        //         <ItemNameEditor
        //             noMargin
        //             fontSize='12px'                        
        //             name={token.name}
        //             onSaveName={this.SaveName}
        //             onGetItems={this.GetItems}
        //             model={this.token}
        //         />
        //     )
        // }

        nameEditor = (
            <ItemNameEditor
                noMargin
                fontSize='12px'                        
                name={token.name}
                onSaveName={this.SaveName}
                onGetItems={this.GetItems}
                model={token}
                readOnly={token.locked}
                lockable={{
                    isLocked : token.locked,
                    onTokenLock : () => {
                        token.locked = Globals.ProjectManager.Tokens.ToggleTokenLock(this.props.id);                                    
                        this.RCUpdate();
                    }
                }}
            />
        )
        let editor;

        if (font.provider === Strings.CUSTOM || true) {
            editor = (
                <div style={{
                    display : 'grid',
                    gridTemplateColumns : '1fr 2fr',
                    gridTemplateRows : '1fr',
                    gridGap : '8px',
                    marginTop : '16px',
                    alignItems : 'center',
                    wordBreak : 'break-all'
                }}>
                    <div>Provider</div>
                    <div>{font.provider}</div>
                    {/* <div>Name</div> */}
                    {/* <div>{font.name}</div> */}
                    <div>Family</div>
                    <TextInput 
                        value={font.family}
                        onChange={this.SetCustomFontFamily.bind(this)}
                    />
                    <div>Style</div>
                    <TextInput 
                        value={font.style}
                        onChange={this.SetCustomFontStyle.bind(this)}
                    />
                </div>
            )
        }
        else {
            editor = (
                <div style={{
                    display : 'grid',
                    gridTemplateColumns : '1fr 2fr',
                    gridTemplateRows : '1fr',
                    gridGap : '8px',
                    marginTop : '16px',
                    alignItems : 'center'
                }}>
                    <div>Provider</div>
                    <div>{font.provider}</div>
                    <div>Name</div>
                    <div>{font.name}</div>
                    <div>Family</div>
                    <div>{font.family}</div>
                </div>
            )
        }


        return (
            <SC.FCol f1 fw fh style={{position : 'relative'}}>
                <TokenPanelHeader 
                    title='EDIT FONT' 
                    onClose={this.props.onClose} 
                    onCancel={this.props.onCancel} 
                    onAdd={this.props.onAdd} 
                    hasAddCancel
                    onDelete={this.props.onDelete} 
                    stateValue={this.ValueState} 
                />
                <SC.FCol f1 style={{padding : '10px', backgroundColor : SC.CurrentTheme.theme.back}}>
                    <SC.FCol style={{marginLeft : '4px', marginBottom : '8px'}}>
                        {nameEditor}
                    </SC.FCol>    
                    <SC.FCol style={{pointerEvents : token.locked ? 'none' : 'all'}}>
                        <FontPreview 
                            font={font}
                            name={token.name}
                            height={120}
                            SelectFont={this.onSelectFont}
                            reloadId={this.ReloadFontId}
                        />
                        {
                            AppLayout.AppSource.Figma && 
                            <FigmaFontChecker 
                                fontId={this.props.id} 
                                font={Globals.ProjectManager.Tokens.TypePatterns.GetFigmaFontFamily(this.props.id)}
                                GetPluginApi={this.props.GetPluginApi}
                            />
                        }                    
                        {editor}
                        <Seperator />
                        <div style={{marginBottom : '8px'}}>Alternate Name</div>
                        <TextInput 
                            value={alternateName}
                            onChange={this.SetAlternateName.bind(this)}
                        />
                        <Seperator />
                        <div style={{marginBottom : '8px'}}>Font Stack</div>
                        <SC.FCol>
                            <TextValue 
                                dark
                                multiline 
                                value={fontStack}
                                onChange={this.SetFontStack}
                                style={{flex : 'unset'}}
                            />
                            <a href='https://www.cssfontstack.com/' target='_blank' style={{marginTop : '8px'}}>
                                <InfoPanel>
                                    Explore complete collection of web safe CSS font stacks.
                                </InfoPanel>                        
                            </a>
                        </SC.FCol> 
                    </SC.FCol>                                       
                </SC.FCol>          
                <AnimatePresence>
                    {
                        this.showFontSelector && 
                        <motion.div 
                            style={{...SC.Styles.Absolute, zIndex : 10000, backgroundColor : SC.CurrentTheme.theme.back_lighter}}
                            initial={{opacity : 0.7, x : -24}}
                            animate={{opacity : 1, x : 0}}
                            exit={{opacity : 0, x : -48}}
                            transition={{duration : 0.1}}
                        >
                            <FontSelectPanel 
                                onSelect={(font) => {
                                    SetFontTypeFace(this.props.id, font);
                                    
                                    this.props.onBroadCastChange();

                                    const ValueState = Utils.Get(this.ValueState, {
                                        state : Globals.ProjectManager.CurrentState
                                    }, 'ValueState');
                                    ValueState.inherited = false;

                                    this.showFontSelector = false;
                                    this.ReloadFontId = Utils.Id();
                                    this.RCUpdate();
                                }}
                                onPreview={(font) => {                                    
                                    if (font) {
                                        Globals.ProjectManager.SetThemePreviewTypeface(this.props.id, font, font ? true : false);
                                        this.willDeletePreviewFont = Globals.ProjectManager.PreviewFont.PreviewId;
                                        Globals.ProjectManager.UpdateTokenValues({});
                                        this.props.onBroadCastChange();

                                    }
                                    else {
                                        if (Globals.ProjectManager.PreviewFont) {
                                            this.willDeletePreviewFont = Globals.ProjectManager.PreviewFont.PreviewId;
                                            setTimeout(() => {
                                                if (this.willDeletePreviewFont) {
                                                    if (Globals.ProjectManager.PreviewFont && Globals.ProjectManager.PreviewFont.PreviewId !== this.willDeletePreviewFont) {
                                                        return;
                                                    }
                                                }
                                                delete this.willDeletePreviewFont;
                                                Globals.ProjectManager.SetThemePreviewTypeface(this.props.id);                                                
                                                Globals.ProjectManager.UpdateTokenValues({});
                                                this.props.onBroadCastChange();
                                            }, 300);
                                        }
                                    }                                    
                                }}
                                onClose={() => {
                                    this.showFontSelector = false;
                                    this.RCUpdate();
                                }}
                            /> 
                        </motion.div>
                    }
                </AnimatePresence>      
            </SC.FCol>
        )
    }
}


export class FigmaFontChecker extends ReactBaseComponent
{
    constructor(props) {
        super(props);
    }
    
    componentDidMount() {
        super.componentDidMount();
        if (this.props.GetPluginApi) {
            this.props.GetPluginApi().CheckFigmaFontExists(this.props.fontId, ({success, error}) => {
                this.setState({
                    success : success,
                    loaded : true
                })
            })
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (!Utils.IsEqual(this.props.font, nextProps.font)) {
            if (this.props.GetPluginApi) {
                this.props.GetPluginApi().CheckFigmaFontExists(nextProps.fontId, ({success, error}) => {
                    this.setState({
                        success : success,
                        loaded : true
                    })
                })
            }
        }
        return true;
    }
    renderCustom() {
        if (this.state.loaded && !this.state.success) {
            if (this.props.compact) {
                return (
                    <SC.Icons.FontError style={{marginRight : '4px'}} />
                )
            }
            return (
                <InfoPanel error style={{
                    ...SC.Styles.Flex.RowAlcJsb,
                    color : SC.CurrentTheme.theme.color_notification.error,
                    justifyContent : 'flex-start',
                    marginTop : '8px',
                    marginBottom : 0
                }}>
                    <SC.Icons.Figma />
                    <SC.Icons.FontError />
                    <div style={{
                        marginLeft : '8px'
                    }}>Font can not be loaded in Figma.</div>
                </InfoPanel>
            )
        }
        return null;
    }
}