import React from 'react';
import {
    Utils,
    MetaData,
    Globals,
    Strings
} from '../../../../importer';

import DataTypes from '../../../../../toolabs-modules/toolabs-metaui/datatypes';
import Chroma from 'chroma-js';
import Handlebars  from 'handlebars';
import {DEFAULT_TEMPLATES, TARGET_DEFAULTS} from './templates/index';
import {TARGET_DEFAULTS_ONLYTOKENS} from './templates/onlyTokens';
import TEMPLATES_TYPOGRAPHY from './templates/typography';
import TEMPLATES_MOTION from './templates/motion';
import TEMPLATES_IMAGES from './templates/images';
import { LoadZipper } from '../../publish/steps';
import { GetSpacePatternItem } from '../../panels/left/designsystem/spacing/patterns';
import { GetFigmaLayoutValue, GetFigmaLayoutCss } from '../../../../apps/figmaplugin/designsystem/autolayouts';
import svgr from '@svgr/core'
import PrettySvg from 'pretty';
import { GetSelectedTokenIds } from '../../panels/left/designsystem/groupmembers/selectOptions';

const TokenTypes = Globals.ProjectManager.Tokens.Types;

const GenerateTemplateString = ({templateString, templateData, blocks, GlobalState}) => {
    let exportedCode = '';

    templateData.activeTheme = Globals.ProjectManager.States.GetCurrentStateExported(GlobalState || 'Default');

    Handlebars.noConflict();
    window.Handlebars = Handlebars;

    
    if (blocks && Array.isArray(blocks)) {
        blocks.map((block, i) => {
            try {
                var func = new Function("return " + block.code)();
                Handlebars.registerHelper(block.name, func);
            } catch (error) {
                
            }
        })
    }

    Handlebars.registerHelper('eachJson', (context, options) => {
        var ret = "";

        for (var i = 0, j = context.length; i < j; i++) {
            ret = ret + `    {\n    ${options.fn(context[i])}    }`;
            if (i < context.length - 1)
                ret += ',\n';
        }

        return ret;
    });

    try {        
        const template = Handlebars.compile(templateString);          
        exportedCode += template(templateData) ;
    } catch (error) {
        console.log(error);
        exportedCode += error.toString();
    }
    return exportedCode;
}

export const GenerateTokenExoprts = ({
    type,
    target,
    tokenIds,
    aliaseIds,
    namePrefix,
    nameFormat,
    isLookup,
    GlobalState,
    TokenOptions,
    onlyModifiedTokens,
    generateDataSchema,
    StateArray,
    onlyTokens
}) => {
    const result = {
        tokenItems : [],
        exportedCode : '',
        templateString : null
    };

    let CODE_TEMPLATE_LIST = TARGET_DEFAULTS;
    if (onlyTokens)
        CODE_TEMPLATE_LIST = TARGET_DEFAULTS_ONLYTOKENS;
    
    let checkForSelectedState;
    let UseStateArray;
    if (GlobalState !== 'Default' && onlyModifiedTokens) {
        checkForSelectedState = GlobalState;
    }
    else if (!onlyModifiedTokens)
        UseStateArray = StateArray;

    const allPrefix = Utils.UseNullOrEmpty(namePrefix, '');
    const prefix = Utils.JustGet(TokenOptions, '', 'prefix')

    const useNameFormat = Utils.UseNullOrEmpty(nameFormat, 'kebabCase');

    const formatName = (exportedName, usePrefix) => {
        let formattedName = allPrefix + ' ' + usePrefix + ' ' + exportedName;
        return Utils.FormatName(formattedName, useNameFormat).trim();
    }

    const dataSchema_basics = {
        activeTheme: 'Active Theme',
        id : 'Universal Id',
        name : 'Display Name',
        formattedName : 'Formatted Name',        
        groupName : 'Group Name'
    };

    const dataSchema_aliases = {
        aliaseName : 'Aliase Name',
        formattedAliaseName : 'Formatted Aliase Name',
        firstReferenceName: 'Name of first referenced token/aliase',
        formattedFirstReferenceName: 'Formatted name of first referenced token/aliase'
    }

    const useCustomTemplate = target === 'custom' || Utils.JustGet(TokenOptions, false, 'customTemplate');

    if (type === TokenTypes.Fonts) {
        const prefixFont = prefix + ' ' + Utils.JustGet(TokenOptions, '', 'prefixFont');
        const prefixStyle = prefix + ' ' + Utils.JustGet(TokenOptions, '', 'prefixStyle');

        if (generateDataSchema) {
            result.DataSchema = {
                fonts : [
                    {
                        ...dataSchema_basics,
                        fontFamily : 'Font Family',
                        alternateName : 'Alternate Font Name',
                        fontStack : 'Font Stack'
                    }
                ],
                typeStyles : [
                    {
                        ...dataSchema_basics,
                        ...dataSchema_aliases,
                        fontFamily : 'Font Family',                        
                        fontFamilyVariable : 'Font Family Var. Name',                        
                        alternateName : 'Alternate Font Name',
                        fontStack : 'Font Stack',
                        fontSize : 'Font Size',
                        fontWeight : 'Font Weight',
                        lineHeight : 'Line Height',                        
                        paragraphSpacing : 'Paragraph Spacing',
                        // lineHeightPct : 'Line Height (%)',                        
                        letterSpacing : 'Letter Spacing',
                        wordSpacing : 'Word Spacing',
                        fontSizeRem : "Font Size (REM)",
                        lineHeightRem : 'Line Height REM)',
                    }
                ]                
            };
        }        

        const fontItems = [];

        const font_varnames = {};                                
        const fontIds = Globals.ProjectManager.Tokens.Order(TokenTypes.Fonts);
        Utils.ForEach(fontIds, (tokenId, i) => {
            const token = Globals.ProjectManager.Tokens.Token(tokenId);
            if (token) {
                const tokenItem = {
                    id : tokenId,
                    name : token.name,
                    value : Globals.ProjectManager.Tokens.ValueOf({model : token, state : checkForSelectedState, statearray : UseStateArray}),
                    exportedName : Utils.UseNullOrEmpty(Globals.ProjectManager.TokenMetaData().GetOption(tokenId, token.name, 'export', 'name'), token.name),
                    exclude : Globals.ProjectManager.TokenMetaData().GetOption(tokenId, false, 'export', 'exclude')
                }                
    
                if (tokenItem.value) {
                    tokenItem.formattedName = formatName(tokenItem.exportedName, prefixFont);
    
                    tokenItem.fontFamily = tokenItem.value.family;
                    tokenItem.alternateName = Globals.ProjectManager.Tokens.ValueOf({model : token, name : 'alternateName', state : checkForSelectedState, statearray : UseStateArray});
                    tokenItem.fontStack = Globals.ProjectManager.Tokens.ValueOf({model : token, name : 'fontStack', state : checkForSelectedState, statearray : UseStateArray});
                
    
                    font_varnames[tokenId] = tokenItem.formattedName;
    
                    fontItems.push(tokenItem);
                    result.tokenItems.push(tokenItem);    
                }
            }                              
        });

        result.exportedCode = '';
        
        result.templateString = null;
        if (useCustomTemplate)
            result.templateString = Utils.JustGet(TokenOptions, null, 'template');
        if (Utils.IsNull(result.templateString)) {
            result.templateString = TEMPLATES_TYPOGRAPHY[target];
            if (target === 'json') {
                const isLookupData = isLookup || Utils.JustGet(TokenOptions, false, 'lookup');
                if (isLookupData) {
                    result.templateString = TEMPLATES_TYPOGRAPHY.jsonLookup;
                }
            }                        
        };                
        

     
        const textPatternItems = [];
        const textPatternIds = [...Globals.ProjectManager.Tokens.TypePatterns.Get(), ...Globals.ProjectManager.Tokens.Aliases.Order(TokenTypes.Fonts)];
        Utils.ForEach(textPatternIds, (textPatternId, ) => {            
            const textPattern = Globals.ProjectManager.Tokens.Token(textPatternId);
            if (textPattern && (!tokenIds || tokenIds && tokenIds.indexOf(textPatternId) > -1)) {
                const textStyle = Globals.ProjectManager.Tokens.TypePatterns.GetPatternStyle(textPattern, null, null, UseStateArray);                    
                const typeScale = Globals.ProjectManager.Tokens.Typescale.Get(null, UseStateArray);

                const tokenItem = {
                    id : textPatternId,
                    name : textPattern.name,
                    value : Globals.ProjectManager.Tokens.ValueOf({model : textPattern, state : checkForSelectedState, statearray : UseStateArray}),
                    exportedName : Utils.UseNullOrEmpty(Globals.ProjectManager.TokenMetaData().GetOption(textPatternId, textPattern.name, 'export', 'name'), textPattern.name),
                    exclude : Globals.ProjectManager.TokenMetaData().GetOption(textPatternId, false, 'export', 'exclude')
                };
    
                if (tokenItem.value !== null) {                    

                    tokenItem.formattedName = formatName(tokenItem.exportedName, prefixStyle);
    
                    tokenItem.fontFamily = Utils.UseNullOrEmpty(textStyle.family, textStyle.fontFamily);
                    tokenItem.fontSize = textStyle.fontSize;
                    tokenItem.fontSizeRem = Utils.ToNumber(Utils.parseSize(textStyle.fontSize).value) / typeScale.baseSize;
                    tokenItem.fontWeight = Utils.UseNullOrEmpty(textStyle.style, Utils.UseNullOrEmpty(textStyle.fontWeight, '400'));
                    if (Utils.IsNotNullOrEmpty(textStyle.lineHeight)) {
                        tokenItem.lineHeight = textStyle.lineHeight;
                        try {
                            tokenItem.lineHeightRem = Utils.ToNumber(Utils.parseSize(textStyle.lineHeight).value) / typeScale.baseSize;   
                        } catch (error) {
                            
                        }                        
                        // tokenItem.lineHeightPct = textStyle.lineHeight
                    }                        
                    if (Utils.IsNotNullOrEmpty(textStyle.letterSpacing))
                        tokenItem.letterSpacing = textStyle.letterSpacing;
                    if (Utils.IsNotNullOrEmpty(textStyle.wordSpacing))
                        tokenItem.wordSpacing = textStyle.wordSpacing;
                    tokenItem.paragraphSpacing = Utils.UseNullOrEmpty(textStyle.paragraphSpacing, 0);
    
                    const fontId = Globals.ProjectManager.Tokens.TypePatterns.GetPatternFontId(textPattern, null, UseStateArray);
                    const fontToken = Globals.ProjectManager.Tokens.Token(fontId);
                    if (fontToken) {
                        tokenItem.fontFamilyVariable = formatName(fontToken.name, prefixFont);;
                        tokenItem.alternateName = Globals.ProjectManager.Tokens.ValueOf({model : fontToken, name : 'alternateName', state : checkForSelectedState, statearray : UseStateArray})
                        tokenItem.fontStack = Globals.ProjectManager.Tokens.ValueOf({model : fontToken, name : 'fontStack', state : checkForSelectedState, statearray : UseStateArray});
                    }

                    if (textPattern.aliase) {
                        const aliasetokenId = Globals.ProjectManager.Tokens.Aliases.GetStateTokenId({Id : textPatternId, StateArray : UseStateArray});
                        const firstRefrencedTokenId = Globals.ProjectManager.Tokens.Aliases.GetStateTokenId({Id : textPatternId, StateArray : UseStateArray, firstReference: true});
                        const aliasedToken = Globals.ProjectManager.Tokens.Token(aliasetokenId);
                        if (aliasedToken) {
                            tokenItem.aliaseName = aliasedToken.name;
                            tokenItem.formattedAliaseName = formatName(aliasedToken.name, prefix);
                        }
                        if (firstRefrencedTokenId !== aliasetokenId) {
                            const firstReferenceToken = Globals.ProjectManager.Tokens.Token(firstRefrencedTokenId);
                            if (firstReferenceToken) {
                                tokenItem.firstReferenceName = firstReferenceToken.name;
                                tokenItem.formattedFirstReferenceName = formatName(firstReferenceToken.name, prefix);
                            }   
                        }
                        else {
                            tokenItem.firstReferenceName = tokenItem.aliaseName;
                            tokenItem.formattedFirstReferenceName = tokenItem.formattedAliaseName
                        }
                    }
                    

                    if (target === 'css') {                        
                        tokenItem.fontFamilyVariable = `var(--${tokenItem.fontFamilyVariable})`;                        
                    }                    
                    
                    textPatternItems.push(tokenItem);
                    result.tokenItems.push(tokenItem);
                }     
            }                           
        });

        
        result.exportedCode = GenerateTemplateString({
            templateString : result.templateString,
            templateData : {
                type : type,
                fonts : fontItems,
                typeStyles : textPatternItems
            },
            blocks : Utils.JustGet(TokenOptions, [], 'handlebarBlocks'),
            GlobalState : GlobalState
        });

    }
    else if (type === TokenTypes.SpacePatterns && false) {
        if (generateDataSchema) {
            result.DataSchema = {
                spacePatterns : [
                    {
                        ...dataSchema_basics,
                        value : 'Font Size'
                    }
                ]  
            };
        }
    }
    else if (type === TokenTypes.Motion) {
        const prefixDurtions = prefix + ' ' + Utils.JustGet(TokenOptions, '', 'prefixDuration');
        const prefixEase = prefix + ' ' + Utils.JustGet(TokenOptions, '', 'prefixEase');
        const prefixTransitions = prefix + ' ' + Utils.JustGet(TokenOptions, '', 'prefixTransition');

        if (generateDataSchema) {
            result.DataSchema = {
                durations : [
                    {
                        ...dataSchema_basics,
                        ...dataSchema_aliases,
                        duration : 'Duration'
                    }
                ],
                easeCurves : [
                    {
                        ...dataSchema_basics,
                        ...dataSchema_aliases,
                        easeCurve : 'Bezier Curve',
                        x1 : "Curve.x.1",
                        y1 : "Curve.y.1",
                        x2 : "Curve.x.2",
                        y2 : "Curve.y.2",
                    }
                ],
                transitions : [
                    {
                        ...dataSchema_basics,
                        duration : 'Duration Value',
                        durationVariable : 'Duration Var. Name',
                        delay : 'Delay Value (ms)',
                        delayVariable : 'Delay Var. Name',
                        easeCurve : 'Ease Curve',
                        easeCurveVariable : 'Ease Curve Var. Name',
                    }
                ]
            };
        }

        const durationItems = [];
        const hasDurations = !Utils.JustGet(TokenOptions, false, 'durations', 'disabled');
        
        const result_durations = GetSelectedTokenIds({
            selectionType : Utils.JustGet(TokenOptions, 'all', 'durations', 'type'), 
            data : Utils.JustGet(TokenOptions, {}, 'durations'), 
            useTokenType : TokenTypes.TimePatterns
        });
        const durationIds = result_durations.selectedTokens; //[...Globals.ProjectManager.Tokens.TimePatterns.Get(), ...Globals.ProjectManager.Tokens.Aliases.Order(TokenTypes.Motion)];
        const duration_varnames = {};
        Utils.ForEach(durationIds, (durationId, i) => {
            const token = Globals.ProjectManager.Tokens.Token(durationId);
            if (token && (!durationIds || durationIds && durationIds.indexOf(durationId) > -1)) {
                const tokenItem = {
                    id : durationId,
                    name : token.name,
                    value : Globals.ProjectManager.Tokens.TimePatterns.GetTimeSize(token, null, null, UseStateArray),
                    exportedName : Utils.UseNullOrEmpty(Globals.ProjectManager.TokenMetaData().GetOption(durationId, token.name, 'export', 'name'), token.name),
                    exclude : Globals.ProjectManager.TokenMetaData().GetOption(durationId, false, 'export', 'exclude')
                }
                tokenItem.duration = tokenItem.value;
                tokenItem.formattedName = formatName(tokenItem.exportedName, prefixDurtions);
    
    
                duration_varnames[durationId] = tokenItem.formattedName;                

                const tokenData = FormatTokenExportValue({
                    type : type,
                    tokenId : durationId,
                    token : token,
                    tokenItem : tokenItem,                        
                    UseStateArray : UseStateArray,
                    formatName : formatName,
                    prefix : prefix,
                    options : {},
                    target : target
                });
                
                tokenData.duration = tokenItem.duration;

                durationItems.push(tokenData);                
                result.tokenItems.push(tokenItem);
            }
                        
        });

        result.exportedCode = '';

        result.templateString = null;
        if (useCustomTemplate)
            result.templateString = Utils.JustGet(TokenOptions, null, 'template');
        if (Utils.IsNull(result.templateString)) {
            result.templateString = TEMPLATES_MOTION[target];
            if (target === 'json') {
                const isLookupData = isLookup || Utils.JustGet(TokenOptions, false, 'lookup');
                if (isLookupData) {
                    result.templateString = TEMPLATES_MOTION.jsonLookup;
                }
            }                        
        }

     
        const easeCurveItems = [];
        const result_EaseCurves = GetSelectedTokenIds({
            selectionType : Utils.JustGet(TokenOptions, 'all', 'ease', 'type'), 
            data : Utils.JustGet(TokenOptions, {}, 'ease'), 
            useTokenType : TokenTypes.Motion
        });

        const easeCurveIds = result_EaseCurves.selectedTokens;
        const ease_varnames = {};
        Utils.ForEach(easeCurveIds, (easeCurveId, ) => {
            const easePattern = Globals.ProjectManager.Tokens.Token(easeCurveId);

            const tokenItem = {
                id : easeCurveId,
                name : easePattern.name,
                value : Globals.ProjectManager.Tokens.ValueOf({model : easePattern, statearray : UseStateArray}),
                exportedName : Utils.UseNullOrEmpty(Globals.ProjectManager.TokenMetaData().GetOption(easeCurveId, easePattern.name, 'export', 'name'), easePattern.name),
                exclude : Globals.ProjectManager.TokenMetaData().GetOption(easeCurveId, false, 'export', 'exclude')
            };
            if (tokenItem.value) {
                tokenItem.formattedName = formatName(tokenItem.exportedName, prefixEase);

                tokenItem.bezierCurve = JSON.stringify(tokenItem.value, null, 2);
                tokenItem.x1 = tokenItem.value.x1;
                tokenItem.y1 = tokenItem.value.y1;
                tokenItem.x2 = tokenItem.value.x2;
                tokenItem.y2 = tokenItem.value.y2;
              
                ease_varnames[easeCurveId] = tokenItem.formattedName;
                    
                result.tokenItems.push(tokenItem);

                const tokenData = FormatTokenExportValue({
                    type : type,
                    tokenId : easeCurveId,
                    token : easePattern,
                    tokenItem : tokenItem,                        
                    UseStateArray : UseStateArray,
                    formatName : formatName,
                    prefix : prefix,
                    options : {},
                    target : target
                });

                easeCurveItems.push({
                    ...tokenItem,
                    ...tokenData                    
                });
            }            
        });


        const transitionItems = [];
        const result_transitions = GetSelectedTokenIds({
            selectionType : Utils.JustGet(TokenOptions, 'all', 'transitions', 'type'), 
            data : Utils.JustGet(TokenOptions, {}, 'transitions'), 
            useTokenType : TokenTypes.Transitions
        });

        const transitionIds = result_transitions.selectedTokens;

        Utils.ForEach(transitionIds, (transitionId, ) => {
            const transitionModel = Globals.ProjectManager.Tokens.Token(transitionId);
            const easeId = Globals.ProjectManager.Tokens.ValueOf({model : transitionModel, name : 'easeId', statearray : UseStateArray});
            const durationId = Globals.ProjectManager.Tokens.ValueOf({model : transitionModel, name : 'durationId', statearray : UseStateArray});
            const delayId = Globals.ProjectManager.Tokens.ValueOf({model : transitionModel, name : 'delayId', statearray : UseStateArray});

            const tokenItem = {
                id : transitionId,
                name : transitionModel.name,
                exportedName : Utils.UseNullOrEmpty(Globals.ProjectManager.TokenMetaData().GetOption(transitionId, transitionModel.name, 'export', 'name'), transitionModel.name),
                exclude : Globals.ProjectManager.TokenMetaData().GetOption(transitionId, false, 'export', 'exclude')
            };
            tokenItem.formattedName = formatName(tokenItem.exportedName, prefixTransitions);

            const easeItem = Utils.Find(easeCurveItems, (item) => {return item.id === easeId});
            const durationItem = Utils.Find(durationItems, (item) => {return item.id === durationId});
            const delayItem = delayId ? Utils.Find(durationItems, (item) => {return item.id === delayId}) : {duration : 0};

            if (easeItem) {
                tokenItem.easeCurve = easeItem.bezierCurve;
                tokenItem.bezierCurve = easeItem.bezierCurve;
                tokenItem.x1 = easeItem.x1;
                tokenItem.y1 = easeItem.y1;
                tokenItem.x2 = easeItem.x2;
                tokenItem.y2 = easeItem.y2;
                tokenItem.easeCurveVariable = `var(--${duration_varnames[easeId]})`;
            }
            if (durationItem) {
                tokenItem.duration = durationItem.duration;
                tokenItem.durationVariable = `var(--${duration_varnames[durationId]})`;
            }
            
            if (delayItem) {
                tokenItem.delay = delayItem.duration;
                tokenItem.delayVariable = `var(--${duration_varnames[delayId]})`;
            }
            
            transitionItems.push(tokenItem);
            result.tokenItems.push(tokenItem);
        });

        result.exportedCode = GenerateTemplateString({
            templateString : result.templateString,
            templateData : {
                type : type,
                durations : durationItems,
                easeCurves : easeCurveItems,
                transitions : transitionItems
            },
            blocks : Utils.JustGet(TokenOptions, [], 'handlebarBlocks'),
            GlobalState : GlobalState
        });
    }    
    else if (type === TokenTypes.CustomTokenTypes) {

        const customTokenTypeModel = Globals.ProjectManager.Tokens.Token(TokenOptions.customTokenType);

        if (customTokenTypeModel) {
            result.templateData = {
                type : type,
                tokens : []
            };
            const options = {};
    
            if (generateDataSchema) {
                const schema = {
                    ...dataSchema_basics,
                    value : 'Token Value'
                };
        
                Utils.ForEach(customTokenTypeModel.fieldIds, (fieldId, i) => {
                    const field = Utils.JustGet(customTokenTypeModel, null, 'fields', fieldId);
                    if (field) {
                        schema[field.name] = field.name;
                    }            
                });
    
                result.DataSchema = {
                    customTokenType : 'Custom Token Type',
                    tokens : [{...schema}]
                };
            }
    
            Utils.ForEach(tokenIds, (tokenId, i) => {
                const token = Globals.ProjectManager.Tokens.Token(tokenId);
                if (token) {
                    const tokenItem = {
                        id : tokenId,
                        name : token.name,                    
                        exportedName : Utils.UseNullOrEmpty(Globals.ProjectManager.TokenMetaData().GetOption(tokenId, token.name, 'export', 'name'), token.name),
                        exclude : Globals.ProjectManager.TokenMetaData().GetOption(tokenId, false, 'export', 'exclude'),
                        value : GetTokenValue({type : type, token : token, tokenId : tokenId, UseStateArray : UseStateArray, checkForSelectedState : checkForSelectedState, TokenOptions : TokenOptions})
                    }                         
    
                    const tokenData = FormatTokenExportValue({
                        type : type,
                        tokenId : tokenId,
                        token : token,
                        tokenItem : tokenItem,                        
                        UseStateArray : UseStateArray,
                        formatName : formatName,
                        prefix : prefix,
                        options : options,
                        target : target
                    })                                          
                     
                    Utils.ForEach(customTokenTypeModel.fieldIds, (fieldId, i) => {
                        const field = Utils.JustGet(customTokenTypeModel, null, 'fields', fieldId);
                        if (field) {
                            tokenData[field.name] = Globals.ProjectManager.Tokens.ValueOf({model : token, name : fieldId});
                            if (field.dataType === DataTypes.boolean) {
                                tokenData[field.name] = Utils.IsTrue(tokenData[field.name]);
                            }
                        }            
                    }); 
    
                    result.templateData.tokens.push(tokenData);
    
                    result.tokenItems.push(tokenItem);
                }                                                      
            }); 
    
            result.exportedCode = '';
    
            
            const fnType = Utils.JustGet(CODE_TEMPLATE_LIST, Utils.JustGet(CODE_TEMPLATE_LIST, () => '', target, 'template'), target, type);            
                
            if (fnType)
                result.templateString = fnType(type);
            
            if (useCustomTemplate)
                result.templateString = Utils.JustGet(TokenOptions, result.templateString, 'template');
            else if (target === 'json') {      
                const isLookupData = isLookup || Utils.JustGet(TokenOptions, false, 'lookup');
                if (isLookupData) {
                    result.templateString = Utils.JustGet(CODE_TEMPLATE_LIST, '', target, 'lookupTemplate')(type);
                }
            }             
    
            result.exportedCode = GenerateTemplateString({
                templateString : result.templateString,
                templateData : result.templateData,
                blocks : Utils.JustGet(TokenOptions, [], 'handlebarBlocks'),
                GlobalState : GlobalState
            });
        }        
    }
    else {
        
        result.templateData = {
            type : type,
            tokens : []
        };
        const options = {};

        if (type === TokenTypes.COLOR || type === TokenTypes.Gradients || type === TokenTypes.Shadows) {
            options.colorFormat = Utils.JustGet(TokenOptions, 'hex', 'colorFormat');
        }
        else if (type === TokenTypes.Images) {
            options.downloadAsFile = Utils.JustGet(TokenOptions, false, 'downloadAsFile');
            
            if (options.downloadAsFile) {                        
                options.assetsFolder = Utils.JustGet(TokenOptions, '', 'assetsFolder'); 
            }
        }
        else if (type === TokenTypes.Icons) {
            options.iconFormat = Utils.JustGet(TokenOptions, 'bgimage', 'iconFormat');
            
        }
        Utils.ForEach(tokenIds, (tokenId, i) => {
            const token = Globals.ProjectManager.Tokens.Token(tokenId);
            if (token) {
                const tokenItem = {
                    id : tokenId,
                    name : token.name,                    
                    exportedName : Utils.UseNullOrEmpty(Globals.ProjectManager.TokenMetaData().GetOption(tokenId, token.name, 'export', 'name'), token.name),
                    exclude : Globals.ProjectManager.TokenMetaData().GetOption(tokenId, false, 'export', 'exclude'),
                    value : GetTokenValue({type : type, token : token, tokenId : tokenId, UseStateArray : UseStateArray, checkForSelectedState : checkForSelectedState, TokenOptions : TokenOptions})
                }
                
                                

                if (Utils.IsNotNull(tokenItem.value) || type === TokenTypes.CustomTokenTypes) {                    
                    const tokenData = FormatTokenExportValue({
                        type : type,
                        tokenId : tokenId,
                        token : token,
                        tokenItem : tokenItem,                        
                        UseStateArray : UseStateArray,
                        formatName : formatName,
                        prefix : prefix,
                        options : options,
                        target : target
                    })                                          
                                                                                
                    result.templateData.tokens.push(tokenData);
    
                    result.tokenItems.push(tokenItem);    
                } 
            }                                                      
        });   

        if (aliaseIds) {
            Utils.ForEach(aliaseIds, (aliaseId, i) => {
                const aliase = Globals.ProjectManager.Tokens.Aliases.TokenAliase(aliaseId);
                if (aliase) {
                    const tokenId = Globals.ProjectManager.Tokens.Aliases.GetStateTokenId({Id : aliaseId, StateArray : UseStateArray});
                    const token =  Globals.ProjectManager.Tokens.Token(tokenId);

                    if (token) {
                        const tokenItem = {
                            id : aliaseId,
                            name : aliase.name,
                            value : GetTokenValue({type : type, token : token, tokenId : tokenId, UseStateArray : UseStateArray, checkForSelectedState : checkForSelectedState}),
                            exportedName : Utils.UseNullOrEmpty(Globals.ProjectManager.TokenMetaData().GetOption(aliaseId, aliase.name, 'export', 'name'), aliase.name),
                            exclude : Globals.ProjectManager.TokenMetaData().GetOption(aliaseId, false, 'export', 'exclude')
                        }

                        if (Utils.IsNotNull(tokenItem.value)) {
                            const tokenData = FormatTokenExportValue({
                                type : type,
                                tokenId : tokenId,
                                token : token,
                                aliaseId : aliaseId,
                                aliase : aliase,
                                tokenItem : tokenItem,                                
                                UseStateArray : UseStateArray,
                                formatName : formatName,
                                prefix : prefix,
                                options : options,
                                target : target
                            });

                            result.templateData.tokens.push(tokenData);    
                            result.tokenItems.push(tokenItem);
                        }
                    }
                }
            });
        }

        if (generateDataSchema) {
            let useDataSchema_aliases = {};

            if (type === TokenTypes.COLOR || type === TokenTypes.Shadows || type === TokenTypes.Borders || type === TokenTypes.BorderRadiuses || type === TokenTypes.Motion || type === TokenTypes.FigmaAutoLayouts) {
                useDataSchema_aliases = dataSchema_aliases;
            }

            result.DataSchema = {
                type : 'Token Type',
                tokens : [
                    {
                        ...dataSchema_basics,
                        ...useDataSchema_aliases,
                        value : 'Token Value'
                    }
                ]                                                
            };
        }

        const colorShemaInfo = {
            rgb : 'RGB',
            hex : 'HEX',
            red : 'red',
            green : 'green',
            blue : 'blue',
            alpha : 'alpha',
            hue : 'hue',
            saturation : 'saturation',
            light : 'light',
        }

        if (type === TokenTypes.COLOR) {        
            if (generateDataSchema) {
                result.DataSchema.tokens[0] = {
                    ...result.DataSchema.tokens[0],
                    ...colorShemaInfo
                }
            }
        }
        else if (type === TokenTypes.Gradients) {        
            if (generateDataSchema) {
                result.DataSchema.tokens[0] = {
                    ...result.DataSchema.tokens[0],
                    colors : [{
                        "stop" : "stop",
                        "color" : {...colorShemaInfo}
                    }],
                    angle : "angle"
                }
            }
        }
        else if (type === TokenTypes.Shadows) {        
            if (generateDataSchema) {
                result.DataSchema.tokens[0] = {
                    ...result.DataSchema.tokens[0],
                    layers : [{
                        "spread" : "spread",
                        "blur" : "blur",
                        "x" : "x",
                        "y" : "t",
                        "color" : {...colorShemaInfo}
                    }]
                }
            }
        }
        else if (type === TokenTypes.Borders) {        
            if (generateDataSchema) {
                result.DataSchema.tokens[0] = {
                    ...result.DataSchema.tokens[0],
                    "size" : "Border Size",
                    "style" : "Border Style",
                    "color" : "Border Color",
                    ...colorShemaInfo
                 }
            }
        }
        else if (type === TokenTypes.FigmaAutoLayouts) {        
            if (generateDataSchema) {
                result.DataSchema.tokens[0] = {
                    ...result.DataSchema.tokens[0],
                    "direction" : "Layout Direction",
                    "primaryAxisAlignItems" : "Align Items",
                    "counterAxisAlignItems" : "Justify Items",
                    "flexboxDirection" : "(FlexBox) Direction",
                    "flexboxAlignItems" : "(FlexBox) Align Items",
                    "flexboxJustifyContent" : "(FlexBox) Justify Content",
                    "spacing" : "Spacing",
                    "paddingTop" : "paddingTop",
                    "paddingLeft" : "paddingLeft",
                    "paddingRight" : "paddingRight",
                    "paddingBottom" : "paddingBottom"
                 }
            }
        }
        else if (type === TokenTypes.Icons) {        
            if (generateDataSchema) {
                result.DataSchema.tokens[0] = {
                    ...result.DataSchema.tokens[0],
                    "svg" : "Svg",
                    "cssBackgroundImage" : "Css Background Image",
                    "react" : "React Component"
                 }
            }
        }

        result.exportedCode = '';

        
        if (type === TokenTypes.Images) {
            result.templateString = null;
            if (useCustomTemplate)
                result.templateString = Utils.JustGet(TokenOptions, null, 'template');
            if (Utils.IsNull(result.templateString)) {
                result.templateString = TEMPLATES_IMAGES[target];
                if (target === 'json') {
                    const isLookupData = isLookup || Utils.JustGet(TokenOptions, false, 'lookup');
                    if (isLookupData) {
                        result.templateString = TEMPLATES_IMAGES.jsonLookup;
                    }
                }                        
            }                    
        }
        else {
            const fnType = Utils.JustGet(CODE_TEMPLATE_LIST, Utils.JustGet(CODE_TEMPLATE_LIST, () => '', target, 'template'), target, type);            
            
            if (fnType)
                result.templateString = fnType(type);
            
            if (useCustomTemplate)
                result.templateString = Utils.JustGet(TokenOptions, result.templateString, 'template');
            else if (target === 'json') {      
                const isLookupData = isLookup || Utils.JustGet(TokenOptions, false, 'lookup');
                if (isLookupData) {
                    result.templateString = Utils.JustGet(CODE_TEMPLATE_LIST, '', target, 'lookupTemplate')(type);
                }
            }
        }                

        result.exportedCode = GenerateTemplateString({
            templateString : result.templateString,
            templateData : result.templateData,
            blocks : Utils.JustGet(TokenOptions, [], 'handlebarBlocks'),
            GlobalState : GlobalState
        });
    }   

    return result;
}

export const GetTokenFileType = ({target}) => {
    let fileType = target;
    if (fileType === 'sass')
        fileType = 'scss';
    else if (fileType === 'android')
        fileType = 'xml';

    return fileType;
}

export const DownloadTokens = ({target, type, exportedCode, downloadAsFile, tokenItems}) => {
    LoadZipper(true).then(({jsZip, jsZipUtils, FileSaver}) => {            
        let fileType = GetTokenFileType({target});
            
        const fileName = Utils.px(Utils.px('ToolabsTokens_', type) + '.', fileType);
        var file = new File([exportedCode], fileName, {type: "application/json"});

        if (type === TokenTypes.Images) {            
            if (downloadAsFile) {
                const urlToPromise = (url) => {
                    return new Promise(function (resolve, reject) {
                        jsZipUtils.getBinaryContent(url, function (err, data) {
                        if (err) {
                            reject(err);
                        } else {
                            resolve(data);
                        }
                        });
                    });
                };
                var zip = new jsZip();
                const zip_images = zip.folder('images');
                Utils.ForEach(tokenItems, (tokenItem, ) => {
                    zip_images.file(tokenItem.fileName, urlToPromise(tokenItem.url), {binary:true});                        
                });
                zip.file(fileName, exportedCode)
                zip.generateAsync({type:"blob"})
                .then((blob) => {

                    FileSaver.saveAs(blob, `${Utils.px('ToolabsTokens_', type)}.zip`);
                });
            }
        }
        else {
            FileSaver.saveAs(file);
        }                    
    });
}

const FormatTokenExportValue = ({type, tokenId, aliaseId, token, aliase, tokenItem, UseStateArray, formatName, prefix, options, target}) => {
    let tokenData = {
        id : tokenItem.id,
        name : tokenItem.name,
        type : type,
        value : tokenItem.value,
        formattedName : tokenItem.formattedName
    }
    tokenItem.formattedName = formatName(tokenItem.exportedName, prefix);
    const ConvertColorFormat = (color, format) => {
        if (Chroma.valid(color)) {
            const colorItem = Chroma(color);
            if (format === 'rgba')
                return colorItem.css('rgba');
            else if (format === 'hsla')
                return colorItem.css('hsl');
    
            return  colorItem.hex();
        }        
        return color;
    }

    const getColorSchemaData = (color) => {        
        const colorData = {};
        if (Chroma.valid(color)) {
            const rgba = Chroma(color).rgba();
            
            colorData.rgb = color;
            colorData.hex = Chroma(color).hex();

            colorData.red = rgba[0].toFixed(1);
            colorData.green = rgba[1].toFixed(1);
            colorData.blue = rgba[2].toFixed(1);
            colorData.alpha = rgba[3].toFixed(1);

            const hsla = Chroma(color).hsl();
            colorData.hue = Utils.UseNullOrEmpty(hsla[0], 0);
            colorData.saturation = Utils.UseNullOrEmpty(hsla[1], 0);
            colorData.light = Utils.UseNullOrEmpty(hsla[2], 0);
        }        
        return colorData;
    }

    if (type === TokenTypes.COLOR) {
            
        if (Chroma.valid(tokenItem.value)) {
            tokenItem.value = ConvertColorFormat(tokenItem.value, options.colorFormat);

            tokenData = {...tokenData, ...getColorSchemaData(tokenItem.value)};
        }                
        tokenItem.valueItem = (
            <div style={{
                width : '28px',
                height : '100%',
                backgroundColor : tokenItem.value,
                borderRadius : '2px'
            }} />
        );                        
    } 
    else if (type === TokenTypes.Gradients) {
        const stops = Utils.JustGet(tokenItem.value, [], 'gradient', 'colors');
        const angle = Utils.Get(tokenItem.value.gradient, 0, 'path', 'angle');
        tokenItem.value = Utils.GetGradientCss(stops, tokenItem.value.type, angle, (tokenId) => {
            return Globals.ProjectManager.Tokens.ValueOfId(tokenId, UseStateArray);
        }, (gradientColor) => {
            return ConvertColorFormat(gradientColor, options.colorFormat);
        })

        tokenData.colors = [];
        tokenData.angle = angle;
        stops.map(({color, stop}) => {
            const layerData = {stop, ...getColorSchemaData(color), color : ConvertColorFormat(color, options.colorFormat)};

            tokenData.colors.push(layerData);
        })
    }
    else if (type === TokenTypes.Shadows) {
        const tokenvalue = Utils.JustGet(tokenItem.value, [],  'values');                            

        const getShadowColor = (colorId) => {
            let colorValue = Globals.ProjectManager.Tokens.ValueOfId(colorId, UseStateArray);           
            return ConvertColorFormat(colorValue, options.colorFormat);
        }
        
        tokenData.layers = [];
        if (Array.isArray(tokenvalue)) {
            tokenvalue.map(({color, ...layer}) => {
                const layerData = {...layer, ...getColorSchemaData(color), color : ConvertColorFormat(color, options.colorFormat)};

                tokenData.layers.push(layerData);
            })
        }

        tokenItem.value = Utils.GetShadowCss(tokenvalue, token.textShadow, getShadowColor,  (shadowColor) => {
            return ConvertColorFormat(shadowColor, options.colorFormat);
        });
    }
    else if (type === TokenTypes.Borders) {                    
        const size = Utils.px(tokenItem.value.value, tokenItem.value.Unit);        
        tokenData.size = size;
        tokenData.style = tokenData.value.style;        
        tokenData.color = '';
        if (tokenItem.value.colorId) {
            tokenData.color = Globals.ProjectManager.Tokens.ValueOfId(tokenItem.value.colorId, UseStateArray);
            tokenData = {...tokenData, ...getColorSchemaData(tokenData.color)};
        }
        tokenItem.value = `${size} ${tokenItem.value.style}`;
    }
    else if (type === TokenTypes.BorderRadiuses) {                    
        if (target === 'android') 
            tokenItem.value = tokenItem.value.value;
        else
            tokenItem.value = Utils.px(tokenItem.value.value, tokenItem.value.Unit);
    }
    else if (type === TokenTypes.SpacePatterns) {                    
        const patternvalue = Globals.ProjectManager.Tokens.SpacePatterns.GetSpaceSize(token, null, null, UseStateArray);
        const patternUnit = Globals.ProjectManager.Tokens.SpacePatterns.GetSpaceUnit(token, null, UseStateArray);
        if (target === 'android') 
            tokenItem.value = patternvalue;
        else
            tokenItem.value = Utils.px(patternvalue, patternUnit);
    }                   
    else if (type === TokenTypes.Transforms) {                    
        tokenItem.value = Utils.GetTransformCssAll(Utils.Get(tokenItem.value, [], 'items'), tokenItem.value.originX, tokenItem.value.orignY, false).transform;
    }
    else if (type === TokenTypes.Filters) {                    
        tokenItem.value = Utils.GetFilterCss(Utils.JustGet(tokenItem.value, {}, 'items'), MetaData.Properties.filter)
    }
    else if (type === TokenTypes.Images) {
        tokenItem.url = tokenItem.value.url;
        tokenItem.fileName = tokenItem.formattedName + '.jpeg';
        if (options.downloadAsFile) {
            tokenItem.value = `${options.assetsFolder}${tokenItem.formattedName}`;
        }
        else {
            tokenItem.value = tokenItem.value.url;
        }                            
    }
    else if (type === TokenTypes.Icons) {                    
        if (tokenItem.value)  {
            const format = Utils.JustGet(options, 'bgimage', 'iconFormat');

            if (tokenItem.value.provider === Strings.ICON_GOOGLE_MATERIAL) {
                let svg = '<svg width="24" height="24" viewBox="0 0 24 24">';
                tokenItem.value.paths.map((p, i) => {
                    svg += `<path d="${p}" />`
                })
                svg += '</svg>';

                const prettiedSvg = PrettySvg(svg);;
                const url = `url(data:image/svg+xml;base64,${window.btoa(prettiedSvg)})`;
                tokenItem.value = url;

                if (format === 'svg') {
                    tokenItem.value = prettiedSvg;
                }
                else if (format === 'react') {
                    tokenItem.value = '<IconComponent />';
                }

                // tokenItem.value = `data:image/svg+xml;base64,${svg}`;
                
                tokenData.cssBackgroundImage = url;
                tokenData.svg = prettiedSvg;
            }
            else if (tokenItem.value.provider === 'svg') {
                let svg = tokenItem.value.data;                
                const prettiedSvg = PrettySvg(svg);;
                const url = `url(data:image/svg+xml;base64,${window.btoa(prettiedSvg)})`;
                tokenItem.value = url;

                if (format === 'svg') {
                    tokenItem.value = prettiedSvg;
                }
                else if (format === 'react') {
                    try {
                        // tokenItem.react = await svgr(svg, { icon: true }, { componentName: tokenItem.name });   
                        tokenItem.value = tokenItem.react;
                    } catch (error) {
                        
                    }                    
                }

                // tokenItem.value = `data:image/svg+xml;base64,${svg}`;
                
                tokenData.cssBackgroundImage = url;
                tokenData.svg = prettiedSvg;
            }
            else if (tokenItem.value.provider === Strings.FIGMA) {
                let svg = tokenItem.value.svg;                
                const prettiedSvg = PrettySvg(svg);;
                const url = `url(data:image/svg+xml;base64,${window.btoa(prettiedSvg)})`;
                tokenItem.value = url;

                if (format === 'svg') {
                    tokenItem.value = prettiedSvg;
                }
                else if (format === 'react') {
                    try {
                        // tokenItem.react = await svgr(svg, { icon: true }, { componentName: tokenItem.name });   
                        tokenItem.value = tokenItem.react;
                    } catch (error) {
                        
                    }                    
                }

                // tokenItem.value = `data:image/svg+xml;base64,${svg}`;
                
                tokenData.cssBackgroundImage = url;
                tokenData.svg = prettiedSvg;
            }
            else if (tokenItem.value.provider === Strings.CUSTOM) {
                try {
                    const binarySvg = tokenItem.value.data.toString().replace('data:image/svg+xml;base64,', '');
                    let svg = window.atob(binarySvg);
                    const prettiedSvg = PrettySvg(svg);;
                    const url = `url(data:image/svg+xml;base64,${binarySvg})`;
                    tokenItem.value = url;

                    if (format === 'svg') {
                        tokenItem.value = prettiedSvg;
                    }
                    else if (format === 'react') {
                        tokenItem.value = '<IconComponent />';
                    }

                    // tokenItem.value = `data:image/svg+xml;base64,${svg}`;
                    
                    tokenData.cssBackgroundImage = url;
                    tokenData.svg = prettiedSvg;
                } catch (error) {
                    
                }                                
            }
            else if (tokenItem.value.provider === Strings.ICONFINDER) {
                try {
                    let svg = tokenItem.value.svg;
                    const prettiedSvg = PrettySvg(svg);;
                    const url = `url(data:image/svg+xml;base64,${window.btoa(prettiedSvg)})`;
                    tokenItem.value = url;

                    if (format === 'svg') {
                        tokenItem.value = prettiedSvg;
                    }
                    else if (format === 'react') {
                        tokenItem.value = '<IconComponent />';
                    }

                    // tokenItem.value = `data:image/svg+xml;base64,${svg}`;
                    
                    tokenData.cssBackgroundImage = url;
                    tokenData.svg = prettiedSvg;
                } catch (error) {
                    
                }                                
            }

            if (false) {
                let toBase64 = true;
                if (toBase64) {
                    if (tokenItem.value.provider === Strings.CUSTOM) {
                        try {
                            tokenItem.value = window.atob(tokenItem.value.data);
                        } catch (error) {
                            console.log(error);
                        }
                    }
                    else if (tokenItem.value.provider === Strings.FIGMA)
                    tokenItem.value = tokenItem.value.svg;
                else if (tokenItem.value.provider === Strings.ICONFINDER || tokenItem.value.provider === 'svg') {
                    tokenItem.value = tokenItem.value.data;
                } 
                    // else if (tokenItem.value.provider === Strings.FIGMA)
                    //     tokenItem.value = `data:image/svg+xml;base64,${window.btoa(tokenItem.value.svg)}`;
                    // else if (tokenItem.value.provider === Strings.ICONFINDER || tokenItem.value.provider === 'svg') {
                    //     tokenItem.value = `data:image/svg+xml;base64,${tokenItem.value.data}`;
                    // }
                    else if (tokenItem.value.provider === Strings.ICON_GOOGLE_MATERIAL) {
                        let svg = '<svg width="24" height="24" viewBox="0 0 24 24">';
                        tokenItem.value.paths.map((p, i) => {
                            svg += `<path d="${p}" />`
                        })
                        svg += '</svg>';
                        // tokenItem.value = `data:image/svg+xml;base64,${svg}`;
                        const prettiedSvg = PrettySvg(svg);;
                        tokenItem.value = `data:image/svg+xml;base64,${window.btoa(prettiedSvg)}`;
                        tokenData.cssBackgroundImage = `url(${tokenItem.value})`;
                        tokenData.svg = prettiedSvg;
                    }
                }
                else {
                    
                }
            }
            
            
        }
    }
    else if (type === TokenTypes.FigmaAutoLayouts) {                    
        const layoutValue = GetFigmaLayoutValue(token, UseStateArray);
        if (layoutValue) {
            const cssLayout = GetFigmaLayoutCss(token, UseStateArray);
            tokenData.direction = layoutValue.vertical ? 'vertical' : 'horizontal';
            tokenData.primaryAxisAlignItems = layoutValue.primaryAxisAlignItems;
            tokenData.counterAxisAlignItems = layoutValue.counterAxisAlignItems;

            tokenData.flexboxDirection = cssLayout.flexDirection;
            tokenData.flexboxAlignItems = cssLayout.alignItems;
            tokenData.flexboxJustifyContent = cssLayout.justifyItems

            tokenData.spacing = layoutValue.spacing;
            tokenData.paddingTop = layoutValue.paddingTop;
            tokenData.paddingLeft = layoutValue.paddingLeft;
            tokenData.paddingRight = layoutValue.paddingRight;
            tokenData.paddingBottom = layoutValue.paddingBottom;
        }
    }
    else {
        if (Utils.IsObject(tokenItem.value)) {
            tokenItem.value = JSON.stringify(tokenItem.value, null, 2);
        }
            
    }   

    tokenData.value = tokenItem.value;
    tokenData.formattedName = tokenItem.formattedName;

    if (token.aliase) {
        const aliasetokenId = Globals.ProjectManager.Tokens.Aliases.GetStateTokenId({Id : tokenId, StateArray : UseStateArray});
        const firstRefrencedTokenId = Globals.ProjectManager.Tokens.Aliases.GetStateTokenId({Id : tokenId, StateArray : UseStateArray, firstReference: true});
        const aliasedToken = Globals.ProjectManager.Tokens.Token(aliasetokenId);
        if (aliasedToken) {
            tokenData.aliaseName = aliasedToken.name;
            tokenData.formattedAliaseName = formatName(aliasedToken.name, prefix);
        }
        if (firstRefrencedTokenId !== aliasetokenId) {
            const firstReferenceToken = Globals.ProjectManager.Tokens.Token(firstRefrencedTokenId);
            if (firstReferenceToken) {
                tokenData.firstReferenceName = firstReferenceToken.name;
                tokenData.formattedFirstReferenceName = formatName(firstReferenceToken.name, prefix);
            }   
        }
        else {
            tokenData.firstReferenceName = tokenData.aliaseName;
            tokenData.formattedFirstReferenceName = tokenData.formattedAliaseName
        }
    }
    else if (aliase) {
        const aliasedToken = token;
        if (aliasedToken) {
            tokenData.aliaseName = aliasedToken.name;
            tokenData.formattedAliaseName = formatName(aliasedToken.name, prefix);
        }
    }

    const group = Globals.ProjectManager.Tokens.GetTokenGroup(tokenId);
    if (group) {
        tokenData.groupName = Utils.UseNullOrEmpty(group.name, '');
    }

    return tokenData;
}

const GetTokenValue = ({type, token, tokenId, UseStateArray, checkForSelectedState, TokenOptions}) => {
    let value;
    if (type === TokenTypes.SpacePatterns) {
        const spacePattern = GetSpacePatternItem(tokenId, UseStateArray);
        if (spacePattern) {
            value = Utils.px(spacePattern.spaceValue, spacePattern.spaceUnit)   ;
        }                    
    }
    else if (type === TokenTypes.FigmaAutoLayouts) {
        value = {
            
        };
    }
    else if (type === TokenTypes.CustomTokenTypes) {
        
        value = {
            
        };

        const tokenTypeModel = Globals.ProjectManager.Tokens.Token(TokenOptions.customTokenType);
        if (tokenTypeModel.fieldIds && Array.isArray(tokenTypeModel.fieldIds) && tokenTypeModel.fieldIds.length > 0) {
            const field = Utils.JustGet(tokenTypeModel, null, 'fields', tokenTypeModel.fieldIds[0]);
            if (field) {
                value = Globals.ProjectManager.Tokens.ValueOf({model : token, name : tokenTypeModel.fieldIds[0]});
            }
        }
    }
    else {
        value = Globals.ProjectManager.Tokens.ValueOf({model : token, state : checkForSelectedState, statearray : UseStateArray});

        // if (type === TokenTypes.COLOR && token.aliase) {
        //     value = Globals.ProjectManager.Tokens.Aliases.GetColorAliaseValue(token.id, value);
        // }
    }

    return value;
}