import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    AppLayout,
    Strings,
    Globals
} from '../../../../../../../importer';

import ChromaJs from 'chroma-js';
import ColorEditor, {Input_Hex, Input_RGB, FormatRGB} from '../../../../../../../components/editors/color';
import GradientEditor, {InitializeGradientToken} from '../../../../../../../components/editors/gradient';
import { ItemNameEditor, GroupTitle, TokenItemBox, TokenPanelHeader } from '../../common';
import RadioGroup from '../../../../../../../components/editors/radiogroup';
import TextColorBox, { ColorContrastViewer } from './textcolor';
import { GetColorTokenList, ColorTokenCardItem, ColorTokenListItem, GetGradientTokenList } from '..';
import { ListCardView } from '../../../../../../../components/light_dark_bar';
import { RenderColorTokenList } from '../../../../right/iteminspector/selectToken/color';
import { Seperator, StyleToken_Color } from '../../../../right/iteminspector/styleitems/common';
import ColorVariants from './variants';
import { LeftScrollPanel } from '../../../common';
import AliaseItem from '../../aliases/item';
import { InfoPanel } from '../../../../../../../components/info';
import Switch from '../../../../../../../components/editors/Switch';

const Label = (props) => <div style={{minWidth : '120px'}}>{props.children}</div>

export default class ColorTokenEditor extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.HandleColorChange = this.HandleColorChange.bind(this);
        this.SaveColorChange = this.SaveColorChange.bind(this);
        this.onColorChangeComplete = this.onColorChangeComplete.bind(this);
        this.CopyFromToken = this.CopyFromToken.bind(this);
        
        this.Ref_Hex = React.createRef();
        this.Ref_GradientEditor = React.createRef();

        this.SaveName = this.SaveName.bind(this);
        this.GetItems = this.GetItems.bind(this);

        this.isGradient = this.props.isGradient;
        this.onChangeColorType = this.onChangeColorType.bind(this);
        this.SaveGradient = this.SaveGradient.bind(this);
        this.EditGradientColor = this.EditGradientColor.bind(this);        
        this.LogGradientChange = this.LogGradientChange.bind(this);

        this.SelectVariantColor = this.SelectVariantColor.bind(this);
        this.SelectColorToken = this.SelectColorToken.bind(this);
        this.BindColorTokenToGradient = this.BindColorTokenToGradient.bind(this);
        
        this.RemoveStateOverride = this.RemoveStateOverride.bind(this);        
        this.MoveValueToParentState = this.MoveValueToParentState.bind(this);
        this.SwitchStateToTokensOverriden = this.SwitchStateToTokensOverriden.bind(this);
        this.ApplyValueToCurrentState = this.ApplyValueToCurrentState.bind(this);        
        

        this.LoadToken(this.props);
    }
    RemoveStateOverride() {
        if (this.ValueState.ValueState && !this.ValueState.ValueState.inherited) {
            Globals.ProjectManager.Tokens.DeleteValue({
                id : this.props.id,
                state : this.ValueState.ValueState.state
            })
            this.LoadToken(this.props);
            Events.BCE(Events.GLOBAL.TOKENS_CHANGED);
        }        
    }
    MoveValueToParentState(toState) {
        if (this.ValueState.ValueState) {            
            Globals.ProjectManager.Tokens.MoveValueToState({
                id : this.props.id,
                fromState : this.ValueState.ValueState.state,
                toState : toState
            });
            this.LoadToken(this.props);
            this.RCUpdate();
        }
    }
    ApplyValueToCurrentState() {
        if (this.ValueState.ValueState) {
            Globals.ProjectManager.Tokens.MoveValueToState({
                id : this.props.id,
                fromState : this.ValueState.ValueState.state,
                toState : Globals.ProjectManager.CurrentState
            });
            this.LoadToken(this.props);
            this.RCUpdate();
        }
    }
    SwitchStateToTokensOverriden() {
        const ToState = this.ValueState.ValueState.state;
        delete this.ValueState.ValueState;
        Globals.ProjectManager.States.SetStateFromLabel(ToState);
    }
    componentWillUnmount() {
        this.props.onClosed && this.props.onClosed();
        super.componentWillUnmount();        
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.GlobalStateId !== nextProps.GlobalStateId || this.props.id !== nextProps.id) {
            if (!this.props.isNew && !this.props.gradientColorStop) {
                const token = Globals.ProjectManager.Tokens.Token(this.props.id);
                if (!token) {
                    this.props.onClose && this.props.onClose();
                    return false;
                }
                if (this.props.GlobalState !== nextProps.GlobalState || this.props.id !== nextProps.id || this.props.GlobalStateId !== nextProps.GlobalStateId)
                    this.LoadToken(nextProps);
            }
        }
        else if (nextProps.justEditor && nextProps.itemId !== this.props.itemId) {
            this.token = nextProps.newModel;
            this.VariableValue = nextProps.newModel.value.Default.value;
        }
        return true;
    }
    LoadToken(props) {
        if (props.newModel) {
            this.token = props.newModel;
            if (this.isGradient) {
                
                this.SaveGradient();
                if (!Utils.IsObject(this.VariableValue))
                    this.VariableValue = {};
            }
            else {
                this.VariableValue = Utils.Get(props.newModel, '#fff', 'value', 'Default', 'value');
            }            
        }
        else {
            this.token = Globals.ProjectManager.Tokens.Token(props.id);
            if (this.token) {
                this.isGradient = this.token.type === Globals.ProjectManager.Tokens.Types.Gradients;
                this.ValueState = {
                    onRemoveOverride : this.RemoveStateOverride,
                    onMoveToParent : this.MoveValueToParentState,
                    onApplyToCurrentState : this.ApplyValueToCurrentState,
                    onSwitchToOverridenState : this.SwitchStateToTokensOverriden
                };
                if (this.isGradient) {                
                    this.VariableValue = Utils.DeepClone(Globals.ProjectManager.Tokens.GetStateValue({Id : props.id, StateArray : props.StateArray, info : this.ValueState}));
                    if (!this.VariableValue) {
                        this.VariableValue = {};
                        InitializeGradientToken(this.VariableValue);
                        this.SaveGradient(this.VariableValue);
                    }
                    if (props.GlobalState !== Strings.DEFAULT)
                        this.DefaultStateValue = Utils.UseNull(Utils.DeepClone(Globals.ProjectManager.Tokens.ValueOf({model : this.token, state : Strings.DEFAULT})), {});
                }
                else {                
                    this.VariableValue = Utils.UseNullOrEmpty(Globals.ProjectManager.Tokens.GetStateValue({Id : props.id, StateArray : props.StateArray, info : this.ValueState}), '#fff');
                    if (props.GlobalState !== Strings.DEFAULT)
                        this.DefaultStateValue = Utils.UseNullOrEmpty(Globals.ProjectManager.Tokens.ValueOf({model : this.token, state : Strings.DEFAULT}), '#fff');               
                }   
            }                        
        }
    }
    GetItems() {
        return Globals.ProjectManager.Tokens.Colors.List();
    }
    onChangeColorType(type) {
        this.isGradient = type === 'gradient';
        Globals.ProjectManager.Tokens.Colors.ConvertGradient({Id : this.props.id, ToGradient : this.isGradient});
        if (this.isGradient) {
            
            this.token.value = {};
            InitializeGradientToken(this.token.value);
            
            this.VariableValue = this.token.value;
        }
        else {
            this.VariableValue = '#fff';
        }
        this.props.onChangeColorType && this.props.onChangeColorType(type);
        this.LoadToken(this.props);
        this.RCUpdate();
    }
    SaveGradient(item, value) {
        if (this.props.newModel) {
            this.props.newModel.value = item;
        }
        else {            
            Globals.ProjectManager.Tokens.SetValue({id : this.props.id, value : Utils.DeepClone(item), state : this.props.isNew ? 'Default' : this.props.GlobalState});
            if (this.props.isNew) {
                this.props.onPreviewChange && this.props.onPreviewChange(item);
            }
            else {
                const changingTokens =  [{
                    Id : this.props.id,
                    Type : Globals.ProjectManager.Tokens.Types.Gradients,
                    value : value
    
                }];
                Events.BroadcastThrottle_50(Events.GLOBAL.TOKEN_VALUE_CHANGING, changingTokens);
            }            
        }
    }
    LogGradientChange() {
        Globals.ProjectManager.LogTokenChange({Desc : 'Change Gradient'});
    }
    SetIsFigmaGradient(isFigmaToken) {
        Globals.ProjectManager.Tokens.UpdateProp({id : this.props.id, name : 'figmaGradient', value : !isFigmaToken});
        this.token.figmaGradient = !isFigmaToken;
        this.RCUpdate();
    }
    HandleColorChange(color) {
        if (!this.ChangeLogged && !this.props.newModel) {
            Globals.ProjectManager.LogTokenChange({Desc : 'Change Color'});
            Globals.ProjectManager.SendTeamMessage_TokenChanged(this.props.id, Globals.ProjectManager.Tokens.Types.COLOR);
            if (Globals.ProjectManager.CurrentState !== Strings.DEFAULT) {
                const ValueState = Utils.Get(this.ValueState, {
                    state : Globals.ProjectManager.CurrentState
                }, 'ValueState');
                ValueState.inherited = false;
            }            
            // AppState.History_DesignSystem.Log({Desc : 'Change color'});
        }
        this.ChangeLogged = true;
        const rgbValue = color;
        this.VariableValue = rgbValue;
        if (this.props.newModel) {
            Utils.Set(this.props.newModel, rgbValue, 'value', 'Default', 'value');
            this.props.onPreviewChange && this.props.onPreviewChange(rgbValue);
        }
        else {
            const result = {
                items : []
            }
            Globals.ProjectManager.Tokens.Colors.SetValue({
                id : this.props.id, 
                value : rgbValue, 
                offline : true, 
                state : this.props.isNew ? Strings.DEFAULT : this.props.GlobalState, 
                result : result
            });
            
            
            if (this.props.isNew)
                this.props.onPreviewChange && this.props.onPreviewChange(rgbValue);
            else 
            {
                const changingTokens =  [{
                    Id : this.props.id,
                    Type : Globals.ProjectManager.Tokens.Types.COLOR,
                    value : rgbValue
    
                }, ...result.items];

                if (result.aliases) {
                    result.aliases.map((aliaseId) => {
                        changingTokens.push({
                            Id : aliaseId,
                            Type : Globals.ProjectManager.Tokens.Types.COLOR,
                            value : rgbValue
                        })
                    })
                }

                Events.BroadcastThrottle_50(Events.GLOBAL.TOKEN_VALUE_CHANGING, changingTokens);
            }
            
        }        
        this.RCUpdate();
    }    
    SaveColorChange(color) {
        if (this.props.gradientColorStop)
            return;
        this.ChangeLogged = false;
        const rgbValue = color;
        this.VariableValue = rgbValue;
        if (this.props.newModel) {
            Globals.ProjectManager.Tokens.SetValueOf({model : this.props.newModel, value : rgbValue, state : this.props.GlobalState});
        }
        else {
            Globals.ProjectManager.Tokens.Colors.SetValue({id : this.props.id, value : rgbValue, 
                state : this.props.isNew ? Strings.DEFAULT : this.props.GlobalState, 
            });
            const ValueState = Utils.Get(this.ValueState, {
                state : Globals.ProjectManager.CurrentState
            }, 'ValueState');
            ValueState.inherited = false;
            Events.BroadcastThrottle_50(Events.GLOBAL.TOKEN_VALUE_CHANGING, [{
                Id : this.props.id,
                Type : Globals.ProjectManager.Tokens.Types.COLOR,
                value : rgbValue
            }]);
        }        
        this.RCUpdate();        
    }
    onColorChangeComplete() {
        this.Ref_Hex.current && this.Ref_Hex.current.FocusHex(true);
    }
    CopyFromToken(value) {
        if (this.isGradient) {
            this.SaveGradient(value);
            this.RCUpdate();
        }   
        else {
            this.HandleColorChange(value);
        }     
    }
    SaveName(name) {
        this.token.name = name;
        if (this.props.newModel)
            this.props.newModel.name = name;
        else
            Globals.ProjectManager.Tokens.ChangeTokenName(this.props.id, name);
        this.RCUpdate();
    }

    EditGradientColor(id, color, onChange) {
        if (this.props.onEditColor) {
            this.props.onEditColor(id, color, onChange);
        }
        else if (this.props.RefToolbar && this.props.RefToolbar.current) {
            this.props.RefToolbar.current.ShowPanel({
                type : AppState.ItemTypes.BOARD.COLOR,
                title : 'GRADIENT COLOR',
                justEditor : true,
                itemId : id,
                noGradient : true,
                gradientColorStop : true,
                color : color,
                onChange : onChange,
                onRegisterClose : (callClose) => {
                    super.AddCloseCallback(callClose);
                },
                onClosed : () => {

                }                
            })
        }   
    }

    SelectVariantColor(excludeIds, onSelected) {
        this.SelectColorToken(onSelected, null, null, null, null, excludeIds, true);

        if (this.props.onSelectColor) {
            this.props.onSelectColor(onSelected, null, null, null, excludeIds, true);
        }
        else if (this.props.RefToolbar && this.props.RefToolbar.current) {
            this.props.RefToolbar.current.ShowPanel({
                type : AppState.ItemTypes.BOARD.COLOR,
                title : 'SELECT VARIANT TOKEN',
                noGradient : true,
                excludeIds : excludeIds,
                forAliase : true,
                onSelect : (tokenId, isGradient) => {
                    onSelected(tokenId)
                },
                onPreviewToken : ({show, tokenId}) => {
                },
                onRegisterClose : (callClose) => {
                    super.AddCloseCallback(callClose);
                }
            })
        }    
    }
    SelectColorToken(onSelect, onPreview, onClosed, onPreviewNewToken, colorId, excludeIds, forAliase) {
        if (this.props.onSelectColor) {
            this.props.onSelectColor(onSelect, onPreview, onClosed, onPreviewNewToken, excludeIds, forAliase);
        }
        else if (this.props.RefToolbar && this.props.RefToolbar.current) {
            this.props.RefToolbar.current.ShowPanel({
                type : AppState.ItemTypes.BOARD.COLOR,
                title : 'GRADIENT COLOR',
                noGradient : true,
                color : colorId,
                excludeIds : excludeIds,
                forAliase : forAliase,
                onSelect : (tokenId, isGradient) => {
                    onSelect(tokenId)
                },
                onPreviewToken : ({show, tokenId}) => {
                    onPreview && onPreview(tokenId, show);
                },
                onClosed : onClosed,
                onRegisterClose : (callClose) => {
                    super.AddCloseCallback(callClose);
                },
                onPreviewNewToken : () => {
                    this.RCUpdate();
                    if (this.Ref_GradientEditor.current) {
                        this.Ref_GradientEditor.current.RCUpdate();
                    }
                }
            })
        }        
    }
    BindColorTokenToGradient(gradientPoint) {
        if (this.props.id) {
            gradientPoint.ColorRelationId = Globals.ProjectManager.Tokens.BindTokenToToken({
                SourceTokenId : this.props.id,
                ConsumerId : gradientPoint.Id,
                OldRelationId : gradientPoint.ColorRelationId,
                TargetTokenId : gradientPoint.colorId
            })
        }        
    }
    EditAliase(aliaseId) {
        this.EditingAliaseId = aliaseId;
        this.RCUpdate();
    }
    DetachFromAliase(aliaseId) {
        Globals.ProjectManager.Tokens.Aliases.SetTokenId({id : aliaseId, tokenId : null});
        this.RCUpdate();
    }
    ChangeAliaseColor(aliaseId) {
        if (this.props.onSelectColor) {
            this.props.onSelectColor((tokenId) => {

            }, null, () => {
                // closed
            });
        }
        else if (this.props.RefToolbar && this.props.RefToolbar.current) {
            this.props.RefToolbar.current.ShowPanel({
                type : AppState.ItemTypes.BOARD.COLOR,
                title : 'ALIASE COLOR',
                noGradient : false,
                forAliase : true,
                color : this.props.id,
                onSelect : (tokenId, isGradient) => {
                    if (tokenId)
                        // Globals.ProjectManager.Tokens.Aliases.SetTokenId({id : aliaseId, tokenId : tokenId});
                        if (aliaseId === 'all') {
                            const aliases = Utils.DeepClone(Utils.JustGet(this.token, [], 'aliases'));
                            aliases.map((tokenAliaseId) => {
                                Globals.ProjectManager.Tokens.Aliases.ReplaceAlisedToken({aliaseId : tokenAliaseId, oldTokenId : this.props.id, newTokenId : tokenId});
                            })                            
                        }
                        else {
                            Globals.ProjectManager.Tokens.Aliases.ReplaceAlisedToken({aliaseId : aliaseId, oldTokenId : this.props.id, newTokenId : tokenId});
                        }                        
                        this.RCUpdate();
                },
                onClosed : null,
                onRegisterClose : (callClose) => {
                    super.AddCloseCallback(callClose);
                }
            })
        }
    }
    renderCustom() {
        const VariableValue = this.VariableValue;
        const DefaultStateValue = this.DefaultStateValue;     
        
        if (this.EditingAliaseId && false) {
            const model = Globals.ProjectManager.Tokens.Aliases.TokenAliase(this.EditingAliaseId);
            if (model) {
                return (
                    <SC.FCol fw fh>
                        <TokenPanelHeader 
                            title='EDIT ALIASE' 
                            hasAddCancel 
                            onClone={this.onCloneAliase}
                            onDelete={this.onDeleteAliase}
                            onClose={() => {delete this.EditingAliaseId; this.RCUpdate();}} 
                            onCancel={() => {delete this.EditingAliaseId; this.RCUpdate();}} 
                        />
                        <LeftScrollPanel>
                            <AliaseItem 
                                type={Globals.ProjectManager.Tokens.Types.COLOR}
                                RefToolbar={this.props.RefToolbar}
                                id={this.EditingAliaseId}
                                aliase={model}
                            />
                        </LeftScrollPanel>                        
                    </SC.FCol> 
                )
            }            
        }

        let editor, figmaEditor;

        if (this.isGradient) {

            let isFigmaToken = this.token.figmaGradient;
            if (isFigmaToken || AppLayout.AppSource.Figma) {                
                figmaEditor = (
                    <SC.FCol style={{overflow : 'hidden'}}>
                        <InfoPanel error style={{
                            ...SC.Styles.Flex.RowAlcJsb,
                            color : SC.CurrentTheme.theme.color_notification.error,
                            justifyContent : 'flex-start',
                            marginTop : '8px',
                            marginBottom : '8px',
                        }}>
                            <SC.Icons.Figma />      
                            <SC.FCol f1  style={{
                                    marginLeft : '16px'
                                }}>
                                {/* <div style={{marginBottom : '8px'}}>Angles can only be edited from Figma.</div> */}
                                <SC.FRow alc jsb>
                                    <div>Angles can be changed independently on Figma</div>
                                    {/* <Switch value={isFigmaToken} round onChange={this.SetIsFigmaGradient.bind(this, isFigmaToken)}/> */}
                                </SC.FRow>
                            </SC.FCol>                                              
                        </InfoPanel>                        
                    </SC.FCol>                
                )
            }
            // else if (AppLayout.AppSource.Figma) {
            //     figmaEditor = (
            //         <InfoPanel error style={{
            //             ...SC.Styles.Flex.RowAlcJsb,
            //             color : SC.CurrentTheme.theme.color_notification.error,
            //             justifyContent : 'flex-start',
            //             marginBottom : '8px',
            //         }}>
            //             <SC.Icons.Figma />      
            //             <SC.FCol f1  style={{
            //                     marginLeft : '16px'
            //                 }}>
            //                 <SC.FRow alc jsb>
            //                     <div>Angles can be changed independently on Figma</div>
            //                     <Switch round onChange={this.AttachGradientFromFigma.bind(this)}/>
            //                 </SC.FRow>
            //             </SC.FCol>                                              
            //         </InfoPanel>  
            //     )
            // }
            editor = (
                <SC.FCol style={{overflow : 'hidden'}}>  
                    {figmaEditor}                 
                    <GradientEditor
                        ref={this.Ref_GradientEditor}
                        item={VariableValue}
                        onSave={this.SaveGradient}            
                        onEditColor={this.EditGradientColor}       
                        ownerPosition={'LeftPanel'}     
                        onBeforeChange={this.LogGradientChange}
                        onSelectColor={this.SelectColorToken}
                        onBindColorTokenToGradient={this.BindColorTokenToGradient}
                    />
                </SC.FCol>                
            )
        }
        else {

            const style_input = {
                border : SC.CurrentTheme.theme.border_ondark,
                backgroundColor : SC.CurrentTheme.theme.back,
                borderRadius : '2px',
                width : '100%',
                padding : '4px',
                boxSizing : 'border-box',
                textTransform : 'uppercase',
                flex : 1
            }

            if (this.token && this.token.mainId) {
                editor = (
                    <SC.FCol style={{marginTop : '4px'}}>
                        <SC.FRow alc jsb>
                            <SC.HeaderTitle style={{padding : '4px', fontSize : '12px'}}>Variant Of</SC.HeaderTitle>
                            <SC.LinkText onClick={() => {
                                Globals.ProjectManager.Tokens.Colors.DetachVariant(this.props.id, this.token.mainId);
                                this.LoadToken(this.props);
                                this.RCUpdate();
                            }}>Detach</SC.LinkText>
                        </SC.FRow>
                        <StyleToken_Color tokenId={this.token.mainId} onSelect={() => {
                            this.props.onLoad && this.props.onLoad(this.token.mainId);
                        }} />
                    </SC.FCol>
                )
            }
            else {
                editor = (
                    <React.Fragment>
                        <ColorEditor 
                            color={VariableValue}
                            onChanging={this.HandleColorChange}
                            onChange={this.SaveColorChange}
                            onComplete={this.onColorChangeComplete}
                            readOnly={this.token.locked}
                        />         
                        <SC.FRow alc>
                            <Label>HEX</Label>
                            <Input_Hex 
                                ref={this.Ref_Hex}
                                id={this.props.id}
                                color={VariableValue}
                                style={style_input}
                                onChange={this.SaveColorChange}
                                onChanging={this.HandleColorChange}
                                readOnly={this.token.locked}
                            />
                        </SC.FRow>
                        <SC.FRow style={{marginTop : '4px'}} alc>
                            <Label>RGB</Label>
                            <div style={{display : 'grid', gridTemplateColumns : '1fr 1fr 1fr', gridGap : '4px'}}>
                                <Input_RGB
                                    id={this.props.id}
                                    color={VariableValue}
                                    style={style_input}
                                    rgb
                                    type='r'
                                    onChange={this.SaveColorChange}
                                    onChanging={this.HandleColorChange}
                                    readOnly={this.token.locked}
                                />
                                <Input_RGB
                                    id={this.props.id}
                                    color={VariableValue}
                                    style={style_input}
                                    rgb
                                    type='g'
                                    onChange={this.SaveColorChange}
                                    onChanging={this.HandleColorChange}
                                    readOnly={this.token.locked}
                                />
                                <Input_RGB
                                    id={this.props.id}
                                    color={VariableValue}
                                    style={style_input}
                                    rgb
                                    type='b'
                                    onChange={this.SaveColorChange}
                                    onChanging={this.HandleColorChange}
                                    readOnly={this.token.locked}
                                />
                            </div>
                        </SC.FRow> 
                        <SC.FRow style={{marginTop : '4px'}} alc>
                            <Label>HSB</Label>
                            <div style={{display : 'grid', gridTemplateColumns : '1fr 1fr 1fr', gridGap : '4px'}}>
                                <Input_RGB
                                    id={this.props.id}
                                    color={VariableValue}
                                    style={style_input}
                                    hsv
                                    type='h'
                                    onChange={this.SaveColorChange}
                                    readOnly={this.token.locked}
                                />
                                <Input_RGB
                                    id={this.props.id}
                                    color={VariableValue}
                                    style={style_input}
                                    hsv
                                    type='s'
                                    onChange={this.SaveColorChange}
                                    readOnly={this.token.locked}
                                />
                                <Input_RGB
                                    id={this.props.id}
                                    color={VariableValue}
                                    style={style_input}
                                    hsv
                                    type='v'
                                    onChange={this.SaveColorChange}
                                    readOnly={this.token.locked}
                                />
                            </div>
                        </SC.FRow> 
                    </React.Fragment>
                )
            }            
        }

        let typeSelector;

        if (this.props.isNew && !this.props.forText && !this.props.noGradient) {
            typeSelector = (
                <RadioGroup 
                    hasBorder
                    noBackColor
                    autoHeight
                    xsmall
                    toRight
                    fullwidth
                    small
                    items={[
                        {id : 'solid', label : 'Solid'},
                        {id : 'gradient', label : 'Gradient'},
                    ]}
                    value={this.isGradient ? 'gradient' : 'solid'}
                    onSelect={this.onChangeColorType}
                    style={{marginTop : '8px'}}
                />
            );
        }

        let semantics;
        const aliases = Utils.JustGet(this.token, [], 'aliases');
        if (aliases && Array.isArray(aliases) && aliases.length > 0) {
            semantics = (
                <SC.FCol style={{backgroundColor : SC.CurrentTheme.theme.back}}>
                    <SC.FCol style={{paddingLeft : '10px', paddingRight : '10px'}}>
                        <SC.FRow alc jsb style={{marginBottom : '8px'}}>
                            <SC.HeaderTitle>Used by Semantic Tokens</SC.HeaderTitle>
                            {
                                aliases.length > 1 &&
                                <SC.LinkText style={{marginLeft : '8px'}} onClick={this.ChangeAliaseColor.bind(this, 'all')}>Change All</SC.LinkText>
                            }
                        </SC.FRow>                        
                        {
                            aliases.map((aliaseId) => {
                                const aliase = Globals.ProjectManager.Tokens.Aliases.TokenAliase(aliaseId);
                                if (aliase) {
                                    return (
                                        <SC.FRow alc style={{marginBottom  : '4px'}}>
                                            <TokenItemBox onClick={this.EditAliase.bind(this, aliaseId)} style={{margin : 0, marginRight : '8px', flex : 1}}>
                                                {aliase.name}
                                            </TokenItemBox>                                                
                                            {/* <SC.LinkText onClick={this.DetachFromAliase.bind(this, aliaseId)}>Detach</SC.LinkText> */}
                                            <SC.LinkText onClick={this.ChangeAliaseColor.bind(this, aliaseId)}>Change</SC.LinkText>
                                        </SC.FRow>
                                        
                                    )
                                }                            
                            })
                        }                    
                    </SC.FCol>
                    <Seperator />
                </SC.FCol>
            )
        }
        
        
        const finalContent = (
            <SC.FCol fw fh style={{backgroundColor : SC.CurrentTheme.theme.back}}>
                <SC.FCol style={{padding : '10px', paddingBottom : 0, boxSizing : 'border-box'}}>
                    <SC.FCol style={{marginBottom : '8px'}}>
                        {(!this.props.justEditor || true) && <ItemNameEditor
                            noMargin
                            fontSize='12px'                        
                            name={this.token.name}
                            onSaveName={this.SaveName}
                            onGetItems={this.GetItems}
                            model={this.token}
                            onSubmit={this.props.onSubmit}
                            readOnly={this.token.locked}
                            lockable={{
                                isLocked : this.token.locked,
                                onTokenLock : () => {
                                    this.token.locked = Globals.ProjectManager.Tokens.ToggleTokenLock(this.props.id);                                    
                                    this.RCUpdate();
                                }
                            }}
                        />}
                        {typeSelector}
                        {
                            !this.isGradient && 
                            <SC.FRow style={{marginTop : '8px'}}>
                                {
                                    DefaultStateValue && 
                                    <div style={{
                                        backgroundColor : DefaultStateValue,
                                        width : '24px',
                                        cursor : 'pointer'
                                    }} title='Default State Color' onClick={() => {
                                        const color = ChromaJs(DefaultStateValue);                                        
                                        this.HandleColorChange(Utils.ToRGBA(FormatRGB(color.rgba()).rgb));
                                    }}>

                                    </div>
                                }
                                <ColorContrastViewer
                                    id={this.props.id} 
                                    backgroundColor={this.props.onBackgroundColor || VariableValue} 
                                    color={this.props.onBackgroundColor ? VariableValue : '#fff'}
                                    onEditTextcolor={this.EditTextColor}
                                    style={{flex : 1}}
                                />
                            </SC.FRow>                        
                        }                    
                    </SC.FCol>                                
                    {editor}
                </SC.FCol>                
                {
                    !this.isGradient && 
                    <SC.FCol style={{backgroundColor : SC.CurrentTheme.theme.back}}>
                        <ColorVariants 
                            id={this.props.id}
                            token={this.token}
                            mainColor={VariableValue}
                            groupId={this.props.groupId}
                            newModel={this.props.newModel}
                            onLoadVariant={this.props.onLoad}
                            onSelectVariantColor={this.SelectVariantColor}
                            name='tints'                    
                            label='Tints'
                            GlobalState={this.props.GlobalState}
                            onSetValue={this.CopyFromToken}
                        />
                        <ColorVariants 
                            id={this.props.id}
                            token={this.token}
                            mainColor={VariableValue}
                            newModel={this.props.newModel}
                            groupId={this.props.groupId}
                            onLoadVariant={this.props.onLoad}
                            onSelectVariantColor={this.SelectVariantColor}
                            isShade
                            name='shades'                    
                            label='Shades'
                            GlobalState={this.props.GlobalState}
                            onSetValue={this.CopyFromToken}
                        />
                        <Seperator />                        
                    </SC.FCol>
                }                    
                {semantics}            
                {
                    !this.token.mainId && 
                    <ColortokenList 
                        onSelect={this.CopyFromToken}
                        gradients={this.isGradient}
                        forText={this.props.forText}
                        title='OTHER TOKENS'
                        notScollable
                        onBackgroundColor={this.props.onBackgroundColor}
                        style={{
                            pointerEvents : this.token.locked ? 'none' : 'all'
                        }}
                    />
                }                
            </SC.FCol>
        )

        if (this.props.withHeader) {
            return (
                <SC.FCol fw fh>
                    <TokenPanelHeader {...this.props.withHeader} stateValue={this.ValueState} locked={this.token.locked} />
                    <LeftScrollPanel>
                        {finalContent}
                    </LeftScrollPanel>                        
                </SC.FCol>  
            )
        }

        return finalContent;
    }
}


class ColortokenList extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.titleNewToken = 'NEW COLOR';
        this.moduleName = 'Colors';
        this.tokenType = Globals.ProjectManager.Tokens.Types.COLOR;        
        this.onToggleExpand = this.onToggleExpand.bind(this);

        this.Load(this.props);
    }    
    Load(props) {
        this.state.isListView = Globals.ProjectManager.Options.Get(false, 'RightPanel', 'DesignSystem', 'ColorEditor', 'ListView');        
        this.expanded = Globals.ProjectManager.Options.Get(true, 'LeftPanel', 'DesignSystem', 'ColorEditor', 'Expanded');
        if (!this.expanded)
            return;
        this.isForText = props.forText && props.onBackgroundColor ? {
            forText : props.forText,
            onBackgroundColor : props.onBackgroundColor
        } : null;
        this.colors = props.gradients ? GetGradientTokenList() : GetColorTokenList({forText : this.isForText});        
    }     
    SetListMode(isListView) {        
        Globals.ProjectManager.Options.Set(isListView, 'RightPanel', 'DesignSystem', 'ColorEditor', 'ListView');
        this.setState({
            isListView : isListView
        })
    }
    onToggleExpand() {
        this.expanded = !this.expanded;
        Globals.ProjectManager.Options.Set(this.expanded, 'LeftPanel', 'DesignSystem', 'ColorEditor', 'Expanded');
        if (this.expanded)
            this.Load(this.props);
        this.RCUpdate();
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.gradients !== nextProps.gradients)
            this.Load(nextProps);
        return true;
    }
    SelectToken(tokenId, value) {
        if (this.props.gradients) {
            const gradientValue = Globals.ProjectManager.Tokens.ValueOfId(tokenId);
            this.props.onSelect(Utils.DeepClone(gradientValue));
        }
        else
            this.props.onSelect(value);
    }   
    PreviewToken(show, tokenId, propName, isGradient) {
        this.isPreviewingGradient = show && isGradient;        
    }
    renderColorGroup(colors, forText, SelectedColorId) {
        if (this.state.isListView) {
            return colors.map((color) => {
                if (!color.filtered) {
                    return (
                        <ColorTokenListItem 
                            key={color.id} 
                            color={color}
                            gradient={color.gradient}
                            onSelect={this.SelectToken.bind(this, color.id, color.value, color.gradient)}
                            onMouseEnter={this.PreviewToken.bind(this, true, color.id, color.gradient)}
                            onMouseLeave={this.PreviewToken.bind(this, false, color.id, color.gradient)}

                            forText={forText}
                            selected={color.id === SelectedColorId}
                        />                                
                    )
                }                
            });
        }
        else {
            return (
                <div style={forText ? {flex : 1, display : 'grid', gridTemplateColumns : '1fr 1fr 1fr', gridGap : '4px'} : {...SC.Styles.Flex.Row, flexWrap : 'wrap'}}>
                    {
                        colors.map((color) => {
                            return (
                                <ColorTokenCardItem 
                                    key={color.id} 
                                    color={color}
                                    gradient={color.gradient}
                                    onSelect={this.SelectToken.bind(this, color.id, color.value, color.gradient)}
                                    onMouseEnter={this.PreviewToken.bind(this, true, color.id, color.gradient)}
                                    onMouseLeave={this.PreviewToken.bind(this, false, color.id, color.gradient)}        
                                    forText={forText}
                                    selected={color.id === SelectedColorId}
                                />                                
                            )
                        })
                    }
                </div>
            );
        }
    }
    renderCustom() {
        let colors;
        if (this.expanded) {
            colors = (
                <RenderColorTokenList 
                    colors={this.colors}
                    isListView={this.state.isListView}
                    forText={this.isForText}
                    renderColorGroup={this.renderColorGroup.bind(this)}
                    GlobalStateId={this.props.GlobalStateId}
                    themeId={this.props.themeId}
                    isGradients={this.props.gradients}
                    justGradients={this.props.gradients}
                    gradients={this.props.gradients ? this.colors : null}                    
                    notScollable={this.props.notScollable}
                />
            );        
        }
                
        let content = colors;
        if (!this.props.notScollable) {
            content = (
                <SC.CustomScrollbars hideTracksWhenNotNeeded autoHide>                    
                    <SC.FCol f1 fw fh>
                        {colors}
                    </SC.FCol>                
                </SC.CustomScrollbars>
            )
        }

        return (
            <SC.FCol f1 style={{backgroundColor : SC.CurrentTheme.theme.back, padding : '8px', paddingTop : 0, ...this.props.style}}>
                <GroupTitle 
                    title={this.props.title || 'TOKENS'} 
                    expandable={{expanded : this.expanded,  onExpand : this.onToggleExpand}}
                    addOn={this.expanded && (
                        <ListCardView 
                            onListView={this.SetListMode.bind(this, true)}
                            onCardView={this.SetListMode.bind(this, false)}
                            isListView={this.state.isListView}
                            isCardView={!this.state.isListView}
                            style={{marginRight : '4px'}}
                            compact
                        />
                    )}
                    style={{padding : 0, paddingLeft : '4px', marginBottom : '4px', marginLeft : '-4px'}}
                />
                {content}                 
            </SC.FCol>            
        )
    }    
}
