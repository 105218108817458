import React from 'react';
import ReactDOM from 'react-dom';

import {
    MetaData,
    Utils,
    UIUtils,
    SC,
    Strings,
    AppLayout
} from '../../../../../../importer';

import {GetItemType} from '../index';
import { onItemShouldUpdate, onItemDragging, onMouseDownOnItem, onItemClick, onItemResized, onConstructItem, onItemDidMount, onItemWillUnMount, onItemDidUpdate, onGetChildItem, onGetItemProps, onGetDomProps } from '../common';
import FlexDropTarget from './flextarget';


export default class FlexContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }        

        onConstructItem(this, props);                

        this.UpdateStyle(this.props);

        this.renderSlot = this.renderSlot.bind(this);
        this.renderSlide = this.renderSlide.bind(this);
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (onItemShouldUpdate.call(this, nextProps, nextState)) {
            return true;
        }

        if (!this.props.isDropTarget && nextProps.isDropTarget || this.props.isDropTarget && !nextProps.isDropTarget) {
            return true;
        }
        if (this.state.isGridChildTarget !== nextState.isGridChildTarget)          
            return true;
        
        return false;
    }
    componentDidMount() {
        onItemDidMount.call(this);
    }
    componentWillUnmount() {
        onItemWillUnMount.call(this);
    }
    componentDidUpdate(prevProps, prevState) {
        onItemDidUpdate.call(this, prevProps, prevState);
    }
    UpdateStyle(props) {
        this.renderData = Utils.DeepClone(props.GetComponentManager().GetItemStyle(props.Id));
        const {style} = this.renderData;
        if (!style.position)
            style.position = 'relative';

        if (this.props.IsRootItem) {
            if (!style.width)
                style.width = '100%';
            if (!style.height)
                style.height = '100%';                        
        }
    }     
    GetMaxSize(scaled) {
        let width, height;
        if (this.props.IsRootItem) {
            if (this.props.IsRootItem.Viewport) {      
                width = this.props.IsRootItem.Viewport.Width;
                height = this.props.IsRootItem.Viewport.Height;
                const bounds = UIUtils.Utils.GetBounds(this);
                if (this.props.IsRootItem.IsPage)
                    height = 999999;
                return {
                    width : width,
                    height : height,
                    top : bounds.top,
                    left : bounds.left,
                    actualwidth : bounds.width,
                    actualheight : bounds.height
                }
            }
        }
        return this.props.GetParentSize(scaled);
    }    
    ActivateGridChildTarget(activate) {
        this.setState({isGridChildTarget : activate});
    }
    renderContent() {
        const SubItems = this.props.GetComponentManager().GetChildItems(this.props.Id);
        const items = [];
      
        Utils.ForEach(SubItems, (SubItem, i) => {
            let ChildItem = onGetChildItem({
                ParentDesigner : this,
                ParentProps : this.props,
                ChildItemId : SubItem.Id                
            })
            if (ChildItem) {
                items.push(ChildItem);
            }            
        });
        if (this.props.isSlotContainer && items.length === 0) {
            items.push(
                <SC.DesignItem_Placeholder />
            )
        }
        else if (this.props.isSlideContainer && items.length === 0) {
            items.push(
                <div key='Empty'>EMPTY</div>
            )
        }
        
        return items;
    }
    renderSlot({SlotItemId, SlotItem, ContainerStyle}) {

        const slotContainer = onGetChildItem({
            ChildItemId : SlotItemId,
            ChildItem : SlotItem,
            ParentDesigner : this,
            ParentProps : this.props,
            childProps : {
                isSlotContainer : {
                    style : ContainerStyle
                }                   
            }
        });

        return slotContainer;
    }
    renderSlide({SlideItemId, SlideItem, ContainerStyle, ParentzIndex}) {
        const slideContainer = onGetChildItem({
            ChildItemId : SlideItemId,
            ChildItem : SlideItem,
            ParentDesigner : this,
            ParentProps : this.props,
            childProps : {
                isSlideContainer : {
                    style : ContainerStyle
                }                   
            }
        });

        return slideContainer;
    }
    render() {         
        if (Utils.IsTrue(this.renderData.props.hidden))
            return null;
            
        // this.RenderIndex = (this.RenderIndex || 0) + 1;
        let prestyle = {};
        let outerStyle = {};
        let otuerProps = {};
        
        if (this.props.IsRootItem) {
            if (this.props.IsRootItem.SubComponent) {
                outerStyle =  this.props.IsRootItem.SubComponent.style;       
                otuerProps.onMouseDown = this.props.Preview ? this.props.IsRootItem.SubComponent.onMouseDown : this.onMouseDown;
                otuerProps.onMouseUp = this.props.Preview ? this.props.IsRootItem.SubComponent.onMouseUp : this.onItemClick;
                otuerProps.onDoubleClick = this.props.IsRootItem.SubComponent.onDoubleClick;
            }
            else if (this.props.IsRootItem.IsPage) {
                outerStyle.height = 'auto';
                outerStyle.minHeight = 'auto';
            }
        }
        else if (this.props.isSlotContainer) {
            outerStyle = this.props.isSlotContainer.style;
        }
        else if (this.props.isSlideContainer) {
            prestyle = this.props.isSlideContainer.style;
        }        
        
        const style = {
            ...prestyle,
            position : 'relative',
            boxSizing : 'border-box',            
            ...this.renderData.style,
            ...outerStyle                   
        }
        if (this.isSelected) {
            style.transition = 'all 0.2s ease';
            style.outline = 'none';
        }
        
        if (this.isDragging) {
            style.opacity = 0.5;
        }        

        
        

        if (!this.props.Preview) {
            const props = onGetItemProps({designer : this, props : otuerProps, style : style});

            let isTarget = true;
            if (this.props.IsGridChild) {
                props.onMouseEnter = this.ActivateGridChildTarget.bind(this, true);
                props.onMouseLeave = this.ActivateGridChildTarget.bind(this, false);
                
                if (this.props.isDropTarget) {
                    style.zIndex = (this.props.ParentzIndex || 1) + 1;
                }

                if (!this.state.isGridChildTarget) {
                    isTarget = false;                    
                }
            }

            // if (this.props.isDropTarget) {
            //     style.outline = '2px dashed yellow';
            // }

            return (
                <FlexDropTarget 
                    {...this.props}
                    itemProps={props}
                    onGetSize={this.GetItemSize}
                    onMouseOverChild={this.onMouseOverChild}
                    isDropTarget={isTarget ? this.props.isDropTarget : null}
                    selected={this.isSelected}
                    SelectionId={this.SelectionId || this.props.SelectionId}
                />
            )                           
        }
        const props = onGetDomProps({designer : this, props : otuerProps, style : style});
        // this.RenderIndex = (this.RenderIndex || 0) + 1;        

        return ( 
            <SC.Div_Relative
                {...props}                
            >
                {this.renderContent()}
                {/* <div style={{position : 'absolute', top : 0, left : 0, color : 'red'}}>{this.RenderIndex || 0}</div> */}
                {/* {this.RenderIndex} */}
            </SC.Div_Relative>
        );
    }    
}
 