import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppLayout,
    Links,
    Events,
    MetaData,
    AppState,
    Globals
} from '../../../../../../../importer';
import { TokenGroup, TokenItemBox, GroupTitle, TokenPanelHeader, ItemNameEditor } from '../../common';

import BaseGroup from '../base';
import { StyleToken_Color, PropertyLabel, StyleGroupTitle, SmallButtonGroup, Seperator } from '../../../../right/iteminspector/styleitems/common';
import ContentAlignments from '../../../../right/iteminspector/styleitems/layout/grid/designer/contentAlignment';
import { ListCardView } from '../../../../../../../components/light_dark_bar';
import { LeftScrollPanel } from '../../../common';
import { GridTemplateItem } from '../../../../right/iteminspector/selectToken/grid/templates';
import TemplateDimension from '../../../../right/iteminspector/styleitems/layout/grid/designer/templateDimension';
import Switch from '../../../../../../../components/editors/Switch';

export default class Containers extends BaseGroup
{
    constructor(props) {
        super(props);        
        
        this.title = 'GRID';
        this.elementType = Globals.ProjectManager.Templates.Types.Grid;
        this.DefaultId = Globals.ProjectManager.Templates.DefaultTemplateId(this.elementType);

        this.SelectColor = this.SelectColor.bind(this);

        this.LoadOptions();        
    }
    LoadOptions() {
        super.LoadOptions();
        this.state.isListView = Globals.ProjectManager.Options.Get(true, 'LeftPanel', 'DesignSystem', 'Elements', this.elementType, 'ListView');
    }
    SelectColor(templateId, propName, colorId, title, backgroundTokenId) {
        let onBackgroundColor;        
        if (backgroundTokenId) 
            onBackgroundColor = Globals.ProjectManager.Tokens.GetStateValue({Id : backgroundTokenId, StateArray : this.props.StateArray});
        const props = {
            text : true,
            onBackgroundColor : onBackgroundColor,
            hasAccessibility : true,
            propName : propName
        };
        props[propName] = colorId;
        this.SelectToken({templateId : templateId, propName : propName, type : AppState.ItemTypes.BOARD.COLOR, title : title,
            props : props
        });
    }
    
    DeleteGridProperty(templateId, prop) {
        
    }
    SetListMode(isListView) {        
        Globals.ProjectManager.Options.Set(isListView, 'LeftPanel', 'DesignSystem', 'Elements', this.elementType, 'ListView');
        this.setState({
            isListView : isListView
        })
    }
    renderDefault() {
        const designerLineColor = Globals.ProjectManager.Templates.GetStateValue({Id : this.DefaultId,  name : 'designerLineColor'});
        return (
            <React.Fragment>
                <SC.FCol style={{padding : '8px'}}>
                    {designerLineColor && <PropertyLabel>Designer Line Color</PropertyLabel>}
                    <StyleToken_Color 
                        style={{marginTop : 0, marginBottom : '4px'}}
                        tokenId={designerLineColor} 
                        onSelect={this.SelectColor.bind(this, this.DefaultId, 'designerLineColor', designerLineColor, 'GRID DESIGNER LINE COLOR')} 
                        label='Designer Line Color'
                    />                            
                </SC.FCol>
                <GroupTitle 
                    title='Templates'
                    hasAdd={{onAdd : this.AddTempalte}}
                    addOn={this.expanded && (
                        <ListCardView 
                            onListView={this.SetListMode.bind(this, true)}
                            onCardView={this.SetListMode.bind(this, false)}
                            isListView={this.state.isListView}
                            isCardView={!this.state.isListView}
                            style={{marginRight : '4px'}}
                            compact
                        />
                    )}
                />
                <SC.FCol style={{padding : '8px', paddingTop : '4px'}}>
                    {this.renderTemplates()}
                </SC.FCol>                
            </React.Fragment>            
        )
    }
    renderTemplates() {
        const templateIds = Globals.ProjectManager.Templates.Order(this.elementType);
        const templateItems = [];
        Utils.ForEach(templateIds, (templateId, i) => {
            if (templateId !== this.DefaultId) {
                const template = Globals.ProjectManager.Templates.Template(templateId);
                template && templateItems.push(
                    <TokenItemBox onClick={this.EditTemplate.bind(this, templateId)}>
                        <SC.FRow f1 overflowHidden>
                            <SC.TextDivAbbr style={{flex : 1}}>
                                {template.name}
                            </SC.TextDivAbbr>                            
                            {
                                !this.state.isListView &&
                                <div style={{width : '100px', height : '75px'}}>
                                    <GridTemplateItem
                                        template={template}
                                        width={100}
                                        height={75}
                                        justContent
                                    />
                                </div>                                
                            }
                        </SC.FRow>                        
                    </TokenItemBox>                
                )
            }            
        });
        return templateItems;
    }    
    AddTempalte() {
        const newId = Globals.ProjectManager.Templates.Add({type : this.elementType, name : 'Grid', value : {
            model : MetaData.ItemInitializer.GetGridLayout().GridModel,
            rowGap : 20,
            columnGap : 20
        }});
        this.EditingTemplate = {
            Id : newId
        };
        this.RCUpdate();
    }
}