import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Events,
    Globals,
    Utils
} from '../../../../../../importer';

import { TokenPanelHeader, GroupTitle, TokenItemBox } from '../../../left/designsystem/common';
import { GetDataApi, GetUserId } from '../../../../../../appstate/AppState';
import UserAvatar from 'react-user-avatar';
import { DotMenu } from '../../../left/common';


export default class TeamMembersPanel extends ReactBaseComponent {
    constructor(props) {
        super(props);        
    }
    componentDidMount() {
        super.componentDidMount();
        this.LoadTeam();
    }
    LoadTeam() {
        GetDataApi().get_team_data(Globals.ProjectManager.TeamId).then((result) => {
            this.team = result;
            this.setState({loading : false});
        })
    }
    renderCustom() {

        let editors = [];
        if (Globals.ProjectManager.SessionManager && this.team) {            
            if (this.state.showMembers) {
                const users = Globals.ProjectManager.SessionManager.GetActiveUsers();
                this.team.members.map(({name, id, avatar, email, uid}) => {
                    editors.push(
                        <TeamUser 
                            key={id}
                            id={id}
                            uid={uid}
                            name={name}                                                
                            avatar={avatar}
                            email={email}
                        />
                    )      
                })
            }            
        }
        return(
            <SC.FCol style={{paddingTop : '8px'}}>
                <GroupTitle 
                    noPadding
                    title={'MEMBERS'}
                    expandable={{expanded : this.state.showMembers, onExpand : () => {this.setState({showMembers : !this.state.showMembers})}}}
                    hasAdd={Globals.ProjectManager.IsAdmin() ? {onAdd : this.props.onInviteMember, title : 'Invite Member'} : null}
                />  
                {editors}                                                            
            </SC.FCol>
        );
    }
}

class TeamUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }    
    render() { 
        const {id, name, email, active, avatar, editor} = this.props;
        return (  
            <SC.HoverParent 
                defaultStyle={{
                    display : 'flex',
                    alignItems : 'center',
                    gridTemplateColumns : 'auto 1fr',
                    gridGap : '8px',
                    padding : '8px',
                    borderRadius : '4px',
                    transition : 'all 0.2s ease',
                    fontSize : '11px'
                }}
                hoverStyle={{
                    backgroundColor : SC.CurrentTheme.theme.back_lighter
                }}
            >
                <MemberAvatar avatar={avatar} noState name={Utils.UseNullOrEmpty(name, email)} />
                <SC.TextDivAbbr style={{flex : 1}}>{name || email}</SC.TextDivAbbr>
                {
                    editor === 'figma' && <SC.Icons.Figma size={10}/>
                }
            </SC.HoverParent>
        );
    }
}

// https://github.com/multiavatar/Multiavatar

export const MemberAvatar = ({avatar, noState, state, name}) => {
    return (
        <div style={{
            position : 'relative',
            color : '#fff'
        }}>
            <UserAvatar 
                src={avatar}
                name={Utils.UseNullOrEmpty(name, 'User')}
                size="24"
                style={{position : 'relative', fontSize : '10px'}}
            />
            {                
                !noState &&
                <div 
                    style={{
                        position : 'absolute',
                        top : 0, left : 0,
                        width : '8px', height : '8px', borderRadius : '4px',
                        backgroundColor : state === 'online' ? '#14ec14' : '#da0306'
                    }}
                />
            } 
        </div>
        
    )
    return (
        <div style={{
            minWidth : '24px',
            minHeight : '24px',
            maxHeight : '24px',
            border : '1px solid #D3D3D3',
            boxSizing : 'border-box',
            borderRadius : '50%',
            cursor : 'pointer',
            backgroundImage : `url(${avatar})`,
            backgroundSize : '110% 110%',
            backgroundRepeat : 'no-repeat',
            backgroundPosition : 'center 0',
            position : 'relative'
        }}>
            {
                state && 
                <div 
                    style={{
                        position : 'absolute',
                        top : 0, left : 0,
                        width : '8px', height : '8px', borderRadius : '4px',
                        backgroundColor : state === 'online' ? 'green' : 'red'
                    }}
                />
            }            
        </div>
    )
}