import React from 'react';
import ReactDOM from 'react-dom';

import {
    MetaData,
    Utils,
    UIUtils,
    SC,
    Strings,
    AppLayout
} from '../../../../../../../importer';

import BaseGeneric from '../base';
import { onGetChildItem } from '../../common';

export default class Generic_ProgressBar extends BaseGeneric {
    constructor(props) {
        super(props);        
    }
    UpdateStyle(props) {
        this.renderData = props.GetComponentManager().GetItemStyle(props.Id);        

        const MetaItem = this.props.GetComponentManager().GetMetaItem(this.props.Id);
        if (MetaItem) {
            const ProgressId = Utils.JustGet(MetaItem.Generic, null, 'ProgressId');     
            this.renderDataProgress = props.GetComponentManager().GetItemStyle(ProgressId);
            const ProgressValueId = Utils.JustGet(MetaItem.Generic, null, 'ProgressValueId');     
            this.renderDataProgressValue = props.GetComponentManager().GetItemStyle(ProgressValueId);
        }        
    }
    renderContent({style, props}) {      
        let style_track =  {
            ...style
        };

        const state = this.props.GetComponentManager().ComponentState;
        let statename = Utils.UseNullOrEmpty(state, '').replace(',', '_');
        const classId=`gcss-${this.props.Id}-${statename}`;
        return (
            <SC.FRow alc jsb {...props}>
                <ProgressBar     
                    State={state}
                    classId={classId}
                    value={this.renderData.props.value}
                    style={{
                        track : style_track,
                        progress : this.renderDataProgress.style,
                        progressValue : this.renderDataProgressValue.style
                    }}
                    
                /> 
            </SC.FRow>
        )
    }
}

class ProgressBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value : this.props.value || 50
        }
        this.updateStyle(props);
    }
    updateStyle(props) {
        const style = props.style;        
        const classId = props.classId;

        const style_track = {
            width : '100%',
            ...style.track,
            margin : 0            
        };
        const style_progress = {
            margin : 0,
            backgroundColor : style.track.backgroundColor,
            ...style.progress,
        };
        const style_progressValue = {
            margin : 0,
            ...style.progressValue,
        };

        let css_track = Utils.ConvertObjectToCss(style_track);
        let css_progress = Utils.ConvertObjectToCss(style_progress);
        let css_progressValue = Utils.ConvertObjectToCss(style_progressValue);

        Utils.SetStyleSheet({
            Id : classId, 
            StyleDefinition : `
        .${classId}, .${classId}[role]  {
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;            
            border: none;
            width : 100%;
            height : 100%;            
        }
        .${classId}[role]:after {
            background-image: none;
        }    
        .${classId}[role] strong {
            display: none;
        }

        .${classId}, .${classId}[role][aria-valuenow] {
            ${css_track}
        }
        .${classId}::-webkit-progress-bar {
            ${css_progress}            
        }
        .${classId}::-moz-progress-bar { 
            ${css_progress}
        }        
        .${classId}::-webkit-progress-value {
            ${css_progressValue}
        }
        .${classId}[aria-valuenow]:before  {
            
        }
        `, TargetDocument : this.props.document})
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (!Utils.IsEqual(this.props.style, nextProps.style)) {
            this.updateStyle(nextProps);
        }
        return true;
    }
    componentWillUnmount() {
        // Utils.RemoveStyleSheet(this.props.classId);
    }
    render() {
        return (
            <progress  
                min={1} 
                max={100} 
                step={1} 
                value={this.state.value}
                onChange={(e) => {this.setState({value : e.target.value})}}
                className={this.props.classId}
            />
        )
    }
}