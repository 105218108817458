import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    AppLayout
} from '../../../importer';
import styled from 'styled-components';
import DropDownSelect from '../../../components/editors/enum_dropdown';

export default class TopNavigator extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.OnRouteChanged = this.OnRouteChanged.bind(this);
    }
    OnRouteChanged(module) {
        this.selectedModule = module;
        if (module === 'ComponentList') {
            super.RedirectTo(Links.Board_Components(this.props.BoardId));
        }
        else if (module === 'Admin') {
            super.RedirectTo(Links.Admin());
        }
        else if (module === 'ComponentCanvas') {
            super.RedirectTo(Links.Board_ComponentsCanvas(this.props.BoardId));
        }
        if (module === 'ArtboardList') {
            super.RedirectTo(Links.Board_Artboards(this.props.BoardId));
        }
        else if (module === 'ResponsiveComponentCanvas') {
            super.RedirectTo(Links.Board_ResponsiveComponentsCanvas(this.props.BoardId));
        }  
        else if (module === 'PrototypeCanvasFixedDevice') {
            super.RedirectTo(Links.Prototype_Canvas_FixedDevice(this.props.BoardId, '6TPYbZmPW'));
        }  
        else if (module === 'PrototypeCanvasFreeDevice') {
            super.RedirectTo(Links.Prototype_Canvas_FreeDevice(this.props.BoardId, '6TPYbZmPW'));
        }
        else if (module === 'PrototypeNonCanvas') {
            super.RedirectTo(Links.Prototype_NonCanvas(this.props.BoardId, '6TPYbZmPW'));
        }
        else if (module === 'DSDocument') {
            super.RedirectTo(Links.Board_Documents(this.props.BoardId));
        }
        else if (module === 'Publish') {
            super.RedirectTo(Links.Board_Publish(this.props.BoardId));
        }     
        else if (module === 'ExportToFile') {
            super.RedirectTo(Links.Board_ExportToFile(this.props.BoardId));
        }
        else if (module === 'API') {
            super.RedirectTo(Links.Board_API(this.props.BoardId));
        }
        else if (module === 'Release') {
            super.RedirectTo(Links.Board_Release(this.props.BoardId));
        }        
        else if (module === 'ImportExportTokens') {
            super.RedirectTo(Links.Board_ImportExportTokens(this.props.BoardId));
        }
        else if (module === 'CodePrototype') {
            super.RedirectTo(Links.Board_Playground(this.props.BoardId));
        } 
        else if (module === 'JsonDebugger') {
            super.RedirectTo(Links.Debug_Board_Json(this.props.BoardId));
        }         
    }
    renderCustom() {
        let buttons;

        if (this.props.components && this.props.canvas || this.props.component || this.props.prototype) {
            let previewLink;

            if (this.props.component) {
                previewLink = this.props.preview ? Links.Component_Edit(this.props.BoardId, this.props.component.id) : Links.Component_Preview(this.props.BoardId, this.props.component.id);
            }
            else if (this.props.prototype) {
                if (this.props.prototype.fixedcanvas)
                    previewLink = this.props.preview ? Links.Prototype_Canvas_FixedDevice(this.props.BoardId, this.props.prototype.id) : Links.Prototype_Canvas_FixedDevice_Preview(this.props.BoardId, this.props.prototype.id);
                else if (this.props.prototype.freecanvas)
                    previewLink = this.props.preview ? Links.Prototype_Canvas_FreeDevice(this.props.BoardId, this.props.prototype.id) : Links.Prototype_Canvas_FreeDevice_Preview(this.props.BoardId, this.props.prototype.id);
            }
            else if (this.props.components) {
                if (this.props.responsive) {
                    previewLink = this.props.preview ? Links.Board_ResponsiveComponentsCanvas(this.props.BoardId, this.props.components.id) : Links.Board_ResponsiveComponentsCanvas_Preview(this.props.BoardId, this.props.components.id);
                }
                else {
                    previewLink = this.props.preview ? Links.Board_ComponentsCanvas(this.props.BoardId, this.props.components.id) : Links.Board_ComponentsCanvas_Preview(this.props.BoardId, this.props.components.id);
                }                
            }
            buttons = (
                <SC.FRow style={{marginLeft : '8px'}}>
                    <Links.Link To={previewLink} title='Preview'>
                        <SC.Icons.Icon_Button hasCursor hasFill style={{height : '100%'}}>
                            <SC.Icons.Icon_View size={22} off={this.props.preview} />
                        </SC.Icons.Icon_Button>
                    </Links.Link> 
                </SC.FRow>
            )
        }
        
        return (
            <React.Fragment>
                <SC.FRow f1 />
                <SC.FRow f1>
                    <DropDownSelect
                        fullwidth
                        noBackColor
                        autoHeight
                        light
                        largeText
                        placeholder='Select Module'
                        style={{minWidth : '240px', maxHeight : '28px', borderRadius : '2px', border : SC.CurrentTheme.theme.border_ondark}}
                        renderLabelItem={(item) => {
                            return (
                                <DropDownMenuItem style={{flex : 1, fontSize : '12px'}}>
                                    <SC.FRow alc justifyCenter f1>
                                        {item}
                                    </SC.FRow>                                
                                </DropDownMenuItem>
                            )
                        }}
                        renderItem={(item, selected) => {
                            return (
                                <SC.FRow alc jsb>                                    
                                    <div style={{textAlign : 'center', flex : 1}}>{item.label}</div>
                                    {selected && <SC.Icons.Icon_Button hasFill fill={SC.CurrentTheme.theme.color_brand} style={{marginLeft : '4px'}}><SC.Icons.Icon_Check size={20} /></SC.Icons.Icon_Button>}
                                </SC.FRow>
                            )
                        }}
                        items={[
                            {id : 'Admin', label : 'Admin'},
                            {id : 'ComponentList', label : 'Component List'},
                            // {id : 'ComponentCanvas', label : 'Components Canvas'},
                            {id : 'ArtboardList', label : 'Responsive Components Canvas List'},
                            {id : 'ResponsiveComponentCanvas', label : 'Responsive Components Canvas'},
                            // {id : 'PrototypeCanvasFixedDevice', label : 'Prototype Canvas - Fixed Devices'},
                            // {id : 'PrototypeCanvasFreeDevice', label : 'Prototype Canvas - Free Devices'},
                            // {id : 'PrototypeNonCanvas', label : 'Prototype'},
                            {id : 'DSDocument', label : 'Design System Documents'},
                            {id : 'Publish', label : 'Export to Code'},
                            {id : 'ExportToFile', label : 'Export to File'},
                            {id : 'API', label : 'API'},
                            {id : 'Release', label : 'Release'},
                            {id : 'ImportExportTokens', label : 'Import/Export Tokens'},
                            {id : 'CodePrototype', label : 'Prototype with Code'},
                            {id : 'JsonDebugger', label : 'JSON Debugger'},
                        ]}
                        onChange={this.OnRouteChanged}
                        value={this.selectedModule}
                    />
                    {buttons}
                </SC.FRow>
            </React.Fragment>            
        )
    }
}

const DropDownMenuItem = styled.div`
    font-size : 14px;
    letter-spacing : 0.07em;    
    display : flex;
    align-items : center;
    height : 100%;
    cursor : pointer;
    font-weight : 500;
    text-transform : uppercase;
    color : ${props => props.selected ? props.theme.font_hover : props.theme.font};
    &:hover {
        color : ${props => props.theme.font_hover};
    }
`;