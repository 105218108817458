import React from 'react';
import ReactDOM from 'react-dom';
import BaseItem, { BuildItemStyles } from '../../BaseItem';
import { Utils, MetaData } from '../../../importer';

class Progress extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			value: this.props.value || 50
		}
		this.updateStyle(props);
	}
	
	updateStyle(props) {
		const style = props.style;
		const classId = props.classId;

		const {
			':hover': styleobj_track_hover,
			...styleobj_track_rest } = style.track;

		const {
			':hover': styleobj_progress_hover,
			...styleobj_progress_rest } = style.progress;
		const {
			':hover': styleobj_progressValue_hover,
			...styleobj_progressValue_rest } = style.progressValue;

		const style_track = {
			...styleobj_track_rest,
			width: '100%',
			margin : 0,
			boxSizing : 'border-box'
		};
		const style_progress = {
			margin : 0,			
			backgroundColor : style.track.backgroundColor,
			...styleobj_progress_rest,			
		};
		const style_progressValue = {
			margin : 0,
			...styleobj_progressValue_rest,
		};

		let css_track = Utils.ConvertObjectToCss(style_track);
		let css_track_hover = Utils.ConvertObjectToCss(styleobj_track_hover);

		let css_progress = Utils.ConvertObjectToCss(style_progress);
		let css_progress_hover = Utils.ConvertObjectToCss(styleobj_progress_hover);
		let css_progressValue = Utils.ConvertObjectToCss(style_progressValue);
		let css_progressValue_hover = Utils.ConvertObjectToCss(styleobj_progressValue_hover);

		Utils.SetStyleSheet({
			Id: classId,
			StyleDefinition: `
			.${classId}, .${classId}[role]  {
				appearance: none;
				-moz-appearance: none;
				-webkit-appearance: none;            
				border: none;
				width : 100%;
				height : 100%;
			}
			.${classId}[role]:after {
				background-image: none;
			}    
			.${classId}[role] strong {
				display: none;
			}
	
			.${classId}, .${classId}[role][aria-valuenow] {
				${css_track}
			}
			.${classId}::-webkit-progress-bar {
				${css_progress}            
			}
			.${classId}::-moz-progress-bar { 
				${css_progress}
			}        
			.${classId}::-webkit-progress-value {
				${css_progressValue}
			}
			.${classId}[aria-valuenow]:before  {
				
			}
        `, TargetDocument: this.props.document
		})
	}
	shouldComponentUpdate(nextProps, nextState) {
		if (!this.suspendPropUpdate) {
			if (!Utils.IsEqual(this.props.style, nextProps.style)) {
				this.updateStyle(nextProps);
			}
			if (nextProps.value !== nextState.value) {
				this.state.value = nextProps.value;
				return true;
			}
		}
		
		return true;
	}
	componentWillUnmount() {
        Utils.RemoveStyleSheet(this.props.classId);
    }
	render() {
		const { min, max } = this.props;
		return (
			<progress
				min={min}
				max={max}
				value={this.state.value}
				disabled={this.props.disabled}
				className={this.props.classId}				
			/>
		)
	}
}

class Generic_Progress extends BaseItem {
	constructor(props) {
		super(props);

		this.InstanceId = Utils.Id();
	}	
	renderItem({ style, props, events, rest }) {
		const { MetaItem } = this.props;

		let style_Progress;		
		const ProgressId = Utils.JustGet(MetaItem.Generic, null, 'ProgressId');
		if (ProgressId) {
			const MetaItemProgress = Utils.Find(MetaItem.SubItems, (Item) => { return Item.Id === ProgressId });
			if (MetaItemProgress) {
				const ProgressStlye = BuildItemStyles(MetaItemProgress, this.props);
				if (ProgressStlye) {
					style_Progress = ProgressStlye.style;
				}
			}
		}

		let style_ProgressValue;		
		const ProgressValueId = Utils.JustGet(MetaItem.Generic, null, 'ProgressValueId');
		if (ProgressValueId) {
			const MetaItemProgressValue = Utils.Find(MetaItem.SubItems, (Item) => { return Item.Id === ProgressValueId });
			if (MetaItemProgressValue) {
				const ProgressValueStlye = BuildItemStyles(MetaItemProgressValue, this.props);
				if (ProgressValueStlye) {
					style_ProgressValue = ProgressValueStlye.style;
				}
			}
		}
		const models = Utils.JustGet(this.props, {}, 'Models');

		return (
			<div style={{ width: style.width, maxWidth: style.maxWidth }}>
				<Progress
					classId={`gcssre-${this.InstanceId}`}
					document={this.props.document}
					disabled={this.props.IsStateActive('disabled', 'disabled')}
					value={Utils.JustGet(models, 0, 'value')}
					min={Utils.JustGet(models, 0, 'min')}
					max={Utils.JustGet(models, 0, 'max')}
					style={{
						track: style || {},
						progress: style_Progress || {},
						progressValue: style_ProgressValue || {}
					}}
				/>
			</div>
		)
	}
}

export default Generic_Progress;
