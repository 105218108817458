import DataType from '../datatypes';

const CommonAttributes = [
    
];

const Path = {
    type : 'path',
    Attributes : CommonAttributes.concat(
        [
            'd', 'fill', 'stroke', 'stroke-width', 'stroke-linecap', 'stroke-dasharray', 'stroke-dashoffset'
        ]
    ),
};

const Circle = {
    type : 'circle',
    Attributes : CommonAttributes.concat(
        [
            'cx', 'cy', 'r', 'fill', 'stroke', 'stroke-width', 'stroke-linecap', 'stroke-dasharray', 'stroke-dashoffset'
        ]
    ),
};

const Elements = {
    Path,
    Circle
}
export default Elements;