import React from 'react';
import BaseItem from '../../BaseItem';
import {Utils, MetaData} from '../../../importer';

class Generic_Text extends BaseItem
{
    constructor(props) {
        super(props);

        this.InstanceId = Utils.Id();     
    }
    renderItem({style, props, events, rest}) {                   
        const models = Utils.JustGet(this.props, {}, 'Models');
     
        return (
            <div style={style}>
                {props.text}
            </div>
        )
    }
}

export default Generic_Text;