import {
    AppState,
    Events,    
    Utils,
    Globals,
    MetaData,
    SC
} from '../../../../importer';

const GetData = () => {
    return Utils.Get(Globals.ProjectManager.Data, {}, 'Data');
}

export default class CustomTokensManager {
    constructor(Manager) {        
        this.Manager = Manager;
    }
    GetData() {
        return Utils.JustGet(this.Manager, {}, 'Data', 'Data');
    }
    GetTokenTypeIds() {
        return Utils.JustGet(this.GetData(), [], 'customTokens', 'types', 'order');
    }
    GetTokenTypes() {
        return Utils.JustGet(this.GetData(), [], 'customTokens', 'types', 'list');
    }
    Add({id, ...props}) {
        const order = this.GetTokenTypeIds();
        order.push(id);
        this.Manager.DataManager.Set(order, 'customTokens', 'types', 'order');
        this.Manager.DataManager.Set({...props}, 'customTokens', 'types', 'list');
    }
}