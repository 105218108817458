import React from 'react';
import ReactDOM from 'react-dom';

import {
    MetaData,
    Utils,
    UIUtils,
    SC,
    Strings,
    AppLayout
} from '../../../../../../../importer';

import BaseGeneric from '../base';
import { onGetChildItem } from '../../common';

export default class Generic_Checkbox extends BaseGeneric {
    constructor(props) {
        super(props);        
    }
    shouldComponentUpdate(nextProps, nextState) {
        let willUpdate = super.shouldComponentUpdate(nextProps, nextState);
        return willUpdate;
    }
    renderContent({style, props}) {
        const style_check = {};

        style_check.stroke = this.renderData.props.checkFill || '#000';
        style_check.strokeWidth = this.renderData.props.checkStrokeWidth || 1;
        const roundCap = this.renderData.props.roundCap;
        if (roundCap) {
            style_check.strokeLinecap = 'round';
            style_check.strokeLinejoin = 'round';
        }

        const checkSize = (this.renderData.props.checkSize || 100) * 2;
        style_check.width = Utils.px(checkSize, '%');
        style_check.height = Utils.px(checkSize, '%');

        let isCheckVisible = this.props.GetComponentManager().IsStateActive('Checked') ? true : false;

        return (
            <SC.Div_Relative {...props} style={{...style}}>
                <input type='checkbox' style={{
                    border: 0,
                    clip: 'rect(0 0 0 0)',
                    clippath: 'inset(50%)',
                    height: '1px',
                    margin: '-1px',
                    overflow: 'hidden',
                    padding: 0,
                    position: 'absolute',
                    whiteSpace: 'nowrap',
                    width: '1px'
                }}  checked={isCheckVisible}/>
                <div style={{...SC.Styles.Flex.Cell, ...SC.Styles.Absolute, opacity : (this.renderData.props.checkOpacity || 0) / 100}}>
                    <svg viewBox="0 0 24 24" style={style_check}>
                        <path fill="none" d="M6,11.3 L10.3,16 L18,6.2" />
                    </svg>
                </div>
            </SC.Div_Relative>
        )
    }
}