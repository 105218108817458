import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    AppLayout,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../../../../../importer';

import { GetUnsortedBorderRadiusTokenList, GetUnsortedBorderRadiusTokenListOfIds, GetBorderRadiusTokenValue } from '../../../../../panels/left/designsystem/borders';
import { GetTokenAliaseListOfIds, GetTokenAliaseList } from '../../../../../panels/left/designsystem/aliases';

export default class DocumentToken_Radii extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {

        };    
    }
    shouldComponentUpdate(nextProps, nextState) {

        if (this.state.ShouldUpdate !== nextState.ShouldUpdate)
            return true;
        
        if (Utils.HasAnyChange(this.props, nextProps, 'GlobalThemeId', 'GlobalState', 'StyleId', 'RenderId', 'LoadId', 'themeId', 'themeType', 'states', 'ReversedStyleState', 'isAliase'))
            return true;
        
        return false;
    }
    componentDidUpdate(prevProps, prevState) {
        this.state.ShouldUpdate = false;
    }
    render() {   
        let borders;
        if (this.props.isAliase) {
            const aliases = this.props.selection.type === 'selected' ? GetTokenAliaseListOfIds(this.props.selection.tokenIds, this.props.ReversedStyleState) : GetTokenAliaseList(Globals.ProjectManager.Tokens.Types.BorderRadiuses , this.props.ReversedStyleState);
            borders = [];
            Utils.ForEach(aliases, (aliase, i) => {
                const tokenItem = GetBorderRadiusTokenValue(Globals.ProjectManager.Tokens.Token(aliase.tokenId), aliase.tokenId);
                borders.push({
                    id : aliase.id,
                    model : {
                        name : aliase.name
                    },
                    style : tokenItem.style
                })
            });
        }
        else {
            borders = this.props.selection.type === 'selected' ? GetUnsortedBorderRadiusTokenListOfIds(this.props.selection.tokenIds, this.props.ReversedStyleState) : GetUnsortedBorderRadiusTokenList(this.props.ReversedStyleState);
        }

        const borderItems = [];

        const cardMinWidth = (100 * (this.props.cardSize || 100)) / (this.props.viewMode === 'card' ? 100 : 200);
        const cardMaxWidth = (120 * (this.props.cardSize || 100)) / (this.props.viewMode === 'card' ? 100 : 200);

        if (this.props.viewMode === 'card') {
            Utils.ForEach(borders, (border, ) => {
                borderItems.push(
                    <SC.FCol key={border.id}>
                        <div style={{flex : 1, borderRadius : '4px', marginBottom : '8px',
                            borderStyle : 'solid', borderWidth : '1px', borderColor : SC.CurrentTheme.theme.color_brand, borderRadius : border.style,
                            minHeight : '24px'
                        }} />
                        <div style={{}}>{border.model.name}</div>
                        <div style={{fontFamily : 'monospace'}}>{border.style}</div>
                    </SC.FCol>                    
                )

                if (this.props.forTemplateSelect && borderItems.length === 3) {
                    return false;
                }
            });
            
            return (
                <div style={{
                    display : 'grid',
                    gridGap : '16px',
                    gridTemplateColumns : `repeat(auto-fill, minmax(${cardMinWidth}px, 1fr))`,
                    gridAutoRows : Utils.px(cardMaxWidth),
                    alignSelf : this.props.alignment,
                    width : this.props.size ? Utils.px(this.props.size, '%') : 'auto'
                }}
                >
                    {borderItems}
                </div>
            )  
        }
        else {

        }

        Utils.ForEach(borders, (border, ) => {                
            borderItems.push(
                <React.Fragment>
                    <div style={{fontFamily : 'monospace'}}>{border.model.name}</div>
                    <div style={{fontFamily : 'monospace'}}>{border.style}</div>
                    <div style={{flex : 1, aspectRatio : '1/1', borderRadius : '4px', maxWidth : '80px', minHeight : '40px', maxHeight : '80px', marginBottom : '8px', borderStyle : 'solid', borderWidth : '2px', borderColor : SC.CurrentTheme.theme.color_brand, borderRadius : border.style}}>
                    </div>                                           
                </React.Fragment>                    
            )

            if (this.props.forTemplateSelect && borderItems.length === 3) {
                return false;
            }
        });

        return (
            <div style={{
                display : 'grid',
                gridGap : '24px',
                gridColumnGap : '48px',
                gridTemplateColumns : 'auto auto minMax(50px, auto)',
                gridAutoRows : 'auto',
                justifyContent : 'start',
                alignItems : 'center',
                alignSelf : this.props.alignment,
                width : this.props.size ? Utils.px(this.props.size, '%') : 'auto'
            }}
            >
                <div>NAME</div>
                <div>RADIUS</div>
                <div />                
                {borderItems}
            </div>
        )     
    }
}