import React from 'react';
import {
    ReactBaseComponent,
    Events,
    SC,
} from '../../../importer';
import styled, {css} from 'styled-components';
import Globals from '../../../appstate/Globals';

const HistoryInfo = styled.div`
    position : absolute;
    right : calc(100% + 10px);
    width : 400px;
    bottom : 0;
    top : 0;
    padding : 4px;
    padding-right : 12px;        
    display : flex;
    align-items : center;
    justify-content : flex-end;
    margin-top : 4px;
    margin-bottom : 4px;
    margin-right : -8px;
`;
class HistoryBar extends ReactBaseComponent
{
    constructor(props) {
        super(props);
        this.Undo = this.Undo.bind(this);
        this.Redo = this.Redo.bind(this);
        this.ShowHistory = this.ShowHistory.bind(this);
        super.ListenEvents(
            Events.DESIGNER.HISTORY.LIST,
            Events.DESIGNER.COMPONENT.HISTORY.LOG,
            Events.DESIGNER.COMPONENT.HISTORY.CHANGED
        );
        const Manager = this.GetManager();
        if (Manager) {
            this.HasHistoryPrev = Manager.HasPrev();
            this.HasHistoryNext = Manager.HasNext();     
        }        
    }   
    GetManager() {
        return Globals.ProjectManager.HistoryManager;
    }
    OnEvent(Event, Params) {
        if (Event === Events.DESIGNER.HISTORY.LIST && Params[0] === Events.PARAMS.HIDE)
            this.HistoryOpen = false;
        else if (Event === Events.DESIGNER.COMPONENT.HISTORY.LOG) {      
            const Manager = this.GetManager();
            if (Manager) {
                this.HasHistoryPrev = Manager.HasPrev();
                this.HasHistoryNext = Manager.HasNext();
            }                  
        }
        else if (Event === Events.DESIGNER.COMPONENT.HISTORY.CHANGED) {
            // const [IsUndo, Desc] = Params;
            // this.ShowHistoryInfo = `${IsUndo ? 'Undo ' : 'Redo '} ${Desc}`;
            // if (this.historyTimer)
            //     clearTimeout(this.historyTimer);
                
            // this.historyTimer = setTimeout(() => {
            //     this.ShowHistoryInfo = null;
            //     delete this.historyTimer;
            //     this.RCUpdate();
            // }, 2000);
        }
        this.RCUpdate();
    }
    ShowHistory() {
        if (this.HistoryOpen) {
            this.HistoryOpen = false;
            Events.BCE(Events.DESIGNER.HISTORY.LIST, Events.PARAMS.HIDE);
        }
        else {
            this.HistoryOpen = true;        
            Events.BCE(Events.DESIGNER.HISTORY.LIST, Events.PARAMS.SHOW);
        }
        this.RCUpdate();
    }
    Undo() {
        this.GetManager() && this.GetManager().Undo();      
    }
    Redo() {
        this.GetManager() && this.GetManager().Redo();
    } 
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.Id !== this.props.Id) {
            this.HasHistoryPrev = false;
            this.HistoryOpen = false;
            this.HasHistoryNext = false;
            return true;
        }
        if (this.props.hidden !== nextProps.hidden)
            return true;
        return super.ShouldUpdate(nextProps, nextState);
    }
    
    renderCustom() {
        
        return (
            <SC.FRow style={{position : 'relative', visibility : this.props.hidden ? 'hidden' : 'visible'}}>     
                <SC.Icons.Icon_Button 
                    style={{padding : '4px', cursor : 'pointer'}}
                    onClick={this.HasHistoryPrev ? this.Undo : undefined} 
                    title='Undo'
                    hasFill
                    fill={this.HasHistoryPrev ? null : '#494949'}
                    hoverFill={this.HasHistoryPrev ? null : '#494949'}
                >
                    <SC.Icons.Icon_Undo />
                </SC.Icons.Icon_Button>
                <SC.Icons.Icon_Button 
                    style={{padding : '4px', cursor : 'pointer'}}
                    onClick={this.HasHistoryNext ? this.Redo : undefined} 
                    title='Undo'
                    hasFill
                    fill={this.HasHistoryNext ? null : '#494949'}
                    hoverFill={this.HasHistoryNext ? null : '#494949'}
                >
                    <SC.Icons.Icon_Redo />
                </SC.Icons.Icon_Button>
                <SC.Icons.Icon_Button 
                    style={{padding : '4px', cursor : 'pointer'}}
                    onClick={(this.HasHistoryNext || this.HasHistoryPrev) ? this.ShowHistory : null}
                    title='History Panel' 
                    hasFill
                    fill={(this.HasHistoryPrev || this.HasHistoryNext) ? (this.HistoryOpen ? SC.CurrentTheme.theme.color_brand : null) : '#494949'}
                    hoverFill={(this.HasHistoryPrev || this.HasHistoryNext) ? null : '#494949'}
                >
                    <SC.Icons.Icon_History size={20} />
                </SC.Icons.Icon_Button>
                {
                    this.ShowHistoryInfo ? 
                    <HistoryInfo>
                        {this.ShowHistoryInfo}
                    </HistoryInfo> : null
                }
            </SC.FRow>
        )        
    }
}

export default HistoryBar;