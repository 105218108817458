import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    MetaData,
    Strings,
    Globals
} from '../../../../../../../importer';
import { TokenGroup, TokenItemBox } from '../../common';

import BaseGroup from '../base';
import { StyleToken_Color, PropertyLabel } from '../../../../right/iteminspector/styleitems/common';
import { TextStyleEditors } from '../../../../right/iteminspector/styleitems/text';

export default class Containers extends BaseGroup
{
    constructor(props) {
        super(props);        
        this.elementType = Globals.ProjectManager.Templates.Types.Text;
        this.title = 'TEXT';
        this.DefaultId = Globals.ProjectManager.Templates.DefaultTemplateId(this.elementType);

        this.LoadOptions();        
    }
    SelectColor(templateId, tokenIdcolor) {
        const backgroundTokenId = Globals.ProjectManager.Templates.GetStateValue({Id : Globals.ProjectManager.Templates.DefaultTemplateId(Globals.ProjectManager.Templates.Types.Container), name : 'backgroundColor'});
        let onBackgroundColor;
        if (backgroundTokenId) 
            onBackgroundColor = Globals.ProjectManager.Tokens.GetStateValue({Id : backgroundTokenId, StateArray : this.props.StateArray});
        this.SelectToken({templateId : templateId, propName : 'color', type : AppState.ItemTypes.BOARD.COLOR, title : 'TEXT COLOR',
            props : {
                text : true,
                onBackgroundColor : onBackgroundColor,
                hasAccessibility : true,
                color : tokenIdcolor
            }
        });
    }
    SelectTextPattern(templateId) {        
        this.SelectToken({templateId : templateId, propName : 'textPattern', type : AppState.ItemTypes.BOARD.PATTERN_TEXT, title : 'TEXT COLOR',});
    }
    SetFontStyle(templateId, prop, value) {
        Globals.ProjectManager.Templates.SetValue({id : templateId, value : value, name : prop, type : this.elementType});
        this.RCUpdate();
    }
    renderDefault() {
        const defaultTemplate = Globals.ProjectManager.Templates.DefaultTemplate(this.elementType);
        const tokenIdcolor = Globals.ProjectManager.Templates.GetStateValue({Id : this.DefaultId,  name : 'color'});
        const tokenIdtextPattern = Globals.ProjectManager.Templates.GetStateValue({Id : this.DefaultId,  name : 'textPattern'});
        const textAlign = Globals.ProjectManager.Templates.GetStateValue({Id : this.DefaultId,  name : 'textAlign'});
        const fontStyle = Globals.ProjectManager.Templates.GetStateValue({Id : this.DefaultId,  name : 'fontStyle'});
        const textDecoration = Globals.ProjectManager.Templates.GetStateValue({Id : this.DefaultId,  name : 'textDecoration'});
        const textTransform = Globals.ProjectManager.Templates.GetStateValue({Id : this.DefaultId,  name : 'textTransform'});
        const isUnderline = textDecoration === 'underline';
        const isOverline = textDecoration === 'lineThrough';

        return (
            <SC.FCol style={{padding : '8px'}}>      
                <TextStyleEditors 
                    colorId={tokenIdcolor}
                    textPatternId={tokenIdtextPattern}
                    textAlign={textAlign}
                    fontStyle={fontStyle}
                    isUnderline={isUnderline}
                    isOverline={isOverline}
                    isLowerCase={textTransform === 'lowercase'}
                    isUpperCase={textTransform === 'uppercase'}
                    onSelectTextPattern={this.SelectTextPattern.bind(this, this.DefaultId)}
                    onSelectColor={this.SelectColor.bind(this, this.DefaultId, tokenIdcolor)}
                    onSetFontStyle={this.SetFontStyle.bind(this, this.DefaultId)}
                />                 
            </SC.FCol>
        )
    }    
}