import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Globals,
    Utils,
    Events
} from '../../../../../../../importer';

import { TokenPanelHeader, PanelHeaderAddButton, GroupTitle } from '../../../../left/designsystem/common';
import MultipleTokenSelector from '../../../../left/designsystem/groupmembers';

export default class TokenGroupMembers extends ReactBaseComponent 
{
    constructor(props) {
        super(props);

        this.SaveMembers = this.SaveMembers.bind(this);        

        this.tokens = [];

        if (this.props.group) {
            if (this.props.group.tokens) {
                Utils.ForEach(this.props.group.tokens, ({id}, ) => {
                    this.tokens.push(id);
                });
            }          
        }
    }

    SaveMembers(tokens) {
        if (this.props.isAliase)
            Globals.ProjectManager.Tokens.Aliases.SetGroupOrder(this.props.tokenType, this.props.group.id, tokens);        
        else
            Globals.ProjectManager.Tokens.SetGroupOrder(this.props.tokenType, this.props.group.id, tokens);        

        Events.BCE(Events.GLOBAL.TOKENS_CHANGED);
    }

    renderCustom() {
        return (
            <SC.FCol f1>
                <TokenPanelHeader title={this.props.title} onClose={this.props.onClose} style={{padding : '2px'}} />
                <SC.FCol f1 style={{backgroundColor : SC.CurrentTheme.theme.back}}>
                    <MultipleTokenSelector 
                        tokenType={this.props.tokenType}
                        selectedTokens={this.tokens}
                        isAliase={this.props.isAliase}
                        onSelectionChanged={this.SaveMembers}
                    />
                </SC.FCol>
            </SC.FCol>
        )
    }

}