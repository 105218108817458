import {
    Utils,
    AppState,
    SC,
    Strings,
    AppLayout,
    Globals
} from '../../../../importer';
import { GetDataApi, GetUserId, GetUserName } from "../../../../appstate/AppState";
import firebase from '@firebase/app';
import { checkFirebaseJson } from "../../../../db/firebasedb";

var config = {
    apiKey: "AIzaSyAYzCR2XKn-9Ha592WTJAs0Q-v0r5fcb4M",
    authDomain: "toolabs-beta-20-2.firebaseapp.com",
    databaseURL: "https://toolabs-beta-20-2-figma.firebaseio.com",
    projectId: "toolabs-beta-20-2",
    storageBucket: "toolabs-beta-20-2.appspot.com",
    messagingSenderId: "30542139765",
    appId: "1:30542139765:web:09f2b838e4f536e450833c"
};

let firebaseFigmaApp;

export default  class FigmaManager {
    constructor(Manager) {
        this.Manager = Manager;

    }
    Reload() {
        delete this.Data;
        return this.Load();
    }
    Load() {
        return new Promise((resolve) => {
            if (this.Data) {
                resolve();
                return;
            }
            const path = `/data/${this.Manager.Id}/`;
            this.get_path(path).then((result) => {
                this.Data = Utils.DeepClone(result);
                if (!this.Data) {
                    this.Data = {
                    };
                    
                    // this.set_path(path, this.Data);
                }                
                resolve();
            })
        });         
    }
    AddComponent({state, fileId, componentId, name, description, componentSvg, width, height, tokens}) {
        const existingComponent = this.Component(componentId) || {};
        const data = {
            ...existingComponent,
            fileId : fileId,            
            name : name,
            description : description,
            width : width,
            height : height,
            
        }
        Utils.Set(data, componentSvg, 'svg', state || 'Default');
        if (tokens) {
            const useTokens = {                
            };

            Utils.ForEach(tokens, (tokenInfo, ) => {
                Utils.Set(useTokens, {
                    name : tokenInfo.name,
                    type : tokenInfo.type
                }, 'nodes', tokenInfo.id);

                [
                    {type : 'fills', name : 'fillStyleId'},
                    {type : 'strokes', name : 'strokeStyleId'},
                    {type : 'effects', name : 'effectStyleId'},
                    {type : 'textStyleId', name : 'textStyleId'},
                ].map((figmaStyle) => {
                    const tokenId = Utils.JustGet(tokenInfo, null, figmaStyle.name, 'tokenId');
                    if (tokenId) {
                        const tokenNodes = Utils.Get(useTokens, [], 'tokens', tokenId);
                        tokenNodes.push({
                            nodeId : tokenInfo.id,
                            style : figmaStyle.type
                        });
                    }
                });

                if (tokenInfo.tokens && Array.isArray(tokenInfo.tokens) && tokenInfo.tokens.length > 0) {
                    tokenInfo.tokens.map(({id, type}) => {
                        if (id) {
                            const tokenNodes = Utils.Get(useTokens, [], 'tokens', id);
                            tokenNodes.push({
                                nodeId : tokenInfo.id,
                                style : type
                            });
                        }
                    })
                }

                data.tokens = useTokens;

            });
        }

        Utils.Set(this.Data, data, 'components', componentId);
        this.set_path(`/data/${this.Manager.Id}/components/${componentId}`, data);
        // AppState.GetDataApi().update_path_figma_data(this.Manager.Id, ['components', componentId], data);
    }
    DeleteComponent(componentId) {
        Utils.UnSet(this.Data, 'components', componentId);
        this.delete_path(`/data/${this.Manager.Id}/components/${componentId}`);
        // AppState.GetDataApi().delete_path_figma_data(this.Manager.Id, ['components', componentId]);
    }
    Components() {
        return Utils.Get(this.Data, {}, 'components');
    }    
    Component(componentId) {
        return Utils.Get(this.Data, {}, 'components', componentId);
    }

    // Database
    getDataBase() {
        if (!firebaseFigmaApp)
            firebaseFigmaApp = firebase.initializeApp(config, 'figma');
        return firebase.database(firebaseFigmaApp);
    }
    set_path(path, value) {
        this.getDataBase().ref(path).set(checkFirebaseJson(value));
    }
    delete_path(path) {
        this.getDataBase().ref(path).remove();
    }
    get_path(path) {
        return new Promise((resolve) => {
            this.getDataBase().ref(path).once('value').then(function (snapshot) {
                if (snapshot) {
                    resolve(snapshot.val());
                }
            });
        })
    }
}