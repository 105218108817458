import React from 'react';
import BaseItem from './BaseItem';
import {Utils} from '../importer';
import GetItemType from './index';
import { motion } from 'framer-motion';

export const GetSubItems = function(SubMetaItems, Props, ItemProps, RootStateId) {
    const SubItems = [];
    if (SubMetaItems) {
        Utils.ForEach(SubMetaItems, (SubMetaItem, i) => {
            const SubItemType = GetItemType(SubMetaItem);
            if (SubItemType) {
                SubItems.push(
                    <SubItemType
                        key={SubMetaItem.Id}
                        Id={SubMetaItem.Id}
                        MetaItem={SubMetaItem}                        
                        StateId={Props.StateId}
                        ThemeId={Props.ThemeId}
                        Theme={Props.Theme}
                        Models={Props.Models}
                        StyleState={Props.StyleState}                    
                        ReverseStyleState={Props.ReverseStyleState}             
                        GetComponentMetaData={Props.GetComponentMetaData}           
                        GlobalVariations={Props.GlobalVariations}
                        
                        onGetModelValue={Props.onGetModelValue}
                        onSetModelValue={Props.onSetModelValue}
                        IsStateActive={Props.IsStateActive}
                        onSetState={Props.onSetState}
                        onUnsetState={Props.onUnsetState}
                        onUnsetState={Props.UnsetState}
                        renderSlot={Props.renderSlot}
                        renderCollectionItem={Props.renderCollectionItem}
                        {...ItemProps}
                    />
                )
            }
        });
    }
    return SubItems;
}

export default class Container extends BaseItem
{
    constructor(props) {
        super(props);
    }    
    renderItem({style, props, transitions, events, rest}) {                
        
        // this.RenderIndex = (this.RenderIndex || 0) + 1;
        if (style.overflowX && !style.overflowY)
            style.overflowY = 'hidden';
        if (style.overflowY && !style.overflowX)
            style.overflowX = 'hidden';
            
        const content = GetSubItems(this.props.MetaItem.SubItems, this.props, {
            getDataRowValue : this.props.getDataRowValue,
            index : this.props.index,
            document : this.props.document
        }, this.RootStateId);

        let transitionHover = Utils.JustGet(transitions, null, 'hover');
        if (!transitionHover) {
            if (this.props.RootItem) {
                transitionHover = Utils.JustGet(this.props.RootItem.transitions, null, 'hover');
            }
        }


        if (transitionHover) {

            const transformTemplate = ({x, y}) => {
                const t = `matrix(1, 0, 0, 1, 0, ${Utils.UseNullOrEmpty(y, '0').replace('px','')})`;
                return t;
            };

            return (
                <motion.div
                    initial={{
                        opacity : Utils.UseNullOrEmpty(style.opacity, 1)
                    }}
                    whileHover={{...transitionHover.changes}}
                    transition={{...transitionHover.transition}}
                    // transformTemplate={transformTemplate}
                    style={{
                        // overflow : 'auto', 
                        ...style,
                        transformOrigin : transitionHover.transformOrigin
                    }}
                    {...events}
                    {...rest}
                >
                    {content}
                </motion.div>
            );
        }
        return (
            <div               
                style={{
                    // overflow : 'auto', 
                    ...style
                }}
                {...events}
                {...rest}
            >
                {content}
            </div>
        )
    }
}