import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    AppLayout,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../../../../../importer';
import { GetCustomTokenList, GetCustomTokenListOfIds } from '../../../../../panels/left/designsystem/custom/tokens';

import { GetTextContentTokenList, GetTextContentTokenListOfIds } from '../../../../../panels/left/designsystem/textContent';
import { DocumentLabel, DocumentTextValue } from '../typography';


export default class DocumentToken_Texts extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {

        };    

        this.tokenTypeModel = Globals.ProjectManager.Tokens.Token(this.props.tokenType);
        this.tokenFields = [];

        if (this.tokenTypeModel) {
            Utils.ForEach(this.tokenTypeModel.fieldIds, (fieldId, i) => {
                const field = Utils.JustGet(this.tokenTypeModel, null, 'fields', fieldId);
                if (field) {
                    this.tokenFields.push({
                        id : fieldId,
                        model : field
                    });
                }            
            });
        }        
    }
    shouldComponentUpdate(nextProps, nextState) {

        if (this.state.ShouldUpdate !== nextState.ShouldUpdate)
            return true;
        
        if (Utils.HasAnyChange(this.props, nextProps, 'GlobalThemeId', 'GlobalState', 'StyleId', 'RenderId', 'LoadId', 'themeType', 'states', 'ReversedStyleState'))
            return true;
        
        return false;
    }
    componentDidUpdate(prevProps, prevState) {
        this.state.ShouldUpdate = false;
    }
    render() {        
        // const tokens = this.props.selection.type === 'selected' ? GetCustomTokenListOfIds(this.props.tokenType, this.props.selection.tokenIds, this.props.ReversedStyleState) : GetCustomTokenList(this.props.tokenType, this.props.ReversedStyleState);
        const tokens = GetCustomTokenList(this.props.tokenType, this.props.ReversedStyleState);
        const tokenItems = [];

        Utils.ForEach(tokens, ({id}, ) => {
            const token = Globals.ProjectManager.Tokens.Token(id);
            const fieldItems = [];
        
            Utils.ForEach(this.tokenFields, (field, i) => {                 
                const value = Globals.ProjectManager.Tokens.ValueOf({model : token, name : field.id});              
                fieldItems.push(
                    <React.Fragment>
                        <DocumentLabel>{field.model.name}</DocumentLabel>
                        <DocumentTextValue>{value}</DocumentTextValue>
                    </React.Fragment>
                )
            });

            tokenItems.push(
                <div
                    style={{
                        display : 'grid',
                        gridTemplateColumns : 'auto 1fr',
                        gridColumnGap : '16px',
                        gridRowGap : '8px',
                        alignContent : 'start',                            
                        fontSize : '14px',
                        padding : '16px',
                        borderRadius : '4px',
                        alignSelf : 'flex-start',
                        backgroundColor : this.props.textStyles.backgroundHighlight
                    }}
                >
                    <DocumentLabel>Name</DocumentLabel>
                    <DocumentTextValue>{token.name}</DocumentTextValue>
                    {fieldItems}                    
                </div>
            )
        });

        return (
            <div style={{
                display : 'grid',
                gridGap : '48px',
                gridRowGap : '16px',
                gridTemplateColumns : '1fr',
                gridAutoRows : 'auto',
                justifyContent : 'start',
                alignItems : 'start'
            }}
            >
                {tokenItems}
            </div>
        )  
    }
}