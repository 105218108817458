import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppLayout,
    Links,
    Events,
    Strings,
    MetaData,
    Globals
} from '../../../../../../importer';

import {TokenGroup, TokenPanelHeader, TokenItemBox} from '../../designsystem/common';
import {UIElementGrid} from '../UIElements';
import ToolbarItem, {DraggableToolbarItem, ElementBox} from '../UIElements/element';
import ToolbarIcons from '../../../../../../styled/toolbar';
import {MOCKUP_FAKETYPES} from '../../designsystem/mockupcontent/collection/populator';
import Loadable from 'react-loadable';
import MockupSchemaEditor from '../../designsystem/mockupcontent/collection/schemaEditor';

const MockupInitializer = Loadable({
    loader: () => import('./initializer'),
    loading: () => <SC.Loading_Icon />
}); 

export default class MockupDataPanel extends ReactBaseComponent {
    constructor(props) {
        super(props);        

        this.AddCollection = this.AddCollection.bind(this);
        this.EditCollection = this.EditCollection.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
        
        this.onSortToken = this.onSortToken.bind(this);

        this.OnDragEnd = this.OnDragEnd.bind(this);

        this.Ref_DataEditor = React.createRef();
        this.onToggleExpand = this.onToggleExpand.bind(this);
        this.state.expanded = !this.props.expanable || Globals.ProjectManager.Options.Get(true, 'LeftPanel', 'DesignSystem', 'MockupDataPanel', 'Expanded');

        if (this.state.expanded) {
            this.Load();
            this.wasNotInitialized = this.isInitialized;
        }
            

        if (AppLayout.AppSource.Figma) {
            super.ListenEvents(Events.GLOBAL.MOCKUPS_CHANGED);
        }
    }
    OnEvent(Event, Params) {
        this.Load();
        this.RCUpdate();
    }
    onToggleExpand() {
        const expanded = !this.state.expanded;
        Globals.ProjectManager.Options.Set(expanded, 'LeftPanel', 'DesignSystem', 'MockupDataPanel', 'Expanded');
        if (expanded)
            this.Load();

        this.setState({
            expanded : expanded
        })        
    }      
    OnDragEnd() {
        setTimeout(() => {
            this.props.onClose && this.props.onClose();
        }, 50);        
    }  
    Load(props) {
        this.EditTokenId = this.props.id;
        this.isInitialized = Globals.ProjectManager.Mockups.IsInitialized();
        this.dataItems = LoadMockupFields({forSelect : this.props.forSelect});        
    }  
    componentDidMount() {
        super.componentDidMount();
        if (!this.wasNotInitialized && !this.isInitialized) {
            this.RCUpdate();
            return;
        }
        if (this.props.EditMode) {
            if (this.props.id) {
                this.OpenEditor(this.props.id);
            }                
            else if (this.dataItems.length > 0) {

            }
            else {
                this.AddCollection();
            }
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.id !== this.props.id) {
            this.EditTokenId = nextProps.id;
        }
        return true;
    }
    OpenEditor(id) {
        delete this.closeDataEditor;
        this.EditTokenId = id;
        this.EditTokenId && Events.BCE(Events.DESIGNER.BOARD.MOCKUPDATA.EDIT, {
            id : this.EditTokenId,
            onCancel : this.onCancel,
            onClose : this.onSave,
            onDelete : this.onDelete,
            onFieldsChanged : () => {
                this.Load(this.props);
                this.RCUpdate();
            },
            isNew : this.IsNew,
            ref : this.Ref_DataEditor,
            RegisterClose : (close) => {
                super.AddCloseCallback(close);
                this.closeDataEditor = close;
                // onAddCloseBack && onAddCloseBack(close);
                // registerClose && registerClose(close);                
            }                   
        }); 
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.id !== prevProps.id) {
            if (this.props.id) {
                this.OpenEditor(this.props.id);
            }
            else if (this.closeDataEditor) {
                this.closeDataEditor();
            }
        }
    }
    EditCollection(id) {                
        if (this.props.onEdit) {
            this.props.onEdit(id);
            return;
        }        
        if (this.props.EditMode) {
            super.RedirectTo(Links.Board_RealContentEdit(Globals.ProjectManager.Id, id));
            return;
        }
        
        this.EditTokenId = id;

        this.setState({ShouldUpdate : true}, () => {
            this.OpenEditor(id);
        });
           
    }
    onSortToken(oldIndex, newIndex) {
        Utils.ChangePlace(this.dataItems, oldIndex, newIndex);
        Globals.ProjectManager.Mockups.ChangeCollectionOrder(oldIndex, newIndex);        
        this.RCUpdate();
    }   
    AddCollection() {
        if (this.props.onEdit) {
            this.props.onEdit();
            return;
        }
        this.IsNew = true;
        this.EditTokenId = Globals.ProjectManager.Mockups.AddCollection({});

        this.EditCollection(this.EditTokenId);
    }
    onDelete() {
        if (Globals.ProjectManager.Mockups.DeleteCollection(this.EditTokenId)) {
            this.Load(this.props);
            this.onCancel();
        }
    }
    onCancel() {        
        if (this.IsNew) {
            if (Globals.ProjectManager.Mockups.DeleteCollection(this.EditTokenId)) {
                
            }
        }
        delete this.IsNew;
        delete this.EditTokenModel;
        delete this.EditTokenId;

        if (this.props.EditMode)
            super.RedirectTo(Links.Board_RealContent(Globals.ProjectManager.Id));
        else
            this.RCUpdate();

        if (this.closeDataEditor) {
            this.closeDataEditor();
            delete this.closeDataEditor;
            return true;
        }        
        return false;
    }
    onSave() {
        this.IsNew = false;
        this.Load(this.props);
                
        return this.onCancel();
    }   
    SelectItem(field) {
        this.props.onSelect && this.props.onSelect(field.FieldId);
    }
    renderCustom() { 
        let content;

        let ItemType = DraggableToolbarItem;
        if (this.props.forSelect) {
            ItemType = ToolbarItem;
        }

        if (this.EditTokenId) {
            return (
                <SC.FCol fw fh style={{position : 'relative', backgroundColor : SC.CurrentTheme.theme.back_lighter}}>                    
                    <MockupSchemaEditor 
                        id={this.EditTokenId}
                        isNew={this.IsNew}
                        onClose={this.onSave}
                        onCancel={this.onCancel}
                        onDelete={this.onDelete}
                        onFieldsChanged={() => {
                            if (this.Ref_DataEditor.current) {
                                this.Ref_DataEditor.current.Load();
                                this.Ref_DataEditor.current.RCUpdate();
                            }
                        }}                        
                    />
                </SC.FCol>
            )
        }
        else {                
            if (this.state.expanded) {
                content = (
                    <SC.FCol>
                        {this.dataItems.length === 0 && !this.isInitialized && <MockupInitializer onInitialized={
                            () => {
                                this.isInitialized = true; 
                                this.Load(); 
                                this.RCUpdate();
                            }} />}
                        {
                            this.dataItems.map((collectionItem, i) => {     
                                return (
                                    <SC.FCol key={collectionItem.id}>
                                        <SC.FRow alc jsb style={{padding : '8px', paddingLeft : '10px', paddingBottom : 0}}>
                                            <div> {Utils.UseNullOrEmpty(collectionItem.name, 'Collection')}</div>
                                            <TokenItemBox title='Click to edit collection' style={{paddingTop : '4px', paddingBottom : '4px', margin : 0}} onClick={this.EditCollection.bind(this, collectionItem.id)}>
                                                Edit
                                            </TokenItemBox>
                                        </SC.FRow>
                                        
                                        <UIElementGrid>
                                            {
                                                collectionItem.fields.map((field) => {
                                                    let IconType;
                                                    
                                                    return (
                                                        <ItemType 
                                                            key={field.FieldId}
                                                            title={field.Name}
                                                            IconType={field.IconType}
                                                            OnDragEnd={this.OnDragEnd}
                                                            DragItem={this.props.forSelect ? null : field.DragItem}
                                                            forSelect={this.props.forSelect ? {
                                                                disabled : this.props.forSelect && this.props.dataType && this.props.dataType !== field.dataType,
                                                                selected : this.props.dataId === field.FieldId
                                                            } : null}                                                        
                                                            onClick={this.props.forSelect ? this.SelectItem.bind(this, field) : null}
                                                        />
                                                    )
                                                })
                                            }
                                        </UIElementGrid>                                    
                                    </SC.FCol>
                                )
                            })
                        }
                    </SC.FCol>
                )
            }
        }
        

        if (this.props.justContent)
            return content;

        return (
            <SC.FCol fw fh style={{position : 'relative'}}>
                <TokenPanelHeader 
                    title='MOCKUP DATA' 
                    notBackClosable={this.props.EditMode}
                    onClose={this.props.onClose} 
                    style={{backgroundColor : SC.CurrentTheme.theme.back_lighter}}
                >
                    <SC.Icons.Icon_Button hasFill onClick={this.AddCollection} style={{cursor : 'pointer', paddingRight : '4px'}} 
                        title='Add New Collection' 
                    >
                        <SC.Icons.Icon_Plus size={20} />
                    </SC.Icons.Icon_Button>
                </TokenPanelHeader>
                <SC.FCol f1 style={{backgroundColor : SC.CurrentTheme.theme.back}}>
                    <SC.CustomScrollbars>
                        {content}
                    </SC.CustomScrollbars>            
                </SC.FCol>                
            </SC.FCol>
        )
           
    }
}

export const LoadMockupFields = ({forSelect}) => {
    const dataItems = [];

    let collectionIds = Globals.ProjectManager.Mockups.Collections();

    Utils.ForEach(collectionIds, (collectionId, ) => {
        const collection = Globals.ProjectManager.Mockups.Collection(collectionId);
        if (collection) {
            const collectionItem = {
                id : collectionId,
                name : collection.name,
                isDefaultText : collection.defaultText,
                fields : []
            };                
            collection && Utils.ForEach(collection.fields, (fieldId, ) => {
                const field = Globals.ProjectManager.Mockups.Field(fieldId);
                if (field) {
                    let IconType;
                    let DragItem;
                    if (field.dataType === MetaData.DataTypes.string) {
                        IconType = ToolbarIcons.Text;
                        DragItem = {
                            Type : Strings.TOOLBARITEM,
                            Item : {
                                MetaItem : {
                                    Type : MetaData.Components.Text.Type
                                }
                            },
                            MockupFieldId : fieldId
                        }
                    }
                    else if (field.dataType === MetaData.DataTypes.image) {
                        IconType = ToolbarIcons.Image;
                        DragItem = {
                            Type : Strings.TOOLBARITEM,
                            Item : {
                                MetaItem : {
                                    Type : MetaData.Components.Image.Type
                                }
                            },
                            MockupFieldId : fieldId
                        }
                    }
                    else if (field.dataType === MetaData.DataTypes.number) {
                        IconType = ToolbarIcons.Text;
                        DragItem = {
                            Type : Strings.TOOLBARITEM,
                            Item : {
                                MetaItem : {
                                    Type : MetaData.Components.Text.Type
                                }
                            },
                            MockupFieldId : fieldId
                        }
                    }
                    else if (field.dataType === MetaData.DataTypes.boolean && forSelect) {
                        IconType = ToolbarIcons.Checkbox;                            
                    }
                    if (IconType) {
                        collectionItem.fields.push({
                            FieldId : fieldId,
                            Name : field.name,
                            dataType : field.dataType,
                            IconType : IconType,
                            DragItem : DragItem
                        })
                    }                                            
                }
            });

            dataItems.push(collectionItem);
        }            
    });   

    return dataItems;
}