import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    AppLayout,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../../../../importer';
import styled, { css} from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';

import {DOCUMENT_ITEMTYPES, DOCUMENT_TOKENTYPES} from '../../../../manager/documents';
import DropDownSelect from '../../../../../../components/editors/enum_dropdown';
import { PropertyLabel } from '../../../../panels/right/iteminspector/styleitems/common';

import TokenExporter from './exportWidget';

import DocumentTokens_Colors from './colors';
import DocumentTokens_Gradients from './gradients';
import DocumentTokens_Shadows from './shadows';
import DocumentTokens_Typography from './typography';
import DocumentTokens_Motion from './motion';
import DocumentTokens_BorderRadii from './borderRadii';
import DocumentTokens_BorderStyles from './borderStyles';
import DocumentTokens_Transforms from './transforms';
import DocumentTokens_Filters from './filters';
import DocumentTokens_Spacing from './spacing';
import DocumentToken_Texts from './texts';
import DocumentToken_Image from './image';
import DocumentToken_Icons from './icons';
import DocumentToken_Sounds from './sounds';
import DocumentToken_FigmaAutolayouts from './figmaAutolayouts';
import DocumentToken_CustomTokens from './customTokens';
import SystemStatePanel from '../../../../panels/left/systemstates';
import { TokenTypes } from '../../../../manager/tokens';
import { GetSelectedTokenIds } from '../../../../panels/left/designsystem/groupmembers/selectOptions';

export default class DocumentItem_Tokens extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {

        };    

        this.SetSpecificState = this.SetSpecificState.bind(this);        

        this.Load(this.props.pageId, this.props.id);
    }
    componentDidMount() {
        Events.Listen(Events.GLOBAL.TOKENS_CHANGED, this);
        Events.Listen(Events.GLOBAL.TOKENS_ORDER_CHANGED, this);
    }
    componentWillUnmount() {
        Events.DropListen(Events.GLOBAL.TOKENS_CHANGED, this);
        Events.DropListen(Events.GLOBAL.TOKENS_ORDER_CHANGED, this);
    }
    OnEvent(Event, Params) {
        if (Event === Events.GLOBAL.TOKENS_ORDER_CHANGED) {
            const [tokenType] = Params;
            if (tokenType === this.tokenType) {
                this.Load(this.props.pageId, this.props.id);        
                this.Update();
            }
            return;
        }
        this.Load(this.props.pageId, this.props.id);
        this.Update();
    }

    shouldComponentUpdate(nextProps, nextState) {

        if (this.props.autoFocus !== nextProps.autoFocus)
            return true;
        if (this.state.ShouldUpdate !== nextState.ShouldUpdate)
            return true;
        
        if (this.props.RenderId !== nextProps.RenderId || this.props.GlobalThemeId !== nextProps.GlobalThemeId) {            
            this.Load(nextProps.pageId, nextProps.id);
            return true;
        }        
        if (Utils.HasAnyChange(this.props, nextProps, 'preview', 'GlobalThemeId', 'GlobalState', 'StyleId', 'themeId'))
            return true;
        
        return false;
    }
    Load(pageId, id) {
        this.LoadId = Utils.Id();
        this.data = Globals.ProjectManager.GetDocumentManager().GetPageItem(pageId, id);
        this.tokenType = Utils.JustGet(this.data.data, Globals.ProjectManager.Tokens.Types.COLOR, 'type');        
        this.useTokenType = this.tokenType;
        this.widgetType = Utils.JustGet(this.data.data, 'widget', 'widgetType');        
        this.subType = Utils.JustGet(this.data.data, '', 'subType');
        
        this.selectionType = Utils.JustGet(this.data.data, 'all', 'tokens', 'selection');

        if (this.tokenType === TokenTypes.Fonts) {
            if (this.subType === 'fonts')
                this.useTokenType = TokenTypes.Fonts;
            else if (this.subType === 'scale') {

            }
            else {
                this.useTokenType = TokenTypes.TextPatterns;
            }
                
        }
        else if (this.tokenType === TokenTypes.Spacings) {
            if (this.subType === 'scale') {

            }
            else {
                this.useTokenType = TokenTypes.SpacePatterns;
                if (this.selectionType === 'aliases' || this.selectionType === 'selectedaliases' || this.selectionType === 'aliaseGroups')
                    this.useTokenType = TokenTypes.Spacings;
            }
                
        }
        else if (this.tokenType === TokenTypes.Motion) {
            if (this.subType === 'durations') {
                this.useTokenType = TokenTypes.TimePatterns;
            }
            else if (this.subType === 'transitions') {
                this.useTokenType = TokenTypes.Transitions;
            }              
        }
        
        this.themeType = Utils.JustGet(this.data.data, 'active', 'theme');
        this.states = null;
        if (this.themeType === 'specific') {
            this.states = Utils.JustGet(this.data.data, {}, 'states');

            const result = Utils.States.BuildSystemStateLabelFromVariations({
                States : Globals.ProjectManager.States.Get(),
                StateOrder : Globals.ProjectManager.States.GetOrder(),
                StateVariation : this.states || {}
            })
            this.LocalGlobaslState = result.State;
            const result_array = Utils.States.GenerateStateArray({GlobalStateLabel : result.State});
            this.ReversedStyleState = Utils.Reverse(result_array.GlobalStateArray);
        }
        else if (this.themeType === 'custom') {
            this.filteredThemeStates = false;
            
            this.SpecificStateVariation = {

            };
            this.LocalGlobaslState = 'Default';
            this.ReversedStyleState = ['Default'];

            const onlySelectedStates = Utils.JustGet(this.data.data, false, 'filterStates');
            if (onlySelectedStates) {
                this.filteredThemeStates = Utils.JustGet(this.data.data, [], 'filteredStates');
            }
        }

        this.isAliase = false;

        const result = GetSelectedTokenIds({
            selectionType : this.selectionType,
            data : this.data.data,
            useTokenType : this.useTokenType
        });

        this.isAliase = result.isAliase;
        this.selectedTokens = result.selectedTokens;
    }
    SetSpecificState(stateId, variationId) {
        Utils.Set(this.SpecificStateVariation, variationId, stateId);
        const result = Utils.States.BuildSystemStateLabelFromVariations({
            States : Globals.ProjectManager.States.Get(),
            StateOrder : Globals.ProjectManager.States.GetOrder(),
            StateVariation : this.SpecificStateVariation
        })

        const result_array = Utils.States.GenerateStateArray({GlobalStateLabel : result.State});
        this.LocalGlobaslState = result.State;
        this.ReversedStyleState = Utils.Reverse(result_array.GlobalStateArray);

        this.setState({
            ShouldUpdate : true
        })
    }
    componentDidUpdate(prevProps, prevState) {
        this.state.ShouldUpdate = false;
    }
    
    Update() {
        this.setState({
            ShouldUpdate : true
        })
    }    
    render() {        

        let content;

        const tokenProps = {
            id : this.props.id,
            onItemSettings : this.props.onItemSettings,
            GlobalState : this.props.GlobalState,
            GlobalThemeId : this.props.GlobalThemeId,
            themeId : this.props.themeId,
            textStyles : this.props.textStyles,
            RenderId : this.props.RenderId,
            LoadId : this.LoadId,
            StyleId : this.props.StyleId,
            alignment : this.props.alignment,
            viewMode : this.props.viewMode,
            preview : this.props.preview,
            forTemplateSelect : this.props.forTemplateSelect,
            size : this.props.size,
            cardSize : this.props.cardSize,
            widgetType : this.widgetType,
            themeType : this.themeType,
            LocalGlobaslState : this.LocalGlobaslState,            
            ReversedStyleState : this.ReversedStyleState,
            states : this.states,
            isAliase : this.isAliase,
            selection : {
                type : 'selected', //(this.selectionType === 'selected' || this.selectionType === 'groups' || this.selectionType === 'selectedaliases' || this.selectionType === 'aliaseGroups') ? 'selected' : 'all',                
                tokenIds : this.selectedTokens
            }
        }

        if (this.widgetType === 'export') {
            content = (
                <TokenExporter 
                    {...tokenProps}
                    type={this.tokenType}
                />
            );
        }
        else {
            if (this.tokenType === Globals.ProjectManager.Tokens.Types.COLOR) {
                content = (
                    <DocumentTokens_Colors 
                        {...tokenProps}
                    />
                )
            }
            else if (this.tokenType === Globals.ProjectManager.Tokens.Types.Gradients) {
                content = (
                    <DocumentTokens_Gradients 
                        {...tokenProps}
                    />
                )            
            }
            else if (this.tokenType === Globals.ProjectManager.Tokens.Types.Shadows) {
                content = (
                    <DocumentTokens_Shadows 
                        {...tokenProps}
                    />
                )
            }
            else if (this.tokenType === Globals.ProjectManager.Tokens.Types.Borders) {
                content = (
                    <DocumentTokens_BorderStyles
                        {...tokenProps}
                    />
                )        
            }
            else if (this.tokenType === Globals.ProjectManager.Tokens.Types.BorderRadiuses) {
                content = (
                    <DocumentTokens_BorderRadii
                        {...tokenProps}
                    />
                )            
            }
            else if (this.tokenType === Globals.ProjectManager.Tokens.Types.Fonts) {
                content = (
                    <DocumentTokens_Typography
                        {...tokenProps}
                        subType={this.subType}
                    />
                )
            }
            else if (this.tokenType === Globals.ProjectManager.Tokens.Types.Motion) {            
                content = (
                    <DocumentTokens_Motion
                        {...tokenProps}
                        subType={this.subType}
                    />
                )
            }
            else if (this.tokenType === Globals.ProjectManager.Tokens.Types.Transforms) {            
                content = (
                    <DocumentTokens_Transforms
                        {...tokenProps}
                    />
                )
            }
            else if (this.tokenType === Globals.ProjectManager.Tokens.Types.Filters) {            
                content = (
                    <DocumentTokens_Filters
                        {...tokenProps}
                    />
                )
            }
            else if (this.tokenType === Globals.ProjectManager.Tokens.Types.Spacings) {            
                content = (
                    <DocumentTokens_Spacing
                        {...tokenProps}
                        subType={this.subType}
                    />
                )
            }
            else if (this.tokenType === Globals.ProjectManager.Tokens.Types.ContentTexts) {            
                content = (
                    <DocumentToken_Texts
                        {...tokenProps}
                    />
                )
            }
            else if (this.tokenType === Globals.ProjectManager.Tokens.Types.Images) {            
                content = (
                    <DocumentToken_Image
                        {...tokenProps}
                    />
                )
            }
            else if (this.tokenType === Globals.ProjectManager.Tokens.Types.Icons) {            
                content = (
                    <DocumentToken_Icons
                        {...tokenProps}
                    />
                )
            }
            else if (this.tokenType === Globals.ProjectManager.Tokens.Types.Sounds) {            
                content = (
                    <DocumentToken_Sounds
                        {...tokenProps}
                    />
                )
            }
            else if (this.tokenType === Globals.ProjectManager.Tokens.Types.FigmaAutoLayouts) {            
                content = (
                    <DocumentToken_FigmaAutolayouts
                        {...tokenProps}
                    />
                )
            }
            else if (this.tokenType === Globals.ProjectManager.Tokens.Types.CustomTokenTypes) {            
                content = (
                    <DocumentToken_CustomTokens
                        {...tokenProps}
                        tokenType={Utils.JustGet(this.data.data, '', 'tokenType')}
                    />
                )
            }
            else {
                content = (
                    <div>{this.tokenType}</div>
                )
            }
        }        

        // const alignment = Globals.ProjectManager.GetDocumentManager().GetPageItemValue(this.props.id, this.props.pageId, 'flex-start', 'alignment');

        let stateEditor;

        if (this.themeType === 'custom') {
            stateEditor = (
                <SC.FRow style={{...SC.Styles.Font()}}>
                    <SystemStatePanel 
                        contentStyle={{margin : 0, marginBottom : '16px', minWidth : '200px'}}
                        justContent
                        onSetStateVariation={this.SetSpecificState}
                        currentVariations={this.SpecificStateVariation}
                        filteredStates={this.filteredThemeStates}
                        noBreakpoints={this.filteredThemeStates && this.filteredThemeStates.indexOf('System_MediaSize') > -1}
                    /> 
                </SC.FRow>
            )
        }

        return (
            <motion.div
                style={{
                    display : 'flex',
                    flexDirection : 'column'
                }}
                layoutTransition={{
                    duration : 0.2
                }}
            >
                {stateEditor}
                {content}
            </motion.div>          
        )
    }
}