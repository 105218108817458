import React from 'react';
import ReactDOM from 'react-dom';

import {
    MetaData,
    Utils,
    UIUtils,
    SC,
    Strings,
    AppLayout
} from '../../../../../../importer';
import FlexBox from './flexbox';
import Grid from './grid';

export default class Container extends React.Component {
    constructor(props) {
        super(props);
        this.display = this.props.GetComponentManager().GetContainerType(this.props.Id);
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.ComponentStateId !== nextProps.ComponentStateId) {
            this.display = this.props.GetComponentManager().GetContainerType(nextProps.Id);
        }
        return true;
    }
    render() {
        if (this.display === 'grid') {
            return (
                <Grid {...this.props} />
            )
        }
        return (
            <FlexBox {...this.props} />
        );
    }    
}