import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    AppState,
    Events,
    SC,
    Loading,
    Globals,
    Strings,
    Links,
    Utils,
    UIUtils,
    MetaData
} from '../../../../../../../importer';

import { TokenPanelHeader, TokenItemBox } from '../../../../left/designsystem/common';

export default class SelectDataModel extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.titleNewToken = 'FAKE DATA';
        this.moduleName = 'DataModels';            

        this.Ref_PatternEdit = React.createRef();

        this.Load(this.props);
    }
    Load(props) {
        this.dataItems = [];

        const collectionIds = Globals.ProjectManager.Mockups.Collections();
        Utils.ForEach(collectionIds, (collectionId, ) => {
            const collection = Globals.ProjectManager.Mockups.Collection(collectionId);
            collection && Utils.ForEach(collection.fields, (fieldId, ) => {
                const field = Globals.ProjectManager.Mockups.Field(fieldId);
                if (field && field.dataType === this.props.dataType) {
                    this.dataItems.push({
                        CollectionId : collectionId,
                        CollectionName : collection.name,
                        FieldId : fieldId,
                        Name : field.name
                    })
                }
            });
        });      
    }
    SelectDataModel(FieldId) {
        this.props.onSelect(FieldId);
    }
    renderCustom() {
        const style_selected = {
            borderLeft : '1px solid', 
            borderLeftColor : SC.CurrentTheme.theme.color_brand
        }
        return (
            <SC.FCol f1>
                <TokenPanelHeader 
                    title='MOCKUP DATA' 
                    hasAddCancel 
                    onClose={this.props.onClose} 
                    style={{padding : '2px'}} 
                />
                <SC.FCol f1 style={{backgroundColor : SC.CurrentTheme.theme.back, padding : '8px'}}>
                    {
                        this.dataItems.map((dataItem) => {                            
                            return (
                                <TokenItemBox 
                                    key={dataItem.FieldId} 
                                    onClick={this.SelectDataModel.bind(this, dataItem.FieldId)}
                                    style={this.props.dataId === dataItem.FieldId ? style_selected : {}}
                                >
                                    {dataItem.CollectionName}.{dataItem.Name}
                                </TokenItemBox>
                            )
                        })
                    }
                </SC.FCol>
            </SC.FCol>
        )
    }
}