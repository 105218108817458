import React from 'react';
import ReactDOM from 'react-dom';

import {
    Utils,
    Globals
} from '../../../../../../importer';

import { onConstructItem, onItemShouldUpdate, onItemDidMount, onItemWillUnMount, onItemDidUpdate } from '../common';

export default class BaseGeneric extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  };

        onConstructItem(this, props);
        this.UpdateStyle(this.props);
    }
    UpdateStyle(props) {
        this.renderData = props.GetComponentManager().GetItemStyle(props.Id);
    }      
    componentDidMount() {
        onItemDidMount.call(this);
    }
    componentWillUnmount() {
        onItemWillUnMount.call(this);
    }
    componentDidUpdate(prevProps, prevState) {
        onItemDidUpdate.call(this, prevProps, prevState);
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (onItemShouldUpdate.call(this, nextProps, nextState)) {
            return true;
        }                
                    
        return false;
    }  
    GetMaxSize(scaled) {        
        let width, height;
        if (this.props.IsRootItem) {
            if (this.props.IsRootItem.Viewport) {      
                width = this.props.IsRootItem.Viewport.Width;
                height = this.props.IsRootItem.Viewport.Height;
                return {
                    width : width,
                    height : height
                }
            }
        }
        return this.props.GetParentSize(scaled);
    }  
    renderItem({otuerProps, outerStyle}) {
        const style = {
            ...Globals.ProjectManager.CurrentTheme.BodyStyle,
            ...this.renderData.style,
            position : 'relative',
            boxSizing : 'border-box',
            ...outerStyle
        };
        if (this.isSelected) {
            style.transition = 'all 0.2s ease';
        }

        if (this.isDragging) {
            style.opacity = 0.5;
        }

        const {isSubComponentChild, onMouseOverChild, ...props} = otuerProps;
        
        if (!this.props.Preview) {
            props.onMouseDown = this.onMouseDown;
            props.onMouseUp = this.onItemClick;
        }

        return this.renderContent({
            style : style, props : props
        })
        
    }
    renderContent({style, props}) {
        return (
            <div>
                Generic {this.props.GenericType}
            </div>
        )
    }
    render() {
        if (Utils.IsTrue(this.renderData.props.hidden))
            return null;
            
        let outerStyle = {};
        let otuerProps = {};
        
        if (this.props.IsRootItem && this.props.IsRootItem.SubComponent) {
            outerStyle =  this.props.IsRootItem.SubComponent.style;    
            if (!this.props.isSubComponentChild) {
                otuerProps.onMouseDown = this.props.Preview ? this.props.IsRootItem.SubComponent.onMouseDown : this.onMouseDown;
                otuerProps.onMouseUp = this.props.Preview ? this.props.IsRootItem.SubComponent.onMouseUp : this.onItemClick;
                otuerProps.onDoubleClick = this.props.IsRootItem.SubComponent.onDoubleClick;
                otuerProps.onMouseOver = this.props.Preview ? this.props.IsRootItem.SubComponent.onMouseOver : this.onMouseOver;
                otuerProps.onMouseOut = this.props.Preview ? this.props.IsRootItem.SubComponent.onMouseOut : this.onMouseOut;
            }            
            if (!this.props.Preview) {
                otuerProps.onMouseOverChild = this.onMouseOverChild;
            }
        }
        otuerProps.isSubComponentChild = this.props.isSubComponentChild;
        return this.renderItem({otuerProps : otuerProps, outerStyle : outerStyle});
        
    }
}