import React, { useState } from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../../importer';
import { AnimatePresence, motion } from 'framer-motion';

import TextInput from '../../../../components/editors/textinput';
import DropDownSelect from '../../../../components/editors/enum_dropdown';
import { Seperator, PropertyLabel, StyleGroupTokenItem } from '../../panels/right/iteminspector/styleitems/common';
import SystemStatePanel from '../../panels/left/systemstates';
import { TokenItemBox, TokenPanelHeader } from '../../panels/left/designsystem/common';
import Switch from '../../../../components/editors/Switch';
import MultipleTokenSelector from '../../panels/left/designsystem/groupmembers';
import MultipleTokenGroupSelector from '../../panels/left/designsystem/groupmembers/groups';
import SelectTokenOptions from '../../panels/left/designsystem/groupmembers/selectOptions';
import { NoteArea } from '../../../../components/editors/textvalue';
import { CopyToClipboardBox } from './tokengroup';

export default class ExportTemplateSchameViewer extends ReactBaseComponent
{
    constructor(props) {
        super(props);        
    }    
    renderLine(name, obj, result, indent) {
        let content;
        let nameItem = '';       
        let postcontent; 

        if (Array.isArray(obj)) {            
            content = [];
            if (Utils.IsNotNullOrEmpty(name)) {
                const copyText = `{{#each ${name}}}\n\n{{/each}}`;
                content.push(
                    <React.Fragment>
                        <span>{name} : [</span>
                        <CopyTemplateValue value={copyText} />
                    </React.Fragment>
                )
            }
            result.colCount++;
            obj.map((item) => {
                content.push(this.renderLine('', item, result, true));
            })            
            result.colCount--;
            if (Utils.IsNotNullOrEmpty(name)) {
                const copyText = `{{/each}}`;
                content.push(
                    <React.Fragment>                        
                        <div>]</div>
                        <CopyTemplateValue value={copyText} />
                    </React.Fragment>
                )
            }
        }
        else if (Utils.IsObject(obj)) {
            // nameItem = '{';
            
            content = [];
            if (Utils.IsNotNullOrEmpty(name)) {                
                content.push(
                    <div style={{alignSelf : 'flex-start'}}>{`${name} :`}</div>
                )
                content.push(<div style={{justifySelf : 'start'}}>{` {`}</div>)
                result.colCount++;
            }
            const items = [];
            Utils.ForEach(obj, (item, key) => {
                items.push(this.renderLine(key, item, result, true));
            });
            // content.push(<div />);
            content.push(
                <div style={{
                    display : 'grid',
                    alignItems : 'center',
                    gridTemplateColumns : 'auto auto',
                    gridGap : '8px',
                    gridRowGap : '4px',
                    justifyItems : 'end',
                    gridColumnStart : 'span 2',
                    // marginLeft : Utils.IsNotNullOrEmpty(name) ? '30px' : 0
                    paddingLeft : indent ? '60px' : 0,
                    overflowX: indent ? 'none' : 'auto'
                    // borderLeft : Utils.IsNotNullOrEmpty(name) ? SC.CurrentTheme.theme.border_seperator : 'none'
                }}>
                    {items}
                </div>
            )
            if (Utils.IsNotNullOrEmpty(name))
                result.colCount--;
            if (Utils.IsNotNullOrEmpty(name))
                postcontent = (<div>{'}'}</div>)
        }
        else {
            const copyText = `{{${name}}}`;
            nameItem = (
                <React.Fragment>
                    <SC.TextSpanAbbr style={{maxWidth : '18ch'}} title={obj}>{obj} : </SC.TextSpanAbbr>
                    <CopyTemplateValue value={copyText} />
                </React.Fragment>                
            )
        }
        
        if (Utils.IsNotNullOrEmpty(name)) {
            // if (Array.isArray(obj)) {
            //     // nameItem = (
            //     //     <SC.FRow alc>
            //     //         <span>{name} : [</span>
            //     //         <TokenItemBox style={{margin : 0, marginLeft : '8px'}}>{`{{`}#each {name}{`}}`}</TokenItemBox>
            //     //     </SC.FRow> 
            //     // )
            // }
            // else if (Utils.IsObject(obj))
            //     nameItem = `${name} : {`;
        }
        else {
            if (Array.isArray(obj))
                nameItem = '[';
            // else if (Utils.IsObject(obj)) 
            //     nameItem = '{';
        }
            
        return (
            <React.Fragment>
                {nameItem}
                 {content}
                 {postcontent}
            </React.Fragment>
        )
    }
    renderCustom() {            
        const {schema} = this.props;
        const result = {
            colCount : 0
        };
        let content = this.renderLine('', schema, result)
        return (
            <NoteArea dark style={{    
                fontSize : '12px'           
            }}>
                {content}
            </NoteArea>
        )
    }
}

const CopyTemplateValue = ({value}) => {
    const [showCopied, setShowCopied] = useState(false);
    return (
        <CopyToClipboardBox onShowInfo={(show) => setShowCopied(show)} text={value} style={{justifySelf : 'start'}} >
            <TokenItemBox style={{margin : 0, fontFamily : 'monospace',  color : SC.CurrentTheme.theme.color_brand_text, position : 'relative'}} title='Click to copy'>
                {showCopied && <SC.AbsoluteOverlay style={{...SC.Styles.Flex.Cell}}>Copied!</SC.AbsoluteOverlay>}
                <div style={{visibility : showCopied ? 'hidden' : 'visible'}}>{value}</div>
            </TokenItemBox>
        </CopyToClipboardBox>
    )
}