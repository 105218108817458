const Platforms = {
    React : {
        Name : 'React',
        Web : true
    },
    PReact : {
        Name : 'PReact',
        Web : true
    },
    Angular : {
        Name : 'Angular',
        Web : true
    },
    ReactNative : {
        Name : 'ReactNative',
        Mobile : true
    },
    VueJs : {
        Name : 'VueJS',
        Web : true
    },
    HtmlElement : {
        Name : 'HtmlElement',
        Web : true
    },

    IsReact(p) {
        return p === this.React.Name;
    },
    IsPReact(p) {
        return p === this.PReact.Name;
    },
    IsVue(p) {
        return p === this.VueJs.Name
    }
};

Platforms.UseCssName = (PlatformName) => {
    if (PlatformName === Platforms.React.Name || PlatformName === Platforms.Angular.Name)
        return true;
}

module.exports = Platforms;
