import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Events,
    AppState,
    MetaData,
    Utils,
    Strings,
    AppLayout
} from '../../../../../../../importer';

import { StyleGroupTitle, SmallButtonGroup, PropertyLabel, StyleGroupTokenItem, ToggleButton } from '../common';
import FlexLayout from './flex';
import Switch from '../../../../../../../components/editors/Switch';
import { AlignmentOption } from './grid/designer/contentAlignment';
import DropDownSelect from '../../../../../../../components/editors/enum_dropdown';

export default class Layout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }

        this.SetToGrid = this.SetToGrid.bind(this);
        this.SetFlexDirection = this.SetFlexDirection.bind(this);
        this.ToggleGridLines = this.ToggleGridLines.bind(this);
        
    }
    SetFlexDirection(direction) {
        const display = Utils.JustGet(this.props.display, 'flex', 'Value');
        const changes = [
            {
                PropertyName : 'flexDirection',
                Value : direction,
                PropParentNames : ['flexOptions']
            },
            {
                PropertyName : 'display',
                Value : 'flex',
                PropParentNames : []
            }
        ];
        if (display === 'grid') {
            changes.push({
                PropertyName : 'Grid',
                Delete : true,
                RemoveFullProp : true                
            });
            changes.push({
                PropertyName : 'Grid',
                Delete : true,
                NotStatefull : true,
                RemoveFullProp : true
            });

            setTimeout(() => {
                AppLayout.Refs.SelectionDesigner && AppLayout.Refs.SelectionDesigner.ReloadItemDesigner(this.props.Id);
            }, 200);
        }
        

        this.props.onSetPropertyValues(changes, true);

        const MetaItem = this.props.GetComponentManager().GetMetaItem(this.props.Id);

        Utils.ForEach(MetaItem.SubItems, (SubItem, i) => {
            const ChildItem = this.props.GetComponentManager().GetMetaItem(SubItem.Id);
            this.props.GetComponentManager().RemoveItemProperty({
                MetaItem : ChildItem,
                Id : SubItem.Id,
                PropertyName : 'GridChild',
                RemoveFullProp : true,
                DoNotLog : true,
                Silent : true
            });            
        });
        
        this.props.GetComponentManager().ResetItemAndChildStyle(this.props.Id);
        this.props.GetComponentManager().UpdateRenderers();
    }
    SetToGrid() {
        const MetaItem = this.props.GetComponentManager().GetMetaItem(this.props.Id);
        let COLUMN_COUNT = 2, ROW_COUNT = 2;
        const flexDirection = Utils.JustGet(this.props.flexOptions, 'row', 'flexDirection', 'Value');

        if (MetaItem.SubItems && MetaItem.SubItems.length > 0) {
            if (Utils.IsFlexRow(flexDirection)) {
                COLUMN_COUNT = MetaItem.SubItems.length;
                ROW_COUNT = 1;
            }
            else {
                ROW_COUNT = MetaItem.SubItems.length;
                COLUMN_COUNT = 1;
            }
        }

        const changes = [
            {
                PropertyName : 'display',
                Value : 'grid',
                PropParentNames : [],
                Silent : true
            },
            {
                PropertyName : 'model',
                Value : MetaData.ItemInitializer.GetGridLayout({colCount : COLUMN_COUNT, rowCount : ROW_COUNT}).GridModel,
                ParentNames : ['Grid'],
                Silent : true
            }, 
            {
                PropertyName : 'columnGap',
                Value : 16,
                ParentNames : ['Grid'],
                Silent : true                
            }, 
            {
                PropertyName : 'rowGap',
                Value : 16,
                ParentNames : ['Grid'],
                Silent : true                
            },
            {
                PropertyName : 'initialized',
                Value : false,
                NotStatefull : true,
                PropParentNames : ['gridOptions'],
                ParentNames : ['Grid'],
            }
        ];
        FlexStyleNames.map((flexStyle) => {
            changes.push({
                PropertyName : flexStyle,
                Delete : true,
                PropParentNames : ['flexOptions'],
                Silent : true
            })
        })

                

        Utils.ForEach(MetaItem.SubItems, (SubItem, i) => {
            const ChildItem = this.props.GetComponentManager().GetMetaItem(SubItem.Id);
            let colStart = (i % (COLUMN_COUNT)) + 1;
            let rowStart =  Math.floor(i / COLUMN_COUNT) + 1;
            this.props.GetComponentManager().SetItemPropertyValue({
                MetaItem : ChildItem,
                Id : SubItem.Id,
                PropertyName : 'gridColumnStart',
                Value : colStart,
                DoNotLog : true,
                ParentNames : ['GridChild'],
                Silent : true
            });
            this.props.GetComponentManager().SetItemPropertyValue({
                MetaItem : ChildItem,
                Id : SubItem.Id,
                PropertyName : 'gridColumnEnd',
                Value : colStart + 1,
                DoNotLog : true,
                ParentNames : ['GridChild'],
                Silent : true
            });
            this.props.GetComponentManager().SetItemPropertyValue({
                MetaItem : ChildItem,
                Id : SubItem.Id,
                PropertyName : 'gridRowStart',
                Value : rowStart,
                DoNotLog : true,
                ParentNames : ['GridChild'],
                Silent : true
            });
            this.props.GetComponentManager().SetItemPropertyValue({
                MetaItem : ChildItem,
                Id : SubItem.Id,
                PropertyName : 'gridRowEnd',
                Value : rowStart + 1,
                DoNotLog : true,
                ParentNames : ['GridChild'],
                Silent : true
            });
        });

        this.props.onSetPropertyValues(changes, true);
        this.props.GetComponentManager().ResetItemAndChildStyle(this.props.Id);
        this.props.GetComponentManager().UpdateRenderers();
        
        setTimeout(() => {
            AppLayout.Refs.SelectionDesigner && AppLayout.Refs.SelectionDesigner.ReloadItemDesigner(this.props.Id);
        }, 200);
    }
    ToggleGridLines(value) {
        this.props.onSetPropertyValues([
            {
                PropertyName : 'showGridLines',
                Value : value,
                PropParentNames : ['gridOptions'],
                NotStatefull : true,
                ParentNames : ['Grid']
            }
        ]);
        
    }
    SetOverflow(direction, value) {
        this.props.onSetPropertyValues([
            {
                PropertyName : direction,
                Value : value
            }
        ]);
        this.setState({ShouldUpdate : true});
    }
    renderOverflowItem(vertical, item) {
        return (
            <SC.FRow alc jsb style={{fontSize : '11px'}}>
                <SC.Icons.Icon_ScrollArrow size={20} style={vertical ? {} : {transform : 'rotate(90deg)'}} />
                {item ? item.label : 'Default'}
            </SC.FRow>
        )
    }
    componentDidUpdate(prevProps, prevState) {
        this.state.ShouldUpdate = false;
    }
    render() { 
        const display = Utils.JustGet(this.props.display, 'flex', 'Value');
        const value_overflow_x = Utils.JustGet(this.props.overflowX, null, 'Value');
        const value_overflow_y = Utils.JustGet(this.props.overflowY, null, 'Value');

        let content;
        
        let flexDirection;
        if (display === 'flex') {
            flexDirection = Utils.JustGet(this.props.flexOptions, 'row', 'flexDirection', 'Value');
            content = (
                <FlexLayout 
                    {...this.props} 
                    flexDirection ={flexDirection}
                />
            )
        }   
        else {
            const ShowGridLines = Utils.JustGet(this.props.gridOptions, true, 'showGridLines', 'Value');
            content = (
                <SC.FCol>
                    <StyleGroupTokenItem style={{marginTop : '12px', marginBottom : '12px', padding : '8px', textAlign : 'center'}} onClick={this.props.onDesignGrid}>
                        <StyleGroupTitle>OPEN GRID DESIGNER</StyleGroupTitle>
                    </StyleGroupTokenItem>
                    <SC.FRow alc jsb>
                        <div style={{marginRight : '8px', fontSize : '11px'}}>Show Grid Lines on Canvas</div>
                        <Switch small value={ShowGridLines} onChange={this.ToggleGridLines} />
                    </SC.FRow>
                </SC.FCol>                
            )
        }

        return (
            <SC.FCol>
                <SC.FRow jsb alc>
                    <StyleGroupTitle>LAYOUT</StyleGroupTitle>    
                    <SC.FRow style={{width : '185px'}}>
                        <FlexDirectionSelector
                            flexDirection={flexDirection}
                            onSelect={this.SetFlexDirection}
                        />                                             
                        {
                            !this.props.justFlex &&
                            <AlignmentOption 
                                alignContent={'stretch'}
                                justifyContent={'stretch'}
                                all
                                selected={display === 'grid'}
                                onClick={display === 'flex' ? this.SetToGrid : null}
                                style={{marginLeft : '30px', width : '30px', height : '28px'}}
                                style_grid={{width : '18px', height : '18px'}}
                                title='Grid Layout'
                            />
                        }                        
                    </SC.FRow>
                </SC.FRow>
                {content}
                <SC.FRow alc jsb style={{marginTop : '8px'}}>
                    <PropertyLabel style={{flex : 1}}>Scroll</PropertyLabel>
                    <div style={{width : '185px', display : 'grid', gridTemplateColumns : '1fr 1fr', gridGap : '4px'}}>
                        <OverflowDrowdown 
                            value={value_overflow_x}
                            onChange={this.SetOverflow.bind(this, 'overflowX')}
                            renderLabel={this.renderOverflowItem.bind(this, false)}
                        />
                        <OverflowDrowdown 
                            value={value_overflow_y}
                            onChange={this.SetOverflow.bind(this, 'overflowY')}
                            renderLabel={this.renderOverflowItem.bind(this, true)}
                        />                        
                    </div>
                </SC.FRow>             
            </SC.FCol>            
            
        )
    }
}

const OverflowDrowdown = (props) => {
    return (
        <DropDownSelect
            hasBorder                            
            autoHeight
            xsmall
            boxStyle={{paddingLeft : 0}}
            items={[
                {id : Strings.NULL, label : Strings.DEFAULT},
                {id : MetaData.Styles.overflow.values.auto.Id, label : MetaData.Styles.overflow.values.auto.Label},
                {id : MetaData.Styles.overflow.values.scroll.Id, label : MetaData.Styles.overflow.values.scroll.Label},
                {id : MetaData.Styles.overflow.values.hidden.Id, label : MetaData.Styles.overflow.values.hidden.Label},                            
                {id : MetaData.Styles.overflow.values.visible.Id, label : MetaData.Styles.overflow.values.visible.Label},
            ]}
            value={props.value}
            onChange={props.onChange}
            noBackColor
            renderLabel={props.renderLabel}
        />
    )
}
 
export const FlexStyleNames = ['flexDirection', 'justifyContent', 'alignItems', 'flexWrap'];

export const GridStyleNames = ['justifyContent', 'alignContent', 'alignItems', 'justifyItems', 'columnGap', 'rowGap'];
export const GridChildStyleNames = ['gridColumnStart', 'gridColumnEnd', 'gridRowStart', 'gridRowEnd'];
export const NotStatefullGridStyleNames = ['initialized', 'showGridLines'];

export class FlexDirectionSelector extends React.Component {

    constructor(props) {
        super(props);
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.flexDirection !== nextProps.flexDirection)
            return true;
        if (this.props.themeId !== nextProps.themeId)
            return true;
        return false;
    }
    SetFlexDirection(direction) {
        this.props.onSelect(direction)
    }
    render() {
        const {flexDirection} = this.props;
        return (
            ['row', 'column', 'row-reverse', 'column-reverse'].map((direction, i) => {
                const selected = flexDirection === direction;
                return (
                    <SmallButtonGroup 
                        key={i}
                        hasBorders size={20} 
                        first={i===0}
                        last={i===3}
                        onClick={selected ? null : this.SetFlexDirection.bind(this, direction)} 
                        selected={selected} 
                        title={`Flex ${MetaData.Properties.FlexLabels[direction]}`}
                        style_svg={{transform : `rotate(${i*90}deg)`}}
                        style={{width : '30px', height : '28px', marginRight : '1px'}}
                    >
                        <FlexDirectionPaths selected={selected} />
                    </SmallButtonGroup>
                )                                        
            })
        )
    }
}

export const FlexDirectionPaths = ({selected}) => {
    return (
        <React.Fragment>
            <path fill={ selected ? SC.CurrentTheme.theme.color_brand : null} d="M19.5,11 C19.7761424,11 20,11.2238576 20,11.5 C20,11.7761424 19.7761424,12 19.5,12 L7.5,12 C7.22385763,12 7,11.7761424 7,11.5 C7,11.2238576 7.22385763,11 7.5,11 L19.5,11 Z"/>
            <path fill={ selected ? SC.CurrentTheme.theme.color_brand : null} d="M15.1464466 7.85355339C14.9511845 7.65829124 14.9511845 7.34170876 15.1464466 7.14644661 15.3417088 6.95118446 15.6582912 6.95118446 15.8535534 7.14644661L19.8535534 11.1464466C20.0488155 11.3417088 20.0488155 11.6582912 19.8535534 11.8535534L15.8535534 15.8535534C15.6582912 16.0488155 15.3417088 16.0488155 15.1464466 15.8535534 14.9511845 15.6582912 14.9511845 15.3417088 15.1464466 15.1464466L18.7928932 11.5 15.1464466 7.85355339zM4 3.5C4 3.22385763 4.22385763 3 4.5 3 4.77614237 3 5 3.22385763 5 3.5L5 19.5C5 19.7761424 4.77614237 20 4.5 20 4.22385763 20 4 19.7761424 4 19.5L4 3.5z"/>
        </React.Fragment>
    )
}