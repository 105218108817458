import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    AppState,
    Events,
    UIUtils,
    Utils,
    Strings
} from '../../../importer';

import ReactResizeDetector from 'react-resize-detector';
import ChromaJs from 'chroma-js';

export default class ColorPickerTester extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {

        }

        this.onCanvasResized = this.onCanvasResized.bind(this);

        this.SetColor = this.SetColor.bind(this);        
        
        this.onChangingSaturation = this.onChangingSaturation.bind(this);
        this.onChangeSaturation = this.onChangeSaturation.bind(this);
        this.onChangingHue = this.onChangingHue.bind(this);
        this.onChangeHue = this.onChangeHue.bind(this);
        this.onChangeAlpha = this.onChangeAlpha.bind(this);
        this.onChangingAlpha = this.onChangingAlpha.bind(this);
        this.onChangeHex = this.onChangeHex.bind(this);

        this.state = {
            ...this.SetColor(Utils.UseNull(this.props.color, '#FFFFFF'))
        };        
    }    
    onCanvasResized(width) {
        this.setState({
            width : width
        });            
    }
    componentDidMount() {
        const DN = ReactDOM.findDOMNode(this);
        const bounds = DN.getBoundingClientRect();
        this.setState({
            width : bounds.width
        });

    }
    SetColor(color) {
        if (ChromaJs.valid(color)) {
            const chromaColor = ChromaJs(color);
            const hsv = chromaColor.hsv();
            this.propColor = Utils.ToRGBA(FormatRGB(chromaColor.rgba()).rgb);
            return {
                hex : chromaColor.hex(),
                hsv : [GetHue(hsv), hsv[1], hsv[2]],
                alpha : chromaColor.alpha() 
            };
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.color !== this.propColor) {
            if (ChromaJs.valid(nextProps.color)) {
                const rgbacolor = Utils.ToRGBA(FormatRGB(ChromaJs(nextProps.color).rgba()).rgb);
                if (rgbacolor !== this.propColor) {
                    const color = this.SetColor(nextProps.color);
                    if (color) {
                        this.state.hex = color.hex;
                        this.state.hsv = color.hsv;
                        this.state.alpha = color.alpha;
                    }            
                    return true;
                }
            }                        
        }
        return true;
    }
    onChangingSaturation(saturation, value) {
        // console.log(`${this.state.hsv}`);
        const hue = GetHue(this.state.hsv);
        const color = ChromaJs(hue, saturation, value, 'hsv').alpha(this.state.alpha);
        this.propColor = Utils.ToRGBA(FormatRGB(color.rgba()).rgb);
        this.props.onChanging && this.props.onChanging(this.propColor);        
        const hsv = color.hsv();
        hsv[0] = hue;
        // console.log(`hue : ${hsv[0]}`);
        this.setState({
            hsv : hsv,
            hex : color.hex()    
        })
    }
    onChangeSaturation(saturation, value) {
        this.onChangingSaturation(saturation, value);
        this.propColor = Utils.ToRGBA(FormatRGB(ChromaJs(this.state.hex).rgba()).rgb);
        this.props.onChange && this.props.onChange(this.propColor);
        this.props.onComplete && this.props.onComplete();
    }
    onChangingHue(hue) {
        // console.log(`${this.state.hsv}`);        
        const color = ChromaJs(hue, this.state.hsv[1], this.state.hsv[2], 'hsv').alpha(this.state.alpha);
        const hsv = color.hsv();
        hsv[0] = hue;
        this.propColor = FormatRGB(color.rgba());
        this.propColor = Utils.ToRGBA(FormatRGB(color.rgba()).rgb);
        this.props.onChanging && this.props.onChanging(this.propColor);
        this.setState({
            hex : color.hex(),
            hsv : hsv
        })                
    }
    onChangeHue(hue) {
        this.onChangingHue(hue);
        this.propColor = Utils.ToRGBA(FormatRGB(ChromaJs(this.state.hex).rgba()).rgb);
        this.props.onChange && this.props.onChange(this.propColor);
        this.props.onComplete && this.props.onComplete();
    }
    onChangeHex(hex) {
        const color = ChromaJs(hex);
        this.setState({
            hex : hex,
            hsv : color.hsv(),
            alpha : color.alpha()
        }) 
    }
    onChangingAlpha(alpha) {        
        const color = ChromaJs(...this.state.hsv, 'hsv').alpha(alpha);
        this.setState({
            hex : color.hex(),
            alpha : alpha
        })        
        this.propColor = FormatRGB(color.rgba());
        this.propColor = Utils.ToRGBA(FormatRGB(color.rgba()).rgb);
        this.props.onChanging && this.props.onChanging(this.propColor);
    }
    onChangeAlpha(alpha) {
        this.onChangingAlpha(alpha);
        this.propColor = Utils.ToRGBA(FormatRGB(ChromaJs(this.state.hex).rgba()).rgb);
        this.props.onChange && this.props.onChange(this.propColor);
        this.props.onComplete && this.props.onComplete();
    }
    render() {        
        let content;
        if (this.state.width) {
            content = (
                <React.Fragment>
                    <SaturationGraph     
                        width={this.state.width}
                        height={Math.min(this.state.width, 240)}
                        hsv={this.state.hsv}
                        color={this.state.hex}
                        onChanging={this.onChangingSaturation}
                        onChanged={this.onChangeSaturation}
                        style={{marginBottom : '4px'}}
                    />
                    <HueGraph     
                        width={this.state.width}
                        height={24}
                        hsv={this.state.hsv}
                        onChanging={this.onChangingHue}
                        onChanged={this.onChangeHue}
                        style={{marginBottom : '4px'}}
                    />
                    <AlphaGraph     
                        width={this.state.width}
                        height={24}
                        color={this.state.hex}
                        onChanging={this.onChangingAlpha}
                        onChanged={this.onChangeAlpha}
                        style={{marginBottom : '4px'}}
                    />
                </React.Fragment>
            )
        }
        return (
            <SC.FCol style={{alignSelf : 'stretch', flex : 1, marginBottom : '8px', pointerEvents : this.props.readOnly ? 'none' : 'all'}}>
                {content}
                <ReactResizeDetector handleWidth handleHeight onResize={this.onCanvasResized} />
            </SC.FCol>
        )
       
    }
}

class SaturationGraph extends React.Component {
    constructor(props) {
        super(props);

        this.Ref_Handle = React.createRef();

        // this.CalculateColor = throttle(this.CalculateColor, 50);
        this.CalculateColor = this.CalculateColor.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);

        const hsv = this.props.hsv;
        this.backgroundColor = ChromaJs(GetHue(hsv), 1, 1, 'hsv');
        this.saturation = hsv[1];
        this.bright = hsv[2];
    }
    componentDidMount() {
        
    }
    componentWillUnmount() {
        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.onMouseUp);
    }
    onMouseDown(e) {
        AppState.Designer.PropbarPickerActive = true;
        document.addEventListener('mousemove', this.onMouseMove);
        document.addEventListener('mouseup', this.onMouseUp);

        const DN = ReactDOM.findDOMNode(this);
        this.DraggingData = {
            bounds : DN.getBoundingClientRect(),
            hue : GetHue(this.props.hsv),
            DNHandle : ReactDOM.findDOMNode(this.Ref_Handle.current)
        };
        if (Number.isNaN(this.DraggingData.hue))
            this.DraggingData.hue = 0;
        this.CalculateColor(e.clientX, e.clientY);
    }
    onMouseUp(e) {
        setTimeout(() => {
            AppState.Designer.PropbarPickerActive = false;
        }, 100);        
        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.onMouseUp);

        this.CalculateColor(e.clientX, e.clientY, true);
        delete this.DraggingData;
    }
    onMouseMove(e) {
        this.CalculateColor(e.clientX, e.clientY);
    }
    CalculateColor(x, y, willEnd) {
        const {width, height} = this.DraggingData.bounds;
        let left = Math.min(width, Math.max(0, x - this.DraggingData.bounds.left));
        let top = Math.min(height, Math.max(0, y - this.DraggingData.bounds.top));

        this.saturation = left / width;
        this.bright = 1 - (top / height)

        this.DraggingData.color = ChromaJs(this.DraggingData.hue, this.saturation, this.bright, 'hsv').hex();
        if (willEnd)
            this.props.onChanged(this.saturation, this.bright);
        else
            this.props.onChanging(this.saturation, this.bright);

        // console.log(`${this.saturation}, ${this.bright}`);
        if (this.DraggingData.DNHandle) {
            this.DraggingData.DNHandle.style.left = unit(this.saturation * 100, '%');
            this.DraggingData.DNHandle.style.bottom = unit(this.bright * 100, '%');
            this.DraggingData.DNHandle.style.backgroundColor = this.DraggingData.color;
        }        
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.DraggingData) {
            return false;
        }
        else {
            if (this.props.hsv[0] !== nextProps.hsv[0] || this.props.hsv[1] !== nextProps.hsv[1] || this.props.hsv[2] !== nextProps.hsv[2]) {
                const hsv = nextProps.hsv;
                this.backgroundColor = ChromaJs(GetHue(hsv), 1, 1, 'hsv');
                this.saturation = hsv[1];
                this.bright = hsv[2];
            }
        }
        return true;
    }
    render() {
        const {style, width, height, color} = this.props;                
        return (
            <div 
                style={{
                    ...style,
                    position : 'relative',
                    width : unit(width),
                    height : unit(height),
                    backgroundColor : this.backgroundColor
                }}
                onMouseDown={this.onMouseDown}
                
            >
                <div style={{
                    ...Styles.absolute,
                    background : 'linear-gradient(to right, #fff, rgba(204, 154, 129, 0))'
                }} />
                <div style={{
                    ...Styles.absolute,
                    background : 'linear-gradient(to top, #000, rgba(204, 154, 129, 0))'
                }} />
                <div 
                    style={{
                        width : '10px',
                        height : '10px',
                        borderRadius : '50%',
                        border : '1px solid #ffff',
                        outline : '1px solid #0000',
                        position : 'absolute',
                        boxSizing : 'border-box',
                        left : unit(this.saturation * 100, '%'),
                        bottom : unit(this.bright * 100, '%'),
                        transform : 'translate(-50%, 50%)',
                        backgroundColor : this.DraggingData ? this.DraggingData.color : color,
                        boxShadow : '0 0 2px 0 rgba(0,0,0,0.6)',
                        pointerEvents : 'all'

                    }} 
                    ref={this.Ref_Handle}
                    onMouseDown={this.onMouseDown}
                />
            </div>
        )
    }
}

class HueGraph extends React.Component {
    constructor(props) {
        super(props);

        this.Ref_Handle = React.createRef();

        // this.CalculateColor = throttle(this.CalculateColor, 50);
        this.CalculateColor = this.CalculateColor.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);        
        this.state = {};
        this.hue = GetHue(this.props.hsv);
        this.ratio = this.hue / 360;
        this.backgroundColor = ChromaJs(this.hue, 1, 1, 'hsv');
    }
    componentDidMount() {
        
    }
    componentWillUnmount() {
        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.onMouseUp);
    }
    onMouseDown(e) {
        AppState.Designer.PropbarPickerActive = true;
        document.addEventListener('mousemove', this.onMouseMove);
        document.addEventListener('mouseup', this.onMouseUp);

        const DN = ReactDOM.findDOMNode(this);

        this.DraggingData = {
            bounds : DN.getBoundingClientRect(),
            DNHandle : ReactDOM.findDOMNode(this.Ref_Handle.current),
            saturation : this.props.hsv[1],
            bright : this.props.hsv[2]
        };
        this.CalculateColor(e.clientX, e.clientY);
    }
    onMouseUp(e) {
        setTimeout(() => {
            AppState.Designer.PropbarPickerActive = false;    
        }, 100);
        
        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.onMouseUp);
        this.CalculateColor(e.clientX, e.clientY, true);
        delete this.DraggingData;
        this.setState({ShouldUpdate : true});
    }
    onMouseMove(e) {
        this.CalculateColor(e.clientX, e.clientY);
    }
    CalculateColor(x, y, willEnd) {
        const {width} = this.DraggingData.bounds;
        this.ratio = (Math.min(width, Math.max(0, x - this.DraggingData.bounds.left))) / this.props.width;

        this.hue = Math.min(360, Math.max(0, this.ratio * 360));        
        this.DraggingData.color = ChromaJs(this.hue, this.DraggingData.saturation, this.DraggingData.bright, 'hsv').hex();
        this.backgroundColor = ChromaJs(this.hue, 1, 1, 'hsv').hex();
        
        if (willEnd)
            this.props.onChanged(this.hue);
        else
            this.props.onChanging(this.hue);
        
        // this.DraggingData.DNHandle.style.left = unit(this.ratio * 100, '%');
        // this.DraggingData.DNHandle.style.backgroundColor = this.backgroundColor;
        this.setState({ShouldUpdate : true})
    }
    shouldComponentUpdate(nextProps, nextState) {
        
        if (nextProps.width !== this.props.width)
            return true;

        if (this.DraggingData) {
            if (this.state.ShouldUpdate !== nextProps.ShouldUpdate)
                return true;
            return false;
        }
        else {
            if (this.props.hsv[0] !== nextProps.hsv[0]) {
                this.hue = nextProps.hsv[0];
                if (Number.isNaN(this.hue))
                    this.hue = 0;
                this.ratio = this.hue / 360;
                this.backgroundColor = ChromaJs(this.hue, 1, 1, 'hsv').hex();
            }
            else
                return false;
        }
        return true;
    }
    render() {
        this.state.ShouldUpdate = false;
        const {width, height, style} = this.props;        
        return (
            <div 
                style={{
                    ...style,
                    position : 'relative',
                    width : unit(width),
                    height : unit(height),
                    background : 'linear-gradient(to right, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%)'
                }}
                onMouseDown={this.onMouseDown}
                
            >               
                <div 
                    style={{
                        width : '6px',
                        top : 0,
                        bottom : 0,
                        borderRadius : '2px',
                        border : '1px solid #ffff',
                        outline : '1px solid #0000',
                        position : 'absolute',
                        boxSizing : 'border-box',
                        left : unit(this.ratio * 100, '%'),
                        transform : 'translateX(-50%)',
                        backgroundColor : this.backgroundColor,
                        boxShadow : '0 0 2px 0 rgba(0,0,0,0.6)',
                        pointerEvents : 'none'

                    }} 
                    ref={this.Ref_Handle}
                />
            </div>
        )
    }
}

class AlphaGraph extends React.Component {
    constructor(props) {
        super(props);

        this.Ref_Handle = React.createRef();

        // this.CalculateColor = throttle(this.CalculateColor, 50);
        this.CalculateColor = this.CalculateColor.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
    }
    componentDidMount() {
        
    }
    componentWillUnmount() {
        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.onMouseUp);
    }
    onMouseDown(e) {
        AppState.Designer.PropbarPickerActive = true;
        document.addEventListener('mousemove', this.onMouseMove);
        document.addEventListener('mouseup', this.onMouseUp);

        const DN = ReactDOM.findDOMNode(this);

        this.DraggingData = {
            bounds : DN.getBoundingClientRect(),
            DNHandle : ReactDOM.findDOMNode(this.Ref_Handle.current),            
        };
        this.CalculateColor(e.clientX, e.clientY);
    }
    onMouseUp(e) {
        setTimeout(() => {
            AppState.Designer.PropbarPickerActive = false;    
        }, 100);
        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.onMouseUp);
        this.CalculateColor(e.clientX, e.clientY, true);
        delete this.DraggingData;
    }
    onMouseMove(e) {
        this.CalculateColor(e.clientX, e.clientY);
    }
    CalculateColor(x, y, willEnd) {
        const {width} = this.DraggingData.bounds;
        this.alpha = Utils.ToInteger(((Math.min(width, Math.max(0, x - this.DraggingData.bounds.left))) / this.props.width) * 100) / 100;

        this.DraggingData.color = ChromaJs(this.hue, this.DraggingData.saturation, this.DraggingData.bright, 'hsv').hex();
        this.backgroundColor = ChromaJs(this.hue, 1, 1, 'hsv').hex();
        
        if (willEnd)
            this.props.onChanged(this.alpha);
        else
            this.props.onChanging(this.alpha);
        
        this.DraggingData.DNHandle.style.left = unit(this.alpha * 100, '%');
        this.DraggingData.DNHandle.style.backgroundColor = ChromaJs(this.props.color).alpha(this.alpha);
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.width !== this.props.width)
            return true;
            
        if (this.DraggingData) {
            return false;
        }
        else {
            if (this.props.color !== nextProps.color) {
                return true;
            }
            else
                return false;
        }
    }
    render() {
        const {width, height, style} = this.props;        
        const rgb = ChromaJs(this.props.color).rgba();
        return (
            <div 
                style={{
                    ...style,
                    position : 'relative',
                    width : unit(width),
                    height : unit(height),
                    backgroundColor : 'white'
                }}
                onMouseDown={this.onMouseDown}
                
            >               
                <div 
                    style={{
                        ...Styles.absolute, 
                        background : 'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMUlEQVQ4T2NkYGAQYcAP3uCTZhw1gGGYhAGBZIA/nYDCgBDAm9BGDWAAJyRCgLaBCAAgXwixzAS0pgAAAABJRU5ErkJggg==") left center',                        
                    }} 
                />
                <div 
                    style={{
                        ...Styles.absolute, 
                        background : alphacolor(rgb)
                    }} 
                />                
                <div 
                    style={{
                        width : '6px',
                        top : 0,
                        bottom : 0,
                        borderRadius : '2px',
                        border : '1px solid #ffff',
                        outline : '1px solid #0000',
                        position : 'absolute',
                        boxSizing : 'border-box',
                        left : unit(rgb[3] * 100, '%'),
                        transform : 'translateX(-50%)',
                        backgroundColor : this.props.color,
                        boxShadow : '0 0 2px 0 rgba(0,0,0,0.6)',
                        pointerEvents : 'none'

                    }} 
                    ref={this.Ref_Handle}
                />
            </div>
        )
    }
}

export class Input_Hex extends React.Component {
    constructor(props) {
        super(props);

        this.Ref_Input_Hex = React.createRef();

        this.onChangeHex = this.onChangeHex.bind(this);
        this.onChangingHex = this.onChangingHex.bind(this);
        this.onChangingAlpha = this.onChangingAlpha.bind(this);
        this.onChangeAlpha = this.onChangeAlpha.bind(this);
        
        if (ChromaJs.valid(this.props.color)) {
            this.state = {
                hex : ChromaJs(this.props.color).hex('rgb'),
                alpha : ChromaJs(this.props.color).alpha() * 100
            };
        }
        else {
            this.state = {
                hex : '#fffff',
                alpha : 100
            }
        }
        
    }   
    FocusHex(focus) {
        if (this.Ref_Input_Hex.current) {
            if (focus) {
                this.Ref_Input_Hex.current.focus({preventScroll: true});
                this.Ref_Input_Hex.current.select();
            }            
            else {
                this.Ref_Input_Hex.current.blur();
            }
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.id !== this.props.id) {
            this.FocusHex(true);   
        }
        else if (this.state.changing && this.state.hex !== prevState.hex) {
            if (ChromaJs.valid(this.state.hex)) {
                const rgbvalue = FormatRGB(ChromaJs(this.state.hex).alpha(Utils.UseNullOrEmpty(this.state.alpha, 100) / 100).rgba());
                const rgb = Utils.ToRGBA(rgbvalue.rgb);
                if (rgb !== this.props.color) {
                    this.props.onChanging && this.props.onChanging(rgb);
                    this.props.onChange && this.props.onChange(rgb);
                }                    
            }            
        }
    }
    componentDidMount() {
        this.FocusHex(true);        
    }
    onChangingHex(e) {
        let hex = e.target.value.replace(/#/g, '');        
        this.setState({
            hex : '#' + hex,
            alpha : Utils.UseNullOrEmpty(this.state.alpha, 100),
            changing : true
        });        
    }
    
    onChangeHex(e) {
        if (ChromaJs.valid(e.target.value)) {
            const rgbvalue = FormatRGB(ChromaJs(e.target.value).alpha(this.state.alpha / 100).rgba());
            const rgb = Utils.ToRGBA(rgbvalue.rgb);
            if (rgb !== this.props.color) {
                this.props.onChanging && this.props.onChanging(rgb);
                this.props.onChange && this.props.onChange(rgb);
            }
            this.state.changing = false;
        }
        else {
            if (e.target.value) {
                let name = e.target.value.toString().replace('#', '');
                if (ChromaJs.valid(name)) {
                    this.setState({
                        hex : ChromaJs(name).hex(),
                        changing : true
                    }, () => {
                        this.setState({changing : false});
                    });
                    return;
                }
            }
            this.setState({
                hex : this.props.hex,                
                changing : false
            })
        }
    }    
    onChangeAlpha(e) {
        this.state.alpha = Number(e.target.value);
        if (ChromaJs.valid(this.state.hex)) {
            const rgbvalue = FormatRGB(ChromaJs(this.state.hex).alpha(this.state.alpha / 100).rgba());
            const rgb = Utils.ToRGBA(rgbvalue.rgb);
            if (rgb !== this.props.color) {
                this.props.onChanging && this.props.onChanging(rgb);
                this.props.onChange && this.props.onChange(rgb);
            }                
        }
        
        this.state.changing = false;
    }
    onChangingAlpha(e) {
        if (Utils.IsNotNullOrEmpty(e.target.value)) {            
            const alpha = Math.min(100, Math.max(0, Number(e.target.value)));
            if (alpha !== this.state.alpha) {
                if (ChromaJs.valid(this.state.hex)) {
                    const rgbvalue = FormatRGB(ChromaJs(this.state.hex).alpha(alpha / 100).rgba());
                    const rgb = Utils.ToRGBA(rgbvalue.rgb);
                    if (rgb !== this.props.color) {
                        this.props.onChanging && this.props.onChanging(rgb);
                        this.props.onChange && this.props.onChange(rgb);
                    } 
                }                
            }            
            this.setState({
                alpha : alpha
            })
        }        
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.changing) {
            return true;
        }
        else {
            if (ChromaJs.valid(nextProps.color)) {
                const ccolor = ChromaJs(nextProps.color);
                const hex = ccolor.hex('rgb');
                const alpha = ccolor.alpha() * 100;
                if (this.state.hex !== hex || alpha !== this.state.alpha) {
                    this.state.hex = hex;
                    this.state.alpha = alpha;
                    return true;
                }
            }
        }
        return false;
    }
    render() {        
        const style_input = {
            backgroundColor : SC.CurrentTheme.theme.input_back, ...SC.Styles.FontStyles.Monospace, fontSize : '14px'
        }
        return (
            <div style={{display : 'grid', gridTemplateColumns : '2fr 1fr', gridGap : '4px'}}>
                <input 
                    style={{...this.props.style, ...style_input, maxWidth : '88px'}}
                    value={this.state.hex}
                    onChange={this.onChangingHex}
                    onBlur={this.onChangeHex}
                    ref={this.Ref_Input_Hex}
                    spellCheck='false'
                    readOnly={this.props.readOnly}
                />
                <input 
                    style={{...this.props.style, ...style_input, width : 'unset', flex : 'unset'}}
                    value={this.state.alpha}
                    onChange={this.onChangingAlpha}
                    onBlur={this.onChangeAlpha}
                    type='number'
                    min={0}
                    max={100}
                    readOnly={this.props.readOnly}
                />
            </div>
        )
    }
}

export class Input_RGB extends React.Component {
    constructor(props) {
        super(props);


        this.onChange = this.onChange.bind(this);
        this.onChanging = this.onChanging.bind(this);
        
        this.state = {
            value : this.GetValue(this.props, this.props.color)
        }
    }   
    GetValue(props, color) {
        if (ChromaJs.valid(color)) {
            if (props.rgb) {
                const colorvalue = ChromaJs(color).rgba();
                const rgb = FormatRGB(colorvalue);
                if (props.type === 'a') {
                    this.max = 100;                
                }
                else {
                    this.max = 255;
                }            
                return rgb.rgb[props.type];
            }
            else if (props.hsv) {            
                const colorvalue = ChromaJs(color).hsv();
                if (props.type === 'h') {
                    this.max = 360;                
                    return Utils.ToInteger(colorvalue[0]);
                }
                else {
                    this.max = 100;
                    if (props.type === 's')
                        return Utils.ToInteger(100 * colorvalue[1]);
                    return Utils.ToInteger(100 * colorvalue[2]);
                }                                    
            }
        }                
    }
    
    onChanging(e) {
        if (Utils.IsNotNullOrEmpty(e.target.value)) {
            let value = Number(e.target.value);
            if (value === this.state.value)
                return;
            if (this.props.rgb) {
                if (this.props.type === 'a') {
                    value = Math.min(Math.max(0, value), 100);
                }
                else {
                    value = Math.min(Math.max(0, value), 255);
                }
            }
            else if (this.props.hsv) {
                if (this.props.type === 'h') {
                    value = Math.min(Math.max(0, value), 360);
                }
                else {
                    value = Math.min(Math.max(0, value), 100);
                }
            }
            this.setState({
                value : value,
                changing : true
            });        
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.state.changing && this.state.value !== prevState.value)
            this.onChange();
    }
    onChange(e) {        
        if (this.props.rgb) {
            const rgb = FormatRGB(ChromaJs(this.props.color).rgba());
            if (rgb.rgb[this.props.type] !== this.state.value) {
                rgb.rgb[this.props.type] = this.state.value;            
                this.props.onChange(Utils.ToRGBA(rgb.rgb));
            }
        }
        else if (this.props.hsv) {
            let isChanged = true;
            const hsv =  ChromaJs(this.props.color).hsv();
            if (this.props.type === 'h') {
                if (Utils.ToInteger(hsv[0]) === this.state.value)
                    isChanged = false;
                else
                    hsv[0] = this.state.value;
            }
            else if (this.props.type === 's') {
                if (Utils.ToInteger(100 * hsv[1]) === this.state.value)
                    isChanged = false;
                else
                    hsv[1] = this.state.value / 100;
            }
            else if (this.props.type === 'v') {
                if (Utils.ToInteger(100 * hsv[2]) === this.state.value)
                    isChanged = false;
                else
                    hsv[2] = this.state.value / 100;
            }
            if (isChanged) {
                let alpha = 1;
                if (ChromaJs.valid(this.props.color))
                    alpha = ChromaJs(this.props.color).alpha();
                const rgbvalue = FormatRGB(ChromaJs(...hsv, 'hsv').alpha(alpha).rgba());
                this.props.onChange(Utils.ToRGBA(rgbvalue.rgb));
            }            
        }
        if (e) {
            this.setState({
                changing : false
            })
        }        
    }    
    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.changing) {
            return true;
        }
        else {
            if (this.props.color !== nextProps.color || this.props.rgb !== nextProps.rgb) {
                if (ChromaJs.valid(nextProps.color)) {
                    this.state.value = this.GetValue(nextProps, nextProps.color);
                    return true;
                }
            }
        }
        return false;
    }
    render() {        
        const style_input = {
            backgroundColor : SC.CurrentTheme.theme.input_back, ...SC.Styles.FontStyles.Monospace, fontSize : '14px'
        }
        return (
            <input 
                style={{...this.props.style, ...style_input}}
                value={this.state.value}
                onChange={this.onChanging}
                onBlur={this.onChange}
                type='number'
                min={0}
                max={this.max}
                readOnly={this.props.readOnly}
            />
        )
    }
}


const unit = (value, u) => `${value}${u || 'px'}`;

const Styles = {
    absolute : {
        position : 'absolute',
        top : 0,
        left : 0,
        right : 0,
        bottom : 0
    }
}

const alphacolor = (rgb) => `linear-gradient(to right, rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0) 0%, rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 1) 100%)`;

export const FormatRGB = (rgb) => {
    return {
        rgb : {
            r : rgb[0],
            g : rgb[1],
            b : rgb[2],
            a : Utils.UseNullOrEmpty(rgb[3], 1)
        }        
    }
}

const GetHue = (hsv) => Number.isNaN(hsv[0]) ? 0 : hsv[0];

