import React from 'react';
import ReactDOM from 'react-dom';

import {
    MetaData,
    Utils,
    UIUtils
} from '../../../../../../../importer';
import ReactResizeDetector from 'react-resize-detector';
import { onConstructItem, onItemDidMount, onItemWillUnMount, onItemDidUpdate, onItemShouldUpdate, onItemClick, onGetItemProps } from '../../common';

var eCharts = null;
var eChartsPromise = null;

export default class ApexChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }

        this.onResize = this.onResize.bind(this);
        this.Load = this.Load.bind(this);
        this.Ref_EChart = React.createRef();

        onConstructItem(this, props);

        this.UpdateStyle(this.props);
        
    }
    UpdateStyle(props) {        
        const renderData = props.GetComponentManager().GetItemStyle(props.Id);
        const currendProps = Utils.JustGet(this.renderData, {}, 'props');
        this.renderData = renderData;
        if (Utils.HasAnyChange(currendProps, renderData.props, 'ChartType', 'ChartTheme', 'DataSource', 'ChartSubType', 'Animated')) {
            this.Load();
        }
        this.renderData = renderData;
    }
    componentWillUnmount() {
        onItemWillUnMount.call(this);
        if (this.eChartInstance) {
            this.eChartInstance.dispose();
            this.eChartInstance = null;
        }
    }
    componentDidUpdate(prevProps, prevState) {
        onItemDidUpdate.call(this, prevProps, prevState);
    }
    GetMaxSize() {        
        return this.props.GetParentSize();
    }    
    componentDidMount() {
        onItemDidMount.call(this);
        if (!eCharts) {
            if (!eChartsPromise)
                eChartsPromise = [import('echarts')];
            Promise.all(eChartsPromise).then((r) => {
                eCharts = r[0];                
                this.Load();
            })
        }
        else
            this.Load();
    }
    shouldComponentUpdate(nextProps, nextState) {
        let willUpdate = false;
        if (onItemShouldUpdate.call(this, nextProps, nextState)) {
            willUpdate = true;
        }          
        return willUpdate;
    }
    Load() {
        if (this.Ref_EChart.current) {

            const bounds = UIUtils.Utils.GetBounds(this);
            this.Width = bounds.width;
            this.Height = bounds.height;
           
            this.Loaded = true;
            if (this.eChartInstance)
                this.eChartInstance.dispose();
            
            const {ChartType, ChartSubType, DataSource, ChartTheme} = this.renderData.props;
            
            if (ChartType) {
                if (MetaData.Components.Widget.WidgetTypes.eCharts.MetaData.Types[ChartType]) {
                    const MetaChartType = MetaData.Components.Widget.WidgetTypes.eCharts.MetaData.Types[ChartType];
                    let ChartDef = MetaChartType.SubTypes.Default;
                    if (ChartSubType && MetaChartType.SubTypes[ChartSubType]) 
                        ChartDef = MetaChartType.SubTypes[ChartSubType];

                    if (ChartDef && ChartDef.init) {
                        this.eChartInstance = ChartDef.init(eCharts, ReactDOM.findDOMNode(this.Ref_EChart.current), ChartTheme);
                        return;
                    }
                }
            }   
        }
        this.setState({ShouldUpdate : true});
    }   
    onResize(w, h) {
        const that = this;
        if (this.inResizeMode)
            return;
        this.inResizeMode = true;
        setTimeout(() => {
            if (that.Ref_EChart.current && that.eChartInstance && !that.eChartInstance.isDisposed()) {
                that.eChartInstance.resize();
            }
            this.inResizeMode = false;
        }, 200);                                                                                                                                                                                                                                                                                                                                                         
    }    
    render() { 
        if (Utils.IsTrue(this.renderData.props.hidden))
            return null;
            
        const props = onGetItemProps({designer : this});

        return (
            <div style={{...this.renderData.style, overflow : 'hidden'}} {...props} >
                <div style={{width : '100%', height:'100%'}} ref={this.Ref_EChart} />
                <ReactResizeDetector handleWidth handleHeight onResize={this.onResize} />
            </div>
        )
    }
}
 