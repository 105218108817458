import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    AppLayout,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../importer';

import { AnimatePresence, motion } from 'framer-motion';

import ColorEditor, {Input_Hex, Input_RGB, FormatRGB} from '../../../components/editors/color';


class ColorPicker extends React.Component {
    constructor(props) {
        super(props);           
    }
    render() { 

        const style_input = {
            border : SC.CurrentTheme.theme.border_ondark,
            backgroundColor : SC.CurrentTheme.theme.back,
            borderRadius : '2px',
            width : '100%',
            padding : '4px',
            boxSizing : 'border-box',
            textTransform : 'uppercase',
            flex : 1
        }

        const initial = {y : -8};
        if (this.props.centerHorizontal)
            initial.x = '-50%';

        return (  
            <motion.div
                style={{
                    position : 'absolute', 
                    top : '100%',
                    right : 0,
                    left : 0,
                    marginTop : '4px',
                    padding : '8px',
                    backgroundColor : SC.CurrentTheme.theme.dropdown_back_dark,
                    border : SC.CurrentTheme.theme.border_popup,
                    borderRadius : '2px',
                    boxShadow : SC.CurrentTheme.theme.popup_shadow,
                    pointerEvents : 'all',
                    zIndex : 10000,
                    ...this.props.style
                }}
                initial={initial}
                animate={{y : 0}}
                exit={{y : -8, opacity : 0.7}}
                transition={{duration : 0.2}}                           
            >
                <ColorEditor 
                    color={this.props.color}
                    onChanging={this.props.onChanging}
                    onChange={this.props.onChange}
                    onComplete={this.props.onComplete}
                /> 
                <SC.FRow alc>
                    <Label>HEX</Label>
                    <Input_Hex 
                        ref={this.Ref_Hex}
                        id={this.props.id}
                        color={this.props.color}
                        style={style_input}
                        onChange={this.props.onChange}
                    />
                </SC.FRow>
            </motion.div>
        );
    }
}
 
const HoC_ColorPicker = UIUtils.OutSideClickHandler(ColorPicker);

const Label = (props) => <div style={{minWidth : '120px'}}>{props.children}</div>

export default HoC_ColorPicker;