import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppLayout,
    Links,
    Events,
    Loading,
    Strings,
    Globals
} from '../../../../../../importer';

import {TokenItemBox, TokenPanelHeader, TokenItemDragHandle, TokenItemBoxHoveredName} from '../common';
import { SortableList, SortableListItem } from '../../../../../../components/SortableContainer';
import BooleanEditor from './item';
import BaseTokenList from '../BaseTokenList';
import {Droppable, Draggable} from 'react-beautiful-dnd';
import { LeftScrollPanel } from '../../common';
import Switch from '../../../../../../components/editors/Switch';
import { StatefulTokenListItemName } from '../colors';
import { GRANT_TYPES } from '../../../../manager';

export default class BooleanTokens extends BaseTokenList
{
    constructor(props) {
        super(props);

        this.title = this.props.title || 'BOOLEANS';
        this.moduleName = 'Booleans';
        this.tokenType = Globals.ProjectManager.Tokens.Types.Booleans;
        this.hasGroups = true;
        this.noListView = true;
        this.onEditBoolean = this.onEditBoolean.bind(this);
        this.EditBoolean = this.EditBoolean.bind(this);
        this.onDeleteBoolean = this.onDeleteBoolean.bind(this);
        this.onCloneBoolean = this.onCloneBoolean.bind(this);
        this.onCancelAddBoolean = this.onCancelAddBoolean.bind(this);
        this.onSubmitNewBoolean = this.onSubmitNewBoolean.bind(this);

        AppLayout.Refs.DesignSystem.Booleans = this;

        super.LoadOptions();
        if (this.expanded || this.props.singleView) {
            this.expanded = true;
            this.Load(this.state.isGroupView);
        }
    }
    componentWillUnmount() {
        super.componentWillUnmount();
        AppLayout.Refs.DesignSystem.Booleans = null;
    }
    Load(groupView) {        
        this.tokens = GetBooleanTokenList();        
        if (groupView)
            this.groups = super.GetGroupedTokenList(this.tokens)
    }        
    SearchToken(filter) {
        let filterText;
        if (Utils.IsNotNullOrEmpty(filter))
            filterText = filter.toUpperCase();
        Utils.ForEach(this.tokens, (token, i) => {
            delete token.filtered;
            if (filterText) {
                if (token.name.toUpperCase().indexOf(filterText) < 0) {                    
                    token.filtered = true;
                }                
            }
        });
        this.RenderId = Utils.Id();
        this.RCThrottledUpdate_1();
    }  
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.filterText !== nextProps.filterText) {
            setTimeout(() => {
                this.SearchToken(nextProps.filterText);
            }, 1);
            return false;
        }
        if (super.shouldComponentUpdate(nextProps, nextState)) {
            return true;
        }
        return false;
    }    
    AddTokenToGroup(group) {
        if (!Globals.ProjectManager.CheckGrant(GRANT_TYPES.EDIT_TOKEN.ALL)) {
            return;
        }

        this.AddingToGroup = group;
        this.IsNew = true;
        this.EditBooleanId = Utils.Id();
        this.EditBooleanModel = {
            name : 'New Boolean'
        };
        
        this.props.onPanelOverlay({
            show : true,
            render : (props) => {
                return (
                    <SC.FCol fw fh>
                        <TokenPanelHeader 
                            title='NEW BOOLEAN' 
                            notBackClosable
                            hasAddCancel 
                            onClose={this.onCancelAddBoolean} 
                            onCancel={this.onCancelAddBoolean} 
                            onAdd={this.onSubmitNewBoolean} 
                        />
                        <LeftScrollPanel>
                            <BooleanEditor 
                                id={this.EditBooleanId}
                                newModel={this.EditBooleanModel}
                                GlobalState={props.GlobalState}
                                GlobalStateId={props.GlobalStateId}
                                offline
                            />
                        </LeftScrollPanel>                        
                    </SC.FCol>                    
                )
            }
        })
    }
    onEditBoolean(id, e) {
        if (this.props.onSelect) {
            this.props.onSelect(id, e);
            return;
        }

        if (!Globals.ProjectManager.CheckGrant(GRANT_TYPES.EDIT_TOKEN.ALL)) {
            return;
        }
        
        this.EditBoolean(id);
    }
    onChangeValue(id, value) {
        Globals.ProjectManager.Tokens.SetValue({id : id, value : value});
        Events.BroadcastThrottle(Events.GLOBAL.TOKEN_VALUE_CHANGING, [{
            Id : id,
            Type : Globals.ProjectManager.Tokens.Types.Booleans,
            value : value

        }]);
        this.RCUpdate();
    }
    onShowMenu(id, e) {
        this.props.onShowMenu && this.props.onShowMenu(id, e);
    }
    EditBoolean(id) {        
        this.EditBooleanId = id;

        if (Globals.ProjectManager.AuditManager) {
            this.WillEditToken = Utils.DeepClone(Globals.ProjectManager.Tokens.Token(id));
        }

        this.props.onPanelOverlay({
            show : true,
            render : (props) => {
                return (
                    <SC.FCol fw fh>
                        <TokenPanelHeader 
                            title='EDIT BOOLEAN' 
                            hasAddCancel 
                            onClose={this.onSubmitNewBoolean} 
                            onCancel={this.onCancelAddBoolean} 
                            onAdd={this.onSubmitNewBoolean} 
                            onDelete={this.onDeleteBoolean} 
                            onClone={this.onCloneBoolean}
                        />
                        <LeftScrollPanel>
                            <BooleanEditor 
                                ref={this.Ref_BooleanEdit}
                                id={id}
                                GlobalState={props.GlobalState}
                                GlobalStateId={props.GlobalStateId}
                                onCancel={this.onCancelAddBoolean}
                                onClosed={() => {
                                    const tokencurrent = Globals.ProjectManager.Tokens.Token(id);
                                    
                                    if (this.WillEditToken && tokencurrent) { 
                                        const diff = Utils.GetFlattenedDiff(this.WillEditToken, tokencurrent);
                                        if (diff.length > 0) {
                                            Globals.ProjectManager.AuditManager && Globals.ProjectManager.AuditManager.TokenChanged({TokenId : id, Change : {
                                                changes : diff
                                            }});
                                        }                                                                        
                                    }
                                    delete this.WillEditToken;
                                }}
                            />
                        </LeftScrollPanel>                        
                    </SC.FCol>                    
                )
            }
        })
    }    
    onCloneBoolean() {
        const cloneId = Globals.ProjectManager.Tokens.Clone(this.EditBooleanId, this.tokenType);
        this.Load(this.props);
        this.EditBoolean(cloneId);        
    }
    onDeleteBoolean() {
        if (Globals.ProjectManager.Tokens.Delete(this.tokenType, this.EditBooleanId)) {
            this.Load(this.props);
            this.onCancelAddBoolean();
        }
    }
    onCancelAddBoolean() {
        delete this.IsNew;
        delete this.EditBooleanModel;
        delete this.EditBooleanId;

        this.props.onPanelOverlay({close : true});
        this.RCUpdate();
    }
    onSubmitNewBoolean() {
        if (this.IsNew) {
            
            const BooleanItem = AddNewBoolean(this.EditBooleanModel, this.EditBooleanId);
            this.tokens.push(BooleanItem);

            if (this.AddingToGroup) {
                this.AddingToGroup.tokens.push(BooleanItem);
                Globals.ProjectManager.Tokens.AddTokenToGroup({type : this.tokenType, groupid : this.AddingToGroup.id, tokenid : BooleanItem.id})
                delete this.AddingToGroup;
            } 

            this.WillScrollTo = this.EditBooleanId;
            this.selectedId = this.EditBooleanId;
        }        
        else {
            const token = Globals.ProjectManager.Tokens.Token(this.EditBooleanId);
            if (token) {
                const localpattern = Utils.Find(this.tokens, (item) => {return item.id === this.EditBooleanId});
                localpattern.name = token.name;
                Events.BCE(Events.GLOBAL.TOKEN_VALUE_CHANGING);
            }            
        }
                
        Events.BCE(Events.GLOBAL.TOKENS_CHANGED);
        this.onCancelAddBoolean();
    }    
    renderTokenList(tokens, gorupId) {
        let style;
        style = {
            marginLeft : '10px', marginRight : '10px', marginTop : '6px', marginBottom : 0,
            ...SC.Styles.Flex.Column
        }

        const transformItems = [];
        Utils.ForEach(tokens, (model, i) => {                   
            !model.filtered && transformItems.push(
                <SortableListItem
                    key={model.id}
                    draggableId={model.id}                    
                    index={i}
                    ItemBoxType={BooleanListItem}
                    isDragDisabled={!this.HasEditGrant}
                    BoxProps={{
                        name : model.name,
                        value : model.value,
                        valueState : model.valueState,
                        locked : model.locked,
                        onSelect : this.onEditBoolean.bind(this, model.id),
                        onShowMenu : this.onShowMenu.bind(this, model.id),
                        onChange : this.onChangeValue.bind(this, model.id)
                    }}                
                />
            )
        });    
        return (
            <Droppable 
                droppableId={gorupId}
                type={'TOKENS'}                
            >
                {
                    (provided, snapshot) => (
                        <div
                            {...provided.droppableProps} 
                            ref={provided.innerRef} 
                            style={{
                                ...provided.droppableProps.style,
                                ...style,
                                border : '1px dashed',                                                    
                                borderColor : snapshot.isDraggingOver ? SC.CurrentTheme.theme.color_brand : 'transparent'                                
                            }}
                        >
                            {transformItems}
                            {provided.placeholder}
                        </div>
                    )
                }
            </Droppable>
        )  
    }    
}

export const BooleanListItem = ({onSelect, onShowMenu, onChange, notDraggable, style, sortableProps, name, value, valueState, locked, onPreview, selected, ...rest}) => {
    const style_box = {
        ...style,
        padding : '6px',
        flex : 1,
        marginRight : '8px',
        marginBottom : 0
    }
    if (selected) {
        style_box.borderLeft = '1px solid';
        style_box.borderLeftColor = SC.CurrentTheme.theme.color_brand;
    }
    return (        
            <SC.FRow f1 alc jsb overflowHidden style={{marginBottom : '4px'}}>
                <TokenItemBox onClick={onSelect} onContextMenu={onShowMenu} style={style_box} {...onPreview} {...rest} selected={selected}>
                    <StatefulTokenListItemName 
                        name={name}
                        sortableProps={sortableProps}
                        ValueState={valueState}
                        locked={locked}
                    />
                </TokenItemBox>                      
                <Switch round value={Utils.IsTrue(Utils.IsTrue(value))} onChange={onChange}/>
            </SC.FRow>                 
    )
}

export const GetBooleanTokenList = (StateArray) => {
    const tokenids = Globals.ProjectManager.Tokens.Booleans();
    return GetBooleanTokenListOfIds(tokenids, StateArray);
}
export const GetBooleanTokenListOfIds = (tokenids, StateArray) => {
    
    const tokens = Globals.ProjectManager.Tokens.TokenList(Globals.ProjectManager.Tokens.Types.Booleans);
    const tokenitems = [];

    Utils.ForEach(tokenids, (id, i) => {
        const token = tokens[id];
        if (token) {
            const info = {};
            const tokenvalue = Utils.IsTrue(Globals.ProjectManager.Tokens.ValueOf({model : token, statearray : StateArray, info : info}));
            const tokenitem = {
                id : id,
                name : token.name,
                value : tokenvalue,
                valueState : info.ValueState,
                locked : token.locked
            }
            tokenitems.push(tokenitem);                
        }
    });


    return tokenitems;
}

export const AddNewBoolean = (model, id) => {
    Globals.ProjectManager.Tokens.Add({
        type : Globals.ProjectManager.Tokens.Types.Booleans,
        name : model.name,
        value : model.value,
        id : id
    })
    return {
        id : id,
        name : model.name,
        value : model.value
    };
}