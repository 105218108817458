import React from 'react';
import ReactDOM from 'react-dom';
import ReactResizeDetector from 'react-resize-detector';
import MetaData from '../../../../toolabs-metaui';

var ApexCharts = null;
var apexchartsPromise = null;

const MetaChart = MetaData.Components.Widget.WidgetTypes.ApexCharts.MetaData;

class Widget_EChart extends React.Component
{
    constructor(props) {
        super(props);        
        this.state =  {
            Loaded : false
        };
        this.Load = this.Load.bind(this);
        this.onResize = this.onResize.bind(this);
        this.ScaleFactor = 1;
        this.Ref_EChart = React.createRef();

        if (this.props.ScaledTo)
            this.ScaleFactor = this.props.ScaledTo;
    }
    componentDidMount() {
        if (!ApexCharts) {
            if (!apexchartsPromise)
                apexchartsPromise = [import('apexcharts')];
            Promise.all(apexchartsPromise).then((r) => {
                ApexCharts = r[0].default;                
                this.Load();
            })
        }
        else
            this.Load();
    }
    componentWillUnmount() {
        if (this.eChartInstance) {
            this.eChartInstance.destroy();
            this.eChartInstance = null;
        }
    }
    onResize(w, h) {
        this.Width = w;
        this.Height = h;
        if (this.eChartInstance) {
            this.eChartInstance.updateOptions(
                {
                    chart : {
                        width : this.Width,
                        height : this.Height
                    }
                },
                true,
                true
            )
        }              
    }
    Load() {
        if (this.Ref_EChart.current) {                        
            const DN = ReactDOM.findDOMNode(this);
            const bounds = DN.getBoundingClientRect();
            this.Width = bounds.width;
            this.Height = bounds.height;


            if (this.eChartInstance)
                this.eChartInstance.destroy();
            const WidgetOptions = this.props;
            if (WidgetOptions) {
                if (WidgetOptions.ChartType) {
                    if (WidgetOptions.ChartOptions) {

                    }
                    else {
                        if (MetaChart.Types[WidgetOptions.ChartType]) {
                            const MetaChartType = MetaChart.Types[WidgetOptions.ChartType];
                            let ChartDef = MetaChartType.SubTypes.Default;

                            let options = {
                                width : this.Width,
                                height : this.Height,
                                sparkline : this.props.Sparkline
                            };

                            if (ChartDef && ChartDef.init) {
                                this.eChartInstance = ChartDef.init(ApexCharts, ReactDOM.findDOMNode(this.Ref_EChart.current), options);
                                this.setState({Loaded : true});
                                return;
                            }
                        }
                    }
                }
            }
        }
    }
    componentWillUnmount() {
        this.eChartInstance && this.eChartInstance.destroy();
        this.eChartInstance = null;
    }
    
    render() {  
        return (
            <React.Fragment>
                <div style={{width : '100%', height:'100%', boxSizing : 'border-box'}} ref={this.Ref_EChart} />
                {this.state.Loaded && <ReactResizeDetector handleWidth handleHeight onResize={this.onResize} /> }
            </React.Fragment>
        )             
        return (
            <div style={{width : '100%', height:'100%',  position:'relative', overflow:'hidden'}} >                                
                <div style={{width : '100%', height:'100%'}} ref={this.Ref_EChart} />
                {this.state.Loaded && <ReactResizeDetector handleWidth handleHeight onResize={this.onResize} /> }
            </div>
        )
    }
}

export default Widget_EChart;
