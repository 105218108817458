import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    UIUtils,
    Globals
} from '../../../../../../importer';

import { CD_LP_ToobarItem, CD_LP_TooobarItemArrow } from '../../../../../../styled/toolbar';
import styled from 'styled-components';
import DragSource from '../../../../../../components/dragdrop/HoCDragSource';
import { TokenItemBox } from '../../designsystem/common';

export default class ToolbarItem extends React.PureComponent {
    render() {
        const { title, IconType, DragItem, forSelect, customContent, style, ...rest } = this.props;
        if (customContent) {
            // return (
            //     <TokenItemBox  {...rest} style={{...this.props.style, padding : 0, paddingTop : '4px'}}>
            //         {this.props.children}
            //     </TokenItemBox>
            // )
            return (
                <ElementBox style={style} {...rest}>
                    {this.props.children}
                </ElementBox>    
            )
        }
        const style_box = {};
        if (forSelect) {
            style_box.cursor = 'pointer';
            if (forSelect.disabled) {
                style_box.opacity = '0.5';
                style_box.cursor = 'not-allowed';
            }
            if (forSelect.selected) {
                style_box.border = SC.CurrentTheme.theme.border_brand;
            }
        }
        
        return (
            <ElementBox {...rest} style={{...style, ...style_box}}>
                <SC.Div_Flex_Cell f1>
                    <IconType size={28} />
                </SC.Div_Flex_Cell>                
                {title}                
            </ElementBox>
        )
    }
}

export const DraggableToolbarItem = DragSource(ToolbarItem);

export const ElementBox = styled.div`
    display : flex;
    flex-direction : column;
    justify-content : space-between;
    padding : 4px;
    align-items : center;
    overflow : hidden;       
    transition : all 0.3s ease;
    border-radius : 2px;
    font-size : 11px;
    border : ${props => props.theme.border_ondark};
    background-color : ${props => props.theme.isLight ? props.theme.back_lighter : props.theme.back};
    padding : 4px;
    cursor : move;
    &: hover{
        // border : 1px solid ${props => props.theme.border_hover};
        // color : ${props => props.theme.font_hover};
        background-color : ${props => props.theme.isLight ? props.theme.back : props.theme.back_lightest};
        color : ${props => props.theme.font_hover};        
    }
`;

