import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppLayout,
    Links,
    Events,
    Loading,
    UIUtils,
    Globals
} from '../../../../../../importer';

import {TokenItemBox, TokenPanelHeader, TokenItemDragHandle, TokenItemBoxHoveredName} from '../common';
import { LeftScrollPanel } from '../../common';
import { SortableList, SortableListItem } from '../../../../../../components/SortableContainer';
import TransformTokenEditor from './item';
import BaseTokenList from '../BaseTokenList';
import {Droppable, Draggable} from 'react-beautiful-dnd';
import { StatefulTokenListItemName, StatefulTokenMarker, TokenLockedMarker } from '../colors';
import { GRANT_TYPES } from '../../../../manager';

export default class Transforms extends BaseTokenList
{
    constructor(props) {
        super(props);

        this.title = 'TRANSFORMS';
        this.moduleName = 'Transforms';
        this.tokenType = Globals.ProjectManager.Tokens.Types.Transforms;
        this.hasGroups = true;
        
        this.Ref_TransformEdit = React.createRef();
        this.onEditTransform = this.onEditTransform.bind(this);
        this.onDeleteTransform = this.onDeleteTransform.bind(this);
        this.onCloneTransform = this.onCloneTransform.bind(this);
        this.onCancelAddTransform = this.onCancelAddTransform.bind(this);
        this.onSubmitNewTransform = this.onSubmitNewTransform.bind(this);

        this.RemoveStateOverride = this.RemoveStateOverride.bind(this);        
        this.MoveValueToParentState = this.MoveValueToParentState.bind(this);
        this.SwitchStateToTokensOverriden = this.SwitchStateToTokensOverriden.bind(this);
        this.ApplyValueToCurrentState = this.ApplyValueToCurrentState.bind(this);        

        AppLayout.Refs.DesignSystem.Transforms = this;

        super.LoadOptions();
        if (this.expanded || this.props.singleView) {
            this.expanded = true;
            this.Load(this.state.isGroupView);
        }
    }
    componentWillUnmount() {
        super.componentWillUnmount();
        AppLayout.Refs.DesignSystem.Transforms = null;
    }
    Load(groupView) {        
        this.tokens = GetTransformTokenList();        
        if (groupView)
            this.groups = super.GetGroupedTokenList(this.tokens)
    }      
    SearchToken(filter) {
        let filterText;
        let filteredCount = 0;
        if (Utils.IsNotNullOrEmpty(filter))
            filterText = filter.toUpperCase();
        Utils.ForEach(this.tokens, (token, i) => {
            delete token.filtered;
            if (filterText) {
                if (token.name.toUpperCase().indexOf(filterText) < 0) {                    
                    token.filtered = true;
                }
            }
        });
        this.RenderId = Utils.Id();
        this.RCThrottledUpdate_1();
    }  
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.filterText !== nextProps.filterText) {
            setTimeout(() => {
                this.SearchToken(nextProps.filterText);
            }, 1);
            return false;
        }
        return super.shouldComponentUpdate(nextProps, nextState);
    }
    AddTokenToGroup(group) {
        if (!Globals.ProjectManager.CheckGrant(GRANT_TYPES.EDIT_TOKEN.ALL)) {
            return;
        }

        this.AddingToGroup = group;
        this.IsNew = true;
        this.EditTransformId = Utils.Id();
        this.EditTransformModel = {
            name : 'New Transform',
            items : [
                {
                    id : Utils.Id(),
                    type : 'scale',
                    locked : true
                }    
            ],
        };
        
        this.props.onPanelOverlay({
            show : true,
            render : (props) => {
                return (
                    <SC.FCol fw fh>
                        <TokenPanelHeader 
                            title='NEW TRANSFORM' 
                            notBackClosable
                            hasAddCancel 
                            onClose={this.onCancelAddTransform} 
                            onCancel={this.onCancelAddTransform} 
                            onAdd={this.onSubmitNewTransform} 
                        />
                        <LeftScrollPanel>
                            <TransformTokenEditor 
                                ref={this.Ref_TransformEdit}
                                newModel={this.EditTransformModel}
                                GlobalState={props.GlobalState}
                                offline
                            />
                        </LeftScrollPanel>                        
                    </SC.FCol>                    
                )
            }
        })
    }
    RemoveStateOverride() {
        if (this.ValueState.ValueState && !this.ValueState.ValueState.inherited) {
            Globals.ProjectManager.Tokens.DeleteValue({
                id : this.EditTransformId,
                state : this.ValueState.ValueState.state
            })            
            delete this.ValueState.ValueState;
            Globals.ProjectManager.Tokens.GetStateValue({Id : this.EditTransformId, info: this.ValueState});
            this.tokenValueId = Utils.Id();
            Events.BCE(Events.GLOBAL.TOKENS_CHANGED);
        }        
    }
    MoveValueToParentState() {
        if (this.ValueState.ValueState) {
            Globals.ProjectManager.Tokens.MoveValueToState({
                id : this.EditTransformId,
                fromState : this.ValueState.ValueState.state
            });
            delete this.ValueState.ValueState;
            Globals.ProjectManager.Tokens.GetStateValue({Id : this.EditTransformId, info: this.ValueState});
            this.tokenValueId = Utils.Id();
            this.props.onUpdate();
        }
    }
    ApplyValueToCurrentState() {
        if (this.ValueState.ValueState) {
            Globals.ProjectManager.Tokens.MoveValueToState({
                id : this.EditTransformId,
                fromState : this.ValueState.ValueState.state,
                toState : Globals.ProjectManager.CurrentState
            });
            delete this.ValueState.ValueState;
            Globals.ProjectManager.Tokens.GetStateValue({Id : this.EditTransformId, info: this.ValueState});
            this.tokenValueId = Utils.Id();
            this.props.onUpdate();
        }
    }
    SwitchStateToTokensOverriden() {
        const ToState = this.ValueState.ValueState.state;
        delete this.ValueState.ValueState;
        Globals.ProjectManager.States.SetStateFromLabel(ToState);
    }
    onEditTransform(id, e) {        
        if (this.props.onSelect) {
            this.props.onSelect(id, e);
            return;
        }

        if (!Globals.ProjectManager.CheckGrant(GRANT_TYPES.EDIT_TOKEN.ALL)) {
            return;
        }
        
        this.EditTransformId = id;
        this.ValueState = {
            onRemoveOverride : this.RemoveStateOverride,
            onMoveToParent : this.MoveValueToParentState,
            onApplyToCurrentState : this.ApplyValueToCurrentState,
            onSwitchToOverridenState : this.SwitchStateToTokensOverriden
        };
        Globals.ProjectManager.Tokens.GetStateValue({Id : this.EditTransformId, info: this.ValueState});

        if (Globals.ProjectManager.AuditManager) {
            this.WillEditToken = Utils.DeepClone(Globals.ProjectManager.Tokens.Token(id));
        }

        this.props.onPanelOverlay({
            show : true,
            render : (props) => {
                if (props.GlobalState !== this.props.GlobalState) {
                    if (this.ValueState)
                        delete this.ValueState.ValueState;
                    Globals.ProjectManager.Tokens.GetStateValue({Id : this.EditTransformId, info: this.ValueState});
                }
                return (
                    <SC.FCol fw fh>
                        <TokenPanelHeader 
                            title='EDIT TRANSFORM' 
                            hasAddCancel 
                            onClose={this.onSubmitNewTransform} 
                            onCancel={this.onCancelAddTransform} 
                            onAdd={this.onSubmitNewTransform} 
                            onDelete={this.onDeleteTransform} 
                            onClone={this.onCloneTransform}
                            stateValue={this.ValueState}
                        />
                        <LeftScrollPanel>
                            <TransformTokenEditor 
                                ref={this.Ref_TransformEdit}
                                id={id}
                                renderId={this.tokenValueId}
                                GlobalState={props.GlobalState}
                                GlobalStateId={props.GlobalStateId}
                                onCancel={this.onCancelAddTransform}
                                onChanged={() => {
                                    const ValueState = Utils.Get(this.ValueState, {
                                        state : Globals.ProjectManager.CurrentState
                                    }, 'ValueState');
                                    ValueState.inherited = false;
                                    this.props.onUpdate();
                                }}
                                onClosed={() => {
                                    const tokencurrent = Globals.ProjectManager.Tokens.Token(id);
                                    
                                    if (this.WillEditToken && tokencurrent) { 
                                        const diff = Utils.GetFlattenedDiff(this.WillEditToken, tokencurrent);
                                        if (diff.length > 0) {
                                            Globals.ProjectManager.AuditManager && Globals.ProjectManager.AuditManager.TokenChanged({TokenId : id, Change : {
                                                changes : diff
                                            }});
                                        }                                                                        
                                    }
                                    delete this.WillEditToken;
                                }}
                            />
                        </LeftScrollPanel>                        
                    </SC.FCol>                    
                )
            }
        })
    }    
    onCloneTransform() {
        const cloneId = Globals.ProjectManager.Tokens.Clone(this.EditTransformId, Globals.ProjectManager.Tokens.Types.Transforms);
        this.Load(this.props);
        this.onEditTransform(cloneId);        
    }
    onDeleteTransform() {
        if (Globals.ProjectManager.Tokens.Delete(Globals.ProjectManager.Tokens.Types.Transforms, this.EditTransformId)) {
            this.Load(this.props);
            this.onCancelAddTransform();
        }
    }
    onCancelAddTransform() {
        delete this.IsNew;
        delete this.EditTransformModel;
        delete this.EditTransformId;

        this.props.onPanelOverlay({close : true});
        this.RCUpdate();
    }
    onSubmitNewTransform() {
        if (this.IsNew) {
            
            const TransformItem = AddNewTransformToken(this.EditTransformModel, this.EditTransformId);
            this.tokens.push(TransformItem);

            if (this.AddingToGroup) {
                this.AddingToGroup.tokens.push(TransformItem);
                Globals.ProjectManager.Tokens.AddTokenToGroup({type : this.tokenType, groupid : this.AddingToGroup.id, tokenid : TransformItem.id})
                delete this.AddingToGroup;
            } 

            this.WillScrollTo = this.EditTransformId;
            this.selectedId = this.EditTransformId;            
        }        
        else {
            const token = Globals.ProjectManager.Tokens.Token(this.EditTransformId);
            if (token) {
                const localpattern = Utils.Find(this.tokens, (item) => {return item.id === this.EditTransformId});
                localpattern.name = token.name;
                Events.BCE(Events.GLOBAL.TOKEN_VALUE_CHANGING);
            }            
        }
                
        Events.BCE(Events.GLOBAL.TOKENS_CHANGED);
        this.onCancelAddTransform();
    }    
    renderTokenList(tokens, gorupId) {
        let style;
        if (this.state.isListView) {
            style = {
                marginLeft : '10px', marginRight : '10px', marginTop : '6px', 
                ...SC.Styles.Flex.Column
            }
        }
        else {
            style = {
                margin : '10px', marginBottom : '4px', marginTop : '4px', marginBottom : 0,
                ...SC.Styles.Flex.Column
            }
        }

        const transformItems = [];
        if (this.state.isListView) {
            Utils.ForEach(tokens, (transform, i) => {                   
                transform && !transform.filtered && transformItems.push(
                    <SortableListItem
                        key={transform.id}
                        draggableId={transform.id}
                        index={i}
                        ItemBoxType={TransformListItem}
                        isDragDisabled={!this.HasEditGrant}
                        BoxProps={{
                            transform : transform,
                            onSelect : this.onEditTransform.bind(this, transform.id)
                        }}                
                    />
                )
            });            
        }
        else {               
            Utils.ForEach(tokens, (transform, i) => {                   
                transform && !transform.filtered && transformItems.push(
                    <SortableListItem
                        key={transform.id}
                        draggableId={transform.id}
                        index={i}
                        ItemBoxType={TransformCardItem}
                        BoxProps={{
                            transform : transform,
                            onClick : this.onEditTransform.bind(this, transform.id)
                        }}                
                    />
                )
            });
        }

        return (
            <Droppable 
                droppableId={gorupId}
                type={'TOKENS'}
                direction={this.state.isListView ? 'vertical' : 'horizontal'}
            >
                {
                    (provided, snapshot) => (
                        <div
                            {...provided.droppableProps} 
                            ref={provided.innerRef} 
                            style={{
                                ...provided.droppableProps.style,
                                ...style,
                                border : '1px dashed',                                                    
                                borderColor : snapshot.isDraggingOver ? SC.CurrentTheme.theme.color_brand : 'transparent',                     
                            }}
                        >
                            {transformItems}
                            {provided.placeholder}
                        </div>
                    )
                }
            </Droppable>
        )  
    }    
}

export const AddNewTransformToken = (model, id) => {
    Globals.ProjectManager.Tokens.Add({
        type : Globals.ProjectManager.Tokens.Types.Transforms,
        name : model.name,
        value : {
            items : model.items,
            originX : model.originX || 'center',
            originY : model.originY || 'center'
        },
        id : id
    })
    let transformvalue = Utils.GetTransformCss(model.items, model.originX, model.originY, Globals.ProjectManager.Tokens.ValueOfId); 
    transformvalue.transformOrigin = Utils.GetTransformOrigin(model);
    return GetTransformTokenItem(id);
}

export const TransformCardItem = ({transform, listView, onClick, sortableProps, onPreview, selected, small, style, ...rest}) => {    
    const size = '60px';
    const box = '60px';
    const style_box = {
        cursor : 'pointer',
        backgroundColor : SC.CurrentTheme.theme.back,            
        width : small ? box : '100%', height : small ? box : '120px', marginBottom : small ? 0 : '4px', ...SC.Styles.Flex.Cell, overflow : 'hidden',
        pointerEvents : 'none',
        position : 'relative'
    };
    if (small) {
        style_box.backgroundImage = 'unset';
    }
    else {
        if (selected) {
            style_box.borderLeft = '2px solid';
            style_box.borderLeftColor = SC.CurrentTheme.theme.color_brand;
        }
    }
    let preview = (
        <SC.GridBackground small {...Utils.JustGet(sortableProps, {}, 'handleProps')} style={style_box} dark={!SC.CurrentTheme.theme.isLight} {...rest} onClick={onClick} {...onPreview}>
            <div style={{width : size, minHeight : size, borderRadius : '3px', backgroundColor : 'rgba(184, 109, 240, 0.34)', position : 'relative', transformOrigin : 'center', transform : small ? 'scale(0.3)' : 'scale(0.7)'}}>
                <div
                    style={{
                        width : size, height : size, borderRadius : '4px', backgroundColor : 'rgba(0, 125, 255, 0.5)', ...SC.Styles.Absolute,
                        ...transform.value
                    }} 
                >
                </div>                        
            </div>           
            {
                !listView && transform.valueState && 
                <StatefulTokenMarker
                    style={{
                        position : 'absolute',
                        top : '4px',
                        left : '4px'
                    }}
                    {...transform.valueState}
                />
            }
            {
                transform.locked && <TokenLockedMarker overlay />
            }
        </SC.GridBackground>
    )
    return preview;   
}

export const TransformListItem = ({onSelect, notDraggable, style, sortableProps, transform, onPreview, selected, ...rest}) => {
    const style_box = {
        ...style,
        padding : '0px', paddingLeft : '6px',
        ...SC.Styles.Flex.RowAlcJsb,
        minHeight : '60px'
    }
    if (selected) {
        style_box.borderLeft = '1px solid';
        style_box.borderLeftColor = SC.CurrentTheme.theme.color_brand;
    }
    return (
        <TokenItemBox onClick={onSelect} style={style_box} {...onPreview} {...rest} selected={selected}>
            <SC.FRow f1 alc overflowHidden>
                <StatefulTokenListItemName 
                    name={transform.name}
                    sortableProps={sortableProps}
                    ValueState={transform.valueState}
                    locked={transform.locked}
                />                
            </SC.FRow>                
            <TransformCardItem
                transform={transform}
                key={transform.id} 
                notDraggable={notDraggable}
                small
                listView
            />   
        </TokenItemBox>
    )
}

export const GetTransformTokenList = (StateArray) => {
    const tokenids = Globals.ProjectManager.Tokens.Transforms();
    return GetTransformTokenListOfIds(tokenids, StateArray);
}
export const GetTransformTokenListOfIds = (tokenids, StateArray) => {    
    const items = [];

    Utils.ForEach(tokenids, (id, i) => {
        items.push(GetTransformTokenItem(id, StateArray));
    });  
    return items;
}

export const GetTransformTokenDesignList = (StateArray) => {
    const tokenids = Globals.ProjectManager.Tokens.Transforms();
    return GetTransformTokenDesignListOfIds(tokenids, StateArray);
}
export const GetTransformTokenDesignListOfIds = (tokenids, StateArray) => {    
    const items = [];

    Utils.ForEach(tokenids, (id, i) => {
        items.push(GetTransformTokenItem(id, StateArray, true));
    });  
    return items;
}

export const GetTransformTokenItem = (id, StateArray, GetDesignData) => {
    const token = Globals.ProjectManager.Tokens.Token(id);
    if (!token)
        return null;
    const info = {};
    const transform = Globals.ProjectManager.Tokens.ValueOf({model : token, statearray : StateArray, info : info});
    const transforms = Utils.JustGet(transform, [], 'items');
    let transformvalue;
    if (transform.matrix && !GetDesignData) {
        transformvalue = {
            transform : transform.matrix,
        }
    }
    else {
        transformvalue = Utils.GetTransformCss(transforms, transform.originX, transform.originY, Globals.ProjectManager.Tokens.ValueOfId); 
    };    

    transformvalue.transformOrigin = Utils.GetTransformOrigin(transform);

    return {
        id : id,
        name : token.name,
        value : transformvalue,
        motionValue : Utils.GetTransformMotionObject(transforms),
        valueState : info.ValueState,
        locked : token.locked
    }
}
