import React from 'react';
import ReactDOM from 'react-dom'; 
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    UIUtils
} from '../../importer';
import * as Styled from './styles';
import InputForm from './input_form';
import Scrollbars from 'react-custom-scrollbars';
import AppMetaData from '../../../metadata.js';
import FileUploader from 'react-firebase-file-uploader';
import firebase from '@firebase/app';
import '@firebase/storage';

export default class Account_Settings extends ReactBaseComponent {
    constructor(props) {
        super(props);

        this.onSend = this.onSend.bind(this);

        this.clearForm = this.clearForm.bind(this);
        this.onTopicChanged = this.onTopicChanged.bind(this);
        this.onSubjectChanged = this.onSubjectChanged.bind(this);
        this.onDescriptionChanged = this.onDescriptionChanged.bind(this);
        this.deleteFile = this.deleteFile.bind(this);
        this.clearForm();
        this.files = [];
    }

    clearForm() {
        this.Topic = "";
        this.Subject = "";
        this.Description = "";
        this.files = [];
        this.RCUpdate();
    }
    onSend() {
        if (this.validate()) {
            AppState.CreateFeedback(this.Topic, this.Subject, this.Description,this.files).then((result_save) => {

                Events.Notify("Thank you for your feedback. We will respond as soon as possible.", Events.GLOBAL.NOTIFICATION.TYPES.SUCCESS, true);
                this.clearForm();
            });
        }
    }

    validate() {

        var isValid = 
            //& this.Ref_Topic.Validate(this.Topic, true)
             this.Ref_Subject.Validate(this.Subject, true)
            & this.Ref_Description.Validate(this.Description, true);

        return isValid;
    }

    onTopicChanged(topic) {
        if (this.Ref_Topic)
            this.Topic = topic;
    }
    onSubjectChanged(subject) {
        if (this.Ref_Subject)
            this.Subject = subject;
    }
    onDescriptionChanged(description) {
        if (this.Ref_Description)
            this.Description = description;
    }
    getFileName(file) {
        if (file.target.value) {
            var parts = file.target.value.split("\\");
            return parts[parts.length - 1];
        }
    }
    fileSelected(file) {
        this.tempFileName = this.getFileName(file);
        this.Ref_FileUploader.handleFileSelection(file);
    }
    deleteFile(i) {
        Utils.RemoveEquals(this.files, this.files[i]);
        this.RCUpdate();
    }
    fileUploaded(file) {
        console.log(file);
        var storageRef = firebase.storage();
        var imaageRef = storageRef.ref('support/feedback/' + file);
        var that = this;

        // Get the download URL
        imaageRef.getDownloadURL().then(function (url) {
            that.files.push({ name: that.tempFileName, url: url });
            AppState.OverlayManager.HideLoading();
            that.RCUpdate();
        }).catch(function (error) {
            AppState.OverlayManager.HideLoading();
        });

    }
    renderCustom() {

        let topics = [{
            id: 1,
            label: 'Suggestion'
        },
        {
            id: 2,
            label: 'Bug Report'
        },
        {
            id: 3,
            label: 'Information Request'
        },
        {
            id: 4,
            label: 'Other'
        }
        ];

        var files = (this.files.map((file, i) =>
            <Styled.FileName key={i}>
                {file.name}
                <SC.Icons.Icon_Button w={`16px`} cursorPointer onClick={() => { this.deleteFile(i) }} f1>
                    <SC.Icons.Icon_Delete size={12} />
                </SC.Icons.Icon_Button>
            </Styled.FileName>));
        
        return (
            <SC.FCol alc f1 style={{paddingTop : '96px'}}>
                <Styled.Feedback_Main >
                    <Styled.Form_Description>
                        Your feedback is invaluable to us. If you have problems with your account, want to request a feature, or report a bug, we’re more than happy to help.
                    </Styled.Form_Description>
                    <SC.InnerPanelGroup style={{alignSelf : 'stretch', flex : 'unset', marginTop : '24px', padding : '16px', paddingTop : '8px'}}>
                        <SC.FCol alignStart style={{ width: '100%'}}>
                            <InputForm
                                label="SUBJECT"
                                width={'100%'}
                                value = {this.Subject}
                                placeholder="Let us know how can we help you"
                                ref={(r) => this.Ref_Subject = r}
                                required
                                onChange={this.onSubjectChanged}
                            >
                            </InputForm >
                            <br />
                            <InputForm
                                label="MESSAGE"
                                value = {this.Description}
                                textArea
                                width={'100%'}
                                placeholder="Please include as much information as possible"
                                ref={(r) => this.Ref_Description = r}
                                required
                                onChange={this.onDescriptionChanged}
                            >
                            </InputForm >
                        </SC.FCol>
                        <SC.FRow style={{marginTop : '16px'}}>
                            {files}
                            <Styled.AttachButton

                                onClick={() => ReactDOM.findDOMNode(this.Ref_FileInput).click()}>

                                    <SC.Icons.Icon_Attach size={26} />
                                <div style={{marginLeft:5}}>
                                Attach File
                                </div>
                                <input type="file" style={{ display: 'none' }}
                                    ref={(r) => this.Ref_FileInput = r}
                                    onChange={(file) => this.fileSelected(file)}>
                                </input >
                            </Styled.AttachButton>
                        </SC.FRow>
                    </SC.InnerPanelGroup>
                    <SC.FRow style={{ width: '100%', marginTop: 20 }} justifyEnd>                        
                        <SC.Buttons.RoundButton round style={{marginLeft : '16px'}}  onClick={this.onSend}>
                        Send Your Feedback
                        </SC.Buttons.RoundButton>
                    </SC.FRow>
                    <div style={{ display: 'none' }}>
                        <FileUploader

                            ref={(r) => this.Ref_FileUploader = r}
                            name="Feedback_Attachment"
                            randomizeFilename
                            storageRef={firebase.storage().ref('support/feedback')}
                            onUploadStart={() => AppState.OverlayManager.ShowLoading("Please Wait")}
                            onUploadError={this.handleUploadError}
                            onUploadSuccess={(file) => this.fileUploaded(file)}
                            onProgress={this.handleProgress}
                        />
                    </div>
                </Styled.Feedback_Main>
            </SC.FCol>
        );
    }
}

