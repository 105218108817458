import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    AppLayout,
    Globals
} from '../../../../../../../../../importer';
import { StyleGroupTokenItem, SmallButtonGroup, StyleToken_Color, Seperator, StyleGroupTitle, StyleGroup } from '../../../common';
import { PanelHeaderAddButton } from '../../../../../../left/designsystem/common';
import { SortableList, SortableListItem } from '../../../../../../../../../components/SortableContainer';

export default class GenericCollections extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.AddSlide = this.AddSlide.bind(this);
        this.onSortItems = this.onSortItems.bind(this);

        this.LoadComponent(this.props);
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.Id !== this.props.Id || nextProps.ManagerId !== this.props.ManagerId) {
            this.LoadComponent(nextProps);
            return true;
        }
        if (this.props.component.Id !== nextProps.component.Id) {
            this.LoadComponent(nextProps);
            return true;
        }
        
        if (Utils.HasAnyChange(this.props, nextProps, 'component')) {
            this.LoadComponent(nextProps);
            return true;
        }
            

        if (this.props.GlobalStateId !== nextProps.GlobalStateId)
            return true;        
        return super.ShouldUpdate(nextProps, nextState);
    }    
    LoadComponent(props) {
        this.Slides = this.props.GetComponentManager().GetDesignManager().GetItemProp(this.props.Id, 'Slides') || {};
        if (!this.Slides) {
            const slideId = Utils.Id();            
            this.Slides = {
                dataSource : 'static',                
            }
            this.props.GetComponentManager().GetDesignManager().SetItemProp(this.props.Id, this.Slides, 'Slides');
            const SlideId = this.AddNewSlideItem();
        }

    }
    AddNewSlideItem() {

        const result = this.props.GetComponentManager().GetDesignManager().AddSliderItem({ParentId : this.props.Id});

        const slideItems = Utils.Get(this.Slides, [], 'items');
        slideItems.push(result);
        this.Slides.activeSlide = result.Id;        
        this.props.GetComponentManager().GetDesignManager().SetItemProp(this.props.Id, this.Slides, 'Slides');
        const Designer = AppLayout.Refs.SelectionDesigner.GetItemDesigner(this.props.Id);
        if (Designer) {
            Designer.RefreshGenericItem();
        }
        return result.Id;
    }
    AddSlide() {
        this.AddNewSlideItem();
        this.RCUpdate();
    }
    SelectSlide(slide) {
        this.Slides = this.props.GetComponentManager().GetDesignManager().GetItemProp(this.props.Id, 'Slides') || {};
        this.Slides.activeSlide = slide.Id;
        this.SaveAndUpdate();
    }
    DeleteSlide(slide) {
        this.Slides = this.props.GetComponentManager().GetDesignManager().GetItemProp(this.props.Id, 'Slides') || {};
        const index = Utils.FindIndex(this.Slides.items, (item) => {return item.Id === slide.Id});
        if (index > 0)
            this.Slides.activeSlide = this.Slides.items[index-1].Id;
        else if (this.Slides.items.length > (index + 1)) 
            this.Slides.activeSlide = this.Slides.items[index+1].Id;
        else
            this.Slides.activeSlide = null;
        Utils.Remove(this.Slides.items, (item) => {return item.Id === slide.Id});
        this.props.GetComponentManager().GetDesignManager().DeleteItem(slide.ItemId, true);
        this.SaveAndUpdate(); 
    }
    SaveAndUpdate() {
        this.props.GetComponentManager().GetDesignManager().SetItemProp(this.props.Id, this.Slides, 'Slides');
        const Designer = AppLayout.Refs.SelectionDesigner.GetItemDesigner(this.props.Id);
        if (Designer) {
            Designer.RefreshGenericItem();
        }
        this.RCUpdate();   
    }
    onSortItems(oldIndex, newIndex) {
        Utils.ChangePlace(this.Slides.items, oldIndex, newIndex);
        this.SaveAndUpdate();
    }
    renderCustom() {
        let content;

        const slideItems = [];
        const MetaItem = this.props.GetMetaItem(this.props.Id);
        Utils.ForEach(this.Slides.items, (SlideItem, i) => {
            const selected = this.Slides.activeSlide === SlideItem.Id;
            slideItems.push(
                <SortableListItem
                    key={SlideItem.Id}
                    draggableId={SlideItem.Id}
                    index={i}
                    ItemBoxType={CollectionItem}
                    BoxProps={{
                        item : SlideItem,
                        index : i,
                        selected : selected,
                        onClick : this.SelectSlide.bind(this, SlideItem),
                        onDelete : this.DeleteSlide.bind(this, SlideItem)
                    }}                
                />              
            )
        });
        return (
            <SC.FCol>
                <SC.FRow alc jsb>
                    <StyleGroupTitle>{this.props.title}</StyleGroupTitle>
                    <PanelHeaderAddButton 
                        onAdd={this.AddSlide}
                        title='Add Slide'
                    />
                </SC.FRow>
                <SortableList                            
                    onSort={this.onSortItems}
                >
                    {slideItems}
                </SortableList>                
            </SC.FCol>
        )
    }
}

const CollectionItem = ({sortableProps, index, selected, item, onDelete, onClick}) => {
    return (
        <SC.FRow alc jsb style={{marginTop  :'4px'}}>            
            <StyleGroupTokenItem 
                style={{
                    padding : '8px',
                    paddingTop : '6px',
                    paddingBottom : '6px',
                    flex : 1,
                    marginRight : '8px',
                    ...SC.Styles.Flex.RowAlcJsb
                }}
                onClick={onClick} 
                selected={selected}
            >
                <SC.DragBox first {...sortableProps.handleProps} style={{cursor:'move', height : 'unset', marginRight :'4px', border : 'none'}} onMouseDown={(e) => e.stopPropagation()}>
                    <SC.Icons.DragIcon xsmall />
                </SC.DragBox>
                <div style={{flex : 1}}>
                    Slide {index+1} {item.Id}
                </div>                
            </StyleGroupTokenItem>
            <SC.Icons.Icon_Button hasFill onClick={onDelete}>
                <SC.Icons.Icon_Delete size={18} />
            </SC.Icons.Icon_Button>
        </SC.FRow>  
    )
}