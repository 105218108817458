import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppLayout,
    Links,
    Events,
    Loading,
    Strings,
    Globals,
    MetaData
} from '../../../../../../../importer';
import TextInput from '../../../../../../../components/editors/textinput';
import ToolbarIcons from '../../../../../../../styled/toolbar';
import Switch from '../../../../../../../components/editors/Switch';
import NumberInput  from '../../../../../../../components/editors/input_number';
import { ImageBox } from '../../images';
import VisibilitySensor from 'react-visibility-sensor';

export default class DataRowItem extends ReactBaseComponent
{
    constructor(props) {
        super(props);
        this.onChangeValue = this.onChangeValue.bind(this);
        this.Delete = this.Delete.bind(this);
        this.Refresh = this.Refresh.bind(this);
    }
    onChangeValue(field, value) {
        Globals.ProjectManager.Mockups.SetRowValue(this.props.row.id, field.id, value, Globals.ProjectManager.CurrentState);
        Utils.Set(this.props.row, value, 'data', field.id, Globals.ProjectManager.CurrentState, 'Value');
        this.RCUpdate();
    }
    onSelectImage(field) {
        this.props.onSelectImage(field);
    }
    Delete() {
        this.props.onDelete(this.props.row.id);
    }
    Refresh() {
        this.props.onRefresh(this.props.row.id);
    }
    renderCustom() {
        const fieldItems = [];
        Utils.ForEach(this.props.fields, (field, ) => {
            let value = Globals.ProjectManager.Mockups.GetRecordValueOf(this.props.row.data, field.id);
            
            fieldItems.push(
                <div>{field.name}</div>
            );
            let valueItem;
            if (field.dataType === MetaData.DataTypes.string) {
                valueItem = (
                    <TextInput
                        style={{backgroundColor : SC.CurrentTheme.theme.back, fontSize : '11px', height : '28px'}}
                        value={value}
                        onChange={this.onChangeValue.bind(this, field)}
                        themeId={AppLayout.ThemeId}
                    />  
                )
            }
            else if (field.dataType === MetaData.DataTypes.boolean) {
                valueItem = (
                    <Switch
                        round
                        value={Utils.IsTrue(value)}
                        onChange={this.onChangeValue.bind(this, field)}
                    />  
                )
            }
            else if (field.dataType === MetaData.DataTypes.number) {
                valueItem = (
                    <NumberInput 
                        value={value} 
                        onChange={this.onChangeValue.bind(this, field)}
                        boxStyle={{
                            width : '100px',
                            height : '24px',
                            marginLeft : '4px',
                            marginRight : '16px',
                            backgroundColor : SC.CurrentTheme.theme.input_back
                        }}
                        style={{                                    
                            width : '100%',
                            textAlign : 'center',
                            fontSize : '11px',                
                            padding : 0
                        }}
                    />  
                )
            }
            else if (field.dataType === MetaData.DataTypes.image) {
                const url = Utils.JustGet(value, null, 'url');
                if (Utils.IsNotNullOrEmpty(url)) {
                    valueItem = (
                        <VisibilitySensor 
                            partialVisibility={true} 
                        >
                            {
                                ({isVisible}) => {
                                    if (isVisible || field.isVisible) {
                                        field.isVisible = true;
                                        return (
                                            <ImageBox 
                                                url={url}
                                                style={{height : '80px', backgroundColor : SC.CurrentTheme.theme.back}}
                                                onClick={this.onSelectImage.bind(this, field)}
                                            />
                                        )
                                    }
                                    return (
                                        <div style={{minHeight : '40px', flex : 1}} />
                                    )
                                }                        
                            }
                        </VisibilitySensor> 
                    );
                }
                else {
                    valueItem = (
                        <SC.Div_Flex_Cell fw fh 
                            style={{
                                minWidth : '44px',
                                height : '44px',
                                cursor : 'pointer',
                                backgroundColor : SC.CurrentTheme.theme.back
                            }}
                            onClick={this.onSelectImage.bind(this, field)}
                        >
                            <ToolbarIcons.Image style={{width : '24px'}}/>
                        </SC.Div_Flex_Cell> 
                    )
                }
            }   
            fieldItems.push(valueItem);
        });
        return (
            <div
                style={{
                    display : 'grid',
                    gridTemplateColumns : 'auto 1fr',
                    gridRowGap : '4px',
                    gridColumnGap : '8px',
                    alignItems : 'center',
                    padding : '8px',
                    backgroundColor : SC.CurrentTheme.theme.back_lighter,
                    borderRadius : '4px',
                    border : SC.CurrentTheme.theme.border_seperator,
                    alignContent : 'flex-start',
                    paddingTop : '4px'
                }}
            >            
                <SC.FRow jsb alc justifyEnd style={{gridColumnStart : 1, gridColumnEnd : 3, paddingBottom : '4px'}}>
                    # {this.props.index+1}
                    <SC.FRow>
                        <SC.Icons.Icon_Button hasFill style={{marginRight : '8px', cursor : 'pointer'}} onClick={this.Delete}>
                            <SC.Icons.Icon_Delete size={18} />
                        </SC.Icons.Icon_Button>
                        <SC.Icons.Icon_Button hasFill style={{cursor : 'pointer'}} onClick={this.Refresh}>
                            <SC.Icons.Icon_Refresh size={18} />
                        </SC.Icons.Icon_Button>
                    </SC.FRow>                    
                </SC.FRow>                                          
                {fieldItems}
            </div>          
        )
    }
}
