import ReactDOM from 'react-dom';
import {
    AppState,
    UIUtils,
    Utils,
    Events,
    Strings,
    AppLayout,
    MetaData,
    Globals
} from '../../importer';
import { LOG_TYPES } from '../../appstate/historymanager/base';
import ComponentDesignManager from '../../views/project/component/manager';

const DragDropManager = {
    DragData : null,       
    StartDragItem({Item, x, y, Copy, GetComponentManager}) {
        Events.BCE(Events.DESIGNER.ARTBOARD.ITEM.SELECTEDGUIDE, Events.PARAMS.HIDE);
        this.Suspended = false;       
        AppState.DisableItemSelection = true;

        this.Copy = Copy;
        const DragItem = Utils.DeepClone(Item);
        DragItem.GetComponentManager = GetComponentManager;
        DragItem.SourceId = Item.Id;
        if (Copy)
            DragItem.Id = Utils.Id();
        this.DragData = {
            DragItem : DragItem,            
            Copy : Copy
        };     

        if (DragItem.Type === Strings.TOOLBARITEM) {
            this.DragData.DragItem.Item = Globals.ProjectManager.CreateTempItem(DragItem.Item.MetaItem);
            this.DragData.DragItem.Id = this.DragData.DragItem.Item.Id;
            if (DragItem.Item.MetaItem.Type === 'TokenValue')
                this.DragData.TokenValue = true;         
        }   

        if (DragDropManager.Ref_NewArtBoardItem) {
            DragDropManager.Ref_NewArtBoardItem.BeginDrag(x, y)                
        }
    },
    MoveDragItem(x, y) {
        if (this.DragData && DragDropManager.Ref_NewArtBoardItem) {            
            DragDropManager.Ref_NewArtBoardItem.UpdatePosition(x, y);    
        }
    },
    Dropped() {
        AppState.DisableItemSelection = false;
        if (DragDropManager.Ref_NewArtBoardItem) {
            DragDropManager.Ref_NewArtBoardItem.EndDrag();
        }
        setTimeout(() => {
            AppLayout.Refs.SelectionDesigner && AppLayout.Refs.SelectionDesigner.Show();    
        }, 300);
        
        this.DragData = null;
    },
    ApplyTokenBinding(IsPreview, SetPreview) {
        if (this.DragData.TokenValueOnItemId && ComponentDesignManager.Active().ComponentManager) {
            const MetaItem = ComponentDesignManager.Active().ComponentManager.GetMetaItem(this.DragData.TokenValueOnItemId);
            if (MetaItem) {                        
                const ItemDesigner = ComponentDesignManager.Active().ComponentManager.GetItemDesigner(this.DragData.TokenValueOnItemId);
                if (ItemDesigner) {                            
                    if (ItemDesigner.ChangingStyleValues) {
                        let isProp = false;
                        let parentNames = [];
                        const {TokenType} = DragDropManager.DragData.DragItem.Item.MetaItem;
                        if (TokenType === AppState.ItemTypes.BOARD.COLOR || TokenType === AppState.ItemTypes.BOARD.SIZE) {
                            if (ItemDesigner.ChangingStyleValues) {
                                Utils.ForEach(ItemDesigner.ChangingStyleValues, ({prop, value}, i) => {
                                    ComponentDesignManager.Active().ComponentManager.SetItemToken({
                                        MetaItem : MetaItem, 
                                        Id : this.DragData.TokenValueOnItemId, 
                                        StyleName : prop, 
                                        ParentNames : parentNames || [], 
                                        TokenId : this.DragData.DragItem.Item.MetaItem.Id,
                                        Preview : IsPreview,
                                        SetPreview : SetPreview
                                    });
                                });
                            }                               
                        } 
                        else if (TokenType === AppState.ItemTypes.BOARD.SHADOW) {
                            if (ItemDesigner.ChangingStyleValues) {
                                Utils.ForEach(ItemDesigner.ChangingStyleValues, ({prop, value}, i) => {                                    
                                    ComponentDesignManager.Active().ComponentManager.SetItemToken({
                                        MetaItem : MetaItem, 
                                        Id : this.DragData.TokenValueOnItemId, 
                                        StyleName : prop, 
                                        ParentNames : parentNames || [], 
                                        TokenId : this.DragData.DragItem.Item.MetaItem.Id,
                                        Preview : IsPreview,
                                        SetPreview : SetPreview
                                    });
                                });
                            }                               
                        } 
                        else if (TokenType === AppState.ItemTypes.BOARD.PATTERN_TEXT) {
                            if (Utils.IsOneOf(MetaItem.Type, MetaData.Components.Text.Type, MetaData.Components.Button.Type)) {
                                ComponentDesignManager.Active().ComponentManager.SetItemToken({
                                    MetaItem : MetaItem, 
                                    Id : this.DragData.TokenValueOnItemId, 
                                    StyleName : 'text', 
                                    ParentNames : parentNames || [], 
                                    TokenId : this.DragData.DragItem.Item.MetaItem.Id,
                                    Preview : IsPreview,
                                    SetPreview : SetPreview
                                });
                            }
                        }  
                        if (!IsPreview || IsPreview && !SetPreview)
                            delete ItemDesigner.ChangingStyleValues;   
                    }
                    else if (ItemDesigner.ChangingTextValue) {
                        ComponentDesignManager.Active().ComponentManager.SetItemToken({
                            MetaItem : MetaItem, 
                            Id : this.DragData.TokenValueOnItemId, 
                            StyleName : 'textContent', 
                            ParentNames : parentNames || [], 
                            TokenId : this.DragData.DragItem.Item.MetaItem.Id,
                            Preview : IsPreview,
                            SetPreview : SetPreview
                        });
                    }
                    ComponentDesignManager.Active().ComponentManager.UpdateItemDesigner(this.DragData.TokenValueOnItemId);
                }                
            }                    
        }
    },
    EndDragItem(x, y) {
        if (DragDropManager.Ref_NewArtBoardItem) {
            DragDropManager.Ref_NewArtBoardItem.EndDrag();
        }
        setTimeout(() => {
            AppState.DisableItemSelection = false;
            Events.BCE(Events.DESIGNER.ARTBOARD.ITEM.SELECTEDGUIDE, Events.PARAMS.SHOW);
        }, 200);

        const ItemType = Utils.JustGet(this.DragData, null, 'DragItem', 'Type');
        if (this.DragData) {
            if (this.DragData.TokenValue) {
                this.ApplyTokenBinding();
            }   
            if (this.DragData.OnArtBoard) {
                if (AppLayout.Refs.Artboard.Canvas && AppLayout.Refs.Artboard.Canvas.DropItem) {
                    AppLayout.Refs.Artboard.Canvas.DropItem({
                        Type : 'MetaItem',
                        Data : this.DragData
                    });
                }
                else if (this.DragData.OnArtBoard && !this.DragData.Copy && ItemType === Strings.CHILDITEM) {
                    Events.BCE(Events.DESIGNER.ITEM.DELETE, Utils.Get(this.DragData.DragItem, null, 'Id'))
                    this.DragData.OnArtBoard = false;                             
                    this.DragData = null;
                    return;
                }
            }                     
        }       
        this.DragData = null;
    }
};

export default DragDropManager;
