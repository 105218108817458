import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    AppLayout,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../../../../../importer';

import { GetSoundTokenList, GetSoundTokenListOfIds } from '../../../../../panels/left/designsystem/sounds';
import { SoundTokenPlayerWidget } from '../../../../../panels/left/designsystem/sounds/item';
import { LoadZipper } from '../../../../../publish/steps';

export default class DocumentToken_Sounds extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {

        };    
    }
    shouldComponentUpdate(nextProps, nextState) {

        if (this.state.ShouldUpdate !== nextState.ShouldUpdate)
            return true;
        
        if (Utils.HasAnyChange(this.props, nextProps, 'GlobalThemeId', 'GlobalState', 'StyleId', 'RenderId', 'LoadId', 'themeType', 'states', 'ReversedStyleState'))
            return true;
        
        return false;
    }
    componentDidUpdate(prevProps, prevState) {
        this.state.ShouldUpdate = false;
    }
    
    render() {                        
        const tokens = this.props.selection.type === 'selected' ? GetSoundTokenListOfIds(this.props.selection.tokenIds, this.props.ReversedStyleState) : GetSoundTokenList(this.props.ReversedStyleState);
        const tokenItems = [];

        const icon_style = { width : '60px', minHeight : '60px' }

        Utils.ForEach(tokens, (token, ) => {
                        
            tokenItems.push(
                <SC.FCol>
                    <SoundTokenPlayerWidget 
                        tokenvalue={token}
                        sound={token.data}
                        type={token.category}
                        fileName={token.fileName}  
                        style={{
                            border : 'none',
                            backgroundColor : this.props.textStyles.backgroundHighlight
                        }}
                        hoverStyle={{
                            backgroundColor : 'red'
                        }}
                    />
                    <a href={process.env.PUBLIC_URL + `/assets/sounds/${token.category}/${token.fileName}.m4a`} download={token.fileName}>
                        <SC.FRow alc justifyEnd style={{cursor : 'pointer'}} >
                            <div style={{marginRight : '8px'}} >Download Sound File</div>
                            <SC.Icons.Icon_Button hasFill >
                                <SC.Icons.Download />
                            </SC.Icons.Icon_Button>
                        </SC.FRow>
                    </a>
                    
                </SC.FCol>                
            )
        });

        return (
            <div style={{
                display : 'grid',
                gridGap : '24px',
                gridTemplateColumns : '1fr',
                gridAutoRows : 'auto',
                justifyContent : 'start',
                alignItems : 'center',
                alignSelf : this.props.alignment,
                width : this.props.size ? Utils.px(this.props.size, '%') : 'auto'
            }}
            >
                {tokenItems}
            </div>
        )  
    }
}