
const IS_USED = 'is being used.';

const Strings = {
    DEFAULT : 'Default',
    AUTO : 'Auto',
    NULL : 'null',
    CUSTOM : 'Custom',
    SYSTEM : 'System',
    THEMES : 'Themes',
    VARIABLES : 'Variables',
    ASSETS : 'Assets',
    TAGS : 'Tags',
    LIST : 'List',
    COLORS : 'Colors',
    ICONS : 'Icons',
    FONTS : 'Fonts',
    IMAGES : 'Images',
    GRADIENTS : 'Gradients',
    SHADOWS : 'Shadows',
    SPACE : 'Space',
    SIZES : 'Sizes',
    ANIMATIONS : 'Animations',
    TRANSITIONS : 'Transitions',    
    TRANSITIONID : 'TransitionId',
    VIEWID : 'ViewId',
    SOUNDID : 'SoundId',
    RELATION : 'RelationId',
    SOUNDS : 'Sounds',
    CONTENTS : 'Contents',
    CONTENT_TEXTS : 'ContentItems',
    STATES : 'States',
    PSEUDOSTATES : 'PseudoStates',
    STYLESETS : 'Elements',
    LAYOUTS : 'Layouts',
    LAYOUT : 'MetaLayout',
    STYLESETS_LBL : 'Style Sets',
    API : 'API',
    MODELS : 'Models',
    ACTIONS : 'Actions',
    DEFINITION : 'Definition',
    EVENTHANDLERS : 'Handlers',    
    CONDITIONS : 'Conditions',
    FUNCTIONS : 'Functions',
    VALUES : 'Values',
    VALUE : 'value',
    VARIATIONS : 'Variations',
    ORDER : 'Order',
    HORIZONTAL : 'Horizontal',
    VERTICAL : 'Vertical',
    STATEVARIATION : 'StateVariation',
    PSEUDOSTATEVARIATION : 'PseudoStateVariation',
    STYLE : 'Style',
    PROPERTY : 'Property',
    COMPONENT : 'Component',
    VIEW : 'View',
    TOKEN : 'Token',
    METAITEM : 'MetaItem',
    ELEMENT : 'Element',
    COMPONENTS : 'Components',
    ADD_NEW_TAG : 'Add Tag',
    SELECT_TAG : 'Select Tag',
    CHANGE_TEMPLATE : 'Change Template',
    SELECT_TOKENS : 'Select Tokens',
    FONT_GOOGLE : 'Google Fonts',
    FONT_WEBSAE : 'Websafe Fonts',
    ICON_GOOGLE_MATERIAL : 'Google Material Icons',    
    FIGMA : 'Figma',
    ICONFINDER : 'Iconfinder',
    SOUND_FACEBOOKKIT : 'Facebook Sound Kit',
    DESIGNTOKENS : 'Tokens',
    PROTOTYPES : 'Prototypes',
    PLAYGROUND : 'Playground',
    PUBLISH : 'Publish Manual',
    TEMPLATES : 'Publish Guide',
    SETTINGS : 'Project Settings',
    DESIGN : 'Design System',
    TOP : 'Top',
    LEFT : 'Left',
    BOTTOM : 'Bottom',
    RIGHT : 'Right',
    TOPLEFT : 'TopLeft',
    TOPRIGHT : 'TopRight',
    BOTTOMLEFT : 'BottomLeft',
    BOTTOMRIGHT : 'BottomRight',
    FOREACHCORNER : 'For each corner',
    FOREACHSIDE : 'For each side',
    MINMAXVALUES : 'Min/Max Width & Height',
    AUTOSCALE : 'Auto Scale Component',
    FIXEDSIZE : 'Fixed Component Size',
    PROPERTIES : 'Properties',
    EVENTS : 'Events',
    ADVANCED : 'Advanced',
    INTERACTIONS : 'Interactions',
    ARTBOARDITEM : 'ArtBoardItem',
    CHILDITEM : 'ChildItem',
    TOOLBARITEM : 'ToolbarItem',
    TOKENVALUE : 'TokenValue',
    MESSAGE_ITEM_HAS_LINKS : `Item ${IS_USED}`,
    MESSAGE_ASSET_IS_USED : `Asset ${IS_USED}`,
    MESSAGE_MODEL_IS_USED : `Model ${IS_USED}`,
    MESSAGE_QUERY_DELETE : 'Do you want to delete?',
    STYLESET_IS_USED : `Style Set ${IS_USED}`,
    TAG_IS_USED : `Tag ${IS_USED}`,
    COMPONENT_IS_USED : `Component ${IS_USED}`,
    ITEM_ISLOCKED : 'Locked item can not be deleted',
    SELECT_ALL: 'Select All',
    UNDESELECT_ALL: 'Unselect All',
    SELECT_COMPONENT : 'Select Component',
    Error_Delete : (message) => {
        return `${message}
        You can not delete it.`
    },
    SAVED_SUCCESS : "Changes saved successfully.",
    PASSWORD_RESET_MAIL_SENT : "To change password, we sent an email with a link to Google.",
    Delete_Account : (email)=>{
        return `You are about to delete your account and all projects for ${email} email.`;
    },
    UNITS : {
        px : 'px',
        em : 'em',
        rem : 'rem',
        per : '%',
        vw : 'vw',
        vh : 'vh',
        vmin : 'vmin',
        vmax : 'vmax'
    }

}

export default Strings;