import Utils from '../../../../toolabs-utils';

export default (MetaItem, GenericType, Name) => {
    const RootId = Utils.Id();
    MetaItem.name = Name;
    
    const Component = {            
        RootItem : {
            Id : RootId
        },
        MetaItems : {},
        PreviewSize : {
            width : 24,
            height : 24
        },
        Default: {
            'Designboard': {
                'type': 'grid'
            },
            'height': {
                'value': 24
            },                
            'width': {
                'value': 24
            }
        }
            
    };
    Component.MetaItems[RootId] = MetaItem;

    Component.PseudoStates = {
        Order : ['Checked', 'System', 'disabled', 'hover'],        
        System : {
            name : 'System State',
            Variations : {
                Order : ['active', 'focus'],
                active : {
                    name : 'Active',
                    pseudo : true
                },
                focus : {
                    name : 'Focused',
                    pseudo : true
                },                
            }
        },
        Checked : {
            SingleVariation : true,
            name : 'Checked'
        },
        disabled : {
            SingleVariation : true,
            name : 'Disabled',
            previewable : true,
        },
        hover : {
            SingleVariation : true,
            name : 'Hover',
            pseudo : true
        }
    };

    Component.Handlers = {
        
    };

    Component.Models = {
        List : {
            Checked: {
                dataType: 'boolean',
                name: 'Checked',
                value: false
            },
            Label : {
                dataType : 'string',
                name : 'Label',
                value : 'Option Label'
            }
        },
        Order: ['Label', 'Checked']
    };

    MetaItem.Events = [ 
        
    ];
    Component.TextItemId = Utils.Id();
    
    Utils.MergeTo(MetaItem, {
        Generic : {
            Type : GenericType
        },
        Models : {
            Default : {
                Default : {
                    value : {
                        Id: 'Checked'
                    },
                }
            }
        },
        Property : {
            Default: {
                Checked: {
                    GenericOptions: {
                        checkFill: {
                            value: 'rgba(110, 110, 110, 1)'
                        },
                        checkStrokeWidth: {
                            value: 2
                        }
                    }
                },
                Default : {
                    fixedHeight : {
                        value : true
                    }
                }
            },
            Default: {
                Default: {
                    GenericOptions: {
                        checkFill: {
                            value: 'rgba(110, 110, 110, 0)'
                        },
                        checkSize: {
                            value: 100
                        }
                    },
                    SizeLocked : {
                        value : true
                    },
                    backgroundColor: {
                        value: 'rgba(245, 245, 245, 1)'
                    },
                    borderColor: {
                        value: 'rgba(110, 110, 110, 1)'
                    },
                    borderRadius: {
                        value: 100,
                        Unit : '%'
                    },
                    borderStyle: {
                        value: 'solid'
                    },
                    borderWidth: {
                        value: 2
                    },
                    height: {
                        value: 24
                    },
                    width: {
                        value: 24
                    }
                },
                'disabled,Checked': {
                    GenericOptions: {
                        checkFill: {
                            value: 'rgba(209, 209, 209, 1)'
                        }
                    }
                },
                active: {
                    borderColor: {
                        value: 'rgba(68, 137, 220, 1)'
                    }
                },
                disabled: {
                    borderColor: {
                        value: 'rgba(209, 209, 209, 1)'
                    }
                }
            }
        },
        TextItemId : Component.TextItemId
    });

    
    Component.MetaItems[Component.TextItemId] = {        
        Generic : {
            Type : GenericType,
            SubType : 'Text'
        },
        ParentId: RootId,
        Models : {
            Default : {
                Default : {
                    text : {
                        Id: 'Label'
                    },
                }
            }
        },
        Property: {
            Default: {
                Default: {
                    alignSelf : {
                        value : 'flexCenter'
                    },
                    color : {
                        value : 'rgba(137, 137, 137, 1)'
                    },
                    marginLeft : {
                        value : 8
                    }
                }
            }
        },
        Type: 'Text'
    };
    return Component;
}