export const TARGET_DEFAULTS = {
    yaml : {
        nameFormat : 'kebabCase',
        template : (tokenType) => `${tokenType}:
{{#each tokens}}
  {{formattedName}}: '{{value}}'
{{/each}}
`
    },
    less : {
        nameFormat : 'kebabCase',
        template : (tokenType) => `/* ${tokenType} */
{{#each tokens}}
@{{formattedName}}: {{value}};
{{/each}}
`
    },
    sass : {
        nameFormat : 'kebabCase',
        template : (tokenType) => `/* ${tokenType} */
{{#each tokens}}
\${{formattedName}}: {{value}};
{{/each}}
`
    },
    css : {
        nameFormat : 'kebabCase',
        template : (tokenType) => `/* ${tokenType} */
:root {
{{#each tokens}}
  --{{formattedName}}: {{{value}}};
{{/each}}
}
`
    },
    js : {
        nameFormat : 'constantCase',
        template : (tokenType) => `/* ${tokenType} */
{{#each tokens}}
const {{formattedName}} = "{{{value}}}";
{{/each}}
`
    },
    json : {
        nameFormat : 'camelCase',
        template : (tokenType) => `  "${tokenType}" : [
{{#eachJson tokens}}
  "name" : "{{formattedName}}",
      "value" : "{{{value}}}"
{{/eachJson}}
\n  ]`,
          lookupTemplate : (tokenType) => `{
  "${tokenType}" : {
    {{#each tokens}}
    "{{formattedName}}" : {
      "name" : "{{name}}",
      "value" : "{{{value}}}"
    },
  {{/each}}
  }
}`   
    },
    ios : {
        nameFormat : 'camelCase',
        template : () => `
import UIKit;

extension UIColor {
{{#each tokens}}
  class func {{formattedName}}() -> UIColor {
    return UIColor( red: ({{red}})/255, green: ({{green}})/255, blue: ({{blue}})/255, alpha: ({{alpha}}) );
  }
{{/each}}
}
`,
Contents : () => `
struct ContentStrings {
  {{#each tokens}}
    static let {{formattedName}} = "{{{value}}}";
  {{/each}}
}
`
    },
    android : {
      nameFormat : 'camelCase',
      colors : () => `
<?xml version="1.0" encoding="UTF-8"?>
<resources>
{{#each tokens}}
  <color category="color" name="{{formattedName}}">{{{value}}}</color>
{{/each}}
</resources>
`,
  Shadows : () => `
<?xml version="1.0" encoding="UTF-8"?>
<resources>
{{#each tokens}}
  <property category="shadow" name="{{formattedName}}">{{value}}</property>
{{/each}}
</resources>
`,
Borders : () => `
<?xml version="1.0" encoding="UTF-8"?>
<resources>
{{#each tokens}}
  <property category="border-style" name="{{formattedName}}">{{value}}</property>
{{/each}}
</resources>
`,
BorderRadiuses : () => `
<?xml version="1.0" encoding="UTF-8"?>
<resources>
{{#each tokens}}
  <dimen category="border-radius" name="{{formattedName}}">{{value}}</dimen>
{{/each}}
</resources>
`,
SpacePatterns : () => `
<?xml version="1.0" encoding="UTF-8"?>
<resources>
{{#each tokens}}
  <dimen category="spacing" name="{{formattedName}}">{{value}}</dimen>
{{/each}}
</resources>
`,
Contents : () => `
<?xml version="1.0" encoding="UTF-8"?>
<resources>
{{#each tokens}}
  <property category="text" name="{{formattedName}}">{{value}}</property>
{{/each}}
</resources>
`,
    }
};
