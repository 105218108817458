const GetPresets = function() {
    return [
        {
            group : 'Basic',
            items : [
                {name : 'Ease', path : {x1 : 0.25, y1 : 0.1, x2 :0.25, y2 : 1}},
                {name : 'Linear', path : {x1 : 0, y1 : 0, x2 :1, y2 : 1}},
                {name : 'Ease-In', path : {x1 : 0.42, y1 : 0, x2 :1, y2 : 1}},
                {name : 'Ease-Out', path : {x1 : 0, y1 : 0, x2 :0.58, y2 : 1}},
                {name : 'Ease-In-Out', path : {x1 : 0.42, y1 : 0, x2 :0.58, y2 : 1}}                        
            ]
        },
        {
            group : 'Ease In',
            items : [
                {name : 'in-quad', path : {x1 : 0.550, y1 : 0.085, x2 : 0.680, y2 : 0.530}},
                {name : 'in-cubic', path : {x1 : 0.550, y1 : 0.055, x2 : 0.675, y2 : 0.190}},
                {name : 'in-quart', path : {x1 : 0.895, y1 : 0.030, x2 : 0.685, y2 : 0.220}},
                {name : 'in-quint', path : {x1 : 0.755, y1 : 0.050, x2 : 0.855, y2 : 0.060}},
                {name : 'in-sine', path : {x1 : 0.470, y1 : 0.000, x2 : 0.745, y2 : 0.715}},
                {name : 'in-expo', path : {x1 : 0.950, y1 : 0.050, x2 : 0.795, y2 : 0.035}},
                {name : 'in-circ', path : {x1 : 0.600, y1 : 0.040, x2 : 0.980, y2 : 0.335}},
                {name : 'in-back', path : {x1 : 0.600, y1 : -0.280, x2 : 0.735, y2 : 0.045}},
            ]
        },
        {
            group : 'Ease Out',
            items : [
                {name : 'out-quad', path : {x1 : 0.250, y1 : 0.460, x2 : 0.450, y2 : 0.940}},
                {name : 'out-cubic', path : {x1 : 0.215, y1 : 0.610, x2 : 0.355, y2 : 1.000}},
                {name : 'out-quart', path : {x1 : 0.165, y1 : 0.840, x2 : 0.440, y2 : 1.000}},
                {name : 'out-quint', path : {x1 : 0.230, y1 : 1.000, x2 : 0.320, y2 : 1.000}},
                {name : 'out-sine', path : {x1 : 0.390, y1 : 0.575, x2 : 0.565, y2 : 1.000}},
                {name : 'out-expo', path : {x1 : 0.190, y1 : 1.000, x2 : 0.220, y2 : 1.000}},
                {name : 'out-circ', path : {x1 : 0.075, y1 : 0.820, x2 : 0.165, y2 : 1.000}},
                {name : 'out-back', path : {x1 : 0.175, y1 : 0.885, x2 : 0.320, y2 : 1.275}},
            ]
        },
        {
            group : 'Ease In & Out',
            items : [
                {name : 'in-out-quad', path : {x1 : 0.455, y1 : 0.030, x2 : 0.515, y2 : 0.955}},
                {name : 'in-out-cubic', path : {x1 : 0.645, y1 : 0.045, x2 : 0.355, y2 : 1.000}},
                {name : 'in-out-quart', path : {x1 : 0.770, y1 : 0.000, x2 : 0.175, y2 : 1.000}},
                {name : 'in-out-quint', path : {x1 : 0.860, y1 : 0.000, x2 : 0.070, y2 : 1.000}},
                {name : 'in-out-sine', path : {x1 : 0.445, y1 : 0.050, x2 : 0.550, y2 : 0.950}},
                {name : 'in-out-expo', path : {x1 : 1.000, y1 : 0.000, x2 : 0.000, y2 : 1.000}},
                {name : 'in-out-circ', path : {x1 : 0.785, y1 : 0.135, x2 : 0.150, y2 : 0.860}},
                {name : 'in-out-back', path : {x1 : 0.680, y1 : -0.550, x2 : 0.265, y2 : 1.550}}
            ]
        }
    ];
}
export default GetPresets;