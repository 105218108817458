import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    AppLayout,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../../../../importer';
import styled, { css} from 'styled-components';

import SlateEditor from './editor';


export default class DocumentItem_Text extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {
            editing : false
        };

        this.Ref_Editor = React.createRef();

        this.onChange = this.onChange.bind(this);      
        this.onReloadValue = this.onReloadValue.bind(this);
        this.ShowColorEditor = this.ShowColorEditor.bind(this);
        
        this.EditorId = this.props.StyleId;
        this.Load(this.props.pageId, this.props.id);
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.RenderId !== nextProps.RenderId) {
            this.Load(nextProps.pageId, nextProps.id);
            return true;
        }
        if (this.props.StyleId !== nextProps.StyleId || this.props.themeId !== nextProps.themeId) {
            this.EditorId = Utils.Id();
            return true;
        }

        if (Utils.HasAnyChange(this.props, nextProps, 'preview', 'GlobalThemeId', 'GlobalState'))
            return true;
        if (this.state.ShouldUpdate !== nextState.ShouldUpdate)
            return true;        
        return true;
    }
    Load(pageId, id) {
        this.data = Globals.ProjectManager.GetDocumentManager().GetPageItem(pageId, id);
        this.type = Utils.JustGet(this.data.data, 'paragraph', 'tag');       
    }
    componentDidUpdate(prevProps, prevState) {
        this.state.ShouldUpdate = false;
        if (this.props.selected !== prevProps.selected) {
            setTimeout(() => {
                this.setState({editing : this.props.selected});
            }, 100);
        }
    }
    onChange(value) {
        Utils.Set(this.data.data, Utils.DeepClone(value), 'content');
        // this.data.data.content = PlainSlateTextParser.serialize(value);
        Globals.ProjectManager.GetDocumentManager().SetItem(this.props.id, this.data);
        this.setState({
            value : value,
            ShouldUpdate : true
        })             
    }
    onReloadValue(value) {
        this.EditorId = Utils.Id();
        this.onChange(value);
    }
    Update() {
        this.setState({
            ShouldUpdate : true
        })
    }    
    ShowColorEditor() {
        this.props.onShowColorEditor(this.props.id);
    }
    render() {        

        const value = Utils.JustGet(this.data.data, [
            {
                type: this.type,
                children: [{ text: '' }]
            }
        ], 'content');

        // return (
        //     <div>Text Item : {JSON.stringify(value)}</div>
        // )

        return (
            <SlateEditor 
                key={this.EditorId}
                id={this.props.id}
                value={value}
                onChange={this.onChange}
                onReloadValue={this.onReloadValue}
                heroItem={this.props.heroItem}
                textStyles={this.props.textStyles}
                StyleId={this.props.StyleId}
                themeId={this.props.themeId}
                selected={this.props.selected}
                editing={this.state.editing}
                preview={this.props.preview}
                shared={this.props.shared}
                onSuspendToolbar={this.props.onSuspendToolbar}
                toolbarOnRight={this.props.layoutItem && this.props.layoutItem.right}
                autoFocus={this.props.autoFocus}
                onShowColorEditor={this.ShowColorEditor}
                hidden={this.props.hidden}
                onChangeHidden={this.props.onChangeHidden}
            />
        )
    }
}