import React from 'react';
import ReactDOM from 'react-dom';

import {
    MetaData,
    Utils,
    UIUtils,
    SC,
    Strings,
    AppLayout
} from '../../../../../../../importer';

import BaseGeneric from '../base';
import { onGetChildItem } from '../../common';

export default class Generic_Toggle extends BaseGeneric {
    constructor(props) {
        super(props);        
    }
    renderContent({style, props}) {      
        const MetaItem = this.props.GetComponentManager().GetMetaItem(this.props.Id);
        if (!MetaItem) {
            console.log('ASSERT : Null MetaItem');
            return null;
        }
        let ThumbItem;
        const ThumbId = Utils.JustGet(MetaItem.Generic, null, 'ThumbId');        
        if (ThumbId) {
            ThumbItem = onGetChildItem({
                ParentDesigner : this,
                ParentProps : this.props,
                ChildItemId : ThumbId,
                childProps : {
                    
                }
            })
        }

        return (
            <SC.Div_Relative style={style} {...props}>
                {ThumbItem}
            </SC.Div_Relative>
        )
    }
}