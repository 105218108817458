import MetaData from '../toolabs-metaui';
import Utils from '../toolabs-utils';
import PublishMetaItem from './metaitem';
import PublishPrototype from './prototype';
//todo
const Publish_Document_Template = function(Template, Board, Platform, MetaDataVersion) {    
    return PublishPrototype(Template, Board, Platform, MetaDataVersion);
    
    let ConvertedTemplate = {
        Name : Utils.ToCamelCase(Utils.Get(Template.Data, Template.Model.name || Template.Model.Name || Template.Model._id, 'Published', 'Name')),
        Id : Template.Model._id,
        MetaDataVersion : MetaDataVersion,
        MainViewId : Template.Data.MainViewId,
        Views : {

        },
        Default : {

        }
    };

    Utils.ForEach(Template.Data.Views, (View, ViewId) => {
        const ConvertedView = {
            name : View.name
        };

        const PublishContext = {
            Platform : Platform,
            MetaItems : Utils.UseNull(View.Design.MetaItems, []),
        };
        ConvertedView.RootView = PublishMetaItem(PublishContext.MetaItems[View.Design.RootId], View.Design.RootId, PublishContext, true);

        ConvertedTemplate.Views[ViewId] = ConvertedView;
    });
   

    // Public
    ConvertedTemplate.Public = {

    };
    
    return ConvertedTemplate;
}

export default Publish_Document_Template;