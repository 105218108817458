import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppLayout,
    Links,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../../importer';

import TextInput from '../../../../components/editors/textinput';
import TextValue from '../../../../components/editors/textvalue';


export default class CusotmHandlerbarsBlock extends ReactBaseComponent
{
    constructor(props) {
        super(props);
    }
    AddCustomTemplateBlock() {
        this.props.blocks.push({
            name : '',
            code : ''
        });
        this.props.onSave(this.props.blocks);
    }
    onChangeName(block, name) {
        block.name = name;
        this.props.onSave(this.props.blocks);
    }
    onChangeCode(block, code) {
        block.code = code;
        this.props.onSave(this.props.blocks);
    }
    DeleteBlock(i) {
        this.props.blocks.splice(i, 1);
        this.props.onSave(this.props.blocks);
    }
    renderCustom() {
        const blocks = this.props.blocks;
        return (
            <SC.FCol style={this.props.style}>
                <SC.FRow alc jsb >
                    <div>CUSTOM HELPER BLOCKS</div>
                    <SC.Icons.Icon_Button onClick={this.AddCustomTemplateBlock.bind(this)} hasFill style={{cursor : 'pointer'}}>
                        <SC.Icons.Icon_Plus size={24} />
                    </SC.Icons.Icon_Button>
                </SC.FRow>
                {
                    blocks.map((block, i) => {
                        return (
                            <div
                                key={i}
                                style={{
                                    display : 'grid',
                                    gridTemplateColumns : 'auto 1fr',
                                    gridRowGap : '4px',
                                    gridColumnGap : '8px',
                                    alignItems : 'center',
                                    padding : '8px',
                                    backgroundColor : SC.CurrentTheme.theme.back_lighter,
                                    borderRadius : '4px',
                                    border : SC.CurrentTheme.theme.border_seperator,
                                    alignContent : 'flex-start',
                                    paddingTop : '4px',
                                    marginTop : '8px'
                                }}
                            >            
                                <div>Name</div>
                                <SC.FRow alc jsb>
                                    <TextInput
                                        style={{fontSize : '12px', height : '28px'}}
                                        dark
                                        value={block.name}
                                        onChange={this.onChangeName.bind(this, block)}
                                        themeId={AppLayout.ThemeId}
                                    />  
                                    <SC.Icons.Icon_Button hasFill style={{marginLeft : '8px', marginRight : '4px', cursor : 'pointer'}} onClick={this.DeleteBlock.bind(this, i)}>
                                        <SC.Icons.Icon_Delete size={18} />
                                    </SC.Icons.Icon_Button>   
                                </SC.FRow>
                                
                                <div>Code</div>
                                <TextValue 
                                    key={this.props.RenderId}
                                    dark
                                    justEditor
                                    multiline 
                                    value={block.code}
                                    boxStyle={{height : '100%'}}
                                    style={{color : SC.CurrentTheme.theme.font, fontSize : '13px', lineHeight : '22px', letterSpacing : '0.1em', height : '100%'}}
                                    onBlured={this.onChangeCode.bind(this, block)}
                                />
                            </div> 
                        )
                    })
                }
            </SC.FCol>
        )
    }

}