import {
    AppState,
    Events,    
    Utils,
    Globals
} from '../../../importer';
import chroma from 'chroma-js';
import {FontLoader} from '../../../toolabs-importer';
import Strings from '../../../appstate/Strings';

const GetData = () => {
    return Utils.Get(Globals.ProjectManager.Data, {}, 'Data');
}

const TemplatesManager =  {
    Types : {
        Container : 'Container',
        Text : 'Text',
        Icon : 'Icon',
        Grid : 'Grid'
    },          
    Order(type) {
        return Utils.JustGet(GetData(), [], 'templates', type, 'order');
    },
    List() {
        return Utils.JustGet(GetData(), {}, 'templates', 'list');
    },
    Template(id) {        
        return Utils.JustGet(GetData(), null, 'templates', 'list', id);
    },
    DefaultTemplate(type) {
        const Id = this.DefaultTemplateId(type);
        let model = this.Template(Id);
        if (!model) {
            this.Add({type : type, id : Id, name : 'Default'});
        }
        return this.Template(Id);
    },
    DefaultTemplateId(type) {
        return 'Default' + type;;
    },
    Add({type, name, value, id, ...rest}) {        
        const useId = id || Utils.Id();
        const order = this.Order(type);
        order.push(useId);                
        Globals.ProjectManager.DataManager.Set(order, 'templates', type, 'order');
        const model = {
            name : name,
            value : {
                Default : {
                    value : value
                }
            },
            ...rest
        };
        Globals.ProjectManager.DataManager.Set(model, 'templates', 'list', useId);
        return useId;
    },
    Clone(id, type) {
        const source = this.Template(id);
        const clone = Utils.DeepClone(source);
        clone.name = 'Copy of ' + clone.name;
        
        const useId = Utils.Id();
        const order = this.Order(type);
        order.push(useId);                
        Globals.ProjectManager.DataManager.Set(order, 'templates', type, 'order');        
        Globals.ProjectManager.DataManager.Set(clone, 'templates', 'list', useId);
        return useId;
    },
    Delete(type, id) {
        if (Globals.ProjectManager.RelationManager.HasRelation(id)) {
            Events.AlertSimple(Strings.Error_Delete(Strings.MESSAGE_ITEM_HAS_LINKS), Events.GLOBAL.NOTIFICATION.TYPES.WARNING);
            return false;
        }        

        const model = this.Template(id);
        const order = this.Order(type);
        Utils.RemoveEquals(order, id);
        Globals.ProjectManager.DataManager.Set(order, 'templates', type, 'order');
        Globals.ProjectManager.DataManager.Delete('templates', 'list', id);
        Globals.ProjectManager.RelationManager.DeleteOwner(id);        
        return true;
    },
    ChangeOrder(type, oldIndex, newIndex) {
        const order = this.Order(type);
        Utils.ChangePlace(order, oldIndex, newIndex);
        Globals.ProjectManager.DataManager.Set(order, 'templates', type, 'order');
    },
    ValueOf({model, defaultValue, name = 'value', state, statearray}) {
        let value;
        if (state) {
            return  Utils.JustGet(model, null, name, state, 'value');
        }
        const UseStateArray = statearray || Globals.ProjectManager.ReversedStyleState;
        Utils.ForEach(UseStateArray, (State, i) => {
            let statevalue = Utils.JustGet(model, null, name, State, 'value');
            if (Utils.IsNotNullOrEmpty(statevalue)) {
                value = statevalue;
                return false;
            }
        });
        return value;
    },
    ValueOfId(Id, StateArray) {
        return Globals.ProjectManager.Templates.ValueOf({
            model : Globals.ProjectManager.Templates.Template(Id),
            statearray : StateArray
        })
    },
    GetStateValue({Id, name = 'value', StateArray = Globals.ProjectManager.ReversedStyleState}) {
        const model = this.Template(Id);
        if (model) {
            let value;
            Utils.ForEach(StateArray, (GlobalState, i) => {
                let statevalue = Utils.JustGet(model, null, name, GlobalState, 'value');
                if (Utils.IsNotNullOrEmpty(statevalue)) {
                    value = statevalue;
                    return false;
                }
            });
            return value;
        }
    },
    GetParentStateValue({model, CurrentState, name, prop}) {
        if (model) {
            let value;
            const startIndex = Globals.ProjectManager.ReversedStyleState.indexOf(CurrentState)
            if (startIndex >= 0) {
                for (let i=startIndex + 1; i<Globals.ProjectManager.ReversedStyleState.length;  i++) {
                    let statevalue = Utils.JustGet(model, null, name, Globals.ProjectManager.ReversedStyleState[i], 'value');    
                    if (Utils.IsNotNullOrEmpty(statevalue)) {
                        value = statevalue;
                        break;
                    }
                }
            }
            return value;
        }
    },
    SetValue({id, value, name = 'value', offline, state, type, ...rest}) {
        if (offline) {
            Utils.Set(GetData(), value, 'templates', 'list', id, name, state || Globals.ProjectManager.CurrentState, 'value' )
        } 
        else
            Globals.ProjectManager.DataManager.Set(value, 'templates', 'list', id, name, state || Globals.ProjectManager.CurrentState, 'value');
    },    
    SetSubValue({id, value, name = 'value', prop}) {
        Globals.ProjectManager.DataManager.Set(value, 'templates', 'list', id, name, Globals.ProjectManager.CurrentState, 'value', prop);
    },
    SetValueOf({model, value, name = 'value', state, subProps}) {
        const UseState = state || Globals.ProjectManager.CurrentState;
        const parentValue = this.GetParentStateValue({model : model, name : name, CurrentState : UseState});
        if (parentValue === value) {
            this.DeleteValueOf({model : model, name : name});
            return;
        }
        Utils.Set(model, value, name, UseState, 'value' );
    },
    UpdateProp({id, name, value}) {
        Globals.ProjectManager.DataManager.Set(value, 'templates', 'list', id, name);
    },
    SaveModel(type, id, model) {
        Globals.ProjectManager.DataManager.Set(model, 'templates', 'list', id);
    },
    SetName(id, name) {
        this.UpdateProp({id : id, name : 'name', value : name});
    },
    SetNote(id, note) {
        this.UpdateProp({id : id, name : 'note', value : note});
    },
    // Token Ids
    GridTemplates() {
        return this.Order(this.Types.Grid);
    },
    TokenList(type) {
        const ids = this.Order(type);
        const itemlist = {};
        Utils.ForEach(ids, (id) => {
            itemlist[id] = this.Token(id);
        });
        return itemlist;
    },    
    
};
export default TemplatesManager;