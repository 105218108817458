import React from 'react';
import BaseItem from '../BaseItem';
import {Utils, MetaData} from '../../importer';

import Widget_Video from './Video';
import Widget_Spotify from './Spotify';
import Widget_MapBox from './MapBox';
import Widget_EChart from './ECharts';
import Widget_ApexChart from './ApexCharts';
import Widget_Lottie from './Lottie';

const WidgetTypes = MetaData.Components.Widget.WidgetTypes;

export default class Widget extends BaseItem
{
    constructor(props) {
        super(props);
    }    
    renderItem({style, props, events, rest}) {

        let WidgetItem;        
        const WidgetType = props.Type;

        if (WidgetType === WidgetTypes.Video.Type) {
            WidgetItem = (
                <Widget_Video 
                    {...props} 
                    style={style} 
                />
            )
        }
        else if (WidgetType === WidgetTypes.Spotify.Type) {
            WidgetItem = (
                <Widget_Spotify {...props} style={style} />
            )
        }
        else if (WidgetType === WidgetTypes.MapBox.Type) {
            WidgetItem = (
                <Widget_MapBox 
                    {...props} style={style}                     
                />
            )
        }
        else if (WidgetType === WidgetTypes.eCharts.Type) {
            WidgetItem = (
                <Widget_EChart {...props} style={style} />
            )
        }
        else if (WidgetType === WidgetTypes.ApexCharts.Type) {
            WidgetItem = (
                <Widget_ApexChart 
                    {...props} style={style} style={style} 
                />                    
            )
        }
        else if (WidgetType === WidgetTypes.Lottie.Type) {
            WidgetItem = (
                <Widget_Lottie {...props} style={style} />
            )
        }

        
        return (
            <div
                style={{...style, boxSizing : 'border-box',  position:'relative', overflow:'hidden'}}
                {...events}
                {...rest}
            >
                {WidgetItem || WidgetType}
            </div>
        );
    }
}