import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    MetaData,
    Globals
} from '../../../../../../../importer';
import { GetIconTokenValueItem } from '../../../../left/designsystem/icons';
import { IconBox } from '../../../../left/designsystem/icons/item';
import { StyleGroupTokenItem, StyleToken_Color } from '../common';
import VariableBinder from '../variable';

export default class ItemIcon extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.SelectModel = this.SelectModel.bind(this);
        this.SetModelBinding = this.SetModelBinding.bind(this);
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (Utils.HasAnyChange(this.props, nextProps, 'Id', 'ManagerId', 'GlobalStateId', 'GlobalState', 'icon', 'color'))
            return true;        

        return super.ShouldUpdate(nextProps, nextState);
    }
    SelectModel() {
        Events.BCE(Events.DESIGNER.BOARD.ITEM.SELECT_MODEL, {
            title : 'ICON',
            dataType : MetaData.DataTypes.icon,
            onSelect : this.SetModelBinding,
            tokenId : this.props.icon.TokenId
        });
    }
    SetModelBinding(modelId) {
        this.props.onBindToModel({
            PropertyName : 'icon',
            ModelId : modelId
        });
        this.RCUpdate();
    }
    renderCustom() {
        let valueName;
        let isEmpty = true;
        let iconPreview, hasFillColor;
        
        const modelId = Utils.JustGet(this.props.modelId, null, 'ModelId');
        if (modelId) {
            const model = this.props.GetComponentManager().Models.GetModel(modelId);
            valueName = model.name;
            isEmpty = false;
            if (model.TokenId) {
                const iconResult = RenderIconTokenPreview({TokenId : model.TokenId, StateArray : this.props.StateArray});
                hasFillColor = iconResult.hasFillColor;
                iconPreview = iconResult.preview;
            }
        }
        else if (this.props.icon.TokenId) {
            const tokenModel = Globals.ProjectManager.Tokens.Token(this.props.icon.TokenId);
            if (tokenModel) {
                valueName = tokenModel.name;   
                const iconResult = RenderIconTokenPreview({TokenId : this.props.icon.TokenId, StateArray : this.props.StateArray});
                hasFillColor = iconResult.hasFillColor;
                iconPreview = iconResult.preview;
                isEmpty = false;
            }            
        }
        else {
            hasFillColor = this.props.hasFillColor;
        }


        return (
            <SC.FCol style={{marginTop : '8px'}}>
                <IconTokenSelectItem 
                    isEmpty={isEmpty}
                    name={valueName}                    
                    tokenId={this.props.icon.TokenId}
                    onSelectToken={this.props.onSelectIcon}
                    modelId={modelId}
                    onSelectModel={this.SelectModel}
                >
                    {iconPreview}
                </IconTokenSelectItem>
                {
                    hasFillColor && 
                    <StyleToken_Color
                        tokenId={this.props.color.TokenId} 
                        onSelect={this.props.onSelectColor} 
                        label='Fill Color'
                    />
                }                
            </SC.FCol>             
        )       
    }
}

export const IconTokenSelectItem = (props) => {
    return (
        <SC.FRow style={props.style}>
            <StyleGroupTokenItem 
                style={{flex : 1, padding : 0, ...SC.Styles.Flex.Row}}
                onClick={props.onSelectToken}
            >
                <SC.FCol overflowHidden f1 style={{justifyContent : 'space-evenly', padding : '8px', paddingTop : '4px', paddingBottom : '4px'}}>
                    <SC.TextDivAbbr style={{flex : 1, fontWeight : 400}}>
                        {props.label || 'Icon'}
                    </SC.TextDivAbbr>
                    <SC.TextDivAbbr style={{marginTop : props.isEmpty ? 0 : '8px', fontSize : '11px'}}>
                        {props.name}
                    </SC.TextDivAbbr>
                </SC.FCol>
                {
                    !props.isEmpty ?
                    <SC.GridBackground small dark={!SC.CurrentTheme.theme.isLight} style={{display : 'flex', alignItems : 'center', margin : '2px', borderRadius : '2px', overflow : 'hidden'}} >
                        {props.children}      
                    </SC.GridBackground>
                    : 
                    <SC.Icons.Icon_Button hasFill style={{paddingRight : '4px'}}>
                        <SC.ToolbarStyles.Icon size={20} />
                    </SC.Icons.Icon_Button>
                }
            </StyleGroupTokenItem>   
            <VariableBinder 
                modelId={props.modelId}
                onSelect={props.onSelectModel}
            />
        </SC.FRow>
    )
}

export const RenderIconTokenPreview = (props) => {
    const tokenModel = Globals.ProjectManager.Tokens.Token(props.TokenId);
    const token = {};
    GetIconTokenValueItem(tokenModel, token, props.StateArray);
    token.name = tokenModel.name;    
    const icon_style = {
        width : '60px', height : '40px'
    };
    let hasFillColor, iconPreview;
    if (Utils.IsNotNullOrEmpty(token.url)) {
        iconPreview = (
            <IconBox                         
                url={token.url}
                style={icon_style}
            />
        )                     
    }    
    else if (token.paths) {
        hasFillColor = true;
        iconPreview = (
            <IconBox                        
                url={token.url}
                style={icon_style}
        >
                <SC.Icons_Preview compact xmlns='http://www.w3.org/2000/svg' viewBox={`0 0 24 24`} width='80%' dark={SC.CurrentTheme.theme.isLight} >
                    {token.paths}
                </SC.Icons_Preview>    
            </IconBox>
        )
    }  
    return {
        preview : iconPreview,
        hasFillColor : hasFillColor,
        name : tokenModel.name
    }
}