import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    UIUtils,
    Globals
} from '../../../../../../../importer';
import { StyleGroupTokenItem, SmallButtonGroup, ToggleButton, StyleGroupTitle, PropertyLabel } from '../common';
import { FontFamilyPreview } from '../../../../left/designsystem/typography/fonts';
import TextValue from '../../../../../../../components/editors/textvalue';
import VariableBinder, {RefreshMockupValueButton} from '../variable';
import MetaData from '../../../../../../../../toolabs-modules/toolabs-metaui';
import TextParameters from './parameters';

export default class TextContent extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.SelectModel = this.SelectModel.bind(this);
        this.SetModelBinding = this.SetModelBinding.bind(this);
        this.SetValue = this.SetValue.bind(this);
        this.SelectTextToken = this.SelectTextToken.bind(this);        
        this.ToggleTextSource = this.ToggleTextSource.bind(this);

        this.isModel = Utils.JustGet(this.props.modelId, null, 'ModelId') ? true : false;
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.Id !== this.props.Id || nextProps.ManagerId !== this.props.ManagerId) {
            this.isModel = Utils.JustGet(nextProps.modelId, null, 'ModelId') ? true : false;
            return true;
        }
        if (this.props.GlobalStateId !== nextProps.GlobalStateId)
            return true;
        if (Utils.HasAnyChange(this.props, nextProps, 'textContent', 'modelId'))
            return true;

        return super.ShouldUpdate(nextProps, nextState);
    }    
    SelectModel() {
        const modelId = Utils.JustGet(this.props.modelId, null, 'ModelId');
        Events.BCE(Events.DESIGNER.BOARD.ITEM.SELECT_MODEL, {
            title : 'TEXT CONTENT',
            selectedId : modelId,
            dataType : MetaData.DataTypes.string,
            onSelect : this.SetModelBinding,
            initialValue : this.FinalValue
        });
    }
    SetModelBinding(modelId) {
        this.props.onBindToModel({
            PropertyName : 'textContent',
            ModelId : modelId
        })
        if (modelId)
            this.isModel = true;
        if (this.props.textContent.TokenId && modelId) {
            this.props.SetTokenBinding('textContent', this.props.Id, null);
        }
        
        this.RCUpdate();
    }
    SetValue(value) {
        const modelId = Utils.JustGet(this.props.modelId, null, 'ModelId');
        if (modelId) {
            this.props.GetComponentManager().Models.SetValue(modelId, value);
        }
        else if (this.props.textContent.TokenId) {
            Globals.ProjectManager.Tokens.SetValue({id : this.props.textContent.TokenId, value : value});
            Events.BroadcastThrottle_50(Events.GLOBAL.TOKEN_VALUE_CHANGING, [{
                Id : this.props.textContent.TokenId,
                Type : Globals.ProjectManager.Tokens.Types.ContentTexts,
                value : value
            }]);
            this.RCUpdate();
        }
        else {
            this.props.GetComponentManager().GetDesignManager().SetItemTextContent({            
                Id : this.props.Id,
                Value : value
            });  
        }
    }
    ToggleTextSource(isModel) {
        if (isModel === this.isModel)
            return;
                    
        if (!this.isModel) {
            this.SelectModel();            
        }
        else {
            this.SelectTextToken();
        }
        this.RCUpdate();
    }
    SelectTextToken() {
        this.props.onSelectContent(null, (tokenId) => {
            if (!tokenId) {
                
            }
            else {
                const modelId = Utils.JustGet(this.props.modelId, null, 'ModelId');        
                if (modelId) {
                    this.props.onBindToModel({
                        PropertyName : 'textContent',
                        ModelId : null
                    })
                }                    
                this.isModel = false;
                this.props.SetTokenBinding('textContent', this.props.Id, tokenId);
            }
        }, this.FinalValue);
    }
    RefreshModelMockup(modelId, dataId) {
        const value = Globals.ProjectManager.Mockups.GetRandomValue(dataId);
        this.props.GetComponentManager().Models.SetValue(modelId, value);
        this.RCUpdate();
    }
    renderCustom() {
        let isEmpty = true, valueName, textItem;
        if (this.isModel) {
            let refreshMockup;
            const modelId = Utils.JustGet(this.props.modelId, null, 'ModelId');
            if (modelId) {
                this.FinalValue = this.props.GetComponentManager().GetItemTextValue({MetaItem  :this.props.GetMetaItem(), Id : this.props.Id, PropertyName : 'textContent'});
                const model = this.props.GetComponentManager().Models.GetModel(modelId);
                if (model) {
                    valueName = model.name;
                    isEmpty = false;

                    if (model.DataId) {
                        refreshMockup = (
                            <RefreshMockupValueButton onRefresh={this.RefreshModelMockup.bind(this, modelId, model.DataId)} />
                        )
                    }
                }    
            }

            textItem = (
                <SC.FRow alc jsb style={{marginTop : '8px', marginBottom : '4px'}}>
                    <StyleGroupTokenItem 
                        style={{flex : 1, padding : '8px', paddingTop : '4px', paddingBottom : '4px'}}
                        onClick={this.SelectModel}
                    >
                    <PropertyLabel 
                            hasValue={!isEmpty}
                            style={{flex : 1}}
                        >
                            {valueName || 'Select Text Variable'}
                        </PropertyLabel>                               
                    </StyleGroupTokenItem>
                    {refreshMockup}
                </SC.FRow>                
            )
        }
        else {
            if (this.props.textContent.TokenId) {
                const tokenModel = Globals.ProjectManager.Tokens.Token(this.props.textContent.TokenId);
                if (tokenModel) {
                    valueName = tokenModel.name;            
                    isEmpty = false;
                    this.FinalValue = Globals.ProjectManager.Tokens.ValueOf({model : tokenModel});
                }
            }      
            else {
                this.FinalValue = Utils.JustGet(this.props.text, '', 'Value');
            }   
            if (!this.props.optional || !isEmpty) {
                textItem = (
                    <StyleGroupTokenItem 
                        style={{flex : 1, padding : '8px', paddingTop : '4px', paddingBottom : '4px', marginTop : '8px', marginBottom : '4px'}}
                        onClick={this.SelectTextToken}
                    >
                       <PropertyLabel 
                            hasValue={!isEmpty}
                            style={{flex : 1}}
                        >
                            {valueName || 'Select Text Token'}
                        </PropertyLabel>                    
                    </StyleGroupTokenItem>
                )
            }
            
        }

        return (
            <React.Fragment>
                <SC.FRow alc jsb>
                    <StyleGroupTitle>TEXT CONTENT</StyleGroupTitle>
                    <SC.FRow justifyEnd>
                            <ToggleButton 
                                style={{marginRight : '4px'}} 
                                selected={!this.isModel}
                                onClick={this.ToggleTextSource.bind(this, false)}
                            >
                                Token
                            </ToggleButton>
                            <ToggleButton selected={this.isModel} onClick={this.ToggleTextSource.bind(this, true)}>Variable</ToggleButton>
                        </SC.FRow>                   
                </SC.FRow>
                {textItem}
                <TextValue 
                    key={this.props.textContent.TokenId || this.props.Id}
                    dark
                    multiline 
                    value={this.FinalValue}
                    onChange={this.SetValue}
                />
            </React.Fragment>
        )
    }
}

export const TextContentPropertyEditor = (props) => {
    let mockupRefresh;
    if (props.onRefreshMockup) {
        mockupRefresh = (
            <SC.Icons.Icon_Button 
                hasFill 
                style={{marginLeft : '8px', cursor : 'pointer'}} 
                title='Generate New Mockup Value'
                onClick={props.onRefreshMockup}
            >
                <SC.Icons.Icon_Refresh 
                    size={20}                            
                />
            </SC.Icons.Icon_Button>
        )
    }
    return (
        <SC.FCol style={{...props.style}}>
            <SC.FRow style={{marginBottom : '4px'}}>
                <StyleGroupTokenItem style={{flex : 1, padding : '8px', paddingTop : '4px', paddingBottom : '4px'}} onClick={props.onSelectContent}>
                    <PropertyLabel 
                        hasValue={props.value !== null}
                        menuOptions={{
                            onShowValueMenu : props.onShowValueMenu,
                            propName : 'hidden',
                            isProp : true
                        }}
                    >{props.label}</PropertyLabel>
                </StyleGroupTokenItem>
                <VariableBinder 
                    modelId={props.modelId}
                    onSelect={props.onSelectModel}
                />
                {mockupRefresh}
            </SC.FRow>
            <TextValue 
                key={props.Id}
                dark
                multiline 
                value={props.value}
                onChange={props.onChange}
            />
        </SC.FCol>   
    )
}