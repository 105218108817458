import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppLayout,
    Links,
    Events,
    MetaData,
    Strings,
    Globals
} from '../../../../../../importer';

import { GetColorTokenListOfIds, ColorTokenListItem, GetGradientTokenListOfIds, GetColorTokenItem } from '../colors';
import { ShadowListItem, GetShadowTokenListOfIds, GetShadowTokenItem } from '../shadows';
import { GetSpacePatternItem, GetSpacingPatternListOfIds, SpacePattern } from '../spacing/patterns';
import { GetTransformTokenListOfIds, TransformListItem } from '../transforms';
import { GetFilterTokenListOfIds, FilterListItem, GetFilterTokenItem } from '../filters';
import { GetTextContentTokenListOfIds, TextContentListItem } from '../textContent';
import { BorderStyleTokenItem, GetBorderStyleTokenListOfIds, BorderRadiusTokenItem, GetUnsortedBorderRadiusTokenListOfIds, GetBorderStyleTokenValue, GetBorderRadiusTokenValue  }from '../borders';
import { IconListItem, GetIconTokenListOfIds } from '../icons';
import { IconBox } from '../icons/item';
import { GetTextPatternListOfIds, GetTextPatternTokenItem, TextPattern } from '../typography/patterns';
import { GetFontItemListOfIds, FontSelectItem } from '../typography/fonts';
import { GetDurationPatternListOfIds, DurationPattern, GetDurationTokenItemOfId } from '../motion/patterns';
import { GetEaseCurveTokenListOfIds, EasePattern, GetEaseCurveTokenItem } from '../motion/easing';
import { GetTransitionTokenListOfIds, TransitionListItem } from '../motion/transitions';
import { GetTokenAliaseListOfIds, ColorTokenAliaseListItem, ShadowTokenAliaseListItem, BorderTokenAliaseListItem, RadiusTokenAliaseListItem, SpacePatternAliaseItem } from '../aliases';
import DropDownSelect from '../../../../../../components/editors/enum_dropdown';
import { SortableList, SortableListItem } from '../../../../../../components/SortableContainer';
import { FigmaAutoLayoutAliaseItem, FilterTokenAliaseListItem, MotionDurationAliaseItem, MotionEaseAliaseItem, TextPatternAliaseItem } from '../aliases/aliaseGroup';
import { FigmaLayoutListItem, GetFigmaLayoutTokenItem, GetFigmaLayoutTokenListOfIds } from '../../../../../../apps/figmaplugin/designsystem/autolayouts';
import { CustomTokenListItem, GetCustomTokenListOfIds } from '../custom/tokens';

const TokenTypes = Globals.ProjectManager.Tokens.Types;

export default class SelectTokenOptions extends ReactBaseComponent {

    constructor(props) {
        super(props);
    }
    
    onSortItems(prop, selectedTokens, oldIndex, newIndex) {
        // const selectedTokens = Utils.JustGet(this.props.data, [], 'data', prop, 'ids');        
        Utils.ChangePlace(selectedTokens, oldIndex, newIndex);
        this.props.onOrderChanged(prop, selectedTokens);
    }    
    renderCustom() {
        const {selection, tokenType, subType} = this.props;
        let selectedTokens = this.props.GetSelectedTokens();
        
        let useTokenType = tokenType;
        if (tokenType === TokenTypes.Motion) {
            if (subType === 'durations' || !subType) {
                useTokenType = TokenTypes.TimePatterns;
            }
            else if (subType === 'transitions') {
                useTokenType = TokenTypes.Transitions;
            } 
        }
        else if (tokenType === TokenTypes.Fonts) {
            if (Utils.UseNullOrEmpty(subType, 'patterns') === 'patterns') {
                useTokenType = TokenTypes.TextPatterns;
            }
        }
        else if (tokenType === TokenTypes.Spacings || tokenType === TokenTypes.SpacePatterns) {
            if (Utils.UseNullOrEmpty(subType, 'patterns') === 'patterns') {
                useTokenType = TokenTypes.SpacePatterns;
            }
        }
        
        let content;
        if (selection === 'all' || selection === 'selected' || selection === 'groups') {
            const isGroups = selection === 'groups';      
            
            if (isGroups) {
                const groupIds = this.props.groupIds || [];
                const groups = Globals.ProjectManager.Tokens.GetGroups(useTokenType);
                const allGroupTokens = [];
                Utils.ForEach(groupIds, (groupId, ) => {
                    const group = Utils.Find(groups, (g) => {return g.id === groupId});
                    if (group) {
                        if (group.order) {
                            allGroupTokens.push(...group.order);
                            group.order.map((tokenId) => {
                                if (selectedTokens.indexOf(tokenId) < 0)
                                    selectedTokens.push(tokenId);
                            })
                        }
                        if (group.id === 'Default') {
                            const defaultTokens = Globals.ProjectManager.Tokens.GetDefaultGroupTokenIds(useTokenType);
                            allGroupTokens.push(...defaultTokens);
                            defaultTokens && defaultTokens.map((tokenId) => {
                                if (selectedTokens.indexOf(tokenId) < 0)
                                    selectedTokens.push(tokenId);
                            })
                        }
                    }                    
                });
                const groupTokens = [];
                selectedTokens.map((tokenId) => {
                    if (allGroupTokens.indexOf(tokenId) >= 0) {
                        groupTokens.push(tokenId);
                    }
                })
                selectedTokens = groupTokens;
            }   
            

            if (selection === 'all') {
                const tokenids = Globals.ProjectManager.Tokens.Order(useTokenType);
                tokenids && tokenids.map((tokenId) => {
                    if (selectedTokens.indexOf(tokenId) < 0)
                        selectedTokens.push(tokenId);
                })
            }            

            const tokenItems = [];

            if (tokenType === TokenTypes.COLOR) {
                
                const colors = GetColorTokenListOfIds(selectedTokens);

                Utils.ForEach(colors, (token, i) => {                                                                                                 
                    tokenItems.push({
                        id : token.id,
                        ItemType : ColorTokenListItem,
                        props : {
                            color : token
                        }
                    })
                });
            }
            else if (tokenType === TokenTypes.Gradients) {                
                const colors = GetGradientTokenListOfIds(selectedTokens);
                
                Utils.ForEach(colors, (token, i) => {                                                                                                 
                    tokenItems.push({
                        id : token.id,
                        ItemType : ColorTokenListItem,
                        props : {
                            color : token,
                            gradient : true
                        }
                    })
                });
            }
            else if (tokenType === TokenTypes.Shadows) {
                const tokens = GetShadowTokenListOfIds(selectedTokens).All;                    
                Utils.ForEach(tokens, (token, i) => {                                                                                                 
                    tokenItems.push({
                        id : token.id,
                        ItemType : ShadowListItem,
                        props : {
                            shadow : token,
                            textShadow : token.textShadow
                        }
                    })
                });
            }
            else if (tokenType === TokenTypes.Borders) {
                const tokens = GetBorderStyleTokenListOfIds(selectedTokens);
                                    
                Utils.ForEach(tokens, (token, i) => {                                                                                                 
                    tokenItems.push({
                        id : token.id,
                        ItemType : BorderStyleTokenItem,
                        props : {
                            border : token,
                            isListView : true
                        }
                    })
                });
            }
            else if (tokenType === TokenTypes.BorderRadiuses) {
                const tokens = GetUnsortedBorderRadiusTokenListOfIds(selectedTokens);
                                                    
                Utils.ForEach(tokens, (token, i) => {                                                                                                 
                    tokenItems.push({
                        id : token.id,
                        ItemType : BorderRadiusTokenItem,
                        props : {
                            radius : token,
                            isListView : true
                        }
                    })
                });
            }
            else if (tokenType === TokenTypes.Transforms) {
                const tokens = GetTransformTokenListOfIds(selectedTokens);
        
                Utils.ForEach(tokens, (token, i) => {                                                                                                 
                    tokenItems.push({
                        id : token.id,
                        ItemType : TransformListItem,
                        props : {
                            transform : token
                        }
                    })
                });
            }
            else if (tokenType === TokenTypes.Filters) {
                const tokens = GetFilterTokenListOfIds(selectedTokens);
                                    
                Utils.ForEach(tokens, (token, i) => {                                                                                                 
                    tokenItems.push({
                        id : token.id,
                        ItemType : FilterListItem,
                        props : {
                            filter : token
                        }
                    })
                });
            }
            else if (tokenType === TokenTypes.ContentTexts) {
                const tokens = GetTextContentTokenListOfIds(selectedTokens);
                                
                Utils.ForEach(tokens, (token, i) => {                                                                                                 
                    tokenItems.push({
                        id : token.id,
                        ItemType : TextContentListItem,
                        props : {
                            name : token.name,
                            value : token.value
                        }
                    })
                });
            }
            else if (tokenType === TokenTypes.Icons) {
                const tokens = GetIconTokenListOfIds(selectedTokens);
                const icon_style = { width : '60px', height : '60px' }

                Utils.ForEach(tokens, (token, i) => {                                                                                                 
                    let iconitem; 
                    if (Utils.IsNotNullOrEmpty(token.url)) {
                        iconitem = (
                            <IconBox                         
                                url={token.url}
                                style={{...icon_style}}
                                title={token.name}                        
                            />
                        )                     
                    }    
                    else if (token.paths) {
                        iconitem = (
                            <IconBox                        
                                style={{...icon_style}}
                                title={token.name}
                            >
                                <SC.Icons_Preview compact  xmlns='http://www.w3.org/2000/svg' viewBox={`0 0 24 24`} width='80%' dark={SC.CurrentTheme.theme.isLight} >
                                    {token.paths}
                                </SC.Icons_Preview>    
                            </IconBox>
                        )
                    }   

                    tokenItems.push({
                        id : token.id,
                        ItemType : IconListItem,
                        props : {
                            name : token.name,
                            url : token.url,
                            icon : iconitem,
                        }
                    })
                });
            }
            else if (tokenType === TokenTypes.Fonts) {
                if (subType === 'fonts') {
                    const tokens = GetFontItemListOfIds(selectedTokens);
                    Utils.ForEach(tokens, (token, i) => {                                                                                                 
                        tokenItems.push({
                            id : token.id,
                            ItemType : FontSelectItem,
                            props : {
                                name : token.name,
                                font : token.font                                
                            }
                        })
                    });
                }
                else if (subType === 'scale') {

                }
                else {
                    const tokens = GetTextPatternListOfIds(selectedTokens);
                    Utils.ForEach(tokens, (token, i) => {                                                                                                 
                        tokenItems.push({
                            id : token.id,
                            ItemType : TextPattern,
                            props : {
                                name : token.name,
                                pattern : token.pattern,
                                listView : true
                            }
                        })
                    });
                }
            }
            else if (tokenType === TokenTypes.Spacings || tokenType === TokenTypes.SpacePatterns) {
                if (subType === 'scale') {

                }
                else {                    
                    const tokens = GetSpacingPatternListOfIds(selectedTokens);
                    Utils.ForEach(tokens, (token, i) => {                                                                                                 
                        tokenItems.push({
                            id : token.id,
                            ItemType : SpacePattern,
                            props : {
                                name : token.name,
                                pattern : token.pattern,
                                spaceValue : token.spaceValue,
                                listView : true
                            }
                        })
                    });
                }
            }
            else if (tokenType === TokenTypes.Motion) {
                if (subType === 'durations') {
                    const tokens = GetDurationPatternListOfIds(selectedTokens);
                    Utils.ForEach(tokens, (token, i) => {                                                                                                 
                        tokenItems.push({
                            id : token.id,
                            ItemType : DurationPattern,
                            props : {
                                name : token.name,
                                pattern : token.pattern,
                                timeValue : token.timeValue
                            }
                        })
                    });
                }
                else if (subType === 'ease') {
                    const tokens = GetEaseCurveTokenListOfIds(selectedTokens);
                    Utils.ForEach(tokens, (token, i) => {                                                                                                 
                        tokenItems.push({
                            id : token.id,
                            ItemType : EasePattern,
                            props : {
                                name : token.name,
                                pattern : token,
                                listView : true
                            }
                        })
                    });
                }
                else if (subType === 'transitions') {
                    const tokens = GetTransitionTokenListOfIds(selectedTokens);
                    Utils.ForEach(tokens, (token, i) => {                                                                                                 
                        tokenItems.push({
                            id : token.id,
                            ItemType : TransitionListItem,
                            props : {
                                name : token.name,
                                transition : token,
                                listView : true
                            }
                        })
                    });
                }
            }
            else if (tokenType === TokenTypes.FigmaAutoLayouts) {
                const tokens = GetFigmaLayoutTokenListOfIds(selectedTokens);
                                
                Utils.ForEach(tokens, (token, i) => {                                                                                                 
                    tokenItems.push({
                        id : token.id,
                        ItemType : FigmaLayoutListItem,
                        props : {
                            name : token.name,
                            value : token.value,
                            FigmaLayout : token
                        }
                    })
                });
            }
            else if (tokenType === TokenTypes.CustomTokenTypes) {
                const tokens = GetCustomTokenListOfIds(this.props.customTokenType, selectedTokens);
                                
                Utils.ForEach(tokens, (token, i) => {                                                                                                 
                    tokenItems.push({
                        id : token.id,
                        ItemType : CustomTokenListItem,
                        props : {
                            name : token.name,
                            value : token.value
                        }
                    })
                });
            }
        
            content = (
                <React.Fragment>
                    {
                        selection === 'selected' &&
                        <SC.Buttons.RoundButton 
                            xsmall 
                            onClick={this.props.onSelectTokens} 
                            style={{marginTop : '8px', marginBottom : '8px', alignSelf : 'center'}}
                        >
                            Select Tokens
                        </SC.Buttons.RoundButton>
                    }                    
                    {
                        selection === 'groups' && 
                        <SC.Buttons.RoundButton 
                            xsmall 
                            onClick={this.props.onSelectGroups} 
                            style={{marginTop : '8px', marginBottom : '8px', alignSelf : 'center'}}
                        >
                            Select Token Groups
                        </SC.Buttons.RoundButton>
                    }    

                    <SortableList
                        type='ITEMS'
                        onSort={this.onSortItems.bind(this, 'tokens', selectedTokens)}
                        onDragStart={this.onDragStart}
                        onDragEnd={this.onDragEnd}                            
                    >
                        {
                            tokenItems.map((token, i) => {
                                return (
                                    <SortableListItem 
                                        key={token.id}
                                        draggableId={token.id}
                                        index={i}
                                        ItemBoxType={token.ItemType}
                                        BoxProps={token.props}
                                    />
                                )
                            })
                        }
                    </SortableList>
                        
                </React.Fragment>
            )
        }
        else if (selection === 'aliases' || selection === 'selectedaliases' || selection === 'aliaseGroups') {
            const isGroups = selection === 'aliaseGroups';       
            let willUseTokenType = useTokenType;
            if (useTokenType === TokenTypes.TextPatterns)
                willUseTokenType = TokenTypes.Fonts;
            else if (useTokenType === TokenTypes.TimePatterns)
                willUseTokenType = TokenTypes.Motion;
            else if (useTokenType === TokenTypes.Motion && this.props.subType === 'ease')
                willUseTokenType = TokenTypes.MotionEase;

            if (isGroups) {
                const groupIds = this.props.groupIds || [];
                const groups = Globals.ProjectManager.Tokens.Aliases.GetGroups(willUseTokenType);
                const allGroupTokens = [];
                Utils.ForEach(groupIds, (groupId, ) => {
                    const group = Utils.Find(groups, (g) => {return g.id === groupId});
                    if (group) {
                        if (group && group.order) {
                            allGroupTokens.push(...group.order);
                            group.order.map((tokenId) => {
                                if (selectedTokens.indexOf(tokenId) < 0)
                                    selectedTokens.push(tokenId);
                            })
                        }
                        if (group.id === 'Default') {
                            const defaultTokens = Globals.ProjectManager.Tokens.GetDefaultGroupTokenIds(willUseTokenType, true);
                            allGroupTokens.push(...defaultTokens);
                            defaultTokens && defaultTokens.map((tokenId) => {
                                if (selectedTokens.indexOf(tokenId) < 0)
                                    selectedTokens.push(tokenId);
                            })
                        }
                    }                    
                });
                const groupTokens = [];
                selectedTokens.map((tokenId) => {
                    if (allGroupTokens.indexOf(tokenId) >= 0) {
                        groupTokens.push(tokenId);
                    }
                })
                selectedTokens = groupTokens;
            }
            
            if (selection === 'aliases') {
                const tokenids = Globals.ProjectManager.Tokens.Aliases.Order(willUseTokenType);
                tokenids.map((tokenId) => {
                    if (selectedTokens.indexOf(tokenId) < 0)
                        selectedTokens.push(tokenId);
                })
            }  

            const tokenItems = [];
            const aliases = GetTokenAliaseListOfIds(selectedTokens);

            if (tokenType === TokenTypes.COLOR) {
                Utils.ForEach(aliases, (aliase, i) => {              
                    if (!aliase.tokenItem) {
                        aliase.tokenItem = GetColorTokenItem({
                            id : aliase.tokenId,
                            token : Globals.ProjectManager.Tokens.Token(aliase.tokenId)                    
                        })
                    }

                    tokenItems.push({
                        id : aliase.id,
                        ItemType : ColorTokenAliaseListItem,
                        props : {
                            aliase : aliase
                        }
                    })
                });
            }
            else if (tokenType === TokenTypes.Gradients) {                
                const colors = GetGradientTokenListOfIds(selectedTokens);
                
                Utils.ForEach(colors, (token, i) => {                                                                                                 
                    tokenItems.push({
                        id : token.id,
                        ItemType : ColorTokenListItem,
                        props : {
                            color : token,
                            gradient : true
                        }
                    })
                });
            }
            else if (tokenType === TokenTypes.Shadows) {
                Utils.ForEach(aliases, (aliase, i) => {              
                    if (!aliase.tokenItem) {
                        aliase.tokenItem = GetShadowTokenItem({
                            id : aliase.tokenId,
                            token : Globals.ProjectManager.Tokens.Token(aliase.tokenId)                    
                        })
                    }

                    tokenItems.push({
                        id : aliase.id,
                        ItemType : ShadowTokenAliaseListItem,
                        props : {
                            aliase : aliase
                        }
                    })
                });
            }
            else if (tokenType === TokenTypes.Borders) {
                Utils.ForEach(aliases, (aliase, i) => {              
                    if (!aliase.tokenItem) {
                        aliase.tokenItem = GetBorderStyleTokenValue(Globals.ProjectManager.Tokens.Token(aliase.tokenId), aliase.tokenId);
                    }

                    tokenItems.push({
                        id : aliase.id,
                        ItemType : BorderTokenAliaseListItem,
                        props : {
                            aliase : aliase
                        }
                    })
                });
            }
            else if (tokenType === TokenTypes.BorderRadiuses) {
                Utils.ForEach(aliases, (aliase, i) => {              
                    if (!aliase.tokenItem) {
                        aliase.tokenItem = GetBorderRadiusTokenValue(Globals.ProjectManager.Tokens.Token(aliase.tokenId), aliase.tokenId);
                    }

                    tokenItems.push({
                        id : aliase.id,
                        ItemType : RadiusTokenAliaseListItem,
                        props : {
                            aliase : aliase
                        }
                    })
                });
            }
            else if (tokenType === TokenTypes.Spacings || tokenType === TokenTypes.SpacePatterns) {
                Utils.ForEach(aliases, (aliase, i) => {              
                    if (!aliase.tokenItem) {
                        aliase.tokenItem = GetSpacePatternItem(aliase.tokenId);
                    }

                    tokenItems.push({
                        id : aliase.id,
                        ItemType : SpacePatternAliaseItem,
                        props : {
                            aliase : aliase
                        }
                    })
                });
            }
            else if (tokenType === TokenTypes.Fonts || subType === TokenTypes.Fonts) {
                Utils.ForEach(aliases, (aliase, i) => {              
                    if (!aliase.tokenItem) {
                        aliase.tokenItem = GetTextPatternTokenItem({id : aliase.tokenId});
                    }

                    tokenItems.push({
                        id : aliase.id,
                        ItemType : TextPatternAliaseItem,
                        props : {
                            aliase : aliase
                        }
                    })
                });
            }
            else if (willUseTokenType === TokenTypes.Motion) {
                Utils.ForEach(aliases, (aliase, i) => {              
                    if (!aliase.tokenItem) {
                        aliase.tokenItem = GetDurationTokenItemOfId(aliase.tokenId);
                    }

                    tokenItems.push({
                        id : aliase.id,
                        ItemType : MotionDurationAliaseItem,
                        props : {
                            aliase : aliase
                        }
                    })
                });
            }
            else if (willUseTokenType === TokenTypes.MotionEase) {
                Utils.ForEach(aliases, (aliase, i) => {              
                    if (!aliase.tokenItem) {
                        aliase.tokenItem = GetEaseCurveTokenItem(aliase.tokenId);
                    }

                    tokenItems.push({
                        id : aliase.id,
                        ItemType : MotionEaseAliaseItem,
                        props : {
                            aliase : aliase
                        }
                    })
                });
            }
            else if (tokenType === 'Filters') {
                Utils.ForEach(aliases, (aliase, i) => {              
                    if (!aliase.tokenItem) {
                        aliase.tokenItem = GetFilterTokenItem(aliase.tokenId);
                    }

                    tokenItems.push({
                        id : aliase.id,
                        ItemType : FilterTokenAliaseListItem,
                        props : {
                            aliase : aliase
                        }
                    })
                });
            }
            else if (tokenType === TokenTypes.FigmaAutoLayouts) {
                Utils.ForEach(aliases, (aliase, i) => {              
                    if (!aliase.tokenItem) {
                        aliase.tokenItem = GetFigmaLayoutTokenItem(aliase.tokenId);
                    }

                    tokenItems.push({
                        id : aliase.id,
                        ItemType : FigmaAutoLayoutAliaseItem,
                        props : {
                            aliase : aliase
                        }
                    })
                });
            }
        
            content = (
                <React.Fragment>
                    {
                        selection === 'selectedaliases' &&
                        <SC.Buttons.RoundButton 
                            xsmall 
                            onClick={this.props.onSelectAliases} 
                            style={{marginTop : '8px', marginBottom : '8px', alignSelf : 'center'}}
                        >
                            Select Alises
                        </SC.Buttons.RoundButton>
                    }
                    {
                        selection === 'aliaseGroups' && 
                        <SC.Buttons.RoundButton 
                            xsmall 
                            onClick={this.props.onAliaseGroups} 
                            style={{marginTop : '8px', marginBottom : '8px', alignSelf : 'center'}}
                        >
                            Select Aliase Groups
                        </SC.Buttons.RoundButton>
                    }  
                    <SortableList
                        type='ITEMS'
                        onSort={this.onSortItems.bind(this, 'aliases', selectedTokens)}
                        onDragStart={this.onDragStart}
                        onDragEnd={this.onDragEnd}                            
                    >
                        {
                            tokenItems.map((token, i) => {
                                return (
                                    <SortableListItem 
                                        key={token.id}
                                        draggableId={token.id}
                                        index={i}
                                        ItemBoxType={token.ItemType}
                                        BoxProps={token.props}
                                    />
                                )
                            })
                        }
                    </SortableList>
                        
                </React.Fragment>
            )          
        }
        
        const tokenSelectOptions = [
            {id : 'all', label : 'All Tokens'},
            {id : 'selected', label : 'Selected Tokens'}            
        ];
        const hasGroups = !Utils.IsOneOf(tokenType, TokenTypes.Shadows, TokenTypes.Fonts, TokenTypes.Spacings, TokenTypes.Borders, TokenTypes.BorderRadiuses, TokenTypes.Filters, TokenTypes.Motion);
        if (hasGroups)
            tokenSelectOptions.push({id : 'groups', label : 'Selected Groups'});

        let hasAliases = Utils.IsOneOf(tokenType, TokenTypes.COLOR, TokenTypes.Shadows, TokenTypes.Borders, TokenTypes.BorderRadiuses, TokenTypes.Spacings, TokenTypes.SpacePatterns, TokenTypes.Filters, TokenTypes.FigmaAutoLayouts) || Utils.IsOneOf(useTokenType, TokenTypes.TextPatterns);

        if (tokenType === TokenTypes.Motion && subType !== 'transitions')
            hasAliases = true;

        if (hasAliases) {
            tokenSelectOptions.push({id : 'aliases', label : 'All Semantic Aliases', hasSeperator : true});
            tokenSelectOptions.push({id : 'selectedaliases', label : 'Selected Aliases'});

            tokenSelectOptions.push({id : 'aliaseGroups', label : 'Selected Aliases Groups'});
            // if (hasGroups || tokenType === TokenTypes.Shadows) {
            //     tokenSelectOptions.push({id : 'aliaseGroups', label : 'Selected Aliases Groups'});
            // }
        }

        return (
            <SC.FCol>
                <SC.FRow alc jsb style={{marginBottom : '8px'}}>
                    <SC.GroupLabel style={{flex : 1}}>TOKENS</SC.GroupLabel>
                    <DropDownSelect 
                        style={{flex : 1, fontSize : '11px'}}
                        items={tokenSelectOptions}
                        value={selection}
                        hasBorder            
                        autoHeight
                        xsmall
                        toRight
                        popupStyle={{left : 'unset', width : '180px'}}
                        onChange={this.props.onOptionsChanged}
                    />
                </SC.FRow>
                {content}
            </SC.FCol>
        )
    }
}

export const GetSelectedTokenIds = ({selectionType, data, useTokenType}) => {
    const result = {
        selectedTokens : []
    };

    let willUseTokenType = useTokenType;
    if (useTokenType === TokenTypes.SpacePatterns)
        willUseTokenType = TokenTypes.Spacings;
    else if (useTokenType === TokenTypes.TextPatterns)
        willUseTokenType = TokenTypes.Fonts;
    else if (useTokenType === TokenTypes.TimePatterns)
        willUseTokenType = TokenTypes.Motion;

    if (selectionType === 'selected') {
        result.selectedTokens = Utils.JustGet(data, [], 'tokens', 'ids');    
    }
    else if (selectionType === 'all') {
        result.selectedTokens = Utils.JustGet(data, [], 'tokens', 'ids');
        let tokenids;
        if (useTokenType === TokenTypes.CustomTokenTypes) {
            tokenids = Globals.ProjectManager.Tokens.CustomTokenIds(data.customTokenType);
        }   
        else {
            tokenids = Globals.ProjectManager.Tokens.Order(useTokenType);
        }
        

        tokenids && tokenids.map((tokenId) => {
            if (result.selectedTokens.indexOf(tokenId) < 0)
                result.selectedTokens.push(tokenId);
        })

        if (useTokenType === 'Fonts') {
            let textStyles = Globals.ProjectManager.Tokens.Order(TokenTypes.TextPatterns);
            textStyles && textStyles.map((tokenId) => {
                if (result.selectedTokens.indexOf(tokenId) < 0)
                    result.selectedTokens.push(tokenId);
            })
        }
        else if (useTokenType === 'Motion') {
            // let durationIds = Globals.ProjectManager.Tokens.Order(TokenTypes.TimePatterns);
            // durationIds && durationIds.map((tokenId) => {
            //     if (result.selectedTokens.indexOf(tokenId) < 0)
            //         result.selectedTokens.push(tokenId);
            // })

            // let transitionIds = Globals.ProjectManager.Tokens.Order(TokenTypes.Transitions);
            // transitionIds && transitionIds.map((tokenId) => {
            //     if (result.selectedTokens.indexOf(tokenId) < 0)
            //         result.selectedTokens.push(tokenId);
            // })
        }
    }
    else if (selectionType === 'groups') {            
        result.selectedTokens = Utils.JustGet(data, [], 'tokens', 'ids');
        const groupIds = Utils.JustGet(data, [], 'groups', 'ids');
        const groups = Globals.ProjectManager.Tokens.GetGroups(useTokenType);
        const allGroupTokens = [];
        Utils.ForEach(groupIds, (groupId, ) => {
            const group = Utils.Find(groups, (g) => {return g.id === groupId});
            if (group) {
                if (group.order) {
                    allGroupTokens.push(...group.order);
                    group.order.map((tokenId) => {
                        if (result.selectedTokens.indexOf(tokenId) < 0)
                            result.selectedTokens.push(tokenId);
                    })
                }
                if (group.id === 'Default') {
                    const defaultTokens = Globals.ProjectManager.Tokens.GetDefaultGroupTokenIds(useTokenType);
                    allGroupTokens.push(...defaultTokens);
                    defaultTokens && defaultTokens.map((tokenId) => {
                        if (result.selectedTokens.indexOf(tokenId) < 0)
                            result.selectedTokens.push(tokenId);
                    })
                }
            }                
        });

        const groupTokens = [];
        result.selectedTokens.map((tokenId) => {
            if (allGroupTokens.indexOf(tokenId) >= 0) {
                groupTokens.push(tokenId);
            }
        })
        result.selectedTokens = groupTokens;
    }
    else if (selectionType === 'aliases') {
        result.selectedTokens = Utils.JustGet(data, [], 'aliases', 'ids');

        let willUseAliaseType = willUseTokenType;
        if (useTokenType === TokenTypes.TimePatterns) {
            willUseAliaseType = TokenTypes.Motion;
        }
        else if (useTokenType === TokenTypes.Motion) {
            willUseAliaseType = TokenTypes.MotionEase;
        }

        let aliaseIds = Globals.ProjectManager.Tokens.Aliases.Order(willUseAliaseType);

        

        aliaseIds && aliaseIds.map((aliaseId) => {
            if (result.selectedTokens.indexOf(aliaseId) < 0)
                result.selectedTokens.push(aliaseId);
        })

        result.isAliase = true;
    }
    else if (selectionType === 'selectedaliases') {
        result.isAliase = true;
        result.selectedTokens = Utils.JustGet(data, [], 'aliases', 'ids');
    }
    else if (selectionType === 'aliaseGroups') {
        result.isAliase = true;
        
        let willUseAliaseType = willUseTokenType;
        if (willUseTokenType === TokenTypes.Motion) {
            willUseAliaseType = TokenTypes.MotionEase;
        }

        result.selectedTokens = Utils.JustGet(data, [], 'aliases', 'ids');
        const groupIds = Utils.JustGet(data, [], 'aliaseGroups', 'ids');
        const groups = Globals.ProjectManager.Tokens.Aliases.GetGroups(willUseAliaseType);
        const allGroupTokens = [];
        Utils.ForEach(groupIds, (groupId, ) => {
            const group = Utils.Find(groups, (g) => {return g.id === groupId});
            if (group) {
                if (group.order) {
                    allGroupTokens.push(...group.order);
                    group.order.map((tokenId) => {
                        if (result.selectedTokens.indexOf(tokenId) < 0)
                            result.selectedTokens.push(tokenId);
                    })
                }
                if (group.id === 'Default') {
                    const defaultTokens = Globals.ProjectManager.Tokens.GetDefaultGroupTokenIds(willUseAliaseType, true);
                    allGroupTokens.push(...defaultTokens);
                    defaultTokens && defaultTokens.map((tokenId) => {
                        if (result.selectedTokens.indexOf(tokenId) < 0)
                            result.selectedTokens.push(tokenId);
                    })
                }
            }                
        });
        const groupTokens = [];
        result.selectedTokens.map((tokenId) => {
            if (allGroupTokens.indexOf(tokenId) >= 0) {
                groupTokens.push(tokenId);
            }
        })
        result.selectedTokens = groupTokens;
    }

    return result;
}