import React from 'react';
import {
    ReactBaseComponent,
    SC,
    AppState,
    UIUtils,
    Utils
} from '../../../../../../../../importer';

import styled, {css} from 'styled-components';


export const PresetItem = styled.div`        
    width : 48%;
    height : 115px;
    ${
        props => {
            if (props.index % 2 === 0) {
                return css`
                    border-right : 1px solid ${props => props.theme.back_lighter};
                `;
            }
        }
    }
    ${
        props => {
            if (props.index < 2) {
                return css`
                    margin-top : 14px;
                `;
            }
        }
    }
    display : flex;
    flex-direction : column;
    justify-content : center;
    align-items: center;
    cursor : pointer;
`;

const PresetFill = styled.path`
fill : ${props => props.theme.back};
${PresetItem}:hover &{
    fill : ${props => props.theme.back_lighter};
}
`;

const PresetPath = styled.path`
stroke : ${props => props.selected ? props.theme.color_brand : (props.theme.isLight ? '#414246' : '#9e9e9e')};
stroke-width : ${props => props.curveWidth ||  (props.theme.isLight && props.selected ? 3 : 2)};
${PresetItem}:hover &{
    stroke : ${props => props.theme.color_brand};    
    stroke-width : 2;
}
`;

export const GetEaseCurvePath = ({x1,x2,y1,y2, size}) => {
    const useSize = size || 68;
    const c1x = x1 * useSize    
    const c1y = y1 * (-1 * useSize) + useSize;
    const c2x = x2 * useSize;
    const c2y = useSize - (y2 * useSize);
    return `M0,${useSize} C${c1x},${c1y} ${c2x},${c2y} ${useSize},${0}`;
}

export const PresetCurve = (props) => {
    let cubicpath = props.curve;
    if (!cubicpath) {
        if (!props.path)
            return null;
        
        cubicpath = GetEaseCurvePath({...props.path, size : 68});
    }

    return (
        <SC.Div_Flex_Cell onClick={props.onClick}>
            <SC.Svg width={props.small ? 34 : (props.large ? 102 : (props.size || 68))}  viewBox="0 0 68 100" >
                <g fill="none" fillRule="evenodd" transform="translate(0, 16)">
                    <PresetFill selected={props.selected} stroke={SC.CurrentTheme.theme.isLight ? '#666666' : "#4A4A4A"} d="M.5.5h67v67H.5z"/>
                    <path stroke={SC.CurrentTheme.theme.isLight ? '#666666' : "#4A4A4A"} d="M17.5.5v67m17-67v67m17-67v67m16-50.5H1m66.5 17H1m66.5 17H1" strokeLinecap="square"/>                    
                    <PresetPath selected={props.selected} d={cubicpath} curveWidth={props.curveWidth} />
                </g>                
            </SC.Svg>
        </SC.Div_Flex_Cell>
    )
}
