import Utils from '../../../../toolabs-utils';

export default (MetaItem, GenericType, Name) => {
    const RootId = Utils.Id();
    MetaItem.name = Name;
    
    const Component = {            
        RootItem : {
            Id : RootId
        },
        MetaItems : {},
        PreviewSize : {
            width : 140,
            height : 40
        },
        Default: {
            'Designboard': {
                'type': 'grid'
            },
            'height': {
                'value': 40
            },                
            'width': {
                'value': 140
            }
        }
            
    };
    Component.MetaItems[RootId] = MetaItem;

    Component.PseudoStates = {
        Order : ['System', 'disabled', 'hover'],        
        System : {
            name : 'System State',
            Variations : {
                Order : ['indeterminate', 'determinate'],
                active : {
                    name : 'Indeterminate',
                    pseudo : true
                },
                focus : {
                    name : 'Determinate',
                    pseudo : true
                },                
            }
        },
        disabled : {
            SingleVariation : true,
            name : 'Disabled',
            previewable : true,
        },
        hover : {
            SingleVariation : true,
            name : 'Hover',
            pseudo : true
        }
    };

    Component.Handlers = {
        
    };

    Component.Models = {
        List : {
            value : {
                dataType: 'number',
                name: 'Value 2',
                value : 0
            },
            min : {
                dataType: 'number',
                name: 'Min 1',
                value : 0
            },
            max : {
                dataType: 'number',
                name: 'Max 2',
                value : 100
            }
        },
        Order: ['value', 'min', 'max']
    };

    MetaItem.Events = [ 
        
    ];

    Component.ProgressId = Utils.Id();
    Component.ProgressValueId = Utils.Id();
    
    Utils.MergeTo(MetaItem, {
        Generic : {
            Type : GenericType,
            ProgressId : Component.ProgressId,
            ProgressValueId : Component.ProgressValueId
        },
        Models : {
            Default : {
                Default : {
                    value: {
                        Id: 'value'
                    },
                    min: {
                        Id: 'min'
                    },
                    max: {
                        Id: 'max'
                    }
                    ,
                    step: {
                        Id: 'step'
                    }
                }
            }
        },
        Property: {
            Default: {
                Default: {                   
                    backgroundColor: {
                        value: 'rgba(110, 110, 110, 1)'
                    },
                    width : {
                        value : 200
                    },
                    height: {
                        value: 20
                    },
                    position : {
                        value : 'relative'
                    }
                },
                disabled: {
                    backgroundColor: {
                        value: 'rgba(209, 209, 209, 1)'
                    }
                }
            }
        },
        SubItems : [
            {Id : Component.ProgressId},
            {Id : Component.ProgressValueId}
        ]
    });

    Component.MetaItems[Component.ProgressId] = {        
        Generic : {
            Type : GenericType,
            SubType : 'Progress'
        },
        ParentId: RootId,
        Property: {
            Default: {
                Default: {
                    backgroundColor: {
                        value: 'rgba(245, 245, 245, 1)'
                    }
                }
            }
        },
        Type: 'Div'
    };
    Component.MetaItems[Component.ProgressValueId] = {        
        Generic : {
            Type : GenericType,
            SubType : 'ProgressValue'
        },
        ParentId: RootId,
        Property: {
            Default: {
                Default: {
                }
            }
        },
        Style: {
            Default: {
            }
        },
        Type: 'Div'
    };
    
    return Component;
}