import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    AppLayout,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../../../../importer';
import styled, { css} from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';

import {DOCUMENT_ITEMTYPES} from '../../../../manager/documents';
import Widget from '../../widget';
import DocumentItem from '..';
import { SortableListItem, SortableList } from '../../../../../../components/SortableContainer';

export default class DocumentListItem extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {
        };

        this.onSortItems = this.onSortItems.bind(this);        
        this.onDragStart = this.onDragStart.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);        

        this.Load(this.props.id);
    }

    Load(id) {
        const itemIds = Globals.ProjectManager.GetDocumentManager().GetItemIds(this.props.id);
        if (itemIds.length === 0 && !this.props.preview) {
            this.WillFocusToNewId = Globals.ProjectManager.GetDocumentManager().AddItem({pageId : this.props.id});
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.id !== nextProps.id) {
            this.Load(nextProps.id);
        }
        return true;
    }
    onSortItems(oldIndex, newIndex) {
        Globals.ProjectManager.GetDocumentManager().ChangeOrder(oldIndex, newIndex, this.props.id);
        this.setState({ShouldUpdate : true});
    }
    onDragEnd() {
        this.props.ItemProps.context.dragging = false;
        this.props.ItemProps.context.disabled = false;
    }
    onDragStart() {
        this.props.ItemProps.context.dragging = true;
    }
    render() {
        const {id, name} = this.props

        this.state.ShouldUpdate = false;

        const itemIds = Globals.ProjectManager.GetDocumentManager().GetItemIds(this.props.id);

        const items = [];            
        let itemIndex = 0;
        itemIds.map((itemId, index) => {                
            const isLayoutItem = Globals.ProjectManager.GetDocumentManager().GetPageItemValue(itemId, this.props.pageId, false, 'layoutId');
            if (!isLayoutItem) {
                items.push(
                    <SortableListItem 
                        key={itemId}
                        draggableId={itemId}
                        index={itemIndex}
                        ItemBoxType={DocumentItem}                        
                        BoxProps={{
                            id : itemId,
                            parentId : this.props.id,
                            index : itemIndex,
                            width : this.props.pageWidth,
                            last : index === itemIds.length - 1,
                            autoFocus : itemId === this.WillFocusToNewId || itemId === this.WillFocusToItem,
                            tabItem : true,
                            style : this.props.style,
                            toobarStyle : this.props.toobarStyle,
                            // showItemSettings : this.showItemSettings === itemId,
                            ...this.props.ItemProps,
                            toolbarPosition : '-60px'
                        }}
                    /> 
                ) 
                
                itemIndex += 1;
            }                              
        })

        return (
            <SortableList
                type='SUBITEMS'
                onSort={this.onSortItems}
                onDragStart={this.onDragStart}
                onDragEnd={this.onDragEnd}                            
            >
                {items}
            </SortableList>
        )
    }
}