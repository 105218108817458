import Utils from '../toolabs-utils';
import MetaComponents from './components';
import DataTypes from './datatypes';
import Styles from './styles';
import Props from './props';

const ItemInitializer = {
    FromType(type, empty) {
        return this.FromMetaData({
            Type : type
        }, empty);
    },
    ElementTemplate(ItemMetaData) {
        const template = this.FromMetaData(ItemMetaData);
        delete template.Events;
        delete template.Props;
        delete template.Description;
        delete template.Styles;
        delete template.States;
        return template;
    },
    FromMetaData(MetaData, empty, ModelManager) {
        let MetaItem = {
            Type : MetaData.Type,
            Tokens : {
                Default : {
                    Default : {

                    }
                }
            },
            Property : {
                Default : {
                    Default : {
                        
                    }
                }
            }
        };
        if (empty) {
            
        }
        else {
            if (MetaData.Type == MetaComponents.Content.Type) {                
                MetaItem.Property = {
                    Default : {
                        Default : {
                            display : {
                                value : 'flex'
                            },
                            flexDirection : {
                                value : Props.FlexValues.ROW
                            },
                            justifyContent : {
                                value : Props.FlexValues.FLEXSPACEAROUND
                            },
                            alignItems : {
                                value : Props.FlexValues.FLEXCENTER
                            }
                        }
                    }
                };
                
            }
            else if (MetaData.Type == MetaComponents.Div.Type || MetaData.Type == MetaComponents.Repeater.Type || MetaData.Type === MetaComponents.Token.Type) {
                MetaItem.Property = {
                    Default : {                 
                        Default : {       
                            backgroundColor : {
                                value : '#FFF'
                            },
                            position : {
                                value : 'relative'
                            },
                            display : {
                                value : 'flex'
                            },
                            flexDirection : {
                                value : Props.FlexValues.COLUMN
                            },
                            justifyContent : {
                                value : Props.FlexValues.FLEXSPACEAROUND
                            },
                            alignItems : {
                                value : Props.FlexValues.FLEXCENTER
                            }
                        }
                    }
                };
                if (MetaData.GridLayout) {
                    MetaItem.Property.Default.Default.display.value = 'grid';
                    MetaItem.Property.Default.Default = {
                        ...MetaItem.Property.Default.Default,
                        display : {
                            value : 'grid'
                        },
                        Grid : {
                            model : {
                                value : this.GetGridLayout().GridModel
                            },
                            rowGap : {
                                value : 16
                            },
                            columnGap : {
                                value : 16
                            }
                        }
                    }
                    MetaItem.Property.Grid = {
                        initialized : {value : true},
                        showGridLines : {value : true},
                    }
                    

                    delete MetaData.GridLayout;
                }
                else {
                    MetaItem.Property.Default.Default = {
                        ...MetaItem.Property.Default.Default,
                        display : {
                            value : 'flex'
                        },
                        flexDirection : {
                            value : Props.FlexValues.COLUMN
                        },
                        justifyContent : {
                            value : Props.FlexValues.FLEXSPACEAROUND
                        },
                        alignItems : {
                            value : Props.FlexValues.FLEXCENTER
                        }
                    }
                }

                if (MetaData.Type == MetaComponents.Repeater.Type || MetaData.Type == MetaComponents.Token.Type) {
                    MetaItem.Property.Default.Default.minWidth = {
                        value : 100
                    };
                    MetaItem.Property.Default.Default.minHeight = {
                        value : 100
                    }
                }                

                if (MetaData.Type == MetaComponents.Token.Type) {
                    delete MetaItem.Property.Default.Default.backgroundColor;
                }

                
            }
            else if (MetaData.Type == MetaComponents.Layout.Type) {               
                MetaItem.Property = {
                    Default : {
                        Default : {
                            MetaLayout : {
                                value : {
                                    baseType: 'Grid',                                
                                    Size: {
                                        width: 400,
                                        height: 400
                                    }
                                }
                            }
                        }
                    }
                }
            }            
            else if (MetaData.Type == MetaComponents.Text.Type) {
                MetaItem.Property = {
                    Default : {    
                        Default : {
                            textVerticalAlign : {
                                value : 'flexCenter'
                            },
                            height : {
                                value : 'auto'
                            },
                            text : {
                                value : ''
                            }
                        }
                    }
                };
                MetaItem.IsNew = true;
            }
            else if (MetaData.Type == MetaComponents.Svg.Type) {
                MetaItem.Property = {
                    Default : {
                        Default : {
                            width : {
                                value : 50
                            },
                            height : {
                                value : 50
                            }
                        }
                    }
                }
            }
            else if (MetaData.Type == MetaComponents.SvgIcon.Type) {
                MetaItem.Property = {
                    Default : {
                        Default : {
                            width : {
                                value : 50
                            },
                            height : {
                                value : 50
                            },
                            fill : {
                                value : '#b3b3b5'
                            }
                        }
                    }
                }
            }
            else if (MetaData.Type == MetaComponents.Input.Type) {
                MetaItem.Property = {
                    Default : {
                        Default : {
                            width : {
                                value : 120
                            },
                            height : {
                                value : 30
                            }                  
                        }      
                    }
                }
            }
            else if (MetaData.Type == MetaComponents.Component.Type) {
                MetaItem.Property = {
                    Default : {   
                        Default : {
                        }                 
                    }
                }                
            }
            else if (MetaData.Type == MetaComponents.Image.Type) {
                MetaItem.Property = {
                    Default : {
                        Default : {
                            width : {
                                value : 100,
                                Unit : '%'
                            },
                            minHeight : {
                                value : 100              
                            }
                        }
                    }
                };
            }    
            else if (MetaData.Type == MetaComponents.Widget.Type) {
                MetaItem.Property = {
                    Default : {
                        Default : {
                            width : {
                                value : 100,
                                Unit : '%'
                            },
                            height : {
                                value : 250
                            }
                        }
                    }
                }                               
            }            

            MetaItem = Utils.Merge(MetaItem, MetaData);
        }

        
        if (MetaData.Type == MetaComponents.Component.Type) {
            let Id = Utils.Get(MetaData, MetaData.Id, 'Property', 'Default', 'Default', 'Component', 'value', 'Id');
            if (Id)
                Utils.Set(MetaItem, Id, 'Property', 'Default', 'Default', 'Component', 'value', 'Id');
        }
        else if (MetaData.Type == MetaComponents.Layout.Type) {
            if (MetaData.Id) {
                const MetaLayout = ModelManager.GetModel(MetaData.Id);
                if (MetaLayout) {
                    Utils.Set(MetaItem,  Utils.DeepClone(MetaLayout), 'Property', 'Default', 'Default', 'MetaLayout', 'value');    
                }
            }
        }
        else if (MetaData.Type == MetaComponents.SvgIcon.Type) {
            let Provider = MetaData.Provider || Utils.Get(MetaData, null, 'Property', 'Default', 'Default', 'MetaIcon', 'Source', 'value');
            if (Provider && Utils.IsObject(Provider)) {
                Utils.Set(MetaItem, 'online', 'Property', 'Default', 'Default', 'MetaIcon', 'Source', 'value');
                Utils.Set(MetaItem, Provider, 'Property', 'Default', 'Default', 'MetaIcon', 'Info', 'value');             
            }
            else {
                if (Provider === 'online') {
                    Utils.Set(MetaItem, MetaData.Property.Default.Default.MetaIcon, 'Property', 'Default', 'Default', 'MetaIcon');
                }
                else {
                    let Id = Utils.Get(MetaData, MetaData.Id, 'Property', 'Default', 'Default', 'MetaIcon', 'TokenId', 'value');
                    if (Id) {
                        Utils.Set(MetaItem, 'token', 'Property', 'Default', 'Default', 'MetaIcon', 'Source', 'value');
                        Utils.Set(MetaItem, Id, 'Property', 'Default', 'Default', 'MetaIcon', 'TokenId');
                    }
                }                
            }    
        }
        else if (MetaData.Type == MetaComponents.Image.Type) {
            let Provider = MetaData.Provider || Utils.Get(MetaData, null, 'Property', 'Default', 'Default', 'MetaImage', 'Source', 'value');
            if (Provider && Utils.IsObject(Provider)) {
                if (Provider.Provider === 'unsplash') {
                    Utils.Set(MetaItem, Provider.Provider, 'Property', 'Default', 'Default', 'MetaImage', 'Source', 'value');
                    Utils.Set(MetaItem, Provider.url, 'Property', 'Default', 'Default', 'MetaImage', 'url', 'value');
                    Utils.Set(MetaItem, Provider, 'Property', 'Default', 'Default', 'MetaImage', 'Info', 'value');
                }                
            }
            else {
                if (Provider === 'unsplash') {
                    Utils.Set(MetaItem, MetaData.Property.Default.Default.MetaImage, 'Property', 'Default', 'Default', 'MetaImage');
                }
                else {
                    let Id = Utils.Get(MetaData, MetaData.Id, 'Property', 'Default', 'Default', 'MetaImage', 'TokenId', 'value');
                    if (Id) {
                        Utils.Set(MetaItem, 'token', 'Property', 'Default', 'Default', 'MetaImage', 'Source', 'value');
                        Utils.Set(MetaItem, Id, 'Property', 'Default', 'Default', 'MetaImage', 'TokenId');
                    }
                }                
            }            
        }
        else if (MetaData.Type == MetaComponents.Text.Type) {
            MetaItem.IsNew = true;
        }
        else if (MetaData.Type === MetaComponents.Widget.Type) {
            if (MetaData.Widget) {
                MetaItem.Widget = {
                    Type : MetaData.Widget.Type
                }
                if (MetaItem.Widget.Type === MetaComponents.Widget.WidgetTypes.eCharts.Type) {
                    if (MetaData.Widget.Chart) {                    
                        if (MetaData.Widget.Chart && MetaData.Widget.Chart.Type)
                            Utils.Set(MetaItem, MetaData.Widget.Chart.Type, 'Property', 'Default', 'Default', 'ChartType', 'value');
                        if (MetaData.Widget.Chart && MetaData.Widget.Chart.SubType)
                            Utils.Set(MetaItem, MetaData.Widget.Chart.SubType, 'Property', 'Default', 'Default', 'ChartSubType', 'value')
                    }
                    else {
                        Utils.Set(MetaItem, Utils.Get(MetaData, {}, 'Property', 'Default', 'Default'), 'Property', 'Default', 'Default');
                    }
                }
                else if (MetaItem.Widget.Type === MetaComponents.Widget.WidgetTypes.ApexCharts.Type) {
                    if (MetaData.Widget.Chart) {                    
                        if (MetaData.Widget.Chart && MetaData.Widget.Chart.Type)
                            Utils.Set(MetaItem, MetaData.Widget.Chart.Type, 'Property', 'Default', 'Default', 'ChartType', 'value');
                    }
                    else {
                        Utils.Set(MetaItem, Utils.Get(MetaData, {}, 'Property', 'Default', 'Default'), 'Property', 'Default', 'Default');
                    }
                }
            }                                            
        }
        else if (MetaData.Type === MetaComponents.Token.Type) {
            if (MetaData.Token) {
                MetaItem.Widget = {
                    Type : MetaData.Token.Type
                }               
                delete MetaItem.Token;
                Utils.Set(MetaItem, MetaData.Token.Template, 'Property', 'Default', 'Default', 'TokenOptions', 'Template', 'value');
            }                                            
        }
        else if (MetaData.Type === MetaComponents.Data.Type) {
            const data = MetaData.DataModel;
            if (data) {                
                if (data.type === DataTypes.string) {
                    MetaItem = this.FromType(MetaComponents.Text.Type);
                    
                    Utils.Set(MetaItem.Property.Default.Default, data.value, 'text', 'value');
                    MetaItem.BindToVariableAfterCreate = {
                        data : data
                    };
                    MetaItem.BindToVariableAfterCreate.path = ['Property', 'Default', 'Default', 'text'];
                    delete MetaItem.IsNew;
                }
                else if (data.type === DataTypes.MetaImage) {                    
                    MetaItem = this.FromType(MetaComponents.Image.Type);
                    Utils.Set(MetaItem.Property.Default.Default, Utils.Get(data.value, '', 'url'), 'MetaImage', 'url', 'value');
                    Utils.Set(MetaItem.Property.Default.Default, 'url', 'MetaImage', 'Source', 'value');

                    MetaItem.BindToVariableAfterCreate = {
                        data : data
                    };
                    MetaItem.BindToVariableAfterCreate.path = ['Property', 'Default', 'Default', 'MetaImage'];
                }
                else if (data.type === DataTypes.list) {                    
                    MetaItem = this.FromType(MetaComponents.Repeater.Type);
                    Utils.Set(MetaItem.Property.Default.Default, 'datasource', 'RepeatType', 'value');

                    MetaItem.BindToVariableAfterCreate = {
                        data : data
                    };
                    MetaItem.BindToVariableAfterCreate.path = ['Property', 'Default', 'Default', 'Data'];
                }

                if (MetaItem && MetaItem.BindToVariableAfterCreate) {
                    MetaItem.BindToVariableAfterCreate.fieldId = MetaData.DataModel.fieldId;
                }
            }
        }

        return MetaItem;
    },
    GetItemTypeForDataType(dataType) {
        if (dataType === DataTypes.string) 
            return MetaComponents.Text.Type;
        else if (dataType === DataTypes.MetaImage) 
            return MetaComponents.Image.Type;
        else if (dataType === DataTypes.list) 
            return MetaComponents.Repeater.Type;
        else
            return MetaComponents.Data.Type;
    },
    GetItemTypesForDataType(dataType) {
        const types = {
            default : this.GetItemTypeForDataType(dataType)
        };
        if (dataType === DataTypes.string) {
            types.others = [
                MetaComponents.Input.Type
            ];
        }
        else if (dataType === DataTypes.number) {
            types.others = [
                MetaComponents.Input.Type
            ];
        }
        else if (dataType === DataTypes.MetaImage) {
            types.others = [
                MetaComponents.Div.Type
            ];
        }
        return types;
    },
    GetGridLayout(options = {colCount : 4, rowCount : 4}) {
        return {
            baseType: 'Grid',                               
            GridModel : {                          
                Columns: {
                    Items: [
                        {
                            Id: Utils.Id(),
                            Single: {
                                size: {
                                    unit: 'fr',
                                    value: 1
                                }
                            },
                            Repeat: options.colCount
                        }
                    ]
                },
                Rows: {
                    Items: [
                        {
                            Id: Utils.Id(),
                            Single: {
                                size: {
                                    unit: 'fr',
                                    value: 1
                                }
                            },
                            Repeat: options.rowCount
                        }
                    ]
                },
                AutoColumns: {
                    Items: []
                },
                AutoRows: {
                    Items: []
                }
            }
        };
    }
}

export default ItemInitializer;



