import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    UIUtils,
    Strings,
    Globals,
    AppLayout
} from '../../../../../../../importer';

import {GroupTitle, TokenItemBox, TokenItemDragHandle, TokenItemBoxHoveredName} from '../../common';

import { SortableList, SortableListItem } from '../../../../../../../components/SortableContainer';
import { ListCardView } from '../../../../../../../components/light_dark_bar';
import TransitionTokenEditor from './item';
import { motion } from 'framer-motion';
import { GetEaseCurveTokenItem } from '../easing';
import { GetTransformTokenItem } from '../../transforms';
import { GetDurationTokenItem } from '../patterns';
import { GetEaseCurvePath } from '../easing/bezierEditor/presetcurve';
import { Seperator } from '../../../../right/iteminspector/styleitems/common';
import { StatefulTokenListItemName, StatefulTokenMarker, TokenLockedMarker } from '../../colors';
import { GRANT_TYPES } from '../../../../../manager';

export default class Transitions extends ReactBaseComponent
{
    constructor(props) {
        super(props);        
        
        this.PanelMode = this.props.isSelectList ? 'RightPanel' : 'LeftPanel';

        this.state.isListView = Globals.ProjectManager.Options.Get(true, this.PanelMode, 'DesignSystem', 'Transitions', 'ListView');        
        this.Load(this.props);

        if (Utils.IsNotNullOrEmpty(this.props.filterText)) {
            this.SearchToken(this.props.filterText);
        }

        this.Ref_PatternEdit = React.createRef();
        this.AddPattern = this.AddPattern.bind(this);
        this.onEditPattern = this.onEditPattern.bind(this);
        this.onDeletePattern = this.onDeletePattern.bind(this);
        this.onClonePattern = this.onClonePattern.bind(this);
        this.onCancelAddPattern = this.onCancelAddPattern.bind(this);
        this.onSubmitNewPattern = this.onSubmitNewPattern.bind(this);

        AppLayout.Refs.DesignSystem.Transitions = this;

        this.onSortPattern = this.onSortPattern.bind(this);
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.filterText !== nextProps.filterText) {
            setTimeout(() => {
                this.SearchToken(nextProps.filterText);
            }, 1);
            return false;
        }
        if (this.props.GlobalState !== nextProps.GlobalState || this.props.GlobalStateId !== nextProps.GlobalStateId || this.props.ReloadId !== nextProps.ReloadId) {
            this.Load(nextProps);
        }
        return true;
    }
    SearchToken(filter, DoNotUpdate) {
        let filterText;
        let filteredCount = 0;
        if (Utils.IsNotNullOrEmpty(filter))
            filterText = filter.toUpperCase();
        Utils.ForEach(this.patternItems, (token, i) => {
            delete token.filtered;
            if (filterText) {
                if (token.name.toUpperCase().indexOf(filterText) < 0) {                    
                    token.filtered = true;
                }
            }
        });
        if (!DoNotUpdate) {
            this.RenderId = Utils.Id();
            this.RCThrottledUpdate_1();
        } 
    }
    Load(props) {       
        this.patternItems = GetTransitionTokenList();
    }    
    Reload() {
        this.Load();
        this.RCUpdate();
    }
    SetListMode(isListView) {        
        Globals.ProjectManager.Options.Set(isListView, this.PanelMode, 'DesignSystem', 'Transitions', 'ListView');
        this.setState({
            isListView : isListView
        })
    }
    onSortPattern(oldIndex, newIndex) {
        Utils.ChangePlace(this.patternItems, oldIndex, newIndex);
        Globals.ProjectManager.Tokens.ChangeOrder(Globals.ProjectManager.Tokens.Types.Transitions, oldIndex, newIndex);        
        this.RCUpdate();
    }
    AddPattern() {
        if (!this.props.hasEditGrant) {
            return;
        }

        this.IsNew = true;
        this.EditPatternId = Utils.Id();
        this.EditPatternModel = {
            name : 'New Transition',
            values : []
        };
        
        this.props.onPanelOverlay({
            show : true,
            render : () => {
                return (
                    <TransitionTokenEditor 
                        isNew
                        ref={this.Ref_PatternEdit}
                        newModel={this.EditPatternModel}
                        onCancelAddPattern={this.onCancelAddPattern}
                        onSubmitNewPattern={this.onSubmitNewPattern}
                        RefToolbar={this.props.RefToolbar}
                        offline
                    />
                )
            }
        })
    }
    onEditPattern(id) {         
        if (!this.props.hasEditGrant) {
            return;
        }

        this.EditPatternId = id;
        this.props.onPanelOverlay({
            show : true,
            render : (props) => {
                return (
                    <TransitionTokenEditor 
                        ref={this.Ref_PatternEdit}
                        id={id}
                        onCancelAddPattern={this.onSubmitNewPattern}
                        onSubmitNewPattern={this.onSubmitNewPattern}
                        onDeletePattern={this.onDeletePattern}
                        onClonePattern={this.onClonePattern}
                        GlobalStateId={props.GlobalStateId}
                        RefToolbar={this.props.RefToolbar}
                    />
                )
            }
        })
    }    
    onDeletePattern() {
        if (Globals.ProjectManager.Tokens.Delete(Globals.ProjectManager.Tokens.Types.Transitions, this.EditPatternId)) {
            this.Load(this.props);
            this.onCancelAddPattern();
        }
    }
    onClonePattern() {
        const cloneId = Globals.ProjectManager.Tokens.Clone(this.EditPatternId, Globals.ProjectManager.Tokens.Types.Transitions);
        this.Load(this.props);
        this.onEditPattern(cloneId);
        // this.props.onUpdate();
    }
    onCancelAddPattern() {
        delete this.IsNew;
        delete this.EditPatternModel;
        delete this.EditPatternId;

        this.props.onPanelOverlay({close : true});
        this.RCUpdate();
    }
    onSubmitNewPattern() {
        if (this.IsNew) {
            this.patternItems.push(AddNewTransitionToken(this.EditPatternModel, this.EditPatternId));

            this.WillScrollTo = this.EditPatternId;
            this.selectedId = this.EditPatternId;
        }        
        else {
            const token = Globals.ProjectManager.Tokens.Token(this.EditPatternId);            
            
            if (token) {
                const transitionItem = GetTransitionTokenItem(this.EditPatternId);
                const index = Utils.FindIndex(this.patternItems, (item) => {return item.id === this.EditPatternId});
                this.patternItems[index] = transitionItem;
                Events.BCE(Events.GLOBAL.TOKEN_VALUE_CHANGING);
            }            
        }
                
        Events.BCE(Events.GLOBAL.TOKENS_CHANGED);
        this.onCancelAddPattern();
    }    

    renderCustom() {
        
        const patternItems = [];        

        Utils.ForEach(this.patternItems, (pattern, i) => {
            pattern && !pattern.filtered && patternItems.push(                    
                <SortableListItem
                    key={pattern.id}
                    draggableId={pattern.id}
                    index={i}
                    ItemBoxType={TransitionListItem}
                    isDragDisabled={!this.HasEditGrant}
                    BoxProps={{
                        listView : this.state.isListView,
                        transition : pattern,
                        onClick : this.onEditPattern.bind(this, pattern.id)
                    }}                
                />  
            )
        });   
        return (
            <SC.FCol style={{paddingBottom : '4px', marginTop : '16px'}}>
                <GroupTitle 
                    title='Transitions' 
                    subGroup
                    hasAdd={this.props.hasEditGrant ? {onAdd : this.AddPattern} : null} 
                    style={{paddingLeft : '12px'}}
                    addOn={(
                        <ListCardView 
                            onListView={this.SetListMode.bind(this, true)}
                            onCardView={this.SetListMode.bind(this, false)}
                            isListView={this.state.isListView}
                            isCardView={!this.state.isListView}
                            compact
                        />
                    )}
                />
                <SortableList
                    style={this.state.isListView ? {
                        paddingLeft : '8px', paddingRight : '8px', marginTop : '6px'
                    } : {
                        display : 'grid',
                        gridGap : '4px',
                        gridTemplateColumns : 'repeat(auto-fill, minmax(100px, 1fr))',
                        gridAutoRows : 'auto',
                        padding : '8px',
                        transition : 'all 0.2s ease'
                    }}
                    onSort={this.onSortPattern}
                >
                    {patternItems}
                </SortableList>
            </SC.FCol>
        )
    }
}

export const TransitionItem = ({pattern, listView, notDraggable, sortableProps = {}, style, ...rest}) => {
    const style_box = listView ? {padding : '4px', paddingLeft : '6px', ...SC.Styles.Flex.RowAlcJsb} : {
        
    };

    if (!listView) {
        return (
            <TokenItemBox {...rest} {...sortableProps.handleProps} style={style_box} title={pattern.name}>                
                animation
            </TokenItemBox>            
        )
    }

    return (
        <TokenItemBox {...rest} style={{...style, ...style_box}}>
            <SC.FRow f1 alc overflowHidden>
                {
                    !notDraggable && 
                    <TokenItemDragHandle {...sortableProps.handleProps} />
                }
                <TokenItemBoxHoveredName style={sortableProps ? {} : {transform : 'translateX(0)'}}>
                    <SC.TextDivAbbr style={{flex : 1}}>
                        {pattern.name}
                    </SC.TextDivAbbr>
                </TokenItemBoxHoveredName>
            </SC.FRow>                
            animation
        </TokenItemBox>
    )
}


export const TransitionCardItem = ({transition, justContent, onClick, sortableProps, valueState, onPreview, selected, small, listView, style, ...rest}) => {    
    const size = '100px';
    const box = '100px';
    const style_box = {
        cursor : 'pointer',
        backgroundColor : SC.CurrentTheme.theme.back,            
        width : listView ? box : '100%', height : small ? 'unset' : '120px', marginBottom : small ? 0 : '4px', ...SC.Styles.Flex.Cell, overflow : 'hidden', alignSelf : 'stretch'
    };
    if (small) {
        style_box.backgroundImage = 'unset';
    }
    else {
        if (selected) {
            style_box.borderLeft = '2px solid';
            style_box.borderLeftColor = SC.CurrentTheme.theme.color_brand;
        }
    }
    const slashMotion = {
        rest: { 
            scaleX : 1,
            scaleY : 1,
            rotate : 0,
            x : 0,
            y : 0,
            opacity : 1
        },
        hover: {
            // scaleX: 2,
            // roate: 45,
            transition: {
                duration: 0.4,
                type: "tween",
                ease: "easeIn",
            }
        }
    };
    if (transition.ease && transition.ease.path) {
        slashMotion.hover.transition.ease = [
            Number(transition.ease.path.x1),
            Number(transition.ease.path.y1),
            Number(transition.ease.path.x2),
            Number(transition.ease.path.y2) 
        ];        
    }
    if (transition.duration && Utils.IsNotNullOrEmpty(transition.duration.timeValue)) {
        slashMotion.hover.transition.duration = transition.duration.timeValue / 1000;
    }
    slashMotion.rest.transition = slashMotion.hover.transition;
    let transformOrigin = 'center center';

    if (transition.transform && transition.transform.motionValue) {
        Utils.ForEach(transition.transform.motionValue, (value, prop) => {
            slashMotion.hover[prop] = value;
            // slashMotion.rest[prop] = 0;
        });
        if (transition.transform.value && transition.transform.value.transformOrigin)
            transformOrigin = transition.transform.value.transformOrigin;
    }

    if (Utils.IsNotNullOrEmpty(transition.opacity)) {
        slashMotion.hover.opacity = transition.opacity;
    }

    const motionContent = (
        <motion.div style={{width : size, height : size, borderRadius : '3px', backgroundColor : 'rgba(184, 109, 240, 0.34)', position : 'relative', transformOrigin : 'center', transform : small ? 'scale(0.3)' : 'scale(0.7)'}}>
            <motion.div
                style={{
                    width : size, height : size, borderRadius : '4px', backgroundColor : 'rgba(0, 125, 255, 0.5)', ...SC.Styles.Absolute,
                    transformOrigin : transformOrigin
                }} 
                variants={slashMotion}
            >
                {
                    transition.ease && transition.ease.path && 
                    <SC.Svg viewBox="0 0 100 100" width={100} height={100}>
                        <path d={GetEaseCurvePath({...transition.ease.path, size : 80})} fill='none' stroke='#94a9ff' strokeWidth={3} strokeLinecap='round' style={{transform : 'translate(10px, 10px)'}} />
                    </SC.Svg>
                }                
            </motion.div>                        
        </motion.div>
    );
    if (justContent) {
        return motionContent;
    }
    let preview = (
        <motion.div
            style={{
                ...SC.GetGridBackgroundStyle(true, true),
                ...style_box,
                pointerEvents : 'none',
                position : 'relative'
            }}            
            // whileHover='hover'
        >
            {motionContent}
            {
                !listView && valueState && <StatefulTokenMarker
                    style={{
                        position : 'absolute',
                        top : '4px',
                        left : '4px'
                    }}
                    {...valueState}
                />
            }
            {
                transition.locked && <TokenLockedMarker overlay />                
            }
        </motion.div>
        // <SC.GridBackground small {...Utils.JustGet(sortableProps, {}, 'handleProps')} style={style_box} dark={!SC.CurrentTheme.theme.isLight} {...rest} onClick={onClick} {...onPreview}>
        //     <div style={{width : size, height : size, borderRadius : '3px', backgroundColor : 'rgba(184, 109, 240, 0.34)', position : 'relative', transformOrigin : 'center', transform : small ? 'scale(0.3)' : 'scale(0.7)'}}>
        //         <motion.div
        //             style={{
        //                 width : size, height : size, borderRadius : '4px', backgroundColor : 'rgba(0, 125, 255, 0.5)', ...SC.Styles.Absolute,
        //                 // ...transition.value
        //             }} 
        //             whileHover={{
        //                 scale : 1.2,
        //                 raote : 45,
        //             }}
        //         >
        //         </motion.div>                        
        //     </div>           
        // </SC.GridBackground>
    )
    return preview;   
}

export const TransitionListItem = ({onSelect, notDraggable, style, listView, sortableProps, transition, onPreview, selected, ...rest}) => {
    const style_box = {
        ...style,
        flex : 1,
        padding : '0px', 
        paddingLeft : listView ? '6px' : 0,
        ...SC.Styles.Flex.RowAlcJsb,
        alignItems : 'stretch'
    }
    if (selected) {
        style_box.borderLeft = '1px solid';
        style_box.borderLeftColor = SC.CurrentTheme.theme.color_brand;
    }

    return (
        <motion.div
            whileHover='hover'
            initial='rest'
            // animate='rest'
        >
            <TokenItemBox onClick={onSelect} style={style_box} {...onPreview} {...rest} selected={selected}>
                {
                    listView && 
                    <SC.FCol f1 overflowHidden style={{padding : '4px', paddingTop : '8px'}}>
                        <SC.FRow alc overflowHidden>
                            <StatefulTokenListItemName 
                                name={transition.name}
                                sortableProps={sortableProps}
                                ValueState={transition.valueState}
                                locked={transition.locked}
                            />
                        </SC.FRow>
                        <Seperator />
                        <SC.FCol f1 style={{...SC.Styles.FontStyles.Monospace}}>
                            {
                                transition.ease && 
                                <SC.FRow overflowHidden>
                                    <SC.TextDivAbbr style={{flex : 1, lineHeight : '20px'}}>
                                        {transition.ease.name}
                                    </SC.TextDivAbbr>
                                </SC.FRow>
                            }
                            {
                                transition.duration && transition.duration.id && transition.duration.pattern &&
                                <SC.FRow overflowHidden alc>                                
                                    <SC.TextDivAbbr style={{flex : 1, lineHeight : '20px'}}>
                                        <span style={{marginRight : '4px'}}>Duration</span>[{transition.duration.timeValue} ms] {transition.duration.pattern.name}
                                    </SC.TextDivAbbr>
                                </SC.FRow>
                            }
                            {
                                transition.delay && transition.delay.id && transition.delay.pattern &&
                                <SC.FRow overflowHidden alc>
                                    <SC.TextDivAbbr style={{flex : 1, lineHeight : '20px'}}>
                                    <span style={{marginRight : '4px'}}>Delay</span>[{transition.delay.timeValue} ms] {transition.delay.pattern.name}
                                    </SC.TextDivAbbr>
                                </SC.FRow>
                            }
                            {
                                transition.transform && 
                                <SC.FRow overflowHidden>
                                    <SC.TextDivAbbr style={{flex : 1, lineHeight : '20px'}}>
                                        {transition.transform.name}
                                    </SC.TextDivAbbr>
                                </SC.FRow>
                            }
                        </SC.FCol>                    
                    </SC.FCol>    
                }                                            
                <TransitionCardItem
                    transition={transition}
                    key={transition.id} 
                    notDraggable={notDraggable}
                    small
                    listView={listView}
                    valueState={transition.valueState}
                />   
            </TokenItemBox>
        </motion.div>        
    )
}

export const AddNewTransitionToken = (model, id) => {
    Globals.ProjectManager.Tokens.Add({
        type : Globals.ProjectManager.Tokens.Types.Transitions,
        name : model.name,
        easeId : { Default : { value : model.easeId } },
        durationId : { Default : { value : model.durationId } },
        delayId : { Default : { value : model.delayId } },
        transformId : { Default : { value : model.transformId } },
        opacity : { Default : { value : model.opacity } },                
        effectsFrom : model.effectsFrom,
        id : id
    })
    
    return GetTransitionTokenItem(id);
}

export const GetTransitionTokenList = (StateArray) => {
    const tokenids = Globals.ProjectManager.Tokens.Transitions();
    return GetTransitionTokenListOfIds(tokenids, StateArray);
}
export const GetTransitionTokenListOfIds = (tokenids, StateArray) => {
    const items = [];

    Utils.ForEach(tokenids, (id, i) => {
        items.push(GetTransitionTokenItem(id, StateArray));
    });  
    return items;
}

export const GetTransitionTokenItem = (id, StateArray) => {
    
    const token = Globals.ProjectManager.Tokens.Token(id);
    if (!token)
        return null;
    const transition = {
        id : id,
        name : token.name,
        ease : {
            name : 'Select Ease Curve'
        },
        duration : {
            pattern : {   
                name : 'Select Duration'             
            }
        },
        delay : {
            pattern : {   
                name : 'Select Delay'             
            }
        },
        transform : {
            name : 'Select Transform'
        }
    }    
    const info = {};
    const easeId = Globals.ProjectManager.Tokens.ValueOf({model : token, name : 'easeId', statearray : StateArray, info : info});            
    if (easeId)
        transition.ease =  GetEaseCurveTokenItem(easeId, StateArray) ||{};   

    const durationId = Globals.ProjectManager.Tokens.ValueOf({model : token, name : 'durationId', statearray : StateArray, info : info});
    if (durationId) {
        const durationPattern = Globals.ProjectManager.Tokens.TimePatterns.GetPattern(durationId);
        if (durationPattern) {
            transition.duration = GetDurationTokenItem(durationPattern, durationId, StateArray);
        }
    }
    const delayId = Globals.ProjectManager.Tokens.ValueOf({model : token, name : 'delayId', statearray : StateArray, info : info});
    if (delayId) {
        const delayPattern = Globals.ProjectManager.Tokens.TimePatterns.GetPattern(delayId);
        if (delayPattern) {
            transition.delay = {
                id : delayId,
                pattern : delayPattern,
                timeValue : Globals.ProjectManager.Tokens.TimePatterns.GetTimeSize(delayPattern, null, null, StateArray)
            }
        }
    }            
    const transformId = Globals.ProjectManager.Tokens.ValueOf({model : token, name : 'transformId', statearray : StateArray, info : info});
    if (transformId) {
        const transform = GetTransformTokenItem(transformId, StateArray);
        if (transform)
            transition.transform = transform;
        else {
            Globals.ProjectManager.Tokens.SetValue({
                id : id,
                name : 'transformId',
                value : null,
                type : Globals.ProjectManager.Tokens.Types.Transitions
            });
        }
    }

    transition.opacity = Globals.ProjectManager.Tokens.ValueOf({model : token, name : 'opacity', statearray : StateArray, info : info});
    transition.effectsFrom = Utils.IsTrue(token.effectsFrom);
    transition.valueState = info.ValueState;
    transition.locked = token.locked

    return transition;
}