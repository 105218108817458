import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    AppState,
    Utils,
    Strings,
    MetaData
} from '../../../../importer';
import styled, {css} from 'styled-components';
import DragSource from '../../../../components/dragdrop/HoCDragSource';
import { Widget_Chart_Icons, WidgetIcon } from '../../panels/left/meta_item_sources/itemicon';
import { ElementBox } from '../../panels/left/toolbar/UIElements/element';
import RadioGroup from '../../../../components/editors/radiogroup';


const DragSourceComponent = DragSource(ElementBox);

export default class Select_Widget extends ReactBaseComponent
{
    constructor(props) {
        super(props);
    }
    
    renderCustom() {                
        let WidgetItems = [];
        let ChartItems = null;
        
        Utils.ForEach(MetaData.Components.Widget.WidgetTypes, (WidgetType, type) => {
            if ((WidgetType.Type === MetaData.Components.Widget.WidgetTypes.eCharts.Type || WidgetType.Type === MetaData.Components.Widget.WidgetTypes.ApexCharts.Type)) {
                if (!ChartItems) {                    
                    ChartItems = [];
                    let charts = [];
                    let Type = MetaData.Components.Widget.WidgetTypes.eCharts.Type;
                    if (this.ChartProvider === MetaData.Components.Widget.WidgetTypes.ApexCharts.Type) {
                        Type = MetaData.Components.Widget.WidgetTypes.ApexCharts.Type;
                        charts = [
                            {
                                Type : 'Line',
                                Label : 'Line',
                                Icon : Widget_Chart_Icons.Line
                            },
                            {
                                Type : 'Area',
                                Label : 'Area',
                                Icon : Widget_Chart_Icons.LineArea
                            },
                            {
                                Type : 'Column',
                                Label : 'Column',
                                Icon : Widget_Chart_Icons.Column
                            },
                            {
                                Type : 'Bar',
                                Label : 'Default',
                                Icon : Widget_Chart_Icons.Bar
                            },
                            {
                                Type : 'Pie',
                                Label : 'Pie',
                                Icon : Widget_Chart_Icons.Pie
                            },
                            {
                                Type : 'Doughnut',
                                Label : 'Doughnut',
                                Icon : Widget_Chart_Icons.Doughnut
                            },
                            {
                                Type : 'Radial',
                                Label : 'Radial',
                                Icon : Widget_Chart_Icons.Doughnut
                            },
                            {
                                Type : 'Bubble',
                                Label : 'Bubble',
                                Icon : Widget_Chart_Icons.Bubble
                            },
                            {
                                Type : 'Scatter',
                                Label : 'Scatter',
                                Icon : Widget_Chart_Icons.Scatter
                            },
                            {
                                Type : 'Heatmap',
                                Label : 'Heatmap',
                                Icon : Widget_Chart_Icons.Heatmap
                            }
                        ];
                    }
                    else {
                        charts = [
                            {
                                Type : 'Line',
                                SubType : 'Default',
                                Icon : Widget_Chart_Icons.Line
                            },
                            {
                                Type : 'Line',
                                Label : 'Area',
                                SubType : 'Area',
                                Icon : Widget_Chart_Icons.LineArea
                            },
                            {
                                Type : 'Bar',
                                SubType : 'Default',
                                Icon : Widget_Chart_Icons.Bar
                            },
                            {
                                Type : 'Pie',
                                SubType : 'Default',
                                Icon : Widget_Chart_Icons.Pie
                            },
                            {
                                Type : 'Pie',
                                Label : 'Doughnut',
                                SubType : 'Doughnut',
                                Icon : Widget_Chart_Icons.Doughnut
                            },
                            {
                                Type : 'Scatter',
                                SubType : 'Default',
                                Icon : Widget_Chart_Icons.Bubble
                            },
                            {
                                Type : 'Scatter',
                                Label : 'Bubble',
                                SubType : 'Bubble',
                                Icon : Widget_Chart_Icons.Bubble
                            },
                            {
                                Type : 'Sunburst',
                                SubType : 'Default',
                                Icon : Widget_Chart_Icons.Sunburst
                            },
                            {
                                Type : 'Funnel',
                                SubType : 'Default',
                                Icon : Widget_Chart_Icons.Funnel
                            },
                            {
                                Type : 'Gauge',
                                SubType : 'Default',
                                Icon : Widget_Chart_Icons.Gauge
                            },
                            {
                                Type : 'Heatmap',
                                SubType : 'Default',
                                Icon : Widget_Chart_Icons.Heatmap
                            },
                            {
                                Type : 'Pictorial',
                                SubType : 'Default',
                                Icon : Widget_Chart_Icons.Line
                            },
                            {
                                Type : 'Pictorial',
                                Label : 'Pictorial Bar',
                                SubType : 'Bar',
                                Icon : Widget_Chart_Icons.Line
                            }
                        ];
                    }

                    ChartItems.push(
                        <SC.FCol style={{padding:'8px', paddingTop : 0}}>
                            <SC.FRow>
                                <RadioGroup
                                    small
                                    itemStyle={{border : 'none'}}
                                    Container={{Type : SC.FRow, Props : {alignStretch : true, style : {marginBottom:'4px'} }}}
                                    items={[
                                        {id : MetaData.Components.Widget.WidgetTypes.eCharts.Type, label : 'ECharts'},
                                        {id : MetaData.Components.Widget.WidgetTypes.ApexCharts.Type, label : 'ApexCharts'}
                                    ]}
                                    value={this.ChartProvider || 'eCharts'}
                                    onSelect={(sid) => {this.ChartProvider = sid; this.RCUpdate()}}
                                />
                            </SC.FRow>
                            <div style={{
                                display : 'grid',
                                gridTemplateColumns : 'repeat(2, 1fr)',
                                gridGap : '8px',
                                justifyItems : 'stretch',
                                alignItems : 'center',
                                paddign : '4px'
                            }}>
                                {
                                    charts.map((ct, i) => {
                                        return (
                                            <DragSourceComponent    
                                                style={{
                                                    minHeight : 'unset',
                                                    height : '50px',
                                                    alignSelf : 'stretch',
                                                    margin : 0,
                                                    fontSize : '11px',
                                                    flex : 1
                                                }}
                                                DragItem={{
                                                    Type : Strings.TOOLBARITEM,
                                                    Item : {
                                                        MetaItem : {
                                                            Type : MetaData.Components.Widget.Type,
                                                            Widget : {
                                                                Type : Type,
                                                                Chart : {
                                                                    Type : ct.Type,
                                                                    SubType : ct.SubType
                                                                }
                                                            }
                                                        }
                                                    }
                                                }}
                                            >
                                                <SC.FCol alc ja f1>
                                                    <ct.Icon size={30}/>
                                                    <span>{ct.Label || ct.Type}</span>
                                                </SC.FCol>                                           
                                            </DragSourceComponent>
                                        )
                                })
                                }
                            </div>
                        </SC.FCol>
                    )
                }
            }
            else if (WidgetType.Type === MetaData.Components.Widget.WidgetTypes.Spotify.Type) {

            }
            else {
                
                WidgetItems.push(
                    <DragSourceComponent 
                        key={type} 
                        style={{
                            minHeight : 'unset',
                            height : '60px',
                            alignSelf : 'stretch',
                            margin : 0,
                            fontSize : '11px',
                            flex : 1,
                            padding : '4px'
                        }}
                        DragItem = {{
                            Type : Strings.TOOLBARITEM,
                            Item : {
                                MetaItem : {
                                    Type : MetaData.Components.Widget.Type,
                                    Widget : {
                                        Type : WidgetType.Type
                                    }
                                }
                            }                                    
                        }} 
                    >
                        <SC.FCol alc ja f1>
                            {WidgetIcon(WidgetType.Type)}
                            <span>{WidgetType.Label}</span>
                        </SC.FCol>
                    </DragSourceComponent>
                )
            }
            
        });        
        let content = (
            <SC.FCol>
                <SC.FCol style={{padding:'8px'}}>
                    <div style={{
                        display : 'grid',
                        gridTemplateColumns : 'repeat(2, 1fr)',
                        gridGap : '8px',
                        justifyItems : 'stretch',
                        alignItems : 'center',
                        paddign : '4px'
                    }}>
                        {WidgetItems}
                    </div>
                </SC.FCol>
                {ChartItems}
            </SC.FCol>
        )
        if (this.props.justContent)
            return content;
        return (
            <React.Fragment>                                                         
                <SC.FCol f1>
                    <SC.CustomScrollbars hideTracksWhenNotNeeded autoHide>
                        {content}
                    </SC.CustomScrollbars>
                </SC.FCol>
            </React.Fragment>
        )
    }
}