import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    AppLayout,
    Globals
} from '../../../../../../importer';

import styled from 'styled-components';
import {TokenGroup, TokenItemBox, TokenPanelHeader} from '../common';
import { ListCardView } from '../../../../../../components/light_dark_bar';
import { motion } from 'framer-motion';
import { BaseSizeSlider, ScaleDropdown } from '../typography';
import SpacePatterns from './patterns';
import { LeftScrollPanel } from '../../common';
import { GRANT_TYPES } from '../../../../manager';

export default class Spacings extends ReactBaseComponent
{
    constructor(props) {
        super(props);        
        
        this.onChangeBaseSize = this.onChangeBaseSize.bind(this);
        this.onChangingBaseSize = this.onChangingBaseSize.bind(this);
        this.SelectExactScale = this.SelectExactScale.bind(this);
        this.onChangeCustomScale = this.onChangeCustomScale.bind(this);

        this.AddSpaceToken = this.AddSpaceToken.bind(this);

        this.BroadcastThemeChangeThrottled = this.BroadcastThemeChangeThrottled.bind(this);
        this.BroadcastThemeChange = Utils.Throttle(this.BroadcastThemeChangeThrottled, 250);

        this.model = Utils.DeepClone(Globals.ProjectManager.Tokens.SpaceScale.Get());

        this.scaleValue = (this.model.ratio - 1) * 1000;
        this.onToggleExpand = this.onToggleExpand.bind(this);
        this.expanded = this.props.singleView || Globals.ProjectManager.Options.Get(false, 'LeftPanel', 'DesignSystem', 'Spacing', 'Expanded');
        this.HasEditGrant = Globals.ProjectManager.CheckGrant(GRANT_TYPES.EDIT_TOKEN.ALL);
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.GlobalState !== nextProps.GlobalState || this.props.GlobalStateId !== nextProps.GlobalStateId) {
            this.model = Utils.DeepClone(Globals.ProjectManager.Tokens.SpaceScale.Get());
            this.scaleValue = (this.model.ratio - 1) * 1000;
        }
        return true;
    }
    onToggleExpand() {
        this.expanded = !this.expanded;
        Globals.ProjectManager.Options.Set(this.expanded, 'LeftPanel', 'DesignSystem', 'Spacing', 'Expanded');
        this.RCUpdate();
    }    
    onChangeBaseSize(value) {
        this.ChangeLogged = false;
        Globals.ProjectManager.Offline = false;
        this.ChangeBaseSize(value);
    }
    onChangingBaseSize(value) {
        if (!this.ChangeLogged) {
            Globals.ProjectManager.LogTokenChange({Desc : 'Change Space Base Size'});
            this.ChangeLogged = true;
        }
        Globals.ProjectManager.Offline = true;
        this.ChangeBaseSize(value);        
    }
    ChangeBaseSize(value) {
        const scaleModel = this.model;        
        scaleModel.baseSize = value;
        Globals.ProjectManager.Tokens.SpaceScale.SetScale(scaleModel);
        this.spacingId = Utils.Id();
        this.RCUpdate();
        this.BroadcastThemeChange();
    }        
    SelectExactScale(id, item) {
        Globals.ProjectManager.LogTokenChange({Desc : 'Change Space Base Size'});
        this.model.scaleType = item.id;
        this.model.ratio = item.value;
        Globals.ProjectManager.Tokens.SpaceScale.SetScale(this.model);
        this.scaleValue = item.value;
        this.BroadcastThemeChange();
        this.spacingId = Utils.Id();
        this.RCUpdate();
    }
    onChangeCustomScale(value) {
        Globals.ProjectManager.LogTokenChange({Desc : 'Change Space Scale'});
        this.model.scaleType = 'custom';
        this.model.ratio = value;
        Globals.ProjectManager.Tokens.SpaceScale.SetScale(this.model);
        this.BroadcastThemeChange();
        this.spacingId = Utils.Id();
        this.RCUpdate();
    }
    BroadcastThemeChangeThrottled() {
        Globals.ProjectManager.UpdateTokenValues({spacePatterns : true});
        const patterns = Globals.ProjectManager.Tokens.SpacePatterns.Get();
        const changes = [];
        Utils.ForEach(patterns, (patternId, i) => {
            changes.push({
                Id : patternId,
                Type : Globals.ProjectManager.Tokens.Types.Spacings
            });

            const aliases = Globals.ProjectManager.Tokens.GetAliaseIdsOfTokenId(patternId);
            if (aliases && Array.isArray(aliases) && aliases.length > 0) {
                aliases.map((aliaseId) => {
                    changes.push({
                        Id : aliaseId,
                        Type : Globals.ProjectManager.Tokens.Types.Spacings
                    });
                })
            }
        });    
        Events.BCE(Events.GLOBAL.TOKEN_VALUE_CHANGING, changes);
    }
    AddSpaceToken() {
        this.IsNew = true;
        this.EditPatternId = Utils.Id();        
        this.EditPatternModel = Globals.ProjectManager.Tokens.SpacePatterns.NewPattern(this.EditPatternId);
        this.EditPatternModel.scaleIndex = 0;
        this.EditPatternModel.name = 'New Space';

        this.EditToken(this.EditPatternModel);
    }        
    renderCustom() {
        const scaleIndex = Utils.FindIndex(Globals.ProjectManager.Tokens.Utils.ScalePresets, (preset) => {return preset.id === this.model.scaleType});        
        const scalePreset = Globals.ProjectManager.Tokens.Utils.ScalePresets[scaleIndex];
        
        let content = (this.expanded || this.props.singleView) && (
            <motion.div
                initial={{opacity : 0.5, y : -4}}
                animate={{opacity : 1, y : 0}}
                transition={{duration : 0.2}}
                style={{paddingLeft : '2px', paddingRight : '2px'}}
            >
                <SC.FCol style={{padding : '8px', paddingLeft : '12px', fontSize : '11px', marginBottom : '16px'}}>
                    <BaseSizeSlider 
                        title='Base Space Size'
                        min={4}
                        baseSize={this.model.baseSize} 
                        onChangeBaseSize={this.onChangeBaseSize} 
                        onChangingBaseSize={this.onChangingBaseSize} 
                        readOnly={!this.HasEditGrant}
                    />
                    <ScaleDropdown title='Space Scale'
                        max={200}
                        label={scalePreset.label} 
                        value={this.model.scaleType}
                        scaleValue={this.model.ratio}
                        selectedIndex={scaleIndex-1}
                        SelectPreset={this.SelectExactScale}
                        onChangeCustom={this.onChangeCustomScale}
                        readOnly={!this.HasEditGrant}
                    />
                </SC.FCol>
                <SpacePatterns 
                    hasEditGrant={this.HasEditGrant}
                    onPanelOverlay={this.props.onPanelOverlay} 
                    spacingId={this.spacingId}
                    baseSize={this.model.baseSize}
                    ratio={this.model.ratio}
                    onAddPattern={this.AddSpaceToken}
                    GlobalStateId={this.props.GlobalStateId}
                    notDraggable
                    onSelectPattern={this.props.onSelectPattern}
                    onShowMenu={this.props.onShowMenu}
                    filterText={this.props.filterText}
                    onUpdate={() => {
                        this.state.ShouldUpdate = true;
                        this.props.onUpdate();
                    }}
                />
            </motion.div>
        )
        

        if (this.props.singleView ) {
            if (!this.props.noScrolls)
                content = (
                    <LeftScrollPanel>
                        {content}
                    </LeftScrollPanel>
                )
        }
        else {
            content = (
                <TokenGroup 
                    title='SPACING' 
                    hideTitle={this.props.singleView}
                    last={this.props.last} 
                    expandable={{expanded : this.expanded,  onExpand : this.onToggleExpand }}
                >
                    {content}                
                </TokenGroup>
            )
        }
        return (
            <React.Fragment>
                {this.props.children}
                {content}
            </React.Fragment>
        )
    }
}