import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Events,
    AppLayout,
    MetaData,
    Utils,
    Strings,
    Globals
} from '../../../../../../../importer';

import NumberInput from '../../../../../../../components/editors/input_number';
import DropDownSelect from '../../../../../../../components/editors/enum_dropdown';
import TextInput from '../../../../../../../components/editors/textinput';
import VariableBinder from '../variable';
import { PropertyLabel, StyleGroupTokenItem, Seperator, StyleGroupTitle } from '../common';
import { BooleanEditor } from '../hidden';
import { TextContentPropertyEditor } from '../textContent';
import { ImageSourceSelectoer, ImageTokenSelectItem, ImagePositionEditor, ImageSizeAndPositionEditor } from '../image';
import { IconTokenSelectItem, RenderIconTokenPreview } from '../icon';


export default class ComponentPropertyItem extends ReactBaseComponent
{
    constructor(props) {
        super(props);
        this.SelectTextContent = this.SelectTextContent.bind(this);
        this.SelectImageToken = this.SelectImageToken.bind(this);
        this.SetImagePropertyValues = this.SetImagePropertyValues.bind(this);
        this.SetImagePropertyModel = this.SetImagePropertyModel.bind(this);
        this.SelectIconToken = this.SelectIconToken.bind(this);
        this.ToggleImageSource = this.ToggleImageSource.bind(this);
        this.ShowUnsplashGallery = this.ShowUnsplashGallery.bind(this);
        this.ToggleTextSource = this.ToggleTextSource.bind(this);

        this.LoadModelType(this.props);
    }   
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.Id !== nextProps.Id) {
            this.LoadModelType(nextProps);
        }
        return true;
    }
    LoadModelType(props) {
        const ModelId = Utils.JustGet(props.value, null, 'ModelId');
        this.ValueType = null;
        if (ModelId)
            this.ValueType = 'model';
        else if (Utils.JustGet(props.value, null, 'TokenId'))
            this.ValueType = 'token';
        else {
            if (props.model.dataType === MetaData.DataTypes.image) {
                this.ValueType = 'url';
                const url = Utils.JustGet(props.value, null, 'Value', 'url');
                if (!Utils.IsNotNullOrEmpty(url) || props.value.isValueDefault) {
                    if (props.model.DataId && props.value.MockupId) {
                        this.ValueType = 'mockup';
                    }
                }            
            }
            else {
                this.ValueType = 'custom';
                const customValue = Utils.JustGet(props.value, null, 'Value');
                if (!Utils.IsNotNullOrEmpty(customValue) || props.value.isValueDefault) {
                    if (props.model.DataId && props.value.MockupId) {
                        this.ValueType = 'mockup';
                    }
                }            
            }
        }
    }
    SelectModel(dataType, initialValue) {
        Events.BCE(Events.DESIGNER.BOARD.ITEM.SELECT_MODEL, {
            title : 'HIDDEN',
            dataType : dataType,
            onSelect : (modelId) => {
                if (modelId) {                    
                    this.ValueType = 'model';
                }
                this.props.onBindToModel(modelId);
            },
            initialValue : initialValue
        });
    }
    SelectTextContent() {
        const currentTokenId = Utils.JustGet(this.props.value, null, 'TokenId');
        this.props.onSelectContent(currentTokenId, 
            (itemId, tokenId) => {
                if (tokenId) {
                    this.ValueType = 'token';
                }
                this.props.onBindToToken(tokenId);
                this.RCUpdate();
            },
            (previewToken) => {
                this.props.GetComponentManager().SetSubComponentModelPreviewTokenId({MetaItem : this.props.GetMetaItem(), Id : this.props.Id, ModelId : this.props.modelId, PreviewTokenId : previewToken.show ? previewToken.tokenId : null})
                this.props.GetComponentManager().UpdateItemPreviews(this.props.Id, previewToken.show) ;
            },
            Utils.JustGet(this.props.value, '', 'Value')
        )
    }
    SelectImageToken() {
        const currentTokenId = Utils.JustGet(this.props.value, null, 'TokenId');
        this.props.onSelectImage(currentTokenId, 
            (itemId, tokenId) => {
                if (tokenId) {
                    this.ValueType = 'token';
                }
                this.props.onBindToToken(tokenId);                
                this.RCUpdate();
            },
            (previewToken) => {
                this.props.GetComponentManager().SetSubComponentModelPreviewTokenId({MetaItem : this.props.GetMetaItem(), Id : this.props.Id, ModelId : this.props.modelId, PreviewTokenId : previewToken.show ? previewToken.tokenId : null})                
            }
        );
    }
    SetImagePropertyValues(props) {
        this.props.onSetModelProps(props);
    }
    SetImagePropertyModel(prop) {
        this.props.onSetModelPropModel && this.props.onSetModelPropModel(prop.PropertyName, prop.ModelId);
    }
    SelectIconToken() {
        const currentTokenId = Utils.JustGet(this.props.value, null, 'TokenId');
        this.props.onSelectIcon(currentTokenId, 
            (itemId, tokenId) => {
                this.props.onBindToToken(tokenId);
                this.RCUpdate();
            },
            (previewToken) => {
                this.props.GetComponentManager().SetSubComponentModelPreviewTokenId({MetaItem : this.props.GetMetaItem(), Id : this.props.Id, ModelId : this.props.modelId, PreviewTokenId : previewToken.show ? previewToken.tokenId : null})
                this.props.GetComponentManager().UpdateItemPreviews(this.props.Id, previewToken.show) ;
            }
        );
    }
    RefreshMockup(mockup) {
        const Result = {};
        const MockupValue = this.props.GetComponentManager().GetSubcomponentMockupValue({
            Id : this.props.Id, 
            ModelId : this.props.modelId,
            MockupFieldId : mockup.FieldId,
            Refresh : Result
        });
        Utils.Set(this.props.value, Result.MockupId, 'MockupId');
        this.RCUpdate();
        const Designer = AppLayout.Refs.SelectionDesigner.GetItemDesigner(this.props.Id);
        if (Designer && Designer.SubcomponentManager) {
            Designer.SubcomponentManager.LoadMockupValues();
            Designer.SubcomponentManager.UpdateStyles();
        }
    }
    PickupMockup(mockup) {
        this.props.onPickMockup({
            FieldId : mockup.FieldId,
            onSelect : (mockupId, value) => {
                Utils.Set(this.props.value, mockupId, 'MockupId');
                this.props.GetComponentManager().SetSubcomponentMockupValue({Id : this.props.Id, ModelId : this.props.modelId, MockupId : mockupId});
                this.RCUpdate();
                const Designer = AppLayout.Refs.SelectionDesigner.GetItemDesigner(this.props.Id);
                if (Designer && Designer.SubcomponentManager) {
                    Designer.SubcomponentManager.LoadMockupValues();
                    Designer.SubcomponentManager.UpdateStyles();
                }
            }
        })
    }
    ToggleTextSource(isModel) {
        if (this.ValueType === isModel) {
            return;
        }

        if (isModel === 'model') {
            this.SelectModel();
        }
        else if (isModel === 'token') {
            this.SelectTextContent();            
        }
        else {
            this.ValueType = isModel;
            if (isModel === 'mockup' || isModel === 'custom') {
                this.props.GetComponentManager().SetSubComponentModels({
                    MetaItem : this.props.GetMetaItem(this.props.Id),
                    Id : this.props.Id,
                    ComponentId : this.props.component.Id,
                    ModelId : this.props.modelId,
                    WillDelete : true
                });
                if (isModel === 'mockup') {                    
                    this.props.GetComponentManager().ResetItemStyle(this.props.Id);
                    this.RefreshMockup({
                        FieldId : this.props.model.DataId
                    });                    
                    return;
                }
                else if (isModel === 'custom') {
                    const useValue = Utils.JustGet(this.props.value, '', 'Value');
                    this.props.onChangeModelValue(useValue, MetaData.DataTypes.string, 'custom');
                }
            }            
        }
        this.RCUpdate();
    }
    ToggleImageSource(isModel) {
        if (this.ValueType === isModel) {
            return;
        }

        if (isModel === 'model') {
            this.SelectModel();
        }
        else if (isModel === 'token') {
            this.SelectImageToken();            
        }
        else {
            this.ValueType = isModel;
            if (isModel === 'mockup' || isModel === 'url') {
                this.props.GetComponentManager().SetSubComponentModels({
                    MetaItem : this.props.GetMetaItem(this.props.Id),
                    Id : this.props.Id,
                    ComponentId : this.props.component.Id,
                    ModelId : this.props.modelId,
                    WillDelete : true
                });
                if (isModel === 'mockup') {                    
                    this.props.GetComponentManager().ResetItemStyle(this.props.Id);
                    this.RefreshMockup({
                        FieldId : this.props.model.DataId
                    });                    
                    return;
                }
            }            
        }
        this.RCUpdate();
    }
    ShowUnsplashGallery() {
        const originalValue = Utils.JustGet(this.props.value, null, 'Value', 'url');;
        this.props.onSelectUnsplashImage(
            (img) => {
                if (img) {
                    this.props.onChangeModelValue({url : img.url}, MetaData.DataTypes.image, 'url');
                }
            },
            (show, url) => {
                if (show) {
                    this.props.onChangeModelValue({url : url}, MetaData.DataTypes.image, 'url');
                }
                else {
                    this.props.onChangeModelValue({url : originalValue}, MetaData.DataTypes.image, 'url');
                }
            },
            () => {

            }
        );
    }

    renderCustom() {
        const {model, value} = this.props;
        let valueName, tokenId, modelId;
        let modelValue;
        let hasMockupValue;

        if (model.DataId) {
            hasMockupValue = {
                FieldId : model.DataId
            };
        }

        modelId = Utils.JustGet(value, null, 'ModelId');
        if (modelId) {
            const boundmodel = this.props.GetComponentManager().Models.GetModel(modelId);
            if (boundmodel) {
                tokenId = boundmodel.TokenId;
                valueName = boundmodel.name;
                modelValue = this.props.GetComponentManager().Models.GetValue(modelId);
            }            
        }
        else {
            tokenId = Utils.JustGet(value, null, 'TokenId');
            if (tokenId) {
                const token = Globals.ProjectManager.Tokens.Token(tokenId);
                if (token)
                    valueName = token.name;
            }
            else if (model.DataId) {
                if (value && value.MockupId) {
                    modelValue = Globals.ProjectManager.Mockups.GetRecordValue(value.MockupId, model.DataId);
                    hasMockupValue.RecordId = value.MockupId;
                    if (!this.ValueType)
                        this.ValueType = 'mockup';
                }
            }
            else {
                tokenId = model.TokenId;
                valueName = 'Default Value';
            }
        }
        
        
        let editor;

        let noTopMargin = false;

        if (model.dataType === MetaData.DataTypes.boolean) {
            const useValue = Utils.IsTrue(Utils.JustGet(value, modelValue, 'Value'));
            noTopMargin = true;
            editor = (
                <BooleanEditor 
                    label={model.name}
                    value={modelValue || useValue}
                    onChange={this.props.onChangeModelValue}
                    modelId={modelId}
                    onSelectModel={this.SelectModel.bind(this, MetaData.DataTypes.boolean, useValue)}
                    onRefreshMockup={hasMockupValue ? this.RefreshMockup.bind(this, hasMockupValue) : null}
                />
            )
        }
        else if (model.dataType === MetaData.DataTypes.string) {
            const useValue = Utils.JustGet(value, modelValue, 'Value');
            noTopMargin = true;

            const textValueTypes = [
                {id : 'custom', label : 'Custom'},
                {id : 'token', label : 'Token'},
                {id : 'model', label : 'Variable'},
            ];
            if (hasMockupValue) {
                textValueTypes.splice(0, 0, {id : 'mockup', label : 'Mockup'});
            }

            const textSourceType = Utils.UseNullOrEmpty(this.ValueType, 'custom');
            let textItemEditor, isEmpty = true;

            if (textSourceType === 'token') {
                let tokenName = 'Select Text Token';
                if (tokenId) {
                    const tokenModel = Globals.ProjectManager.Tokens.Token(tokenId);
                    if (tokenModel)
                        tokenName = tokenModel.name;            

                    isEmpty = false;
                }       

                textItemEditor = (
                    <StyleGroupTokenItem 
                        style={{flex : 1, padding : '8px', paddingTop : '4px', paddingBottom : '4px', marginTop : '8px'}}
                        onClick={this.SelectImageToken}
                    >
                        <PropertyLabel 
                            hasValue={tokenId}
                            style={{flex : 1}}
                        >
                            {tokenName}
                        </PropertyLabel>                    
                    </StyleGroupTokenItem>                    
                )
            }
            else if (textSourceType === 'custom') {
                isEmpty = !Utils.IsNotNullOrEmpty(useValue);
                textItemEditor = (
                    <SC.FRow alc jsb style={{marginTop : '8px'}}>
                        <TextInput 
                            key={this.props.Id}
                            dark
                            value={useValue}
                            style={{height : '28px', fontSize : '11px'}}
                            placeholder={model.name}
                            onChange={(customValue) => {
                                this.props.onChangeModelValue(customValue, MetaData.DataTypes.string, 'custom');
                            }}
                            onChanging={(customValue) => {
                                this.props.onChangeModelValue(customValue, MetaData.DataTypes.string, 'custom');
                            }}
                        />
                    </SC.FRow>
                )
            }
            else if (textSourceType === 'mockup') {
                
            }
            else if (textSourceType === 'model') {                
                if (modelId) {
                    isEmpty = false;
                }       

                textItemEditor = (
                    <StyleGroupTokenItem 
                        style={{flex : 1, padding : '8px', paddingTop : '4px', paddingBottom : '4px', marginTop : '8px'}}
                        onClick={this.SelectModel.bind(this, MetaData.DataTypes.string, useValue)}
                    >
                        <PropertyLabel 
                            hasValue={!isEmpty}
                            style={{flex : 1}}
                        >
                            {valueName || 'Select Variable'}
                        </PropertyLabel>                    
                    </StyleGroupTokenItem>                    
                )
            }                         

            editor = (
                <SC.FCol>
                    <PropValueTypeSelector 
                        title={model.name}
                        hasMockupValue={hasMockupValue && textSourceType === 'mockup'}
                        onRefreshMockup={this.RefreshMockup.bind(this, hasMockupValue)}
                        onPickMockup={this.PickupMockup.bind(this, hasMockupValue)}
                        sourceTypes={textValueTypes}
                        sourceType={textSourceType}
                        onChangeSourceType={this.ToggleTextSource}                        
                    />
                    {textItemEditor}
                </SC.FCol>                
            )
        }
        else if (model.dataType === MetaData.DataTypes.color) {
            const useValue = Utils.JustGet(value, modelValue, 'Value');
            noTopMargin = true;
            editor = (
                <TextContentPropertyEditor 
                    key={this.props.Id}
                    label={model.name}
                    value={modelValue || useValue}
                    onChange={this.props.onChangeModelValue}
                    modelId={modelId}
                    onSelectModel={this.SelectModel.bind(this, MetaData.DataTypes.color, useValue)}
                    onSelectContent={this.SelectTextContent}
                    style={{marginTop : this.props.first ? '8px' : 0}}
                />
            )
        }
        else if (model.dataType === MetaData.DataTypes.number) {
            const useValue = Utils.JustGet(value, modelValue, 'Value');
            noTopMargin = true;

            const textValueTypes = [
                {id : 'custom', label : 'Custom'},
                {id : 'model', label : 'Variable'},
            ];
            if (hasMockupValue) {
                textValueTypes.splice(0, 0, {id : 'mockup', label : 'Mockup'});
            }

            const textSourceType = Utils.UseNullOrEmpty(this.ValueType, 'custom');
            let textItemEditor, isEmpty = true;

            if (textSourceType === 'custom') {
                isEmpty = !Utils.IsNotNullOrEmpty(useValue);
                textItemEditor = (
                    <SC.FRow alc jsb style={{marginTop : '8px'}}>
                        <TextInput 
                            key={this.props.Id}
                            dark
                            value={useValue}
                            style={{height : '28px', fontSize : '11px'}}
                            placeholder={model.name}
                            onChange={(customValue) => {
                                this.props.onChangeModelValue(customValue, MetaData.DataTypes.number, 'custom');
                            }}
                        />
                    </SC.FRow>
                )
            }
            else if (textSourceType === 'mockup') {
                
            }
            else if (textSourceType === 'model') {                
                if (modelId) {
                    isEmpty = false;
                }       

                textItemEditor = (
                    <StyleGroupTokenItem 
                        style={{flex : 1, padding : '8px', paddingTop : '4px', paddingBottom : '4px', marginTop : '8px'}}
                        onClick={this.SelectModel.bind(this, MetaData.DataTypes.number, useValue)}
                    >
                        <PropertyLabel 
                            hasValue={!isEmpty}
                            style={{flex : 1}}
                        >
                            {valueName || 'Select Variable'}
                        </PropertyLabel>                    
                    </StyleGroupTokenItem>                    
                )
            }             
            
            editor = (
                <SC.FCol>
                    <PropValueTypeSelector 
                        title={model.name}
                        hasMockupValue={hasMockupValue && textSourceType === 'mockup'}
                        onRefreshMockup={this.RefreshMockup.bind(this, hasMockupValue)}
                        onPickMockup={this.PickupMockup.bind(this, hasMockupValue)}
                        sourceTypes={textValueTypes}
                        sourceType={textSourceType}
                        onChangeSourceType={this.ToggleTextSource}                        
                    />
                    {textItemEditor}
                </SC.FCol>                
            )
        }
        else if (model.dataType === MetaData.DataTypes.image) {
            const useValue = Utils.JustGet(value, modelValue, 'Value', 'url');
            noTopMargin = true;
            
            const imageValueTypes = [
                {id : 'url', label : 'Custom'},
                {id : 'token', label : 'Token'},
                {id : 'model', label : 'Variable'},
            ];

            if (hasMockupValue) {
                imageValueTypes.splice(0, 0, {id : 'mockup', label : 'Mockup'});
            }

            const imageSourceType = Utils.UseNullOrEmpty(this.ValueType, 'url');

            let imageItemEditor, isEmpty = true;

            if (imageSourceType === 'token') {
                let tokenName = 'Select Image Token';
                if (tokenId) {
                    const tokenModel = Globals.ProjectManager.Tokens.Token(tokenId);
                    if (tokenModel)
                        tokenName = tokenModel.name;            

                    isEmpty = false;
                }       

                imageItemEditor = (
                    <StyleGroupTokenItem 
                        style={{flex : 1, padding : '8px', paddingTop : '4px', paddingBottom : '4px', marginTop : '8px', marginBottom : '4px'}}
                        onClick={this.SelectImageToken}
                    >
                        <PropertyLabel 
                            hasValue={tokenId}
                            style={{flex : 1}}
                        >
                            {tokenName}
                        </PropertyLabel>                    
                    </StyleGroupTokenItem>                    
                )
            }
            else if (imageSourceType === 'url') {
                isEmpty = !Utils.IsNotNullOrEmpty(useValue);
                imageItemEditor = (
                    <SC.FCol alc jsb style={{marginTop : '8px'}}>
                        <SC.Buttons.RoundButton xsmall onClick={this.ShowUnsplashGallery} style={{width : '100%', marginBottom : '8px'}}>
                            Select from Unsplash
                        </SC.Buttons.RoundButton>
                        <TextInput 
                            key={this.props.Id}
                            dark
                            value={useValue}
                            style={{height : '28px', fontSize : '11px'}}
                            placeholder='Url'
                            onChange={(newurl) => {
                                this.props.onChangeModelValue({url : newurl}, MetaData.DataTypes.image, 'url');
                            }}
                        />                        
                    </SC.FCol>
                )
            }
            else if (imageSourceType === 'mockup') {
                isEmpty = hasMockupValue.FieldId ? false : true;                
            }
            else if (imageSourceType === 'model') {                
                if (modelId) {
                    isEmpty = false;
                }       

                imageItemEditor = (
                    <StyleGroupTokenItem 
                        style={{flex : 1, padding : '8px', paddingTop : '4px', paddingBottom : '4px', marginTop : '8px', marginBottom : '4px'}}
                        onClick={this.SelectModel.bind(this, MetaData.DataTypes.image, useValue)}
                    >
                        <PropertyLabel 
                            hasValue={!isEmpty}
                            style={{flex : 1}}
                        >
                            {valueName || 'Select Variable'}
                        </PropertyLabel>                    
                    </StyleGroupTokenItem>                    
                )
            }
            
            let imageProperties;            
            if (!isEmpty) {
                const imageProps = {};
                imageProps.positionX = Utils.JustGet(value, 'center', 'Options', 'positionX', 'value');
                imageProps.positionY = Utils.JustGet(value, 'center', 'Options', 'positionY', 'value');            
                imageProps.sizeType = Utils.JustGet(value, 'cover', 'Options', 'backgroundSize', 'value');
                imageProps.customXprops = {};
                imageProps.customYprops = {};
                if (imageProps.positionX === 'custom') {
                    imageProps.customXprops = {
                        value : Utils.JustGet(value, 0, 'Options', 'customPositionX', 'value'),
                        modelId : Utils.JustGet(value, 0, 'Options', 'customPositionX', 'ModelId'),
                    }
                }
                if (imageProps.positionY === 'custom') {
                    imageProps.customYprops = {
                        value : Utils.JustGet(value, 0, 'Options', 'customPositionY', 'value'),
                        modelId : Utils.JustGet(value, 0, 'Options', 'customPositionY', 'ModelId'),
                    }
                }
    
                if (imageProps.sizeType === 'custom') {
                    imageProps.customSizePropsWidth = {
                        value : Utils.JustGet(value, 'auto', 'Options', 'backgroundWidth', 'value'),
                        modelId : Utils.JustGet(value, null, 'OptionModels', 'backgroundWidth', 'ModelId')
                    }
                    imageProps.customSizePropsHeight = {
                        value : Utils.JustGet(value, 'auto', 'Options', 'backgroundHeight', 'value'),
                        modelId : Utils.JustGet(value, null, 'OptionModels', 'backgroundHeight', 'ModelId')
                    }
                }

                imageProperties = (
                    <ImageSizeAndPositionEditor 
                        {...imageProps}
                        onSetPropertyValues={this.SetImagePropertyValues}
                        onSetPropModel={this.SetImagePropertyModel}
                        onUpdate={this.RCUpdate}
                    />
                )
            }

            editor = (
                <SC.FCol>
                    <PropValueTypeSelector 
                        title={model.name}
                        hasMockupValue={hasMockupValue && imageSourceType === 'mockup'}
                        onRefreshMockup={this.RefreshMockup.bind(this, hasMockupValue)}
                        onPickMockup={this.PickupMockup.bind(this, hasMockupValue)}
                        sourceTypes={imageValueTypes}
                        sourceType={imageSourceType}
                        onChangeSourceType={this.ToggleImageSource}                        
                    />
                    <SC.FCol style={{paddingLeft  :'8px', marginTop : '8px', borderLeft : SC.CurrentTheme.theme.border_seperator}}>
                        {imageItemEditor}
                        {imageProperties}
                    </SC.FCol>                    
                </SC.FCol>                
            )            
        }
        else if (model.dataType === MetaData.DataTypes.icon) {
            noTopMargin = true;
            const useValue = Utils.JustGet(value, modelValue, 'Value');
            let preview;
            if (tokenId) {
                const iconResult = RenderIconTokenPreview({TokenId : tokenId, StateArray : this.props.StateArray});
                preview = iconResult.preview;
            }
            editor = (
                <IconTokenSelectItem 
                    label={model.name}
                    name={valueName}
                    modelId={modelId}
                    tokenId={tokenId}
                    onSelectModel={this.SelectModel.bind(this, MetaData.DataTypes.icon, useValue)}
                    onSelectToken={this.SelectIconToken}  
                    style={{marginTop : this.props.first ? '8px' : '4px'}}
                >
                    {preview}
                </IconTokenSelectItem>
            )
        }        

        return (
            <React.Fragment>
                {this.props.first ? <div style={{marginTop : '8px'}} /> : <Seperator />}                
                {editor}
            </React.Fragment>
        )        
    }
}

export const NumberEditor = (props) => {
    let mockupRefresh;
    if (props.onRefreshMockup) {
        mockupRefresh = (
            <SC.Icons.Icon_Button 
                hasFill 
                style={{marginLeft : '8px', cursor : 'pointer'}} 
                title='Generate New Mockup Value'
                onClick={props.onRefreshMockup}
            >
                <SC.Icons.Icon_Refresh 
                    size={20}                            
                />
            </SC.Icons.Icon_Button>
        )
    }
    return (
        <SC.FRow style={{...props.style}}>
            <StyleGroupTokenItem style={{flex : 1, padding : '2px', paddingLeft : '8px'}}>
                <SC.FRow alc jsb>
                    <PropertyLabel 
                        style={{marginRight : '18px'}}
                        hasValue={props.value !== null}
                        menuOptions={{
                            onShowValueMenu : props.onShowValueMenu,
                            propName : 'hidden',
                            isProp : true
                        }}
                    >{props.label}</PropertyLabel>
                    <SC.FRow alc>
                        <NumberInput 
                            value={props.value} 
                            onChange={props.onChangeValue} 
                            onChanging={props.onChangingValue} 
                            xsmall
                            style={{fontSize : '12px'}}
                            boxStyle={{maxWidth : '76px'}}
                        />
                    </SC.FRow>                        
                </SC.FRow>
            </StyleGroupTokenItem>   
            <VariableBinder 
                modelId={props.modelId}
                onSelect={props.onSelectModel}
            />
            {mockupRefresh}
        </SC.FRow>                     
    )      
}

const PropValueTypeSelector = (props) => {
    let mockupSelector;
    if (props.hasMockupValue) {
        mockupSelector = (
            <SC.FRow alc jsb style={{marginTop : '8px'}}>
                <StyleGroupTokenItem style={{
                    ...SC.Styles.Flex.RowAlcJsb,
                    padding : '8px', paddingTop : '2px', paddingBottom : '2px', cursor : 'pointer'
                }} onClick={props.onRefreshMockup}>
                    <div>Random</div>
                    <SC.Icons.Icon_Button 
                        hasFill 
                        style={{marginLeft : '8px'}} 
                    >
                        <SC.Icons.Icon_Refresh 
                            size={20}                            
                        />
                    </SC.Icons.Icon_Button>
                </StyleGroupTokenItem>
                <StyleGroupTokenItem style={{
                    ...SC.Styles.Flex.RowAlcJsb,
                    padding : '8px', paddingTop : '2px', paddingBottom : '2px', cursor : 'pointer', marginLeft : '8px', flex : 1, alignSelf : 'stretch'
                }} onClick={props.onPickMockup}>
                    <div>Pick Mockup Value</div>                   
                </StyleGroupTokenItem>
            </SC.FRow>
        )
    }
    return (
        <React.Fragment>
            <SC.FRow alc jsb style={{paddingLeft : '4px'}}>
                <StyleGroupTitle style={{textTransform : 'uppercase'}}>{props.title}</StyleGroupTitle>
                <DropDownSelect 
                    hasBorder            
                    autoHeight
                    xsmall
                    toRight
                    fitWidth
                    style={{minWidth : '100px', fontSize : '11px'}}
                    items={props.sourceTypes}
                    value={props.sourceType}
                    onChange={props.onChangeSourceType}
                />
            </SC.FRow>        
            {mockupSelector}   
        </React.Fragment>
    )
}