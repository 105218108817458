import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    AppState,
    Events,
    SC,
    Loading,
    Globals,
    Strings,
    Links,
    Utils,
    AppLayout
} from '../../../../../../../importer';

import { GetImageTokenList, ImagesTokenList, AddOrUpdateImageToken } from '../../../../left/designsystem/images';
import ImageEditor from  '../../../../left/designsystem/images/item';
import BaseSelectToken from '../BaseSelectToken';

export default class SelectImageToken extends BaseSelectToken
{
    constructor(props) {
        super(props);

        this.titleNewToken = 'NEW IMAGE';
        this.moduleName = 'Images';
        this.tokenType = Globals.ProjectManager.Tokens.Types.Images;        

        this.Ref_ImageEdit = React.createRef();

        this.Load(this.props);
    }
    IsTokenPropChanged(nextProps, nextState) {
        if (Utils.HasAnyChange(this.props, nextProps, 'GlobalState')) {
            this.Load(nextProps);
            return true;
        }
        return this.props.image !== nextProps.image;
    }
    GetTokens(props) {
        return GetImageTokenList(props.StateArray);                        
    }        
    GetNewModel(Id) {
        const Model =  {
            name : 'New Image',
            value : {
                Default : {
                    value : {

                    }
                }
            }
        };
        AddOrUpdateImageToken({
            isNew : this.EditingToken.IsNew,
            newId : Id,
            model : Model
        })
        this.FireSelectToken(Id);
        return Model;
    }
    SaveToken() {
        if (!Utils.IsNotNullOrEmpty(this.Ref_ImageEdit.current.tokenvalue.provider)) {
            this.onCancelAddToken();
            return;
        }
        Globals.ProjectManager.Tokens.SetValue({
            id : this.EditingToken.Id,
            value : this.Ref_ImageEdit.current.tokenvalue,
            type : Globals.ProjectManager.Tokens.Types.Images
        });
        const NewModel = AddOrUpdateImageToken({
            id : this.EditingToken.Id,
            model : this.EditingToken.Model,
            willUploadFile : this.EditingToken.Model.willUploadFile
        })
        AppLayout.Refs.DesignSystem.Images && AppLayout.Refs.DesignSystem.Images.Reload();
        return NewModel;
    }   
    onCloseTokenEditor() {
        if (this.EditingToken && this.EditingToken.IsNew) {
            delete this.EditingToken;            
            this.RCUpdate();
            return;
        }
        super.onCloseTokenEditor();
    }
    onCancelAddToken() {
        this.FireSelectToken(this.LastBoundTokenId);
        delete this.LastBoundTokenId;
        Globals.ProjectManager.Tokens.Delete(Globals.ProjectManager.Tokens.Types.Images, this.EditingToken.Id);
        super.onCancelAddToken();
    }
    UpdateDesignSystemPanel() {
        AppLayout.Refs.DesignSystem.Typograpgy && AppLayout.Refs.DesignSystem.Typograpgy.RCUpdate();
    }
    UpdateSelectedToken() {
        if (this.EditingToken) {
            const SelectedToken = Utils.Find(this.tokens, (token) => {return token.id === this.EditingToken.Id});
            const token = Globals.ProjectManager.Tokens.Token(this.EditingToken.Id);
            SelectedToken.url = Utils.JustGet(Globals.ProjectManager.Tokens.ValueOf({model : token, statearray : this.props.StateArray}), '', 'url');
            AppLayout.Refs.DesignSystem.Images && AppLayout.Refs.DesignSystem.Images.Reload();
        }
    }
    GetStyleName() {
        return 'backgroundImage';
    }
    PreviewNewToken(show, url) {
        super.PreviewNewToken(show ? {url : url}  : null);
    }
    renderTokenList() {
        return (
            <SC.CustomScrollbars hideTracksWhenNotNeeded autoHide>
                <ImagesTokenList 
                    isListView={this.state.isListView}
                    images={this.tokens}
                    style={{padding : 0, paddingLeft : 0, paddingRight : 0}}
                    onSelect={this.SelectToken}
                    onPreview={this.PreviewToken}
                    selectedId={this.props.image}
                />
            </SC.CustomScrollbars> 
        )
    }
    renderTokenEditor() {
        return (
            <ImageEditor 
                ref={this.Ref_ImageEdit}                
                newModel={this.EditingToken.IsNew ?  this.EditingToken.Model : null}  
                onPreviewChange={this.PreviewNewToken}
                onRightPanel
                id={this.EditingToken.Id}
                noHeader
                onToggleGalleryView={() => {
                    this.HideEditorHeader = !this.HideEditorHeader;
                    this.RCUpdate();
                }}
            />                 
        )        
    }
    GetSelectedTokenId() {
        return this.props.image;
    }
    renderSelectedToken() {
        if (this.props.image) {
            return this.renderSelectedTokenHeader();
        }
    }
}