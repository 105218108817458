import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    AppState,
    Utils,
    Events,
    Strings,
    Globals
} from '../../../../../../../../importer';
       
import {ItemNameEditor, TokenItemBox} from '../../../common';
import { PatternScaleSelector } from '../../../spacing/patterns/item';

export default class DurationPatternCard extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.SaveName = this.SaveName.bind(this);
        this.renderScaleItem = this.renderScaleItem.bind(this);        
        this.SelectTimeScale = this.SelectTimeScale.bind(this);

        this.pattern = Globals.ProjectManager.Tokens.TimePatterns.GetPattern(this.props.id);
    }
    componentWillUnmount() {
        this.props.onClosed && this.props.onClosed();
        super.componentWillUnmount();        
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.GlobalStateId !== nextProps.GlobalStateId || this.props.id !== nextProps.id || this.props.renderId !== nextProps.renderId) {
            this.pattern = Globals.ProjectManager.Tokens.TimePatterns.GetPattern(nextProps.id);
            if (!this.pattern) {
                this.props.onClose();
                return false;
            }
            return true;
        }
        return super.ShouldUpdate(nextProps, nextState);
    }
    
    renderScaleItem({id, scale}) {
        if (id === 'Custom') {
            return (
                <div style={{
                    fontSize : `14px`,
                    fontWeight : 500
                }}>Custom</div>  
            )
        }
        return (
            <SC.FRow>
                <div style={{
                    fontSize : `14px`,
                    fontWeight : 500
                }}>{scale.px} <span style={{fontSize:'12px', fontWeight : 300}}>px</span></div>                
            </SC.FRow>
        )
    }    
    SetScaleType(isCustom) {
        if (isCustom) {
            Globals.ProjectManager.Tokens.SetValue({id : this.props.id, value : 1, name : 'scaleFactor'});    
        }
        else
            Globals.ProjectManager.Tokens.DeleteValue({id : this.props.id, name : 'scaleFactor'});

        this.props.onSave();
        Globals.ProjectManager.UpdateTokenValues({});
        if (this.props.isNew)
            this.props.onPreviewChange && this.props.onPreviewChange(this.pattern);
        else {
            Events.BroadcastThrottle_50(Events.GLOBAL.TOKEN_VALUE_CHANGING, [
                {
                    Id : this.props.id,
                    Type : Globals.ProjectManager.Tokens.Types.Motion,
                    value : Globals.ProjectManager.Tokens.TimePatterns.GetTimeSize(this.pattern)
                }
            ]);
        }      
        this.RCUpdate();
    }
    SelectTimeScale(id, value) {
        !this.props.isNew && Globals.ProjectManager.LogTokenChange({Desc : 'Change Pattern'});

        Globals.ProjectManager.Tokens.SetValue({id : this.props.id, value : id, name : 'scaleIndex'});
        if (id === 'Custom') {            
            Globals.ProjectManager.Tokens.SetValue({id : this.props.id, value : {
                value : value,
                Unit : Utils.Defaults.Unit()
            }, name : 'customSize'});
        }
        else {
            Globals.ProjectManager.Tokens.DeleteValue({id : this.props.id, name : 'customSize'});
        }        
                
        this.props.onSave();        
        if (this.props.isNew)
            this.props.onPreviewChange && this.props.onPreviewChange(this.pattern);
        else {
            Events.BroadcastThrottle_50(Events.GLOBAL.TOKEN_VALUE_CHANGING, [
                {
                    Id : this.props.id,
                    Type : Globals.ProjectManager.Tokens.Types.Motion,
                    value : Globals.ProjectManager.Tokens.TimePatterns.GetTimeSize(this.pattern)
                }
            ]);
        }      
        this.RCUpdate();      
    }
    SaveName(name) {
        this.pattern.name = name;
        Globals.ProjectManager.Tokens.ChangeTokenName(this.props.id, name);
        this.RCUpdate();
    }
    onChangeSize(prop, newSize) {
        !this.props.isNew && Globals.ProjectManager.LogTokenChange({Desc : 'Change Pattern'});
        const size = Globals.ProjectManager.Tokens.ValueOf({model : this.pattern, name : prop}) || {};
        size.value = newSize.value;
        Globals.ProjectManager.Tokens.SetValue({id : this.props.id, value : size, name : prop});
        this.props.onSave();
        Globals.ProjectManager.UpdateTokenValues({});
        if (this.props.isNew)
            this.props.onPreviewChange && this.props.onPreviewChange(this.pattern);
        else {
            Events.BroadcastThrottle_50(Events.GLOBAL.TOKEN_VALUE_CHANGING, [
                {
                    Id : this.props.id,
                    Type : Globals.ProjectManager.Tokens.Types.Motion,
                    value : Globals.ProjectManager.Tokens.TimePatterns.GetTimeSize(this.pattern)
                }
            ]);
        }
    }    
    DeleteSize(prop) {
        !this.props.isNew && Globals.ProjectManager.LogTokenChange({Desc : 'Change Pattern'});        
        Globals.ProjectManager.Tokens.DeleteValue({id : this.props.id,  name : prop});
        this.props.onSave();
        Globals.ProjectManager.UpdateTokenValues({});
        if (this.props.isNew)
            this.props.onPreviewChange && this.props.onPreviewChange(this.pattern);
        else {
            Events.BroadcastThrottle_50(Events.GLOBAL.TOKEN_VALUE_CHANGING, [
                {
                    Id : this.props.id,
                    Type : Globals.ProjectManager.Tokens.Types.Motion,
                    value : Globals.ProjectManager.Tokens.TimePatterns.GetTimeSize(this.pattern)
                }
            ]);
        }
    }
    onChangeCustomFactor(prop, value) {
        !this.props.isNew && Globals.ProjectManager.LogTokenChange({Desc : 'Change Duration'});
        Globals.ProjectManager.Tokens.SetValue({id : this.props.id, value : value, name : prop});
        this.props.onSave();
        Globals.ProjectManager.UpdateTokenValues({});
        if (this.props.isNew)
            this.props.onPreviewChange && this.props.onPreviewChange(this.pattern);
        else {
            Events.BroadcastThrottle_50(Events.GLOBAL.TOKEN_VALUE_CHANGING, [
                {
                    Id : this.props.id,
                    Type : Globals.ProjectManager.Tokens.Types.Motion,
                    value : Globals.ProjectManager.Tokens.TimePatterns.GetTimeSize(this.pattern)
                }
            ]);
        }
        this.RCUpdate();
    }     
    renderCustom() {

        const pattern = this.pattern;
        const scaleIndex = Globals.ProjectManager.Tokens.ValueOf({model : pattern, name : 'scaleIndex'});
        
        return (
            <SC.FCol fw fh style={{padding : '10px', position : 'relative', backgroundColor : SC.CurrentTheme.theme.back, boxSizing : 'border-box'}}>
                <ItemNameEditor
                    noMargin
                    fontSize='12px'                        
                    name={pattern.name}
                    onSaveName={this.SaveName}
                    onGetItems={this.props.onGetItems}
                    model={pattern}
                    readOnly={this.pattern.locked}
                    lockable={{
                        isLocked : this.pattern.locked,
                        onTokenLock : () => {
                            this.pattern.locked = Globals.ProjectManager.Tokens.ToggleTokenLock(this.props.id);                                    
                            this.RCUpdate();
                        }
                    }}
                />  
                <PatternScaleSelector 
                    compact
                    title='DURATION'
                    customSize={pattern.customSize ? (Globals.ProjectManager.Tokens.ValueOf({model : pattern, name : 'customSize'}) || {}) : null}
                    onChangeCustom={this.onChangeSize.bind(this, 'customSize')}
                    onDeleteCustom={this.DeleteSize.bind(this, 'customSize')}
                    scaleFactor={Globals.ProjectManager.Tokens.ValueOf({model : pattern, name : 'scaleFactor'})}
                    scaleDiff={Globals.ProjectManager.Tokens.ValueOf({model : pattern, name : 'scaleDiff'}) || 0}
                    onChangeCustomFactor={this.onChangeCustomFactor.bind(this, 'scaleFactor')}
                    onChangeCustomDiff={this.onChangeCustomFactor.bind(this, 'scaleDiff')}
                    scaleIndex={scaleIndex}
                    baseValue={this.props.baseSize}
                    ratio={this.props.ratio}
                    onSelect={this.SelectTimeScale}
                    onSetModularScale={this.SetScaleType.bind(this, false)}
                    onSetCustomScale={this.SetScaleType.bind(this, true)}
                    scalesId={this.scalesId}
                    timeScale
                    locked={this.pattern.locked}
                />
            </SC.FCol>
        )
    }
}

