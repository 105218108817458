import React from 'react';
import {
    SC,
    Globals,
    Utils,
} from '../../../../../../../importer';

import { GroupTitle } from '../../../../left/designsystem/common';
import { ListCardView } from '../../../../../../../components/light_dark_bar';
import TextPatternEditor from '../../../../left/designsystem/typography/patterns/item';
import AppLayout from '../../../../../../../appstate/AppLayout';
import { TextPatternList } from '../../../../left/designsystem/typography/patterns';
import { onSelectTypeFace } from '../../../../left/designsystem/typography';
import BaseSelectToken from '../BaseSelectToken';
import SelectToken from '..';
import { motion } from 'framer-motion';
import ComponentDesignManager from '../../../../../component/manager';
import {AddNewTokenAliase} from '../../../../left/designsystem/aliases';

export default class SelectTokenTextPattern extends BaseSelectToken
{
    constructor(props) {
        super(props);
        this.state.isListView = false;
        this.titleNewToken = 'NEW TEXT PATTERN';
        this.moduleName = 'Typography';
        this.tokenType = Globals.ProjectManager.Tokens.Types.Fonts;        
        this.hasCustomTokenList = true;

        this.hasAliases = !this.props.forAliase;
        this.state.isAliaseListView = true;

        this.Ref_PatternEdit = React.createRef();

        this.SelectFont = this.SelectFont.bind(this);
        this.SaveEditingPattern = this.SaveEditingPattern.bind(this);

        this.SelectAliaseToken = this.SelectAliaseToken.bind(this);

        this.Load(this.props);
    }
    IsTokenPropChanged(nextProps, nextState) {
        return this.props.textPattern !== nextProps.textPattern || this.props.GlobalState !== nextProps.GlobalState;
    }
    IsEmpty() {
        return false;
    }
    GetNewModel(Id) {
        const Model = Globals.ProjectManager.Tokens.TypePatterns.NewPattern(Id);        
        Globals.ProjectManager.Tokens.Add({
            type : Globals.ProjectManager.Tokens.Types.TextPatterns,
            id : Id,
            ...Model
        });
        this.FireSelectToken(Id);
        return Model;
    }
    PreEditToken() {
    }    
    onCloseTokenEditor() {
        if (this.EditingToken && this.EditingToken.IsNew) {
            delete this.EditingToken;            
            this.RCUpdate();
            return;
        }
        super.onCloseTokenEditor();
    }
    onCancelAddToken() {
        const willDeleteId = this.EditingToken.Id;
        this.FireSelectToken(this.LastBoundTokenId);
        delete this.LastBoundTokenId;
        super.onCancelAddToken();
        Globals.ProjectManager.Tokens.TypePatterns.DeletePattern(willDeleteId);
        this.UpdateDesignSystemPanel();
    }
    UpdateDesignSystemPanel() {
        AppLayout.Refs.DesignSystem.Typograpgy && AppLayout.Refs.DesignSystem.Typograpgy.RCUpdate();
    }
    SaveToken() {
        if (this.EditingToken.IsNew) {
            const pattern = Globals.ProjectManager.Tokens.Token(this.EditingToken.Id);
            // const patterns = Globals.ProjectManager.Tokens.TypePatterns.Get();
            // Globals.ProjectManager.Tokens.TypePatterns.Set(patterns);
            Globals.ProjectManager.UpdateTokenValues({});
            this.UpdateDesignSystemPanel();
            return {
                id : this.EditingToken.Id
            }
        }        
        else {
            // const patterns = Globals.ProjectManager.Tokens.TypePatterns.Get();
            // Globals.ProjectManager.Tokens.TypePatterns.Set(patterns);
        }                
        return {
            id : this.EditingToken.Id
        };
    }   
    SaveEditingPattern() {
         
        if (this.Ref_PatternEdit.current)
            this.Ref_PatternEdit.current.RCUpdate();
        else
            this.RCUpdate();
    }
    onSubmitAddToken() {
        if (this.EditingToken.IsAlias) {
            AddNewTokenAliase({
                type : this.tokenType,
                name : this.EditingToken.Model.name,
                id : this.EditingToken.Id,
                tokenId : this.EditingToken.Model.tokenId,
                groupId : Utils.JustGet(this.AddingToAliaseGroup, null, 'id')
            });
            this.GetTokens();
            this.CancelNewTokenPreview();
            
            this.props.onUpdate && this.props.onUpdate();
            this.SelectToken(this.EditingToken.Id);
            delete this.EditingToken;
        }
        else {
            const NewToken = this.SaveToken();
            if (NewToken) {
                this.CancelNewTokenPreview();
                Globals.ProjectManager.Tokens.UpdateCurrentTheme({id : NewToken.id, value : ThemeValue, type : this.tokenType});
                
                this.props.onUpdate && this.props.onUpdate();
                this.SelectToken(NewToken.id, this.EditingToken.isGradient);
                delete this.EditingToken;
            }
        }        
    }
    GetStyleName() {
        return 'textPattern';
    }
    SelectFont() {
        onSelectTypeFace((fontId) => {
            if (this.Ref_PatternEdit.current)
                this.Ref_PatternEdit.current.SelectFont(fontId);
            else
                this.RCUpdate();
        }, null, null, this.RCUpdate);
    }    
    PreviewToken(show, tokenId, pattern) { 
        super.PreviewToken(show, tokenId, 'textPattern');
    }
    SearchToken(filter) {
        this.FilterText = filter;
        this.RCThrottledUpdate_1();
    }
    SelectAliaseToken(onSelect, onPreview, onClosed, onPreviewNewToken) {
        this.showTextPatternSelector = {
            type : AppState.ItemTypes.BOARD.PATTERN_TEXT,
            title : 'SELECT ALIASE TOKEN',
            onSelect : (tokenId, isGradient) => {
                onSelect(tokenId)
            },
            onPreviewToken : ({show, tokenId}) => {
                onPreview && onPreview(tokenId, show);
            },
            onClosed : onClosed,
            onPreviewNewToken : () => {
                ComponentDesignManager.Active().ComponentManager && ComponentDesignManager.Active().ComponentManager.UpdateRendererNewTokenPreview(this.props.Id, this.EditingToken.Id);
                onPreviewNewToken && onPreviewNewToken();
            }
        };            
        this.RCUpdate();
    }

    renderInnerSelector() {
        if (this.showTextPatternSelector) {
            return (
                <motion.div 
                    style={{
                        ...SC.Styles.Absolute, 
                        zIndex : 999999999, 
                        left : 0,
                        right : 0,
                        top : 0,
                        bottom : 0,
                        boxSizing : 'border-box',
                        width : '280px' ,
                        borderRight : SC.CurrentTheme.theme.border_ondark,
                        borderLeft : SC.CurrentTheme.theme.border_ondark,
                        backgroundColor : SC.CurrentTheme.theme.back,
                    }}
                    initial={{opacity : 0.7, x : 24}}
                    animate={{opacity : 1, x : 0}}
                    exit={{opacity : 0, x : 24}}
                    transition={{duration : 0.1}}
                >
                    <SC.FCol fw fh>
                        <SC.FCol f1 style={{backgroundColor : SC.CurrentTheme.theme.back}}>
                            <SelectToken                                         
                                onClose={this.CloseTokenSelector}
                                onPreviewToken={this.PreviewToken}
                                GlobalState={this.props.GlobalState}
                                GlobalStateId={this.props.GlobalStateId}
                                themeId={this.props.themeId}
                                onSetPropertyValues={this.SetItemPropertyValues}
                                onBindToModel={this.BindToModel}
                                GetComponentManager={this.GetComponentManager}
                                GetMetaItem={this.GetMetaItem}
                                forAliase
                                onClose={() => {
                                    delete this.showTextPatternSelector;
                                    this.RCUpdate();
                                }}
                                onClosed={() => {                                            
                                }}
                                {...this.showTextPatternSelector}
                            />
                        </SC.FCol>                    
                    </SC.FCol>
                </motion.div>     
            );
        }
    }

    renderTokenList() {
        return (
            <TextPatternList 
                isListView={this.state.isListView}
                dark
                excludeIds={this.props.excludeIds}
                onSelect={this.SelectToken}
                notDraggable
                onPreview={this.PreviewToken}
                selectedId={this.props.textPattern}                    
                StateArray={this.props.StateArray}
                filter={this.FilterText}
            />
        )
    }
    renderTokenAliaseListHeader() {
        return (
            <GroupTitle 
                title='SEMANTIC TOKENS' 
                hasSearch={{onSearch : this.SearchToken}}
                hasAdd={{onAdd : this.AddTokenAlias}}
                addOn={(
                    <SC.FRow>
                        <ListCardView 
                            onListView={this.SetAliaseListMode.bind(this, true)}
                            onCardView={this.SetAliaseListMode.bind(this, false)}
                            isListView={this.state.isAliaseListView}
                            isCardView={!this.state.isAliaseListView}
                            style={{marginRight : '4px'}}
                            compact
                        />
                    </SC.FRow>                    
                )}
                style={{padding : 0, paddingLeft : '4px', marginBottom : '4px'}}
            />
        )
    }
    renderTokenAliases() {
        return (
            <TextPatternList 
                isListView={this.state.isAliaseListView}
                dark
                aliases
                excludeIds={this.props.excludeIds}
                onSelect={this.SelectToken}
                notDraggable
                onPreview={this.PreviewToken}
                selectedId={this.props.textPattern}                    
                StateArray={this.props.StateArray}
                filter={this.FilterText}
            />
        )
    }
    UpdateSelectedToken() {
        if (this.EditingToken.IsNew) {
            
        }        
        else {
            // const patterns = Globals.ProjectManager.Tokens.TypePatterns.Get();
            // Globals.ProjectManager.Tokens.TypePatterns.Set(patterns);
        }  
    }
    renderTokenEditor() {
        const typeScale = Globals.ProjectManager.Tokens.Typescale.Get();
        return (
            <SC.CustomScrollbars hideTracksWhenNotNeeded autoHide>
                <SC.FCol fw fh>
                    <TextPatternEditor 
                        compact
                        editing
                        isNew={this.EditingToken.IsNew}
                        pattern={this.EditingToken.Model}
                        id={this.EditingToken.Id}
                        GlobalStateId={this.props.GlobalStateId}
                        onSave={this.SaveEditingPattern}
                        onSelectFont={this.SelectFont}
                        ref={this.Ref_PatternEdit}
                        onClose={this.onSubmitAddToken} 
                        onPreviewChange={this.EditingToken.IsNew ? this.PreviewNewToken : null}
                        StateArray={this.props.StateArray}
                        baseSize={typeScale.baseSize}
                        ratio={typeScale.ratio}
                    />
                </SC.FCol>
            </SC.CustomScrollbars>
        )        
    }
    GetSelectedTokenId() {
        return this.props.textPattern;
    }
    renderSelectedToken() {
        if (this.props.textPattern) {
            return this.renderSelectedTokenHeader();
        }
    }
}