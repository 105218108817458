import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    UIUtils,
    AppState
} from '../../../../../importer';

import { AnimatePresence, motion } from 'framer-motion';

import CompactColorPicker from '../../../../../components/editors/color/compact';
import { PropertyLabel } from '../../../panels/right/iteminspector/styleitems/common';
import { TokenPanelHeader } from '../../../panels/left/designsystem/common';
import RadioGroup from '../../../../../components/editors/radiogroup';
import SelectColorToken from '../../../panels/right/iteminspector/selectToken';

class SelectDocumentColor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  
            color : Utils.UseNullOrEmpty(this.props.color, '#fff'),
            colorType : this.props.tokenId ? 'token' : 'custom'
        }

        this.ShowPicker = this.ShowPicker.bind(this);        
        this.onChange = this.onChange.bind(this);
        this.onChanging = this.onChanging.bind(this);
        this.onChangeComplete = this.onChangeComplete.bind(this);
        this.SaveColor = Utils.Throttle(this.SaveColor, 100);
        this.onChangeColorType = this.onChangeColorType.bind(this);

    }
    ShowPicker() {
        if (this.state.showEditor)
            this.onChangeComplete();
        this.setState({showEditor : !this.state.showEditor})
    }
    
    onChangeColorType(type) {
        this.setState({colorType : type})
    }
    onChanging(color) {
        this.setState({color : color});
    }
    onChange(color) {
        this.setState({color : color});
        this.SaveColor(color);
    }
    SaveColor(color) {
        this.props.onChange('custom', color);
    }
    onChangeComplete() {
        this.props.onChange('custom', this.state.color);
    }
    render() { 
        let content;
        if (this.state.colorType === 'token') {
            const TokenSelectorVisible = {
                type : AppState.ItemTypes.BOARD.COLOR,
                text : this.props.forText,
                title : this.props.forText ? 'TEXT COLOR' : 'BACKGROUND COLOR',
                onBackgroundColor : this.props.onBackgroundColor,
                hasAccessibility : this.props.forText ? true : false,
                color : this.props.tokenId,
                onSelect : (tokenId) => {
                    this.props.onChange('token', tokenId);
                }
            }
            if (this.props.forText) {
                TokenSelectorVisible.onBackgroundColor = this.props.forText.backgroundColor;
            }
            content = (
                <SelectColorToken                                         
                    innerMode
                    onClose={this.props.onClose}
                    onPreviewToken={this.PreviewToken}
                    GlobalState={this.props.GlobalState}
                    GlobalStateId={this.props.GlobalStateId}
                    themeId={this.props.themeId}
                    onClosed={() => {
                        
                    }}
                    {...TokenSelectorVisible}
                    onUpdate={() => {this.RenderId = Utils.Id();}}
                />
            )
        }
        else {         
            content = (
                <SC.CustomScrollbars>
                        <div style={{
                        padding : '10px', boxSizing : 'border-box', paddingTop : 0,
                        width : '100%',
                        height : '100%',
                        backgroundColor : SC.CurrentTheme.theme.back
                        // display : 'grid',
                        // gridTemplateColumns : 'repeat(auto-fill, minmax(200px, 1fr))',
                        // gridGap : '4px',
                        // gridAutoRows : 'minmax(260px, auto)'
                    }}>
                        <CompactColorPicker 
                            onClose={this.ShowPicker}
                            color={this.state.color}
                            onChanging={this.onChanging}
                            onChange={this.onChange}
                            onComplete={this.onChangeComplete}
                            style={{top : 0, border : 'none'}}
                        />
                    </div>
                </SC.CustomScrollbars>                                   
            )
        }
        return (
            <SC.FCol fw fh  style={{backgroundColor : SC.CurrentTheme.theme.back}}>
                <TokenPanelHeader
                    title='SELECT COLOR' 
                    onClose={this.props.onClose} 
                    style={{backgroundColor : SC.CurrentTheme.theme.back_lighter}}
                />
                <RadioGroup 
                    hasBorder
                    noBackColor
                    autoHeight
                    xsmall
                    toRight
                    fullwidth
                    small
                    items={[
                        {id : 'custom', label : 'Custom'},
                        {id : 'token', label : 'Token'},
                    ]}
                    value={this.state.colorType}
                    onSelect={this.onChangeColorType}
                    style={{padding : '8px'}}
                />
                <SC.FCol f1>
                    {content}
                </SC.FCol>
                
            </SC.FCol>
        )
        return (  
            <SC.FRow alc jsb style={{marginBottom : '8px', paddingLeft : '4px'}}>
                <PropertyLabel>{this.props.label}</PropertyLabel>
                <SC.GridBackground 
                    small 
                    dark={!SC.CurrentTheme.theme.isLight} 
                    style={{
                        // height : '100%', 
                        boxSizing : 'border-box',
                        borderRadius : '2px', 
                        overflow : 'hidden', 
                        cursor : 'pointer',                        
                        border : SC.CurrentTheme.theme.border_seperator,
                        borderWidth : '2px'
                    }}
                >
                    <div 
                        onClick={this.ShowPicker} 
                        style={{backgroundColor : this.state.color, minWidth : '28px', minHeight : '20px', height : '100%', borderRadius : '2px'}} 
                    />
                </SC.GridBackground>  
                {
                    this.state.showEditor && false &&
                    <CompactColorPicker 
                        onClose={this.ShowPicker}
                        color={this.state.color}
                        onChanging={this.onChanging}
                        onChange={this.onChange}
                        onComplete={this.onChangeComplete}
                    />
                }
                {
                    this.state.showEditor &&
                    <SC.AbsoluteOverlay style={{
                        border : '8px solid red'
                    }}></SC.AbsoluteOverlay>
                }
            </SC.FRow>
                      
        );
    }
}
 
export default SelectDocumentColor;

export const DocumentColorProp = ({label, onSelectToken, color}) => {
    return (
        <SC.FRow alc jsb style={{marginBottom : '8px'}}>
            <PropertyLabel>{label}</PropertyLabel>
            <SC.GridBackground 
                small 
                dark={!SC.CurrentTheme.theme.isLight} 
                style={{
                    // height : '100%', 
                    boxSizing : 'border-box',
                    borderRadius : '2px', 
                    overflow : 'hidden', 
                    cursor : 'pointer',                        
                    border : SC.CurrentTheme.theme.border_seperator,
                    borderWidth : '2px'
                }}
            >
                <div 
                    onClick={onSelectToken} 
                    style={{backgroundColor : color, minWidth : '28px', minHeight : '20px', height : '100%', borderRadius : '2px'}} 
                />
            </SC.GridBackground>  
        </SC.FRow>
    )
}