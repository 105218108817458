import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../../importer';
import { AnimatePresence, motion } from 'framer-motion';

import TextInput from '../../../../components/editors/textinput';
import DropDownSelect from '../../../../components/editors/enum_dropdown';
import { Seperator, PropertyLabel, StyleGroupTokenItem } from '../../panels/right/iteminspector/styleitems/common';
import SystemStatePanel from '../../panels/left/systemstates';
import { TokenPanelHeader } from '../../panels/left/designsystem/common';
import Switch from '../../../../components/editors/Switch';
import MultipleTokenSelector from '../../panels/left/designsystem/groupmembers';
import MultipleTokenGroupSelector from '../../panels/left/designsystem/groupmembers/groups';
import SelectTokenOptions from '../../panels/left/designsystem/groupmembers/selectOptions';
import { TokenTypes } from '../../manager/tokens';

export default class ExportOptionsPanel extends ReactBaseComponent
{
    constructor(props) {
        super(props);
    
        this.SetTokenSelection = this.SetTokenSelection.bind(this);          
        this.onSortItems = this.onSortItems.bind(this);            

        this.SelectTokens = this.SelectTokens.bind(this);                
        this.SelectGroups = this.SelectGroups.bind(this);        
        this.SelectAliases = this.SelectAliases.bind(this);
        this.SelectAliaseGroups = this.SelectAliaseGroups.bind(this);
        
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.onAliaseSelectionChanged = this.onAliaseSelectionChanged.bind(this);
        this.onTokenGroupSelectionChanged = this.onTokenGroupSelectionChanged.bind(this);
        this.onAliaseGroupSelectionChanged = this.onAliaseGroupSelectionChanged.bind(this);
    }
    
    SetExportOption(prop, value) {
        if (this.props.subType) {
            Globals.ProjectManager.SetExportDefinitionGroupProp(this.props.id, this.props.groupId, value, this.props.subType, prop);
        }
        else {
            Globals.ProjectManager.SetExportDefinitionGroupProp(this.props.id, this.props.groupId, value, prop);
        }
        
        this.RCUpdate();
    }
            
    
    SetTokenSelection(value) {

        let action, option;
        if (value === 'selected') {                                    
            option = value;
            action = this.SelectTokens;
        }
        else if (value === 'groups') {
            option = value;
            action = this.SelectGroups;
        }
        else if (value === 'aliases') {
            option = value;
        }
        else if (value === 'selectedaliases') {
            option = value;
            action = this.SelectAliases;
        }  
        else if (value === 'aliaseGroups') {
            option = value;
            action = this.SelectAliaseGroups;
        }            
        else {
            option = 'all';
        }

        this.SetExportOption('type', option);
        this.props.onUpdateCode();
        if (action)
            action();
        else
            this.RCUpdate();
    }    
    onSortItems(prop, selectedTokens) {        
        Utils.Set(this.props.options, selectedTokens,  prop, 'ids');
        this.SetExportOption(prop, this.props.options[prop]);
        this.props.onUpdateCode();
    }
    SelectTokens() {
        this.ShowSelectTokens = !this.ShowSelectTokens;
        this.RCUpdate();
    }
    SelectGroups() {
        this.ShowSelectGroups = !this.ShowSelectGroups;
        this.RCUpdate();
    }
    SelectAliaseGroups() {
        this.ShowSelectAliseGroups = !this.ShowSelectAliseGroups;
        this.RCUpdate();
    }
    SelectAliases() {
        this.ShowSelectAlises = !this.ShowSelectAlises;
        this.RCUpdate();
    }    
    
    onSelectionChanged(selectedTokens) {
        Utils.Set(this.props.options, selectedTokens, 'tokens', 'ids');
        this.SetExportOption('tokens', this.props.options.tokens);        
        this.props.onUpdateCode();
    }
    onAliaseSelectionChanged(selectedTokens) {
        Utils.Set(this.props.options, selectedTokens, 'aliases', 'ids');
        this.SetExportOption('aliases', this.props.options.aliases);
        this.props.onUpdateCode();
    }
    onTokenGroupSelectionChanged(selectedGroups) {
        Utils.Set(this.props.options, selectedGroups, 'groups', 'ids');
        this.SetExportOption('groups', this.props.options.groups);
        this.props.onUpdateCode();
    }
    onAliaseGroupSelectionChanged(selectedGroups) {
        Utils.Set(this.props.options, selectedGroups, 'aliaseGroups', 'ids');
        this.SetExportOption('aliaseGroups', this.props.options.aliaseGroups);
        this.props.onUpdateCode();
    }

    renderCustom() {            
        let content;
        
        const selection = Utils.JustGet(this.props.options, 'all', 'type');
        let tokenType = this.props.tokenType;
        let customTokenType;
        if (this.props.tokenType === TokenTypes.SpacePatterns)
            tokenType = TokenTypes.Spacings;

        const subType = this.props.subType;
        let groupIds = [];
        if (selection === 'all' || selection === 'selected' || selection === 'groups') {
            groupIds = Utils.JustGet(this.props.options, [], 'groups', 'ids');
        }            
        else if (selection === 'aliases' || selection === 'selectedaliases' || selection === 'aliaseGroups') {
            groupIds = Utils.JustGet(this.props.options, [], 'aliaseGroups', 'ids');
        }

        
        let selectionProps = {
                
        };
        selectionProps.Type = MultipleTokenSelector;

        if (this.props.tokenType === TokenTypes.CustomTokenTypes) {
            customTokenType = Utils.JustGet(this.props.options, null, 'customTokenType');
        }
        
        if (this.ShowSelectTokens) {
            selectionProps.header = {
                title : 'SELECT TOKENS',
                onClose : this.SelectTokens
            };
            selectionProps.body = {
                selectedTokens : Utils.JustGet(this.props.options, [], 'tokens', 'ids'),
                onSelectionChanged : this.onSelectionChanged
            };
            
        }
        else if (this.ShowSelectAlises) {
            selectionProps.header = {
                title : 'SELECT SEMANTIC ALIASES',
                onClose : this.SelectAliases
            };
            selectionProps.body = {
                selectedTokens : Utils.JustGet(this.props.options, [], 'aliases', 'ids'),
                onSelectionChanged : this.onAliaseSelectionChanged,
                isAliase : true,
            };
        }       
        else if (this.ShowSelectGroups) {
            selectionProps.header = {
                title : 'SELECT TOKEN GROUPS',
                onClose : this.SelectGroups
            };
            selectionProps.body = {
                selectedGroups : groupIds,
                onSelectionChanged : this.onTokenGroupSelectionChanged
            };
            selectionProps.Type = MultipleTokenGroupSelector;
        }
        else if (this.ShowSelectAliseGroups) {
            selectionProps.header = {
                title : 'SELECT ALIASE GROUPS',
                onClose : this.SelectAliaseGroups
            };
            selectionProps.body = {
                selectedGroups : groupIds,
                onSelectionChanged : this.onAliaseGroupSelectionChanged,
                isAliase : true
            };          
            selectionProps.Type = MultipleTokenGroupSelector;
        }
        else {
            selectionProps.header = {
                title : 'TOKEN OPTIONS',
                onClose : this.props.onClose
            };
            selectionProps.content = (
                <SC.CustomScrollbars>
                    <SC.FCol f1 style={{padding : '8px'}}>
                        <SelectTokenOptions 
                            selection={selection}
                            tokenType={tokenType}
                            customTokenType={customTokenType}
                            subType={subType}
                            onOptionsChanged={this.SetTokenSelection}
                            onOrderChanged={this.onSortItems}
                            onSelectTokens={this.SelectTokens} 
                            onSelectGroups={this.SelectGroups} 
                            onSelectAliases={this.SelectAliases} 
                            onAliaseGroups={this.SelectAliaseGroups} 
                            groupIds={groupIds}
                            GetSelectedTokens={() => {
                                if (selection === 'all' || selection === 'selected' || selection === 'groups') {
                                    return Utils.JustGet(this.props.options, [], 'tokens', 'ids');
                                }
                                else if (selection === 'aliases' || selection === 'selectedaliases' || selection === 'aliaseGroups') {
                                    return Utils.JustGet(this.props.options, [], 'aliases', 'ids');
                                }
                                return [];
                            }}
                        />
                    </SC.FCol>
                </SC.CustomScrollbars>
            )
        }    
        
        if (!selectionProps.content) {
            selectionProps.content = (
                <SC.FCol f1>
                    <selectionProps.Type                             
                        tokenType={tokenType}
                        subType={subType}       
                        customTokenType={customTokenType}
                        {...selectionProps.body}                 
                    />
                </SC.FCol>                    
            )
        }
        
        content = (
            <motion.div 
                style={{...SC.Styles.Absolute, zIndex : 10000, display : 'flex', flexDirection : 'column'}}
                initial={{opacity : 0.7, x : 24}}
                animate={{opacity : 1, x : 0}}
                exit={{opacity : 0, x : 24}}
                transition={{duration : 0.2}}
            >
                <SC.BackClosableTitle 
                    style={{
                        padding : '4px',
                        borderBottom : SC.CurrentTheme.theme.border_ondark,
                        backgroundColor : SC.CurrentTheme.theme.back_lighter
                    }} 
                    {...selectionProps.header}
                />
                {selectionProps.content}
            </motion.div>
        )
        
        return content;
    }
}

export const TOKEN_EXPORT_TARGETS = [
    {id : 'json', label : 'JSON'},
    {id : 'js', label : 'Javascript'},
    {id : 'css', label : 'CSS'},
    {id : 'sass', label : 'Sass'},
    {id : 'less', label : 'Less'},
    {id : 'yaml', label : 'Yaml'},
    {id : 'android', label : 'Android'},
    {id : 'ios', label : 'iOS'},
]
