import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    AppLayout
} from '../../../importer';

import styled, {css} from 'styled-components';
import TextInput from '../../../components/editors/textinput';
import { Seperator } from '../../project/panels/right/iteminspector/styleitems/common';
import { DotMenu } from '../../project/panels/left/common';
import DropDownSelect from '../../../components/editors/enum_dropdown';
import { GetDataApi, GetUserId } from '../../../appstate/AppState';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import Input_Name from '../../../components/editors/input_name';
import { InfoPanel } from '../../../components/info';
import AuditManager from '../../project/manager/auditmanager';

const style_header = {
    fontSize : '24px', 
    paddingLeft : '4px', 
    fontWeight : 500, 
    letterSpacing : '1.2px'
}


export default class ProjectList extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.EditProject = this.EditProject.bind(this);
        this.Delete = this.Delete.bind(this);
        this.Clone = this.Clone.bind(this);
        this.ValidateNewItem = this.ValidateNewItem.bind(this);
    }
    componentDidMount() {
        super.componentDidMount();
        if (this.props.teamId) {
            this.RefListenerTeamProjects = GetDataApi().api.get_ref(`/teams/${this.props.teamId}/projects`);
            this.RefListenerTeamProjects.on("value", (snapshot) => {
                this.props.onLoad();
            });
        }        
    }
    componentWillUnmount() {
        super.componentWillUnmount();
        if (this.RefListenerTeamProjects) {
            this.RefListenerTeamProjects.off('value');
        }
    }
    EditProject(id) {
        super.RedirectTo(Links.Board_Edit(id));
    }
    Delete(id, isArchieved) {
        if (isArchieved) {
            AppState.Data.Board.DeleteFromArchive(id, this.props.teamId).then((result) => {
                this.props.onLoad();
            })
        }
        else {
            AppState.Data.Board.Delete(id, this.props.teamId).then((result) => {
                this.props.onLoad();
            })
        }           
    }
    Clone(id) {
        AppState.Data.Board.Clone(id).then((result) => {
            this.props.onLoad();
        })
    } 
    ValidateNewItem(name) {
        let result = {

        };
        if (!Utils.CheckUnique(name, this.props.projects, 'name', null)) {
            result.error = true;
            result.message = `Name must be unique`;
        }
        return result;
    }    
    renderCustom() {
        let content;
        if (this.props.loading) {
            content = (
                <SC.Loading_Icon small />
            )
        }
        else {
            const projects = [], deletedProjects = [];

            (this.props.projects || []).map((project) => {
                if (project.deleted)
                    deletedProjects.push(project);
                else 
                    projects.push(project);
            });

            let useProjects = this.props.deleted ? deletedProjects : projects;

            if (!this.props.deleted && projects.length === 0) {
                content = (
                    <InfoPanel 
                        style={{
                            padding : '16px',
                            lineHeight : '22px',
                            cursor : 'pointer',
                            fontSize : '14px',
                            marginTop : '16px',                            
                        }}
                        hoverStyle={{
                            backgroundColor : SC.CurrentTheme.theme.back_lightest
                        }}
                        onClick={this.props.onNew}
                    >
                        <div style={{fontWeight : 'bold', color : SC.CurrentTheme.theme.font_hover, marginBottom : '16px'}}>No Projects</div>
                        {this.props.teamId ? 'Create Team Project' : 'Create New Project'}
                    </InfoPanel>
                )
            }
            else {
                content = ( 
                    <Droppable 
                        droppableId='MyProjects'
                        type='MYPROJECTS'
                    >
                        {
                            provided => (
                                <div
                                    {...provided.droppableProps} 
                                    ref={provided.innerRef} 
                                    style={{
                                        ...provided.droppableProps.style,
                                        ...SC.Styles.Flex.Column
                                    }}
                                >
                                    {
                                        useProjects.map((project, i) => {
                                            if (project.filtered)
                                                return null;
                                            if (Utils.IsTrue(project.deleted) !== Utils.IsTrue(this.props.deleted))
                                                return null;
                                            return (
                                                <ProjectCard 
                                                    draggable={false}
                                                    index={i}
                                                    model={project} 
                                                    key={project.id}
                                                    teamId={this.props.teamId} 
                                                    admin={this.props.admin}
                                                    shareable={this.props.shareable}
                                                    canDelete={this.props.canDelete}
                                                    onEdit={this.EditProject}
                                                    onDelete={this.Delete}
                                                    onClone={this.Clone}
                                                    onRestored={this.props.onLoad}
                                                    onLoad={this.props.onLoad}
                                                    onGetTeams={this.props.onGetTeams}
                                                    ValidateName={this.ValidateNewItem}                                                            
                                                />
                                            )
                                        })
                                    }
                                    {provided.placeholder}
                                </div>
                            )
                        }                                  
                    </Droppable>
                )   
            }                        
        }
        return (
            <SC.FCol style={this.props.style}>
                <div  style={style_header}>
                    {this.props.title}
                </div>
                {content}
            </SC.FCol>
        )
    }
}

export class ProjectCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.onRename = this.onRename.bind(this);        
        this.onSubmitName = this.onSubmitName.bind(this);
        
        this.onClone = this.onClone.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.Restore = this.Restore.bind(this);        

        this.Share = this.Share.bind(this);        
        this.onRemoveFromTeam = this.onRemoveFromTeam.bind(this);        
        this.EditProject = this.EditProject.bind(this);        
        this.ShowMenu = this.ShowMenu.bind(this);
    }
    ShowMenu(e) {
        this.setState({showMenu : !this.state.showMenu, showTeamMenu : false});
    } 
    onRename() {
        this.setState({renaming : true, showMenu : false});
    }
    onSubmitName() {
        const oldName = this.props.model.Name || this.props.model.name;
        setTimeout(() => {
            this.props.model.Name = this.Ref_Name.GetValue();
            if (this.props.model.Name !== oldName) {
                AppState.Data.Board.ChangeProp(this.props.model.id, 'Name', this.props.model.Name);
                this.setState({renaming : false}, () => {
                    const audit = new AuditManager();
                    audit.InsertLog({
                        UserId : GetUserId(),
                        Editor : 'DSM',
                        Type : 'System',
                        ProjectId : this.props.model.id,
                        Data : {
                            prop : 'name',
                            old : oldName,
                            new : this.props.model.Name
                        }
                    });
                });      
            }                  
        }, 10);        
    }
    EditProject() {
        if (this.state.renaming) {
            return;
        }
        this.setState({showMenu : false}, () => {
            this.props.onEdit(this.props.model.id);
        });
    }
    onClone() {
        this.setState({showMenu : false}, () => {
            this.props.onClone(this.props.model.id);
        });
    }
    onDelete() {
        this.setState({showMenu : false}, () => {
            this.props.onDelete(this.props.model.id, this.props.model.deleted);
        });        
    }    
    Restore(e) {
        e && e.stopPropagation();

        this.setState({showMenu : false}, () => {
            AppState.Data.Board.RestoreDeleted(this.props.model.id, this.props.teamId);
            delete this.props.model.deleted;
            this.props.onRestored();
        });         
    }
    Share() {   
        this.setState({showTeamMenu : true});     
    }
    onRemoveFromTeam() {
        GetDataApi().unmake_team_project(this.props.model.id, this.props.teamId).then(() => {
            this.props.onLoad();
        })
    }
    ShareWithTeam(teamId) {
        this.setState({showMenu : false}, () => {
            GetDataApi().make_team_project(this.props.model.id, teamId, this.props.teamId).then(() => {
                this.props.onLoad();
            })
        }); 
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (!this.state.showMenu && nextState.showMenu) {
            const teams = this.props.onGetTeams();
            this.activeTeams = [];
            teams.map((team) => {
                if (team.owner) 
                    this.activeTeams.push(team);
            })
            teams.map((team) => {
                if (team.active && !team.owner) 
                    this.activeTeams.push(team);
            })
                    
        }
        return true;
    }
    renderItem(dragHandle) {
        const {model} = this.props;
        const name = model.name || model.Name;

        let menuContent;
        if (this.state.showMenu) {
            if (this.state.showTeamMenu) {
                const teams = this.props.onGetTeams();
                menuContent = (
                    <SC.FCol style={{minWidth : '160px'}}>
                        <SC.BackClosableTitle title='SELECT TEAM' onClick={() => {this.setState({showTeamMenu : false})}} style={{marginBottom : '8px'}} />
                        {
                            this.activeTeams.map(({name, id}) => {
                                if (id === this.props.teamId)
                                    return null;
                                return (
                                    <SC.PopupItem onClick={this.ShareWithTeam.bind(this, id)}>
                                        {name}
                                    </SC.PopupItem>
                                )
                            })
                        }
                    </SC.FCol>
                )
            }
            else {
                let shareWithTeam = 'Share with Team';
                if (this.activeTeams && this.activeTeams.length === 1) {
                    shareWithTeam = `Share with Team ${this.activeTeams[0].name}`;
                }
                const isProjectOwner = !this.props.teamId || model.ownerUserId === GetUserId();
                const isAdminOrNotTeam = this.props.admin || !this.props.teamId;
                menuContent = (
                    <SC.FCol style={{minWidth : '160px'}}>         
                        {
                            isAdminOrNotTeam && 
                            <SC.PopupItem onClick={this.onRename}>
                                Rename
                            </SC.PopupItem>
                        }                                                               
                        <SC.PopupItem onClick={this.onClone}>
                            Duplicate
                        </SC.PopupItem>
                        {
                            model.deleted && isAdminOrNotTeam &&
                            <React.Fragment>
                                <SC.PopupItem onClick={this.Restore}>
                                    Restore
                                </SC.PopupItem>
                                <SC.PopupItem onClick={this.onDelete}>
                                    Delete Permanently
                                </SC.PopupItem>
                            </React.Fragment>                                 
                        }                        
                        {
                            !model.deleted &&
                            <React.Fragment>
                                {
                                    ( this.props.admin && this.props.teamId) &&
                                    <SC.PopupItem onClick={this.onRemoveFromTeam}>
                                        Remove from Team
                                    </SC.PopupItem>
                                }
                                {
                                    (this.props.shareable || this.props.admin || !this.props.teamId) &&
                                    <SC.PopupItem onClick={this.Share}>
                                        {this.props.teamId ? 'Move to Another Team' : shareWithTeam}
                                    </SC.PopupItem>  
                                }   
                                {
                                    this.props.canDelete && isAdminOrNotTeam &&
                                    <SC.PopupItem onClick={this.onDelete}>
                                        Delete
                                    </SC.PopupItem>
                                }                                                                 
                            </React.Fragment>
                        }                                                                                      
                    </SC.FCol>
                )
            }
        }

        return (
            <SC.HoverParent 
                defaultStyle={{
                    padding : '16px',
                    paddingTop : this.props.noMenu ? '16px' : '8px',
                    paddingBottom : this.props.noMenu ? '16px' : '8px',
                    backgroundColor : SC.CurrentTheme.theme.back_lighter,
                    borderRadius : '4px',
                    border : SC.CurrentTheme.theme.border_seperator,                    
                    position : 'relative',
                    ...SC.Styles.Flex.RowAlcJsb,
                    ...this.props.style,
                    cursor : this.state.renaming ? 'default' : 'pointer',
                    fontSize : '14px',
                    marginTop : this.props.index > 0 ? '16px' : 0
                }} 
                hoverStyle={{
                    backgroundColor : SC.CurrentTheme.theme.back_lightest,
                }}
                onClick={(this.state.renaming) ? null : this.EditProject}
            >
                {dragHandle}
                {
                    this.state.renaming ? 
                    <Input_Name
                        ref={(r) => this.Ref_Name = r}
                        InputType={Input_ProjectName}
                        required
                        autoFocus
                        value={name || ''}
                        styleProps = {{
                            placeholder : 'Project Name',
                            onBlur : this.onSubmitName
                        }}
                        onValidate={this.props.ValidateName}
                        onCancel={() => {  
                            setTimeout(() => {
                                this.setState({renaming : false});
                            }, 10);                            
                        }}
                        onSubmit={this.onSubmitName}
                        showError
                    /> : 
                    <SC.TextDivAbbr>{name}</SC.TextDivAbbr>
                }
                <SC.FRow alc>
                    {
                        model.deleted && <SC.LinkText primary style={{paddingLeft : '8px', paddingRight : 'px', marginRight : '4px'}} onClick={this.Restore}>Restore</SC.LinkText>
                    }
                    {
                        !this.props.noMenu && 
                        <DotMenu onClick={this.ShowMenu} isOpen={this.state.showMenu} onClose={this.ShowMenu}>
                            { this.state.showMenu && menuContent }
                        </DotMenu>
                    }                    
                </SC.FRow>                
            </SC.HoverParent>
        )
    }
    render() { 
        const {model} = this.props;        
      
        if (this.props.draggable) {
            return (  
                <Draggable key={model.id}  draggableId={model.id} index={this.props.index}>
                    {
                        (provided, snapshot) => {
                            return (
                                <div {...provided.draggableProps} style={{...provided.draggableProps.style, marginTop : '16px'}} ref={provided.innerRef}>                                
                                    {this.renderItem(
                                        <SC.HoverChild defaultStyle={{ width : '24px', ...SC.Styles.Flex.Cell, position : 'absolute', right : '100%', top : '50%', transform : 'translateY(-50%)'}} >
                                            <SC.DragBox {...provided.dragHandleProps} first style={{...provided.dragHandleProps.style, height : 'unset', marginLeft : '4px', border : 'none'}} onMouseDown={(e) => e.stopPropagation()}>
                                                <SC.Icons.DragIcon  style={{pointer : 'unset'}}/>
                                            </SC.DragBox>
                                        </SC.HoverChild>
                                    )}
                                </div>
                            )
                        }
                    }
                </Draggable>            
            );
        }
        return this.renderItem();
    }
}

export const Input_ProjectName = styled(SC.Card_Input) `
    font-size : 14px;
    padding-left : 0px;
    color : ${props => props.theme.text_card_title};
    font-weight : ${props => props.theme.font_weight};
    border-bottom : 1px solid ${props => props.theme.border_onlight};
`;
