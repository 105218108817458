import {
    AppState,
    Events,
    Strings,
    MetaData,
    Utils,
    UIUtils
} from '../../../../importer';

import { LOG_TYPES } from '../../../../appstate/historymanager/base';
import ComponentDesignManager from '.';


export default class ComponentActionManager {
    constructor(Manager) {
        this.State = 'Default';

        this.Manager = Manager;    
        const that_manager = this;

        this.ItemEvents = {
            AddEvent(MetaItem, Id, NewEventModel) {
                const NewEvent = NewEventModel || {
                    id : Utils.Id()
                };
                let Events = Utils.Get(MetaItem, [], Strings.EVENTS);                
                if (!Array.isArray(Events))
                    Events = [];
                Events.push(NewEvent);
                that_manager.Manager.DataManager.Item.Set(Id, Events, Strings.EVENTS);
                return NewEvent;
            },
            GetEvents(MetaItem) {
                return Utils.Get(MetaItem, [], Strings.EVENTS);
            },            
            IsEventValid(EventItem) {
                if (!EventItem.triggerId)
                    return false;
                if (EventItem.conditional) {
                    if (!EventItem.conditionItems)
                        return false;
                    if (!EventItem.conditionItems.if)
                        return false;
                    if (!EventItem.conditionItems.if.conditionId)
                        return false;
                    if (!EventItem.conditionItems.if.runActionId)
                        return false;
                }

                if (!EventItem.runActionId)
                    return false;

                return true;
            },
            DeleteEvent(MetaItem, Id, EventId) {
                let Events = Utils.Get(MetaItem, [], Strings.EVENTS);       
                if (!Array.isArray(Events)) {
                    Events = Utils.ToArray(Events);
                }
                Utils.Remove(Events, (Item) => {return Item.id === EventId});
                that_manager.Manager.DataManager.Item.Set(Id, Events, Strings.EVENTS);
            },
            SetTrigger(MetaItem, Id, EventId, Trigger) {
                const Events = Utils.Get(MetaItem, [], Strings.EVENTS);
                const EventModel = Utils.Find(Events, (Item) => {return Item.id === EventId});
                if (EventModel) {
                    Utils.Set(EventModel, Trigger, 'triggerId');
                    that_manager.Manager.DataManager.Item.Set(Id, Events, Strings.EVENTS);
                }
            },
            SetActionId(MetaItem, Id, EventId, Model, ActionId) {
                const Events = Utils.Get(MetaItem, [], Strings.EVENTS);
                const EventModel = Utils.Find(Events, (Item) => {return Item.id === EventId});
                if (EventModel) {
                    const Prop = 'runActionId';
                    let PropValue = Utils.JustGet(EventModel, null, Prop, that_manager.Manager.GlobalState, that_manager.Manager.ComponentState);
                    if (PropValue) {
                        if (PropValue.value !== ActionId && PropValue.RelationId) {
                            this.Manager.RelationManager.DeleteRelationId(PropValue.RelationId);
                        }
                    }                                        
                    const RelationId = this.Manager.RelationManager.AddRelation(Id, EventId, ActionId, {
                        OwnerType : this.Manager.RelationManager.Types.COMPONENT,
                        ConsumerType : this.Manager.RelationManager.Types.METAITEM,
                        TargetType : this.Manager.RelationManager.Types.ACTION
                    })
                    Utils.Set(Model, {value : ActionId, RelationId : RelationId}, Prop, that_manager.Manager.GlobalState, that_manager.Manager.ComponentState);
                    that_manager.Manager.DataManager.Item.Set(Id, Events, Strings.EVENTS);
                }     
            },
            SaveEventValue(MetaItem, Id, EventId, Prop, Value, options = {}) {
                const Events = Utils.Get(MetaItem, [], Strings.EVENTS);
                const EventModel = Utils.Find(Events, (Item) => {return Item.id === EventId});
                if (EventModel) {
                    let State = that_manager.Manager.ComponentState;
                    const GlobalState = that_manager.Manager.GlobalState;
                    if (State !== Strings.DEFAULT && !options.exact) {
                        const DefaultStateValue = Utils.JustGet(EventModel, null, Prop, Strings.DEFAULT);
                        if (Utils.IsNull(DefaultStateValue)) {
                            State = Strings.DEFAULT;
                        }
                    }
                    Utils.Set(EventModel, {value : Value}, Prop, GlobalState, State);
                    that_manager.Manager.DataManager.Item.Set(Id, Events, Strings.EVENTS);
                }                
            },
            GetEventValue(MetaItem, Id, EventId, Prop, options = {}) {
                const Events = Utils.Get(MetaItem, [], Strings.EVENTS);
                const EventModel = Utils.Find(Events, (Item) => {return Item.id === EventId});
                if (EventModel) {
                    return this.GetStateValue(EventModel, Prop, 'value', options.exact);
                }
            },
            GetStateValue(Owner, Prop, SubProp, exact, Default) {
                const State = that_manager.Manager.ComponentState;
                const GlobalState = that_manager.Manager.GlobalState;
                let PropValue = Utils.JustGet(Owner, null, Prop, GlobalState, State, SubProp);
                if (Utils.IsNull(PropValue) && !exact) {
                    if (State && State !== 'Default') {
                        if (ComponentDesignManager.Active().ComponentManager.States.StateArray) {
                            Utils.ForEach(ComponentDesignManager.Active().ComponentManager.States.StateArray,(sc) => {
                                const StatePropValue = Utils.JustGet(Owner, null, Prop, sc, SubProp);
                                if (Utils.IsNull(PropValue))
                                    PropValue = StatePropValue;                                    
                            });
                        }    
                    }
                }
                return PropValue;
            },
            SetConditional(MetaItem, Id, EventId, IsConditional) {
                const Events = Utils.Get(MetaItem, [], Strings.EVENTS);
                const EventModel = Utils.Find(Events, (Item) => {return Item.id === EventId});
                if (EventModel) {
                    if (EventModel.conditional && !IsConditional) {

                    }
                    else {

                    }
                    EventModel.conditional = IsConditional;
                    that_manager.Manager.DataManager.Item.Set(Id, Events, Strings.EVENTS);
                }
            },            
            SetConditionStateValue(MetaItem, Id, EventId, Condition, Prop, PropValue) {
                const Events = Utils.Get(MetaItem, [], Strings.EVENTS);
                const EventModel = Utils.Find(Events, (Item) => {return Item.id === EventId});
                if (EventModel) {
                    const OldActionId = this.GetStateValue(Condition, Prop, 'value', true);           
                    if (OldActionId && OldActionId !== PropValue) {
                        const OldRelationId = this.GetStateValue(Condition, Prop, 'RelationId', true);
                        OldRelationId && this.Manager.RelationManager.DeleteRelationId(OldRelationId);
                    }
                    const State = that_manager.Manager.ComponentState;
                    const GlobalState = that_manager.Manager.GlobalState;
                    Utils.Set(Condition, {
                        value : PropValue,
                        RelationId : this.Manager.RelationManager.AddRelation(that_manager.Manager.DataManager.Id, Id, PropValue, {
                            OwnerType : this.Manager.RelationManager.Types.COMPONENT,
                            ConsumerType : this.Manager.RelationManager.Types.METAITEM,
                            TargetType : this.Manager.RelationManager.Types.ACTION,
                            State : State,
                            handlerName : EventId
                        })
                    }, Prop, GlobalState, State);
                    that_manager.Manager.DataManager.Item.Set(Id, Events, Strings.EVENTS);
                }     
            },
            SaveEvent(MetaItem, Id, EventModel) {
                const Events = Utils.Get(MetaItem, [], Strings.EVENTS);
                const index = Utils.FindIndex(Events, (Item) => {return Item.id === EventModel.id});
                if (index >= 0) {
                    Events[index] = EventModel;
                    that_manager.Manager.DataManager.Item.Set(Id, Events, Strings.EVENTS);
                }
            }
        }

        this.ComponentEvents = {
            AddEvent(NewEventModel) {
                let Events = that_manager.Manager.DataManager.Get([], Strings.INTERACTIONS);
                const NewEvent = NewEventModel || {
                    id : Utils.Id()
                };
                if (!Array.isArray(Events))
                    Events = [];
                Events.push(NewEvent);
                that_manager.Manager.DataManager.Set(Events, Strings.INTERACTIONS);
                return NewEvent;
            },
            GetEvents() {
                return that_manager.Manager.DataManager.Get([], Strings.INTERACTIONS);
            },
            DeleteEvent(EventId) {
                let Events = this.GetEvents();
                if (!Array.isArray(Events)) {
                    Events = Utils.ToArray(Events);
                }
                Utils.Remove(Events, (Item) => {return Item.id === EventId});
                that_manager.Manager.DataManager.Set(Events, Strings.INTERACTIONS);
            },
            GetEventValue(EventId, Prop, options = {}) {
                const Events = this.GetEvents();
                const EventModel = Utils.Find(Events, (Item) => {return Item.id === EventId});
                if (EventModel) {
                    return this.GetStateValue(EventModel, Prop, 'value', options.exact);
                }
            },
            GetStateValue(Owner, Prop, SubProp, exact, Default) {
                return AppState.Manager_MetaItem.Events.GetStateValue(Owner, Prop, SubProp, exact, Default);
            },
            SetStateValue(Owner, Prop, SubProp, Value) {
                let State = that_manager.Manager.ComponentState;
                const GlobalState = that_manager.Manager.GlobalState;
                if (State !== Strings.DEFAULT || GlobalState !== Strings.DEFAULT) {
                    const DefaultStateValue = Utils.JustGet(Owner, null, Prop, Strings.DEFAULT, SubProp);
                    if (Utils.IsNull(DefaultStateValue)) {
                        State = Strings.DEFAULT;
                    }
                }
                Utils.Set(Owner, Value, Prop, GlobalState, State, SubProp);
                const Events = this.GetEvents();
                that_manager.Manager.DataManager.Set(Events, Strings.INTERACTIONS);
            },
            SaveEventValue(EventId, Prop, Value, options = {}) {
                let State = that_manager.Manager.ComponentState;
                const GlobalState = that_manager.Manager.GlobalState;
                const Events = this.GetEvents();
                const EventModel = Utils.Find(Events, (Item) => {return Item.id === EventId});
                if (EventModel) {
                    if (State !== Strings.DEFAULT && !options.exact) {
                        const DefaultStateValue = Utils.JustGet(EventModel, null, Prop, Strings.DEFAULT);
                        if (Utils.IsNull(DefaultStateValue)) {
                            State = Strings.DEFAULT;
                        }
                    }
                    Utils.Set(EventModel, {value : Value}, Prop, GlobalState, State);
                    that_manager.Manager.DataManager.Set(Events, Strings.INTERACTIONS);
                }                
            },
            SetTrigger(EventId, Trigger) {
                const Events = this.GetEvents();
                const EventModel = Utils.Find(Events, (Item) => {return Item.id === EventId});
                if (EventModel) {
                    Utils.Set(EventModel, Trigger, 'triggerId');
                    that_manager.Manager.DataManager.Set(Events, Strings.INTERACTIONS);
                }
            },
            SetActionId(EventId, Model, ActionId) {                
                const Events = this.GetEvents();
                const EventModel = Utils.Find(Events, (Item) => {return Item.id === EventId});
                if (EventModel) {
                    let State = that_manager.Manager.ComponentState;
                    const GlobalState = that_manager.Manager.GlobalState;
                    const Prop = 'runActionId';                    
                    let PropValue = Utils.JustGet(EventModel, null, Prop, GlobalState, State, 'value');
                    if (PropValue) {

                    }                    
                    Utils.Set(Model, {value : ActionId}, Prop, GlobalState, State);
                    that_manager.Manager.DataManager.Set(Events, Strings.INTERACTIONS);
                }     
            },
            SetTriggerEventId(EventId, Model, TriggerEventId) {
                const Events = this.GetEvents();
                const EventModel = Utils.Find(Events, (Item) => {return Item.id === EventId});
                if (EventModel) {
                    const State = that_manager.Manager.ComponentState;
                    const GlobalState = that_manager.Manager.GlobalState;
                    const Prop = 'onEventId';
                    let PropValue = Utils.JustGet(EventModel, null, Prop, GlobalState, State, 'value');
                    if (PropValue) {

                    }                    
                    Utils.Set(Model, {value : TriggerEventId}, Prop, GlobalState, State);
                    that_manager.Manager.DataManager.Set(Events, Strings.INTERACTIONS);
                }     
            },
            SetTriggerModelId(EventId, Model, TriggerModelId) {
                const Events = this.GetEvents();
                const EventModel = Utils.Find(Events, (Item) => {return Item.id === EventId});
                if (EventModel) {
                    const Prop = 'onModelId';
                    let State = that_manager.Manager.ComponentState;
                    const GlobalState = that_manager.Manager.GlobalState;
                    let PropValue = Utils.JustGet(EventModel, null, Prop, GlobalState, State, 'value');
                    if (PropValue) {

                    }                    
                    Utils.Set(Model, {value : TriggerModelId}, Prop, GlobalState, State);
                    that_manager.Manager.DataManager.Set(Events, Strings.INTERACTIONS);
                }     
            },
            SetTriggerConditionId(EventId, Model, ConditionId) {
                const Events = this.GetEvents();
                const EventModel = Utils.Find(Events, (Item) => {return Item.id === EventId});
                if (EventModel) {
                    const Prop = 'conditionId';
                    let State = that_manager.Manager.ComponentState;
                    const GlobalState = that_manager.Manager.GlobalState;
                    let PropValue = Utils.JustGet(EventModel, null, Prop, GlobalState, State, 'value');
                    if (PropValue) {

                    }                    
                    Utils.Set(Model, {value : ConditionId}, Prop, GlobalState, State);
                    that_manager.Manager.DataManager.Set(Events, Strings.INTERACTIONS);
                }     
            },
            SetConditionStateValue(EventId, Condition, Prop, PropValue) {
                const Events = this.GetEvents();
                const EventModel = Utils.Find(Events, (Item) => {return Item.id === EventId});
                if (EventModel) {
                    const OldActionId = this.GetStateValue(Condition, Prop, 'value', true);           
                    if (OldActionId && OldActionId !== PropValue) {
                        const OldRelationId = this.GetStateValue(Condition, Prop, 'RelationId', true);
                        OldRelationId && AppState.this.Manager.RelationManager.DeleteRelationId(OldRelationId);
                    }
                    let State = that_manager.Manager.ComponentState;
                    const GlobalState = that_manager.Manager.GlobalState;
                    Utils.Set(Condition, {
                        value : PropValue,
                        RelationId : AppState.this.Manager.RelationManager.AddRelation(that_manager.Manager.DataManager.Id, that_manager.Manager.DataManager.Id, PropValue, {
                            OwnerType : AppState.this.Manager.RelationManager.Types.COMPONENT,
                            ConsumerType : AppState.this.Manager.RelationManager.Types.COMPONENT,
                            TargetType : AppState.this.Manager.RelationManager.Types.ACTION,
                            State : State,
                            handlerName : EventId
                        })
                    }, Prop, GlobalState, State);
                    that_manager.Manager.DataManager.Set(Events, Strings.INTERACTIONS);
                }     
            },
            SetConditional(EventId, IsConditional) {
                const Events = this.GetEvents();
                const EventModel = Utils.Find(Events, (Item) => {return Item.id === EventId});
                if (EventModel) {
                    if (EventModel.conditional && !IsConditional) {

                    }
                    else {

                    }
                    EventModel.conditional = IsConditional;
                    that_manager.Manager.DataManager.Set(Events, Strings.INTERACTIONS);
                }
            }, 
        }
        this.Manager.ConditionManager = {
            List() {
                return that_manager.Manager.DataManager.Get({}, Strings.CONDITIONS);
            },
            GetModel(Id) {
                return that_manager.Manager.DataManager.Get({}, Strings.CONDITIONS, Id);
            },
            Add(Model) {
                // TODO Check Duplicate Name
                const Id = Utils.Id();
                ComponentDesignManager.Active().ComponentManager.Log({
                    Target : LOG_TYPES.EVENTS,
                    Id : Id,
                    Desc : `New Condition : ${Model.name}`
                });

                const ModelOrders = that_manager.Manager.DataManager.Get([], Strings.CONDITIONS, Strings.ORDER);
                ModelOrders.push(Id);
                that_manager.Manager.DataManager.Set(ModelOrders, Strings.CONDITIONS, Strings.ORDER);
                that_manager.Manager.DataManager.Set(Model, Strings.CONDITIONS, Id);
                const Models = this.List();
                Models[Id] = Model;
                return Id;
            },
            AddDefault(name) {
                return this.Add(
                    {
                        name : name || 'Default Condition'                        
                    }
                );
            },
            DeleteModel(Id) {
                if (this.Manager.RelationManager.HasRelation(Id)) {
                    Events.AlertSimple(Strings.Error_Delete(Strings.MESSAGE_MODEL_IS_USED), Events.GLOBAL.NOTIFICATION.TYPES.WARNING);
                    return false;
                }
                const Model = this.GetModel(Id);

                ComponentDesignManager.Active().ComponentManager.Log({
                    Target : LOG_TYPES.EVENTS,
                    Id : Id,
                    Desc : `Event Handler Deleted : ${Model.name}`
                });

                that_manager.Manager.DataManager.Delete(Strings.CONDITIONS, Id);
                // TODO : Delete from Models.States...

                // Delete Condition Relations
                Utils.ForEach(Model.Conditions,(Condition, i) => {
                    this.Manager.RelationManager.DeleteConsumer(that_manager.Manager.DataManager.Id, Condition.Id);
                });

                const ModelOrders = that_manager.Manager.DataManager.Get([], Strings.CONDITIONS, Strings.ORDER);
                Utils.RemoveEquals(ModelOrders, Id);
                that_manager.Manager.DataManager.Set(ModelOrders, Strings.CONDITIONS, Strings.ORDER);
                Events.BCE(Events.DESIGNER.CONDITIONVIEW, 'Deleted', Id);
                return true;
            },
            UpdateProp(Id, Prop, Value) {
                that_manager.Manager.DataManager.Set(Value, Strings.CONDITIONS, Id, Prop);
            },
            UpdateCondition(Id, Value) {
                that_manager.Manager.DataManager.Set(Value, Strings.CONDITIONS, Id);
            }

        }
    }

    List() {
        return this.Manager.DataManager.Get({}, Strings.EVENTHANDLERS);
    }
    Events() {
        return this.Manager.DataManager.Get({}, Strings.EVENTS);
    }
    GetModel(Id) {
        return this.Manager.DataManager.Get({}, Strings.EVENTHANDLERS)[Id];
    }
    GetEvent(Id) {
        return this.Manager.DataManager.Get({}, Strings.EVENTS)[Id];
    }
    AddEvent(Model) {
        ComponentDesignManager.Active().ComponentManager.Log({
            Target : LOG_TYPES.EVENTS,
            Id : Id,
            Desc : `New Event : ${Model.name}`
        });

        const Models = this.Events();
        const Id = Utils.Id();
        Models[Id] = Model;
        this.Manager.DataManager.Set(Model, Strings.EVENTS, Id);
        const ModelOrders = this.Manager.DataManager.Get([], Strings.EVENTS, Strings.ORDER);
        ModelOrders.push(Id);
        this.Manager.DataManager.Set(ModelOrders, Strings.EVENTS, Strings.ORDER);
        return Id;
    }
    AddDefault(name) {
        return this.Add(
            {
                name : name || 'Default Event Handler',
                Actions : [{
                    Id : Utils.Id(),
                    Type : MetaData.Actions.ToState.Type,
                    name : 'Default Action',
                    Definition : {
                        StateType : 'new'
                    }
                }]
            }
        );
    }
    Add(Model) {
        // TODO Check Duplicate Name
        ComponentDesignManager.Active().ComponentManager.Log({
            Target : LOG_TYPES.EVENTS,
            Id : Id,
            Desc : `New Event Handler : ${Model.name}`
        });

        const Models = this.List();
        const Id = Utils.Id();
        Models[Id] = Model;
        this.Manager.DataManager.Set(Model, Strings.EVENTHANDLERS, Id);
        const ModelOrders = this.Manager.DataManager.Get([], Strings.EVENTHANDLERS, Strings.ORDER);
        ModelOrders.push(Id);
        this.Manager.DataManager.Set(ModelOrders, Strings.EVENTHANDLERS, Strings.ORDER);
        return Id;
    }
    Clone(Id) {
        const source = this.GetModel(Id);
    }
    DeleteEvent(Id) {
        if (this.Manager.RelationManager.HasRelation(Id)) {
            Events.AlertSimple(Strings.Error_Delete(Strings.MESSAGE_MODEL_IS_USED), Events.GLOBAL.NOTIFICATION.TYPES.WARNING);
            return false;
        }
        const Model = this.GetEvent(Id);

        ComponentDesignManager.Active().ComponentManager.Log({
            Target : LOG_TYPES.EVENTS,
            Id : Id,
            Desc : `Event Handler Deleted : ${Model.name}`
        });

        this.Manager.DataManager.Delete(Strings.EVENTS, Id);

        const ModelOrders = this.Manager.DataManager.Get([], Strings.EVENTS, Strings.ORDER);
        Utils.RemoveEquals(ModelOrders, Id);
        this.Manager.DataManager.Set(ModelOrders, Strings.EVENTS, Strings.ORDER);
        return true;
    }
    DeleteModel(Id) {
        if (this.Manager.RelationManager.HasRelation(Id)) {
            Events.AlertSimple(Strings.Error_Delete(Strings.MESSAGE_MODEL_IS_USED), Events.GLOBAL.NOTIFICATION.TYPES.WARNING);
            return false;
        }
        const Model = this.GetModel(Id);
        if (!Model)
            return;

            ComponentDesignManager.Active().ComponentManager.Log({
            Target : LOG_TYPES.EVENTS,
            Id : Id,
            Desc : `Event Handler Deleted : ${Model.name}`
        });

        this.Manager.DataManager.Delete(Strings.EVENTHANDLERS, Id);
        // TODO : Delete from Models.States...

        // Delete Action Relations
        Utils.ForEach(Model.Actions,(action, i) => {
            this.Manager.RelationManager.DeleteConsumer(this.Manager.DataManager.Id, action.Id);
        });

        const ModelOrders = this.Manager.DataManager.Get([], Strings.EVENTHANDLERS, Strings.ORDER);
        Utils.RemoveEquals(ModelOrders, Id);
        this.Manager.DataManager.Set(ModelOrders, Strings.EVENTHANDLERS, Strings.ORDER);
        Events.BCE(Events.DESIGNER.ACTIONVIEW, 'Deleted', Id);
        return true;
    }
    UpdateProp(Id, Prop, Value) {
        this.Manager.DataManager.Set(Value, Strings.EVENTHANDLERS, Id, Prop);
    }
    UpdateAction(Id, Index, Value) {
        this.Manager.DataManager.Set(Value, Strings.EVENTHANDLERS, Id, 'Actions', Index);
    }

    
}
