import React from 'react';
import ReactDOM from 'react-dom';
import {
    SC,
    Utils,
    AppLayout,
    Globals
} from '../../../../../../../importer';
import {motion} from 'framer-motion';
import { GetSpacePatternItem } from '../../../../../panels/left/designsystem/spacing/patterns';
import { GetTokenAliaseListOfIds, GetTokenAliaseList } from '../../../../../panels/left/designsystem/aliases';
import { DocumentTextValue, DocumentLabel } from '../typography';

export default class DocumentToken_Spacing extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {

        };    
    }
    shouldComponentUpdate(nextProps, nextState) {

        if (this.state.ShouldUpdate !== nextState.ShouldUpdate)
            return true;
        
        if (Utils.HasAnyChange(this.props, nextProps, 'GlobalThemeId', 'GlobalState', 'StyleId', 'RenderId', 'LoadId', 'themeId', 'themeType', 'states', 'ReversedStyleState', 'isAliase'))
            return true;
        
        return false;
    }
    componentDidUpdate(prevProps, prevState) {
        this.state.ShouldUpdate = false;
    }
    render() {        
        
        if (this.props.subType === 'scale') {
            const spaceScale = Globals.ProjectManager.Tokens.SpaceScale.Get(this.props.GlobalState, this.props.ReversedStyleState);
            let spaceScaleName = spaceScale.ratio;
            if (spaceScale.scaleType !== 'Custom') {
                const spaceScaleItem = Utils.Find(Globals.ProjectManager.Tokens.Utils.ScalePresets, (item) => {return item.id === spaceScale.scaleType});
                if (spaceScaleItem)
                    spaceScaleName = `${spaceScaleItem.label} - Factor of ${spaceScale.ratio}`
            }
            
            return (
                <div
                    style={{
                        display : 'grid',
                        gridTemplateColumns : 'auto 1fr',
                        gridColumnGap : '16px',
                        gridRowGap : '8px',
                        alignContent : 'start',                            
                        fontSize : '14px',
                        padding : '16px',
                        borderRadius : '4px',
                        alignSelf : 'flex-start',
                        backgroundColor : this.props.textStyles.backgroundHighlight
                    }}
                >
                    <DocumentLabel>Space Scale</DocumentLabel>
                    <DocumentTextValue>{spaceScaleName}</DocumentTextValue>
                    <DocumentLabel>Base Space</DocumentLabel>
                    <DocumentTextValue>{spaceScale.baseSize} px</DocumentTextValue>
                </div>
            )
        }
        else {
            const patterns = [];
            if (this.props.isAliase) {
                const aliases = this.props.selection.type === 'selected' ? GetTokenAliaseListOfIds(this.props.selection.tokenIds, this.props.ReversedStyleState) : GetTokenAliaseList(Globals.ProjectManager.Tokens.Types.Spacings , this.props.ReversedStyleState);
                Utils.ForEach(aliases, (aliase, i) => {
                    const tokenItem = GetSpacePatternItem(aliase.tokenId, this.props.ReversedStyleState);
                    if (tokenItem) {
                        patterns.push({
                            name : aliase.name,
                            spaceSize : tokenItem.spaceValue,
                            spaceUnit : tokenItem.spaceUnit
                        })
                    }                    
                });
            }
            else {
                const spacePatternIds = this.props.selection.type === 'selected' ? this.props.selection.tokenIds : Globals.ProjectManager.Tokens.SpacePatterns.Get();
                
                Utils.ForEach(spacePatternIds, (spacePatternId, ) => {
                    const spacePattern = Globals.ProjectManager.Tokens.Token(spacePatternId);
                    if (spacePattern) {
                        const spaceSize = Globals.ProjectManager.Tokens.SpacePatterns.GetSpaceSize(spacePattern, null, null, this.props.ReversedStyleState);
                        const spaceUnit = Globals.ProjectManager.Tokens.SpacePatterns.GetSpaceUnit(spacePattern, null, null, this.props.ReversedStyleState);
                        patterns.push({
                            name : spacePattern.name,
                            spaceSize : spaceSize,
                            spaceUnit : spaceUnit
                        })
                    }                    
                });
            }

            // const patterns_sorted = Utils.Sort(patterns, (item) => {return item.spaceSize});

            let content_patterns = [];
            Utils.ForEach(patterns, (pattern, ) => {

                content_patterns.push(
                    <React.Fragment>          
                        <div style={{fontFamily : 'monospace'}}>{pattern.name}</div>
                        <div style={{fontFamily : 'monospace'}}>{pattern.spaceSize} {pattern.spaceUnit}</div>
                        <SC.FRow style={{
                            height : '24px',                    
                            alignItems : 'stretch'
                        }}>
                            <div style={{                            
                                backgroundColor : this.props.textStyles.backgroundHighlight,
                                width : '24px',
                            }} />
                            <motion.div
                                style={{
                                    borderLeft : '1px dashed #ff33b1',
                                    borderRight : '1px dashed #ff33b1',
                                    backgroundColor : 'rgba(255, 51, 177,0.2)',
                                    marginTop : '-4px',
                                    marginBottom : '-4px'
                                }}
                                initial={{
                                    width : 0
                                }}
                                animate={{
                                    width : Utils.px(pattern.spaceSize, pattern.spaceUnit),
                                }}
                                transition={{
                                    duration : 0.3
                                }}
                            >
                            
                            </motion.div>
                            <div style={{                            
                                // flex : 1,
                                backgroundColor : this.props.textStyles.backgroundHighlight,
                                width : '24px',
                            }} />
                        </SC.FRow>
                    </React.Fragment>
                )

                if (this.props.forTemplateSelect && content_patterns.length === 3) {
                    return false;
                }
            });

            return (
                <div style={{
                    display : 'grid',
                    gridGap : '16px',
                    gridColumnGap : AppLayout.Media.Small ? '16px' : '48px',
                    gridTemplateColumns : 'auto auto 1fr',
                    gridAutoRows : 'auto',
                    justifyContent : 'start',
                    alignItems : 'center',
                    overflowX : 'auto',
                    paddingBottom : '8px',
                    alignSelf : this.props.alignment,
                    width : this.props.size ? Utils.px(this.props.size, '%') : 'auto'
                }}
                >
                    <div>NAME</div>
                    <div>SIZE</div>       
                    <div />         
                    {content_patterns}
                </div>
                
            )
        }        
    }
}