import React from 'react';

class Widget_Video extends React.Component
{
    constructor(props) {
        super(props);        
    }
    
    render() {        
        return (
            <div  style={{width : '100%', height : '100%'}} >
               <iframe 
                style={{width : '100%', height : '100%'}}
                src={this.props.Id || "https://open.spotify.com/embed/album/1DFixLWuPkv3KT3TnV35m3"}
                frameborder="0" 
                allowtransparency="true" 
                allow="encrypted-media">
               </iframe>
            </div>
        )
    }
}

export default Widget_Video;
