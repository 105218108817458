import React, {Component} from 'react';

class ToolabsReactBase extends React.Component
{
    constructor(props) {
        super(props);
        this.state = {
            ShouldUpdate : false
        };
        this.RCUpdate = this.RCUpdate.bind(this);
    }
    componentDidMount() {
        this.Mounted = true;
    }
    componentWillUnmount() {
        this.Mounted = false;
    }
    RCSetStateValue(Property, Value) {
        if (this.state) {
            if (this.Mounted)
            {
                let state = {};
                state[Property] = Value;
                this.setState(state);
            }
        }
        else {
            this.state = {};
            this.state[Property] = Value;
        }
    }
    RCUpdate() {
        this.RCSetStateValue('ShouldUpdate', true);
    }
    render() {
        this.state.ShouldUpdate = false;
        if (this.renderCustom)
            return this.renderCustom();
        return null;
    }
}
export default ToolabsReactBase;