import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    Strings,
    Globals,
    AppLayout
} from '../../../../../../../importer';

import {TokenGroup, GroupTitle, TokenItemBox, TokenPanelHeader, TokenItemBoxHoveredName, TokenItemDragHandle} from '../../common';
import Dropzone from 'react-dropzone';
import { FontLoader } from '../../../../../../../toolabs-importer';
import FontStyleEditor, { FigmaFontChecker } from './item';
import { StatefulTokenMarker, TokenLockedMarker } from '../../colors';
import { GRANT_TYPES } from '../../../../../manager';

export default class FontTokens extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.SelectFont = this.SelectFont.bind(this);
        this.onDeleteFont = this.onDeleteFont.bind(this);
        this.onCancelAddFont = this.onCancelAddFont.bind(this);
        this.onSubmitNewFont = this.onSubmitNewFont.bind(this);
        this.onToggleExpand = this.onToggleExpand.bind(this);

        this.state.expanded = Globals.ProjectManager.Options.Get(true, 'LeftPanel', 'DesignSystem', 'Fonts', 'Expanded');

        this.dark = true;        
    }     
    onToggleExpand() {
        
        Globals.ProjectManager.Options.Set(!this.state.expanded, 'LeftPanel', 'DesignSystem', 'Fonts', 'Expanded');
        this.setState({
            expanded : !this.state.expanded
        });
    }   
    SelectFont() {
        if (!this.props.hasEditGrant)
            return;

        this.props.onSelectFont((fontId) => {
            this.onEditFont(fontId);
        }, null, null);
    }
    onEditFont(id) {
        if (!Globals.ProjectManager.CheckGrant(GRANT_TYPES.EDIT_TOKEN.ALL)) {
            return;
        }

        this.EditFontId = id;
        const isDefaultFont = this.EditFontId === 'DefaultFont';
        
        if (Globals.ProjectManager.AuditManager) {
            this.WillEditToken = Utils.DeepClone(Globals.ProjectManager.Tokens.Token(id));
        }

        this.props.onPanelOverlay({
            show : true,
            render : (props) => {
                return (
                    <FontStyleEditor 
                        id={id}
                        isDefaultFont={isDefaultFont}
                        GlobalStateId={this.props.GlobalStateId}
                        onClose={this.onCancelAddFont}
                        onCancel={this.onCancelAddFont}
                        onDelete={isDefaultFont ? null : this.onDeleteFont}
                        onBroadCastChange={this.props.onBroadCastChange}
                        onClosed={() => {
                            const tokencurrent = Globals.ProjectManager.Tokens.Token(id);
                            
                            if (this.WillEditToken && tokencurrent) { 
                                const diff = Utils.GetFlattenedDiff(this.WillEditToken, tokencurrent);
                                if (diff.length > 0) {
                                    Globals.ProjectManager.AuditManager && Globals.ProjectManager.AuditManager.TokenChanged({TokenId : id, Change : {
                                        changes : diff
                                    }});
                                }                                                                        
                            }
                            delete this.WillEditToken;
                        }}
                        GetPluginApi={this.props.GetPluginApi}
                    />              
                )
            }            
        })
    }
    onCancelAddFont() {
        delete this.IsNew;
        delete this.EditFontModel;
        delete this.EditFontId;

        this.props.onPanelOverlay({close : true});
        this.RCUpdate();
    }
    onSubmitNewFont() {
        this.onCancelAddFont();
    }
    onDeleteFont() {
        if (!Globals.ProjectManager.CheckGrant(GRANT_TYPES.DELETE_TOKEN.ALL)) {
            return;
        }
        const font = Globals.ProjectManager.Tokens.Token(this.EditFontId);

        if (Globals.ProjectManager.Tokens.Delete(Globals.ProjectManager.Tokens.Types.Fonts, this.EditFontId)) {            
            this.props.onFontDeleted && this.props.onFontDeleted(this.EditFontId, font);
            this.onCancelAddFont();
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.GlobalState !== nextProps.GlobalState || this.props.GlobalStateId !== nextProps.GlobalStateId || this.props.filterText !== nextProps.filterText) {
            return true;
        }
        if (this.state.expanded !== nextState.expanded)
            return true;

        return super.ShouldUpdate(nextProps, nextState);
    }

    DropCustomFontFile(MetaFile, event) {
        if (event && event.target && event.target.result) {
            MetaFile.base64 = event.target.result;                
        
            Globals.ProjectManager.Tokens.UploadCustomFont(MetaFile, () => {
                Globals.ProjectManager.Tokens.Add({
                    type : Globals.ProjectManager.Tokens.Types.Fonts,
                    name : MetaFile.Name,
                    value : {
                        provider : Strings.CUSTOM,
                        fontId : MetaFile.Id,
                        name : MetaFile.Name,
                        family : MetaFile.family
                    }
                });

                Utils.Remove(this.LoadingFontItems, (item) => {return item.Id === MetaFile.Id});
                this.RCUpdate();
            });
        }
    }
    DropMultipleFiles(files) {
        this.LoadingFontItems = [];

        Utils.ForEach(files,(file, i) => {
            this.LoadingProgress = 0;
            this.RCUpdate();  
            
            const MetaFile = {
                Id : Utils.Id(),
                fileName : file.name,
                Name : file.name.replace(/\.[^/.]+$/, ""),
                family : file.name.replace(/\.[^/.]+$/, "")
            };
            this.LoadingFontItems.push(MetaFile);
            
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = this.DropCustomFontFile.bind(this, MetaFile);
        });
        this.RCUpdate();
    }


    renderCustom() {
        const fonts = Globals.ProjectManager.Tokens.Fonts();
        const defaultFont = Globals.ProjectManager.Tokens.DefaultFont();
        const infoDefault = {};
        const tokenDefault  = Globals.ProjectManager.Tokens.Token('DefaultFont');
        const defaultFontValue = Globals.ProjectManager.Tokens.ValueOf({model : tokenDefault, info : infoDefault}) || {};
        let fontItems = [];        

        if (this.state.expanded) {

            let filterText;
            if (Utils.IsNotNullOrEmpty(this.props.filterText)) 
                filterText = this.props.filterText.toUpperCase();

            if (tokenDefault) {
                fontItems = [
                    <FontSelectItem key={'DefaultFont'} id={'DefaultFont'} 
                        font={defaultFont} 
                        name={tokenDefault.name} 
                        checkFigma={this.props.GetPluginApi} 
                        valueState={infoDefault.ValueState} 
                        onClick={this.onEditFont.bind(this, 'DefaultFont')} 
                        locked={tokenDefault.locked}
                    />
                ];
    
                if (filterText) {
                    if (tokenDefault.name.toUpperCase().indexOf(filterText) < 0) {                    
                        fontItems = [];
                    } 
                }
            }            
            
            Utils.ForEach(fonts, (fid, i) => {
                if (fid !== 'DefaultFont') {
                    const token = Globals.ProjectManager.Tokens.Token(fid);
                    if (token) {
                        const info = {};
                        const font = Globals.ProjectManager.Tokens.ValueOf({model : token, info : info}) || {};
            
                        let filtered;
                        if (filterText) {
                            if (token.name.toUpperCase().indexOf(filterText) < 0) {                    
                                filtered = true;
                            }    
                            if (!filtered) {

                            }
                        }

                        if (filtered)
                            return null;

                        fontItems.push(
                            <FontSelectItem 
                                key={fid} 
                                id={fid} 
                                font={font} 
                                name={token.name} 
                                checkFigma={this.props.GetPluginApi} 
                                valueState={info.ValueState} 
                                onClick={this.onEditFont.bind(this, fid)} 
                                locked={token.locked}
                            />
                        )
                    }                    
                }            
            });
        }
        

        let loadingMessage;
        if (this.LoadingFontItems && this.LoadingFontItems.length > 0) {
            loadingMessage = (
                <SC.FRow alc style={{marginBottom : '8px'}}>
                    <SC.Loading_Icon small />
                    <div style={{marginLeft : '4px'}}>Uploading {this.LoadingFontItems.length} Fonts</div>
                </SC.FRow>
            )
        }

        return (
            <SC.FCol style={{marginTop : '4px', marginBottom : this.state.expanded ? 0 : '-16px'}}>
                <GroupTitle 
                    subGroup 
                    title='Fonts' 
                    hasAdd={this.props.hasEditGrant ? {onAdd : this.SelectFont} : null} 
                    hasDotMenu={
                        this.props.hasEditGrant && this.state.expanded ? {
                            onLockAllTokens : (locked) => {
                                fonts.map((fid) => {
                                    Globals.ProjectManager.DataManager.Set(locked, 'tokens', 'list', fid, 'locked');
                                    this.RCUpdate();
                                })
                            }
                        } : null
                    }
                    expandable={{
                        expanded : this.state.expanded,
                        onExpand : this.onToggleExpand
                    }}
                    style={{paddingLeft : '12px'}} 
                />
                <SC.FCol style={{paddingLeft : '8px', paddingRight : '8px', marginTop : '6px'}}>
                    {loadingMessage}
                    {fontItems}
                </SC.FCol>
            </SC.FCol>
        )
    }
}

export const FontSelectItem = ({onClick, selected, sortableProps, valueState, checkFigma, name, locked,  ...rest }) => {    
    let figmaErrorMark;
    if (checkFigma && AppLayout.AppSource.Figma) {
        figmaErrorMark = (
            <FigmaFontChecker 
                compact
                fontId={rest.id} 
                font={Globals.ProjectManager.Tokens.TypePatterns.GetFigmaFontFamily(rest.id)}
                GetPluginApi={checkFigma}
            />
        )
    }

    const style_box = {...SC.Styles.Flex.RowAlcJsb, paddingTop : '6px', paddingBottom : '6px'};

    if (selected) {
        style_box.borderLeft = SC.CurrentTheme.theme.border_brand;
    }

    return (
        <TokenItemBox onClick={onClick} selected={selected} style={style_box}>
            {   
                sortableProps && <TokenItemDragHandle {...sortableProps.handleProps} />                    
            }                   
            <TokenItemBoxHoveredName style={sortableProps ? {} : {transform : 'translateX(0)'}}>
                {
                    valueState && <StatefulTokenMarker {...valueState} />
                }
                <SC.FCol>
                    <SC.FRow alc>
                        {locked && <TokenLockedMarker />}
                        <div style={{marginBottom : '4px'}}>{name}</div>    
                    </SC.FRow>                    
                    <FontFamilyPreview {...rest} />
                </SC.FCol>                
            </TokenItemBoxHoveredName>                            
            {figmaErrorMark}
        </TokenItemBox>
    )
}

export const FontFamilyPreview = (props) => {
    const font = props.font || {};        
    const style = {fontSize : '14px', overflow : 'hidden', transition : 'all 0.3s ease', ...Globals.ProjectManager.Tokens.Get_FontFamilyOf(font), ...props.style};
    let preview = props.text;
    if (!Utils.IsNotNullOrEmpty(preview)) {
        preview = FontFamilyDisplayName(font);
    }
        
    return (
        <SC.TextDivAbbr style={style}>{preview}</SC.TextDivAbbr>
    )
}

export const FontFamilyDisplayName = (font) => {
    let preview = '';
    let textFamily = font.family;
    // if (font.provider === Strings.FONT_WEBSAE)
    //     textFamily = font.name;
    
    let fontStyle = '';
    let textVariant = '';

    if (Utils.IsNotNullOrEmpty(font.style)) {
        textVariant = font.style;
        textFamily = font.family;
    }
    else {
        let variant = font.variant ? font.variant.toString() : Utils.UseNullOrEmpty(font.style, 'regular');
    
        if (variant && variant.indexOf('italic') > -1) {
            variant = variant.replace('italic', '');
            fontStyle = ' italic';
        }
        
    
        textVariant = Utils.UseUndefined(variant, '');
        if (font.provider === Strings.FONT_WEBSAE) 
            textVariant = font.weight ||'normal';
    }
    
    preview = textFamily + ' ' + textVariant + fontStyle;
    return preview;
}

export const GetFontItemList = () => {
    return GetFontItemListOfIds(Globals.ProjectManager.Tokens.Fonts());
}

export const GetFontItemListOfIds = (fontIds) => {
    const fontItems = [];
    Utils.ForEach(fontIds, (fid, i) => {
        const token = Globals.ProjectManager.Tokens.Token(fid);
        if (token) {
            const font = Globals.ProjectManager.Tokens.ValueOf({model : token}) || {};

            fontItems.push({
                id : fid,
                name : token.name,
                font : font
            });
        }        
    });

    return fontItems;
}