import React from 'react';
import {
    ReactBaseComponent,
    AppState,
    SC,
    Utils,
    Events,
    AppLayout,
    Globals,
    Strings
} from '../../../../../../importer';

import styled from 'styled-components';
import {TokenGroup, TokenItemBox, TokenItemDragHandle, TokenItemBoxHoveredName, DraggableTokenItem, TokenPanelHeader, GroupTitle, EditableName, TokenItemBoxHovered} from '../common';
import { ListCardView } from '../../../../../../components/light_dark_bar';
import { motion } from 'framer-motion';
import ColorTokenEditor from './color';
import { LeftScrollPanel } from '../../common';
import Chroma from 'chroma-js';
import {Droppable, Draggable} from 'react-beautiful-dnd';
import NodeNameEditor from '../common/nameeditor';
import BaseTokenList from '../BaseTokenList';
import DragSource from '../../../../../../components/dragdrop/HoCDragSource';
import { InitializeGradientToken } from '../../../../../../components/editors/gradient';
import { GRANT_TYPES } from '../../../../manager';

export default class Colors extends BaseTokenList
{
    constructor(props) {
        super(props);

        this.title = 'COLORS';
        this.moduleName = 'Colors';
        this.tokenType = Globals.ProjectManager.Tokens.Types.COLOR;
        this.hasGroups = true;
        this.expanded = true;

        this.state.isGroupView = true;
        this.state.isListView = false;
        
        this.Ref_ColorEdit = React.createRef();
        this.onEditColor = this.onEditColor.bind(this);
        this.EditColor = this.EditColor.bind(this);        
        this.onDeleteColor = this.onDeleteColor.bind(this);
        this.onCloneColor = this.onCloneColor.bind(this);
        this.onCancelAddColor = this.onCancelAddColor.bind(this);
        this.onCancelNewColor = this.onCancelNewColor.bind(this);
        this.onCloseEditor = this.onCloseEditor.bind(this);
        this.onSubmitNewColor = this.onSubmitNewColor.bind(this);
        this.SaveEditingColor = this.SaveEditingColor.bind(this);

        AppLayout.Refs.DesignSystem.Colors = this;

        super.LoadOptions();
        if (this.expanded || this.props.singleView || this.props.forSelect) {
            this.expanded = true;
            this.Load(this.state.isGroupView);
        } 
        
    }
    componentWillUnmount() {
        super.componentWillUnmount();
        AppLayout.Refs.DesignSystem.Colors = null;
    }
    Load(groupView) {                
        this.colors = GetColorTokenList();        
        this.gradients = GetGradientTokenList();        
        if (groupView) {
            this.groups = this.GetGroupedTokenList(Utils.Concat(this.colors, this.gradients));
        }
        if (Utils.IsNotNullOrEmpty(this.FilterText))
            this.SearchToken(this.FilterText);
    }
    SearchToken(filter) {
        this.FilterText = filter;
        if (this.state.isGroupView)
            FiltercolorItems({filter : filter, groups : this.groups});
        else
            FiltercolorItems({filter : filter, colors : this.colors});
        
        this.RenderId = Utils.Id();
        this.RCThrottledUpdate_1();
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.filterText !== nextProps.filterText) {
            setTimeout(() => {
                this.SearchToken(nextProps.filterText);
            }, 1);
            return false;
        }
        if (super.shouldComponentUpdate(nextProps, nextState)) {
            return true;
        }
        return false;
    }       
    AddTokenToGroup(group) {
        if (!Globals.ProjectManager.CheckGrant_AddToken(this.tokenType)) {
            return;
        }
        
        this.IsNew = true;        
        this.EditColorId = Utils.Id();
        this.AddingToGroup = group;
        this.EditColorModel = {
            name : 'New Color',
            value : {
                Default : {
                    value : '#fff'
                }
            }
        };
        AddNewColorToken(false, this.EditColorModel, this.EditColorId);
        
        this.props.onPanelOverlay({
            show : true,
            render : () => {
                return (
                    <SC.FCol fw fh>
                        <TokenPanelHeader 
                            notBackClosable 
                            title='NEW COLOR' 
                            hasAddCancel 
                            onClose={this.onCancelNewColor} 
                            onCancel={this.onCancelNewColor} 
                            onAdd={this.onSubmitNewColor} 
                        />
                        <LeftScrollPanel>
                            <ColorTokenEditor 
                                ref={this.Ref_ColorEdit}
                                id={this.EditColorId}
                                isNew
                                groupId={this.AddingToGroup ? this.AddingToGroup.id : null}
                                // newModel={this.EditColorModel}
                                GlobalState={this.props.GlobalState}
                                RefToolbar={this.props.RefToolbar}
                                onSubmit={this.onSubmitNewColor}
                            />
                        </LeftScrollPanel>                        
                    </SC.FCol>                    
                )
            }
        })
    }    
    onEditColor(id, isGradient, e) {        
        if (this.props.onSelect) {
            this.props.onSelect(id, isGradient, e);
            return;
        }
        this.EditColor(id, isGradient);
    }
    onShowMenu(id, isGradient, e) {
        this.props.onShowMenu && this.props.onShowMenu(id, isGradient, e);
    }
    EditColor(id, isGradient) {              
        // const color = Utils.Find(this.colors, (item) => {return item.id === id}) || {};
        // this.props.onShowConsumers(id, color.ref);
        // return;

        if (!Globals.ProjectManager.CheckGrant(GRANT_TYPES.EDIT_TOKEN.ALL)) {
            return;
        }

        this.EditColorId = id;
        this.IsEditingGradient = isGradient;
        let token = Utils.Find(isGradient ? this.gradients : this.colors, (item) => {return item.id === id});
        if (!token) {
            token = Globals.ProjectManager.Tokens.Token(id);
            if (token) {
                const tokenItem = GetColorTokenItem({token : token, id : id});
                this.colors.push(tokenItem);
            }
            else {
                return;
            }
        }


        if (Globals.ProjectManager.AuditManager) {
            this.WillEditToken = Utils.DeepClone(Globals.ProjectManager.Tokens.Token(id));
        }
        
        if (!token) {
            Globals.ProjectManager.Tokens.Delete(isGradient ? Globals.ProjectManager.Tokens.Types.Gradients : Globals.ProjectManager.Tokens.Types.COLOR, id);
            this.Reload();
            return;
        }
        this.props.onPanelOverlay({
            show : true,
            render : (props) => {
                return (
                    <SC.FCol fw fh>
                        <ColorTokenEditor 
                            ref={this.Ref_ColorEdit}
                            id={id}
                            onLoad={this.EditColor}
                            groupId={token.groupId}
                            isGradient={isGradient}
                            GlobalState={props.GlobalState}
                            GlobalStateId={props.GlobalStateId}
                            onClose={this.onCloseEditor}
                            onClosed={() => {
                                const tokencurrent = Globals.ProjectManager.Tokens.Token(id);
                                
                                if (this.WillEditToken && tokencurrent) { 
                                    const diff = Utils.GetFlattenedDiff(this.WillEditToken, tokencurrent);
                                    if (diff.length > 0) {
                                        Globals.ProjectManager.AuditManager && Globals.ProjectManager.AuditManager.TokenChanged({TokenId : id, Change : {
                                            changes : diff
                                        }});
                                    }                                                                        
                                }
                                delete this.WillEditToken;
                            }}
                            RefToolbar={this.props.RefToolbar}
                            withHeader={{
                                title : isGradient ? 'EDIT GRADIENT' : 'EDIT COLOR',
                                hasAddCancel : true,
                                onClose : this.onCloseEditor,
                                onCancel : this.onCancelAddColor,
                                onAdd : this.onSubmitNewColor,
                                onClone : this.onCloneColor,
                                onDelete : this.onDeleteColor
                            }}
                        />                       
                    </SC.FCol>                    
                )
            },
            renderOld : (props) => {
                return (
                    <SC.FCol fw fh>
                        <TokenPanelHeader title={isGradient ? 'EDIT GRADIENT' : 'EDIT COLOR'}
                            hasAddCancel 
                            onClose={this.onCloseEditor}
                            onCancel={this.onCancelAddColor} 
                            onAdd={this.onSubmitNewColor} 
                            onClone={this.onCloneColor}
                            onDelete={this.onDeleteColor}                                
                        />
                        <LeftScrollPanel>
                            <ColorTokenEditor 
                                ref={this.Ref_ColorEdit}
                                id={id}
                                groupId={token.groupId}
                                isGradient={isGradient}
                                GlobalState={props.GlobalState}
                                GlobalStateId={props.GlobalStateId}
                                onClose={this.onCloseEditor}
                                RefToolbar={this.props.RefToolbar}
                            />
                        </LeftScrollPanel>                        
                    </SC.FCol>                    
                )
            }
        })       
    }    
    onCloneColor() {
        const cloneId = Globals.ProjectManager.Tokens.Clone(this.EditColorId, this.IsEditingGradient ? Globals.ProjectManager.Tokens.Types.Gradients : Globals.ProjectManager.Tokens.Types.COLOR);
        if (cloneId) {
            const sourceitem = Utils.Find(this.IsEditingGradient ? this.gradients : this.colors, (item) => {return item.id === this.EditColorId});
            const targetitem = Utils.DeepClone(sourceitem);
            targetitem.id = cloneId;
            targetitem.ref = React.createRef();
            targetitem.name = 'Copy of ' + sourceitem.name;
            if (this.IsEditingGradient)
                this.gradients.push(targetitem);
            else
                this.colors.push(targetitem);
            this.EditColor(cloneId, this.IsEditingGradient);
        }        
    }
    onDeleteColor() {
        if (this.IsEditingGradient) {
            if (!Globals.ProjectManager.Tokens.Delete(Globals.ProjectManager.Tokens.Types.Gradients, this.EditColorId))            
                return;
            Utils.Remove(this.gradients, (item) => {return item.id === this.EditColorId});
        }
        else {
            if (!Globals.ProjectManager.Tokens.Colors.Delete(this.EditColorId))
                return;
            Utils.Remove(this.colors, (item) => {return item.id === this.EditColorId});
        }
        if (this.state.isGroupView)
            this.Load(this.state.isGroupView);
        this.onCancelAddColor();
        this.RCUpdate();
    }
    onCancelNewColor() {
        if (this.IsNew && this.EditColorModel) {
            if (this.IsEditingGradient) {
                Globals.ProjectManager.Tokens.Delete(Globals.ProjectManager.Tokens.Types.Gradients, this.EditColorId);
                Utils.Remove(this.gradients, (item) => {return item.id === this.EditColorId});
            }
            else {
                Globals.ProjectManager.Tokens.Colors.Delete(this.EditColorId);
                Utils.Remove(this.colors, (item) => {return item.id === this.EditColorId});
            }
            if (this.state.isGroupView)
                this.Load(this.state.isGroupView);

            this.RCUpdate();
        }
        this.onCancelAddColor();
    }
    onCancelAddColor() {        
        delete this.IsNew;
        delete this.EditColorModel;
        delete this.EditColorId;

        this.props.onPanelOverlay({close : true});
    }
    onCloseEditor() {
        if (!this.IsNew)
            this.onSubmitNewColor();
        else
            this.onCancelAddColor();
    }
    onSubmitNewColor() {
        const token = Globals.ProjectManager.Tokens.Token(this.EditColorId);
        if (token) {            
            if (this.IsNew) {
                const tokenItem = {
                    id : this.EditColorModel,
                    name : token.name,
                    ref : React.createRef()
                };
                GetcolorTokenValue(token, tokenItem, this.props.forText, this.props.StateArray);      

                
                if (this.AddingToGroup) {
                    this.AddingToGroup.tokens.push(tokenItem);
                    Globals.ProjectManager.Tokens.AddTokenToGroup({type : this.EditColorModel.isGradient ? Globals.ProjectManager.Tokens.Types.Gradients : Globals.ProjectManager.Tokens.Types.COLOR, groupid : this.AddingToGroup.id, tokenid : this.EditColorId})
                    delete this.AddingToGroup;
                }                 
                else {
                    if (this.EditColorModel.isGradient) {                               
                        this.gradients.push(tokenItem)
                    }
                    else {
                        this.colors.push(tokenItem)                
                    }
                }
            }
            else {
                const localpattern = Utils.Find(this.IsEditingGradient ? this.gradients : this.colors, (item) => {return item.id === this.EditColorId});
                if (localpattern) {
                    localpattern.name = token.name;
                    GetcolorTokenValue(token, localpattern);
                }                                     
            }

            delete Globals.ProjectManager.CurrentTheme.ChangingTokens;
            Events.BCE(Events.GLOBAL.TOKENS_CHANGED);
        }  

        // if (this.IsNew) {
        //     let newModel = AddNewColorToken(this.EditColorModel.isGradient, this.EditColorModel, this.EditColorId);
            
        //     if (this.AddingToGroup) {
        //         this.AddingToGroup.tokens.push(newModel);
        //         Globals.ProjectManager.Tokens.AddTokenToGroup({type : this.EditColorModel.isGradient ? Globals.ProjectManager.Tokens.Types.Gradients : Globals.ProjectManager.Tokens.Types.COLOR, groupid : this.AddingToGroup.id, tokenid : this.EditColorId})
        //         delete this.AddingToGroup;
        //     }                 
        //     else {
        //         if (this.EditColorModel.isGradient) {                               
        //             this.gradients.push(newModel)
        //         }
        //         else {
        //             this.colors.push(newModel)                
        //         }
        //     }
        //     this.WillScrollTo = this.EditColorId;
        //     this.selectedId = this.EditColorId;     
        //     this.RCUpdate();  
        // }        
        // else {
        //     const token = Globals.ProjectManager.Tokens.Token(this.EditColorId);
        //     if (token) {
        //         const localpattern = Utils.Find(this.IsEditingGradient ? this.gradients : this.colors, (item) => {return item.id === this.EditColorId});
        //         if (localpattern)
        //             localpattern.name = token.name;
        //         GetcolorTokenValue(token, localpattern); 
        //         delete Globals.ProjectManager.CurrentTheme.ChangingTokens;
        //         Events.BCE(Events.GLOBAL.TOKENS_CHANGED);
        //     }            
        // }
                
        this.onCancelAddColor();
    }
    SaveEditingColor() {
        if (this.Ref_ColorEdit.current)
            this.Ref_ColorEdit.current.RCUpdate();
        else
            this.RCUpdate();
    }        
    onSortNoneGroupedList(oldIndex, newIndex, groupType) {
        if (groupType === 'Gradients') {
            Globals.ProjectManager.Tokens.ChangeOrder(Globals.ProjectManager.Tokens.Types.Gradients, oldIndex, newIndex);
            Utils.ChangePlace(this.gradients, oldIndex, newIndex);
        }
        else {
            Globals.ProjectManager.Tokens.Colors.ChangeOrder(oldIndex, newIndex);
            Utils.ChangePlace(this.colors, oldIndex, newIndex);
        }  
    }    
    renderNoneGroupedList() {
        return (
            <React.Fragment>
                {this.renderTokenList(this.colors, 'Default', 'Colors')}
                {this.renderTokenList(this.gradients, 'Gradients', 'Gradients')}    
            </React.Fragment>
        )        
    }
    renderTokenList(colors = [], id, type) {        
        if (colors.length === 0 && !this.state.isGroupView)
            return null;
        const style = {
            margin : '4px', marginLeft : this.state.isListView ? '10px' : '8px', marginRight : '10px', marginBottom : 0,
            ...SC.Styles.Flex.Column
        } 
        if (!this.state.isListView) {
            style.flexDirection = 'row';
            style.flexWrap = 'wrap';
        }

        const colorItems = colors.map((color, i) => {           
            if (!color.filtered)  {
                let DragItem = {
                    Type : Strings.TOOLBARITEM,
                    Item : {                            
                        MetaItem : {
                            Type : Strings.TOKENVALUE,
                            TokenType : AppState.ItemTypes.BOARD.COLOR,
                            Id : color.id,
                            Value : color.value,
                            name : color.name
                        }
                    } 
                };
                if (this.state.isListView) {
                    return (
                        <Draggable key={color.id}  draggableId={color.id} index={i} isDragDisabled={!this.HasEditGrant}>
                            {
                                (provided, snapshot) => {
                                    return (
                                        <ColorTokenListItem 
                                            DragItem={DragItem}
                                            color={color}     
                                            gradient={color.gradient}
                                            sortableProps={{
                                                handleProps : provided.dragHandleProps,
                                                props : provided.draggableProps,
                                                ref : provided.innerRef,
                                                isDragging : snapshot.isDragging,
                                                isDragDisabled : !this.HasEditGrant
                                            }}                                       
                                            onSelect={this.onEditColor.bind(this, color.id, color.gradient)}
                                            onMenu={this.onShowMenu.bind(this, color.id, color.gradient)}
                                        />
                                    )                                          
                                }
                            }
                        </Draggable>                                                                                    
                    )
                }    
                else {
                    return (
                        <DraggableCardItem
                            key={color.id} 
                            color={color}
                            gradient={color.gradient}
                            DragItem={DragItem}
                            onSelect={this.onEditColor.bind(this, color.id, color.gradient)}
                            onMenu={this.onShowMenu.bind(this, color.id, color.gradient)}
                        />
                    )
                }  
            }                                                                  
        });


        return (
            <Droppable 
                droppableId={id}
                type={type || 'TOKENS'}
                direction={this.state.isListView ? 'vertical' : 'horizontal'}
            >
                {
                    (provided, snapshot) => (
                        <div
                            {...provided.droppableProps} 
                            ref={provided.innerRef} 
                            style={{
                                ...provided.droppableProps.style,
                                ...style,
                                border : '1px dashed',                                                    
                                borderColor : snapshot.isDraggingOver ? SC.CurrentTheme.theme.color_brand : 'transparent',
                            }}
                        >
                            {
                                colorItems
                            }
                            {provided.placeholder}
                        </div>
                    )
                }
            </Droppable>
        )        
    }        
}

export const AddNewColorToken = (isGradient, model, id) => {
    let newItem;
    if (isGradient) {               
        Globals.ProjectManager.Tokens.Add({
            type : Globals.ProjectManager.Tokens.Types.Gradients,
            name : model.name,
            id : id,
            value : {
                type : model.value.type,
                gradient : Utils.DeepClone(model.value.gradient)
            }
        });
        newItem = {
            id : id,                    
            name : model.name,
            value : model.value.gradient && Utils.GetGradientCss(model.value.gradient.colors, model.value.type, Utils.Get(model.value.gradient, 0, 'path', 'angle'))
        };        
    }
    else {
        let value = model.value.Default.value;
        if (!Chroma.valid(value)) {
            value = 'rgba(255,255,255,1)';
        }

        Globals.ProjectManager.Tokens.Colors.Add({
            name : model.name,
            value : value,
            id : id,
        });

        newItem = {
            id : id,
            value : Chroma(value).hex(),
            name : model.name
        };
    }     
    return newItem;
}

export const GetColorTokenList = (props = {forText : false}) => {
    const tokenids = Globals.ProjectManager.Tokens.Colors.Order();
    return GetColorTokenListOfIds(tokenids, props);
}

export const GetColorTokenListOfIds = (tokenids, props) => {
    const tokens = Globals.ProjectManager.Tokens.Colors.List();
    const colors = [];    
    Utils.ForEach(tokenids, (id, i) => {
        const token = tokens[id];
        const tokenItem = GetColorTokenItem({token : token, id : id, ...props});
        if (tokenItem) {
            colors.push(tokenItem);
        }
    });
    return colors;
}

export const GetColorTokenItem = ({token, id, forText, StateArray, contextState}) => {
    let useToken = token;
    if (!useToken && id) {
        useToken = Globals.ProjectManager.Tokens.Token(id);
    }
    if (useToken) {
        if (useToken.type === 'Gradients') {
            return GetGradientTokenItem({token : token, id : id, StateArray : StateArray, contextState : contextState});
        }
        const tokenItem = {
            id : id,
            name : useToken.name,
            ref : React.createRef()
        };
        GetcolorTokenValue(useToken, tokenItem, forText, StateArray, contextState);            
        return tokenItem;
    }
}

export const GetcolorTokenValue = (token, tokenItem, forText, StateArray, contextState) => {
    let tokenvalue;
    if (tokenItem) {
        const valueInfo = {
            contextState : contextState || Globals.ProjectManager.CurrentState
        };
        if (Utils.IsNotNull(StateArray))
            tokenvalue = Globals.ProjectManager.Tokens.GetStateValue({Id : tokenItem.id, StateArray : StateArray, info : valueInfo});
        else
            tokenvalue = Globals.ProjectManager.Tokens.ValueOf({model : token, info : valueInfo}); 
        let hexValue ='#fff'
        if (Chroma.valid(tokenvalue)) {
            hexValue = Chroma(tokenvalue).hex();
        }
        tokenItem.value = hexValue;
        tokenItem.ValueState = valueInfo.ValueState;
        tokenItem.locked = token.locked;
    
        if (forText) {
            if (Chroma.valid(forText.onBackgroundColor)) {
                tokenItem.contrast = Chroma.contrast(forText.onBackgroundColor || '#000', hexValue).toFixed(2);
                tokenItem.contrastLevel = Utils.GetContrastLevel(tokenItem.contrast);
            }        
            else {
                
            }
        }
    }                
}

export const GetGradientTokenList = (StateArray) => {
    const gradient_tokenids = Globals.ProjectManager.Tokens.Gradients();
    return GetGradientTokenListOfIds(gradient_tokenids, StateArray);
}

export const GetGradientTokenListOfIds = (gradient_tokenids, StateArray) => {
    const gradients = [];
    
    const gradient_tokens = Globals.ProjectManager.Tokens.TokenList(Globals.ProjectManager.Tokens.Types.Gradients);
    Utils.ForEach(gradient_tokenids, (id, i) => {
        const token = gradient_tokens[id];
        const tokenItem = GetGradientTokenItem({token : token, id : id, StateArray : StateArray});
        if (tokenItem) {
            gradients.push(tokenItem);
        }            
    });
    return gradients;
}

export const GetGradientTokenItem = ({token, id, StateArray}) => {
    if (token) {
        let tokenvalue;
        const valueInfo = {};
        if (StateArray)
            tokenvalue = Globals.ProjectManager.Tokens.GetStateValue({Id : id, StateArray : StateArray, info : valueInfo});
        else
            tokenvalue = Globals.ProjectManager.Tokens.ValueOf({model : token, info : valueInfo});        
        if (!tokenvalue) {
            tokenvalue = {};
            InitializeGradientToken(tokenvalue);
        }
        if (tokenvalue) {
            const tokenItem = {
                id : id,
                name : token.name,
                gradient : true,
                ValueState : valueInfo.ValueState,
                value : tokenvalue.gradient && Utils.GetGradientCss(tokenvalue.gradient.colors, tokenvalue.type, Utils.Get(tokenvalue.gradient, 0, 'path', 'angle'),
                    (tokenId) => {
                        return Globals.ProjectManager.Tokens.ValueOfId(tokenId, StateArray);
                    }
                 )
            };
            return tokenItem;
        }           
    }
}

export const ColorTokenListItem = ({onSelect, onMenu, DragItem, gradient, sortableProps, color, noLock, aliase, aliasedName, forText, selected, ...rest}) => {
    let name = color.name;
    let valueState = color.ValueState;
    let isLocked = color.locked;

    if (aliase) {             
        valueState = aliase.ValueState;
        isLocked = aliase.locked;
        name = aliase.name || name;
    }
        
    if (aliasedName) {
        name = (
            <SC.FRow alc>
                <div>{name}</div>
                <SC.Icons.Icon_Button hasFill>
                    <SC.Icons.Arrow_Back expanded />
                </SC.Icons.Icon_Button>
                <SC.TextDivAbbr>{aliasedName}</SC.TextDivAbbr>
            </SC.FRow>
        );
    }   

    const itemProps = {
        title : !gradient ? color.value : name
    };
    let ItemType = DraggableTokenItem;
    if (!DragItem) {
        ItemType = TokenItemBox;        
    } 
    else {
        itemProps.DragItem = DragItem;
    }

    const previewStyle = {};
    if (gradient)
        previewStyle.background = color.value;
    else
        previewStyle.backgroundColor = color.value;

    let content;
    

    if (forText) {
        const style_box = {
            padding : '4px', 
            marginBottom : '2px',
            ...SC.Styles.Flex.RowAlcJsb
        };
        if (selected) {
            style_box.borderLeft = '1px solid';
            style_box.borderLeftColor = SC.CurrentTheme.theme.color_brand;
        }
        content = (
            <ItemType 
                ref={color.ref} 
                style={style_box} 
                onClick={onSelect} 
                onContextMenu={onMenu}
                {...itemProps}
                {...rest}
                selected={selected} 
            >
               <SC.FCol f1 style={{justifyContent : 'space-evenly', overflow : 'hidden'}}>                    
                    <SC.FRow f1 alc style={{padding : '4px'}}>
                        {valueState && <StatefulTokenMarker {...valueState} />}
                        <SC.TextDivAbbr style={{flex : 1}}>
                            {name}
                        </SC.TextDivAbbr>    
                    </SC.FRow>                    
                    <SC.TextDivAbbr style={{marginLeft : '4px', fontSize : '11px', fontWeight : 500, paddingBottom : '4px', ...SC.Styles.FontStyles.Monospace}}>
                        {color.value}
                    </SC.TextDivAbbr>
                </SC.FCol>
                <SC.GridBackground 
                    title={name}
                    small 
                    dark={!SC.CurrentTheme.theme.isLight} 
                    style={{hoverflow : 'hidden', cursor : 'pointer', margin : '-4px', alignSelf : 'stretch'}}
                    {...rest}
                >
                    <SC.FRow
                        onClick={onSelect} 
                        onContextMenu={onMenu}
                        alc
                        jsb
                        style={{backgroundColor : forText.onBackgroundColor, color : color.value, width : '100px', height : '100%', fontWeight : 500, paddingLeft : '8px', paddingRight : '8px', boxSizing : 'border-box'}} 
                    >
                        <div style={{fontSize : '11px'}}>{color.contrastLevel}</div>
                        <div style={{fontSize : '14px'}}>{color.contrast}</div>
                    </SC.FRow>
                </SC.GridBackground>                     
            </ItemType>            
        )
    }
    else {
        const style_box = {
            padding : 0, paddingLeft : '6px', marginBottom : '2px', borderRadius : '4px',
            ...SC.Styles.Flex.RowAlcJsb
        };
        if (selected) {
            style_box.borderLeft = '1px solid';
            style_box.borderLeftColor = SC.CurrentTheme.theme.color_brand;
        }
    
        content = (        
            <ItemType 
                ref={color.ref}                 
                onClick={onSelect} 
                onContextMenu={onMenu}
                {...itemProps}
                {...rest}
                style={style_box} 
                selected={selected} 
            >
                <SC.FRow f3 alc overflowHidden>      
                    <StatefulTokenListItemName 
                        name={name}
                        sortableProps={sortableProps}
                        ValueState={valueState}     
                        locked={isLocked && !noLock}
                    />
                </SC.FRow>     
                <SC.FRow alc justifyEnd style={{overflow : 'hidden'}}>
                    <SC.GridBackground small dark={!SC.CurrentTheme.theme.isLight} style={{height : '100%', marginTop : '2px', marginBottom : '2px', width : gradient ? '94px' : '30px', minWidth : '30px', borderRadius : '2px', overflow : 'hidden', border : SC.CurrentTheme.theme.border_ondark}}>
                        <div style={{height : '24px', borderRadius : '2px', ...previewStyle, width : '100%'}} />
                    </SC.GridBackground>                 
                    {
                        !gradient && 
                        <SC.TextDivAbbr style={{marginLeft : '4px', fontSize : '11px', fontWeight : 500, width : '60px', borderRadius : '2px', ...SC.Styles.FontStyles.Monospace}}>
                            {color.value}
                        </SC.TextDivAbbr>
                    }    
                </SC.FRow>                
                {/* <TokenItemBoxHovered style={{right : 0, left : 'unset', width : '24px', backgroundColor : SC.CurrentTheme.theme.back}}>
                    <SC.Icons.Icon_Button hasFill>
                        <SC.Icons.Icon_DotMenu size={18} />
                    </SC.Icons.Icon_Button>
                </TokenItemBoxHovered> */}
            </ItemType>
        )
    }

    if (sortableProps) {
        return (
            <div 
                {...sortableProps.props}
                ref={sortableProps.ref}
            >
                {content}
            </div>
        )
    }

    return content;
}

export const ColorTokenCardItem = ({onSelect, onMenu, gradient, sortableProps, color, aliase, forText, selected, ...rest}) => {
    const previewStyle = {};
    if (gradient)
        previewStyle.background = color.value;
    else
        previewStyle.backgroundColor = color.value;
    
    let name = color.name;
    let valueState = color.ValueState;
    let isLocked = color.locked;
    
    if (aliase) {
        name = aliase.name;
        valueState = aliase.ValueState;
        isLocked = aliase.locked;
    }

    if (forText) {
        return (
            <SC.GridBackground 
                title={`${name} ${color.value}`} 
                small 
                dark={!SC.CurrentTheme.theme.isLight} 
                style={{
                    overflow : 'hidden', cursor : 'pointer', position : 'relative'
                }}
                {...rest}
            >
                <SC.FCol alc justifyCenter 
                    onClick={onSelect} 
                    onContextMenu={onMenu}
                    style={{
                        backgroundColor : forText.onBackgroundColor, color : color.value, minWidth : '48px', height : '48px', fontWeight : 500,
                        border : selected ? SC.CurrentTheme.theme.border_brand : 'none',
                        boxSizing : 'border-box'
                    }}                     
                >
                    <div style={{fontSize : '11px'}}>{color.contrastLevel}</div>
                    <div style={{fontSize : '14px'}}>{color.contrast}</div>
                </SC.FCol>
                {
                    valueState &&
                    <StatefulTokenMarker
                        style={{
                            position : 'absolute',
                            top : '4px',
                            left : '4px',
                            border : '1px solid #fff'
                        }}
                        {...valueState}
                    />
                }
                {
                    isLocked && 
                    <TokenLockedMarker overlay style={{
                        fill : color.value,
                        stroke : color.value,
                        filter : 'invert(1)'
                    }}/>
                }
            </SC.GridBackground>
        )
    }

    const content = (
        <SC.GridBackground 
            title={`${name} ${color.value}`} 
            small 
            dark={!SC.CurrentTheme.theme.isLight} 
            style={{
                // height : '100%', 
                margin : sortableProps ? 0 : (selected ? '2px' : '4px'), 
                padding : selected ? '2px' : 0, 
                boxSizing : 'border-box', border: selected ? SC.CurrentTheme.theme.border_brand : 'none', 
                borderRadius : '4px', 
                overflow : 'hidden', 
                cursor : 'pointer',
                position : 'relative'
            }}
            {...rest}
        >
            <div 
                onClick={onSelect} 
                onContextMenu={onMenu}
                style={{...previewStyle, borderRadius : '4px',  minWidth : '32px', minHeight : '32px', height : '100%', boxSizing : 'border-box', border : SC.CurrentTheme.theme.border_seperator}} 
            />
            {
                color.ValueState &&
                <StatefulTokenMarker
                    style={{
                        position : 'absolute',
                        top : '4px',
                        left : '4px',
                        border : '1px solid #fff'
                    }}
                    {...color.ValueState}
                />
            }
            {
                isLocked && 
                <TokenLockedMarker overlay style={{
                    fill : color.value,
                    stroke : color.value,
                    filter : 'invert(1)'
                }}/>
            }
        </SC.GridBackground>
    )

    if (sortableProps) {
        const draggablestyle = Utils.JustGet(sortableProps.props, {}, 'style');
        return (
            <div 
                {...sortableProps.props}
                {...sortableProps.handleProps}
                ref={sortableProps.ref}
                style={{
                    ...draggablestyle,
                    margin : selected ? '2px' : '4px'
                }}
            >
                {content}
            </div>
        )
    }

    return content;
}

const DraggableCardItem = DragSource(ColorTokenCardItem);

export const FiltercolorItems = ({filter, groups, colors}) => {
    let filterText;
    if (Utils.IsNotNullOrEmpty(filter))
        filterText = filter.toUpperCase();
        
    if (groups) {
        Utils.ForEach(groups, (group, g) => {
            delete group.filtered;

            group.filtered = filterText ? true : false;
            Utils.ForEach(group.tokens, (token, t) => {
                delete token.filtered;
                if (filterText) {
                    if (token.name && token.name.toUpperCase().indexOf(filterText) < 0) {                    
                        token.filtered = true;
                    }
                    if (token.filtered) {
                        if (token.value.toUpperCase().indexOf(filterText) >= 0)
                            delete token.filtered;
                    }
                    if (!token.filtered)
                        group.filtered = false;
                }
            });
        });
    }
    else {
        Utils.ForEach(colors, (token, i) => {
            delete token.filtered;
            if (filterText) {
                if (token.name && token.name.toUpperCase().indexOf(filterText) < 0) {                    
                    token.filtered = true;
                }
                if (token.filtered) {
                    if (token.value.toUpperCase().indexOf(filterText) >= 0)
                        delete token.filtered;
                }
            }
        });
    }
}

export const StatefulTokenListItemName = ({sortableProps, ValueState, name, locked}) => {
    return (
        <React.Fragment>
            {
                sortableProps && !sortableProps.isDragDisabled &&
                <TokenItemDragHandle {...sortableProps.handleProps} />
            }      
            <TokenItemBoxHoveredName style={sortableProps && !sortableProps.isDragDisabled ? {} : {transform : 'translateX(0)'}}>
                {
                    ValueState && <StatefulTokenMarker {...ValueState} />
                }
                {locked && <TokenLockedMarker />}
                <SC.TextDivAbbr style={{flex : 1}}>
                    {name}
                </SC.TextDivAbbr>
            </TokenItemBoxHoveredName>
        </React.Fragment>        
    )
}

export const StatefulTokenMarker = (props) => {
    let backgroundColor = SC.CurrentTheme.theme.border_onlight;
    if ((props.contextState || Globals.ProjectManager.CurrentState) !== 'Default') {
        if (props.inherited)
            backgroundColor = '#e86925';
        else
            backgroundColor = SC.CurrentTheme.theme.color_brand;
    }
    return (
        <div 
            style={{
                width : '6px', height : '6px', borderRadius : '4px', marginRight : '6px',
                backgroundColor : backgroundColor,
                boxSizing : 'border-box',
                ...props.style
            }}
        /> 
    )
}

export const TokenLockedMarker = ({overlay, style}) => {
    const content = (
        <SC.Icons.Icon_Button hasFill hasStroke style={{width : 'auto', marginRight : overlay ? 0 : '6px', minWidth : 'auto', minHeight : 'auto', cursor : 'inherit'}}>
            <SC.Icons.Icon_Lock size={overlay ? 10 : 10} style={style}/>
        </SC.Icons.Icon_Button>
    )
    if (overlay) {
        return (
            <SC.AbsoluteOverlay style={{...SC.Styles.Flex.Cell, cursor : 'inherit', pointerEvents : 'none'}}>
                {content}
            </SC.AbsoluteOverlay>
        )        
    }
    return content;
}