import {
    AppState,
    Events,
    Strings,
    MetaData,
    Utils,
    AppLayout,
    Globals,
    UIUtils
} from '../../../../importer';

import ComponentDesignManager from ".";

export default  class ComponentRenderManager {
    constructor(props = {GlobalState : 'Default', ComponentState : 'Default'}) {
        this.GlobalState = props.GlobalState;
        this.ComponentState = props.ComponentState;

        this.RendererId = Utils.Id();        

        this.Models = {
            GetModel : () => {

            }
        }        

        this.StateArray = [
            {Global : 'Default', Component : 'Default'}
        ];
    }
    Destruct() {
        if (ComponentDesignManager.Active().DesignManagers[this.Id])
            ComponentDesignManager.Active().DesignManagers[this.Id].UnRegisterRenderer(this.RendererId);
    }
    static Managers = {};

    Load(Id) {
        this.Id = Id;
        this.GetManager(Id).RegisterRenderer(this, this.RendererId);
        this.States = this.GetManager(this.Id).States;

        this.LoadMockupValues();

        return true;
    }
    LoadMockupValues() {
        if (this.isSubComponent) {
            const ModelIds = this.GetManager(this.Id).Models.Order();
            Utils.ForEach(ModelIds, (ModelId, ) => {
                const model = this.GetManager(this.Id).Models.GetModel(ModelId);
                if (model && model.DataId) {                    
                    const randomValue = this.isSubComponent.GetManager().GetDesignManager().GetSubcomponentMockupValue({
                        Id : this.isSubComponent.ItemId, 
                        ModelId : ModelId,
                        MockupFieldId : model.DataId
                    });
                    // Globals.ProjectManager.Mockups.GetRandomValue(model.DataId);
                    Utils.Set(this, randomValue, 'ModelValues', ModelId, 'value');
                }
            });
        }
    }
    LoadPrototype(Id, ViewId) {
        this.Id = ViewId;
        this.PrototypeId = Id;
        this.GetManager(ViewId).RegisterRenderer(this, this.RendererId);
        this.States = this.GetManager(this.Id).States;
        return true;
    }
    GetSubcomponentManager() {
        if (this.Editing) 
            return this.GetManager(this.Id);
        return this;
    }
    GetDesignManager() {
        return this.GetManager(this.Id);
    }
    GetManager(Id) {    
        return ComponentDesignManager.Active().GetDesignManager(Id, this.PrototypeId);
    }
    UpdateStyles() {
        this.onUpdate && this.onUpdate();
    }
    SetEditing(editing) {
        this.Editing = editing;        
        if (editing) {
            const DesignManager = this.GetManager(this.Id);
            DesignManager.ActiveRenderer = this;

            this.GetDesignManager().SwitchState({
                GlobalState : this.GlobalState, 
                ComponentState : this.ComponentState, 
                StateArray : this.StateArray, 
                GlobalStateArray : this.GlobalStateArray, 
                ReversedStyleState : this.ReversedStyleState, 
                ReversedGlobalStateArray : this.ReversedGlobalStateArray
            });
            
            ComponentDesignManager.Active().SetActiveComponentManager(DesignManager);    
            DesignManager.onActive = (active) => {
                if (!active) {
                    this.Editing = false;
                    this.onActiveChanged && this.onActiveChanged();
                }                
            }
            Events.BCE(Events.DESIGNER.COMPONENT.STATE.CHANGED_VARIATION);
        }                
    }    
    SetStateVariations(ComponentVariations) {

    }
    SetSelectedVariation(StateId, VariationId, internal) {
        if (VariationId)
            Utils.Set(this, VariationId, 'CurrentVariations', StateId);
        else
            Utils.UnSet(this, 'CurrentVariations', StateId);

        this.SetState({CurrentGlobalState : this.GlobalState, ComponentVariations : this.CurrentVariations});
        if (this.onStateChanged) {
            this.onStateChanged();
        }
    }
    IsStateActive(StateId) {
        return this.CurrentVariations[StateId];
    }
    GetSelectedVariation() {
        return this.CurrentVariations;
    }
    SetState({CurrentGlobalState, ComponentVariations, PseudoStateVariations}) {        
        this.CurrentVariations = Utils.Merge(ComponentVariations || {}, PseudoStateVariations);

        const PseudoStates = this.GetDesignManager().States.PseudoStates();
        const PseudoStateOrder = Utils.Get(PseudoStates, [], Strings.ORDER);

        this.ComponentState = Utils.States.BuildStateLabelFromVariations({
            States : this.GetDesignManager().States.States(),
            StateOrders : this.GetDesignManager().States.Order(), 
            Variations : this.CurrentVariations, 
            PseudoStates : {
                Order : PseudoStateOrder,
                States : PseudoStates
            },
            PseudoStateVariations : PseudoStateVariations
        })

        // this.GetDesignManager().States.GetCustomState(this.CurrentVariations);

        this.GlobalState = Utils.UseNullOrEmpty(CurrentGlobalState, 'Default');
        this.StateArray = [];
        this.GlobalStateArray = [];

        const Manager = this.GetManager(this.Id);
        this.ComponentStateArray = Utils.States.BuildStateArrayFromVariations({
            States : Manager.States.Get(), 
            StateOrders : Manager.States.Order(), 
            Variations : this.CurrentVariations,
            PseudoStates : PseudoStates
        });        
        const StateCombinations_Component = Utils.Combinations(this.ComponentStateArray).filter(x => x.length > 0);
        
        // this.ComponentStateArray.splice(0, 0, 'Default');

        const Result = Utils.States.GenerateStateArray({GlobalStateLabel : CurrentGlobalState, StateCombinations_Component : StateCombinations_Component});
        this.StateArray = Result.StateArray;
        this.GlobalStateArray = Result.GlobalStateArray;

        this.ReversedGlobalStateArray = Utils.Reverse(Utils.DeepClone(this.GlobalStateArray));
        this.ReversedStyleState = Utils.Reverse(Utils.DeepClone(this.StateArray));

        if (this.Editing) {
            this.GetDesignManager().SwitchState({
                Variations : this.CurrentVariations,
                GlobalState : this.GlobalState, 
                ComponentState : this.ComponentState, 
                StateArray : this.StateArray, 
                GlobalStateArray : this.GlobalStateArray, 
                ReversedStyleState : this.ReversedStyleState, 
                ReversedGlobalStateArray : this.ReversedGlobalStateArray
            });
        }
    }
    SetGlobalState(GlobalState) {

    }
    IsDefaultState() {
        return this.ComponentState === Strings.DEFAULT && this.GlobalState === Strings.DEFAULT;
    }
    GetMetaItem(Id) {
        if (this.PrototypeId)
            return this.GetDesignManager().GetMetaItem(Id);
        return Globals.ProjectManager.GetComponentMetaItem(this.Id, Id);
    }
    GetRootId() {
        if (this.PrototypeId)
            return Globals.ProjectManager.GetPrototypeViewData(this.PrototypeId, this.Id).RootId;
        return Globals.ProjectManager.GetComponentRootId(this.Id);
    }
    GetRootMetaItem() {
        return this.GetMetaItem(this.GetRootId());
    }
    GetChildItems(Id) {
        const MetaItem = this.GetMetaItem(Id);
        return MetaItem.SubItems;
    }
    GetItemStyle(Id) {
        const Manager = this.GetManager(this.Id);
        const MetaItem = this.GetMetaItem(Id);
        
        const itemdata = Utils.DeepClone(Manager.GetItemStateStyle({
            MetaItem : MetaItem, 
            Id : Id, 
            StateArray : this.StateArray, 
            ReverseStyleState : this.ReversedStyleState,
            ReversedGlobalStateArray : this.ReversedGlobalStateArray, 
            GlobalState : this.GlobalState, 
            ComponentState : this.ComponentState
        }));       
        
        if (itemdata && itemdata.models) {
            Utils.ForEach(itemdata.models, (modelinfo, propName) => {
                const modelValue = this.GetModelValue({ModelId : modelinfo.modelId});
                if (modelValue && Utils.IsNotNull(modelValue.value)) {
                    if (Utils.IsOneOf(propName, 'hidden', 'textContent'))
                        itemdata.props[propName] = modelValue.value;        
                    else if (Utils.IsOneOf(propName, 'backgroundColor', 'color'))
                        itemdata.style[propName] = modelValue.value;                        
                    else if (propName === 'backgroundImage') {
                        itemdata.style.backgroundImage = Utils.url(modelValue.value.url);
                        if (modelValue.options) {
                            let positionX = Utils.JustGet(modelValue.options.positionX, null, 'value');
                            let positionY = Utils.JustGet(modelValue.options.positionY, null, 'value');
                            if (positionX) {
                                if (positionX === 'custom') {
                                    positionX = Utils.JustGet(modelValue.options.customPositionX, 'center', 'value');
                                    if (positionX !== 'center')
                                        positionX += '%';                      
                                }
                            } 
                            if (positionY) {
                                if (positionY === 'custom') {
                                    const positionModelId = Utils.JustGet(modelValue.options.customPositionY, null, 'ModelId');
                                    if (positionModelId) {
                                        positionY = this.GetModelValue({ModelId : positionModelId});
                                    }
                                    else {
                                        positionY = Utils.JustGet(modelValue.options.customPositionY, 'center', 'value');
                                    }
                                    
                                    if (positionY !== 'center')
                                    positionY += '%';                      
                                }
                            } 
                            itemdata.style.backgroundPosition = `${positionX || 'center'} ${positionY || 'center'}`;
                            const backgroundSize = Utils.JustGet(modelValue.options.backgroundSize , null, 'value');
                            if (backgroundSize) {
                                if (backgroundSize === 'custom') {
                                    let backgroundWidth = Utils.JustGet(modelValue.options.backgroundWidth, null, 'value');
                                    if (backgroundWidth && backgroundWidth !== 'auto')
                                        backgroundWidth += '%';                    
                                    let backgroundHeight = Utils.JustGet(modelValue.options.backgroundHeight, null, 'value');
                                    if (backgroundHeight && backgroundHeight !== 'auto')
                                        backgroundHeight += '%';
                                    
                                    itemdata.style.backgroundSize = (backgroundWidth || 'auto') + ' ' + (backgroundHeight || 'auto');
                                }
                                else
                                    itemdata.style.backgroundSize = backgroundSize || 'auto';
                            }                            
                        }
                    }
                    else if (propName === 'icon') {
                        itemdata.props.icon = modelValue.value;
                    }
                    else {
                        itemdata.props[propName] = modelValue.value;
                    }
                } 
            });
        }
        return itemdata;
    }    
    GetProperty(Id, Name, DefaultValue) {
        return this.GetManager(this.Id).GetProperty(Id, Name, DefaultValue, this.IsSubComponent ? (modelId) => {
            if (this.ModelValues && this.ModelValues[modelId]) {
                return this.ModelValues[modelId].value;
            }
        } : null);
    }
    GetContainerType(Id) {
        const display = this.GetManager(this.Id).GetItemPropertyOfState({
            MetaItem : this.GetMetaItem(Id),
            PropertyName : 'display',
            DefaultValue : 'flex',
            ReversedStyleState : this.ReversedStyleState
        });
        return Utils.JustGet(display, 'flex', 'Value');
    }
    GetItemRenderProperty(Id, PropertyName) {
        return this.GetManager(this.Id).GetItemRenderProperty(Id, PropertyName);
    }
    // Temp, for build errors    
    IsSelected(Id) {
        return this.GetManager(this.Id).IsSelected(Id);
    }
    GetItemRenderValue(props) {
        return this.GetManager(this.Id).GetItemRenderValue(props);
    }
    Register_ItemDesigner(Id, designer) {
        return this.GetManager(this.Id).Register_ItemDesigner(Id, designer);
    }
    UnRegister_ItemDesigner(Id, designer) {
        return this.GetManager(this.Id).UnRegister_ItemDesigner(Id, designer);
    }
    GetItemType(ItemType, Draggable, GenericType) {
        return this.GetManager(this.Id).GetItemType(ItemType, Draggable, GenericType);
    }
    GetItemTextValue(props) {
        return this.GetManager(this.Id).GetItemTextValue(props);
    }
    GetItemPropertyValue(props) {
        return this.GetManager(this.Id).GetItemPropertyValue(props);
    }
    GetItemGridModel(props) {
        return this.GetManager(this.Id).GetItemGridModel(props);
    }
    GetModelValue(props) {
        let value, optionValues;
        if (this.ModelValues && Utils.IsNotNull(this.ModelValues[props.ModelId])) {
            value = this.ModelValues[props.ModelId].value;
            optionValues = this.ModelValues[props.ModelId].options;
        }
        else
            value = this.GetManager(this.Id).GetModelValue(props);
        return {
            value : value,
            options : optionValues
        }
    } 
    GetComputedStyleOf(...props) {
        return this.GetManager(this.Id).GetComputedStyleOf(...props);
    } 
    SelectItem(...props)  {
        return this.GetManager(this.Id).SelectItem(...props);
    }
    GetSelectedItems() {
        return this.GetManager(this.Id).GetSelectedItems();
    }
    SetItemSize(props) {
        return this.GetManager(this.Id).SetItemSize(props);
    }
}