import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    Strings,
    Globals,
    MetaData
} from '../../../../../importer';

import styled, {css} from 'styled-components';
import ComponentDesignManager from '../../../component/manager';
import { Seperator } from '../iteminspector/styleitems/common';
import { GenericTable_SubTypeIds } from '../../../../../../toolabs-modules/toolabs-metaui/components/generic/initialize/table';
import { GenericCarousel_SubTypeIds } from '../../../../../../toolabs-modules/toolabs-metaui/components/generic/initialize/carousel';
import { GenericTabs_SubTypeIds } from '../../../../../../toolabs-modules/toolabs-metaui/components/generic/initialize/tabs';
import { GenericAccordion_SubTypeIds } from '../../../../../../toolabs-modules/toolabs-metaui/components/generic/initialize/accordion';

export default class ComponentLayers extends ReactBaseComponent
{
    constructor(props) {
        super(props);
    }
    renderCustom() {
        if (!this.props.Generic)
            return null;
        return (
            <GenericNodes />
        )
    }
}

class Node extends ReactBaseComponent
{
    constructor(props) {
        super(props);
    }
    renderCustom() {
        return (
            <NodeBox selected={this.props.selected} onClick={this.props.onSelect}>
                <SC.FRow f1 alc jsb>
                    {this.props.name}
                    {
                        this.props.isRoot ?
                        <SC.Icons.Icon_Button hasFill style={{cursor : 'pointer' }} onClick={this.props.onDelete} >
                            <SC.Icons.Icon_Delete size={15}/>
                        </SC.Icons.Icon_Button> : null
                    }                    
                </SC.FRow>            
            </NodeBox>
        )
    }
}

class GenericNodes extends ReactBaseComponent
{
    constructor(props) {
        super(props);
    }
    SelectItem(id) {
        ComponentDesignManager.Active().ComponentManager.SelectItem(id, true);
        this.RCUpdate();
    }
    DeleteItem(id) {
        ComponentDesignManager.Active().ComponentManager.DeleteItem(id);
    }
    renderCustom() {
        const MetaComponent = ComponentDesignManager.Active().ComponentManager.Data;
        const RootId = ComponentDesignManager.Active().ComponentManager.GetRootId();
        if (!RootId) {
            return null;
        }
        let nodes = [];

        if (MetaComponent.GenericType === MetaData.Components.RadioGroup.Type) {
            nodes.push({id : RootId, name : 'Radio Button', isRoot : true});
            nodes.push({id : MetaComponent.TextItemId, name : 'Label'});            
        }
        else if (MetaComponent.GenericType === MetaData.Components.Toggle.Type) {
            nodes.push({id : RootId, name : 'Switch Box', isRoot : true});
            nodes.push({id : MetaComponent.ThumbId, name : 'Thumb'});            
        }
        else if (MetaComponent.GenericType === MetaData.Components.RangeInput.Type) {
            nodes.push({id : RootId, name : 'Track Bar', isRoot : true});
            nodes.push({id : MetaComponent.ThumbId, name : 'Thumb'});            
        }
        else if (MetaComponent.GenericType === MetaData.Components.ProgressBar.Type) {
            nodes.push({id : RootId, name : 'Progress Box', isRoot : true});
            nodes.push({id : MetaComponent.ProgressId, name : 'Progress Bar'});
            nodes.push({id : MetaComponent.ProgressValueId, name : 'Value Section'});
        }
        else if (MetaComponent.GenericType === MetaData.Components.Dropdown.Type) {
            nodes.push({id : RootId, name : 'Button', isRoot : true});
            nodes.push({id : MetaComponent.ContentId, name : 'Content Box'});            
        }
        else if (MetaComponent.GenericType === MetaData.Components.Table.Type) {
            nodes.push({id : RootId, name : 'Table', isRoot : true});
            GenericTable_SubTypeIds.map((SubType) => {
                nodes.push({id : MetaComponent[SubType.Id], name : SubType.Label});            
            })                        
        }
        else if (MetaComponent.GenericType === MetaData.Components.Carousel.Type) {
            nodes.push({id : RootId, name : 'Carousel', isRoot : true});
            GenericCarousel_SubTypeIds.map((SubType) => {
                nodes.push({id : MetaComponent[SubType.Id], name : SubType.Label});            
            })                        
        }
        else if (MetaComponent.GenericType === MetaData.Components.Tab.Type) {
            nodes.push({id : RootId, name : 'Tabs', isRoot : true});
            GenericTabs_SubTypeIds.map((SubType) => {
                nodes.push({id : MetaComponent[SubType.Id], name : SubType.Label});            
            })                        
        }
        else if (MetaComponent.GenericType === MetaData.Components.Accordion.Type) {
            nodes.push({id : RootId, name : 'Accordion', isRoot : true});
            GenericAccordion_SubTypeIds.map((SubType) => {
                nodes.push({id : MetaComponent[SubType.Id], name : SubType.Label});            
            })                        
        }
        else {
            return null;
            nodes.push({isRoot : true, id : RootId, name : MetaData.Components[MetaComponent.GenericType].Name});
        }
        return (
            <SC.FCol style={{padding : '4px', paddingBottom : 0}}>
                {
                    nodes.map(({id, name, isRoot}) => {
                        return (
                            <Node
                                key={id} 
                                Id={id} 
                                name={name} 
                                isRoot={isRoot}
                                onSelect={this.SelectItem.bind(this, id)}
                                onDelete={this.DeleteItem.bind(this, id)}
                                selected={ComponentDesignManager.Active().ComponentManager.IsSelected(id)}

                            />
                        )
                    })
                }
                <Seperator style={{marginTop : '4px'}} />
            </SC.FCol>
        )
    }
}

const NodeBox = styled.div`
    box-sizing : border-box;
    padding : 4px;
    padding-left : 8px;
    padding-right : 8px;
    border-radius : 2px;
    display : flex;
    align-items : stretch;
    font-size : 11px;
    background-color : ${props => props.selected ? props.theme.layernode_selected : 'unset'};
    color : ${props => props.selected ? props.theme.font_hover : props.theme.font};
    cursor : pointer;
    &:hover {
        color : ${props => props.theme.font_hover};
        background-color : ${props => props.selected ? props.theme.layernode_selected : props.theme.layernode_hover};
    }
`