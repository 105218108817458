import React from 'react';
import {
    ReactBaseComponent,
    SC,
    AppState,
    Utils,
    AppLayout,
    Links,
    Events,
    Loading,
    Strings,
    Globals,
    MetaData
} from '../../../../../../importer';

import styled from 'styled-components';
import { TokenItemBox } from '../common';
import PlaySound from './playsound';

export default class FacebookSoundKit extends ReactBaseComponent
{
    constructor(props) {
        super(props);
    }

    Select(category, soundid, fileName) {
        this.props.onSelect(category, soundid, fileName);
    }

    renderCustom() {
        const systemsounds = MetaData.Sounds;

        let sounditems = [];

        Utils.ForEach(systemsounds,(category, i) => {            
            sounditems.push(
                <div key={category.name} style={{marginBottom : '8px'}}>
                    <div>{category.name}</div>                    
                    <SC.FCol
                        style={{
                            marginTop : '8px',                            
                        }}
                    >
                        {
                            category.sounds.map((sound, j) => {
                                return (
                                    <SC.FRow style={{
                                        alignItems : 'stretch',
                                        marginBottom : '4px',
                                        minHeight : '40px'
                                    }}>
                                        <TokenItemBox key={sound.id} onClick={this.Select.bind(this, category.name, sound.id, sound.name)} style={{flex : 1, marginBottom : 0, marginRight : '4px'}}>
                                            {sound.name}
                                        </TokenItemBox>    
                                        <PlaySound                                             
                                            xsmall 
                                            type={category.name} 
                                            fileName={sound.name}                                            
                                        />
                                    </SC.FRow>
                                    
                                )
                            })
                        }
                    </SC.FCol>                    
                </div>
            )          
        });

        return (
            <SC.FCol fw fw style={{paddingBottom : '24px'}}>
                {sounditems}
            </SC.FCol>
        )
    }
}