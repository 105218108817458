import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    AppLayout,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../../../importer';
import styled, { css} from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import Slider from '../../../../../components/editors/slider';
import TextInput from '../../../../../components/editors/textinput';
import AddCancelBar from '../../../../../components/AddCancelBar';
import SystemStatePanel from '../../../panels/left/systemstates';

import {DOCUMENT_ITEMTYPES, DOCUMENT_TOKENTYPES} from '../../../manager/documents';
import Widget from '../widget';

import ItemEditor_Wizard from './wizard';
import ItemEditor_Text from './text';
import ItemEditor_Image from './image';
import ItemEditor_Video from './video';
import ItemEditor_Tokens from './tokens';
import ItemEditor_Component from './component';
import ItemEditor_FigmaComponent from './figmaComponent';
import ItemEditor_IFrame from './iframe';
import ItemEditor_Lottie from './lottie';
import ItemEditor_Carousel from './carousel';
import ItemEditor_LinkCard from './linkcard';
import ItemEditor_Accessbility from './accessibility';

import {DocumentToolbarButton, DocumentToolbarSeperator, DocumentToolbarColorPicker, DocumentToolbarBox, RenderDocumentVisibleBar} from './text/toolbar';
import { TextAlignLines } from "../../../panels/right/iteminspector/styleitems/text";
import SelectDocumentComponent from './wizard/selectComponent';
import SelectDocumentFigmaComponent from './wizard/figmaComponents';
import SelectDocumentImage from './wizard/selectImage';
import LightDarkBar from '../../../../../components/light_dark_bar';
import DropDownSelect from '../../../../../components/editors/enum_dropdown';
import { TokenTypes } from '../../../manager/tokens';
import ItemBottomMargin from './spacer';
import Switch from '../../../../../components/editors/Switch';
import { CopyExportTokenCodeBox } from '../../../tokens/export/tokengroup';
import TextValue from '../../../../../components/editors/textvalue';

export default class DocumentItem extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {

        };

        this.Ref_Editor = React.createRef();
        this.Ref_Toolbar = React.createRef();

        this.Delete = this.Delete.bind(this);        
        this.onMouseEnter = this.onMouseEnter.bind(this);        
        this.onMouseEnterForced = this.onMouseEnterForced.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);        
        this.onMouseMove = this.onMouseMove.bind(this);        
        this.Reload = this.Reload.bind(this);
        this.NewItemAdded = this.NewItemAdded.bind(this);
        this.SuspendToolbar = this.SuspendToolbar.bind(this);        
        
        this.Load(this.props.id);
    }
    Load(id) {
        this.data = Globals.ProjectManager.GetDocumentManager().GetPageItem(this.props.pageId, id);        
        this.marginBottom = Utils.JustGet(this.data.data, 24, 'bottom');

        if (Utils.IsOneOf(this.data.type, DOCUMENT_ITEMTYPES.iframe, DOCUMENT_ITEMTYPES.figmaLiveEmbed, DOCUMENT_ITEMTYPES.storybookEmbed, DOCUMENT_ITEMTYPES.video, DOCUMENT_ITEMTYPES.lottie, DOCUMENT_ITEMTYPES.linkcard)) {
            this.state.url = Utils.JustGet(this.data.data, '', 'url');
            if (this.data.type === DOCUMENT_ITEMTYPES.linkcard)
                this.state.link = Utils.JustGet(this.data.data, '', 'link');            
        }
        else if (this.data.type === DOCUMENT_ITEMTYPES.token) {
            this.hasWidth = true;
            this.hasViewMode = false;
            this.hasCardWidth = false;

            this.tokenType = Utils.JustGet(this.data.data, Globals.ProjectManager.Tokens.Types.COLOR, 'type');        
            this.subTokenType = Utils.JustGet(this.data.data, '', 'subType');

            if (this.tokenType === TokenTypes.Fonts) {                
                this.hasWidth = false;
                if (Utils.UseNullOrEmpty(this.subTokenType, 'patterns') === 'patterns')
                    this.hasViewMode = true;
                else if (this.subTokenType === 'fonts') {
                    this.hasViewMode = 2;    
                }
            }
            else if (Utils.IsOneOf(this.tokenType, TokenTypes.COLOR, TokenTypes.Gradients, TokenTypes.Icons)) {        
                this.hasViewMode = true;
                this.hasCardWidth = true;
            }
        }
    }
    Reload() {
        this.Load(this.props.id);
        this.RenderId = Utils.Id();
        if (this.state.addItemId) {
            this.setState({addItemId : false});
        }
        else
            this.Update();
    }
    NewItemAdded(isLayout) {
        if (!this.props.onSuspendItemSelection)
            return;
        this.props.onSuspendItemSelection();
        if (isLayout) {
            Globals.ProjectManager.GetDocumentManager().SetItemValue(this.state.addItemId, this.props.id, 'layoutId');
            this.subItemId = this.state.addItemId;
            this.setState({
                addItemId : false
            })
        }
        else {
            this.state.toolbarExpanded = true;            
        }
        this.Reload();
        this.IsNewItem = true;
        setTimeout(() => {
            this.props.onSuspendItemSelection();
        }, 300);
    }

    Delete(id, parentId) {
        this.setState({willDelete : true}, () => {
            setTimeout(() => {
                this.props.onDeleteItem(this.props.id, parentId);
            }, 200);
        })        
    }
    componentDidMount() {
        if (this.props.autoFocus && !this.props.preview) {
            this.props.onMouseEnter(this.props.id, this.subItemId, this);
            this.state.toolbarExpanded = true;
            // this.Update();
        }
    }
    shouldComponentUpdate(nextProps, nextState) {                                
        if (this.props.RenderId !== nextProps.RenderId) {
            this.Load(nextProps.id);
            this.RenderId = nextProps.RenderId;
            return true;
        }
        if (this.state.ShouldUpdate !== nextState.ShouldUpdate)
            return true;
        if (Utils.HasAnyChange(this.props, nextProps, 'GlobalState', 'GlobalThemeId', 'themeId', 'autoFocus', 'mobile'))
            return true;
        return true;
    }
    componentDidUpdate(prevProps, prevState) {
        this.state.ShouldUpdate = false;
        setTimeout(() => {
            this.IsNewItem = false;
        }, 100);        
    }    
    onMouseEnterForced(e) {
        this.props.context.disabled = false;
        AppLayout.Designer.SuspendDocumentDesigner = false;
        e.preventDefault();
        e.stopPropagation();

        if (this.props.context.id === this.props.id)
            return;
        
        this.props.onItemSettings();
        this.props.onMouseEnter && this.props.onMouseEnter(this.props.id, this.subItemId, this);
        this.Update();
    }
    onMouseEnter(e) {
        if (this.props.preview || this.props.context.dragging || this.props.context.disabled || AppLayout.Designer.SuspendDocumentDesigner)
            return;        
        this.isMouseOver = true;        
        e.preventDefault();
        e.stopPropagation();

        if (this.props.context.id === this.props.id)
            return;

        setTimeout(() => {
            if (this.isMouseOver) {
                this.props.onMouseEnter && this.props.onMouseEnter(this.props.id, this.subItemId, this);
                this.Update();
            }
        }, 100);        
    }    
    onMouseLeave(e) {
        this.isMouseOver = false;
    }
    onMouseMove(e) {
        if (this.props.context.widgetRef) {
            const bounds = UIUtils.Utils.GetBounds(this);
            this.props.context.widgetRef.UpdatePosition(Math.max(0, Math.min(e.clientY - bounds.top, e.clientY - bounds.top - 92) + 46));
        }
    }
    Update() {
        if (this.props.id !== this.props.context.id)
            this.state.suspendToolbar = false;
        this.setState({
            ShouldUpdate : true
        })
    }
    UpdateScrollPosition(pos) {
        if (this.Ref_Toolbar.current) {
            const DN = ReactDOM.findDOMNode(this.Ref_Toolbar.current);
            const bounds = DN.getBoundingClientRect();
            // console.log(`${pos} - ${bounds.top}`);
            DN.style.transform = 'translateY('+(pos + bounds.top)+'px)';
        }
    }
    onSetItemProp(id, prop, value) {
        Globals.ProjectManager.GetDocumentManager().SetItemValue(id, value, prop);
        this.RenderId = Utils.Id();
        this.Update();
    }
    onSetItemPropOffline(id, prop, value) {
        Globals.ProjectManager.GetDocumentManager().SetItemValueOffline(id, value, prop);
        this.RenderId = Utils.Id();
        this.Update();
    }
    SetImageUrl(value) {
        this.setState({url : value})
    }
    SetLink(value) {
        this.setState({link : value})
    }
    SaveImageUrl(id) {
        Globals.ProjectManager.GetDocumentManager().SetItemValue(id, this.state.url, 'url');
        Globals.ProjectManager.GetDocumentManager().SetItemValue(id, 'url', 'source');
        const storageId = Utils.JustGet(this.data.data, null, 'storageId');
        if (storageId) {
            Globals.ProjectManager.DeleteStorageImage(storageId);
            Globals.ProjectManager.GetDocumentManager().DeleteItemValue(id, 'storageId');
        }
        this.RenderId = Utils.Id();
        this.state.showImageUrl = false;
        this.state.url = null;
        this.Update();
    }
    SaveIframeUrl(id) {
        Globals.ProjectManager.GetDocumentManager().SetItemValue(id, this.state.url, 'url');
        this.RenderId = Utils.Id();
        this.Update();
    }
    SaveLink() {
        Globals.ProjectManager.GetDocumentManager().SetItemValue(this.props.id, this.state.link, 'link');
        this.RenderId = Utils.Id();
        this.Update();
    }
    UploadImageFile(id, e) {
        const file = e.target.files[0];
        const newId = Utils.Id();
        Globals.ProjectManager.GetDocumentManager().SetItemValue(id, URL.createObjectURL(file), 'url');
        Globals.ProjectManager.GetDocumentManager().SetItemValue(id, 'file', 'source');
        Globals.ProjectManager.GetDocumentManager().SetItemValue(id, newId, 'storageId');
        this.RenderId = Utils.Id();
        this.setState({
            ShouldUpdate : true
        });
        
        const onUploadComplete = this.UploadCompleted.bind(this, id, newId);
        Globals.ProjectManager.DataManager.Storage.Save(newId, file, {}, (progress) => {
        }).then(onUploadComplete);
    }

    UploadCompleted(itemId, newId, result) {
        if (result) {
            result.ref.getDownloadURL().then((downloadURL) => {
                Globals.ProjectManager.GetDocumentManager().SetItemValue(itemId, downloadURL, 'url');
                Globals.ProjectManager.GetDocumentManager().SetItemValue(itemId, 'file', 'source');
                Globals.ProjectManager.GetDocumentManager().SetItemValue(itemId, newId, 'storageId');
                this.RenderId = Utils.Id();
                this.Update();
            });

        }
    }
    SuspendToolbar(suspend) {
        this.setState({suspendToolbar : suspend});
    }
    renderImageToolbar(source, url, id) {
        if (!this.Ref_ImageFile)
            this.Ref_ImageFile = React.createRef();
        return (
            <React.Fragment>
                <DocumentToolbarButton active={source === 'unsplash'} onMouseDown={() => {
                    this.setState({showImageSelector : true})
                }}>
                    <div style={{paddingLeft : '4px', paddingRight : '4px'}}>Unsplash</div>
                </DocumentToolbarButton>
                <DocumentToolbarButton active={source === 'url'} onMouseDown={() => {
                    this.setState({showImageUrl : true, url : url});
                }} style={{marginLeft : '4px', marginRight : '4px'}}>
                    <div style={{paddingLeft : '4px', paddingRight : '4px'}}>Url</div>
                </DocumentToolbarButton>
                <DocumentToolbarButton active={source === 'file'} hasFill onMouseDown={() => this.Ref_ImageFile.current.click() }>
                    <SC.Icons.Upload />
                </DocumentToolbarButton>
                <input type="file" style={{ display: 'none' }}
                    ref={this.Ref_ImageFile}
                    accept="image/*"
                    onChange={this.UploadImageFile.bind(this, id)}>
                </input >
            </React.Fragment>
        )
    }
    renderImageUrl(id, url) {
        return (
            <SC.FRow alc 
                style={{padding : '4px', width : '100%'}}
                onMouseDown={(e) => {
                    e.preventDefault(); 
                    e.stopPropagation();
                }}
            >
                <div>Url</div>
                <TextInput 
                    themeId={this.props.themeId}
                    placeholder='Enter Url'
                    dark
                    autoFocus
                    value={url}
                    onChanging={this.SetImageUrl.bind(this)}
                    onChange={this.SetImageUrl.bind(this)}
                    style={{
                        height : '30px',
                        width : '400px',
                        marginLeft : '8px',
                        marginRight : '8px'
                    }}
                    onSubmit={this.SaveImageUrl.bind(this, id)}
                    onCancel={() => this.setState({showImageUrl : false})}
                />
                <AddCancelBar 
                    onCancel={() => this.setState({showImageUrl : false})}
                    onAdd={this.SaveImageUrl.bind(this, id)}
                />
            </SC.FRow>
        )
    }
    onShowItemSettings(id) {

    }
    AddLayoutItem(index) {
        const id = Globals.ProjectManager.GetDocumentManager().AddItem({            
            data : {
                data : {layoutId : this.props.id}
            }
        });
        Globals.ProjectManager.GetDocumentManager().SetItemValue(this.props.id, id, index);
        this.setState({
            addItemId : id,
            subItemIndex : index
        })
        // Globals.ProjectManager.GetDocumentManager().SetItemValue(this.props.id, Utils.Id(), index);
        // this.Update();
    }
    DeleteSubItem(id, prop) {
        Globals.ProjectManager.GetDocumentManager().DeleteItem(id);
        Globals.ProjectManager.GetDocumentManager().DeleteItemValue(this.props.id, prop);
        this.setState({
            addItemId : null,
            subItemIndex : null,
            ShouldUpdate : true
        })
    }
    onMouseLeaveSubItem(subItemId) {
        delete this.willOverSubItem;
    }
    onMouseEnterSubItem(subItemId) {
        this.willOverSubItem = subItemId;
        setTimeout(() => {
            if (this.willOverSubItem) {
                this.subItemId = this.willOverSubItem;
                if (this.subItemId) {
                    this.LayoutIsVertical = false;
                    const data = Globals.ProjectManager.GetDocumentManager().GetPageItem(this.props.pageId, this.subItemId);
                    if (Utils.IsOneOf(data.type, DOCUMENT_ITEMTYPES.iframe, DOCUMENT_ITEMTYPES.figmaLiveEmbed, DOCUMENT_ITEMTYPES.storybookEmbed, DOCUMENT_ITEMTYPES.video, DOCUMENT_ITEMTYPES.lottie, DOCUMENT_ITEMTYPES.linkcard)) {
                        this.state.url = Utils.JustGet(data.data, '', 'url');
                        if (data.type === DOCUMENT_ITEMTYPES.linkcard)
                            this.state.link = Utils.JustGet(data.data, '', 'link');            
                    }
                    else if (data.type === DOCUMENT_ITEMTYPES.token) {
                        this.hasWidth = true;
                        this.hasViewMode = false;
                        this.hasCardWidth = false;
            
                        this.tokenType = Utils.JustGet(data.data, Globals.ProjectManager.Tokens.Types.COLOR, 'type');        
                        this.subTokenType = Utils.JustGet(data.data, '', 'subType');
            
                        if (this.tokenType === TokenTypes.Fonts) {                
                            this.hasWidth = false;
                            if (Utils.UseNullOrEmpty(this.subTokenType, 'patterns') === 'patterns')
                                this.hasViewMode = true;
                            else if (this.subTokenType === 'fonts')
                                this.hasViewMode = 2;    
                        }
                        else if (this.tokenType === TokenTypes.COLOR || this.tokenType === TokenTypes.Gradients) {        
                            this.hasViewMode = true;
                            this.hasCardWidth = true;
                        }
                    }

                    if (this.Ref_LeftItem && this.Ref_LeftItem.current && this.Ref_RightItem && this.Ref_RightItem.current) {
                        const boundsLeft = UIUtils.Utils.GetBounds(this.Ref_LeftItem.current);
                        const boundsRight = UIUtils.Utils.GetBounds(this.Ref_RightItem.current);
                        if (boundsRight.top > boundsLeft.top) {
                            this.LayoutIsVertical = boundsRight.top - boundsLeft.top;
                        }
                    }

                }
                this.props.onMouseEnter && this.props.onMouseEnter(this.props.id, this.subItemId, this);
                this.setState({
                    ShouldUpdate : true
                })
            }
        }, 200);
        
    }
    SelectAccessibilityColor(id, prop, label, tokenId, forText, backgroundColor) {
        this.props.onSelectToken({
            tokenId : tokenId,
            title : label.toUpperCase(),
            forText : forText, 
            backgroundColor : backgroundColor,
            onSelect : (colorId) => {
                Globals.ProjectManager.GetDocumentManager().SetItemValue(id, colorId, prop);
                this.Update();
            }
        });
    }
    renderItem({id, data, showToolbar, selected, props}) {
        const result = {
            showToolbar : showToolbar || !this.props.preview && this.props.context && this.props.context.id === this.props.id && !this.state.suspendToolbar,
            props : {
                id : id,    
                pageId : this.props.pageId,      
                onItemSettings : this.props.onItemSettings,
                RenderId : this.RenderId,
                StyleId : this.props.StyleId,
                GlobalThemeId : this.props.GlobalThemeId,
                GlobalState : this.props.GlobalState,
                themeId : this.props.themeId,
                selected : selected || this.props.context && this.props.context.id === id,
                preview : this.props.preview,
                forTemplateSelect : this.props.forTemplateSelect,
                shared : this.props.shared,
                textStyles : this.props.textStyles,
                heroItem : this.props.heroItem,
                onSuspendToolbar : this.SuspendToolbar,
                onSuspendItemSelection : this.props.onSuspendItemSelection,
                ...props
            }
        };

        const hidden = Utils.JustGet(data.data, false, 'hidden');

        const getVisibleBar = () => {                        
            return (
                <RenderDocumentVisibleBar 
                    hidden={hidden}
                    onChange={this.onSetItemProp.bind(this, id, 'hidden', !hidden)}
                />
            )
            // return (
            //     <React.Fragment>
            //         <DocumentToolbarButton active={hidden} onMouseDown={this.onSetItemProp.bind(this, id, 'hidden', !hidden)} title='Hide in view mode'>
            //             <SC.Icons.Icon_Button hasFill>
            //                 <SC.Icons.Icon_Visibility visible={!hidden} />
            //             </SC.Icons.Icon_Button>
            //         </DocumentToolbarButton>
            //         <DocumentToolbarSeperator style={{alignSelf : 'stretch', height : 'unset', marginLeft : '4px', marginRight : '4px'}} />
            //     </React.Fragment>
            // )
        }

        const getAlignmentBar = (alignment, extended) => {
            const items = ['flex-start', 'center', 'flex-end', 'stretch'];
            if (extended) 
                items.push('fullwidth');
            return items.map((alignItem, i) => {
                let style_extended = {};
                if (i === 4)
                    style_extended = {marginLeft : '-4px', minWidth : '22px'}
                return (
                    <DocumentToolbarButton key={i} active={alignment === alignItem} onMouseDown={this.onSetItemProp.bind(this, id, 'alignment', alignItem)}>
                        <TextAlignLines boxed selected={alignment === alignItem} align={alignItem} style_center={style_extended}/>
                    </DocumentToolbarButton>
                )
            });
        }

        const getSliderBar = (size, min, max, prop, label) => {
            return (
                <React.Fragment>
                    <DocumentToolbarSeperator style={{alignSelf : 'stretch', height : 'unset', marginLeft : '4px', marginRight : '4px'}} />
                    <div style={{whiteSpace : 'nowrap'}}>{label}</div>
                    <Slider 
                        min={min}
                        max={max}
                        value={size} 
                        style={{width : '120px', marginLeft : '8px'}} 
                        onChange={this.onSetItemProp.bind(this, id, prop)}
                        onChanging={this.onSetItemPropOffline.bind(this, id, prop)}
                    />
                </React.Fragment>
            )
        }

        if (false && this.props.sortableProps.isDragging) {
            result.itemEditor = (
                <div>Dragging</div>
            )
        }
        else {
            if (data.type === DOCUMENT_ITEMTYPES.text) {
                result.itemEditor = (
                    <ItemEditor_Text 
                        {...result.props}
                        autoFocus={this.IsNewItem}
                        onShowColorEditor={this.props.onShowColorEditor}
                        hidden={hidden}
                        onChangeHidden={this.onSetItemProp.bind(this, id, 'hidden', !hidden)}
                    />
                )
            }
            else if (data.type === DOCUMENT_ITEMTYPES.themeConfigurator) {
                result.itemEditor = (
                    <SC.FCol>
                        <SC.GroupLabel style={{fontSize : '12px'}}>Theme Configurator</SC.GroupLabel>
                        <SystemStatePanel 
                            contentStyle={{margin : 0, marginBottom : '16px', minWidth : '200px', width : '240px', backgroundColor : this.props.textStyles.backgroundHighlight}}
                            justContent               
                            themeId={this.props.themeId}         
                        />
                    </SC.FCol>                
                )
            }
            else if (data.type === DOCUMENT_ITEMTYPES.iframe || data.type === DOCUMENT_ITEMTYPES.figmaLiveEmbed || data.type === DOCUMENT_ITEMTYPES.storybookEmbed) {
                if (this.props.index === 0)
                    result.marginBottom = '-16px';
    
                const alignment = Utils.JustGet(data.data, 'stretch', 'alignment');
                
                result.itemEditor = (
                    <ItemEditor_IFrame 
                        {...result.props}
                        autoFocus={result.props.selected || this.props.index === 0}
                        type={data.type}
                    />
                );
                if (alignment === 'fullwidth') {
                    result.fullWidthItem = result.itemEditor;
                    result.itemEditor = null;
                }
                if (result.showToolbar) {                
                    const size = Utils.JustGet(data.data, 100, 'size');
                    result.itemToolbar = (
                        <SC.FCol style={{width : '100%', boxSizing : 'border-box'}}>
                            <SC.FRow alc style={{padding : '4px', borderBottom : SC.CurrentTheme.theme.border_seperator, whiteSpace : 'nowrap'}}>
                                <div>{data.type === DOCUMENT_ITEMTYPES.figmaLiveEmbed ? 'Figma Url' : 'Url'}</div>
                                <TextInput 
                                    themeId={this.props.themeId}
                                    placeholder='Enter Url'
                                    dark
                                    value={this.state.url}
                                    onChanging={this.SetImageUrl.bind(this)}
                                    onChange={this.SaveIframeUrl.bind(this, id)}
                                    onBlur={this.SaveIframeUrl.bind(this, id)}
                                    style={{
                                        marginLeft : '8px',
                                        height : '30px',
                                    }}
                                    onSubmit={this.SaveIframeUrl.bind(this, id)}
                                />
                            </SC.FRow>    
                            <SC.FRow alc style={{padding : '4px'}}>
                                {getVisibleBar()}
                                {getAlignmentBar(alignment, true)}
                                {
                                    alignment !== 'fullwidth' && alignment !== 'stretch' && getSliderBar(size, 10, 100, 'size', 'W')                                
                                }
                                {getSliderBar(Utils.JustGet(data.data, 400, 'height'), 100, 800,  'height', 'H')}
                            </SC.FRow>
                                            
                        </SC.FCol>
                    );
                }            
            }
            else if (data.type === DOCUMENT_ITEMTYPES.code) {
                const value = Utils.JustGet(data.data, '', 'value');
                if (this.props.preview) {
                    const codeStyle = {
                        height : '100%'
                    };
                    let CodeId = this.CodeId;
                    if (SC.CurrentTheme.theme.isLight) {
                        codeStyle.filter = 'invert(1)';
                        CodeId += 'Light';
                    }
                    const toolbarStyle = {
                        bottom : 'unset',
                        top : '8px'
                    };
                    result.itemEditor = (
                        <CopyExportTokenCodeBox 
                            RenderId={CodeId}
                            exportedCode={value}
                            noClassName
                            style={{...codeStyle, overflow : 'auto'}}
                            // inlineDownloadButtons
                            toolbarStyle={toolbarStyle}
                        />
                    )
                }
                else {
                    result.itemEditor = (
                        <div>
                            <div>The code will be highlighted in preview mode.</div>
                            <TextValue
                                multiline
                                placeholder='Write your code here'
                                value={value}
                                onChange={this.onSetItemProp.bind(this, id, 'value')}
                            />
                        </div>                        
                    )
                    // result.itemToolbar = (
                    //     <SC.FRow alc style={{padding : '4px', width : '100%'}}>
                    //         <DropDownSelect 
                    //             items={[
                    //                 {id : 'javascript'},
                    //                 {id : 'javascript'},
                    //             ]}
                    //             onChange={this.onSetItemProp.bind(this, id, 'heightType')}
                    //             style={{fontSize : '11px', backgroundColor : SC.CurrentTheme.theme.back}}
                    //             value={'javascript'}
                    //             hasBorder                                     
                    //             noBackColor   
                    //             autoHeight
                    //             xsmall
                    //             toRight
                    //         />
                    //     </SC.FRow>
                    // )
                }                
            }
            else if (data.type === DOCUMENT_ITEMTYPES.linkcard) {
                if (this.props.index === 0)
                    result.marginBottom = '-16px';
    
                const alignment = Utils.JustGet(data.data, 'center', 'alignment');
    
                if (this.state.showImageSelector) {
                    result.itemEditor = (
                        <SelectDocumentImage
                            {...this.props}
                            onClose={() => this.setState({showImageSelector : false})}                        
                            propName='url'
                            onUpdate={() => {
                                this.state.showImageSelector = false;
                                this.Reload();
                            }}
                        />
                    )
                }
                else {
                    result.itemEditor = (
                        <ItemEditor_LinkCard 
                            {...result.props}
                            key={this.RenderId}
                            autoFocus={result.props.selected || this.props.index === 0}
                        />
                    )
                }
                
    
                if (alignment === 'fullwidth') {
                    result.fullWidthItem = result.itemEditor;
                    result.itemEditor = null;
                }
                if (result.showToolbar) {      
                    const source = Utils.JustGet(data.data, null, 'source');
                    const imageurl = Utils.JustGet(data.data, '', 'url');
                    const direction = Utils.JustGet(data.data, 'horizontal', 'direction');
                    const openInNewWindow = Utils.JustGet(data.data, false, 'newWindow');
                    const linkColor = Utils.JustGet(data.data, this.props.textStyles.backgroundHighlight, 'backColor');
                    result.itemToolbar = (
                        <SC.FRow>
                            <SC.FCol style={{width : '100%', boxSizing : 'border-box'}}>
                                <SC.FRow alc style={{padding : '4px', borderBottom : SC.CurrentTheme.theme.border_seperator, whiteSpace : 'nowrap'}}>
                                    <div>Link Url</div>
                                    <TextInput 
                                        themeId={this.props.themeId}
                                        placeholder='Enter Url'
                                        dark
                                        value={this.state.link}
                                        onChanging={this.SetLink.bind(this)}
                                        onChange={this.SaveLink.bind(this)}
                                        onBlur={this.SaveLink.bind(this)}
                                        style={{
                                            marginLeft : '8px',
                                            height : '30px',
                                        }}
                                        onSubmit={this.SaveLink.bind(this)}
                                    />
                                    <div style={{marginLeft : '8px', marginRight : '4px'}}>Open in New Window</div>
                                    <Switch round value={openInNewWindow} onChange={this.onSetItemProp.bind(this, id, 'newWindow')} />
                                </SC.FRow>    
                                <SC.FRow alc style={{padding : '4px'}}>
                                    {getVisibleBar()}
                                    {getAlignmentBar(alignment, false)}                            
                                    <DocumentToolbarSeperator style={{alignSelf : 'stretch', height : 'unset', marginLeft : '4px', marginRight : '8px'}} />
                                    {
                                        this.state.showImageUrl ? this.renderImageUrl(id, this.state.url) : 
                                        <SC.FRow alc>
                                            {this.renderImageToolbar(source, imageurl, id)}
                                            <DocumentToolbarSeperator style={{alignSelf : 'stretch', height : 'unset', marginLeft : '4px', marginRight : '8px'}} />
                                            <div>Image Width</div>
                                            <Slider 
                                                min={50}
                                                max={400}
                                                value={Utils.JustGet(data.data, 200, 'imageWidth')} 
                                                style={{marginLeft : '8px', width : '180px'}} 
                                                onChange={this.onSetItemProp.bind(this, id, 'imageWidth')}
                                                onChanging={this.onSetItemPropOffline.bind(this, id, 'imageWidth')}
                                            /> 
                                            <DocumentToolbarColorPicker 
                                                color={linkColor}                                            
                                                onChangeColor={this.onSetItemProp.bind(this, id, 'backColor')}
                                                onSaveColor={this.onSetItemProp.bind(this, id, 'backColor')}
                                            />
                                        </SC.FRow>                                
                                    }                            
                                </SC.FRow>                        
                            </SC.FCol>
                            <DocumentToolbarSeperator style={{alignSelf : 'stretch', height : 'unset', marginRight : 0}} />
                            <div style={{display : 'grid', gridTemplateRows : '1fr 1fr', gridGap : '4px', padding : '4px'}}>
                                <DocumentToolbarButton active={direction !== 'vertical'} style={{paddingLeft : '8px', paddingRight : '8px'}} onClick={this.onSetItemProp.bind(this, id, 'direction', 'horizontal')}>
                                    Horizontal        
                                </DocumentToolbarButton>
                                <DocumentToolbarButton active={direction === 'vertical'} style={{paddingLeft : '8px', paddingRight : '8px'}} onClick={this.onSetItemProp.bind(this, id, 'direction', 'vertical')}>
                                    Vertical        
                                </DocumentToolbarButton>
                            </div>                     
                        </SC.FRow>                    
                    );
                }
            }            
            else if (data.type === DOCUMENT_ITEMTYPES.lottie) {
                const alignment = Utils.JustGet(data.data, 'stretch', 'alignment');
    
                result.itemEditor = (
                    <ItemEditor_Lottie 
                        {...result.props}
                        key={this.RenderId}
                        autoFocus={result.props.selected || this.props.index === 0}
                    />
                );
    
                if (alignment === 'fullwidth') {
                    result.fullWidthItem = result.itemEditor;
                    result.itemEditor = null;
                }
                if (result.showToolbar) {    
                    if (this.props.index === 0)
                        result.marginBottom = '-16px';            
                    const size = Utils.JustGet(data.data, 100, 'size');
                    result.itemToolbar = (
                        <SC.FCol style={{width : '100%', boxSizing : 'border-box'}}>
                            <SC.FRow alc style={{padding : '4px', borderBottom : SC.CurrentTheme.theme.border_seperator}}>
                                <div>Url</div>
                                <TextInput 
                                    placeholder='Enter Url'
                                    dark
                                    value={this.state.url}
                                    onChanging={this.SetImageUrl.bind(this)}
                                    onChange={this.SaveIframeUrl.bind(this, id)}
                                    onBlur={this.SaveIframeUrl.bind(this, id)}
                                    themeId={this.props.themeId}
                                    style={{
                                        marginLeft : '8px',
                                        height : '30px',
                                    }}
                                    onSubmit={this.SaveIframeUrl.bind(this, id)}
                                />
                                <a href='https://lottiefiles.com/featured' target='_blank'>
                                    <DocumentToolbarButton style={{whiteSpace : 'nowrap', marginLeft : '16px'}}>
                                        Browse Lottie Files
                                    </DocumentToolbarButton>
                                </a>
                            </SC.FRow>    
                            <SC.FRow alc style={{padding : '4px'}}>
                                {getVisibleBar()}
                                {getAlignmentBar(alignment, true)}
                                {
                                    alignment !== 'fullwidth' && alignment !== 'stretch' && getSliderBar(size, 10, 100, 'size', 'W')                                
                                }
                                {getSliderBar(Utils.JustGet(data.data, 400, 'height'), 100, 800, 'height', 'H')}
                                <DocumentToolbarSeperator style={{alignSelf : 'stretch', height : 'unset', marginLeft : '4px', marginRight : '8px'}} />
                                <div>Speed</div>
                                <Slider 
                                    min={0}
                                    max={40}
                                    value={Utils.JustGet(data.data, 10, 'speed')} 
                                    style={{marginLeft : '8px', width : '140px'}} 
                                    onChange={this.onSetItemProp.bind(this, id, 'speed')}
                                    onChanging={this.onSetItemPropOffline.bind(this, id, 'speed')}
                                />    
                            </SC.FRow>
                                            
                        </SC.FCol>
                    );
                }            
            }
            else if (data.type === DOCUMENT_ITEMTYPES.image) {
                if (this.state.showImageSelector && !result.props.layoutItem) {
                    result.itemEditor = (
                        <SelectDocumentImage
                            {...this.props}
                            onClose={() => this.setState({showImageSelector : false})}                        
                            onUpdate={() => {
                                this.state.showImageSelector = false;
                                this.Reload();
                            }}
                        />
                    )
                }
                else {
                    const alignment = Utils.JustGet(data.data, 'stretch', 'alignment');
                    result.itemEditor = (
                        <ItemEditor_Image 
                            {...result.props}
                            autoFocus={result.props.selected || this.props.index === 0}
                        />
                    );
    
                    if (alignment === 'fullwidth') {
                        result.fullWidthItem = result.itemEditor;
                        result.itemEditor = null;
                    }
    
                    if (result.showToolbar) {                    
                        const source = Utils.JustGet(data.data, null, 'source');
                        const url = Utils.JustGet(data.data, '', 'url');
                        const size = Utils.JustGet(data.data, 100, 'size');
                        if (this.state.showImageUrl) {
                            result.itemToolbar = this.renderImageUrl(id, this.state.url);
                        }
                        else {                        
                            result.itemToolbar = (
                                <SC.FRow alc style={{padding : '4px', width : '100%'}}>
                                    {getVisibleBar()}
                                    {this.renderImageToolbar(source, url, id)}
                                    <DocumentToolbarSeperator style={{alignSelf : 'stretch', height : 'unset', marginLeft : '4px', marginRight : '4px'}} />
                                    {getAlignmentBar(alignment, true)}
                                    {
                                        alignment !== 'fullwidth' && alignment !== 'stretch' && getSliderBar(size, 10, 100, 'size', 'W')                                    
                                    }                                                        
                                                                    
                                </SC.FRow>
                            );
                        }
                    }
                }
            }
            else if (data.type === DOCUMENT_ITEMTYPES.video) {
                if (this.props.index === 0)
                    result.marginBottom = '-16px';
    
                const alignment = Utils.JustGet(data.data, 'stretch', 'alignment');
                
                result.itemEditor = (
                    <ItemEditor_Video 
                        {...result.props}
                        autoFocus={result.props.selected || this.props.index === 0}
                    />
                );
                if (alignment === 'fullwidth') {
                    result.fullWidthItem = result.itemEditor;
                    result.itemEditor = null;
                }
                if (result.showToolbar) {                
                    const size = Utils.JustGet(data.data, 100, 'size');
                    result.itemToolbar = (
                        <SC.FCol style={{width : '100%', boxSizing : 'border-box'}}>
                            <SC.FRow alc style={{padding : '4px', borderBottom : SC.CurrentTheme.theme.border_seperator}}>
                                <div>Url</div>
                                <TextInput 
                                    themeId={this.props.themeId}
                                    placeholder='Enter Url'
                                    dark
                                    value={this.state.url}
                                    onChanging={this.SetImageUrl.bind(this)}
                                    onChange={this.SaveIframeUrl.bind(this, id)}
                                    onBlur={this.SaveIframeUrl.bind(this, id)}
                                    style={{
                                        marginLeft : '8px',
                                        height : '30px',
                                    }}
                                    onSubmit={this.SaveIframeUrl.bind(this, id)}
                                />
                            </SC.FRow>    
                            <SC.FRow alc style={{padding : '4px'}}>
                                {getVisibleBar()}
                                {getAlignmentBar(alignment, true)}
                                {
                                    alignment !== 'fullwidth' && alignment !== 'stretch' && getSliderBar(size, 10, 100, 'size', 'W')                                
                                }
                                {getSliderBar(Utils.JustGet(data.data, 400, 'height'), 100, 800, 'height', 'H')}
                            </SC.FRow>
                                            
                        </SC.FCol>
                    );
                }     
            }
            else if (data.type === DOCUMENT_ITEMTYPES.token) {
                const alignment = Utils.JustGet(data.data, 'stretch', 'alignment');
                const viewMode = Utils.JustGet(data.data, this.hasViewMode === 2 ? 'card' : 'list', 'view');
                const size = Utils.JustGet(data.data, 100, 'size');
                const cardSize = Utils.JustGet(data.data, 100, 'cardSize');
                const tokenType = Utils.JustGet(data.data, Globals.ProjectManager.Tokens.Types.COLOR, 'type');  
                let tokenTitle = '';
                const tokenTypeItem = Utils.Find(DOCUMENT_TOKENTYPES, (ti) => {return ti.id === tokenType});
                if (tokenTypeItem)
                    tokenTitle = tokenTypeItem.label;

                result.itemEditor = (
                    <ItemEditor_Tokens 
                        {...result.props}
                        alignment={alignment}
                        viewMode={viewMode}
                        size={alignment !== 'fullwidth' && alignment !== 'stretch' ? size : null}
                        cardSize={cardSize}
                    />
                )
    
                if (result.showToolbar) {     
                    const widgetType = Utils.JustGet(data.data, null, 'widgetType');
                    let optionsBar = (
                        <React.Fragment>
                            <DocumentToolbarSeperator style={{alignSelf : 'stretch', height : 'unset', marginLeft : '8px', marginRight : '4px'}} />
                            <div>{tokenTitle}</div>
                            <DocumentToolbarButton onClick={this.props.onItemSettings}>
                                <div style={{paddingLeft : '4px', paddingRight : '4px', whiteSpace : 'nowrap'}}>Token Options</div>
                            </DocumentToolbarButton>
                        </React.Fragment>
                    )
                    if (widgetType === 'export') {
                        const heightType = Utils.JustGet(data.data, 'auto', 'heightType');
                        const height = Utils.JustGet(data.data, '400', 'height');
                        result.itemToolbar = (
                            <SC.FRow alc style={{padding : '4px', width : '100%'}}>                            
                                {getVisibleBar()}
                                <DropDownSelect 
                                    items={[{id : 'auto', label : 'Auto Height'}, {id : 'custom', label : 'Custom Height'}]}
                                    onChange={this.onSetItemProp.bind(this, id, 'heightType')}
                                    style={{fontSize : '11px', backgroundColor : SC.CurrentTheme.theme.back}}
                                    value={heightType}
                                    hasBorder                                     
                                    noBackColor   
                                    autoHeight
                                    xsmall
                                    toRight
                                />
                                {
                                    heightType === 'custom' && 
                                    <Slider 
                                        min={100}
                                        max={800}
                                        value={height} 
                                        style={{width : '180px', marginLeft : '8px'}} 
                                        onChange={this.onSetItemProp.bind(this, id, 'height')}
                                        onChanging={this.onSetItemPropOffline.bind(this, id, 'height')}
                                    />
                                }
                                {optionsBar}
                            </SC.FRow>
                        );
                    }
                    else {
                        result.itemToolbar = (
                            <SC.FRow alc style={{padding : '4px', width : '100%'}}>
                                {getVisibleBar()}
                                {getAlignmentBar(alignment)}
                                {
                                    this.hasWidth && alignment !== 'fullwidth' && alignment !== 'stretch' && getSliderBar(size, 10, 100, 'size', 'W')                                
                                }
                                {
                                    this.hasViewMode && 
                                    <React.Fragment>
                                        <DocumentToolbarSeperator style={{alignSelf : 'stretch', height : 'unset', marginLeft : '4px', marginRight : '4px'}} />
                                        <DocumentToolbarButton hasFill active={viewMode === 'list'} onClick={this.onSetItemProp.bind(this, id, 'view', 'list')} title='List View'>
                                            <SC.Icons.Icon_List_View />
                                        </DocumentToolbarButton>
                                        <DocumentToolbarButton hasFill active={viewMode === 'card'} onClick={this.onSetItemProp.bind(this, id, 'view', 'card')} title='Card View'>
                                            <SC.Icons.Icon_Card_View />
                                        </DocumentToolbarButton>
                                    </React.Fragment>
                                }                            
                                {this.hasCardWidth && getSliderBar(cardSize, 50, 150, 'cardSize', 'Card Size')}
                                {optionsBar}
                            </SC.FRow>
                        );
                    }
                    
                }
            }
            else if (data.type === DOCUMENT_ITEMTYPES.component) {
                if (this.state.showComponentSelector) {
                    result.itemEditor = (
                        <SelectDocumentComponent 
                            {...this.props}
                            onClose={() => this.setState({showComponentSelector : false})}                        
                            onUpdate={() => {
                                this.state.showComponentSelector = false;
                                this.Reload();
                            }}
                        />
                    )
                }
                else {
                    result.itemEditor = (
                        <ItemEditor_Component 
                            {...result.props}
                            onSelectComponent={() => this.setState({showComponentSelector : true})}
                        />
                    )
                    if (result.showToolbar) {
                        const alignment = Utils.JustGet(data.data, null, 'alignment');
                        result.itemToolbar = (
                            <SC.FRow style={{padding : '4px', width : '100%'}}>
                                {getVisibleBar()}
                                {getAlignmentBar(alignment)}
                                <DocumentToolbarSeperator style={{alignSelf : 'stretch', height : 'unset', marginLeft : '4px', marginRight : '4px'}} />
                                <DocumentToolbarButton onClick={() => this.setState({showComponentSelector : true})}>
                                    <div style={{paddingLeft : '4px', paddingRight : '4px'}}>Select Component</div>
                                </DocumentToolbarButton>
                                <DocumentToolbarSeperator style={{alignSelf : 'stretch', height : 'unset', marginLeft : '4px', marginRight : '4px'}} />
                                <DocumentToolbarButton onClick={this.props.onItemSettings} >
                                    <div style={{paddingLeft : '4px', paddingRight : '4px'}}>Options</div>
                                </DocumentToolbarButton>
                            </SC.FRow>
                        );
                    }
                }            
            }
            else if (data.type === DOCUMENT_ITEMTYPES.figmaComponent) {
                if (this.state.showFigmaComponentSelector) {
                    result.itemEditor = (
                        <SelectDocumentFigmaComponent 
                            {...this.props}
                            forSelect
                            onClose={() => this.setState({showFigmaComponentSelector : false})}                        
                            onUpdate={() => {
                                this.state.showFigmaComponentSelector = false;
                                this.Reload();
                            }}
                        />
                    )
                }
                else {
                    result.itemEditor = (
                        <ItemEditor_FigmaComponent 
                            {...result.props}
                            onSelectComponent={() => this.setState({showFigmaComponentSelector : true})}
                        />
                    )
                    if (result.showToolbar) {
                        const alignment = Utils.JustGet(data.data, null, 'alignment');
                        result.itemToolbar = (
                            <SC.FRow style={{padding : '4px', width : '100%'}}>
                                {getVisibleBar()}
                                {getAlignmentBar(alignment)}
                                <DocumentToolbarSeperator style={{alignSelf : 'stretch', height : 'unset', marginLeft : '4px', marginRight : '4px'}} />
                                <DocumentToolbarButton onClick={() => this.setState({showFigmaComponentSelector : true})}>
                                    <div style={{paddingLeft : '4px', paddingRight : '4px'}}>Select Component</div>
                                </DocumentToolbarButton>
                                <DocumentToolbarSeperator style={{alignSelf : 'stretch', height : 'unset', marginLeft : '4px', marginRight : '4px'}} />
                                <DocumentToolbarButton onClick={this.props.onItemSettings} >
                                    <div style={{paddingLeft : '4px', paddingRight : '4px'}}>Options</div>
                                </DocumentToolbarButton>
                            </SC.FRow>
                        );
                    }
                }            
            }
            else if (data.type === DOCUMENT_ITEMTYPES.divider) {
                result.itemEditor = (
                    <hr style={{
                        borderTop : '1px solid ' + this.props.textStyles.dividerColor,
                        borderColor : this.props.textStyles.dividerColor,
                        width : '100%'
                    }}/>
                )
            }
            else if (data.type === DOCUMENT_ITEMTYPES.space) {
                this.marginBottom = 0;
                const size = Utils.JustGet(data.data, 0, 'size');
                if (this.props.preview) {
                    if (size > 0) {
                        result.itemEditor = (
                            <div style={{minHeight : Utils.px(size)}} />
                        );
                    }                
                }
                else {
                    result.itemEditor = (
                        <ItemBottomMargin 
                            onChangeSpace={(bottom) => {
                                this.onSetItemProp(id, 'size', bottom);
                            }} 
                            bottom={size} 
                            visible={this.props.context.id === id}
                        />
                    );
                }            
            }
            else if (data.type === DOCUMENT_ITEMTYPES.accessibility) {
                const size = Utils.JustGet(data.data, 100, 'size');
                const alignment = Utils.JustGet(data.data, 'flex-start', 'alignment');
                const backgroundColorId = Utils.JustGet(data.data, null, 'background');
                const textColorId = Utils.JustGet(data.data, null, 'text');
                let backgroundColor, textColor;
                if (backgroundColorId) {
                    backgroundColor = Globals.ProjectManager.Tokens.ValueOfId(backgroundColorId);
                }
                if (textColorId) {
                    textColor = Globals.ProjectManager.Tokens.ValueOfId(textColorId);
                }
    
                const item_back = {label : 'Background Color', name : 'background', color : backgroundColor,  tokenId : backgroundColorId};
                const item_text = {label : 'Text Color', name : 'text', color : textColor, tokenId : textColorId, style : {marginLeft : '8px'}};
    
                result.itemEditor = (
                    <ItemEditor_Accessbility 
                        {...result.props}
                        backgroundColorId={backgroundColorId}
                        textColorId={textColorId}
                        alignment={alignment}
                        size={alignment !== 'fullwidth' && alignment !== 'stretch' ? size : null}
                        onSelectBackColor={this.SelectAccessibilityColor.bind(this, id, item_back.name, item_back.label, item_back.tokenId)}
                        onSelectTextColor={this.SelectAccessibilityColor.bind(this, id, item_text.name, item_text.label, item_text.tokenId, true, backgroundColor)}
                    />
                );
    
                if (result.showToolbar) {          
                    
                    result.itemToolbar = (
                        <SC.FRow alc style={{padding : '4px'}}>
                            {getVisibleBar()}
                            {getAlignmentBar(alignment, false)}
                            {
                                alignment !== 'fullwidth' && alignment !== 'stretch' && getSliderBar(size, 10, 100, 'size', 'W')                                
                            }
                            <DocumentToolbarSeperator style={{alignSelf : 'stretch', height : 'unset', marginLeft : '4px', marginRight : '4px'}} />
                            {
                                [item_back, item_text].map((item) => {
                                    return (
                                        <DocumentToolbarButton style={item.style} onClick={this.SelectAccessibilityColor.bind(this, id, item.name, item.label, item.tokenId, item.name === 'text', backgroundColor)}>
                                            <div>{item.label}</div>
                                            <SC.GridBackground 
                                                small 
                                                dark={!SC.CurrentTheme.theme.isLight} 
                                                style={{
                                                    // height : '100%', 
                                                    boxSizing : 'border-box',
                                                    borderRadius : '2px', 
                                                    overflow : 'hidden', 
                                                    cursor : 'pointer',                        
                                                    border : SC.CurrentTheme.theme.border_seperator,
                                                    borderWidth : '2px',
                                                    minWidth : '28px',
                                                    marginLeft : '8px',
                                                }}
                                            >
                                                <div 
                                                    onMouseDown={this.ShowColorPicker} 
                                                    style={{backgroundColor : item.color || '#fff', minWidth : '28px', minHeight : '20px', height : '100%', borderRadius : '2px'}} 
                                                />
                                            </SC.GridBackground>
                                        </DocumentToolbarButton>
                                    )
                                })
                            }                                                 
                        </SC.FRow>
                    );
                }     
            }
            else if (data.type === DOCUMENT_ITEMTYPES.tab) {
                if (this.props.index === 0)
                    result.marginBottom = '-16px';
    
                const alignment = Utils.JustGet(data.data, 'stretch', 'alignment');
    
                result.itemEditor = (
                    <ItemEditor_Carousel 
                        {...result.props}
                        key={this.RenderId}
                        autoFocus={result.props.selected || this.props.index === 0}
                        ItemProps={this.props.ItemProps}
                        pageWidth={this.props.width}
                    />
                )
    
                if (alignment === 'fullwidth') {
                    result.fullWidthItem = result.itemEditor;
                    result.itemEditor = null;
                }
                if (result.showToolbar) {                
                    const size = Utils.JustGet(data.data, 100, 'size');
                    result.itemToolbar = (
                        <SC.FRow alc style={{padding : '4px', width : '100%'}}>
                            {getVisibleBar()}
                            {getAlignmentBar(alignment, true)}
                            {
                                alignment !== 'fullwidth' && alignment !== 'stretch' && getSliderBar(size, 10, 100, 'size', 'W')                                
                            }
                            <DocumentToolbarSeperator style={{alignSelf : 'stretch', height : 'unset', marginLeft : '8px', marginRight : '4px'}} />
                            <DocumentToolbarButton onClick={this.props.onItemSettings}>
                                <div style={{paddingLeft : '4px', paddingRight : '4px'}}>Editor</div>
                            </DocumentToolbarButton>
                        </SC.FRow>
                    );
                }  
            }
            else if (data.type === DOCUMENT_ITEMTYPES.layout) {
                if (this.state.addItemId) {
                    result.itemEditor = (
                        <ItemEditor_Wizard 
                            id={this.state.addItemId}
                            active
                            forLayout
                            onUpdate={this.Reload}
                            onNewItemAdded={this.NewItemAdded.bind(this, true)}
                            GlobalState={this.props.GlobalState}
                            GlobalStateId={this.props.GlobalStateId}
                            textStyles={this.props.textStyles}                        
                            width={'100%'}
                            onCancel={this.DeleteSubItem.bind(this, this.state.addItemId, this.state.subItemIndex)}
                            ItemProps={this.props.ItemProps}
                        />
                    )
                }
                else if (this.state.showImageSelector) {
                    result.itemEditor = (
                        <SelectDocumentImage
                            {...this.props}
                            id={this.subItemId}
                            onClose={() => this.setState({showImageSelector : false})}                        
                            onUpdate={() => {
                                this.state.showImageSelector = false;
                                this.Reload();
                            }}
                        />
                    )
                }
                else if (this.state.showComponentSelector) {
                    result.itemEditor = (
                        <SelectDocumentComponent 
                            {...this.props}
                            id={this.subItemId}
                            onClose={() => this.setState({showComponentSelector : false})}                        
                            onUpdate={() => {
                                this.state.showComponentSelector = false;
                                this.Reload();
                            }}
                        />
                    )
                }
                else if (this.state.showFigmaComponentSelector) {
                    result.itemEditor = (
                        <SelectDocumentFigmaComponent 
                            {...this.props}
                            id={this.subItemId}
                            onClose={() => this.setState({showFigmaComponentSelector : false})}                        
                            onUpdate={() => {
                                this.state.showFigmaComponentSelector = false;
                                this.Reload();
                            }}
                        />
                    )
                }
                else {
                    if (!this.Ref_LeftItem)
                        this.Ref_LeftItem = React.createRef();
                    if (!this.Ref_RightItem)
                        this.Ref_RightItem = React.createRef();
                    const item1 = Globals.ProjectManager.GetDocumentManager().GetPageItemValue(this.props.id, this.props.pageId, null, 'item1');
                    const item2 = Globals.ProjectManager.GetDocumentManager().GetPageItemValue(this.props.id, this.props.pageId, null, 'item2');
                    const items = [];
                    
                    result.showToolbar = !this.props.preview && this.props.context.id === this.props.id && !this.state.suspendToolbar;
                    let item1Empty = true;
                    if (item1) {
                        const itemdata = Globals.ProjectManager.GetDocumentManager().GetPageItem(this.props.pageId, item1);
                        if (itemdata.type !== 'layout') {
                            item1Empty = false;
                            const itemEditor = this.renderItem({id : item1, data : itemdata, showToolbar : result.showToolbar && this.subItemId === item1, selected : result.props.selected, props : {
                                layoutItem : {
                                    id : this.props.id
                                }
                            }});
        
                            items.push(
                                <SC.Div_Relative 
                                    onMouseEnter={this.onMouseEnterSubItem.bind(this, item1)}
                                    onMouseLeave={this.onMouseLeaveSubItem.bind(this, item1)}
                                    ref={this.Ref_LeftItem}
                                >
                                    {itemEditor.itemEditor}
                                    {
                                        this.subItemId === item1 && result.showToolbar &&
                                        <DocumentToolbarBox
                                            style={{
                                                position : 'absolute',
                                                top : '8px',
                                                left : '8px'
                                            }}
                                            onClick={this.DeleteSubItem.bind(this, item1, 'item1')}
                                        >
                                            <DocumentToolbarButton hasFill>
                                                <SC.Icons.Icon_Delete />
                                            </DocumentToolbarButton>
                                        </DocumentToolbarBox>
                                    }
                                </SC.Div_Relative>
                            );
                            if (this.subItemId === item1)
                                result.itemToolbar = itemEditor.itemToolbar;
                        }                    
                    }
    
                    if (item1Empty) {
                        if (this.props.preview) {
                            items.push(<div />);
                        }
                        else {
                            items.push(
                                <EmptyCell 
                                    style={{
                                        backgroundColor : this.props.textStyles.backgroundHighlight,
                                    }}
                                    onAdd={this.AddLayoutItem.bind(this, 'item1')} 
                                />
                            )
                        }                    
                    }
                            
                    let item2Empty = true;
                    if (item2) {
                        const itemdata = Globals.ProjectManager.GetDocumentManager().GetPageItem(this.props.pageId, item2);
    
                        if (itemdata.type && itemdata.type !== 'layout') {
                            item2Empty = false;
    
                            const itemEditor = this.renderItem({id : item2, data : itemdata, showToolbar : result.showToolbar && this.subItemId === item2, selected : result.props.selected, props : {
                                layoutItem : {
                                    id : this.props.id,
                                    right : true
                                }
                            }})
        
                            items.push(
                                <SC.Div_Relative 
                                    onMouseEnter={this.onMouseEnterSubItem.bind(this, item2)}
                                    onMouseLeave={this.onMouseLeaveSubItem.bind(this, item2)}                        
                                    ref={this.Ref_RightItem}
                                >
                                    {itemEditor.itemEditor}
                                    {
                                        this.subItemId === item2 && result.showToolbar &&
                                        <DocumentToolbarBox
                                            style={{
                                                position : 'absolute',
                                                top : '8px',
                                                right : '8px'
                                            }}
                                            onClick={this.DeleteSubItem.bind(this, item2, 'item2')}
                                        >
                                            <DocumentToolbarButton hasFill>
                                                <SC.Icons.Icon_Delete />
                                            </DocumentToolbarButton>
                                        </DocumentToolbarBox>
                                    }                            
                                </SC.Div_Relative>
                            );
                            if (this.subItemId === item2) {
                                result.itemToolbar = itemEditor.itemToolbar;                   
                                result.toolbarToRight = true;
                                if (this.LayoutIsVertical) {
                                    result.transformToolbar = this.LayoutIsVertical;
                                }
                            }
                        }                    
                    }
                    if (item2Empty) {
                        if (this.props.preview) {
                            items.push(<div />);
                        }
                        else {
                            items.push(
                                <EmptyCell 
                                    style={{
                                        backgroundColor : this.props.textStyles.backgroundHighlight,
                                    }}
                                    onAdd={this.AddLayoutItem.bind(this, 'item2')} 
                                />
                            )
                        }
                    }
    
                    result.itemEditor = (
                        <div
                            style={{
                                display : 'grid',
                                gridTemplateColumns :'repeat(auto-fill, minmax(max(290px, calc(50% - 24px)), 1fr))',
                                gridGap : '48px',
                                minHeight : '80px',
                                position : 'relative'        
                            }}
                        >
                            {items}
                            {
                                result.showToolbar && 
                                <DocumentToolbarBox
                                    style={{
                                        position : 'absolute',
                                        top : '50%',
                                        left : '50%',
                                        transform : 'translate(-50%, -50%)'
                                    }}
                                    onClick={() => {
                                        this.onSetItemProp(this.props.id, 'item1', item2);
                                        this.onSetItemProp(this.props.id, 'item2', item1);
                                        this.Update();
                                    }}
                                >
                                    <DocumentToolbarButton hasFill>
                                        <SC.Icons.Loop />
                                    </DocumentToolbarButton>
                                </DocumentToolbarBox>
                            }
                        </div>
                    );
                }            
            }
            else if (!this.props.preview) {
                result.isWziard = true;
                result.itemEditor = (
                    <ItemEditor_Wizard 
                        id={id}
                        parentId={this.props.parentId}
                        active
                        onUpdate={this.Reload}
                        onNewItemAdded={this.NewItemAdded.bind(this, false)}
                        GlobalState={this.props.GlobalState}
                        GlobalStateId={this.props.GlobalStateId}
                        textStyles={this.props.textStyles}
                        width={this.props.width}
                        forLayout={result.props.layoutItem}
                        emptyFirst={this.props.last && this.props.index === 0}
                        onCancel={this.Delete}
                        ItemProps={this.props.ItemProps}
                    />
                )
            }
        }

        

        return result;
    }

    render() {
        let marginBottom = 16;
        
        const hidden = Utils.JustGet(this.data, false, 'data', 'hidden');

        if (hidden && this.props.preview) {
            return null;
        }
       
        const renderedItem = this.renderItem({
            id : this.props.id,
            data : this.data
        })

        let toolbarItem = (
            <AnimatePresence>
                {renderedItem.itemToolbar && renderedItem.showToolbar && 
                    <motion.div 
                        style={{                            
                            zIndex : 99999999,                                                        
                            position : 'sticky',
                            top : '48px',
                        }}
                        initial={{x : -20}}
                        animate={{x : 0, y : renderedItem.toolbarToRight && renderedItem.transformToolbar ? renderedItem.transformToolbar : 0}}
                        exit={{x : -20}}
                        transition={{duration : 0.2}}
                        onMouseDown={(e) => e.stopPropagation()}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div
                            ref={this.Ref_Toolbar}
                            style={{
                                border : SC.CurrentTheme.theme.border_popup,
                                borderRadius : '2px',
                                boxSizing : 'border-box',
                                backgroundColor : SC.CurrentTheme.theme.back_lighter,
                                boxShadow : SC.CurrentTheme.theme.popup_shadow,  
                                ...SC.Styles.Font(),
                                ...renderedItem.props.style,
                                fontSize : '11px',
                                display : 'flex',
                                position : 'absolute',
                                left : renderedItem.toolbarToRight && !renderedItem.transformToolbar ? 'unset' :  this.props.toolbarPosition,
                                right : renderedItem.toolbarToRight && !renderedItem.transformToolbar ?  this.props.toolbarPosition : 'unset',
                                bottom : this.props.tabItem ? '100%' : 'calc(100% + 8px)',
                                transform : 'translateX(80px)'
                            }}
                            onMouseEnter={(this.props.preview || renderedItem.isWziard ) ? null : this.onMouseEnter}    
                            onMouseOver={(this.props.preview || renderedItem.isWziard ) ? null : this.onMouseEnter}    
                            onMouseLeave={(this.props.preview || renderedItem.isWziard ) ? null : this.onMouseLeave}                        
                        >       
                            {/* <DocumentToolbarButton hasFill hasCursor 
                                style={{minWidth : '32px', minHeight : '32px', boxSizing : 'border-box'}}
                                onClick={() => this.setState({toolbarExpanded  : !this.state.toolbarExpanded})}
                            >
                                {
                                    this.state.toolbarExpanded ? <SC.Icons.Icon_Close size={20}/> : <SC.Icons.Icon_Settings />                                            
                                }                                            
                            </DocumentToolbarButton>                                  */}
                            
                            {renderedItem.itemToolbar}
                        </div>                                            
                    </motion.div>				
            }
            </AnimatePresence> 
        )
        return (            
            <AnimatePresence>
                {
                    !this.state.willDelete && 
                    <div
                        style={{
                            position : (this.props.layoutItem) ? 'unset' : 'relative',
                            marginBottom : '8px',   
                            pointerEvents : this.props.forTemplateSelect ? 'none' : 'all'
                        }}
                        onMouseEnter={(this.props.preview || renderedItem.isWziard ) ? null : this.onMouseEnter}    
                        onMouseOver={(this.props.preview || renderedItem.isWziard ) ? null : this.onMouseEnter}    
                        onMouseLeave={(this.props.preview || renderedItem.isWziard ) ? null : this.onMouseLeave}                                                
                    >
                        {toolbarItem}
                        {
                            (!this.props.preview && !renderedItem.isWziard && this.props.context.id === this.props.id) && 
                            <Widget
                                dragProps={this.props.sortableProps.handleProps}
                                parentId={this.props.parentId}
                                onAddItem={this.props.onAddItem}
                                onAddItemBefore={this.props.onAddItemBefore}
                                onCloneItemBefore={this.props.onCloneItemBefore}
                                onCloneItemAfter={this.props.onCloneItemAfter}
                                onItemSettings={this.props.onItemSettings}
                                onDelete={this.Delete}
                                context={this.props.context}
                                index={this.props.index}
                                last={this.props.last}
                                onMoveUp={this.props.onMoveUp}
                                onMoveDown={this.props.onMoveDown}
                                width={this.props.width}
                                tabItem={this.props.tabItem}
                                left={this.props.toolbarPosition}
                                wizard={renderedItem.isWziard ? {
                                    left : this.props.toolbarPosition
                                } : false}
                            />                        
                        }
                        <motion.div  
                            style={{
                                position : (this.props.layoutItem) ? 'unset' : 'relative',
                                display : 'grid',
                                gridTemplateColumns : this.props.preview ? '1fr' : '1fr', //Utils.px(this.props.width, '%'),
                                paddingLeft : this.props.tabItem ? 0 : (this.props.mobile) ? '20px' : '40px', 
                                paddingRight : (this.props.mobile || this.props.tabItem) ? '20px' : '40px',
                                justifyContent : 'center',
                                alignContent : 'start',
                                color : this.props.textStyles.paragraph.color,
                                fontWeight : this.props.textStyles.paragraph.fontWeight,
                            }}
                            exit={{opacity : 0, x : 48}}
                            transition={{duration : 0.2}}                        
                        >
                            <SC.Div_Relative 
                                style={{
                                    maxWidth : renderedItem.isWziard ? '100%' : this.props.width,
                                    width : '100%', marginLeft : 'auto', marginRight : 'auto', cursor : 'default', position : (this.props.layoutItem) ? 'unset' : 'relative',
                                    paddingLeft : '16px',
                                    boxSizing : 'border-box',
                                    borderLeft : !this.props.preview &&this.props.context.id === this.props.id ? SC.CurrentTheme.theme.border_seperator : '1px solid transparent',
                                }} 
                                onMouseDown={(e) => e.stopPropagation()}
                                title=''
                            >
                                
                                <DocumentItemBox
                                    selected={renderedItem.props.selected}  
                                    onClick={(this.props.preview || renderedItem.isWziard ) ? null : this.onMouseEnterForced}                                      
                                    // onMouseMove={renderedItem.props.selected ? this.onMouseMove : null}
                                    // onMouseOver={renderedItem.props.selected ? this.onMouseMove : null}
                                    style={{
                                        marginBottom : (!this.props.preview && renderedItem.props.selected && !renderedItem.isWziard || this.props.forTemplateSelect) ? 0 : Utils.px(this.marginBottom),
                                        ...this.props.style,
                                        opacity : (hidden && this.data.type !== DOCUMENT_ITEMTYPES.text) ? 0.4 : 1
                                    }}
                                >
                                    {renderedItem.itemEditor}
                                    {
                                        !this.props.preview && renderedItem.props.selected && this.data.type !== DOCUMENT_ITEMTYPES.space && !renderedItem.isWziard && 
                                        <ItemBottomMargin 
                                            onChangeSpace={(bottom) => {
                                                this.onSetItemProp(this.props.id, 'bottom', bottom);
                                                this.marginBottom = bottom;
                                                this.setState({ShouldUpdate : true});
                                            }} 
                                            bottom={this.marginBottom} 
                                            visible
                                        />
                                    }
                                    
                                </DocumentItemBox>                                                                                           
                            </SC.Div_Relative>                            
                        </motion.div >
                        {renderedItem.fullWidthItem}                        
                    </div>
                }                
            </AnimatePresence>
                              
        )
    }
}

const EmptyCell = (props) => {
    return (
        <DocumentToolbarButton onMouseDown={props.onAdd} style={{padding : 0, alignSelf : 'stretch'}}>
            <SC.Icons.Icon_Button hasFill style={{cursor : 'pointer'}}>
                <SC.Icons.Icon_Plus size={32} />
            </SC.Icons.Icon_Button>
        </DocumentToolbarButton>
    )   
}

const DocumentItemBox = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    outline : ${props => props.selected ? '1px transparent': '1px transparent'};
    outline-style : solid;
    outline-offset : 4px;
    transition : 0.2s ease;
    transition-property : outline;    
    &:hover {
        // outline : ${props => props.selected ? '1px transparent' : props.theme.border_brand};
        outline-style : dashed;
    }
`;