import Utils from '../../../../toolabs-utils';

export default (MetaItem, GenericType, Name) => {
    const RootId = Utils.Id();
    MetaItem.name = Name;
    
    const Component = {            
        RootItem : {
            Id : RootId
        },
        MetaItems : {},
        PreviewSize : {
            width : 32,
            height : 32
        },
        Default: {
            'Designboard': {
                'type': 'grid'
            },
            'height': {
                'value': 32
            },                
            'width': {
                'value': 32
            }
        }
            
    };
    Component.MetaItems[RootId] = MetaItem;

    Component.PseudoStates = {
        Order : ['Checked', 'System', 'disabled', 'hover'],        
        System : {
            name : 'System State',
            Variations : {
                Order : ['active', 'focus'],
                active : {
                    name : 'Active',
                    pseudo : true
                },
                focus : {
                    name : 'Focused',
                    pseudo : true
                },                
            }
        },
        Checked : {
            SingleVariation : true,
            name : 'Checked',
            previewable : true
        },
        disabled : {
            SingleVariation : true,
            name : 'Disabled',
            previewable : true,
        },
        hover : {
            SingleVariation : true,
            name : 'Hover',
            pseudo : true
        }
    };

    Component.Handlers = {
        
    };

    Component.Models = {
        List : {
            'Checked': {
                dataType: 'boolean',
                name: 'Checked',
                value : false,
                stateBinding : {
                    variations: {
                        Checked : 'Checked'
                    }
                }
            }
        },
        Order: ['Checked']
    };

    MetaItem.Events = [ 
        
    ];
    
    Utils.MergeTo(MetaItem, {
        Generic : {
            Type : GenericType
        },
        Models : {
            Default : {
                Default : {
                    value: {
                        Id: 'Checked'
                    },
                }
            }
        },
        Property : {
            Default: {
                Checked: {
                    GenericOptions: {
                        checkFill: {
                            value: 'rgba(110, 110, 110, 1)'
                        },
                        checkStrokeWidth: {
                            value: 2
                        },
                        checkOpacity : {
                            value : 100
                        }
                    }
                },
                active: {
                    borderColor: {
                        value: 'rgba(68, 137, 220, 1)'
                    }
                },
                disabled: {
                    borderColor: {
                        value: 'rgba(209, 209, 209, 1)'
                    }
                },
                Default: {
                    GenericOptions: {
                        checkFill: {
                            value: 'rgba(110, 110, 110, 0)'
                        },
                        checkSize: {
                            value: 100
                        },
                        checkOpacity : {
                            value : 0
                        }
                    },
                    value: {
                        ModelId: 'Checked'
                    },
                    fixedHeight : {
                        value : true
                    },
                    fixedWidth : {
                        value : true
                    },
                    backgroundColor: {
                        value: 'rgba(245, 245, 245, 1)'
                    },
                    borderColor: {
                        value: 'rgba(110, 110, 110, 1)'
                    },
                    borderRadius: {
                        value: 4
                    },
                    borderStyle: {
                        value: 'solid'
                    },
                    borderWidth: {
                        value: 2
                    },
                    height: {
                        value: 32
                    },
                    width: {
                        value: 32
                    }
                },
                'disabled,Checked': {
                    GenericOptions: {
                        checkFill: {
                            value: 'rgba(209, 209, 209, 1)'
                        }
                    }
                }
            }
        }
    });

    return Component;
}