import React from 'react';
import { Utils } from '../toolabs-importer';
import styled, { css} from 'styled-components';
import {CurrentTheme} from './index';

const TransitionHoverOn = css`
    transition : all 0.2s ease;
`;
const TransitionHoverOff = css`
    transition : all 0.4s ease;
`;

export const svgns = "http://www.w3.org/2000/svg";

export const Svg = ({ children, ...rest }) => {
    return (
        <svg xmlns={svgns} viewBox="0 0 24 24" {...rest}>
            {children}
        </svg>
    )
};

const card_icon_fill = (props) => props.error ? CurrentTheme.theme.card_input_error_border_color : (props.empty ? "#999999" : props.color || "#E4E4E4");

const Svg_g = styled.g`
    fill : ${props => props.fill};
    &:hover {
        fill : ${props => props.hoverFill}
    }
`;

const Card_Icon_Button = styled.div `
    padding : 5px;
    display : flex;
    align-items : center;
    justify-content : center;
    cursor : ${props => props.disabled ? 'unset' : 'pointer'};
    fill : ${props => props.disabled || props.selected || props.fill};
    &:hover {
        fill : ${props => props.disabled || props.hoverFill};
    }
`;
const Card_Icon = styled.svg`
    fill : ${props => props.selected ? (props.hoverColor || props.theme.icon) : (props.fill || '#8B8B8B')};
    ${TransitionHoverOff}
    ${Card_Icon_Button}:hover &{
        fill : ${props => props.hoverColor || props.theme.icon};
        transform : scale(1.2);
        ${TransitionHoverOn}
    }
`
const Card_Icon_Stroke = styled.svg`
    fill : none;
    stroke : #8B8B8B;
    ${TransitionHoverOff}
    ${Card_Icon_Button}:hover &{
        stroke : ${props => props.hoverColor || props.theme.icon};
        transform : scale(1.2);
        ${TransitionHoverOn}
    }
`;

const Icon_Button = styled.div`
    display : flex;
    flex-direction : column;
    justify-content : center;
    align-items : center;
    min-width : 24px;
    min-height : 24px;
    cursor : ${props => props.hasCursor ? 'pointer' : 'default'};
    fill : ${props => props.hasFill ? (props.disabled || props.selected || props.fill || (props.light ? props.theme.icon_light : props.theme.icon) ) : 'none'};
    stroke : ${props => props.hasStroke ? (props.disabled || props.selected || props.stroke || props.fill || props.theme.icon) : 'none'};
    &:hover {
        fill : ${props => props.hasFill ? (props.hoverFill || props.theme.icon_hover || '#AEAEAE') : 'none'};
        stroke : ${props => props.hasStroke ? (props.hoverStroke|| props.theme.icon_hover || '#AEAEAE') : 'none'};
        color : ${props => props.theme.font_hover};
    }
`;

const Icon_ButtonBox = styled.div`
    display : flex;
    justify-content : center;
    align-items : center;
    cursor : ${props => props.hasCursor ? 'pointer' : 'default'};
    fill : ${props => props.hasFill ? (props.disabled || props.selected || props.fill || (props.light ? props.theme.icon_light : props.theme.icon) ) : 'none'};
    stroke : ${props => props.hasStroke ? (props.disabled || props.selected || props.fill || props.theme.icon) : 'none'};
    background-color : rgba(0,0,0,0);
    min-width : 24px;
    min-height : 24px;
    border-radius : 2px;    
    &:hover {
        fill : ${props => props.hasFill ? (props.hoverFill || props.theme.icon_hover || '#AEAEAE') : 'none'};
        stroke : ${props => props.hasStroke ? (props.hoverStroke|| props.theme.icon_hover || '#AEAEAE') : 'none'};
        background-color : ${props => props.dark ? props.theme.back : props.theme.back_lighter};
    }
`

export const Icons = {
    Icon_Box: styled.div `
        width : 30px;
        display : flex;
        align-items : center;
        justify-content : center;
        cursor : pointer;
        ${
        props => {
            if (!props.noSeperator)
                return css`border-left : 1px solid #343638;`;
        }
        }
    `,
    Icon_Button : Icon_Button,
    Icon_ButtonBox : Icon_ButtonBox,

    Icon_Form_Cancel: (props) => {
        return (
            <Svg width={props.size || 24} >
            <path d="M12.707 12l2.147 2.146a.5.5 0 0 1-.708.708L12 12.707l-2.146 2.147a.5.5 0 0 1-.708-.708L11.293 12 9.146 9.854a.5.5 0 1 1 .708-.708L12 11.293l2.146-2.147a.5.5 0 0 1 .708.708L12.707 12zM3 5.5A2.5 2.5 0 0 1 5.5 3h13A2.5 2.5 0 0 1 21 5.5v13a2.5 2.5 0 0 1-2.5 2.5h-13A2.5 2.5 0 0 1 3 18.5v-13zm1 0v13A1.5 1.5 0 0 0 5.5 20h13a1.5 1.5 0 0 0 1.5-1.5v-13A1.5 1.5 0 0 0 18.5 4h-13A1.5 1.5 0 0 0 4 5.5z"/>
            </Svg>
        )
    },
    Icon_Form_Submit: (props) => {
        return (
            <Svg width={props.size || 24} >
                <path d="M5.5 3h13A2.5 2.5 0 0 1 21 5.5v13a2.5 2.5 0 0 1-2.5 2.5h-13A2.5 2.5 0 0 1 3 18.5v-13A2.5 2.5 0 0 1 5.5 3zm0 1A1.5 1.5 0 0 0 4 5.5v13A1.5 1.5 0 0 0 5.5 20h13a1.5 1.5 0 0 0 1.5-1.5v-13A1.5 1.5 0 0 0 18.5 4h-13zm9.646 5.146a.5.5 0 0 1 .708.708l-5 5a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708l1.646 1.647 4.646-4.647z"/>
            </Svg>
        )
    },
    Icon_Close: (props) => {
        return (
            <Svg width={props.size || 25} viewBox="0 0 25 25">
                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
            </Svg>
        )
    },
    Icon_Sort_AZ: (props) => {
        if (props.SortUp) {
            return (
                <Svg width={24} >
                    <path fill={props.selected ? CurrentTheme.theme.color_brand : CurrentTheme.theme.icon} d="M8 5.7v13.8a.5.5 0 1 1-1 0V5.7L4.85 7.86a.5.5 0 1 1-.7-.7l3-3c.2-.2.5-.2.7 0l3 3a.5.5 0 0 1-.7.7L8 5.71zm10.32 10.8A2 2 0 0 1 17 20h-3.5a.5.5 0 0 1-.5-.5v-6c0-.28.22-.5.5-.5H17a2 2 0 0 1 1.32 3.5zM17.33 9h-2.66l-.7 1.7a.5.5 0 0 1-.93-.4l2.5-6a.5.5 0 0 1 .92 0l2.5 6a.5.5 0 0 1-.92.4l-.7-1.7zm-.41-1L16 5.8 15.08 8h1.84zM14 17v2h3a1 1 0 0 0 0-2h-3zm0-1h3a1 1 0 0 0 0-2h-3v2z"/>
                </Svg>
            )
        }
        return (
            <Svg width={24} >
                <path fill={props.selected ? CurrentTheme.theme.color_brand : CurrentTheme.theme.icon} d="M7.9 19.8a.5.5 0 0 1-.8 0l-2.95-2.95a.5.5 0 0 1 .7-.7L7 18.29V4.5a.5.5 0 0 1 1 0v13.8l2.15-2.15a.5.5 0 0 1 .7.7L7.9 19.8zm10.42-3.3A2 2 0 0 1 17 20h-3.5a.5.5 0 0 1-.5-.5v-6c0-.28.22-.5.5-.5H17a2 2 0 0 1 1.32 3.5zM17.33 9h-2.66l-.7 1.7a.5.5 0 0 1-.93-.4l2.5-6a.5.5 0 0 1 .92 0l2.5 6a.5.5 0 0 1-.92.4l-.7-1.7zm-.41-1L16 5.8 15.08 8h1.84zM14 17v2h3a1 1 0 0 0 0-2h-3zm0-1h3a1 1 0 0 0 0-2h-3v2z"/>
            </Svg>
        )        
    },
    Icon_Search : (props) => {
        return (
            <Svg width={props.size || 25} height={props.size || 25} viewBox="0 0 25 25" style={props.style}>
                <path d="M24.806 23.534L19.72 18.49c3.793-4.395 3.577-11.033-.604-15.214C16.96 1.078 14.073 0 11.228 0c-2.844 0-5.732 1.078-7.93 3.276-4.397 4.396-4.397 11.465 0 15.862 2.155 2.155 5.043 3.276 7.93 3.276 2.587 0 5.216-.905 7.285-2.716l5.086 5.086c.12.13.34.216.56.216.21 0 .43-.086.6-.26.34-.3.34-.86.04-1.206zM4.504 17.888c-1.81-1.767-2.758-4.18-2.758-6.68s.99-4.915 2.758-6.725c1.81-1.81 4.18-2.76 6.724-2.76 2.544 0 4.914.993 6.725 2.76 1.767 1.81 2.758 4.18 2.758 6.724 0 2.543-.99 4.914-2.75 6.724-1.81 1.77-4.224 2.76-6.724 2.76s-4.913-.99-6.724-2.8z" />
            </Svg>
        )
    },
    Icon_Refresh : (props) => {
        return (
            <Svg width={props.size || 24}  style={props.style}>
                <path d="M17 18.246a8 8 0 00-4.505-14.23.5.5 0 11.061-1C17.29 3.307 21 7.237 21 12a8.986 8.986 0 01-3.342 7H20.5a.5.5 0 110 1h-4a.5.5 0 01-.5-.5v-4a.5.5 0 111 0v2.746zM7 5.754a8 8 0 004.54 14.233.5.5 0 01-.056.998C6.734 20.717 3 16.778 3 12a8.986 8.986 0 013.342-7H3.5a.5.5 0 010-1h4a.5.5 0 01.5.5v4a.5.5 0 01-1 0V5.754z"/>
            </Svg>
        )
    },
    Icon_Canvas_Focus : (props) => {
        return (            
            <Svg  width={props.size || 24}>
                <path d="M21.987 13A9.5 9.5 0 0 1 13 21.987V23.5a.5.5 0 1 1-1 0v-1.513A9.5 9.5 0 0 1 3.013 13H1.5a.5.5 0 1 1 0-1h1.513A9.5 9.5 0 0 1 12 3.013V1.5a.5.5 0 1 1 1 0v1.513A9.5 9.5 0 0 1 21.987 12H23.5a.5.5 0 1 1 0 1h-1.513zM12.5 21a8.5 8.5 0 1 0 0-17 8.5 8.5 0 0 0 0 17zm0-4a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9zm0-1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
                {props.selected && <circle cx="12.5" cy="12.5" r="4.5" fill="#19BEF2"/>}
            </Svg>
        )
    },
    Icon_Canvas_Fullscreen : (props) => {
        return (            
            <Svg  width={props.size || 24}>
                {!props.expanded && <path d="M4.707 20H7.5a.5.5 0 110 1h-4a.5.5 0 01-.5-.5v-4a.5.5 0 111 0v2.793l5.146-5.147a.5.5 0 01.708.708L4.707 20zM19.293 4H16.5a.5.5 0 110-1h4a.5.5 0 01.5.5v4.031a.5.5 0 11-1 0V4.707l-5.146 5.147a.5.5 0 01-.708-.708L19.293 4z"/>}{!props.expanded && <path d="M4.707 20H7.5a.5.5 0 110 1h-4a.5.5 0 01-.5-.5v-4a.5.5 0 111 0v2.793l5.146-5.147a.5.5 0 01.708.708L4.707 20zM19.293 4H16.5a.5.5 0 110-1h4a.5.5 0 01.5.5v4.031a.5.5 0 11-1 0V4.707l-5.146 5.147a.5.5 0 01-.708-.708L19.293 4z"/>}
                {props.expanded && <path d="M8.293 15H3.5a.5.5 0 110-1h6a.5.5 0 01.5.5v6a.5.5 0 11-1 0v-4.793l-5.146 5.147a.5.5 0 01-.708-.708L8.293 15zm7.414-6H20.5a.5.5 0 110 1h-6a.5.5 0 01-.5-.5v-6a.5.5 0 111 0v4.793l5.146-5.147a.5.5 0 01.708.708L15.707 9z"/>}
            </Svg>
        )
    },
    Icon_TagFilter: (props) => {
        return (
            <Svg width={props.size || 24} >
                <path d="M15,12.7071068 L15,18.5 C15,18.7044523 14.8755245,18.8883067 14.6856953,18.9642383 L9.68569534,20.9642383 C9.35726314,21.0956112 9,20.8537323 9,20.5 L9,12.7071068 L3.14644661,6.85355339 C3.05267842,6.7597852 3,6.63260824 3,6.5 L3,3.5 C3,3.22385763 3.22385763,3 3.5,3 L20.5,3 C20.7761424,3 21,3.22385763 21,3.5 L21,6.5 C21,6.63260824 20.9473216,6.7597852 20.8535534,6.85355339 L15,12.7071068 Z M14,13 L10,13 L10,19.7614835 L14,18.1614835 L14,13 Z M4,4 L4,6.29289322 L9.70710678,12 L14.2928932,12 L20,6.29289322 L20,4 L4,4 Z"/>
            </Svg>
        )
    },
    Icon_Arrow: (props) => {
        return (
            <Svg width={props.size || 12} viewBox="0 0 13 8" style={{transform : `rotate(${props.expanded ? 180 : 0}deg)`}}>
                {
                    <path  d="M11.48.34l1.18 1.2-6 6.12-6-6.12L1.84.34l4.82 4.8" />
                }
            </Svg>
        )
    },
    Icon_ScrollArrow: (props) => {
        return (
            <Svg width={props.size || 24}  style={props.style}>
                <path d="M12,6.70710678 L8.85355339,9.85355339 C8.65829124,10.0488155 8.34170876,10.0488155 8.14644661,9.85355339 C7.95118446,9.65829124 7.95118446,9.34170876 8.14644661,9.14644661 L12.1464466,5.14644661 C12.3417088,4.95118446 12.6582912,4.95118446 12.8535534,5.14644661 L16.8535534,9.14644661 C17.0488155,9.34170876 17.0488155,9.65829124 16.8535534,9.85355339 C16.6582912,10.0488155 16.3417088,10.0488155 16.1464466,9.85355339 L13,6.70710678 L13,17.2928932 L16.1464466,14.1464466 C16.3417088,13.9511845 16.6582912,13.9511845 16.8535534,14.1464466 C17.0488155,14.3417088 17.0488155,14.6582912 16.8535534,14.8535534 L12.8535534,18.8535534 C12.6582912,19.0488155 12.3417088,19.0488155 12.1464466,18.8535534 L8.14644661,14.8535534 C7.95118446,14.6582912 7.95118446,14.3417088 8.14644661,14.1464466 C8.34170876,13.9511845 8.65829124,13.9511845 8.85355339,14.1464466 L12,17.2928932 L12,6.70710678 Z M5.5,21 C5.22385763,21 5,20.7761424 5,20.5 C5,20.2238576 5.22385763,20 5.5,20 L19.5,20 C19.7761424,20 20,20.2238576 20,20.5 C20,20.7761424 19.7761424,21 19.5,21 L5.5,21 Z M5.5,4 C5.22385763,4 5,3.77614237 5,3.5 C5,3.22385763 5.22385763,3 5.5,3 L19.5,3 C19.7761424,3 20,3.22385763 20,3.5 C20,3.77614237 19.7761424,4 19.5,4 L5.5,4 Z"/>
            </Svg>
        )
    },
    Loop: (props) => {
        return (
            <Svg width={props.size || 24}  style={props.style}>
                 <path d="M4.707 18l2.147 2.146a.5.5 0 01-.708.708l-3-3a.5.5 0 010-.708l3-3a.5.5 0 01.708.708L4.707 17H18.5a1.5 1.5 0 001.5-1.5v-3a.5.5 0 111 0v3a2.5 2.5 0 01-2.5 2.5H4.707zM19.293 7l-2.147-2.146a.5.5 0 01.708-.708l3 3a.5.5 0 010 .708l-3 3a.5.5 0 01-.708-.708L19.293 8H5.5A1.5 1.5 0 004 9.5v3a.5.5 0 11-1 0v-3A2.5 2.5 0 015.5 7h13.793z"/>
            </Svg>
        )
    },
    Icon_Note: (props) => {
        return (
            <Svg width="20" height="19" viewBox="0 0 20 19">
                <g fill="#808081" fillRule="evenodd">
                    <path d="M14.84 18.26l-5.72-.9.3-.6 4.72.73.16-1.1.82.1-.27 1.74zM.1 15.93H.04L2.2 2.23l.82.14 6.6 1.04-.2.8-6.52-1L.95 15.4l4.2.74-.03.74L.08 16l.02-.07z" />
                    <path d="M18.98 15.52l-13.26 1.4L4.44 4.77l9.2-1.06.16-.73L4.36 4v-.02l-.83.1 1.44 13.75.83-.1 13.26-1.38.83-.1L18.42 2.5l-.83.1-.6.05-.72.9 1.4-.16 1.28 12.1z" />
                    <path fillRule="nonzero" d="M13.8 3.95c-.35-.33-.58-.8-.64-1.28C13.02 1.5 13.84.45 15 .3c1.15-.13 2.2.7 2.34 1.85.14 1.17-.68 2.22-1.83 2.36-.5.1-.9.05-1.24-.2l-1.73 2.2-.07.1-.43-.3 1.8-2.33zm1.63-.04c.82-.1 1.4-.83 1.3-1.65C16.63 1.4 15.9.8 15.07.9c-.82.1-1.4.87-1.3 1.7.1.82.85 1.4 1.67 1.3z" />
                </g>
            </Svg>
        )
    },
    Icon_Delete: (props) => {
        return (
            <Svg width={props.size ? (props.size - 5) : 12} viewBox="0 0 12 16" style={props.style} >
                <path
                    fillOpacity="0.8"
                    fillRule="evenodd"
                    d="M5 1.5a1 1 0 00-1 1h4a1 1 0 00-1-1H5zm4 1a2 2 0 00-2-2H5a2 2 0 00-2 2H0v1h1v10a2 2 0 002 2h6a2 2 0 002-2v-10h1v-1H9zm1 1H2v10a1 1 0 001 1h6a1 1 0 001-1v-10zm-6 7v-4h1v4H4zm3 0v-4h1v4H7z"
                    clipRule="evenodd"
                ></path>
            </Svg>
        )
    },
    Icon_Clone : (props) => {
        return (
            <Svg width={props.size || 24} {...props} >
                <path d="M8 7v1H6.5C5.67 8 5 8.67 5 9.5v9c0 .83.67 1.5 1.5 1.5h7c.83 0 1.5-.67 1.5-1.5V17h1v1.5a2.5 2.5 0 0 1-2.5 2.5h-7A2.5 2.5 0 0 1 4 18.5v-9A2.5 2.5 0 0 1 6.5 7H8zm8-3h-5.5C9.67 4 9 4.67 9 5.5v9c0 .83.67 1.5 1.5 1.5h7c.83 0 1.5-.67 1.5-1.5V7h-2.5a.5.5 0 0 1-.5-.5V4zm4 2.53v7.97a2.5 2.5 0 0 1-2.5 2.5h-7A2.5 2.5 0 0 1 8 14.5v-9A2.5 2.5 0 0 1 10.5 3h5.97c.13 0 .27.04.38.15l3 3c.11.1.16.25.15.38zM17 6h1.3L17 4.7V6zm-5.5 7a.5.5 0 1 1 0-1h2a.5.5 0 1 1 0 1h-2zm0-2a.5.5 0 1 1 0-1h5a.5.5 0 1 1 0 1h-5zm0-2a.5.5 0 1 1 0-1h5a.5.5 0 1 1 0 1h-5z"/>
            </Svg>
        )
    },
    Icon_Import : (props) => {
        return (
            <Svg width={props.size || 24} {...props} >
                <path d="M3 11.707l.354-.353 8-8L11.707 3l.354.354 8 8 .353.353-.707.707-.353-.353-7.647-7.647-7.293 7.293L11.707 19l3.647-3.646.353-.354.707.707-.353.354-4 4-.354.353-.353-.353-8-8L3 11.707zm7.914-.5h2.293A6 6 0 0117.45 21.45l-.707-.707a5 5 0 00-3.535-8.535h-2.294l1.647 1.646-.707.707-2.5-2.5L9 11.707l.354-.353 2.5-2.5.707.707-1.647 1.646z"/>
            </Svg>
        )
    },
    Icon_Delete_Warning: (props) => {
        return (
            <Svg viewBox='0 0 87 48' width='87' height='48'>
                <g fill='none' fillRule='evenodd'>
                    <g fillRule='nonzero' fill='#ff5252'>
                        <path d='M44.336 3.314C43.798 2.415 42.923 2 41.981 2c-.875 0-1.817.415-2.356 1.314l-25.17 40.259C13.246 45.51 14.59 48 16.812 48h50.406c2.221 0 3.5-2.49 2.356-4.427L44.336 3.314zM67.823 45.51a.738.738 0 0 1-.673.415H16.811a.738.738 0 0 1-.673-.415c-.067-.139-.202-.484 0-.83L41.375 4.42c.202-.346.471-.346.673-.346a.9.9 0 0 1 .673.346l25.17 40.259c.134.415.067.691-.068.83z'
                        />
                        <path d='M42.767 22.175c-1.39 0-2.582.41-3.443 1.162-.86.751-1.324 1.844-1.324 3.21h2.185c0-.819.265-1.434.728-1.775a3.187 3.187 0 0 1 1.854-.615c.86 0 1.523.205 1.92.683.397.478.662 1.161.662 2.05 0 .751-.132 1.366-.463 1.844-.332.478-.861 1.23-1.656 2.186-.794.752-1.258 1.298-1.456 1.777-.199.478-.265 1.23-.265 2.39h2.185c0-.75.066-1.297.132-1.639.066-.342.331-.751.795-1.161.927-.957 1.655-1.777 2.185-2.665.53-.888.794-1.776.794-2.8 0-1.436-.397-2.597-1.258-3.349-.86-.751-1.986-1.298-3.575-1.298zM41.2 37.509h2.4v2.421h-2.4z'
                        />
                    </g>
                    <path fill='#ff5252' d='M7.706.634l15.588 9-1 1.732-15.588-9zM2.272 14.886l12.803 2.258-.347 1.97-12.803-2.258zM.923 28.528l9.945-1.045.21 1.989-9.946 1.045zM78.294 5.634l-15.588 9 1 1.732 15.588-9zM85.608 19.563l-12.666 2.925.45 1.949 12.666-2.925zM84.097 35.15l-9.744-2.25-.45 1.95 9.744 2.25z'
                    />
                </g>
            </Svg>
        )
    },
    Icon_List_View: (props) => {
        return (
            <Svg height={props.size || 11} viewBox="0 0 17 11">
                <path d="M9,5V7.2H21V5M9,16H21V13.8H9m0-2.2H21V9.4H9M4,7.2H8V5H4M4,16H8V13.8H4m0-2.2H8V9.4H4Z" transform="translate(-4 -5)"/>
            </Svg>
        )
    },
    Icon_Color_Medium_View : (props) => {
        return (
            <Svg width={props.size || 28} >
                <path fill={props.selected ? CurrentTheme.theme.color_brand : "#AEAEAE"} d="M16 20h4v-4h-4m0-2h4v-4h-4m-6-2h4V4h-4m6 4h4V4h-4m-6 10h4v-4h-4m-6 4h4v-4H4m0 10h4v-4H4m6 4h4v-4h-4M4 8h4V4H4v4z"/>
            </Svg>
        )
    },
    Icon_Card_View: (props) => {
        return (
            <Svg width={props.size || 11} viewBox="0 0 14 14">
                <path d="M3,9.222H9.222V3H3M3,17H9.222V10.778H3M10.778,17H17V10.778H10.778m0-7.778V9.222H17V3" transform="translate(-3 -3)"/>
            </Svg>
        )
    },
    Icon_Image_Card_View: (props) => {
        return (
            <Svg width="24" height="21" viewBox="0 0 24 21">
                <g fill="none" fillRule="evenodd">
                    <path fill={props.selected ? CurrentTheme.theme.color_brand : "#AEAEAE"} fillRule="nonzero" d="M20.055 0v1.315h2.63v2.63H24V0h-3.945zm2.63 19.068h-2.63v1.316H24v-3.946h-1.315v2.63zM1.315 1.315h2.63V0H0v3.945h1.315v-2.63zm0 15.123H0v3.946h3.945v-1.316h-2.63v-2.63z" />
                    <path stroke={props.selected ? CurrentTheme.theme.color_brand : "#AEAEAE"} d="M3.46 3.46h17.08v13.465H3.46V3.46z" />
                </g>
            </Svg>
        )
    },
    Icon_Image_List_View: (props) => {
        return (
            <Svg width="20" height="17" viewBox="0 0 20 17">
                <g fill={props.selected ? CurrentTheme.theme.color_brand : "#AEAEAE"} fillRule="evenodd">
                    <path fillRule="nonzero" d="M16.712 0v1.096h2.192v2.192H20V0h-3.288zm2.192 15.89h-2.192v1.096H20V13.7h-1.096v2.19zM1.096 1.096h2.192V0H0v3.288h1.096V1.096zm0 12.603H0v3.285h3.288V15.89H1.096V13.7z" />
                    <path d="M2.466 2.466h15.068V14.52H2.466" />
                </g>
            </Svg>
        )
    },
    Icon_Font_Back_Light: (props) => {
        return (
            <Svg width={props.size || 17} viewBox="0 0 19 20">
                <g fill="none" fillRule="evenodd">
                    <path stroke={props.selected ? CurrentTheme.theme.color_brand : "#8B8C8D"} d="M6.81 7.93V.513h11.624v11.623H12.07V7.93H6.813z" />
                    <path fill={props.selected ? CurrentTheme.theme.color_brand : "#8B8C8D"} fillRule="nonzero" d="M0 7.377h12.623V20H0z" />
                    <path fill={props.selected ? CurrentTheme.theme.color_brand : "#8B8C8D"} d="M1.052 8.43h9.257v9.256H1.05z" />
                </g>
            </Svg>
        )
    },
    Icon_Font_Back_Dark: (props) => {
        return (
            <Svg width={props.size || 18} viewBox="0 0 20 21">
                <g fill="none" fillRule="evenodd">
                    <g fillRule="nonzero" transform="translate(1)">
                        <path fill={props.selected ? CurrentTheme.theme.color_brand : "#8B8C8D"} fillRule="evenodd" d="M6 7.094h6.26v5.424h6.26V0H6z" />
                        <path stroke={props.selected ? CurrentTheme.theme.color_brand : "#8B8C8D"} d="M6.5 6.594V.5h11.52v11.52h-5.26V6.592H6.5z" />
                    </g>
                    <path stroke={props.selected ? CurrentTheme.theme.color_brand : "#8B8C8D"} d="M1 7h12.52v12.52H1z" />
                    <path d="M2.6 9.4h8.8v8.8H2.6z" />
                </g>
            </Svg>
        )
    },
    Icon_Font_Upper: (props) => {
        return (
            <Svg width="20" height="20" viewBox="0 0 20 20">
                <g fill="none" fillRule="evenodd">
                    <path stroke={props.selected ? CurrentTheme.theme.color_brand : "#8B8C8D"} d="M.5.5h19v19H.5z" />
                    <text fill={props.selected ? CurrentTheme.theme.color_brand : "#8B8C8D"} fontFamily="Arial-BoldMT, Arial" fontSize="17" fontWeight="bold" >
                        <tspan x="4" y="16">A</tspan>
                    </text>
                </g>
            </Svg>
        )
    },
    Icon_Font_Lower: (props) => {
        return (
            <Svg width="20" height="20" viewBox="0 0 20 20">
                <g fill="none" fillRule="evenodd">
                    <path stroke={props.selected ? CurrentTheme.theme.color_brand : "#8B8C8D"} d="M.5.5h19v19H.5z" />
                    <text fill={props.selected ? CurrentTheme.theme.color_brand : "#8B8C8D"} fontFamily="Arial-BoldMT, Arial" fontSize="20" fontWeight="bold" transform="translate(0 -3)">
                        <tspan x="4" y="18">a</tspan>
                    </text>
                </g>
            </Svg>
        )
    },
    Font_Icon: (props) => {
        return (
            <Svg {...props} width={props.size || 29} viewBox="0 0 26 29">
                <path fill={props.fill || '#aeaeae'} d="M.11.667h25.008l.04 8.376h-1.196c-.392-2.996-1.514-4.493-3.367-5.55-1.04-.584-2.596-.903-4.664-.957v21.35c0 1.53.27 2.544.806 3.045.534.506 1.645.756 3.336.756v1.055H5.26v-1.055c1.623 0 2.7-.253 3.235-.76.534-.508.8-1.52.8-3.035V2.536c-2.027.052-3.582.37-4.664.954-1.984 1.086-3.11 2.583-3.363 5.55H.07L.11.67z" />
            </Svg>
        )
    },
    Transition_Icon: (props) => {
        return (
            <Svg width={props.size || [45, 90][props.viewMode]} {...props} viewBox="0 0 22 22">
                <g fill={card_icon_fill(props)}>
                    <path d='M21.46 13.195c-.314-.314-.75-.363-1.136-.193l-4.737-4.713c-.048-.048-.072-.096-.12-.169.12-.193.169-.41.169-.652 0-.363-.145-.701-.411-.967a1.355 1.355 0 0 0-.967-.411c-.241 0-.459.072-.652.17-.049-.05-.097-.073-.17-.122L8.749 1.426c.17-.363.097-.822-.193-1.136a1.021 1.021 0 0 0-1.426 0 1.021 1.021 0 0 0 0 1.426c.314.314.75.362 1.136.193l3.02 3.021a9.63 9.63 0 0 0-9.23 1.305c-.194-.12-.412-.17-.653-.17-.363 0-.701.146-.967.412-.29.29-.435.628-.435.99 0 .363.145.701.41.967.267.266.605.411.968.411.362 0 .7-.145.966-.41.266-.267.411-.605.411-.968 0-.169-.024-.338-.097-.483 3.045-2.271 7.299-2.296 10.344 0a1.562 1.562 0 0 0-.097.484c0 .362.145.7.41.966.267.266.605.411.968.411.169 0 .338-.024.483-.097 2.272 3.07 2.272 7.299 0 10.344a1.562 1.562 0 0 0-.483-.097c-.363 0-.701.145-.967.41-.266.267-.411.605-.411.967 0 .363.145.701.41.967.267.266.605.411.968.411.362 0 .7-.145.966-.41.266-.267.411-.605.411-.968 0-.241-.072-.459-.17-.652a9.639 9.639 0 0 0 1.306-9.232l3.02 3.021c-.168.363-.096.822.194 1.136.387.387 1.04.387 1.426 0 .41-.41.41-1.04.024-1.45zM1.837 7.927a.672.672 0 0 1-.919 0 .571.571 0 0 1-.193-.46.66.66 0 0 1 .652-.652.66.66 0 0 1 .653.652v.025c-.024.169-.072.338-.193.435zm13.098 12.445a.66.66 0 0 1-.193.46.672.672 0 0 1-.919 0 .66.66 0 0 1-.193-.46.66.66 0 0 1 .193-.459c.121-.12.266-.169.435-.193h.025a.66.66 0 0 1 .652.652zM13.823 7.927c-.12-.121-.169-.266-.193-.435v-.024c0-.049 0-.097.024-.146a.893.893 0 0 1 .17-.338.568.568 0 0 1 .338-.169c.048 0 .096-.024.145-.024a.66.66 0 0 1 .652.652c0 .049 0 .097-.024.145a.893.893 0 0 1-.17.339.644.644 0 0 1-.338.169c-.048 0-.12.024-.169 0a.48.48 0 0 1-.435-.17z'
                        fill='#D9D9D9' />
                </g>
            </Svg>
        );
    },
    Icon_Visibility: (props) => {
        return (
            <Svg width={props.visible ? (props.size || 18) : (props.size || 19)} viewBox={props.visible ? "0 0 12 8" : "0 0 13 9"}>
                {
                    props.visible ?
                        <path d="M11.85 3.97C10.65 1.57 8.4.62 6 .62c-2.4 0-4.65.94-5.85 3.35l-.15.3.15.32C1.35 7 3.6 7.9 6 7.9c2.4 0 4.65-.93 5.85-3.34l.15-.32-.15-.3zm-11.03.4c.75-1.03 1.65-1.96 2.7-2.66-.52.7-.82 1.6-.82 2.46-.08 1 .22 2.02.82 2.8C2.47 6.26 1.57 5.4.82 4.4zm5.18 3C4.65 7.4 3.52 6 3.52 4.27c0-1.7 1.13-3.1 2.48-3.1s2.47 1.4 2.47 3.1c0 1.7-1.12 3.1-2.47 3.1zm2.47-.47c.6-.78.9-1.8.83-2.8 0-.85-.3-1.7-.83-2.4 1.05.7 1.96 1.62 2.7 2.64-.74 1-1.64 1.86-2.7 2.56z" />
                        :
                        <Svg_g {...props} >
                            <path d="M12.85 3.97C11.65 1.57 9.4.62 7 .62c-2.4 0-4.65.94-5.85 3.35l-.15.3.15.32C2.35 7 4.6 7.9 7 7.9c2.4 0 4.65-.93 5.85-3.34l.15-.32-.15-.3zm-11.03.4c.75-1.03 1.65-1.96 2.7-2.66-.52.7-.82 1.6-.82 2.45-.08 1 .22 2.02.82 2.8-1.05-.7-1.95-1.55-2.7-2.56zm5.18 3C5.65 7.4 4.52 6 4.52 4.27c0-1.7 1.13-3.1 2.48-3.1s2.47 1.4 2.47 3.1c0 1.7-1.12 3.1-2.47 3.1zm2.47-.47c.6-.78.9-1.8.83-2.8 0-.85-.3-1.7-.83-2.4 1.05.7 1.96 1.62 2.7 2.64-.74 1-1.64 1.86-2.7 2.56z" />
                            <path d="M1.02 8.28l-.35-.5L12.32.5l.35.5" />
                        </Svg_g>
                }
            </Svg>
        )
    },
    Icon_View : (props) => {
        if (props.off) {
            return (
                <Svg width={props.size || 24} >
                    <path d="M12 19a10.8 10.8 0 0 1-3.15-.49.5.5 0 0 1 .3-.95A9.8 9.8 0 0 0 12 18c5.24 0 9.7-4.68 10.84-6a21.64 21.64 0 0 0-3.61-3.3.5.5 0 0 1-.2-.33.5.5 0 0 1 .79-.48 22.7 22.7 0 0 1 4.07 3.79.5.5 0 0 1 0 .63c-.05.07-1.36 1.7-3.45 3.33C18.48 17.18 15.42 19 12 19zM4.47 16.2a.5.5 0 0 1-.3-.1 22.6 22.6 0 0 1-4.05-3.78.5.5 0 0 1-.01-.63c.05-.07 1.34-1.69 3.45-3.33C5.52 6.83 8.58 5 12 5c.89 0 1.77.12 2.63.34a.5.5 0 0 1-.26.97C13.6 6.1 12.8 6 12 6c-5.24 0-9.7 4.68-10.84 6a21.68 21.68 0 0 0 3.6 3.3c.1.07.18.19.2.32a.5.5 0 0 1-.5.58z"/>
                    <path d="M8 12.5a.5.5 0 0 1-.5-.5A4.5 4.5 0 0 1 12 7.5a.5.5 0 0 1 0 1A3.5 3.5 0 0 0 8.5 12a.5.5 0 0 1-.5.5zM12 16.5a.5.5 0 0 1 0-1 3.5 3.5 0 0 0 3.5-3.5.5.5 0 0 1 1 0 4.5 4.5 0 0 1-4.5 4.5zM2.25 22.25a.5.5 0 0 1-.35-.85L21.4 1.9c.09-.1.22-.15.35-.15s.26.05.35.15c.2.2.2.5 0 .7L2.6 22.1a.5.5 0 0 1-.35.15z"/>
                </Svg>
            )
        }      
        return (
            <Svg width={props.size || 24} >
                 <path d="M12 16a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-7a3 3 0 1 0 0 6 3 3 0 0 0 0-6z"/>
                <path d="M12 19C6.28 19 1.56 14.15.25 12.66a1 1 0 0 1 0-1.32C1.55 9.86 6.28 5 12 5c5.71 0 10.43 4.84 11.75 6.34a1 1 0 0 1 0 1.32C22.45 14.14 17.72 19 12 19zm0-13c-5.3 0-9.76 4.6-11 6 1.24 1.4 5.7 6 11 6s9.76-4.6 11-6c-1.24-1.4-5.7-6-11-6z"/>
            </Svg>
        )
    },
    Icon_SharePreview: (props) => {
        return (
            <Svg width={props.size || 24}  viewBox='0 0 24 24'>
                 <path d="M6.59 18.08A3.5 3.5 0 0 1 10.04 14h2.46c.28 0 .5.22.5.5v3.23a.32.32 0 0 0 .53.24L20.56 12a1.3 1.3 0 0 0 0-1.98l-7.03-5.98a.32.32 0 0 0-.53.24V7.5a.5.5 0 0 1-.5.5h-3A6.5 6.5 0 0 0 3 14.5c0 2.85 1.27 4.6 3.88 5.34l-.3-1.76zM10.04 15a2.5 2.5 0 0 0-2.46 2.91l.41 2.5a.5.5 0 0 1-.59.58C3.84 20.29 2 18.08 2 14.5A7.5 7.5 0 0 1 9.5 7H12V4.27a1.32 1.32 0 0 1 2.17-1l7.04 5.98a2.3 2.3 0 0 1 0 3.5l-7.04 5.98a1.32 1.32 0 0 1-2.17-1V15h-1.96z"/>
            </Svg>
        )
    },
    Icon_Device_All : (props) => {        
        return (
            <Svg  width={props.size || 24}>                
                 <path d="M14 18H4.5A2.5 2.5 0 0 1 2 15.5a.5.5 0 0 1 .5-.5H4V6.489a2.5 2.5 0 0 1 2.5-2.5h11.009a2.5 2.5 0 0 1 2.5 2.5V8h.491A1.5 1.5 0 0 1 22 9.5v10.01a1.5 1.5 0 0 1-1.5 1.5h-5a1.5 1.5 0 0 1-1.5-1.5V18zm0-1v-1H3.085A1.5 1.5 0 0 0 4.5 17H14zm0-2v-1H6.5a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 .5.5V8h1.009V6.489a1.5 1.5 0 0 0-1.5-1.5H6.5a1.5 1.5 0 0 0-1.5 1.5V15h9zm0-2V9.5A1.5 1.5 0 0 1 15.5 8H17V7H7v6h7zm1.5-4a.5.5 0 0 0-.5.5v10.01a.5.5 0 0 0 .5.5h5a.5.5 0 0 0 .5-.5V9.5a.5.5 0 0 0-.5-.5h-5zm2 10a.5.5 0 1 1 0-1h1a.5.5 0 1 1 0 1h-1z"/>
            </Svg>
        )
    },
    Icon_Device_xLarge : (props) => {        
        return (
            <Svg  width={props.size || 24}>                
                <path  d="M3 5v12.01h18V5H3zm1.686 13.01H2.5a.5.5 0 0 1-.5-.5V4.5a.5.5 0 0 1 .5-.5h19a.5.5 0 0 1 .5.5v13.01a.5.5 0 0 1-.5.5h-2.186l.495.99H21.5a.5.5 0 1 1 0 1h-4a.5.5 0 1 1 0-1h1.191l-.495-.99H5.804L5.31 19H6.5a.5.5 0 1 1 0 1h-4a.5.5 0 1 1 0-1h1.691l.495-.99z"/>
            </Svg>
        )
    },
    Icon_Device_Large : (props) => {
        return (
            <Svg  width={props.size || 24}>                
                <path d="M4.5 5C3.67 5 3 5.67 3 6.5v8c0 .83.67 1.5 1.5 1.5h15c.83 0 1.5-.67 1.5-1.5v-8c0-.83-.67-1.5-1.5-1.5h-15zm0-1h15A2.5 2.5 0 0 1 22 6.5v8a2.5 2.5 0 0 1-2.5 2.5h-15A2.5 2.5 0 0 1 2 14.5v-8A2.5 2.5 0 0 1 4.5 4z"/>
                <path d="M10 16.5a.5.5 0 1 1 1 0v3a.5.5 0 1 1-1 0v-3zm3 0a.5.5 0 1 1 1 0v3a.5.5 0 1 1-1 0v-3z"/>
                <path d="M8.5 20a.5.5 0 1 1 0-1h7a.5.5 0 1 1 0 1h-7z"/>
            </Svg>
        )
    },
    Icon_Device_Medium : (props) => {
        return (
            <Svg  height={props.size || 24} >                
                <path d="M22 17h-7.09c-.2.58-.76 1-1.41 1h-3a1.5 1.5 0 0 1-1.41-1H2v1.5c0 .28.22.5.5.5h19a.5.5 0 0 0 .5-.5V17zM3 16V5.49c0-.83.67-1.5 1.5-1.5h15c.83 0 1.5.67 1.5 1.5V16h1.5c.28 0 .5.22.5.5v2c0 .83-.67 1.5-1.5 1.5h-19A1.5 1.5 0 0 1 1 18.5v-2c0-.28.22-.5.5-.5H3zm1 0h5.5c.28 0 .5.22.5.5s.22.5.5.5h3a.5.5 0 0 0 .5-.5c0-.28.22-.5.5-.5H20V5.49a.5.5 0 0 0-.5-.5h-15a.5.5 0 0 0-.5.5V16z"/>
            </Svg>
        )
    },
    Icon_Device_Small : (props) => {
        return (
            <Svg  height={props.size || 19} >                
                <path fillRule="nonzero" d="M5,4.5 L5,19.5 C5,20.3284271 5.67157288,21 6.5,21 L17.5113578,21 C18.3397849,21 19.0113578,20.3284271 19.0113578,19.5 L19.0113578,4.5 C19.0113578,3.67157288 18.3397849,3 17.5113578,3 L6.5,3 C5.67157288,3 5,3.67157288 5,4.5 Z M4,4.5 C4,3.11928813 5.11928813,2 6.5,2 L17.5113578,2 C18.8920696,2 20.0113578,3.11928813 20.0113578,4.5 L20.0113578,19.5 C20.0113578,20.8807119 18.8920696,22 17.5113578,22 L6.5,22 C5.11928813,22 4,20.8807119 4,19.5 L4,4.5 Z"/>
                <path d="M11,19 C11,18.448 11.448,18 12,18 C12.552,18 13,18.448 13,19 C13,19.552 12.552,20 12,20 C11.448,20 11,19.552 11,19 Z"/>
                <path fillRule="nonzero" d="M10.5,5 C10.2238576,5 10,4.77614237 10,4.5 C10,4.22385763 10.2238576,4 10.5,4 L13.5,4 C13.7761424,4 14,4.22385763 14,4.5 C14,4.77614237 13.7761424,5 13.5,5 L10.5,5 Z"/>                
            </Svg>
        )
    },
    Icon_Device_xSmall : (props) => {
        return (
            <Svg  height={props.size || 16}  width="24">                
                <path d="M7 4.5v15c0 .83.67 1.5 1.5 1.5h7.01c.83 0 1.5-.67 1.5-1.5v-15c0-.83-.67-1.5-1.5-1.5H8.5C7.67 3 7 3.67 7 4.5zm-1 0A2.5 2.5 0 0 1 8.5 2h7.01a2.5 2.5 0 0 1 2.5 2.5v15a2.5 2.5 0 0 1-2.5 2.5H8.5A2.5 2.5 0 0 1 6 19.5v-15z"/>
                <path d="M11 19a1 1 0 1 1 2 0 1 1 0 0 1-2 0z"/>
            </Svg>
        )
    },
    Arrow_Back : (props) => {
        return (
            <Svg height={props.size ||24}  style={{paddingBottom : '2px', transform : `rotate(${props.expanded ? 180 : 0}deg)`, ...props.style}}>
                <path d="M8.7 12h9.8a.5.5 0 1 1 0 1H8.7l3.15 3.15a.5.5 0 0 1-.7.7l-4-4a.5.5 0 0 1 0-.7l4-4a.5.5 0 0 1 .7.7L8.71 12z"/>
            </Svg>
        )
    },
    Icon_ExpandArrow : (props) => {        
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={props.size ||10} viewBox="0 0 13 8" style={{transform : `rotate(${props.rotate || (props.expanded ? 180 : 90)}deg)`, transition : '0.2s ease', transitionProperty : 'transform'}} >
                <path fill={props.fill || "#8B8B8B"} d="M10.82 7.32L12 6.12 6 0 0 6.12l1.18 1.2L6 2.52"/>   
            </svg>
        );
    },    
    Icon_Arrow_Filled : (props) => {
        return (
            <Svg width={props.size ||24}  style={{transform : `rotate(${props.rotate || (props.expanded ? 90 : 0)}deg)`, transition : 'transform 0.2s ease 0s', ...props.style}}>
                <path d="M10,17L15,12L10,7V17Z" />
            </Svg>
        )
    },
    Icon_Lock: (props) => {
        if (props.open) {
            return (
                <Svg width={props.size || 11} viewBox="0 0 14 18.06" style={props.style}>
                    <path stroke="none" d="M11.62 7.06a.5.5 0 0 1-.25 0H2.5A1.5 1.5 0 0 0 1 8.56v7a1.5 1.5 0 0 0 1.5 1.5h9a1.5 1.5 0 0 0 1.5-1.5v-7a1.5 1.5 0 0 0-1.38-1.5zM12 6.1a2.31 2.31 0 0 1 2 2.45v7a2.5 2.5 0 0 1-2.5 2.5h-9a2.5 2.5 0 0 1-2.5-2.5v-7A2.32 2.32 0 0 1 2 6.1c3.5-.05 5.43-.01 10 0zm-6 4.95v.38a4.24 4.24 0 0 0 .45 1.9l.27.55a.3.3 0 0 0 .56 0l.27-.56a4.24 4.24 0 0 0 .45-1.9v-.37a1 1 0 0 0-2 0zm-1 0a2 2 0 0 1 4 0v.38a5.24 5.24 0 0 1-.55 2.34l-.28.55a1.3 1.3 0 0 1-2.34 0l-.28-.55A5.24 5.24 0 0 1 5 11.44z"/>
                    <path fill="none" strokeLinecap="round" d="M11.5 6.08c-.01-1.66-.3-3.6-2.13-4.85C7.67.06 4.52.05 2.83 3.3"/>
                </Svg>
            )
        }
        return (
            <Svg width={props.size || 11} viewBox="0 0 14 18.06" style={props.style}>
                <path stroke="none" d="M11.62 7a.5.5 0 0 1-.25 0H2.5A1.5 1.5 0 0 0 1 8.5v7A1.5 1.5 0 0 0 2.5 17h9a1.5 1.5 0 0 0 1.5-1.5v-7A1.5 1.5 0 0 0 11.62 7zM3 6h8V5a4 4 0 0 0-8 0zm9 .05a2.5 2.5 0 0 1 2 2.45v7a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 0 15.5v-7a2.5 2.5 0 0 1 2-2.45V5a5 5 0 0 1 10 0zM6 11v.38a4.24 4.24 0 0 0 .45 1.9l.27.55a.3.3 0 0 0 .56 0l.27-.55a4.24 4.24 0 0 0 .45-1.9V11a1 1 0 0 0-2 0zm-1 0a2 2 0 0 1 4 0v.38a5.24 5.24 0 0 1-.55 2.34l-.28.56a1.3 1.3 0 0 1-2.34 0l-.28-.56A5.24 5.24 0 0 1 5 11.38z"/>
            </Svg>
        )
    },
    Icon_Layers : (props) => {
        return (
            <Svg width={props.size ||"24"} >
                <path fill="none" d="M0 0h24v24H0z"/>
                <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27-7.38 5.74zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16z"/>
            </Svg>
        )
    },
    Icon_Menu : (props) => {
        return (
            <Svg width={props.size || "24"} >
                {
                    props.expanded ? 
                    <path d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z" /> : 
                    <path d="M5,13L9,17L7.6,18.42L1.18,12L7.6,5.58L9,7L5,11H21V13H5M21,6V8H11V6H21M21,16V18H11V16H21Z" />
                }
            </Svg>
        )
    },
    Icon_Move : (props) => {
        return (
            <Svg width={props.size} >
                <path d="M13 21.3l2.15-2.15a.5.5 0 0 1 .7.7l-3 3a.5.5 0 0 1-.7 0l-3-3a.5.5 0 0 1 .7-.7L12 21.29V15.5a.5.5 0 1 1 1 0v5.8zM3.7 13l2.15 2.15a.5.5 0 0 1-.7.7l-3-3a.5.5 0 0 1 0-.7l3-3a.5.5 0 1 1 .7.7L3.71 12H9.5a.5.5 0 1 1 0 1H3.7zm17.6-1l-2.15-2.15a.5.5 0 0 1 .7-.7l3 3c.2.2.2.5 0 .7l-3 3a.5.5 0 0 1-.7-.7L21.29 13H15.5a.5.5 0 1 1 0-1h5.8zM13 3.7v5.8a.5.5 0 1 1-1 0V3.7L9.85 5.86a.5.5 0 1 1-.7-.7l3-3c.2-.2.5-.2.7 0l3 3a.5.5 0 0 1-.7.7L13 3.71z"/>
            </Svg>
        )
    },
    Icon_FullScreen : (props) => {
        return (
            <Svg width={props.size || "20"} >
                <path d="M9.5 13.09l1.41 1.41-4.5 4.5H10v2H3v-7h2v3.59l4.5-4.5m1.41-3.59L9.5 10.91 5 6.41V10H3V3h7v2H6.41l4.5 4.5m3.59 3.59l4.5 4.5V14h2v7h-7v-2h3.59l-4.5-4.5 1.41-1.41M13.09 9.5l4.5-4.5H14V3h7v7h-2V6.41l-4.5 4.5-1.41-1.41z"/>
            </Svg>
        )
    },
    Card_Icon_Save: ({hoverColor, ...props}) => {
        return (
            <Svg width={props.size || 8} viewBox="0 0 8 7" {...props}>
                <path d='M1 3L0 4.1l2.7 2.7c.3.3.8.2 1-.1L8 1.3 6.8.3 3.1 5.1l-2-2z' />
            </Svg>
        )
    },
    Card_Icon_Cancel: ({hoverColor, ...props}) => {
        return (
            <Svg  width={props.size || 10} viewBox="0 0 10 10" {...props}>
                <path strokeWidth="2" d="M9 1L5 5l4 4.013m-8 0L5 5 1 1" strokeLinejoin="round" />
            </Svg>
        )
    },
    Icon_DropDown: (props) => {
        return (
            <Svg width={props.size || 8} viewBox="0 0 8 5" style={{ marginTop: '2px' }} >
                <path d="M6.91-.014l.752.768-3.83 3.908L0 .754l.754-.768L3.83 3.06" />
            </Svg>
        )
    },
    Icon_Props : (props) => {
        return (
            <Svg width={props.size ||20}   >
                <path fill="none" d="M0 0h24v24H0z"/>
                <path d="M3 17v2h6v-2H3zM3 5v2h10V5H3zm10 16v-2h8v-2h-8v-2h-2v6h2zM7 9v2H3v2h4v2h2V9H7zm14 4v-2H11v2h10zm-6-4h2V7h4V5h-4V3h-2v6z"/>
            </Svg>
        )
    },
    Icon_TextList_Number : (props) => {
        return (
            <Svg width={props.size ||20}   >
                <path d="M5 8h.5a.5.5 0 010 1h-2a.5.5 0 010-1H4V5h-.5a.5.5 0 010-1h1a.5.5 0 01.5.5V8zm-1 3v.5a.5.5 0 11-1 0v-1a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v1a.5.5 0 01-.146.354L4.707 13H5.5a.5.5 0 110 1h-2a.5.5 0 01-.354-.854L5 11.293V11H4zm-.5 5a.5.5 0 110-1h2a.5.5 0 01.5.5v1a.5.5 0 01-.146.354L5.707 17l.147.146A.5.5 0 016 17.5v1a.5.5 0 01-.5.5h-2a.5.5 0 110-1H5v-.293l-.354-.353a.5.5 0 010-.708L5 16.293V16H3.5zm5 1a.5.5 0 110-1h12a.5.5 0 110 1h-12zm0-5a.5.5 0 110-1h12a.5.5 0 110 1h-12zm0-5a.5.5 0 010-1h12a.5.5 0 110 1h-12z"/>
            </Svg>
        )
    },
    Icon_TextList_Bullet : (props) => {
        return (
            <Svg width={props.size ||20}   >
                <path d="M3 6a1 1 0 011-1h1a1 1 0 011 1v1a1 1 0 01-1 1H4a1 1 0 01-1-1V6zm5.5 1a.5.5 0 010-1h12a.5.5 0 110 1h-12zM3 11a1 1 0 011-1h1a1 1 0 011 1v1a1 1 0 01-1 1H4a1 1 0 01-1-1v-1zm5.5 1a.5.5 0 110-1h12a.5.5 0 110 1h-12zM3 16a1 1 0 011-1h1a1 1 0 011 1v1a1 1 0 01-1 1H4a1 1 0 01-1-1v-1zM4 6v1h1V6H4zm0 5v1h1v-1H4zm0 5v1h1v-1H4zm4.5 1a.5.5 0 110-1h12a.5.5 0 110 1h-12z"/>
            </Svg>
        )
    },
    Icon_Code : (props) => {
        return (
            <Svg width={props.size ||20}   >
                <path d="M7.88 13.175a.5.5 0 01-.76.65l-3-3.5a.5.5 0 010-.65l3-3.5a.5.5 0 11.76.65L5.159 10l2.72 3.175zm1.073 6.536a.5.5 0 11-.906-.422l7-15a.5.5 0 01.906.422l-7 15zm7.167-8.886a.5.5 0 01.76-.65l3 3.5a.5.5 0 010 .65l-3 3.5a.5.5 0 01-.76-.65L18.841 14l-2.72-3.175z"/>
            </Svg>
        )
    },
    Icon_Link : (props) => {
        return (
            <Svg width={props.size ||20}   >
                <path d="M12.15 11.847a.5.5 0 01.71-.705 3.34 3.34 0 01-.004 4.713l-2.168 2.173a3.33 3.33 0 01-4.694.02 3.312 3.312 0 01-.034-4.684l2.184-2.215a.5.5 0 11.712.702l-2.183 2.215a2.312 2.312 0 00.023 3.269c.913.9 2.38.893 3.284-.014l2.168-2.172a2.34 2.34 0 00.003-3.302zm-.462.306a.5.5 0 11-.71.705 3.343 3.343 0 01.002-4.715l2.088-2.1a3.506 3.506 0 015.042.076 3.283 3.283 0 01-.166 4.64l-2.265 2.107a.5.5 0 11-.68-.732l2.263-2.107a2.283 2.283 0 00.024-3.32 2.506 2.506 0 00-3.509.042l-2.089 2.099c-.91.914-.91 2.39 0 3.305z"/>
            </Svg>
        )
    },
    Icon_Unlink : (props) => {
        return (
            <Svg width={props.size ||20}   >
                <path d="M11.853 8.842a.5.5 0 11-.706-.709l2.175-2.162a3.334 3.334 0 014.704.003 3.309 3.309 0 01.008 4.679l-2.216 2.203a.5.5 0 01-.705-.709l2.205-2.193a2.31 2.31 0 00.002-3.272 2.334 2.334 0 00-3.293-.002l-2.174 2.162zm.294 6.304a.5.5 0 11.706.708l-2.174 2.171a3.327 3.327 0 11-4.707-4.708l2.175-2.17a.5.5 0 01.706.707L6.68 14.025a2.327 2.327 0 00-.002 3.29 2.33 2.33 0 003.296.003l2.174-2.172zM10 6.5a.5.5 0 01-1 0v-2a.5.5 0 011 0v2zm5 13a.5.5 0 11-1 0v-2a.5.5 0 111 0v2zM7.854 7.146a.5.5 0 11-.708.708l-2-2a.5.5 0 11.708-.708l2 2zm11 11a.5.5 0 01-.708.708l-2-2a.5.5 0 01.708-.708l2 2zM6.5 9a.5.5 0 010 1h-2a.5.5 0 010-1h2zm13 5a.5.5 0 110 1h-2a.5.5 0 110-1h2z"/>
            </Svg>
        )
    },
    DragIcon: (props) => {
        if (props.small || props.xsmall) {
            return (
                <Svg xmlns={svgns} width={props.xsmall ? 6 : 20} viewBox="0 0 6.75 11" style={{ ...props.style }}>
                    <Svg_g fill="#6E6E6E" hoverFill="#FFF">
                        <circle cx="1.25" cy="1.25" r="1.25"/>
                        <circle cx="5.5" cy="1.25" r="1.25"/>
                        <circle cx="1.25" cy="5.5" r="1.25"/>
                        <circle cx="5.5" cy="5.5" r="1.25"/>
                        <circle cx="1.25" cy="9.75" r="1.25"/>
                        <circle cx="5.5" cy="9.75" r="1.25"/>
                    </Svg_g>
                </Svg>
            )
        }
        return (
            <Svg xmlns={svgns} width="8.13" height="11.82" viewBox="0 0 8.13 11.82">
                <g fill="#6F6F6F">
                    <circle cx="1.48" cy="1.48" r="1.48" />
                    <circle cx="1.48" cy="5.91" r="1.48" />
                    <circle cx="1.48" cy="10.34" r="1.48" />
                    <circle cx="6.65" cy="1.48" r="1.48" />
                    <circle cx="6.65" cy="5.91" r="1.48" />
                    <circle cx="6.65" cy="10.34" r="1.48" />
                </g>
            </Svg>
        )
    },
    Upload : (props) => {
        return (
          <Svg width={props.size || 24} >
            <path d="M12 11.7v7.8a.5.5 0 1 1-1 0v-7.8l-2.15 2.15a.5.5 0 0 1-.7-.7l3-3c.2-.2.5-.2.7 0l3 3a.5.5 0 0 1-.7.7L12 11.71zM15.74 7h.76a4.5 4.5 0 1 1 0 9 .5.5 0 1 1 0-1 3.5 3.5 0 0 0 0-7h-.53c.02.16.03.33.03.5a.5.5 0 1 1-1 0A3.5 3.5 0 0 0 11.5 5H11a4 4 0 0 0-4 4v.5a.5.5 0 0 1-.5.5 2.5 2.5 0 1 0 0 5 .5.5 0 1 1 0 1A3.5 3.5 0 0 1 6 9.04 5 5 0 0 1 11 4h.5a4.5 4.5 0 0 1 4.24 3z" />
          </Svg>
        );
    },
    Download : (props) => {
        return (
          <Svg width={props.size || 24} >
            <path d="M13 14.293l3.146-3.147a.5.5 0 01.708.708l-4 4a.5.5 0 01-.708 0l-4-4a.5.5 0 01.708-.708L12 14.293V3.5a.5.5 0 111 0v10.793zm7 1.207a.5.5 0 111 0v3a2.5 2.5 0 01-2.5 2.5h-12A2.5 2.5 0 014 18.5v-3a.5.5 0 111 0v3A1.5 1.5 0 006.5 20h12a1.5 1.5 0 001.5-1.5v-3z" />
          </Svg>
        );
    },
    Goto_Parent : (props) => {
        return (
            <Svg width={props.size || 24}  style={{transform : 'rotateX(180deg)'}}>
                <path d="M4.7 16l3.15 3.15a.5.5 0 0 1-.7.7l-4-4a.5.5 0 0 1 0-.7l4-4a.5.5 0 0 1 .7.7L4.71 15H18.5c.83 0 1.5-.67 1.5-1.5v-6c0-.83-.67-1.5-1.5-1.5h-15a.5.5 0 0 1 0-1h15A2.5 2.5 0 0 1 21 7.5v6a2.5 2.5 0 0 1-2.5 2.5H4.7z"/>
            </Svg>
        );
    },
    Bind_TextContent : (props) => {
        return (
            <Svg width={props.size || 24} >
                <path d="M11.5 10a.5.5 0 1 1 0-1h5a.5.5 0 1 1 0 1h-5zm-2 3a.5.5 0 1 1 0-1h7a.5.5 0 1 1 0 1h-7zm0 3a.5.5 0 1 1 0-1h5a.5.5 0 1 1 0 1h-5zm2-11a.5.5 0 1 1 0-1h7A2.5 2.5 0 0 1 21 6.5v13a2.5 2.5 0 0 1-2.5 2.5h-11A2.5 2.5 0 0 1 5 19.5v-5a.5.5 0 1 1 1 0v5c0 .83.67 1.5 1.5 1.5h11c.83 0 1.5-.67 1.5-1.5v-13c0-.83-.67-1.5-1.5-1.5h-7zM6 8.5a.5.5 0 0 1-1 0V5a2 2 0 1 1 4 0v3.5a3.5 3.5 0 0 1-7 0v-5a.5.5 0 0 1 1 0v5a2.5 2.5 0 0 0 5 0V5a1 1 0 1 0-2 0v3.5z"/>
            </Svg>
        );
    },
    Icon_Plus: (props) => {
        if (CurrentTheme.theme.isLight) {
            return (
                <Svg width={props.lightSize || (props.size - 9 || 11)} viewBox="0 0 13 13" style={props.style}>
                    <path
                        fill="#000"
                        fillOpacity="0.8"
                        fillRule="evenodd"
                        d="M7 0H6v6H0v1h6v6h1V7h6V6H7V0z"
                        clipRule="evenodd"
                    ></path>
                </Svg>                
            )
        }
        return (
            <Svg width={props.size || 24}  style={props.style}>
                <path d="M13 12h5.5a.5.5 0 1 1 0 1H13v5.5a.5.5 0 1 1-1 0V13H6.5a.5.5 0 1 1 0-1H12V6.5a.5.5 0 1 1 1 0V12z"/>
            </Svg>
        )
    },
    Icon_Group_Plus: (props) => {
        return (
            <Svg width={props.size || 24} viewBox="0 0 11 11" style={props.style}>
                <path d="M12.333,5.667h2.333a.333.333,0,0,1,0,.667H12.333V8.667a.333.333,0,1,1-.667,0V6.333H9.333a.333.333,0,1,1,0-.667h2.333V3.333a.333.333,0,1,1,.667,0Zm2,4.333A.333.333,0,1,1,15,10v2.33A1.67,1.67,0,0,1,13.333,14H5.67A1.672,1.672,0,0,1,4,12.33V4.667A1.67,1.67,0,0,1,5.67,3H8a.333.333,0,1,1,0,.667H5.67a1,1,0,0,0-1,1V12.33a1,1,0,0,0,1,1h7.663a1,1,0,0,0,1-1Z" transform="translate(-4 -3)"/>
            </Svg>
        )
    },
    Icon_Group: (props) => {
        return (
            <Svg width={props.size || 24} viewBox="0 0 24 16" style={props.style}>
                 <path d="M20.5,18.5H3.5V7.5h17M20,6H12L10,4H4A1.993,1.993,0,0,0,2,6V18a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V8A2,2,0,0,0,20,6Z" transform="translate(-2 -4)"></path>
            </Svg>
        )
    },
    Icon_Plus_Box: (props) => {
        return (
            <Svg width={props.size || 24} >
                <path d="M13 12h3.5a.5.5 0 1 1 0 1H13v3.5a.5.5 0 1 1-1 0V13H8.5a.5.5 0 1 1 0-1H12V8.5a.5.5 0 1 1 1 0V12zM3 5.5A2.5 2.5 0 0 1 5.5 3h14A2.5 2.5 0 0 1 22 5.5v14a2.5 2.5 0 0 1-2.5 2.5h-14A2.5 2.5 0 0 1 3 19.5v-14zm1 0v14c0 .83.67 1.5 1.5 1.5h14c.83 0 1.5-.67 1.5-1.5v-14c0-.83-.67-1.5-1.5-1.5h-14C4.67 4 4 4.67 4 5.5z"/>                
            </Svg>
        )
    },
    Icon_Minus: (props) => {
        return (
            <Svg width={props.size || 10} viewBox='0 0 10 2'>
                <path fill={props.fill || "#6A6C6E"} fillRule='evenodd' d='M0 0h10v2H0z' />
            </Svg>
        )
    },
    Icon_Sound: (props) => {
        return (
            <Svg width={props.small ? 53 : props.xsmall ? 44 : 107} height="100%" viewBox="0 0 107 73" >
                <path style={{transition : 'fill 0.2s ease'}} fill={props.playing ? CurrentTheme.theme.color_brand : '#676769'} d="M105.4 41.8h-7.8l-6-1.7-7 5.3-6.1-7.1-7 11-6-12.3-7.2 22.1L52.5 30l-7.1 43-6.7-52-5.6 34.7-7.6-17.9-5.5 7.3-11-5H1.6C.8 40 0 39.3 0 38.5c0-.7.7-1.3 1.7-1.3h8.2l9 4.3 7.6-9.9 5.4 12.6L39.4 0l6.4 52.8 6-39L59 46.7l6-17.8L72 43l6-9.8 7 8.1 6-4.2 7 2.1h7.4c.9 0 1.6.7 1.6 1.4.1.5-.6 1.2-1.6 1.2z" />
            </Svg>
        )
    },
    GlobalState : (props) => {
        return (
            <svg width={props.size || 24 }  style={{transform : 'rotate(100deg) rotateY(180deg)'}}>
                <path d="M13,14C9.64,14 8.54,15.35 8.18,16.24C9.25,16.7 10,17.76 10,19A3,3 0 0,1 7,22A3,3 0 0,1 4,19C4,17.69 4.83,16.58 6,16.17V7.83C4.83,7.42 4,6.31 4,5A3,3 0 0,1 7,2A3,3 0 0,1 10,5C10,6.31 9.17,7.42 8,7.83V13.12C8.88,12.47 10.16,12 12,12C14.67,12 15.56,10.66 15.85,9.77C14.77,9.32 14,8.25 14,7A3,3 0 0,1 17,4A3,3 0 0,1 20,7C20,8.34 19.12,9.5 17.91,9.86C17.65,11.29 16.68,14 13,14M7,18A1,1 0 0,0 6,19A1,1 0 0,0 7,20A1,1 0 0,0 8,19A1,1 0 0,0 7,18M7,4A1,1 0 0,0 6,5A1,1 0 0,0 7,6A1,1 0 0,0 8,5A1,1 0 0,0 7,4M17,6A1,1 0 0,0 16,7A1,1 0 0,0 17,8A1,1 0 0,0 18,7A1,1 0 0,0 17,6Z" />
            </svg>
        )
    },
    Icon_Cancel: (props) => {
        return (
            <Svg xmlns={svgns} width={props.size || 10} viewBox="0 0 10 10" {...props}>
                <path strokeWidth="2" d="M9 1L5 5l4 4.013m-8 0L5 5 1 1" strokeLinejoin="round" />
            </Svg>
        )
    },
    Icon_Edit: (props) => {
        return (
            <Card_Icon viewBox="0 0 24 24" {...props} xmlns={svgns} width={props.size || 18} >
                <path d="M20 11.5a.5.5 0 1 1 1 0v7a2.5 2.5 0 0 1-2.5 2.5H5.49a2.5 2.5 0 0 1-2.5-2.5v-13A2.5 2.5 0 0 1 5.49 3h7.01a.5.5 0 1 1 0 1H5.49c-.83 0-1.5.67-1.5 1.5v13c0 .83.67 1.5 1.5 1.5H18.5c.83 0 1.5-.67 1.5-1.5v-7zm-1.15-8.35l2 2c.2.2.2.5 0 .7l-8 8a.5.5 0 0 1-.35.15h-2a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .15-.35l8-8c.2-.2.5-.2.7 0zM18.5 4.2L11 11.7V13h1.3l7.5-7.5-1.3-1.3z"/>
            </Card_Icon>
        )
    },
    Card_Icon_Button : Card_Icon_Button,
    Icon_Undo: (props) => {
        return (
            <Svg width="24" >
                <path d="M3.92 11.76a.5.5 0 0 1-.84-.52A9.01 9.01 0 0 1 10.72 7H11a9 9 0 0 1 8.99 8.5l.01 2a.5.5 0 0 1-1 0l-.01-1.95A8 8 0 0 0 11 8h-.28a8.01 8.01 0 0 0-6.8 3.76zM8.5 11a.5.5 0 1 1 0 1h-5a.5.5 0 0 1-.5-.5v-5a.5.5 0 0 1 1 0V11h4.5z"/>
            </Svg>
        )
    },
    Icon_Redo: (props) => {
        return (
            <Svg width="24" >
                 <path d="M19 6.5a.5.5 0 1 1 1 0v5a.5.5 0 0 1-.5.5h-5a.5.5 0 1 1 0-1H19V6.5zm-15 11a.5.5 0 0 1-1 0l.01-2A9 9 0 0 1 12 7h.28c3.11 0 6 1.6 7.64 4.24a.5.5 0 0 1-.84.52A8.01 8.01 0 0 0 12.28 8H12a8 8 0 0 0-7.99 7.55L4 17.5z"/>
            </Svg>
        )
    },
    Icon_History : (props) => {
        return (
            <Svg  width={props.size  || 24}>                
                  <path d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-1a9 9 0 1 0 0-18 9 9 0 0 0 0 18zm3.8-6.9a.5.5 0 1 1-.6.8l-3.5-2.5a.5.5 0 0 1-.18-.27l-1.5-5.5a.5.5 0 1 1 .96-.26l1.46 5.33 3.35 2.4z"/>
            </Svg>
        )
    },
    Icon_Settings: (props) => {
        return (
            <Svg width={props.size || 15} viewBox="0 0 14 15">
                <path d="M8 14.22H6c-.12 0-.22-.1-.22-.23v-2.2c-.42-.2-.83-.3-1.2-.5l-1.5 1.5c-.1.1-.24.1-.33 0l-1.4-1.5c-.1-.1-.1-.2 0-.3l1.5-1.56c-.22-.4-.4-.8-.5-1.2H.23C.1 8.3 0 8.2 0 8.1v-2c0-.13.1-.23.23-.23h2.12c.1-.43.28-.84.5-1.22l-1.5-1.53c-.1-.08-.1-.23 0-.32l1.4-1.42c.1-.1.23-.1.32 0l1.5 1.52c.38-.22.78-.4 1.2-.5V.23C5.77.1 5.87 0 6 0h2c.12 0 .22.1.22.23V2.4c.42.1.83.27 1.2.5l1.5-1.52c.1-.1.24-.1.32 0l1.4 1.43c.1.1.1.3 0 .4l-1.5 1.6c.23.38.4.8.5 1.2h2.13c.13 0 .23.1.23.24v2c0 .13-.1.23-.23.23h-2.12c-.1.4-.28.82-.5 1.2l1.5 1.53c.1.1.1.23 0 .32l-1.4 1.4c-.1.1-.24.1-.32 0l-1.5-1.5c-.38.2-.78.4-1.2.5V14c0 .06-.03.1-.07.16-.05.04-.1.06-.17.06zM7 9.86c1.5 0 2.7-1.23 2.7-2.75 0-1.5-1.2-2.7-2.7-2.7-1.5 0-2.7 1.3-2.7 2.8 0 1.5 1.2 2.7 2.7 2.7z" />
            </Svg>
        )
    },
    Icon_SliderSettings : (props) => {
        return (
            <Svg width={props.size || 15} >
                <path d="M12.9499909,17 C12.7183558,18.1411202 11.709479,19 10.5,19 C9.29052104,19 8.28164422,18.1411202 8.05000906,17 L3.5,17 C3.22385763,17 3,16.7761424 3,16.5 C3,16.2238576 3.22385763,16 3.5,16 L8.05000906,16 C8.28164422,14.8588798 9.29052104,14 10.5,14 C11.709479,14 12.7183558,14.8588798 12.9499909,16 L20.5,16 C20.7761424,16 21,16.2238576 21,16.5 C21,16.7761424 20.7761424,17 20.5,17 L12.9499909,17 Z M18.9499909,12 C18.7183558,13.1411202 17.709479,14 16.5,14 C15.290521,14 14.2816442,13.1411202 14.0500091,12 L3.5,12 C3.22385763,12 3,11.7761424 3,11.5 C3,11.2238576 3.22385763,11 3.5,11 L14.0500091,11 C14.2816442,9.85887984 15.290521,9 16.5,9 C17.709479,9 18.7183558,9.85887984 18.9499909,11 L20.5,11 C20.7761424,11 21,11.2238576 21,11.5 C21,11.7761424 20.7761424,12 20.5,12 L18.9499909,12 Z M9.94999094,7 C9.71835578,8.14112016 8.70947896,9 7.5,9 C6.29052104,9 5.28164422,8.14112016 5.05000906,7 L3.5,7 C3.22385763,7 3,6.77614237 3,6.5 C3,6.22385763 3.22385763,6 3.5,6 L5.05000906,6 C5.28164422,4.85887984 6.29052104,4 7.5,4 C8.70947896,4 9.71835578,4.85887984 9.94999094,6 L20.5,6 C20.7761424,6 21,6.22385763 21,6.5 C21,6.77614237 20.7761424,7 20.5,7 L9.94999094,7 Z M7.5,8 C8.32842712,8 9,7.32842712 9,6.5 C9,5.67157288 8.32842712,5 7.5,5 C6.67157288,5 6,5.67157288 6,6.5 C6,7.32842712 6.67157288,8 7.5,8 Z M16.5,13 C17.3284271,13 18,12.3284271 18,11.5 C18,10.6715729 17.3284271,10 16.5,10 C15.6715729,10 15,10.6715729 15,11.5 C15,12.3284271 15.6715729,13 16.5,13 Z M10.5,18 C11.3284271,18 12,17.3284271 12,16.5 C12,15.6715729 11.3284271,15 10.5,15 C9.67157288,15 9,15.6715729 9,16.5 C9,17.3284271 9.67157288,18 10.5,18 Z"/>
            </Svg>
        )
    },
    Icon_Bind : (props) => {
        return (
            <Svg width="7" height={props.size || 16} viewBox="0 0 7 16">
                <path d="M2.32 5.85h-.64v-4.3h3.9V5.9H4.9v.8h1.6V.6H.77v6.1H2.3m3.1 3.4v3.86H1.9V10.1h.53V8.98H.8v6.1h5.7v-6.1H4.87v1.12" />
                <path d="M4.1 5.03v5.8h-.95v-5.8" />
            </Svg>
        )
    },
    Icon_Events : (props) => {
        return (
            <Svg height={props.size || 16}  style={props.style}>
                  <path d="M11.86 14H5.5a.5.5 0 0 1-.4-.8l8-11c.32-.44 1.02-.11.89.42L12.14 10h6.36c.4 0 .64.46.4.8l-8 11c-.32.44-1.02.11-.89-.42L11.86 14zm.53-9.12L6.5 13h6.01c.33 0 .56.3.49.62l-1.38 5.5L17.5 11H11.5a.5.5 0 0 1-.49-.62l1.38-5.5z"/>
            </Svg>
        )
    },
    Icon_DotMenu : (props) => {
        return (
            <Svg height={props.size || 24}  style={props.style}>
                <path  d="M12,16A2,2 0 0,1 14,18A2,2 0 0,1 12,20A2,2 0 0,1 10,18A2,2 0 0,1 12,16M12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12A2,2 0 0,1 12,10M12,4A2,2 0 0,1 14,6A2,2 0 0,1 12,8A2,2 0 0,1 10,6A2,2 0 0,1 12,4Z" />
            </Svg>
        )
    },
    Icon_Wizard : (props) => {
        return (
            <Svg height={props.size || 24} >
                <path d="M7.5 5.6L5 7l1.4-2.5L5 2l2.5 1.4L10 2 8.6 4.5 10 7 7.5 5.6m12 9.8L22 14l-1.4 2.5L22 19l-2.5-1.4L17 19l1.4-2.5L17 14l2.5 1.4M22 2l-1.4 2.5L22 7l-2.5-1.4L17 7l1.4-2.5L17 2l2.5 1.4L22 2m-8.66 10.78l2.44-2.44-2.12-2.12-2.44 2.44 2.12 2.12m1.03-5.49l2.34 2.34a1 1 0 0 1 0 1.41L5.04 22.71a1 1 0 0 1-1.41 0l-2.34-2.34a1 1 0 0 1 0-1.41L12.96 7.29a1 1 0 0 1 1.41 0z"/>
            </Svg>
        )
    },
    Icon_Command : (props) => {
        return (
            <Svg height={props.size || 24} >
                 <path fill={CurrentTheme.theme.icon} d="M15 15H9V9.02L15 9V15zM9 6.5C9 5.67 8.33 5 7.5 5h-1C5.65 5 5 5.67 5 6.5v1.02c0 .83.66 1.5 1.5 1.5H9V6.5zM6.5 15c-.84 0-1.5.67-1.5 1.5v1.03c0 .82.66 1.5 1.5 1.5h1c.83 0 1.5-.68 1.5-1.5V15H6.5zm8.5 2.5c0 .84.67 1.5 1.5 1.5h1c.83 0 1.5-.66 1.5-1.5v-1c0-.83-.67-1.5-1.5-1.5H15v2.5zM17.5 9c.83 0 1.5-.67 1.5-1.5v-1c0-.83-.67-1.5-1.5-1.5h-1c-.83 0-1.5.67-1.5 1.5V9h2.5zM10 10.02V14h4v-4l-4 .02zM17.5 14a2.5 2.5 0 0 1 2.5 2.5v1a2.5 2.5 0 0 1-2.5 2.5h-1a2.5 2.5 0 0 1-2.5-2.5V14h3.5zM10 17.53a2.5 2.5 0 0 1-2.5 2.5h-1a2.5 2.5 0 0 1-2.5-2.5V16.5A2.5 2.5 0 0 1 6.5 14H10v3.53zm0-7.51H6.5A2.5 2.5 0 0 1 4 7.52V6.5A2.5 2.5 0 0 1 6.5 4h1A2.5 2.5 0 0 1 10 6.5v3.52zm4-3.52A2.5 2.5 0 0 1 16.5 4h1A2.5 2.5 0 0 1 20 6.5v1a2.5 2.5 0 0 1-2.5 2.5H14V6.5z"/>
            </Svg>
        )
    },
    Icon_Image : (props) => {
        return (
            <Svg width={props.size || [45, 90][props.viewMode || 0]} {...props} viewBox="0 0 45 45">
                <g fill={props.fill}>
                    <path d="M43.22.188H9.47c-.94 0-1.83.843-1.83 1.78v5.438l-6.187 1.64C.563 9.282.093 10.22.328 11.11l8.72 32.437c.233.89 1.17 1.453 2.06 1.22l28.126-7.5h4.032c.937 0 1.547-.798 1.547-1.736V1.97c-.047-.94-.657-1.782-1.594-1.782zM9.223 2.298h33.912V35.67H9.225V2.297z" />
                    <circle cx="21.281" cy="12.328" r="3.563" />
                    <path d="M35.578 20.906c-3.844-5.343-9.234 1.782-10.406 3.563-1.172 1.78-3.985 0-6.422-1.08-2.438-1.08-5.39 7.405-5.39 7.405l26.062.14s0-4.64-3.844-10.03z" />
                </g>
            </Svg>
        )
    },
    Icon_Icon : (props) => {
        return (
            <Svg width={props.size ? props.size : 45} {...props} viewBox="0 0 45 45">
                <g fill={card_icon_fill(props)} fillRule="evenodd">
                    <path fillRule="nonzero" d="M41.37 0H28.307c-2 0-3.63 1.628-3.63 3.63v13.064c0 2 1.63 3.63 3.63 3.63H41.37c2.002 0 3.63-1.63 3.63-3.63V3.63C45 1.627 43.372 0 41.37 0zm2.178 16.694c0 1.2-.977 2.177-2.177 2.177H28.308c-1.2 0-2.177-.975-2.177-2.175V3.63c0-1.2.976-2.178 2.176-2.178H41.37c1.2 0 2.178.977 2.178 2.177v13.063zm-2.177 7.983H28.308c-2 0-3.63 1.628-3.63 3.63V41.37c0 2.002 1.63 3.63 3.63 3.63H41.37c2.002 0 3.63-1.628 3.63-3.63V28.307c0-2-1.628-3.63-3.63-3.63zm2.18 16.693c0 1.2-.977 2.178-2.177 2.178H28.307c-1.2 0-2.177-.977-2.177-2.177V28.308c0-1.2.976-2.177 2.176-2.177H41.37c1.2 0 2.178.976 2.178 2.176V41.37zM16.694 24.678H3.63c-2.002 0-3.63 1.628-3.63 3.63V41.37C0 43.373 1.628 45 3.63 45h13.064c2 0 3.63-1.628 3.63-3.63V28.307c0-2-1.63-3.63-3.63-3.63zM18.87 41.37c0 1.2-.976 2.178-2.176 2.178H3.63c-1.2 0-2.178-.977-2.178-2.177V28.308c0-1.2.977-2.177 2.177-2.177h13.063c1.2 0 2.177.976 2.177 2.176V41.37zM16.695 0H3.63C1.627 0 0 1.628 0 3.63v13.064c0 2 1.628 3.63 3.63 3.63h13.064c2 0 3.63-1.63 3.63-3.63V3.63c0-2.002-1.63-3.63-3.63-3.63zm2.177 16.694c0 1.2-.976 2.177-2.176 2.177H3.63c-1.2 0-2.178-.975-2.178-2.175V3.63c0-1.2.977-2.178 2.177-2.178h13.063c1.2 0 2.177.977 2.177 2.177v13.063zM5.076 31.034l1.282-1.28c.316-.317.827-.317 1.143 0l2.66 2.66 2.664-2.66c.315-.317.827-.317 1.143 0l1.28 1.28c.317.316.317.828 0 1.143l-2.66 2.662 2.66 2.66c.317.315.317.827 0 1.142l-1.28 1.282c-.316.315-.828.315-1.143 0l-2.662-2.662-2.66 2.662c-.316.315-.827.315-1.143 0l-1.283-1.282c-.315-.315-.315-.827 0-1.143l2.662-2.66-2.663-2.664c-.315-.315-.315-.827 0-1.143zM33.986 4.61h1.702c.42 0 .76.34.76.76v3.534h3.535c.42 0 .76.34.76.76v1.702c0 .42-.34.76-.76.76l-3.534-.002v3.535c0 .417-.34.757-.76.757h-1.7c-.42 0-.76-.34-.76-.76v-3.534h-3.537c-.42 0-.76-.34-.76-.76V9.66c0-.418.34-.758.76-.757h3.534V5.368c0-.42.34-.758.758-.758z" />
                    <rect width="10" height="10" x="5" y="5" rx="1" />
                    <circle cx="35" cy="35" r="5" />
                </g>
            </Svg>
        )
    },
    Color_Icon: (props) => {
        return (
            <Svg width={props.size || [25, 40, 60][props.viewMode] || 25} {...props} viewBox="0 0 25 25">
                <g fill={card_icon_fill(props)}>
                    <g stroke={card_icon_fill(props)}>
                        <path d="M7.172 5.807C8.3 4.98 9.627 4.41 11.07 4.187v-2.72c-2.19.253-4.188 1.113-5.83 2.41l1.93 1.93z" />
                        <path d="M3.64 11.612c.222-1.44.792-2.768 1.62-3.894l-1.93-1.93C2.032 7.43 1.17 9.43.915 11.613H3.64z" opacity=".98" />
                        <path d="M5.26 18.208c-.828-1.126-1.4-2.453-1.62-3.895H.915c.254 2.185 1.117 4.183 2.415 5.824l1.93-1.93z" opacity=".94" />
                        <path d="M17.778 5.807c-1.127-.828-2.455-1.398-3.897-1.62v-2.72c2.19.253 4.19 1.115 5.83 2.412l-1.93 1.93z" opacity=".7" />
                        <path d="M17.67 20.118c-1.127.828-2.455 1.398-3.898 1.618v2.724c2.187-.254 4.187-1.117 5.828-2.413l-1.93-1.93z" opacity=".85" />
                        <path d="M7.064 20.118c1.127.828 2.454 1.398 3.897 1.618v2.724c-2.18-.254-4.18-1.117-5.82-2.413l1.93-1.93z" opacity=".88" />
                        <path d="M21.177 11.612c-.22-1.44-.79-2.768-1.62-3.894l1.93-1.93c1.298 1.64 2.16 3.64 2.415 5.824h-2.725z" opacity=".75" />
                        <path d="M19.557 18.208c.83-1.126 1.4-2.453 1.62-3.895h2.725c-.254 2.185-1.117 4.183-2.414 5.824l-1.93-1.93z" opacity=".79" />
                    </g>
                    <path d="M8.557 17.122l-.373-.373.002-.04c0-.03.027-.56.27-.81.084-.088.128-.228.18-.39.067-.22.145-.467.35-.67l3.506-3.506 1.472 1.47-3.507 3.505c-.204.204-.452.282-.67.35-.164.05-.304.095-.388.18-.25.242-.78.27-.81.27h-.04zm-.194-.44l.263.26c.16-.012.5-.067.65-.215.114-.115.28-.167.458-.222.21-.066.426-.134.6-.307l3.382-3.38-1.224-1.224-3.383 3.38c-.18.174-.25.39-.31.6-.06.176-.11.343-.22.458-.15.148-.21.49-.22.65zM14.68 12.7c-.114.114-.297.114-.41 0l-1.662-1.66c-.113-.112-.113-.296 0-.408l.57-.57c.113-.112.296-.112.41 0l1.66 1.66c.113.114.113.297 0 .41l-.57.57zm-.732-2.685l1.007-1.007c.372-.372.976-.372 1.348 0 .373.373.373.976 0 1.348l-1.006 1.006-1.35-1.347z" />
                    <path d="M10.15 14.304l-.763.763c-.128.128-.183.305-.242.492-.062.19-.127.4-.287.56-.055.05-.103.21-.127.37l.09.08c.16-.03.32-.07.37-.13.16-.16.37-.228.57-.29.188-.06.365-.115.493-.243l1.61-1.61h-1.71z" />
                </g>
            </Svg>
        )
    },
    Gradient_Icon: (props) => {
        return (
            <Svg width={props.size || [25, 40, 60][props.viewMode] || 25} {...props} viewBox="0 0 21 21">
                <g fill={card_icon_fill(props)}>

                    <path d='M21 .5V21H0V0h21v.5zM20 1H1v19h19V1z' fill='#898A8C' />
                    <path fill='#898A8C' d='M2 14.001h2.667v6.005H2z' />
                    <path fill='#ACACAD' d='M2.324 9.333h2.343v2.334H2.324z' />
                    <path fill='#B9B9BB' d='M4.667 7H7v2.333H4.667zM9.333 7h2.333v2.333H9.333zM14 7h2.333v2.333H14z'
                    />
                    <path fill='#ACACAD' d='M7 9.333h2.333v2.333H7zM11.667 9.333H14v2.333h-2.333zM16.333 9.333h2.373v2.334h-2.373z'
                    />
                    <path fill='#898A8C' d='M4.59 17.518H7V20H4.59z' />
                    <path fill='#898A8C' d='M.59 17H21v4H.59z' />
                    <path fill='#898A8C' d='M9.333 17.518h2.423V20H9.333zM14 17.518h2.437V20H14z'
                    />
                    <path fill='#9F9FA1' d='M4.66 11.667H7v3.5H4.66zM9.333 11.667h2.333v3.5H9.333zM14 11.667h2.333v3.5H14z'
                    />
                    <path fill='#898A8C' d='M6.993 14.001h2.359v6.005H6.993zM11.66 13.993l2.34.008v6.006l-2.34-.008zM16.322 14.001h2.385v6.006h-2.385z'
                    />


                </g>
            </Svg>
        )
    },
    Sound_Icon: (props) => {
        return (
            <Svg width={props.size || [25, 40, 60][props.viewMode] || 25} {...props} viewBox="0 0 18 15">
                <g fill={card_icon_fill(props)}>

                    <path d='M12.747 4L12 4.906c.561.528.884 1.291.884 2.094 0 .803-.323 1.566-.884 2.094l.747.906C13.544 9.243 14 8.154 14 7.01c0-1.144-.456-2.233-1.253-2.99V4zM15.83 2.02l-.83.904a5.582 5.582 0 0 1 1.77 4.086A5.582 5.582 0 0 1 15 11.097l.83.903A6.822 6.822 0 0 0 18 7a6.822 6.822 0 0 0-2.17-5v.02zM9.07.105l-5.01 3.95H.607c-.335 0-.606.29-.606.65v5.614c0 .358.271.649.606.649h3.455L9.07 14.9c.185.125.42.133.612.021A.657.657 0 0 0 10 14.36V.645a.659.659 0 0 0-.303-.562.572.572 0 0 0-.626.022z'
                        fill='#B9B9BB' />
                </g>
            </Svg>
        )
    },
    Content_Icon: (props) => {
        return (
            <Svg width={props.size || [25, 40, 60][props.viewMode] || 25} {...props} viewBox="0 0 18 19">
                <g fill={card_icon_fill(props)}>

                    <path d='M14.479 3.06c-.016 3.284-.03 5.258-.03 8.526 0 .12.014.256.06.377.367 1.1.75 2.2 1.116 3.284.03.09.061.18.123.24.091.121.198.121.275 0 .046-.074.092-.18.122-.27l.963-2.757c.107-.331.245-.648.245-1.024 0-3.194.015-5.077.03-8.285v-.106c-.963 0-1.926-.015-2.89-.015-.014 0-.014.015-.014.03zm2.293 8.617c-.015.06-.046.12-.061.196-.153.451-.321.903-.474 1.355-.03.106-.077.136-.168.136-.138-.015-.26 0-.398-.015-.03 0-.092-.045-.107-.076-.183-.527-.351-1.039-.535-1.566v-.045c.566 0 1.147.015 1.743.015zM17.368 2.247c-.978 0-1.926-.015-2.89-.015 0-.015-.015-.045-.015-.075V1.072c0-.437.245-.723.612-.723h1.682c.367 0 .611.286.611.723.016.407.016.798 0 1.175zM.046 4.16h12.889v1.536H.046zM.046 8.544h3.853v1.536H.046zM5.382 8.544h7.553v1.536H5.382zM.046 12.927h12.889v1.536H.046zM5.978 17.296h11.406v1.536H5.978zM.046 17.296h3.776v1.536H.046z'
                        fill='#B9B9BB' />
                </g>
            </Svg>
        )
    },
    Size_Icon: (props) => {
        return (
            <Svg width={props.size || 22} {...props} viewBox="0 0 28 28">
                <path d="M.672.112c-.313 0-.56.247-.56.56v20.173c0 2.78 2.264 5.043 5.043 5.043 2.78 0 5.043-2.264 5.043-5.043V.672c0-.313-.246-.56-.56-.56H.672zm.56 1.12H9.08v4.035H1.233V1.233zM16.23 2.668l-4.685 4.685v1.59L16.23 4.26l5.536 5.535-10.22 10.243v.807c0 .27-.023.56-.046.83l4.707-4.708h8.585v7.845H10.22v-.045c-.358.426-.762.83-1.21 1.166h16.878V15.846h-8.585l6.052-6.05-7.127-7.13zM1.233 6.388h7.845v4.26H1.233v-4.26zm0 5.38h7.845V15.8H1.233v-4.035zm0 5.154h7.845v3.923c0 2.152-1.77 3.922-3.923 3.922-2.152 0-3.922-1.77-3.922-3.922v-3.923zm3.922 2.286c-.92 0-1.68.763-1.68 1.68 0 .92.76 1.683 1.68 1.683.92 0 1.68-.76 1.68-1.68 0-.92-.76-1.68-1.68-1.68zm0 1.12c.314 0 .56.248.56.56 0 .315-.246.562-.56.562-.314 0-.56-.247-.56-.56 0-.314.246-.56.56-.56z" />
            </Svg>
        )
    },
    Icon_Home : (props) => {
        return (
            <Svg width={props.size || 16} >
                <path d="M20 10.74v7.76a2.5 2.5 0 0 1-2.5 2.5h-3a.5.5 0 0 1-.5-.5v-6a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v6a.5.5 0 0 1-.5.5h-3A2.5 2.5 0 0 1 4 18.5v-7.76l-.18.15a.5.5 0 1 1-.64-.78l8.5-7a.5.5 0 0 1 .64 0l8.5 7a.5.5 0 1 1-.64.78l-.18-.15zm-1-.82l-7-5.77-7 5.77A.5.5 0 0 1 5 10v8.5c0 .83.67 1.5 1.5 1.5H9v-5.5c0-.83.67-1.5 1.5-1.5h3c.83 0 1.5.67 1.5 1.5V20h2.5c.83 0 1.5-.67 1.5-1.5V10a.5.5 0 0 1 0-.08z"/>
            </Svg>
        )
    },
    Icon_Home_Filled : (props) => {
        return (
            <Svg width={props.size || 16} >
                <path  d="M10,20V14H14V20H19V12H22L12,3L2,12H5V20H10Z" />
            </Svg>
        )
    },
    Icon_Email_Sent: (props) => {
        return (
            <Svg width='70' height='29' xmlns='http://www.w3.org/2000/svg'>
                <g fill='#6CC3CE' fillRule='evenodd'>
                    <path d='M26 2.561v23.997c0 .856.719 1.578 1.571 1.578H68.43c.852 0 1.571-.722 1.571-1.578V2.56c0-.856-.719-2-1.571-2H27.57c-.852 0-1.571 1.144-1.571 2zm3.683-.671h36.634L48 18.424 29.683 1.89zm-2.303.933l12.566 10.982L27.38 25.247V2.823zm41.243 0v22.424l-12.57-11.442 12.57-10.982zM47.296 20.627c.4.364 1.009.364 1.408 0l5.81-5.392 11.868 11.459H29.618l11.867-11.459 5.811 5.392z'
                        fillRule='nonzero' />
                    <path d='M0 4h22v3H0zM5 13h16v3H5zM10 21h12v3H10z' />
                </g>
            </Svg>
        )
    },
    Icon_Check : (props) => {
        return (
            <Svg width={props.size || 16} >
                <path d="M10.5 14.8l6.65-6.65a.5.5 0 0 1 .7.7l-7 7a.5.5 0 0 1-.7 0l-3-3a.5.5 0 0 1 .7-.7l2.65 2.64z"/>
            </Svg>
        )
    },
    Icon_Attach: (props) => {
        return (
            <Svg width={props.size || 14} selected={props.selected} viewBox="0 0 26 26">
                <g fill='none' fillRule='evenodd'>
                    <path d='M0 0h26v26H0z' fill='#222326' />
                    <path stroke='#454545' d='M.5.5h25v25H.5z' />
                    <path d='M7.002 18.707c-1.676-1.677-1.542-3.813.359-5.716l6.131-6.111c.31-.31.882-.686 1.555-.805.575-.103 1.431-.055 2.24.755.834.833.908 1.657.823 2.202-.123.791-.621 1.361-.83 1.57l-6.166 6.147c-.212.212-.709.71-1.368.71-.4 0-.78-.179-1.132-.53-.314-.315-.909-1.172.302-2.384l6.127-6.133a.357.357 0 0 1 .505.504L9.421 15.05c-.83.83-.443 1.232-.307 1.368.218.218.429.326.632.326.363 0 .7-.338.863-.5l6.166-6.148c.16-.158.539-.59.63-1.173.085-.555-.123-1.089-.62-1.586-.478-.477-1.02-.664-1.613-.559-.51.091-.951.384-1.175.608l-6.131 6.11c-.945.946-2.248 2.815-.36 4.706 1.759 1.757 3.504.75 4.658-.403l5.057-5.09a.357.357 0 0 1 .507.504l-5.058 5.09c-1.93 1.929-3.995 2.076-5.668.404z'
                        fill='#BABABA' fillRule='nonzero' />
                </g>
            </Svg>
        )
    },
    FontStyle_Size : (props) => {
        return (
            <Svg width={props.size || 24} >
                <path fill={CurrentTheme.theme.icon} d="M17 12v5h.5a.5.5 0 1 1 0 1h-2a.5.5 0 1 1 0-1h.5v-5h-2v.5a.5.5 0 1 1-1 0v-1c0-.28.22-.5.5-.5h6c.28 0 .5.22.5.5v1a.5.5 0 1 1-1 0V12h-2zm-7-6v11h1.5a.5.5 0 1 1 0 1h-4a.5.5 0 1 1 0-1H9V6H5v1.5a.5.5 0 0 1-1 0v-2c0-.28.22-.5.5-.5h10c.28 0 .5.22.5.5v2a.5.5 0 1 1-1 0V6h-4z"/>
            </Svg>
        )
    },
    FontStyle_LineHeight : (props) => {
        return (
            <Svg width={props.size || 20}  style={{paddingTop : '1px'}}>
                <path fill={CurrentTheme.theme.icon} d="M19 5.7v11.6l1.15-1.15a.5.5 0 0 1 .7.7l-2 2a.5.5 0 0 1-.7 0l-2-2a.5.5 0 0 1 .7-.7L18 17.29V5.71l-1.15 1.14a.5.5 0 0 1-.7-.7l2-2c.2-.2.5-.2.7 0l2 2a.5.5 0 0 1-.7.7L19 5.71zM8 18V5H4v1.5a.5.5 0 0 1-1 0v-2c0-.28.22-.5.5-.5h10c.28 0 .5.22.5.5v2a.5.5 0 1 1-1 0V5H9v13h1.5a.5.5 0 1 1 0 1h-4a.5.5 0 1 1 0-1H8z"/>
            </Svg>
        )
    },
    FontStyle_LetterSpace : (props) => {
        return (
            <Svg width={props.size || 20} viewBox="0 0 23 22" style={{paddingTop : '3px'}}>
                <path fill={CurrentTheme.theme.icon} d="M3.29 8h4.9L5.74 1.9zm-.4 1L1 13.68a.53.53 0 0 1-.67.29.5.5 0 0 1-.3-.65l5.21-13a.53.53 0 0 1 .98 0l5.21 13a.5.5 0 0 1-.3.65.53.53 0 0 1-.67-.29L8.59 9zM21.9 6.8a.48.48 0 0 1 .05.2l-.01.2v5.3a.51.51 0 0 0 .53.5.5.5 0 1 1 0 1 1.58 1.58 0 0 1-1.32-.7 2.66 2.66 0 0 1-1.81.7h-2.09c-2 0-3.13-.86-3.13-2.5 0-1.7 1.03-2.21 3.83-2.73l.22-.04 1.04-.21c1.09-.26 1.6-.7 1.68-1.34C20.8 5.68 20 5 18.3 5c-1.83 0-2.6.5-2.6 1.5a.52.52 0 0 1-1.05 0c0-1.66 1.3-2.5 3.65-2.5 2.12 0 3.37.97 3.61 2.8zm-1 4.7V8.9a4.47 4.47 0 0 1-1.44.59c-.3.07-.61.14-1.1.23l-.21.04c-2.31.42-2.99.76-2.99 1.74 0 1.03.62 1.5 2.09 1.5h2.09a1.53 1.53 0 0 0 1.56-1.5zM18.18 20H5.82l1.22 1.15a.48.48 0 0 1 0 .7.56.56 0 0 1-.75 0l-2.13-2a.48.48 0 0 1 0-.7l2.13-2a.56.56 0 0 1 .75 0 .48.48 0 0 1 0 .7L5.82 19h12.36l-1.22-1.15a.48.48 0 0 1 0-.7.56.56 0 0 1 .75 0l2.13 2a.48.48 0 0 1 0 .7l-2.13 2a.56.56 0 0 1-.75 0 .48.48 0 0 1 0-.7z"/>
            </Svg>
        )
    },
    SocialMedia_Google : (props) => {
        return (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width={props.size || 20} viewBox="0 0 48 48"><g><path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path><path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path><path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path><path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path><path fill="none" d="M0 0h48v48H0z"></path></g>
            </svg>
        )
    },
    SocialMedia_Facebook : (props) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={props.size || 20} ><path fill="#FFF" d="M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.351c0 .731.593 1.324 1.325 1.324h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.293h6.116c.73 0 1.323-.593 1.323-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z"/>
            </svg>
        )
    },
    SocialMedia_Twitter : (props) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={props.size || 20} viewBox="0 0 28 24"><path xmlns="http://www.w3.org/2000/svg" fill="#fff" d="M30 2.9c-1.1.5-2.3.8-3.5 1 1.3-.8 2.2-2 2.7-3.4-1.2.7-2.5 1.2-3.9 1.5-1.2-1.3-2.8-2-4.5-2-3.4 0-6.2 2.8-6.2 6.2 0 .5.1 1 .2 1.4-5.1-.3-9.7-2.7-12.7-6.5-.5.9-.8 2-.8 3.1 0 2.1 1.1 4 2.7 5.1-1 0-2-.3-2.8-.7v.1c0 3 2.1 5.5 4.9 6-.5.1-1.1.2-1.6.2-.4 0-.8 0-1.2-.1.8 2.4 3.1 4.2 5.7 4.3-2.1 1.7-4.8 2.6-7.6 2.6-.5 0-1 0-1.5-.1 2.7 1.7 6 2.8 9.4 2.8 11.3 0 17.5-9.4 17.5-17.5v-.8c1.3-.9 2.4-2 3.2-3.2z"/>
            </svg>
        )
    },
    Device_Plus : (props) => {
        return (
            <Svg width={props.size || 24} >
                <path d="M19,17 L20.5,17 C20.7761424,17 21,17.2238576 21,17.5 C21,17.7761424 20.7761424,18 20.5,18 L19,18 L19,19.5 C19,19.7761424 18.7761424,20 18.5,20 C18.2238576,20 18,19.7761424 18,19.5 L18,18 L16.5,18 C16.2238576,18 16,17.7761424 16,17.5 C16,17.2238576 16.2238576,17 16.5,17 L18,17 L18,15.5 C18,15.2238576 18.2238576,15 18.5,15 C18.7761424,15 19,15.2238576 19,15.5 L19,17 Z M20.0071085,8 L4,8 L4,17.508331 C4,18.3367581 4.67157288,19.008331 5.5,19.008331 L13.5,19.008331 C13.7761424,19.008331 14,19.2321886 14,19.508331 C14,19.7844734 13.7761424,20.008331 13.5,20.008331 L5.5,20.008331 C4.11928813,20.008331 3,18.8890429 3,17.508331 L3,6.5 C3,5.11928813 4.11928813,4 5.5,4 L18.5071085,4 C19.8878203,4 21.0071085,5.11928813 21.0071085,6.5 L21.0071085,7.48426505 C21.00727,7.48949024 21.0073514,7.49473562 21.0073514,7.5 C21.0073514,7.50526438 21.00727,7.51050976 21.0071085,7.51573495 L21.0071085,12.5 C21.0071085,12.7761424 20.7832508,13 20.5071085,13 C20.2309661,13 20.0071085,12.7761424 20.0071085,12.5 L20.0071085,8 Z M20.0071085,7 L20.0071085,6.5 C20.0071085,5.67157288 19.3355356,5 18.5071085,5 L5.5,5 C4.67157288,5 4,5.67157288 4,6.5 L4,7 L20.0071085,7 Z"/>
            </Svg>
        )
    },
    Accessibility : (props) => {
        return (
            <Svg width={props.size || 24} >
                <path d="M21,9H15V22H13V16H11V22H9V9H3V7H21M12,2A2,2 0 0,1 14,4A2,2 0 0,1 12,6C10.89,6 10,5.1 10,4C10,2.89 10.89,2 12,2Z"/>
            </Svg>            
        )
    },
    FavoriteStar : (props) => {
        return (
            <Svg width={props.size || 24} >
                <path d="M12 2.95l2.234 6.926 7.277-.016-5.896 4.264 2.263 6.916-5.877-4.29-5.878 4.29 2.264-6.915L2.49 9.86l7.277.016 2.234-6.926z"/>
            </Svg>            
        )
    },
    Icon_Team : (props) => {
        return (
            <Svg width={(props.activeUserCount || props.small) ? 16 : 24} style={props.activeUserCount ? {transform : 'translateY(-8px)'} : {}}>
                <path fill="none" d="M0 0h24v24H0z"/>
                <path d="M16.67 13.13C18.04 14.06 19 15.32 19 17v3h4v-3c0-2.18-3.57-3.47-6.33-3.87z" fillRule="evenodd"/>
                <circle cx="9" cy="8" fillRule="evenodd" r="4"/>
                <path d="M15 12c2.21 0 4-1.79 4-4s-1.79-4-4-4c-.47 0-.91.1-1.33.24a5.98 5.98 0 010 7.52c.42.14.86.24 1.33.24zM9 13c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z" fillRule="evenodd"/>
            </Svg>
        )
    },
    Icon_Notification : (props) => {
        return (
            <Svg width={props.size || 24} >
                <path d="M0 0h24v24H0z" fill="none"/>
                <path d="M7.58 4.08L6.15 2.65C3.75 4.48 2.17 7.3 2.03 10.5h2c.15-2.65 1.51-4.97 3.55-6.42zm12.39 6.42h2c-.15-3.2-1.73-6.02-4.12-7.85l-1.42 1.43c2.02 1.45 3.39 3.77 3.54 6.42zM18 11c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2v-5zm-6 11c.14 0 .27-.01.4-.04.65-.14 1.18-.58 1.44-1.18.1-.24.15-.5.15-.78h-4c.01 1.1.9 2 2.01 2z"/>
            </Svg>
        )
    },
    ErrorIcons: {
        info: (props) => {
            return (
                <Svg width={props.size || "22"} height={props.size || "22"} viewBox="0 0 22 22">
                    <g fill={"none"} fillRule="evenodd" transform="translate(1 1)">
                        <circle cx="10" cy="10" r="10" stroke={props.color || "#707278"} />
                        <path fill={props.color || "#707278"} fillRule="nonzero" d="M11.03 5c-.3 0-.55.1-.75.3-.2.22-.3.47-.3.76 0 .3.1.55.3.75.2.23.46.33.75.33.3 0 .54-.1.75-.3.2-.2.3-.46.3-.76s-.1-.54-.3-.75c-.2-.2-.45-.3-.75-.3zm.45 3.32l-.03.1h.03v-.1zm-.03.1c-.95.13-1.9.2-2.85.33l-.1.42h.26c.16 0 .28.04.37.13.1.08.14.17.14.28 0 .17-.05.25-.14.58l-1 3.45c-.1.3-.13.5-.13.7 0 .27.1.5.27.67.2.18.78.26 1.1.26.8 0 2.16-.43 2.9-1.7l-.6-.34c-.3.46-.85.8-1.1.95-.27.12-.4.12-.5.12-.04 0-.1-.03-.13-.07-.05-.06-.07-.1-.07-.16 0-.1.04-.3.12-.58l1.44-5.05z" />
                    </g>
                </Svg>
            )
        },
        warning: (props) => {
            return (
                <Svg width="20" height="18" viewBox="0 0 20 18">
                    <path d="M19.65 14.65L11.8 1.05C11.4.4 10.73 0 10 0S8.57.4 8.2 1.04L.28 14.76c-.37.64-.37 1.44 0 2.08.37.64 1.05 1.03 1.8 1.03h15.86c1.14 0 2.07-.93 2.07-2.07 0-.4-.1-.8-.33-1.15zm-1.72 2.3H2.06c-.4 0-.8-.22-1-.57-.2-.36-.2-.8 0-1.16L9 1.5c.2-.36.6-.58 1-.58s.8.22 1 .58l7.87 13.63v.03c.14.2.2.4.2.64 0 .64-.5 1.15-1.14 1.15zM10 12.15c.27 0 .65-.2.67-.68l.23-5.37c0-.5-.4-.9-.9-.9s-.9.4-.9.9l.23 5.37c.03.5.37.67.67.67zm0 .75c-.3 0-.6.12-.82.34-.2.2-.33.5-.33.8 0 .32.12.6.33.83.22.2.52.33.82.33.3 0 .6-.12.8-.33.23-.22.35-.5.35-.82 0-.3-.12-.6-.34-.8-.2-.23-.5-.35-.8-.35z" />
                </Svg>
            )
        },
        success: (props) => {
            return (
                <Svg width="22" height="22" viewBox="0 0 22 22">
                    <g fill="none" fillRule="evenodd" transform="translate(1 1)">
                        <circle cx="10" cy="10" r="10" stroke="#000" />
                        <path fill="#000" fillRule="nonzero" d="M6.27 9.3L5 10.63l3.25 3.26c.4.34 1.03.3 1.37-.1l5.18-6.7L13.34 6l-4.56 5.86-2.5-2.57z" />
                    </g>
                </Svg>
            )
        },
        error: (props) => {
            return (
                <Svg width="22" height="22" viewBox="0 0 22 22">
                    <g fill="none" fillRule="evenodd" stroke="#000" transform="translate(1 1)">
                        <circle cx="10" cy="10" r="10" />
                        <g fill="#000">
                            <path d="M12.95 6.48l.7.72-6.8 6.56-.7-.72z" />
                            <path d="M6.8 6.48l-.68.72 6.8 6.56.68-.72z" />
                        </g>
                    </g>
                </Svg>
            )
        }
    },
    Flex : {
        Flex_Start : (props) => {
            return (
                <Svg width={props.size || 24} >
                    <path d="M20 15.5c0-.83-.67-1.5-1.5-1.5h-10c-.83 0-1.5.67-1.5 1.5v1c0 .83.67 1.5 1.5 1.5h10c.83 0 1.5-.67 1.5-1.5v-1zm1 0v1a2.5 2.5 0 0 1-2.5 2.5h-10A2.5 2.5 0 0 1 6 16.5v-1A2.5 2.5 0 0 1 8.5 13h10a2.5 2.5 0 0 1 2.5 2.5zm-3-8v1a2.5 2.5 0 0 1-2.5 2.5h-7A2.5 2.5 0 0 1 6 8.5v-1A2.5 2.5 0 0 1 8.5 5h7A2.5 2.5 0 0 1 18 7.5zm-1 0c0-.83-.67-1.5-1.5-1.5h-7C7.67 6 7 6.67 7 7.5v1c0 .83.67 1.5 1.5 1.5h7c.83 0 1.5-.67 1.5-1.5v-1zm-14-4a.5.5 0 0 1 1 0v17a.5.5 0 1 1-1 0v-17z"/>
                </Svg>
            )
        },
        Flex_Center : (props) => {
            return (
                <Svg width={props.size || 24} >
                    <path d="M7 8.5c0 .83.67 1.5 1.5 1.5h8c.83 0 1.5-.67 1.5-1.5v-1c0-.83-.67-1.5-1.5-1.5h-8C7.67 6 7 6.67 7 7.5v1zM13 19v1.5a.5.5 0 1 1-1 0V19H6.5A2.5 2.5 0 0 1 4 16.5v-1A2.5 2.5 0 0 1 6.5 13H12v-2H8.5A2.5 2.5 0 0 1 6 8.5v-1A2.5 2.5 0 0 1 8.5 5H12V3.5a.5.5 0 1 1 1 0V5h3.5A2.5 2.5 0 0 1 19 7.5v1a2.5 2.5 0 0 1-2.5 2.5H13v2h5.5a2.5 2.5 0 0 1 2.5 2.5v1a2.5 2.5 0 0 1-2.5 2.5H13zm-8-2.5c0 .83.67 1.5 1.5 1.5h12c.83 0 1.5-.67 1.5-1.5v-1c0-.83-.67-1.5-1.5-1.5h-12c-.83 0-1.5.67-1.5 1.5v1z"/>
                </Svg>
            )
        },
        Flex_End : (props) => {
            return (
                <Svg width={props.size || 24} >
                    <path d="M4 15.5v1c0 .83.67 1.5 1.5 1.5h10c.83 0 1.5-.67 1.5-1.5v-1c0-.83-.67-1.5-1.5-1.5h-10c-.83 0-1.5.67-1.5 1.5zm-1 0A2.5 2.5 0 0 1 5.5 13h10a2.5 2.5 0 0 1 2.5 2.5v1a2.5 2.5 0 0 1-2.5 2.5h-10A2.5 2.5 0 0 1 3 16.5v-1zm3-8A2.5 2.5 0 0 1 8.5 5h7A2.5 2.5 0 0 1 18 7.5v1a2.5 2.5 0 0 1-2.5 2.5h-7A2.5 2.5 0 0 1 6 8.5v-1zm1 0v1c0 .83.67 1.5 1.5 1.5h7c.83 0 1.5-.67 1.5-1.5v-1c0-.83-.67-1.5-1.5-1.5h-7C7.67 6 7 6.67 7 7.5zm13-4a.5.5 0 1 1 1 0v17a.5.5 0 1 1-1 0v-17z"/>
                </Svg>
            )
        },
        Flex_Stretch : (props) => {
            return (
                <Svg width={props.size || 24}  style={props.style} >
                    <path d="M5 11.5v1c0 .83.67 1.5 1.5 1.5h11c.83 0 1.5-.67 1.5-1.5v-1c0-.83-.67-1.5-1.5-1.5h-11c-.83 0-1.5.67-1.5 1.5zm-1 0A2.5 2.5 0 0 1 6.5 9h11a2.5 2.5 0 0 1 2.5 2.5v1a2.5 2.5 0 0 1-2.5 2.5h-11A2.5 2.5 0 0 1 4 12.5v-1zM3.5 4a.5.5 0 0 1 0-1h17a.5.5 0 1 1 0 1h-17zm0 17a.5.5 0 1 1 0-1h17a.5.5 0 1 1 0 1h-17z"/>
                </Svg>
            )
        },
        SameWidth : (props) => {
            return (
                <Svg width={props.size || 24}  style={props.style} >
                    <path d="M17.293 12H6.707l3.147 3.146a.5.5 0 0 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L6.707 11h10.586l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L17.293 12zM20 3.5a.5.5 0 1 1 1 0v16a.5.5 0 1 1-1 0v-16zm-17 0a.5.5 0 0 1 1 0v16a.5.5 0 1 1-1 0v-16z"/>
                </Svg>
            )
        },
        SameHeight : (props) => {
            return (
                <Svg width={props.size || 24}  style={props.style} >
                    <path d="M12 6.707L8.854 9.854a.5.5 0 0 1-.708-.708l4-4a.5.5 0 0 1 .708 0l4 4a.5.5 0 0 1-.708.708L13 6.707v10.586l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L12 17.293V6.707zM5.5 21a.5.5 0 1 1 0-1h14a.5.5 0 1 1 0 1h-14zm0-17a.5.5 0 0 1 0-1h14a.5.5 0 1 1 0 1h-14z"/>
                </Svg>
            )
        },
    },
    Figma : (props) => {
        return (
            <Svg width={props.size || "12"} fill="none" viewBox="0 0 12 18">
                <path d="M6 9a3 3 0 016 0 3 3 0 01-6 0z" fill="#1ABCFE"/>
                <path d="M0 15a3 3 0 013-3h3v3a3 3 0 11-6 0z" fill="#0ACF83"/>
                <path d="M6 0v6h3a3 3 0 100-6H6z" fill="#FF7262"/>
                <path d="M0 3a3 3 0 003 3h3V0H3a3 3 0 00-3 3z" fill="#F24E1E"/>
                <path d="M0 9a3 3 0 003 3h3V6H3a3 3 0 00-3 3z" fill="#A259FF"/>
            </Svg>
        )
    },
    FontError : (props) => {
        return (
            <Svg width={props.size || "24"} fill="none" viewBox="0 0 24 24" style={props.style}>
                <rect width="19" height="16" x="2" y="4" fill="#FFEB00" rx="4"></rect>
                <path
                    fill="#000"
                    fillOpacity="0.8"
                    fillRule="evenodd"
                    d="M16.064 13.7H15c0-1.526.269-2.021 1.15-2.49.493-.263.905-.624.905-1.202 0-.692-.414-1.133-1.204-1.133-.789 0-1.216.305-1.262 1.133h-1.092c.05-1.406.852-2.117 2.354-2.117 1.503 0 2.275.847 2.275 2.117 0 .855-.517 1.55-1.218 1.992-.73.46-.845.56-.845 1.7zm.223 1.558a.781.781 0 11-1.562 0 .782.782 0 011.562 0zM5.027 16l3.11-8H9.17l3.111 8h-1.019l-.787-2.025h-3.64L6.046 16h-1.02zm3.627-6.705l1.45 3.73h-2.9l1.45-3.73z"
                    clipRule="evenodd"
                ></path>
            </Svg>
        )
    }
}
