import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../../importer';
import { AnimatePresence, motion } from 'framer-motion';

import TextInput from '../../../../components/editors/textinput';
import DropDownSelect from '../../../../components/editors/enum_dropdown';
import { Seperator, PropertyLabel, StyleGroupTokenItem } from '../../panels/right/iteminspector/styleitems/common';
import SystemStatePanel from '../../panels/left/systemstates';
import { TokenPanelHeader } from '../../panels/left/designsystem/common';
import Switch from '../../../../components/editors/Switch';
import MultipleTokenSelector from '../../panels/left/designsystem/groupmembers';
import MultipleTokenGroupSelector from '../../panels/left/designsystem/groupmembers/groups';
import SelectTokenOptions from '../../panels/left/designsystem/groupmembers/selectOptions';

export default class ExportOptionsPanel extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.ChangeTarget = this.ChangeTarget.bind(this);        
        this.ChangeNameFormat = this.ChangeNameFormat.bind(this);
        this.onChangeFileName = this.onChangeFileName.bind(this);        
        this.ChnagePrefix = this.ChnagePrefix.bind(this);        
        this.RenderNameFormat = this.RenderNameFormat.bind(this);
    
        this.SetTokenSelection = this.SetTokenSelection.bind(this);          
        this.onSortItems = this.onSortItems.bind(this);            

        this.SelectTokens = this.SelectTokens.bind(this);                
        this.SelectGroups = this.SelectGroups.bind(this);        
        this.SelectAliases = this.SelectAliases.bind(this);
        this.SelectAliaseGroups = this.SelectAliaseGroups.bind(this);
        
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.onAliaseSelectionChanged = this.onAliaseSelectionChanged.bind(this);
        this.onTokenGroupSelectionChanged = this.onTokenGroupSelectionChanged.bind(this);
        this.onAliaseGroupSelectionChanged = this.onAliaseGroupSelectionChanged.bind(this);
    }

    ChangeTarget(target) {
        this.props.options.target = target;
        this.props.onSaveOptions();
    }
    ChangeNameFormat(nameFormat) {
        Utils.Set(this.props.options, nameFormat, this.props.options.target, 'nameFormat');
        Utils.Get(this.props.options, nameFormat, 'allTargets', 'nameFormat');
        this.props.onSaveOptions();
    }
    RenderNameFormat(item, isSelected) {
        return (
            <SC.FRow>
                <div style={{flex : 1}}>{item.label}</div>
                <div style={{flex : 1, letterSpacing : '0.1em', fontWeight : 500}}>{Utils.FormatName('token name', item.id)}</div>
            </SC.FRow>
        )
    }
    onChangeFileName(fileName) {
        Utils.Set(this.props.options, fileName, this.props.options.target, 'fileName');
        Utils.Get(this.props.options, fileName, 'allTargets', 'fileName');
        this.props.onSaveOptionsSilent();
    }
    ChnagePrefix(prefix) {
        Utils.Set(this.props.options, prefix, this.props.options.target, 'prefix');
        Utils.Get(this.props.options, prefix, 'allTargets', 'prefix');
        this.props.onSaveOptions();
    }
    
    SetTokenSelection(value) {

        let action, option;
        if (value === 'selected') {                                    
            option = value;
            action = this.SelectTokens;
        }
        else if (value === 'groups') {
            option = value;
            action = this.SelectGroups;
        }
        else if (value === 'aliases') {
            option = value;
        }
        else if (value === 'selectedaliases') {
            option = value;
            action = this.SelectAliases;
        }  
        else if (value === 'aliaseGroups') {
            option = value;
            action = this.SelectAliaseGroups;
        }            
        else {
            option = 'all';
        }

        Utils.Set(this.props.options, option, 'TokenTypeOptions', 'allTargets', this.props.tokenOptions, 'data', 'tokens', 'selection');
        this.props.onSaveOptions();
        if (action)
            action();
        else
            this.RCUpdate();
    }    
    onSortItems(prop, selectedTokens) {
        Utils.Set(this.props.options, selectedTokens, 'TokenTypeOptions', 'allTargets', this.props.tokenOptions, 'data', prop, 'ids');
        this.props.onSaveOptions();
        this.RCUpdate();
    }
    SelectTokens() {
        this.ShowSelectTokens = !this.ShowSelectTokens;
        this.RCUpdate();
    }
    SelectGroups() {
        this.ShowSelectGroups = !this.ShowSelectGroups;
        this.RCUpdate();
    }
    SelectAliaseGroups() {
        this.ShowSelectAliseGroups = !this.ShowSelectAliseGroups;
        this.RCUpdate();
    }
    SelectAliases() {
        this.ShowSelectAlises = !this.ShowSelectAlises;
        this.RCUpdate();
    }    
    
    onSelectionChanged(selectedTokens) {
        Utils.Set(this.props.options, selectedTokens, 'TokenTypeOptions', 'allTargets', this.props.tokenOptions, 'data', 'tokens', 'ids');
        this.props.onSaveOptions();
        this.RCUpdate();
    }
    onAliaseSelectionChanged(selectedTokens) {
        Utils.Set(this.props.options, selectedTokens, 'TokenTypeOptions', 'allTargets', this.props.tokenOptions, 'data', 'aliases', 'ids');
        this.props.onSaveOptions();
        this.RCUpdate();
    }
    onTokenGroupSelectionChanged(selectedGroups) {
        Utils.Set(this.props.options, selectedGroups, 'TokenTypeOptions', 'allTargets', this.props.tokenOptions, 'data', 'groups', 'ids');
        this.props.onSaveOptions();
        this.RCUpdate();
    }
    onAliaseGroupSelectionChanged(selectedGroups) {
        Utils.Set(this.props.options, selectedGroups, 'TokenTypeOptions', 'allTargets', this.props.tokenOptions, 'data', 'aliaseGroups', 'ids');
        this.props.onSaveOptions();
        this.RCUpdate();
    }

    renderCustom() {            
        let content;
        if (this.props.tokenOptions) {
            const tokenTypeOptions = Utils.JustGet(this.props.options, {}, 'TokenTypeOptions', 'allTargets', this.props.tokenOptions);

            const selection = Utils.JustGet(tokenTypeOptions, 'all', 'data', 'tokens', 'selection');
            const tokenType = this.props.tokenOptions;
            const subType = Utils.JustGet(tokenTypeOptions, '', 'data', 'subType');
            let groupIds = [];
            if (selection === 'all' || selection === 'selected' || selection === 'groups') {
                groupIds = Utils.JustGet(tokenTypeOptions, [], 'data', 'groups', 'ids');
            }            
            else if (selection === 'aliases' || selection === 'selectedaliases' || selection === 'aliaseGroups') {
                groupIds = Utils.JustGet(tokenTypeOptions, [], 'data', 'aliaseGroups', 'ids');
            }

            
            let selectionProps = {
                  
            };
            selectionProps.Type = MultipleTokenSelector;

            if (this.ShowSelectTokens) {
                selectionProps.header = {
                    title : 'SELECT TOKENS',
                    onClose : this.SelectTokens
                };
                selectionProps.body = {
                    selectedTokens : Utils.JustGet(tokenTypeOptions, [], 'data', 'tokens', 'ids'),
                    onSelectionChanged : this.onSelectionChanged
                };
            }
            else if (this.ShowSelectAlises) {
                selectionProps.header = {
                    title : 'SELECT SEMANTIC ALIASES',
                    onClose : this.SelectAliases
                };
                selectionProps.body = {
                    selectedTokens : Utils.JustGet(tokenTypeOptions, [], 'data', 'aliases', 'ids'),
                    onSelectionChanged : this.onAliaseSelectionChanged,
                    isAliase : true,
                };
            }       
            else if (this.ShowSelectGroups) {
                selectionProps.header = {
                    title : 'SELECT TOKEN GROUPS',
                    onClose : this.SelectGroups
                };
                selectionProps.body = {
                    selectedGroups : groupIds,
                    onSelectionChanged : this.onTokenGroupSelectionChanged
                };
                selectionProps.Type = MultipleTokenGroupSelector;
            }
            else if (this.ShowSelectAliseGroups) {
                selectionProps.header = {
                    title : 'SELECT ALIASE GROUPS',
                    onClose : this.SelectAliaseGroups
                };
                selectionProps.body = {
                    selectedGroups : groupIds,
                    onSelectionChanged : this.onAliaseGroupSelectionChanged,
                    isAliase : true
                };          
                selectionProps.Type = MultipleTokenGroupSelector;
            }
            else {
                selectionProps.header = {
                    title : 'TOKEN OPTIONS',
                    onClose : this.props.onCloseTokenOptions
                };
                selectionProps.content = (
                    <SC.FCol f1 style={{padding : '8px'}}>
                        <SelectTokenOptions 
                            selection={selection}
                            tokenType={tokenType}
                            subType={subType}
                            onOptionsChanged={this.SetTokenSelection}
                            onOrderChanged={this.onSortItems}
                            onSelectTokens={this.SelectTokens} 
                            onSelectGroups={this.SelectGroups} 
                            onSelectAliases={this.SelectAliases} 
                            onAliaseGroups={this.SelectAliaseGroups} 
                            groupIds={groupIds}
                            GetSelectedTokens={() => {
                                if (selection === 'all' || selection === 'selected' || selection === 'groups') {
                                    return Utils.JustGet(tokenTypeOptions, [], 'data', 'tokens', 'ids');
                                }
                                else if (selection === 'aliases' || selection === 'selectedaliases' || selection === 'aliaseGroups') {
                                    return Utils.JustGet(tokenTypeOptions, [], 'data', 'aliases', 'ids');
                                }
                                return [];
                            }}
                        />
                    </SC.FCol>
                )
            }    
            
            if (!selectionProps.content) {
                selectionProps.content = (
                    <SC.FCol f1>
                        <selectionProps.Type                             
                            tokenType={tokenType}
                            subType={subType}       
                            {...selectionProps.body}                 
                        />
                    </SC.FCol>                    
                )
            }
            
            content = (
                <motion.div 
                    style={{...SC.Styles.Absolute, zIndex : 10000, display : 'flex', flexDirection : 'column'}}
                    initial={{opacity : 0.7, x : 24}}
                    animate={{opacity : 1, x : 0}}
                    exit={{opacity : 0, x : 24}}
                    transition={{duration : 0.2}}
                >
                    <SC.BackClosableTitle 
                        style={{
                            padding : '4px',
                            borderBottom : SC.CurrentTheme.theme.border_ondark,
                            backgroundColor : SC.CurrentTheme.theme.back_lighter
                        }} 
                        {...selectionProps.header}
                    />
                    {selectionProps.content}
                </motion.div>
            )
        }
        else {
            content = (
                <React.Fragment>
                    <SC.HeaderTitle style={{
                        padding : '8px',
                        borderBottom : SC.CurrentTheme.theme.border_ondark,
                        backgroundColor : SC.CurrentTheme.theme.back_lighter
                    }}>
                        EXPORT OPTIONS
                    </SC.HeaderTitle>
                    <SC.FCol
                        style={{                        
                            padding : '8px',
                            fontSize : '11px'
                        }}
                    >
                        <SC.FRow alc>
                            <div style={{flex : 1}}>Format</div>
                            <DropDownSelect 
                                items={TOKEN_EXPORT_TARGETS}
                                style={{flex : 1}}
                                value={this.props.options.target}
                                onChange={this.ChangeTarget}
                                hasBorder            
                                autoHeight
                                xsmall
                                toRight
                            />
                        </SC.FRow>                    
                        <SC.FRow alc style={{marginTop : '8px'}}>
                            <div style={{flex : 1}}>Name Format</div>
                            <DropDownSelect 
                                items={[
                                    {id : 'camelCase', label : 'Camel Case'},
                                    {id : 'pascalCase', label : 'Pascal Case'},
                                    {id : 'snakeCase', label : 'Snake Case'},
                                    {id : 'kebabCase', label : 'Kebab Case'},
                                    {id : 'constantCase', label : 'Constant Case'},
                                    {id : 'dotCase', label : 'Dot Case'},
                                    {id : 'lowerCase', label : 'Lower Case'},
                                ]}
                                style={{flex : 1, }}
                                popupStyle={{width : '240px'}}
                                value={this.props.nameFormat}
                                onChange={this.ChangeNameFormat}
                                renderItem={this.RenderNameFormat}
                                hasBorder            
                                autoHeight
                                xsmall
                                right={0}
                                left='unset'
                            />
                        </SC.FRow> 
                        <SC.FRow alc style={{marginTop : '8px'}}>
                            <div style={{flex : 1}}>Token Name Prefix</div>
                            <div style={{flex : 1}}>
                                <TextInput 
                                    value={this.props.prefix}
                                    onChange={this.ChnagePrefix}
                                    dark
                                    themeId={this.props.themeId}
                                />
                            </div>                    
                        </SC.FRow>                                      
                        <Seperator />
                        <PropertyLabel>STATES</PropertyLabel>
                        <SystemStatePanel 
                            contentStyle={{margin : 0, marginBottom : '8px'}}
                            justContent
                        />
                        {
                            this.props.GlobalState !== 'Default' &&
                            <SC.FRow alc jsb style={{padding : '8px'}}>
                                <div>Only export tokens with values modified in this state</div>
                                <Switch round value={this.props.onlyModifiedTokens} onChange={this.props.onChangeModifiedtokens} />
                            </SC.FRow>
                        }                
                        <Seperator />
                        <PropertyLabel>File Name</PropertyLabel>                
                        <TextInput 
                            dark 
                            style={{height : '28px'}} 
                            value={this.props.fileName} 
                            onChange={this.onChangeFileName} 
                            themeId={this.props.themeId}
                        />
                        <StyleGroupTokenItem style={{
                            display : 'flex', alignItems : 'center', justifyContent : 'space-between', padding : '8px', marginTop : '8px', paddingTop : '4px', paddingBottom : '4px'
                        }}  onClick={this.props.onDownloadAll}>
                            <div>DOWNLOAD ALL</div>
                            <SC.Icons.Icon_Button hasCursor hasFill title='Download'>
                                <SC.Icons.Download />
                            </SC.Icons.Icon_Button>
                        </StyleGroupTokenItem>
                    </SC.FCol>   
                </React.Fragment>
            )
        }
        return (
            <SC.FCol 
                f1
                style={{
                    width : '280px',                                     
                    boxSizing : 'border-box',
                    backgroundColor : SC.CurrentTheme.theme.back,
                    borderRight : SC.CurrentTheme.theme.border_ondark,                    
                }}
            >
                {content}             
            </SC.FCol>
        )
    }
}

export const TOKEN_EXPORT_TARGETS = [
    {id : 'json', label : 'JSON'},
    {id : 'js', label : 'Javascript'},
    {id : 'css', label : 'CSS'},
    {id : 'sass', label : 'Sass'},
    {id : 'less', label : 'Less'},
    {id : 'yaml', label : 'Yaml'},
    {id : 'android', label : 'Android'},
    {id : 'ios', label : 'iOS'},
]
