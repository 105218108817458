import React from 'react';
import ReactDOM from 'react-dom';

import {
    MetaData,
    Utils,
    Strings,
    SC
} from '../../../../../../importer';

import { onConstructItem, onItemShouldUpdate, onItemDidMount, onItemWillUnMount, onItemDidUpdate, onGetItemProps } from '../common';
import Container from '../container';

export default class Repeater extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }

        onConstructItem(this, props);

        this.UpdateStyle(this.props);
    }
    UpdateStyle(props) {
        this.renderData = props.GetComponentManager().GetItemStyle(props.Id);
    }       
    componentDidMount() {
        onItemDidMount.call(this);
    }
    componentWillUnmount() {
        onItemWillUnMount.call(this);
    }
    componentDidUpdate(prevProps, prevState) {
        onItemDidUpdate.call(this, prevProps, prevState);
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (onItemShouldUpdate.call(this, nextProps, nextState)) {
            return true;
        }                
                    
        if (!this.props.isDropTarget && nextProps.isDropTarget || this.props.isDropTarget && !nextProps.isDropTarget) {                     
            return true;
        }

        return false;
    }  
    GetMaxSize() {        
        return this.props.GetParentSize();
    }    
    renderContent() {
        if (this.props.isSubComponentChild && this.props.renderSlot) {
            return this.props.renderSlot({
                slotId : this.props.Id,
                ParentzIndex : this.props.ParentzIndex + 1
            })
        }
        return (
            <SC.DesignItem_Placeholder />
        )
    }
    render() { 
        if (Utils.IsTrue(this.renderData.props.hidden))
            return null;
            
        const style = {
            ...this.renderData.style            
        };
        if (this.isSelected) {
            style.transition = 'all 0.2s ease';
        }

        if (this.isDragging) {
            style.opacity = 0.5;            
        }        

        const props = onGetItemProps({designer : this, style : style});

        const count = 3;

        let masteritem = (
            <Container 
                {...this.props}
            />
        )

        const items = [];

        for (let i=1; i<count; i++) {
            items.push(
                <Container 
                    key={i}
                    {...this.props}
                    Preview
                    isDropTarget={false}
                />
            )
        }
      
        return (
            <React.Fragment>
                {masteritem}
                {items}
            </React.Fragment>
        )
      
        return (
            <SC.Div_Relative
                {...props}
            >
                <SC.DesignItem_Placeholder />
            </SC.Div_Relative>
        )    
    }
}
 