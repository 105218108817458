import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    AppState,
    Utils,
    Events,
    Loading,
    UIUtils,
    MetaData,
    Strings
} from '../../../../importer';

import GoogleFonts from './googleFonts';
import WebsafeFonts from './websafeFonts';
import CustomFont from './customfont';
import {Grid} from './common';

export default class Typefaces  extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.SelectFont = this.SelectFont.bind(this);
        this.Close = this.Close.bind(this);

    }
    componentDidMount() {
        super.componentDidMount();
        setTimeout(() => {
             this.setState({Animated: true})
         }, 0)
    }
    
    SelectFont(font) {
        this.WillClose = true;
        this.RCSetStateValue('Animated', false);
        this.props.onSelectFont(font)
    }
    Close() {
        this.WillClose = true;
        this.RCSetStateValue('Animated', false);
        setTimeout(() => {
            this.props.onClose();    
        }, 100);        
    }
    renderCustom() {

        let classNames_Card = 'card-animated-fade-fromleft';
        if (this.state.Animated)
            classNames_Card += ' card-animated-mounted';
        else if (this.WillClose)
            classNames_Card += ' card-animated-fade-fromleft-closing';

        let content;

        this.fontSize = Math.max(8, 80 * (this.props.Options.scale || 0.5));
        this.gridCellWidth = Math.max(200, (this.props.width || 800) * (this.props.Options.scale || 0.5));

        const dark = SC.CurrentTheme.theme.isLight ? !this.props.Options.IsDark : this.props.Options.IsDark;

        if (this.props.Options.fontType === Strings.FONT_GOOGLE) {
            content = (
                <GoogleFonts
                    dark={dark}
                    onSelectFont={this.SelectFont}
                    FilterText={this.props.Options.filterText}
                    sorting={this.props.Options.sorting}
                    categories={Utils.DeepClone(this.props.Options.categories)}
                    previewText={this.props.Options.previewText}
                    scale={this.props.Options.scale}
                    onPreview={this.props.onPreview}
                    justContent
                />
            )
        }
        else if (this.props.Options.fontType === Strings.CUSTOM) {
            return (
                <CustomFont 
                    dark={dark}
                    previewText={this.props.Options.previewText}
                    onSelectFont={this.SelectFont}
                    onPreview={this.props.onPreview}
                    compact={this.props.compact}
                />
            )
        }
        else {
            content = (
                <WebsafeFonts
                    dark={dark}
                    onSelectFont={this.SelectFont}
                    FilterText={this.props.Options.filterText}
                    previewText={this.props.Options.previewText}
                    fontWeight={this.props.Options.fontWeight}
                    scale={this.props.Options.scale}
                    onPreview={this.props.onPreview}
                    justContent
                    fontSize={this.fontSize}
                />
            )
        }

        return (
            <Grid cellWidth={this.gridCellWidth} style={this.props.compact ? {gridAutoRows : 'minmax(140px, auto)'} : {}}>
                {content}
            </Grid> 
        )
    }
}
