import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Strings,
    Links,
    Events,
    Loading,
    UIUtils
} from '../../../../importer';
import styled, {css} from 'styled-components';

import RadioGroup from '../../../../components/editors/radiogroup';
import Switch from '../../../../components/editors/Switch';
import TextValue from '../../../../components/editors/textvalue';
import { QuickBrown } from './common';

const FilterGroup = (props) => {
    return (
        <SC.InnerPanel style={{padding : '4px', paddingTop : '8px', paddingBottom : '8px', borderRadius : '2px', ...props.style}}>
            {props.children}
        </SC.InnerPanel>
    )
}

export default class FontFilterPanel extends ReactBaseComponent
{
    constructor(props) {
        super(props);

    }
    ToggleCategory(name, value) {
        this.props.categories[name] = !value;
        this.props.onChangeCategories();
    }
    renderCustom() {

        let content;

        if (this.props.fontType === Strings.FONT_GOOGLE) {
            const GoogleCategories = [
                {name : 'serif', label : 'Serif'},
                {name : 'sans-serif', label : 'Sans Serif'},
                {name : 'display', label : 'Display'},
                {name : 'handwriting', label : 'Handwriting'},
                {name : 'monospace', label : 'Monospace'},
            ];
            content = (
                <SC.FCol>
                    <div style={{marginTop : '16px', paddingLeft : '4px', marginBottom : '4px'}}>Categories</div>
                    <FilterGroup>
                        <SC.FCol style={{paddingLeft : '4px', paddingRight : '4px', fontSize : '12px'}}>
                            {
                                GoogleCategories.map((gc, i) => {
                                    return (
                                        <SC.FRow key={i} alignCetner f1 alc style={{margin : '4px'}}>
                                            <SC.FCol f1 justifyEnd>
                                                {gc.label}
                                            </SC.FCol>
                                            <SC.FCol>
                                                <Switch round small value={!this.props.categories[gc.name]} onChange={this.ToggleCategory.bind(this, gc.name)} />
                                            </SC.FCol>
                                        </SC.FRow>
                                    )
                                })
                            }
                        </SC.FCol>
                    </FilterGroup>
                    <div style={{marginTop : '16px', paddingLeft : '4px', marginBottom : '4px'}}>Sorting</div>
                    <FilterGroup>
                        <RadioGroup
                            vertical
                            small
                            itemStyle={{border : 'none', margin : 0,fontSize : '12px'}}
                            Container={{Type : SC.FCol, Props : {jsb : true, style : {marginBottom : '-4px'}} }}
                            items={[
                                {id : 'alpha', label : 'Alphabetical'},
                                {id : 'date', label : 'Date Added'},
                                {id : 'popularity', label : 'Popular'},
                                {id : 'style', label : 'Number of Styles'},
                                {id : 'trending', label : 'Trending'}
                            ]}
                            value={this.props.sorting}
                            onSelect={this.props.onChangeSorting}
                        />
                    </FilterGroup>
                </SC.FCol>
            )
        }
        else if (this.props.fontType === Strings.FONT_WEBSAE) {
            const FontWeights = [100, 200, 300, 400, 500, 600, 700, 800, 900].map((w) => {
                return {
                    id : w,
                    label : `${w}${(w === 400  ? ' (Normal)' : '')}${(w === 700  ? ' (Bold)' : '')}`
                }
            })
            content = (
                <SC.FCol>
                    <div style={{marginTop : '16px', paddingLeft : '4px', marginBottom : '4px'}}>Font Weight</div>                    
                    <FilterGroup>
                        <RadioGroup
                            vertical
                            small
                            itemStyle={{border : 'none', margin : 0, fontSize : '12px'}}
                            Container={{Type : SC.FCol, Props : {jsb : true, style : {marginBottom : '-4px'}} }}
                            items={FontWeights}
                            value={this.props.fontWeight}
                            onSelect={this.props.onChangeWeight}
                        />
                    </FilterGroup>
                </SC.FCol>
            )
        }

        return (
            <SC.FCol f1>                
                <FilterGroup>
                    <RadioGroup
                        vertical
                        small
                        itemStyle={{border : 'none', margin : 0, fontSize : '12px'}}
                        Container={{Type : SC.FCol, Props : {jsb : true, style : {marginBottom : '-4px'}} }}
                        items={[
                            {id : Strings.FONT_WEBSAE, label : 'Websafe Fonts'},
                            {id : Strings.FONT_GOOGLE, label : 'Google Fonts'},
                            {id : Strings.CUSTOM, label : 'Upload Font File'}
                        ]}
                        value={this.props.fontType}
                        onSelect={this.props.onChangeFontType}
                    />
                </FilterGroup>
                {content}
                <div style={{marginTop : '16px', paddingLeft : '4px', marginBottom : '4px'}}>Preview Text</div>
                <SC.FCol>
                    <TextValue multiline value={this.props.previewText || QuickBrown} onChange={this.props.onChangePreviewText} />
                </SC.FCol>                
            </SC.FCol>
        )
    }
}

export class GoogleFontCategories extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    ToggleCategory(name, value) {
        this.props.categories[name] = !value;
        this.props.onChangeCategories();
    }
    render() { 
        const GoogleCategories = [
            {name : 'serif', label : 'Serif'},
            {name : 'sans-serif', label : 'Sans Serif'},
            {name : 'display', label : 'Display'},
            {name : 'handwriting', label : 'Handwriting'},
            {name : 'monospace', label : 'Monospace'},
        ];

        return (
            <SC.FCol>
                {!this.props.compact && <div style={{marginTop : '16px', paddingLeft : '4px', marginBottom : '4px'}}>Categories</div>}
                <FilterGroup style={this.props.compact ? {backgroundColor : SC.CurrentTheme.theme.back_lighter, marginTop : '8px'} : {}}>
                    <SC.FCol style={{paddingLeft : '4px', paddingRight : '4px', fontSize : '12px'}}>
                        {
                            GoogleCategories.map((gc, i) => {
                                return (
                                    <SC.FRow key={i} alignCetner f1 alc style={{margin : '4px'}}>
                                        <SC.FCol f1 justifyEnd>
                                            {gc.label}
                                        </SC.FCol>
                                        <SC.FCol>
                                            <Switch round small value={!this.props.categories[gc.name]} onChange={this.ToggleCategory.bind(this, gc.name)} />
                                        </SC.FCol>
                                    </SC.FRow>
                                )
                            })
                        }
                    </SC.FCol>
                </FilterGroup>
            </SC.FCol>
        )
    }
}
 