import { Utils, Events, AppLayout } from "../../../../importer";
import { GetDataApi, GetUserId, GetUserName } from "../../../../appstate/AppState";
import firebase from '@firebase/app';
import { checkFirebaseJson } from "../../../../db/firebasedb";
import Globals from "../../../../appstate/Globals";


var config = {
    apiKey: "AIzaSyAYzCR2XKn-9Ha592WTJAs0Q-v0r5fcb4M",
    authDomain: "toolabs-beta-20-2.firebaseapp.com",
    databaseURL: "https://toolabs-beta-20-2-sessions.firebaseio.com",
    projectId: "toolabs-beta-20-2",
    storageBucket: "toolabs-beta-20-2.appspot.com",
    messagingSenderId: "30542139765",
    appId: "1:30542139765:web:09f2b838e4f536e450833c"
};

let firebaseSessionsApp;

export default class SessionManager {
    constructor(Manager) {        
        this.Manager = Manager;

        this.ActiveEditors = {};
        this.ActiveEditorChangeRefs = {};
        this.ActiveEditorChangeRefs_Documents = {};
        this.DbListeners = [];
        
        this.onSetPath = this.onSetPath.bind(this);        
        this.onDeletePath = this.onDeletePath.bind(this);        
        this.onUpdatePaths = this.onUpdatePaths.bind(this);        

        this.onSetPathDocuments = this.onSetPathDocuments.bind(this);        
        this.onDeletePathDocuments = this.onDeletePathDocuments.bind(this);        
        this.onUpdatePathsDocuments = this.onUpdatePathsDocuments.bind(this);        
        

        this.ActiveEditors = {};
        this.Messages = [];        
        this.DbListeners = [];

        this.Id = Utils.Id();
    }

    Clear() {
        console.log(`Clear SessionManager ${Date().toString()}`);
        
        this.getDataBase().ref('.info/connected').off('value');

        if (this.Ref_Editor) {
            this.Ref_Editor.remove();
        }

        this.DbListeners.map((listener) => {
            listener.off();
        })


        if (GetDataApi().IsLocal()) {
            this.ActiveEditors = {
                "1" : {
                    name : 'Namık 1',
                    avatarUrl : "https://pbs.twimg.com/profile_images/975867914887741440/RL7ke5ge_200x200.jpg",
                },
                "2" : {
                    name : 'Namık 2',
                    avatarUrl : "https://pbs.twimg.com/profile_images/975867914887741440/RL7ke5ge_200x200.jpg",
                },
                "3" : {
                    name : 'Namık 3',
                    avatarUrl : "https://pbs.twimg.com/profile_images/975867914887741440/RL7ke5ge_200x200.jpg",
                }
            };
            this.Messages = [
                {
                    userName : 'Namık',
                    avatar : "https://pbs.twimg.com/profile_images/975867914887741440/RL7ke5ge_200x200.jpg",
                    message : {
                        type : TEAM_MESSAGE_TYPES.TOKEN_ADDED,
                        id : '14uZaPvxo'
                    }
                },
                {
                    userName : 'Namık',
                    avatar : "https://pbs.twimg.com/profile_images/975867914887741440/RL7ke5ge_200x200.jpg",
                    message : {
                        type : TEAM_MESSAGE_TYPES.TOKEN_CHANGED,
                        id : '14uZaPvxo'
                    }
                },
                {
                    userName : 'Namık',
                    avatar : "https://pbs.twimg.com/profile_images/975867914887741440/RL7ke5ge_200x200.jpg",
                    message : {
                        type : TEAM_MESSAGE_TYPES.TOKEN_DELETED,
                        id : '14uZaPvxo'
                    }
                }
            ];
        }
        else {
            this.ActiveEditors = {};
            this.Messages = [];
        }
        
        this.DbListeners = [];
    }
    GetEditorsRef() {        
        if (!this.Ref_Editor) {
            
            
            console.log(`Create EditorRef ${Date().toString()}`);

            var ListenRef_Editors = this.getDataBase().ref(this.getSessionPath() + 'editors');
            ListenRef_Editors.off();
            this.Ref_Editor = this.getDataBase().ref(this.getSessionEditorPath());
        }
        
        return this.Ref_Editor;
    }
    Load() {
        
        if (GetDataApi().IsLocal())
            return;

        var isOnlineForDatabase = {
            start : firebase.database.ServerValue.TIMESTAMP,
            uid : GetUserId(),
            name : GetUserName(),
            editor : this.Manager.Editor
        };

        this.getDataBase().ref('.info/connected').on('value', (snapshot) => {
            // If we're not currently connected, don't do anything.
            if (snapshot.val() == false) {
                return;
            };
        
            // If we are currently connected, then use the 'onDisconnect()' 
            // method to add a set which will only trigger once this 
            // client has disconnected by closing the app, 
            // losing internet, or any other means.
            this.GetEditorsRef().onDisconnect().remove().then(() => {
                // The promise returned from .onDisconnect().set() will
                // resolve as soon as the server acknowledges the onDisconnect() 
                // request, NOT once we've actually disconnected:
                // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect
        
                // We can now safely set ourselves as 'online' knowing that the
                // server will mark us as offline once we lose connection.
                this.GetEditorsRef().set(isOnlineForDatabase);

                GetDataApi().api.onSetPath = this.onSetPath;
                GetDataApi().api.onUpdatePaths = this.onUpdatePaths;
                GetDataApi().api.onDeletePath = this.onDeletePath;

                GetDataApi().api.onSetPathDocuments = this.onSetPathDocuments;
                GetDataApi().api.onUpdatePathsDocuments = this.onUpdatePathsDocuments;
                GetDataApi().api.onDeletePathDocuments = this.onDeletePathDocuments;

                var ListenRef_Editors = this.getDataBase().ref(this.getSessionPath() + 'editors');
                ListenRef_Editors.off();

                ListenRef_Editors.on('child_added', async (editors) => {            
            
                    if (editors.key !== this.Id) {
                        const newEditor = editors.val();
                        this.ActiveEditors[editors.key] = {
                            name : newEditor.name,
                            uid : newEditor.uid,
                            editor : newEditor.editor
                        };
                        this.ActiveEditorChangeRefs[editors.key] = this.getDataBase().ref(this.getSessionPath() + 'editors/' + editors.key + '/Changes');
                        if (newEditor.editor !== 'figma') {
                            this.ActiveEditorChangeRefs_Documents[editors.key] = this.getDataBase().ref(this.getSessionPath() + 'editors/' + editors.key + '/DocumentChanges');
                        }
                        

                        const avatar = await GetDataApi().UserAvatar(newEditor.uid);
                        if (this.ActiveEditors[editors.key]) {
                            if (avatar && avatar.url) {
                                this.ActiveEditors[editors.key].avatarUrl = avatar.url;
                            }
                        }                        

                        this.onActiveEditorsChanged();                        
                    }                
                });
                ListenRef_Editors.on('child_removed', (editors) => {            
                    const editorKey = editors.key;
                    delete this.ActiveEditors[editorKey];
                    delete this.ActiveEditorChangeRefs[editorKey];
                    delete this.ActiveEditorChangeRefs_Documents[editorKey];
                    this.onActiveEditorsChanged();
                });
        
                this.DbListeners.push(ListenRef_Editors); 

                this.RefChanges = this.getDataBase().ref(this.getSessionPath() + 'editors/' + this.Id + '/Changes');
                this.RefChanges.on('child_added', async (change) => {            
                    const changeValue = change.val();
                    change.ref.remove(); 

                    if (changeValue.info) {
                        this.Messages.splice(0, 0, {
                            id : change.key,
                            message : changeValue.message,
                            userName : Utils.JustGet(this.ActiveEditors, 'Someone', changeValue.editorId, 'name'),
                            uid : Utils.JustGet(this.ActiveEditors, null, changeValue.editorId, 'uid'),
                            avatar : Utils.JustGet(this.ActiveEditors, null, changeValue.editorId, 'avatarUrl'),
                            editor : Utils.JustGet(this.ActiveEditors, null, changeValue.editorId, 'editor'),
                        });
                        const MAX_MESAGE_COUNT = 4;
                        if (this.Messages.length > MAX_MESAGE_COUNT) {
                            this.Messages.splice(MAX_MESAGE_COUNT, 1);
                        }
                        Events.BCE(Events.GLOBAL.TEAM.MESSAGES);
                    }
                    else if (changeValue.custom) {
                        if (changeValue.loadFont && changeValue.fontId) {
                            setTimeout(() => {
                                const font = Globals.ProjectManager.Tokens.Token(changeValue.fontId);
                                if (font) {
                                    Globals.ProjectManager.LoadFont(font);
                                }
                            }, 100);
                        }
                    }
                    else if (changeValue.figma) {
                        console.log(`FileId : ${AppLayout.AppSource.Figma.FileId}, Received : ${changeValue.fileId}`);
                        if (changeValue.fileId === AppLayout.AppSource.Figma.FileId) {
                            if (changeValue.StateChanged) {
                                console.log(`State Changed : ${JSON.stringify(changeValue.GlobalState)}`);
                                AppLayout.AppSource.Figma.SuspendStateChange = true;
                                Globals.ProjectManager.States.ToState(changeValue.GlobalState);
                                setTimeout(() => {
                                    AppLayout.AppSource.Figma.SuspendStateChange = false;
                                }, 100);
                            }
                        }                        
                    }
                    else if (changeValue.path) {
                        const paths = changeValue.path.replace(`/projects/data/${this.Manager.Id}/`, '').split('/').filter(x => x);
                        if (changeValue.delete) {
                            Utils.UnSet(this.Manager.Data, 'Data', ...paths);
                        }
                        else {
                            const value = await GetDataApi().api.get_path_value(changeValue.path);
                            Utils.Set(this.Manager.Data, value, 'Data', ...paths);
                        }            
                        Events.BCE(Events.GLOBAL.TOKENS_CHANGED);                    
                    }                    
                });

                this.DbListeners.push(this.RefChanges);
            });
        });
    }
    onActiveEditorsChanged() {
        Events.BCE(Events.GLOBAL.TEAM.ACTIVEUSERSCHANGED, this.GetActiveUserCount());
    }
    GetActiveUserCount() {
        return Utils.Keys(this.ActiveEditors).length;
    }
    GetActiveUsers() {
        const users = [];
        Utils.ForEach(this.ActiveEditors, (props, id) => {
            users.push({id : id, ...props});
        });
        return users;
    }
    GetUserInfo(id) {
        const info = {};
        Utils.ForEach(this.ActiveEditors, ({uid, editor}, editorId) => {            
            if (uid === id) {
                info.state = 'online';
                info.editor = editor;
                return false;
            }
        });
        return info;
    }
    GetUserState(id) {
        let state;
        Utils.ForEach(this.ActiveEditors, ({uid}, editorId) => {
            if (uid === id) {
                state = 'online';
                return false;
            }
        });
        
        return state;
    }
    GetMessages() {
        return this.Messages || [];
    }
    ListenDocumentChanges() {        
        if (GetDataApi().IsLocal())
            return;

        if (AppLayout.AppSource.Figma)
            return;

        this.RefChangesDocuments = this.getDataBase().ref(this.getSessionPath() + 'editors/' + this.Id + '/DocumentChanges');
        this.RefChangesDocuments.on('child_added', async (change) => {  
            if (this.Manager.DocumentManager) {
                const changeValue = change.val();
                const [documentId, ...paths] = changeValue.path.replace(`/documents/data/${this.Manager.Id}/`, '').split('/').filter(x => x);
                if (documentId === this.Manager.DocumentManager.Id) {
                    if (changeValue.delete) {
                        Utils.UnSet(this.Manager.DocumentManager, 'Data', ...paths);
                    }
                    else {
                        const value = await GetDataApi().api.get_path_documents_value(changeValue.path);
                        Utils.Set(this.Manager.DocumentManager, value, 'Data', ...paths);
                    }            
                    AppLayout.Refs.DocumentDesigner.Main && AppLayout.Refs.DocumentDesigner.Main.Refresh();
                }                
            }            
            change.ref.remove(); 
        });

        this.DbListeners.push(this.RefChangesDocuments);
    }
    SendChangeMessage(message) {
        const LastMessage = {
            info : true,
            message : message
        };
        if (this.LastMessage) {
            if (this.LastMessage.info && LastMessage.info) {
                if (this.LastMessage.message.type === message.type && this.LastMessage.message.id === message.id) {
                    return;
                }
            }
        }
        this.LastMessage = LastMessage;
        this.BroadcastMessage(LastMessage);        
    }
    onSetPath(path, value) {        
        this.BroadcastMessage({
            path : path,
            // value : value,
        });
    }
    onUpdatePaths(paths) {
        Utils.ForEach(paths, (value, path) => {
            this.BroadcastMessage({
                path : path,
                // value : value,
            });
        });    
    }
    onDeletePath(path) {
        this.BroadcastMessage({
            path : path,
            delete : true,
        });
    }
    BroadcastMessage(message) {
        Utils.ForEach(this.ActiveEditorChangeRefs, (EditorRef, EditorId) => {
            this.BroadcastMessageToEditor(message, EditorRef, EditorId);
        });
        // const willRemoveEditors = [];
        // Utils.ForEach(this.ActiveEditorChangeRefs, (EditorRef, EditorId) => {
        //     this.getDataBase().ref(this.getSessionPath() + 'editors/' + EditorId + '/uid').once('value').then( (snapshot) => {
        //         if (snapshot.val()) {
        //             EditorRef.push().set(checkFirebaseJson({
        //                 editorId : this.Id,
        //                 ...message
        //             }))     
        //         }
        //         else {
        //             willRemoveEditors.push(EditorId);
        //             this.getDataBase().ref(this.getSessionPath() + 'editors/' + EditorId).remove();
        //         }
        //     });            
        // });
        // willRemoveEditors.map((EditorId) => {
        //     delete this.ActiveEditorChangeRefs[EditorId];
        //     delete this.ActiveEditorChangeRefs_Documents[EditorId];
        // })
    }
    BroadcastMessageToEditor(message, EditorRef, EditorId) {
        return new Promise(() => {
            this.getDataBase().ref(this.getSessionPath() + 'editors/' + EditorId + '/uid').once('value').then( (snapshot) => {
                if (snapshot.val()) {
                    EditorRef.push().set(checkFirebaseJson({
                        editorId : this.Id,
                        ...message
                    }))     
                }
                else {
                    delete this.ActiveEditorChangeRefs[EditorId];
                    delete this.ActiveEditorChangeRefs_Documents[EditorId];
                    this.getDataBase().ref(this.getSessionPath() + 'editors/' + EditorId).remove();
                }
            });    
        })
    }

    onSetPathDocuments(path) {
        Utils.ForEach(this.ActiveEditorChangeRefs_Documents, (EditorRef, EditorId) => {
            EditorRef.push().set(checkFirebaseJson({
                path : path,
                editorId : this.Id
            }))
        });
    }
    onUpdatePathsDocuments(paths) {
        Utils.ForEach(this.ActiveEditorChangeRefs_Documents, (EditorRef, EditorId) => {
            Utils.ForEach(paths, (value, path) => {
                EditorRef.push().set(checkFirebaseJson({
                    path : path,
                    editorId : this.Id
                }))
            });            
        });
    }
    onDeletePathDocuments(path) {
        Utils.ForEach(this.ActiveEditorChangeRefs_Documents, (EditorRef, EditorId) => {
            EditorRef.push().set(checkFirebaseJson({
                path : path,
                delete : true,
                editorId : this.Id
            }))
        });
    }

    getDataBase() {
        if (!firebaseSessionsApp)
            firebaseSessionsApp = firebase.initializeApp(config, 'sessions');
        return firebase.database(firebaseSessionsApp);
    }
    set_path(path, value) {
        this.getDataBase().ref(path).set(checkFirebaseJson(value));
    }
    delete_path(path) {
        this.getDataBase().ref(path).remove();
    }
    getSessionPath() {
        return `sessions/${this.Manager.Id}/`;
    }
    getSessionEditorPath() {
        return `sessions/${this.Manager.Id}/editors/${this.Id}/`;
    }
}

export const TEAM_MESSAGE_TYPES = {
    TOKEN_ADDED : 'TokenAdded',
    TOKEN_CHANGED : 'TokenChanged',
    TOKEN_DELETED : 'TokenDeleted',
    DOCUMENT_FOLDER_ADDED : 'DocFolderAdded',
    DOCUMENT_FOLDER_DELETED : 'DocFolderDeleted',
    DOCUMENT_PAGE_ADDED : 'DocPageAdded',
    DOCUMENT_PAGE_DELETED : 'DocPageDeleted',
    DOCUMENT_ITEM_ADDED : 'DocItemAdded',
    DOCUMENT_ITEM_CHANGED : 'DocItemChanged',
    DOCUMENT_ITEM_DELETED : 'DocItemDeleted',
}
