import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    AppLayout,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../../../../../importer';

import Chroma from 'chroma-js';
import { GetColorTokenList, GetColorTokenListOfIds, GetColorTokenItem } from '../../../../../panels/left/designsystem/colors';
import styled from 'styled-components';
import { GetTokenAliaseListOfIds, GetTokenAliaseList } from '../../../../../panels/left/designsystem/aliases';
import { DocumentGradientItem_Card, DocumentGradientItem_Cell } from '../gradients';


export default class DocumentToken_Colors extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {

        };    
    }
    shouldComponentUpdate(nextProps, nextState) {

        if (this.state.ShouldUpdate !== nextState.ShouldUpdate)
            return true;
        
        if (Utils.HasAnyChange(this.props, nextProps, 'GlobalThemeId', 'GlobalState', 'StyleId', 'RenderId', 'LoadId', 'alignment', 'themeId', 'themeType', 'states', 'ReversedStyleState', 'isAliase'))
            return true;
        
        return false;
    }
    componentDidUpdate(prevProps, prevState) {
        this.state.ShouldUpdate = false;
    }
    render() {                
        let colors;
        if (this.props.isAliase) {
            const aliases = this.props.selection.type === 'selected' ? GetTokenAliaseListOfIds(this.props.selection.tokenIds, this.props.ReversedStyleState) : GetTokenAliaseList(Globals.ProjectManager.Tokens.Types.COLOR , this.props.ReversedStyleState);
            colors = [];
            Utils.ForEach(aliases, (aliase, i) => {
                const tokenItem = GetColorTokenItem({
                    id : aliase.tokenId,
                    token : Globals.ProjectManager.Tokens.Token(aliase.tokenId),
                    StateArray : this.props.ReversedStyleState
                })
                if (tokenItem) {
                    colors.push({
                        id : aliase.id,
                        name : aliase.name,
                        value : Globals.ProjectManager.Tokens.Aliases.GetColorAliaseValue(aliase.id, tokenItem.value),
                        isGradient : tokenItem.gradient,
                    })


                }                
            });
        }
        else {
            colors = this.props.selection.type === 'selected' ? GetColorTokenListOfIds(this.props.selection.tokenIds, {StateArray : this.props.ReversedStyleState}) : GetColorTokenList({StateArray : this.props.ReversedStyleState});
        }

        if (colors.length === 0) {
            return (
                <div>Empty</div>
            )
        }

        const colorItems = [];
        const scaleFactor = (this.props.cardSize || 100) / (this.props.viewMode === 'card' ? 100 : 200);
        const cardMinWidth = 100 * scaleFactor;
        const cardMaxWidth = 120 * scaleFactor;
        const dividerMargin = 32 * scaleFactor;


        Utils.ForEach(colors, (color, ) => {            
            if (color.isGradient) {
                if (this.props.viewMode === 'card') {
                    colorItems.push(
                        <DocumentGradientItem_Card key={color.id} color={color.value} name={color.name} />                    
                    )
                }
                else {
                    colorItems.push(
                        DocumentGradientItem_Cell({color : color.value, name : color.name, cardMinWidth : cardMinWidth, dividerMargin : dividerMargin, dividerColor : this.props.textStyles.dividerColor})
                    );
                }
            }
            else {
                let hex, rgba;
                if (Chroma.valid(color.value)) {
                    hex = Chroma(color.value).hex();
                    rgba = Chroma(color.value).css('rgba');
                }

                if (this.props.viewMode === 'card') {
                    colorItems.push(
                        <DocumentColorItem_Card key={color.id} color={color.value} name={color.name} hex={hex} />                    
                    )
                }
                else {                  
                    colorItems.push(
                        <DocumentTableCell style={{backgroundColor : color.value, border : SC.CurrentTheme.theme.border_seperator, flex : 1, borderRadius : '4px', width : Utils.px(cardMinWidth), minHeight : Utils.px(cardMinWidth)}} />
                    );
                    colorItems.push(
                        <div style={{
                            display : 'grid',
                            // gridColumnGap : '16px',
                            justifyItems : 'flex-start',
                            gridTemplateColumns : `repeat(auto-fit, minmax(120px, 1fr))`,
                        }}>
                            <DocumentTableCell style={{fontFamily : 'monospace'}}>{color.name}</DocumentTableCell>
                            <DocumentTableCell style={{fontFamily : 'monospace'}}>{hex}</DocumentTableCell>
                            <DocumentTableCell style={{fontFamily : 'monospace'}}>{rgba}</DocumentTableCell>
                        </div>
                    )
                    colorItems.push(
                        <div style={{
                            gridColumn : 'span 2',
                            height : '1px',
                            backgroundColor : this.props.textStyles.dividerColor,
                            marginTop : Utils.px(dividerMargin),
                            marginBottom : Utils.px(dividerMargin)
                        }} 
                        />
                    );
                } 
            }     
            
            if (this.props.forTemplateSelect && colorItems.length === 6) {
                return false;
            }
        });
        

        if (this.props.viewMode === 'card') {
            return (
                <div style={{
                    display : 'grid',
                    gridGap : '16px',
                    gridTemplateColumns : `repeat(auto-fill, minmax(${cardMinWidth}px, 1fr))`,
                    gridAutoRows : Utils.px(cardMaxWidth),
                    alignSelf : this.props.alignment,
                    width : this.props.size ? Utils.px(this.props.size, '%') : 'auto'
                }}                    
                >
                    {colorItems}
                </div>
            )   
        }
        else {
            return (
                <div                     
                    style={{
                        display : 'grid',
                        gridTemplateColumns : 'auto 1fr',     
                        gridTemplateRows : 'auto',  
                        alignSelf : this.props.alignment,                 
                        width : this.props.size ? Utils.px(this.props.size, '%') : 'auto',
                        justifyContent : this.props.alignment,
                        alignItems : 'center'
                    }}                    
                >
                    {colorItems}
                </div>
            )   
        }
             
    }
}

export const DocumentTableCell = styled.div`
    padding-left : 16px;
    padding-right : 16px;
    text-overflow : ellipsis;
    white-space : nowrap;
    overflow : hidden;
`;

const DocumentColorItem_Card = ({color, name, hex}) => {
    return (
        <SC.FCol>
            <div style={{backgroundColor : color, border : SC.CurrentTheme.theme.border_seperator, flex : 1, borderRadius : '4px', marginBottom : '8px'}}>

            </div>
            <SC.TextDivAbbr>{name}</SC.TextDivAbbr>
            <div style={{fontFamily : 'monospace'}}>{hex}</div>
        </SC.FCol> 
    )
}