import React from 'react';
import {
    ReactBasicComponent,
    SC,
    Utils,
    AppLayout,
    Links,
    Events,
    Loading,
    Strings,
    Globals
} from '../../../../../../importer';

import {TokenItemBox, TokenPanelHeader, TokenItemDragHandle, TokenItemBoxHoveredName} from '../common';
import { SortableList, SortableListItem } from '../../../../../../components/SortableContainer';

export default class MetadataDefinitions extends ReactBasicComponent
{
    constructor(props) {
        super(props);

    }
    renderCustom() {
        return (
            <React.Fragment>
                {this.props.children}
                <SC.FCol>
                    Metadata Definitions
                </SC.FCol>
            </React.Fragment>            
        )
    }
}