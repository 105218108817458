import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Events,
    AppState,
    MetaData,
    Utils,
    Strings,
    ReactPureComponent
} from '../../../../../../../../../importer';

import styled, {css} from 'styled-components';
import { SmallButtonGroup, PropertyLabel, StyleGroupTokenItem, StyleItemPopup, StyleGroupTitle } from '../../../common';
import ItemSizer from './itemsizer';
import { GridCellBox } from '../common';

export default class GridJustifyContent extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.SaveColumnGap = this.SaveColumnGap.bind(this);
        this.SaveRowGap = this.SaveRowGap.bind(this);

        this.gap = {
            row : {value : this.props.rowGap || 0},
            column : {value : this.props.columnGap || 0}
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        this.gap.row.value = nextProps.rowGap || 0;
        this.gap.column.value = nextProps.columnGap || 0;
        return true;
    }
    ChangeAlignment(name, oldValue, value) {
        if (value === oldValue)
            this.props.onDelete(name);
        else
            this.props.onChange(name, value);
    }
    SaveColumnGap(e) {
        this.props.onChange('columnGap', this.gap.column.value);
    }
    SaveRowGap(e) {
        this.props.onChange('rowGap', this.gap.row.value);
    }
    renderCustom() {

        const {rowGap, columnGap, justifyItems, alignItems} = this.props;
        return (
            <SC.FCol style={{marginTop : '4px'}}>
                <SC.FRow>                    
                    <div style={{width : '76px'}} />
                    <PropertyLabel style={{width : '64px'}}>Gap</PropertyLabel>
                    <PropertyLabel style={{flex : 1}}>Cell Alignment</PropertyLabel>
                </SC.FRow>
                <SC.InnerPanelGroup style={{
                        display : 'grid',
                        gridTemplateColumns : 'auto auto 1fr',
                        alignItems : 'center',
                        justifyItems : 'center',
                        gridRowGap : '4px',
                        gridColumnGap : '4px',
                        paddingRight : 0,
                        marginTop : '4px'
                    }}>
                        <PropertyLabel style={{justifySelf : 'start'}} >Horizontal</PropertyLabel>
                        <ItemSizer spinned value={this.gap.column} onSave={this.SaveColumnGap}/>
                        <GridCellBox 
                            style={{justifyContent : 'flex-end'}}
                            direction='row' 
                            value={justifyItems} 
                            onClick={this.ChangeAlignment.bind(this, 'justifyItems', justifyItems)} 
                            BoxContainer={FlexBoxActive} 
                            BoxStyle={{}}
                        />
                        <PropertyLabel style={{justifySelf : 'start'}} >Vertical</PropertyLabel>
                        <ItemSizer spinned value={this.gap.row} onSave={this.SaveRowGap}/>
                        <GridCellBox 
                            style={{justifyContent : 'flex-end'}}
                            direction='column' 
                            value={alignItems} 
                            onClick={this.ChangeAlignment.bind(this, 'alignItems', alignItems)} 
                            BoxContainer={FlexBoxActive} 
                            BoxStyle={{}}
                        />
                    </SC.InnerPanelGroup>
            </SC.FCol>
        )
        return (
            <SC.InnerPanelGroup style={{
                display : 'grid',
                gridTemplateColumns : 'auto auto 1fr',
                alignItems : 'center',
                justifyItems : 'center',
                gridRowGap : '4px',
                gridColumnGap : '4px',
                paddingTop : '2px',
                marginTop : '4px'
            }}>
                <div />
                <PropertyLabel >Gap</PropertyLabel>
                <PropertyLabel >Alignment</PropertyLabel>
                <PropertyLabel style={{justifySelf : 'start'}}>Horizontal</PropertyLabel>
                <ItemSizer spinned value={this.gap.column} onSave={this.SaveColumnGap}/>
                <GridCellBox 
                    style={{justifyContent : 'flex-end'}}
                    direction='row' 
                    value={justifyItems} 
                    onClick={this.ChangeAlignment.bind(this, 'justifyItems', justifyItems)} 
                    BoxContainer={FlexBoxActive} 
                    BoxStyle={{}}
                />
                <PropertyLabel style={{justifySelf : 'start'}} >Vertical</PropertyLabel>
                <ItemSizer spinned value={this.gap.row} onSave={this.SaveRowGap}/>
                <GridCellBox 
                    style={{justifyContent : 'flex-end'}}
                    direction='column' 
                    value={alignItems} 
                    onClick={this.ChangeAlignment.bind(this, 'alignItems', alignItems)} 
                    BoxContainer={FlexBoxActive} 
                    BoxStyle={{}}
                />
            </SC.InnerPanelGroup>
        )        
    }
}

export const FlexBoxActive = styled.div`
    height : 36px;
    width : 36px;
    padding : 1px;
    box-sizing : border-box;
    border :${props => props.theme.border_seperator};
    border-radius : 2px;
    cursor : pointer;
    transition : all 0.3s ease;
    background-color : ${props => props.selected ? props.theme.back : 'unset'};
    &:hover {
        background-color : ${props => props.theme.buttongroup_back_hover};
    }
`;

const ContentAlignment = (props) => {
    const alignValues = [
        'start',
        'end',
        'center',
        'stretch',
        'space-around',
        'space-between',
        'space-evenly'
    ];    

    return alignValues.map((jv, i) => {
        return (
            <AlignmentOption
                value={jv}
                onClick={() => {
                    props.onChange(jv);
                }}
                selected={jv === props.value}
                alignContent={props.alignContent}
                justifyContent={props.justifyContent}
                vertical={props.vertical}
            />            
        )
    })
};


const AlignmentOption = ({onClick, value, selected, vertical, all, justifyContent, alignContent, style}) => {    
    return (
        <SmallButtonGroup
            first
            last
            noSvg
            hasBorders                
            onClick={onClick}
            title={MetaData.Properties.FlexLabels[value]}
            style={{width : '100%', height : '100%', padding : '2px', ...style}}
            selected={selected}
        >
            <div style={{
                display : 'grid',
                width : '100%',
                height : '100%',
                gridTemplateColumns : (!vertical && value === 'stretch' || justifyContent === 'stretch') ? '1fr 1fr' : '5px 5px',
                gridTemplateRows : (vertical && value === 'stretch' || alignContent === 'stretch') ? '1fr 1fr' : '5px 5px',
                gridGap : '1px',
                justifyContent : vertical ? justifyContent : value,
                alignContent : vertical ? value : alignContent
            }}>
                <div style={{backgroundColor : selected ? SC.CurrentTheme.theme.color_brand : '#606060', opacity : selected ? 0.75 : 1}} />
                <div style={{backgroundColor : selected ? SC.CurrentTheme.theme.color_brand : '#606060', opacity : selected ? 0.75 : 1}} />
                <div style={{backgroundColor : selected ? SC.CurrentTheme.theme.color_brand : '#606060', opacity : selected ? 0.75 : 1}} />
                <div style={{backgroundColor : selected ? SC.CurrentTheme.theme.color_brand : '#606060', opacity : selected ? 0.75 : 1}} />
            </div>
        </SmallButtonGroup>
    )
}

const Div_FlexBox = styled.div`    
    display : flex;
    width : 100%;
    height : 100%;
    flex-direction : ${props => props.fd};
    justify-content : ${props => props.jct};
    align-items : ${props => props.alit};
`;

const FlexItem = styled.div`
    display : flex;
    justify-content : center;
    align-items : center;
    width : ${props => props.vertical ? 8 : 1}px;
    height : ${props => props.vertical ? 1 : 8}px;
    border-radius : 1px;
    background-color : ${props => props.theme.isLight ? (props.selected ? '#4b4b4b':'#808284') : (props.selected ? props.theme.color_brand:'#808284')};
    margin : 1px;
    ${
        props => {
            if (props.stretch) {
                if (props.vertical)
                    return css`width : unset; align-self : stretch;`;
                else
                    return css`height : unset; align-self : stretch;`;
            }
                
        }
    }
`;

const AlignBox = styled.div`
    display : flex;
    flex-direction : ${props => props.fd};
    justify-content : ${props => props.jct};
    align-items : ${props => props.alit};
`;