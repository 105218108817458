import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,    
    Strings,
    Globals
} from '../../../../importer';
import styled from 'styled-components';
import Input_Name from '../../../../components/editors/input_name';

import Dropzone from 'react-dropzone';

export default class CustomFontUpload extends ReactBaseComponent
{
    constructor(props) {
        super(props);
        this.onDrop = this.onDrop.bind(this);

        this.TempFontName = Utils.Id();
        this.TempFontFamily = 'PrefiewCustomFont'; //Utils.Id();
        this.UploadFile = this.UploadFile.bind(this);
    }
    onDrop(files) {
        if (files && files.length > 0) {

            const file = files[0];
            this.LoadingProgress = 0;
            this.RCUpdate();  
            
            this.MetaFile = {
                Id : this.TempFontName,
                fileName : file.name,
                Name : file.name.replace(/\.[^/.]+$/, ""),
                family : file.name.replace(/\.[^/.]+$/, "")
            };
            
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.MetaFile.base64 = reader.result;                
                var newStyle = document.createElement('style');
                const fontdata = `
                    @font-face {
                        font-family : "${this.TempFontFamily}";
                        src : url(${this.MetaFile.base64}) format("woff");
                    }
                `;
                newStyle.appendChild(document.createTextNode(fontdata));                                
                document.head.appendChild(newStyle);

                this.RCUpdate();
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };               
        }
    }  
    UploadFile(e) {
        e && e.stopPropagation();
        this.setState({
            uploading : true
        })
        Globals.ProjectManager.Tokens.UploadCustomFont(this.MetaFile, () => {
            this.setState({
                uploading : false
            })
            this.props.onSelectFont({
                provider : Strings.CUSTOM,
                fontId : this.MetaFile.Id,
                name : this.MetaFile.Name,
                family : this.MetaFile.family
            });
        });        
    }
    renderCustom() {
        const style_line = {lineHeight : '24px'};
        const style_box = {position : 'relative', height : '300px'};
        const style_innerbox = {marginBottom : '4px', flex : 2};
        if (this.props.compact) {
            style_box.height = '100%';

            style_innerbox.flexDirection = 'column';
            style_innerbox.paddingTop = '16px';
        }
        return (
            <SC.FCol f1 fw fh>
                <Dropzone
                    onDrop={this.onDrop}
                    ref={(r) => this.Ref_DropZone = r}
                    style={{}}
                    accept=".woff,. woff2, .ttf, .otf"
                    activeStyle={{}}
                >
                {({ isDragActive, getRootProps, getInputProps, isDragReject, acceptedFiles, rejectedFiles }) => {
                    return (
                        <SC.FCol style={style_box} {...getRootProps()}>
                            <input {...getInputProps()} />
                            <NewItemCell
                                style={style_innerbox}
                                active={isDragActive}
                            >
                                <SC.FRow f1>
                                    <SC.FCol f1 alc>
                                        <SC.Icons.Icon_Button>
                                            <SC.Div_Flex_Cell style={{marginBottom : '8px'}}>
                                                <SC.Icons.Icon_Button hasFill noHover>
                                                    <SC.Icons.Upload size={48} />
                                                </SC.Icons.Icon_Button>
                                            </SC.Div_Flex_Cell>
                                        </SC.Icons.Icon_Button>                                    
                                        <div style={style_line}>Drop Here</div>
                                        <div style={style_line}>OR</div>
                                        <div style={style_line}>Click to Seelct</div>
                                        <div style={style_line}>.woff, .woff2, .ttf font files</div>
                                    </SC.FCol>
                                </SC.FRow>     
                                {
                                    this.MetaFile ? 
                                    <SC.FCol f1 alc style={{cursor : 'unset'}} onClick={(e) => e.stopPropagation()}>
                                        <div style={style_line}>{this.MetaFile.Name}</div>
                                        <div style={{fontFamily : this.TempFontFamily, fontSize : '36px', marginBottom : '16px'}}>Preview Text</div>
                                        <SC.FCol>
                                            <Input_Name
                                                ref={(r) => this.Ref_Name = r}
                                                InputType={SC.Card_Input}
                                                required
                                                styleProps = {{
                                                    style : {
                                                        marginBottom : '16px',
                                                        fontSize : '16px',
                                                        width : '220px'
                                                    }
                                                }}
                                                value={this.MetaFile.family}
                                                onChange={(name) => {this.MetaFile.family = name; this.RCUpdate();}}
                                            />
                                        </SC.FCol>
                                        <SC.Buttons.RoundButton onClick={this.UploadFile}>Upload Font</SC.Buttons.RoundButton>
                                    </SC.FCol> : null
                                }                           
                            </NewItemCell>                            
                            {
                                this.state.uploading ?
                                <SC.Div_Flex_Cell style={{
                                        position : 'absolute', top : 0, left : 0, right : 0, bottom : 0, backgroundColor : 'rgba(0,0,0,0.5)'
                                    }}>
                                    <SC.Loading_Icon justIcon/>
                                </SC.Div_Flex_Cell> : null
                            }
                        </SC.FCol>
                    );
                }}

                </Dropzone>
            </SC.FCol>
        )
    }
}

const NewItemCell = styled.div`
    display : flex;
    align-items : center;
    justify-content : center;
    font-size : 14px;
    cursor : pointer;
    border : 1px dashed ${props => props.active ? props.theme.color_brand : '#636469'};
    transition : all 0.2s ease;
    flex : 1;
    &:hover {
        border : 1px dashed #aeaeae;
    }
`;
