import React from 'react';
import ReactDOM from 'react-dom';

import {
    ReactBaseComponent,
    SC,
    Utils,
    UIUtils,
    AppState,
    Links,
    Events,
    AppLayout,
    Globals,
    Strings
} from '../../../importer';
import styled from 'styled-components';
import { OutsideCloseDotMenuContainer } from '../../../views/project/panels/left/common';
import { AccountMenuItem } from '../../../views/header/account';

import Icon_Demo from '../../../components/illustrations/content';
import { PopupArrow } from '../../../views/project/panels/right/iteminspector/styleitems/common';
import { GetDataApi } from '../../../appstate/AppState';

export const FigmaInfoPanel = (props) => {
    return (
        <div
            style={{
                backgroundColor : props.warning ? 'rgba(255, 235, 0, 0.54)' : '#e8f8ff',
                color : '#000',
                marginBottom : '8px',
                borderRadius : '2px',
                position : 'relative',
                lineHeight : '16px',
                padding : '14px',
                paddingRight : '18px',
                ...props.style
            }}
        >
            {props.children}
            {
                props.onClose && 
                <SC.Icons.Icon_Button 
                    hasFill hasCursor 
                    title={`Dont show again`}
                    onClick={props.onClose}
                    style={{
                    position : 'absolute',
                    right : '8px', top : '8px'
                }}>
                    <SC.Icons.Icon_Close size={14} />
                </SC.Icons.Icon_Button>
            }            
        </div>
    )
}

export class FigmaMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = { version : AppState.Versions.Figma.DSM }
        this.close = this.close.bind(this);        
        this.Ref_Container = React.createRef();
    }
    
    close(e) {
        this.props.onToggle();
    }
    componentDidMount() {
        
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (!this.props.open && nextProps.open) {
            if (window.innerHeight < 700) {
                this.hideVideo = true;
            }
        }
        return true;
    }
    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.open && this.props.open) {
            GetDataApi().api.get_path('shared/versions/figma/latest').then(async (figmaVersion) => {
                if (figmaVersion && figmaVersion.value) {
                    AppState.Versions.Figma.DSM = figmaVersion.value;
                    this.setState({version : figmaVersion.value})
                }
            });
        }
    }
    render() { 
        let projectMenu;
        if (this.props.projectId && this.props.open) {
            const style = {paddingTop : '8px', paddingBottom : '8px'};
            projectMenu = (
                <SC.FCol style={{
                    borderBottom : '1px solid #4c4c4c',
                    paddingBottom : '8px',
                }}>
                    <AccountMenuItem onClick={this.props.onClose} style={{paddingTop : '12px', paddingBottom : '6px'}}>
                        <SC.FRow alc style={{marginLeft : '-8px'}}>
                            <SC.Icons.Icon_Button hasFill fill='#fff' hoverFill='#fff' style={{marginRight : '4px', marginBottom : '-2px'}}>
                                <SC.Icons.Arrow_Back />
                            </SC.Icons.Icon_Button>
                            Back to Projects    
                        </SC.FRow>                        
                    </AccountMenuItem>
                    <AccountMenuItem style={style} onClick={(e) => {this.close(e); this.props.onRename();}}>
                        Rename
                    </AccountMenuItem>
                    <AccountMenuItem style={style} onClick={(e) => {this.close(e); this.props.onEditStates();}}>
                        Edit Theme States
                    </AccountMenuItem>
                    <AccountMenuItem style={style} onClick={(e) => {this.close(e); this.props.onDuplicate();}}>
                        Duplicate Project
                    </AccountMenuItem>
                    <AccountMenuItem style={style} onClick={(e) => {this.close(e); this.props.onDelete();}}>
                        Delete Project
                    </AccountMenuItem>
                    <AccountMenuItem style={style} onClick={(e) => {this.close(e); this.props.onDetachFromFigma();}} title='This will detach this DSM library from this Figma file and all DSM Token - Figma Style connections will be lost.'>
                        Detach from Figma File
                    </AccountMenuItem>
                </SC.FCol>
            )
        }
        return (  
            <SC.Icons.Icon_ButtonBox hasFill hasCursor  hoverFill='#fff' fill='hsla(0,0%,100%,.8)' onMouseDown={this.props.open ? null : this.props.onToggle} 
                style={{position : 'relative', pointerEvents : 'all', backgroundColor : '#2c2c2c'}} >
                    {
                        this.props.notifyVersion &&
                        <div style={{position : 'absolute', width : '8px', height : '8px', borderRadius : '5px', top : '4px', right : '2px', backgroundColor : 'red' }}></div>
                    }
                <SC.Svg width="18" height="18" viewBox="0 0 18 18">
                    <path d="M16 13H2v1h14v-1zm0-5H2v1h14V8zm0-5H2v1h14V3z" fillRule="nonzero" stroke="none"></path>
                </SC.Svg>

                {
                    this.props.open && 
                    <SC.AbsoluteOverlay style={{position : 'fixed', zIndex : 99999, cursor : 'default'}}>
                        <OutsideCloseDotMenuContainer onClose={this.close}
                            onGetBounds={() => {
                                if (this.Ref_Container.current) {
                                    return UIUtils.Utils.GetBounds(this.Ref_Container.current);
                                }
                            }}
                            style={{
                                position : 'fixed', top : this.props.top || '41px', right : '10px', left : '10px',
                                 paddingTop : 0, maxHeight : 'calc(100% - 54px)', height : '100%',
                                 backgroundColor : 'unset',
                                 boxShadow : 'unset'
                            }}
                        >
                            <SC.CustomScrollbars>
                                <SC.FCol ref={this.Ref_Container} fw style={{backgroundColor : '#2c2c2c', paddingBottom : '8px', boxShadow : SC.CurrentTheme.theme.popup_shadow,}}>
                                    {
                                        AppState.Versions.Figma.Plugin !== this.state.version && !AppLayout.isDevelopment &&
                                        <div style={{color : '#ff4242', padding : '14px', lineHeight : '22px', borderBottom : '1px solid #4c4c4c', fontWeight : 'bold', fontSize : '14px', cursor : 'pointer'}} onClick={() => { 
                                            if (window && window.location)
                                                window.location.reload(true);
                                        }}>Click to reload DSM and then reload the plugin to get latest version!</div>
                                    }
                                    <PopupArrow color='#2c2c2c' right='-2px' left='unset' arrow='9px' />
                                    
                                    {projectMenu}      
                                    <FigmaGoToolabsItem compact={this.hideVideo} onClose={this.close} projectId={this.props.projectId} style={{backgroundColor : '#212121'}} />
                                    <AccountMenuItem onMouseDown={(e) => {this.close(e); this.props.onSettings();}}>
                                        Settings
                                    </AccountMenuItem>
                                    <AccountMenuItem onMouseDown={() => {this.props.onToggle(); AppState.Logout()}}>
                                        Logout
                                    </AccountMenuItem>
                                    <SC.FRow alc jsb style={{
                                        borderTop : '1px solid #4c4c4c',                                
                                        padding : '14px', paddingBottom : '8px',
                                        fontWeight : 200,
                                        opacity : 0.7
                                    }}>
                                        <div>Plugin Version {AppState.Versions.Figma.Plugin}</div>                                
                                    </SC.FRow>                                    
                                </SC.FCol>                                
                            </SC.CustomScrollbars>                            
                        </OutsideCloseDotMenuContainer>
                    </SC.AbsoluteOverlay>
                }
            </SC.Icons.Icon_ButtonBox>            
        );
    }
}


export const FigmaGoToolabsItem = (props) => {
    return (
        <div
            onMouseDown={(e) => {
                e && e.stopPropagation();
                window.open( 'https://app.toolabs.com/' + (props.projectId ? ('#/board/' + props.projectId) : ''), "_blank");              
                setTimeout(() => {
                    props.onClose && props.onClose();
                }, 200);
            }} 
        >
            <AccountMenuItem style={{
                borderBottom : '1px solid #4c4c4c',
                paddingTop : '16px', marginBottom : '8px', paddingBottom : '12px', ...props.style
            }}>
                <div>Go to Toolabs DSM</div>
                <div style={{
                    display : 'grid',
                    gridTemplateColumns : '80px 1fr', 
                    gridGap : '10px'
                }}>
                    <Icon_Demo size={100} circleFill='#f0f2ff38'/>
                    <SC.FCol
                        style={{
                            fontWeight : 500,
                            fontSize : '13px',
                            lineHeight : '1.7em',
                            color : '#fff',
                            marginLeft : '24px',
                            paddingTop : '16px'
                        }}
                    >
                        <div>Document</div>
                        <div style={{fontWeight : 300, fontSize : '12px', color : '#dedede'}}>your #designsystem</div>
                        <div>Export Design Tokens</div>
                    </SC.FCol>
                </div>              
                {!props.compact && <video src='https://www.toolabs.com/contentassets/videos/documentation.webm' style={{width : '100%'}} autoPlay loop/>}
            </AccountMenuItem>
        </div>        
    )
}

export const FigmaIcon_Style = (props) => {
    return (
        <SC.Svg width="10"
        height="10"
        fill="none"
        viewBox="0 0 10 10" style={props.style} >
            <path
                fill="#000"
                fillOpacity="0.8"
                fillRule="evenodd"
                d="M.5 2a1.5 1.5 0 103 0 1.5 1.5 0 00-3 0zm6 0a1.5 1.5 0 103 0 1.5 1.5 0 00-3 0zM8 9.5a1.5 1.5 0 110-3 1.5 1.5 0 010 3zM.5 8a1.5 1.5 0 103 0 1.5 1.5 0 00-3 0z"
                clipRule="evenodd"
            ></path>
        </SC.Svg>
    )
}
export const FigmaIcon_DetachStyle = (props) => {
    return (
        <SC.Svg width="14"
        height="14"
        fill="none"
        viewBox="0 0 14 14" style={props.style} >
            <path
                fill="#000"
                fillOpacity="0.8"
                fillRule="evenodd"
                d="M4 0v3h1V0H4zm9.103.896a2.975 2.975 0 00-4.207 0l-2.75 2.75.707.708 2.75-2.75a1.975 1.975 0 112.793 2.792l-2.75 2.75.707.708 2.75-2.75a2.975 2.975 0 000-4.208zM.896 13.104a2.975 2.975 0 010-4.208l2.75-2.75.707.708-2.75 2.75a1.975 1.975 0 102.793 2.792l2.75-2.75.707.707-2.75 2.75a2.975 2.975 0 01-4.207 0zM14 10h-3V9h3v1zm-4 1v3H9v-3h1zM3 4H0v1h3V4z"
                clipRule="evenodd"
            ></path>
        </SC.Svg>
    )
}

export const FigmaIcon_Edit = (props) => {
    return (
        <SC.Svg width="12"
        height="14"
        fill="none"
        viewBox="0 0 12 14" style={props.style} >
            <path
                fill="#000"
                fillOpacity="0.8"
                fillRule="evenodd"
                d="M2 7.05V0h1v7.05a2.5 2.5 0 010 4.9V14H2v-2.05a2.5 2.5 0 010-4.9zM4 9.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM9 14h1V6.95a2.5 2.5 0 000-4.9V0H9v2.05a2.5 2.5 0 000 4.9V14zm2-9.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z"
                clipRule="evenodd"
            ></path>
        </SC.Svg>
    )
}
export const FigmaIcon_Attach = (props) => {
    return (
        <SC.Svg width="6" height="14" viewBox="0 0 6 14" fill="none" style={props.style} >
            <path
                fill="#000"
                fillOpacity="0.8"
                fillRule="evenodd"
                d="M3 1C4.10457 1 5 1.89543 5 3V5H6V3C6 1.34315 4.65685 0 3 0C1.34315 0 0 1.34315 0 3V5H1V3C1 1.89543 1.89543 1 3 1ZM5 9H6V11C6 12.6569 4.65685 14 3 14C1.34315 14 0 12.6569 0 11V9H1V11C1 12.1046 1.89543 13 3 13C4.10457 13 5 12.1046 5 11V9ZM2.5 4V10H3.5V4H2.5Z"
                clipRule="evenodd"
            ></path>
        </SC.Svg>
    )
}
export const FigmaIcon_Layout_Padding = (props) => {
    return (
        <SC.Svg width="24" height="24" viewBox="0 0 24 24" style={props.style} fill={props.isDSM ? SC.CurrentTheme.theme.icon : "#000"}>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15 9H9v6h6V9zM8 8v8h8V8H8z"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18 6H6v12h12V6zM5 5v14h14V5H5z"/>
        </SC.Svg>
    )
}
export const FigmaIcon_Layout_Spacing = (props) => {
    return (
        <SC.Svg width="12" height="13" viewBox="0 0 12 13" style={props.style} fill={props.isDSM ? SC.CurrentTheme.theme.icon : "#000"}>
            <path d="M11 13v-2H1v2H0v-3h12v3h-1zm1-10H0V0h1v2h10V0h1v3zM9 7V6H3v1h6z"></path>
        </SC.Svg>
    )
}
export const Figma_PopupItem = (props) => {
    const style = {
        minHeight : '32px', fontWeight : 500, maxHeight : '32px', boxSizing : 'border-box', display : 'flex', alignItems : 'center', ...props.style
    }
    if (props.disabled) {
        style.opacity = 0.4;
        style.cursor = 'not-allowed';
    }
    return (
        <SC.PopupItem onClick={props.disabled ? null : props.onClick} style={style}>
            {props.children}
        </SC.PopupItem>
    )
}

export class FigmaStyleMenuPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = { offset : 0 }

        this.Ref_Menu = React.createRef();
    }
    componentDidMount() {
        const DN = ReactDOM.findDOMNode(this.Ref_Menu.current);
        if (DN) {
            const bounds = DN.getBoundingClientRect();
            if (bounds.bottom > document.documentElement.clientHeight) {
                this.setState({
                    offset : bounds.bottom - document.documentElement.clientHeight + 20
                })
            }
        }
    }
    render() { 
        return (  
            <OutsideCloseDotMenuContainer 
                onClose={this.props.onClose}
                x={this.props.noTransform ? 0 : '-50%'}
                style={{
                    left : '50%',
                    transform :  this.props.noTransform ? 'none' : 'translateX(-50%)',
                    width : '200px',
                    paddingTop : '4px',
                    paddingBottom : '4px',
                    marginTop : '30px',
                    top : Utils.px((this.props.top || 0) - this.state.offset),
                    ...this.props.style
                }}
            >
                <div ref={this.Ref_Menu}>
                    {this.props.children}
                </div>                    
            </OutsideCloseDotMenuContainer>
        );
    }
}