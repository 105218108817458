import React from 'react';
import BaseItem from '../BaseItem';
import {Utils, MetaData} from '../../importer';

import Generic_Text from './Text';
import Generic_Button from './Button';
import Generic_TextField from './TextField';
import Generic_DateInput from './DateInput';
import Generic_Textarea from './Textarea';
import Generic_Checkbox from './Checkbox';
import Generic_Toggle from './Toggle';
import Generic_RangeInput from './RangeInput';
import Generic_ProgressBar from './ProgressBar';
import Generic_RadioGroup from './RadioGroup';
import Generic_Selectbox from './Selectbox';
import Generic_Dropdown from './Dropdown';
import Generic_Carousel from './Carousel';
import Generic_Accordion from './Accordion';
import Generic_Tab from './Tab';
import Generic_Table from './Table';


const Generics = MetaData.Components.Generic;

export default (props) => {
    const {MetaItem} = props;
    let GenericType;
    if (MetaItem) {
        if (MetaItem.Type === Generics.Text.Type)
            GenericType = Generic_Text;
        else if (MetaItem.Type === Generics.Button.Type)
            GenericType = Generic_Button;
        else if (MetaItem.Type === Generics.Input.Type)
            GenericType = Generic_TextField;
        else if (MetaItem.Type === Generics.DateInput.Type)
            GenericType = Generic_DateInput;
        else if (MetaItem.Type === Generics.Textarea.Type)
            GenericType = Generic_Textarea;
        else if (MetaItem.Type === Generics.Checkbox.Type)
            GenericType = Generic_Checkbox;
        else if (MetaItem.Type === Generics.Toggle.Type)
            GenericType = Generic_Toggle;
        else if (MetaItem.Type === Generics.RangeInput.Type)
            GenericType = Generic_RangeInput;
        else if (MetaItem.Type === Generics.ProgressBar.Type)
            GenericType = Generic_ProgressBar;
        else if (MetaItem.Type === Generics.RadioGroup.Type)
            GenericType = Generic_RadioGroup;
        else if (MetaItem.Type === Generics.Select.Type)
            GenericType = Generic_Selectbox;
        else if (MetaItem.Type === Generics.Dropdown.Type)
            GenericType = Generic_Dropdown;
        else if (MetaItem.Type === Generics.Carousel.Type)
            GenericType = Generic_Carousel;
        else if (MetaItem.Type === Generics.Accordion.Type)
            GenericType = Generic_Accordion;
        else if (MetaItem.Type === Generics.Tab.Type)
            GenericType = Generic_Tab;
        else if (MetaItem.Type === Generics.Table.Type)
            GenericType = Generic_Table;            
        
        if (GenericType) {
            return (
                <GenericType {...props} />
            )
        }
    }
    

    return (
        <div>{MetaItem.Type}</div>
    )

}
