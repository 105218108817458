import Styles from '../../styles';

const CommonStyles = [
    Styles.width.name,
    Styles.height.name,
    Styles.color.name,
    Styles.backgroundColor.name
];

const CommonEvents = [

];

const WebComponents = {
    Names : [
        'Div',
        'Span',
        'Text',
        'Button'
    ],
    Components : {
        Div : {
            Type : 'Div',
            Name : 'Container',
            Description : '',
            Styles : CommonStyles.concat([
                Styles.padding.name
            ])            
        },
        Span : {
            Type : 'Span',
            Name : 'Span',
            Description : '',
            Styles : CommonStyles.concat([
                
            ])
        },
        Text : {
            Type : 'Text',
            Name : 'Text',
            Description : '',
            Styles : CommonStyles.concat([
                
            ])
        },
        Button : {
            Type : 'Button',
            Name : 'Button',
            Description : '',
            Styles : CommonStyles.concat([
                
            ]),
            Events : CommonEvents.concat([
                {
                    name : 'click',
                    label : 'Click'
                }
            ])
        }
    }
};

export default WebComponents;
// module.exports = WebComponents;
