import DataType from '../../datatypes';

export default {
    Type : 'ApexCharts',
    Label : 'ApexCharts',
    Options : {
        ChartType : {
            name : 'ChartType',
            default : 'Pie'
        }, 
        Sparkline : { 
            name : 'Sparkline'
        },
        ChartTheme : { 
            name : 'ChartTheme'
        },
        DataSource : { 
            name : 'DataSource'
        },
        Animated : { 
            name : 'Animated'
        }
    },
    MetaData : {
        Types : {
            Line : {
                Label : 'Line',
                Options : {
                    Smoothed : {
                        label : 'Smooth Lines',
                        type : DataType.boolean
                    }
                },
                SubTypes : {
                    Default : {
                        init : (ApexChart, node, params) => {
                            const options = {
                                chart : {
                                    type: 'line',
                                    height : params.height,
                                    width : params.width,
                                    sparkline : {
                                        enabled : params.sparkline
                                    },
                                    animations : {
                                        enabled : params.animated
                                    }
                                },
                                series: [{
                                    name: 'sales',
                                    data: [30,40,35,50,49,60,70,91,125]
                                }],
                                xaxis: {
                                    categories: [1991,1992,1993,1994,1995,1996,1997, 1998,1999]
                                }
                            }
                            const eChartInstance =  new ApexChart(node, options);
                            eChartInstance.render();
                            return eChartInstance;
                        }
                    },
                }
            },
            Area : {
                Label : 'Area',
                Options : {
                    Smoothed : {
                        label : 'Smooth Lines',
                        type : DataType.boolean
                    }
                },
                SubTypes : {
                    Default : {
                        init : (ApexChart, node, params) => {
                            const options = {
                                chart: {
                                    height : params.height,
                                    width : params.width,
                                    sparkline : {
                                        enabled : params.sparkline
                                    },
                                    animations : {
                                        enabled : params.animated
                                    },
                                    type: 'area',
                                    zoom: {
                                        enabled: false
                                    }
                                },
                                series: [
                                    {
                                        name: "Series 1",
                                        data: [
                                        {
                                            x: "02-10-2017",
                                            y: 34
                                        },
                                        {
                                            x: "02-11-2017",
                                            y: 43
                                        },
                                        {
                                            x: "02-12-2017",
                                            y: 31
                                        },
                                        {
                                            x: "02-13-2017",
                                            y: 43
                                        },
                                        {
                                            x: "02-14-2017",
                                            y: 33
                                        },
                                        {
                                            x: "02-15-2017",
                                            y: 52
                                        }
                                        ]
                                    }
                                ],
                                xaxis: {
                                type: "datetime"
                                }
                            }
                            const eChartInstance =  new ApexChart(node, options);
                            eChartInstance.render();
                            return eChartInstance;
                        }
                    },
                }
            },
            Column : {
                Label : 'Column',
                Options : {
                    
                },
                SubTypes : {
                    Default : {
                        init : (ApexChart, node, params) => {
                            const options = {
                                chart: {
                                    height : params.height,
                                    width : params.width,
                                    sparkline : {
                                        enabled : params.sparkline
                                    },
                                    animations : {
                                        enabled : params.animated
                                    },
                                    type: 'bar',
                                },
                                plotOptions: {
                                    bar: {
                                        horizontal: false,
                                        endingShape: 'rounded',
                                        columnWidth: '55%',
                                    },
                                },
                                dataLabels: {
                                    enabled: false
                                },
                                stroke: {
                                    show: true,
                                    width: 2,
                                    colors: ['transparent']
                                },
                                series: [{
                                    name: 'Net Profit',
                                    data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
                                }, {
                                    name: 'Revenue',
                                    data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
                                }, {
                                    name: 'Free Cash Flow',
                                    data: [35, 41, 36, 26, 45, 48, 52, 53, 41]
                                }],
                                xaxis: {
                                    categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
                                },
                                yaxis: {
                                    title: {
                                        text: '$ (thousands)'
                                    }
                                },
                                fill: {
                                    opacity: 1
                    
                                },
                                // legend: {
                                //     floating: true
                                // },
                                tooltip: {
                                    y: {
                                        formatter: function (val) {
                                            return "$ " + val + " thousands"
                                        }
                                    }
                                }
                            }
                            const eChartInstance =  new ApexChart(node, options);
                            eChartInstance.render();
                            return eChartInstance;
                        }
                    },
                }
            },
            Bar : {
                Label : 'Bar',
                Options : {
                    
                },
                SubTypes : {
                    Default : {
                        init : (ApexChart, node, params) => {
                            const options = {
                                chart: {
                                    height : params.height,
                                    width : params.width,
                                    sparkline : {
                                        enabled : params.sparkline
                                    },
                                    animations : {
                                        enabled : params.animated
                                    },
                                    type: 'bar'                                            
                                },
                                plotOptions: {
                                    bar: {
                                        horizontal: true,
                                    }
                                },
                                dataLabels: {
                                    enabled: false
                                },
                                series: [{
                                    data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380]
                                }],
                                xaxis: {
                                    categories: ['South Korea', 'Canada', 'United Kingdom', 'Netherlands', 'Italy', 'France', 'Japan', 'United States', 'China', 'Germany'],
                                },
                                yaxis: {
                                    
                                },
                                tooltip: {
                    
                                }
                            }
                            const eChartInstance =  new ApexChart(node, options);
                            eChartInstance.render();
                            return eChartInstance;
                        }
                    },
                }
            },
            Pie : {
                Label : 'Pie',
                Options : {
                    
                },
                SubTypes : {
                    Default : {
                        init : (ApexChart, node, params) => {
                            const options = {
                                chart: {
                                    height : params.height,
                                    width : params.width,
                                    sparkline : {
                                        enabled : params.sparkline
                                    },
                                    animations : {
                                        enabled : params.animated
                                    },
                                    type: 'pie',
                                },
                                series: [44, 55, 41, 17, 15],
                            }
                            const eChartInstance =  new ApexChart(node, options);
                            eChartInstance.render();
                            return eChartInstance;
                        }
                    },
                }
            },
            Doughnut : {
                Label : 'Doughnut',
                Options : {
                    
                },
                SubTypes : {
                    Default : {
                        init : (ApexChart, node, params) => {
                            const options = {
                                chart: {
                                    height : params.height,
                                    width : params.width,
                                    sparkline : {
                                        enabled : params.sparkline
                                    },
                                    animations : {
                                        enabled : params.animated
                                    },
                                    type: 'donut',
                                },
                                series: [44, 55, 41, 17, 15],
                            }
                            const eChartInstance =  new ApexChart(node, options);
                            eChartInstance.render();
                            return eChartInstance;
                        }
                    },
                }
            },
            Radial : {
                Label : 'Radial',
                Options : {
                    
                },
                SubTypes : {
                    Default : {
                        init : (ApexChart, node, params) => {
                            const options = {
                                chart: {
                                    height : params.height,
                                    width : params.width,
                                    sparkline : {
                                        enabled : params.sparkline
                                    },
                                    animations : {
                                        enabled : params.animated
                                    },
                                    type: 'radialBar',
                                },
                                plotOptions: {
                                    radialBar: {
                                        hollow: {
                                            size: '70%',
                                        }
                                    },
                                },
                                series: [70],
                                labels: ['Cricket'],
                            }
                            const eChartInstance =  new ApexChart(node, options);
                            eChartInstance.render();
                            return eChartInstance;
                        }
                    },
                }
            },
            Bubble : {
                Label : 'Bubble',
                Options : {
                    
                },
                SubTypes : {
                    Default : {
                        init : (ApexChart, node, params) => {                                    
                            const generateData = function(baseval, count, yrange) {
                                var i = 0;
                                var series = [];
                                while (i < count) {
                                    var x = Math.floor(Math.random() * (750 - 1 + 1)) + 1;;
                                    var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
                                    var z = Math.floor(Math.random() * (75 - 15 + 1)) + 15;
                    
                                    series.push([x, y, z]);
                                    baseval += 86400000;
                                    i++;
                                }
                                return series;
                            }
                            const options = {
                                chart: {
                                    height : params.height,
                                    width : params.width,
                                    sparkline : {
                                        enabled : params.sparkline
                                    },
                                    animations : {
                                        enabled : params.animated
                                    },
                                    type: 'bubble',
                                },
                                dataLabels: {
                                    enabled: false
                                },
                                series: [{
                                        name: 'Bubble1',
                                        data: generateData(new Date('11 Feb 2017 GMT').getTime(), 20, {
                                            min: 10,
                                            max: 60
                                        })
                                    },
                                    {
                                        name: 'Bubble2',
                                        data: generateData(new Date('11 Feb 2017 GMT').getTime(), 20, {
                                            min: 10,
                                            max: 60
                                        })
                                    },
                                    {
                                        name: 'Bubble3',
                                        data: generateData(new Date('11 Feb 2017 GMT').getTime(), 20, {
                                            min: 10,
                                            max: 60
                                        })
                                    },
                                    {
                                        name: 'Bubble4',
                                        data: generateData(new Date('11 Feb 2017 GMT').getTime(), 20, {
                                            min: 10,
                                            max: 60
                                        })
                                    }
                                ],
                                fill: {
                                    opacity: 0.8,
                                    gradient: {
                                        enabled: false
                                    }
                                },
                                title: {
                                    text: 'Simple Bubble Chart'
                                },
                                xaxis: {
                                    tickAmount: 12,
                                    type: 'category',
                                },
                                yaxis: {
                                    max: 70
                                }
                            }
                            const eChartInstance =  new ApexChart(node, options);
                            eChartInstance.render();
                            return eChartInstance;
                        }
                    },
                }
            },
            Scatter : {
                Label : 'Scatter',
                Options : {
                    
                },
                SubTypes : {
                    Default : {
                        init : (ApexChart, node, params) => {
                            const options = {
                                chart: {
                                    height : params.height,
                                    width : params.width,
                                    sparkline : {
                                        enabled : params.sparkline
                                    },
                                    animations : {
                                        enabled : params.animated
                                    },
                                    type: 'scatter',
                                    zoom: {
                                        enabled: false
                                    }
                                },                                        
                                series: [{
                                    name: "SAMPLE A",
                                    data: [
                                    [16.4, 5.4], [21.7, 2], [25.4, 3], [19, 2], [10.9, 1], [13.6, 3.2], [10.9, 7.4], [10.9, 0], [10.9, 8.2], [16.4, 0], [16.4, 1.8], [13.6, 0.3], [13.6, 0], [29.9, 0], [27.1, 2.3], [16.4, 0], [13.6, 3.7], [10.9, 5.2], [16.4, 6.5], [10.9, 0], [24.5, 7.1], [10.9, 0], [8.1, 4.7], [19, 0], [21.7, 1.8], [27.1, 0], [24.5, 0], [27.1, 0], [29.9, 1.5], [27.1, 0.8], [22.1, 2]]
                                },{
                                    name: "SAMPLE B",
                                    data: [
                                    [6.4, 13.4], [1.7, 11], [5.4, 8], [9, 17], [1.9, 4], [3.6, 12.2], [1.9, 14.4], [1.9, 9], [1.9, 13.2], [1.4, 7], [6.4, 8.8], [3.6, 4.3], [1.6, 10], [9.9, 2], [7.1, 15], [1.4, 0], [3.6, 13.7], [1.9, 15.2], [6.4, 16.5], [0.9, 10], [4.5, 17.1], [10.9, 10], [0.1, 14.7], [9, 10], [12.7, 11.8], [2.1, 10], [2.5, 10], [27.1, 10], [2.9, 11.5], [7.1, 10.8], [2.1, 12]]
                                },{
                                    name: "SAMPLE C",
                                    data: [
                                    [21.7, 3], [23.6, 3.5], [24.6, 3], [29.9, 3], [21.7, 20], [23, 2], [10.9, 3], [28, 4], [27.1, 0.3], [16.4, 4], [13.6, 0], [19, 5], [22.4, 3], [24.5, 3], [32.6, 3], [27.1, 4], [29.6, 6], [31.6, 8], [21.6, 5], [20.9, 4], [22.4, 0], [32.6, 10.3], [29.7, 20.8], [24.5, 0.8], [21.4, 0], [21.7, 6.9], [28.6, 7.7], [15.4, 0], [18.1, 0], [33.4, 0], [16.4, 0]]
                                }],
                                xaxis: {
                                    tickAmount: 10,
                                },
                                yaxis: {
                                    tickAmount: 7
                                }
                            }
                            const eChartInstance =  new ApexChart(node, options);
                            eChartInstance.render();
                            return eChartInstance;
                        }
                    },
                }
            },
            Heatmap : {
                Label : 'Heatmap',
                Options : {
                    
                },
                SubTypes : {
                    Default : {
                        init : (ApexChart, node, params) => {
                           
                            const generateData = function(count, yrange) {
                                var i = 0;
                                var series = [];
                                while (i < count) {
                                    var x = (i + 1).toString();
                                    var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
                    
                                    series.push({
                                        x: x,
                                        y: y
                                    });
                                    i++;
                                }
                                return series;
                            }
                            const options = {
                                chart: {
                                    height : params.height,
                                    width : params.width,
                                    sparkline : {
                                        enabled : params.sparkline
                                    },
                                    animations : {
                                        enabled : params.animated
                                    },
                                    type: 'heatmap',
                                },
                                dataLabels: {
                                    enabled: false
                                },
                                colors: ["#008FFB"],
                                series: [{
                                        name: 'Metric1',
                                        data: generateData(20, {
                                            min: 0,
                                            max: 90
                                        })
                                    },
                                    {
                                        name: 'Metric2',
                                        data: generateData(20, {
                                            min: 0,
                                            max: 90
                                        })
                                    },
                                    {
                                        name: 'Metric3',
                                        data: generateData(20, {
                                            min: 0,
                                            max: 90
                                        })
                                    },
                                    {
                                        name: 'Metric4',
                                        data: generateData(20, {
                                            min: 0,
                                            max: 90
                                        })
                                    },
                                    {
                                        name: 'Metric5',
                                        data: generateData(20, {
                                            min: 0,
                                            max: 90
                                        })
                                    },
                                    {
                                        name: 'Metric6',
                                        data: generateData(20, {
                                            min: 0,
                                            max: 90
                                        })
                                    },
                                    {
                                        name: 'Metric7',
                                        data: generateData(20, {
                                            min: 0,
                                            max: 90
                                        })
                                    },
                                    {
                                        name: 'Metric8',
                                        data: generateData(20, {
                                            min: 0,
                                            max: 90
                                        })
                                    },
                                    {
                                        name: 'Metric9',
                                        data: generateData(20, {
                                            min: 0,
                                            max: 90
                                        })
                                    }
                                ],
                                xaxis: {
                                    type: 'category',
                                },
                            }
                            const eChartInstance =  new ApexChart(node, options);
                            eChartInstance.render();
                            return eChartInstance;
                        }
                    },
                }
            },
        }                                
    }
}