import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    AppLayout,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../../../../../importer';

export default class DocumentItem_GistFrame extends React.Component
{
    constructor(props) {
        super(props);

        this.Ref_IFrame = React.createRef();
    }
    componentDidMount() {
        this.CreateContent();
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.url !== this.props.url) {
            this.CreateContent();
        }
    }
    CreateContent() {
        if (this.Ref_IFrame.current) {
            let doc = this.Ref_IFrame.current.document;
            if (this.Ref_IFrame.current.contentDocument) 
                doc = this.Ref_IFrame.current.contentDocument;
            else if (this.Ref_IFrame.current.contentWindow) 
                doc = this.Ref_IFrame.current.contentWindow.document;
            
            const styles = '<style>*{font-size:12px;}</style>';
            // const iframeHtml = `<html><head><base target="_parent">${styles}</head><body><script type="text/javascript" src="${this.props.url}"></script>}</body></html>`;
            
            let url = this.props.url;
            if (url.indexOf('.js') < 0)
                url += '.js';
            const iframeHtml = `<html><head><base target="_parent">${styles}</head><body><script src="${url}"></script></body></html>`;

            doc.open();
            doc.writeln(iframeHtml);
            doc.close();
        }
    }
    render() {
        return (
            <iframe                   
                ref={this.Ref_IFrame}
                style={this.props.style}
                allowfullscreen
            /> 
        )
    }
}