import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    AppLayout,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../../../../importer';
import styled, { css} from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import ToolbarIcons from '../../../../../../styled/toolbar';

import SlateEditor from '../text/editor';

export default class DocumentItem_Image extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {

        };    

        this.onChangeSubtitle = this.onChangeSubtitle.bind(this);
        this.onReloadSubtitle = this.onReloadSubtitle.bind(this);
        
        this.Load(this.props.id);
    }    
    shouldComponentUpdate(nextProps, nextState) {

        if (this.props.autoFocus !== nextProps.autoFocus)
            return true;
        if (this.state.ShouldUpdate !== nextState.ShouldUpdate)
            return true;
        
        if (this.props.RenderId !== nextProps.RenderId) {
            this.Load(nextProps.id);
            return true;
        }        
        if (Utils.HasAnyChange(this.props, nextProps, 'preview', 'GlobalThemeId', 'GlobalState', 'StyleId', 'themeId')) {
            if (this.props.preview !== nextProps.preview)
                this.EditorId = Utils.Id();
            return true;
        }
        
        return false;
    }
    Load(id) {
        
    }
    componentDidUpdate(prevProps, prevState) {
        this.state.ShouldUpdate = false;
    }
    
    Update() {
        this.setState({
            ShouldUpdate : true
        })
    }    
    onChangeSubtitle(value) {
        Globals.ProjectManager.GetDocumentManager().SetItemValue(this.props.id, Utils.DeepClone(value), 'content');
        this.setState({
            value : value,
            ShouldUpdate : true
        })             
    }
    onReloadSubtitle(value) {
        this.EditorId = Utils.Id();
        this.onChangeSubtitle(value);
    }
    render() {        

        let content;

        const alignment = Globals.ProjectManager.GetDocumentManager().GetPageItemValue(this.props.id, this.props.pageId, 'center', 'alignment');
        const url = Globals.ProjectManager.GetDocumentManager().GetPageItemValue(this.props.id, this.props.pageId, '', 'url');
        const size = (alignment !== 'fullwidth' && alignment !== 'stretch') ? Globals.ProjectManager.GetDocumentManager().GetPageItemValue(this.props.id, this.props.pageId, 100, 'size') : null;
        const subtitle = Globals.ProjectManager.GetDocumentManager().GetPageItemValue(this.props.id, this.props.pageId, [
            {
                type: 'subtitle',
                children: [{ text: '' }]
            }
        ], 'content');
        const isEmpty = !Utils.IsNotNullOrEmpty(url);
        if (isEmpty && this.props.preview)
            return null;
            
        return (
            <SC.FCol>
                <motion.div
                    style={{
                        ...SC.Styles.Flex.Column
                    }}
                    layoutTransition={{
                        duration : 0.2
                    }}
                >
                    {
                        isEmpty && !this.props.preview ? 
                        <SC.Div_Flex_Cell fw fh 
                            style={{
                                backgroundColor : this.props.textStyles.backgroundHighlight,
                                minWidth : '44px',
                                height : '200px'
                            }}
                        >
                            <ToolbarIcons.Image style={{width : '48px'}}/>
                        </SC.Div_Flex_Cell>  
                        :
                        <img 
                            src={url}
                            style={{
                                alignSelf : alignment,
                                maxWidth : size ? Utils.px(size, '%') : '100%',
                                width : 'auto'
                            }}
                        />
                    }                    
                    <SC.Div_Relative
                        style={{
                            alignSelf : alignment,
                            width : size ? Utils.px(size, '%') : '100%',
                            marginTop : '8px',
                        }}
                    >
                        <SlateEditor 
                            key={this.EditorId}
                            placeholder={this.props.preview ? '' : 'Subtitle'}
                            onChange={this.onChangeSubtitle}
                            onReloadValue={this.onReloadSubtitle}
                            textStyles={this.props.textStyles}
                            StyleId={this.props.StyleId}
                            value={subtitle}
                            fixedType={'subtitle'}
                            selected={this.props.selected}
                            themeId={this.props.themeId}
                            preview={this.props.preview}
                        />
                    </SC.Div_Relative>                    
                </motion.div>
            </SC.FCol>            
        )
    }
}