import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    AppLayout,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../../../importer';

import {GroupTitle, EditableName} from '../../../panels/left/designsystem/common';
import styled, { css} from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { OutsideCloseDotMenuContainer, DotMenu } from '../../../panels/left/common';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import { CopyToClipboardBox } from '../../../tokens/export/tokengroup';

export default class DocumentPages extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.onDragEndToken = this.onDragEndToken.bind(this);
        this.AddFolder = this.AddFolder.bind(this);        
        this.AddPage = this.AddPage.bind(this);        
    }
    AddFolder() {
        const newFolder = Globals.ProjectManager.GetDocumentManager().AddFolder();
        this.NewFolderId = newFolder.id;
        this.props.onSelectPage(newFolder.pageId);
    }
    DeleteFolder(id) {
        const newPageId = Globals.ProjectManager.GetDocumentManager().DeleteFolder(id);
        this.props.onSelectPage(newPageId);
    }
    AddPage() {
        const newPageId = Globals.ProjectManager.GetDocumentManager().AddPage();
        this.props.onSelectPage(newPageId);
    }
    componentDidUpdate(prevProps, prevState) {
        delete this.NewFolderId;
    }
    onDragEndToken(result) {
        const { source, destination } = result;
        if (!destination) {
            return;
        }
        if (result.type === 'FOLDERS') {
            Globals.ProjectManager.GetDocumentManager().ChangeFolderOrder(source.index, destination.index);
        }
        else {
            if (source.droppableId === destination.droppableId) {
                Globals.ProjectManager.GetDocumentManager().ChangePageOrder(destination.droppableId, destination.droppableId, source.index, destination.index);                
            } 
            else {
                Globals.ProjectManager.GetDocumentManager().ChangePageOrder(source.droppableId, destination.droppableId, source.index, destination.index);
            }     
        }        
        this.RCUpdate();
    }
    renderCustom() {
        let content;

        if (this.props.loaded) {
            if (!this.props.showItemSettings) {                 
                const folders = Globals.ProjectManager.GetDocumentManager().GetFolders();
                const style_folder = {
                    ...this.props.panelStyle,
                    height : this.props.panelStyle.lineHeight,
                    backgroundColor : 'unset'
                }
                content = (
                    <SC.FCol style={{padding : '8px', paddingTop : '24px', alignSelf : 'stretch', userSelect : 'none'}}>
                        <DragDropContext
                            onDragEnd={this.onDragEndToken}
                        >                        
                            <Droppable 
                                droppableId='Folders'
                                type='FOLDERS'
                            >
                                {
                                    provided => (
                                        <div
                                            {...provided.droppableProps} 
                                            ref={provided.innerRef} 
                                            style={{
                                                ...provided.droppableProps.style,
                                                ...SC.Styles.Flex.Column
                                            }}
                                        >
                                            {
                                                folders.map((folder, i) => {
                                                    return (
                                                        <DocumentFolder 
                                                            index={i}
                                                            key={folder.id}
                                                            folder={folder}
                                                            isNew={folder.id === this.NewFolderId}
                                                            selectedPageId={this.props.selectedPageId}
                                                            onSelectPage={this.props.onSelectPage}
                                                            onDeleteFolder={this.DeleteFolder.bind(this, folder.id)}
                                                            preview={this.props.preview}
                                                            style={style_folder}
                                                            color={this.props.panelStyle.color}
                                                            backgroundHighlight={this.props.backgroundHighlight}
                                                        />
                                                    )
                                                })
                                            }
                                            {provided.placeholder}
                                        </div>
                                    )
                                }    
                            </Droppable>                    
                        </DragDropContext>                        
                        {
                            !this.props.preview && 
                            <React.Fragment>
                                <SC.LinkText style={{...SC.Styles.Font(), backgroundColor : SC.CurrentTheme.theme.back_lighter}}>
                                    <AddItemLink onClick={this.AddPage}>
                                        <SC.Icons.Icon_Button hasFill >
                                            <SC.Icons.Icon_Plus size={20} />
                                        </SC.Icons.Icon_Button>
                                        Add Root Level Page
                                    </AddItemLink>
                                </SC.LinkText>
                                <SC.LinkText style={{...SC.Styles.Font(), backgroundColor : SC.CurrentTheme.theme.back_lighter}}>    
                                    <AddItemLink onClick={this.AddFolder}>
                                        <SC.Icons.Icon_Button hasFill >
                                            <SC.Icons.Icon_Plus size={20} />
                                        </SC.Icons.Icon_Button>
                                        Add Category
                                    </AddItemLink>
                                </SC.LinkText>
                            </React.Fragment>                            
                        }
                    </SC.FCol>
                )
            }
        }

        return (
            <React.Fragment>
                {content}                
            </React.Fragment>
        )
    }
}

class DocumentFolder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  
            collapsed : this.props.folder.collapsed
        }

        this.ShowMenu = this.ShowMenu.bind(this);
        this.AddPage = this.AddPage.bind(this);        
        this.SaveName = this.SaveName.bind(this);        
        this.DeleteFolder = this.DeleteFolder.bind(this);        
        this.EditName = this.EditName.bind(this);        
        this.ToggleExpanded = this.ToggleExpanded.bind(this);        
    }
    EditName(e) {
        e.stopPropagation();
        this.setState({willEdit : true, isMenuOpen : false}, () => {
            this.state.willEdit = false;
        });
    }
    SaveName(name) {
        this.props.folder.title = name;
        Globals.ProjectManager.GetDocumentManager().SaveFolderName(this.props.folder.id, name);        
        this.Update();
    }
    AddPage() {
        this.NewPageId = Globals.ProjectManager.GetDocumentManager().AddPage(this.props.folder.id);
        this.SelectPage(this.NewPageId);
    }    
    SelectPage(id) {
        this.props.onSelectPage(id);
    }
    SavePageName(pageId, name) {
        Globals.ProjectManager.GetDocumentManager().SavePageName(this.props.folder.page ? null : this.props.folder.id, pageId, name);          
    }
    SavePageVisibility(pageId, hidden) {
        Globals.ProjectManager.GetDocumentManager().SavePageProp(this.props.folder.page ? null : this.props.folder.id, pageId, 'hidden', hidden);          
    }
    DeletePage(page) {        
        const willSelectPageId = Globals.ProjectManager.GetDocumentManager().DeletePage(this.props.folder.page ? null : this.props.folder.id, page.id);
        this.SelectPage(willSelectPageId);
        delete this.NewPageId;
    }
    DeleteFolder() {
        this.setState({isMenuOpen : !this.state.isMenuOpen});
        this.props.onDeleteFolder();
    }
    ClonePage(page) {
        const willSelectPageId = Globals.ProjectManager.GetDocumentManager().ClonePage(this.props.folder.page ? null : this.props.folder.id, page.id);
        this.SelectPage(willSelectPageId);
        delete this.NewPageId;
    }
    componentDidUpdate(prevProps, prevState) {
        delete this.NewPageId;
        this.state.ShouldUpdate = false;
    }
    Update() {
        this.setState({ShouldUpdate : true})
    }
    ShowMenu(e) {
        e && e.stopPropagation();
        this.setState({isMenuOpen : !this.state.isMenuOpen});
    }
    ToggleExpanded() {
        if (!this.props.preview)
            Globals.ProjectManager.GetDocumentManager().SaveFolderProp(this.props.folder.id, 'collapsed', !this.state.collapsed);        
        this.setState({collapsed : !this.state.collapsed})
    }
    render() { 
        if (this.props.folder.page) {
            const page = this.props.folder;
            if (this.props.preview && page.hidden) {
                return null;
            }
            return (
                <DocumentPage
                    index={this.props.index}
                    key={page.id}
                    page={page}
                    rootPage
                    isNew={this.NewPageId === page.id}
                    selected={this.props.selectedPageId === page.id}
                    onSelect={this.SelectPage.bind(this, page.id)}
                    onSaveName={this.SaveName.bind(this)}
                    onSavePageVisibility={this.SavePageVisibility.bind(this, page.id)}
                    onDeletePage={this.DeletePage.bind(this, page)}
                    onClonePage={this.ClonePage.bind(this, page)}
                    preview={this.props.preview}
                    style={this.props.style}
                    backgroundHighlight={this.props.backgroundHighlight}
                />
            )
        }
        const pages = [];
        this.props.folder.pages && this.props.folder.pages.map((page, i) => {
            if (page) {
                if (this.props.preview && page.hidden) {

                }
                else {
                    pages.push(
                        <DocumentPage
                            index={i}
                            key={page.id}
                            page={page}
                            isNew={this.NewPageId === page.id}
                            selected={this.props.selectedPageId === page.id}
                            onSelect={this.SelectPage.bind(this, page.id)}
                            onSaveName={this.SavePageName.bind(this, page.id)}
                            onSavePageVisibility={this.SavePageVisibility.bind(this, page.id)}
                            onDeletePage={this.DeletePage.bind(this, page)}
                            onClonePage={this.ClonePage.bind(this, page)}
                            preview={this.props.preview}
                            style={this.props.style}
                            backgroundHighlight={this.props.backgroundHighlight}
                        />
                    )
                }
                                    
            }                        
        });
        return (  
            <Draggable key={this.props.folder.id}  draggableId={this.props.folder.id} index={this.props.index}>                
                {
                    (provided, snapshot) => (
                        <div {...provided.draggableProps} style={{...provided.draggableProps.style, ...SC.Styles.Flex.Column}} ref={provided.innerRef}>
                            <SC.HoverParent 
                                defaultStyle={{marginBottom : '-2px', marginTop : '8px', cursor : 'pointer', ...SC.Styles.Flex.RowAlcJsb, borderLeft : '1px solid transparent'}}                                 
                                onClick={this.ToggleExpanded}
                            >
                                <SC.HoverChild defaultStyle={{ width : '24px', ...SC.Styles.Flex.Cell, alignSelf : 'stretch'}}>
                                    {
                                        !this.props.preview && 
                                        <SC.DragBox {...provided.dragHandleProps} first style={{...provided.dragHandleProps.style, alignSelf : 'stretch', height : 'unset', marginLeft : 0, border : 'none'}} onMouseDown={(e) => e.stopPropagation()}>
                                            <SC.Icons.DragIcon xsmall/>
                                        </SC.DragBox>
                                    }
                                </SC.HoverChild>
                                <SC.Icons.Icon_Button hasFill style={{marginLeft : '-8px'}}>
                                    <SC.Icons.Icon_Arrow_Filled expanded={!this.state.collapsed} size={20} />
                                </SC.Icons.Icon_Button>
                                <EditableName 
                                    value={this.props.folder.title} 
                                    onSave={this.SaveName} 
                                    willEdit={this.props.isNew || this.state.willEdit}
                                    notDeletable
                                    style={{...this.props.style, ...SC.Styles.Flex.RowAlcJsb, fontWeight : 'bold', opacity : 0.6}}
                                />
                                {
                                    !this.props.preview && 
                                    <SC.HoverChild style={{...SC.Styles.Font()}}>
                                        <DotMenu onClick={this.ShowMenu} isOpen={this.state.isMenuOpen} onClose={this.ShowMenu}>
                                            {
                                                this.state.isMenuOpen &&
                                                <SC.FCol style={{minWidth : '160px', lineHeight : '18px'}}>
                                                    <SC.PopupItem onClick={this.EditName}>
                                                        Rename Folder
                                                    </SC.PopupItem>                                                    
                                                    <SC.PopupItem onClick={this.DeleteFolder} style={{...SC.Styles.Flex.RowAlcJsb}}>
                                                        <div>Delete</div>
                                                        <SC.Icons.Icon_Button hasFill hasCursor style={{marginLeft : '4px', marginRight : '4px'}}>
                                                            <SC.Icons.Icon_Delete size={16} />
                                                        </SC.Icons.Icon_Button>
                                                    </SC.PopupItem>
                                                </SC.FCol>
                                            }
                                        </DotMenu>       
                                    </SC.HoverChild>                                  
                                }
                            </SC.HoverParent>
                            {
                                !this.state.collapsed && 
                                <Droppable 
                                    droppableId={this.props.folder.id}
                                    type={'PAGES'}
                                >
                                    {
                                        (provided, snapshot) => (
                                            <div
                                                {...provided.droppableProps} 
                                                ref={provided.innerRef} 
                                                style={{
                                                    ...provided.droppableProps.style,
                                                    paddingTop : pages.length === 0 ? '16px' : 0
                                                }}
                                            >
                                                {pages}  
                                                {provided.placeholder}
                                            </div>
                                        )
                                    }
                                </Droppable>  
                            }
                            
                            {
                                !this.state.collapsed && !this.props.preview && 
                                <SC.LinkText style={{...SC.Styles.Font(), backgroundColor : SC.CurrentTheme.theme.back_lighter, marginBottom : '8px'}}>
                                    <AddItemLink onClick={this.AddPage} style={{marginTop : 0, marginLeft : '32px', ...SC.Styles.Font()}}>
                                        <SC.Icons.Icon_Button hasFill style={{marginRight : '8px'}}>
                                            <SC.Icons.Icon_Plus size={20} />
                                        </SC.Icons.Icon_Button>
                                        Add Page
                                    </AddItemLink>                          
                                </SC.LinkText>
                            }                            
                        </div>
                    )
                }
            </Draggable>          
        );
    }
}
 
class DocumentPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }

        this.SaveName = this.SaveName.bind(this);
        this.DeletePage = this.DeletePage.bind(this);        
        this.ClonePage = this.ClonePage.bind(this);        
        this.ShowMenu = this.ShowMenu.bind(this);
        this.CopyLinkToPage = this.CopyLinkToPage.bind(this);        
        this.EditName = this.EditName.bind(this);        
        this.ToggleVisibility = this.ToggleVisibility.bind(this);        
        this.onMouseLeave = this.onMouseLeave.bind(this);        
    }
    componentDidMount() {
        this.Mounted = true;
    }
    componentWillUnmount() {
        this.Mounted = false;
    }
    ToggleVisibility() {
        this.props.page.hidden = !this.props.page.hidden;
        this.props.onSavePageVisibility(this.props.page.hidden);
        this.Update();
    }
    SaveName(name) {
        this.props.page.title = name;
        this.props.onSaveName(name);
        this.Update();
    }
    EditName(e) {
        e.stopPropagation();
        this.setState({willEdit : true, isMenuOpen : false}, () => {
            this.state.willEdit = false;
        });
    }
    Update() {
        this.setState({ShouldUpdate : true})
    }
    DeletePage(e) {
        e.stopPropagation();
        this.props.onDeletePage();
    }
    ClonePage(e) {
        e.stopPropagation();
        this.props.onClonePage();
    }
    ShowMenu(e) {        
        e && e.stopPropagation();
        if (!this.Mounted)
            return;
        const DN = ReactDOM.findDOMNode(this);
        DN.addEventListener('mouseleave', this.onMouseLeave);
        this.setState({isMenuOpen : !this.state.isMenuOpen});
    }
    onMouseLeave(e) {
        if (!this.Mounted)
            return;
        const DN = ReactDOM.findDOMNode(this);
        DN.removeEventListener('mouseleave', this.onMouseLeave);
        if (this.state.isMenuOpen)
            this.setState({isMenuOpen : false});
    }
    CopyLinkToPage(e) {
        e.stopPropagation();
        this.setState({isMenuOpen : false});
    }
    render() { 
        const style = {
            ...SC.Styles.Flex.RowAlcJsb,
            marginTop : this.props.index === 0 ? '4px' : (this.props.rootPage ? '8px' : 0), paddingLeft : this.props.rootPage ? 0 : '24px',
            // borderLeft : this.props.selected ? SC.CurrentTheme.theme.border_brand : '1px solid transparent',
            borderRadius : '4px',
            paddingRight : '4px'
        };
        if (this.props.selected)
            style.backgroundColor = this.props.backgroundHighlight;
        else {
            if (this.props.page.hidden)
                style.opacity = 0.5;
        }
        
        return (  
            <Draggable key={this.props.page.id}  draggableId={this.props.page.id} index={this.props.index}>
                {
                    (provided, snapshot) => {
                        return (
                            <div {...provided.draggableProps} style={{...provided.draggableProps.style}} ref={provided.innerRef}>
                                <SC.HoverParent 
                                    defaultStyle={style}
                                    hoverStyle={{
                                        backgroundColor : this.props.backgroundHighlight
                                    }}
                                    onClick={this.props.onSelect}
                                >                                       
                                    <SC.Icons.Icon_ButtonBox  hasFill style={{flex : 1, height : '28px', ...this.props.style, cursor : 'pointer', }}>
                                        <SC.HoverChild defaultStyle={{ width : '24px', ...SC.Styles.Flex.Cell, alignSelf : 'stretch'}} >
                                            {
                                                !this.props.preview && 
                                                <SC.DragBox {...provided.dragHandleProps} first style={{...provided.dragHandleProps.style, alignSelf : 'stretch', height : 'unset', marginLeft : '4px', border : 'none'}} onMouseDown={(e) => e.stopPropagation()}>
                                                    <SC.Icons.DragIcon xsmall style={{pointer : 'unset'}}/>
                                                </SC.DragBox>
                                            }
                                        </SC.HoverChild>
                                        {
                                            this.props.preview ? 
                                            <SC.TextDivAbbr style={{flex : 1}}>{this.props.page.title}</SC.TextDivAbbr> : 
                                            <EditableName 
                                                value={this.props.page.title} 
                                                willEdit={this.props.isNew || this.state.willEdit}
                                                onSave={this.SaveName} 
                                                notDeletable
                                                style={{...this.props.style, ...SC.Styles.Flex.RowAlcJsb, fontWeight : this.props.selected ? 'bold' : 'inherit', backgroundColor : 'unset'}}
                                            />
                                        }                                        
                                        {
                                            !this.props.preview && 
                                            <SC.HoverChild style={this.props.selected ? { opacity : 1} : {}}>
                                                <SC.FRow style={{...SC.Styles.Font()}}>
                                                    <SC.Icons.Icon_Button hasFill hasCursor title='Show/Hide Page' onClick={this.ToggleVisibility}>
                                                        <SC.Icons.Icon_Visibility size={16} visible={!this.props.page.hidden} />
                                                    </SC.Icons.Icon_Button>                                                    
                                                    <DotMenu onClick={this.ShowMenu} isOpen={this.state.isMenuOpen} onClose={this.ShowMenu}>
                                                        {
                                                            this.state.isMenuOpen &&
                                                            <SC.FCol style={{minWidth : '160px', lineHeight : '18px'}}>
                                                                <SC.PopupItem onClick={this.EditName}>
                                                                    Rename Page
                                                                </SC.PopupItem>
                                                                <SC.PopupItem onClick={this.CopyLinkToPage}>
                                                                    <CopyToClipboardBox text={`/#/${Globals.ProjectManager.Id}/${this.props.page.id}`}>
                                                                        Copy Internal Link to Page
                                                                    </CopyToClipboardBox>                                                            
                                                                </SC.PopupItem>
                                                                <SC.PopupItem onClick={this.CopyLinkToPage}>
                                                                    <CopyToClipboardBox text={`https://dsm.toolabs.com/#/${Globals.ProjectManager.Id}/${this.props.page.id}`}>
                                                                        Copy Shareable Link to Page
                                                                    </CopyToClipboardBox>                                                            
                                                                </SC.PopupItem>
                                                                <SC.PopupItem onClick={this.ClonePage} style={{...SC.Styles.Flex.RowAlcJsb}}>
                                                                    <div>Clone</div>
                                                                    <SC.Icons.Icon_Button hasFill hasCursor style={{marginLeft : '4px', marginRight : '4px'}} >
                                                                        <SC.Icons.Icon_Clone size={16} />
                                                                    </SC.Icons.Icon_Button>
                                                                </SC.PopupItem>
                                                                <SC.PopupItem onClick={this.DeletePage} style={{...SC.Styles.Flex.RowAlcJsb}}>
                                                                    <div>Delete</div>
                                                                    <SC.Icons.Icon_Button hasFill hasCursor style={{marginLeft : '4px', marginRight : '4px'}}>
                                                                        <SC.Icons.Icon_Delete size={16} />
                                                                    </SC.Icons.Icon_Button>
                                                                </SC.PopupItem>
                                                            </SC.FCol>
                                                        }
                                                    </DotMenu>    
                                                </SC.FRow>                                                   
                                            </SC.HoverChild>                                  
                                        }
                                    </SC.Icons.Icon_ButtonBox>                                                                                                                  
                                </SC.HoverParent> 
                            </div>                                                       
                        )                                          
                    }
                }
            </Draggable>   
            
        );
    }
}

const AddItemLink = styled.div`
    opacity : 0.7;
    cursor : pointer;
    display : flex;
    align-items : center;
    &: hover{
        opacity : 1;
    }
`;