import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    AppLayout,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../../../../importer';

import DropDownSelect from '../../../../../../components/editors/enum_dropdown';
import SystemStatePanel from '../../../../panels/left/systemstates';
import { PropertyLabel } from '../../../../panels/right/iteminspector/styleitems/common';
import Switch from '../../../../../../components/editors/Switch';
import { TokenItemBox } from '../../../../panels/left/designsystem/common';

export default class DocumentItemThemeOption extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.UpdateItem = this.UpdateItem.bind(this);        
        this.SetSpecificState = this.SetSpecificState.bind(this);        
    }
    UpdateItem() {
        this.props.onUpdateItem(this.props.showItemSettings.ref);
    }    
    SetItemProp(prop, value) {
        Globals.ProjectManager.GetDocumentManager().SetItemValue(this.props.id, value, prop);        
        this.UpdateItem();
        this.RCUpdate();
    }    
    SetSpecificState(stateId, variationId) {
        Globals.ProjectManager.GetDocumentManager().SetItemValue(this.props.id, variationId, 'states', stateId);        
        this.UpdateItem();
        this.RCUpdate();
    }
    ToggleCustomState(stateid, filteredStates, checkted) {
        if (checkted) {
            Utils.RemoveEquals(filteredStates, stateid);
        }
        else
            filteredStates.push(stateid);

        Globals.ProjectManager.GetDocumentManager().SetItemValue(this.props.id, filteredStates, 'filteredStates');        
        this.UpdateItem();
        this.RCUpdate();
    }
    ToggleFilterStates(checked) {
        Globals.ProjectManager.GetDocumentManager().SetItemValue(this.props.id, checked, 'filterStates');        
        this.UpdateItem();
        this.RCUpdate();
    }
    renderCustom() {
        const data = Globals.ProjectManager.GetDocumentManager().GetItem(this.props.id);
        const themeOption = Utils.JustGet(data.data, 'active', 'theme');

        let themeContent;

        if (themeOption === 'specific') {
            const states = Utils.JustGet(data.data, {}, 'states');
            themeContent = (
                <SystemStatePanel 
                    contentStyle={{margin : 0, marginTop : '8px'}}
                    justContent
                    onSetStateVariation={this.SetSpecificState}
                    currentVariations={states}
                />
            )
        }
        else if (themeOption === 'custom') {
            const states = Globals.ProjectManager.States.Get().Order || [];
            const filteredStates = Utils.JustGet(data.data, [], 'filteredStates');
            const onlySelectedStates = Utils.JustGet(data.data, false, 'filterStates');
            themeContent = (
                <SC.FCol style={{marginTop : '8px'}}>
                    <SC.FRow alc jsb>
                        <PropertyLabel>Show Only Selected States</PropertyLabel>
                        <Switch round onChange={this.ToggleFilterStates.bind(this)} value={onlySelectedStates} />
                    </SC.FRow>    
                    {
                        onlySelectedStates && 
                        <div style={{
                            display : 'grid',
                            gridTemplateColumns : 'auto 1fr',
                            alignItems : 'center',
                            gridGap : '8px',
                            padding : '8px'
                        }}>
                            {
                                states.map((stateid) => {
                                    // if (stateid !== 'System_MediaSize') 
                                    {
                                        const stateDef = Globals.ProjectManager.States.GetState(stateid);
                                        const isSelected = filteredStates.indexOf(stateid) > -1; 
                                        return (
                                            <React.Fragment>
                                                <Switch key={stateid} checkEdit value={!isSelected} onChange={this.ToggleCustomState.bind(this, stateid, filteredStates)} />
                                                <SC.FRow alc cursorPointer onClick={this.ToggleCustomState.bind(this, stateid, filteredStates, isSelected)}>
                                                    {stateDef.name}
                                                </SC.FRow>
                                            </React.Fragment>          
                                        )
                                    }                                
                                })
                            }
                        </div>
                    }                    
                </SC.FCol>
            );
        }

        return (
            <React.Fragment>                    
                <SC.FRow alc jsb>
                    <SC.GroupLabel style={{flex : 1}}>THEME</SC.GroupLabel>
                    <DropDownSelect 
                        style={{flex : 1, fontSize : '11px'}}
                        items={[
                            {id : 'active', label : 'Active Theme'},
                            {id : 'specific', label : 'Specific Theme'},
                            {id : 'custom', label : 'Theme Selector'}
                        ]}
                        value={themeOption}
                        hasBorder            
                        autoHeight
                        xsmall
                        toRight
                        onChange={this.SetItemProp.bind(this, 'theme')}
                    />
                </SC.FRow>
                {
                    themeContent
                }                  
            </React.Fragment>
        )
    }
}