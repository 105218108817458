import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    AppLayout,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../../../../importer';

import styled, { css} from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { DocumentToolbarButton } from '../text/toolbar';
import Chroma from 'chroma-js';
import { GetContrastLevel } from '../../../../panels/left/designsystem/colors/color/textcolor';

export default class DocumentItem_Accessibility extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {

        };    
        
        this.Load(this.props);
    }    
    Load(props) {
        this.backgroundColor = this.props.textStyles.backgroundHighlight;
        this.textColor = this.props.textStyles.color;

        if (props.backgroundColorId) {
            const token = Globals.ProjectManager.Tokens.Token(props.backgroundColorId);
            if (token) {
                this.backgroundColorName = token.name;
                this.backgroundColor = Globals.ProjectManager.Tokens.ValueOf({model : token});
            }            
        }
        if (props.textColorId) {
            const token = Globals.ProjectManager.Tokens.Token(props.textColorId);
            if (token) {
                this.textColorName = token.name;
                this.textColor = Globals.ProjectManager.Tokens.ValueOf({model : token});
            }            
        }

        if (Chroma.valid(this.backgroundColor)  && Chroma.valid(this.textColor)) {
            this.backgroundColor = Chroma(this.backgroundColor).hex();
            this.textColor = Chroma(this.textColor).hex();
            this.contrast = Chroma.contrast(this.backgroundColor, this.textColor).toFixed(2);
            this.contrastLevel = GetContrastLevel(this.contrast);
        } 
    }
    componentDidUpdate(prevProps, prevState) {
        this.state.ShouldUpdate = false;
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.backgroundColorId !== this.props.backgroundColorId || nextProps.textColorId !== this.props.textColorId || this.props.GlobalThemeId !== nextProps.GlobalThemeId || this.props.GlobalState !== nextProps.GlobalState) {
            this.Load(nextProps);
        }
        return true;
    }
    Update() {
        this.setState({
            ShouldUpdate : true
        })
    }
    render() {              
        const style_fail = {};
        if (this.contrastLevel === 'FAIL') {
            style_fail.color = this.backgroundColor;
            style_fail.filter = 'invert(0.8)';
        }
        return (
            <SC.FRow style={{
                padding : '16px',
                borderRadius : '4px',
                backgroundColor : this.backgroundColor,
                color : this.textColor,
                overflow : 'hidden',
                alignSelf : this.props.alignment,
                boxSizing : 'border-box',
                width : this.props.size ? Utils.px(this.props.size || 100, '%') : 'auto'
            }}>
                <SC.FCol ja 
                    style={{
                        fontSize : '14px',  padding : '8px', borderRadius : '2px', backgroundColor : this.textColor, color : this.backgroundColor,
                        cursor : this.props.preview ? 'unset' : 'pointer'
                    }}
                    title='Click to select background color'
                    onClick={this.props.preview ? null : this.props.onSelectBackColor}
                >
                    <SC.TextDivAbbr>{this.backgroundColorName}</SC.TextDivAbbr>
                    <div style={{fontSize : '18px', fontFamily : 'monospace'}}>{this.backgroundColor}</div>
                </SC.FCol>
                <SC.FCol alc f1 style={{fontSize : '28px', fontWeight : 'bold', lineHeight : '36px', textAlign : 'center'}}>
                    <div>{this.contrast} : 1</div>
                    <div style={style_fail}>{this.contrastLevel}</div>
                </SC.FCol>
                <SC.FCol 
                    ja 
                    style={{
                        fontSize : '14px',  padding : '8px', borderRadius : '2px', alignItems : 'flex-end', backgroundColor : this.backgroundColor, color : this.textColor,
                        cursor : this.props.preview ? 'unset' : 'pointer'
                    }}
                    title='Click to select text color'
                    onClick={this.props.preview ? null : this.props.onSelectTextColor}
                >
                    <SC.TextDivAbbr>{this.textColorName}</SC.TextDivAbbr>
                    <div style={{fontSize : '18px', fontFamily : 'monospace'}}>{this.textColor}</div>
                </SC.FCol>
            </SC.FRow>
        )        
    }
}
