import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    AppLayout,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../../../importer';

import DocumentPages from '../pages';
import { AnimatePresence, motion } from 'framer-motion';
import { DropAreaContainer } from '../../../panels/left/designsystem/common';
import Dropzone from 'react-dropzone';
import { DocumentToolbarButton, DocumentToolbarSeperator } from '../item/text/toolbar';
import Slider from '../../../../../components/editors/slider';
import TextInput from '../../../../../components/editors/textinput';
import AddCancelBar from '../../../../../components/AddCancelBar';
import { TextAlignLines } from "../../../panels/right/iteminspector/styleitems/text";
import Switch from '../../../../../components/editors/Switch';
import SystemStatePanel from '../../../panels/left/systemstates';

export default class DocumentLeftPanel extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.UploadImageFile = this.UploadImageFile.bind(this);
    }
    UploadImageFile(files) {
        if (files && files.length > 0) {
            const file = files[0];
            const newId = Utils.Id();
            Globals.ProjectManager.GetDocumentManager().SetLogo({
                url : URL.createObjectURL(file),
                url : '',
                storageId : newId,
                source : 'file'
            }, true);

            this.RCUpdate();

            const onUploadComplete = this.UploadCompleted.bind(this, newId);
            Globals.ProjectManager.DataManager.Storage.Save(newId, file, {}, (progress) => {
            }).then(onUploadComplete);
        }       
    }  
    UploadCompleted(newId, result) {
        if (result) {
            result.ref.getDownloadURL().then((downloadURL) => {
                Globals.ProjectManager.GetDocumentManager().SetLogo({
                    url : downloadURL,
                    storageId : newId,
                    source : 'file'
                });
                this.RCUpdate();
            });
        }
    }
    SaveLogoLink(link) {
        Globals.ProjectManager.GetDocumentManager().SetLogoProp('link', link);
    }
    SaveImageUrl() {
        Globals.ProjectManager.GetDocumentManager().SetItemValue(this.props.id, this.state.url, 'url');
        Globals.ProjectManager.GetDocumentManager().SetItemValue(this.props.id, 'url', 'source');
        const storageId = Utils.JustGet(this.data.data, null, 'storageId');
        if (storageId) {
            Globals.ProjectManager.DeleteStorageImage(storageId);
            Globals.ProjectManager.GetDocumentManager().DeleteItemValue(this.props.id, 'storageId');
        }
        this.RenderId = Utils.Id();
        this.state.showImageUrl = false;
        this.state.url = null;
        this.Update();
    }
    SetLogoProp(prop, value) {
        Globals.ProjectManager.GetDocumentManager().SetLogoProp(prop, value);
        this.RCUpdate();
    }
    RemoveLogo() {
        Globals.ProjectManager.GetDocumentManager().DeleteLogo();
        this.RCUpdate();
    }
    renderCustom() {
        let content, editor;
        
        const LogoData = Globals.ProjectManager.GetDocumentManager().GetLogoData();
        const LogoUrl = Utils.UseNullOrEmpty(LogoData.source === 'url' ? LogoData.link : LogoData.url, null);
        const showThemeWidget = Globals.ProjectManager.GetDocumentManager().GetOption('themeSwitcher');
        const logoAlignment = Utils.JustGet(LogoData, 'stretch', 'align');

        const style_image = {
            maxWidth : '100%',
            opacity : 1,
            width : logoAlignment === 'stretch' ? '100%' : Utils.px(Utils.JustGet(LogoData, 80, 'width'), '%'),
            alignSelf : logoAlignment                        
        };
        
        if (this.props.preview) {
            if (this.props.shared && this.props.mobile) {
                return (
                    <React.Fragment>                    
                        {
                            !this.state.expanded && 
                            <div style={{
                                position : 'absolute',
                                top : 0,
                                left : 0, 
                                width : '60px',
                                height : '48px',
                                ...SC.Styles.Flex.Cell,                    
                                zIndex : 999999,
                                pointerEvents : 'all',
                                cursor : 'pointer',
                            }} onClick={() => this.setState({expanded : !this.state.expanded})}>
                                <SC.Icons.Icon_Button hasFill hasCursor >
                                    <SC.Icons.Icon_Menu expanded />
                                </SC.Icons.Icon_Button>                    
                            </div>
                        }
                        <AnimatePresence>
                            {
                                this.state.expanded && 
                                <motion.div 
                                    style={{
                                        display : 'flex', 
                                        flexDirection : 'column',
                                        position : 'absolute',
                                        top : 0,
                                        left : 0, 
                                        height : '100vh',
                                        width : '240px',
                                        backgroundColor : SC.CurrentTheme.theme.back,
                                        pointerEvents : 'all',
                                        zIndex : 999999,
                                        alignItems : 'stretch',
                                        boxShadow : SC.CurrentTheme.theme.popup_shadow,
                                        ...this.props.panelStyle
                                    }}
                                    initial={{opacity : 0.7, x : -40}}
                                    animate={{opacity : 1, x : 0}}
                                    exit={{opacity : 0, x : -40}}
                                    transition={{duration : 0.2}}
                                >
                                    <SC.Icons.Icon_Button hasFill hasCursor onClick={() => this.setState({expanded : false})} style={{
                                        width : '60px',
                                        height : '48px',
                                    }}>
                                        <SC.Icons.Icon_Menu />
                                    </SC.Icons.Icon_Button>
                                    <MobileNav 
                                        LogoUrl={LogoUrl}
                                        style_image={style_image}
                                        showThemeWidget={showThemeWidget}
                                        {...this.props}
                                        onClose={() => this.setState({expanded : false})}
                                        onSelectPage={(pageId) => {
                                            this.props.onSelectPage(pageId);
                                            this.setState({expanded : false});
                                        }}
                                    />
                                </motion.div>
                            }
                        </AnimatePresence>
                    </React.Fragment>
                )
                
            }
            else {
                editor = (
                    <PanelContent 
                        LogoUrl={LogoUrl}
                        style_image={style_image}
                        showThemeWidget={showThemeWidget}
                        {...this.props}
                    />
                )
                
            }
        }
        else {            
            content = (
                <DocumentPages 
                    {...this.props}
                />
            )
            let logoToolbar;

            if (!this.props.preview && this.state.showLogoBar ) {                

                logoToolbar = (
                    <motion.div 
                        style={{
                            position : 'absolute',
                            top : '100%',
                            left : '24px',
                            zIndex : 9999999,
                        }}
                        initial={{x : -20}}
                        animate={{x : 0}}
                        exit={{x : -20}}
                        transition={{duration : 0.2}}
                    >
                        <LogoToolbar 
                            themeId={this.props.themeId}
                            onSaveLink={this.SaveLogoLink.bind(this)}
                            onSetProp={this.SetLogoProp.bind(this)}
                            onRemoveLogo={this.RemoveLogo.bind(this)}
                            onUpload={this.UploadImageFile}
                            link={LogoData.link}
                            width={Utils.JustGet(LogoData, 80, 'width')}
                            logoAlignment={logoAlignment}
                            source={LogoData.source}
                            LogoUrl={LogoUrl}
                        />                                           
                    </motion.div>	
                )
            }

            editor = (
                <React.Fragment>
                    <SC.FCol style={{...SC.Styles.Font(), backgroundColor : SC.CurrentTheme.theme.back_lighter}}>
                        <div
                            style={{
                                padding : '8px',
                                position : 'relative',
                                display : 'flex',
                                flexDirection : 'column',
                                boxSizing : 'border-box',
                                ...SC.Styles.Font()
                            }}
                            onMouseEnter={this.props.preview ? null : (e) => {
                                this.setState({showLogoBar : true})
                            }}
                            onMouseLeave={this.props.preview ? null : (e) => {
                                this.setState({showLogoBar : false})
                            }}
                        >
                            {
                                this.props.preview && Utils.IsNotNullOrEmpty(LogoUrl) ? 
                                <img 
                                    src={LogoUrl} 
                                    style={style_image}
                                />  :
                                <Dropzone
                                    onDrop={this.UploadImageFile}
                                    ref={(r) => this.Ref_DropZone = r}
                                    style={{}}
                                    accept="image/*"
                                    activeStyle={{}}                
                                >
                                    {({ isDragActive, getRootProps, getInputProps}) => {
                                        if (!Utils.IsNotNullOrEmpty(LogoUrl)) {       
                                            if (!this.props.preview) {
                                                return (
                                                    <DropAreaContainer 
                                                        {...getRootProps()}
                                                        active={isDragActive}
                                                        style={{
                                                            backgroundColor : 'rgba(0, 0, 0, 0.05)',
                                                            alignSelf : 'stretch',
                                                            textAlign : 'center'
                                                        }}
                                                    >
                                                        <input {...getInputProps()} />
                                                        Logo
                                                    </DropAreaContainer>
                                                )
                                            }            
                                        }
                                        else {
                                            return (
                                                <React.Fragment>
                                                    <img 
                                                        src={LogoUrl} 
                                                        {...getRootProps()}
                                                        style={{
                                                            ...style_image,
                                                            border : '1px dashed',
                                                            borderRadius  :'2px',
                                                            borderColor : isDragActive ? SC.CurrentTheme.theme.color_brand : 'transparent'
                                                        }}
                                                    />
                                                    <input {...getInputProps()} />
                                                </React.Fragment>                                    
                                            )
                                        }
                                    }}
                                </Dropzone>   

                            }                    
                            <AnimatePresence>
                                {logoToolbar}
                            </AnimatePresence>
                        </div>
                        {
                            !this.props.preview && 
                            <SC.FRow alc jsb style={{
                                ...SC.Styles.Font(),
                                paddingLeft : '16px',
                                paddingRight : '16px'
                            }}>
                                <div>Show Theme Switcher</div>
                                <Switch round value={showThemeWidget} onChange={(checked) => {
                                    Globals.ProjectManager.GetDocumentManager().SetOption(checked, 'themeSwitcher');
                                    this.RCUpdate();
                                }}/>
                            </SC.FRow>
                        }                
                        {
                            showThemeWidget && 
                            <SystemStatePanel 
                                justContent
                                themeId={this.props.themeId}
                                contentStyle={{
                                    backgroundColor : this.props.backgroundHighlight,
                                    margin : '8px',
                                    marginTop : '8px'
                                }}
                            />
                        }
                    </SC.FCol>                    
                    <SC.CustomScrollbars>
                        {content}
                    </SC.CustomScrollbars>
                </React.Fragment>
            )
        }
        return (
            <SC.FCol 
                jsb
                style={{
                    width : '280px',                     
                    boxSizing : 'border-box',
                    position : 'relative',
                    backgroundColor : SC.CurrentTheme.theme.back,
                    // borderLeft : (this.props.shared) ? 'none' : (SC.CurrentTheme.theme.isLight ? SC.CurrentTheme.theme.border_onlight_line : SC.CurrentTheme.theme.border_ondark),
                    ...this.props.panelStyle
                }}
            >                
                                   
                {editor}
            </SC.FCol>
        )
    }
}

const PanelContent = ({LogoUrl, style_image, showThemeWidget, ...props}) => {
    return (
        <SC.FCol f1>
            {
                LogoUrl && 
                <div style={{
                    padding : '8px',
                    border : '1px solid transparent'
                }}>
                    <img 
                        src={LogoUrl} 
                        style={style_image}
                    />
                </div>                
            }     
            {
                showThemeWidget && 
                <SystemStatePanel 
                    justContent
                    themeId={props.themeId}
                    contentStyle={{
                        backgroundColor : props.backgroundHighlight,
                        margin : '8px',
                        marginTop : '8px'
                    }}
                />
            }                              
            <SC.CustomScrollbars>
                <DocumentPages 
                    {...props}
                />
            </SC.CustomScrollbars>
        </SC.FCol>
    )
}

const MobileNav = UIUtils.OutSideClickHandler(PanelContent);

export class LogoToolbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    SaveLogoLink() {
        this.props.onSaveLink(this.state.link);
        this.setState({
            showImageUrl : false            
        })
    }
    SetLogoProp(prop, value) {
        this.props.onSetProp(prop, value);
    }
    UploadImageFile(files) {
        this.props.onUpload(files);
    }
    render() {         
        if (!this.Ref_ImageFile)
            this.Ref_ImageFile = React.createRef();

        return (  
            <div
                style={{
                    border : SC.CurrentTheme.theme.border_popup,
                    borderRadius : '2px',
                    boxSizing : 'border-box',
                    backgroundColor : SC.CurrentTheme.theme.back_lighter,
                    boxShadow : SC.CurrentTheme.theme.popup_shadow,  
                    padding : '4px',
                    ...SC.Styles.Font(),
                    fontSize : '11px'
                }}
            >
                {
                    this.state.showImageUrl ?
                    <SC.FRow alc 
                        style={{padding : '4px', width : '100%'}}
                        onMouseDown={(e) => {
                            e.preventDefault(); 
                            e.stopPropagation();
                        }}
                    >
                        <div>Url</div>
                        <TextInput 
                            themeId={this.props.themeId}
                            placeholder='Enter Url'
                            dark
                            autoFocus
                            value={this.props.link}
                            onChanging={(link) => this.setState({link : link})}
                            onChange={(link) => this.setState({link : link})}
                            style={{
                                height : '30px',
                                width : '400px',
                                marginLeft : '8px',
                                marginRight : '8px'
                            }}
                            onSubmit={this.SaveLogoLink.bind(this)}
                            onCancel={() => this.setState({showImageUrl : false})}
                        />
                        <AddCancelBar 
                            onCancel={() => this.setState({showImageUrl : false})}
                            onAdd={this.SaveLogoLink.bind(this)}
                        />
                    </SC.FRow> : 
                    <SC.FRow alc style={{whiteSpace : 'nowrap'}}>
                        {                                    
                            ['flex-start', 'center', 'flex-end', 'stretch'].map((alignItem, i) => {
                                return (
                                    <DocumentToolbarButton key={i} active={this.props.logoAlignment === alignItem} onMouseDown={this.SetLogoProp.bind(this, 'align', alignItem)}>
                                        <TextAlignLines boxed selected={this.props.logoAlignment === alignItem} align={alignItem}/>
                                    </DocumentToolbarButton>
                                )
                            })
                        }
                        <DocumentToolbarSeperator style={{alignSelf : 'stretch', height : 'unset', marginLeft : '4px', marginRight : '4px'}} />
                        <DocumentToolbarButton active={this.props.source === 'url'} onMouseDown={() => {
                            this.setState({showImageUrl : true, url : this.props.link});
                        }}>
                            <div style={{paddingLeft : '4px', paddingRight : '4px'}}>Url</div>
                        </DocumentToolbarButton>
                        <DocumentToolbarSeperator style={{alignSelf : 'stretch', height : 'unset', marginLeft : '4px', marginRight : '4px'}} />
                        <DocumentToolbarButton active={this.props.source === 'file'} hasFill onMouseDown={() => this.Ref_ImageFile && this.Ref_ImageFile.current.click() }>
                            <div style={{paddingLeft : '8px', marginRight : '4px'}}>Upload</div>
                            <SC.Icons.Upload />
                            <input type="file" style={{ display: 'none' }}
                                ref={this.Ref_ImageFile}
                                accept="image/*"
                                onChange={(e) => {
                                    this.UploadImageFile(e.target.files)
                                }}>
                            </input >
                        </DocumentToolbarButton>
                        {
                            Utils.IsNotNullOrEmpty(this.props.LogoUrl) && this.props.logoAlignment !== 'stretch' &&
                            <React.Fragment>
                                <DocumentToolbarSeperator style={{alignSelf : 'stretch', height : 'unset', marginLeft : '4px', marginRight : '8px'}} />
                                <div>Image Width</div>
                                <Slider 
                                    min={20}
                                    max={100}
                                    value={this.props.width} 
                                    style={{marginLeft : '8px', width : '180px', marginRight : '8px'}} 
                                    onChange={this.SetLogoProp.bind(this, 'width')}
                                />
                            </React.Fragment>
                        }                                
                        {
                            Utils.IsNotNullOrEmpty(this.props.LogoUrl) &&
                            <React.Fragment>
                                <DocumentToolbarSeperator style={{alignSelf : 'stretch', height : 'unset', marginLeft : '4px', marginRight : '8px'}} />
                                <DocumentToolbarButton style={{whiteSpace : 'nowrap'}} onClick={this.props.onRemoveLogo}>
                                    Remove Logo
                                </DocumentToolbarButton>
                            </React.Fragment>
                        }
                    </SC.FRow>
                }
                
            </div> 
        );
    }
}