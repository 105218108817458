import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppLayout,
    Links,
    Events,
    MetaData,
    Strings,
    Globals
} from '../../../../../../importer';
import { GetColorTokenList, GetGradientTokenList, ColorTokenListItem, GetColorTokenItem } from '../colors';
import { GetShadowTokenList, ShadowListItem, GetShadowTokenItem } from '../shadows';
import { GetBorderStyleTokenList, GetBorderRadiusTokenList, BorderStyleTokenItem, BorderRadiusTokenItem, GetBorderStyleTokenValue, GetBorderRadiusTokenValue } from '../borders';
import { GetTransformTokenList, TransformListItem } from '../transforms';
import { GetFilterTokenList, FilterListItem } from '../filters';
import { GetTextContentTokenList, TextContentListItem } from '../textContent';
import { GetIconTokenList, IconListItem } from '../icons';
import { GetFontItemList, FontSelectItem } from '../typography/fonts';
import { GetTextPatternList, TextPattern } from '../typography/patterns';
import { GetDurationPatternList, DurationPattern } from '../motion/patterns';
import { GetEaseCurveTokenList, EasePattern } from '../motion/easing';
import { GetTransitionTokenList, TransitionListItem } from '../motion/transitions';
import { IconBox } from '../icons/item';
import { TokenItemBox, TokenPanelHeader } from '../common';
import Switch from '../../../../../../components/editors/Switch';
import { SearchInput } from '../../../../searchbar';
import { ColorTokenAliaseListItem, ShadowTokenAliaseListItem, BorderTokenAliaseListItem, RadiusTokenAliaseListItem, SpacePatternAliaseItem, GetTokenAliaseList } from '../aliases';
import { GetSpacePatternItem, GetSpacingPatternList, SpacePattern } from '../spacing/patterns';

const TokenTypes = Globals.ProjectManager.Tokens.Types;

export default class MultipleTokenGroupSelector extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.onSearch = this.onSearch.bind(this);        
        this.CanceSearch = this.CanceSearch.bind(this);
    }
    onSearch(filter) {
        this.setState({FilterText : filter});
    }
    CanceSearch() {
        this.setState({FilterText : ''});
    }
    ToggleSelectedGroup(groupId, selectedGroups, selected) {
        if (selected)
        selectedGroups.push(groupId);
        else
            Utils.RemoveEquals(selectedGroups, groupId);
        
        this.props.onSelectionChanged && this.props.onSelectionChanged(selectedGroups);
        this.RCUpdate();
    }
    ToggleSelectedAliase(tokenId, selectedTokens, selected) {
        if (selected)
            selectedTokens.push(tokenId);
        else
            Utils.RemoveEquals(selectedTokens, tokenId);
        
        this.props.onSelectionChanged && this.props.onSelectionChanged(selectedTokens);
        this.RCUpdate();
    }
    SelectAll(select, tokens) {
        if (!tokens)
            return;
            
        const {selectedGroups}  = this.props;
        
        let filterText;
        if (Utils.IsNotNullOrEmpty(this.state.FilterText)) {
            filterText = this.state.FilterText.toUpperCase();
        }

        tokens.map(({id, name}) => {
            let isFiltered = false;
            if (filterText) {
                if (name && name.toUpperCase().indexOf(filterText) < 0) {                    
                    isFiltered = true;
                }
            }
            if (!isFiltered) {
                if (select) {
                    if (selectedGroups.indexOf(id) < 0)
                        selectedGroups.push(id);
                }
                else {
                    Utils.RemoveEquals(selectedGroups, id);
                }
            }                
        })

        this.props.onSelectionChanged && this.props.onSelectionChanged(selectedGroups);
        this.RCUpdate();
    }
    renderCustom() {
        const {selectedGroups, tokenType, subType}  = this.props;

        let filterText;
        if (Utils.IsNotNullOrEmpty(this.state.FilterText)) {
            filterText = this.state.FilterText.toUpperCase();
        }

        let groups;
        if (this.props.isAliase) {
            groups = Globals.ProjectManager.Tokens.Aliases.GetGroups(tokenType);
        }
        else {
            if (tokenType === TokenTypes.CustomTokenTypes) {
                groups = Globals.ProjectManager.Tokens.GetGroups(this.props.customTokenType);
            }
            else {
                groups = Globals.ProjectManager.Tokens.GetGroups(tokenType);
            }            
        }
        const groupItems = [];
        Utils.ForEach(groups, (group, i) => {

            let isFiltered;

            if (filterText) {
                if (group.name.toUpperCase().indexOf(filterText) < 0) {                    
                    isFiltered = true;
                }
            }

            if (!isFiltered) {
                const isSelected = selectedGroups.indexOf(group.id) > -1;
                const onClick = this.ToggleSelectedGroup.bind(this, group.id, selectedGroups, !isSelected);
                groupItems.push(
                    <Switch key={i} checkEdit value={isSelected} onChange={onClick} />
                );
                groupItems.push(
                    <TokenItemBox onClick={onClick}>
                        {group.name}
                    </TokenItemBox>
                )
            }            
        });

    
        
        return (
            <React.Fragment>
                <div style={{padding : '8px', paddingBottom : 0}}>
                    <SearchInput
                        FilterText={this.state.FilterText}
                        Search={this.onSearch}
                        CancelSearch={this.CanceSearch}
                        small
                        full                            
                    /> 
                </div> 
                <SC.FRow alc jsb style={{padding : '10px'}}>
                    <SC.LinkText onClick={this.SelectAll.bind(this, true, groups)}>Select All</SC.LinkText>
                    <SC.LinkText onClick={this.SelectAll.bind(this, false, groups)}>Deselect All</SC.LinkText>
                </SC.FRow>
                <SC.CustomScrollbars>
                    <div style={{
                        padding : '8px',
                        paddingTop : 0,
                        display : 'grid',
                        gridTemplateColumns : 'auto 1fr',
                        alignItems : 'center',
                        gridColumnGap : '8px'
                    }}>
                        {groupItems}
                    </div>
                </SC.CustomScrollbars>
            </React.Fragment>            
        )
    }
}