import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppLayout,
    Links,
    Events,
    MetaData,
    Strings,
    Globals
} from '../../../../../../../importer';
import { TokenGroup, TokenItemBox, GroupTitle, TokenPanelHeader, ItemNameEditor } from '../../common';

import BaseGroup from '../base';
import { StyleToken_Color, PropertyLabel, StyleGroupTitle, SmallButtonGroup, Seperator } from '../../../../right/iteminspector/styleitems/common';
import ContentAlignments from '../../../../right/iteminspector/styleitems/layout/grid/designer/contentAlignment';
import ItemAlignments from '../../../../right/iteminspector/styleitems/layout/grid/designer/itemAlignmentAndGap';
import { ListCardView } from '../../../../../../../components/light_dark_bar';
import { LeftScrollPanel } from '../../../common';
import { GridTemplateItem } from '../../../../right/iteminspector/selectToken/grid/templates';
import TemplateDimension from '../../../../right/iteminspector/styleitems/layout/grid/designer/templateDimension';
import Switch from '../../../../../../../components/editors/Switch';

export default class GridTemplateEditor extends BaseGroup
{
    constructor(props) {
        super(props);        
        
        this.SaveModel = this.SaveModel.bind(this);
        this.SaveGridProperty = this.SaveGridProperty.bind(this);
        this.ToggleShowAdvanced = this.ToggleShowAdvanced.bind(this);
        
        this.GetItems = this.GetItems.bind(this);
    }   
    DeleteGridProperty(templateId, prop) {
        
    }
    SaveModel() {
        const GridModel = Globals.ProjectManager.Templates.GetStateValue({Id : this.props.id,  name : 'value'});
        this.SaveGridProperty('model', GridModel.model);
    }
    SaveGridProperty(prop, value) {
        const GridModel = Globals.ProjectManager.Templates.GetStateValue({Id : this.props.id,  name : 'value'});
        GridModel[prop] = value;
        Globals.ProjectManager.Templates.SetSubValue({id : this.props.id,  name : 'value', prop : prop, value : value});
        this.RCUpdate();
    }

    GetItems() {
        const templateIds = Globals.ProjectManager.Templates.Order(this.elementType);
        const templateItems = [];
        Utils.ForEach(templateIds, (templateId, i) => {
            if (templateId !== this.DefaultId) {
                const template = Globals.ProjectManager.Templates.Template(templateId);
                templateItems.push(template);
            }            
        });
        return templateItems;
    }
    SaveName(id, name) {
        Globals.ProjectManager.Templates.SetName(id, name);
    }
    ToggleShowAdvanced() {
        this.showAdvancedEditors = !this.showAdvancedEditors;
        this.RCUpdate();
    }
    DeleteTemplate() {
        Globals.ProjectManager.Templates.Delete(Globals.ProjectManager.Templates.Types.Grid, this.props.id);
        this.props.onClose();
    }
    renderCustom() {
        const template = Globals.ProjectManager.Templates.Template(this.props.id);
        const GridModel = Globals.ProjectManager.Templates.GetStateValue({Id : this.props.id,  name : 'value'});
        const justifyContent = GridModel.justifyContent;
        const alignContent = GridModel.alignContent;
        const justifyItems = GridModel.justifyItems;
        const alignItems = GridModel.alignItems;
        const rowGap = GridModel.rowGap;
        const columnGap = GridModel.columnGap;
        
        const width = this.props.width - 58;
        const height = width * 0.75;

        return (
            <SC.FCol fw fh  style={{backgroundColor : SC.CurrentTheme.theme.back, paddingBottom : '8px'}}>
                <TokenPanelHeader title={'EDIT GRID TEMPLATE'}
                    hasAddCancel 
                    onClose={this.props.onClose}
                    onCancel={this.onCancelAddColor} 
                    onAdd={this.onSubmitNewColor} 
                    onClone={this.onCloneColor}
                    onDelete={this.DeleteTemplate}                                
                    style={{backgroundColor : SC.CurrentTheme.theme.back_lighter}}
                />
                <ItemNameEditor
                    noMargin
                    style={{padding : '8px'}}
                    fontSize='12px'                        
                    name={template.name}
                    onSaveName={this.SaveName.bind(this, this.props.id)}
                    onGetItems={this.GetItems}
                    model={template}
                />  
                <SC.FCol f1>
                    <SC.CustomScrollbars>
                        <SC.FCol fw fh style={{padding : '8px', boxSizing : 'border-box'}}>
                            <div style={{height : Utils.px(height), marginBottom : '8px'}}>
                                <GridTemplateItem
                                    template={template}
                                    width={width}
                                    height={height}
                                    justContent
                                />
                            </div>
                            <SC.FCol f1 style={{backgroundColor : SC.CurrentTheme.theme.back, paddingBottom : '8px'}}>
                                <ContentAlignments 
                                    onChange={this.SaveGridProperty}
                                    justifyContent={justifyContent}
                                    alignContent={alignContent}
                                />
                                <ItemAlignments 
                                    onChange={this.SaveGridProperty}
                                    onDelete={this.DeleteGridProperty.bind(this, this.DefaultId)}
                                    justifyItems={justifyItems}
                                    alignItems={alignItems}
                                    rowGap={rowGap}
                                    columnGap={columnGap}
                                />
                                <SC.FRow alc justifyEnd style={{marginTop : '8px'}}>
                                    <div style={{marginRight : '8px', fontSize : '11px'}}>Advanced Grid Options</div>
                                    <Switch small value={this.showAdvancedEditors} onChange={this.ToggleShowAdvanced} />
                                </SC.FRow>
                                <Seperator />
                                <TemplateDimension templateName='Columns' singleLabel='Column' headerLabel='COLUMNS' model={GridModel.model} SaveModel={this.SaveModel} advancedOptions={this.showAdvancedEditors} />
                                <Seperator />
                                <TemplateDimension templateName='Rows' noMargin={!this.showAdvancedEditors} singleLabel='Row' headerLabel='ROWS' model={GridModel.model} SaveModel={this.SaveModel} />            
                                
                                {
                                    this.showAdvancedEditors && 
                                    <React.Fragment>
                                        <Seperator />
                                        <TemplateDimension auto noMargin templateName='AutoColumns' singleLabel='Column' headerLabel='AUTO COLUMNS' model={GridModel.model} SaveModel={this.SaveModel} />
                                        <Seperator />
                                        <TemplateDimension auto templateName='AutoRows' singleLabel='Row' headerLabel='AUTO ROWS' model={GridModel.model} SaveModel={this.SaveModel} />
                                    </React.Fragment>
                                }                     
                            </SC.FCol>
                        </SC.FCol>    
                    </SC.CustomScrollbars>   
                </SC.FCol>                                                                
            </SC.FCol>
            
        )
    }
}