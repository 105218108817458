import React from 'react';
import BaseItem from './BaseItem';
import {Utils} from '../importer';

export default class SvgIcon extends BaseItem
{
    constructor(props) {
        super(props);
    }    
    renderItem({style, props, events, rest}) {
        return renderEngineIcon({
            icon : props.icon,
            style : style,
            props : {
                ...events,
                ...rest
            }
        })        
    }
}

export const renderEngineIcon = ({icon, style, props}) => {
    let content;
    if (icon) {
        if (icon.data) {
            if (icon.provider === 'Iconfinder' || icon.Base64)
                style.backgroundImage = `url(data:image/svg+xml;base64,${icon.data})`;
            else
                style.backgroundImage = `url(${icon.data})`;
            style.backgroundSize = 'contain';
            style.backgroundRepeat = 'no-repeat';
            style.backgroundPosition = 'center';                
        }
        else if (icon.paths) {
            content = (
                <svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' viewBox={`0 0 24 24`} >
                    {
                        icon.paths.map((p, i) => {
                            return (
                                <path key={i} d={p} />
                            )
                        })
                    }
                </svg>
            );
        }
    }
    return (
        <div 
            style={style}
            {...props}
        >
            {content}
        </div>
    );
}