import React from 'react';
import BaseItem from '../../BaseItem';
import {Utils, MetaData} from '../../../importer';
import Radium from 'radium';

class TextInput extends React.Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);

        this.state = {
            value : this.props.value || ''
        }

    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.value !== this.state.value)
            this.state.value = nextProps.value;
        return true;
    }
    onChange(e) {
        let value = e.target.value;
        this.setState({value : value})
        this.props.onChange(value, e);
    }
    onFocus(e) {        
        this.props.onFocus && this.props.onFocus(e);
        if (window.AppState) {
            window.AppState.Designer.SetEditingText(true);;
        }
    }
    onBlur(e) {
        this.props.onBlur && this.props.onBlur();
        if (window.AppState) {
            window.AppState.Designer.EditingText = false;
        }
    }
    render() {                        

        return (
            <input
                type={this.props.dateType}         
                {...this.props}
                onChange={this.onChange}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                value={this.state.value}                                
            >
            </input>
        )
    }
}

const StyledInput = Radium(TextInput)

class Generic_TextInput extends BaseItem
{
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }
    onChange(value, e) {
        const modelid = Utils.JustGet(this.StateStatics, null, 'variables_current', 'value');
        if (modelid) 
            this.props.onSetModelValue(modelid, value);        

        this.props.onChange && this.props.onChange(value, e);
        if (this.props.events) {
            if (this.props.events.onChange)
                this.props.events.onChange(e);
        }
    }
    renderItem({style, props, events, rest}) {                        
        const dateType = Utils.JustGet(props.GenericOptions, 'date', 'datetype');

        return (
            <StyledInput
                {...events}
                {...rest}
                style={{
                    fontFamily : 'unset',
                    fontSize : 'unset',
                    ...style
                }}
                type={dateType}
                readOnly={this.props.IsStateActive('readonly', 'readonly')}
                disabled={this.props.IsStateActive('disabled', 'disabled')}
                onChange={this.onChange}
                value={props.value}
            />
        )
    }
}

export default Generic_TextInput;