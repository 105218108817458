import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Events,
    AppState,
    MetaData,
    Utils,
    Strings,
    Globals
} from '../../../../../../../importer';

import NumberInput from '../../../../../../../components/editors/input_number';
import DropDownSelect from '../../../../../../../components/editors/enum_dropdown';
import TextInput from '../../../../../../../components/editors/textinput';
import VariableBinder from '../variable';
import { PropertyLabel, StyleGroupTokenItem, Seperator, StyleGroupTitle } from '../common';
import { BooleanEditor } from '../hidden';
import { TextContentPropertyEditor } from '../textContent';
import { ImageSourceSelectoer, ImageTokenSelectItem, ImagePositionEditor, ImageSizeAndPositionEditor } from '../image';
import { IconTokenSelectItem, RenderIconTokenPreview } from '../icon';

export default class ComponentPreviewPropItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }

        this.onChangeImageUrl = this.onChangeImageUrl.bind(this);
        
    }
    onChangeImageUrl(value) {
        this.props.onChange({
            url : value
        })
    }
    render() { 
        let editor;
        if (this.props.dataType === 'string') {
            editor = (
                <TextInput 
                    value={this.props.value}
                    onChange={this.props.onChange}
                    dark
                />
            )
        }
        else if (this.props.dataType === 'image') {
            const url = Utils.JustGet(this.props.value, '', 'url');
            editor = (
                <TextInput 
                    value={url}
                    onChange={this.onChangeImageUrl}
                    dark
                />
            )
        }
        else {
            
            if (Utils.IsObject(this.props.value)) {
                editor = (<div />);
            }
            else {
                editor = (
                    <TextInput 
                        value={this.props.value}
                        onChange={this.props.onChange}
                        dark
                    />
                )
            }
        }
        return (  
            <React.Fragment>
                <PropertyLabel>{this.props.name}</PropertyLabel>
                {editor}
            </React.Fragment>
        );
    }
}
 