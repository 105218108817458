import Strings from './Strings';
import { Utils } from '../toolabs-importer';

var db_api = null;

const Data = {
    SetDataApi(api) {
        db_api = api;
    },
    Set_Path(value, path) {
        db_api.api.set_path(path, value);
    },
    Data: {

    },
    Admin: {
        GetApi() {
            return db_api;
        },
        UserList() {
            return db_api.admin_user_list();
        },
        UserInfo(id) {
            return db_api.admin_user_info(id);
        },
        UserBoards(id) {
            return db_api.admin_user_boards(id);
        },
        SwitchToUser(id) {
            this.AdminUser = db_api.UserId;
            db_api.LoadUser(id);
        },
        SwitchToAdmin() {
            db_api.LoadUser(this.AdminUser);
        },
        ImportProject(uid, pid) {
            db_api.admin_import_board(uid, pid);
        }
    },
    User: {
        Info(id) {
            return db_api.UserInfo(id);
        },
        Set(Value, ...Path) {
            Utils.Set(this.Data, Utils.UseUndefined(Value, null), ...Path)
            db_api.update_path_user_board(Data.Board.Id, Path, Utils.UseUndefined(Value, null));
        },
        Delete(...Path) {
            Utils.UnSet(this.Data, ...Path);
            db_api.delete_path_user_board(Data.Board.Id, Path);
        },
        Get(DefValue, ...Path) {
            return Utils.Get(this.Data, DefValue, ...Path);
        },
        Options : {
            Set(Value, ...Path) {
                db_api.update_path_user_option(Path, Utils.UseUndefined(Value, null));
            },
            Delete(...Path) {
                db_api.delete_path_user_option(Path);
            },
            Get(...Path) {
                return db_api.get_path_user_option(Path);
            },
        }
    },
    UserStats : {
        TimeSpans : {

        },
        Modules : {
            LOGIN : 'Login',
            PROJECTS : 'Projects',
            PR_SETTINGS : 'Settings',
            PR_TOKENS : Strings.DESIGNTOKENS,
            PR_ELEMENTS : Strings.STYLESETS,
            PR_LAYOUTS : Strings.LAYOUTS,
            PR_COMPONENTS : Strings.COMPONENTS,
            PR_PLAYGROUND : Strings.PLAYGROUND,
            PR_PUBLISH : Strings.PUBLISH,
            PR_TEMPLATES : Strings.TEMPLATES,
            CMP_DESIGN : 'Component_Design',
            CMP_PREVIEW : 'Component_Preview',
            CMP_STATES : 'Component_States',
            CMP_EVENTS : 'Component_Events',
            CMP_CONDITIONS : 'Component_Conditions',
            CMP_VARIABLES : 'Component_Variables'
        },
        Start(...Path) {
            const count = this.Get(0, ...Path, 'Count') + 1;
            this.Set(count, ...Path, 'Count');
            Utils.Set(this.TimeSpans, Date.now(), ...Path);
        },
        End(...Path) {
            const StartTime = Utils.Get(this.TimeSpans, null, ...Path);
            if (StartTime) {
                const duration = Date.now() - StartTime;
                this.SetLog(duration, StartTime, ...Path);
            }
        },
        Update(...Path) {
            const count = this.Get(0, ...Path) + 1;
            this.Set(count, ...Path);
            return count;
        },
        SetLog(Value, ...Path) {
            db_api.update_path_user_log(Path, Utils.UseUndefined(Value, null));
        },
        Set(Value, ...Path) {
            Utils.Set(this.Data, Utils.UseUndefined(Value, null), ...Path)
            db_api.update_path_user_config(Path, Utils.UseUndefined(Value, null));
        },
        Delete(...Path) {
            Utils.UnSet(this.Data, ...Path);
            db_api.delete_path_user_config( Path);
        },
        Get(DefValue, ...Path) {
            return Utils.Get(this.Data, DefValue, ...Path);
        },
    }
};

export default Data;
