import DataType from '../datatypes';

const Attributes = {
    width : {
        type : DataType.size,
        name : 'width',
        label : 'Width'
    },
    height : {
        type : DataType.size,
        name : 'width',
        label : 'Width'
    },
    d : {
        type : DataType.text,
        label : 'Path',
        name : 'd'
    },
    'fill' : {
        type : DataType.color,
        label : 'Fill',
        name : 'fill',
        other_values : ['none']
    },
    'stroke' : {
        type : DataType.color,
        label : 'Stroke',
        name : 'stroke',
        other_values : ['none']
    },        
    'stroke-width' : {
        type : DataType.svg_length,
        label : 'Stroke Width',
        name : 'strokeWidth'
    },
    'stroke-linecap' : {
        type : DataType.enum,
        label : 'Stroke Width',
        name : 'strokeLinecap',
        values : ['butt', 'round', 'square', 'inherit']
    },
    'stroke-dasharray' : {
        type : DataType.array_number,
        label : 'Stroke Dasharray',
        name : 'strokeDasharray'
    },
    'stroke-dashoffset' : {
        type : DataType.svg_length,
        label : 'Stroke Dashoffset',
        name : 'strokeDashoffset'
    },
    'cx' : {
        type : DataType.svg_length,
        label : 'cx',
        name : 'cx'
    },
    'cy' : {
        type : DataType.svg_length,
        label : 'cy',
        name : 'cy'
    },
    'r' : {
        type : DataType.svg_length,
        label : 'r',
        name : 'r'
    },
};

export default Attributes;