import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    UIUtils,
    Globals
} from '../../../../../../../importer';

import { ItemNameEditor } from '../../common';
import ShadowEditor from '../../../../../../../components/editors/shadow';
import { ShadowPreviewBox } from '../';
import LightDarkBar from '../../../../../../../components/light_dark_bar';

export default class ShadowTokenEditor extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.SaveName = this.SaveName.bind(this);
        this.GetItems = this.GetItems.bind(this);
        this.onShadowChanged = this.onShadowChanged.bind(this);
        this.LogTokenChange = this.LogTokenChange.bind(this);
        this.SelectColor = this.SelectColor.bind(this);
        this.EditColor = this.EditColor.bind(this);        
        this.onRefreshPreview = this.onRefreshPreview.bind(this);
        
        this.Ref_Editor = React.createRef();
        this.Ref_Name = React.createRef();
        this.Ref_Preview = React.createRef();

        if (this.props.newModel) {
            this.token = this.props.newModel;
            this.EditingModel = this.props.newModel;
        }
        else {
            this.Load(this.props.id);            
        }
    }
    Load(id) {
        this.token = Globals.ProjectManager.Tokens.Shadow(id);
        this.EditingModel = {
            values : Utils.DeepClone(Utils.JustGet(Globals.ProjectManager.Tokens.ValueOf({model : this.token}), [], 'values')),
            dark : Globals.ProjectManager.Tokens.ValueOf({model : this.token, name : 'dark'}),
            textShadow : this.token ? this.token.textShadow : false
        };

        this.shadowvalue = Utils.GetShadowCss(this.EditingModel.values, this.EditingModel.textShadow, (tokenId) => {
            return Globals.ProjectManager.Tokens.GetStateValue({Id : tokenId})
        }); 
    }
    componentWillUnmount() {
        this.props.onClosed && this.props.onClosed();
        super.componentWillUnmount();        
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.GlobalState !== this.props.GlobalState || this.props.GlobalStateId !== nextProps.GlobalStateId) {
            if (!this.props.newModel) {
                this.token = Globals.ProjectManager.Tokens.Shadow(nextProps.id);
                if (!this.token) {
                    this.props.onCancel();
                    return false;
                }
                this.EditingModel.values = Utils.DeepClone(Utils.JustGet(Globals.ProjectManager.Tokens.ValueOf({model : this.token}), [], 'values'));

                this.shadowvalue = Utils.GetShadowCss(this.EditingModel.values, this.EditingModel.textShadow, (tokenId) => {
                    return Globals.ProjectManager.Tokens.GetStateValue({Id : tokenId})
                }); 
            }
        }
        if (this.props.id !== nextProps.id || this.props.renderId !== nextProps.renderId)
            this.Load(nextProps.id);
        return true;
    }
    GetItems() {
        return Globals.ProjectManager.Tokens.TokenList(Globals.ProjectManager.Tokens.Types.Shadows);
    }        
    onShadowChanged() {
        if (!this.ShadowChangeLogged && !this.props.newModel) {
            Globals.ProjectManager.LogTokenChange({Desc : 'Change Shadow'});
            this.ShadowChangeLogged = true;            
        }

        const shadows = Utils.JustGet(this.EditingModel, [], 'values');
        this.shadowvalue = Utils.GetShadowCss(shadows, this.EditingModel.textShadow, (tokenId) => {
            return Globals.ProjectManager.Tokens.GetStateValue({Id : tokenId})
        });

        if (this.props.newModel)
            this.props.onPreviewChange && this.props.onPreviewChange(this.EditingModel);
        else {            
            const changingTokens =  [{
                Id : this.props.id,
                Type : Globals.ProjectManager.Tokens.Types.Shadows,
                value : this.shadowvalue

            }];

            if (this.token.aliases) {
                this.token.aliases.map((aliaseId) => {
                    changingTokens.push({
                        Id : aliaseId,
                        Type : Globals.ProjectManager.Tokens.Types.Shadows,
                        value : this.shadowvalue
                    })
                })
            }

            Events.BroadcastThrottle_50(Events.GLOBAL.TOKEN_VALUE_CHANGING, changingTokens);
        }
        this.props.onChanged && this.props.onChanged();        
        this.RCThrottledUpdate_1();
    }
    onRefreshPreview() {
        const shadows = Utils.JustGet(this.EditingModel, [], 'values');
        this.shadowvalue = Utils.GetShadowCss(shadows, this.EditingModel.textShadow, (tokenId) => {
            return Globals.ProjectManager.Tokens.GetStateValue({Id : tokenId})
        });
        if (this.Ref_Preview.current) {
            const DN = ReactDOM.findDOMNode(this.Ref_Preview.current);
            if (this.token.textShadow) {
                DN.textShadow = this.shadowvalue;
            }
            else {
                DN.boxShadow = this.shadowvalue;
            }
        }
    }
    LogTokenChange() {
        if (this.props.newModel)
            return;
        Globals.ProjectManager.LogTokenChange({Desc : 'Change Shadow'});
    }

    SaveName(name) {
        if (this.props.newModel)
            this.props.newModel.name = name;
        else
            Globals.ProjectManager.Tokens.ChangeTokenName(this.props.id, name);
        this.RCUpdate();
    }
    EditColor(shadow, index) {
        const onChange = (color) => {
            const editingShadow = this.EditingModel.values[index];
            editingShadow.color = color;                        

            if (this.props.offline) {
                
            }
            else {
                Globals.ProjectManager.Tokens.SetValue({
                    id : this.props.id,
                    value : {
                        values : Utils.JustGet(this.EditingModel, [], 'values')
                    },
                    type : Globals.ProjectManager.Tokens.Types.Shadows,
                    textShadow : this.token.textShadow
                })  
            }

            this.onShadowChanged();
        }
        if (this.props.onEditColor) {
            this.props.onEditColor(shadow, onChange);
        }
        else if (this.props.RefToolbar && this.props.RefToolbar.current) {
            this.props.RefToolbar.current.ShowPanel({
                type : AppState.ItemTypes.BOARD.COLOR,
                title : 'SHADOW COLOR',
                justEditor : true,
                itemId : shadow.id,
                noGradient : true,
                color : shadow.color,
                onChange : onChange,
                onRegisterClose : (callClose) => {
                    super.AddCloseCallback(callClose);
                },
                onClosed : () => {

                }                
            })
        }   
    }
    SelectColor(onSelect, onPreview, onClosed, colorId) {
        if (this.props.onSelectColor) {
            this.props.onSelectColor(onSelect, onPreview, onClosed);
        }
        else if (this.props.RefToolbar && this.props.RefToolbar.current) {
            this.props.RefToolbar.current.ShowPanel({
                type : AppState.ItemTypes.BOARD.COLOR,
                title : 'SHADOW COLOR',
                justEditor : true,
                noGradient : true,
                color : colorId,
                onSelect : (tokenId, isGradient) => {
                    onSelect(tokenId)
                },
                onPreviewToken : ({show, tokenId}) => {
                    onPreview(tokenId, show);
                },
                onClosed : onClosed,
                onRegisterClose : (callClose) => {
                    super.AddCloseCallback(callClose);
                },
                onPreviewNewToken : () => {
                    this.RCUpdate();
                    if (this.Ref_Editor.current) {
                        this.Ref_Editor.current.RCUpdate();
                    }
                }
            })
        }        
    }
    renderCustom() {                    
        return (
            <SC.FCol f1 fw fh style={{padding : '10px', boxSizing : 'border-box', backgroundColor : SC.CurrentTheme.theme.back}}>
                <SC.FCol style={{marginBottom : '8px'}}>
                    {!this.props.justEditor && 
                        <ItemNameEditor
                            noMargin
                            fontSize='12px'                        
                            name={this.token.name}
                            onSaveName={this.SaveName}
                            onGetItems={this.GetItems}
                            model={this.token}
                            readOnly={this.token.locked}
                            lockable={{
                                isLocked : this.token.locked,
                                onTokenLock : () => {
                                    this.token.locked = Globals.ProjectManager.Tokens.ToggleTokenLock(this.props.id);                                    
                                    this.RCUpdate();
                                }
                            }}
                        />
                    }
                </SC.FCol>    
                <SC.FRow jsb alc style={{paddingLeft : '4px', pointerEvents : this.token.locked ? 'none' : 'all'}}>
                    <div>Preview Background</div>
                    <SC.FRow>
                        <LightDarkBar 
                            small 
                            Dark={this.EditingModel.dark}
                            medium
                            onChange={(e) => {
                                Globals.ProjectManager.Tokens.SetValue({id : this.props.id, value : !this.EditingModel.dark, name : 'dark'})
                                this.EditingModel.dark = !this.EditingModel.dark;
                                this.RCUpdate();
                            }}
                        />
                    </SC.FRow>
                    
                </SC.FRow>
                <ShadowPreviewBox ref={this.Ref_Preview} dark={this.EditingModel.dark} style={{maxHeight : '160px', marginTop : '8px', marginBottom : 0}} >
                    {
                        this.token.textShadow ? 
                        <div style={{
                            textShadow : this.shadowvalue,
                            fontSize : '24px',
                            marginTop : this.props.DragItem ? '24px' : 0,
                            marginBottom : this.props.DragItem ? '24px' : 0,
                            fontWeight : 500,
                            color : this.EditingModel.dark ? '#2d2e32' : '#fff',
                        }}>
                            Text Shadow
                        </div> : 
                        <div style={{
                            boxShadow : this.shadowvalue,
                            width : '50%',
                            height : '50%',
                            backgroundColor : this.EditingModel.dark ? '#2d2e32' : '#fff',
                            marginTop : '8px',
                            marginBottom : '8px',
                            minHeight : '60px',
                            borderRadius : '4px',
                            transition : 'box-shadow 0.2s ease'
                        }} />
                    }                       
                </ShadowPreviewBox> 
                <ShadowEditor
                    ref={this.Ref_Editor}
                    id={this.props.id}
                    style={{marginTop : '8px', paddingLeft : '2px', paddingRight : '2px', flex : 1, pointerEvents : this.token.locked ? 'none' : 'all'}}
                    model={this.EditingModel}
                    onUpdate={this.onShadowChanged}
                    onRefreshPreview={this.onRefreshPreview}
                    offline={this.props.offline}
                    hideShadowType={this.props.hideShadowType || (this.props.newModel ? false : true)}
                    ownerPosition={'LeftPanel'}
                    onBeforeChange={this.LogTokenChange}
                    onSelectColor={this.SelectColor}
                    onEditcolor={this.EditColor}
                /> 
            </SC.FCol>
        )
    }
}
