import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    Strings
} from '../../importer';

import styled, {css} from 'styled-components';
import DropDownItemPopup from './dropdownpopup';
import { AnimatePresence } from 'framer-motion';

const BS = SC.Board_Styles;


const DropDownItem = styled.button`       
    position : relative;    
    border : none;
    background : none;
    padding : 0;
    fill : #A7A7A8;
    &:hover {
        fill : #FFFFFF;
    }
    &:active {
        
    }
    &:focus {
        
    }
    text-align : left;
    font-size : 12px;
    height : ${props => props.xsmall ? 28 : 30}px;
    max-height : ${props => props.xsmall ? 28 : 30}px;
    ${
        props => {
            if (props.fullwidth)
                return css`width : 100%;`;
            if (props.xsmall) 
                return css`min-width : 60px`;
        }
    }
`;


const DropDown_Box = styled.div`
    height : 100%;
    padding-left : 6px;
    padding-right : 0;
    display : flex;
    justify-content : space-between;
    align-items : center;            
    box-sizing : border-box;
    overflow : hidden;
    cursor : pointer;
    color : ${props => props.theme.font};
    border : ${props => props.open ? props.theme.border_brand : props.theme.border_seperator};
    ${props => props.defaultStyle};
    &:hover {        
        background-color : ${props => props.theme.back_lightest};
        color : ${props => props.theme.font_hover};
        ${props => props.hoverStyle};
    }
    &:focus {
        border : ${props => props.theme.border_brand};
        color : ${props => props.theme.font_hover};
    }
`;

class DropDownSelect extends ReactBaseComponent
{        
    renderCustom() {
        const {items, value, xsmall} = this.props;
        let selectedItem = null;        
        if (Utils.IsNotNull(value)) {
            const valueitem = Utils.Find(items, (item) => {return item.id === value});
            if (valueitem) {
                
                if (this.props.renderLabel)
                    selectedItem = this.props.renderLabel(valueitem);
                else
                    selectedItem = valueitem.label || value;
            }
        }        
        else {
            if (this.props.renderLabel)
                selectedItem = this.props.renderLabel();
            else {                
                if (this.props.nullItem) {
                    selectedItem = this.props.nullItem;
                }
                else {
                    const nullitem = Utils.Find(items, (item) => {return item.id === Strings.NULL});
                    if (nullitem)
                        selectedItem = nullitem.label || nullitem.id;
                }
            }
        }
        const style = {
            
        };
        
        let useTheme = this.props.useTheme || SC.CurrentTheme.theme;

        if (!this.props.noBackColor) {            
            if (this.props.empty) {
                if (this.props.dark)
                    style.backgroundColor = useTheme.back;
                else
                    style.backgroundColor = '#303135';
            }
            else {
                if (this.props.dark)
                    style.backgroundColor = useTheme.back;
                else
                    style.backgroundColor = '#28292d';
            }            
            style.backgroundColor = useTheme.dropdown_back;
        }                

        if (this.props.hasBorder) {
            // let color = useTheme.border_seperator_color;
            // if (this.props.open) {
            //     color = this.props.focusedColor || '#6E6E6F';
            // }
            // else {
            //     if (this.props.dark)
            //         color = '#343439';
            //     else
            //         color = useTheme.border_seperator_color;
            // }
            // style.border = `1px solid ${color}`;            
        }
        else 
            style.border = 'none';

        style.borderRadius = '2px';
        const style_item = Utils.Merge({flex : 1}, this.props.itemStyle);
        if (this.props.alignCenter)
            style_item.textAlign = 'center';
        style_item.position = 'relative';
        style_item.flex = 1;
        style_item.alignSelf = 'stretch';        
        style_item.overflow = 'hidden';
        return (
            <DropDownItem 
                theme={useTheme}
                empty={!value} 
                xsmall={xsmall} 
                fullwidth={this.props.fullwidth} 
                light={this.props.light} 
                style={this.props.style}
                onClick={this.props.readOnly ? null :  
                    () => {
                        this.ShowPopup  = !this.ShowPopup && !this.IsClosingPopup; 
                        this.IsClosingPopup = false;
                        this.RCUpdate();
                    }
                }
            >
                {
                    this.ShowPopup && !this.IsClosingPopup ?
                    <DropDownItemPopup 
                        useTheme={useTheme}
                        xsmall={xsmall || this.props.smallPopup}
                        autoHeight={this.props.autoHeight}
                        empty={!value}
                        {...this.props}
                        onSelect={this.props.onChange} 
                        onClose={
                            () => {
                                this.IsClosingPopup = true;
                                this.RCUpdate(); 
                                setTimeout(() => {
                                    this.IsClosingPopup = false;
                                    this.ShowPopup = false                                    
                                }, 200);                                
                                if (this.props.onClosed)
                                    this.props.onClosed();
                            }
                        }
                        focusedColor={this.props.focusedColor}
                        light={this.props.light}
                    />
                    : null
                }
                <DropDown_Box 
                    theme={useTheme}
                    xsmall={xsmall} 
                    empty={!value} 
                    open={this.ShowPopup && !this.IsClosingPopup} 
                    focusedColor={this.props.focusedColor}                     
                    dark={this.props.dark}
                    defaultStyle={{...style, ...this.props.boxStyle}}
                    hoverStyle={{...this.props.boxHoverStyle}}
                >                
                    {
                        this.props.justDropDown ? null : 
                        <SC.FRow alc style={style_item}>
                            {
                                this.props.renderLabelItem ? this.props.renderLabelItem(selectedItem || this.props.placeholder, value) : 
                                <SC.TextSpanAbbr theme={useTheme} style={{flex : 1}}>{selectedItem || this.props.placeholder} </SC.TextSpanAbbr>
                            }
                        </SC.FRow>
                    }                        
                    {
                        this.props.readOnly ? null : 
                        <SC.Icons.Icon_Button theme={useTheme} hasFill hasCursor>
                            <SC.Icons.Icon_DropDown size={this.props.dropDownSize} />
                        </SC.Icons.Icon_Button>
                    }                    
                </DropDown_Box>
            </DropDownItem> 
        );
    }
}

export default DropDownSelect;