import React from 'react';
import {
    ReactBaseComponent,
    AppState,
    SC,
    Utils,
    Events,
    AppLayout,
    Globals,
    Strings
} from '../../../../../../../importer';

import styled from 'styled-components';
import {ItemNameEditor, TokenItemBox, DraggableTokenItem, TokenPanelHeader, GroupTitle, EditableName} from '../../common';
import { StyleToken_Color, ToggleButton } from '../../../../right/iteminspector/styleitems/common';
import { InfoPanel } from '../../../../../../../components/info';
import { StyleTokenBorderStyle } from '../../../../right/iteminspector/styleitems/borders';
import { StyleTokenShadowItem } from '../../../../right/iteminspector/styleitems/shadow';
import { SpacePattern, GetSpacePatternItem } from '../../spacing/patterns';

import ColorTokenEditor from '../../colors/color';

import { BorderRadiusTokenItem, GetBorderRadiusTokenValue } from '../../borders';
import { TextPattern, GetTextPatternTokenItem } from '../../typography/patterns';
import { DurationPattern, GetDurationTokenItemOfId } from '../../motion/patterns';
import ShadowTokenEditor from '../../shadows/item';
import BorderTokenEditor from '../../borders/border';
import BorderRadiusTokenEditor from '../../borders/radius';
import { FilterListItem, GetFilterTokenItem } from '../../filters';
import { EasePattern, GetEaseCurveTokenItem } from '../../motion/easing';
import { FigmaLayoutListItem, GetFigmaLayoutTokenItem } from '../../../../../../../apps/figmaplugin/designsystem/autolayouts';
import InputSlider from '../../../../../../../components/editors/input_slider';

export default class AliaseItem extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.SaveName = this.SaveName.bind(this);
        this.GetItems = this.GetItems.bind(this);

        this.SelectToken = this.SelectToken.bind(this);
        this.EditToken = this.EditToken.bind(this);
        this.ToggleRefrenceMode = this.ToggleRefrenceMode.bind(this);        
        
        this.aliase = this.props.aliase;
        const aliaseToken = Globals.ProjectManager.Tokens.Aliases.TokenAliase(this.props.id);
        if (aliaseToken) {
            this.aliase.tokenId = Globals.ProjectManager.Tokens.Aliases.GetStateTokenIdOf({model : aliaseToken, firstReference : true})
        }        
        

    }
    SaveName(name) {
        this.aliase.name = name;
        if (!this.props.isNew)
            Globals.ProjectManager.Tokens.Aliases.ChangeAliaseName(this.props.id, name);
        this.RCUpdate();
    }
    GetItems() {
        return Globals.ProjectManager.Tokens.Aliases.List(this.props.type);
    }
    EditToken() {
        
    }
    ToggleRefrenceMode() {
        this.aliase.isAbsolute = !this.aliase.isAbsolute;
        if (this.aliase.isAbsolute)
            this.aliase.tokenId = this.props.id;
        else {
            delete this.aliase.tokenId;
        }
        Globals.ProjectManager.Tokens.Aliases.UpdateProp({
            id : this.props.id, 
            name : 'isAbsolute',
            value : this.aliase.isAbsolute
        });
        Globals.ProjectManager.Tokens.Aliases.UpdateProp({
            id : this.props.id, 
            name : 'type',
            value : this.props.type
        });
        this.RCUpdate();
    }
    componentWillUnmount() {
        this.props.onClosed && this.props.onClosed();
        super.componentWillUnmount();        
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.id !== this.props.id) {
            this.aliase = nextProps.aliase;
        }
        if (this.props.GlobalStateId !== nextProps.GlobalStateId) {
            
        }
        this.aliase = nextProps.aliase;
        return true;
    }
    onChangeOpacity(value) {
        Globals.ProjectManager.Tokens.Aliases.SetValue({id : this.props.id, name: 'opacity', value : value, type : Globals.ProjectManager.Tokens.Types.COLOR});
    }
    onChangingOpacity(value) {        
        Globals.ProjectManager.Tokens.Aliases.SetValue({id : this.props.id, name: 'opacity', value : value, type : Globals.ProjectManager.Tokens.Types.COLOR});
    }
    renderSelectToken() {
        this.aliase.isAbsolute = Globals.ProjectManager.Tokens.Aliases.GetProp(this.props.id, 'isAbsolute');
        

        let item;
        let isReference = !this.aliase.isAbsolute;
        let canHaveAbsoluteValue = false;

        if (this.props.type === Globals.ProjectManager.Tokens.Types.COLOR) {
            canHaveAbsoluteValue = true;
            if (!isReference) {
                item = (
                    <SC.FCol>
                        <ColorTokenEditor 
                            id={this.props.id}
                            isAbsoluteAliase    
                            // isNew={this.aliase.tokenId}
                            GlobalState={this.props.GlobalState}
                            GlobalStateId={this.props.GlobalStateId}
                            justEditor
                        />
                    </SC.FCol>
                )
            }
            else {
                const opacity = Globals.ProjectManager.Tokens.GetStateValue({Id: this.props.id, name: 'opacity', aliaseProperty: true});
                item =  (
                    <>
                        <StyleToken_Color 
                            tokenId={this.aliase.tokenId}
                            onSelect={this.SelectToken} 
                            isReference={isReference}
                        />
                        <SC.FCol alignStretch style={{marginTop: '16px'}}>
                            <div style={{marginBottom: '8px'}}>Opacity</div>
                            <InputSlider 
                                horizontal 
                                small 
                                reverse                                 
                                postFix='%'
                                value={opacity ?? 100}
                                min={0}
                                max={100}
                                allowOverflow
                                onChange={this.onChangeOpacity.bind(this)}
                                onChanging={this.onChangingOpacity.bind(this)}
                                inputStyle={{fontSize : '12px', marginLeft : '-8px'}}
                            />
                        </SC.FCol>
                    </>                    
                )
            }            
        }
        else if (this.props.type === Globals.ProjectManager.Tokens.Types.Shadows) {
            canHaveAbsoluteValue = true;

            if (!isReference) {
                item = (
                    <SC.FCol f1>
                        <ShadowTokenEditor 
                            {...this.props}
                            id={this.props.id}    
                            // isNew={this.aliase.tokenId}
                            GlobalState={this.props.GlobalState}
                            justEditor                            
                        />
                    </SC.FCol>
                )
            }
            else {
                item =  (
                    <StyleTokenShadowItem 
                        tokenId={this.aliase.tokenId}
                        onSelect={this.SelectToken} 
                        isReference={isReference}
                    />
                )
            }
        }
        else if (this.props.type === Globals.ProjectManager.Tokens.Types.Borders) {
            canHaveAbsoluteValue = true;

            if (!isReference) {
                item = (
                    <SC.FCol f1>
                        <BorderTokenEditor 
                            {...this.props}
                            id={this.props.id}    
                            // isNew={this.aliase.tokenId}
                            GlobalState={this.props.GlobalState}
                            justEditor                            
                        />
                    </SC.FCol>
                )
            }
            else {
                item =  (
                    <StyleTokenBorderStyle 
                        tokenId={this.aliase.tokenId}
                        onSelectBorderStyle={this.SelectToken} 
                        isReference={isReference}
                    />
                )
            }
        }
        else if (this.props.type === Globals.ProjectManager.Tokens.Types.BorderRadiuses) {
            let radiusModel;
            if (this.aliase.tokenId)
                radiusModel = GetBorderRadiusTokenValue(Globals.ProjectManager.Tokens.Token(this.aliase.tokenId), this.aliase.tokenId);
            if (!radiusModel) {
                radiusModel = {
                    model : {
                        name : 'Select Radius Token'
                    }
                }
            }

            canHaveAbsoluteValue = true;

            if (!isReference) {
                item = (
                    <SC.FCol f1>
                        <BorderRadiusTokenEditor
                            {...this.props}
                            id={this.props.id}    
                            // isNew={this.aliase.tokenId}
                            GlobalState={this.props.GlobalState}
                            justEditor                            
                        />
                    </SC.FCol>
                )
            }
            else {
                item =  (
                    <BorderRadiusTokenItem 
                        isListView
                        radius={radiusModel}
                        tokenId={this.aliase.tokenId}
                        onSelect={this.SelectToken}
                        isReference={isReference} 
                    />
                )
            }
        }
        else if (this.props.type === Globals.ProjectManager.Tokens.Types.Spacings) {
            if (this.aliase.tokenId) {
                const spacePattern = GetSpacePatternItem(this.aliase.tokenId);
                item =  (
                    <SpacePattern 
                        pattern={spacePattern.pattern}
                        spaceValue={spacePattern.spaceValue}
                        spaceUnit={spacePattern.spaceUnit}
                        onClick={this.SelectToken} 
                        listView
                        isReference={isReference}
                    />
                )
            }
            else {
                item = (
                    <TokenItemBox onClick={this.SelectToken}>
                        Select Space Token
                    </TokenItemBox>
                )
            }
        }      
        else if (this.props.type === Globals.ProjectManager.Tokens.Types.FigmaAutoLayouts) {
            if (this.aliase.tokenId) {
                const layoutToken = GetFigmaLayoutTokenItem(this.aliase.tokenId);
                item =  (
                    <FigmaLayoutListItem 
                        FigmaLayout={layoutToken}
                        onClick={this.SelectToken} 
                        listView
                    />
                )
            }
            else {
                item = (
                    <TokenItemBox onClick={this.SelectToken}>
                        Select Auto Layout Token
                    </TokenItemBox>
                )
            }
        }               
        else if (this.props.type === Globals.ProjectManager.Tokens.Types.Fonts) {
            let pattern;
            if (this.aliase.tokenId) {
                pattern = GetTextPatternTokenItem({id : this.aliase.tokenId});                                
            }
            if (pattern) {
                item =  (
                    <TextPattern 
                        pattern={pattern.pattern}
                        onClick={this.SelectToken} 
                        listView
                        isReference={isReference}
                    />
                )
            }
            else {
                item = (
                    <TokenItemBox onClick={this.SelectToken}>
                        Select Text Style
                    </TokenItemBox>
                )
            }
        }
        else if (this.props.type === Globals.ProjectManager.Tokens.Types.Motion) {
            if (this.aliase.tokenId) {
                const spacePattern = GetDurationTokenItemOfId(this.aliase.tokenId);
                item =  (
                    <DurationPattern 
                        pattern={spacePattern.pattern}
                        timeValue={spacePattern.timeValue}
                        onClick={this.SelectToken} 
                        listView
                        isReference={isReference}
                    />
                )
            }
            else {
                item = (
                    <TokenItemBox onClick={this.SelectToken}>
                        Select Duration Token
                    </TokenItemBox>
                )
            }
        } 
        else if (this.props.type === Globals.ProjectManager.Tokens.Types.MotionEase) {
            if (this.aliase.tokenId) {
                const easePattern = GetEaseCurveTokenItem(this.aliase.tokenId);
                item =  (
                    <EasePattern 
                        pattern={easePattern}
                        onClick={this.SelectToken} 
                        listView
                        isReference={isReference}
                    />
                )
            }
            else {
                item = (
                    <TokenItemBox onClick={this.SelectToken}>
                        Select Ease Curve Token
                    </TokenItemBox>
                )
            }
        } 
        else if (this.props.type === Globals.ProjectManager.Tokens.Types.Filters) {
            if (this.aliase.tokenId) {
                const filterToken = GetFilterTokenItem(this.aliase.tokenId);
                item =  (
                    <FilterListItem 
                        filter={filterToken}
                        onClick={this.SelectToken} 
                        listView
                        isReference={isReference}
                    />
                )
            }
            else {
                item = (
                    <TokenItemBox onClick={this.SelectToken}>
                        Select Filter Token
                    </TokenItemBox>
                )
            }
        } 

        return (
            <SC.FCol f1 style={{marginTop : canHaveAbsoluteValue ? '8px' : 0}}>
                {canHaveAbsoluteValue && <div style={{
                    display : 'grid',
                    gridGap : '8px',
                    paddingLeft : '8px',
                    paddingRight : '8px',
                    gridTemplateColumns : '1fr 1fr'
                }}>
                    <ToggleButton selected={isReference} onClick={this.ToggleRefrenceMode}>Reference to token</ToggleButton>
                    <ToggleButton selected={!isReference} onClick={this.ToggleRefrenceMode}>Absolute Value</ToggleButton>
                </div>}
                {
                    isReference && 
                    <InfoPanel style={{fontSize : '11px', margin : '8px'}}>
                        This aliase will use the following token 
                    </InfoPanel>
                }
                <SC.FCol f1 style={{
                    padding : isReference ? '8px' : 0
                }}>
                    {item}
                </SC.FCol>                
                {/* {
                    this.aliase.tokenId && 
                    <SC.LinkText onClick={this.EditToken} style={{marginTop : '8px', alignSelf : 'flex-end', fontSize : '11px'}}>
                        Edit Token
                    </SC.LinkText>
                }                                 */}
            </SC.FCol>
        )
    }
    SelectToken() {
        let onClosed;
        const onPreview = () => {
                    
        };
        const excludeIds = [this.props.id, ...Globals.ProjectManager.Tokens.GetAliaseIdsOfTokenId(this.props.id)];
        const onSelect = (tokenId) => {
            if (this.props.id !== tokenId) {
                this.aliase.tokenId = tokenId;
                if (!this.props.isNew) {
                    Globals.ProjectManager.Tokens.Aliases.SetTokenId({id : this.props.id, tokenId : tokenId});
                    Events.BCE(Events.GLOBAL.TOKENS_CHANGED);

                    // const value = Globals.ProjectManager.Tokens.ValueOfId(tokenId);
                    // const changes = [];
                    // excludeIds.map((id) => {
                    //     changes.push({
                    //         Id : id,
                    //         Type : this.props.type,
                    //         value : value
                    //     })
                    // })
    
                    // Events.BCE(Events.GLOBAL.TOKEN_VALUE_CHANGING, changes);
                }                
            }    
            this.RCUpdate();        
        };

        if (this.props.onSelectColor) {
            this.props.onSelectColor(onSelect, onPreview, onClosed);
        }
        else if (this.props.RefToolbar && this.props.RefToolbar.current) {
            const props = {
                title : 'SELECT TOKEN',        
                forAliase : true        
            };
            if (this.props.type === Globals.ProjectManager.Tokens.Types.COLOR) {
                props.type = AppState.ItemTypes.BOARD.COLOR;
                props.title = 'SELECT COLOR TOKEN';
                props.noGradient = false;
                props.isGradients = true;
            }
            else if (this.props.type === Globals.ProjectManager.Tokens.Types.Shadows) {
                props.type = AppState.ItemTypes.BOARD.SHADOW;
                props.title = 'SELECT SHADOW TOKEN';
                props.textAndBox = true;
            }
            else if (this.props.type === Globals.ProjectManager.Tokens.Types.Borders) {
                props.type = AppState.ItemTypes.BOARD.BORDER_STYLE
                props.title = 'SELECT BORDER TOKEN';
            }
            else if (this.props.type === Globals.ProjectManager.Tokens.Types.BorderRadiuses) {
                props.type = AppState.ItemTypes.BOARD.BORDER_RADIUS;
                props.title = 'SELECT RADIUS TOKEN';
            }
            else if (this.props.type === Globals.ProjectManager.Tokens.Types.Spacings) {
                props.type = AppState.ItemTypes.BOARD.SPACING;
                props.title = 'SELECT SPACE TOKEN';
            }
            else if (this.props.type === Globals.ProjectManager.Tokens.Types.FigmaAutoLayouts) {
                props.type = AppState.ItemTypes.BOARD.FIGMA_AUTOLAYOUT;
                props.title = 'SELECT AUTO LAYOUT TOKEN';
            }
            else if (this.props.type === Globals.ProjectManager.Tokens.Types.Fonts) {
                props.type = AppState.ItemTypes.BOARD.PATTERN_TEXT;
                props.title = 'SELECT TEXT STYLE';
            }
            else if (this.props.type === Globals.ProjectManager.Tokens.Types.Motion) {
                props.type = AppState.ItemTypes.BOARD.DURATION;
                props.title = 'SELECT DURATION';
            }
            else if (this.props.type === Globals.ProjectManager.Tokens.Types.MotionEase) {
                props.type = AppState.ItemTypes.BOARD.EASECURVE;
                props.title = 'SELECT EASE CURVE';
            }
            else if (this.props.type === Globals.ProjectManager.Tokens.Types.Filters) {
                props.type = AppState.ItemTypes.BOARD.FILTER;
                props.title = 'SELECT FILTER';
            }
            else
                return;
            this.props.RefToolbar.current.ShowPanel({
                ...props,
                forAliase : false,
                excludeIds : excludeIds,
                // forAliase : true,
                onSelect : onSelect,
                onPreviewToken : onPreview,
                onClosed : onClosed,
                propName : 'tokenId',
                tokenId : this.aliase.tokenId,
                onRegisterClose : (callClose) => {
                    super.AddCloseCallback(callClose);
                },
                onPreviewNewToken : () => {
                    
                }
            })
        } 
    }
    renderCustom() {
        return (
            <SC.FCol fw fh style={{backgroundColor : SC.CurrentTheme.theme.back, boxSizing : 'border-box'}}>
                <SC.FCol style={{
                    padding : '8px'
                }}>
                    <ItemNameEditor
                        noMargin
                        fontSize='12px'                        
                        name={this.aliase.name}
                        onSaveName={this.SaveName}
                        onGetItems={this.GetItems}
                        model={this.aliase}
                        onSubmit={this.props.onSubmit}
                        readOnly={this.aliase.locked}
                        lockable={{
                            isLocked : this.aliase.locked,
                            onTokenLock : () => {
                                this.aliase.locked = Globals.ProjectManager.Tokens.ToggleTokenLock(this.props.id);                                    
                                this.RCUpdate();
                            }
                        }}
                    />    
                </SC.FCol>                
                {this.renderSelectToken()}
            </SC.FCol>
        )
    }
}