import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppLayout,
    Links,
    Events,
    Strings,
    MetaData,
    Globals
} from '../../../../../../importer';

import styled from 'styled-components';
import {TokenItemBox, TokenItemDragHandle, TokenItemBoxHoveredName} from '../common';
import { SortableList, SortableListItem } from '../../../../../../components/SortableContainer';
import ImageEditor from './item';
import Dropzone from 'react-dropzone';
import DragSource from '../../../../../../components/dragdrop/HoCDragSource';
import BaseTokenList from '../BaseTokenList';
import {Droppable, Draggable} from 'react-beautiful-dnd';
import { StatefulTokenListItemName, StatefulTokenMarker, TokenLockedMarker } from '../colors';
import { GRANT_TYPES } from '../../../../manager';

export default class Images extends BaseTokenList
{
    constructor(props) {
        super(props);

        this.title = 'IMAGES';
        this.moduleName = 'Images';
        this.tokenType = Globals.ProjectManager.Tokens.Types.Images;
        this.hasGroups = true;
        
        this.Ref_ImageEdit = React.createRef();
        this.onEditImage = this.onEditImage.bind(this);
        this.onShowMenu = this.onShowMenu.bind(this);
        this.EditImage = this.EditImage.bind(this);
        this.onDeleteImage = this.onDeleteImage.bind(this);
        this.onCancelAddImage = this.onCancelAddImage.bind(this);
        this.onSubmitNewImage = this.onSubmitNewImage.bind(this);
        this.PreviewChange = this.PreviewChange.bind(this);

        this.onDrop = this.onDrop.bind(this);

        AppLayout.Refs.DesignSystem.Images = this;
        
        super.LoadOptions();
        if (this.expanded || this.props.singleView) {
            this.expanded = true;
            this.Load(this.state.isGroupView);
        }
    }
    componentWillUnmount() {
        super.componentWillUnmount();
        AppLayout.Refs.DesignSystem.Images = null;
    }
    Load(groupView) {        
        this.tokens = GetImageTokenList();        
        if (groupView)
            this.groups = super.GetGroupedTokenList(this.tokens);
    }
    SearchToken(filter) {
        let filterText;
        let filteredCount = 0;
        if (Utils.IsNotNullOrEmpty(filter))
            filterText = filter.toUpperCase();
        Utils.ForEach(this.tokens, (token, i) => {
            delete token.filtered;
            if (filterText) {
                if (token.name.toUpperCase().indexOf(filterText) < 0) {                    
                    token.filtered = true;
                }
            }
        });
        this.RenderId = Utils.Id();
        this.RCThrottledUpdate_1();
    }  
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.filterText !== nextProps.filterText) {
            setTimeout(() => {
                this.SearchToken(nextProps.filterText);
            }, 1);
            return false;
        }
        if (super.shouldComponentUpdate(nextProps, nextState)) {
            return true;
        }
        if (this.props.dragSource !== nextProps.dragSource)
            return true;
        return false;
    }              
    AddTokenToGroup(group) {
        if (!Globals.ProjectManager.CheckGrant(GRANT_TYPES.EDIT_TOKEN.ALL)) {
            return;
        }

        this.AddingToGroup = group;
        this.IsNew = true;
        this.EditImageId = Utils.Id();
        this.EditImageModel = {
            name : 'New Image'
        };
        
        this.props.onPanelOverlay({
            show : true,
            render : (props) => {
                return (
                    <ImageEditor 
                        ref={this.Ref_ImageEdit}
                        newModel={this.EditImageModel}
                        onCancelAddImage={this.onCancelAddImage}
                        onSubmitNewImage={this.onSubmitNewImage}
                    />                 
                )
            }
        })
    }
    onEditImage(id, e) {        
        if (this.props.onSelect) {
            this.props.onSelect(id, e);
            return;
        }
        this.EditImage(id);
    }
    onShowMenu(id, e) {
        this.props.onShowMenu && this.props.onShowMenu(id, e);
    }
    EditImage(id) {                

        if (!Globals.ProjectManager.CheckGrant(GRANT_TYPES.EDIT_TOKEN.ALL)) {
            return;
        }
        
        this.EditImageId = id;
        this.EditInfo = {};
        const localpattern = Utils.Find(this.tokens, (item) => {return item.id === this.EditImageId});
        this.EditInfo.currentValue = localpattern.url;

        if (Globals.ProjectManager.AuditManager) {
            this.WillEditToken = Utils.DeepClone(Globals.ProjectManager.Tokens.Token(id));
        }

        this.props.onPanelOverlay({
            show : true,
            render : ({GlobalStateId}) => {
                return (
                    <ImageEditor 
                        ref={this.Ref_ImageEdit}
                        id={id}
                        onCancelAddImage={this.onSubmitNewImage}
                        onSubmitNewImage={this.onSubmitNewImage}
                        onDeleteImage={this.onDeleteImage}
                        info={this.EditInfo}
                        GlobalStateId={GlobalStateId}
                        onPreviewChange={this.PreviewChange}
                        onClosed={() => {
                            const tokencurrent = Globals.ProjectManager.Tokens.Token(id);
                            
                            if (this.WillEditToken && tokencurrent) { 
                                const diff = Utils.GetFlattenedDiff(this.WillEditToken, tokencurrent);
                                if (diff.length > 0) {

                                    this.Load(this.state.isGroupView);
                                    this.RCUpdate();
                                    Globals.ProjectManager.AuditManager && Globals.ProjectManager.AuditManager.TokenChanged({TokenId : id, Change : {
                                        changes : diff
                                    }});
                                }                                                                        
                            }
                            delete this.WillEditToken;
                        }}
                    />               
                )
            }
        })
    }        
    PreviewChange(show, url) {
        Events.BroadcastThrottle_50(Events.GLOBAL.TOKEN_VALUE_CHANGING, [{
            Id : this.EditImageId,
            Type : Globals.ProjectManager.Tokens.Types.Images,
            value : show ? url : (this.EditInfo ? this.EditInfo.currentValue : null)
        }]);
    }
    onDeleteImage() {
        if (Globals.ProjectManager.Tokens.Delete(Globals.ProjectManager.Tokens.Types.Images, this.EditImageId)) {
            this.Load(this.state.isGroupView);
            this.onCancelAddImage();
        }
    }
    onCancelAddImage() {
        delete this.IsNew;
        delete this.EditImageModel;
        delete this.EditImageId;

        this.props.onPanelOverlay({close : true});
        this.RCUpdate();
    }
    onSubmitNewImage() {
        if (this.IsNew) {
            const newItem = AddOrUpdateImageToken({isNew : true, model : this.EditImageModel, willUploadFile : this.EditImageModel.willUploadFile});
            this.tokens.push(newItem);

            if (this.AddingToGroup) {
                this.AddingToGroup.tokens.push(newItem);
                Globals.ProjectManager.Tokens.AddTokenToGroup({type : this.tokenType, groupid : this.AddingToGroup.id, tokenid : newItem.id})
                delete this.AddingToGroup;
            } 

            Events.BCE(Events.GLOBAL.TOKENS_CHANGED);
        }
        else {
            const token = Globals.ProjectManager.Tokens.Token(this.EditImageId);
            if (token) {
                const localpattern = Utils.Find(this.tokens, (item) => {return item.id === this.EditImageId});
                AddOrUpdateImageToken({isNew : false, willUploadFile : this.EditInfo.willUploadFile, existingItem : localpattern, id : this.EditImageId});
            }            
            delete this.EditInfo;
        }
   
        this.onCancelAddImage();
    }    
    onDrop(files) {
        if (files) {
            Utils.ForEach(files,(file, i) => {
                const MetaImage = {
                    name : file.name
                };
                const Id = Globals.ProjectManager.Tokens.Add({
                    type  :Globals.ProjectManager.Tokens.Types.Images,
                    name : file.name,
                    value : {
                        loading : true,
                        provider : 'File'
                    }
                });
                this.tokens.push({
                    id : Id,
                    name : file.name,
                    loading : true
                })
                this.RCUpdate();
                Globals.ProjectManager.UploadImageFile(Id, file, null, () => {
                    this.Load(this.state.isGroupView);
                    this.RCUpdate();
                });
            });
        }
    }
    renderTokenList(tokens, gorupId) {
        const style = {
            margin : '4px', marginLeft : this.state.isListView ? '10px' : '8px', marginRight : '10px', marginBottom : 0,
            ...SC.Styles.Flex.Column
        } 
        if (!this.state.isListView) {
            style.flexDirection = 'row';
            style.flexWrap = 'wrap';
        }
        return (
            <Droppable 
                droppableId={gorupId}
                type={'TOKENS'}
                direction={this.state.isListView ? 'vertical' : 'horizontal'}
            >
                {
                    (provided, snapshot) => (
                        <div
                            {...provided.droppableProps} 
                            ref={provided.innerRef} 
                            style={{
                                ...provided.droppableProps.style,
                                ...style,
                                border : '1px dashed',                                                    
                                borderColor : snapshot.isDraggingOver ? SC.CurrentTheme.theme.color_brand : 'transparent',                               
                            }}
                        >
                            <ImagesTokenList 
                                dragSource={this.props.dragSource}
                                sortable
                                isListView={this.state.isListView}
                                onSelect={this.onEditImage}
                                onShowMenu={this.onShowMenu}
                                images={tokens}
                            />
                            {provided.placeholder}
                        </div>
                    )
                }
            </Droppable>
        )  
    }
    
    renderCustom() {
        return (
            <Dropzone
                onDrop={this.onDrop}
                ref={(r) => this.Ref_DropZone = r}
                style={{}}
                accept="image/*"
                activeStyle={{}}
                onClick={(e) => {e.preventDefault()}}
                inputProps={{
                    style : {width : '10px'}
                }}
            >
            {({ isDragActive, getRootProps, getInputProps }) => {
                return (
                    <SC.FCol 
                        style={{
                            border : '1px dashed',
                            borderColor :isDragActive ? SC.CurrentTheme.theme.color_brand : 'transparent',
                            flex : 1
                        }}
                        {...getRootProps()}
                    >                
                        {super.renderCustom()}                                
                    </SC.FCol>                    
                )
            }}                
            </Dropzone>            
        )
    }    
}

export const ImageBox = styled.div`
    width : 100%;
    height : 100%;
    background-image : url(${props => props.url});
    background-size : contain;
    background-repeat : no-repeat;
    background-position : center;
    box-sizing : border-box;
    cursor : pointer;
    border-radius : 1px;
    transition : all 0.2s ease;
    background-clip : content-box;
    position : relative;
    &:hover {
        color : white;
        background-color : ${props => props.theme.back_lightest};
    }    
`;

export class ImagesTokenList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    onEditImage(id, e) {
        this.props.onSelect(id, e);
    }
    onShowMenu(id, e) {
        this.props.onShowMenu(id, e);
    }
    PreviewPattern(show, imageId, image) {
        this.WillShowPreview = {
            show : show,
            imageId : imageId
        };
        setTimeout(() => {
            if (this.WillShowPreview) {
                this.props.onPreview && this.props.onPreview(this.WillShowPreview.show, this.WillShowPreview.imageId);    
            }
        }, 200);
    }
    render() { 
        const imageItems = [];
        const {isListView, dragSource, sortable, images, onPreview} = this.props;

        if (isListView) {
            let ListItemType = ImageListItem;
            if (dragSource)
                ListItemType = DraggableListItem;
            
            Utils.ForEach(images, (image, i) => {       
                if (!image.filtered) {
                    const props = {};
                    if (dragSource) {
                        props.DragItem = {
                            Type : Strings.TOOLBARITEM,
                            Item : {
                                MetaItem : {
                                    Type: MetaData.Components.Image.Type,
                                    Tokens : {
                                        Default : {
                                            Default : {
                                                backgroundImage : {
                                                    Id : image.id
                                                }
                                            }
                                        }
                                    }
                                }
                            } 
                        };
                    }
    
                    const BoxProps = {
                        name : image.name,
                        url : image.url,
                        loading : image.loading,
                        selected : this.props.selectedId === image.id,
                        ...props,
                        onMouseEnter : onPreview ? this.PreviewPattern.bind(this, true, image.id, image) : null,
                        onMouseLeave : onPreview ? this.PreviewPattern.bind(this, false, image.id, image) : null,
                        onClick : this.onEditImage.bind(this, image.id),
                        onContextMenu : this.onShowMenu.bind(this, image.id),
                        valueState : image.valueState,
                        locked : image.locked
                    }
    
                    if (sortable) {
                        imageItems.push(                    
                            <SortableListItem
                                key={image.id}
                                draggableId={image.id}
                                index={i}
                                ItemBoxType={ListItemType}
                                isDragDisabled={!this.HasEditGrant}
                                BoxProps={BoxProps}                
                            />  
                        )
                    }
                    else {
                        imageItems.push(
                            <ListItemType                             
                                key={image.id} 
                                {...BoxProps}
                            />
                        )
                    }
                }                
            });
            
        }
        else {
            let ImageType = ImageBox;
            if (dragSource)
                ImageType = DraggableImageBox;
            Utils.ForEach(images, (image, i) => {                    

                if (!image.filtered) {
                    if (image.loading) {
                        imageItems.push(
                            <TokenItemBox style={{display : 'flex', alignItems : 'center', justifyContent : 'center'}}>
                                <SC.Loading_Icon />
                            </TokenItemBox>                            
                        )
                    }
                    else if (Utils.IsNotNullOrEmpty(image.url)) {
                        const props = {};
                        if (dragSource) {
                            props.DragItem = {
                                Type : Strings.TOOLBARITEM,
                                Item : {
                                    MetaItem : {
                                        Type: MetaData.Components.Image.Type,
                                        Tokens : {
                                            Default : {
                                                Default : {
                                                    backgroundImage : {
                                                        Id : image.id
                                                    }
                                                }
                                            }
                                        }
                                    }
                                } 
                            };
                        }
                        
                        imageItems.push(
                            <SC.GridBackground key={image.id} small dark={!SC.CurrentTheme.theme.isLight} style={{position : 'relative'}}>
                                <ImageType                                 
                                    onClick={this.onEditImage.bind(this, image.id)} 
                                    onContextMenu={this.onShowMenu.bind(this, image.id)} 
                                    url={image.url}
                                    title={image.name}
                                    sortable={sortable}
                                    style={{borderLeft : this.props.selectedId === image.id ? '2px solid' : 'none', borderLeftColor : SC.CurrentTheme.theme.color_brand}}
                                    {...props}
                                    onMouseEnter={onPreview ? this.PreviewPattern.bind(this, true, image.id, image) : null}
                                    onMouseLeave={onPreview ? this.PreviewPattern.bind(this, false, image.id, image) : null}
                                />
                                {
                                    !isListView && image.valueState && 
                                    <StatefulTokenMarker 
                                        style={{
                                            position : 'absolute',
                                            top : '4px',
                                            left : '4px'
                                        }}
                                        {...image.valueState}
                                    />
                                }
                                {
                                    !isListView && image.locked && 
                                    <TokenLockedMarker overlay />
                                }
                            </SC.GridBackground>
                            
                        )                     
                    }    
                    else {
                        imageItems.push(
                            <button 
                                key={image.id} 
                                onClick={this.onEditImage.bind(this, image.id)} 
                                onContextMenu={this.onShowMenu.bind(this, image.id)} 
                            />
                        )
                    }    
                }                            
            });
            
        }

        const ContainerProps = {
            style : isListView ? 
            {
                padding : '4px', paddingLeft  :'8px', paddingRight : '8px',
                transition : 'all 0.2s ease',
                ...this.props.style
            } :
            {
                display : 'grid',
                gridGap : '4px',
                gridTemplateColumns : sortable ? 'repeat(auto-fill, minmax(60px, 1fr))' : '1fr',
                gridAutoRows : sortable ? '60px' : '100px',
                transition : 'all 0.2s ease',
                flex : 1,
                ...this.props.style
            },
            className : isListView ? '' : (images.length > 0 ? 'cssgrid' : '')
        }

        
        if (sortable && isListView) {
            return imageItems;
        }
        return (
            <SC.FCol {...ContainerProps}>
                {imageItems}
            </SC.FCol>
        )
    }
}
 
export const ImageListItem = ({name, url, loading, sortableProps, valueState, selected, locked, ...rest}) => {
    const style_box = {
        padding : 0,
        paddingLeft : '6px',
        ...SC.Styles.Flex.RowAlcJsb        
    };
    if (selected) {
        style_box.borderLeft = '1px solid';
        style_box.borderLeftColor = SC.CurrentTheme.theme.color_brand;
    }

    return (
        <TokenItemBox {...rest} style={style_box} selected={selected} >
           <SC.FRow f1 alc overflowHidden>
                <StatefulTokenListItemName 
                    name={name}
                    sortableProps={sortableProps}
                    ValueState={valueState}
                    locked={locked}
                /> 
            </SC.FRow>           
            <SC.GridBackground small dark={!SC.CurrentTheme.theme.isLight} style={{margin : '2px', borderRadius : '2px', overflow : 'hidden'}}>
                {
                    loading ? <SC.Loading_Icon /> : <ImageBox url={url} draggable={false} style={{width : '96px', height : '60px'}} />
                }                     
            </SC.GridBackground> 
        </TokenItemBox>
    )
}

const DraggableImageBox = DragSource(ImageBox);
const DraggableListItem = DragSource(ImageListItem);

export const GetImageTokenList = (StateArray) => {
    const tokenids = Globals.ProjectManager.Tokens.Images();
    const tokens = Globals.ProjectManager.Tokens.TokenList(Globals.ProjectManager.Tokens.Types.Images);
    const images = [];

    Utils.ForEach(tokenids, (id, i) => {
        const token = tokens[id];
        if (token) {
            const info = {};
            const tokenvalue = Globals.ProjectManager.Tokens.ValueOf({model : token, statearray : StateArray, info : info}) || {};
            images.push({
                id : id,
                url : tokenvalue.url,
                loading : tokenvalue.loading,
                name : token.name,
                valueState : info.ValueState,
                locked : token.locked
            })
        }
    });

    return images;
}

export const AddOrUpdateImageToken = ({isNew, newId, model, id, existingItem, willUploadFile}) => {
    if (isNew) {
        const useNewId = Globals.ProjectManager.Tokens.Add({
            type : Globals.ProjectManager.Tokens.Types.Images,
            name : model.name,
            id : newId,
            value : model.value                 
        }); 
        if (model.willUploadFile)
            Globals.ProjectManager.UploadImageFile(useNewId, model.willUploadFile);

        return {
            id : useNewId,
            url : model.value.url,
            name : model.name
        };
    }        
    else {            
        const token = Globals.ProjectManager.Tokens.Token(id);
        if (token) {
            const tokenvalue = Globals.ProjectManager.Tokens.ValueOf({model : token});
            
            if (existingItem) {
                existingItem.name = token.name;
                existingItem.url = tokenvalue.url;
            }
                        
            if (willUploadFile) {                
                if (tokenvalue && tokenvalue.UrlId)
                    Globals.ProjectManager.DataManager.Storage.Delete(tokenvalue.UrlId);
                Globals.ProjectManager.UploadImageFile(id, willUploadFile);                                
            }
            else {
                if (tokenvalue && tokenvalue.provider !== 'File' && tokenvalue.UrlId)
                    Globals.ProjectManager.DataManager.Storage.Delete(tokenvalue.UrlId);
            }
            Events.BCE(Events.GLOBAL.TOKEN_VALUE_CHANGING);
            if (!existingItem) {
                return {
                    id : id,
                    url : model.value.url,
                    name : model.name
                };
            }
        }              
    }
}