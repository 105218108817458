import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    AppLayout,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../../../../importer';
import { SearchInput } from '../../../../searchbar';
import UnsplashGallery from '../../../../../../integrations/unsplash';

export default class SelectDocumentImage extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.onSearch = this.onSearch.bind(this);        
        this.CanceSearch = this.CanceSearch.bind(this);        
        this.SetlectImage = this.SetlectImage.bind(this);
    }
    
    SetlectImage(img) {
        if (this.props.heroImage) {
            Globals.ProjectManager.GetDocumentManager().SetHeroProp('url', img.url);
            Globals.ProjectManager.GetDocumentManager().SetHeroProp('source', 'unsplash');
            Globals.ProjectManager.GetDocumentManager().DeleteHeroStorageImage();            
        }
        else {
            Globals.ProjectManager.GetDocumentManager().SetItemValue(this.props.id, img.url, this.props.propName || 'url');
            Globals.ProjectManager.GetDocumentManager().SetItemValue(this.props.id, 'unsplash', 'source');
            const storageId = Globals.ProjectManager.GetDocumentManager().GetPageItemValue(this.props.id, this.props.pageId, null, 'storageId');
            if (storageId) {
                Globals.ProjectManager.DeleteStorageImage(storageId);
                Globals.ProjectManager.GetDocumentManager().DeleteItemValue(this.props.id, 'storageId');
            }
        }            

        this.props.onUpdate();
    }
    onSearch(filterText) {
        this.setState({FilterText : filterText});
    }
    CanceSearch() {
        this.setState({FilterText : null});
    }
    renderCustom() {
        return (
            <SC.FCol style={{
                marginTop : '24px',
                marginBottom : '24px',
                lineHeight : 'unset',
                ...SC.Styles.Font()
            }}>
                <SC.FRow alc jsb>
                    <SC.BackClosableTitle title='UNSPLASH GALLERY' onClose={this.props.onClose} />    
                    <SearchInput
                        FilterText={this.state.FilterText}
                        Search={this.onSearch}
                        CancelSearch={this.CanceSearch}
                    /> 
                </SC.FRow>
                
                <div style={{
                    minHeight : '600px',
                    height : '600px',
                    marginTop : '16px'
                }}>
                    <UnsplashGallery
                        filterText={this.state.FilterText}
                        options={{
                            single : true,
                            onSelect : this.SetlectImage
                        }} 
                    />
                </div>
            </SC.FCol>
        )
    }
}