import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    UIUtils
} from '../../importer';
import styled, {css} from 'styled-components';

const CheckBox = styled.div`
    width : ${props => props.large ? 24 : 20}px;
    aspect-ratio : 1 / 1;
    background-color : ${props => props.theme.input_back};
    border : ${props => props.theme.border_seperator};
    display : flex;
    justify-content : center;
    align-items : center;
    cursor : pointer;
    box-sizing : border-box;
    border-radius : 2px;
`;

export default class Switch extends ReactBaseComponent
{
    constructor(props) {
        super(props);
        this.Toggle = this.Toggle.bind(this);
        this.Checked = Utils.IsTrue(this.props.value);
    }
    shouldComponentUpdate(nextProps, nextState) {
        this.Checked = Utils.IsTrue(nextProps.value);
        return true;
        // if (Utils.HasAnyChange(this.props, nextProps, 'value', 'readOnly', 'checkEdit', 'round', 'title', 'size', 'small', 'light'))
        //     return true;
        // return super.ShouldUpdate(nextProps, nextState);
    }
    Toggle(e) {
        e.stopPropagation();
        if (this.props.readOnly)
            return;
        this.Checked = !this.Checked;
        this.props.onChange && this.props.onChange(this.Checked);
        this.RCUpdate();
    }
    renderCustom() {       
        if (this.props.checkEdit) {
            return (
                <CheckBox large={this.props.large} checked={this.Checked} onClick={this.Toggle} >
                {
                    this.Checked ? 
                    <SC.Svg width={this.props.large ? 12 : 10} viewBox="0 0 6 5">
                        <path fill="#0096ea" d="M.78 2L0 2.85l2 2c.24.23.62.2.83-.07L6 .7 5.1 0 2.3 3.6.8 2z"/>
                    </SC.Svg> : null
                }
                </CheckBox>
            )
        }
        else if (this.props.round) {
            return (
                <div style={{display : 'flex', cursor:'pointer', ...this.props.style}} title={this.props.title} onMouseDown={this.Toggle}>
                    <SC.Svg height={this.props.size || 14} viewBox="0 0 28 14">
                        <g transform="translate(-483 -402)">
                            <path fill={this.Checked ? SC.CurrentTheme.theme.switch_round_back : SC.CurrentTheme.theme.switch_round_back_unchecked} d="M504 402h-14a7 7 0 1 0 0 14h14a7 7 0 0 0 0-14z"/>
                            <RoundSwitch_Circle checked={this.Checked} cx={this.Checked ? 18 : 5} cy="5" r="5" fill="#fff" transform="translate(485 404)"/>
                        </g>
                    </SC.Svg>                
                </div>
            );
        }
        return (
            <div style={{display : 'flex', cursor:'pointer'}} title={this.props.title}  onMouseDown={this.Toggle}>
                <SC.Svg  width={this.props.small ? 26 : 32} height={16} viewBox="0 0 32 16" >
                    <path fill={SC.CurrentTheme.theme.isLight ? '#cccccc' : "#414246"} d="M0 7h32v2H0z"/>
                    <Switch_Circle checked={this.Checked} cy="8" cx={this.Checked ? 24 : 8} r={this.props.size || 8} light={this.props.light} />
                </SC.Svg>                
            </div>
        )       
    }
}
const RoundSwitch_Circle = styled.circle`
    transition : 0.2s ease-in-out;
    transition-property : cx, fill;
    cx : ${props => props.checked ? 18 : 5};
    fill : ${props => props.checked ? props.theme.switch_round_thumb_checked : props.theme.switch_round_thumb};
`;
const Switch_Circle = styled.circle`
    transition : 0.2s ease-in-out;
    transition-property : cx, fill;
    cx : ${props => props.checked ? 24 : 8};
    fill : ${props => props.checked ? (props.light ? '#a4a4a4' : '#0096ea') : (props.theme.isLight ? '#b3b3b3' : '#555555')};
`;