import React from 'react';
import ReactDOM from 'react-dom';

import {
    MetaData,
    Utils,
    UIUtils,
    SC,
    Strings,
    AppLayout
} from '../../../../../../../importer';

import BaseGeneric from '../base';
import { onGetChildItem } from '../../common';

export default class Generic_DateInput extends BaseGeneric {
    constructor(props) {
        super(props);        
    }
    renderContent({style, props}) {      
        
        let type = this.renderData.props.datetype || 'date';
        return (
            <input 
                style={style} 
                type={type}
                {...props}
            >                
            </input>
        );
    }
}