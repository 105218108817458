import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    UIUtils,
    Utils,
    AppState,
    Strings
} from '../../importer';

import styled, {css} from 'styled-components';
import { motion } from 'framer-motion';

const BS = SC.Board_Styles;

const Style_Popup = styled.div`
    position : absolute;
    ${
        props => {
            if (props.toRight) {
                return css`
                        left : 0;
                    `;
            }
            else {
                return css`
                        right : 0;
                    `;
            }
        }
    }
    z-index : 1000000;
    border : ${props => props.focusedColor || props.theme.border_popup};
    border-radius : 2px;
    box-sizing : border-box;
    background-color : ${props => props.light ? props.theme.dropdown_back : props.theme.dropdown_back_dark};
    box-shadow : ${props => props.theme.popup_shadow};    
    ${
        props => {
            if (props.autoHeight) {
                return css`
                `;
            }     
            return css`
                height : ${props => props.height || '200px'};
            `;       
        }
    }
    ${
        props => {
            if (props.positionTopUp)
                return css`bottom : 100%; margin-bottom : 4px;`;
            return css`top : 100%; margin-top : ${props => props.margin || '2'}px;`;
        }
    }
`;

const StyleInput = styled.input`
    border : none;
    background-color : transparent;
    width : 100%;
    color : #D1D1D1;
    height : 30px;
`;



class HoC_PopUp extends ReactBaseComponent
{
    constructor(props) {
        super(props);
        this.Search = this.Search.bind(this);        
        this.FilterText = '';

        this.Cursor = 0;    
    }
    componentDidMount() {
        super.componentDidMount();
        if (this.props.hasSearch) {
            // setTimeout(() => {
            //     this.Ref_Search && this.Ref_Search.focus();
            // }, 10);
        }        
        
        let DN = ReactDOM.findDOMNode(this);
        if (DN) {
            const bounds = DN.getBoundingClientRect();            
            if (bounds.bottom > document.documentElement.clientHeight) {
                if (!this.props.noUpwardPopup)
                    this.positionTopUp = true;
                else
                    this.hasMaxHeight = document.documentElement.clientHeight - bounds.top - 40;
                this.RCUpdate();
            }
        }
    }
    Select(Item, e) {
        e && e.stopPropagation();
        let value = Item ? Item.id : null;
        if (value === 'null')
            value = null;
        this.props.onSelect && this.props.onSelect(value, Item);
        this.props.onClose();
    }
    MoveCursor(isDown) {
        this.Cursor = ((this.Cursor || 0) + (isDown ? 1 : -1)) % this.props.items.length;
        const target = this.refs[`Ref_Item_${this.Cursor}`];
        if (this.Ref_Scroll && target) {
            this.Ref_Scroll.scrollTop(ReactDOM.findDOMNode(target).offsetTop);
        }
        this.RCUpdate();
    }
    SelectCursor() {
        if (this.Cursor < this.props.items.length && this.Cursor > -1) {
            this.Select(this.props.items[this.Cursor].id, this.props.items[this.Cursor]);
        }
    }
    Search(value) {
        this.FilterText = value;
        this.RCUpdate();
    }
    onHoverItem(id) {
        this.props.onHoverItem(id);
    }
    renderCustom() {        
        const {items, value} = this.props;
        let useTheme = this.props.useTheme || SC.CurrentTheme.theme;
        let content;

        if (this.props.getCustomContent) {
            content = this.props.getCustomContent();
        }
        else {
            let SelectItems = [];
            let cursor = 0;
            Utils.ForEach(items,(Item) => {
                if (!Item.hiddenInPopup) {
                    if (Item.hasSeperator) {
                        SelectItems.push(
                            <div style={{
                                borderTop : useTheme.border_seperator,
                                marginTop : '4px',
                                marginBottom : '4px'
                            }} />
                        )
                    }

                    if (Item.notSelectable) {
                        let itemContent = Item.Label;
                        if (Item.renderItem)
                            itemContent = Item.renderItem();
                        SelectItems.push(itemContent);
                    }
                    else if (!this.FilterText || !this.props.hasSearch || Item.label && Item.label.toUpperCase().indexOf(this.FilterText.toUpperCase()) > -1) {
                        const isSelected = Item.id === this.props.value;
                        let itemContent = null;
                        if (Item.renderItem)
                            itemContent = Item.renderItem();
                        else if (this.props.renderItem)
                            itemContent = this.props.renderItem(Item, Item.id === this.props.value);
                        if (!itemContent) {
                            itemContent = Item.label || Item.id;
                            if (isSelected) {
                                itemContent = (
                                    <SC.FRow alc jsb>                                    
                                        {itemContent}
                                        <SC.Icons.Icon_Button hasFill fill={useTheme.color_brand} style={{marginLeft : '4px'}}><SC.Icons.Icon_Check size={20} /></SC.Icons.Icon_Button>
                                    </SC.FRow>
                                )
                            }                                                
                        }
                        SelectItems.push(
                            <SC.PopupItem
                                ref={'Ref_Item_' + cursor} 
                                key={Item.id} 
                                onMouseUp={this.Select.bind(this, Item)} 
                                onMouseOver={this.props.onHoverItem ? this.onHoverItem.bind(this, Item.id) : null}
                                style={this.props.popupItemStyle}
                                light={this.props.light}
                                selected={isSelected}
                                largeText={this.props.largeText}
                                theme={useTheme}
                            >
                                {itemContent}
                            </SC.PopupItem>
                        );
                        
                        cursor++;
                    }   
                }
                            
            });
            if (SelectItems.length === 0) {
                if (this.props.emptyPlaceholder) {
                    SelectItems = (
                        <SC.Div_Flex_Cell pa={8}>
                            {this.props.emptyPlaceholder}
                        </SC.Div_Flex_Cell>
                    )
                }
            }
            if (this.Cursor >= SelectItems.length)
                this.Cursor = 0;
                
            content = (
                <React.Fragment>
                    {
                        this.props.hasSearch ? 
                        <SC.FRow alignStretch style={{backgroundColor:'#27282B'}} ml={1}>
                            <SC.Div_Flex_Cell pl={5} mr={5}>
                                <BS.Icon_Search size='14'/>
                            </SC.Div_Flex_Cell>     
                            <UIUtils.Utils.SearchInput                         
                                ref={(r) => this.Ref_Search = r}
                                value={this.FilterText} 
                                onSearch={this.Search} 
                                placeholder='Search'
                                InputType={StyleInput}
                                onDown={this.MoveCursor.bind(this, true)}
                                onUp={this.MoveCursor.bind(this, false)}
                                onSubmit={() => this.SelectCursor()}
                            />
                        </SC.FRow> : null
                    }                    
                    <SC.FCol f1>
                    {
                        this.props.autoHeight && !this.hasMaxHeight ? SelectItems : 
                        <SC.CustomScrollbars notAutoHide hideTracksWhenNotNeeded ref={(r) => this.Ref_Scroll = r} >
                            <SC.FCol>
                                {SelectItems}
                            </SC.FCol>
                        </SC.CustomScrollbars>
                    }                        
                    </SC.FCol>                  
                    {this.props.renderPopupFooter && this.props.renderPopupFooter()}     
                </React.Fragment>
            )
        }

        
        const style = {
            position : 'absolute',
            left : this.props.toRight ? 0 : 'unset',
            right : this.props.toRight ? 'unset' : 0,
            zIndex : 100000            
        };
        if (this.positionTopUp) {
            style.bottom = '100%';
            style.marginBottom = '4px';
        }
        else {
            style.top = '100%';
            style.marginTop = Utils.px(this.props.margin || 2)
        }
        if (!this.props.fitWidth) {
            if (this.props.toRight) {
                style.right = 0;
                style.left = '-20%';
            }
            else
                style.left = this.props.left || 0;
        }
        else {
            style.right = this.props.right || 0;
            style.left = this.props.left || 0;
        }
        if (this.props.fullwidth)
            style.minWidth = '100%';

        return (
            <motion.div 
                onMouseLeave={this.props.onClosed}                
                className={this.props.className}                
                style={{...style, ...this.props.popupStyle}}
                id='DropDownPopup'
                initial={{y : this.props.popupFromDown ? 10 : -10}}
                animate={{y : 0}}
                exit={{y : this.props.popupFromDown ? 4 : -4}}
                transition={{duration : 0.2}}
            >
                <SC.FCol fw fh style={{
                    width : 'auto',
                    border : useTheme.border_popup,
                    borderRadius : '2px',
                    boxSizing : 'border-box',
                    backgroundColor : this.props.light ? useTheme.dropdown_back : useTheme.dropdown_back_dark,
                    boxShadow : useTheme.popup_shadow,
                    height : this.hasMaxHeight ? Utils.px(this.hasMaxHeight) : (this.props.autoHeight ? 'unset' : (this.props.height || '200px')),
                    ...this.props.popupInnerStyle
                }}>
                    {content}
                </SC.FCol>                
            </motion.div>
        )        
    }

}

export default UIUtils.OutSideClickHandler(HoC_PopUp);