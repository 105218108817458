import React from 'react';
import {
    SC,
    Globals,
    Utils,
} from '../../../../../../../importer';

import AppLayout from '../../../../../../../appstate/AppLayout';
import BaseSelectToken from '../BaseSelectToken';
import { AddNewFigmaLayoutToken, FigmaLayoutListItem, GetFigmaLayoutTokenList } from '../../../../../../../apps/figmaplugin/designsystem/autolayouts';
import FigmaLayoutTokenEditor from '../../../../../../../apps/figmaplugin/designsystem/autolayouts/item';
import { GetAliaseTokenItem, GetTokenAliaseList } from '../../../../left/designsystem/aliases/aliaseGroup';
import { GroupTitle } from '../../../../left/designsystem/common';
import { ListCardView } from '../../../../../../../components/light_dark_bar';

export default class SelectFigmaAutoLayoutToken extends BaseSelectToken
{
    constructor(props) {
        super(props);

        this.titleNewToken = 'NEW AUTO LAYOUT';
        this.moduleName = 'FigmaAutoLayouts';
        this.tokenType = Globals.ProjectManager.Tokens.Types.FigmaAutoLayouts;        
        this.hasListMode = false;

        this.hasAliases = !this.props.forAliase;

        this.Load(this.props);
    }
    IsTokenPropChanged(nextProps, nextState) {
        if (Utils.HasAnyChange(this.props, nextProps, 'GlobalState')) {
            this.Load(nextProps);
            return true;
        }        
    }
    GetTokens(props) {
        this.aliases = GetTokenAliaseList(Globals.ProjectManager.Tokens.Types.FigmaAutoLayouts);
        if (this.props.excludeIds) {
            this.props.excludeIds.map((excludeId) => {
                Utils.Remove(this.aliases, (item) => {return item.id === excludeId});
            })            
        }

        this.aliases.map((aliase) => {
            aliase.tokenItem = GetAliaseTokenItem(aliase, Globals.ProjectManager.Tokens.Types.FigmaAutoLayouts);
        })

        return GetFigmaLayoutTokenList(props.StateArray);
    }            
    GetNewModel(Id) {
        const Model = {
            name : 'New Auto Layout'
        };
        AddNewFigmaLayoutToken(Model, Id);
        this.FireSelectToken(Id);
        return Model;
    }
    SaveToken() {
        if (this.EditingToken.IsNew) {
            const transformItem = AddNewFigmaLayoutToken(this.EditingToken.Model, this.EditingToken.Id);
            AppLayout.Refs.DesignSystem.FigmaAutoLayouts && AppLayout.Refs.DesignSystem.FigmaAutoLayouts.Reload();
            return transformItem;
        }        
        else {
        }                
    }  
    onCloseTokenEditor() {
        if (this.EditingToken && this.EditingToken.IsNew) {
            delete this.EditingToken;            
            this.RCUpdate();
            return;
        }
        super.onCloseTokenEditor();
    }
    onCancelAddToken() {
        const willDeleteId = this.EditingToken.Id;        
        this.FireSelectToken(this.LastBoundTokenId);
        delete this.LastBoundTokenId;
        super.onCancelAddToken();
        Globals.ProjectManager.Tokens.Delete(Globals.ProjectManager.Tokens.Types.FigmaAutoLayouts, willDeleteId);
    } 
    UpdateSelectedToken() {
        if (this.EditingToken) {
            const SelectedToken = Utils.Find(this.tokens, (token) => {return token.id === this.EditingToken.Id});
            const token = Globals.ProjectManager.Tokens.Token(this.EditingToken.Id);
            const transform = Globals.ProjectManager.Tokens.ValueOf({model : token, statearray : this.props.StateArray});
        }
    }
    GetStyleName() {
        return 'tokenId';
    }
    renderTokenAliaseListHeader() {
        return (
            <GroupTitle 
                title='SEMANTIC TOKENS' 
                hasSearch={{onSearch : this.SearchAliase}}
                hasAdd={{onAdd : this.AddTokenAlias}}
                addOn={(
                    <SC.FRow>
                        <ListCardView 
                            onListView={this.SetAliaseListMode.bind(this, true)}
                            onCardView={this.SetAliaseListMode.bind(this, false)}
                            isListView={this.state.isAliaseListView}
                            isCardView={!this.state.isAliaseListView}
                            style={{marginRight : '4px'}}
                            compact
                        />
                    </SC.FRow>                    
                )}
                style={{padding : 0, paddingLeft : '4px', marginBottom : '4px'}}
            />
        )
    }

    renderTokenAliases() {
        const items = [];

        Utils.ForEach(this.aliases, (FigmaLayout, i) => {
            if (!FigmaLayout || FigmaLayout && FigmaLayout.filtered)
                return null;
            items.push(
                <FigmaLayoutListItem
                    key={FigmaLayout.id}
                    notDraggable
                    name={FigmaLayout.name}
                    FigmaLayout={FigmaLayout.tokenItem}
                    onSelect={this.SelectToken.bind(this, FigmaLayout.id)}
                    selected={FigmaLayout.id === this.GetSelectedTokenId()}
                />
            )
        });

        return (
            <SC.FCol>
                {items}
            </SC.FCol>
        )   
    }
    renderTokenList() {
        const items = [];

        Utils.ForEach(this.tokens, (FigmaLayout, i) => {
            if (!FigmaLayout || FigmaLayout && FigmaLayout.filtered)
                return null;
            items.push(
                <FigmaLayoutListItem
                    key={FigmaLayout.id}
                    notDraggable
                    FigmaLayout={FigmaLayout}
                    onSelect={this.SelectToken.bind(this, FigmaLayout.id)}
                    selected={FigmaLayout.id === this.GetSelectedTokenId()}
                />
            )
        });

        return (
            <SC.FCol>
                {items}
            </SC.FCol>
        )
    }
    renderTokenEditor() {
        return (
            <FigmaLayoutTokenEditor 
                id={this.EditingToken.Id}
                newModel={this.EditingToken.IsNew ?  this.EditingToken.Model : null}  
                GlobalState={Globals.ProjectManager.GlobalState}                      
                offline={this.EditingToken.IsNew}
            />                 
        )        
    }
    GetSelectedTokenId() {
        return this.props.tokenId;
    }
    renderSelectedToken() {
        if (this.GetSelectedTokenId()) {
            return this.renderSelectedTokenHeader();
        }
    }
}
