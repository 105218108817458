import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    UIUtils,
    AppState
} from '../../importer';

import styled, {css} from 'styled-components';

const NoteArea = styled.div`
    background-color : ${props => props.theme.back};
    padding : 8px;
    flex : 1;
    font-size : 12px;
    border-radius : 4px;
    overflow : hidden;
    margin-top : 4px;
    min-height : 60px;
    display : flex;
    box-sizing : border-box;
    border : 1px solid ${props => props.focused ? props.theme.border_selected : props.theme.border_ondark};
`;

class Input_Note extends ReactBaseComponent
{
    constructor(props) {
        super(props);
        this.onFcoused = this.onFcoused.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.value = Utils.UseNullOrEmpty(this.props.value, '');
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.value !== this.props.value)
            this.value = nextProps.value;
        return true;
    }
    onFcoused() {
        this.IsFocused = true;
        AppState.Designer.SetEditingText('input_note');;
        this.RCUpdate();
    }
    onBlur(value) {
        this.value = value;
        this.IsFocused = false;
        AppState.Designer.EditingText = false;
        this.RCUpdate();
        this.props.onChange && this.props.onChange(value);
    }
    renderCustom() {
        let expand = null;
        const wrap_style = {};
        const expandprops = {};
        if (this.props.expandable && this.props.wrap) {
            expandprops.onClick = () => {this.Expanded = !this.Expanded; this.RCUpdate()};
            expandprops.cursorPointer = true;
            expand = (
                <SC.Icons.Icon_Box noSeperator>
                    <SC.Icons.Icon_Arrow expanded={this.Expanded} />
                </SC.Icons.Icon_Box>
            )
            if (!this.Expanded)
                wrap_style.minHeight = 'unset';
        }
        let note_item = (!this.props.expandable || this.Expanded) ?
        <UIUtils.Utils.ContentEditable
            html={`<span>${Utils.UseNull(this.value, '')}</span>`}
            style={{flex : 1}}
            disabled={false}
            onBlur={this.onBlur}
            onChange={this.onChanging}
            onFocus={this.onFcoused}
            plainText
        /> : null;

        let item;

        if (this.props.noIcon)
            item = note_item;
        else {
            item = (
                <SC.FCol f1>
                    <SC.FRow alc jsb mb={(!this.props.expandable || this.Expanded) ? 8 : 0} {...expandprops} >
                        <SC.FRow>
                            <SC.Icons.Icon_Note />
                            <SC.Div_Styled ml={8}>
                                Description
                            </SC.Div_Styled>
                        </SC.FRow>
                        {expand}
                    </SC.FRow>
                    {
                        note_item
                    }
                </SC.FCol>
            )
        }
        if (this.props.wrap) {
            return (
                <NoteArea style={wrap_style} focused={this.IsFocused}>
                    {item}
                </NoteArea>
            )
        }
        return item;
    }
}

export default Input_Note;
