import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    AppState,
    Events,
    SC,
    Loading,
    Globals,
    Strings,
    Links,
    Utils,
    UIUtils,
    MetaData
} from '../../../../../../../importer';

import AppLayout from '../../../../../../../appstate/AppLayout';
import BaseSelectToken from '../BaseSelectToken';
import { GetTextContentTokenList, TextContentListItem } from '../../../../left/designsystem/textContent';
import TextContentEditor from '../../../../left/designsystem/textContent/item';

export default class SelectTextContent extends BaseSelectToken
{
    constructor(props) {
        super(props);

        this.titleNewToken = 'NEW CONTENT';
        this.moduleName = 'TextContents';
        this.tokenType = Globals.ProjectManager.Tokens.Types.ContentTexts;        

        this.Ref_PatternEdit = React.createRef();

        this.Load(this.props);
    }
    IsTokenPropChanged(nextProps, nextState) {
        return this.props.textContent !== nextProps.textContent;
    }
    GetTokens(props) {
        return GetTextContentTokenList();
    }        
    GetNewModel() {
        return {
            name : 'New Content'
        }
    }
    SaveToken() {        
        if (this.EditingToken.IsNew) {
            const item = {
                type : Globals.ProjectManager.Tokens.Types.ContentTexts,
                name : this.EditingToken.Model.name,
                value : this.Ref_PatternEdit.current.tokenvalue,
                id : this.EditingToken.Id
            };
            Globals.ProjectManager.Tokens.Add(item);
            this.Load();            
            AppLayout.Refs.DesignSystem.TextContents && AppLayout.Refs.DesignSystem.TextContents.Reload();
            return item;
        }
        else {

        }
        AppLayout.Refs.DesignSystem.ContentTexts && AppLayout.Refs.DesignSystem.Icons.Reload();
    }   
    GetStyleName() {
        return 'textContent';
    }    
    renderTokenList() {
        return (
            <SC.CustomScrollbars hideTracksWhenNotNeeded autoHide>
                <SC.FCol>
                    {
                        this.tokens.map((token, i) => {
                            return (
                                <TextContentListItem 
                                    key={token.id}
                                    value={token.value}
                                    name={token.name}
                                    isListView={this.state.isListView}
                                    onClick={this.SelectToken.bind(this, token.id)}
                                    onMouseEnter={this.PreviewToken.bind(this, true, token.id, 'textContent')}
                                    onMouseLeave={this.PreviewToken.bind(this, false, token.id, 'textContent')}
                                />
                            )
                        })
                    }                    
                </SC.FCol>
            </SC.CustomScrollbars> 
        )
    }
    renderTokenEditor() {
        return (
            <TextContentEditor 
                ref={this.Ref_PatternEdit}
                newModel={this.EditingToken.IsNew ?  this.EditingToken.Model : null}  
                onCancelAddIcon={this.onCancelAddToken}
                onSubmitNewIcon={this.onSubmitNewPattern}
                onPreviewChange={this.EditingToken.IsNew ? this.PreviewNewToken : null}
                defaultValue={this.props.defaultValue}
                onRightPanel
                noHeader
            />
        )        
    }
    renderSelectedToken() {
        if (this.props.textContent) {
            return this.renderSelectedTokenHeader();
        }
    }
}