import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    AppLayout,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../../../importer';

import {DOCUMENT_ITEMTYPES, DOCUMENT_TOKENTYPES} from '../../../manager/documents';
import { motion } from 'framer-motion';
import TokenSettings from './tokens';
import ComponentSettings from './component';
import FigmaComponentSettings from './figmacomponent';
import SelectColorToken from '../../../panels/right/iteminspector/selectToken/color';

import DocumentStyleEditor from '../styleeditor';
import SelectDocumentColor from '../styleeditor/colorEditor';
import DocumentTabItemSettings from './tabs';
import { DocWidgetIcon_ButtonBox } from '../widget';

export default class DocumentItemPanel extends ReactBaseComponent
{
    constructor(props) {
        super(props);

    }    
    renderCustom() {
        let content;
        let style;
        if (this.props.showItemSettings) {
            const itemId = this.props.showItemSettings.itemId
            const subItemId = this.props.showItemSettings.subItemId
            
            
            if (itemId) {
                const useItemId = subItemId || itemId;
                const data = Globals.ProjectManager.GetDocumentManager().GetItem(useItemId);

                if (data.type === DOCUMENT_ITEMTYPES.token) {                                        
                    content = (
                        <TokenSettings 
                            {...this.props}
                            id={useItemId}                            
                        />
                    )                    
                }
                else if (data.type === DOCUMENT_ITEMTYPES.component) {                                        
                    content = (
                        <ComponentSettings
                            {...this.props}
                            id={useItemId}                            
                        />
                    )                    
                }
                else if (data.type === DOCUMENT_ITEMTYPES.figmaComponent) {                                        
                    content = (
                        <FigmaComponentSettings
                            {...this.props}
                            id={useItemId}                            
                        />
                    )                    
                }
                else if (data.type === DOCUMENT_ITEMTYPES.text) {                                        
                    content = (
                        <SelectDocumentColor 
                            color={this.props.showItemSettings.color}
                            tokenId={this.props.showItemSettings.tokenId}
                            onClose={this.props.onClose}
                            onChange={() => {
                                
                            }}
                        />
                    )                    
                }
                else if (data.type === DOCUMENT_ITEMTYPES.accessibility) {                                        
                    content = (
                        <SelectColorToken 
                            title={this.props.showItemSettings.props.title || 'SELECT COLOR'}
                            tokenId={this.props.showItemSettings.props.tokenId}
                            propName='tokenId'
                            isForText={this.props.showItemSettings.props.forText}
                            onBackgroundColor={this.props.showItemSettings.props.backgroundColor}
                            hasAccessibility={this.props.showItemSettings.props.forText}
                            onSelect={(tokenId) => {
                                this.props.showItemSettings.props.onSelect(tokenId);
                            }}
                            onClose={this.props.onClose}
                        />
                    )                    
                }
                else if (data.type === DOCUMENT_ITEMTYPES.tab) {                                        
                    content = (
                        <DocumentTabItemSettings                             
                            onClose={this.props.onClose}
                            {...this.props}
                            id={useItemId}    
                        />
                    )                    
                }
                else {
        
                }
            }
            else  {
                content = (
                    <DocumentStyleEditor 
                        {...this.props}
                    />
                )                
            }            
        }
        else {
            
            content =(
                <SC.FRow alc onClick={this.props.onStyleEditor} style={{cursor : 'pointer'}}>
                    <div>Style Editor</div>
                    <DocWidgetIcon_ButtonBox hasFill style={{ width : '28px', height : '28px', marginLeft : '8px'}}>
                        <SC.Icons.Icon_SliderSettings size={24} />
                    </DocWidgetIcon_ButtonBox>
                </SC.FRow>
            )
        }
        

        return (
            <RightInnerPanelContainer expanded={this.props.showItemSettings ? true : false}>
                {content}
            </RightInnerPanelContainer>
        )
    }
}

export const RightInnerPanelContainer = (props) => {
    let style;
    if (props.expanded) {
        style = {
            bottom  :'8px',
            borderRadius : '4px',
            width : '280px',                
        };
    }
    else {
        style = {
            paddingLeft : '8px',
            backgroundColor : SC.CurrentTheme.theme.back_lighter
        }
    }
    

    return (
        <motion.div style={{
            ...SC.Styles.Flex.Column,
            position : 'absolute',
            top : '8px',
            right : '8px',
            ...style,         
            boxSizing : 'border-box',
            overflow : 'hidden',                
            zIndex : 99999999,
            boxShadow : SC.CurrentTheme.theme.popup_shadow,  
            border : SC.CurrentTheme.theme.border_popup,
            backgroundColor : SC.CurrentTheme.theme.back_lighter,
            ...props.style
        }}
            animate={{
                x : 0
            }}
            exit={{
                
            }}
            transition={{
                duration : 0.2
            }}
            layoutTransition={{
                duration : 0.2
            }}
        >
            {props.children}
        </motion.div>
    )
}