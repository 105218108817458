import React from 'react';
import {
    ReactBasicComponent,
    SC,
    Utils,
    AppLayout,
    Globals
} from '../../importer';

import InputSlider from '../../components/editors/input_slider';
import Slider from '../../components/editors/slider';

export default class ZoomSlider extends ReactBasicComponent {
    constructor(props) {
        super(props);
            
        Globals.ZoomSliderRef = this;

        this.onChangingZoom = this.onChangingZoom.bind(this);
        this.onChangeZoom = this.onChangeZoom.bind(this);
        this.Update = this.Update.bind(this);// Utils.Throttle(this.Update, 100);
        this.RCThrottledUpdate_0_5 = Utils.Throttle(this.RCUpdate, 50);
    }
    Update() {
        if (Globals.ZoomConsumer) {
            Globals.ZoomConsumer.UpdateZoom({zoom : AppLayout.Designer.Zoom, save : false});
        }        
        this.RCUpdate();
    }
    onChangingZoom(zoom) {
        AppLayout.Designer.Zoom = zoom / 100;
        if (!this.ZoomStarted) {
            this.ZoomStarted = true;
        }
        this.Update();        
    }    
    onChangeZoom(zoom) {
        this.onChangingZoom(zoom);        
        if (Globals.ZoomConsumer) {
            Globals.ZoomConsumer.UpdateZoom({zoom : AppLayout.Designer.Zoom, save : true});
        }        
        setTimeout(() => {
            if (AppLayout.Refs.PanzoomCanvas)
                AppLayout.Refs.PanzoomCanvas.EndTransform();
        }, 100);
        this.ZoomStarted = false;
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.value !== this.props.value || this.props.width !== nextProps.width)
            return true;
        return super.ShouldUpdate(nextProps, nextState);
    }
    renderCustom() {
        let useTheme = this.props.theme || SC.CurrentTheme.theme;
        if (this.props.isScaleMode) {
            return (
                <Slider
                    small
                    min={20}
                    max={100}
                    handleBack={useTheme.back_lighter}
                    value={Utils.ToInteger(100 * AppLayout.Designer.Zoom)}
                    onChanging={this.onChangingZoom}
                    onChange={this.onChangeZoom}
                    themeId={this.props.themeId}
                    theme={useTheme}
                />
            )
        }
        return (
            <SC.FRow>
                <InputSlider 
                    horizontal
                    small
                    reverse
                    min={20}
                    max={300}
                    preFix='%'
                    inputStyle={{textAlign : 'left'}}
                    value={Utils.ToInteger(100 * AppLayout.Designer.Zoom)}
                    onChanging={this.onChangingZoom}
                    onChange={this.onChangeZoom}
                    zoomHandle
                    allowOverflow
                    sliderStyle={{width : '140px'}}
                    themeId={this.props.themeId}
                    theme={useTheme}
                />
                {
                    AppLayout.Designer.Zoom !== 1 ? 
                    <SC.Icons.Icon_Button 
                        style={{cursor : 'pointer'}}
                        title='Reset to 100%'
                        hasFill
                        theme={useTheme}
                        onClick={() => {this.onChangeZoom(100)}}
                    >
                        <SC.Icons.Icon_Close size={16} />
                    </SC.Icons.Icon_Button> : null
                }
            </SC.FRow>
        )
    }
}