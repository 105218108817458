import React from 'react';
import {
    ReactBaseComponent,
    Events,
    AppState,
    SC,
    UIUtils,
    Utils,
    Strings
} from '../../../../importer';
import styled, {css} from 'styled-components';
import Switch from '../../../../components/editors/Switch';
import DropDownSelect from '../../../../components/editors/enum_dropdown';


export const StateBox = styled.div`
    background-color : ${props => props.theme.back_lighter};    
    border-radius : 2px;
    padding : 4px;
    padding-left : 6px;
    padding-right : 6px;
    box-sizing : border-box;
    display : flex;
    justify-content : space-between;
    align-items : center;
    min-width : 100px;
    ${
        props => {
            if (props.preview)
                return css`
                    flex : 1;
                `;
        }
    }
`;

export const StateBox_Label = styled.div`
    font-size : 11px;
    margin-right : 4px;
    ${
        props => {
            if (props.preview)
                return css`
                    flex : 1;
                `;
        }
    }
`;

export const MultiState_Value = styled.div`
    margin-left : 6px;
    padding-left : 8px;
    border-left : ${props => props.theme.border_onlight_line};
    position : relative;
    align-self : stretch;

    fill : #A7A7A8;
    cursor : pointer;
    &:hover {
        color : #FFFFFF;
        fill : #FFFFFF;
    }
    ${
        props => {
            if (props.preview)
                return css`
                    flex : 1;
                `;
        }
    }
`;

export const MultiState_Popup = styled.div`
    position : absolute;
    right : -6px;
    top : 24px;
    z-index : 3000000;
    min-width : 150%;       
    box-sizing : border-box;
    border : 1px solid ${props => props.theme.sizecard_input_border_color};
    background-color : ${props => props.theme.sizecard_unitbutton_color};
`;

export const PopupItem = styled.div`
    padding : 8px;
    ${SC.TransitionHoverOff}
    cursor : pointer;
    &:hover {
        background-color : ${props => props.theme.sizecard_unit_hovercolor};
        color : ${props => props.theme.sizecard_input_color};
        ${SC.TransitionHoverOn}
    }    
`;

class HoC_StateSelection extends ReactBaseComponent
{
    Select(id, vid) {
        this.props.onSelect(id, vid);
        this.props.onClose();
    }
    renderCustom() {        
        return (
            <MultiState_Popup>
                <SC.FCol>
                {
                    this.props.variations.map((vi, i) => {
                        return (
                            <PopupItem key={vi.id} selected={this.props.selected === vi.id} onClick={this.Select.bind(this,this.props.id, vi.id)} >{vi.label}</PopupItem>
                        )
                    })
                }
                </SC.FCol>
            </MultiState_Popup>
        )        
    }

}

export const MultiStateSelector =  UIUtils.OutSideClickHandler(HoC_StateSelection);

class GlobalStateItem extends ReactBaseComponent
{   
    constructor(props) {
        super(props);

        this.SelectVariation = this.SelectVariation.bind(this);
    }
    SetStateVariation(stateId, varId, checked) {
        this.props.onSetStateVariation(stateId, checked ? varId : 0);
    }
    SelectVariation(vid) {
        this.props.onSetStateVariation(this.props.id, vid === 'Default' ? null : vid);
    }
    renderCustom() {
        const {id, SelectedVariation, compact} = this.props;
        const stateModel = this.props.StateModel || this.props.GetStateModel(id);
        
        let content = null;
        const selected = SelectedVariation && SelectedVariation[id];
        if (stateModel.SingleVariation) {            
            return (
                <React.Fragment>
                    <StateBox_Label title={stateModel.name} onClick={this.SetStateVariation.bind(this, id, selected ? null : id)} style={{...SC.Styles.TextAbbr, color : selected ? SC.CurrentTheme.theme.color_brand_text : 'unset'}} preview={this.props.preview}>{stateModel.name}</StateBox_Label>
                    <Switch small round value={selected} onChange={this.SetStateVariation.bind(this, id, selected ? null : id)} />
                </React.Fragment>
            )
        }
        else {
            const Variations = Utils.Get(stateModel, null, 'Variations') || {};
            const Ordered = Utils.Get(Variations, [], 'Order');
            const variationItems = [{id : Strings.DEFAULT, label : Utils.Get(stateModel, 'Default', 'defaultName')}];
            let value = null;
            Utils.ForEach(Ordered, (varid) => {
                if (Variations[varid]) {
                    variationItems.push({
                        id : varid,
                        label : Variations[varid].name
                    });
                    if (SelectedVariation && SelectedVariation[id] === varid) {
                        value = varid;
                    }
                }                                    
            });
            if (variationItems.length === 0) {
                return null;
            }
            return (
                <React.Fragment>
                    <StateBox_Label title={stateModel.name} style={{...SC.Styles.TextAbbr, color : selected ? SC.CurrentTheme.theme.color_brand_text : 'unset'}} preview={this.props.preview}>{stateModel.name}</StateBox_Label>
                    <DropDownSelect          
                        autoHeight
                        noUpwardPopup
                        xsmall
                        toRight
                        fullwidth
                        noBackColor
                        style={{flex : 1, fontSize : '11px'}}
                        items={variationItems}
                        value={value || 'Default'}
                        onChange={this.SelectVariation}
                        placeholder='Default'
                    />
                </React.Fragment>
            )        
        }        
    }
}

export default GlobalStateItem;

export const StateSelectorsBox = (props) => {
    return (
        <div style={{
            display : 'grid',
            gridTemplateColumns : '1fr minMax(120px, auto)',
            gridAutoRows : 'minmax(28px, auto)',
            gridColumnGap : '8px',
            alignItems : 'center',
            ...props.style
        }}>
            {props.children}
        </div>
    )
}