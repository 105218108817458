import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Strings,
    Loading,
    MetaData,
    Globals
} from '../../../../../../importer';
import styled, {css} from 'styled-components';
import { ItemNameEditor, TokenItemBox, TokenGalleryView, DropFileArea, DropAreaContainer, TokenPanelHeader } from '../common';
import RadioGroup from '../../../../../../components/editors/radiogroup';
import Dropzone from 'react-dropzone';
import { LeftScrollPanel } from '../../common';
import PlaySound, {HoC_SoundPlayer} from './playsound';
import FacebookSoundKit from './facebooksoundkit';

export default class SoundEditor extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.SaveName = this.SaveName.bind(this);
        this.GetItems = this.GetItems.bind(this);

        this.Ref_Name = React.createRef();

        this.onDrop = this.onDrop.bind(this);
        this.SelectFacebookSound = this.SelectFacebookSound.bind(this);
        this.onSelectFacebookSound = this.onSelectFacebookSound.bind(this);

        this.RemoveStateOverride = this.RemoveStateOverride.bind(this);        
        this.MoveValueToParentState = this.MoveValueToParentState.bind(this);
        this.SwitchStateToTokensOverriden = this.SwitchStateToTokensOverriden.bind(this);
        this.ApplyValueToCurrentState = this.ApplyValueToCurrentState.bind(this);        

        if (this.props.newModel) {
            this.token = this.props.newModel;
            this.tokenvalue = {
                
            }
        }
        else {
            this.LoadToken(this.props.id);
        }
    }
    RemoveStateOverride() {
        if (this.ValueState.ValueState && !this.ValueState.ValueState.inherited) {
            Globals.ProjectManager.Tokens.DeleteValue({
                id : this.props.id,
                state : this.ValueState.ValueState.state
            })
            this.LoadToken(this.props.id);
            Events.BCE(Events.GLOBAL.TOKENS_CHANGED);
        }        
    }
    MoveValueToParentState() {
        if (this.ValueState.ValueState) {
            Globals.ProjectManager.Tokens.MoveValueToState({
                id : this.props.id,
                fromState : this.ValueState.ValueState.state
            });
            this.LoadToken(this.props.id);
            this.RCUpdate();
        }
    }
    ApplyValueToCurrentState() {
        if (this.ValueState.ValueState) {
            Globals.ProjectManager.Tokens.MoveValueToState({
                id : this.props.id,
                fromState : this.ValueState.ValueState.state,
                toState : Globals.ProjectManager.CurrentState
            });
            this.LoadToken(this.props.id);
            this.RCUpdate();
        }
    }
    SwitchStateToTokensOverriden() {
        const ToState = this.ValueState.ValueState.state;
        delete this.ValueState.ValueState;
        Globals.ProjectManager.States.SetStateFromLabel(ToState);
    }
    LoadToken(id) {
        this.token = Globals.ProjectManager.Tokens.Token(id);
        if (this.token) {
            this.ValueState = {
                onRemoveOverride : this.RemoveStateOverride,
                onMoveToParent : this.MoveValueToParentState,
                onApplyToCurrentState : this.ApplyValueToCurrentState,
                onSwitchToOverridenState : this.SwitchStateToTokensOverriden
            };
            this.tokenvalue = Utils.DeepClone(Globals.ProjectManager.Tokens.ValueOf({model : this.token, info : this.ValueState})) || {};
        }
    }
    componentWillUnmount() {
        this.props.onClosed && this.props.onClosed();
        super.componentWillUnmount();        
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.GlobalState !== nextProps.GlobalState || this.props.GlobalStateId !== nextProps.GlobalStateId) {
            if (!nextProps.newModel) {
                this.LoadToken(nextProps.id);
                if (!this.token) {
                    this.props.onCancelAddSound();
                    return false;
                }
            }            
        }
        return true;
    }
    GetItems() {
        return Globals.ProjectManager.Tokens.TokenList(Globals.ProjectManager.Tokens.Types.Sounds);
    }        
    
    SaveName(name) {
        if (this.props.newModel) {
            this.props.newModel.name = name;
            this.nameChanged = true;
        }
        else
            Globals.ProjectManager.Tokens.ChangeTokenName(this.props.id, name);
        this.RCUpdate();
    }
    SelectFacebookSound() {
        this.ShowGallery = {

        };
        this.RCUpdate();
    }
    onSelectFacebookSound(category, soundid, fileName) {
        if (this.props.newModel) {
            if (!this.nameChanged)
                this.props.newModel.name = fileName;
        }
        else if (this.token.name === this.tokenvalue.fileName) {   
            Globals.ProjectManager.LogTokenChange({Desc : 'Change Sound Token'});         
            Globals.ProjectManager.Tokens.UpdateProp({id : this.props.id, name : 'name', value : fileName} );
        }

        this.tokenvalue.provider = Strings.SOUND_FACEBOOKKIT;
        this.tokenvalue.category = category;
        this.tokenvalue.soundid = soundid;
        this.tokenvalue.fileName = fileName;        
        
        delete this.ShowGallery;
        this.SaveValue();
        this.RCUpdate();
    }
    onDrop(files) {
        if (files && files.length > 0) {
            var reader = new FileReader();
            reader.onload = (e) => {
                Globals.ProjectManager.LogTokenChange({Desc : 'Change Sound Token'});
                this.tokenvalue.data = e.target.result;
                this.tokenvalue.fileName = files[0].name;
                this.tokenvalue.provider = Strings.CUSTOM;
                this.SaveValue();
                this.RCUpdate();
            }
            reader.readAsDataURL(files[0]);
        }
    }   
    SaveValue() {
        if (this.props.newModel) {
            
        }
        else {            
            Globals.ProjectManager.Tokens.SetValue({
                id : this.props.id,
                value : this.tokenvalue
            });
            const ValueState = Utils.Get(this.ValueState, {
                state : Globals.ProjectManager.CurrentState
            }, 'ValueState');
            ValueState.inherited = false;
        }        
    }
    renderCustom() {
        let info = (
            <SoundTokenPlayerWidget  
                tokenvalue={this.tokenvalue}
                sound={this.tokenvalue.data}
                type={this.tokenvalue.category}
                fileName={this.tokenvalue.fileName}  
            />
        );

        return (
            <SC.FCol fw fh>
                {
                    !this.ShowGallery && 
                    <React.Fragment>
                        <TokenPanelHeader title={this.props.newModel ? 'NEW SOUND' : 'EDIT SOUND'} 
                            hasAddCancel 
                            notBackClosable={this.props.newModel ? true : false}
                            onClose={this.props.onCancelAddSound} 
                            onCancel={this.props.onCancelAddSound} 
                            onAdd={() => {
                                if (this.props.newModel) {
                                    this.props.newModel.value = this.tokenvalue;
                                }
                                this.props.onSubmitNewSound();
                            }} 
                            onDelete={this.props.onDeleteSound}
                            stateValue={this.ValueState}
                        />
                        <LeftScrollPanel>
                            <SC.FCol fw fh f1 style={{padding : '10px', boxSizing : 'border-box', backgroundColor : SC.CurrentTheme.theme.back, pointerEvents : this.token.locked ? 'none' : 'all'}}>
                                <ItemNameEditor
                                    noMargin
                                    fontSize='12px'                        
                                    name={this.token.name}
                                    onSaveName={this.SaveName}
                                    onGetItems={this.GetItems}
                                    model={this.token}
                                    readOnly={this.token.locked}
                                    lockable={{
                                        isLocked : this.token.locked,
                                        onTokenLock : () => {
                                            this.token.locked = Globals.ProjectManager.Tokens.ToggleTokenLock(this.props.id);                                    
                                            this.RCUpdate();
                                        }
                                    }}
                                />
                                <SC.FCol f1 style={{marginTop : '16px'}}>
                                    {info}
                                    <Dropzone
                                        onDrop={this.onDrop}
                                        ref={(r) => this.Ref_DropZone = r}
                                        style={{}}
                                        accept="audio/*"
                                        activeStyle={{}}                
                                    >
                                    {({ isDragActive, getRootProps, getInputProps }) => {
                                        return (
                                            <SC.FCol style={{ position : 'relative'}} {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <SC.FCol onClick={(e) => {e.stopPropagation()}}>                                                    
                                                    <DropAreaContainer
                                                        onClick={this.SelectFacebookSound}                            
                                                        style={{marginTop : '4px', minHeight : '80px'}}
                                                    >
                                                        Select from Facebook Sound Kit
                                                    </DropAreaContainer>
                                                    <SC.Div_Flex_Cell style={{padding : '4px'}}>
                                                        OR
                                                    </SC.Div_Flex_Cell>
                                                </SC.FCol> 
                                                <DropFileArea active={isDragActive} />                                                                                               
                                                {
                                                    this.IsUploading ?
                                                    <SC.Div_Flex_Cell style={{
                                                            position : 'absolute', top : 0, left : 0, right : 0, bottom : 0, backgroundColor : 'rgba(0,0,0,0.5)'
                                                        }}>
                                                        <SC.Loading_Icon justIcon/>
                                                    </SC.Div_Flex_Cell> : null
                                                }
                                            </SC.FCol>
                                        );
                                    }}

                                    </Dropzone>
                                </SC.FCol>                                                       
                            </SC.FCol>        
                        </LeftScrollPanel>
                    </React.Fragment>
                }                
                {
                    this.ShowGallery && 
                    <TokenGalleryView 
                        title='FACEBOOK SOUND KIT' 
                        onClose={() => {
                            delete this.ShowGallery;
                            this.RCUpdate();
                        }}
                        style={{backgroundColor : SC.CurrentTheme.theme.back}}
                    >
                        <FacebookSoundKit 
                            onSelect={this.onSelectFacebookSound}
                        />
                    </TokenGalleryView>                    
                } 
            </SC.FCol>
        )
    }
}


class SoundTokenInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        const {tokenvalue} = this.props;
        if (tokenvalue.provider === Strings.CUSTOM) {
            return (
                <TokenItemBox onMouseEnter={this.props.onMouseEnter} onMouseLeave={this.props.onMouseLeave} style={this.props.style} hoverStyle={this.props.hoverStyle}>       
                    <SC.FCol>
                        <SC.FRow jsb>
                            <SC.FCol ja>
                                <SC.TextDivAbbr>
                                    Custom Sound
                                </SC.TextDivAbbr>
                                <SC.TextDivAbbr>
                                    {tokenvalue.fileName}
                                </SC.TextDivAbbr>
                            </SC.FCol>                            
                            {/* <PlaySound 
                                sound={tokenvalue.data}
                                type={tokenvalue.category}
                                fileName={tokenvalue.fileName}  
                            /> */}
                            <SC.Icons.Icon_Sound small={!this.props.xsmall} xsmall={this.props.xsmall} playing={this.props.playing} />
                        </SC.FRow>
                    </SC.FCol>                             
                </TokenItemBox>
            )
        }
        else if (tokenvalue.provider === Strings.SOUND_FACEBOOKKIT) {
            return (
                <TokenItemBox onMouseEnter={this.props.onMouseEnter} onMouseLeave={this.props.onMouseLeave} style={this.props.style} hoverStyle={this.props.hoverStyle}>       
                    <SC.FCol>
                        <SC.FRow jsb alc>
                            <SC.FCol ja>
                                <SC.TextDivAbbr style={{lineHeight : '24px'}}>
                                    Facebook Sound Kit
                                </SC.TextDivAbbr>
                                <SC.TextDivAbbr style={{lineHeight : '24px'}}>
                                    {tokenvalue.category}
                                </SC.TextDivAbbr>
                                <SC.TextDivAbbr style={{lineHeight : '24px'}}>
                                    {tokenvalue.fileName}
                                </SC.TextDivAbbr>
                            </SC.FCol>                            
                            <SC.Icons.Icon_Sound small={!this.props.xsmall} xsmall={this.props.xsmall} playing={this.props.playing} />
                        </SC.FRow>
                    </SC.FCol>                             
                </TokenItemBox>
            )
        }
        return null;
    }
}
 
export const SoundTokenPlayerWidget = HoC_SoundPlayer(SoundTokenInfo);