import Data from './AppData';
import Events from './Events';
import Strings from './Strings';
import AppLayout from './AppLayout';
import {Utils, MetaData} from '../toolabs-importer';
import { checkFirebaseJson } from '../db/firebasedb';
import ReactGA from 'react-ga';
import packageJSON from '../../../package.json';
import { DOCUMENT_ITEMTYPES } from '../views/project/manager/documents';
// import Globals from './Globals';

const AnalysisEvents = {
    Category:
        {
            CRUD: {
                Name: 'CRUD',
                Events:
                    {
                        Create: 'Create',
                        Delete: 'Delete',
                        Edit: 'Edit'
                    }
            },
            Authentication: {
                Name: 'Authentication',
                Events:
                    {
                        Register: 'Register',
                        Login: 'Login',
                        Logout: 'Logout'
                    }
            },
        }
};

const OverlayManager = {
    Instance: null,
    ShowMessage(Message, Options) {
        if (this.Instance) {
            this.Instance.ShowMessage(Message, Options || {});
        }
    },
    ShowConfirmation(Message, Options = {}) {
        Options.Confirmation = true;
        this.ShowMessage(Message, Options);
    },
    ShowModal(Content, Options) {
        if (this.Instance) {
            return this.Instance.ShowModal(Content, Options);
        }
    },
    Show(Content, Options) {
        if (this.Instance) {
            let OverlayProxy = {
                Options: Options || {}
            };
            this.Instance.Show(OverlayProxy, Content);
            return OverlayProxy;
        }
        return null;
    },
    Close(Proxy) {
        if (this.Instance) {
            this.Instance.Close(Proxy);
        }
    },
    ShowLoading(Message) {
        if (this.Instance) {
            this.Instance.ShowLoading(Message);
        }
    },
    HideLoading() {
        this.Instance && this.Instance.HideLoading();
    }
};

const CatchedData = {
    Get(...Path) {
        return Utils.Get(this.Data, null, ...Path);
    },
    Set(Value, ...Path) {
        Utils.Set(this.Data, Value, ...Path);
    },
    Clear(...Path) {
        Utils.UnSet(this.Data, ...Path);
    },
    ClearAll() {
        this.Data = {};
    },
    Data: {

    },
    COMPONENT : 0,
    COMPONENT_PUBLISHED : 1,
    BOARD : 2,
    BOARD_PUBLISHED : 3,
    ICONS :4,
    SOUNDS :5,
    CUSTOMFONTS : 6,
    CUSTOMICONS : 7,
    CUSTOMSOUNDS : 8,
    PROTOTYPE : 9,
    PROTOTYPE_PUBLISHED : 10,
    DOCUMENTS_TEMPLATE : 11,
    DOCUMENTS_TEMPLATE_PUBLISHED : 12
};

const AppState = {
    AnalysisEvents: AnalysisEvents,
    OverlayManager : OverlayManager,
    CatchedData : CatchedData,
    DesignData : Data,
    User: {},
    UserSettings : {
        email_notification : true,
        push_notification : true,
        play_sounds : true,
        tutorial_history : {}
    },
    Versions : {
        Studio : {
            DSM : packageJSON.config.studio.version
        },
        Figma : {
            Plugin : packageJSON.config.figma.version
        }
    },
    CanvasState : {},
    Assets : {
        Icon(Id) {
            return db_api.asset_icon_paths(Id);
        },
        SystemIcons() {
            return db_api.asset_icons();
        },
        SystemSounds() {
            return MetaData.Sounds;
        },
        MapBoxStyles : {
            List() {
                return [
                    {id : Strings.NULL, label : Strings.DEFAULT},
                    {label : 'Streets', id : 'mapbox://styles/mapbox/streets-v10'},
                    {label : 'Outdoors', id : 'mapbox://styles/mapbox/outdoors-v10'},
                    {label : 'Light', id : 'mapbox://styles/mapbox/light-v9'},
                    {label : 'Dark', id : 'mapbox://styles/mapbox/dark-v9'},
                    {label : 'Satellite', id : 'mapbox://styles/mapbox/satellite-v9'},
                    {label : 'Satellite Streets', id : 'mapbox://styles/mapbox/satellite-streets-v10'},
                    {label : 'Navigation-Preview-day', id : 'mapbox://styles/mapbox/navigation-preview-day-v4'},
                    {label : 'Navigation-Preview-night', id : 'mapbox://styles/mapbox/navigation-preview-night-v4'},
                    {label : 'Navigation-Guidance-day', id : 'mapbox://styles/mapbox/navigation-guidance-day-v4'},
                    {label : 'Navigation-Guidance-night', id : 'mapbox://styles/mapbox/navigation-guidance-night-v4'},
                ]
            }
        },
    },
    GetDataApi() {
        return db_api;
    },
    Load() {
        Data.SetDataApi(db_api);
        this.Loaded = true;
        db_api.onProjectListChanged = () => {
            if (AppLayout.Refs.ProjectList && AppLayout.Refs.ProjectList.Reload)
                AppLayout.Refs.ProjectList.Reload();
        }
        db_api.OnAuthChanged = (User) => {
            if (User) {
                db_api.LoadUser(User.id);
                AppState.DesignData.User.Info(AppState.User.uid).then(
                    (result) => {
                        if (result && result.value) {
                            AppState.UserInfo = result.value;
                            AppState.UserInfo.username = User.name;
                            AppState.UserInfo.email = User.email;
                            if (result.value.settings) {
                               AppState.UserSettings = result.value.settings;
                               db_api.SystemAdmin = result.value.admin;
                               AppState.SystemAdmin = result.value.admin;
                               if(!AppState.UserSettings.tutorial_history)
                                    AppState.UserSettings.tutorial_history = {};
                                
                                if (Utils.JustGet(AppState.UserSettings, false, 'options', 'Theme', 'Light'))
                                    AppLayout.Theme.Current = 'Light';                                    
                            }
                            Data.UserStats.Data = result.value.statistics || {};
                            const count = Data.UserStats.Get(0, Data.UserStats.Modules.LOGIN, 'Count') + 1;
                            Data.UserStats.Set({
                                Count : count,
                                LastLogin : Date.now()
                            }, Data.UserStats.Modules.LOGIN);
                        }

                        db_api.listen_projects_new();
                        Events.BCE(Events.SESSION, User);
                    }
                );
                db_api.OnConnectionStateChanged = (state) => {
                    if (!state.connected) {
                        Events.Notify('Internet connection is lost. Your changes will not be saved.', Events.GLOBAL.NOTIFICATION.TYPES.ERROR);
                    }
                    else if (state.connected) {
                        Events.Notify('Internet connection is restored.', Events.GLOBAL.NOTIFICATION.TYPES.INFO, true, true);
                    }
                }                
            }
            else {
                db_api.LoadUser(0);
                Events.BCE(Events.SESSION, false);
            }
        }
        db_api.init();
    },
    Login(u, p, cb) {
        db_api.Login(u, p, (result) => {
            if (!result.Error) {
                // console.log(result.User);
            }
            cb && cb({
                Error: result.Error,
                User : result.User,
                Message: result.Message
            })

        });
    },
    SavePassword(u, k, p, cb) {
        db_api.SavePassword(u, k, p, (result) => {
            cb && cb({
                Error: result.Error,
                Message: result.Message
            })

        });
    },
    LoginWithGoogle({notPopup, appSource = 'DSM'}) {
        return db_api.LoginWithGoogle(notPopup).then(
            (result) => {
                if (result) {
                    const {additionalUserInfo} = result; 
                    if (additionalUserInfo && additionalUserInfo.isNewUser)
                    {
                        const {profile} = additionalUserInfo;
                        db_api.new_user({
                            uid : result.user.uid, 
                            username : profile.name, 
                            email : profile.email, 
                            password : '', 
                            provider : additionalUserInfo.providerId,
                            pictureUrl : profile.picture,
                            app : appSource
                        });
                    }
                    return result.user;
                }
            }
        );;
    },
    LoginWithTwitter({notPopup, appSource}) {
        return db_api.LoginWithTwitter(notPopup).then(
            (result) => {
                if (result) {
                    const {additionalUserInfo} = result; 
                    if (additionalUserInfo && additionalUserInfo.isNewUser)
                    {
                        const {profile} = additionalUserInfo;
                        db_api.new_user({
                            uid : result.user.uid, 
                            username : profile.name, 
                            email : profile.email, 
                            password : '', 
                            provider : additionalUserInfo.providerId,
                            pictureUrl : profile.picture,
                            app : appSource
                        });                        
                    }
                    return result.user;
                }
            }
        );;
    },
    LoginWithFacebook() {
        return db_api.LoginWithFacebook();
    },
    RegisterApiAuthentication(reguestId, user) {
        return db_api.RegisterApiAuthentication(reguestId, user);
    },
    SignUp({email, password, user, reguestId = null, appSource, callback}) {        
        db_api.Register(email, password, user).then(
            (userid) => {
                if(reguestId) {
                    this.RegisterApiAuthentication(reguestId, {uid: userid});
                }
                if(callback) 
                    callback({userid : userid});

                db_api.new_user({
                    uid : userid, 
                    username : user,
                    email : email,
                    password : password, 
                    provider : 'email',
                    app : appSource
                });
            }
        ).catch((error) => {
            if (callback) {
                callback({error : error})
            }
        })        

    },
    CreateFeedback(topic, subject,description,files) {
        return db_api.create_feedback( topic, subject,description,files);

    },
    ResetPassword(email) {
        return db_api.ResetPassword(email);
    },
    Logout() {
        db_api.Logout();
    },
    ShowChatPanel() {
        if (!window.$crisp) {
            window.$crisp = [];
            window.CRISP_WEBSITE_ID = "301456a8-530e-4c85-817d-3baf296d3173";
            var script = document.createElement("script");
            script.src = "https://client.crisp.chat/l.js";
            script.async = true;
            script.onload = () => {
                const closedCallback = () => {
                    window.$crisp.push(['do', 'chat:hide']);
                };
                window.$crisp.push(["on", "chat:closed", closedCallback])
                if (AppState.User) {
                    window.$crisp.push(["set", "user:email", [AppState.User.email]]);
                    window.$crisp.push(["set", "user:user:nickname", [AppState.User.displayName]]);
                }
                window.$crisp.push(['do', 'chat:open']);
            }
            document.body.appendChild(script);
        } else {
            window.$crisp.push(['do', 'chat:show']);
            window.$crisp.push(['do', 'chat:open']);
        }
    },
    UpdateUserToken(uid, token) {

        fetch('https://iid.googleapis.com/iid/v1/'+token+'/rel/topics/updates', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': 'key='+db_api.api.getConfig().serverKey,
            }
        });

        return db_api.update_user_token(uid, token);
    },
    UpdateUserSettings({uid, name, settings, onNameChanged}) {

        if(name && name !== this.User.displayName)
        {
            db_api.api.UpdateUserName(name, onNameChanged);
        }        

        if (settings) {
            this.UserSettings = settings;

            return db_api.update_user_settings(uid, name,email,settings);
        }        
    },
    SetUserOption(value, ...props) {
        db_api.set_user_setting(GetUserId(), value, props);
    },
    GetUserOption(defaultValue, ...props) {
        return Utils.JustGet(AppState.UserSettings, defaultValue, 'options', ...props);
    },
    RequestNotificationPermission () {
        db_api.request_notification_permission();
    },
    UpdateUserSettingsTutorialHistory(uid,tutorial_history) {

        this.UserSettings.tutorial_history = tutorial_history;

        return db_api.update_user_settings_tutorial_history(uid, tutorial_history);
    },
    DeleteUser(uid)
    {
        db_api.api.DeleteUser();
        return db_api.delete_user(uid);
    },
    Data : {
        BoardList() {
            return db_api.project_list();
        },
        Board: {
            ModelTypes : {
                COMPONENT : 'component',
                PROTOTYPES : 'prototype'
            },           
            // Up : New Data Handling
            DefaultDemoId : '-LyTBB5G_4zZUrmZybL2',
            DefaultDemoVersion : 5,
            LoadDefaultDemo() {
                const that = this;
                return new Promise((resolve, reject) => {
                    db_api.board_demo_templates().then((result) => {
                        if (result && result.value) {
                            const templates = result.value;
                            if (templates) {
                                if (templates[that.DefaultDemoId]) {
                                    if (Utils.IsObject(templates[that.DefaultDemoId]) && Utils.IsObject(templates[that.DefaultDemoId]).Version === that.DefaultDemoVersion) {
                                        that.Load(templates[that.DefaultDemoId].Id).then((result_load) => {
                                            resolve(result_load);
                                            return;
                                        })
                                    }
                                }
                            }
                        }
                        db_api.get_demo_template(this.DefaultDemoId).then((result_template) => {
                            if (result_template) {
                                AppState.OverlayManager.ShowLoading("Please Wait")

                                setTimeout(() => {
                                    try {
                                        const newid = db_api.new_board_id();
                                        const convertedids = {};
                                        convertedids[this.DefaultDemoId] = newid;
                                        result_template.Data.Components && Utils.ForEach(result_template.Data.Components.Order, (cid, i) => {
                                            convertedids[cid] =  db_api.comp_newid();                                            
                                        });
                                        result_template.Data.Prototypes && Utils.ForEach(result_template.Data.Prototypes.Order, (cid, i) => {
                                            convertedids[cid] =  db_api.comp_newid();                                            
                                        });

                                        let clone_template = Utils.DeepClone(result_template);
                                        Utils.DeepReplace(result_template, clone_template, convertedids, true, true);

                                      
                                        // Generate new Prototype Ids and update references                                        

                                        clone_template.Model.TemplateId = this.DefaultDemoId;
                                        clone_template.Model.TemplateVersion = this.DefaultDemoVersion;
                                        clone_template = checkFirebaseJson(clone_template);
                                        
                                        db_api.save_demo_template(clone_template, this.DefaultDemoId, newid).then((result) => {
                                            resolve(newid);
                                        })
                                    } catch (error) {
                                        console.log(JSON.stringify(error));
                                    }
                                    finally {
                                        AppState.OverlayManager.HideLoading();
                                    }
                                }, 100);
                            }
                        })
                    })
                });
            },
            LoadWorkshopProject(WorkshopId) {
                const that = this;
                return new Promise((resolve, reject) => {
                    db_api.get_workshop_template(WorkshopId).then((result_template) => {
                        if (result_template) {
                            AppState.OverlayManager.ShowLoading("Please Wait")

                            setTimeout(() => {
                                try {
                                    const componentids = {

                                    }   ;

                                    if (result_template.ComponentModels) {
                                        ['data', 'models'].map((type) => {
                                            const delete_ids = [];
                                            Utils.ForEach(result_template.Components[type],(data, cid) => {
                                                if (!result_template.ComponentModels[cid])
                                                    delete_ids.push(cid);
                                                else
                                                    componentids[cid] =  db_api.comp_newid();
                                            });
                                            delete_ids.map((id) => {
                                                delete result_template.Components[type][id];
                                            })
                                        })
                                    }
                                    let clone_template = Utils.DeepClone(result_template);
                                    Utils.ForEach(result_template.ComponentModels,(cmodel, cid) => {
                                        Utils.DeepReplace(result_template, clone_template, componentids, true, true);
                                    });
                                    // Generate new Prototype Ids and update references
                                    const prototypeids = {

                                    }   ;
                                    if (result_template.PrototypeModels) {
                                        ['data', 'models'].map((type) => {
                                            const delete_ids = [];
                                            Utils.ForEach(result_template.Prototypes[type],(data, cid) => {
                                                if (!result_template.PrototypeModels[cid])
                                                    delete_ids.push(cid);
                                                else
                                                    prototypeids[cid] =  db_api.comp_newid();
                                            });
                                            delete_ids.map((id) => {
                                                delete result_template.Prototypes[type][id];
                                            })
                                        })
                                    }
                                    Utils.ForEach(result_template.PrototypeModels,(cmodel, cid) => {
                                        Utils.DeepReplace(result_template, clone_template, prototypeids, true, true);
                                    });


                                    const newid = db_api.new_board_id();
                                    const board_clone = Utils.DeepClone(clone_template);
                                    const boardmap = {};
                                    boardmap[WorkshopId] = newid;
                                    Utils.DeepReplace(board_clone, clone_template, boardmap, true, true);
                                    clone_template.Model.TemplateId = WorkshopId;
                                    clone_template.Model.TemplateVersion = this.DefaultDemoVersion;
                                    clone_template = checkFirebaseJson(clone_template);
                                    db_api.save_demo_template(clone_template, WorkshopId, newid).then((result) => {
                                        resolve(newid);
                                    })
                                } catch (error) {
                                    console.log(JSON.stringify(error));
                                }
                                finally {
                                    AppState.OverlayManager.HideLoading();
                                }
                            }, 100);
                        }
                    })
                });
            },
            Clone(SourceId) {
                const that = this;
                return new Promise((resolve, reject) => {
                    db_api.project_data(SourceId).then((result_template) => {
                        if (result_template) {
                            const newid = db_api.new_project_id();
                            const convertedids = {};
                            convertedids[SourceId] = newid;
                            result_template.Data.Components && Utils.ForEach(result_template.Data.Components.Order, (cid, i) => {
                                convertedids[cid] =  db_api.comp_newid();                                
                            });
                            result_template.Data.Prototypes && Utils.ForEach(result_template.Data.Prototypes.Order, (cid, i) => {
                                convertedids[cid] =  db_api.comp_newid();                                
                            });

                            let clone_template = Utils.DeepClone(result_template);
                            Utils.DeepReplace(result_template, clone_template, convertedids, true, true);

                            
                            // Generate new Prototype Ids and update references                                        

                            clone_template = checkFirebaseJson(clone_template);
                            clone_template.Model.Name = clone_template.Model.Name + ' Duplicate';
                            const clone_documents = null;
                            
                            db_api.import_project(clone_template.Model.Name, clone_template.Model, clone_template.Data, clone_documents, newid).then((result) => {
                                resolve({
                                    id : result.id,
                                    name : clone_template.Model.Name,
                                    Dashboard : clone_template.Model.Dashboard ||{},
                                    Note : clone_template.Model.Note,
                                });
                            })
                        }
                    })
                });
            },
            New(name, teamId) {
                return new Promise((resolve) => {
                    db_api.new_project(name, teamId).then((result) => {
                        ReactGA.event({
                            category: AnalysisEvents.Category.CRUD.Name,
                            action: AnalysisEvents.Category.CRUD.Events.Create,
                            type: 'Board',
                            value: result.id
                        });
    
                        resolve(result);
                    });
                })                
            },
            Delete(id, teamId) {
                return new Promise((resolve, reject) => {
                    Events.Ask_Delete('Project will be marked as deleted but will not be deleted permanently! You can restore it from Deleted Projects.', Events.GLOBAL.NOTIFICATION.TYPES.WARNING).then((result) => {
                        if (result === Events.GLOBAL.NOTIFICATION.RESULT.CANCEL) {
                            reject();
                        }
                        else {
                            if (teamId)
                                db_api.mark_team_project_deleted(teamId, id);
                            else
                                db_api.mark_project_deleted(id);
                            resolve(true);
                        }
                    })
                })
            },
            RestoreDeleted(id, teamId) {
                if (teamId) 
                    db_api.restore_team_project_deleted(teamId, id);
                else
                    db_api.restore_project_deleted(id);
            },
            DeleteFromArchive(id) {
                return new Promise((resolve, reject) => {
                    Events.Ask_Delete('Project will be deleted permanently with all your tokens and components!', Events.GLOBAL.NOTIFICATION.TYPES.WARNING).then((result) => {
                        if (result === Events.GLOBAL.NOTIFICATION.RESULT.CANCEL) {
                            reject();
                        }
                        else {
                            db_api.project_data(id).then(async (result) => {
                                if (result.Data) {
                                    const storage_ids = [];
                                    // const images = Utils.JustGet(result.Data, [], 'tokens', 'Images', 'order');
                                    // Utils.ForEach(images, (imageid,) => {
                                    //     const imagetoken = Utils.JustGet(result.Data, null, 'tokens', 'list', imageid, 'value');
                                    //     if (imagetoken) {
                                    //         Utils.ForEach(imagetoken, (StateValue, State) => {
                                    //             const provider = Utils.JustGet(StateValue, null, 'value', 'provider');
                                    //             if (provider === 'File') {
                                    //                 const UrlId = Utils.JustGet(StateValue, null, 'value', 'UrlId');
                                    //                 UrlId && storage_ids.push(UrlId);                                                    
                                    //             }                                                
                                    //         });
                                    //     }
                                    // });

                                    // const result_document = await db_api.get_document(id, 'Default');

                                    // if (result_document && result_document.value) {
                                    //     Utils.ForEach(result_document.value.itemdata, (pageItems, pageId) => {
                                    //         Utils.ForEach(pageItems, (itemData, itemId) => {
                                    //             if (itemData) {
                                    //                 if (itemData.type === DOCUMENT_ITEMTYPES.image) {
                                    //                     const storageId = Utils.JustGet(itemData, null, 'data', 'storageId');
                                    //                     if (storageId) {
                                    //                         storage_ids.push(storageId);
                                    //                     }
                                    //                 }                                                    
                                    //             }
                                    //         });
                                    //     });
                                    // }


                                    db_api.delete_project(id, {
                                        storage_ids : storage_ids
                                    }).then((res_delte) => {
                                        ReactGA.event({
                                            category: AnalysisEvents.Category.CRUD.Name,
                                            action: AnalysisEvents.Category.CRUD.Events.Delete,
                                            type: 'Board',
                                            value: id
                                        });
                                        resolve(res_delte);
                                    })
                                }                                
                            })
                        }
                    })
                })
            },
            ChangeProp(Id, Prop, Value) {
                db_api.change_board_prop(Id, Prop, Value);
            },            
            Get(Id) {
                return new Promise((resolve) => {
                    db_api.project_data(Id).then((result) => {

                        Data.User.Data = result.UserConfig || {};
                        resolve(result);
                    })
                })
            },
        },
    },
    Designer : {
        EditingText : false,
        SetEditingText(editing) {
            this.EditingText = editing;
        }
    },
    ItemTypes: {
        BOARD: {
            SEMANTICTOKEN : 'TCTBST',
            COLOR: 'TCTBC',
            GRADIENT: 'TCTBG',
            SHADOW: 'TCTBSD',
            FONT: 'TCTBF',
            ICON: 'TCTBIC',
            IMAGE: 'TCTBIM',
            TRANSFORM : 'TCTBTRF',
            FILTER : 'TCTBFTR',
            SIZE: 'TCTBS',
            CUSTOM: 'TCTBCT',
            EASECURVE : 'TCBEC',
            DURATION : 'TCBDUR',
            TRANSITION: 'TCTBT',
            SOUND: 'TCTBSND',
            ANIMATION: 'TCTBA',
            DATA : 'TCTBDATA',
            CONTENT: 'TCTBCS',
            CONTENT_TEXT: 'TCTBCST',
            PATTERN_TEXT : 'TCTBPTEXT',
            BORDER_STYLE : 'TCTBBS',
            BORDER_RADIUS : 'TCTBBR',
            BOOLEAN : 'TCTBOOL',
            SPACING : 'TCTSPACE',
            FIGMA_AUTOLAYOUT : 'TCTFGM_AL',
            URL: 'TCTBURL',
            COMPONENT: 'TCTBCM',
            PROTOTYPE : 'TCTBPR',
            ARTBOARD : 'TCTBAB',
            DOCUMENTS_TEMPLATE : 'TCTBDT',
            STYLESET: 'TCTBSS',
            LAYOUT: 'TCTBLOUT',
            GRID : 'TCTBGRID',
            API_HANDLERS : 'TCTBAPI_EH',
            API_FUNCTIONS : 'TCTBAPI_FN',
            CATEGORIES: {
                VARIABLES: 'TCTBCV',
                ASSETS: 'TCTBCA',
            }

        },
        COMPONENT: {
            COMPONENT: 'TCTCC'
        }
    },
    Team : {
        EditorTypes : {
            owner : {
                id : 'owner',
                title : 'Owner'                
            },            
            edit : {
                id : 'edit',
                title : 'Editor'
            },
            view : {
                id : 'view',
                title : 'Viewer'
            },
            documentEditor : {
                id : 'documentEditor',
                title : 'Document Editor'
            }
        },
        GetTypeName(type) {
            if (this.EditorTypes[type])
                return this.EditorTypes[type].title;
        }
    }
};


export default AppState;

let db_api = null;
import('../db').then((prom) => {
    if (prom && prom.default) {
        prom.default.then((r) => {
            db_api = r;
            AppState.Load();
        })
    }
})

window.AppState = AppState;

export const GetDataApi = () => {return db_api};

export const GetUserId = () => {return Utils.JustGet(AppState.User, null, 'uid')};
export const GetUserName = () => {return Utils.JustGet(AppState.User, '?', 'displayName')};