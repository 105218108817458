import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    Globals
} from '../../../importer';

import ShadowItemEditor from './shadowItemEditor';
import {SortableList, SortableListItem} from '../../SortableContainer';

export default class ShadowEditor extends ReactBaseComponent {
    constructor(props) {
        super(props);

        this.AddShadow = this.AddShadow.bind(this);
        this.onRefresh = this.onRefresh.bind(this);
        this.onSortItems = this.onSortItems.bind(this);

        if (this.props.model.values && Array.isArray(this.props.model.values)) {
            this.props.model.values.map((shadow) => {
                if (!shadow.id)
                    shadow.id = Utils.Id();
            })
        }
    }

    AddShadow() {
        this.props.onBeforeChange && this.props.onBeforeChange();
        const {model} = this.props;
        const shadows = model.values;
        shadows.push({
            id : Utils.Id(),
            x : 1,
            y : 1,
            blur : 4,
            spread : 0,
            color : 'rgba(0,0,0,0.5)'
        });
        this.UpdateShadows(shadows);
    }
    Clone(shadow) {
        this.props.onBeforeChange && this.props.onBeforeChange();
        const clone = Utils.DeepClone(shadow);
        shadow.id = Utils.Id();
        const {model} = this.props;
        model.values.push(clone);
        this.UpdateShadows(model.values);
    }
    Delete(id) {
        this.props.onBeforeChange && this.props.onBeforeChange();
        const {model} = this.props;        
        const deleted = Utils.Remove(model.values, (item) => {return item.id === id} );
        if (deleted && deleted.length > 0) {
            if (deleted[0].ColorRelationId)
                Globals.ProjectManager.RelationManager.DeleteRelationId(deleted[0].ColorRelationId);
        }
        this.UpdateShadows(model.values);
    }    
    UpdateShadows(shadows) {
        if (this.props.offline) {
            this.props.model.values = shadows;
        }
        else {
            Globals.ProjectManager.Tokens.SetValue({
                id : this.props.id,
                value : {
                    values : shadows
                },
                type : Globals.ProjectManager.Tokens.Types.Shadows,
                textShadow : this.props.model.textShadow
            })  
        }
              
        this.props.onUpdate();
        this.RCUpdate();
    }
    onChangeShadow(i, shadow) {
        const {model} = this.props;
        if (!model.values)
            model.values = [shadow];
        else if (model.values.length === 0)
            model.values.push(shadow);
        else if (i < model.values.length && i >= 0)
            model.values[i] = Utils.DeepClone(shadow);
        this.UpdateShadows(model.values);
    }
    onRefresh() {
        this.props.onUpdate();
    }    
    SetShadowType(isText) {
        const {model} = this.props;
        model.textShadow = isText;
        this.props.onUpdate();
        this.RCUpdate();
    }
    onSortItems(oldIndex, newIndex) {
        const {model} = this.props;
        Utils.ChangePlace(model.values, oldIndex, newIndex);
        this.UpdateShadows(model.values);
    }
    EditColor(shadow, index) {
        this.props.onEditcolor(shadow, index, (color) => {
            this.RCUpdate();
        })
    }
    renderCustom() {
        const {model} = this.props;
        const shadows = model.values;
        if (shadows.length === 0) {
            setTimeout(() => {
                this.AddShadow();
            }, 1);
        }        
        const Buttons = [];
        Buttons.push(
            <SC.Icons.Icon_Button hasFill key={2} >
                <SC.Div_Flex_Cell cursorPointer onClick={this.AddShadow} pr={4} pl={8}>
                    <SC.Icons.Icon_Plus size={20} />
                </SC.Div_Flex_Cell>
            </SC.Icons.Icon_Button>
        )

        return (
            <SC.FCol f1 style={this.props.style}>                        
                {
                    this.props.hideShadowType ? null : 
                    <SC.FRow style={{marginBottom  :'8px', marginTop : '4px'}}>
                        <SC.FRow alc justifyCenter f1 cursorPointer onClick={this.SetShadowType.bind(this, false)}>
                            <SC.Div_Flex_Cell mr={8}>
                                <SC.Svg width="18" height="18" viewBox="0 0 18 18">
                                    <g fill={!Utils.IsTrue(model.textShadow) ? "#0096ea" : "#797B7D"} fillRule="evenodd">
                                    <path fillRule="nonzero" d="M15.3 15.3V0H0v15.3h15.3zM1.04 14.27V1.04h13.23v13.2H1.03z"/>
                                    <path d="M16 4.02l1.54 2v11.5H6L4.03 16H16"/>
                                    </g>
                                </SC.Svg>
                            </SC.Div_Flex_Cell>
                            Box Shadow
                        </SC.FRow>
                        <SC.FRow alc justifyCenter f1 cursorPointer onClick={this.SetShadowType.bind(this, true)}>
                            <SC.Div_Flex_Cell mr={8}>
                                <SC.Svg width="18" height="18" viewBox="0 0 18 18">
                                    <g fill={Utils.IsTrue(model.textShadow) ? "#0096ea" : "#797B7D"} fillRule="evenodd">
                                        <path fillRule="nonzero" d="M15.3 15.3V0H0v15.3h15.3zM1.04 14.27V1.04h13.23v13.2H1.03z"/>
                                        <path d="M16 4.02l1.54 2v11.5H6L4.03 16H16"/>
                                        <path d="M4 4h6.93v1.6H8.27L8.3 12H6.63l.02-6.4H4"/>
                                    </g>
                                </SC.Svg>
                            </SC.Div_Flex_Cell>
                            Text Shadow
                        </SC.FRow>                        
                    </SC.FRow>
                }                        
                <SC.FRow jsb alc style={{marginBottom : '6px', marginTop :'4px', marginRight : '-10px'}}>
                    <SC.HeaderTitle style={{padding : 0}}>
                        SHADOW LAYERS
                    </SC.HeaderTitle>
                    <SC.FRow justifyEnd >
                        {Buttons}
                    </SC.FRow>
                </SC.FRow>
                <SC.FCol f1 style={{marginLeft : '-12px', marginRight : '-12px'}}>
                    <SC.CustomScrollbars>
                        <SortableList                            
                            onSort={this.onSortItems}
                        >
                            {
                                shadows.map((shadow, i) => {
                                    return (
                                        <SortableListItem 
                                            key={shadow.id}
                                            draggableId={shadow.id}
                                            index={i}
                                            ItemBoxType={ShadowItemEditor}
                                            BoxProps={{
                                                notFirst : i > 0,
                                                shadow  :shadow,
                                                id : this.props.id,
                                                textShadow : model.textShadow,
                                                onChange : this.onChangeShadow.bind(this, i),
                                                onChanging : this.onRefresh,
                                                onClone : this.Clone.bind(this, shadow),
                                                onDelete : this.Delete.bind(this, shadow.id),
                                                ownerPosition : this.props.ownerPosition,
                                                onBeforeChange : this.props.onBeforeChange,
                                                onSelectColor : this.EditColor.bind(this, shadow, i),
                                                onRefreshPreview : this.props.onRefreshPreview
                                            }}
                                        />
                                    )
                                })
                            }
                        </SortableList>
                    </SC.CustomScrollbars>
                </SC.FCol>
            </SC.FCol>
        );	
    }
}