import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    MetaData,
    Strings
} from '../../importer';
import Scrollbars from 'react-custom-scrollbars';
import VisibilitySensor from 'react-visibility-sensor';
import DragSource from '../../components/dragdrop/HoCDragSource';
import { API_Unsplash } from '../unsplash';

export class ImageThumbnail extends ReactBaseComponent
{
    constructor(props) {
        super(props);
        this.ImageLoaded = this.ImageLoaded.bind(this);
    }    
    ImageLoaded() {
        this.loaded = true;
        this.showLoader = this.props.last;
        this.RCUpdate();
    }
    renderCustom() {
        const {url, hasSelect, last, onLoadPage, ...rest} = this.props;
        let selectOverlay = null;
        let selectProps = null;
        if (hasSelect) {
            selectProps = {
                onClick : hasSelect.onSelect,
                
            }
            selectOverlay = (
                <SC.Overlay
                    style={Utils.Merge({                        
                        fontSize : '16px',                        
                    }, hasSelect.selected ? {opacity :  1} : null)}
                >
                    Click to {hasSelect.selected ? 'deselect' : 'select'}
                </SC.Overlay>
            )
        }
        else {
            selectOverlay = (
                <SC.Overlay />
            )
        }
        return (
            <React.Fragment>
                <div
                    style={{
                        position:'relative', width : '100%', height : 'auto', marginBottom : '20px', overflow : 'hidden', cursor : 'pointer', margin : '4px'
                    }}
                    {...rest}
                    onClick={this.props.onSelect}
                    {...selectProps}
                >
                    <img 
                        onLoad={this.ImageLoaded}
                        draggable={false}
                        style={Utils.Merge({
                            width : '100%',
                            marginBottom : '-20px'                            
                        },this.loaded ? null : {minHeight: '100px', backgroundColor:'rgba(0,0,0,0.1)'})}
                        src={this.props.url}
                    />
                    {selectOverlay}
                    <div style={{
                        position : 'absolute',
                        left : '4px',
                        right : '4px',
                        bottom : '4px',
                        overflow : 'hidden',
                        fontSize : '11px',
                        zIndex : 999999
                    }} onClick={(e) => {e.stopPropagation()}} title={`Photo by ${this.props.authorName} on Unsplash`}>
                        <a href={`${this.props.authorLink}?utm_source=toolabs&utm_medium=referral`} target='_blanck'>
                            <SC.FRow alc>
                                <svg viewBox="0 0 24 24" style={{minWidth : '18px', maxWidth : '20px', marginRight : '4px'}}>
                                    <path fill="#ffffffa1" d="M4 4h3l2-2h6l2 2h3a2 2 0 012 2v12a2 2 0 01-2 2H4a2 2 0 01-2-2V6a2 2 0 012-2m8 3a5 5 0 00-5 5 5 5 0 005 5 5 5 0 005-5 5 5 0 00-5-5m0 2a3 3 0 013 3 3 3 0 01-3 3 3 3 0 01-3-3 3 3 0 013-3z"/>
                                </svg>
                                <SC.TextDivAbbr style={{color : '#fff'}}>
                                    {this.props.authorName}
                                </SC.TextDivAbbr>
                            </SC.FRow>                            
                        </a>
                    </div>
                </div>
                {
                    this.showLoader ? 
                    <VisibilitySensor partialVisibility={true} >
                        {
                            ({isVisible}) => {
                                if (isVisible) {
                                    this.props.onLoadPage();
                                    this.showLoader = false;
                                    this.RCUpdate();
                                }
                                return (<div style={{width:'100%', height : '200px'}} />)
                            }
                        }                         
                    </VisibilitySensor> : null
                }
            </React.Fragment>
        )
    }
}

 const DragThumbnail = DragSource(ImageThumbnail);

export default class OnlineImageSelect extends ReactBaseComponent
{
    constructor(props) {
        super(props);
        this.LoadPage = this.LoadPage.bind(this);
        this.refreshIndex = 0;
    }
    componentDidMount() {
        super.componentDidMount();
        this.LoadPage();     
    }    
    //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.filter !== this.props.filter || this.props.provider !== prevProps.provider) {
            delete this.images;
            this.Ref_Scroll && this.Ref_Scroll.scrollTop(0);
            this.LoadPage();
        }
    }
    
    LoadPage() {
        if (this.props.provider === 'Unsplash') {
            const that = this;
            if (API_Unsplash.IsLoading())
                return;
            API_Unsplash.Search({
                SearchText : this.props.filter
            }).then((result) => {
                this.total = result.total;
                const images = Utils.Get(that, [[],[]], 'images');
                let tempIndex = images[0].length;
                Utils.ForEach(result.images, (img, i) => {
                    images[i%2].push(img);
                });
                this.refreshIndex = tempIndex;
                that.RCUpdate();            
            })
        }        
    }
    renderCustom() {
        if (this.images) {
            return (
                <SC.FCol f1>                    
                    <SC.FRow style={{paddingLeft:'8px', marginBottom:'8px'}}>
                        { Utils.IsNotNullOrEmpty(this.props.filter) ? `${this.total} images found` : 'listing popular images' }
                    </SC.FRow>
                    <SC.CustomScrollbars hideTracksWhenNotNeeded autoHide ref={(r) => this.Ref_Scroll = r} >
                        <div style={{
                            display : 'grid',
                            gridGap : '4px',
                            gridTemplateColumns : '1fr 1fr',
                            marginTop : '8px'
                        }}>
                            {
                                this.images.map((col, i) => {
                                    return (
                                        <SC.FCol key={i}>
                                            {
                                                col.map((img, j) => {
                                                    return (
                                                        <DragThumbnail 
                                                            key={img.id} 
                                                            url={img.preview} 
                                                            last={j===this.refreshIndex}
                                                            onLoadPage={this.LoadPage}
                                                            DragItem={{
                                                                Type : Strings.TOOLBARITEM,
                                                                Item : {
                                                                    MetaItem : {
                                                                        Type : MetaData.Components.Image.Type,
                                                                        Id : img.id,
                                                                        Provider : {
                                                                            Provider : 'unsplash',
                                                                            id : img.id,
                                                                            preview : img.preview,
                                                                            url : img.url,
                                                                            width : img.width,
                                                                            height : img.height
                                                                        }
                                                                    }
                                                                } 
                                                            }}
                                                        />
                                                    )
                                                })
                                            }                                            
                                        </SC.FCol>
                                    )
                                })
                            }
                        </div>                                             
                    </SC.CustomScrollbars>                    
                </SC.FCol>
            )
        }        
        return (
            <SC.Div_Flex_Cell fw fh>
                <SC.Loading_Icon />
            </SC.Div_Flex_Cell>
        )
    }
}