import React from 'react';
import {
    ReactBaseComponent,
    AppState,
    SC,
    Utils,
    Events,
    AppLayout,
    Globals,
    Strings
} from '../../../../../../importer';

import styled from 'styled-components';
import {TokenGroup, TokenItemBox, DraggableTokenItem, TokenPanelHeader, GroupTitle, EditableName, TokenItemDragHandle, TokenItemBoxHoveredName} from '../common';
import { LeftScrollPanel } from '../../common';
import { SortableList, SortableListItem } from '../../../../../../components/SortableContainer';
import BaseTokenList, { GetGroupedTokenListWithGroups, DraggableGroupHeader } from '../BaseTokenList'
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import { AnimatePresence, motion } from 'framer-motion';
import AliaseEditor from './item';
import { StyleTokenAliase_Color } from '../../../right/iteminspector/styleitems/common';
import { ColorTokenListItem, GetColorTokenItem, StatefulTokenListItemName } from '../colors';
import { ShadowCardItem, GetShadowTokenItem } from '../shadows';
import { BorderStyleTokenItem, GetBorderStyleTokenValue, GetBorderRadiusTokenValue } from '../borders';
import { GetSpacePatternItem, SpacePattern } from '../spacing/patterns';
import { GRANT_TYPES } from '../../../../manager';
import { TextPattern, GetTextPatternTokenItem } from '../typography/patterns';
import { GetDurationTokenItemOfId } from '../motion/patterns';
import { FilterCardItem, GetFilterTokenItem } from '../filters';
import { GetEaseCurveTokenItem } from '../motion/easing';
import { PresetCurve } from '../motion/easing/bezierEditor/presetcurve';
import { FigmaLayoutListItem, GetFigmaLayoutTokenItem } from '../../../../../../apps/figmaplugin/designsystem/autolayouts';


export default class TokenAliaseGroup extends ReactBaseComponent
{
    constructor(props) {
        super(props);
        
        this.AddToken = this.AddToken.bind(this);
        this.AddGroup = this.AddGroup.bind(this);
        this.onCancelAddAliase = this.onCancelAddAliase.bind(this);
        this.onSubmitNewAliase = this.onSubmitNewAliase.bind(this);
        this.onCloneAliase = this.onCloneAliase.bind(this);
        this.onDeleteAliase = this.onDeleteAliase.bind(this);
        this.EditAliaseValue = this.EditAliaseValue.bind(this);

        this.RemoveStateOverride = this.RemoveStateOverride.bind(this);        
        this.MoveValueToParentState = this.MoveValueToParentState.bind(this);
        this.SwitchStateToTokensOverriden = this.SwitchStateToTokensOverriden.bind(this);
        this.ApplyValueToCurrentState = this.ApplyValueToCurrentState.bind(this);   

        this.onDragEndToken = this.onDragEndToken.bind(this);
        this.ToggleGroupView = this.ToggleGroupView.bind(this);

        this.state.isGroupView = Globals.ProjectManager.Options.Get(this.state.isGroupView ? true : false, 'LeftPanel', 'DesignSystem', 'Aliases', this.props.type, 'GroupView');
        this.onToggleExpand = this.onToggleExpand.bind(this);
        this.expanded = Globals.ProjectManager.Options.Get(true, 'LeftPanel', 'DesignSystem', 'Aliases', this.props.type, 'Expanded');

        this.Load(this.state.isGroupView);

        this.HasEditGrant = Globals.ProjectManager.CheckGrant(GRANT_TYPES.EDIT_TOKEN);

        if (this.props.type === Globals.ProjectManager.Tokens.Types.COLOR) {
            AppLayout.Refs.DesignSystem.ColorAliases = this;
        }
        else if (this.props.type === Globals.ProjectManager.Tokens.Types.Shadows) {
            AppLayout.Refs.DesignSystem.ShadowAliases = this;
        }
        else if (this.props.type === Globals.ProjectManager.Tokens.Types.Borders) {
            AppLayout.Refs.DesignSystem.BordersAliases = this;
        }
        else if (this.props.type === Globals.ProjectManager.Tokens.Types.BorderRadiuses) {
            AppLayout.Refs.DesignSystem.RadiusAliases = this;
        }
        else if (this.props.type === Globals.ProjectManager.Tokens.Types.Spacings) {
            AppLayout.Refs.DesignSystem.SpaceAliases = this;
        }
        else if (this.props.type === Globals.ProjectManager.Tokens.Types.FigmaAutoLayouts) {
            AppLayout.Refs.DesignSystem.FigmaAutoLayoutAliases = this;
        }
        else if (this.props.type === Globals.ProjectManager.Tokens.Types.Fonts) {
            AppLayout.Refs.DesignSystem.TextPatternAliases = this;
        }
        else if (this.props.type === Globals.ProjectManager.Tokens.Types.Motion) {
            AppLayout.Refs.DesignSystem.MotionDurationAliases = this;
        }
        else if (this.props.type === Globals.ProjectManager.Tokens.Types.MotionEase) {
            AppLayout.Refs.DesignSystem.EaseCurveAliases = this;
        }
        else if (this.props.type === Globals.ProjectManager.Tokens.Types.Filters) {
            AppLayout.Refs.DesignSystem.FilterAliases = this;
        }
        
        this.SearchToken = this.SearchToken.bind(this);        
    }
    componentWillUnmount() {
        super.componentWillUnmount();

        if (this.props.type === Globals.ProjectManager.Tokens.Types.COLOR) {
            AppLayout.Refs.DesignSystem.ColorAliases = null;
        }
        else if (this.props.type === Globals.ProjectManager.Tokens.Types.Shadows) {
            AppLayout.Refs.DesignSystem.ShadowAliases = null;
        }
        else if (this.props.type === Globals.ProjectManager.Tokens.Types.Borders) {
            AppLayout.Refs.DesignSystem.BordersAliases = null;
        }
        else if (this.props.type === Globals.ProjectManager.Tokens.Types.BorderRadiuses) {
            AppLayout.Refs.DesignSystem.RadiusAliases = null;
        }
    }
    Reload() {
        this.Load(this.state.isGroupView);
        this.RCUpdate();
    }
    Load(groupView) {                
        this.aliases = GetTokenAliaseList(this.props.type);        
        Utils.ForEach(this.aliases, (aliase, i) => { 
            if (this.EditAliaseId === aliase.id)
                this.EditAliaseModel = aliase;
            if (aliase.tokenId) {
                aliase.tokenItem = GetAliaseTokenItem(aliase, this.props.type);
                if (aliase.firstReferencedId && aliase.firstReferencedId !== aliase.tokenId) {
                    aliase.firstReferenceItem = GetAliaseTokenItem(aliase, this.props.type, aliase.firstReferencedId);
                }
            }
            else {
                aliase.tokenItem = {
                    
                }
            }
        });

        if (groupView) {
            this.groups = GetGroupedTokenAliaseList(this.props.type, this.aliases);
        }

        if (Utils.IsNotNullOrEmpty(this.FilterText))
            this.SearchToken(this.FilterText);
    }
    
    SearchToken(filter) {        
        this.FilterText = filter;
        if (this.state.isGroupView) {            
            FilterAliaseItems({filter : filter, groups : this.groups});
        }
        else {
            FilterAliaseItems({filter : filter, aliases : this.aliases});
        }
        this.RenderId = Utils.Id();
        this.RCThrottledUpdate_1();
    }
    shouldComponentUpdate(nextProps, nextState) {     
        if (this.props.filterText !== nextProps.filterText) {
            setTimeout(() => {
                this.SearchToken(nextProps.filterText);
            }, 1);
            return false;
        }   
        if (this.props.GlobalState !== nextProps.GlobalState || this.props.GlobalStateId !== nextProps.GlobalStateId) {
            this.Load(nextState.isGroupView);
            if (this.EditAliaseModel && !this.EditAliaseModel.isAbsolute) {
                this.EditAliaseValue(this.EditAliaseModel);
            }
            return true;
        }
        if (this.state.isGroupView !== nextState.isGroupView) {
            if (nextState.isGroupView)
                this.Load(true);
            else
                delete this.groups;
            return true;
        }
        if (this.props.themeId !== nextProps.themeId)
            return true;
        if (Utils.HasAnyChange(this.state, nextState, 'isListView', 'ShouldUpdate', 'showGroupMenu'))
            return true;
        if (this.props.themeId !== nextProps.themeId)
            return true;

        return super.ShouldUpdate(nextProps, nextState);      
    }
    onToggleExpand() {
        this.expanded = !this.expanded;
        Globals.ProjectManager.Options.Set(this.expanded, 'LeftPanel', 'DesignSystem', 'Aliases', this.props.type, 'Expanded');
        if (this.expanded)
            this.Load(this.state.isGroupView);
        this.RCUpdate();
    }
    ToggleGroupView() {
        Globals.ProjectManager.Options.Set(!this.state.isGroupView, 'LeftPanel', 'DesignSystem', 'Aliases', this.props.type, 'GroupView');
        if (this.expanded)
            this.Load(!this.state.isGroupView);
        this.setState({
            isGroupView : !this.state.isGroupView
        });
    }
    AddGroup() {
        const name = 'New Group';
        const groupid = Globals.ProjectManager.Tokens.Aliases.AddGroup({type : this.props.type, name : name})
        this.WillFocustoGroupId = groupid;
        const groups = Utils.Get(this, [], 'groups');
        if (groups.length === 0) {
            this.Load(true);
            this.RCUpdate();
            return;
        }
        groups.push({
            id : groupid,
            name : name,
            tokens : []
        })

        this.RCUpdate();
    }
    SaveGroupName(group, name) {
        group.name = name;
        Globals.ProjectManager.Tokens.Aliases.ChangeGroupName({type : this.props.type, id : group.id, name : name})
        this.RCUpdate();
    }
    DeleteGroup(group) {
        Globals.ProjectManager.Tokens.Aliases.DeleteGroup({type : this.props.type, id : group.id});
        this.Load(this.state.isGroupView);
        this.RCUpdate();
    }
    DeleteGroupWithTokens(group) {
        Globals.ProjectManager.Tokens.Aliases.DeleteGroup({type : this.props.type, id : group.id, deleteTokens : true});
        this.Load(this.state.isGroupView);
        this.RCUpdate();
    }
    RemoveStateOverride() {
        if (this.ValueState.ValueState && !this.ValueState.ValueState.inherited) {
            Globals.ProjectManager.Tokens.DeleteValue({
                id : this.EditAliaseId,
                state : this.ValueState.ValueState.state,
                name : 'tokenId'
            })            
            delete this.ValueState.ValueState;
            this.EditAliaseModel.tokenId = Globals.ProjectManager.Tokens.Aliases.GetStateTokenId({Id : this.EditAliaseId,  info : this.ValueState});
            this.tokenValueId = Utils.Id();
            Events.BCE(Events.GLOBAL.TOKENS_CHANGED);
            this.props.onUpdate();
        }        
    }
    MoveValueToParentState() {
        if (this.ValueState.ValueState) {
            Globals.ProjectManager.Tokens.MoveValueToState({
                id : this.EditAliaseId,
                fromState : this.ValueState.ValueState.state,
                name : 'tokenId'
            });
            delete this.ValueState.ValueState;
            this.EditAliaseModel.tokenId = Globals.ProjectManager.Tokens.Aliases.GetStateTokenId({Id : this.EditAliaseId,  info : this.ValueState});
            this.tokenValueId = Utils.Id();
            this.props.onUpdate();
        }
    }
    ApplyValueToCurrentState() {
        if (this.ValueState.ValueState) {
            Globals.ProjectManager.Tokens.MoveValueToState({
                id : this.EditAliaseId,
                fromState : this.ValueState.ValueState.state,
                toState : Globals.ProjectManager.CurrentState
            });
            delete this.ValueState.ValueState;
            this.EditAliaseModel.tokenId = Globals.ProjectManager.Tokens.Aliases.GetStateTokenId({Id : this.EditAliaseId,  info : this.ValueState});
            this.tokenValueId = Utils.Id();
            this.props.onUpdate();
        }
    }
    SwitchStateToTokensOverriden() {
        const ToState = this.ValueState.ValueState.state;
        delete this.ValueState.ValueState;
        Globals.ProjectManager.States.SetStateFromLabel(ToState);
    }
    EditAliaseValue(aliase) {

        if (!this.HasEditGrant) {
            return;
        }

        this.IsNew = false;

        if (Globals.ProjectManager.AuditManager && !this.WillEditToken) {
            this.WillEditToken = Utils.DeepClone(Globals.ProjectManager.Tokens.Aliases.TokenAliase(aliase.id));
        }

        this.EditAliaseId = aliase.id;
        this.EditAliaseModel = aliase;

        this.ValueState = {
            onRemoveOverride : this.RemoveStateOverride,
            onMoveToParent : this.MoveValueToParentState,
            onApplyToCurrentState : this.ApplyValueToCurrentState,
            onSwitchToOverridenState : this.SwitchStateToTokensOverriden
        };
        Globals.ProjectManager.Tokens.Aliases.GetStateTokenId({Id : this.EditAliaseId,  info : this.ValueState});

        

        this.props.onPanelOverlay({
            show : true,
            render : (props) => {
                if (props.GlobalState !== this.props.GlobalState) {
                    if (this.ValueState)
                        delete this.ValueState.ValueState;
                    Globals.ProjectManager.Tokens.Aliases.GetStateTokenId({Id : this.EditAliaseId,  info : this.ValueState});
                }
                
                return (
                    <SC.FCol fw fh>
                        <TokenPanelHeader 
                            title='EDIT ALIASE' 
                            hasAddCancel 
                            onClone={this.onCloneAliase}
                            onDelete={this.onDeleteAliase}
                            onClose={this.onCancelAddAliase} 
                            onCancel={this.onCancelAddAliase} 
                            stateValue={this.ValueState}
                        />
                        <LeftScrollPanel>
                            <AliaseEditor 
                                type={this.props.type}
                                RefToolbar={this.props.RefToolbar}
                                id={this.EditAliaseId}
                                aliase={this.EditAliaseModel}
                                renderId={this.tokenValueId}
                                GlobalStateId={this.props.GlobalStateId}
                                GlobalState={this.props.GlobalState}
                                onClosed={() => {
                                    const tokencurrent = Globals.ProjectManager.Tokens.Aliases.TokenAliase(aliase.id);
                                    
                                    if (this.WillEditToken && tokencurrent) { 
                                        const diff = Utils.GetFlattenedDiff(this.WillEditToken, tokencurrent);
                                        if (diff.length > 0) {
                                            Globals.ProjectManager.AuditManager && Globals.ProjectManager.AuditManager.TokenChanged({TokenId : aliase.id, Change : {
                                                changes : diff
                                            }});
                                        }                                                                        
                                    }
                                    delete this.WillEditToken;
                                }}
                            />
                        </LeftScrollPanel>                        
                    </SC.FCol>                    
                )
            }
        })
    }
    EditAliase(aliase, e) {        
        if (this.props.type === Globals.ProjectManager.Tokens.Types.COLOR) {
            if (this.props.onSelectColor) {
                this.props.onSelectColor(aliase, e);
                return;
            }
        }
        else if (this.props.type === Globals.ProjectManager.Tokens.Types.Shadows) {
            if (this.props.onSelectShadow) {
                this.props.onSelectShadow(aliase, e);
                return;
            }
        }
        else if (this.props.type === Globals.ProjectManager.Tokens.Types.Borders) {
            if (this.props.onSelectBorder) {
                this.props.onSelectBorder(aliase, e);
                return;
            }
        }
        else if (this.props.type === Globals.ProjectManager.Tokens.Types.BorderRadiuses) {
            if (this.props.onSelectRadius) {
                this.props.onSelectRadius(aliase, e);
                return;
            }
        }
        else if (this.props.type === Globals.ProjectManager.Tokens.Types.Spacings) {
            if (this.props.onSelectSpace) {
                this.props.onSelectSpace(aliase, e);
                return;
            }
        }
        else if (this.props.type === Globals.ProjectManager.Tokens.Types.FigmaAutoLayouts) {
            if (this.props.onSelectAutoLayout) {
                this.props.onSelectAutoLayout(aliase, e);
                return;
            }
        }
        else if (this.props.type === Globals.ProjectManager.Tokens.Types.Fonts) {
            if (this.props.onSelectTextStyle) {
                this.props.onSelectTextStyle(aliase, e);
                return;
            }
        }
        else if (this.props.type === Globals.ProjectManager.Tokens.Types.Motion) {
            if (this.props.onSelectDuration) {
                this.props.onSelectDuration(aliase, e);
                return;
            }
        }
        else if (this.props.type === Globals.ProjectManager.Tokens.Types.MotionEase) {
            if (this.props.onSelectEaseCurve) {
                this.props.onSelectEaseCurve(aliase, e);
                return;
            }
        }
        else if (this.props.type === Globals.ProjectManager.Tokens.Types.Filters) {
            if (this.props.onSelectFilter) {
                this.props.onSelectFilter(aliase, e);
                return;
            }
        }
        this.EditAliaseValue(aliase);
        
    }
    onShowMenu(aliase, e) {
        this.props.onShowMenu && this.props.onShowMenu(aliase, e);
    }
    AddTokenToGroup(group) {
        if (!Globals.ProjectManager.CheckGrant(GRANT_TYPES.EDIT_TOKEN.ALL)) {
            return;
        }

        this.IsNew = true;
        this.EditAliaseId = Utils.Id();
        this.AddingToGroup = group;
        this.EditAliaseModel = {
            name : 'New Aliase',
            id : this.EditAliaseId
        };
        this.props.onPanelOverlay({
            show : true,
            render : (props) => {
                return (
                    <SC.FCol fw fh>
                        <TokenPanelHeader 
                            notBackClosable
                            title='NEW ALIASE' 
                            hasAddCancel 
                            onClose={this.onCancelAddAliase} 
                            onCancel={this.onCancelAddAliase} 
                            onAdd={this.onSubmitNewAliase} 
                        />
                        <LeftScrollPanel>
                            <AliaseEditor 
                                type={this.props.type}
                                RefToolbar={this.props.RefToolbar}
                                GlobalStateId={this.props.GlobalStateId}
                                isNew
                                id={this.EditAliaseId}
                                aliase={this.EditAliaseModel}
                            />
                        </LeftScrollPanel>                        
                    </SC.FCol>                    
                )
            }
        })
    }
    AddToken() {
        this.AddTokenToGroup();
    }
    onCancelAddAliase() {
        if (this.EditAliaseModel) {
            delete this.EditAliaseModel.ValueState;
            this.EditAliaseModel.tokenId = Globals.ProjectManager.Tokens.Aliases.GetStateTokenId({Id : this.EditAliaseId,  info : this.EditAliaseModel});
            this.EditAliaseModel.tokenItem = GetAliaseTokenItem(this.EditAliaseModel, this.props.type); 
        }        

        delete this.IsNew;
        delete this.EditAliaseModel;
        delete this.EditAliaseId;

        this.props.onPanelOverlay({close : true});

        

        this.RCUpdate();
    }
    onSubmitNewAliase() {
        if (this.IsNew) {
            if (!this.EditAliaseModel.tokenId) {
                Events.AlertSimple('Aliase token can not be empty!', Events.GLOBAL.NOTIFICATION.TYPES.ERROR);
                return;
            }
            AddNewTokenAliase({
                type : this.props.type,
                name : this.EditAliaseModel.name,
                id : this.EditAliaseId,
                tokenId : this.EditAliaseModel.tokenId
            });

            const aliaseItem = {
                name : this.EditAliaseModel.name,
                id : this.EditAliaseId,
                tokenId : this.EditAliaseModel.tokenId
            };

            aliaseItem.tokenItem = GetAliaseTokenItem(aliaseItem, this.props.type); 

            if (this.AddingToGroup) {
                this.AddingToGroup.tokens.push(aliaseItem);
                Globals.ProjectManager.Tokens.Aliases.AddTokenToGroup({type : this.props.type, groupid : this.AddingToGroup.id, tokenid : this.EditAliaseId})
                delete this.AddingToGroup;
            }                 
            else {
                this.aliases.push(aliaseItem)
            }

            this.WillScrollTo = this.EditAliaseId;
            this.selectedId = this.EditAliaseId;
        }        
        else {
            const aliase = Globals.ProjectManager.Tokens.Aliases.TokenAliase(this.EditAliaseId);
            if (aliase) {
                const localpattern = Utils.Find(this.aliases, (item) => {return item.id === this.EditAliaseId});
                localpattern.name = aliase.name;
                Events.BCE(Events.GLOBAL.TOKEN_VALUE_CHANGING);
            }            
        }
                
        this.onCancelAddAliase();
    }
    onCloneAliase() {
        const cloneId = Globals.ProjectManager.Tokens.Aliases.Clone(this.EditAliaseId, this.props.type);
        this.Load(this.state.isGroupView);        
        this.RCUpdate();
        const model = Utils.Find(this.aliases, (a) => {return a.id === cloneId});
        this.EditAliaseValue(model);
    }
    onDeleteAliase() {
        if (Globals.ProjectManager.Tokens.Aliases.Delete({
            type : this.props.type,
            id : this.EditAliaseId
        })) {
            this.Load(this.state.isGroupView);
            this.onCancelAddAliase();
        }
        
    }
    onDragEndToken(result) {
        const { source, destination } = result;
        if (!destination) {
            return;
        }
        if (result.type === 'GROUPS') {
            Globals.ProjectManager.Tokens.Aliases.ChangeOrderOfGroups(this.props.type, source.index, destination.index);
            Utils.ChangePlace(this.groups, source.index, destination.index);
        }
        else {
            if (this.state.isGroupView) {
                if (source.droppableId === destination.droppableId) {
                    if (source.droppableId !== 'Default') {
                        Globals.ProjectManager.Tokens.Aliases.ChangeGroupOrderOfToken(this.props.type, source.droppableId, source.index, destination.index);                
                    }
                    const group = Utils.Find(this.groups, (item) => {return item.id === source.droppableId});
                    if (group && group.tokens) {
                        Utils.ChangePlace(group.tokens, source.index, destination.index);
                    }
                    if (source.droppableId === 'Default') {
                        const orders = [];
                        Utils.ForEach(group.tokens, ({id}, ) => {
                            orders.push(id);
                        });
                        Globals.ProjectManager.Tokens.Aliases.SetDefaultGroupOrders(this.props.type, orders);
                    }
                } 
                else {
                    const sourcegroup = Utils.Find(this.groups, (item) => {return item.id === source.droppableId});
                    const targetgroup = Utils.Find(this.groups, (item) => {return item.id === destination.droppableId});
                    const token = sourcegroup.tokens[source.index]
                    sourcegroup.tokens.splice(source.index, 1);
                    Globals.ProjectManager.Tokens.Aliases.ChangeGroupOfToken(this.props.type, sourcegroup.id, targetgroup.id, token.id, destination.index);
                    targetgroup.tokens.splice(destination.index, 0, token);                    
                }
            }   
            else {
                this.onSortNoneGroupedList(source.index, destination.index, source.droppableId);                              
            }         
        }
        Events.BCE(Events.GLOBAL.TOKENS_ORDER_CHANGED, this.props.type);
        this.RCUpdate();
    }
    onSortNoneGroupedList(oldIndex, newIndex, groupType) {
        Globals.ProjectManager.Tokens.Aliases.ChangeOrder(this.props.type, oldIndex, newIndex);
        Utils.ChangePlace(this.aliases, oldIndex, newIndex);
    }
    renderContent() {

    }    
    ToggleExpandGroup(group, delayed, e) {
        e && e.stopPropagation();
        group.collapsed = !group.collapsed;
        Globals.ProjectManager.Options.Set(group.collapsed, 'TokenAliaseGroups', this.props.type, group.id, 'collapsed');        
        setTimeout(() => {
            this.RCUpdate();
        }, delayed ? 200 : 0);        
    }
    CloneAliaseGroup(group) {
        Globals.ProjectManager.Tokens.Aliases.CloneGroup(group.id, this.props.type);
        this.Load(true);
        this.RCUpdate();
    }
    ShowGroupMenu(groupId) {
        this.setState({
            showGroupMenu : this.state.showGroupMenu === groupId ? false : groupId
        })
    }
    SelectGroupMembers(group) {
        if (this.props.onSelectGroupMembers) {

        }
        else {
            if (this.props.RefToolbar && this.props.RefToolbar.current) {
                this.props.RefToolbar.current.ShowPanel({
                    type : 'GroupMembers',
                    title : 'Select Group Members',
                    group : group,
                    isAliase : true,
                    tokenType : this.props.type,
                    onRegisterClose : (callClose) => {
                        super.AddCloseCallback(callClose);
                    },                    
                    onClose : () => {
                        this.Reload();
                    },               
                    onClosed : () => {
    
                    }                
                })
            }  
        }
    }
    componentDidUpdate(prevProps, prevState) {
        delete this.WillFocustoGroupId;
    }
    renderTokenGroup(group, index) {
        return (
            <DraggableGroupHeader 
                group={group}
                index={index}
                isEditable={this.HasEditGrant}
                onSaveName={this.SaveGroupName.bind(this, group)}
                onDelete={this.DeleteGroup.bind(this, group)}
                onDeleteWithTokens={this.DeleteGroupWithTokens.bind(this, group)}
                onAddToGroup={this.AddTokenToGroup.bind(this, group)}
                onToggleExpandGroup={this.ToggleExpandGroup.bind(this, group, false)}
                willFocus={this.WillFocustoGroupId === group.id}
                onToggleExpandGroupDelayed={this.ToggleExpandGroup.bind(this, group, true)}
                onClone={this.CloneAliaseGroup.bind(this, group)}
                onShowMenu={this.ShowGroupMenu.bind(this, group.id)}
                showMenu={group.id === this.state.showGroupMenu}
                onSelectMembers={this.SelectGroupMembers.bind(this, group)}
            >
                {!group.collapsed && this.renderTokenList(group.tokens, group.id)}
            </DraggableGroupHeader>
        )        
    }
    renderTokenList(aliases, gorupId) {
        let items = [];
        if (aliases) {
            if (this.props.type === Globals.ProjectManager.Tokens.Types.COLOR) {
                aliases.map((aliase, i) => {
                    !aliase.filtered && items.push(
                        <SortableListItem
                            key={aliase.id}
                            draggableId={aliase.id}
                            index={i}
                            ItemBoxType={ColorTokenAliaseListItem}
                            isDragDisabled={!this.HasEditGrant}
                            BoxProps={{
                                aliase : aliase,
                                isDragDisabled : !this.HasEditGrant,
                                onSelect : this.EditAliase.bind(this, aliase),
                                onMenu : this.onShowMenu.bind(this, aliase)
                            }}                
                        />
                    )
                })
            }
            else if (this.props.type === Globals.ProjectManager.Tokens.Types.Shadows) {
                aliases.map((aliase, i) => {
                    Utils.IsObject(aliase) && !aliase.filtered && items.push(
                        <SortableListItem
                            key={aliase.id}
                            draggableId={aliase.id}
                            index={i}
                            ItemBoxType={ShadowTokenAliaseListItem}
                            isDragDisabled={!this.HasEditGrant}
                            BoxProps={{
                                aliase : aliase,
                                isDragDisabled : !this.HasEditGrant,
                                onSelect : this.EditAliase.bind(this, aliase),
                                onMenu : this.onShowMenu.bind(this, aliase)
                            }}                
                        />
                    )
                })
            }
            else if (this.props.type === Globals.ProjectManager.Tokens.Types.Borders) {
                aliases.map((aliase, i) => {
                    !aliase.filtered && items.push(
                        <SortableListItem
                            key={aliase.id}
                            draggableId={aliase.id}
                            index={i}
                            ItemBoxType={BorderTokenAliaseListItem}
                            isDragDisabled={!this.HasEditGrant}
                            BoxProps={{
                                aliase : aliase,
                                isDragDisabled : !this.HasEditGrant,
                                onSelect : this.EditAliase.bind(this, aliase),
                                onMenu : this.onShowMenu.bind(this, aliase)
                            }}                
                        />
                    )
                })
            }
            else if (this.props.type === Globals.ProjectManager.Tokens.Types.BorderRadiuses) {
                aliases.map((aliase, i) => {
                    !aliase.filtered && items.push(
                        <SortableListItem
                            key={aliase.id}
                            draggableId={aliase.id}
                            index={i}
                            ItemBoxType={RadiusTokenAliaseListItem}
                            isDragDisabled={!this.HasEditGrant}
                            BoxProps={{
                                aliase : aliase,
                                isDragDisabled : !this.HasEditGrant,
                                onSelect : this.EditAliase.bind(this, aliase),
                                onMenu : this.onShowMenu.bind(this, aliase)
                            }}                
                        />
                    )
                })
            }
            else if (this.props.type === Globals.ProjectManager.Tokens.Types.Spacings) {
                aliases.map((aliase, i) => {
                    !aliase.filtered && items.push(
                        <SortableListItem
                            key={aliase.id}
                            draggableId={aliase.id}
                            index={i}
                            ItemBoxType={SpacePatternAliaseItem}
                            isDragDisabled={!this.HasEditGrant}
                            BoxProps={{
                                aliase : aliase,
                                isDragDisabled : !this.HasEditGrant,
                                onSelect : this.EditAliase.bind(this, aliase),
                                onMenu : this.onShowMenu.bind(this, aliase)
                            }}                
                        />
                    )
                })
            }
            else if (this.props.type === Globals.ProjectManager.Tokens.Types.FigmaAutoLayouts) {
                aliases.map((aliase, i) => {
                    !aliase.filtered && items.push(
                        <SortableListItem
                            key={aliase.id}
                            draggableId={aliase.id}
                            index={i}
                            ItemBoxType={FigmaAutoLayoutAliaseItem}
                            isDragDisabled={!this.HasEditGrant}
                            BoxProps={{
                                aliase : aliase,
                                isDragDisabled : !this.HasEditGrant,
                                onSelect : this.EditAliase.bind(this, aliase),
                                onMenu : this.onShowMenu.bind(this, aliase)
                            }}                
                        />
                    )
                })
            }
            else if (this.props.type === Globals.ProjectManager.Tokens.Types.Fonts) {
                aliases.map((aliase, i) => {
                    !aliase.filtered && items.push(
                        <SortableListItem
                            key={aliase.id}
                            draggableId={aliase.id}
                            index={i}
                            ItemBoxType={TextPatternAliaseItem}
                            isDragDisabled={!this.HasEditGrant}
                            BoxProps={{
                                aliase : aliase,
                                isDragDisabled : !this.HasEditGrant,
                                onSelect : this.EditAliase.bind(this, aliase),
                                onMenu : this.onShowMenu.bind(this, aliase)
                            }}                
                        />
                    )
                })
            }
            else if (this.props.type === Globals.ProjectManager.Tokens.Types.Motion) {
                aliases.map((aliase, i) => {
                    !aliase.filtered && items.push(
                        <SortableListItem
                            key={aliase.id}
                            draggableId={aliase.id}
                            index={i}
                            ItemBoxType={MotionDurationAliaseItem}
                            isDragDisabled={!this.HasEditGrant}
                            BoxProps={{
                                aliase : aliase,
                                isDragDisabled : !this.HasEditGrant,
                                onSelect : this.EditAliase.bind(this, aliase),
                                onMenu : this.onShowMenu.bind(this, aliase)
                            }}                
                        />
                    )
                })
            }
            else if (this.props.type === Globals.ProjectManager.Tokens.Types.MotionEase) {
                aliases.map((aliase, i) => {
                    !aliase.filtered && items.push(
                        <SortableListItem
                            key={aliase.id}
                            draggableId={aliase.id}
                            index={i}
                            ItemBoxType={MotionEaseAliaseItem}
                            isDragDisabled={!this.HasEditGrant}
                            BoxProps={{
                                aliase : aliase,
                                isDragDisabled : !this.HasEditGrant,
                                onSelect : this.EditAliase.bind(this, aliase),
                                onMenu : this.onShowMenu.bind(this, aliase)
                            }}                
                        />
                    )
                })
            }
            else if (this.props.type === Globals.ProjectManager.Tokens.Types.Filters) {
                aliases.map((aliase, i) => {
                    !aliase.filtered && items.push(
                        <SortableListItem
                            key={aliase.id}
                            draggableId={aliase.id}
                            index={i}
                            ItemBoxType={FilterTokenAliaseListItem}
                            isDragDisabled={!this.HasEditGrant}
                            BoxProps={{
                                aliase : aliase,
                                isDragDisabled : !this.HasEditGrant,
                                onSelect : this.EditAliase.bind(this, aliase),
                                onMenu : this.onShowMenu.bind(this, aliase)
                            }}                
                        />
                    )
                })
            }
            else {
                items = aliases.map((token, i) => {
                    return (
                        <TokenItemBox key={token.id} >
                            {token.name}
                        </TokenItemBox>
                    )
                })
            }
        }
        
        return (
            <Droppable 
                droppableId={gorupId}
                type={this.props.type}
                direction={'vertical'}                
            >
                {
                     (provided, snapshot) => (
                        <div
                            {...provided.droppableProps} 
                            ref={provided.innerRef} 
                            style={{
                                ...provided.droppableProps.style,
                                marginLeft : '8px', marginRight : '8px', 
                                marginTop : this.state.isGroupView ? '6px' : '6px',
                                minHeight : (snapshot.isDraggingOver) ? '16px' : 0,
                                border : '1px dashed',                                                    
                                borderColor : snapshot.isDraggingOver ? SC.CurrentTheme.theme.color_brand : 'transparent',
                                backgroundColor : snapshot.isDraggingOver ? SC.CurrentTheme.theme.back_lightest : 'transparent'                  
                            }}
                        >
                            {items}
                            {provided.placeholder}
                        </div>
                    )
                }
            </Droppable>
        )      
    }
    renderNoneGroupedList(tokens, groupId) {
        return this.renderTokenList(this.aliases, 'Default');
    }
    renderFolder(content) {
        return (
            <TokenGroup 
                title={this.props.title} 
                hasAdd={this.HasEditGrant && this.expanded  ? {onAdd : this.state.isGroupView ? this.AddGroup : this.AddToken, groupView : this.state.isGroupView} : null}
                hasGroup={this.expanded  ? {onToggle : this.ToggleGroupView, selected : this.state.isGroupView} : null}                                
                expandable={{expanded : this.expanded,  onExpand : this.onToggleExpand}}
                last={this.props.last}
                style={{flex : 1, paddingBottom : '4px'}}
                // hasSearch={this.expanded ? {onSearch : this.SearchToken, style : {marginLeft : '10px', marginRight : '10px'}} : false}
            >
                {
                    this.expanded && 
                    <motion.div
                        initial={{opacity : 0.5, y : -4}}
                        animate={{opacity : 1, y : 0}}
                        transition={{duration  :0.2}}
                        style={{flex : 1}}
                    >
                        {
                            this.props.singleView && !this.props.noScrolls ? 
                            <LeftScrollPanel>
                                {content}        
                            </LeftScrollPanel> : content
                        }                                            
                    </motion.div>
                }                
            </TokenGroup>
        )
    }
    renderCustom() {
        let content;
        
        if (this.state.isGroupView && this.groups && this.expanded) {
            const groupItems = [];
            Utils.ForEach(this.groups, (group, i) => {
                if (!group.filtered) {
                    groupItems.push(
                        this.renderTokenGroup(group, i)
                    )
                }                
            });
            content = (
                <DragDropContext
                    onDragEnd={this.onDragEndToken}
                >                        
                    <Droppable 
                        droppableId='TokenGroups'
                        type='GROUPS'
                    >
                        {
                            provided => (
                                <div
                                    {...provided.droppableProps} 
                                    ref={provided.innerRef} 
                                    style={{
                                        ...provided.droppableProps.style,
                                        ...SC.Styles.Flex.Column,
                                        marginTop : '4px'
                                    }}
                                >
                                    {groupItems}   
                                    {provided.placeholder}
                                </div>
                            )
                        }    
                    </Droppable>                    
                </DragDropContext>
            )
        }
        else {
            content = (
                <DragDropContext
                    onDragEnd={this.onDragEndToken}
                >
                    {this.renderNoneGroupedList(this.aliases, 'Default')}
                </DragDropContext>
            )
        }
                
        return (
            <SC.FCol>
                {this.renderFolder(content)}
            </SC.FCol>
        )    
    }
}

export const AddNewTokenAliase = ({type, name, id, tokenId, groupId}) => {
    return Globals.ProjectManager.Tokens.Aliases.AddAliase({
        type : type,
        name : name,
        id : id,
        tokenId : tokenId,
        groupId : groupId
    });
}

export const GetTokenAliaseList = (type, StateArray) => {
    const aliaseIds = Globals.ProjectManager.Tokens.Aliases.Order(type);
    return GetTokenAliaseListOfIds(aliaseIds, StateArray);
}

export const GetTokenAliaseListOfIds = (aliaseIds, StateArray) => {
    const aliases = [];

    if (aliaseIds) {
        aliaseIds.map((aliaseId) => {
            const aliase = GetTokenAliaseItem(aliaseId, StateArray);
            if (aliase) {
                aliases.push(aliase)
            }
        })
    }
    return aliases;
}


export const GetAliaseTokenItem = (aliase, type, tokenId) => {    
    const useTokenId = tokenId ?? aliase.tokenId;
    const token = Globals.ProjectManager.Tokens.Token(useTokenId);
    if (token) {
        if (type === Globals.ProjectManager.Tokens.Types.COLOR) {
            const token = Globals.ProjectManager.Tokens.Token(useTokenId);
            const tokenItem = GetColorTokenItem({
                id : useTokenId,
                token : token                    
            });
            tokenItem.value = Globals.ProjectManager.Tokens.Aliases.GetColorAliaseValue(aliase.id, tokenItem.value);
            return tokenItem;
        }
        if (type === Globals.ProjectManager.Tokens.Types.Shadows) {
            return GetShadowTokenItem({
                id : useTokenId,
                token : Globals.ProjectManager.Tokens.Token(useTokenId)                    
            })
        }
        else if (type === Globals.ProjectManager.Tokens.Types.Borders) {
            return GetBorderStyleTokenValue(
                Globals.ProjectManager.Tokens.Token(useTokenId),
                useTokenId
            );
        } 
        else if (type === Globals.ProjectManager.Tokens.Types.BorderRadiuses) {
            return GetBorderRadiusTokenValue(
                Globals.ProjectManager.Tokens.Token(useTokenId),
                useTokenId
            );
        }  
        else if (type === Globals.ProjectManager.Tokens.Types.Spacings) {
            return GetSpacePatternItem(
                useTokenId
            );
        }
        else if (type === Globals.ProjectManager.Tokens.Types.FigmaAutoLayouts) {
            return GetFigmaLayoutTokenItem(
                useTokenId
            );
        }
        else if (type === Globals.ProjectManager.Tokens.Types.Fonts) {
            return GetTextPatternTokenItem({id : 
                useTokenId
            });
        }
        else if (type === Globals.ProjectManager.Tokens.Types.Motion) {
            return GetDurationTokenItemOfId(useTokenId);
        }
        else if (type === Globals.ProjectManager.Tokens.Types.MotionEase) {
            return GetEaseCurveTokenItem(useTokenId);
        }
        else if (type === Globals.ProjectManager.Tokens.Types.Filters) {
            return GetFilterTokenItem(useTokenId);
        }  
    }                              
    else {
        return {
        
        }
    }
}
export const GetTokenAliaseItem = (aliaseId, StateArray) => {
    const aliase = Globals.ProjectManager.Tokens.Aliases.TokenAliase(aliaseId);
    if (aliase) {
        const stateInfo = {};
        if (aliase.isAbsolute) {
            return {
                id : aliaseId,
                name : aliase.name,
                tokenId : aliaseId,
                // ValueState : stateInfo.ValueState
            };
        }

        const tokenId = Globals.ProjectManager.Tokens.Aliases.GetStateTokenIdOf({model : aliase, StateArray : StateArray, info : stateInfo});
        const firstReferencedId = Globals.ProjectManager.Tokens.Aliases.GetStateTokenIdOf({model : aliase, StateArray : StateArray, info : stateInfo, firstReference: true});
        if (aliase.type === 'COLOR') {

        }
        return {
            id : aliaseId,
            name : aliase.name,
            locked : aliase.locked,
            tokenId : tokenId,
            firstReferencedId: firstReferencedId ?? tokenId,
            ValueState : stateInfo.ValueState
        };
    }
}

export const GetGroupedTokenAliaseList = (tokenType, tokens) => {
    const groups = Globals.ProjectManager.Tokens.Aliases.GetGroups(tokenType);    
    return GetGroupedTokenListWithGroups(groups, tokens, false, tokenType, true);
}

export const ColorTokenAliaseListItem = ({onSelect, onMenu, notDraggable, style, sortableProps, aliase, gradient, onPreview, selected, ...rest}) => {
    const style_box = {
        ...style,
        padding : '0px', paddingLeft : '6px',
        ...SC.Styles.Flex.RowAlcJsb
    }
    if (selected) {
        style_box.borderLeft = '1px solid';
        style_box.borderLeftColor = SC.CurrentTheme.theme.color_brand;
    }
    const previewStyle = {};
    if (!aliase.tokenItem)
        return null;

    if (aliase.tokenItem.gradient)
        previewStyle.background = aliase.tokenItem.value;
    else
        previewStyle.backgroundColor = aliase.tokenItem.value;

    return (
        <TokenItemBox onClick={onSelect} onContextMenu={onMenu} style={style_box} {...onPreview} {...rest} selected={selected}>
            <SC.FRow f1 alc overflowHidden>
                <StatefulTokenListItemName 
                    name={aliase.name}
                    sortableProps={sortableProps}
                    ValueState={aliase.ValueState}
                    locked={aliase.locked}
                />                                      
            </SC.FRow>                
            <SC.GridBackground small dark={!SC.CurrentTheme.theme.isLight} style={{height : '100%', width : gradient ? '94px' : '30px', minWidth : '30px', borderRadius : '2px', overflow : 'hidden', border : SC.CurrentTheme.theme.border_ondark}}>
                <div style={{height : '24px', ...previewStyle, width : '100%'}} />
            </SC.GridBackground>
        </TokenItemBox>
    )
}

export const ShadowTokenAliaseListItem = ({onSelect, onMenu, notDraggable, style, sortableProps, aliase, gradient, onPreview, selected, ...rest}) => {
    const style_box = {
        ...style,
        padding : '0px', paddingLeft : '6px',
        ...SC.Styles.Flex.RowAlcJsb
    }
    if (selected) {
        style_box.borderLeft = '1px solid';
        style_box.borderLeftColor = SC.CurrentTheme.theme.color_brand;
    }
    const previewStyle = {};
    if (gradient)
        previewStyle.background = aliase.tokenItem.value;
    else
        previewStyle.backgroundColor = aliase.tokenItem.value;

    return (
        <TokenItemBox onClick={onSelect} onContextMenu={onMenu} style={style_box} {...onPreview} {...rest} selected={selected}>
            <SC.FRow f1 alc overflowHidden>
                <StatefulTokenListItemName 
                    name={aliase.name}
                    sortableProps={sortableProps}
                    ValueState={aliase.ValueState}
                    locked={aliase.locked}
                />
            </SC.FRow>                
            <ShadowCardItem
                shadow={aliase.tokenItem}
                textShadow={aliase.tokenItem.textShadow}
                key={aliase.id} 
                notDraggable={notDraggable}
            />
        </TokenItemBox>
    )
}

export const BorderTokenAliaseListItem = ({onSelect, onMenu, notDraggable, style, sortableProps, aliase, gradient, onPreview, selected, ...rest}) => {
    const style_box = {...SC.Styles.Flex.RowAlcJsb, ...style};
    
    if (!aliase.tokenItem) {
        console.log(`ASSERT : NULL TOKEN`);
        return null;
    }

    return (
        <TokenItemBox  onClick={onSelect} onContextMenu={onMenu} {...rest} selected={selected} style={style_box}>
            <StatefulTokenListItemName 
                name={aliase.name}
                sortableProps={sortableProps}
                ValueState={aliase.ValueState}
                locked={aliase.locked}
            />  
            <SC.FRow f1 alc justifyEnd>
                <SC.TextSpanAbbr style={{...SC.Styles.FontStyles.Monospace}}>
                    {aliase.tokenItem.width} {aliase.tokenItem.style}
                </SC.TextSpanAbbr> 
                <div style={{
                    borderTopStyle : aliase.tokenItem.style,
                    borderTopWidth : aliase.tokenItem.width,
                    borderTopColor : aliase.tokenItem.color,
                    flex : 1,
                    transition : 'all 0.3s ease',
                    maxWidth : '80px',
                    marginLeft : '8px'
                }} />                                 
            </SC.FRow>                                
        </TokenItemBox>      
    )
}

export const RadiusTokenAliaseListItem = ({onSelect, onMenu, notDraggable, style, sortableProps, aliase, gradient, onPreview, selected, ...rest}) => {
    const style_box = {...SC.Styles.Flex.RowAlcJsb,  ...style};
    
    return (
        <TokenItemBox  onClick={onSelect} onContextMenu={onMenu} {...rest} selected={selected} style={style_box}>
            <StatefulTokenListItemName 
                name={aliase.name}
                sortableProps={sortableProps}
                ValueState={aliase.ValueState}
                locked={aliase.locked}
            />                          
            <SC.FRow f1 alc justifyEnd>
                <SC.TextSpanAbbr style={{...SC.Styles.FontStyles.Monospace}}>
                    {aliase.tokenItem.style}
                </SC.TextSpanAbbr>                                            
            </SC.FRow>                                
        </TokenItemBox>      
    )
}

export const SpacePatternAliaseItem = ({onSelect, onMenu, notDraggable, style, sortableProps, aliase, onPreview, selected, ...rest}) => {
    const style_box = {...style};
    const absvalue = Math.abs(aliase.tokenItem.spaceValue);
    const style_preview = {
        minWidth : Utils.px(absvalue),
        minHeight : Utils.px(absvalue),
        maxWidth : Utils.px(absvalue),
        maxHeight : Utils.px(absvalue),
        backgroundColor : SC.CurrentTheme.theme.color_brand,
        transition : 'all 0.3s ease'
    };
    if (aliase.tokenItem.spaceValue < 0) {
        style_preview.backgroundColor = '#e252d7';
    }
    if (true) {
        delete style_preview.minHeight;
        style_preview.maxHeight = '4px';
        style_preview.minHeight = '4px';
        delete style_preview.border;
    }
    return (
        <TokenItemBox onClick={onSelect} onContextMenu={onMenu} {...rest} style={style_box} {...onPreview} >
            <SC.FRow alc jsb style={{marginBottom : '8px'}}>
                <SC.FRow f1 alc overflowHidden>
                    <StatefulTokenListItemName 
                        name={aliase.name}
                        sortableProps={sortableProps}
                        ValueState={aliase.ValueState}
                        locked={aliase.locked}
                    /> 
                </SC.FRow>                
                <div style={{fontWeight : 'bold', ...SC.Styles.FontStyles.Monospace}}><span style={{marginRight : '4px', marginLeft : '4px'}}>{aliase.tokenItem.spaceValue}</span>{aliase.tokenItem.spaceUnit}</div>
            </SC.FRow>
            <div style={style_preview}>
            </div>
        </TokenItemBox>
    )
}

export const TextPatternAliaseItem = ({onSelect, onMenu, notDraggable, style, sortableProps, aliase, onPreview, selected, ...rest}) => {
    const style_box = {...style};    

    return (
        <TokenItemBox onClick={onSelect} onContextMenu={onMenu} {...rest} style={style_box} {...onPreview} >
            <SC.FRow alc jsb style={{marginBottom : '8px'}}>
                <SC.FRow f1 alc overflowHidden>
                    <StatefulTokenListItemName 
                        name={aliase.name}
                        sortableProps={sortableProps}
                        ValueState={aliase.ValueState}
                        locked={aliase.locked}
                    /> 
                </SC.FRow>                
                <div style={{fontWeight : 'bold', ...SC.Styles.FontStyles.Monospace}}><span style={{marginRight : '4px', marginLeft : '4px'}}>{aliase.tokenItem.spaceValue}</span>{aliase.tokenItem.spaceUnit}</div>
            </SC.FRow>
            <TextPattern 
                pattern={aliase.firstReferenceItem?.pattern ?? aliase.tokenItem?.pattern}
                listView
                justContent
                sortableProps={sortableProps}
            />
        </TokenItemBox>
    )
}

export const MotionDurationAliaseItem = ({onSelect, onMenu, notDraggable, style, sortableProps, aliase, onPreview, selected, ...rest}) => {
    const style_box = {...style, ...SC.Styles.Flex.RowAlcJsb};
    const timeValue = Math.abs(aliase.tokenItem.timeValue);
    
    return (
        <TokenItemBox onClick={onSelect} onContextMenu={onMenu} {...rest} style={style_box} {...onPreview} >
            <SC.FRow f1 alc overflowHidden>
                <StatefulTokenListItemName 
                    name={aliase.name}
                    sortableProps={sortableProps}
                    ValueState={aliase.ValueState}
                    locked={aliase.locked}
                /> 
            </SC.FRow>                
            {
                timeValue && 
                <SC.FRow style={{marginLeft  :'8px', fontWeight : 'bold', ...SC.Styles.FontStyles.Monospace}} alc>
                    {timeValue}
                    <div style={{marginLeft : '4px', fontSize : '11px'}}>ms</div>
                </SC.FRow>
            }
        </TokenItemBox>
    )
}

export const MotionEaseAliaseItem = ({onSelect, onMenu, notDraggable, style, sortableProps, aliase, onPreview, selected, ...rest}) => {
    const style_box = {...style, ...SC.Styles.Flex.RowAlcJsb};
    
    return (
        <TokenItemBox onClick={onSelect} onContextMenu={onMenu} {...rest} style={style_box} {...onPreview} >
            <SC.FRow f1 alc overflowHidden>
                <StatefulTokenListItemName 
                    name={aliase.name}
                    sortableProps={sortableProps}
                    ValueState={aliase.ValueState}
                    locked={aliase.locked}
                /> 
            </SC.FRow>        
            <PresetCurve path={aliase.tokenItem.path} size={48} selected  />
        </TokenItemBox>
    )
}

export const FilterTokenAliaseListItem = ({onSelect, onMenu, notDraggable, style, sortableProps, aliase, onPreview, selected, ...rest}) => {
    const style_box = {...SC.Styles.Flex.RowAlcJsb,  ...style};
    
    return (
        <TokenItemBox  onClick={onSelect} onContextMenu={onMenu} {...rest} selected={selected} style={style_box}>
            <StatefulTokenListItemName 
                name={aliase.name}
                sortableProps={sortableProps}
                ValueState={aliase.ValueState}
                locked={aliase.locked}
            />                          
            <FilterCardItem
                filter={aliase.tokenItem}
                key={aliase.id} 
                notDraggable={notDraggable}
                small
                listView
            />                              
        </TokenItemBox>      
    )
}

export const FilterAliaseItems = ({filter, groups, aliases}) => {
    let filterText;
    if (Utils.IsNotNullOrEmpty(filter))
        filterText = filter.toUpperCase();
        
    if (groups) {
        Utils.ForEach(groups, (group, g) => {
            delete group.filtered;

            group.filtered = filterText ? true : false;
            Utils.ForEach(group.tokens, (token, t) => {
                delete token.filtered;
                if (filterText) {
                    if (token.name.toUpperCase().indexOf(filterText) < 0) {                    
                        token.filtered = true;
                    }
                    if (token.filtered && token.tokenItem && token.tokenItem.value) {
                        if (token.tokenItem.value.toString().toUpperCase().indexOf(filterText) >= 0)
                            delete token.filtered;
                    }
                    if (!token.filtered)
                        group.filtered = false;
                }
            });
        });
    }
    else {
        Utils.ForEach(aliases, (aliase, i) => {
            delete aliase.filtered;
            if (filterText && aliase.name) {
                if (aliase.name.toUpperCase().indexOf(filterText) < 0) {                    
                    aliase.filtered = true;
                }
            }
        });
    }
}


export const FigmaAutoLayoutAliaseItem = ({onSelect, onMenu, aliase, ...rest}) => {

    return (
        <FigmaLayoutListItem 
            name={aliase.name}
            FigmaLayout={aliase.tokenItem}
            onSelect={onSelect}
            onContextMenu={onMenu}
            {...rest}
        />
    )    
}

export const AliaseTokenItemReferance = ({name}) => {
    return (
        <SC.FRow justifyEnd alc style={{paddingRight : '6px', marginTop : '-4px'}}>
            <div>{name}</div>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" style={{marginTop : '-2px'}}>
                <path
                    fill="#000"
                    fillOpacity="0.8"
                    fillRule="evenodd"
                    d="M8.854 17.293l-1.499-1.498c1.736.102 2.921-.183 3.736-.775 1.029-.749 1.336-1.896 1.525-2.906.039-.205.071-.398.103-.585.051-.304.1-.591.165-.882.102-.454.231-.833.427-1.136.188-.291.447-.525.842-.68.239-.094.536-.162.909-.19a2 2 0 10.003-1.003 4.19 4.19 0 00-1.277.262c-.6.235-1.021.61-1.317 1.069-.29.447-.45.959-.562 1.458-.07.312-.128.652-.184.98-.03.18-.06.357-.091.522-.186.99-.441 1.78-1.131 2.282-.596.433-1.61.717-3.422.561L8.854 13l-.708-.707-2.5 2.5-.353.353.353.354 2.5 2.5.708-.707zM16 8.146a1 1 0 10.01-.143l-.01.143z"
                    clipRule="evenodd"
                ></path>
                </svg>
        </SC.FRow>
    )
}