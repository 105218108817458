import React from 'react';
import ReactDOM from 'react-dom';

import {
    MetaData,
    Utils,
    UIUtils,
    SC,
    Strings,
    AppLayout
} from '../../../../../../../importer';

import BaseGeneric from '../base';
import {RenderItemIcon} from '../../icon';
import { GenericAccordion_SubTypeIds } from '../../../../../../../../toolabs-modules/toolabs-metaui/components/generic/initialize/accordion';

export default class Generic_Tabs extends BaseGeneric {
    constructor(props) {
        super(props);        
        
        if (this.props.GenericDesigner) {
            this.slides = {
                items : [
                    {Id : 1, name : 'Slide 1'},
                    {Id : 2, name : 'Slide 2'}
                ]
            };

            this.state.active = 0;
        }        
    }
    UpdateStyle(props) {
        this.renderData = props.GetComponentManager().GetItemStyle(props.Id);        

        const MetaItem = this.props.GetComponentManager().GetMetaItem(this.props.Id);
        this.renderDataSubTypeStyles = {};
        GenericAccordion_SubTypeIds.map((SubType) => {
            const SubTypeId = Utils.JustGet(MetaItem.Generic, null, SubType.Id);         
            this.renderDataSubTypeStyles[SubType.Id] = props.GetComponentManager().GetItemStyle(SubTypeId);
        })

        if (this.props.GenericDesigner) {

        }
        else {
            this.slides = Utils.JustGet(this.props, {items : []}, 'Slides');
            if (!this.slides.items)
                this.slides.items = [];
            const index = Math.max(0, Utils.FindIndex(this.slides.items, (slide) => {return slide.Id === this.slides.activeSlide}));
            this.state.active = index || 0;
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (!this.props.isDropTarget && nextProps.isDropTarget || this.props.isDropTarget && !nextProps.isDropTarget) {                     
            return true;
        }
        if (nextState.active !== this.state.active)
            return true;
        if (Utils.HasAnyChange(this.props, nextProps, 'Slides')) {
            this.slides = Utils.JustGet(nextProps, {items : []}, 'Slides');
            if (!this.slides.items)
                this.slides.items = [];
            const index = Math.max(0, Utils.FindIndex(this.slides.items, (slide) => {return slide.Id === this.slides.activeSlide}));
            this.state.active = index || 0;
            return true;
        }
        return super.shouldComponentUpdate(nextProps, nextState);
    }    
    SelectTab(index) {
        if (this.props.GenericDesigner) {

        }
        else {
            const slide = this.slides.items[index];
            this.props.Slides.activeSlide = slide.Id;
            this.props.GetComponentManager().isSubComponent.GetManager().GetDesignManager().SetItemProp(this.props.GetComponentManager().isSubComponent.ItemId, this.props.Slides, 'Slides');
        }

        this.setState({
            active : index
        })
    }
    renderContent({style, props}) {        
        let style_item = {
            ...style,
            overflow : 'hidden'
        };
        
        let vertical = true;

        if (this.props.GenericDesigner) {
            style_item = {
                ...style, flex : 1, height : 'unset', width : 'unset', alignSelf : 'stretch', position : 'relative', overflow : 'hidden'
            }
        }
        else {            
        }

        style_item.display = 'flex';
        style_item.flexDirection = 'column';
        style_item.alignItems = 'stretch';
        
        
        return (
            <div {...props} style={style_item}>
                {
                    this.slides && this.slides.items && this.slides.items.map((slide, i) => {                        
                        let selectedStyle;
                        if (this.state.active === i) {
                            selectedStyle = this.renderDataSubTypeStyles.HeaderSelected.style;
                        };
                        let firstLastStyle;
                        if (i === 0)
                            firstLastStyle = this.renderDataSubTypeStyles.HeaderFirst.style;
                        else if (i === this.slides.items.length - 1)
                            firstLastStyle = this.renderDataSubTypeStyles.HeaderLast.style;
                        return (
                            <React.Fragment key={slide.Id}>
                                <div 
                                    style={{
                                        ...this.renderDataSubTypeStyles.Header.style,
                                        ...firstLastStyle,
                                        ...selectedStyle,
                                    }}
                                    onClick={this.SelectTab.bind(this, i)}
                                >
                                    Header {i+1}
                                </div>
                                {
                                    this.state.active === i && 
                                    <div 
                                        style={{
                                            ...this.renderDataSubTypeStyles.Content.style,
                                            display : 'flex'
                                        }}
                                    >
                                        <TabContent 
                                            slide={slide}                       
                                            renderSlide={this.props.GenericDesigner ? () => (
                                                <div>Content</div>
                                            ) : this.props.renderSlide}
                                        />
                                    </div>
                                }
                            </React.Fragment>
                        )
                    })
                }                
            </div>
        )
    }
}

class TabContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
        
    render() { 
        const style = {
            flex : 1,
            display : 'flex',
            alignItems : 'stretch',
            transition : 'transform 0.4s ease-in-out',
        };
        
      
        return (  
            <div
                style={style}
            >
                {this.props.renderSlide && this.props.renderSlide(this.props.slide)}
            </div>
        );
    }
}
