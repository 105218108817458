import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    MetaData,
    Strings,
    Globals
} from '../../../../../../../importer';
import { TokenGroup, TokenItemBox } from '../../common';

import BaseGroup from '../base';
import { StyleToken_Color, PropertyLabel } from '../../../../right/iteminspector/styleitems/common';
import SizeEditor from '../../../../right/iteminspector/styleitems/size';
import { TextStyleEditors } from '../../../../right/iteminspector/styleitems/text';
import LightDarkBar from '../../../../../../../components/light_dark_bar';

export default class Icons extends BaseGroup
{
    constructor(props) {
        super(props);        
        this.elementType = Globals.ProjectManager.Templates.Types.Icon;
        this.title = 'ICON';
        this.DefaultId = Globals.ProjectManager.Templates.DefaultTemplateId(this.elementType);

        this.LoadOptions();        
    }
    SelectColor(templateId, colorId) {
        const backgroundTokenId = Globals.ProjectManager.Templates.GetStateValue({Id : Globals.ProjectManager.Templates.DefaultTemplateId(Globals.ProjectManager.Templates.Types.Container), name : 'backgroundColor'});
        let onBackgroundColor;
        if (backgroundTokenId) 
            onBackgroundColor = Globals.ProjectManager.Tokens.GetStateValue({Id : backgroundTokenId, StateArray : this.props.StateArray});
        this.SelectToken({templateId : templateId, propName : 'fill', type : AppState.ItemTypes.BOARD.COLOR, title : 'ICON COLOR',
            props : {
                text : true,
                onBackgroundColor : onBackgroundColor,
                hasAccessibility : true,
                fill : colorId,
                propName : 'fill'
            }
        });
    }
    onChangeSize(prop, size) {
        Globals.ProjectManager.Templates.SetValue({id : this.DefaultId, value : size, name : prop, type : this.elementType});
        this.RCUpdate();
    }
    renderDefault() {
        const tokenIdcolor = Globals.ProjectManager.Templates.GetStateValue({Id : this.DefaultId,  name : 'fill'});
        const width = Globals.ProjectManager.Templates.GetStateValue({Id : this.DefaultId,  name : 'width'}) || { value : 50};
        const height = Globals.ProjectManager.Templates.GetStateValue({Id : this.DefaultId,  name : 'height'}) || { value : 50};

        const isDark = Utils.UseNull( Globals.ProjectManager.Templates.GetStateValue({Id : this.DefaultId,  name : 'preview'}), !SC.CurrentTheme.theme.isLight);
        return (
            <SC.FCol style={{padding : '8px'}}>      
                {/* <SC.FRow jsb alc style={{paddingRight : '8px', marginBottom : '12px'}}>
                    <div>Preview Background</div>
                    <SC.FRow>
                        <LightDarkBar 
                            small 
                            Dark={isDark}
                            medium
                            onChange={(e) => {                                    
                                Globals.ProjectManager.Templates.SetValue({id : this.DefaultId, value : !isDark, name : 'preview', type : this.elementType});
                                this.RCUpdate();
                            }}
                        />
                    </SC.FRow>                        
                </SC.FRow> */}
                <StyleToken_Color 
                    style={{marginTop : 0, marginBottom : '4px'}}
                    tokenId={tokenIdcolor} 
                    onSelect={this.SelectColor.bind(this, this.DefaultId, tokenIdcolor)} 
                />             
                <div style={{
                    display : 'grid',
                    gridTemplateColumns : '1fr 1fr 1fr 1fr',
                    gridGap : '8px',
                    marginTop : '8px',
                    alignItems : 'center'
                }}
                >
                    <div>Width</div>
                    <SizeEditor 
                        value={width.value}
                        unit={width.unit}
                        onChange={this.onChangeSize.bind(this, 'width')}
                        onChanging={this.onChangeSize.bind(this, 'width')}
                    />
                    <div>Height</div>
                    <SizeEditor 
                        value={height.value}
                        unit={height.unit}
                        onChange={this.onChangeSize.bind(this, 'height')}
                        onChanging={this.onChangeSize.bind(this, 'height')}
                    />
                </div>
            </SC.FCol>
        )
    }    
}