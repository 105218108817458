import React from 'react';

const cls2 = '#fff';
const cls3 = '#4550e5';
const cls4 = '#d6daff';

export default (props) =>
<svg xmlns="http://www.w3.org/2000/svg" id="Duotone" viewBox="0 0 100 112" width={props.size || 140} >    
    <g id="Icons">
        <g id="Background">
        <g id="New_Symbol_73-9" data-name="New Symbol 73">
            <path d="M89.49 56a40 40 0 1 0-76.44 16.5h72.87A39.86 39.86 0 0 0 89.49 56z" fill={props.circleFill || "#f0f2ff"}/>
            <path d="M10.49 72.49h78M5.99 72.49h2.5M90.49 72.49h2.5" stroke='#4550e5' strokeLinecap='round' strokeLinejoin='round' fill='none'/>
        </g>
        </g>
        <g id="Scene">
        <ellipse cx="64.07" cy="85.26" fill='#f0f2ff' rx="11.5" ry="1.73"/>
        <path d="M55.21 73.65h-29v-43a1.15 1.15 0 0 1 1.15-1.15h26.7a1.14 1.14 0 0 1 1.15 1.15z" fill='#fff'/>
        <path d="M55.21 73.65h-29v-43a1.15 1.15 0 0 1 1.15-1.15h26.7a1.14 1.14 0 0 1 1.15 1.15zM37.27 43.98h6.9M31.75 46.74h17.94" stroke='#4550e5' strokeLinecap='round' strokeLinejoin='round' fill='none'/>
        <path d="M44.86 37.08a4.14 4.14 0 1 1-4.14-4.14 4.14 4.14 0 0 1 4.14 4.14zM23.47 60.54l15.29-10.19a2.28 2.28 0 0 1 2.55 0l15.28 10.19-12.42 7.59h-8.28z" stroke='#4550e5' strokeLinecap='round' strokeLinejoin='round' fill='#d6daff'/>
        <path d="M30 54.33h20.07a1 1 0 0 1 1 1v12.8H29v-12.8a1 1 0 0 1 1-1z" fill='#fff'/>
        <path d="M30 54.33h20.07a1 1 0 0 1 1 1v12.8H29v-12.8a1 1 0 0 1 1-1z" stroke='#4550e5' strokeLinecap='round' strokeLinejoin='round' fill='none'/>
        <path d="M42.1 59.85a2.07 2.07 0 1 1-2.1-2.07 2.07 2.07 0 0 1 2.1 2.07z" stroke='#4550e5' strokeLinecap='round' strokeLinejoin='round'  fill='#d6daff'/>
        <path d="M37.27 64.68h5.52" stroke='#4550e5' strokeLinecap='round' strokeLinejoin='round' fill='none'/>
        <path d="M44.17 68.13h-8.28l-12.42-7.59v17.25h33.12V60.54l-12.42 7.59z" fill='#fff'/>
        <path d="M44.17 68.13h-8.28l-12.42-7.59v17.25h33.12V60.54l-12.42 7.59z" stroke='#4550e5' strokeLinecap='round' strokeLinejoin='round' fill='none'/>
        <path d="M23.47 77.79l12.42-9.66h8.28l12.42 9.66H23.47zM71.45 81.93a1.38 1.38 0 0 1-.67-2.59c3-1.13 6.86-1.74 6.86-6.43a2 2 0 0 0-2-2h-2.83a1.38 1.38 0 0 1 0-2.76h2.81a4.79 4.79 0 0 1 4.78 4.78c0 6.61-5.8 7.94-8.28 8.84a1.35 1.35 0 0 1-.67.16zM67.78 69.51l-4-1.06 4.74-18a2.07 2.07 0 1 1 4 1.05z" fill='#fff' stroke='#4550e5' strokeLinecap='round' strokeLinejoin='round'/>
        <path d="M72 53.49l1.33.35a2.07 2.07 0 0 1 1.48 2.53l-1.41 5.34" stroke='#4550e5' strokeLinecap='round' strokeLinejoin='round' fill='none'/>
        <path d="M66.7 55.57h4.14v4.14H66.7z" stroke='#4550e5' strokeLinecap='round' strokeLinejoin='round' transform="rotate(14.74 68.77 57.65)"  fill='#d6daff'/>
        <path d="M59.01 51.57h4.14v15.18h-4.14zM59.01 51.56l2.07-5.51 2.07 5.51h-4.14z" fill='#fff' stroke='#4550e5' strokeLinecap='round' strokeLinejoin='round'/>
        <path d="M55.87 66.75h15.94a1 1 0 0 1 1 1v13.34a3.6 3.6 0 0 1-3.6 3.6H58.47a3.6 3.6 0 0 1-3.6-3.6V67.75a1 1 0 0 1 1-1z" fill='#fff'/>
        <path d="M55.87 66.75h15.94a1 1 0 0 1 1 1v13.34a3.6 3.6 0 0 1-3.6 3.6H58.47a3.6 3.6 0 0 1-3.6-3.6V67.75a1 1 0 0 1 1-1z" stroke='#4550e5' strokeLinecap='round' strokeLinejoin='round' fill='none'/>
        <circle cx="63.84" cy="74.34" r="3.45" stroke='#4550e5' strokeLinecap='round' strokeLinejoin='round'  fill='#d6daff'/>
        <path d="M60.39 80.55h6.9" stroke='#4550e5' strokeLinecap='round' strokeLinejoin='round' fill='none'/>
        </g>
    </g>
</svg>


