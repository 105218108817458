import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    UIUtils,
    Globals
} from '../../../../../../../importer';

import styled, {css} from 'styled-components';
import { ItemNameEditor, TokenPanelHeader, TokenGalleryView, DropFileArea, DropAreaContainer } from '../../common';
import RadioGroup from '../../../../../../../components/editors/radiogroup';
import TextValue from '../../../../../../../components/editors/textvalue';
import { LeftScrollPanel } from '../../../common';
import UnsplashImageGallery from '../../../../../selectors/image/unsplash';
import Dropzone from 'react-dropzone';

export default class ImageEditor extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.SaveName = this.SaveName.bind(this);
        this.GetItems = this.GetItems.bind(this);

        this.onDrop = this.onDrop.bind(this);
        this.Select = this.Select.bind(this);
        this.SelectOnlineImage = this.SelectOnlineImage.bind(this);
        this.SaveUnsplashImage = this.SaveUnsplashImage.bind(this);

        this.RemoveStateOverride = this.RemoveStateOverride.bind(this);        
        this.MoveValueToParentState = this.MoveValueToParentState.bind(this);
        this.SwitchStateToTokensOverriden = this.SwitchStateToTokensOverriden.bind(this);
        this.ApplyValueToCurrentState = this.ApplyValueToCurrentState.bind(this);

        this.ValidateUrl = this.ValidateUrl.bind(this);
        this.OnUrlChange = this.OnUrlChange.bind(this);

        this.Ref_Name = React.createRef();

        if (this.props.newModel) {
            this.token = this.props.newModel;
            this.tokenvalue = {
                
            }
        }
        else {
            this.LoadToken(this.props.id);
        }
    }
    RemoveStateOverride() {
        if (this.ValueState.ValueState && !this.ValueState.ValueState.inherited) {
            Globals.ProjectManager.Tokens.DeleteValue({
                id : this.props.id,
                state : this.ValueState.ValueState.state
            })
            this.LoadToken(this.props.id);
            Events.BCE(Events.GLOBAL.TOKENS_CHANGED);
        }        
    }
    MoveValueToParentState() {
        if (this.ValueState.ValueState) {
            Globals.ProjectManager.Tokens.MoveValueToState({
                id : this.props.id,
                fromState : this.ValueState.ValueState.state
            });
            this.LoadToken(this.props.id);
            this.RCUpdate();
        }
    }
    ApplyValueToCurrentState() {
        if (this.ValueState.ValueState) {
            Globals.ProjectManager.Tokens.MoveValueToState({
                id : this.props.id,
                fromState : this.ValueState.ValueState.state,
                toState : Globals.ProjectManager.CurrentState
            });
            this.LoadToken(this.props.id);
            this.RCUpdate();
        }
    }
    SwitchStateToTokensOverriden() {
        const ToState = this.ValueState.ValueState.state;
        delete this.ValueState.ValueState;
        Globals.ProjectManager.States.SetStateFromLabel(ToState);
    }
    LoadToken(id) {
        this.token = Globals.ProjectManager.Tokens.Token(id);
        if (this.token) {
            this.ValueState = {
                onRemoveOverride : this.RemoveStateOverride,
                onMoveToParent : this.MoveValueToParentState,
                onApplyToCurrentState : this.ApplyValueToCurrentState,
                onSwitchToOverridenState : this.SwitchStateToTokensOverriden
            };
            this.tokenvalue = Utils.DeepClone(Globals.ProjectManager.Tokens.ValueOf({model : this.token, info : this.ValueState})) || {};
        }
    }
    componentWillUnmount() {
        this.props.onClosed && this.props.onClosed();
        super.componentWillUnmount();        
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.id !== this.props.id || this.props.GlobalStateId !== nextProps.GlobalStateId) {
            this.LoadToken(nextProps.id);
            if (!this.token) {
                this.props.onCancelAddImage();
                return false;
            }
        }
        return true;
    }
    GetItems() {
        return Globals.ProjectManager.Tokens.TokenList(Globals.ProjectManager.Tokens.Types.Images);
    }        
    SaveValue() {
        if (this.props.newModel) {

        }
        else {
            Globals.ProjectManager.Tokens.SetValue({
                id : this.props.id,
                value : this.tokenvalue,
                type : Globals.ProjectManager.Tokens.Types.Images
            })
            const ValueState = Utils.Get(this.ValueState, {
                state : Globals.ProjectManager.CurrentState
            }, 'ValueState');
            ValueState.inherited = false;
        }        
    }
    SaveName(name) {
        if (this.props.newModel)
            this.props.newModel.name = name;
        else
            Globals.ProjectManager.Tokens.ChangeTokenName(this.props.id, name);
        this.RCUpdate();
    }

    Select(e) {
        e && e.stopPropagation();
        this.refs.Ref_File && this.refs.Ref_File.click()
    }
    onDrop(files) {
        if (files && files.length > 0) {
            this.tokenvalue.url = URL.createObjectURL(files[0]);
            this.tokenvalue.provider = 'File';
            if (this.props.newModel)
                this.token.willUploadFile = files[0];
            else
                this.props.info.willUploadFile = files[0];
            this.RCUpdate();
        }
    }     
    HasError() {
        this.Error_Value = false;
        if (this.props.item.provider === 'File') {
            if (!this.props.item.file) {
                this.Error_Value = true;
                this.RCUpdate();
                return true;
            }
        }    
        else {
            if (!this.props.item.onlineImage) {
                this.Error_Value = true;
                this.RCUpdate();
                return true;
            }
        }    
    }
    SelectOnlineImage(e) {        
        e && e.stopPropagation();
        this.ShowGallery = {

        }
        if (this.props.onToggleGalleryView)
            this.props.onToggleGalleryView();
        else
            this.RCUpdate();        
    }
    SaveUnsplashImage(onlineImage) {
        if (!this.props.newModel)
            Globals.ProjectManager.LogTokenChange({Desc : 'Change Image Token'});                   
        this.tokenvalue = {
            provider : 'Unsplash',
            imageId : onlineImage.id,
            url : onlineImage.url,
            width : onlineImage.width,
            height : onlineImage.height
        }
        this.SaveValue();
        delete this.ShowGallery;
        if (this.props.onToggleGalleryView)
            this.props.onToggleGalleryView();
        else
            this.RCUpdate();
    }
    UploadFile(tokenId) {
        return new Promise((resolve) => {
            this.IsLoading = true;
            this.RCUpdate();
            let MetaImage = {
                file: this.willUploadFile,
                name: this.willUploadFile.name,
                provider : 'File'
            };
            Globals.ProjectManager.UploadImageFile(tokenId, this.willUploadFile, MetaImage, 
                () => {           
                    if (!this.props.newModel)
                        Globals.ProjectManager.LogTokenChange({Desc : 'Change Image Token'});                   
                    delete this.willUploadFile;         
                    if (this.tokenvalue.UrlId) {
                        Globals.ProjectManager.DataManager.Storage.Delete(this.tokenvalue.UrlId);
                        delete this.tokenvalue.UrlId;
                    }                       
                    this.IsLoading = false;
                    this.token = Globals.ProjectManager.Tokens.Token(tokenId);
                    this.tokenvalue = Globals.ProjectManager.Tokens.ValueOf({model : this.token});
                    this.RCUpdate();
                    resolve(MetaImage);
                },
                (prog) => {
                    this.LoadingProgress = prog;
                    this.RCUpdate();
                }
            );
        })
        
    }
    ValidateUrl(url) {

    }
    OnUrlChange() {
        this.tokenvalue.provider = 'Url';
        this.RCUpdate();
        if (!this.props.newModel) {     
            Globals.ProjectManager.LogTokenChange({Desc : 'Change Image Token'});                   
            if (this.tokenvalue.UrlId) {
                Globals.ProjectManager.DeleteStorageImage(this.tokenvalue.UrlId);
                delete this.tokenvalue.UrlId;
            }                        
        }
        this.SaveValue();
        this.RCUpdate();
    }    

    renderCustom() {

        let preview = null;
        let previewUrl = this.tokenvalue.url;
        
        if (Utils.IsNotNullOrEmpty(previewUrl)) {
            preview = (
                <SC.GridBackground small dark={!SC.CurrentTheme.theme.isLight} >
                    <StyleItem_Image_Box 
                        backgroundImage={previewUrl} 
                        style={{height:'120px', position:'relative'}}
                        onClick={() => this.SelectOnlineImage()}
                    >
                        <SC.Overlay>
                            Click to Change
                        </SC.Overlay>
                        {
                            this.IsLoading ? <SC.Loading_Icon /> : null
                        }                    
                    </StyleItem_Image_Box>
                </SC.GridBackground>                
            )
        }
           
        return (
            <SC.FCol fw fh>
                {
                    !this.ShowGallery && 
                    <React.Fragment>
                        {
                            !this.props.noHeader && 
                            <TokenPanelHeader title={this.props.newModel ? 'NEW IMAGE' : 'EDIT IMAGE'} 
                                hasAddCancel 
                                notBackClosable={this.props.newModel ? true : false}
                                onClose={this.props.onCancelAddImage} 
                                onCancel={this.props.onCancelAddImage} 
                                onAdd={() => {
                                    if (this.props.newModel) {
                                        this.props.newModel.value = this.tokenvalue;
                                    }
                                    this.props.onSubmitNewImage();
                                }} 
                                onDelete={this.props.onDeleteImage}
                                stateValue={this.ValueState}
                            />
                        }                        
                        <LeftScrollPanel>
                            <SC.FCol fw fh f1 style={{padding : '10px', boxSizing : 'border-box', backgroundColor : SC.CurrentTheme.theme.back}}>
                                <ItemNameEditor
                                    noMargin
                                    fontSize='12px'                        
                                    name={this.token.name}
                                    onSaveName={this.SaveName}
                                    onGetItems={this.GetItems}
                                    model={this.token}
                                    readOnly={this.token.locked}
                                    lockable={{
                                        isLocked : this.token.locked,
                                        onTokenLock : () => {
                                            this.token.locked = Globals.ProjectManager.Tokens.ToggleTokenLock(this.props.id);                                    
                                            this.RCUpdate();
                                        }
                                    }}
                                />  
                                <SC.FCol f1 jsb style={{marginTop : '16px', pointerEvents : this.token.locked ? 'none' : 'all'}}>
                                    {preview}
                                    <Dropzone
                                        onDrop={this.onDrop}
                                        ref={(r) => this.Ref_DropZone = r}
                                        style={{}}
                                        accept="image/*"
                                        activeStyle={{}}                
                                    >
                                    {({ isDragActive, getRootProps, getInputProps}) => {
                                        return (
                                            <SC.FCol style={{ position : 'relative'}} {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <SC.FCol onClick={(e) => {e.stopPropagation()}}>
                                                    <DropAreaContainer
                                                        style={{marginTop : '4px', minHeight : '80px'}}
                                                        onClick={this.SelectOnlineImage}
                                                    >
                                                        Add from Unsplash Gallery
                                                    </DropAreaContainer>
                                                    <SC.Div_Flex_Cell style={{padding : '4px'}}>
                                                        OR
                                                    </SC.Div_Flex_Cell>
                                                </SC.FCol>                                                
                                                <DropFileArea active={isDragActive} />
                                                <SC.FCol onClick={(e) => {e.stopPropagation()}}>
                                                    <SC.Div_Flex_Cell style={{padding : '4px', marginTop : '4px', marginBottom : '8px'}}>
                                                        OR
                                                    </SC.Div_Flex_Cell>
                                                    <SC.FCol>
                                                        <div style={{paddingLeft : '2px', textAlign : 'center'}}>Add Image from URL</div>
                                                        <SC.FRow f1 >        
                                                            <SC.FCol f1>                        
                                                                <TextValue style={{fontSize : '12px'}} value={this.tokenvalue.url || ''} onChange={(url) => {
                                                                    if (url !== this.tokenvalue.url) {
                                                                        this.tokenvalue.url = url;
                                                                        this.tokenvalue.provider = 'Url';
                                                                        Globals.ProjectManager.Tokens.SetSubValue({id : this.props.id, value : url, prop : 'url'});
                                                                        this.RCUpdate();
                                                                    }                                                                    
                                                                }}/>
                                                            </SC.FCol>                                                            
                                                        </SC.FRow>                            
                                                    </SC.FCol>
                                                </SC.FCol>
                                                {
                                                    this.IsUploading ?
                                                    <SC.Div_Flex_Cell style={{
                                                            position : 'absolute', top : 0, left : 0, right : 0, bottom : 0, backgroundColor : 'rgba(0,0,0,0.5)'
                                                        }}>
                                                        <SC.Loading_Icon justIcon/>
                                                    </SC.Div_Flex_Cell> : null
                                                }
                                            </SC.FCol>
                                        );
                                    }}

                                    </Dropzone>
                                </SC.FCol>
                                {false && <SC.FCol>
                                    <RadioGroup
                                        vertical
                                        itemStyle={{  }}
                                        Container={{ Type: SC.FCol, Props: { alignStretch: true, f1 : true, style: { } } }}
                                        items={[
                                            {id : 'File', label : 'File Upload'},
                                            {id : 'Unsplash', label : 'Unsplash Gallery'},
                                            {id : 'Url', label : 'Image Link'},
                                        ]}
                                        value={this.tokenvalue.provider || 'Unsplash'}
                                        onSelect={(sid) => { 
                                            if (this.tokenvalue && this.tokenvalue.UrlId) {
                                                if (sid !== 'File')
                                                    Globals.ProjectManager.DataManager.Storage.Delete(this.tokenvalue.UrlId);
                                            }
                                            this.tokenvalue = {
                                                provider : sid
                                            }
                                            this.SaveValue();
                                            this.setState({ShouldUpdate : true}, () => {
                                                if (sid === 'File') {                            
                                                    this.refs.Ref_File && this.refs.Ref_File.click();
                                                }
                                                else if (sid === 'Url') {                                                    
                                                }
                                                else {                                                    
                                                    this.SelectOnlineImage();                            
                                                }
                                            })                                                                    
                                        }}
                                    />
                                </SC.FCol>  }                                                       
                            </SC.FCol>        
                        </LeftScrollPanel>
                    </React.Fragment>
                }
                {
                    this.ShowGallery && 
                    <TokenGalleryView 
                        title='UNSPLASH GALLERY' 
                        onClose={() => {
                            delete this.ShowGallery;
                            if (this.props.onToggleGalleryView)
                                this.props.onToggleGalleryView();
                            else
                                this.RCUpdate();
                        }}
                        onRightPanel={this.props.onRightPanel}
                    >
                        <UnsplashImageGallery 
                            noInfo
                            onSelect={this.SaveUnsplashImage}
                            onPreviewChange={this.props.onPreviewChange}
                        />
                    </TokenGalleryView>
                }
            </SC.FCol>            
        )
    }
}

const StyleItem_Image_Box = styled.div`
    width : 100%;
    height : 180px;    
    ${
    props => {
        if (props.backgroundImage) {
            return css`
                    background-image : url(${props.backgroundImage});
                    background-size : contain;
                    background-repeat : no-repeat;
                    background-position : center;
                `;
        }
    }
    }
`;