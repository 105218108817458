import {Utils, FontLoader} from '../toolabs-importer';
import getJson from 'get-json-data';

const FontStyleSheetManager = {
    FontList : null,
    GetFonts(options) {
        const that = this;
        return new Promise(
            function(resolve, reject) {

                const sort = Utils.JustGet(options, null, 'sort');
                if (sort !== that.lastSorting)
                    that.lastSorting = sort;
                else {
                    if (that.FontList) {
                        resolve(that.FontList);
                        return;
                    }
                }
                let sort_param = that.lastSorting ? `sort=${that.lastSorting}&` : '';
                getJson(`https://www.googleapis.com/webfonts/v1/webfonts?${sort_param}key=AIzaSyCT1w5aZWrOc9KpXdreNUGilprt0LD4ciA`,
                    function(err, data) {
                        if (err) {

                        }
                        else {
                            if (data && data.items)
                            {
                                that.FontList = data.items;
                                resolve(data.items);
                            }
                        }

                    }
                );
            }
        );

    },
    families : {},
    LoadTempFont(fontFamily, variant, url) {
        return FontLoader.LoadTemp(fontFamily, variant, url);
    },
    Load(fontFamily, variant, url) {
        return FontLoader.Load(fontFamily, variant, url);
    },
    UnloadFont(elementId, fontFamily, variant) {
        FontLoader.UnLoad(elementId, fontFamily, variant);
    },
    LoadCustomFont(fontFamily, fontData) {
        return FontLoader.LoadCustomFont(fontFamily, fontData);
    },
    WebSafeFonts : {
        List() {
            return [
                {
                    Id : 'Arial',
                    Name : 'Arial',
                    Family : 'Arial, Helvetica, sans-serif'
                },
                {
                    Id : 'ArialBlack',
                    Name : 'Arial Black',
                    Family : `'Arial Black', Gadget, sans-serif`
                },
                {
                    Id : 'ArialNarrow',
                    Name : 'Arial Narrow',
                    Family : `'Arial Narrow', sans-serif`
                },
                {
                    Id : 'CenturyGothic',
                    Name : 'Century Gothic',
                    Family : 'Century Gothic, sans-serif'
                },
                {
                    Id : 'CopperplateGothicLight',
                    Name : 'Copperplate Gothic Light',
                    Family : 'Copperplate / Copperplate Gothic Light, sans-serif'
                },
                {
                    Id : 'CourierNew',
                    Name : 'Courier New',
                    Family : `'Courier New', Courier, monospace`
                },
                {
                    Id : 'Georgia',
                    Name : 'Georgia',
                    Family : 'Georgia, Serif'
                },
                {
                    Id : 'GillSans',
                    Name : 'Gill Sans',
                    Family : 'Gill Sans / Gill Sans MT, sans-serif'
                },
                {
                    Id : 'Helvetica',
                    Name : 'Helvetica',
                    Family : '"Helvetica Neue", Helvetica, Arial, sans-serif'
                },
                {
                    Id : 'Impact',
                    Name : 'Impact',
                    Family : 'Impact, Charcoal, sans-serif'
                },
                {
                    Id : 'LucidaConsole',
                    Name : 'Lucida Console',
                    Family : `'Lucida Console', Monaco, monospace`
                },
                {
                    Id : 'LucidaSansUnicode',
                    Name : 'Lucida Sans Unicode',
                    Family : `'Lucida Sans Unicode', 'Lucida Grande', sans-serif`
                },
                {
                    Id : 'PalatinoLinotype',
                    Name : 'Palatino Linotype',
                    Family : `'Palatino Linotype', 'Book Antiqua', Palatino, serif`
                },
                {
                    Id : 'Tahoma',
                    Name : 'Tahoma',
                    Family : 'Tahoma, Geneva, sans-serif'
                },
                {
                    Id : 'TimesNewRoman',
                    Name : 'Times New Roman',
                    Family : `'Times New Roman', Times, serif`
                },
                {
                    Id : 'TrebuchetMS',
                    Name : 'Trebuchet MS',
                    Family : `'Trebuchet MS', Helvetica, sans-serif`
                },
                {
                    Id : 'Verdana',
                    Name : 'Verdana',
                    Family : 'Verdana, Geneva, sans-serif'
                }
            ]
        }
    }
};

export default FontStyleSheetManager;
