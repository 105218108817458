import React from 'react';
import {
    SC,
    Globals,
    Utils,
} from '../../../../../../../importer';

import { TokenItemBox, GroupTitle } from '../../../../left/designsystem/common';
import ColorTokenEditor from '../../../../left/designsystem/colors/color';
import TextColorBox, { ColorContrastViewer } from '../../../../left/designsystem/colors/color/textcolor';
import { AddNewColorToken, GetColorTokenList, GetGradientTokenList, ColorTokenListItem, ColorTokenCardItem, GetcolorTokenValue, GetColorTokenItem, GetGradientTokenItem, FiltercolorItems } from '../../../../left/designsystem/colors';
import AppLayout from '../../../../../../../appstate/AppLayout';
import BaseSelectToken from '../BaseSelectToken';
import { ListCardView } from '../../../../../../../components/light_dark_bar';
import SelectToken from '..';
import { GetGroupedTokenList } from '../../../../left/designsystem/BaseTokenList';
import { motion } from 'framer-motion';
import ComponentDesignManager from '../../../../../component/manager';
import { GetTokenAliaseList, GetGroupedTokenAliaseList, FilterAliaseItems } from '../../../../left/designsystem/aliases';
import {AddNewTokenAliase} from '../../../../left/designsystem/aliases';

export default class SelectColor extends BaseSelectToken
{
    constructor(props) {
        super(props);

        this.titleNewToken = 'NEW COLOR';
        this.titleEditToken = 'EDIT COLOR';
        this.moduleName = 'Colors';
        this.tokenType = Globals.ProjectManager.Tokens.Types.COLOR;        
        this.ToggleAccessibility = this.ToggleAccessibility.bind(this);
        this.ToggleAccessibilityAliases = this.ToggleAccessibilityAliases.bind(this);
        this.ToggleGroupView = this.ToggleGroupView.bind(this);
        this.ToggleAliaseGroupView = this.ToggleAliaseGroupView.bind(this);
        this.ToggleAliaseCollapsed = this.ToggleAliaseCollapsed.bind(this);
        this.AddColorToGroup = this.AddColorToGroup.bind(this);
        this.AddColorToAliaseGroup = this.AddColorToAliaseGroup.bind(this);        
        this.renderColorGroup = this.renderColorGroup.bind(this);
        this.renderColorAliaseGroup = this.renderColorAliaseGroup.bind(this);
        this.SelectGradientColor = this.SelectGradientColor.bind(this);
        this.SelectAliaseToken = this.SelectAliaseToken.bind(this);
        this.onChangeColorType = this.onChangeColorType.bind(this);

        this.hasAliases = !this.props.forAliase;
        this.state.isAliaseListView = true;

        this.hasCustomTokenList = true;

        this.Ref_Editor = React.createRef();

        this.Load(this.props);

    }    
    IsTokenPropChanged(nextProps, nextState) {
        if (Utils.HasAnyChange(this.props, nextProps, 'text', 'onBackgroundColor', 'hasAccessibility', 'GlobalState')) {
            this.Load(nextProps);
            return true;
        }
        if (this.state.isAccessbilityActive !== nextState.isAccessbilityActive || this.state.isAliasAccessbilityActive !== nextState.isAliasAccessbilityActive)
            return true;
        if (this.state.isGroupView !== nextState.isGroupView || this.state.isAliaseGroupView !== nextState.isAliaseGroupView)
            return true;
        
        return this.GetSelectedTokenId(this.props) !== this.GetSelectedTokenId(nextProps) || Utils.HasAnyChange(this.props, nextProps, 'gradient', 'textGradient');

    }
    Load(props) {
        this.isForText = props.hasAccessibility && props.onBackgroundColor ? {
            forText : true,
            onBackgroundColor : props.onBackgroundColor
        } : null;
        this.state.isAccessbilityActive = Globals.ProjectManager.Options.Get(true, 'RightPanel', 'DesignSystem', this.moduleName, 'Accessibility');
        this.state.isAliasAccessbilityActive = Globals.ProjectManager.Options.Get(true, 'RightPanel', 'DesignSystem', this.moduleName, 'Aliases', 'Accessibility');
        this.state.isGroupView = Globals.ProjectManager.Options.Get(false, 'RightPanel', 'DesignSystem', this.moduleName, 'GroupView');
        
        super.Load(props);
    }
    GetTokens(props = this.props) {        
        this.colors = GetColorTokenList({forText : this.isForText, StateArray : props.StateArray});
        this.gradients = GetGradientTokenList(props.StateArray);
        const tokens = [...this.colors, ...this.gradients];
        this.groups = GetGroupedTokenList(Globals.ProjectManager.Tokens.Types.COLOR, tokens);    
        
        this.aliases = GetTokenAliaseList(Globals.ProjectManager.Tokens.Types.COLOR);
        if (this.props.excludeIds) {
            this.props.excludeIds.map((excludeId) => {
                Utils.Remove(this.aliases, (item) => {return item.id === excludeId});
                Utils.Remove(this.colors, (item) => {return item.id === excludeId});
            })            
        }
        this.aliaseGroups = GetGroupedTokenAliaseList(Globals.ProjectManager.Tokens.Types.COLOR, this.aliases);    

        Utils.ForEach(this.aliases, (aliase, i) => { 
            if (aliase.isAbsolute) {
                aliase.tokenItem = GetColorTokenItem({id : aliase.tokenId, forText : props.text, StateArray : props.StateArray });
            }
            else if (aliase.tokenId) {
                aliase.tokenItem = {...Utils.Find(tokens, (item) => {return item.id === aliase.tokenId})};                
                if (aliase.tokenItem) {
                    aliase.tokenItem.value = Globals.ProjectManager.Tokens.Aliases.GetColorAliaseValue(aliase.id, aliase.tokenItem.value);
                    console.log(aliase.tokenItem.value)
                }                    
            }
            if (!aliase.tokenItem) {
                aliase.tokenItem = {
                    name : ''
                };
            }
        });

        return tokens;
    }     
    GetSelectedTokenId(props = this.props) {
        if (props.backgroundColor)
            return props.backgroundColor;
        if (props.color)
            return props.color;
        if (props.propName)
            return props[props.propName];
    }
    GetNewModel(Id) {
        let value;
        let SelectedColorId = this.GetSelectedTokenId();
        if (SelectedColorId) {
            const SelectedToken = Utils.Find(this.tokens, (token) => {return token.id === SelectedColorId});
            if (SelectedToken)
                value = SelectedToken.value;
        }
        const Model = {
            name : 'New Color',
            value : {
                Default : {
                    value : value || this.props.defaultValue || '#fff'
                }
            }
        };
        AddNewColorToken(Model.isGradient, Model, Id);
        this.FireSelectToken(Id);
        return Model;
    }
    SaveToken() {
        if (this.Ref_Editor.current) {
            const isGradient = this.Ref_Editor.current.isGradient;
            let NewModel;
            const token = Globals.ProjectManager.Tokens.Token(this.EditingToken.Id);
            if (isGradient) {
                NewModel = GetGradientTokenItem({token : token, id : this.EditingToken.Id, StateArray : this.props.StateArray });
                this.gradients.push(NewModel);
            }
            else {
                NewModel = GetColorTokenItem({token : token, id : this.EditingToken.Id, forText : this.props.text, StateArray : this.props.StateArray });
                this.colors.push(NewModel);
            }
            // Globals.ProjectManager.Tokens.Colors.SetValue({id : this.EditingToken.Id, value : NewModel.value});
            // Globals.ProjectManager.Tokens.Colors.UpdateProp({id : this.EditingToken.Id, name : 'name', value : NewModel.name} );
            if (this.AddingToGroup) {
                this.AddingToGroup.tokens.push(NewModel);
                Globals.ProjectManager.Tokens.AddTokenToGroup({type : isGradient ? Globals.ProjectManager.Tokens.Types.Gradients : Globals.ProjectManager.Tokens.Types.COLOR, groupid : this.AddingToGroup.id, tokenid : this.EditingToken.Id})
                delete this.AddingToGroup;
            }
            // else if (this.AddingToAliaseGroup) {
            //     this.AddingToAliaseGroup.tokens.push(NewModel);
            //     Globals.ProjectManager.Tokens.AddTokenToGroup({type : isGradient ? Globals.ProjectManager.Tokens.Types.Gradients : Globals.ProjectManager.Tokens.Types.COLOR, groupid : this.AddingToAliaseGroup.id, tokenid : this.EditingToken.Id})
            //     delete this.AddingToGroup;
            // }
            this.UpdateDesignSystemPanel();
            return NewModel;
        }                
    }   
    onSubmitAddToken() {
        if (this.EditingToken.IsAlias) {
            AddNewTokenAliase({
                type : this.tokenType,
                name : this.EditingToken.Model.name,
                id : this.EditingToken.Id,
                tokenId : this.EditingToken.Model.tokenId,
                groupId : Utils.JustGet(this.AddingToAliaseGroup, null, 'id')
            });
            this.GetTokens();
            this.CancelNewTokenPreview();
            
            this.props.onUpdate && this.props.onUpdate();
            this.SelectToken(this.EditingToken.Id);
            delete this.EditingToken;
        }
        else {
            const NewToken = this.SaveToken();
            if (NewToken) {
                this.CancelNewTokenPreview();
                let ThemeValue = NewToken.value;
                Globals.ProjectManager.Tokens.UpdateCurrentTheme({id : NewToken.id, value : ThemeValue, type : this.tokenType});
                
                this.props.onUpdate && this.props.onUpdate();
                this.SelectToken(NewToken.id, this.EditingToken.isGradient);
                delete this.EditingToken;
            }
        }        
    }
    onCloseTokenEditor() {
        if (this.EditingToken && this.EditingToken.IsNew) {
            delete this.EditingToken;            
            this.RCUpdate();
            return;
        }
        super.onCloseTokenEditor();
    }
    onCancelAddToken() {
        this.FireSelectToken(this.LastBoundTokenId);
        delete this.LastBoundTokenId;
        
        this.EditingToken && this.EditingToken.Id && Globals.ProjectManager.Tokens.Colors.Delete(this.EditingToken.Id);
        super.onCancelAddToken();
    }
    UpdateDesignSystemPanel() {
        AppLayout.Refs.DesignSystem.Colors && AppLayout.Refs.DesignSystem.Colors.Reload();
    }
    GetStyleName(isGradient) {
        if (isGradient) {
            return this.props.text ? 'textGradient' : 'gradient';
        }
        let propName = 'backgroundColor';
        if (this.props.text)
            propName = 'color';
        else if (this.props.propName)
            propName = this.props.propName;
        return propName;
    }
    PreviewToken(show, tokenId, propName, isGradient) {
        this.isPreviewingGradient = show && isGradient;
        super.PreviewToken(show, tokenId, propName, false);
    }

    renderTokenListHeader() {
        return (
            <GroupTitle 
                title='TOKENS' 
                hasSearch={{onSearch : this.SearchToken, onToggleSearchMode : this.ToggleSearchMode}}
                searchVisible={this.isSearchMode}
                hasAdd={{onAdd : this.state.isGroupView ? this.AddGroup : this.AddToken, groupView : this.state.isGroupView}}
                addOn={(
                    <SC.FRow>
                        <SC.Icons.Icon_Button hasFill selected={this.state.isGroupView ? SC.CurrentTheme.theme.color_brand : null} onClick={this.ToggleGroupView} style={{cursor : 'pointer', paddingLeft : '4px', paddingRight : '2px', marginRight : '4px'}} title={'Toggle Group View'} >
                            <SC.Icons.Icon_Group size={18} />
                        </SC.Icons.Icon_Button>
                        {
                            this.isForText && 
                            <SC.Icons.Icon_Button hasCursor hasFill style={{minWidth : '22px', marginLeft : '2px', marginRight  : '2px', fill : this.state.isAccessbilityActive ? SC.CurrentTheme.theme.color_brand : null}} onClick={this.ToggleAccessibility}>
                                <SC.Icons.Accessibility size={20}/>
                            </SC.Icons.Icon_Button>
                        }
                        <ListCardView 
                            onListView={this.SetListMode.bind(this, true)}
                            onCardView={this.SetListMode.bind(this, false)}
                            isListView={this.state.isListView}
                            isCardView={!this.state.isListView}
                            style={{marginRight : '4px'}}
                            compact
                        />
                    </SC.FRow>                    
                )}
                style={{padding : 0, paddingLeft : '4px', marginBottom : '4px'}}
            />
        )
    }
    renderTokenAliaseListHeader() {
        return (
            <GroupTitle 
                title='SEMANTIC TOKENS' 
                expandable={{expanded : !this.state.isSemanticsCollapsed, onExpand : this.ToggleAliaseCollapsed}}
                hasSearch={this.state.isSemanticsCollapsed ? null : {onSearch : this.SearchAliase}}
                hasAdd={this.state.isSemanticsCollapsed ? null : {onAdd : this.state.isAliaseGroupView ? this.AddAliaseGroup : this.AddTokenAlias, groupView : this.state.isAliaseGroupView}}
                addOn={this.state.isSemanticsCollapsed ? null : (
                    <SC.FRow>
                        <SC.Icons.Icon_Button hasFill selected={this.state.isAliaseGroupView ? SC.CurrentTheme.theme.color_brand : null} onClick={this.ToggleAliaseGroupView} style={{cursor : 'pointer', paddingLeft : '4px', paddingRight : '2px', marginRight : '4px'}} title={'Toggle Group View'} >
                            <SC.Icons.Icon_Group size={18} />
                        </SC.Icons.Icon_Button>
                        {
                            this.isForText && 
                            <SC.Icons.Icon_Button hasCursor hasFill style={{minWidth : '22px', marginLeft : '2px', marginRight  : '2px', fill : this.state.isAliasAccessbilityActive ? SC.CurrentTheme.theme.color_brand : null}} onClick={this.ToggleAccessibilityAliases}>
                                <SC.Icons.Accessibility size={20}/>
                            </SC.Icons.Icon_Button>
                        }
                        <ListCardView 
                            onListView={this.SetAliaseListMode.bind(this, true)}
                            onCardView={this.SetAliaseListMode.bind(this, false)}
                            isListView={this.state.isAliaseListView}
                            isCardView={!this.state.isAliaseListView}
                            style={{marginRight : '4px'}}
                            compact
                        />
                    </SC.FRow>                    
                )}
                style={{padding : 0, paddingLeft : '4px', marginBottom : '4px', marginLeft : '-8px'}}
            />
        )
    }
    ToggleAccessibility() {
        Globals.ProjectManager.Options.Set(!this.state.isAccessbilityActive, 'RightPanel', 'DesignSystem', this.moduleName, 'Accessibility');        
        this.setState({
            isAccessbilityActive : !this.state.isAccessbilityActive
        })
    }
    ToggleAccessibilityAliases() {
        Globals.ProjectManager.Options.Set(!this.state.isAliasAccessbilityActive, 'RightPanel', 'DesignSystem', this.moduleName,  'Aliases', 'Accessibility');        
        this.setState({
            isAliasAccessbilityActive : !this.state.isAliasAccessbilityActive
        })
    }
    ToggleGroupView() {
        Globals.ProjectManager.Options.Set(!this.state.isGroupView, 'RightPanel', 'DesignSystem', this.moduleName, 'GroupView');        
        this.setState({
            isGroupView : !this.state.isGroupView
        })
    }
    ToggleAliaseGroupView() {        
        Globals.ProjectManager.Options.Set(!this.state.isAliaseGroupView, 'RightPanel', 'DesignSystem', this.moduleName, 'Aliases', 'GroupView');                
        this.setState({
            isAliaseGroupView : !this.state.isAliaseGroupView
        }, () => {
            if (this.filter)
                this.SearchAliase(this.filter);
        })
    }
    ToggleAliaseCollapsed() {        
        Globals.ProjectManager.Options.Set(!this.state.isAliaseGroupView, 'RightPanel', 'DesignSystem', this.moduleName, 'Aliases', 'Collapsed');                
        this.setState({
            isSemanticsCollapsed : !this.state.isSemanticsCollapsed
        })
    }
    SearchToken(filter) {
        this.filter = filter;
        if (this.state.isGroupView)
            FiltercolorItems({filter : filter, groups : this.groups});
        else
            FiltercolorItems({filter : filter, colors : this.tokens});
        
        this.RenderId = Utils.Id();
        this.RCThrottledUpdate_1();
    }
    SearchAliase(filter) {
        this.filter = filter;
        if (this.state.isAliaseGroupView)
            FilterAliaseItems({filter : filter, groups : this.aliaseGroups});
        else
            FilterAliaseItems({filter : filter, aliases : this.aliases});
                    
        this.RenderId = Utils.Id();
        this.RCThrottledUpdate_1();
    }
    renderColorGroup(colors, forText, SelectedColorId) {
        if (this.state.isListView) {
            return colors.map((color) => {
                if (!color.filtered) {
                    return (
                        <ColorTokenListItem 
                            key={color.id} 
                            color={color}
                            gradient={color.gradient}
                            onSelect={this.BindColorToken.bind(this, color.id, color.gradient)}
                            onMouseEnter={this.PreviewToken.bind(this, true, color.id, this.GetStyleName(color.gradient), color.gradient)}
                            onMouseLeave={this.PreviewToken.bind(this, false, color.id, this.GetStyleName(color.gradient), color.gradient)}
                            forText={forText}
                            selected={color.id === SelectedColorId}
                        />                                
                    )
                }                
            });
        }
        else {
            return (
                <div style={forText ? {flex : 1, display : 'grid', gridTemplateColumns : '1fr 1fr 1fr', gridGap : '4px'} : {...SC.Styles.Flex.Row, flexWrap : 'wrap'}}>
                    {
                        colors.map((color) => {
                            return (
                                <ColorTokenCardItem 
                                    key={color.id} 
                                    color={color}
                                    gradient={color.gradient}
                                    onSelect={this.BindColorToken.bind(this, color.id, color.gradient)}
                                    onMouseEnter={this.PreviewToken.bind(this, true, color.id, this.GetStyleName(color.gradient), color.gradient)}
                                    onMouseLeave={this.PreviewToken.bind(this, false, color.id, this.GetStyleName(color.gradient), color.gradient)}
                                    forText={forText}
                                    selected={color.id === SelectedColorId}
                                />                                
                            )
                        })
                    }
                </div>
            );
        }
    }
    renderColorAliaseGroup(aliases, forText, SelectedColorId) {
        if (aliases) {
            if (this.state.isAliaseListView) {
                return aliases.map((aliase) => {
                    if (!aliase.filtered && aliase.tokenId && aliase.tokenItem) {
                        return (
                            <ColorTokenListItem 
                                aliase={aliase}
                                key={aliase.id} 
                                color={aliase.tokenItem}
                                gradient={aliase.tokenItem.gradient}
                                onSelect={this.BindColorToken.bind(this, aliase.id, aliase.tokenItem.gradient)}
                                onMouseEnter={this.PreviewToken.bind(this, true, aliase.tokenItem.id, this.GetStyleName(aliase.tokenItem.gradient), aliase.tokenItem.gradient)}
                                onMouseLeave={this.PreviewToken.bind(this, false, aliase.tokenItem.id, this.GetStyleName(aliase.tokenItem.gradient), aliase.tokenItem.gradient)}
                                forText={forText}
                                selected={aliase.id === SelectedColorId}
                            />                                
                        )
                    }                
                });
            }
            else {
                return (
                    <div style={forText ? {flex : 1, display : 'grid', gridTemplateColumns : '1fr 1fr 1fr', gridGap : '4px'} : {...SC.Styles.Flex.Row, flexWrap : 'wrap'}}>
                        {
                            aliases.map((aliase) => {
                                if (!aliase.filtered && aliase.tokenId && aliase.tokenItem) {
                                    return (
                                        <ColorTokenCardItem 
                                            aliase={aliase}
                                            key={aliase.id} 
                                            color={aliase.tokenItem}
                                            gradient={aliase.tokenItem.gradient}
                                            onSelect={this.BindColorToken.bind(this, aliase.id, aliase.tokenItem.gradient)}
                                            onMouseEnter={this.PreviewToken.bind(this, true, aliase.tokenItem.id, this.GetStyleName(aliase.tokenItem.gradient), aliase.tokenItem.gradient)}
                                            onMouseLeave={this.PreviewToken.bind(this, false, aliase.tokenItem.id, this.GetStyleName(aliase.tokenItem.gradient), aliase.tokenItem.gradient)}
                                            forText={forText}
                                            selected={aliase.id === SelectedColorId}
                                        />                                
                                    )
                                }
                            })
                        }
                    </div>
                );
            }
        }        
    }
    AddColorToGroup(group) {        
        this.AddingToGroup = group;
        this.AddToken();
    }
    AddColorToAliaseGroup(group) {
        this.AddingToAliaseGroup = group;
        this.AddTokenAlias();
    }
    AddToken() {
        if (!Globals.ProjectManager.CheckGrant_AddToken(this.tokenType)) {
            this.AddingToGroup = false;
            return;
        }
        super.AddToken();
    }
    AddTokenAlias() {
        if (!Globals.ProjectManager.CheckGrant_AddToken(this.tokenType)) {
            this.AddingToAliaseGroup = false;
            return;
        }
        super.AddTokenAlias();
    }
    renderTokenList() {
        let forText = this.state.isAccessbilityActive ? this.isForText : null;
        let SelectedColorId = this.GetSelectedTokenId();

        return (
            <RenderColorTokenList
                colors={this.colors}
                gradients={this.gradients}
                groups={this.groups}
                isGroupView={this.state.isGroupView}
                isListView={this.state.isListView}
                onAddColorToGroup={this.AddColorToGroup}
                forText={forText}
                SelectedColorId={SelectedColorId}
                renderColorGroup={this.renderColorGroup}
                RenderId={this.RenderId}
                GlobalStateId={this.props.GlobalStateId}
                themeId={this.props.themeId}
                isGradients={!this.props.noGradient}
                tokenType={this.tokenType}
            />
        )
    }
    renderTokenAliases() {
        if (this.state.isSemanticsCollapsed)
            return null;
        let forText = this.state.isAliasAccessbilityActive ? this.isForText : null;
        let SelectedColorId = this.GetSelectedTokenId();
        return (
            <SC.FCol style={{marginBottom : '16px'}}>
                <RenderColorTokenList
                    colors={this.aliases}
                    aliases
                    groups={this.aliaseGroups}
                    isGroupView={this.state.isAliaseGroupView}
                    isListView={this.state.isAliaseListView}
                    onAddColorToGroup={this.AddColorToAliaseGroup}
                    forText={forText}
                    SelectedColorId={SelectedColorId}
                    renderColorGroup={this.renderColorAliaseGroup}
                    RenderId={this.RenderId}
                    GlobalStateId={this.props.GlobalStateId}
                    themeId={this.props.themeId}
                    isGradients={!this.props.noGradient}
                    tokenType={this.tokenType}
                />
            </SC.FCol>            
        )
    }
    renderTokenEditor() {
        return (
            <React.Fragment>
                <SC.CustomScrollbars>
                <ColorTokenEditor 
                    ref={this.Ref_Editor}
                    onPreviewChange={this.EditingToken.IsNew ? this.PreviewNewToken : null}
                    id={this.EditingToken.Id}
                    {...this.isForText}
                    isNew={this.EditingToken.IsNew}
                    GlobalState={this.props.GlobalState}
                    StateArray={this.props.StateArray}
                    isGradient={this.EditingToken.isGradient}
                    onEditColor={this.SelectGradientColor}
                    noGradient={this.props.noGradient}
                    onChangeColorType={this.onChangeColorType}
                />
                </SC.CustomScrollbars>
                
            </React.Fragment>            
        )        
    }
    renderInnerSelector() {
        if (this.showColorSelector) {
            return (
                <motion.div 
                    style={{
                        ...SC.Styles.Absolute, 
                        zIndex : 999999999, 
                        left : 0,
                        right : 0,
                        top : 0,
                        bottom : 0,
                        boxSizing : 'border-box',
                        width : '280px' ,
                        borderRight : SC.CurrentTheme.theme.border_ondark,
                        borderLeft : SC.CurrentTheme.theme.border_ondark,
                        backgroundColor : SC.CurrentTheme.theme.back,
                    }}
                    initial={{opacity : 0.7, x : 24}}
                    animate={{opacity : 1, x : 0}}
                    exit={{opacity : 0, x : 24}}
                    transition={{duration : 0.1}}
                >
                    <SC.FCol fw fh>
                        <SC.FCol f1 style={{backgroundColor : SC.CurrentTheme.theme.back}}>
                            <SelectToken                                         
                                onPreviewToken={this.PreviewToken}
                                GlobalState={this.props.GlobalState}
                                GlobalStateId={this.props.GlobalStateId}
                                themeId={this.props.themeId}
                                onSetPropertyValues={this.SetItemPropertyValues}
                                onBindToModel={this.BindToModel}
                                GetComponentManager={this.GetComponentManager}
                                GetMetaItem={this.GetMetaItem}
                                forAliase
                                onClose={() => {
                                    delete this.showColorSelector;
                                    this.RCUpdate();
                                }}
                                onClosed={() => {                                            
                                }}
                                {...this.showColorSelector}
                            />
                        </SC.FCol>                    
                    </SC.FCol>
                </motion.div>     
            );
        }
    }
    onChangeColorType(type) {
        this.EditingToken.isGradient = type === 'gradient';
        this.FireSelectToken(null, !this.EditingToken.isGradient);
        this.FireSelectToken(this.EditingToken.Id, this.EditingToken.isGradient);
    }
    SelectGradientColor(id, color, onChange) {
        this.showColorSelector = {
            type : AppState.ItemTypes.BOARD.COLOR,
            title : 'GRADIENT COLOR',
            noGradient : true,
            forAliase : true,
            justEditor : true,
            itemId : id,
            color : color,
            onChange : onChange            
        };            
        this.RCUpdate();
    }
    SelectAliaseToken(onSelect, onPreview, onClosed, onPreviewNewToken) {
        this.showColorSelector = {
            type : AppState.ItemTypes.BOARD.COLOR,
            title : 'SELECT ALIASE TOKEN',
            noGradient : true,
            onSelect : (tokenId, isGradient) => {
                onSelect(tokenId)
            },
            onPreviewToken : ({show, tokenId}) => {
                onPreview && onPreview(tokenId, show);
            },
            onClosed : onClosed,
            onPreviewNewToken : () => {
                ComponentDesignManager.Active().ComponentManager && ComponentDesignManager.Active().ComponentManager.UpdateRendererNewTokenPreview(this.props.Id, this.EditingToken.Id);
                onPreviewNewToken && onPreviewNewToken();
            }
        };            
        this.RCUpdate();
    }
    RemoveTokenBinding(isGradient) {
        super.SelectToken(null, isGradient);
    }
    BindColorToken(colorid, isGradient) {
        this.FireSelectToken(colorid, isGradient);
        !this.props.innerMode && this.Close();
    }
    EditToken(isGradient) {
        
        if (isGradient) {
            this.EditingToken = {
                Id : this.props.gradient,
                isGradient : true
            };
            this.RCUpdate();
        }
        else {
            if (this.GetSelectedTokenId()) {
                this.EditingToken = {
                    Id : this.GetSelectedTokenId()
                };
                this.RCUpdate();
            }
        }
    }
    UpdateSelectedToken() {
        if (this.EditingToken) {
            const SelectedToken = Utils.Find(this.colors, (token) => {return token.id === this.EditingToken.Id});
            const token = Globals.ProjectManager.Tokens.Token(this.EditingToken.Id);
            if (this.EditingToken.isGradient) {
    
            }
            else {    
                GetcolorTokenValue(token, SelectedToken, this.isForText, this.props.StateArray);
            }
            this.UpdateDesignSystemPanel();
        }        
    }
    renderSelectedToken() {
        let selectedTokenItem, selectedColor, selectedGradient;
        let SelectedColorId = this.GetSelectedTokenId();
        if (this.PreviewingTokenId && !this.isPreviewingGradient)
            SelectedColorId = this.PreviewingTokenId;
        
        let backgroundColor, color, nameColor;
        if (SelectedColorId) {
            const SelectedToken = Utils.Find(this.colors, (token) => {return token.id === SelectedColorId});
            if (SelectedToken) {             
                nameColor = SelectedToken.name;
                if (this.props.onBackgroundColor) {
                    backgroundColor = this.props.onBackgroundColor;
                    color = SelectedToken.value;
                }
                else {
                    backgroundColor = SelectedToken.value;
                    color = '#fff';
                }                                       
            }            
        }
        else {
            if (this.props.onBackgroundColor) {
                backgroundColor = this.props.onBackgroundColor;
            }
        }        

        selectedColor =  this.renderSelectedTokenHeader({
            empty : !color,
            onEdit : this.EditToken.bind(this, false), 
            onDelete : this.RemoveTokenBinding.bind(this, false),
            name : nameColor,
            content : (
                <ColorContrastViewer   
                    empty={!color}                          
                    emptyText={'Click to Add Color'}
                    backgroundColor={backgroundColor} 
                    color={color}
                    notAvailable={this.isPreviewingGradient}
                    style={{marginTop : '4px', marginBottom : '4px'}}
                    name={{}}
                    onSelect={!color ? this.AddToken : this.EditToken.bind(this, false)}
                />
            )
        });
    
        let gradientId = this.props.gradient;
        // if (this.isPreviewingGradient && this.PreviewingTokenId)
        //     gradientId = this.PreviewingTokenId;
        if (gradientId) {
            const SelectedToken = Utils.Find(this.gradients, (token) => {return token.id === gradientId});
            if (SelectedToken) {
                selectedGradient = (
                    <SC.FCol>
                        {
                            this.renderSelectedTokenHeader({
                                onEdit : this.EditToken.bind(this, true), 
                                onDelete : this.RemoveTokenBinding.bind(this, true),
                                name : SelectedToken.name,
                                content : (
                                    <div 
                                        style={{
                                            height : '60px',
                                            borderRadius : '2px',
                                            background : SelectedToken.value,
                                            marginTop : '4px',
                                            marginBottom : '4px'
                                        }}
                                    />
                                )
                            })
                        }                
                    </SC.FCol>                                      
                );
            }            
        }
        if (selectedColor || selectedGradient) {
            return (
                <SC.FCol>
                    {selectedColor}
                    {selectedGradient}
                </SC.FCol>
            )
        }
    }
}

export class RenderColorTokenList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (Utils.HasAnyChange(this.props, nextProps, 'isListView', 'isGroupView', 'forText', 'isGradients', 'SelectedColorId', 'RenderId', 'GlobalStateId', 'themeId'))
            return true;
        if (this.state.ShouldUpdate !== nextState.ShouldUpdate)
            return true;
        return false;
    }
    AddColorToGroup(group)  {
        this.props.onAddColorToGroup(group);
    }
    onToggleExpandGroup(group) {
        group.collapsedInSelect = !group.collapsedInSelect;
        if (this.props.aliases)
            Globals.ProjectManager.Tokens.Aliases.SetGroupProperty({type : this.props.tokenType, id : group.id, name : 'collapsedInSelect', value : group.collapsedInSelect});
        else
            Globals.ProjectManager.Tokens.SetGroupProperty({type : this.props.tokenType, id : group.id, name : 'collapsedInSelect', value : group.collapsedInSelect});
        this.setState({ShouldUpdate : true})
    }
    render() { 
        this.state.ShouldUpdate = false;

        let colors, gradients;
        if (this.props.groups && this.props.isGroupView) {
            const groupitems = [];
            Utils.ForEach(this.props.groups, (group, g) => {
               !group.filtered && groupitems.push(
                   <SC.FCol key={group.id} style={{marginTop : g > 0 && !this.props.groups[g-1].collapsedInSelect ? '8px' : 0}}>
                        <GroupTitle 
                            small
                            title={group.name} 
                            hasAdd={{onAdd : this.AddColorToGroup.bind(this, group)}}                                 
                            style={{flex : 1, paddingLeft : 0, paddingRight : 0, marginBottom : '4px', marginLeft : '-8px'}}
                            expandable={{expanded : !group.collapsedInSelect,  onExpand : this.onToggleExpandGroup.bind(this, group)}}
                        />
                       {!group.collapsedInSelect && this.props.renderColorGroup(group.tokens, this.props.forText, this.props.SelectedColorId)}
                   </SC.FCol>
               ) 
            });
            colors = groupitems;                
        }
        else {
            colors = (
                <React.Fragment>
                    {
                        !this.props.justGradients &&
                        <SC.FCol style={this.props.isListView ? {} : {flexDirection : 'row', flexWrap : 'wrap'}}>
                            {this.props.renderColorGroup(this.props.colors, this.props.forText, this.props.SelectedColorId)}
                        </SC.FCol>
                    }                    
                    {
                        this.props.isGradients && 
                        <SC.FCol style={this.props.isListView ? {} : {flexDirection : 'row', flexWrap : 'wrap'}}>
                            {this.props.renderColorGroup(this.props.gradients, this.props.forText, this.props.SelectedColorId)}
                        </SC.FCol>                            
                    }                    
                </React.Fragment>
            )    
        }

        if (this.props.notScollable) {
            return (
                <React.Fragment>
                    {colors}
                    {gradients}    
                </React.Fragment>
            )
        }

        return (
            <React.Fragment>
                {colors}
                {gradients}
            </React.Fragment>             
        )
    }
}
 