import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    AppLayout,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../../../../importer';
import styled, { css} from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import DocumentListItem from './item';

export default class DocumentItem_Carousel extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {

        };    

        this.NavigateTo = this.NavigateTo.bind(this);
        this.renderCollectionItem = this.renderCollectionItem.bind(this);
        
        this.slides = [];
        this.state.active = 0;
        this.state.previous = this.GetPrevious(this.slides.length, this.state.active);
      
        this.Load(this.props.id);
    }    
    shouldComponentUpdate(nextProps, nextState) {

        if (this.props.autoFocus !== nextProps.autoFocus)
            return true;
        if (this.state.ShouldUpdate !== nextState.ShouldUpdate)
            return true;
        
        if (this.props.RenderId !== nextProps.RenderId) {
            this.Load(nextProps.id);
            return true;
        }        
        if (Utils.HasAnyChange(this.props, nextProps, 'preview', 'GlobalThemeId', 'GlobalState', 'StyleId', 'themeId'))
            return true;
        
        if (this.state.selectedTabId !== nextState.selectedTabId)
            return true;

        return true;
    }
    Load(id) {
        
    }
    componentDidUpdate(prevProps, prevState) {
        this.state.ShouldUpdate = false;
    }
    
    Update() {
        this.setState({
            ShouldUpdate : true
        })
    }    
    GetNext(count, current) {
        return (current + 1) % count;
    }
    GetPrevious(count, current) {
        let previous = (current + -1) % count;
        if (previous < 0)
            previous = count - 1;
        return previous;
    }
    MoveSlide(offset) {
        if (!this.slides)
            return;
            
        const active = offset > 0 ? this.GetNext(this.slides.length, this.state.active) : this.GetPrevious(this.slides.length, this.state.active);
        
        this.setState({
            active : active,
            previous : this.state.active,
            fromRight : offset > 0
        });
    }
    NavigateTo(index) {
        if (!this.slides)
            return;

        this.setState({
            active : index,
            previous : this.state.active,
            fromRight : index > this.state.active
        });
    }
    renderCollectionItem() {
        return (
            <SC.FCol>
                Slide
            </SC.FCol>
        )
    }
    SelectTab(id) {
        this.setState({selectedTabId : id});
    }
    render() {        

        if (!this.props.ItemProps)
            return null;

        const viewType = Globals.ProjectManager.GetDocumentManager().GetPageItemValue(this.props.id, this.props.pageId, 'tab', 'viewType');
        const items = Globals.ProjectManager.GetDocumentManager().GetPageItemValue(this.props.id, this.props.pageId, [], 'items');
        
        const alignment = Globals.ProjectManager.GetDocumentManager().GetPageItemValue(this.props.id, this.props.pageId, 'center', 'alignment');
        const size = (alignment !== 'fullwidth' && alignment !== 'stretch') ? Globals.ProjectManager.GetDocumentManager().GetPageItemValue(this.props.id, this.props.pageId, 100, 'size') : null;
        

        const style = {
            alignSelf : alignment,
            width : size ? Utils.px(size, '%') : 'auto',
            ...this.props.ItemProps.textStyles.body
        };

        if (viewType === 'accordion') {
            return (
                <SC.FCol style={style}>
                    {
                        items.map((item) => {
                            return (
                                <AccordionItem 
                                    key={item.id}
                                    name={item.name}
                                    id={item.id}
                                    expanded={Globals.ProjectManager.GetDocumentManager().GetPageItemValue(item.id, this.props.pageId, false, 'expanded')}
                                    ItemProps={this.props.ItemProps}
                                >
                                    <DocumentListItem                                         
                                        id={item.id}
                                        ItemProps={this.props.ItemProps}
                                    />
                                </AccordionItem>
                            )
                        })
                    }
                </SC.FCol>
            )
        }
        else if (viewType === 'carousel') {
            
        }
        else {            
            let tabs = [];
            let tabContent;
            Utils.ForEach(items, (item, i) => {
                let isSelected;
                if ((!this.state.selectedTabId && i === 0) || this.state.selectedTabId === item.id) {
                    isSelected = true;
                    tabContent = (
                        <DocumentListItem                                         
                            key={item.id}
                            id={item.id}
                            ItemProps={this.props.ItemProps}
                            style={{
                                paddingTop : '20px',
                                paddingBottom : '20px'
                            }}
                            toobarStyle={{
                                paddingBottom : 0
                            }}
                        />
                    )
                }
                tabs.push(
                    <button 
                        key={item.id} 
                        onClick={this.SelectTab.bind(this, item.id)}
                        style={{                            
                            ...this.props.ItemProps.textStyles.body,
                            backgroundColor : isSelected ? this.props.textStyles.backgroundHighlight : 'transparent',
                            padding : '8px',
                            textAlign : 'center',
                            border : 'none',
                            cursor : 'pointer',
                        }}
                    >
                        {item.name}
                    </button>
                )
            });
            return (
                <SC.FCol style={style}>
                    <div style={{
                        display : 'grid',
                        gridGap : '8px',
                        gridTemplateColumns : 'repeat(auto-fit, minmax(80px, auto))',
                        justifyContent : 'start'
                    }}>
                        {tabs}
                    </div>
                    <div style={{
                        border : SC.CurrentTheme.theme.border_seperator
                    }} onMouseEnter={(e) => {e.stopPropagation()}}>
                        {tabContent}
                    </div>                    
                </SC.FCol>
            )
        }

    }       
}

const AccordionItem = (props) => {
    const [expanded, setExpanded] = useState(props.expanded);
    return (
        <React.Fragment>
            <SC.FRow alc style={{
                padding  : '8px',
                fontSize : '18px',
                marginBottom : '8px',
                cursor : 'pointer',
                borderRadius : '2px',
                backgroundColor : props.ItemProps.textStyles.backgroundHighlight
            }} onClick={() => {
                if (!props.preview)
                    Globals.ProjectManager.GetDocumentManager().SetItemValue(props.id, !expanded, 'expanded');
                setExpanded(!expanded);
            }}>
                <SC.Icons.Icon_ExpandArrow size={14} expanded={expanded} />
                <div style={{marginLeft : '8px'}} >{props.name}</div>                    
            </SC.FRow>
            <AnimatePresence>
                {
                    expanded && 
                    <motion.div
                        initial={{y : -20}}
                        animate={{y : 0}}
                        exit={{}}
                        transition={{duration : 0.2}}
                    >
                        {props.children}
                    </motion.div>                    
                }
            </AnimatePresence>
            
        </React.Fragment>
    )
}