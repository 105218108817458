import WebComponents from './web';
import Styles, {CommonStyles, TextStyles} from '../styles';
import Props, {CommonProperties} from '../props';
import Events, {CommonEvents} from '../events';
import DataType from '../datatypes';

import Generic from './generic';
import ApexCharts from './widgets/apexCharts';
import eCharts from './widgets/eCharts';


const Content = {
    Type : 'Content',
    Name : 'Placeholder',
    Description : 'Fixed area for external content',
    Styles : CommonStyles.concat(
        [
        ]
    ),
    Props : CommonProperties.concat(
        [            
        ]
    ),
    Events : CommonEvents.concat(
        [
            
        ]
    ),
};

const Token = {
    Type : 'Token',
    Name : 'Token',
    Description : 'Design System Tokens',
    Styles : CommonStyles.concat(
        [
            Styles.overflow.name,
            ...TextStyles
        ]
    ),
    Props : CommonProperties.concat(
        [
            Props.layout.name,
            Props.boxShadow.name,
            Props.MetaImage.name,
            Props.TokenOptions.name
        ]
    ),
    Events : CommonEvents.concat(
        [
            
        ]
    ),
    TokenTypes : {
        Color : {
            Type : 'Color',
            Templates : {
                Default : {
                    Theme : {
                        border : {}
                    },
                    Options : {

                    }
                }
            }
        },
        Gradient : {
            Type : 'Gradient',
        },
        Image : {
            Type : 'Image',
        },
        Icon : {
            Type : 'Icon',
        },
        Font : {
            Type : 'Font',
        },
        TextPattern : {
            Type : 'TextPattern',
        },
        TextScale : {
            Type : 'TextScale',
        },
        Shadow : {
            Type : 'Shadow',
        },
        TextShadow : {
            Type : 'TextShadow',
        },
        Transition : {
            Type : 'Transition',
        },
        Sound : {
            Type : 'Sound',
        },
        Spacing : {
            Type : 'Spacing',
        },
        Component : {
            Type : 'Component'
        }
    }
}

const Repeater = {
    Type : 'Repeater',
    Name : 'Repeater',
    Description : 'Data repeater',
    Styles : CommonStyles.concat(
        [
        ]
    ),
    Props : CommonProperties.concat(
        [            
            Props.count.name,
            Props.Data.name,            
            Props.RepeatType.name,
            Props.boxShadow.name,
            Props.MetaImage.name,
        ]
    ),
    Events : CommonEvents.concat(
        [
            
        ]
    ),
    States : [
        'Default',
        'Hover'
    ]
}

const Layout = {
    Type : 'Layout',
    Name : 'Layout',    
    Description : 'Either drag & drop this icon or just click it to open element template selection panel. And drag & drop a template from the panel.',
    Styles : CommonStyles.concat(
        [
            Styles.gridColumnGap.name,
            Styles.gridRowGap.name
        ]
    ),
    Props : CommonProperties.concat(
        [   
            Props.boxShadow.name,
            Props.MetaImage.name,         
        ]
    ),
    Events : CommonEvents.concat(
        [
            
        ]
    ),
    States : [
        'Default',
        'Hover'
    ]
};

const Div = {
    Type : 'Div',
    Name : 'Container',    
    Description : 'Either drag & drop this icon or just click it to open element template selection panel. And drag & drop a template from the panel.',
    Styles : CommonStyles.concat(
        [
            Styles.overflow.name,
            ...TextStyles
        ],        
    ),
    Props : CommonProperties.concat(
        [
            Props.layout.name,
            Props.boxShadow.name,
            Props.MetaImage.name,
        ]
    ),
    Events : CommonEvents.concat(
        [
            
        ]
    ),
    States : [
        'Default',
        'Hover'
    ]
};
const Span = {
    Type : 'Span',
    Name : 'Span',
    Description : '',
    Styles : CommonStyles.concat(
        [
        ]
    ),
    Props : CommonProperties.concat(
        [            
        ]
    ),
    Events : CommonEvents.concat(
        [
            
        ]
    ),
    States : [
        'Default',
        'Hover'
    ]
};
const Text = {
    Type : 'Text',
    Name : 'Text',
    Description : 'Either drag & drop this icon or just click it to open element template selection panel. And drag & drop a template from the panel.',
    Styles : CommonStyles.concat(
        TextStyles
    ),
    Props : CommonProperties.concat(
        [            
            Props.text.name,
            Props.textShadow.name,
            Props.truncate.name
        ]
    ),
    Events : CommonEvents.concat(
        [
            
        ]
    ),
    States : [
        'Default',
        'Hover'
    ]
};
const Button = {
    Type : 'Button',
    Name : 'Button',
    Description : 'Either drag & drop this icon or just click it to open element template selection panel. And drag & drop a template from the panel.',
    Styles : CommonStyles.concat(
        TextStyles
    ),
    Props : CommonProperties.concat(
        [            
            Props.text.name,
            Props.boxShadow.name,
            Props.truncate.name,
            Props.MetaImage.name,
        ]
    ),
    Events : CommonEvents.concat(
        [
            
        ]
    ),
    States : [
        'Default',
        'Hover',
        'Active'
    ]
};

const Input = {
    Type : 'Input',
    Name : 'Input',
    Description : 'Either drag & drop this icon or just click it to open element template selection panel. And drag & drop a template from the panel.',
    Styles : CommonStyles.concat(
        TextStyles
    ),
    Props : CommonProperties.concat(
        [            
            Props.value.name,
            Props.InputType.name,
            Props.placeholder.name,
            Props.boxShadow.name,
            Props.MetaImage.name,
        ]
    ),
    Events : CommonEvents.concat(
        [
            Events.change.name
        ]
    ),
    States : [
        'Default',
        'Hover'
    ]
};

const Svg = {
    Type : 'Svg',
    Name : 'Svg',
    Description : 'Either drag & drop this icon or just click it to open element template selection panel. And drag & drop a template from the panel.',
    Styles : CommonStyles.concat(
        [
        ]
    ),
    Props : CommonProperties.concat(
        [            
            Props.MetaSvg.name
        ]
    ),
    Events : CommonEvents.concat(
        [
            
        ]
    ),    
    States : [
        'Default',
        'Hover'
    ]
};

const Component = {
    Type : 'Component',
    Name : 'Component',
    Description : 'Either drag & drop this icon or just click it to open element template selection panel. And drag & drop a template from the panel.',
    Styles : CommonStyles.concat(
        [
        ]
    ),
    Props : CommonProperties.concat(
        [            
            Props.Component.name
        ]
    ),
    Events : CommonEvents.concat(
        [
            
        ]
    ),  
    States : [
        'Default',
        'Hover'
    ]
};

const Image = {
    Type : 'Image',
    Name : 'Image',
    Description : 'Either drag & drop this icon or just click it to open element template selection panel. And drag & drop a template from the panel.',
    Styles : CommonStyles.concat(
        [

        ]
    ),
    Props : CommonProperties.concat(
        [            
            Props.MetaImage.name,
            Props.Provider.name
        ]
    ),
    Events : CommonEvents.concat(
        [
            
        ]
    ),   
    States : [
        'Default',
        'Hover'
    ]       
};

const SvgIcon = {
    Type : 'SvgIcon',
    Name : 'Icon',
    Label : 'Icon',
    Description : 'Either drag & drop this icon or just click it to open element template selection panel. And drag & drop a template from the panel.',
    Styles : CommonStyles.concat(
        [
            Styles.fill.name
        ]
    ),
    Props : CommonProperties.concat(
        [            
            Props.MetaIcon.name,
            Props.Provider.name
        ]
    ),
    Events : CommonEvents.concat(
        [
            
        ]
    ),    
    States : [
        'Default',
        'Hover'
    ]      
};

const Data = {
    Type : 'Data',
    Name : 'Data',
    Label : 'Data',
    Description : 'Create Element from Data Model',
    Styles : CommonStyles.concat(
        [            
        ]
    ),
    Props : CommonProperties.concat(
        [
        ]
    ),
    Events : CommonEvents.concat(
        [
            
        ]
    ),
};
const Widget = {
    Type : 'Widget',
    Name : 'Widget',
    Label : 'Widget',
    Title : 'Widgets',
    Description : 'Custom widgets',
    Styles : CommonStyles.concat(
        [            
        ]
    ),
    Props : CommonProperties.concat(
        [
            Props.Provider.name,
            Props.WidgetOptions.name
        ]
    ),
    Events : CommonEvents.concat(
        [
            
        ]
    ),
    States : [
        'Default',
        'Hover'
    ],
    WidgetTypes : {
        Video : {
            Type : 'Video',
            Label : 'Video Player'
        },
        Spotify : {
            Type : 'Spotify',
            Label : 'Spotify Player'
        },
        // GoogleMaps : {
        //     Type : 'GoogleMap',
        //     Label : 'Map'
        // },
        MapBox : {
            Type : 'MapBox',
            Label : 'Map',
            Options : {
                mapTheme : {
                    name : 'mapTheme',
                    default : 'default'
                }, 
                latitude : { 
                    name : 'latitude'
                },
                longitude : { 
                    name : 'longitude'
                },
                mapzoom : { 
                    name : 'mapzoom',
                    default : 11
                },
                mapinteractive : {
                    name : 'mapinteractive'
                }
            }
        },
        Lottie : {
            Type : 'Lottie',
            Label : 'Lottie Animation',
            Options : {
                speed : {
                    name : 'speed',
                    default : 1
                }
            }
        },
        // Barcode : {
        //     Type : 'Barcode',
        //     Label : 'Barcode',
        //     Formats : [
        //         { id : 'CODE128'},
        //         { id : 'EAN13'},
        //         { id : 'UPC'},
        //         { id : 'EAN8'},
        //         { id : 'EAN5'},
        //         { id : 'EAN2'},
        //         { id : 'CODE39'},
        //         { id : 'ITF14'},
        //         { id : 'MSI'},
        //         { id : 'MSI10'},
        //         { id : 'MSI11'},
        //         { id : 'MSI1010'},
        //         { id : 'MSI1110'},
        //         { id : 'pharmacode'},
        //         { id : 'codabar'},
        //     ]
        //     // https://github.com/metafloor/bwip-js
        // },

        ApexCharts : ApexCharts,
        eCharts : eCharts
    }          
};

const ElementTypeList = [
    Div,
    Text,
    Button,
    Input,
    SvgIcon
];

const Componets = {    
    Layout,
    Repeater,
    Content,
    Div,    
    Span,
    Text,
    Svg,
    Component,
    Image,
    Svg,
    SvgIcon,
    Widget,
    Data,
    Token,
    Generic,    

    Button : Generic.Button,
    Input : Generic.Input,
    Textarea : Generic.Textarea,
    DateInput : Generic.DateInput,
    Checkbox : Generic.Checkbox,
    Toggle : Generic.Toggle,
    RadioGroup : Generic.RadioGroup,
    RangeInput : Generic.RangeInput,
    ProgressBar : Generic.ProgressBar,
    Dropdown : Generic.Dropdown,
    Select : Generic.Select,
    Carousel : Generic.Carousel,
    Tab : Generic.Tab,
    Accordion : Generic.Accordion,
    Table : Generic.Table,

    ElementTypeList,
    
    IsContainer(MetaItem) {
        if (!MetaItem)
            return false;
        const {Type, WidgetType} = MetaItem;
        // if (Type === Widget.Type) {
        //     if (WidgetType === Widget.WidgetTypes.GoogleMaps.Type)
        //         return true;
        // }
        return Type === Div.Type || Type === Button.Type || Type === Content.Type || Type === Repeater.Type || Type === Layout.Type || Type === Token.Type;
    },
    HasItemText(MetaItem) {
        if (!MetaItem)
            return false;
        const {Type, WidgetType} = MetaItem;
        // if (Type === Widget.Type) {
        //     if (WidgetType === Widget.WidgetTypes.GoogleMaps.Type)
        //         return true;
        // }
        return Type === Text.Type || Type === Button.Type || Type === Input.Type;
    }
}

export default Componets;
