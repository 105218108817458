import React from 'react';
import {
    ReactBaseComponent,
    SC,
    AppState,
    Utils
} from '../../importer';

import styled, {css} from 'styled-components';

class Numeric extends ReactBaseComponent
{
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.OnKeyUp = this.OnKeyUp.bind(this);
        this.OnKeyDown = this.OnKeyDown.bind(this);
        this.onSave = this.onSave.bind(this);
        this.Increment = this.Increment.bind(this);
        this.Decrement = this.Decrement.bind(this);

        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        
        this.Ref_Input = React.createRef();
    }  
    onCleaveInit(cleave) {
        this.Ref_Cleave = cleave;
    }
    onFocus() {
        this.isFocused = true;
        AppState.Designer.SetEditingText('input_number');;
        this.RCUpdate();
    }
    onBlur() {
        this.isFocused = false;
        AppState.Designer.EditingText = false;
        this.props.onChange &&this.props.onChange(this.props.value);
        this.RCUpdate();
    }
    onChange(e) {
        if (isNaN(e.target.value) || !Utils.IsNotNullOrEmpty(e.target.value)) {
            if (this.Ref_Input.current && Utils.IsNotNullOrEmpty(this.props.value)) {
                this.Ref_Input.current.SetValue(this.props.value);
            }
            return;
        }            
        let value = !Utils.IsNotNullOrEmpty(e.target.value) ? null : Utils.ToNumber(e.target.value);
        if (value === this.props.value)
            return;
            
        if (this.props.min !== undefined && value < this.props.min)
            value = this.props.min;
            
        this.isValueChanged = value !== this.props.value;
                
        if (!isNaN(value))
        {
            if (this.props.onChanging)
                this.props.onChanging(value);
            else
                this.props.onChange &&this.props.onChange(value);
        }            
    }
    onSave() {
        if (this.props.onChanging && this.isValueChanged) 
            this.props.onChange(this.props.value);
        this.isValueChanged = false;
        this.props.onBlur && this.props.onBlur();
    }
    OnKeyDown(e) {
        e.stopPropagation();
        return true;
    }
    OnKeyUp(e) {                
        e.stopPropagation();
        if (Utils.IsDeleteCode(e.keyCode)) {
            if (this.props.onChanging)
                this.props.onChanging(null);
            else
                this.props.onChange(null);
        }
        else if (e.keyCode === 38 || e.keyCode === 40) {
            e.preventDefault();
            let increment = 1;
            if (this.props.numeralDecimalScale) {
                increment = 0.1;
            }
            let value = Number(this.props.value);
            if (e.keyCode === 38)
                value = (value || 0) + (increment);
            else
                value = (value || 0) - (increment);

            if (this.props.min !== undefined && value < this.props.min)
                value = this.props.min;
                
            this.isValueChanged = value !== this.props.value;
                    
            if (!isNaN(value))
            {
                if (this.props.onChanging)
                    this.props.onChanging(value);
                else
                    this.props.onChange &&this.props.onChange(value);
            }            
        }   
        return true;
    }
    Increment() {
        let value = Utils.ToNumber(Utils.UseNull(this.props.value, 0));
        value += 1;
        this.props.onChange(value);
    }
    Decrement() {
        let value = Utils.ToNumber(Utils.UseNull(this.props.value, 0));
        value -= 1;
        this.props.onChange(value);
    }
    renderCustom() {
        const value = Utils.UseNull(this.props.value, '');
        
        const numericOptions = {
            numeral : true,
            numeralThousandsGroupStyle : 'none',
            numeralDecimalScale : Utils.UseNull(this.props.numeralDecimalScale, 0),
            numeralPositiveOnly : this.props.numeralPositiveOnly
        };

        const {onEditorOpen, onDelete, spinnded, property, onSelectStyle, numeralPositiveOnly, 
               parentNames, transform, numeralDecimalScale, inheritedValue, componentProp, onChanging, modelId, styleId, boxStyle, binderCenter, ExpandedGroups, RootItem, onExpand, Locked, StyleSetValues, StateId, valueType, ...rest} = this.props;

        const useBoxStyle = {...boxStyle};

        if (this.props.spinnded) {
            useBoxStyle.border = 'none';
            useBoxStyle.maxHeight = '24px';
        }
        
        const inputitem = (
            <SC.Component_SizeEditor.Container xsmall={this.props.xsmall} active={this.isFocused} style={useBoxStyle}>
                {this.props.prefix}
                <ValueInput                                 
                    {...rest}
                    onChange={this.onChange}
                    onKeyUp={this.OnKeyUp}
                    onKeyDown={this.OnKeyDown}
                    placeholder={'_ _'}
                    options={numericOptions}
                    value={value}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    readOnly={this.props.readOnly}
                    onInit={this.onCleaveInit}
                    InnerRef={this.Ref_Input}
                />    
                {this.props.postfix}
            </SC.Component_SizeEditor.Container>
        );
        if (this.props.spinnded) {
            return (
                <SC.FRow alc>
                    {inputitem}
                    <SpinBox style={this.props.style_spin} onIncrement={this.Increment} onDecrement={this.Decrement} />                    
                </SC.FRow>
            )
        }
        return inputitem;
    }
}

export const SpinBox = (props) => {
    return (
       <SC.FCol justifyCenter style={{...props.style, maxHeight : '24px'}}>
            <SC.Icons.Icon_Button
                hasFill
                style={{
                    flex : 1,
                    cursor : 'pointer',
                    marginBottom : '-3px',
                    minHeight : 'unset'
                }}
                onClick={props.onIncrement}
            >
                <SC.Icons.Icon_Arrow_Filled size={16} style={{transform : 'rotate(-90deg)'}}/>
            </SC.Icons.Icon_Button>
            <SC.Icons.Icon_Button
                hasFill
                style={{
                    flex : 1,
                    cursor : 'pointer',
                    marginTop : '-3px',
                    minHeight : 'unset'
                }}
                onClick={props.onDecrement}
            >
                <SC.Icons.Icon_Arrow_Filled size={16} style={{transform : 'rotate(90deg)'}}/>
            </SC.Icons.Icon_Button>
        </SC.FCol>
    )
}

const ValueInput = styled(
    ({textAlignCenter, ...props}) => <SC.CleaveInput {...props} ref={props.InnerRef} />)
  `
    color : ${props => props.theme.font_input};
    font-size : 16px;
    padding-left : 0px;
    padding-right : 0px;
    letter-spacing : 0.5px;        
    border : 1px solid red;
    background-color : transparent;            
    width : 100%;
    height : 100%;
    box-sizing : border-box;
    font-style : ${props => Utils.IsNotNullOrEmpty(props.value) ? 'normal' : 'italic'};
    &:focus {
        border : 1px solid #0096ea;
    }
    ${
        props => {
            if (props.textAlignCenter) {
                return css`
                    text-align : center;
                    padding-right : 16px;
                `;
            }
        }
    }
`;


export default Numeric;