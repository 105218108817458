import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBasicComponent,    
    UIUtils,
    Utils,
    SC,
    Globals,
    AppLayout
} from '../../../../../importer';
import styled from 'styled-components';

import {DocWidgetIcon_ButtonBox} from '../widget';
import { DocumentToolbarSeperator, DocumentToolbarButton, DocumentToolbarBox } from '../item/text/toolbar';
import SelectDocumentImage from '../item/wizard/selectImage';
import {ImageVerticalPosition} from '../../../panels/right/iteminspector/styleitems/image';
import { SortableList, SortableListItem } from '../../../../../components/SortableContainer';
import TextInput from '../../../../../components/editors/textinput';
import AddCancelBar from '../../../../../components/AddCancelBar';
import DocumentItem from '../item';
import Switch from '../../../../../components/editors/Switch';
import { motion } from 'framer-motion';

export default class DocumentPageHero extends ReactBasicComponent {
    constructor(props) {
        super(props);
     
        this.onResizeHero = this.onResizeHero.bind(this);
        this.StopResizingHero = this.StopResizingHero.bind(this);        
        this.ChangeHeroAlignment = this.ChangeHeroAlignment.bind(this);
        
        this.onSortItems = this.onSortItems.bind(this);        
        this.AddItem = this.AddItem.bind(this);
        this.AddItemBefore = this.AddItemBefore.bind(this);        
        this.CloneItemBefore = this.CloneItemBefore.bind(this);
        this.CloneItemAfter = this.CloneItemAfter.bind(this);        
        this.DeleteItem = this.DeleteItem.bind(this);

        this.Ref_Hero = React.createRef();
        
    }
    StartResizingHero(e) {
        AppLayout.Designer.SuspendDocumentDesigner = true;
        document.addEventListener('mousemove', this.onResizeHero)
        document.addEventListener('mouseup', this.StopResizingHero);
        this.setState({
            resizingHero : true,
            sy : e.clientY,
            dy : 0,
            height : UIUtils.Utils.GetBounds(this.Ref_Hero.current).height - 48
        });
    }
    onResizeHero(e) {
        const dy = e.clientY - this.state.sy;
        const DN = ReactDOM.findDOMNode(this.Ref_Hero.current);
        DN.style.minHeight = Utils.px(this.state.height + dy);
        
    }
    StopResizingHero(e) {
        AppLayout.Designer.SuspendDocumentDesigner = false;
        document.removeEventListener('mousemove', this.onResizeHero);
        document.removeEventListener('mouseup', this.StopResizingHero);                
        const bounds = UIUtils.Utils.GetBounds(this.Ref_Hero.current);
        Globals.ProjectManager.GetDocumentManager().SetHeroProp('height', bounds.height - 48);

        this.setState({
            resizingHero : false
        });
    }
    AddHero() {
        Globals.ProjectManager.GetDocumentManager().AddHero();
        this.state.showHeroToolbar = true;
        this.props.onUpdate();        
    }
    RemoveHero() {
        Globals.ProjectManager.GetDocumentManager().RemoveHero();
        this.props.onUpdate();
    }
    ToggleGlobalHero(value) {
        if (this.props.isGlobal && !value) {
            Globals.ProjectManager.GetDocumentManager().MakeGlobalHero();
        }
        else {
            Globals.ProjectManager.GetDocumentManager().MakeGlobalHero(this.props.heroId);
        }
        this.props.onUpdate();
    }
    UploadImageFile(e) {
        const file = e.target.files[0];
        if (file) {
            const newId = Utils.Id();
            Globals.ProjectManager.GetDocumentManager().SetHeroProp('url', URL.createObjectURL(file));
            Globals.ProjectManager.GetDocumentManager().SetHeroProp('storageId', newId);
            Globals.ProjectManager.GetDocumentManager().SetHeroProp('source', 'file');

            this.RCUpdate();
            
            const onUploadComplete = this.UploadCompleted.bind(this, newId);
            Globals.ProjectManager.DataManager.Storage.Save(newId, file, {}, (progress) => {
            }).then(onUploadComplete);
        }
    }  
    UploadCompleted(newId, result) {
        if (result) {
            result.ref.getDownloadURL().then((downloadURL) => {
                Globals.ProjectManager.GetDocumentManager().SetHeroProp('url', downloadURL);
                this.RCUpdate();
            });
        }
    }
    ChangeHeroAlignment(prop, value, oldValue) {
        Globals.ProjectManager.GetDocumentManager().SetHeroProp(prop, value);
        if (value === 'custom') {
            let customValue = 50;
            if (oldValue === 'top')
                customValue = 0;
            else if (oldValue === 'bottom')
                customValue = 100;

            Globals.ProjectManager.GetDocumentManager().SetHeroProp('customPositionY', customValue);
        }
        this.RCUpdate();
    }    
    onSortItems(oldIndex, newIndex) {
        Globals.ProjectManager.GetDocumentManager().ChangeOrder(oldIndex, newIndex, this.props.heroId);
        this.RCUpdate();
    }
    AddItem() {
        this.WillFocusToNewId = Globals.ProjectManager.GetDocumentManager().AddItem({afterId : this.props.ItemProps.context.id, pageId : this.props.heroId});
        this.props.ItemProps.context.id = this.WillFocusToNewId;
        this.setState({ShouldUpdate : true});
    }
    AddItemBefore() {
        this.WillFocusToNewId = Globals.ProjectManager.GetDocumentManager().AddItem({beforeId : this.props.ItemProps.context.id, pageId : this.props.heroId});
        this.props.ItemProps.context.id = this.WillFocusToNewId;
        this.setState({ShouldUpdate : true}, () => {
            delete this.WillFocusToNewId;
        })
    }
    CloneItemAfter() {
        this.WillFocusToNewId = Globals.ProjectManager.GetDocumentManager().AddItem({afterId : this.props.ItemProps.context.id, clone : true, pageId : this.props.heroId});
        this.props.ItemProps.context.id = this.WillFocusToNewId;
        this.setState({ShouldUpdate : true});
    }
    CloneItemBefore() {
        this.WillFocusToNewId = Globals.ProjectManager.GetDocumentManager().AddItem({beforeId : this.props.ItemProps.context.id, clone : true, pageId : this.props.heroId});
        this.props.ItemProps.context.id = this.WillFocusToNewId;
        this.setState({ShouldUpdate : true}, () => {
            delete this.WillFocusToNewId;
        })
    }
    DeleteItem(id) {
        Globals.ProjectManager.GetDocumentManager().DeleteItem(id, this.props.heroId);
        this.props.ItemProps.onMouseEnter(null, null);
        this.RCUpdate();
    }
    SaveHeroUrl() {
        Globals.ProjectManager.GetDocumentManager().SetHeroProp('source', 'url');
        Globals.ProjectManager.GetDocumentManager().SetHeroProp('url', this.state.url);
        this.setState({showImageUrl : false})
    }
    renderCustom() {    
        if (!this.props.heroId) {
            if (this.props.preview)
                return null;
            return (
                <SC.HoverParent
                    defaultStyle={{
                        ...SC.Styles.Flex.RowAlcJsb,
                        position : 'absolute', top : '8px', left : '8px', cursor : 'pointer'
                    }}
                    onClick={this.AddHero.bind(this)}
                >
                    <DocumentToolbarBox style={{paddingRight : '8px'}}>
                        <DocWidgetIcon_ButtonBox hasFill style={{ width : '28px', height : '28px', marginRight : '8px'}}>
                            <SC.Icons.Icon_Plus />
                        </DocWidgetIcon_ButtonBox>
                        <SC.HoverChild defaultStyle={{opacity : 1}} hoverStyle={{color : SC.CurrentTheme.theme.font_hover}}>
                            Add Hero
                        </SC.HoverChild>    
                    </DocumentToolbarBox>                     
                </SC.HoverParent>
            )
        }
        const heroData = Globals.ProjectManager.GetDocumentManager().GetHeroData(this.props.pageId);
        let heroItems = Globals.ProjectManager.GetDocumentManager().GetItemIds(this.props.heroId);

        let height = Utils.JustGet(heroData, 200, 'height');

        const hasHeroImage = Utils.IsNotNullOrEmpty(heroData.url);
        let style_heroImage;
        if (hasHeroImage && !this.state.showImageSelector) {
            let positionY = Utils.JustGet(heroData, 'center', 'positionY');
            if (positionY === 'custom') {
                positionY = Utils.px(Utils.JustGet(heroData, 0, 'customPositionY'), '%');
            }
            
            style_heroImage = {
                backgroundImage : Utils.url(heroData.url),
                backgroundSize : 'cover',
                backgroundRepeat : 'norepeat',
                backgroundPosition : 'center ' + positionY
            }
        }
        else {
            style_heroImage = {
                backgroundColor : 'rgba(0,0,0,0.2)'
            }
        }

        if (this.props.preview) {
            return (
                <SC.FCol  style={{
                    ...style_heroImage,
                    minHeight : Utils.px(height),
                    paddingTop : '24px',
                    paddingBottom : '24px',
                    justifyContent : 'center'
                }}
                >
                    {
                        heroItems.map((itemId, index) => {
                            return (
                                <DocumentItem 
                                    key={itemId}
                                    index={index}
                                    ItemBoxType={DocumentItem}
                                    id={itemId}
                                    index={index}
                                    width={this.props.ItemProps.pageWidth}
                                    toolbarPosition={'200px'}
                                    heroItem
                                    {...this.props.ItemProps}                                    
                                /> 
                            )
                        })
                    }
                </SC.FCol >
            )
        }
        else {
            
            let heroToolbar;

            if (this.state.showHeroToolbar) {
                const source = Utils.JustGet(heroData, '', 'source');
                const positionY = Utils.JustGet(heroData, 'center', 'positionY');
                const customYprops = {}, customYoptions = {};
                if (positionY === 'custom') {
                    customYprops.value = Utils.JustGet(heroData, 0, 'customPositionY');
                    customYoptions.onChangeValue = (value) => {
                        Globals.ProjectManager.GetDocumentManager().SetHeroProp('customPositionY', value);
                        this.RCUpdate();
                    };
                    customYoptions.onChangingValue = customYoptions.onChangeValue;
                    customYoptions.onSettoAuto = () => {
                        Globals.ProjectManager.GetDocumentManager().SetHeroProp('customPositionY', 'center');
                        this.RCUpdate();
                    }
                }

                if (!this.Ref_ImageFile)
                    this.Ref_ImageFile = React.createRef();


                if (this.state.showImageUrl) {
                    heroToolbar = (
                        <React.Fragment>
                            <div>Url</div>
                            <TextInput 
                                themeId={this.props.themeId}
                                placeholder='Enter Url'
                                dark
                                autoFocus
                                value={this.state.url}
                                onChanging={(link) => this.setState({url : link})}
                                onChange={(link) => this.setState({url : link})}
                                style={{
                                    height : '30px',
                                    width : '400px',
                                    marginLeft : '8px',
                                    marginRight : '8px'
                                }}
                                onSubmit={this.SaveHeroUrl.bind(this)}
                                onCancel={() => this.setState({showImageUrl : false})}
                            />
                            <AddCancelBar 
                                onCancel={() => this.setState({showImageUrl : false})}
                                onAdd={this.SaveHeroUrl.bind(this)}
                            />
                        </React.Fragment>
                    )
                }
                else {
                    heroToolbar = (
                        <React.Fragment>
                            <DocumentToolbarSeperator style={{alignSelf : 'stretch', height : 'unset', marginLeft : '4px', marginRight : '4px'}} />
                            <DocumentToolbarButton active={source === 'unsplash'} onMouseDown={() => this.setState({showImageSelector : true})}>
                                <div style={{paddingLeft : '4px', paddingRight : '4px'}}>Unsplash</div>
                            </DocumentToolbarButton>
                            <DocumentToolbarButton active={source === 'url'} onMouseDown={() => {
                                this.setState({showImageUrl : true, url : heroData.url});
                            }} style={{marginLeft : '4px', marginRight : '4px'}}>
                                <div style={{paddingLeft : '4px', paddingRight : '4px'}}>Url</div>
                            </DocumentToolbarButton>
                            <DocumentToolbarButton active={source === 'file'} hasFill onMouseDown={() => {
                                this.setState({showImageSelector : false});
                                this.Ref_ImageFile.current.click();
                            }}>
                                <SC.Icons.Upload />
                            </DocumentToolbarButton>
                            <input type="file" style={{ display: 'none' }}
                                ref={this.Ref_ImageFile}
                                accept="image/*"
                                onChange={this.UploadImageFile.bind(this)}>
                            </input >
                            <DocumentToolbarSeperator style={{alignSelf : 'stretch', height : 'unset', marginLeft : '4px', marginRight : '4px'}} />
                            <ImageVerticalPosition 
                                onChange={this.ChangeHeroAlignment}
                                positionX='center'
                                positionY={positionY}
                                customYprops={customYprops}
                                customYoptions={customYoptions}
                                plainSlider
                            />
                        </React.Fragment>  
                    )
                }                
            }

            let content;

            if (this.state.showImageSelector) {
                content = (
                    <SC.FCol style={{
                        padding : '48px'
                    }}  onMouseDown={(e) => e.stopPropagation()}>
                        <SelectDocumentImage
                            id={this.props.heroId}
                            heroImage
                            onClose={() => this.setState({showImageSelector : false})}                        
                            propName='url'
                            onUpdate={() => {
                                this.state.showImageSelector = false;
                                this.RCUpdate();
                            }}
                        />
                    </SC.FCol>                    
                )
            }
            else {

                if (heroItems.length === 0) {                    
                    content = (
                        <SC.AbsoluteOverlay style={{padding : '48px', boxSizing : 'border-box', ...SC.Styles.Flex.Cell}} title=''>
                            <DocumentToolbarButton onMouseDown={(e) => {
                                e.stopPropagation();
                                this.setState({showHeroToolbar : false});
                                this.AddItem();
                            }} style={{padding : 0}}>
                                <SC.Icons.Icon_Button hasFill style={{cursor : 'pointer'}}>
                                    <SC.Icons.Icon_Plus size={32} />
                                </SC.Icons.Icon_Button>
                            </DocumentToolbarButton>
                        </SC.AbsoluteOverlay>
                    )
                }
                else {
                    content = (
                        <SortableList
                            type='ITEMS'
                            onSort={this.onSortItems}
                            onDragStart={this.onDragStart}
                            onDragEnd={this.onDragEnd}      
                        >
                            {
                                heroItems.map((itemId, index) => {
                                    return (
                                        <SortableListItem 
                                            key={itemId}
                                            draggableId={itemId}
                                            index={index}
                                            ItemBoxType={DocumentItem}
                                            BoxProps={{
                                                id : itemId,
                                                index : index,
                                                width : '100%',
                                                toolbarPosition : '200px',
                                                heroItem : true,
                                                autoFocus : itemId === this.WillFocusToNewId || itemId === this.WillFocusToItem,
                                                // showItemSettings : this.showItemSettings === itemId,
                                                ...this.props.ItemProps,
                                                width : this.props.ItemProps.pageWidth,
                                                onAddItem : this.AddItem,
                                                onAddItemBefore : this.AddItemBefore,
                                                onCloneItemBefore : this.CloneItemBefore,
                                                onCloneItemAfter : this.CloneItemAfter,
                                                onDeleteItem : this.DeleteItem,
                                                preview : this.state.showHeroToolbar
                                            }}
                                        /> 
                                    )
                                })
                            }
                        </SortableList>
                    )
                }

            }            

            if (this.state.resizingHero) {
                height += this.state.dy;
            }

            return (
                <SC.FCol 
                    style={{
                        minHeight : Utils.px(height),
                        paddingTop : '24px',
                        paddingBottom : '24px',
                        position : 'relative',
                        ...style_heroImage,
                        justifyContent : 'center',
                        cursor : 'ns-resize'
                    }}
                    title='Drag vertically to change height'
                    onMouseDown={this.StartResizingHero.bind(this)}
                    ref={this.Ref_Hero}
                >
                    {/* <DocumentToolbarBox style={{
                            position : 'absolute', bottom : '8px', left : '8px', cursor : 'pointer', padding : '8px',
                            zIndex : 9999999,
                            display : 'grid',
                            gridTemplateColumns : 'auto auto',
                            gridGap : '8px',                            
                        }}  
                        onMouseDown={(e) => e.stopPropagation()}
                    >
                        <div>Visible for All Pages</div>
                        <Switch round value={this.props.isGlobal} onChange={this.ToggleGlobalHero.bind(this)} />
                    </DocumentToolbarBox> */}
                    <DocumentToolbarBox style={{
                            ...SC.Styles.Flex.RowAlcJsb,
                            position : 'absolute', top : '8px', left : '8px', cursor : 'pointer', padding : '4px',
                            zIndex : 9999999
                        }}  
                        onMouseDown={(e) => e.stopPropagation()}
                    >
                        <DocumentToolbarButton onClick={this.RemoveHero.bind(this)}>
                            <SC.Icons.Icon_Button hasFill  style={{marginRight : '4px'}} >
                                <SC.Icons.Icon_Delete size={18}/>
                            </SC.Icons.Icon_Button>
                            <SC.LinkText>Remove Hero</SC.LinkText>
                        </DocumentToolbarButton>
                        <DocumentToolbarSeperator style={{alignSelf : 'stretch', height : 'unset', marginLeft : '4px', marginRight : '4px'}} />
                        <DocumentToolbarButton active={this.state.showHeroToolbar} onClick={() => {this.setState({showHeroToolbar : !this.state.showHeroToolbar})}} style={{minWidth : '100px'}}>
                            {this.state.showHeroToolbar ? 'Close Options' : (hasHeroImage ? 'Image Options' : 'Add Hero Image')}
                        </DocumentToolbarButton>
                        <motion.div style={{
                            ...SC.Styles.Flex.Row,
                            alignItems : 'center',
                        }}                                
                            layoutTransition={{
                                duration : 0.1
                            }}
                        >                            
                            {
                                this.state.showHeroToolbar && heroToolbar
                            }
                        </motion.div>                                                
                    </DocumentToolbarBox>

                    {content}
                </SC.FCol>
            )
        }

    }
}