import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    AppLayout,
    Events,
    MetaData,
    UIUtils,
    Globals,
    ReactBasicComponent
} from '../../../../../../../importer';

import { GetColorTokenList, GetColorTokenListOfIds } from '../../../../../panels/left/designsystem/colors';
import TokenExportGroup, { CopyExportTokenCodeBox } from '../../../../../tokens/export/tokengroup';
import { TARGET_DEFAULTS } from '../../../../../tokens/export/templates';
import { TokenTypes } from '../../../../../manager/tokens';
import {GenerateTokenExoprts, DownloadTokens} from '../../../../../tokens/export/generateTokens';
import DropDownSelect from '../../../../../../../components/editors/enum_dropdown';
import { TOKEN_EXPORT_TARGETS } from '../../../../../tokens/export/panel';
import Switch from '../../../../../../../components/editors/Switch';

export default class DocumentToken_TokenExport extends ReactBasicComponent
{
    constructor(props) {
        super(props);

        this.target = this.props.type === TokenTypes.ContentTexts ? 'js' :  'css';

        this.ChangeTemplate = this.ChangeTemplate.bind(this);        
        this.Download = this.Download.bind(this);
        
        this.Load();
    }
    componentDidMount() {
        const DN = ReactDOM.findDOMNode(this);
        if (DN) {
            
            this.loaded = DN.getBoundingClientRect().width;
            this.RCUpdate();
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.LocalGlobaslState !== nextProps.LocalGlobaslState || this.props.GlobalState !== nextProps.GlobalState || this.props.RenderId !== nextProps.RenderId) {
            this.Load(nextProps);
            return true;
        }
        if (Utils.HasAnyChange(this.props, nextProps, 'StyleId', 'RenderId', 'LoadId', 'preview'))
            return true;
        
        return super.ShouldUpdate(nextProps, nextState);
    }    
    ChangeTemplate(id) {
        Globals.ProjectManager.GetDocumentManager().SetItemValue(this.props.id, id, 'templateId');
        this.templateId = id;
        this.Load();
        this.RCUpdate();
    }
    Download() {
        DownloadTokens({
            target : this.target,
            type : this.props.type,
            exportedCode : this.ExportResult.exportedCode,
            downloadAsFile : this.downloadAsFile,
            tokenItems : this.ExportResult.tokenItems
        })
    }
    Load(props = this.props) {
        this.CodeId = Utils.Id();
        let tokenIds, aliaseIds;
        let useType = props.type;
        if (props.type === TokenTypes.Spacings)
            useType = TokenTypes.SpacePatterns;

        if (props.isAliase) {
            aliaseIds = props.selection.type === 'selected' ? props.selection.tokenIds : Globals.ProjectManager.Tokens.Aliases.Order(useType);        
        }
        else {
            tokenIds = props.selection.type === 'selected' ? props.selection.tokenIds : Globals.ProjectManager.Tokens.Order(useType);        
        }
        

        this.targetTypes = [...TOKEN_EXPORT_TARGETS];

        if (props.type === TokenTypes.ContentTexts) {
            this.targetTypes = [
                {id : 'json', label : 'JSON'},
                {id : 'js', label : 'Javascript'},
                {id : 'android', label : 'Android'},
                {id : 'ios', label : 'iOS'},
            ]
        }

        const options = Globals.ProjectManager.GetExportTokenOptions();
        options.target = this.target;

        const nameFormat = Utils.Get(options, Utils.JustGet(TARGET_DEFAULTS, null, options.target, 'nameFormat'), options.target, 'nameFormat');
        const fileName = Utils.Get(options, Utils.JustGet(TARGET_DEFAULTS, null, options.target, 'fileName'), options.target, 'fileName');
        const tokenTypeOptions = Utils.JustGet(options, {}, 'TokenTypeOptions', options.target, props.type);
        const allTargetOptions = Utils.JustGet(options, {disabled : false}, 'TokenTypeOptions', 'allTargets', props.type);
        const TokenOptions = Utils.Merge(allTargetOptions, tokenTypeOptions);
        this.downloadAsFile = Utils.JustGet(TokenOptions, false, 'downloadAsFile');
        const prefix = Utils.Get(options, 
            Utils.Get(options, null, 'allTargets', 'prefix')
        , options.target, 'prefix');

        const ExportOptions = {
            type : useType,
            tokenIds : tokenIds,
            aliaseIds : aliaseIds,
            target : options.target,
            nameFormat : nameFormat,
            GlobalState : props.LocalGlobaslState || props.GlobalState,
            TokenOptions : TokenOptions,
            onlyModifiedTokens : false,
            generateDataSchema : false,
            namePrefix : prefix,
            StateArray : props.ReversedStyleState || Globals.ProjectManager.ReversedStyleState
        };
        this.ExportResult = GenerateTokenExoprts(ExportOptions);



        // Using Export Definitions

        const exportModels = Globals.ProjectManager.GetExportModels();
        this.exportModelItems = [];
        this.templateId = Globals.ProjectManager.GetDocumentManager().GetPageItemValue(this.props.id, this.props.pageId, null, 'templateId');

        Utils.ForEach(exportModels, (model, i) => {
            if (!this.templateId)
                this.templateId = model.id;
            this.exportModelItems.push({id : model.id, label : model.name});
        });

        const exportDefinition = Globals.ProjectManager.GetExportModel(this.templateId).definition;

        if (exportDefinition.groupData) {
            let exportGroupData;
            Utils.ForEach(exportDefinition.groupData, (exportGroup, id) => {
                if (exportGroup.tokenType === props.type) {
                    exportGroupData = exportGroup;
                    return false;
                }
            });

            if (exportGroupData) {
                const ExportTemplateOptions = {
                    type : useType,
                    tokenIds : tokenIds,
                    aliaseIds : aliaseIds,
                    target : exportDefinition.target,
                    nameFormat : exportDefinition.nameFormat,
                    GlobalState : props.LocalGlobaslState || props.GlobalState,
                    TokenOptions : exportGroupData,
                    onlyModifiedTokens : false,
                    generateDataSchema : false,
                    namePrefix : exportDefinition.prefix,
                    StateArray : props.ReversedStyleState || Globals.ProjectManager.ReversedStyleState
                };
                this.ExportResult = GenerateTokenExoprts(ExportTemplateOptions);
            }
        }

        
    }
    renderCustom() {        
        const heightType = Globals.ProjectManager.GetDocumentManager().GetPageItemValue(this.props.id, this.props.pageId, 'auto', 'heightType');
        const height = Globals.ProjectManager.GetDocumentManager().GetPageItemValue(this.props.id, this.props.pageId, 600, 'height');
        this.showTemplateSelector = Globals.ProjectManager.GetDocumentManager().GetPageItemValue(this.props.id, this.props.pageId, true, 'showTemplateSelector');

        const style = {
            height : heightType === 'auto' ? 'auto' : Utils.px(height)                        
        };
        const toolbarStyle = {
            top : '8px'
        };

        if (heightType === 'custom')  {
            style.overflowY = 'auto';
        }
                 

        if (this.props.preview && !this.showTemplateSelector) {
        }

        const codeStyle = {
            height : '100%'
        };
        let CodeId = this.CodeId;
        if (SC.CurrentTheme.theme.isLight) {
            codeStyle.filter = 'invert(1)';
            CodeId += 'Light';
        }

        let expotTemplateSelector;
                
        if (!this.props.preview || this.showTemplateSelector) {
            expotTemplateSelector = (        
                <React.Fragment>
                    <div>Format</div>
                    <DropDownSelect 
                        items={this.exportModelItems}
                        style={{width : 'auto', minWidth : '120px', marginLeft : '8px', backgroundColor : SC.CurrentTheme.theme.back}}
                        value={this.templateId}
                        onChange={this.ChangeTemplate}
                        hasBorder         
                        noBackColor   
                        autoHeight
                        xsmall
                        toRight
                    />
                </React.Fragment>                    
            )
        }                


        return (
            <SC.FCol style={{
                border : SC.CurrentTheme.theme.border_seperator,
                borderRadius : '2px'
            }}>
                {
                    (!this.props.preview || this.showTemplateSelector) &&
                    <SC.FRow
                        alc
                        style={{
                            backgroundColor : SC.CurrentTheme.theme.back_lighter,
                            borderbottom : SC.CurrentTheme.theme.border_seperator,                        
                            padding : '8px',
                            ...SC.Styles.Font()
                        }}
                    >                    
                        {expotTemplateSelector}
                        {
                            !this.props.preview &&
                            <React.Fragment>                            
                                <div style={{marginRight : '8px', marginLeft : '8px'}}>Hide Format Selection in Preview</div>
                                <Switch round value={!this.showTemplateSelector} onChange={(value) => {
                                    Globals.ProjectManager.GetDocumentManager().SetItemValue(this.props.id, !value, 'showTemplateSelector');
                                    this.RCUpdate();
                                }} />
                            </React.Fragment>
                        }
                    </SC.FRow>
                }
                
                <SC.FCol style={style}>                
                    {
                        this.loaded && 
                        <CopyExportTokenCodeBox 
                            RenderId={CodeId}
                            codeClassName={this.props.codeClassName}
                            exportedCode={this.ExportResult.exportedCode}
                            onDownload={this.Download}
                            inlineDownloadButtons
                            style={{...codeStyle, overflow : 'auto'}}
                            toolbarStyle={toolbarStyle}
                        />
                    }
                    
                </SC.FCol>
            </SC.FCol>            
        )
        
    }
}