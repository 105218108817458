import MetaData from '../toolabs-metaui';
import Utils from '../toolabs-utils';

export default function(PropDef, PropObject, PublishContext) {
    let Value = {

    };

    if (PropObject.ModelId) {
        Value.ModelId = PropObject.ModelId;
    }
    else if (PropObject.TokenId) {
        Value.TokenId = PropObject.TokenId;
        if (PropDef.type === MetaData.DataTypes.string)
            Value.StyleType = MetaData.DataTypes.string;
    }
    else {
        let StyleValue = PropObject.value;
        if (StyleValue !== 'auto' && PropDef.type == MetaData.DataTypes.size) {
            if (PropObject.value !== null && PropObject.value !== undefined) {
                if (PublishContext.Platform.Name === MetaData.Platforms.ReactNative.Name) {
                    StyleValue = PropObject.value || 0;
                }
                else {
                    const unit = ConvertUnit(PropObject.Unit);
                    StyleValue = `${PropObject.value || 0}${unit}`;
                }
            }                        
        }
        else if (PropDef.type === 'enum') {
            const EnumValue = Utils.JustGet(PropDef, null, 'values', StyleValue);
            if (EnumValue) {
                if (EnumValue.css)
                    StyleValue = EnumValue.css;
            }
        }
        Value.value = StyleValue;                                      
    }  
    if (PropDef.type == MetaData.DataTypes.size && Value.value !== undefined) {
        Value.type = 'size';
        Value.unit = ConvertUnit(PropObject.Unit);
    }
    return Value;
}

export const ConvertUnit = (UnitModel, defaultUnit) => {
    let unit = Utils.UseNull(defaultUnit, 'px');
    if (UnitModel && Utils.IsObject(UnitModel)) {
        if (UnitModel.type === 'System')
            unit = UnitModel.unit;
        else if (UnitModel.type === 'Custom') {
            // TODO
        }
    }
    return unit;
};