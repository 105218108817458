import Utils from '../../../../toolabs-utils';

export default (MetaItem, GenericType, Name) => {
    const RootId = Utils.Id();
    MetaItem.name = Name;
    
    const Component = {            
        RootItem : {
            Id : RootId
        },
        MetaItems : {},
        PreviewSize : {
            width : 80,
            height : 40
        },
        Default: {
            'Designboard': {
                'type': 'grid'
            },
            'height': {
                'value': 40
            },                
            'width': {
                'value': 80
            }
        }
            
    };
    Component.MetaItems[RootId] = MetaItem;

    Component.PseudoStates = {
        Order : ['Checked', 'System', 'disabled', 'hover'],        
        System : {
            name : 'System State',
            Variations : {
                Order : ['focus', 'active'],
                active : {
                    name : 'Active',
                    pseudo : true
                },
                focus : {
                    name : 'Focused',
                    pseudo : true
                },                
            }
        },
        Checked : {
            SingleVariation : true,
            name : 'Checked',
            previewable : true
        },
        disabled : {
            SingleVariation : true,
            name : 'Disabled',
            previewable : true,
        },
        hover : {
            SingleVariation : true,
            name : 'Hover',
            pseudo : true
        }
    };

    Component.Handlers = {
        
    };

    Component.Models = {
        List : {
            'Checked': {
                dataType: 'boolean',
                name: 'Checked',
                value : false,
                stateBinding : {
                    variations: {
                        Checked : 'Checked'
                    }
                }
            }
        },
        Order: ['Checked']
    };

    MetaItem.Events = [ 
        
    ];
    
    Component.ThumbId = Utils.Id();

    Utils.MergeTo(MetaItem, {
        Generic : {
            Type : GenericType,
            ThumbId : Component.ThumbId
        },
        Models : {
            Default : {
                Default : {
                    value: {
                        Id: 'Checked'
                    },
                }
            }
        },
        Property: {
            Default: {
                Checked: {
                    backgroundColor: {
                        value: 'rgba(245, 245, 245, 1)'
                    }
                },
                Default: {
                    alignItems: {
                        value: 'flexCenter'
                    },
                    flexDirection: {
                        value: 'column'
                    },
                    justifyContent: {
                        value: 'flexCenter'
                    },
                    layout: {
                        value: 'Flex'
                    },
                    fixedWidth : {
                        value : true
                    },
                    fixedHeight : {
                        value : true
                    },
                    backgroundColor: {
                        value: 'rgba(110, 110, 110, 1)'
                    },
                    borderColor: {
                        value: 'rgba(110, 110, 110, 1)'
                    },
                    borderRadius: {
                        value: 99
                    },
                    borderStyle: {
                        value: 'solid'
                    },
                    borderWidth: {
                        value: 1
                    },
                    height: {
                        value: 40
                    },
                    minHeight: {
                        value: 40
                    },
                    width: {
                        value: 80
                    }
                },
                active: {
                    borderColor: {
                        value: 'rgba(68, 137, 220, 1)'
                    }
                },
                disabled: {
                    backgroundColor: {
                        value: 'rgba(209, 209, 209, 1)'
                    },
                    borderColor: {
                        value: 'rgba(209, 209, 209, 1)'
                    }
                }
            }
        },
        SubItems : [{
            Id : Component.ThumbId
        }]        
    });

    Component.MetaItems[Component.ThumbId] = {        
        Generic : {
            Type : GenericType,
            SubType : 'Thumb'
        },
        ParentId: RootId,
        Property: {
            Default: {
                Checked: {
                    backgroundColor: {
                        value: 'rgba(110, 110, 110, 1)'
                    },
                    left: {
                        Reset: true
                    },
                    right: {
                        value: 3
                    }
                },
                Default: {
                    alignItems: {
                        value: 'flexCenter'
                    },
                    flexDirection: {
                        value: 'column'
                    },
                    justifyContent: {
                        value: 'flexSpaceAround'
                    },
                    layout: {
                        value: 'Flex'
                    },
                    backgroundColor: {
                        value: 'rgba(245, 245, 245, 1)'
                    },
                    borderRadius: {
                        value: 99
                    },
                    height: {
                        value: 32
                    },
                    left: {
                        value: 3
                    },
                    position: {
                        value: 'absolute'
                    },
                    top: {
                        value: 3
                    },
                    width: {
                        value: 32
                    }
                }
            }
        },
        Type: 'Div'
    };
    return Component;
}