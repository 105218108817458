import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    UIUtils,
    Globals
} from '../../../../../../../importer';
import styled from 'styled-components';
import { StyleGroupTokenItem, StyleToken_Color, StyleGroupTitle, StyleGroup, OptionalStyleGroupHeader } from '../common';
import { GetBorderStyleTokenValue } from '../../../../left/designsystem/borders';
import { PanelHeaderAddButton } from '../../../../left/designsystem/common';


export default class BorderStyles extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.SelectBorderStyle = this.SelectBorderStyle.bind(this);
        this.SelectColor = this.SelectColor.bind(this);
        this.SelectedSide = '';
        this.SidesWithValue = {};
        this.SelectBorderSide = this.SelectBorderSide.bind(this);
        this.RemoveTokenBinding = this.RemoveTokenBinding.bind(this);
        this.onAddBorder = this.onAddBorder.bind(this);
        
        this.SelectedSide = Utils.UseNullOrEmpty(this.props.SelectedSide, '');

        this.LoadTokenProps(this.props);
    }
    onAddBorder() {
        this.SelectBorderStyle();
    }
    SelectBorderSide(side) {
        this.SelectedSide = side;
        this.LoadTokenProps(this.props);
        this.showEditor = true;
        this.SelectedSide = side;
        this.RCUpdate();
        this.props.onSideChanged && this.props.onSideChanged(side);
    }
    RemoveTokenBinding() {
        this.props.onRemoveStyle(this.propStyle, this.props.Id, null, [], true);
        this.props.onRemoveStyle(this.propColor, this.props.Id, null);
    }
    LoadTokenProps(props) {

        this.hasNoBorder = true;
        Utils.BorderStyles.Sides.map((side, i) => {
            const propStyle = Utils.BorderStyles.propStyle(side);
            const propColor = Utils.BorderStyles.propColor(side);
            const useSideName = Utils.UseNullOrEmpty(side, 'All');
            this.SidesWithValue[useSideName] = Utils.JustGet(props, null, propStyle, 'TokenId') || Utils.JustGet(props, null, propColor, 'TokenId');

            if (this.SidesWithValue[useSideName])
                this.hasNoBorder = false;
            
        });
        this.propStyle = Utils.BorderStyles.propStyle(this.SelectedSide);
        this.propColor = Utils.BorderStyles.propColor(this.SelectedSide);
        this.tokenId_style = Utils.JustGet(props, null, this.propStyle, 'TokenId');
        this.tokenId_color = Utils.JustGet(props, null, this.propColor, 'TokenId');
    }
    shouldComponentUpdate(nextProps, nextState) {      
          
        if (nextProps.Id !== this.props.Id || nextProps.ManagerId !== this.props.ManagerId) {
            this.showEditor = false;
            this.SelectedSide = '';
            this.LoadTokenProps(nextProps);
            return true;
        }
        
        const tokenId_style = Utils.JustGet(nextProps, null, this.propStyle, 'TokenId');
        const tokenId_color = Utils.JustGet(nextProps, null, this.propColor, 'TokenId');
        if (this.hasNoBorder && (tokenId_style || tokenId_color))
            this.hasNoBorder = false;
        if (tokenId_style !== this.tokenId_style || tokenId_color !== this.tokenId_color) {
            this.tokenId_style = tokenId_style;
            this.tokenId_color = tokenId_color;
            return true;
        }

        if (Utils.HasAnyChange(this.props, nextProps, 'RenderId', 'GlobalStateId', 'GlobalState'))
            return true;     
               
        return super.ShouldUpdate(nextProps, nextState);
    }
    SelectBorderStyle() {
        this.props.onSelectBorderStyle(this.SelectedSide);
    }
    SelectColor() {
        this.props.onSelectBorderColor(this.SelectedSide);
    }
    renderCustom() {        
        const token = {

        };

        let isEmptyStyle = true;
        
        if (this.tokenId_style) {
            const tokenModel = Globals.ProjectManager.Tokens.Token(this.tokenId_style);
            if (tokenModel) {
                const tokenItem = GetBorderStyleTokenValue(tokenModel, this.tokenId_style, this.props.StateArray);
                token.style = tokenItem.style;
                token.width = tokenItem.width;
                token.name = tokenModel.name;
                if (this.tokenId_color) {
                    token.color = Globals.ProjectManager.Tokens.ValueOfId(this.tokenId_color, this.props.StateArray);
                }
                isEmptyStyle = false;
            }            
        }
        let header, content;
        if (this.hasNoBorder && !this.showEditor && !this.props.justContent) {
            header = (
                <OptionalStyleGroupHeader title='BORDER' onClick={this.onAddBorder} />
            )
        }
        else {
            header = (
                <SC.FRow alc jsb>
                    <StyleGroupTitle>BORDER</StyleGroupTitle>
                    <SC.FRow alc style={{fontSize : '11px', height : '18px'}}>
                        {Utils.UseNullOrEmpty(this.SelectedSide, 'All Sides')}                 
                        {
                            !isEmptyStyle &&
                            <SC.Icons.Icon_Button hasFill hasCursor  onClick={this.RemoveTokenBinding} style={{marginLeft : '8px'}}>
                                <SC.Icons.Icon_Delete size={16} />
                            </SC.Icons.Icon_Button>
                        }
                    </SC.FRow>
                </SC.FRow> 
            );
            content = (
                <SC.FRow style={{marginTop : this.props.justContent ? 0 : '8px'}}>
                    <SC.FCol alc justifyCenter>
                        <BorderSideSelector
                            onSelectSide={this.SelectBorderSide}
                            sidesWithValue={this.SidesWithValue}
                            side={this.SelectedSide}
                        >

                        </BorderSideSelector>
                    </SC.FCol>
                    <div style={{flex : 1, overflow : 'hidden'}}>                           
                        <StyleTokenBorderStyle 
                            onSelectBorderStyle={this.SelectBorderStyle}
                            tokenId={this.tokenId_style}
                            StateArray={this.props.StateArray}
                        />
                        <StyleToken_Color 
                            tokenId={this.tokenId_color} 
                            onSelect={this.SelectColor} 
                            label='Border Color'
                            StateArray={this.props.StateArray}
                        />
                    </div>
                </SC.FRow>
            )
        }

        if (this.props.justContent)
            return content;

        return (
            <StyleGroup empty={this.hasNoBorder} key={this.hasNoBorder ? 'noBorder' : 'border'}>
                {header}
                {content}
            </StyleGroup>
        )        
    }
}

export class BorderSideSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    SelectSide(side) {
        this.props.onSelectSide(side);
    }
    onOnverSide(side, show) {
        this.setState({
            OverSide : show ? side : null
        })
    }
    render() { 
        
        const style_border = {
            borderTopColor : SC.CurrentTheme.theme.border_editor_default,
            borderLeftColor : SC.CurrentTheme.theme.border_editor_default,
            borderRightColor : SC.CurrentTheme.theme.border_editor_default,
            borderBottomColor : SC.CurrentTheme.theme.border_editor_default
        }
        const bordersideItems = [];
        ['Top', 'Left', 'Right', 'Bottom'].map((side) => {
            const hasValue = this.props.sidesWithValue[side];
            if (hasValue) {
                const stylename = Utils.BorderStyles.propColor(side);
                style_border[stylename] = SC.CurrentTheme.theme.border_editor_styled;
            }
            if (this.props.side === side || this.state.OverSide === side) {
                const stylename = Utils.BorderStyles.propColor(side);
                style_border[stylename] = SC.CurrentTheme.theme.border_editor_selected;
            }
                
            bordersideItems.push((
                <BorderSide 
                    key={side}
                    top={side === 'Top'}
                    left={side === 'Left'}
                    right={side === 'Right'}
                    bottom={side === 'Bottom'}
                    hasValue={hasValue} 
                    selected={this.props.side === side} 
                    onClick={this.SelectSide.bind(this, side)} 
                    onMouseEnter={this.onOnverSide.bind(this, side, true)}
                    onMouseLeave={this.onOnverSide.bind(this, side, false)}
                />
            ));
        });
        return (  
            <div style={{position : 'relative', width : '70px', height : '70px', marginRight : '8px'}}>
                {bordersideItems}                
                <div style={{
                    position : 'absolute',
                    top : '4px',
                    left : '4px',
                    right : '4px',
                    bottom : '4px',
                    border : '2px solid',
                    borderRadius : '2px',
                    pointerEvents : 'none',
                    transition : 'all 0.2s ease',
                    ...style_border
                }} 
                />
                <BorderAllSides 
                    selected={this.props.side === ''}
                    hasValue={this.props.sidesWithValue.All}
                    onClick={this.SelectSide.bind(this, '')} 
                />                
            </div>
        );
    }
}

const BorderSide = ({top, left, right, bottom, hasValue, selected, ...props}) => {
    let style = {};
    if (top) {
        style = {
            top : 0,
            left : '50%',
            transform : 'translateX(-50%)'
        }
    }
    else if (bottom) {
        style = {
            bottom : 0,
            left : '50%',
            transform : 'translateX(-50%)'
        }
    }
    else if (left) {
        style = {
            left : 0,
            top : '50%',
            transform : 'translateY(-50%)',
            width : '10px',
            height : '50%'
        }
    }
    else if (right) {
        style = {
            right : 0,
            top : '50%',
            transform : 'translateY(-50%)',
            width : '10px',
            height : '50%'
        }
    }

    return (
        <BorderSideBox 
            style={style}
            hasValue={hasValue}
            selected={selected}
            onClick={props.onClick}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
        />  
    )
}

const BorderAllSides = styled.div`
    position : absolute;
    top : 20px;
    left : 20px;
    right : 20px;
    bottom : 20px;
    border : 2px solid;
    border-color : ${props => props.selected ? props.theme.border_editor_selected : (props.hasValue ? props.theme.border_editor_styled : SC.CurrentTheme.theme.border_editor_default)};
    border-radius : 2px;
    cursor : pointer;
    z-index : 1000;
    transition : border-color 0.2s ease;
    &: hover{
        border-color : ${props => props.theme.border_editor_selected};
    }
`;

const BorderSideBox = styled.div`
    position : absolute;
    background-color : ${props => props.selected ? props.theme.border_editor_selected : (props.hasValue ? props.theme.border_editor_styled : SC.CurrentTheme.theme.border_editor_default)};
    width : 50%;
    height : 10px;
    border-radius : 2px;
    zIndex : 1;
    cursor : pointer;
    transition : background-color 0.2s ease;
    &: hover{
        background-color : ${props => props.theme.border_editor_selected};
    }
`;
 
export const StyleTokenBorderStyle = ({onSelectBorderStyle, tokenId, StateArray}) => {
    const token = {

    };

    if (tokenId) {
        const tokenModel = Globals.ProjectManager.Tokens.Token(tokenId);
        if (tokenModel) {
            const tokenItem = GetBorderStyleTokenValue(tokenModel, tokenId, StateArray);
            token.style = tokenItem.style;
            token.width = tokenItem.width;
            token.name = tokenModel.name;            
        }            
    }

    return (
        <StyleGroupTokenItem onClick={onSelectBorderStyle} >
            <SC.FRow>
                <SC.FCol f1 style={{justifyContent : 'space-evenly', padding : '8px', overflow : 'hidden'}}>
                    <SC.TextDivAbbr style={{flex : 1}}>
                        {Utils.UseNullOrEmpty(token.name, 'Border Style')}
                    </SC.TextDivAbbr>                                
                </SC.FCol>
                <div 
                    style={{
                        width : '52px',
                        marginRight : '8px',
                        alignSelf : 'center',
                        borderTopStyle : token.style,
                        borderTopWidth : token.width,
                        borderTopColor : SC.CurrentTheme.theme.color_brand
                    }} 
                />
            </SC.FRow>
        </StyleGroupTokenItem>
    )
}