import Platforms from '../Platforms';
import DataType from '../datatypes';

const Actions = {
    RunFunction : {
        Type : 'Code',
        label : 'Run Code'
    },
    Propagate : {
        Type : 'Propagate',
        label : 'Propagate Event'
    },
    ToState : {
        Type  : 'ToState',
        label : 'Change Component State'
    },
    ChangeGlobalState : {
        Type  : 'ChangeGlobalState',
        label : 'Change System State'
    },
    SetModelValue : {
        Type : 'SetModelValue',
        label : 'Change Variables'
    },
    PlaySound : {
        Type : 'PlaySound',
        label : 'Play Sound'
    },
    TriggerEvent : {
        Type : 'TriggerEvent',
        label : 'Trigger Event'
    },
    ToView : {
        Type : 'ToView',
        label : 'Goto Page'
    },
    Timer : {
        Type : 'Timer',
        label : 'Start Timer'
    }
};

export const MetaConditions = {
    Operands : {
        Variable : {
            Type : 'Variable',
            label : 'Check variable value'
        },
        OtherCondition : {
            Type : 'Condition',
            label : 'Check another condition'
        },
        Custom : {
            Type : 'Custom',
            label : 'Static Value'
        }
    },
    Operators : {
        IsTrue : {
            Type : 'IsTrue',
            label : 'True'
        },
        IsEqual : {
            Type : 'IsEqual',
            label : 'Equal'
        },
        Greator : {
            Type : 'Greator',
            label : 'Greator'
        },        
        GreatorEqual : {
            Type : 'GreatorEqual',
            label : 'Greator & Equal'
        },
        Smaller : {
            Type : 'Smaller',
            label : 'Smaller'
        },
        SmallerEqual : {
            Type : 'SmallerEqual',
            label : 'Smaller & Equal'
        },
        IsOneOf : {
            Type : 'IsOneOf',
            label : 'One Of'
        },
        IsEmpty : {
            Type : 'IsEmpty',
            label : 'Empty'
        }
    }
}

export default Actions;