import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Strings,
    Events,
    MetaData,
    UIUtils
} from '../../../../../../importer';
import styled, {css} from 'styled-components';
import Input_Name from '../../../../../../components/editors/input_name';

export default class NodeNameEditor extends ReactBaseComponent
{
    constructor(props) {
        super(props);
        this.onFocus = this.onFocus.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSaveName = this.onSaveName.bind(this);
        this.ValidateName = this.ValidateName.bind(this);

        this.Ref_Name = React.createRef();

        this.state.value = Utils.UseNull(this.props.name, '');
    }
    onFocus(e) {
        AppState.Designer.SetEditingText('NodeName');;
    }    
    onCancel() {
        AppState.Designer.EditingText = false;
        this.props.onClose && this.props.onClose(this.state.value);
    }
    componentWillUnmount() {
        super.componentWillUnmount();
        this.onCancel();
    }    
    onSaveName() {        
        this.props.onNameChange(this.Ref_Name.current.GetValue());
        this.onCancel();
    }  
    ValidateName(newName) {
        if (this.props.onValidate)
            return this.props.onValidate(newName);
    }
    renderCustom() {
        return (
            <Input_Name
                ref={this.Ref_Name}
                InputType={ST_InputName}
                required
                autoFocus
                autoSelect
                value={this.state.value}
                onSubmit={this.onSaveName}
                styleProps = {{                    
                    onFocus : this.onFocus,
                    onBlur : this.onSaveName,                    
                    onMouseDown : (e) => e.stopPropagation(),
                    onMouseUp : (e) => e.stopPropagation(),
                    onClick : (e) => e.stopPropagation(),
                    placeholder : 'Name',             
                    style : this.props.style       
                }}
                onValidate={this.ValidateName}
                onCancel={this.onCancel}
                onChange={this.onNameChange}
                showError={this.props.selected}
            />
        )
    }
}


const ST_InputName = styled.input`
    font-size : 11px;
    border-width : 1px;
    border-style : solid;
    padding : 2px;
    border-radius : 2px;
    border-color : ${props => props.theme.color_brand};
    width : 100%;
    box-sizing : border-box;
    background-color : rgba(0,0,0,0);
`;