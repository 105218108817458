import React from 'react';
import {
    SC,
    Utils,
    Links,
    AppLayout,
    ReactBasicComponent
} from '../../../importer';
import styled from 'styled-components';
import DropDownSelect from '../../../components/editors/enum_dropdown';
import ShareButton from './share';

export default class TopNavigator extends ReactBasicComponent
{
    constructor(props) {
        super(props);

        this.OnRouteChanged = this.OnRouteChanged.bind(this);
    }    
    shouldComponentUpdate(nextProps, nextState) {
        if (Utils.HasAnyChange(this.props, nextProps, 'BoardId', 'components', 'preview', 'documents', 'learn', 'settings', 'importExportTokens', 'api', 'small'))
            return true;
        return super.ShouldUpdate(nextProps, nextState);
    }
    OnRouteChanged(module) {
        if (module === 'DSDocument') {
            AppLayout.Refs.MainApp.RedirectTo(Links.Board_Documents(this.props.BoardId));
        }
        else if (module === 'ImportExportTokens') {
            AppLayout.Refs.MainApp.RedirectTo(Links.Board_ImportExportTokens(this.props.BoardId));
        }
        else if (module === 'API') {
            AppLayout.Refs.MainApp.RedirectTo(Links.Board_API(this.props.BoardId));
        }
        else if (module === 'Content') {
            AppLayout.Refs.MainApp.RedirectTo(Links.Board_RealContent(this.props.BoardId));
        }
        else if (module === 'Learn') {
            AppLayout.Refs.MainApp.RedirectTo(Links.LearnCenter(this.props.BoardId));
        }
        else if (module === 'Settings') {
            AppLayout.Refs.MainApp.RedirectTo(Links.Board_Settings(this.props.BoardId));
        }
        else {
            AppLayout.Refs.MainApp.RedirectTo(Links.Board_ResponsiveComponentsCanvas(this.props.BoardId));            
        }  
    }
    
    renderCustom() {

        let tools;

        if (this.props.components || this.props.documents) {
            const toolItems = [];

            let previewLink;
            if (this.props.components)
                previewLink = this.props.preview ? Links.Board_ResponsiveComponentsCanvas(this.props.BoardId, this.props.components.id) : Links.Board_ResponsiveComponentsCanvas_Preview(this.props.BoardId, this.props.components.id);
            else
                previewLink = this.props.preview ? Links.Board_Documents(this.props.BoardId) : Links.Board_Documents_Preview(this.props.BoardId);
            toolItems.push(
                <NavigationButton
                    key='preview'
                    style={{
                        ...SC.Styles.Flex.Row,
                        alignItems : 'center',
                        cursor : 'default',
                        paddingRight : '8px',
                        marginRight : '8px',
                        border : 'none'
                    }}
                >
                    <Links.Link To={previewLink} title='Preview'>
                        <SC.Icons.Icon_Button theme={this.props.theme} hasCursor hasFill style={{height : '100%'}}>
                            <SC.Icons.Icon_View size={20} off={this.props.preview} />
                        </SC.Icons.Icon_Button>
                    </Links.Link>
                </NavigationButton>
            )

            if (this.props.documents) {              
                toolItems.push(
                    <ShareButton key='share' theme={this.props.theme} documents />
                )
            }        
            
            tools = (
                <SC.FRow alc style={{
                    position : 'absolute',
                    left : '100%',
                    top : 0, 
                    bottom : 0
                }}>
                    {toolItems}
                </SC.FRow>
            )
        }

        if (this.props.small || true) {
            let selectedModule = 'ResponsiveComponentCanvas';
            if (this.props.documents)
                selectedModule = 'DSDocument';
            else if (this.props.importExportTokens)
                selectedModule = 'ImportExportTokens';
            else if (this.props.api)
                selectedModule = 'API';
            else if (this.props.realContent)
                selectedModule = 'Content';
            else if (this.props.learn)
                selectedModule = 'Learn';
            else if (this.props.settings)
                selectedModule = 'Settings';

            const designerlabel = (
                <SC.FRow justifyCenter alc>
                    <div>Component Designer</div>
                    <div style={{
                        fontFamily : 'monotspace',
                        letterSpacing : 0,
                        fontSize : '10px',
                        marginTop : '-2px',
                        marginLeft : '8px'

                    }}>[ BETA ]</div>
                </SC.FRow>
            );

            const style = {
                paddingLeft : '8px', paddingRight : '4px', borderRadius : '4px', backgroundColor : this.props.theme.back_lighter, border : this.props.theme.border_seperator
            };

            return (
                <SC.FRow alc style={{position : 'relative', color : this.props.theme.font}}>
                    <DropDownSelect
                        fullwidth
                        autoHeight
                        dropDownSize={10}
                        useTheme={this.props.theme}
                        placeholder='Select Module'
                        style={{minWidth : '300px', maxHeight : '28px', marginRight : '16px', borderRadius : '2px', border : this.props.theme.border_ondark}}
                        popupItemStyle={{
                            minHeight : '32px',
                            maxHeight : '32px',
                        }}
                        renderLabelItem={(item) => {
                            return (
                                <DropDownMenuItem theme={this.props.theme} style={{flex : 1, fontSize : '11px', justifyContent : 'center', letterSpacing : '0.10em', wordSpacing : '0.5em', fontWeight : 500}}>
                                    {item}                                
                                </DropDownMenuItem>
                            )
                        }}
                        renderItem={(item, selected) => {
                            return (
                                <SC.FRow f1 alc jsb>                                    
                                    <div style={{textAlign : 'center', color : this.props.theme.font, flex : 1,  textTransform : 'uppercase', padding : '2px', letterSpacing : '0.10em', wordSpacing : '0.5em', fontWeight : 500}}>{item.label}</div>
                                    {selected && <SC.Icons.Icon_Button theme={this.props.theme} hasFill fill={this.props.theme.color_brand} style={{marginLeft : '4px'}}><SC.Icons.Icon_Check size={20} /></SC.Icons.Icon_Button>}
                                </SC.FRow>
                            )
                        }}
                        items={[
                            {id : 'DSDocument', label : 'Design System Documentation'},
                            {id : 'ImportExportTokens', label : 'Export Tokens'},
                            {id : 'API', label : 'GraphQL API'},
                            {id : 'Content', label : 'Real Content Editor'},
                            {id : 'ResponsiveComponentCanvas', label : designerlabel},
                            {id : 'Settings', label : 'Project Settings', hasSeperator : true},
                            {id : 'Learn', label : 'Learn Center'}
                        ]}
                        onChange={this.OnRouteChanged}
                        value={selectedModule}
                        boxStyle={style}
                        style={{minHeight : '28px', height : '28px', width : '340px'}}
                        popupStyle={{minWidth : 'unset', zIndex : 999999999, top : 0, marginTop : 0}}
                        popupInnerStyle={{paddingTop : '4px', paddingBottom : '4px'}}
                        popupFromDown
                    />
                    {tools}
                </SC.FRow>
            )
        }
    }
}

const DropDownMenuItem = styled.div`
    font-size : 14px;
    letter-spacing : 0.07em;    
    display : flex;
    align-items : center;
    height : 100%;
    cursor : pointer;
    font-weight : 500;
    text-transform : uppercase;
    color : ${props => props.selected ? props.theme.font_hover : props.theme.font};
    &:hover {
        color : ${props => props.theme.font_hover};
    }
`;

const NavigationButton = styled.div`
    position : relative;
    letter-spacing : 0.07em;    
    display : flex;
    align-items : center;
    cursor : pointer;
    font-weight : ${props => props.active ? (props.theme.isLight ? 700 : 500) : (props.theme.isLight ? 500 : 300)};
    text-transform : uppercase;
    color : ${props => props.active ? props.theme.font_hover : props.theme.font};
    padding-left : 16px;
    padding-right : 16px;
    min-height : 20px;
    border-left : ${props => props.active ?  props.theme.border_brand : props.theme.border_onlight_line};
    border-width : 2px;
    &:hover {
        color : ${props => props.theme.font_hover};
    }
`;

const LinkButton = styled.button`
    padding-left : 16px;
    padding-right : 16px;
    border-radius : 99px;
    background-color : ${props => props.theme.back_lighter};
    color : ${props => props.theme.font};
    border : 1px solid transparent;
    height : 28px;    
    cursor : pointer;
    &:hover {
        background-color : ${props => props.theme.back_lightest};
        border : ${props => props.theme.border_brand};
    }
`;