import React from 'react';
import {
    SC,
    Globals,
    Utils,
    AppLayout
} from '../../../../../../../importer';

import { GroupTitle } from '../../../../left/designsystem/common';
import { ListCardView } from '../../../../../../../components/light_dark_bar';
import BorderStyleEditor from '../../../../left/designsystem/borders/border';
import { BorderStyleTokenItem, GetBorderStyleTokenList, GetBorderStyleTokenValue } from '../../../../left/designsystem/borders';
import BaseSelectToken from '../BaseSelectToken';
import { GetAliaseTokenItem, GetTokenAliaseList } from '../../../../left/designsystem/aliases/aliaseGroup';

export default class SelectBorderStyle extends BaseSelectToken
{
    constructor(props) {
        super(props);

        this.titleNewToken = 'NEW BORDER';
        this.moduleName = 'BorderStyle';
        this.tokenType = Globals.ProjectManager.Tokens.Types.Borders;        
        this.hasCustomTokenList = true;
        this.state.isListView = false;
        this.DoNotCloseOnSave = true;

        this.hasAliases = !this.props.forAliase;
        this.state.isAliaseListView = true;

        this.Load(this.props);
        
        this.StyleValues = {};
        
        this.SelectedSide = this.props.side;
    }
    IsTokenPropChanged(nextProps, nextState) {
        return this.props.propName !== nextProps.propName ||  this.props[this.props.propName] !== nextProps[this.props.propName];
    }
    GetTokens(props) {

        this.aliases = GetTokenAliaseList(Globals.ProjectManager.Tokens.Types.Borders);
        if (this.props.excludeIds) {
            this.props.excludeIds.map((excludeId) => {
                Utils.Remove(this.aliases, (item) => {return item.id === excludeId});
            })            
        }

        this.aliases.map((aliase) => {
            aliase.tokenItem = GetAliaseTokenItem(aliase, Globals.ProjectManager.Tokens.Types.Borders);
        })

        return GetBorderStyleTokenList();
    }        
    GetNewModel(Id) {
        const item = {
            type : Globals.ProjectManager.Tokens.Types.Borders,
            name : 'New Border',            
            id : this.EditingToken.Id
        };
        Globals.ProjectManager.Tokens.Add(item);
        this.FireSelectToken(Id);
        return item;
    }   
    onCloseTokenEditor() {
        if (this.EditingToken && this.EditingToken.IsNew) {
            delete this.EditingToken;            
            this.RCUpdate();
            return;
        }
        super.onCloseTokenEditor();
    }
    onCancelAddToken() {
        const willDeleteId = this.EditingToken.Id;
        this.FireSelectToken(this.LastBoundTokenId);
        delete this.LastBoundTokenId;
        super.onCancelAddToken();        
        Globals.ProjectManager.Tokens.Delete(Globals.ProjectManager.Tokens.Types.Borders, willDeleteId);
    }
    SaveToken() {
        if (this.EditingToken.IsNew) {           
            Globals.ProjectManager.Tokens.SetValue({id : this.EditingToken.Id, value : this.EditingToken.Model.value, type : Globals.ProjectManager.Tokens.Types.Borders});          
            AppLayout.Refs.DesignSystem.Borders && AppLayout.Refs.DesignSystem.Borders.Reload();
            this.Load();
            return {
                type : Globals.ProjectManager.Tokens.Types.Borders,
                name : this.EditingToken.Model.name,
                value : this.EditingToken.Model.value,
                id : this.EditingToken.Id
            };
        }        
        else {
        }                
    }   
    UpdateSelectedToken() {
        if (this.EditingToken) {
            const SelectedToken = Utils.Find(this.tokens, (token) => {return token.id === this.EditingToken.Id});        
            const token = Globals.ProjectManager.Tokens.Token(this.EditingToken.Id);
            const item = GetBorderStyleTokenValue(token, this.EditingToken.Id, this.props.StateArray);
            SelectedToken.width = item.width;
            SelectedToken.style = item.style;
            AppLayout.Refs.DesignSystem.Borders && AppLayout.Refs.DesignSystem.Borders.Reload();
        }
    }
    GetStyleName() {
        return Utils.BorderStyles.propStyle(this.SelectedSide);
    }
    GetSelectedTokenId() {
        if (this.props.propName === 'tokenId')
            return this.props.tokenId;
        return Utils.JustGet(this.StyleValues, null, Utils.UseNullOrEmpty(this.SelectedSide, 'All'));
    }
    renderTokenAliaseListHeader() {
        return (
            <GroupTitle 
                title='SEMANTIC TOKENS' 
                hasSearch={{onSearch : this.SearchAliase}}
                hasAdd={{onAdd : this.AddTokenAlias}}
                addOn={(
                    <SC.FRow>
                        <ListCardView 
                            onListView={this.SetAliaseListMode.bind(this, true)}
                            onCardView={this.SetAliaseListMode.bind(this, false)}
                            isListView={this.state.isAliaseListView}
                            isCardView={!this.state.isAliaseListView}
                            style={{marginRight : '4px'}}
                            compact
                        />
                    </SC.FRow>                    
                )}
                style={{padding : 0, paddingLeft : '4px', marginBottom : '4px'}}
            />
        )
    }

    renderTokenAliases() {
        return (
            <SC.FCol style={{fontSize : '11px'}}>                        
                {
                    this.aliases.map((aliase, i) => {
                        if (aliase.filtered)
                            return null;
                        return (
                            <BorderStyleTokenItem
                                key={aliase.id}
                                border={aliase.tokenItem}
                                name={aliase.name}
                                isListView={this.state.isAliaseListView}
                                onSelect={this.SelectToken.bind(this, aliase.id)}
                                selected={aliase.id === this.GetSelectedTokenId()}
                                onPreview={{
                                    onMouseEnter : this.PreviewToken.bind(this, true, aliase.id, this.props.propName),
                                    onMouseLeave : this.PreviewToken.bind(this, false, aliase.id, this.props.propName)
                                }}
                            />   
                        )
                    })
                }
            </SC.FCol>
        )     
    }
    renderTokenList() {        
        return (
            <SC.FCol style={{fontSize : '11px'}}>                        
                {
                    this.tokens.map((border, i) => {
                        if (border.filtered)
                            return null;
                        return (
                            <BorderStyleTokenItem
                                key={border.id}
                                border={border}
                                isListView={this.state.isListView}
                                onSelect={this.SelectToken.bind(this, border.id)}
                                selected={border.id === this.GetSelectedTokenId()}
                                onPreview={{
                                    onMouseEnter : this.PreviewToken.bind(this, true, border.id, this.props.propName),
                                    onMouseLeave : this.PreviewToken.bind(this, false, border.id, this.props.propName)
                                }}
                            />   
                        )
                    })
                }
            </SC.FCol>
        )       
    }
    renderTokenEditor() {
        return (
            <BorderStyleEditor 
                id={this.EditingToken.Id}
                newModel={this.EditingToken.IsNew ?  this.EditingToken.Model : null}  
                onClose={this.onCancelAddToken}
                onPreviewChange={this.EditingToken.IsNew ? this.PreviewNewToken : null}
            />                
        )        
    }   
}