import {
    AppState,
    Events,
    Strings,
    MetaData,
    Utils,
    Globals
} from '../../../../importer';

import { LOG_TYPES } from '../../../../appstate/historymanager/base';

export default class ComponentModelManager {
    constructor(Manager) {
        this.Manager = Manager;
    }
        

    List() {
        return this.Manager.DataManager.Get({}, Strings.MODELS, Strings.LIST);
    }
    Order() {
        return this.Manager.DataManager.Get([], Strings.MODELS, Strings.ORDER);
    }
    GetModel(Id) {
        const models = this.List();
        if (models)
            return models[Id];
    }
    Add(Model) {
        // TODO Check Duplicate Name
        const Models = this.List();
        const Id = Utils.Id();
        this.Manager.Log({
            Target : LOG_TYPES.MODEL_ADD,
            Id : Id,
            Desc : `New Model : ${Model.name}`
        });
        Models[Id] = Model;

        if (Model.TokenId) {
            Model.RelationId = Globals.ProjectManager.RelationManager.AddRelation(this.Manager.Id, Id, Model.TokenId,
            {
                OwnerType : 'Component',
                ConsumerType : Globals.ProjectManager.RelationManager.Types.VARIABLE,
                State : Strings.DEFAULT,
                ViewId : this.Manager.PrototypeViewId
            });                    
        }
        else if (Model.DataId) {
            Model.value = Globals.ProjectManager.Mockups.GetRandomValue(Model.DataId);
        }
        this.Manager.DataManager.Set(Model, Strings.MODELS, Strings.LIST, Id);
        const ModelOrders = this.Order();
        ModelOrders.push(Id);
        this.Manager.DataManager.Set(ModelOrders, Strings.MODELS, Strings.ORDER);
        return Id;
    }
    CloneModel(Id) {
        const sourcemodel = this.GetModel(Id);
        const model = Utils.DeepClone(sourcemodel);
        model.name = 'Copy of ' + model.name;
        return this.Add(model);
    }
    DeleteModel(Id) {
        if (this.Manager.RelationManager.HasRelation(Id)) {
            Events.AlertSimple(Strings.Error_Delete(Strings.MESSAGE_MODEL_IS_USED), Events.GLOBAL.NOTIFICATION.TYPES.WARNING);
            return false;
        }
        const Model = this.GetModel(Id);
        this.Manager.Log({
            Target : LOG_TYPES.MODEL_DELETE,
            Id : Id,
            Desc : `Delete Model : ${Model.name}`
        });
        this.Manager.DataManager.Delete(Strings.MODELS, Strings.LIST, Id);
        this.Manager.RelationManager.DeleteConsumer(this.Manager.Id, Id);
        // TODO : Delete from Models.States...

        const ModelOrders = this.Order();
        Utils.RemoveEquals(ModelOrders, Id);
        this.Manager.DataManager.Set(ModelOrders, Strings.MODELS, Strings.ORDER);
        return true;
    }
    SaveModel(id, model) {
        if (model.TokenId && !model.RelationId) {
            model.RelationId = Globals.ProjectManager.RelationManager.AddRelation(this.Manager.Id, id, model.TokenId,
                {
                    OwnerType : Globals.ProjectManager.RelationManager.Types.COMPONENT,
                    ConsumerType : Globals.ProjectManager.RelationManager.Types.VARIABLE,
                    ViewId : this.Manager.PrototypeViewId
                });
        }
        else if (!model.TokenId && model.RelationId) {
            Globals.ProjectManager.RelationManager.DeleteRelationId(model.RelationId);
        }
        this.Manager.DataManager.Set(model, Strings.MODELS, Strings.LIST, id);
        this.Manager.UpdateAll();
    }
    SetTokenId(id, tokenId) {
        const model = this.GetModel(id); 
        if (model && !model.DataId) {
            delete model.PreviewTokenId;
            if (model.RelationId) {
                if (model.TokenId === tokenId)
                    return true;
                Globals.ProjectManager.RelationManager.DeleteStateRelations(model.RelationId);
                delete model.RelationId;
            }            
            model.TokenId = tokenId;
            this.SaveModel(id, model);
            return true;
        }
        return false;
    }
    SetFakeDataId(id, dataId) {
        const model = this.GetModel(id); 
        if (model) {
            if (model.DataRelationId) {
                if (model.DataId === dataId)
                    return;
                Globals.ProjectManager.RelationManager.DeleteStateRelations(model.DataRelationId);
                delete model.DataRelationId;
            }            
            model.DataId = dataId;
            if (dataId)
                model.value = Globals.ProjectManager.Mockups.GetRandomValue(dataId);
            this.SaveModel(id, model);
        }
    }
    SetPreviewToken(id, tokenId) {
        const model = this.GetModel(id); 
        if (model) {
            if (tokenId)
                model.PreviewTokenId = tokenId;
            else
                delete model.PreviewTokenId;
        }
    }
    ChangeName(id, name) {
        this.Manager.DataManager.Set(name, Strings.MODELS, Strings.LIST, id, 'name');
    }
    ChangeOrder(oldIndex, newIndex) {
        const ModelOrders = this.Order();
        Utils.ChangePlace(ModelOrders, oldIndex, newIndex);
        this.Manager.DataManager.Set(ModelOrders, Strings.MODELS, Strings.ORDER);
    }
    GetValue(modelId) {
        const model = this.GetModel(modelId);
        if (model) {
            let tokenId = model.PreviewTokenId || model.TokenId;
            if (tokenId) {
                const tokenValue = Globals.ProjectManager.Tokens.GetStateValue({Id : tokenId, StateArray : this.Manager.ReversedGlobalStateArray});                
                if (tokenValue) {
                    return tokenValue;
                }
            }
            return model.value;
        }            
    }
    SetValue(modelId, value) {
        const model = this.GetModel(modelId);
        model.value = value;
        this.SaveModel(modelId, model);
    }
}