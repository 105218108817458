import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    ReactBasicComponent,
    SC,
    Utils,
    AppLayout,    
    Globals,
    Strings,
    AppState
} from '../../../../../importer';
import {FontLoader} from '../../../../../toolabs-importer';

import { PropertyLabel, Seperator, StyleGroupTokenItem } from '../../../panels/right/iteminspector/styleitems/common';
import Slider from '../../../../../components/editors/slider';
import SelectDocumentColor, {DocumentColorProp as ColorPicker} from './colorEditor';
import { PanelHeaderAddButton, TokenPanelHeader, TokenItemBox, GroupTitle } from '../../../panels/left/designsystem/common';
import { BaseSizeSlider, SliderScaleInput } from '../../../panels/left/designsystem/typography';
import FontSelectPanel, { FontWeightDropdown } from '../../../panels/left/designsystem/typography/fonts/selector';
import { AnimatePresence, motion } from 'framer-motion';
import { TextSpanAbbr } from '../../../../../styled';
import DropDownSelect from '../../../../../components/editors/enum_dropdown';
import { FontFamilyPreview, FontFamilyDisplayName } from '../../../panels/left/designsystem/typography/fonts';
import RadioGroup from '../../../../../components/editors/radiogroup';
import SelectToken from '../../../panels/right/iteminspector/selectToken';

export default class DocumentStyleEditor extends ReactBaseComponent
{
    constructor(props) {
        super(props);          

        this.state.selectedTextItem = {
            id : 'body',
            label : 'Body'
        }
        
    }
    onChangeColor(prop, colorType, color) {
        if (this.willShowSelectColor.textId) {
            if (colorType === 'token') {
                Utils.Set(this.props.documentStyles, color, this.willShowSelectColor.textId, prop + 'TokenId');
            }
            else {
                Utils.UnSet(this.props.documentStyles, this.willShowSelectColor.textId, prop + 'TokenId');
                Utils.Set(this.props.documentStyles, color, this.willShowSelectColor.textId, prop, SC.CurrentTheme.theme.isLight ? 'light' : 'dark');
            }
        }
        else {
            if (colorType === 'token') {
                Utils.Set(this.props.documentStyles, color, prop + 'TokenId');
            }
            else {
                Utils.UnSet(this.props.documentStyles, prop + 'TokenId');
                Utils.Set(this.props.documentStyles, color, prop, SC.CurrentTheme.theme.isLight ? 'light' : 'dark');
            }
        }        
        
        Globals.ProjectManager.GetDocumentManager().SetStyles(this.props.documentStyles);
        this.props.onUpdate();
    }        
    ToggleFontSelector(font) {
        if (this.willShowAddFont)
            delete this.willShowAddFont;
        else {
            this.willShowAddFont = {
                font : font
            }
        } 

        this.RCUpdate();
    }
    ToggleColorSelector(prop, color, tokenId, forText) {
        if (this.willShowSelectColor)
            delete this.willShowSelectColor;
        else {
            this.willShowSelectColor = {
                prop : prop, 
                tokenId : tokenId,
                color : color,
                forText : forText
            }
        } 

        this.RCUpdate();
    }
    ToggleTextColorSelector(textId, prop, color, tokenId, forText) {
        if (this.willShowSelectColor)
            delete this.willShowSelectColor;
        else {
            this.willShowSelectColor = {
                prop : prop, 
                tokenId : tokenId,
                color : color,
                textId : textId,
                forText : forText
            }
        } 

        this.RCUpdate();
    }
    ToggleTextPatternSelector(textId, tokenId) {
        if (this.willShowTextSelector)
            delete this.willShowTextSelector;
        else {
            this.willShowTextSelector = {
                textId,
                tokenId : tokenId
            }
        } 

        this.RCUpdate();
    }
    SelectFont(font) {
        const fonts = Utils.Get(this.props.documentStyles, [], 'fonts');
        let fontId = Utils.Id();
        if (this.willShowAddFont && this.willShowAddFont.font) {            
            const index = Utils.FindIndex(fonts, (item) => {return item.id === this.willShowAddFont.font.id});
            fontId = fonts[index].id;
            fonts.splice(index, 1, {
                ...font,
                id : fontId
            });
        }
        else {
            fonts.push({            
                ...font,
                id : fontId
            });
        }
        
        if (font.provider === Strings.FONT_GOOGLE) {
            FontLoader.Load(font.family, font.variant, font.url);                            
        }
        else if (font.provider === Strings.CUSTOM) {
            Globals.ProjectManager.Tokens.LoadCustomFont(font.fontId);
        }

        Globals.ProjectManager.GetDocumentManager().SetStyles(this.props.documentStyles);
        this.props.onUpdate();
        this.ToggleFontSelector();
    }
    DeleteFont(font) {
        const fonts = Utils.Get(this.props.documentStyles, [], 'fonts');
        Utils.Remove(fonts, (item) => {return item.id === font.id});
        Globals.ProjectManager.GetDocumentManager().SetStyles(this.props.documentStyles);
        this.RCUpdate();
    }
    renderCustom() {
        let content, overlayContent;

        const colorTheme = SC.CurrentTheme.theme.isLight ? 'light' : 'dark';

        if (this.willShowAddFont) {
            overlayContent = (
                <motion.div 
                    style={{...SC.Styles.Absolute, zIndex : 10000, display : 'flex', flexDirection : 'column'}}
                    initial={{opacity : 0.7, x : 24}}
                    animate={{opacity : 1, x : 0}}
                    exit={{opacity : 0, x : 24}}
                    transition={{duration : 0.2}}
                >
                    <FontSelectPanel 
                        onClose={this.ToggleFontSelector.bind(this, null)}
                        onSelect={this.SelectFont.bind(this)}
                    />
                </motion.div>
            )
        }
        else if (this.willShowSelectColor) {
            overlayContent = (
                <motion.div 
                    style={{...SC.Styles.Absolute, zIndex : 10000, display : 'flex', flexDirection : 'column'}}
                    initial={{opacity : 0.7, x : 24}}
                    animate={{opacity : 1, x : 0}}
                    exit={{opacity : 0, x : 24}}
                    transition={{duration : 0.2}}
                >
                    <SelectDocumentColor 
                        color={this.willShowSelectColor.color}
                        forText={this.willShowSelectColor.forText}
                        tokenId={this.willShowSelectColor.tokenId}
                        onClose={this.ToggleColorSelector.bind(this, null)}
                        onChange={this.onChangeColor.bind(this, this.willShowSelectColor.prop )}
                    />
                </motion.div>
            )
        }
        else if (this.willShowTextSelector) {
            const TokenSelectorVisible = {
                type : AppState.ItemTypes.BOARD.PATTERN_TEXT,                
                textPattern : this.willShowTextSelector.tokenId,
                title : 'SELECT TEXT STYLE',
                onSelect : (tokenId) => {
                    Utils.Set(this.props.documentStyles, tokenId, this.willShowTextSelector.textId, 'textTokenId');
                    Globals.ProjectManager.GetDocumentManager().SetStyles(this.props.documentStyles);                    
                    this.props.onUpdate();
                    this.RCUpdate();
                }
            }
            overlayContent = (
                <motion.div 
                    style={{...SC.Styles.Absolute, zIndex : 10000, display : 'flex', flexDirection : 'column'}}
                    initial={{opacity : 0.7, x : 24}}
                    animate={{opacity : 1, x : 0}}
                    exit={{opacity : 0, x : 24}}
                    transition={{duration : 0.2}}
                >
                    <SelectToken                                         
                        onClose={this.ToggleTextPatternSelector.bind(this, null)}
                        GlobalState={this.props.GlobalState}
                        GlobalStateId={this.props.GlobalStateId}
                        themeId={this.props.themeId}                   
                        {...TokenSelectorVisible}
                        onUpdate={() => {this.RenderId = Utils.Id();}}
                    />    
                </motion.div>                      
            )
        }
        else {
            const fonts = Utils.JustGet(this.props.documentStyles, [], 'fonts');
            const fontItems = [];
            let defaultFontId;
            Utils.ForEach(fonts, (font, i) => {
                let name = FontFamilyDisplayName(font);
                fontItems.push({
                    label : name,
                    id : font.id,
                    font : font
                })
                if (i === 0)
                    defaultFontId = font.id
            });
            
            let backgroundColor = Utils.JustGet(this.props.documentStyles.backgroundColor, null, colorTheme);
            const backgroundColorTokenId = this.props.documentStyles.backgroundColorTokenId;
            if (backgroundColorTokenId) {
                backgroundColor = Globals.ProjectManager.Tokens.ValueOfId(backgroundColorTokenId);
            }

            let textColor = Utils.JustGet(this.props.documentStyles.textColor, null, colorTheme);
            const textColorTokenId = this.props.documentStyles.textColorTokenId;
            if (textColorTokenId) {
                textColor = Globals.ProjectManager.Tokens.ValueOfId(textColorTokenId);
            }

            let dividerColor = Utils.JustGet(this.props.documentStyles.dividerColor, null, colorTheme);
            const dividerColorTokenId = this.props.documentStyles.dividerColorTokenId;
            if (dividerColorTokenId) {
                dividerColor = Globals.ProjectManager.Tokens.ValueOfId(dividerColorTokenId);
            }


            content = (
                <React.Fragment>
                    <TokenPanelHeader 
                        title='DOCUMENT STYLE' 
                        onClose={this.props.onClose} 
                        style={{backgroundColor : SC.CurrentTheme.theme.back_lighter}}
                    >                   
                        <SC.Icons.Icon_Button hasFill style={{paddingRight : '8px'}}>
                            <SC.Icons.Icon_SliderSettings size={24} />
                        </SC.Icons.Icon_Button>
                    </TokenPanelHeader>
                    <SC.CustomScrollbars>
                        <SC.FCol style={{padding : '8px'}}>                            
                            <SC.FRow alc jsb style={{marginRight : '-4px', paddingLeft : '4px'}}>
                                <SC.GroupLabel>
                                    FONTS
                                </SC.GroupLabel>
                                <PanelHeaderAddButton onAdd={this.ToggleFontSelector.bind(this, null)} />   
                            </SC.FRow>
                            {
                                fontItems.map((fontItem, i) => {                                    
                                    return (
                                        <SC.FRow key={i} style={{marginBottom : '8px', marginTop : i === 0 ? '8px' : 0}} alc>
                                            <TokenItemBox style={{overflow : 'hidden', marginBottom : 0, flex : 1, marginRight : '4px', fontSize : '14px', paddingTop : '6px', paddingBottom : '6px'}} onClick={this.ToggleFontSelector.bind(this, fontItem.font)}>
                                                <FontFamilyPreview font={fontItem.font} />
                                            </TokenItemBox>
                                            <SC.Icons.Icon_Button hasFill hasCursor onClick={this.DeleteFont.bind(this, fontItem.font)}>
                                                <SC.Icons.Icon_Delete size={16} />
                                            </SC.Icons.Icon_Button>
                                        </SC.FRow>                                        
                                    )
                                })
                            }
                            <Seperator inner />
                            <SC.GroupLabel style={{marginBottom : '8px'}}>
                                LEFT PANEL
                            </SC.GroupLabel>
                            <TextBlockStyle 
                                textType={{id : 'leftPanel', label : 'Left Panel'}}
                                hasBackgroundColor
                                {...this.props}
                                first
                                fontItems={fontItems}
                                defaultFontId={defaultFontId}
                                baseColor={textColor}
                                onToggleColorSelector={this.ToggleTextColorSelector.bind(this, 'leftPanel')}
                                onSelectTextPattern={this.ToggleTextPatternSelector.bind(this, 'leftPanel')}
                            />
                            <Seperator inner />
                            <SC.GroupLabel style={{marginBottom : '8px'}}>
                                PAGE STYLE
                            </SC.GroupLabel>
                            <ColorPicker 
                                label='Background Color'
                                key={colorTheme + '_back'}
                                color={backgroundColor}                                
                                tokenId={backgroundColorTokenId}
                                onChange={this.onChangeColor.bind(this, 'backgroundColor')}
                                onSelectToken={this.ToggleColorSelector.bind(this, 'backgroundColor', backgroundColor, backgroundColorTokenId, false)}
                            />
                            <ColorPicker 
                                label='Text Color'
                                key={colorTheme + '_text'}
                                color={textColor}
                                tokenId={textColorTokenId}
                                onChange={this.onChangeColor.bind(this, 'textColor')}
                                onSelectToken={this.ToggleColorSelector.bind(this, 'textColor', textColor, textColorTokenId, {
                                    backgroundColor : backgroundColor
                                })}
                            />
                            <ColorPicker 
                                label='Divider Color'
                                key={colorTheme + '_divider'}
                                color={dividerColor}
                                tokenId={dividerColorTokenId}
                                onChange={this.onChangeColor.bind(this, 'dividerColor')}
                                onSelectToken={this.ToggleColorSelector.bind(this, 'dividerColor', dividerColor, dividerColorTokenId, false)}
                            />
                            <Seperator inner />
                           <SC.FRow alc jsb style={{marginBottom : '8px'}}>
                                <SC.GroupLabel style={{flex : 1}}>
                                    TEXT BLOCKS
                                </SC.GroupLabel>
                                <DropDownSelect 
                                    style={{flex : 1}}
                                    items={[
                                        {id : 'body', label : 'Body'},
                                        {id : 'd1', label : 'Title'},
                                        {id : 'h1', label : 'H1'},
                                        {id : 'h2', label : 'H2'},
                                        {id : 'h3', label : 'H3'},
                                        {id : 'paragraph', label : 'Paragraph'},
                                        {id : 'code', label : 'Code'},
                                        {id : 'list', label : 'List'}
                                    ]}
                                    value={this.state.selectedTextItem.id}
                                    hasBorder            
                                    autoHeight
                                    xsmall
                                    toRight
                                    onChange={(textId, textItem) => this.setState({selectedTextItem : textItem})}
                                />
                            </SC.FRow>
                            <TextBlockStyle 
                                textType={this.state.selectedTextItem}
                                {...this.props}
                                first
                                backgroundColor={backgroundColor}
                                baseColor={textColor}
                                fontItems={fontItems}
                                defaultFontId={defaultFontId}
                                onToggleColorSelector={this.ToggleTextColorSelector.bind(this, this.state.selectedTextItem.id)}
                                onSelectTextPattern={this.ToggleTextPatternSelector.bind(this, this.state.selectedTextItem.id)}
                            />
                        </SC.FCol>
                    </SC.CustomScrollbars>           
                </React.Fragment>                
            )
        }

        
        return (
            <SC.FCol f1 style={{backgroundColor : SC.CurrentTheme.theme.back}}>
                {content}                      
                <AnimatePresence>
                    {overlayContent}
                </AnimatePresence>  
            </SC.FCol>
        )
    }
}

class TextBlockStyle extends ReactBasicComponent {
    constructor(props) {
        super(props);
        this.state = {  }

        this.SetFont = this.SetFont.bind(this);        
        this.SetTextType = this.SetTextType.bind(this);        

        const textTokenId = Utils.JustGet(this.props.documentStyles, null, this.props.textType.id, 'textTokenId');
        this.state.textType = textTokenId ? 'token' : 'custom';
    }

    onChangeTextStyle(type, prop, value) {
        let useValue = value;
        let useProp = prop;
        if (prop === 'letterSpacingScaled') {
            useProp = 'letterSpacing';
            useValue = value / 100;
        }   
            
        Utils.Set(this.props.documentStyles, useValue, type, useProp);
        Globals.ProjectManager.GetDocumentManager().SetStyles(this.props.documentStyles);
        this.props.onUpdate();        
    }
    onChangeTextColor(type, prop, colorType, color) {
        if (colorType === 'token') {
            Utils.Set(this.props.documentStyles, color, type, prop + 'TokenId');
        }
        else {
            Utils.Set(this.props.documentStyles, color, type, prop, SC.CurrentTheme.theme.isLight ? 'light' : 'dark');
        }
        
        Globals.ProjectManager.GetDocumentManager().SetStyles(this.props.documentStyles);
        this.props.onUpdate();
    }  
    SetFont(fontId) {
        Utils.Set(this.props.documentStyles, fontId, this.props.textType.id, 'fontId');
        Globals.ProjectManager.GetDocumentManager().SetStyles(this.props.documentStyles);
        this.props.onUpdate();        
    }
    SetTextType(type, tokenId) {
        if (type === 'custom') {
            Utils.Set(this.props.documentStyles, null, this.props.textType.id, 'textTokenId');
        }
        else {
            
        }
        this.setState({textType : type})
        
        Globals.ProjectManager.GetDocumentManager().SetStyles(this.props.documentStyles);
        this.props.onUpdate();        
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.StyleId !== nextProps.StyleId || this.props.RenderId !== nextProps.RenderId || this.props.textType.id !== nextProps.textType.id)
            return true;
        return super.ShouldUpdate(nextProps, nextState);
    }
    ToggleColorSelector(prop, color, tokenId, forText) {
        this.props.onToggleColorSelector(prop, color, tokenId, forText);
    }
    render() { 
        const {textType} = this.props;

        let content;
        let font_preview;

        const colorTheme = SC.CurrentTheme.theme.isLight ? 'light' : 'dark';

        let color = Utils.JustGet(this.props.documentStyles, this.props.baseColor, textType.id, 'color', colorTheme);
        const colorTokenId = Utils.JustGet(this.props.documentStyles, null, textType.id, 'colorTokenId');
        if (colorTokenId) {
            color = Globals.ProjectManager.Tokens.ValueOfId(colorTokenId);
        }

        let colorItems;
        if (this.props.hasBackgroundColor) {

            let backgroundColor = Utils.JustGet(this.props.documentStyles, this.props.baseColor, textType.id, 'backgroundColor', colorTheme);
            const backgroundColorTokenId = Utils.JustGet(this.props.documentStyles, null, textType.id, 'backgroundColorTokenId');
            if (backgroundColorTokenId) {
                backgroundColor = Globals.ProjectManager.Tokens.ValueOfId(backgroundColorTokenId);
            }

            colorItems = (
                <React.Fragment>
                    <ColorPicker 
                        label='Background Color'
                        key={colorTheme + '_back'}
                        color={backgroundColor}
                        tokenId={backgroundColorTokenId}
                        onChange={this.onChangeTextColor.bind(this, textType.id, 'backgroundColor')}
                        onSelectToken={this.ToggleColorSelector.bind(this, 'backgroundColor', backgroundColor, backgroundColorTokenId)}
                    />
                    <ColorPicker 
                        label='Text Color'
                        key={colorTheme + '_text'}
                        onChange={this.onChangeTextColor.bind(this, textType.id, 'color')}
                        color={color}
                        tokenId={colorTokenId}
                        onSelectToken={this.ToggleColorSelector.bind(this, 'color', color, colorTokenId, {
                            backgroundColor : backgroundColor
                        })}
                    />
                </React.Fragment>
            )
        }
        else {
            colorItems = (
                <ColorPicker 
                    label='Color'
                    key={colorTheme}
                    onChange={this.onChangeTextColor.bind(this, textType.id, 'color')}
                    color={color}
                    tokenId={colorTokenId}
                    onSelectToken={this.ToggleColorSelector.bind(this, 'color', color, colorTokenId)}
                />
            )
        }
        
        if (this.state.textType === 'token') {
            const textTokenId = Utils.JustGet(this.props.documentStyles, null, textType.id, 'textTokenId');
            let hasText = false;
            let style_pattern = {};
            let font;
            const token_text = {
                name : 'TYPOGRAPHY'
            };
            if (textTokenId) {            
                const tokenModel = Globals.ProjectManager.Tokens.TypePatterns.GetPattern(textTokenId);
                if (tokenModel) {
                    hasText = true;
                    style_pattern = Globals.ProjectManager.Tokens.TypePatterns.GetPatternStyle(tokenModel, null, null, this.props.StateArray) || {};
                    token_text.name = tokenModel.name;
                    const fontid = Globals.ProjectManager.Tokens.ValueOf({model : tokenModel, name : 'fontId', statearray : this.props.StateArray});    
                    font = Globals.ProjectManager.Tokens.FontValue(fontid || 'DefaultFont', this.props.StateArray);
                }            
            }
            content = (
                <StyleGroupTokenItem onClick={() => {this.props.onSelectTextPattern(textTokenId)}} 
                    style={{
                        marginBottom : '8px', padding : '8px',
                        ...SC.Styles.Flex.Row, justifyContent : 'space-between'
                    }}>
                        {hasText && <FontFamilyPreview font={font} style={{...style_pattern, fontSize : '14px', lineHeight : 'unset'}} text={token_text.name} /> }
                        {!hasText && token_text.name}
                        {hasText && <div style={{...SC.Styles.FontStyles.Monospace, fontSize : '14px'}}>{style_pattern.fontSize}</div>}                    
                </StyleGroupTokenItem>
            )
        }
        else {
            const fontId = Utils.JustGet(this.props.documentStyles, this.props.defaultFontId, textType.id, 'fontId');
            const fontSize = Utils.JustGet(this.props.documentStyles, 16, textType.id, 'fontSize');
            const lineHeight = Utils.ToInteger(Utils.JustGet(this.props.documentStyles, fontSize * 1.4, textType.id, 'lineHeight'));
            const letterSpacing = Utils.JustGet(this.props.documentStyles, 0, textType.id, 'letterSpacing');
            
            if (this.props.textType.id !== 'leftPanel') {
                const font = Utils.JustGet(Utils.Find(this.props.fontItems, (f) => {return f.id === fontId}), null, 'font');
                const style_font = {fontSize : Utils.px(fontSize), overflow : 'hidden', transition : 'all 0.3s ease', ...Globals.ProjectManager.Tokens.Get_FontFamilyOf(font)};

                font_preview = (
                    <TokenItemBox style={{overflow : 'hidden', paddingTop : '6px', paddingBottom : '6px', ...style_font, color : color, backgroundColor : this.props.backgroundColor, border : SC.CurrentTheme.theme.border_seperator}}>
                        {this.props.textType.label}
                    </TokenItemBox>
                )
            }

            content = (
                <React.Fragment>
                    {
                        this.props.fontItems.length > 1 &&
                        <SC.FRow alc jsb style={{marginBottom : '8px'}}>
                            <PropertyLabel style={{flex : 1}}>Font</PropertyLabel>
                            <DropDownSelect 
                                style={{flex : 2}}
                                items={this.props.fontItems}
                                value={fontId}
                                hasBorder            
                                autoHeight
                                xsmall
                                toRight
                                onChange={this.SetFont}
                            />
                        </SC.FRow>
                    }
                    
                    <BaseSizeSlider 
                        title={<PropertyLabel>Font Size</PropertyLabel>}
                        baseSize={fontSize} 
                        onChangeBaseSize={this.onChangeTextStyle.bind(this, textType.id, 'fontSize')} 
                        min={8}
                        max={72}
                    />
                    {/* <SC.FRow alc jsb style={{marginBottom : '8px'}}>
                        <PropertyLabel>Font Weight</PropertyLabel>
                        <FontWeightDropdown
                            style={{width : '100px', fontSize : '11px'}}
                            value={Utils.JustGet(this.props.documentStyles, 400, textType.id, 'fontWeight')}
                            onChange={this.onChangeTextStyle.bind(this, textType.id, 'fontWeight')}
                        />
                    </SC.FRow>                 */}
                    <BaseSizeSlider 
                        title={<PropertyLabel>Line Height</PropertyLabel>}
                        baseSize={lineHeight} 
                        onChangeBaseSize={this.onChangeTextStyle.bind(this, textType.id, 'lineHeight')} 
                        min={12}
                        max={120}
                    /> 
                    <SC.FCol style={{overflow : 'hidden', marginTop : '4px'}}>
                        <SC.FRow alc jsb style={{alignItems : 'baseline', marginBottom : '-2px'}}>
                            <SC.SmallText><PropertyLabel>Letter Space</PropertyLabel></SC.SmallText>
                            <SliderScaleInput 
                                largeInput
                                enableNegative
                                value={letterSpacing}
                                onChange={this.onChangeTextStyle.bind(this, textType.id, 'letterSpacing')}
                                onChanging={this.onChangeTextStyle.bind(this, textType.id, 'letterSpacing')}
                                postfix={(
                                    <span style={{fontSize : '11px', alignSelf : 'center', paddingRight : '4px', paddingLeft : '4px', ...SC.Styles.FontStyles.Monospace}}>EM</span>
                                )}
                            />
                        </SC.FRow>
                        <Slider 
                            value={((letterSpacing || 0.1) * 100)}
                            min={-10}
                            max={100}
                            onChange={this.onChangeTextStyle.bind(this, textType.id, 'letterSpacingScaled')}
                                onChanging={this.onChangeTextStyle.bind(this, textType.id, 'letterSpacingScaled')}
                            themeId={AppLayout.ThemeId}
                        />                  
                    </SC.FCol>
                </React.Fragment>
            )
        }

        
        

        
        return (
            <SC.FCol key={textType.id}>
                {!this.props.first && <Seperator />}           
                {font_preview}     
                {colorItems}
                <SC.FRow alc jsb style={{marginBottom : '8px'}}>
                    <PropertyLabel style={{flex : 1}}>Text Style</PropertyLabel>
                    <DropDownSelect 
                        style={{flex : 2}}
                        items={[
                            {id : 'custom', label : 'Custom Style'},
                            {id : 'token', label : 'Token'},
                        ]}
                        value={this.state.textType}
                        hasBorder            
                        autoHeight
                        xsmall
                        toRight
                        onChange={this.SetTextType}
                    />
                </SC.FRow>
                {content}
            </SC.FCol>
        )
    }
}
 