import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    AppLayout,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../../../../../importer';

import { GetIconTokenListOfIds, GetIconTokenList } from '../../../../../panels/left/designsystem/icons';
import { IconBox } from '../../../../../panels/left/designsystem/icons/item';
import { DocumentTableCell } from '../colors';


export default class DocumentToken_Image extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {

        };    
    }
    shouldComponentUpdate(nextProps, nextState) {

        if (this.state.ShouldUpdate !== nextState.ShouldUpdate)
            return true;
        
        if (Utils.HasAnyChange(this.props, nextProps, 'GlobalThemeId', 'GlobalState', 'StyleId', 'RenderId', 'LoadId', 'themeType', 'states', 'ReversedStyleState'))
            return true;
        
        return false;
    }
    componentDidUpdate(prevProps, prevState) {
        this.state.ShouldUpdate = false;
    }
    render() {                        
        const tokens = this.props.selection.type === 'selected' ? GetIconTokenListOfIds(this.props.selection.tokenIds, this.props.ReversedStyleState) : GetIconTokenList(this.props.ReversedStyleState);
        const tokenItems = [];

        const icon_style = { minWidth : '60px', width : '100%', minHeight : '60px' }
        if (this.props.viewMode !== 'card')
            icon_style.height = '100%';

        const scaleFactor = (this.props.cardSize || 100) / (this.props.viewMode === 'card' ? 100 : 200);
        const cardMinWidth = 100 * scaleFactor;
        const cardMaxWidth = 120 * scaleFactor;
        const dividerMargin = 32 * scaleFactor;


        Utils.ForEach(tokens, (token, ) => {
            
            let iconitem; 
            if (Utils.IsNotNullOrEmpty(token.url)) {
                iconitem = (
                    <IconBox                         
                        url={token.url}
                        style={{...icon_style}}
                        title={token.name}                        
                    />
                )                     
            }    
            else if (token.paths) {
                iconitem = (
                    <IconBox
                        style={{...icon_style}}
                        title={token.name}
                    >
                        <SC.Icons_Preview compact  xmlns='http://www.w3.org/2000/svg' viewBox={`0 0 24 24`} width='80%' height='80%' dark={SC.CurrentTheme.theme.isLight} >
                            {token.paths}
                        </SC.Icons_Preview>    
                    </IconBox>
                )
            }   

            if (this.props.viewMode === 'card') {
                tokenItems.push(
                    <div style={{display : 'grid', alignItems :'center', overflow : 'hidden'}} >
                        {iconitem}
                        <DocumentTableCell style={{fontFamily : 'monospace', marginTop : '8px', textAlign : 'center'}}>{token.name}</DocumentTableCell>
                    </div>
                )
            }
            else {
                tokenItems.push(
                    <React.Fragment>
                        {iconitem}
                        <DocumentTableCell style={{fontFamily : 'monospace'}}>{token.name}</DocumentTableCell>
                    </React.Fragment>
                )
            }
            
            if (this.props.forTemplateSelect && tokenItems.length === 3) {
                return false;
            }
        });
        
        if (this.props.viewMode === 'card') {
            return (
                <div style={{
                    display : 'grid',
                    gridGap : '16px',
                    gridTemplateColumns : `repeat(auto-fill, minmax(${cardMinWidth}px, 1fr))`,
                    gridAutoRows : Utils.px(cardMaxWidth),
                    alignSelf : this.props.alignment,
                    width : this.props.size ? Utils.px(this.props.size, '%') : 'auto'
                }}                    
                >
                    {tokenItems}
                </div>
            )   
        }
        else {
            return (
                <div                     
                    style={{
                        display : 'grid',
                        gridTemplateColumns : `${cardMinWidth}px 1fr`,     
                        gridAutoRows : Utils.px(cardMaxWidth),
                        alignSelf : this.props.alignment,                 
                        width : this.props.size ? Utils.px(this.props.size, '%') : 'auto',
                        justifyContent : this.props.alignment,
                        alignItems : 'center',
                        gridRowGap : '16px'
                    }}                    
                >
                    {tokenItems}
                </div>
            )   
        }



        return (
            <div style={{
                display : 'grid',
                gridGap : '48px',
                gridTemplateColumns : 'repeat(auto-fill, minmax(60px, 1fr))',
                gridAutoRows : 'auto',
                justifyContent : 'start',
                alignItems : 'center'
            }}
            >
                {tokenItems}
            </div>
        )  
    }
}