import React from 'react';
import styled from 'styled-components';
import {
    SC,
    Utils,
    UIUtils
} from '../../../../../../../importer';
import {css} from 'styled-components';
import Globals from '../../../../../../../appstate/Globals';
import ComponentDesignManager from '../../../../../component/manager';
import { AnimatePresence, motion} from 'framer-motion';
import VariableBinder from '../variable';
import ChromaJs from 'chroma-js';

const SIDEMARGIN = 10;
export const INSPECTOR_ITEM_MARGIN = '4px';

export const StyleGroup = (props) => {
    return (
        <SC.FCol style={{position : 'relative', paddingTop : props.empty ? 0 : '4px', paddingBottom : props.empty ? 0 : '4px', paddingLeft : Utils.px(SIDEMARGIN), paddingRight : Utils.px(SIDEMARGIN), ...props.style}}>
            {props.children}
        </SC.FCol>
    )
}

export const Seperator = ({marginRight, style, inner}) => (
    <div style={{
        borderTop : SC.CurrentTheme.theme.border_seperator,
        margin : '10px',
        marginLeft : inner ? Utils.px(-1*SIDEMARGIN) : 0,
        marginRight : inner ? Utils.px(-1*SIDEMARGIN) : (marginRight || 0),
        ...style
    }} />
);

export const StyleGroupTitle = SC.GroupLabel;

export const StyleGroupTokenItem = styled.div`
    background-color : ${props => props.theme.isLight ? props.theme.back_lighter : (props.empty ? 'transparent' : props.theme.back_lighter)};
    font-size : 11px;
    transition : all 0.3s ease;
    border-radius : 4px;
    cursor : pointer;
    position : relative;
    overflow : hidden;
    box-sizing : border-box;
    border : ${props => props.focused ? props.theme.border_brand : (props.theme.isLight ? '1px solid #f0f0f0' : props.theme.border_seperator)};
    &: hover{
        background-color : ${props => props.empty ? props.theme.back_lighter : props.theme.back_lightest};
        color : ${props => props.theme.font_hover};
        border : ${props => props.focused ? props.theme.border_brand : (props.theme.isLight ? props.theme.border_ondark : props.theme.border_seperator)};
    }
    ${
        props => {
            if (props.selected) {
                return css`
                    color : ${props => props.theme.font_hover};
                `;
            }
        }
    }
`;

export const StyleButtonGroup = styled.div`
    background-color : ${props => props.theme.isLight ? props.theme.back_lighter : props.theme.back_lighter};
    &: hover{
        background-color : ${props => props.theme.back_lightest};
    }
`;

export const ToggleButton = ({style, onClick, selected, onMouseDown, hasCursor, ...props}) => {
    return (
        <SmallButtonGroup 
            hasBorders noSvg style={{width : 'auto', minHeight : '28px', paddingLeft : '6px', paddingRight : '6px', cursor : selected && !hasCursor ? 'unset' : 'pointer', color : selected ? SC.CurrentTheme.theme.color_brand_text : 'unset', fontSize : '11px', ...SC.Styles.FontStyles.Monospace, ...style}} 
            onClick={onClick} 
            onMouseDown={onMouseDown}
            selected={selected} >
            {props.children}
        </SmallButtonGroup>
    )
}

export const SmallButtonGroup = ({first, width, noSvg, last, middle, grouped, selected, style, style_svg, hasBorders, ...props}) => {
    const style_button = Utils.Merge({ 
        width : width || '28px',
        height : '30px',
        boxSizing : 'border-box',
        // border : '1px solid',
        border : SC.CurrentTheme.theme.border_seperator,
        // borderLeft:  SC.CurrentTheme.theme.border_seperator,
        // borderRight : last ? SC.CurrentTheme.theme.border_seperator : 'none',
        // borderTop : SC.CurrentTheme.theme.border_seperator,
        // borderBottom : SC.CurrentTheme.theme.border_seperator,
        cursor : 'pointer',
        display : 'flex',
        alignItems : 'center',
        justifyContent : 'center'
    }, style);

    if (first) {
        style_button.borderTopLeftRadius = '2px';
        style_button.borderBottomLeftRadius = '2px';
        if (grouped) {
            style_button.marginRight = 0;
            style_button.borderRight = 'none';
        }
        
        if (!last) {
            style_button.borderTopRightRadius = 0;
            style_button.borderBottomRightRadius = 0;
        }        
    }
    if (last) {
        if (!first) {
            style_button.borderTopLeftRadius = 0;
            style_button.borderBottomLeftRadius = 0;
        }        
        style_button.borderTopRightRadius = '2px';
        style_button.borderBottomRightRadius = '2px';
        if (grouped) {
            style_button.marginLeft = 0;
            style_button.borderLeft = 'none';
        }
    }
    
    if (!first && !last) {        
        style_button.borderRadius = 0;
    }
    if (middle) {
        style_button.borderRadius = 0;
        style_button.margin = 0;
        style_button.borderLeft = 'none';
        style_button.borderRight = 'none';
    }
    if (!hasBorders)
        style_button.border = 'none';
    if (selected)
        style_button.backgroundColor = SC.CurrentTheme.theme.back;
    let content;
    if (noSvg)
        content = props.children;
    else
        content = (
            <SC.Icons.Icon_Button hasFill hasCursor>
                <SC.Svg height={props.size || 15} viewBox="0 0 24 24" style={style_svg}>
                    {props.children}
                </SC.Svg>
            </SC.Icons.Icon_Button>
        )
    return (
        <StyleButtonGroup {...props} style={style_button} >
            {content}
        </StyleButtonGroup>        
    )
}

export const StyleToken_Color = ({tokenId, onSelect, label, value, noLabel, onSelectModel, modelId, first, style, StateArray}) => {
    const token = {
        value : value || 'transparent',
        name : label
    }
    const previewStyle = {}
    if (tokenId) {        
        const tokenModel = Globals.ProjectManager.Tokens.Token(tokenId);
        if (tokenModel) {            
            token.name = tokenModel.name;
        }        
        token.value = Globals.ProjectManager.Tokens.GetStateValue({Id : tokenId, StateArray : StateArray});
        previewStyle.backgroundColor = token.value;

        if (tokenModel && tokenModel.type === 'Gradients' && token.value && token.value.gradient) {
            token.value = Utils.GetGradientCss(token.value.gradient.colors, token.value.type, Utils.Get(token.value.gradient, 0, 'path', 'angle'));
            previewStyle.background = token.value;
        }
            
    }   
    else {
        if (value && ChromaJs.valid(token.value)) {
            previewStyle.backgroundColor = token.value;
        }
    }
    
    const item = (
        <StyleGroupTokenItem onClick={onSelect} style={{marginTop : onSelectModel ? 0 : INSPECTOR_ITEM_MARGIN, flex : onSelectModel ? 1 : 'unset', ...style}}>
            <SC.FRow>
                {
                    !noLabel &&
                    <SC.FRow alc f1 style={{paddingLeft : '8px', paddingRight : '4px', overflow : 'hidden',}}>
                        <SC.TextDivAbbr style={{fontSize : '11px', fontWeight : 500}}>
                            {token.name}
                        </SC.TextDivAbbr>
                    </SC.FRow>
                }                
                <SC.FRow overflowhidden>
                    <SC.GridBackground 
                        title={token.name} 
                        small 
                        dark={!SC.CurrentTheme.theme.isLight} 
                        style={{hoverflow : 'hidden', cursor : 'pointer', width : onSelectModel ? '40px' : '60px', margin : '2px', borderRadius : '2px', overflow : 'hidden', height : '24px', boxSizing : 'border-box'}}
                    >
                        <div
                            style={{...previewStyle, width : '100%', height : '100%', fontWeight : 500, boxSizing : 'border-box'}} 
                        >
                            
                        </div>
                    </SC.GridBackground> 
                </SC.FRow>                
            </SC.FRow>
        </StyleGroupTokenItem> 
    )
    if (onSelectModel) {
        return (
            <SC.FRow style={{marginTop : INSPECTOR_ITEM_MARGIN}}>
                {item}
                <VariableBinder 
                    modelId={modelId}
                    onSelect={onSelectModel}
                />  
            </SC.FRow>
        )
    }
    return item;
}

export const StyleTokenAliase_Color = ({aliaseId, onSelect, label, noLabel, first, style, StateArray}) => {
    const token = {
        value : 'transparent',
        name : label
    }
    if (aliaseId) {        
        const aliaseModel = Globals.ProjectManager.Tokens.Aliases.TokenAliase(aliaseId);
        if (aliaseModel) {            
            token.name = aliaseModel.name;
            const tokenId = Globals.ProjectManager.Tokens.Aliases.GetStateTokenId({Id : aliaseId, StateArray : StateArray});
            if (tokenId)
                token.value = Globals.ProjectManager.Tokens.GetStateValue({Id : tokenId, StateArray : StateArray});
        }                
    }   
    
    return (
        <StyleGroupTokenItem onClick={onSelect} style={{marginTop : (first ? '8px' : '4px'), ...style, flex : 'unset'}}>
            <SC.FRow>
                {
                    !noLabel &&
                    <SC.FRow alc f1 style={{padding : '8px', overflow : 'hidden',}}>
                        <SC.TextDivAbbr style={{fontSize : '11px', fontWeight : 500}}>
                            {token.name}
                        </SC.TextDivAbbr>
                    </SC.FRow>
                }                
                <SC.FRow overflowhidden>
                    <SC.GridBackground 
                        title={token.name} 
                        small 
                        dark={!SC.CurrentTheme.theme.isLight} 
                        style={{hoverflow : 'hidden', cursor : 'pointer', width : '60px', margin : '2px', borderRadius : '2px', overflow : 'hidden', height : '28px', boxSizing : 'border-box'}}
                    >
                        <div
                            style={{backgroundColor : token.value, width : '100%', height : '100%', fontWeight : 500, boxSizing : 'border-box'}} 
                        >
                            
                        </div>
                    </SC.GridBackground> 
                </SC.FRow>                
            </SC.FRow>
        </StyleGroupTokenItem> 
    );
}

export const PropertyLabel = ({hasValue, children, menuOptions, style}) => {
    const style_box = {        
    };
    let content = children;
    const style_main = {...style};
    
    return (
        <StyledPropertyLabel style={style_main}>
            {content}
        </StyledPropertyLabel>
    )
}

const StyledPropertyLabel = styled.div`
    color : ${props => props.theme.isLight ? '#4b4b4b' : '#939393'};
    font-weight : ${props => props.theme.isLight ? 500 : 400};
    display : flex;
    align-items : center;
    min-height : 20px;
    font-size : 11px;
`;

export const PropertyHoverContainer = (props) => {
    return props.children;
}

export const CollectionPlus = ({onAdd, title, size, style, ...rest}) => (
    <SC.Icons.Icon_Button hasFill onClick={onAdd} title={title} {...rest} style={{...style, cursor : 'pointer'}}>
        <SC.Icons.Icon_Plus size={size || 18}  />
    </SC.Icons.Icon_Button>
)

export const GroupHeader = (props) => {
    return (
        <SC.FRow alc jsb cursorPointer onClick={props.onExpand} style={{...props.style}}>
            <PropertyLabel hasValue={props.hasValue} menuOptions={props.menuOptions}> 
                <StyleGroupTitle style={props.hasValue ? {fontSize : '11px'}:{}}>{props.title}</StyleGroupTitle>
            </PropertyLabel>
            <SC.Div_Flex_Cell style={{padding : '4px', paddingRight : 0}}>
                <SC.Component_SizeEditor.Icon_ExpandArrow size={10} rotate={props.expanded ? '0' : null} />
            </SC.Div_Flex_Cell>
        </SC.FRow>
    )
}

const StyleItemPopupClass = (props) => {
    const style = {
        position : 'absolute', 
        top : '100%',
        right : 0,
        left : 0,
        marginTop : '4px',
        backgroundColor : props.light ? SC.CurrentTheme.theme.dropdown_back : SC.CurrentTheme.theme.dropdown_back_dark,
        border : SC.CurrentTheme.theme.border_popup,
        borderRadius : '2px',
        boxShadow : SC.CurrentTheme.theme.popup_shadow,
        pointerEvents : 'all',
        zIndex : 10000,
        ...props.style
    };
    if (props.toUp) {
        style.top = 'unset';
        style.bottom = '100%';
        style.marginBottom = '4px';
    }
    return (
        <motion.div
            style={style}
            initial={{y : -20}}
            animate={{y : 0}}
            exit={{y : -20, opacity : 0.7}}
            transition={{duration : 0.2}}                    
        >
            <PopupArrow style={props.style_arrow} toTop={props.toUp} />
            {props.children}
        </motion.div>
    )
}

export const PopupArrow = ({toLeft, toTop, style, right, left, color, arrow}) => {    
    const boxStyle = toLeft ? {
        right : right || '100%',
        top : '50%',
        transform : 'translateY(-50%) rotate(-90deg)',
        marginRight : '-3px'
    } : (
        toTop ? {
            top : '100%',
            left : '50%',
            transform : 'translateX(-50%)',
        } : {
            bottom : '100%',
            left : left || '50%',
            right : right,
            transform : 'translateX(-50%)',
        }
    );
    return (
        <div style={{
            width: 0,
            height: 0,
            borderStyle : 'solid',
            borderWidth : '0 6px ' + (arrow || '6px') + ' 6px',
            borderColor : `transparent transparent ${color || '#39769a'} transparent`,
            position : 'absolute',
            ...boxStyle,
            ...style
        }} />
    )
}

export const StyleItemPopup = UIUtils.OutSideClickHandler(StyleItemPopupClass);

export const OptionalStyleGroupHeader = ({title, onClick, onAdd, showAdd}) => {
    return (
        <SC.FRow alc jsb onClick={onClick} style={{cursor : onClick ? 'pointer' : 'default'}}>
            <StyleGroupTitle>{title}</StyleGroupTitle>
            <SC.Icons.Icon_Button hasFill style={{cursor : 'pointer', paddingRight : '2px', marginRight : '-4px', opacity : (onAdd || showAdd) ? 1 : 0}} title={title} onClick={onAdd}>
                <SC.Icons.Icon_Plus size={20} />
            </SC.Icons.Icon_Button>         
        </SC.FRow>
    )
}