import React, {useState} from 'react';
import {
    SC,
    Utils,
    Links,
    AppLayout,
    ReactBasicComponent,
    Globals
} from '../../../../importer';
import styled from 'styled-components';
import DropDownSelect from '../../../../components/editors/enum_dropdown';
import { OutsideCloseDotMenuContainer, DotMenuContainerBox } from '../../../project/panels/left/common';
import { Seperator } from '../../../project/panels/right/iteminspector/styleitems/common';
import TextInput from '../../../../components/editors/textinput';
import SystemStatePanel from '../../../project/panels/left/systemstates';
import { TokenPanelHeader, TokenItemBox, PanelHeaderAddButton } from '../../../project/panels/left/designsystem/common';
import { InfoPanel } from '../../../../components/info';
import { AnimatePresence } from 'framer-motion';
import Switch from '../../../../components/editors/Switch';


export default class ShareButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
         }

        this.ShowMenu = this.ShowMenu.bind(this);        
        
        
    }
    ShowMenu() {
        this.SharedOptions = Globals.ProjectManager.DocumentManager.GetShareOptions();
        
        this.setState({open : true})
    }
    OpenLink(e) {
        e.stopPropagation();
        this.setState({open : false});
        window.open( "https://dsm.toolabs.com/#/" + Globals.ProjectManager.Id, "_blank"); 
    }
    OpenCustomLink(link) {
        this.setState({open : false});
        window.open( "https://dsm.toolabs.com/#/custom/" + link.id, "_blank"); 
    }
    CopyLink(link, e) {
        e.stopPropagation();
        this.setState({open : false});
        if (navigator && navigator.clipboard)
            navigator.clipboard.writeText(link);
    }    
    
    SetSpecificState(stateId, variationId) {      
        const states = Utils.Get(this.EditingLink.data, {}, 'states');          
        states[stateId] = variationId;        
        this.SaveLink();
        this.setState({ShouldUpdate : true});
    }
    SetLinkTheme(theme) {
        Utils.Set(this.EditingLink.data, theme, 'theme');
        this.SaveLink();
        this.setState({ShouldUpdate : true});
    }
    SetLinkAuthentication(mode) {
        Utils.Set(this.EditingLink.data, !mode, 'authenticate');
        this.SaveLink();
        this.setState({ShouldUpdate : true});
    }
    AddLink() {
        Globals.ProjectManager.DocumentManager.AddSharedLink(this.EditingLink);
        delete this.EditingLink;
        this.setState({editlink : false});
    }
    EditLink(data) {
        this.EditingLink = data;
        this.setState({editlink : true});
    }
    DeleteLink() {
        Globals.ProjectManager.DocumentManager.DeleteSharedLink(this.EditingLink.id);
        delete this.EditingLink;
        this.setState({editlink : false});
    }
    SaveLink() {
        if (this.EditingLink && this.EditingLink.id !== 'new') {
            Globals.ProjectManager.DocumentManager.AddSharedLink(this.EditingLink);
        }
    }
    render() { 
        this.state.ShouldUpdate = false;

        let linkcontent;
        if (this.state.open) {
            if (this.state.editlink) {
                let options;
                if (this.EditingLink.data.type === 'fixedTheme') {
                    const states = Utils.Get(this.EditingLink.data, {}, 'states');
                    const theme = Utils.Get(this.EditingLink.data, 'all', 'theme');
                    options = (
                        <SC.FCol style={{gridColumnStart : 'span 2', paddingTop : '8px'}}>                            
                            <ShareOption_Authenticate
                                authenticate={Utils.JustGet(this.EditingLink.data, false, 'authenticate')}
                                onChange={this.SetLinkAuthentication.bind(this)}
                            /> 
                            <ShareOption_LightDarkMode 
                                mode={theme}
                                onChange={this.SetLinkTheme.bind(this)}
                            />                                    
                            <InfoPanel>
                                <div>This Link will run with following fixed theme configuration : </div>
                            </InfoPanel>  
                            <SystemStatePanel 
                                contentStyle={{margin : 0, marginTop : '8px'}}
                                justContent
                                notExpandable
                                onSetStateVariation={this.SetSpecificState.bind(this)}
                                currentVariations={states}
                            />
                        </SC.FCol>
                    )
                }
                linkcontent = (
                    <SC.FCol>
                        <TokenPanelHeader 
                            title={this.EditingLink.id === 'new' ? 'NEW CUSTOM LINK' : 'EDIT LINK'}                            
                            onClose={() => this.setState({editlink : false})}
                            onCancel={() => this.setState({editlink : false})}
                            onAdd={this.AddLink.bind(this)}                        
                            onDelete={this.EditingLink.id === 'new' ? null : this.DeleteLink.bind(this)}
                            hasAddCancel
                            cancelAndDelete
                            style={{paddingLeft : 0, paddingBottom : '8px'}}
                        />
                        <div style={{
                            display : 'grid',
                            gridGap : '16px',
                            gridRowGap : '8px',
                            marginTop : '8px',
                            gridTemplateColumns : 'auto 1fr',
                            alignItems : 'center',
                            justifyItems : 'stretch',
                            textAlign : 'left'
                        }}>
                            <div>Name</div>
                            <TextInput 
                                autoFocus
                                value={this.EditingLink.data.name}
                                onChange={(name) => {this.EditingLink.data.name = name; this.SaveLink(); this.setState({ShouldUpdate : true})}}
                            />
                            {/* <div>Link Type</div>
                            <DropDownSelect 
                                items={[
                                    {id : 'default', label : 'Default'},
                                    {id : 'fixedTheme', label : 'Specific Theme'},
                                    {id : 'customTheme', label : 'Selection of Themes'}
                                ]}
                                value={this.EditingLink.data.type || 'default'}
                                onChange={(type) => {this.EditingLink.data.type = type; this.setState({ShouldUpdate : true})}}
                                hasBorder
                                autoHeight
                            /> */}
                            {options}
                        </div>
    
                    </SC.FCol>
                )
            }
            else {
                const links = Globals.ProjectManager.DocumentManager.GetSharedLinks();
                if (links.length > 0) {
                    linkcontent = (
                        <SC.FCol>
                            <TokenPanelHeader 
                                title='CUSTOM LINKS'
                                notBackClosable                                
                                style={{paddingLeft : 0, paddingBottom : '8px', borderBottom : 'none'}}
                            >
                                <PanelHeaderAddButton onAdd={this.EditLink.bind(this, {id : 'new', data : {name : '', type : 'fixedTheme'}})} />
                            </TokenPanelHeader>
                            <div
                                style={{
                                    display : 'grid',
                                    gridGap : '16px',
                                    gridRowGap : '8px',
                                    marginTop : '8px',
                                    gridTemplateColumns : '1fr auto',
                                    alignItems : 'center',
                                    justifyItems : 'stretch',
                                    gridTemplateRows : '32px'
                                }}
                            >
                                {
                                    links.map((link) => {
                                        return (
                                            <React.Fragment>
                                                <TokenItemBox onClick={this.EditLink.bind(this, link)}>
                                                    <SC.TextDivAbbr>{link.data.name}</SC.TextDivAbbr>
                                                </TokenItemBox>
                                                <SC.FRow alc>
                                                    <SC.LinkText style={{marginRight : '16px'}} onClick={this.OpenCustomLink.bind(this, link)}>
                                                        Open
                                                    </SC.LinkText>
                                                    <SC.LinkText onClick={this.CopyLink.bind(this, 'https://dsm.toolabs.com/#/custom/' + link.id)}>
                                                        Copy Link
                                                    </SC.LinkText>
                                                </SC.FRow>
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </div>
                        </SC.FCol>                        
                    )
                }  
                else {
                    linkcontent = (
                        <SC.FCol>
                            <SC.LinkText onClick={this.EditLink.bind(this, {id : 'new', data : {name : '', type : 'fixedTheme'}})} >Create Custom Link</SC.LinkText>                            
                        </SC.FCol>
                    )
                }                           
            }
        }

        let theme, askAuthenticate;
        if (this.state.open) {
            theme = Utils.JustGet(this.SharedOptions, 'all', 'theme');
            askAuthenticate = Utils.JustGet(this.SharedOptions, false, 'authenticate');
        }
        
        return (              
            <React.Fragment>
                {
                    this.state.open && 
                    <div style={{
                        position : 'fixed',                        
                        top : 0, left  :0, right : 0, bottom:0,
                        zIndex : 9999999
                    }} 
                     onClick={() => this.setState({open : false})}
                    >
                        
                    </div>
                }
                
                <LinkButton theme={this.props.theme} onClick={this.ShowMenu}>
                    Share
                    <AnimatePresence>
                        {
                            this.state.open &&
                            <DotMenuContainerBox
                                onClose={() => this.setState({open : false})}
                                style={{
                                    padding : '16px', marginTop : '8px',
                                    width : '400px',
                                    zIndex : 999999991, cursor : 'default',
                                    fontSize : '12px'
                                }}
                            >
                                <ShareOption_Authenticate
                                    authenticate={askAuthenticate}
                                    onChange={(value) => {
                                        Utils.Set(this.SharedOptions, !value, 'authenticate');
                                        Globals.ProjectManager.DocumentManager.SetShareOptions(this.SharedOptions);
                                        this.setState({ShouldUpdate : true});
                                    }}
                                />     
                                <ShareOption_LightDarkMode 
                                    mode={theme}
                                    style={{marginBottom : '8px', paddingLeft : '4px'}}
                                    onChange={(value) => {
                                        Utils.Set(this.SharedOptions, value, 'theme');
                                        Globals.ProjectManager.DocumentManager.SetShareOptions(this.SharedOptions);
                                        this.setState({ShouldUpdate : true});
                                    }}
                                />                                                           
                                <SC.FRow alc jsb>
                                    <SC.LinkText onClick={this.OpenLink.bind(this)}>Open in new window</SC.LinkText>
                                    <SC.Buttons.RoundButton small auto style={{fontSize : '12px'}} onClick={this.CopyLink.bind(this, `https://dsm.toolabs.com/#/${Globals.ProjectManager.Id}`)}>
                                        <SC.Icons.Icon_Clone size={20}/>
                                        <div style={{marginLeft : '8px'}}>Copy Link</div>
                                    </SC.Buttons.RoundButton>
                                </SC.FRow>
                                 
                                <Seperator />
                                {linkcontent}
                                
                            </DotMenuContainerBox>
                        }
                    </AnimatePresence>
                </LinkButton>
            </React.Fragment>
            
        );
        return (            
            <LinkButton theme={this.props.theme} onClick={this.ShowMenu}>
                Share
                {
                    this.state.open &&
                    <OutsideCloseDotMenuContainer
                        onClose={() => this.setState({open : false})}
                        style={{
                            padding : '16px', marginTop : '8px',
                            width : '400px',
                            zIndex : 999999, cursor : 'default',
                            fontSize : '12px'
                        }}
                    >
                        
                        <SC.FRow alc jsb>
                            <SC.LinkText onClick={this.OpenLink}>Open in new window</SC.LinkText>
                            <SC.Buttons.RoundButton small auto style={{fontSize : '12px'}} onClick={this.CopyLink.bind(this, `https://dsm.toolabs.com/#/${Globals.ProjectManager.Id}`)}>
                                <SC.Icons.Icon_Clone size={20}/>
                                <div style={{marginLeft : '8px'}}>Copy Link</div>
                            </SC.Buttons.RoundButton>
                        </SC.FRow>
                        <Seperator />
                        {linkcontent}
                        
                    </OutsideCloseDotMenuContainer>
                }
            </LinkButton>
        );
    }
}
 


const LinkButton = styled.button`
    padding-left : 16px;
    padding-right : 16px;
    border-radius : 99px;
    background-color : ${props => props.theme.back_lighter};
    color : ${props => props.theme.font};
    border : 1px solid transparent;
    height : 28px;    
    cursor : pointer;
    position : relative;
    &:hover {
        background-color : ${props => props.theme.back_lightest};
        border : ${props => props.theme.border_brand};
    }
`;

const ShareOption_Authenticate = ({authenticate, onChange}) => {
    return (
        <SC.FRow alc jsb style={{marginBottom : '14px', paddingLeft : '4px'}}>
            <div style={{flex : 1, textAlign : 'left'}}>Public (No Authentication Required)</div>
            <Switch round value={!authenticate} onChange={onChange} />
        </SC.FRow>
    )
}

const ShareOption_LightDarkMode = ({mode, onChange, style}) => {
    return (
        <SC.FRow alc jsb style={{marginBottom : '8px', paddingLeft : '4px', ...style}}>
            <div style={{flex : 1, textAlign : 'left'}}>Light / Dark Mode</div>
                <DropDownSelect 
                    items={[{id : 'all', label : 'Show Theme Switch'}, {id : 'light', label : 'Light Theme'}, {id : 'dark', label: 'Dark Theme'}]}
                    onChange={onChange}
                    style={{fontSize : '11px', flex: 1, backgroundColor : SC.CurrentTheme.theme.back}}
                    value={mode}
                    hasBorder                                     
                    noBackColor   
                    autoHeight
                    xsmall
                    toRight
                />
        </SC.FRow>
    )
}