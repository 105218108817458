import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    AppLayout,
    Globals
} from '../../importer';

import ToolabsLogo from '../logo/brand_logo';
import AccountButton from './account';
import TeamInfoButton from './teambutton';
import ZoomSlider from './zoomslider';
import HistoryBar from './history';
import Events from '../../appstate/Events';
import TopNavigator from './navigator';
import TopNavigatorDebugMode from './navigator/debugmode';
import { GetDataApi, GetUserId } from '../../appstate/AppState';
import { motion } from 'framer-motion';

export default class Header extends ReactBaseComponent {
    constructor(props) {
        super(props);
        
        this.ToggleLightDarkMode = this.ToggleLightDarkMode.bind(this);
        AppLayout.Refs.Header = this;
        super.ListenEvents(
            Events.GLOBAL.MEDIASIZE.CHANGED
        );
    }
    
    componentWillUnmount() {
        super.componentWillUnmount();
        AppLayout.Refs.Header = null;
    }
    OnEvent(Event, Params) {        
        this.RCUpdate();
    }
    SetProjectName(name) {
        this.setState({ProjectName : name});
    }
    ToggleLightDarkMode() {
        if (SC.CurrentTheme.theme.isLight) 
            AppLayout.Theme.Current = AppLayout.Theme.Dark;
        else
            AppLayout.Theme.Current = 'Light';
        
            // AppLayout.Theme.Current = 'Tester';
        // SC.themes.Tester.isLight = !SC.themes.Tester.isLight;

        
        AppState.DesignData.User.Options.Set(!SC.CurrentTheme.theme.isLight, 'Theme', 'Light');
        let LocalOptions;
        try {
            LocalOptions = JSON.parse(localStorage.ToolabsUser);            
        } catch (error) {
            if (error) {

            }
        }
        if (!LocalOptions || !Utils.IsObject(LocalOptions))
            LocalOptions = {};
        LocalOptions.LightTheme = !SC.CurrentTheme.theme.isLight;
        localStorage.ToolabsUser = JSON.stringify(LocalOptions);    
        
        Events.BCE(Events.GLOBAL.THEME.CHANGED);
    }    

    renderCustom() {
        const style = {
            paddingLeft : '12px',
            height : '40px',
            maxHeight : '40px',
            minHeight : '40px',
            transition : 'margin-top 0.2s ease',
            marginTop : 0,
            // backgroundColor : '#2c2c2c',
            // color : '#e5e5e5'
        };

        // let useTheme = SC.themes.Dark6; 
        let useTheme = SC.CurrentTheme.theme;

        if (this.props.hidden) {
            style.marginTop = '-40px';
        }


        let userName;
        if (this.props.showUserName) {
            userName = (
                <SC.TextDivAbbr style={{fontSize : '14px', marginRight : '8px', marginLeft : '8px'}}>
                    {AppState.User.displayName}
                </SC.TextDivAbbr>
            )
        }

        let navigator, teamButton;
        if (this.props.BoardId) {
            navigator = (
                // <TopNavigatorDebugMode {...this.props} />
                <TopNavigator {...this.props} small={AppLayout.Media.Small} theme={useTheme}/>                
            )
         
            teamButton = (
                <TeamInfoButton 
                    onClick={() => {Events.BCE(Events.GLOBAL.TEAM.SHOWPANEL, Events.PARAMS.SHOW);}}
                />
            )
        }        

        return (
            <motion.div
                style={{
                    ...SC.Styles.Flex.RowAlcJsb,
                    height : '40px',
                    maxHeight : '40px',
                    minHeight : '40px',
                    transition : 'margin-top 0.2s ease',
                    marginTop : 0                    
                }}
                animate={{
                    padding : this.props.isCompact ? 16 : 0,
                    paddingLeft : this.props.isCompact ? 40 : 12,
                    paddingRight : this.props.isCompact ? 32 : 0,
                }}
                transition={{
                    duration : 0.2,
                    ease : 'easeInOut'
                }}
            >
                <SC.FRow alc style={{width : '296px', maxWidth : '296px'}}>                    
                    <Links.Link To={Links.Main()} title='Home'>
                        <SC.FRow style={{marginRight : '16px'}}>
                            <ToolabsLogo isLight={useTheme.isLight} size={this.props.isCompact ? 120 : 90} />
                        </SC.FRow>
                    </Links.Link>
                </SC.FRow> 
                <div style={{
                    display : 'grid',
                    gridTemplateColumns : AppLayout.Media.Small ? '1fr auto 1fr' : '1fr auto 1fr',
                    alignItems : 'center',
                    flex : 1
                }}>                
                    <div style={{transform : 'translateX(-190px)', overflow : 'hidden'}}>
                        <Links.Link To={Links.Main()} title='Home' >
                            {
                                this.props.BoardId && !AppLayout.Media.Small &&
                                <SC.LinkText textAbbr theme={useTheme} style={{                            
                                    padding : '2px',
                                    paddingLeft : '12px',
                                    borderLeft : useTheme.border_onlight_line
        
                                }}>
                                    {this.state.ProjectName}
                                </SC.LinkText>
                            }                        
                        </Links.Link>
                    </div>
                    {navigator}    
                    <SC.FRow justifyEnd alc style={{borderRight : useTheme.border_onlight_line, paddingRight : '8px'}}>
                        {
                            this.props.hasZoom && <ZoomSlider theme={useTheme} key='zoom' isScaleMode={this.props.isScaleMode} themeId={this.props.themeId}/>
                        }
                    </SC.FRow> 
                </div>
                <SC.FRow style={{width : !this.props.showUserName ? Utils.px(AppLayout.Panels.rightPanel.width + 8) : 'unset', paddingLeft : '8px', boxSizing : 'border-box'}} alc jsb>                    
                    <SC.FRow alc jsb f1>
                        <HistoryBar {...this.props.hasHistory} theme={useTheme} Id={this.props.id} hidden={!this.props.hasHistory} />
                        {userName}
                        <SC.FRow justifyEnd alc>
                            <LightDarkToggle dark={!useTheme.isLight} onToggle={this.ToggleLightDarkMode} />
                            {teamButton}
                            <AccountButton BoardId={this.props.BoardId} />
                        </SC.FRow>                        
                    </SC.FRow>                    
                </SC.FRow>
            </motion.div>
        )        
    }
}

export const LightDarkToggle = ({dark, onToggle, style}) => {
    return (
        <svg viewBox="0 0 24 24" width={24} style={{cursor : 'pointer', marginRight : '8px', ...style}} onClick={onToggle}>
            <path fill={dark ? "#a6a6a6" : '#898989'} d="M12,18C11.11,18 10.26,17.8 9.5,17.45C11.56,16.5 13,14.42 13,12C13,9.58 11.56,7.5 9.5,6.55C10.26,6.2 11.11,6 12,6A6,6 0 0,1 18,12A6,6 0 0,1 12,18M20,8.69V4H15.31L12,0.69L8.69,4H4V8.69L0.69,12L4,15.31V20H8.69L12,23.31L15.31,20H20V15.31L23.31,12L20,8.69Z" />
        </svg>
    )
}
