import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    UIUtils,
    Globals
} from '../../../../../../../importer';
import { StyleGroupTokenItem, SmallButtonGroup, StyleToken_Color, Seperator, StyleGroupTitle, StyleGroup } from '../common';
import PropertyItem from './propItem';
import { LOG_TYPES } from '../../../../../../../appstate/historymanager/base';
import {ComponentStateConfigurator} from './stateConfigurator';
import GenericProperties from './generic';

export default class SubComponentPropertyEditor extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.GetStateModel = this.GetStateModel.bind(this);
        this.SetStateVariation = this.SetStateVariation.bind(this);

        this.LoadComponent(this.props);
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.Id !== this.props.Id || nextProps.ManagerId !== this.props.ManagerId) {
            this.LoadComponent(nextProps);
            return true;
        }
        if (this.props.component.Id !== nextProps.component.Id) {
            this.LoadComponent(nextProps);
            return true;
        }
        
        if (Utils.HasAnyChange(this.props, nextProps, 'component')) {
            this.LoadComponent(nextProps);
            return true;
        }
            

        if (this.props.GlobalStateId !== nextProps.GlobalStateId)
            return true;        
        return super.ShouldUpdate(nextProps, nextState);
    }    
    LoadComponent(props) {
        this.states = [];
        this.stateModels = {};
        this.models = [];

        const result = GetSubcomponentStateAndModels(props.component.Id, Utils.JustGet(props.component, {}, 'ModelValues'));
        if (result.succuess) {
            this.ComponentId = props.component.Id;
            this.states = result.states;
            this.stateModels = result.stateModels;
            this.models = result.models;
        }
    }
    GetStateModel(id) {
        return this.stateModels[id];
    }
    SetStateVariation(stateId, varId) {    
        this.props.GetComponentManager().Log({
            Target : LOG_TYPES.SET_STYLE,
            Id : this.props.Id,
            Desc : `Component State`
        });
        const Variations = Utils.Get(this.props.component, {}, 'Variations');             
        if (Utils.IsNotNullOrEmpty(varId) && varId !== 'Default') {
            Utils.Set(Variations, varId, stateId);            
        }
        else {
            delete Variations[stateId];        
        }
        this.props.GetComponentManager().SetSubComponentVariation({
            MetaItem : this.props.GetMetaItem(this.props.Id),
            Id : this.props.Id,
            ComponentId : this.props.component.Id,
            StateId : stateId,
            VariationId : varId
        });
        this.RCUpdate();
        this.props.onUpdate();       
    }
    SetModelValue(model, value, dataType, valueType) {
        this.props.GetComponentManager().Log({
            Target : LOG_TYPES.SET_STYLE,
            Id : this.props.Id,
            Desc : `Component State`
        });
        
        this.props.GetComponentManager().SetSubComponentModels({
            MetaItem : this.props.GetMetaItem(this.props.Id),
            Id : this.props.Id,
            ComponentId : this.props.component.Id,
            ModelId : model.id,
            DataType : dataType,
            ModelValue : {
                value : value
            },
            ValueType : valueType
        });
        Utils.Set(model, value, 'Value', 'Value');
        this.props.onUpdate();       
        this.RCUpdate();
    }
    DeletemodelValue(model) {
        this.props.GetComponentManager().Log({
            Target : LOG_TYPES.SET_STYLE,
            Id : this.props.Id,
            Desc : `Component State`
        });
        
        this.props.GetComponentManager().SetSubComponentModels({
            MetaItem : this.props.GetMetaItem(this.props.Id),
            Id : this.props.Id,
            ComponentId : this.props.component.Id,
            ModelId : model.id,
            WillDelete : true
        });
          
        this.props.onUpdate();       
    }
    BindToModel(modelProp, modelId) {
        this.props.GetComponentManager().Log({
            Target : LOG_TYPES.SET_STYLE,
            Id : this.props.Id,
            Desc : `Component Prop`
        });
        
        Utils.Set(modelProp, modelId, 'Value', 'ModelId');
        this.props.GetComponentManager().SetSubComponentModels({
            MetaItem : this.props.GetMetaItem(this.props.Id),
            Id : this.props.Id,
            ComponentId : this.props.component.Id,
            ModelId : modelProp.id,
            BindToModelId : modelId,
            DataType : modelProp.model.dataType
        });
          
        this.props.onUpdate();  
        this.RCUpdate();  
    }
    BindToToken(modelProp, tokenId) {
        this.props.GetComponentManager().Log({
            Target : LOG_TYPES.SET_STYLE,
            Id : this.props.Id,
            Desc : `Component Prop`
        });
        
        this.props.GetComponentManager().SetSubComponentModels({
            MetaItem : this.props.GetMetaItem(this.props.Id),
            Id : this.props.Id,
            ComponentId : this.props.component.Id,
            ModelId : modelProp.id,
            BindToTokenId : tokenId,
            DataType : modelProp.model.dataType
        });
        Utils.Set(modelProp, tokenId, 'Value', 'TokenId');
        this.props.onUpdate();    
        // this.RCUpdate();
    }
    SetModelProps(modelProp, props) {
        this.props.GetComponentManager().Log({
            Target : LOG_TYPES.SET_STYLE,
            Id : this.props.Id,
            Desc : `Component Prop`
        });
        
        Utils.ForEach(props, (prop, i) => {
            this.props.GetComponentManager().SetSubComponentModelProps({
                MetaItem : this.props.GetMetaItem(this.props.Id),
                Id : this.props.Id,
                ComponentId : this.props.component.Id,
                ModelId : modelProp.id,
                PropName : prop.PropertyName,
                Value : prop.Value,
                Delete : prop.Delete
            });
            if (prop.Delete)
                Utils.UnSet(modelProp, 'Value', 'Options', prop.PropertyName, 'value');
            else
                Utils.Set(modelProp, prop.Value, 'Value', 'Options', prop.PropertyName, 'value');
        });
        
        this.props.onUpdate();    
    }
    SetModelPropModel(modelProp, propName, modelId) {
        this.props.GetComponentManager().Log({
            Target : LOG_TYPES.SET_STYLE,
            Id : this.props.Id,
            Desc : `Component Prop`
        });
        this.props.GetComponentManager().SetSubComponentModelProps({
            MetaItem : this.props.GetMetaItem(this.props.Id),
            Id : this.props.Id,
            ComponentId : this.props.component.Id,
            ModelId : modelProp.id,
            PropName : propName,
            BindtoModelId : modelId,
            Delete : modelId ? false : true
        });
        if (!modelId)
            Utils.UnSet(modelProp, 'Value', 'Options', propName, 'ModelId');
        else
            Utils.Set(modelProp, modelId, 'Value', 'Options', propName, 'ModelId');
        this.props.onUpdate();
    }
    renderCustom() {
        let name = 'Select Component';

        let content_state, content_models;
        if (this.props.component.Id) {
            name = Globals.ProjectManager.GetComponentName(this.props.component.Id);
            let stateItems;
            let noState;            
            if (this.states && this.states.length > 0) {
                stateItems = (
                    <ComponentStateConfigurator 
                        states={this.states}
                        variations={Utils.JustGet(this.props.component, {}, 'Variations')}
                        GetStateModel={this.GetStateModel}
                        onChangeState={this.SetStateVariation}
                    />
                )
            }
            else {
                noState = (
                    <div style={{fontSize : '11px'}}>Has no state</div>
                )
            }

            content_state = (
                <SC.FCol style={{marginTop : '4px'}}>
                    <Seperator />                    
                    <StyleGroup>
                        <SC.FRow alc jsb>
                            <StyleGroupTitle>STATES</StyleGroupTitle>
                            {noState}                        
                        </SC.FRow>   
                        {stateItems}
                    </StyleGroup>                    
                </SC.FCol>
            )

            let modelItems;
            let noProp;
            if (this.models && this.models.length > 0) {
                modelItems = (
                    <SC.FCol style={{fontSize : '11px'}}>
                        {this.props.Header}
                        {
                            this.models.map((model, i) => {
                                return (    
                                    <PropertyItem 
                                        key={model.id} 
                                        {...this.props} 
                                        modelId={model.id} 
                                        model={model.model} 
                                        value={model.Value} 
                                        onChangeModelValue={this.SetModelValue.bind(this, model)}
                                        onDeleteModelValue={this.DeletemodelValue.bind(this, model)}
                                        onBindToModel={this.BindToModel.bind(this, model)}
                                        onBindToToken={this.BindToToken.bind(this, model)}
                                        onSetModelProps={this.SetModelProps.bind(this, model)}
                                        onSetModelPropModel={this.SetModelPropModel.bind(this, model)}
                                        GetComponentManager={this.props.GetComponentManager}
                                        first={i === 0}
                                        last={i === this.models.length-1}
                                    />                                                   
                                )
                            })
                        }
                    </SC.FCol>
                )
            }
            else {
                noProp = (
                    <div style={{fontSize : '11px'}}>Has no public properties</div>
                )
            }
            content_models = (
                <SC.FCol>
                    <Seperator />                    
                    <StyleGroup>
                        <SC.FRow alc jsb>
                            <StyleGroupTitle>PROPERTIES</StyleGroupTitle>
                            {noProp}                        
                        </SC.FRow>   
                        {modelItems}
                    </StyleGroup>                    
                </SC.FCol>                
            ) 
        }
        
        let genericItem;

        if (this.props.component.Generic) {
            genericItem = (
                <GenericProperties {...this.props} />
            )
        }

        return (
            <React.Fragment>
                <StyleGroup>
                    <StyleGroupTitle>COMPONENT</StyleGroupTitle>
                    <StyleGroupTokenItem onClick={this.props.onSelectComponent} style={{marginTop : '8px', padding : '8px' }}>
                        <SC.FRow alc jsb style={{marginLeft : 0}}>
                            <SC.FRow f1 alc overflowHidden>      
                                <SC.TextDivAbbr style={{flex : 1, fontSize : '11px'}}>
                                    {name}
                                </SC.TextDivAbbr>
                            </SC.FRow>                                        
                        </SC.FRow>        
                    </StyleGroupTokenItem>
                    {
                        this.ComponentId && 
                        <Links.Link To={Links.Component_Edit(Globals.ProjectManager.Id, this.ComponentId)}> 
                            <SC.Buttons.RoundButton xsmall style={{width : '100%', minWidth : '80px', paddingLeft : '8px', paddingRight : '8px', fontSize : '11px', marginTop : '8px'}}>
                                EDIT MAIN COMPONENT
                            </SC.Buttons.RoundButton> 
                        </Links.Link>
                    }                    
                </StyleGroup>
                {genericItem}
                {content_state}
                {content_models}
            </React.Fragment>
                        
            
        );
    }
}

export const GetSubcomponentStateAndModels = (ComponentId, ModelValues) => {
    const result = {
        states : [],
        stateModels : [],
        models : [],
        succuess : false,
    };

    if (ComponentId) {
        const ComponentData = Globals.ProjectManager.GetComponentData(ComponentId);            
        if (ComponentData) {            
            result.succuess = true;
            if (ComponentData.PseudoStates && ComponentData.PseudoStates.Order) {
                ComponentData.PseudoStates.Order.map((stateid, i) => {
                    const stateModel = ComponentData.PseudoStates[stateid];
                    if (stateModel.previewable) {
                        result.states.push(stateid);
                        result.stateModels[stateid] = stateModel;
                    }
                });                    
            }
            if (ComponentData.States) {
                Utils.ForEach(ComponentData.States.Order, (StateId, i) => {         
                    const StateModel = ComponentData.States[StateId];
                    if (StateModel) {                           
                        result.states.push(StateId);
                        result.stateModels[StateId] = StateModel;
                    }                        
                });    
            }

            // Models
            ComponentData.Models && ComponentData.Models.List && Utils.ForEach(ComponentData.Models.Order,(mid, type) => {
                const model = ComponentData.Models.List[mid];
                if (model) {
                    if (!model.private) {
                        const defaultValue = Utils.JustGet(model, null, 'value');                            
                        let ModelValue = {
                            Value : Utils.Get(ModelValues, null, mid, 'value'),
                            ModelId : Utils.Get(ModelValues, null, mid, 'ModelId'),
                            TokenId : Utils.Get(ModelValues, null, mid, 'TokenId'),
                            Options : Utils.Get(ModelValues, null, mid, 'Options'),
                            MockupId : Utils.Get(ModelValues, null, mid, 'MockupId')
                        };
                        if (Utils.IsNull(ModelValue.Value)) {
                            ModelValue.Value = defaultValue;
                            ModelValue.isValueDefault = true;
                        }
                                               
                        result.models.push(
                            {
                                id : mid,
                                model : model,
                                Value : ModelValue
                            }
                        )
                    }                                
                }                          
            });
        }
    }

    return result;
}