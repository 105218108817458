import React, {useState} from 'react';
import {
    ReactBaseComponent,
    AppState,
    SC,
    Utils,
    Events,
    AppLayout,
    Globals,
    Strings
} from '../../../../../importer';

import styled from 'styled-components';
import {TokenGroup, EditableName, TokenItemBox, DraggableTokenItem, TokenPanelHeader, GroupTitle, PanelHeaderAddButton} from './common';
import { ListCardView } from '../../../../../components/light_dark_bar';
import { motion, useMotionValue } from 'framer-motion';
import { LeftScrollPanel, DotMenu } from '../common';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import { GRANT_TYPES } from '../../../manager';

export default class BaseTokenList extends ReactBaseComponent {
    constructor(props) {
        super(props);        

        this.state.isListView = true;
        this.SearchToken = this.SearchToken.bind(this);
        this.AddToken = this.AddToken.bind(this);
        this.AddGroup = this.AddGroup.bind(this);

        this.onDragEndToken = this.onDragEndToken.bind(this);
        this.ToggleGroupView = this.ToggleGroupView.bind(this);
        
        this.HasEditGrant = Globals.ProjectManager.CheckGrant(GRANT_TYPES.EDIT_TOKEN);

        this.PanelMode = this.props.isSelectList ? 'RightPanel' : 'LeftPanel';
        
    }
    LoadOptions() {
        this.state.isListView = Globals.ProjectManager.Options.Get(this.state.isListView ? true : false, this.PanelMode, 'DesignSystem', this.moduleName, 'ListView');        
        this.state.isGroupView = Globals.ProjectManager.Options.Get(this.state.isGroupView ? true : false, this.PanelMode, 'DesignSystem', this.moduleName, 'GroupView');
        this.onToggleExpand = this.onToggleExpand.bind(this);
        this.expanded = Globals.ProjectManager.Options.Get(this.expanded ? true : false, this.PanelMode, 'DesignSystem', this.moduleName, 'Expanded');
    }
    Load(groupView) {        
        
    }
    Reload() {
        if (this.expanded) {
            this.Load(this.props);
            this.RCUpdate();
        }
    }
    SearchToken(filter) {

    }
    GetGroupedTokenList(tokens) {
        return GetGroupedTokenList(this.tokenType, tokens);
    }

    shouldComponentUpdate(nextProps, nextState) {        
        if (this.props.GlobalState !== nextProps.GlobalState || this.props.GlobalStateId !== nextProps.GlobalStateId) {
            this.Load(nextState.isGroupView);
            return true;
        }
        if (this.props.showSearchBar !== nextProps.showSearchBar)
            return true;
        if (this.props.filterText !== nextProps.filterText) 
            return true;

        if (this.state.isGroupView !== nextState.isGroupView) {
            if (nextState.isGroupView)
                this.Load(true);
            else
                delete this.groups;
            return true;
        }
        if (this.props.themeId !== nextProps.themeId)
            return true;
        if (Utils.HasAnyChange(this.state, nextState, 'isListView', 'ShouldUpdate', 'showGroupMenu'))
            return true;
        if (this.props.themeId !== nextProps.themeId)
            return true;
        if (this.props.singleView !== nextProps.singleView) {
            if (nextProps.singleView && !this.expanded) {
                this.expanded = true;
                this.Load(nextState.isGroupView);
            }            
            return true;
        }
        if (!this.props.hasInfoMessage && nextProps.hasInfoMessage || this.props.hasInfoMessage && !nextProps.hasInfoMessage)
            return true;
        return super.ShouldUpdate(nextProps, nextState);  
    }
    componentDidUpdate(prevProps, prevState) {
        delete this.WillFocustoGroupId;
    }
    onToggleExpand() {
        this.expanded = !this.expanded;
        Globals.ProjectManager.Options.Set(this.expanded, this.PanelMode, 'DesignSystem', this.moduleName, 'Expanded');
        if (this.expanded)
            this.Load(this.state.isGroupView);
        this.RCUpdate();
    }
    SetListMode(isListView) {        
        Globals.ProjectManager.Options.Set(isListView, this.PanelMode, 'DesignSystem', this.moduleName, 'ListView');
        this.setState({
            isListView : isListView
        })
    }
    ToggleGroupView() {
        Globals.ProjectManager.Options.Set(!this.state.isGroupView, this.PanelMode, 'DesignSystem', this.moduleName, 'GroupView');
        this.setState({
            isGroupView : !this.state.isGroupView
        });
    }
    AddGroup() {
        if (!Globals.ProjectManager.CheckGrant_AddTokenGroup(this.tokenType)) {
            return;
        }

        const name = 'New Group';
        const groupid = Globals.ProjectManager.Tokens.AddGroup({type : this.tokenType, name : name})
        this.WillFocustoGroupId = groupid;
        const groups = Utils.Get(this, [], 'groups');
        if (groups.length === 0) {
            this.Load(true);
            this.RCUpdate();
            return;
        }
        groups.push({
            id : groupid,
            name : name,
            tokens : []
        })

        this.RCUpdate();
    }
    SaveGroupName(group, name) {
        group.name = name;
        Globals.ProjectManager.Tokens.ChangeGroupName({type : this.tokenType, id : group.id, name : name})
        this.RCUpdate();
    }
    DeleteGroup(group) {
        Globals.ProjectManager.Tokens.DeleteGroup({type : this.tokenType, id : group.id}).then((result) => {
            this.Reload();
        })        
        this.Reload();
    }
    DeleteGroupWithTokens(group) {
        Globals.ProjectManager.Tokens.DeleteGroup({type : this.tokenType, id : group.id, deleteTokens : true}).then((result) => {
            this.Reload();
        })        
    }
    LockAllTokens(group, locked) {
        Globals.ProjectManager.Tokens.LockAllTokensInGroup({type : this.tokenType, id : group.id, locked : locked});
        this.Reload();
    }
    SelectGroupMembers(group) {
        if (this.props.onSelectGroupMembers) {

        }
        else {
            if (this.props.RefToolbar && this.props.RefToolbar.current) {
                this.props.RefToolbar.current.ShowPanel({
                    type : 'GroupMembers',
                    title : 'Select Group Members',
                    group : group,
                    tokenType : this.tokenType,
                    onRegisterClose : (callClose) => {
                        super.AddCloseCallback(callClose);
                    },                    
                    onClose : () => {
                        this.Reload();
                    },               
                    onClosed : () => {
    
                    }                
                })
            }  
        }
    }
    RemoveFromGroup(tokenId) {
        
    }

    AddTokenToGroup(group) {

    }
    ToggleExpandGroup(group, delayed, e) {
        e && e.stopPropagation();
        group.collapsed = !group.collapsed;
        Globals.ProjectManager.Options.Set(group.collapsed, 'TokenGroups', this.tokenType, group.id, 'collapsed');
        setTimeout(() => {
            this.RCUpdate();
        }, delayed ? 200 : 0);        
    }
    AddToken() {
        this.AddTokenToGroup();
    }

    onDragEndToken(result) {
        const { source, destination } = result;
        if (!destination) {
            return;
        }
        if (result.type === 'GROUPS') {
            Globals.ProjectManager.Tokens.ChangeOrderOfGroups(this.tokenType, source.index, destination.index);
            Utils.ChangePlace(this.groups, source.index, destination.index);
        }
        else {
            if (this.state.isGroupView) {
                if (source.droppableId === destination.droppableId) {
                    if (source.droppableId !== 'Default') {
                        Globals.ProjectManager.Tokens.ChangeGroupOrderOfToken(this.tokenType, source.droppableId, source.index, destination.index);                
                    }
                    const group = Utils.Find(this.groups, (item) => {return item.id === source.droppableId});
                    if (group && group.tokens) {
                        Utils.ChangePlace(group.tokens, source.index, destination.index);
                    }
                    if (source.droppableId === 'Default') {
                        const orders = [];
                        Utils.ForEach(group.tokens, ({id}, ) => {
                            orders.push(id);
                        });
                        Globals.ProjectManager.Tokens.SetDefaultGroupOrders(this.tokenType, orders);
                    }
                } 
                else {
                    const sourcegroup = Utils.Find(this.groups, (item) => {return item.id === source.droppableId});
                    const targetgroup = Utils.Find(this.groups, (item) => {return item.id === destination.droppableId});
                    const token = sourcegroup.tokens[source.index]
                    sourcegroup.tokens.splice(source.index, 1);
                    Globals.ProjectManager.Tokens.ChangeGroupOfToken(this.tokenType, sourcegroup.id, targetgroup.id, token.id, destination.index);
                    targetgroup.tokens.splice(destination.index, 0, token);                    
                }
            }   
            else {
                this.onSortNoneGroupedList(source.index, destination.index, source.droppableId);                              
            }         
        }

        Events.BCE(Events.GLOBAL.TOKENS_ORDER_CHANGED, this.tokenType);
        
        this.RCUpdate();
    }
    onSortNoneGroupedList(oldIndex, newIndex, groupType) {
        Globals.ProjectManager.Tokens.ChangeOrder(this.tokenType, oldIndex, newIndex);
        Utils.ChangePlace(this.tokens, oldIndex, newIndex);
    }
    renderContent() {

    }    
    ShowGroupMenu(groupId) {
        this.setState({
            showGroupMenu : this.state.showGroupMenu === groupId ? false : groupId
        })
    }
    renderTokenGroup(group, index) {
        return (
            <DraggableGroupHeader 
                group={group}
                index={index}
                isEditable={this.HasEditGrant}
                onSaveName={this.SaveGroupName.bind(this, group)}
                onDelete={this.DeleteGroup.bind(this, group)}
                onSelectMembers={this.SelectGroupMembers.bind(this, group)}
                onDeleteWithTokens={this.DeleteGroupWithTokens.bind(this, group)}
                onAddToGroup={this.AddTokenToGroup.bind(this, group)}
                onToggleExpandGroup={this.ToggleExpandGroup.bind(this, group, false)}
                willFocus={this.WillFocustoGroupId === group.id}
                onShowMenu={this.ShowGroupMenu.bind(this, group.id)}
                showMenu={group.id === this.state.showGroupMenu}
                onLockAllTokens={this.LockAllTokens.bind(this, group)}
            >
                {!group.collapsed && this.renderTokenList(group.tokens, group.id)}
            </DraggableGroupHeader>
        )        
    }
    renderTokenList(tokens, gorupId) {

    }
    renderNoneGroupedList(tokens, groupId) {
        return this.renderTokenList(this.tokens, 'Default');
    }
    renderHeaderAddOn() {
        if (this.expanded && !this.noListView) {
            return (
                <ListCardView 
                    onListView={this.SetListMode.bind(this, true)}
                    onCardView={this.SetListMode.bind(this, false)}
                    isListView={this.state.isListView}
                    isCardView={!this.state.isListView}
                    style={{marginLeft : '4px'}}
                    compact
                />
            )
        }
    }
    renderHeaderAddOnLast() {

    }
    renderFolder(content) {
        let groupContent;
        if (this.expanded) {
            groupContent = (
                <motion.div
                    initial={{opacity : 0.5, y : -4}}
                    animate={{opacity : 1, y : 0}}
                    transition={{duration  :0.2}}
                    style={{flex : 1}}
                >
                    {this.props.infoMessage}
                    {
                        this.props.singleView && !this.props.noScrolls? 
                        <LeftScrollPanel>
                            {content}        
                        </LeftScrollPanel> : content
                    }                                            
                </motion.div>
            )
        }
        if (this.noHeader)        
            return groupContent;

        return (
            <TokenGroup 
                title={this.title} 
                hideTitle={this.props.singleView}
                noTopPadding={this.props.singleView && !this.props.isSelectList}
                forSelect={this.props.forSelect}
                hasAdd={this.HasEditGrant && this.expanded  ? {onAdd : this.state.isGroupView ? this.AddGroup : this.AddToken, groupView : this.state.isGroupView} : null}
                hasGroup={this.hasGroups && this.expanded  ? {onToggle : this.ToggleGroupView, selected : this.state.isGroupView} : null}                
                addOn={this.renderHeaderAddOn()}
                addOnLast={this.renderHeaderAddOnLast()}
                // hasSearch={this.expanded ? {onSearch : this.SearchToken, style : {marginLeft : '10px', marginRight : '10px'}} : false}
                expandable={{expanded : this.expanded,  onExpand : this.onToggleExpand}}
                last={this.props.last}
                style={{flex : 1, paddingBottom : '4px'}}
                noBottomPadding={this.props.isSelectList}
            >
                {groupContent}                
            </TokenGroup>
        )
    }
    renderCustom() {
        let content;
        
        if (this.state.isGroupView && this.groups && this.expanded) {
            const groupItems = [];
            Utils.ForEach(this.groups, (group, i) => {
                if (!group.filtered) {
                    groupItems.push(
                        this.renderTokenGroup(group, i)
                    )
                }                
            });
            content = (
                <DragDropContext
                    onDragEnd={this.onDragEndToken}
                >                        
                    <Droppable 
                        droppableId='TokenGroups'
                        type='GROUPS'
                    >
                        {
                            provided => (
                                <div
                                    {...provided.droppableProps} 
                                    ref={provided.innerRef} 
                                    style={{
                                        ...provided.droppableProps.style,
                                        ...SC.Styles.Flex.Column
                                    }}
                                >
                                    {groupItems}   
                                    {provided.placeholder}
                                </div>
                            )
                        }    
                    </Droppable>                    
                </DragDropContext>
            )
        }
        else {
            if (this.tokenType === Globals.ProjectManager.Tokens.Types.Shadows && !this.state.isGroupView) {
                content = this.renderContent();
            }
            else {
                content = (
                    <DragDropContext
                        onDragEnd={this.onDragEndToken}
                    >
                        {this.renderNoneGroupedList(this.tokens, 'Default')}
                    </DragDropContext>
                )
            }
        }
                
        return (
            <React.Fragment>                
                {!this.props.justContent && this.props.children}                
                {this.renderFolder(content)}
            </React.Fragment>            
        )
    }
}


export const GetGroupedTokenList = (tokenType, tokens) => {
    const groups = Globals.ProjectManager.Tokens.GetGroups(tokenType);    
    return GetGroupedTokenListWithGroups(groups, tokens, false, tokenType);
}

export const GetGroupedTokenListWithGroups = (groups, tokens, willAddToDefault, tokenType, isAliase) => {
    if (groups && groups.length > 0) {
        const groupitems = [];
        const tokenGroupMap = {};
        Utils.ForEach(groups, (group, i) => {
            if (group) {
                const groupitem = {
                    ...group,
                    tokens : []
                };
                groupitems.push(groupitem);
                
                groupitem.collapsed = Globals.ProjectManager.Options.Get(false,  isAliase ? 'TokenAliaseGroups' : 'TokenGroups', tokenType, group.id, 'collapsed');        

                Utils.ForEach(group.order, (tokenId, c) => {
                    if (tokenId && Utils.Find(tokens, (t) => {return t.id === tokenId})) {
                        groupitem.tokens.push(tokenId);                    
                        tokenGroupMap[tokenId] = i;
                    }
                    else {
                        console.log('Aliase Erro : ' + tokenId);
                    }          
                });                
            }            
        });
        let defaultGroup = Utils.Find(groupitems, (item) => {return item.id === 'Default'});
        if (!defaultGroup) {
            defaultGroup = groupitems[0];
        }
        Utils.ForEach(tokens, (token, ) => {
            if (token) {
                if (tokenGroupMap[token.id] >= 0) {
                    const group = groupitems[tokenGroupMap[token.id]];
                    if (group) {
                        const index = group.tokens.indexOf(token.id);
                        group.tokens.splice(index, 1, token);
                        token.groupId = group.id;
                    }                    
                    else {
                        defaultGroup.tokens.push(token);
                        if (willAddToDefault)
                            willAddToDefault.push(token.id);
                    }
                }
                else {
                    defaultGroup.tokens.push(token);
                    if (willAddToDefault)
                        willAddToDefault.push(token.id);
                }
            }            
        });            
        
        return groupitems;
    }
    return null;
}

const TokenGroupTitle = styled.div`
    display : flex;
    flex : 1;
    align-items : center;
    transition : 0.2s ease;
    transition-delay : 0.1s;
    transform : translateX(0px);
    transition-property : transform, margin-right;
    margin-right : 0px;
    padding-left : 8px;
    z-index : 999;
    cursor : pointer;
    overflow : hidden;
    ${SC.HoverParent}:hover &{
        margin-right : 12px;
    }
`;

const GroupExpandIccon = styled.div`
    display : flex;
    flex-direction : column;
    justify-content : center;
    align-items : center;
    min-width : 24px;
    min-height : 24px;
    fill : ${props => props.theme.icon};
    transition : transform 0.2s ease;
    transition-delay : 0.1s;
    ${SC.HoverParent}:hover &{
        transform : translateX(4px);
    }
`;

export const DraggableGroupHeader = ({group, index, isEditable, onSaveName, onClone, onDelete, onSelectMembers, onDeleteWithTokens, onRename, onShowMenu, onCloseMenu, showMenu, willFocus, onAddToGroup, onToggleExpandGroup, onLockAllTokens, ...props}) => {
    const [editName, setEditName] = useState(false);
    return (
        <Draggable key={group.id}  draggableId={group.id} index={index}>                
            {
                (provided, snapshot) => (
                    <div {...provided.draggableProps} style={{...provided.draggableProps.style, ...SC.Styles.Flex.Column, zIndex : showMenu ? 100 : 1}} ref={provided.innerRef}>                                                    
                        <SC.HoverParent defaultStyle={{...SC.Styles.Flex.RowAlcJsb, marginTop : '4px', paddingRight : '8px', paddingLeft : '4px', position : 'relative'}}>
                            <SC.HoverChild defaultStyle={{position : 'absolute', top : 0, bottom : 0, ...SC.Styles.Flex.Cell, left : '0', zIndex : 9999}}>
                                <SC.DragBox {...provided.dragHandleProps} first style={{...provided.dragHandleProps.style, cursor:'move', height : 'unset', marginLeft : '12px', border : 'none'}} onMouseDown={(e) => e.stopPropagation()}>
                                    <SC.Icons.DragIcon xsmall />
                                </SC.DragBox>
                            </SC.HoverChild>                            
                            <TokenGroupTitle onClick={onToggleExpandGroup}>
                                <GroupExpandIccon hasFill hasCursor>
                                    <SC.Icons.Icon_Arrow_Filled expanded={!group.collapsed} size={20} />
                                </GroupExpandIccon>
                                <EditableName 
                                    value={group.name} 
                                    onSave={onSaveName} 
                                    willEdit={willFocus || editName} 
                                    style={{fontSize : '12px'}} 
                                    onClose={() => setEditName(false)}
                                    noDoubleClick
                                />    
                            </TokenGroupTitle>
                            {
                                isEditable && 
                                <React.Fragment>
                                    <PanelHeaderAddButton onAdd={onAddToGroup} title='Add to Group' />
                                    <DotMenu onClick={onShowMenu} isOpen={showMenu} onClose={onShowMenu} buttonStyle={{backgroundColor : 'unset'}}>
                                        {
                                            showMenu &&
                                            <SC.FCol style={{minWidth : '160px', lineHeight : '18px'}}>
                                                <SC.PopupItem onClick={() => {onShowMenu(); setEditName(true);}}>
                                                    Rename Folder
                                                </SC.PopupItem>
                                                <SC.PopupItem onClick={() => {onShowMenu(); onSelectMembers();}}>
                                                    Select Group Members
                                                </SC.PopupItem>
                                                {
                                                    onClone && 
                                                    <SC.PopupItem onClick={() => {onShowMenu(); onClone();}}>
                                                        Clone Folder & Tokens
                                                    </SC.PopupItem>
                                                }
                                                {
                                                    group.id !== 'Default' &&
                                                    <SC.PopupItem onClick={onDelete} style={{...SC.Styles.Flex.RowAlcJsb}}>
                                                        <div>Delete</div>
                                                        <SC.Icons.Icon_Button hasFill hasCursor style={{marginLeft : '4px', marginRight : '4px'}}>
                                                            <SC.Icons.Icon_Delete size={16} />
                                                        </SC.Icons.Icon_Button>
                                                    </SC.PopupItem>
                                                }                                        
                                                <SC.PopupItem onClick={() => {onShowMenu(); onDeleteWithTokens();}}>
                                                    {group.id === 'Default' ? 'Delete Tokens' : 'Delete with Tokens'}
                                                </SC.PopupItem>
                                                <SC.PopupItem seperator onClick={() => {onShowMenu(); onLockAllTokens && onLockAllTokens(true);}}>
                                                    Lock All Tokens in Group
                                                </SC.PopupItem>
                                                <SC.PopupItem onClick={() => {onShowMenu(); onLockAllTokens && onLockAllTokens(false);}}>
                                                    UnLock All Tokens in Group
                                                </SC.PopupItem>
                                            </SC.FCol>
                                        }
                                    </DotMenu>
                                </React.Fragment>
                            }
                            
                        </SC.HoverParent>
                        {props.children}
                    </div>
                )
            }
        </Draggable>
    )
}
