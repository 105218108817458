import React from 'react';
import ReactDOM from 'react-dom';
import {
    SC,
    Utils,
    AppLayout,
    Strings,
    Globals
} from '../../../../../../../importer';

import { TokenTypes, CalculateScales } from '../../../../../manager/tokens';
import PatternPreview from './preview';
import { FontFamilyDisplayName } from '../../../../../panels/left/designsystem/typography/fonts';

export default class DocumentToken_Typography extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {

        };    
    }
    shouldComponentUpdate(nextProps, nextState) {

        if (this.state.ShouldUpdate !== nextState.ShouldUpdate)
            return true;
        
        if (Utils.HasAnyChange(this.props, nextProps, 'GlobalThemeId', 'GlobalState', 'StyleId', 'RenderId', 'LoadId', 'themeId', 'themeType', 'states', 'ReversedStyleState'))
            return true;
        
        return false;
    }
    componentDidUpdate(prevProps, prevState) {
        this.state.ShouldUpdate = false;
    }
    DownloadCustomFont() {

    }
    render() {        

        if (this.props.subType === 'fonts') {
            const fontTokenIds = this.props.selection.type === 'selected' ? this.props.selection.tokenIds : Globals.ProjectManager.Tokens.Order(TokenTypes.Fonts);

            let content_fonts = [];
            Utils.ForEach(fontTokenIds, (tokenId, i) => {
                const fontToken = Globals.ProjectManager.Tokens.Token(tokenId);
                const fontValue = Globals.ProjectManager.Tokens.ValueOf({model : fontToken, statearray : this.props.ReversedStyleState});
                const fontStack = Globals.ProjectManager.Tokens.ValueOf({model : fontToken, name : 'fontStack', statearray : this.props.ReversedStyleState});
                if (fontValue) {
                    const name = FontFamilyDisplayName(fontValue);

                    let info;
                    if (this.props.viewMode === 'list') {

                        let downloadInfo;
                        if (fontValue.provider === Strings.CUSTOM) {
                            const CustomFont = AppState.CatchedData.Get(AppState.CatchedData.CUSTOMFONTS, fontValue.fontId);
                            if (CustomFont && CustomFont.StorageUrl) {
                                // downloadInfo = (
                                //     <SC.LinkText onClick={this.DownloadCustomFont.bind(this)}>Download font file</SC.LinkText>
                                // )

                                downloadInfo = (
                                    <a href={CustomFont.StorageUrl} download={Utils.UseNullOrEmpty(CustomFont.fileName, fontValue.name)}>Download font file</a>
                                )
                            }                            
                        }
                        else if (fontValue.provider === Strings.FONT_GOOGLE) {
                            // downloadInfo = <a href={fontValue.url}>Download</a>
                        }

                        info = (
                            <div style={{
                                display : 'grid',
                                gridGap : '8px',
                                fontFamily : 'monospace',
                                fontSize : '12px',
                                alignContent : 'start',
                                padding : '8px'
                            }}> 
                                <div style={{...this.props.textStyles.body}} >{fontToken.name}</div>
                                <div>{fontValue.provider}</div>                               
                                {downloadInfo}
                                <div>{fontStack}</div>

                            </div>
                        )
                    }

                    content_fonts.push(
                        <React.Fragment>
                            <SC.FCol alc key={tokenId} 
                                style={{
                                    backgroundColor : this.props.textStyles.backgroundHighlight,
                                    padding : '16px',
                                    borderRadius : '4px'
                                }}
                            >
                                <SC.FCol alc                            
                                    style={{
                                        ...Globals.ProjectManager.Tokens.Get_FontFamilyOf(fontValue)
                                    }}
                                >
                                    <div style={{
                                        fontSize : '64px',
                                        lineHeight : '100px'
                                    }}>
                                        Ag
                                    </div>
                                    <div style={{...this.props.textStyles.body, textAlign : 'center'}}>{name}</div>
                                </SC.FCol>
                            </SC.FCol>
                            {info}
                        </React.Fragment>
                    )
                }                

            });

            if (this.props.viewMode === 'list') {
                return (
                    <div style={{
                        display : 'grid',
                        gridTemplateColumns : 'minMax(160px, auto) 1fr',
                        gridGap : '16px',
                        justifyContent : 'start',
                        justifyItems : 'stretch'
                    }}>
                        {content_fonts} 
                    </div>
                )
            }
            else {
                return (
                    <div style={{
                        display : 'grid',
                        gridTemplateColumns : 'repeat(auto-fill, minmax(max(300px, calc(50% - 8px)), 1fr))',
                        gridGap : '16px',
                        justifyContent : 'start',
                        justifyItems : 'stretch'
                    }}>
                        {content_fonts} 
                    </div>
                )
            }            
        }
        else if (this.props.subType === 'scale') {
            const typeScale = Utils.DeepClone(Globals.ProjectManager.Tokens.Typescale.Get(this.props.GlobalState, this.props.ReversedStyleState));
            typeScale.downScaleCount = 2;
            typeScale.upScaleCount = 8;
            CalculateScales(typeScale, typeScale.ratio);
            const reversedScales = Utils.Sort(typeScale.scales, (scale) => {return scale.em});

            let typescaleName = typeScale.ratio;
            if (typeScale.scaleType !== 'Custom') {
                const typeScaleItem = Utils.Find(Globals.ProjectManager.Tokens.Utils.ScalePresets, (item) => {return item.id === typeScale.scaleType});
                if (typeScaleItem)
                    typescaleName = <div>{`${typeScaleItem.label} - Factor of `}<span style={{fontWeight : 'bold'}}>{typeScale.ratio}</span></div>
            }
            
            let maxScaleheight = 0;
            const scaleItems = reversedScales.map((scale, i) => {
                maxScaleheight = Math.max(scale.px, maxScaleheight);
                return (
                    <div key={i} style={{
                        fontSize : Utils.px(scale.px),                        
                        fontWeight : i === 2 ? 'bold' : 400,  
                        transition : 'font-size 0.4s ease',
                        position : 'relative',
                        lineHeight : Utils.px(scale.px),                        
                    }}>
                        a
                    </div>
                )
            });

            return (
                <div style={{
                    padding : '16px',
                    borderRadius : '4px',
                    backgroundColor : this.props.textStyles.backgroundHighlight,
                    display : 'grid'
                }}>
                    <div
                        style={{
                            display : 'grid',
                            gridTemplateColumns : 'auto 1fr',
                            gridColumnGap : '16px',
                            gridRowGap : '8px',
                            alignContent : 'start',                            
                            fontSize : '14px',
                            alignItems : 'baseline'
                        }}
                    >
                        <DocumentLabel>Typescale</DocumentLabel>
                        <DocumentTextValue>{typescaleName}</DocumentTextValue>
                        <DocumentLabel>Base Font Size</DocumentLabel>
                        <DocumentTextValue>{typeScale.baseSize} px</DocumentTextValue>
                    </div>
                    <div
                        style={{
                            display : 'grid',
                            gridTemplateColumns : `repeat(${reversedScales.length}, auto)`,        
                            justifyItems : 'center',
                            alignItems : 'baseline',
                            // marginTop : `${(scale > 1.3 ? (scale > 1.5 ? (-50 + offset) : (-20 + (offset/2))) : 0) + (offset/3)}px`,
                            gridGap : '8px',
                            gridRowGap : 0,
                            overflow : 'auto',
                            ...SC.Styles.FontFamily(),
                            minHeight : 1.3 * maxScaleheight
                        }}
                    >
                        {scaleItems}
                        {
                            reversedScales.map((scale, i) => {
                                return (
                                    <div key={'dark'+i}style={{
                                        fontSize : '12px',                                        
                                        fontWeight : 500,
                                        backgroundColor : i === 2 ? this.props.textStyles.backgroundColor : 'unset',
                                        padding : i === 2 ? '4px' : 0,
                                        borderRadius : '2px'
                                    }}>{scale.px}</div>
                                )
                            })
                        }
                    </div>
                </div>
                
            )
        }

        
        let content_patterns;

        

        const textPatternIds = this.props.selection.type === 'selected' ? this.props.selection.tokenIds : Globals.ProjectManager.Tokens.TypePatterns.Get();

        content_patterns = [];
        Utils.ForEach(textPatternIds, (textPatternId, ) => {
            const textPattern = Globals.ProjectManager.Tokens.Token(textPatternId);
            if (textPattern) {
                const textStyle = Globals.ProjectManager.Tokens.TypePatterns.GetPatternStyle(textPattern, null, null, this.props.ReversedStyleState);
    
                content_patterns.push(
                    <div
                        style={{
                            display : 'grid',
                            gridTemplateColumns : (AppLayout.Media.Small || this.props.viewMode === 'card') ? '1fr' : 'repeat(auto-fit, minmax(400px, 1fr))',
                            gridRowGap : '24px'
                        }}
                    >
                        <div
                            style={{
                                display : 'grid',
                                gridTemplateColumns : 'auto 1fr',
                                gridColumnGap : '16px',
                                gridRowGap : '8px',
                                alignContent : 'start',
                                ...this.props.textStyles.paragraph,
                                lineHeight : 'unset',
                                alignItems : 'baseline',
                                fontSize : '14px'
                            }}
                        >
                            <div style={{
                                gridColumnStart : 1,
                                gridColumnEnd : 3,
                                ...this.props.textStyles.paragraph
                            }}>
                                {textPattern.name}
                            </div>
                            <DocumentLabel>Font Family</DocumentLabel>
                            <div style={{fontFamily : 'monospace'}}>{textStyle.fontFamily}</div>                                                          
                            <DocumentLabel>Font Weight</DocumentLabel>
                            <div style={{fontFamily : 'monospace'}}>{textStyle.style || textStyle.fontWeight}</div>
                            <DocumentLabel>Size</DocumentLabel>
                            <div style={{fontFamily : 'monospace'}}>{textStyle.fontSize}</div>                        
                            <DocumentLabel>Line Height</DocumentLabel>
                            <div style={{fontFamily : 'monospace'}}>{textStyle.lineHeight}</div>
                            <DocumentLabel>Letter Spacing</DocumentLabel>
                            <div style={{fontFamily : 'monospace'}}>{textStyle.letterSpacing}</div>
                            <DocumentLabel>Word Spacing</DocumentLabel>
                            <div style={{fontFamily : 'monospace'}}>{textStyle.wordSpacing}</div>
                            <DocumentLabel>Paragraph Spacing</DocumentLabel>
                            <div style={{fontFamily : 'monospace'}}>{Utils.UseNullOrEmpty(textStyle.paragraphSpacing, 0)}</div>
                        </div>
                        <PatternPreview 
                            id={this.props.id}
                            textPatternId={textPatternId} 
                            ReversedStyleState={this.props.ReversedStyleState} 
                            textStyles={this.props.textStyles}
                            preview={this.props.preview}
                        />
                    </div>
                );
    
                content_patterns.push(
                    <div style={{
                        // gridColumnStart : 1,
                        // gridColumnEnd : 3,
                        height : '1px',
                        backgroundColor : this.props.textStyles.dividerColor,
                        // marginTop : '24px',
                        // marginBottom : '24px',
                    }} 
                    />
                )

                if (this.props.forTemplateSelect && content_patterns.length === 2) {
                    return false;
                }
            }            
        });
      
        return (
            <div
                style={{
                    display : 'grid',
                    gridTemplateColumns : '1fr',
                    gridRowGap : '24px'
                }}
            >
                {content_patterns}
            </div>
        )
    }
}

export const DocumentLabel = (props) => {
    return (
        <div style={{fontWeight : 'bold'}}>{props.children}</div>
    )
}

export const DocumentTextValue = (props) => {
    return (
        <div style={{fontFamily : 'monospace'}}>{props.children}</div>
    )
}
