import React from 'react';
import { Utils } from '../toolabs-importer';
import styled, { css, keyframes, ThemeProvider, createGlobalStyle } from 'styled-components';
import {Svg, svgns} from './icons';
import {CurrentTheme} from './index';

export const CD_LP_ToobarItem = styled.div`
    position : relative;
    padding-top : 4px;
    padding-bottom : 4px;    
    cursor : move;  
    width : 36px;  
    height : 36px;    
    box-sizing : border-box;
    display : flex;
    flex-direction : column;
    align-items : center;
    justify-content : space-around;
    background-color : ${props => props.isOpen ? props.theme.back : 'transparent'};
    transition : all 0.4s ease;
    border-radius : 2px;
    margin-bottom : 4px;
    overflow : hidden;
    &:hover {
        background-color : ${props => props.theme.back};
        color : white;        
    }
`;

export const CD_LP_TooobarItemArrow = styled.div`
    border-right : 6px solid ${props => props.isOpen ?  'transparent' : props.theme.back_lighter};
    transition : all 0.2s ease;
    ${CD_LP_ToobarItem}:hover &{
        border-right : 6px solid ${props => props.isOpen ?  props.theme.icon : props.theme.icon};
    }    
`;

export const CD_LP_ToolbarItemText = styled.div`
    font-size : 10px;
    letter-spacing : 0.3px;
    opacity : ${props => props.visible ? 1 : 0};
`;

export const ToolbarItem_G = styled.g`
    fill : ${props => props.noFill ? 'none' : (props.selected ? props.theme.icon : (props.fill || props.theme.icon))};
    stroke : ${props => props.noStroke ? 'none' : (props.selected ? props.theme.icon : (props.stroke || props.theme.icon))};
    ${props => props.ParentItem || CD_LP_ToobarItem}:hover &{
        fill : ${props => props.noFill ? 'none' : props.fillHover || props.theme.icon_hover};
        stroke : ${props => props.noStroke ? 'none' : props.strokeHover || props.theme.icon_hover};
    }
`;

export const PinButton = (props) => {
    return (
        <PinBox onClick={props.onPin} title='Pin panel'>
            <Pin style={Utils.Merge({                                    
                backgroundColor : props.pinned ? CurrentTheme.theme.color_brand : '#757678'
            }, props.style)} />
        </PinBox>
    )
}

export const PinFavoriteButton = ({fill, size}) => {
    return (
        <Svg width={size || 18} viewBox="0 0 24 24" fill={fill}>
            <path fill="none" d="M0 0h24v24H0z"/>
            <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"/>
            <path fill="none" d="M0 0h24v24H0z"/>     
        </Svg>
    )
}


const PinBox = styled.div`
    width : 12px;
    height : 12px;
    border-radius : 50%;
    border : 1px solid #757678;
    display : flex;    
    justify-content : center;
    align-items: center;
    box-sizing : border-box;
    cursor : pointer;
`;
const Pin = styled.div`
    width : 4px;
    height : 4px;
    transition : all 0.2s ease;
    border-radius : 3px;
    ${PinBox}:hover &{
        transform : scale(1.3);
    } 
`;

export default {
    Button: (props) => {
        return (
            <Svg width={props.small ? 16 : 24} viewBox="0 0 24 24">
                <ToolbarItem_G {...props} selected={props.selected} noStroke >
                    <path d="M17 12.5v1a.5.5 0 1 1-1 0v-2a.5.5 0 1 0-1 0v2a.5.5 0 1 1-1 0v-3a.5.5 0 1 0-1 0v3a.5.5 0 1 1-1 0v-8a.5.5 0 1 0-1 0v7.55a1.41 1.41 0 0 1-2.32 1.08l-.92-.77A1.57 1.57 0 0 0 6.75 13a.7.7 0 0 0-.62.4l-.04.07a.8.8 0 0 0 .1.85l4.42 5.32A3.76 3.76 0 0 0 13.5 21a4.5 4.5 0 0 0 4.5-4.5v-4a.5.5 0 1 0-1 0zm-.05-1.4A1.5 1.5 0 0 1 19 12.5v4a5.5 5.5 0 0 1-5.5 5.5 4.76 4.76 0 0 1-3.66-1.72l-4.42-5.32a1.8 1.8 0 0 1-.23-1.93l.03-.07A1.7 1.7 0 0 1 6.75 12c.6 0 1.18.21 1.65.6l.92.77a.41.41 0 0 0 .68-.32V5.5a1.5 1.5 0 0 1 3 0v3.59a1.5 1.5 0 0 1 1.95 1.02 1.5 1.5 0 0 1 2 1zM15 5.5a.5.5 0 1 1-1 0 2.5 2.5 0 1 0-5 0 .5.5 0 0 1-1 0 3.5 3.5 0 0 1 7 0z"/>
                </ToolbarItem_G>
            </Svg>
        );
    },
    Checkbox: (props) => {
        return (
            <Svg width={props.small ? 16 : 24} viewBox="0 0 24 24">
                <ToolbarItem_G {...props} selected={props.selected} noStroke >
                    <path d="M5.5 3h13A2.5 2.5 0 0121 5.5v13a2.5 2.5 0 01-2.5 2.5h-13A2.5 2.5 0 013 18.5v-13A2.5 2.5 0 015.5 3zm0 1A1.5 1.5 0 004 5.5v13A1.5 1.5 0 005.5 20h13a1.5 1.5 0 001.5-1.5v-13A1.5 1.5 0 0018.5 4h-13zm9.646 5.146a.5.5 0 01.708.708l-5 5a.5.5 0 01-.708 0l-2-2a.5.5 0 01.708-.708l1.646 1.647 4.646-4.647z"/>
                </ToolbarItem_G>
            </Svg>
        );
    },
    Toggle: (props) => {
        return (
            <Svg width={props.small ? 16 : 24} viewBox="0 0 24 24">
                <ToolbarItem_G {...props} selected={props.selected} noStroke >
                    <path d="M14 6.516a3.5 3.5 0 0 0 7 0 3.496 3.496 0 0 0-3.492-3.508A3.51 3.51 0 0 0 14 6.516zm.684-3.514L6.5 3.008A3.508 3.508 0 0 0 3 6.516a3.5 3.5 0 0 0 3.5 3.5h8.171A4.491 4.491 0 0 1 13 6.516a4.5 4.5 0 0 1 1.684-3.514zm2.91-.993A4.494 4.494 0 0 1 22 6.5c0 2.5-2.015 4.516-4.5 4.516h-11a4.5 4.5 0 0 1-4.5-4.5 4.508 4.508 0 0 1 4.5-4.508L17.5 2c.032 0 .063.003.094.009zm-11.188 11A.503.503 0 0 1 6.5 13l11 .008a4.508 4.508 0 0 1 4.5 4.508 4.5 4.5 0 0 1-4.5 4.5h-11A4.503 4.503 0 0 1 2 17.5c0-2.45 1.96-4.441 4.406-4.491zM10 17.516a3.51 3.51 0 0 0-3.508-3.508A3.496 3.496 0 0 0 3 17.516a3.5 3.5 0 0 0 7 0zm-.684-3.514A4.5 4.5 0 0 1 11 17.516a4.491 4.491 0 0 1-1.671 3.5H17.5a3.5 3.5 0 0 0 3.5-3.5 3.508 3.508 0 0 0-3.501-3.508l-8.183-.006z"/>
                </ToolbarItem_G>
            </Svg>
        );
    },
    RangeInput: (props) => {
        return (
            <Svg width={props.small ? 16 : 24} viewBox="0 0 24 24">
                <ToolbarItem_G {...props} selected={props.selected} noStroke >
                    <path d="M8 17H3.5a.5.5 0 1 1 0-1H8v-1a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v1h8.5a.5.5 0 1 1 0 1H12v1.031a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1V17zm6-5H3.5a.5.5 0 1 1 0-1H14v-1a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v1h2.5a.5.5 0 1 1 0 1H18v1.031a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V12zM5 7H3.5a.5.5 0 0 1 0-1H5V5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v1h11.5a.5.5 0 1 1 0 1H9v1.031a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V7zm1-2v3.031h2V5H6zm9 5v3.031h2V10h-2zm-6 5v3.031h2V15H9z"/>
                </ToolbarItem_G>
            </Svg>
        );
    },
    ProgressBar: (props) => {
        return (
            <Svg width={props.small ? 16 : 24} viewBox="0 0 24 24" style={{transform : 'rotate(-90deg)s'}}>
                <ToolbarItem_G {...props} selected={props.selected} noStroke >
                    <path d="M6.904 19.803a.5.5 0 0 1-.808 0l-2.95-2.95a.5.5 0 0 1 .708-.707L6 18.293V4.5a.5.5 0 0 1 1 0v13.793l2.146-2.147a.5.5 0 0 1 .708.708l-2.95 2.95zM12.5 6a.5.5 0 1 1 0-1h8a.5.5 0 1 1 0 1h-8zm0 4a.5.5 0 1 1 0-1h6a.5.5 0 1 1 0 1h-6zm0 4a.5.5 0 1 1 0-1h4a.5.5 0 1 1 0 1h-4zm0 4a.5.5 0 1 1 0-1h2a.5.5 0 1 1 0 1h-2z"/>
                </ToolbarItem_G>
            </Svg>
        );
    },
    Dropdown: (props) => {
        return (
            <Svg width={props.small ? 16 : 24} viewBox="0 0 24 24">
                <ToolbarItem_G {...props} selected={props.selected} noStroke >
                    <path  d="M18 18V4.5c0-.83-.67-1.5-1.5-1.5H5.73A2 2 0 0 1 6 4v16a1 1 0 0 0 2 0v-1.5c0-.28.22-.5.5-.5H18zm1 0h2.5c.28 0 .5.22.5.5v1a2.5 2.5 0 0 1-2.5 2.5H7a2 2 0 0 1-2-2V6H4a2 2 0 1 1 0-4h12.5A2.5 2.5 0 0 1 19 4.5V18zM9 19v1a2 2 0 0 1-.27 1H19.5c.83 0 1.5-.67 1.5-1.5V19H9zM5 5V4a1 1 0 1 0-1 1h1zm3.5 2a.5.5 0 0 1 0-1h7a.5.5 0 1 1 0 1h-7zm0 3a.5.5 0 0 1 0-1h7a.5.5 0 1 1 0 1h-7zm0 3a.5.5 0 1 1 0-1h7a.5.5 0 1 1 0 1h-7zm0 3a.5.5 0 1 1 0-1h5a.5.5 0 1 1 0 1h-5z"/>
                </ToolbarItem_G>
            </Svg>
        );
    },
    SelectBox: (props) => {
        return (
            <Svg width={props.small ? 16 : 24} viewBox="0 0 24 24">
                <ToolbarItem_G {...props} selected={props.selected} noStroke >
                    <path fill="currentColor" d="M3 6a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V6zm5.5 1a.5.5 0 0 1 0-1h12a.5.5 0 1 1 0 1h-12zM3 11a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-1zm5.5 1a.5.5 0 1 1 0-1h12a.5.5 0 1 1 0 1h-12zM3 16a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-1zM4 6v1h1V6H4zm0 5v1h1v-1H4zm0 5v1h1v-1H4zm4.5 1a.5.5 0 1 1 0-1h12a.5.5 0 1 1 0 1h-12z"/>
                </ToolbarItem_G>
            </Svg>
        );
    },
    Carousel: (props) => {
        return (
            <Svg width={props.small ? 16 : 24} viewBox="0 0 24 24">
                <ToolbarItem_G {...props} selected={props.selected} noStroke >
                    <path d="M4.707 18l2.147 2.146a.5.5 0 01-.708.708l-3-3a.5.5 0 010-.708l3-3a.5.5 0 01.708.708L4.707 17h14.586l-2.147-2.146a.5.5 0 01.708-.708l3 3a.5.5 0 010 .708l-3 3a.5.5 0 01-.708-.708L19.293 18H4.707zM3 5.5A2.5 2.5 0 015.5 3h13A2.5 2.5 0 0121 5.5v3a2.5 2.5 0 01-2.5 2.5h-13A2.5 2.5 0 013 8.5v-3zm1 0v3A1.5 1.5 0 005.5 10h13A1.5 1.5 0 0020 8.5v-3A1.5 1.5 0 0018.5 4h-13A1.5 1.5 0 004 5.5z"/>
                </ToolbarItem_G>
            </Svg>
        );
    },
    Tabs: (props) => {
        return (
            <Svg width={props.small ? 16 : 24} viewBox="0 0 24 24">
                <ToolbarItem_G {...props} selected={props.selected} >
                    <path stroke='none' d="M4.5 5A1.5 1.5 0 003 6.5v11A1.5 1.5 0 004.5 19h15a1.5 1.5 0 001.5-1.5v-8A1.5 1.5 0 0019.5 8H16a2 2 0 01-2-2 1 1 0 00-1-1H4.5zm0-1H13a2 2 0 012 2 1 1 0 001 1h3.5A2.5 2.5 0 0122 9.5v8a2.5 2.5 0 01-2.5 2.5h-15A2.5 2.5 0 012 17.5v-11A2.5 2.5 0 014.5 4z"/>
                    <path fil='none' d="M2.5 7.5h14M8.5 5v2.5"/>
                </ToolbarItem_G>
            </Svg>
        );
    },
    Accordion: (props) => {
        return (
            <Svg width={props.small ? 16 : 24} viewBox="0 0 24 24">
                <ToolbarItem_G {...props} selected={props.selected} noStroke >
                    <path d="M20 11h-5.05a2.5 2.5 0 0 1-2.45 2h-1a2.5 2.5 0 0 1-2.45-2H4v4h5.5c.28 0 .5.22.5.5 0 .83.67 1.5 1.5 1.5h1c.83 0 1.5-.67 1.5-1.5 0-.28.22-.5.5-.5H20v-4zm0-1V5.5c0-.83-.67-1.5-1.5-1.5h-13C4.67 4 4 4.67 4 5.5V10h5.5c.28 0 .5.22.5.5 0 .83.67 1.5 1.5 1.5h1c.83 0 1.5-.67 1.5-1.5 0-.28.22-.5.5-.5H20zm0 6h-5.05a2.5 2.5 0 0 1-2.45 2h-1a2.5 2.5 0 0 1-2.45-2H4v2.5c0 .83.67 1.5 1.5 1.5h13c.83 0 1.5-.67 1.5-1.5V16zM5.5 3h13A2.5 2.5 0 0 1 21 5.5v13a2.5 2.5 0 0 1-2.5 2.5h-13A2.5 2.5 0 0 1 3 18.5v-13A2.5 2.5 0 0 1 5.5 3z"/>
                </ToolbarItem_G>
            </Svg>
        );
    },
    Table: (props) => {
        return (
            <Svg width={props.small ? 16 : 24} viewBox="0 0 24 24">
                <ToolbarItem_G {...props} selected={props.selected} noStroke >
                    <path d="M15 14v-4H9v4h6zm0 1H9v4h6v-4zM4 14h4v-4H4v4zm0 1v2.5A1.5 1.5 0 005.5 19H8v-4H4zm16-1v-4h-4v4h4zm0 1h-4v4h2.5a1.5 1.5 0 001.5-1.5V15zm-5-6V5H9v4h6zM4 9h4V5H5.5A1.5 1.5 0 004 6.5V9zm16 0V6.5A1.5 1.5 0 0018.5 5H16v4h4zM5.5 4h13A2.5 2.5 0 0121 6.5v11a2.5 2.5 0 01-2.5 2.5h-13A2.5 2.5 0 013 17.5v-11A2.5 2.5 0 015.5 4z"/>
                </ToolbarItem_G>
            </Svg>
        );
    },
    Container: (props) => {
        return (
            <Svg width={props.size || (props.small ? 15 : 22)} viewBox="0 0 24 24">
                <ToolbarItem_G {...props} selected={props.selected} noStroke >
                <path d="M18 20H6a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1V6a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1h12a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1v12a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1zm0-1a1 1 0 0 1 1-1V6a1 1 0 0 1-1-1H6a1 1 0 0 1-1 1v12a1 1 0 0 1 1 1h12zM4 4v1h1V4H4zm0 15v1h1v-1H4zM19 4v1h1V4h-1zm0 15v1h1v-1h-1z"/>
                </ToolbarItem_G>
            </Svg>
        );
    },
    Text: (props) => {
        return (
            <Svg width={props.small ? 15 : 22} viewBox="0 0 24 24">
                <ToolbarItem_G {...props} selected={props.selected} noStroke >
                    <path d="M5.15 12h4.7L7.5 5.9 5.15 12zm-.38 1l-1.8 4.68a.5.5 0 0 1-.94-.36l5-13a.5.5 0 0 1 .94 0l5 13a.5.5 0 0 1-.94.36L10.23 13H4.77zm16.19-2.2a.5.5 0 0 1 .04.2v5.5c0 .28.22.5.5.5a.5.5 0 1 1 0 1c-.53 0-1-.28-1.27-.7a2.5 2.5 0 0 1-1.73.7h-2c-1.92 0-3-.86-3-2.5 0-1.7.99-2.22 3.67-2.73l.2-.04c.45-.09.73-.15 1-.22 1.05-.25 1.55-.69 1.62-1.33-.1-1.5-.88-2.18-2.49-2.18-1.76 0-2.5.5-2.5 1.5a.5.5 0 1 1-1 0C14 8.84 15.26 8 17.5 8c2.03 0 3.22.97 3.46 2.8zM20 15.5v-2.6c-.37.25-.83.45-1.38.59l-1.05.22-.21.04c-2.21.43-2.86.77-2.86 1.75 0 1.03.59 1.5 2 1.5h2c.83 0 1.5-.67 1.5-1.5z"/>
                </ToolbarItem_G>
            </Svg>
        );
    },
    Input: (props) => {
        return (
            <Svg width={props.small ? 16 : 24} viewBox="0 0 24 24">
                <ToolbarItem_G {...props} selected={props.selected} noStroke >
                    <path d="M7 7v5h1.5a.5.5 0 1 1 0 1h-4a.5.5 0 1 1 0-1H6V7H4v.5a.5.5 0 0 1-1 0v-1c0-.28.22-.5.5-.5h6c.28 0 .5.22.5.5v1a.5.5 0 0 1-1 0V7H7zm5.5 0a.5.5 0 1 1 0-1h6A2.5 2.5 0 0 1 21 8.5v8a2.5 2.5 0 0 1-2.5 2.5h-12A2.5 2.5 0 0 1 4 16.5v-1a.5.5 0 1 1 1 0v1c0 .83.67 1.5 1.5 1.5h12c.83 0 1.5-.67 1.5-1.5v-8c0-.83-.67-1.5-1.5-1.5h-6z"/>
                </ToolbarItem_G>
            </Svg>
        );   
    },
    DateInput: (props) => {
        return (
            <Svg width={props.small ? 16 : 24} viewBox="0 0 24 24">
                <ToolbarItem_G {...props} selected={props.selected} noStroke >
                    <path d="M16 4H8v.5a.5.5 0 0 1-1 0V4H5.5A1.5 1.5 0 0 0 4 5.5V8h16V5.5A1.5 1.5 0 0 0 18.5 4H17v.5a.5.5 0 1 1-1 0V4zm1-1h1.5A2.5 2.5 0 0 1 21 5.5v13.007a2.5 2.5 0 0 1-2.5 2.5h-13a2.5 2.5 0 0 1-2.5-2.5V5.5A2.5 2.5 0 0 1 5.5 3H7v-.5a.5.5 0 0 1 1 0V3h8v-.5a.5.5 0 1 1 1 0V3zm3 6H4v9.507a1.5 1.5 0 0 0 1.5 1.5h13a1.5 1.5 0 0 0 1.5-1.5V9z"/>
                </ToolbarItem_G>
            </Svg>
        );   
    },
    Textarea: (props) => {
        return (
            <Svg width={props.small ? 16 : 24} viewBox="0 0 24 24">
                <ToolbarItem_G {...props} selected={props.selected} noStroke >
                <path d="M18 4a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1v12a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1H6a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1V6a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1h12zm0 1H6a1 1 0 0 1-1 1v12a1 1 0 0 1 1 1h12a1 1 0 0 1 1-1V6a1 1 0 0 1-1-1zm-8 3v3.5a.5.5 0 1 1-1 0V8H7.5a.5.5 0 0 1 0-1h4a.5.5 0 1 1 0 1H10zM4 4v1h1V4H4zm15 0v1h1V4h-1zm0 15v1h1v-1h-1zM4 19v1h1v-1H4zm9.5-11a.5.5 0 1 1 0-1h3a.5.5 0 1 1 0 1h-3zm0 3a.5.5 0 1 1 0-1h3a.5.5 0 1 1 0 1h-3zm-6 3a.5.5 0 1 1 0-1h9a.5.5 0 1 1 0 1h-9zm0 3a.5.5 0 1 1 0-1h9a.5.5 0 1 1 0 1h-9z"/>
                </ToolbarItem_G>
            </Svg>
        );   
    },
    ExternalContent: (props) => {
        return (
            <Svg width={props.small ? 14 : 22} viewBox="0 0 24 24">
                <ToolbarItem_G {...props} selected={props.selected} noStroke >
                    <path d="M18 6h2.5a.5.5 0 1 1 0 1H18v2.5a.5.5 0 1 1-1 0V7h-2.5a.5.5 0 1 1 0-1H17V3.5a.5.5 0 1 1 1 0V6zm2 5.5a.5.5 0 1 1 1 0v7a2.5 2.5 0 0 1-2.5 2.5h-13A2.5 2.5 0 0 1 3 18.5v-13A2.5 2.5 0 0 1 5.5 3h7a.5.5 0 1 1 0 1h-7C4.67 4 4 4.67 4 5.5v13c0 .83.67 1.5 1.5 1.5h13c.83 0 1.5-.67 1.5-1.5v-7z"/>
                </ToolbarItem_G>
            </Svg>
        );
    },
    Component : (props) => {
        return (
            <Svg width={props.medium ? 16 : (props.small ? 14 : 24)} viewBox="0 0 20 20">
                <ToolbarItem_G {...props} selected={props.selected} noStroke transform="translate(-420 -166)">
                    <path d="M5,2.5a.5.5,0,0,1,1,0v1a.5.5,0,0,1-1,0ZM16.5,18a.5.5,0,0,1,0,1h-9A2.5,2.5,0,0,1,5,16.5v-9a.5.5,0,0,1,1,0v9A1.5,1.5,0,0,0,7.5,18Zm4,1a.5.5,0,0,1,0-1h1a.5.5,0,0,1,0,1ZM2.5,6a.5.5,0,0,1,0-1h14A2.5,2.5,0,0,1,19,7.5v14a.5.5,0,0,1-1,0V7.5A1.5,1.5,0,0,0,16.5,6Z" transform="translate(418 164)"/>
                    <circle data-name="Ellipse 11" cx="3.5" cy="3.5" r="3.5" transform="translate(426.5 172.5)" fill={CurrentTheme.theme.color_brand}/>
                </ToolbarItem_G>
            </Svg>            
        )
    },
    Component_Convert : (props) => {
        return (
            <Svg width={props.size || 20} stroke="none" viewBox="0 0 20 20">
                <path d="M3 .5a.5.5 0 0 1 1 0v1a.5.5 0 0 1-1 0zM14.5 16a.5.5 0 0 1 0 1h-9A2.5 2.5 0 0 1 3 14.5v-9a.5.5 0 0 1 1 0v9A1.5 1.5 0 0 0 5.5 16zm4 1a.5.5 0 0 1 0-1h1a.5.5 0 0 1 0 1zM.5 4a.5.5 0 0 1 0-1h14A2.5 2.5 0 0 1 17 5.5v14a.5.5 0 0 1-1 0v-14A1.5 1.5 0 0 0 14.5 4z"/>
                <path d="M10.37 9.63h2.91a.42.42 0 0 1 0 .84h-2.91v2.91a.42.42 0 0 1-.84 0v-2.91H6.62a.42.42 0 0 1 0-.84h2.91V6.72a.42.42 0 0 1 .84 0z"/>
            </Svg>     
        )
    },
    Layout: (props) => {
        return (
            <Svg width={props.size || (props.small ? 15 : 22)} viewBox="0 0 24 24">
                <ToolbarItem_G {...props} selected={props.selected} noStroke >
                    <path d="M20 9H9v11h9.5c.83 0 1.5-.67 1.5-1.5V9zm0-1V5.5c0-.83-.67-1.5-1.5-1.5h-13C4.67 4 4 4.67 4 5.5V8h16zM4 9v9.5c0 .83.67 1.5 1.5 1.5H8V9H4zM3 5.5A2.5 2.5 0 0 1 5.5 3h13A2.5 2.5 0 0 1 21 5.5v13a2.5 2.5 0 0 1-2.5 2.5h-13A2.5 2.5 0 0 1 3 18.5v-13z"/>
                </ToolbarItem_G>
            </Svg>
        );
    },
    Image: (props) => {
        return (
            <Svg width={props.size || (props.small ? 15 : 22)} viewBox="0 0 24 24" style={props.style}>
                <ToolbarItem_G {...props} selected={props.selected} noStroke >
                    <path d="M2 6.5A2.5 2.5 0 0 1 4.5 4h15A2.5 2.5 0 0 1 22 6.5v11a2.5 2.5 0 0 1-2.5 2.5h-15A2.5 2.5 0 0 1 2 17.5v-11zm1 0v11c0 .83.67 1.5 1.5 1.5h15c.83 0 1.5-.67 1.5-1.5v-11c0-.83-.67-1.5-1.5-1.5h-15C3.67 5 3 5.67 3 6.5zM16 7h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1zm0 1v2h2V8h-2zM2.85 15.85a.5.5 0 0 1-.7-.7l5-5c.2-.2.5-.2.7 0l5.65 5.64 2.65-2.64c.2-.2.5-.2.7 0l4.5 4.5a.5.5 0 0 1-.7.7l-4.15-4.14-2.65 2.64a.5.5 0 0 1-.7 0L7.5 11.21l-4.65 4.64z"/>
                </ToolbarItem_G>
            </Svg>
        );
    },
    Animation: (props) => {
        return (
            <Svg width={props.size || (props.small ? 15 : 22)} viewBox="0 0 24 24" style={props.style}>
                <ToolbarItem_G {...props} selected={props.selected} noStroke >
                    <path  d="M4,2H14V4H4V14H2V4C2,2.89 2.89,2 4,2M8,6H18V8H8V18H6V8C6,6.89 6.89,6 8,6M12,10H20C21.11,10 22,10.89 22,12V20C22,21.11 21.11,22 20,22H12C10.89,22 10,21.11 10,20V12C10,10.89 10.89,10 12,10M14,12V20L20,16L14,12Z" />
                </ToolbarItem_G>
            </Svg>
        );
    },
    Icon: (props) => {
        return (
            <Svg width={props.small ? 16 : 22} viewBox="0 0 24 24">
                <ToolbarItem_G {...props} selected={props.selected} noStroke >
                    <path d="M3.32 14.38a9 9 0 0 0 16.47 2.12l-3.29-3.3-2.65 2.65a.5.5 0 0 1-.7 0L7.5 10.21l-4.15 4.14a.5.5 0 0 1-.03.03zm-.24-1.17l4.07-4.06c.2-.2.5-.2.7 0l5.65 5.64 2.65-2.64c.2-.2.5-.2.7 0l3.42 3.41a9 9 0 1 0-17.19-2.35zM12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm3-16h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1zm0 1v2h2V7h-2z"/>
                </ToolbarItem_G>
            </Svg>
        );
    },
    Repeater: (props) => {
        return (
            <Svg width={props.small ? 15 : 22} viewBox="0 0 24 24">
                <ToolbarItem_G {...props} selected={props.selected} noStroke >
                    <path d="M20 11h-5.05a2.5 2.5 0 0 1-2.45 2h-1a2.5 2.5 0 0 1-2.45-2H4v4h5.5c.28 0 .5.22.5.5 0 .83.67 1.5 1.5 1.5h1c.83 0 1.5-.67 1.5-1.5 0-.28.22-.5.5-.5H20v-4zm0-1V5.5c0-.83-.67-1.5-1.5-1.5h-13C4.67 4 4 4.67 4 5.5V10h5.5c.28 0 .5.22.5.5 0 .83.67 1.5 1.5 1.5h1c.83 0 1.5-.67 1.5-1.5 0-.28.22-.5.5-.5H20zm0 6h-5.05a2.5 2.5 0 0 1-2.45 2h-1a2.5 2.5 0 0 1-2.45-2H4v2.5c0 .83.67 1.5 1.5 1.5h13c.83 0 1.5-.67 1.5-1.5V16zM5.5 3h13A2.5 2.5 0 0 1 21 5.5v13a2.5 2.5 0 0 1-2.5 2.5h-13A2.5 2.5 0 0 1 3 18.5v-13A2.5 2.5 0 0 1 5.5 3z"/>
                </ToolbarItem_G>
            </Svg>
        );
    },
    Widget: (props) => {    
        return (
            <Svg width={props.size || (props.small ? 16 : 22)} viewBox="0 0 24 24">
                <ToolbarItem_G {...props} selected={props.selected} noStroke >
                    <path d="M16.17 6c-.1 0-.17.07-.17.17v1.66c0 .1.07.17.17.17h1.66c.1 0 .17-.07.17-.17V6.17c0-.1-.07-.17-.17-.17h-1.66zM16 18v-.5a.5.5 0 1 1 1 0v.5h1v-.5a.5.5 0 1 1 1 0v1a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-1a.5.5 0 1 1 1 0v.5h1zm-3-7h.5a.5.5 0 1 1 0 1h-2a.5.5 0 1 1 0-1h.5v-1h-1.5a.5.5 0 1 1 0-1h3a.5.5 0 1 1 0 1H13v1zm5 1h-.5a.5.5 0 1 1 0-1h.5v-.5a.5.5 0 1 1 1 0v2a.5.5 0 1 1-1 0V12zm-5 2h-.5a.5.5 0 1 1 0-1h1c.28 0 .5.22.5.5v2a.5.5 0 0 1-.5.5h-3a.5.5 0 1 1 0-1H13v-1zm3.17-9h1.66c.65 0 1.17.52 1.17 1.17v1.66C19 8.48 18.48 9 17.83 9h-1.66C15.52 9 15 8.48 15 7.83V6.17c0-.65.52-1.17 1.17-1.17zm-10 0h1.66C8.48 5 9 5.52 9 6.17v1.66C9 8.48 8.48 9 7.83 9H6.17C5.52 9 5 8.48 5 7.83V6.17C5 5.52 5.52 5 6.17 5zm0 1c-.1 0-.17.07-.17.17v1.66c0 .1.07.17.17.17h1.66c.1 0 .17-.07.17-.17V6.17c0-.1-.07-.17-.17-.17H6.17zm0 9h1.66c.65 0 1.17.52 1.17 1.17v1.66C9 18.48 8.48 19 7.83 19H6.17C5.52 19 5 18.48 5 17.83v-1.66c0-.65.52-1.17 1.17-1.17zm0 1c-.1 0-.17.07-.17.17v1.66c0 .1.07.17.17.17h1.66c.1 0 .17-.07.17-.17v-1.66c0-.1-.07-.17-.17-.17H6.17zM13 6h-2.5a.5.5 0 1 1 0-1h3c.28 0 .5.22.5.5v2a.5.5 0 1 1-1 0V6zm-2.5 2a.5.5 0 1 1 0-1h1a.5.5 0 1 1 0 1h-1zm-5 6a.5.5 0 1 1 0-1h2a.5.5 0 1 1 0 1h-2zm4 0a.5.5 0 1 1 0-1h1a.5.5 0 1 1 0 1h-1zm1.5 4v.5a.5.5 0 1 1-1 0v-1c0-.28.22-.5.5-.5h2a.5.5 0 1 1 0 1H11zm-2-7h.5a.5.5 0 1 1 0 1h-1a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 1 1 0-1h1c.28 0 .5.22.5.5v.5zm-4-.5a.5.5 0 1 1 1 0v1a.5.5 0 1 1-1 0v-1zm10 0a.5.5 0 1 1 1 0v2a.5.5 0 1 1-1 0v-2zm2 4.5v-.5c0-.28.22-.5.5-.5h1a.5.5 0 1 1 0 1H18v.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1a.5.5 0 1 1 1 0v.5h1zM3 6.5a.5.5 0 0 1-1 0v-2A2.5 2.5 0 0 1 4.5 2h2a.5.5 0 0 1 0 1h-2C3.67 3 3 3.67 3 4.5v2zM17.5 3a.5.5 0 1 1 0-1h2A2.5 2.5 0 0 1 22 4.5v2a.5.5 0 1 1-1 0v-2c0-.83-.67-1.5-1.5-1.5h-2zm-11 18a.5.5 0 1 1 0 1h-2A2.5 2.5 0 0 1 2 19.5v-2a.5.5 0 1 1 1 0v2c0 .83.67 1.5 1.5 1.5h2zM21 17.5a.5.5 0 1 1 1 0v2a2.5 2.5 0 0 1-2.5 2.5h-2a.5.5 0 1 1 0-1h2c.83 0 1.5-.67 1.5-1.5v-2z"/>
                </ToolbarItem_G>
            </Svg>
        );
    },
    Data: (props) => {    
        return (
            <Svg width={props.size || (props.small ? 15 : 20)} viewBox="0 0 48 48">
                <path d="M24 20c-11.215 0-20-3.953-20-9s8.785-9 20-9 20 3.953 20 9-8.785 9-20 9zm0-16C15.486 4 6 6.875 6 11s9.486 7 18 7 18-2.875 18-7-9.486-7-18-7z"/>
                <path d="M24 28c-11.215 0-20-3.953-20-9v-8a1 1 0 1 1 2 0v8c0 4.125 9.486 7 18 7s18-2.875 18-7v-8a1 1 0 1 1 2 0v8c0 5.047-8.785 9-20 9z"/>
                <path d="M24 37c-11.215 0-20-3.953-20-9v-9a1 1 0 1 1 2 0v9c0 4.125 9.486 7 18 7s18-2.875 18-7v-9a1 1 0 1 1 2 0v9c0 5.047-8.785 9-20 9z"/>
                <path d="M24 46c-11.215 0-20-3.953-20-9v-9a1 1 0 1 1 2 0v9c0 4.125 9.486 7 18 7s18-2.875 18-7v-9a1 1 0 1 1 2 0v9c0 5.047-8.785 9-20 9z"/>
            </Svg>            
        )
    },
    Token : (props) => {    
        return (
            <Svg width={props.size || (props.small ? 14 : 20)} viewBox="0 0 28 28">
                <path d="M.672.112c-.313 0-.56.247-.56.56v20.173c0 2.78 2.264 5.043 5.043 5.043 2.78 0 5.043-2.264 5.043-5.043V.672c0-.313-.246-.56-.56-.56H.672zm.56 1.12H9.08v4.035H1.233V1.233zM16.23 2.668l-4.685 4.685v1.59L16.23 4.26l5.536 5.535-10.22 10.243v.807c0 .27-.023.56-.046.83l4.707-4.708h8.585v7.845H10.22v-.045c-.358.426-.762.83-1.21 1.166h16.878V15.846h-8.585l6.052-6.05-7.127-7.13zM1.233 6.388h7.845v4.26H1.233v-4.26zm0 5.38h7.845V15.8H1.233v-4.035zm0 5.154h7.845v3.923c0 2.152-1.77 3.922-3.923 3.922-2.152 0-3.922-1.77-3.922-3.922v-3.923zm3.922 2.286c-.92 0-1.68.763-1.68 1.68 0 .92.76 1.683 1.68 1.683.92 0 1.68-.76 1.68-1.68 0-.92-.76-1.68-1.68-1.68zm0 1.12c.314 0 .56.248.56.56 0 .315-.246.562-.56.562-.314 0-.56-.247-.56-.56 0-.314.246-.56.56-.56z" />
            </Svg>
        )
    },
    Layers : (props) => {    
        return (
            <Svg width={props.size || (props.small ? 14 : 20)} viewBox="0 0 24 24">
                <path d="M12,13.934976 L20.4264545,9.5 L12,5.06502396 L3.57354553,9.5 L12,13.934976 Z M2.26712678,9.05754089 L11.7671268,4.05754089 C11.912897,3.9808197 12.087103,3.9808197 12.2328732,4.05754089 L21.7328732,9.05754089 C22.0890423,9.24499828 22.0890423,9.75500172 21.7328732,9.94245911 L12.2328732,14.9424591 C12.087103,15.0191803 11.912897,15.0191803 11.7671268,14.9424591 L2.26712678,9.94245911 C1.91095774,9.75500172 1.91095774,9.24499828 2.26712678,9.05754089 Z M12,16.934976 L21.2671268,12.0575409 C21.5114902,11.9289286 21.8138468,12.0227634 21.9424591,12.2671268 C22.0710714,12.5114902 21.9772366,12.8138468 21.7328732,12.9424591 L12.2328732,17.9424591 C12.087103,18.0191803 11.912897,18.0191803 11.7671268,17.9424591 L2.26712678,12.9424591 C2.02276336,12.8138468 1.92892856,12.5114902 2.05754089,12.2671268 C2.18615321,12.0227634 2.4885098,11.9289286 2.73287322,12.0575409 L12,16.934976 Z M12,19.934976 L21.2671268,15.0575409 C21.5114902,14.9289286 21.8138468,15.0227634 21.9424591,15.2671268 C22.0710714,15.5114902 21.9772366,15.8138468 21.7328732,15.9424591 L12.2328732,20.9424591 C12.087103,21.0191803 11.912897,21.0191803 11.7671268,20.9424591 L2.26712678,15.9424591 C2.02276336,15.8138468 1.92892856,15.5114902 2.05754089,15.2671268 C2.18615321,15.0227634 2.4885098,14.9289286 2.73287322,15.0575409 L12,19.934976 Z" />
            </Svg>
        )
    },
    TokenValue : (props) => {    
        return (
            <Svg width={props.size || (props.small ? 14 : 26)} viewBox="0 0 24 24">
                <path d="M9.9 5.21a2.9 2.9 0 0 1 3.16.63l4.1 4.1a2.9 2.9 0 0 1 .3 3.75.5.5 0 0 1-.16.22l-.14.14-5.1 5.1a2.9 2.9 0 0 1-4.1 0l-4.11-4.1c-.36-.36-.6-.78-.73-1.24a.5.5 0 0 1-.1-.44 2.9 2.9 0 0 1 .83-2.42l.16-.16V6a3 3 0 0 1 5.9-.79zm-.9.6A2 2 0 0 0 5 6v3.8l3.96-3.96L9 5.8zm1 .47v4.22a.5.5 0 0 1-1 0V7.2l-4.44 4.46A1.9 1.9 0 0 0 4 13h12.73a1.9 1.9 0 0 0-.27-2.34l-4.11-4.1A1.9 1.9 0 0 0 10 6.27zM15.8 14H4.28c.08.12.17.24.28.34l4.1 4.1a1.9 1.9 0 0 0 2.69 0L15.8 14zm.93 2.62l1.42-1.47c.2-.2.52-.2.72 0l1.41 1.47c.97 1 .97 2.62 0 3.62a2.45 2.45 0 0 1-3.55 0c-.97-1-.97-2.62 0-3.62zm.72.7c-.6.6-.6 1.61 0 2.23.59.6 1.53.6 2.11 0 .6-.62.6-1.62 0-2.24l-1.05-1.09-1.06 1.1z"/>
            </Svg>
        )
    },
    Variable : (props) => {    
        return (
            <Svg width={props.size || (props.small ? 14 : 26)} viewBox="0 0 24 24">
                <ToolbarItem_G {...props} selected={props.selected} noStroke >
                    <path d="M9.9 5.21a2.9 2.9 0 0 1 3.16.63l4.1 4.1a2.9 2.9 0 0 1 .3 3.75.5.5 0 0 1-.16.22l-.14.14-5.1 5.1a2.9 2.9 0 0 1-4.1 0l-4.11-4.1c-.36-.36-.6-.78-.73-1.24a.5.5 0 0 1-.1-.44 2.9 2.9 0 0 1 .83-2.42l.16-.16V6a3 3 0 0 1 5.9-.79zm-.9.6A2 2 0 0 0 5 6v3.8l3.96-3.96L9 5.8zm1 .47v4.22a.5.5 0 0 1-1 0V7.2l-4.44 4.46A1.9 1.9 0 0 0 4 13h12.73a1.9 1.9 0 0 0-.27-2.34l-4.11-4.1A1.9 1.9 0 0 0 10 6.27zM15.8 14H4.28c.08.12.17.24.28.34l4.1 4.1a1.9 1.9 0 0 0 2.69 0L15.8 14zm.93 2.62l1.42-1.47c.2-.2.52-.2.72 0l1.41 1.47c.97 1 .97 2.62 0 3.62a2.45 2.45 0 0 1-3.55 0c-.97-1-.97-2.62 0-3.62zm.72.7c-.6.6-.6 1.61 0 2.23.59.6 1.53.6 2.11 0 .6-.62.6-1.62 0-2.24l-1.05-1.09-1.06 1.1z"/>
                </ToolbarItem_G>
            </Svg>
        )
    },
    Video : ({size}) => {
        return (
            <Svg  width={size || 30} viewBox='0 0 24 24'>
                <path fill='#707070' d="M21,9 L19,9 L19,11 L21,11 L21,9 Z M21,8 L21,7.5 C21,6.67157288 20.3284271,6 19.5,6 L19,6 L19,8 L21,8 Z M21,14 L21,12 L19,12 L19,14 L21,14 Z M21,15 L19,15 L19,17 L19.5,17 C20.3284271,17 21,16.3284271 21,15.5 L21,15 Z M3,14 L5,14 L5,12 L3,12 L3,14 Z M3,15 L3,15.5 C3,16.3284271 3.67157288,17 4.5,17 L5,17 L5,15 L3,15 Z M3,9 L3,11 L5,11 L5,9 L3,9 Z M3,8 L5,8 L5,6 L4.5,6 C3.67157288,6 3,6.67157288 3,7.5 L3,8 Z M18,6 L6,6 L6,17 L18,17 L18,6 Z M4.5,5 L19.5,5 C20.8807119,5 22,6.11928813 22,7.5 L22,15.5 C22,16.8807119 20.8807119,18 19.5,18 L4.5,18 C3.11928813,18 2,16.8807119 2,15.5 L2,7.5 C2,6.11928813 3.11928813,5 4.5,5 Z M9,8.5 C9,8.11135276 9.42398562,7.87129618 9.75724788,8.07125354 L14.7572479,11.0712535 C15.0809174,11.2654552 15.0809174,11.7345448 14.7572479,11.9287465 L9.75724788,14.9287465 C9.42398562,15.1287038 9,14.8886472 9,14.5 L9,8.5 Z M10,13.6169048 L13.5281747,11.5 L10,9.38309519 L10,13.6169048 Z"/>
            </Svg>
        )
    },
    Lottie : ({size, ...props}) => {
        return (
            <Svg width={size || 24} viewBox='0 0 24 24'>
                <ToolbarItem_G {...props} selected={props.selected} noStroke >
                    <path  d="M4,2H14V4H4V14H2V4C2,2.89 2.89,2 4,2M8,6H18V8H8V18H6V8C6,6.89 6.89,6 8,6M12,10H20C21.11,10 22,10.89 22,12V20C22,21.11 21.11,22 20,22H12C10.89,22 10,21.11 10,20V12C10,10.89 10.89,10 12,10M14,12V20L20,16L14,12Z" />
                 </ToolbarItem_G>
            </Svg>
        )
    },
    TwoColumnRow : ({size}) => {
        return (
            <Svg width={size || 24} viewBox='0 0 24 24'>
                <path  d="M12 4H5.5A1.5 1.5 0 004 5.5v13A1.5 1.5 0 005.5 20H12V4zm1 0v16h5.5a1.5 1.5 0 001.5-1.5v-13A1.5 1.5 0 0018.5 4H13zM3 5.5A2.5 2.5 0 015.5 3h13A2.5 2.5 0 0121 5.5v13a2.5 2.5 0 01-2.5 2.5h-13A2.5 2.5 0 013 18.5v-13z"/>
            </Svg>
        )
    },
    Web : ({size}) => {
        return (
            <Svg width={size || 24} viewBox='0 0 24 24'>
                 <path d="M3.512 15h4.66A25.567 25.567 0 018 12c0-1.044.06-2.052.172-3h-4.66A8.985 8.985 0 003 12c0 1.052.18 2.062.512 3zm.424 1a9.017 9.017 0 006.092 4.783c-.78-1.06-1.376-2.746-1.714-4.783H3.936zm16.552-1A8.985 8.985 0 0021 12c0-1.052-.18-2.062-.512-3h-4.66c.113.948.172 1.956.172 3s-.06 2.052-.172 3h4.66zm-.424 1h-4.378c-.338 2.037-.935 3.723-1.714 4.783A9.017 9.017 0 0020.064 16zm-10.88-1h5.632c.118-.938.184-1.947.184-3s-.066-2.062-.184-3H9.184A24.046 24.046 0 009 12c0 1.053.066 2.062.184 3zm.151 1c.522 2.968 1.583 5 2.665 5s2.143-2.032 2.665-5h-5.33zm-5.4-8h4.379c.338-2.037.935-3.723 1.714-4.783A9.017 9.017 0 003.936 8zm16.13 0a9.017 9.017 0 00-6.093-4.783c.78 1.06 1.376 2.746 1.714 4.783h4.378zM9.334 8h5.33C14.143 5.032 13.082 3 12 3S9.857 5.032 9.335 8zM12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10z"/>
            </Svg>
        )
    },
    VariableIcon : (props) => {
        return (
            <SC.Icons.Icon_Button hasFill hasStroke fill={props.fill} stroke={props.stroke}>
                <SC.Svg width={props.size || 16} viewBox="0 0 19 19">                    
                    <g fill={props.selected ? SC.CurrentTheme.theme.color_brand : props.circleFill} stroke={props.selected ? 'none' : props.circleStroke} transform="translate(5.54 5.3)" >
                        <circle cx="4" cy="4" r="4" stroke="none"/>
                        <circle cx="4" cy="4" r="3.5"  />
                        <circle cx="3.9" cy="5" r="1" stroke='none'  ref={props.leaderRef} />
                    </g>
                    <path fill='none' d="M11.5.5h5.75l1.25 1.42V7.5M18.5 11.5v5.75l-1.42 1.25H11.5M7.54 18.5H1.79L.54 17.08V11.5M.5 7.5V1.75L1.92.5H7.5"/>
                </SC.Svg>
            </SC.Icons.Icon_Button>        
        );
    }
};