import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Strings,
    AppLayout,
    Events,
    Globals,
    MetaData
} from '../../../../../../../importer';
import { ItemNameEditor, GroupTitle, TokenItemBox, TokenGalleryView, DropFileArea, DropAreaContainer, TokenPanelHeader } from '../../common';
import Switch from '../../../../../../../components/editors/Switch';
import TextValue from '../../../../../../../components/editors/textvalue';
import NumberInput  from '../../../../../../../components/editors/input_number';

import { StyleToken_Color } from '../../../../right/iteminspector/styleitems/common';
import { StyleTokenBorderStyle } from '../../../../right/iteminspector/styleitems/borders';
import { StyleTokenShadowItem } from '../../../../right/iteminspector/styleitems/shadow';
import { BorderRadiusTokenItem, GetBorderRadiusTokenValue } from '../../borders';
import { SpacePattern, GetSpacePatternItem } from '../../spacing/patterns';
import { findLastKey } from 'lodash';
import { GetTextPatternTokenItem, TextPattern } from '../../typography/patterns';
import { FontSelectItem } from '../../typography/fonts';

export default class CustomTokenItemEditor extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.SaveName = this.SaveName.bind(this);
        this.GetItems = this.GetItems.bind(this);

        this.Ref_Name = React.createRef();    

        this.AddField = this.AddField.bind(this);
        this.onSortField = this.onSortField.bind(this);
        this.SaveFields = this.SaveFields.bind(this);        

        this.tokenTypeModel = Globals.ProjectManager.Tokens.Token(this.props.tokenType);
        this.tokenFields = [];

        Utils.ForEach(this.tokenTypeModel.fieldIds, (fieldId, i) => {
            const field = Utils.JustGet(this.tokenTypeModel, null, 'fields', fieldId);
            if (field) {
                this.tokenFields.push({
                    id : fieldId,
                    model : field
                });
            }            
        });

        this.Load(this.props);        

    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.GlobalState !== nextProps.GlobalState || this.props.GlobalStateId !== nextProps.GlobalStateId || this.props.id !== nextProps.id) {
            if (!nextProps.newModel) {
                this.Load(nextProps);
                if (!this.token) {
                    this.props.onCancelAddToken();
                    return false;
                }
            }            
        }
        return true;
    }
    GetItems() {
        return Globals.ProjectManager.Tokens.TokenList(Globals.ProjectManager.Tokens.Types.CustomTokenTypes);
    }        
    Load(props = this.props) {
        if (props.newModel) {
            this.token = props.newModel;
        }
        else {
            this.token = Globals.ProjectManager.Tokens.Token(props.id);
        }               
    }
    onSortField(oldIndex, newIndex) {
        Utils.ChangePlace(this.token.fieldIds, oldIndex, newIndex);
        Utils.ChangePlace(this.fields, oldIndex, newIndex);
        Globals.ProjectManager.Tokens.UpdateProp({id : this.props.id, name : 'fieldIds', value : this.token.fieldIds});
        this.RCUpdate();
    }
    onEditField(id) {

    }
    onDeleteField(id) {

    }
    AddField() {
        const id = Utils.Id();

        const model = {
            name : 'New Field',
            dataType : MetaData.DataTypes.string
        }
        this.fields.push({
            id : id,
            model : model
        });
        const fieldIds = Utils.Get(this.token, [], 'fieldIds');
        fieldIds.push(id);
        Utils.Set(this.token, model, 'fields', id);
        this.SaveFields();
        this.FillFocusToField = id;
        this.RCUpdate();
    }
    SaveFields() {
        Globals.ProjectManager.Tokens.UpdateProp({id : this.props.id, name : 'fields', value : this.token.fields});
    }
    SaveName(name) {
        if (this.props.newModel) {
            this.props.newModel.name = name;
            this.nameChanged = true;
        }
        else
            Globals.ProjectManager.Tokens.ChangeTokenName(this.props.id, name);
        this.RCUpdate();
    }    
    SaveValue() {
        if (this.props.newModel) {
            this.props.newModel.value = this.tokenvalue;       
        }
        else {            
            Globals.ProjectManager.Tokens.SetValue({
                id : this.props.id,
                value : this.tokenvalue
            })
        }        
    }        
    renderCustom() {        


        const fieldItems = [];
        
        Utils.ForEach(this.tokenFields, (field, i) => {                               
            fieldItems.push(
                <CustomTokenFieldValueEditor
                    key={field.id}
                    fieldId={field.id}
                    fieldType={field.model}
                    tokenId={this.props.id}
                    token={this.token}
                    tokenType={this.props.tokenType}
                    isNew={this.props.newModel ? true : false}
                    RefToolbar={this.props.RefToolbar}
                />
            )
        });
        
        return (
            <SC.FCol fw fh style={{backgroundColor : SC.CurrentTheme.theme.back, padding : '10px', boxSizing : 'border-box'}}>
                <ItemNameEditor
                    noMargin
                    fontSize='12px'                        
                    name={this.token.name}
                    onSaveName={this.SaveName}
                    onGetItems={this.GetItems}
                    model={this.token}
                />                
                {fieldItems}
            </SC.FCol>
        )
    }
}


class CustomTokenFieldValueEditor extends ReactBaseComponent {
    constructor(props) {
        super(props);
        this.state = {  }

        this.SetFieldValue = this.SetFieldValue.bind(this);        
        this.SelectToken = this.SelectToken.bind(this);
    }
    SetFieldValue(value) {
        if (!this.props.isNew) {
            Globals.ProjectManager.Tokens.SetValue({
                id : this.props.tokenId, 
                value : value, 
                name : this.props.fieldId, 
                type : this.props.tokenType, 
            });
        }      
        else {
            Globals.ProjectManager.Tokens.SetValueOf({
                model : this.props.token, 
                value : value, 
                name : this.props.fieldId, 
            });
        }  
        this.RCUpdate();
    }
    SelectToken() {
        let onClosed;
        const tokenId = Globals.ProjectManager.Tokens.ValueOf({model : this.props.token, name : this.props.fieldId});
        const onPreview = ({show, tokenId}) => {
                    
        };
        const onSelect = (tokenId) => {
            this.SetFieldValue(tokenId);            
        };

        if (this.props.onSelectColor) {
            this.props.onSelectColor(onSelect, onPreview, onClosed);
        }
        else if (this.props.RefToolbar && this.props.RefToolbar.current) {
            const props = {
                title : 'SELECT TOKEN',        
                forAliase : true        
            };
            if (this.props.fieldType.tokenType === Globals.ProjectManager.Tokens.Types.COLOR) {
                props.type = AppState.ItemTypes.BOARD.COLOR;
                props.title = 'SELECT COLOR TOKEN';
                props.noGradient = false;
                props.isGradients = true;
            }
            else if (this.props.fieldType.tokenType === Globals.ProjectManager.Tokens.Types.Shadows) {
                props.type = AppState.ItemTypes.BOARD.SHADOW;
                props.title = 'SELECT SHADOW TOKEN';
                props.textAndBox = true;
            }
            else if (this.props.fieldType.tokenType === Globals.ProjectManager.Tokens.Types.Borders) {
                props.type = AppState.ItemTypes.BOARD.BORDER_STYLE
                props.title = 'SELECT BORDER TOKEN';
            }
            else if (this.props.fieldType.tokenType === Globals.ProjectManager.Tokens.Types.BorderRadiuses) {
                props.type = AppState.ItemTypes.BOARD.BORDER_RADIUS;
                props.title = 'SELECT RADIUS TOKEN';
            }
            else if (this.props.fieldType.tokenType === Globals.ProjectManager.Tokens.Types.Spacings) {
                props.type = AppState.ItemTypes.BOARD.SPACING;
                props.title = 'SELECT SPACE TOKEN';
            }
            else if (this.props.fieldType.tokenType === Globals.ProjectManager.Tokens.Types.TextPatterns) {
                props.type = AppState.ItemTypes.BOARD.PATTERN_TEXT;
                props.title = 'SELECT TEXT STYLE';
            }
            else if (this.props.fieldType.tokenType === Globals.ProjectManager.Tokens.Types.Fonts) {
                props.type = AppState.ItemTypes.BOARD.FONT;
                props.title = 'SELECT TEXT STYLE';
            }
            else
                return;
            this.props.RefToolbar.current.ShowPanel({
                ...props,
                forAliase : true,
                onSelect : onSelect,
                onPreviewToken : onPreview,
                onClosed : onClosed,
                propName : 'tokenId',
                tokenId : tokenId,
                onRegisterClose : (callClose) => {
                    super.AddCloseCallback(callClose);
                },
                onPreviewNewToken : () => {
                    
                }
            })
        } 
    }
    renderCustom() { 
        const {fieldType, tokenId, token} = this.props;

        let editor, rowEditor;
        const value = Globals.ProjectManager.Tokens.ValueOf({model : this.props.token, name : this.props.fieldId});

        if (fieldType.dataType === MetaData.DataTypes.boolean) {            
            rowEditor = (
                <SC.FRow alc jsb>
                    <SC.TextDivAbbr>{fieldType.name}</SC.TextDivAbbr>
                    <Switch round value={Utils.IsTrue(value)} onChange={this.SetFieldValue} />
                </SC.FRow>
            )
        }
        else if (fieldType.dataType === MetaData.DataTypes.string) {
            editor = (
                <TextValue 
                    multiline 
                    dark
                    value={value} 
                    onChange={this.SetFieldValue} 
                    style={{fontSize : '12px'}}
                />
            )
        }
        else if (fieldType.dataType === MetaData.DataTypes.number) {
            rowEditor = (
                <SC.FRow alc jsb>
                    <SC.TextDivAbbr>{fieldType.name}</SC.TextDivAbbr>
                    <NumberInput 
                        xsmall
                        value={value}
                        onChange={this.SetFieldValue}
                    />
                </SC.FRow>                
            )
        }
        else if (fieldType.dataType === MetaData.DataTypes.token) {
            if (fieldType.tokenType === Globals.ProjectManager.Tokens.Types.COLOR) {
                editor =  (
                    <StyleToken_Color 
                        tokenId={value}
                        onSelect={this.SelectToken} 
                    />
                )
            }            
            else if (fieldType.tokenType === Globals.ProjectManager.Tokens.Types.Shadows) {
                editor =  (
                    <StyleTokenShadowItem 
                        tokenId={value}
                        onSelect={this.SelectToken} 
                    />
                )
            }
            else if (fieldType.tokenType === Globals.ProjectManager.Tokens.Types.Borders) {
                editor =  (
                    <StyleTokenBorderStyle 
                        tokenId={value}
                        onSelectBorderStyle={this.SelectToken} 
                    />
                )
            }
            else if (fieldType.tokenType === Globals.ProjectManager.Tokens.Types.BorderRadiuses) {
                let radiusModel;
                if (value)
                    radiusModel = GetBorderRadiusTokenValue(Globals.ProjectManager.Tokens.Token(value), value);
                if (!radiusModel)
                    radiusModel = {
                        model : {
                            name : 'Select Radius Token'
                        }
                    }
                editor =  (
                    <BorderRadiusTokenItem 
                        isListView
                        radius={radiusModel}
                        tokenId={value}
                        onSelect={this.SelectToken} 
                    />
                )
            }
            else if (fieldType.tokenType === Globals.ProjectManager.Tokens.Types.Spacings) {
                if (value) {
                    const spacePattern = GetSpacePatternItem(value);
                    editor =  (
                        <SpacePattern 
                            pattern={spacePattern.pattern}
                            spaceValue={spacePattern.spaceValue}
                            spaceUnit={spacePattern.spaceUnit}
                            onClick={this.SelectToken} 
                            listView
                        />
                    )
                }
                else {
                    editor = (
                        <TokenItemBox onClick={this.SelectToken}>
                            Select Space Token
                        </TokenItemBox>
                    )
                }
            }
            else if (fieldType.tokenType === Globals.ProjectManager.Tokens.Types.TextPatterns) {
                if (value) {
                    const spacePattern = GetTextPatternTokenItem({id : value});
                    if (spacePattern) {
                        editor =  (
                            <TextPattern 
                                pattern={spacePattern.pattern}
                                onClick={this.SelectToken} 
                                listView
                            />
                        )
                    }                    
                }
                if (!editor) {
                    editor = (
                        <TokenItemBox onClick={this.SelectToken}>
                            Select Space Token
                        </TokenItemBox>
                    )
                }
            }
            else if (fieldType.tokenType === Globals.ProjectManager.Tokens.Types.Fonts) {
                let font;
                if (value) {
                    const token = Globals.ProjectManager.Tokens.Token(value);
                    const info = {};
                    font = Globals.ProjectManager.Tokens.ValueOf({model : token, info : info}) || {};
                }
                editor = (
                    <FontSelectItem 
                        font={font}
                        onClick={this.SelectToken}
                    />
                )
            }
        }

        return (  
            <div style={{
                marginTop : '16px',
                display : 'grid',
                gridGap : '4px',
                gridTemplateColumns : '1fr'
            }}>
                {
                    rowEditor ? rowEditor :
                    <React.Fragment>
                        <div>{fieldType.name}</div>
                        {editor}
                    </React.Fragment>
                }
            </div>
        );
    }
}
