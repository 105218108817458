import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    AppState,
    Events,
    SC,
    Loading,
    Globals,
    Strings,
    Links,
    Utils,
    UIUtils,
    MetaData,
    AppLayout
} from '../../../../../../../importer';

import IconTokenEditor from '../../../../left/designsystem/icons/item';
import { GetIconTokenList, IconsTokenList, AddOrUpdateIconToken, GetIconTokenValueItem } from '../../../../left/designsystem/icons';
import BaseSelectToken from '../BaseSelectToken';

export default class SelectIcontoken extends BaseSelectToken
{
    constructor(props) {
        super(props);

        this.titleNewToken = 'NEW ICON';
        this.moduleName = 'Icons';
        this.tokenType = Globals.ProjectManager.Tokens.Types.Icons;        

        this.Ref_PatternEdit = React.createRef();

        this.Load(this.props);
    }
    IsTokenPropChanged(nextProps, nextState) {
        if (Utils.HasAnyChange(this.props, nextProps, 'GlobalState')) {
            this.Load(nextProps);
            return true;
        }
        return this.props.icon !== nextProps.icon;
    }
    GetTokens(props) {
        return GetIconTokenList(props.StateArray);
    }        
    GetNewModel(Id) {
        const Model =  {
            name : 'New Icon',
            value : {                
            }
        };
        AddOrUpdateIconToken({
            isNew : this.EditingToken.IsNew,
            newId : Id,
            model : Model
        })
        this.FireSelectToken(Id);
        return Model;
    }
    onCloseTokenEditor() {
        if (this.EditingToken && this.EditingToken.IsNew) {
            delete this.EditingToken;            
            this.RCUpdate();
            return;
        }
        super.onCloseTokenEditor();
    }
    onCancelAddToken() {
        const willDeleteId = this.EditingToken.Id;
        this.FireSelectToken(this.LastBoundTokenId);
        delete this.LastBoundTokenId;
        super.onCancelAddToken();        
        Globals.ProjectManager.Tokens.Delete(Globals.ProjectManager.Tokens.Types.Icons, willDeleteId);
        
    }
    SaveToken() {
        if (!this.Ref_PatternEdit.current || !Utils.IsNotNullOrEmpty(this.Ref_PatternEdit.current.tokenvalue.provider)) {
            this.onCancelAddToken();
            return;
        }
        Globals.ProjectManager.Tokens.SetValue({
            id : this.EditingToken.Id,
            value : this.Ref_PatternEdit.current.tokenvalue,
            type : Globals.ProjectManager.Tokens.Types.Icons
        });        
        this.EditingToken.Model.value = this.Ref_PatternEdit.current.tokenvalue;
        Globals.ProjectManager.Tokens.Colors.UpdateProp({id : this.EditingToken.Id, name : 'name', value : this.EditingToken.Model.name} );
        const NewModel = AddOrUpdateIconToken({model : this.EditingToken.Model, newId : this.EditingToken.Id});        
        AppLayout.Refs.DesignSystem.Icons && AppLayout.Refs.DesignSystem.Icons.Reload();
        return NewModel;
    }   
    UpdateSelectedToken() {
        if (this.EditingToken) {
            const SelectedToken = Utils.Find(this.tokens, (token) => {return token.id === this.EditingToken.Id});        
            const token = Globals.ProjectManager.Tokens.Token(this.EditingToken.Id);
            GetIconTokenValueItem(token, SelectedToken, this.props.StateArray);
            AppLayout.Refs.DesignSystem.Icons && AppLayout.Refs.DesignSystem.Icons.Reload();
        }
    }
    GetStyleName() {
        return 'icon';
    }
    PreviewNewToken(show, value) {
        super.PreviewNewToken(show ? value  : null);
    }
    GetSelectedTokenId() {
        return this.props.icon;
    }
    renderTokenList() {
        return (
            <SC.CustomScrollbars hideTracksWhenNotNeeded autoHide>
                <IconsTokenList 
                    isListView={this.state.isListView}
                    icons={this.tokens}
                    style={{padding : 0, paddingLeft : 0, paddingRight : 0}}
                    onSelect={this.SelectToken}
                    onPreview={this.PreviewToken}
                    selectedId={this.props.icon}
                    StateArray={this.props.StateArray}
                />
            </SC.CustomScrollbars> 
        )
    }
    renderTokenEditor() {
        return (
            <IconTokenEditor 
                ref={this.Ref_PatternEdit}
                id={this.EditingToken.Id}
                newModel={this.EditingToken.IsNew ?  this.EditingToken.Model : null}  
                onCancelAddIcon={this.onCancelAddToken}
                onSubmitNewIcon={this.onSubmitNewPattern}
                onPreviewChange={this.PreviewNewToken}
                onToggleGalleryView={() => {
                    this.HideEditorHeader = !this.HideEditorHeader;
                    this.RCUpdate();
                }}
                onRightPanel
                noHeader
            />
        )        
    }    
    renderSelectedToken() {
        if (this.props.icon) {
            return this.renderSelectedTokenHeader();
        }
    }
}