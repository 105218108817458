import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    AppLayout,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../../../../importer';

import DropDownSelect from '../../../../../../components/editors/enum_dropdown';
import { PropertyLabel, Seperator } from '../../../../panels/right/iteminspector/styleitems/common';
import {GroupTitle, EditableName, TokenPanelHeader, TokenItemBox} from '../../../../panels/left/designsystem/common';
import { AnimatePresence, motion } from 'framer-motion';
import {ComponentStateConfigurator} from '../../../../panels/right/iteminspector/styleitems/component/stateConfigurator';
import { GetSubcomponentStateAndModels } from '../../../../panels/right/iteminspector/styleitems/component';
import Switch from '../../../../../../components/editors/Switch';
import ComponentPreviewPropItem from '../../../../panels/right/iteminspector/styleitems/component/previewPropItem';
import ItemThemeOptions from '../themeoptions';

export default class FigmaComponentSettings extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.UpdateItem = this.UpdateItem.bind(this);        
        
        this.Load(this.props.id);
    }
    UpdateItem() {
        this.props.onUpdateItem(this.props.showItemSettings.ref);
    }    
    
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.id !== this.props.id) {
            this.Load(nextProps.id);
        }
        return true;
    }
    Load(itemId) {
        const data = Globals.ProjectManager.GetDocumentManager().GetItem(itemId);
        const id = Utils.JustGet(data.data, null, 'id');
        
        this.Loaded = true;
    }
    renderCustom() {
        if (!this.Loaded) {
            return null;
        }
        let content, header, overlayContent;

        const data = Globals.ProjectManager.GetDocumentManager().GetItem(this.props.id);
        const showStateSelector = Utils.JustGet(data.data, false, 'showStateSelector');
        const showPropEditor = Utils.JustGet(data.data, false, 'showPropEditor');

        header = (
            <TokenPanelHeader 
                title='FIGMA COMPONENT SETTINGS' 
                onClose={this.props.onClose} 
                style={{backgroundColor : SC.CurrentTheme.theme.back_lighter}}
            >                   
            </TokenPanelHeader>
        );


        content = (
            <React.Fragment>                            
               
            </React.Fragment>
        )

        return (
            <SC.FCol f1 style={{backgroundColor : SC.CurrentTheme.theme.back}}>
                {header}
                <SC.CustomScrollbars>         
                    <SC.FCol style={{padding : '8px', paddingBottom : 0}}>
                        <ItemThemeOptions {...this.props} />    
                    </SC.FCol>                    
                    <Seperator inner />           
                    {content}        
                    <AnimatePresence>
                        {overlayContent}
                    </AnimatePresence>              
                </SC.CustomScrollbars>            
            </SC.FCol>
        )
    }
}