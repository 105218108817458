import React from 'react';
import {
    ReactBaseComponent,
    AppState,
    SC,
    Utils,
    Events,
    AppLayout,
    Globals,
    Strings
} from '../../../../../../importer';

import styled from 'styled-components';
import {TokenGroup, TokenItemBox, DraggableTokenItem, TokenPanelHeader, GroupTitle, EditableName, TokenItemDragHandle, TokenItemBoxHoveredName} from '../common';
import { LeftScrollPanel } from '../../common';
import BaseTokenList, { GetGroupedTokenListWithGroups, DraggableGroupHeader } from '../BaseTokenList'
import AliaseEditor from './item';
import { ColorTokenListItem, GetColorTokenItem, StatefulTokenListItemName } from '../colors';
import { ShadowCardItem, GetShadowTokenItem } from '../shadows';
import { BorderStyleTokenItem, GetBorderStyleTokenValue, GetBorderRadiusTokenValue } from '../borders';
import { GetSpacePatternItem, SpacePattern } from '../spacing/patterns';
import { GRANT_TYPES } from '../../../../manager';
import TokenAliaseGroup from './aliaseGroup';

export default class TokenAliases extends BaseTokenList
{
    constructor(props) {
        super(props);
    }
    renderCustom() {
        const props = {
            GlobalState : this.props.GlobalState,
            GlobalStateId : this.props.GlobalStateId,
            themeId : this.props.themeId,
            onPanelOverlay : this.props.onPanelOverlay,
            RefToolbar : this.props.RefToolbar,
            filterText : this.props.filterText,
            onUpdate : this.props.onUpdate
        }
        const content = (
            <React.Fragment>
                <SC.FCol style={{paddingTop : '4px'}}>
                    <TokenAliaseGroup                         
                        type={Globals.ProjectManager.Tokens.Types.COLOR}
                        title='COLORS'
                        onSelectColor={this.props.onSelectColor}        
                        onShowMenu={this.props.onShowMenuColor}
                        {...props}
                    />
                    <TokenAliaseGroup 
                        type={Globals.ProjectManager.Tokens.Types.Shadows}
                        title='SHADOWS'
                        onSelectShadow={this.props.onSelectShadow}
                        onShowMenu={this.props.onShowMenuShadow}
                        {...props}
                    />
                    <TokenAliaseGroup 
                        type={Globals.ProjectManager.Tokens.Types.Borders}
                        title='BORDERS'
                        onSelectBorder={this.props.onSelectBorder}
                        onShowMenu={this.props.onShowMenuBorder}
                        {...props}
                    />
                    <TokenAliaseGroup 
                        type={Globals.ProjectManager.Tokens.Types.BorderRadiuses}
                        title='RADIUSES'
                        onSelectRadius={this.props.onSelectRadius}
                        onShowMenu={this.props.onShowMenuRadius}
                        {...props}
                    />
                    <TokenAliaseGroup 
                        type={Globals.ProjectManager.Tokens.Types.Spacings}
                        title='SIZE & SPACING'
                        onSelectSpace={this.props.onSelectSpace}
                        onShowMenu={this.props.onShowMenuSpace}
                        {...props}
                    />
                    <TokenAliaseGroup 
                        type={Globals.ProjectManager.Tokens.Types.FigmaAutoLayouts}
                        title='AUTO LAYOUTS'
                        onSelectAutoLayout={this.props.onSelectAutoLayout}
                        onShowMenu={this.props.onShowMenuAutoLayout}
                        {...props}
                    />
                    <TokenAliaseGroup 
                        type={Globals.ProjectManager.Tokens.Types.Fonts}
                        title='TEXT STYLES'
                        onSelectTextStyle={this.props.onSelectTextStyle}
                        onShowMenu={this.props.onShowMenuTextStyle}
                        {...props}
                    />
                    <TokenAliaseGroup 
                        type={Globals.ProjectManager.Tokens.Types.Motion}
                        title='MOTION - DURATIONS'
                        onSelectDuration={this.props.onSelectDuration}
                        onShowMenu={this.props.onShowMenuDuration}
                        {...props}
                    />
                    <TokenAliaseGroup 
                        type={Globals.ProjectManager.Tokens.Types.MotionEase}
                        title='MOTION - EASE CURVES'
                        onSelectEaseCurve={this.props.onSelectEaseCurve}
                        onShowMenu={this.props.onShowMenuEaseCurve}
                        {...props}
                    />
                    <TokenAliaseGroup 
                        type={Globals.ProjectManager.Tokens.Types.Filters}
                        title='FILTERS'
                        onSelectFilter={this.props.onSelectFilter}
                        onShowMenu={this.props.onShowMenuFilter}
                        {...props}
                    />
                </SC.FCol>   
            </React.Fragment>
        )
        return (
            <React.Fragment>
                {this.props.children}
                {
                    this.props.noScroll ? content : 
                    <LeftScrollPanel>
                        {content}
                    </LeftScrollPanel>
                }                    
            </React.Fragment>
        )
    }
}


export const AddNewTokenAliase = ({type, name, id, tokenId, groupId}) => {
    return Globals.ProjectManager.Tokens.Aliases.AddAliase({
        type : type,
        name : name,
        id : id,
        tokenId : tokenId,
        groupId : groupId
    });
}

export const GetTokenAliaseList = (type, StateArray) => {
    const aliaseIds = Globals.ProjectManager.Tokens.Aliases.Order(type);
    return GetTokenAliaseListOfIds(aliaseIds, StateArray);
}

export const GetTokenAliaseListOfIds = (aliaseIds, StateArray) => {
    const aliases = [];

    if (aliaseIds) {
        aliaseIds.map((aliaseId) => {
            const aliase = GetTokenAliaseItem(aliaseId, StateArray);
            if (aliase) {
                aliases.push(aliase)
            }
        })
    }
    return aliases;
}

export const GetTokenAliaseItem = (aliaseId, StateArray) => {
    const aliase = Globals.ProjectManager.Tokens.Aliases.TokenAliase(aliaseId);
    if (aliase) {
        const stateInfo = {};
        const tokenId = Globals.ProjectManager.Tokens.Aliases.GetStateTokenIdOf({model : aliase, StateArray : StateArray, info : stateInfo});
        return {
            id : aliaseId,
            name : Utils.UseNullOrEmpty(aliase.name, ''),
            tokenId : tokenId,
            isAbsolute : aliase.isAbsolute,
            ValueState : stateInfo.ValueState
        };
    }
}

export const GetGroupedTokenAliaseList = (tokenType, tokens) => {
    const groups = Globals.ProjectManager.Tokens.Aliases.GetGroups(tokenType);    
    return GetGroupedTokenListWithGroups(groups, tokens, false, tokenType, true);
}

export const ColorTokenAliaseListItem = ({onSelect, onMenu, notDraggable, style, sortableProps, aliase, gradient, onPreview, selected, ...rest}) => {
    const style_box = {
        ...style,
        padding : '0px', paddingLeft : '6px',
        ...SC.Styles.Flex.RowAlcJsb
    }
    if (selected) {
        style_box.borderLeft = '1px solid';
        style_box.borderLeftColor = SC.CurrentTheme.theme.color_brand;
    }
    const previewStyle = {};
    if (!aliase.tokenItem)
        return null;

    if (aliase.tokenItem.gradient)
        previewStyle.background = aliase.tokenItem.value;
    else
        previewStyle.backgroundColor = aliase.tokenItem.value;

    return (
        <TokenItemBox onClick={onSelect} onContextMenu={onMenu} style={style_box} {...onPreview} {...rest} selected={selected}>
            <SC.FRow f1 alc overflowHidden>
                <StatefulTokenListItemName 
                    name={aliase.name}
                    sortableProps={sortableProps}
                    ValueState={aliase.ValueState}
                />                                      
            </SC.FRow>                
            <SC.GridBackground small dark={!SC.CurrentTheme.theme.isLight} style={{height : '100%', width : gradient ? '94px' : '30px', minWidth : '30px', borderRadius : '2px', overflow : 'hidden', border : SC.CurrentTheme.theme.border_ondark}}>
                <div style={{height : '24px', ...previewStyle, width : '100%'}} />
            </SC.GridBackground>
        </TokenItemBox>
    )
}

export const ShadowTokenAliaseListItem = ({onSelect, onMenu, notDraggable, style, sortableProps, aliase, gradient, onPreview, selected, ...rest}) => {
    const style_box = {
        ...style,
        padding : '0px', paddingLeft : '6px',
        ...SC.Styles.Flex.RowAlcJsb
    }
    if (selected) {
        style_box.borderLeft = '1px solid';
        style_box.borderLeftColor = SC.CurrentTheme.theme.color_brand;
    }
    const previewStyle = {};
    if (gradient)
        previewStyle.background = aliase.tokenItem.value;
    else
        previewStyle.backgroundColor = aliase.tokenItem.value;

    return (
        <TokenItemBox onClick={onSelect} onContextMenu={onMenu} style={style_box} {...onPreview} {...rest} selected={selected}>
            <SC.FRow f1 alc overflowHidden>
                <StatefulTokenListItemName 
                    name={aliase.name}
                    sortableProps={sortableProps}
                    ValueState={aliase.ValueState}
                />
            </SC.FRow>                
            <ShadowCardItem
                shadow={aliase.tokenItem}
                textShadow={aliase.tokenItem.textShadow}
                key={aliase.id} 
                notDraggable={notDraggable}
            />
        </TokenItemBox>
    )
}

export const BorderTokenAliaseListItem = ({onSelect, onMenu, notDraggable, style, sortableProps, aliase, gradient, onPreview, selected, ...rest}) => {
    const style_box = {...SC.Styles.Flex.RowAlcJsb, ...style};
    
    if (!aliase.tokenItem) {
        console.log(`ASSERT : NULL TOKEN`);
        return null;
    }

    return (
        <TokenItemBox  onClick={onSelect} onContextMenu={onMenu} {...rest} selected={selected} style={style_box}>
            <StatefulTokenListItemName 
                name={aliase.name}
                sortableProps={sortableProps}
                ValueState={aliase.ValueState}
            />  
            <SC.FRow f1 alc justifyEnd>
                <SC.TextSpanAbbr style={{...SC.Styles.FontStyles.Monospace}}>
                    {aliase.tokenItem.width} {aliase.tokenItem.style}
                </SC.TextSpanAbbr> 
                <div style={{
                    borderTopStyle : aliase.tokenItem.style,
                    borderTopWidth : aliase.tokenItem.width,
                    borderTopColor : SC.CurrentTheme.theme.color_brand,
                    flex : 1,
                    transition : 'all 0.3s ease',
                    maxWidth : '80px',
                    marginLeft : '8px'
                }} />                                 
            </SC.FRow>                                
        </TokenItemBox>      
    )
}

export const RadiusTokenAliaseListItem = ({onSelect, onMenu, notDraggable, style, sortableProps, aliase, gradient, onPreview, selected, ...rest}) => {
    const style_box = {...SC.Styles.Flex.RowAlcJsb,  ...style};
    
    return (
        <TokenItemBox  onClick={onSelect} onContextMenu={onMenu} {...rest} selected={selected} style={style_box}>
            <StatefulTokenListItemName 
                name={aliase.name}
                sortableProps={sortableProps}
                ValueState={aliase.ValueState}
            />                          
            <SC.FRow f1 alc justifyEnd>
                <SC.TextSpanAbbr style={{...SC.Styles.FontStyles.Monospace}}>
                    {aliase.tokenItem.style}
                </SC.TextSpanAbbr>                                            
            </SC.FRow>                                
        </TokenItemBox>      
    )
}

export const SpacePatternAliaseItem = ({onSelect, onMenu, notDraggable, style, sortableProps, aliase, onPreview, selected, ...rest}) => {
    const style_box = {...style};
    const absvalue = Math.abs(aliase.tokenItem.spaceValue);
    const style_preview = {
        minWidth : Utils.px(absvalue),
        minHeight : Utils.px(absvalue),
        maxWidth : Utils.px(absvalue),
        maxHeight : Utils.px(absvalue),
        backgroundColor : SC.CurrentTheme.theme.color_brand,
        transition : 'all 0.3s ease'
    };
    if (aliase.tokenItem.spaceValue < 0) {
        style_preview.backgroundColor = '#e252d7';
    }
    if (true) {
        delete style_preview.minHeight;
        style_preview.maxHeight = '4px';
        style_preview.minHeight = '4px';
        delete style_preview.border;
    }
    return (
        <TokenItemBox onClick={onSelect} onContextMenu={onMenu} {...rest} style={style_box} {...onPreview} >
            <SC.FRow alc jsb style={{marginBottom : '8px'}}>
                <SC.FRow f1 alc overflowHidden>
                    <StatefulTokenListItemName 
                        name={aliase.name}
                        sortableProps={sortableProps}
                        ValueState={aliase.ValueState}
                    /> 
                </SC.FRow>                
                <div style={{fontWeight : 'bold', ...SC.Styles.FontStyles.Monospace}}><span style={{marginRight : '4px', marginLeft : '4px'}}>{aliase.tokenItem.spaceValue}</span>{aliase.tokenItem.spaceUnit}</div>
            </SC.FRow>
            <div style={style_preview}>
            </div>
        </TokenItemBox>
    )

    return (
        <TokenItemBox  onClick={onSelect} {...rest} selected={selected} style={style_box}>
            {
                sortableProps && 
                <SC.DragBox first {...sortableProps.handleProps} style={{border : 'none', paddingTop : '2px'}} onMouseDown={(e) => e.stopPropagation()}>
                    <SC.Icons.DragIcon xsmall />
                </SC.DragBox>
            }   
            <SC.FRow f1 jsb style={{alignSelf : 'center'}}>
                <SC.TextSpanAbbr>
                    {aliase.name}
                </SC.TextSpanAbbr>
            </SC.FRow>
            <div style={style_preview}></div>                        
        </TokenItemBox>      
    )
}

export const FilterAliaseItems = ({filter, groups, aliases}) => {
    let filterText;
    if (Utils.IsNotNullOrEmpty(filter))
        filterText = filter.toUpperCase();
        
    if (groups) {
        Utils.ForEach(groups, (group, g) => {
            delete group.filtered;

            group.filtered = filterText ? true : false;
            Utils.ForEach(group.tokens, (token, t) => {
                delete token.filtered;
                if (filterText) {
                    if (token.name.toUpperCase().indexOf(filterText) < 0) {                    
                        token.filtered = true;
                    }
                    if (token.filtered && token.tokenItem && token.tokenItem.value) {
                        if (token.tokenItem.value.toUpperCase().indexOf(filterText) >= 0)
                            delete token.filtered;
                    }
                    if (!token.filtered)
                        group.filtered = false;
                }
            });
        });
    }
    else {
        Utils.ForEach(aliases, (aliase, i) => {
            delete aliase.filtered;
            if (filterText) {
                if (aliase.name.toUpperCase().indexOf(filterText) < 0) {                    
                    aliase.filtered = true;
                }
            }
        });
    }
}

export class AliaseItemEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return (  
            <SC.FCol fw fh>
                <TokenPanelHeader 
                    title='EDIT ALIASE' 
                    hasAddCancel={this.props.hasAddCancel}
                    onClone={this.props.onCloneAliase}
                    onDelete={this.props.onDeleteAliase}
                    onClose={this.props.onCancelAddAliase} 
                    onCancel={this.onCancelAddAliase} 
                />
                <LeftScrollPanel>
                    <AliaseEditor 
                        type={this.props.type}
                        RefToolbar={this.props.RefToolbar}
                        id={this.props.id}
                        aliase={this.props.aliase}
                    />
                </LeftScrollPanel>                        
            </SC.FCol>  
        );
    }
}
 