import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    AppLayout,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../../../../../importer';
import {motion} from 'framer-motion';
import { GetEaseCurveTokenList, GetEaseCurveTokenListOfIds } from '../../../../../panels/left/designsystem/motion/easing';
import { PresetCurve, GetEaseCurvePath } from '../../../../../panels/left/designsystem/motion/easing/bezierEditor/presetcurve';
import { GetTransitionTokenListOfIds, GetTransitionTokenList, TransitionCardItem, TransitionListItem } from '../../../../../panels/left/designsystem/motion/transitions';
import { DocumentLabel, DocumentTextValue } from '../typography';

export default class DocumentToken_Colors extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {

        };    
    }
    shouldComponentUpdate(nextProps, nextState) {

        if (this.state.ShouldUpdate !== nextState.ShouldUpdate)
            return true;
        
        if (Utils.HasAnyChange(this.props, nextProps, 'GlobalThemeId', 'GlobalState', 'StyleId', 'RenderId', 'LoadId', 'themeId', 'themeType', 'states', 'ReversedStyleState'))
            return true;
        
        return false;
    }
    componentDidUpdate(prevProps, prevState) {
        this.state.ShouldUpdate = false;
    }
    render() {        

        if (this.props.subType === 'scale') {
            const timeScale = Globals.ProjectManager.Tokens.TimeScale.Get(this.props.GlobalState, this.props.ReversedStyleState);
            let timeScaleName = timeScale.ratio;
            if (timeScale.scaleType !== 'Custom') {
                const timeScaleItem = Utils.Find(Globals.ProjectManager.Tokens.Utils.ScalePresets, (item) => {return item.id === timeScale.scaleType});
                if (timeScaleItem)
                    timeScaleName = `${timeScaleItem.label} - Factor of ${timeScale.ratio}`
            }

            return (
                <div
                    style={{
                        display : 'grid',
                        gridTemplateColumns : 'auto 1fr',
                        gridColumnGap : '16px',
                        gridRowGap : '8px',
                        alignContent : 'start',                            
                        fontSize : '14px',
                        padding : '16px',
                        borderRadius : '4px',
                        alignSelf : 'flex-start',
                        backgroundColor : this.props.textStyles.backgroundHighlight
                    }}
                >
                    <DocumentLabel>Time Scale</DocumentLabel>
                    <DocumentTextValue>{timeScaleName}</DocumentTextValue>
                    <DocumentLabel>Base Time</DocumentLabel>
                    <DocumentTextValue>{timeScale.baseSize} ms</DocumentTextValue>
                </div>
            )
        }
        else if (this.props.subType === 'durations') {            
            const durationItems = [];
            const durationIds = this.props.selection.type === 'selected' ? this.props.selection.tokenIds : Globals.ProjectManager.Tokens.TimePatterns.Get();
            
            Utils.ForEach(durationIds, (durationId, i) => {
                const token = Globals.ProjectManager.Tokens.Token(durationId);                
                const value = Globals.ProjectManager.Tokens.TimePatterns.GetTimeSize(token, null, null, this.props.ReversedStyleState);
                let numberValue = Number(value);
                if (!Utils.IsNumber(numberValue))
                    numberValue = 0;

                if (token) {
                    durationItems.push(
                        <React.Fragment>
                            <div style={{fontFamily : 'monospace'}}>{token.name}</div>
                            <div style={{fontFamily : 'monospace'}}>{value} ms</div>                        
                            <div style={{backgroundColor : this.props.textStyles.backgroundHighlight}}>
                                <motion.div
                                    style={{
                                        borderLeft : '1px dashed rgba(18,110,255)',
                                        borderRight : '1px dashed rgba(18,110,255)',
                                        backgroundColor : 'rgba(18,110,255,0.2)',
                                        width : Utils.px(numberValue),
                                        height : '100%'
                                    }}
                                    initial={{
                                        width : 0
                                    }}
                                    animate={{
                                        width : numberValue
                                    }}
                                    transition={{
                                        duration : 0.3
                                    }}
                                >
                                
                                </motion.div>
                            </div>
                        </React.Fragment>
                    )
                }
                
            });

            return  (
                <div style={{display : 'grid'}}>
                    <div style={{
                        display : 'grid',
                        gridGap : '16px',
                        gridColumnGap : AppLayout.Media.Small ? '16px' : '48px',
                        gridTemplateColumns : 'auto auto 1fr',
                        gridAutoRows : 'auto',
                        justifyContent : 'start',
                        overflowX : 'auto'
                    }}
                    >
                        <div>NAME</div>
                        <div>DURATION</div>
                        <div />
                        {durationItems}
                    </div>
                </div>            
            )
        }
        else if (this.props.subType === 'ease') {
            const easeTokenIds = this.props.selection.type === 'selected' ? this.props.selection.tokenIds : Globals.ProjectManager.Tokens.Motion();

            const easeTokens = GetEaseCurveTokenListOfIds(easeTokenIds, this.props.ReversedStyleState);
            const eaeItems = [];
            Utils.ForEach(easeTokens, (easeToken, i) => {
                const {x1,x2,y1,y2} = easeToken.path;
                eaeItems.push(
                    <React.Fragment>
                        {/* <PresetCurve path={easeToken.path} size={96} selected /> */}
                        <div style={{
                            backgroundColor : this.props.textStyles.backgroundHighlight,
                            padding : '8px',
                            borderRadius : '8px',
                            ...SC.Styles.Flex.Cell,                            
                        }}
                        >
                            <SC.Svg viewBox="0 0 100 100" width={100} height={100}>
                                <path d={GetEaseCurvePath({...easeToken.path, size : 80})} fill='none' stroke='#94a9ff' strokeWidth={3} strokeLinecap='round' style={{transform : 'translate(10px, 10px)'}} />
                            </SC.Svg>
                        </div>
                        <motion.div jsb style={{paddingTop  :'16px', paddingBottom  :'16px', alignSelf : 'stretch', ...SC.Styles.Flex.Column, justifyContent : 'space-between'}}>
                            <SC.FCol>
                                <div style={{fontFamily : 'monospace'}}>{easeToken.name}</div>
                                <div style={{fontFamily : 'monospace'}}>cubic-bezier({x1}, {y1}, {x2}, {y2})</div>        
                            </SC.FCol>                            
                            <motion.div
                                style={{
                                    backgroundColor : this.props.textStyles.backgroundHighlight,
                                    height : '24px',
                                    position : 'relative'
                                }}
                            >
                                <motion.div
                                    style={{
                                        width : '32px', height : '32px', borderRadius : '4px', opacity : 0.6,
                                        backgroundColor : '#94a9ff',
                                        position : 'absolute',
                                        top : '-4px'
                                    }}
                                    variants={
                                        {
                                            rest: { 
                                                left : 0,
                                            },
                                            hover: {
                                                left : 'calc(100% - 32px)',
                                                transition: {
                                                    duration: 1,
                                                    ease : [
                                                        Number(x1),
                                                        Number(y1),
                                                        Number(x2),
                                                        Number(y2) 
                                                    ]
                                                }
                                            }
                                        }
                                    }
                                />
                                <motion.div
                                    style={{
                                        width : '32px', height : '32px', borderRadius : '4px', opacity : 0.1,
                                        backgroundColor : '#94a9ff',
                                        position : 'absolute',
                                        top : '-4px'
                                    }}
                                    variants={
                                        {
                                            rest: { 
                                                left : 0,
                                            },
                                            hover: {
                                                left : 'calc(100% - 32px)',
                                                transition: {
                                                    duration: 1,
                                                    ease : 'linear'
                                                }
                                            }
                                        }
                                    }
                                />
                            </motion.div>
                        </motion.div>
                    </React.Fragment>
                )

                if (this.props.forTemplateSelect && eaeItems.length === 3) {
                    return false;
                }
            });

            return (
                <motion.div
                    style={{
                        display : 'grid',
                        gridColumnGap : '48px',
                        gridTemplateColumns : 'auto 1fr',
                        gridAutoRows : 'auto',
                        justifyContent : 'start',
                        alignItems : 'start',
                        gridRowGap : '24px'
                    }}
                    whileHover='hover'
                    whileTap='hover'
                    initial='rest'
                    // animate='rest'
                >
                    {eaeItems}
                </motion.div>               
            )
        }  
        else if (this.props.subType === 'transitions') {
            const tokens = this.props.selection.type === 'selected' ? GetTransitionTokenListOfIds(this.props.selection.tokenIds, this.props.ReversedStyleState) : GetTransitionTokenList(this.props.ReversedStyleState);
            const tokenItems = [];

            const size = '60px';

            Utils.ForEach(tokens, (token, ) => {
                tokenItems.push(
                    <React.Fragment>     
                        <motion.div
                            whileHover='hover'
                            whileTap='hover'
                            initial='rest'
                            style={{...SC.GetGridBackgroundStyle(false, !SC.CurrentTheme.theme.isLight), ...SC.Styles.Flex.Cell, padding : '24px', flex : 1, borderRadius : '2px', marginBottom : '8px'}}
                        >
                            <TransitionCardItem
                                transition={token}
                                key={token.id} 
                                notDraggable
                                listView={false}
                                justContent
                            />
                        </motion.div>                        
                        <SC.FCol style={{marginBottom : '24px'}}>
                            <div style={{fontFamily : 'monospace'}}>{token.name}</div>                            
                            <div style={{fontFamily : 'monospace'}}>{Utils.JustGet(token.ease, '', 'name')}</div>
                            <div style={{fontFamily : 'monospace'}}>Duration : {Utils.JustGet(token.duration, 0, 'timeValue')} ms {Utils.JustGet(token.duration, '', 'pattern', 'name')}</div>
                            {Utils.JustGet(token.delay, null, 'id') && <div style={{fontFamily : 'monospace'}}>Delay : {Utils.JustGet(token.delay, 0, 'timeValue')} ms {Utils.JustGet(token.delay, '', 'pattern', 'name')}</div>}
                            <div style={{fontFamily : 'monospace'}}>{Utils.JustGet(token.transform, '', 'name')}</div>
                        </SC.FCol>                        
                    </React.Fragment>
                )
            });

            return (
                <div style={{
                    display : 'grid',
                    gridColumnGap : '48px',
                    gridTemplateColumns : AppLayout.Media.Small ? 'repeat(auto-fit, minmax(280px, 1fr))' : 'repeat(auto-fit, minmax(400px, 1fr))',
                    gridAutoRows : 'auto',
                    justifyContent : 'start',
                    alignItems : 'start'
                }}
                >
                    {tokenItems}
                </div>
            )  
        }
        
        return null;
    }
}