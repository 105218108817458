import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    Globals
} from '../../../../../../../importer';
import styled, {css} from 'styled-components';

import Chroma from 'chroma-js';
import Switch from '../../../../../../../components/editors/Switch';


export default class TextColor extends ReactBaseComponent
{
    constructor(props) {
        super(props);
        this.SelectTextColor = this.SelectTextColor.bind(this);
        this.TokenSelected = this.TokenSelected.bind(this);

        this.fontFamily = Globals.ProjectManager.Tokens.DefaultFontStyle().family;
    }
    
    shouldComponentUpdate(nextProps, nextState) {
        if (Utils.HasAnyChange(this.props, nextProps, 'id', 'backColor')) {
            if (this.Ref_SelectPanel && this.Ref_SelectPanel.current) {
                this.Ref_SelectPanel.current.UpdateProps({backTokenId : nextProps.id, backColor : nextProps.backColor});
            }
            return true;
        }
        if (this.state.ShouldUpdate !== nextState.ShouldUpdate)
            return true;
        if (this.props.themeId !== nextProps.themeId)
            return true;
        if (nextProps.EditingTextColor)
            return true;
        return false;
    }
    SelectTextColor() {        
        this.Ref_SelectPanel = React.createRef();
        Events.BCE(Events.DESIGNER.BOARD.ITEM.SELECT_TEXTCOLOR, {
            backColor : this.props.backColor,
            backTokenId : this.props.id,
            ref : this.Ref_SelectPanel,
            onSelect : this.TokenSelected,
            selectedId : Globals.ProjectManager.Tokens.Colors.GetForegroundId(this.props.id)
        });
    }
    TokenSelected(tokenId) {
        Globals.ProjectManager.Tokens.Colors.SetValue({id : this.props.id, name : 'foregroundTokenId', value : tokenId});
        this.RCUpdate();

    }
    renderCustom() {
        const {backColor, id} = this.props;
        let textColor = '#fff';
        let textColorName = '';
        const tectColorId = Globals.ProjectManager.Tokens.Colors.GetForegroundId(id);
        if (tectColorId) {
            const textColorModel = Globals.ProjectManager.Tokens.Colors.Get(tectColorId);
            if (textColorModel) {
                textColor = Globals.ProjectManager.Tokens.ValueOf({model : textColorModel});
                textColorName = textColorModel.name;
                if (textColor && backColor) {
                    const contrast = Chroma.contrast(backColor, textColor).toFixed(2);
                    const contrastLevel = GetContrastLevel(contrast);
                    return (
                        <SC.FCol>
                            <SC.FRow alc justifyEnd style={{marginBottom : '16px'}}>
                                <div style={{marginRight : '8px'}}>Edit Text Color</div>
                                <Switch round value={this.props.EditingTextColor} onChange={this.props.onEditTextcolor} />
                            </SC.FRow>                
                            <div style={{marginBottom : '8px'}}>Default Text Color & Accessibility Level</div>
                            <SC.FCol alc style={{
                                justifyContent : 'space-evenly',
                                backgroundColor : backColor,
                                color : textColor,
                                fontSize : '24px',
                                height : '140px',
                                borderRadius : '2px',
                                cursor : 'pointer',
                                fontFamily : this.fontFamily,
                                overflow : 'hidden',
                                padding : '8px',
                                boxSizing : 'border-box'
                            }} onClick={this.SelectTextColor}>
                                <div style={{fontWeight : 500}}>{contrastLevel}</div>
                                <div style={{fontSize : '32px', fontWeight : 400, marginTop : '4px'}}>{contrast}</div>
                                <SC.TextSpanAbbr style={{fontSize : '14px', maxWidth : '100%'}}>{textColor}</SC.TextSpanAbbr>
                                <SC.TextSpanAbbr style={{fontSize : '14px', maxWidth : '100%'}}>{textColorName}</SC.TextSpanAbbr>
                            </SC.FCol>
                        </SC.FCol>                    
                    )
                }                
            }            
        }

        if (!backColor)
            return null;
         
        const contrast_light = Chroma.contrast(backColor, '#fff').toFixed(2);
        const contrastLevel_light = GetContrastLevel(contrast_light);

        const contrast_dark = Chroma.contrast(backColor, '#000').toFixed(2);
        const contrastLevel_dark = GetContrastLevel(contrast_dark);
        
        return (
            <SC.GridBackground small dark={!SC.CurrentTheme.theme.isLight} style={{height : 'auto', ...this.props.style}}>
                <SC.FRow alc style={{
                    justifyContent : 'space-evenly',
                    backgroundColor : backColor,                    
                    fontSize : '24px',
                    height : '140px',
                    borderRadius : '2px',
                    cursor : 'pointer',
                    fontFamily : this.fontFamily,
                    overflow : 'hidden',
                    padding : '8px',
                    boxSizing : 'border-box'
                }} onClick={this.SelectTextColor}>
                    <SC.FCol f1 alc style={{color : '#fff'}}>
                        <div style={{fontWeight : 500}}>{contrastLevel_light}</div>
                        <div style={{fontSize : '32px', fontWeight : 400, marginTop : '4px'}}>{contrast_light}</div>
                    </SC.FCol>
                    <SC.FCol f1 alc style={{color : '#000'}}>
                        <div style={{fontWeight : 500}}>{contrastLevel_dark}</div>
                        <div style={{fontSize : '32px', fontWeight : 400, marginTop : '4px'}}>{contrast_dark}</div>
                    </SC.FCol>
                </SC.FRow>
            </SC.GridBackground>                      
        )
    }
}

export class ColorContrastViewer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  };

        this.CalculateContrasts = this.CalculateContrasts.bind(this);
        this.CalculateContrasts = Utils.Throttle(this.CalculateContrasts, 100);

        !this.props.notAvailable && this.CalculateContrasts(this.props.backgroundColor, this.props.color);
        this.fontFamily = Globals.ProjectManager.Tokens.DefaultFontStyle().family;
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.backgroundColor !== nextProps.backgroundColor || this.props.color !== nextProps.color || this.props.notAvailable !== nextProps.notAvailable) {            
            !nextProps.notAvailable && this.CalculateContrasts(nextProps.backgroundColor, nextProps.color);
            return true;            
        }
        return false;
    }
    CalculateContrasts(backgroundColor, color) {
        if (backgroundColor && color) {
            if (Chroma.valid(backgroundColor)  && Chroma.valid(color)) {
                this.contrast = Chroma.contrast(backgroundColor, color).toFixed(2);
                this.contrastLevel = GetContrastLevel(this.contrast);
            }            
        }        
    }
    render() {         
        return (
            <SC.GridBackground small dark={!SC.CurrentTheme.theme.isLight} style={{height : 'auto', ...this.props.style}}>
                <SC.FRow alc style={{
                    justifyContent : 'space-evenly',
                    backgroundColor : this.props.backgroundColor,
                    color : this.props.color,
                    fontSize : '24px',
                    height : '60px',
                    borderRadius : '2px',
                    cursor : 'pointer',
                    fontFamily : this.fontFamily,
                    overflow : 'hidden',
                    padding : '8px',
                    boxSizing : 'border-box',
                    transition : 'all 0.2s ease'
                }} onClick={this.props.onSelect}>
                    {
                        (this.props.empty || this.props.notAvailable) ? <div style={{fontWeight : 300, fontSize : '16px'}}>{this.props.emptyText}</div> : 
                        <React.Fragment>
                            <div style={{fontWeight : 500}}>{this.contrastLevel}</div>
                            <div style={{fontSize : '32px', fontWeight : 400}}>{this.contrast}</div>
                        </React.Fragment>
                    }                    
                </SC.FRow>
            </SC.GridBackground>
        )
    }
}
 

export const GetContrastLevel = (contrast) => {
    if (contrast < 3.1)
        return 'FAIL';
    if (contrast < 4.5)
        return 'AA Large';
    if (contrast < 7)
        return 'AA';
    return 'AAA';
}