import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    AppLayout,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../../../../importer';
import styled, { css} from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import Chroma from 'chroma-js';

import SlateEditor from '../text/editor';

export default class DocumentItem_LinkCard extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {

        };    

        this.onChangeSubtitle = this.onChangeSubtitle.bind(this);
        this.onReloadSubtitle = this.onReloadSubtitle.bind(this);
        
        this.Load(this.props.id);
    }    
    shouldComponentUpdate(nextProps, nextState) {

        if (this.props.autoFocus !== nextProps.autoFocus)
            return true;
        if (this.state.ShouldUpdate !== nextState.ShouldUpdate)
            return true;
        
        if (this.props.StyleId !== nextProps.StyleId || this.props.themeId !== nextProps.themeId) {
            this.EditorId = Utils.Id();
            return true;
        }
        if (this.props.RenderId !== nextProps.RenderId) {
            this.Load(nextProps.id);
            return true;
        }        
        if (Utils.HasAnyChange(this.props, nextProps, 'preview', 'GlobalThemeId', 'GlobalState', 'StyleId', 'themeId')) {
            if (this.props.preview !== nextProps.preview)
                this.EditorId = Utils.Id();
            return true;
        }
        
        return false;
    }
    Load(id) {
        
    }
    componentDidUpdate(prevProps, prevState) {
        this.state.ShouldUpdate = false;
    }
    
    Update() {
        this.setState({
            ShouldUpdate : true
        })
    }    
    onChangeSubtitle(value) {
        Globals.ProjectManager.GetDocumentManager().SetItemValue(this.props.id, Utils.DeepClone(value), 'content');
        this.setState({
            value : value,
            ShouldUpdate : true
        })             
    }
    onReloadSubtitle(value) {
        this.EditorId = Utils.Id();
        this.onChangeSubtitle(value);
    }
    render() {      

        const alignment = Globals.ProjectManager.GetDocumentManager().GetPageItemValue(this.props.id, this.props.pageId, 'center', 'alignment');
        const url = Globals.ProjectManager.GetDocumentManager().GetPageItemValue(this.props.id, this.props.pageId, '', 'link');
        const imageWidth = Globals.ProjectManager.GetDocumentManager().GetPageItemValue(this.props.id, this.props.pageId, 200, 'imageWidth');
        const direction = Globals.ProjectManager.GetDocumentManager().GetPageItemValue(this.props.id, this.props.pageId, '', 'direction');
        const backColor = Globals.ProjectManager.GetDocumentManager().GetPageItemValue(this.props.id, this.props.pageId, this.props.textStyles.backgroundHighlight, 'backColor');
        const subtitle = Globals.ProjectManager.GetDocumentManager().GetPageItemValue(this.props.id, this.props.pageId, [
            {
                type: 'subtitle',
                children: [{ text: '' }]
            }
        ], 'content');
        const isEmpty = !Utils.IsNotNullOrEmpty(url);
        const openInNewWindow = Globals.ProjectManager.GetDocumentManager().GetPageItemValue(this.props.id, this.props.pageId, false, 'newWindow');

        let backgroundColor, backgroundColorHover;
        if (Chroma.valid(backColor)) {
            const color = Chroma(backColor);
            backgroundColor = color.hex();
            backgroundColorHover = color.brighten(0.5);
        }
        
        const imageurl = Globals.ProjectManager.GetDocumentManager().GetPageItemValue(this.props.id, this.props.pageId, '', 'url');
        let content = (
            <StyledLinkCard
                backColor={backgroundColor}
                backColorHover={backgroundColorHover}
                style={{
                    width : (alignment === 'stretch') ? '100%' : 'auto',
                    display : 'grid',
                    gridTemplateColumns : direction === 'vertical' ? '1fr' : 'auto auto',
                    gridTemplateRows : direction === 'vertical' ? 'auto auto' : '1fr',
                }}
            >
                <img src={imageurl} 
                    style={{
                        width : Utils.px(imageWidth),
                        opacity : 1,                            
                        alignSelf : 'center',
                        justifySelf : 'center'
                    }}
                />
                <SC.FCol style={{
                    padding : '16px',
                    minWidth : '80px',
                    zIndex : 99999999999
                }}>
                    <SlateEditor 
                        key={this.EditorId}
                        placeholder={this.props.preview ? '' : 'Subtitle'}
                        onChange={this.onChangeSubtitle}
                        onReloadValue={this.onReloadSubtitle}
                        textStyles={this.props.textStyles}
                        StyleId={this.props.StyleId}
                        value={subtitle}
                        themeId={this.props.themeId}
                        preview={this.props.preview}
                        selected={this.props.selected}
                        toolbarOnBottom
                        style={{
                            cursor : this.props.preview ? 'pointer' : 'text'
                        }}
                        onSuspendToolbar={this.props.onSuspendToolbar}
                        toolbarOnRight={this.props.layoutItem && this.props.layoutItem.right}
                    />
                </SC.FCol> 
            </StyledLinkCard>
        )
        return (
            <SC.FCol>
                <motion.div
                    style={{
                        ...SC.Styles.Flex.Column
                    }}
                    layoutTransition={{
                        duration : 0.2
                    }}
                >
                    {
                        (this.props.shared || this.props.preview) ? 
                        <a href={url} 
                            target={openInNewWindow ? '_blank' : '_self'}
                            style={{
                                alignSelf : alignment,
                                width : 'auto',                                    
                                height : 'auto',
                            }}>
                            {content}
                        </a> : 
                        <div style={{width : '100%', position : this.props.layoutItem ? 'unset' : 'relative', display : 'flex', flexDirection : 'column'}}>                            
                            <div
                                style={{
                                    alignSelf : alignment,
                                    width : 'auto',                                    
                                    height : 'auto',
                                    minWidth : '200px',
                                    minHeight : '60px',
                                    display : 'flex',

                                }}>
                                    {content}
                            </div>
                        </div>
                    }
                </motion.div>
            </SC.FCol>            
        )
    }
}

const StyledLinkCard = styled.div`
    display : flex;
    align-items : stretch;
    justify-content : flex-start;
    overflow : hidden;
    background-color : ${props => props.backColor};
    cursor : pointer;
    border-radius : 8px;
    height : 100%;
    box-sizing : border-box;
    &:hover {
        background-color : ${props => props.backColorHover};
    }
`;