import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppLayout,
    Links,
    Events,
    Loading,
    Strings,
    Globals
} from '../../../../../../../importer';

import {TokenItemBox, TokenPanelHeader, TokenItemDragHandle, TokenItemBoxHoveredName} from '../../common';
import { SortableList, SortableListItem } from '../../../../../../../components/SortableContainer';
import BaseTokenList from '../../BaseTokenList';
import {Droppable, Draggable} from 'react-beautiful-dnd';
import { DotMenu, LeftScrollPanel } from '../../../common';
import Switch from '../../../../../../../components/editors/Switch';
import { StatefulTokenListItemName } from '../../colors';
import { GRANT_TYPES } from '../../../../../manager';
import CustomTokenItemEditor from './item';

export default class CustomTokenList extends BaseTokenList
{
    constructor(props) {
        super(props);

        this.title = this.props.title;
        this.moduleName = this.props.id;
        this.tokenType = this.props.tokenType;
        this.hasGroups = true;
        this.onEditToken = this.onEditToken.bind(this);
        this.EditToken = this.EditToken.bind(this);
        this.onDeleteToken = this.onDeleteToken.bind(this);
        this.onCloneToken = this.onCloneToken.bind(this);
        this.onCancelAddToken = this.onCancelAddToken.bind(this);
        this.onSubmitNewToken = this.onSubmitNewToken.bind(this);

        AppLayout.Refs.DesignSystem.CustomTokenList = this;

        super.LoadOptions();
        if (this.expanded || this.props.singleView) {
            this.expanded = true;
            this.Load(this.state.isGroupView);
        }
    }
    componentWillUnmount() {
        super.componentWillUnmount();
        AppLayout.Refs.DesignSystem.CustomTokenList = null;
    }
    Load(groupView) {        
        this.tokens = GetCustomTokenList(this.tokenType);        
        if (groupView)
            this.groups = super.GetGroupedTokenList(this.tokens)
    }        
    SearchToken(filter) {
        let filterText;
        if (Utils.IsNotNullOrEmpty(filter))
            filterText = filter.toUpperCase();
        Utils.ForEach(this.tokens, (token, i) => {
            delete token.filtered;
            if (filterText) {
                if (token.name.toUpperCase().indexOf(filterText) < 0) {                    
                    token.filtered = true;
                }                
            }
        });
        this.RenderId = Utils.Id();
        this.RCThrottledUpdate_1();
    }  
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.filterText !== nextProps.filterText) {
            setTimeout(() => {
                this.SearchToken(nextProps.filterText);
            }, 1);
            return false;
        }
        return super.shouldComponentUpdate(nextProps, nextState);
    }
    AddTokenToGroup(group) {
        if (!Globals.ProjectManager.CheckGrant(GRANT_TYPES.EDIT_TOKEN.ALL)) {
            return;
        }

        this.AddingToGroup = group;
        this.IsNew = true;
        this.EditTokenId = Utils.Id();
        this.EditTokenModel = {
            name : 'New Token'
        };
        
        this.props.onPanelOverlay({
            show : true,
            render : (props) => {
                return (
                    <SC.FCol fw fh>
                        <TokenPanelHeader 
                            title={<SC.FRow alc><div style={{marginRight : '1ch'}}>NEW </div>{this.props.title}</SC.FRow>}
                            notBackClosable
                            hasAddCancel 
                            onClose={this.onCancelAddToken} 
                            onCancel={this.onCancelAddToken} 
                            onAdd={this.onSubmitNewToken} 
                        />
                        <LeftScrollPanel>
                            <CustomTokenItemEditor 
                                id={this.EditTokenId}
                                tokenType={this.tokenType}
                                newModel={this.EditTokenModel}
                                GlobalState={props.GlobalState}
                                GlobalStateId={props.GlobalStateId}
                                offline
                            />
                        </LeftScrollPanel>                        
                    </SC.FCol>                    
                )
            }
        })
    }
    onEditToken(id, e) {
        if (this.props.onSelect) {
            this.props.onSelect(id, e);
            return;
        }
        this.EditToken(id);
    }
    onChangeValue(id, value) {
        Globals.ProjectManager.Tokens.SetValue({id : id, value : value});
        Events.BroadcastThrottle(Events.GLOBAL.TOKEN_VALUE_CHANGING, [{
            Id : id,
            Type : this.props.tokenType,
            value : value
        }]);
        this.RCUpdate();
    }
    onShowMenu(id, e) {
        this.props.onShowMenu && this.props.onShowMenu(id, e);
    }
    EditToken(id) {        
        this.EditTokenId = id;
        this.props.onPanelOverlay({
            show : true,
            render : (props) => {
                return (
                    <SC.FCol fw fh>
                        <TokenPanelHeader 
                            title={<SC.FRow alc><div style={{marginRight : '1ch'}}>EDIT</div>{this.props.title}</SC.FRow>}
                            hasAddCancel 
                            onClose={this.onSubmitNewToken} 
                            onCancel={this.onCancelAddToken} 
                            onAdd={this.onSubmitNewToken} 
                            onDelete={this.onDeleteToken} 
                            onClone={this.onCloneToken}
                        />
                        <LeftScrollPanel>
                            <CustomTokenItemEditor 
                                ref={this.Ref_TokenEdit}
                                id={id}
                                tokenType={this.tokenType}
                                GlobalState={props.GlobalState}
                                GlobalStateId={props.GlobalStateId}
                                onCancel={this.onCancelAddToken}
                                RefToolbar={this.props.RefToolbar}
                                onPanelOverlay={this.props.onPanelOverlay}
                            />
                        </LeftScrollPanel>                        
                    </SC.FCol>                    
                )
            }
        })
    }    
    onCloneToken() {
        const cloneId = Globals.ProjectManager.Tokens.CloneCustomToken(this.EditTokenId, this.tokenType);
        this.Load(this.props);
        this.EditToken(cloneId);        
    }
    onDeleteToken() {
        if (Globals.ProjectManager.Tokens.Delete(this.tokenType, this.EditTokenId)) {
            this.Load(this.props);
            this.onCancelAddToken();
        }
    }
    onCancelAddToken() {
        delete this.IsNew;
        delete this.EditTokenModel;
        delete this.EditTokenId;

        this.props.onPanelOverlay({close : true});
        this.RCUpdate();
    }
    onSubmitNewToken() {
        if (this.IsNew) {
            
            const TokenItem = AddNewCustomToken(this.tokenType, this.EditTokenModel, this.EditTokenId);
            this.tokens.push(TokenItem);

            if (this.AddingToGroup) {
                this.AddingToGroup.tokens.push(TokenItem);
                Globals.ProjectManager.Tokens.AddTokenToGroup({type : this.tokenType, groupid : this.AddingToGroup.id, tokenid : TokenItem.id})
                delete this.AddingToGroup;
            } 

            this.WillScrollTo = this.EditTokenId;
            this.selectedId = this.EditTokenId;
        }        
        else {
            const token = Globals.ProjectManager.Tokens.Token(this.EditTokenId);
            if (token) {
                const localpattern = Utils.Find(this.tokens, (item) => {return item.id === this.EditTokenId});
                localpattern.name = token.name;
                Events.BCE(Events.GLOBAL.TOKEN_VALUE_CHANGING);
            }            
        }
                
        Events.BCE(Events.GLOBAL.TOKENS_CHANGED);
        this.onCancelAddToken();
    }        
    renderHeaderAddOnLast() {
        return (
            <SC.Icons.Icon_Button hasCursor hasFill onClick={this.props.onEditDefinition} title='Edit Token Type Definition'>
                <SC.Icons.Icon_Edit />
            </SC.Icons.Icon_Button>
        )
    }
    renderTokenList(tokens, gorupId) {
        let style;
        if (this.state.isListView) {
            style = {
                marginLeft : '10px', marginRight : '10px', marginTop : '6px', marginBottom : 0,
                ...SC.Styles.Flex.Column
            }
        }
        else {
            style = {
                margin : '10px', marginBottom : '4px', marginTop : '4px', marginBottom : 0,
                ...SC.Styles.Flex.Column
            }
        }

        const transformItems = [];
        Utils.ForEach(tokens, (model, i) => {                   
            !model.filtered && transformItems.push(
                <SortableListItem
                    key={model.id}
                    draggableId={model.id}                    
                    index={i}
                    ItemBoxType={CustomTokenListItem}
                    BoxProps={{
                        name : model.name,
                        value : model.value,
                        valueState : model.valueState,
                        isListView : this.state.isListView,
                        onSelect : this.onEditToken.bind(this, model.id),
                        onShowMenu : this.onShowMenu.bind(this, model.id),
                        onChange : this.onChangeValue.bind(this, model.id)
                    }}                
                />
            )
        });    
        return (
            <Droppable 
                droppableId={gorupId}
                type={'TOKENS'}                
            >
                {
                    (provided, snapshot) => (
                        <div
                            {...provided.droppableProps} 
                            ref={provided.innerRef} 
                            style={{
                                ...provided.droppableProps.style,
                                ...style,
                                border : '1px dashed',                                                    
                                borderColor : snapshot.isDraggingOver ? SC.CurrentTheme.theme.color_brand : 'transparent'                                
                            }}
                        >
                            {transformItems}
                            {provided.placeholder}
                        </div>
                    )
                }
            </Droppable>
        )  
    }    
}

export const CustomTokenListItem = ({onSelect, onShowMenu, onChange, notDraggable, isListView, style, sortableProps, name, value, valueState, onPreview, selected, ...rest}) => {
    const style_box = {
        ...style,
        padding : '6px',
        flex : 1,
    }
    if (selected) {
        style_box.borderLeft = '1px solid';
        style_box.borderLeftColor = SC.CurrentTheme.theme.color_brand;
    }
    return (        
        <TokenItemBox onClick={onSelect} onContextMenu={onShowMenu} style={style_box} {...onPreview} {...rest} selected={selected}>
            <StatefulTokenListItemName 
                name={name}
                sortableProps={sortableProps}
                ValueState={valueState}
            />
        </TokenItemBox>
    )
}

export const GetCustomTokenList = (tokenType, StateArray) => {
    const tokenids = Globals.ProjectManager.Tokens.CustomTokenIds(tokenType);
    return GetCustomTokenListOfIds(tokenType, tokenids, StateArray);
}
export const GetCustomTokenListOfIds = (tokenType, tokenids, StateArray) => {
    
    const tokens = Globals.ProjectManager.Tokens.CustomTokenList(tokenType);
    const tokenitems = [];

    Utils.ForEach(tokenids, (id, i) => {
        const token = tokens[id];
        if (token) {
            const info = {};
            const tokenvalue = Utils.IsTrue(Globals.ProjectManager.Tokens.ValueOf({model : token, statearray : StateArray, info : info}));
            const tokenitem = {
                id : id,
                name : token.name,
                value : tokenvalue,
                valueState : info.ValueState
            }
            tokenitems.push(tokenitem);                
        }
    });


    return tokenitems;
}

export const AddNewCustomToken = (CustomTokenType, {name, ...fields}, id) => {
    
    Globals.ProjectManager.Tokens.AddCustomToken({
        typeId : CustomTokenType,
        name : name,
        ...fields,
        id : id
    })
    return {
        id : id,
        name : name,
        ...fields
    };
}