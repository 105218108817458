import React from 'react';
import ReactDOM from 'react-dom';

import {
    MetaData,
    Utils,
    SC
} from '../../../../../../importer';

import ToolbarIcons from '../../../../../../styled/toolbar';
import ReactPlayer from 'react-player'

import { onConstructItem, onItemDidMount, onItemWillUnMount, onItemDidUpdate, onItemShouldUpdate, onItemClick, onGetItemProps } from '../common';
import { WidgetIcon } from '../../../../panels/left/meta_item_sources/itemicon';

export default class Video extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }

        onConstructItem(this, props);

        this.UpdateStyle(this.props);
    }

    UpdateStyle(props) {        
        this.renderData = props.GetComponentManager().GetItemStyle(props.Id);
    }
    componentWillUnmount() {
        onItemWillUnMount.call(this);
    }
    componentDidUpdate(prevProps, prevState) {
        onItemDidUpdate.call(this, prevProps, prevState);
    }
    GetMaxSize() {        
        return this.props.GetParentSize();
    }
    componentDidMount() {
        onItemDidMount.call(this);
    }
    shouldComponentUpdate(nextProps, nextState) {
        let willUpdate = false;
        if (onItemShouldUpdate.call(this, nextProps, nextState)) {
            willUpdate = true;
        }          
        return willUpdate;
    }
    render() {     
        if (Utils.IsTrue(this.renderData.props.hidden))
            return null;
        const props = onGetItemProps({designer : this});
        let content;
        if (Utils.IsNotNullOrEmpty(this.renderData.props.VideoUrl)) {
            content = (
                <ReactPlayer 
                    url={this.renderData.props.VideoUrl}
                    light
                    width='100%'
                    height='100%'
                    style={{pointerEvents  : 'none'}}
                />
            )
        }
        else {
            content = (
                <SC.Div_Flex_Cell
                    style={{
                        border : '1px dashed #636469',
                        minWidth : '44px',
                        height : '100%',
                        width : '100%',
                        boxSizing : 'border-box'
                    }}
                    cursorPointer
                >
                    {WidgetIcon(MetaData.Components.Widget.WidgetTypes.Video.Type, 44)}
                </SC.Div_Flex_Cell>
            )
        }
        return (
            <div 
                style={{
                    ...this.renderData.style,
                    overflow : 'hidden'
                }} 
                {...props}
            >
                {content}
            </div>
        )
    }
}
 