import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    AppLayout,
    Events,
    MetaData,
    UIUtils,
    Globals,
    ReactBasicComponent
} from '../../../../../../../importer';

import SlateEditor from '../../text/editor';
import PlainTextEditor from '../../../../../../../components/editors/SlatePlainTextEditor';

export default class DocumentToken_TypographyPreview extends ReactBasicComponent
{
    constructor(props) {
        super(props);

        this.onChangeText = this.onChangeText.bind(this);
    }

    onChangeText(value) {
        if (this.props.preview)
            return;
        Globals.ProjectManager.GetDocumentManager().SetItemValue(this.props.id, value, 'preview', this.props.textPatternId);
        this.RCUpdate();
    }
    renderCustom() {        
        const textPattern = Globals.ProjectManager.Tokens.Token(this.props.textPatternId);
        const textStyle = Globals.ProjectManager.Tokens.TypePatterns.GetPatternStyle(textPattern, null, null, this.props.ReversedStyleState);
      
        const previewText = Globals.ProjectManager.GetDocumentManager().GetPageItemValue(this.props.id, this.props.pageId, 'Default Preview Text', 'preview', this.props.textPatternId);
        return (
            <SC.FRow alc
                style={{
                    ...textStyle,
                    justifyContent : 'flex-start',
                    overflow : 'hidden',
                    backgroundColor : this.props.textStyles.backgroundHighlight,
                    padding : '24px'
                }}
            >                
                <PlainTextEditor 
                    text={previewText}
                    onChange={this.onChangeText}
                    spellCheck={false}
                    autoCorrect={false}
                />
            </SC.FRow>
        )
    }
}