import React from 'react';
import ReactDOM from 'react-dom';

import {
    MetaData,
    Utils,
    SC
} from '../../../../../../importer';

import ReactResizeDetector from 'react-resize-detector';
import { onConstructItem, onItemDidMount, onItemWillUnMount, onItemDidUpdate, onItemShouldUpdate, onItemClick, onGetItemProps } from '../common';
import { DEFAULT_LOTTIE_FILE } from '../../../../documents/designer/item/wizard';

let LottiePlayer;

export default class WidgetLottiePlayer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }

        this.Load = this.Load.bind(this);
        this.onResize = this.onResize.bind(this);
        this.Ref_Player = React.createRef();

        onConstructItem(this, props);

        this.UpdateStyle(this.props);
        
    }
    UpdateStyle(props) {        
        const renderData = props.GetComponentManager().GetItemStyle(props.Id);
        const currentProps = Utils.JustGet(this.renderData, {}, 'props');
        this.renderData = renderData;
        if (this.renderData.props.LottieUrl !== currentProps.LottieUrl) {
            if ( this.PlayerInstance) {
                this.PlayerInstance.destroy();
            }            
            this.Load();
        }               
        else if (Utils.HasAnyChange(currentProps, renderData.props, 'speed')) {
            if (this.PlayerInstance) {
                this.PlayerInstance.setSpeed(renderData.props.speed);                                
            }
        }
    }
    componentWillUnmount() {
        onItemWillUnMount.call(this);

        if (this.PlayerInstance)
            this.PlayerInstance.destroy();
    }
    componentDidUpdate(prevProps, prevState) {
        onItemDidUpdate.call(this, prevProps, prevState);
    }
    GetMaxSize() {        
        return this.props.GetParentSize();
    }
    HeaderLoaded(scriptLoaded, styleLoaded) {
        if (scriptLoaded) {
            this.scriptLoaded = true;
        }
        if (styleLoaded)
            this.styleLoaded = true;
        if (this.scriptLoaded && this.styleLoaded) {
            LottiePlayer = window.bodymovin;
            
            if (LottiePlayer) {
                this.Load();
            }                    
        }
    }
    componentDidMount() {
        onItemDidMount.call(this);
        LottiePlayer = window.bodymovin;

        if (!LottiePlayer) {            
            const script = document.createElement("script");
            script.src = "https://cdnjs.cloudflare.com/ajax/libs/bodymovin/5.6.5/lottie.min.js";
            script.async = true;
            script.onload = this.HeaderLoaded.bind(this, true);
            document.head.appendChild(script);
        }
        else
            this.Load();
    }
    shouldComponentUpdate(nextProps, nextState) {
        let willUpdate = false;
        if (onItemShouldUpdate.call(this, nextProps, nextState)) {
            willUpdate = true;
        }          
        return willUpdate;
    }
    Load() {
        if (this.Ref_Player.current && LottiePlayer) {

            const {speed} = this.renderData.props;

            const DN = ReactDOM.findDOMNode(this.Ref_Player.current);
            
            this.PlayerInstance = new LottiePlayer.loadAnimation({
                container: DN,
                path: Utils.UseNullOrEmpty(this.renderData.props.LottieUrl, DEFAULT_LOTTIE_FILE),
                renderer: 'svg/canvas/html',
                loop: true, // Optional
                autoplay: true, // Optional
                rendererSettings : {
                    progressiveLoad: true,
                }

            });
            this.PlayerInstance.setSpeed(speed || 1);
        }
        this.setState({ShouldUpdate : true});
    }
   
    onResize(w, h) {
        if (this.PlayerInstance)
            this.PlayerInstance.resize();
    }    
    render() { 
        if (Utils.IsTrue(this.renderData.props.hidden))
            return null;
            
        const props = onGetItemProps({designer : this});

        return (
            <SC.Div_Relative style={{...this.renderData.style, overflow : 'hidden'}} {...props} ref={this.Ref_Player}>
                <ReactResizeDetector handleWidth handleHeight onResize={this.onResize} />                
            </SC.Div_Relative>
        )
    }
}
 