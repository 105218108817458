import React from 'react';
import {
    SC,
    Globals,
    AppLayout,
    Utils
} from '../../../../../../../importer';

import BorderRadiusEditor from '../../../../left/designsystem/borders/radius';
import { BorderRadiusTokenItem, GetBorderRadiusTokenList, GetBorderRadiusTokenValue } from '../../../../left/designsystem/borders';
import { GetAliaseTokenItem, GetTokenAliaseList } from '../../../../left/designsystem/aliases/aliaseGroup';
import { GroupTitle } from '../../../../left/designsystem/common';
import { ListCardView } from '../../../../../../../components/light_dark_bar';
import BaseSelectToken from '../BaseSelectToken';

export default class SelectBorderRadius extends BaseSelectToken
{
    constructor(props) {
        super(props);

        this.titleNewToken = 'NEW RADIUS';
        this.moduleName = 'BorderRadius';
        this.tokenType = Globals.ProjectManager.Tokens.Types.BorderRadiuses;        
        this.hasCustomTokenList = true;
        this.state.isListView = false;
        this.hasAliases = !this.props.forAliase;
        this.state.isAliaseListView = true;

        this.Load(this.props);

        this.RadiusValues = {};
        
        this.SelectedSide = this.props.side;
    }
    IsTokenPropChanged(nextProps, nextState) {
        return this.props.propName !== nextProps.propName || this.props[this.props.propName] !== nextProps[this.props.propName];
    }
    GetTokens(props) {
        const Radiuses = GetBorderRadiusTokenList();
        this.RadiusItemSize = Radiuses.maxRadiusSize;

        this.aliases = GetTokenAliaseList(Globals.ProjectManager.Tokens.Types.BorderRadiuses);
        if (this.props.excludeIds) {
            this.props.excludeIds.map((excludeId) => {
                Utils.Remove(this.aliases, (item) => {return item.id === excludeId});
            })            
        }

        this.aliases.map((aliase) => {
            aliase.tokenItem = GetAliaseTokenItem(aliase, Globals.ProjectManager.Tokens.Types.BorderRadiuses);
        })

        return Radiuses.items;
    }        
    GetNewModel(Id) {
        const item = {
            type : Globals.ProjectManager.Tokens.Types.BorderRadiuses,
            name : 'New Radius',
            value : {
                value : 8
            },
            id : this.EditingToken.Id
        };
        Globals.ProjectManager.Tokens.Add({...item, doNotUpdateTheme : true});
        this.FireSelectToken(Id);
        return item;
    }
    onCloseTokenEditor() {
        if (this.EditingToken && this.EditingToken.IsNew) {
            delete this.EditingToken;            
            this.RCUpdate();
            return;
        }
        super.onCloseTokenEditor();
    }
    onCancelAddToken() {
        const willDeleteId = this.EditingToken.Id;
        this.FireSelectToken(this.LastBoundTokenId);
        delete this.LastBoundTokenId;
        super.onCancelAddToken();        
        Globals.ProjectManager.Tokens.Delete(Globals.ProjectManager.Tokens.Types.BorderRadiuses, willDeleteId);
    }
    SaveToken() {
        if (this.EditingToken.IsNew) {
            const item = {
                type : Globals.ProjectManager.Tokens.Types.BorderRadiuses,
                name : this.EditingToken.Model.name,
                value : this.EditingToken.Model.value,
                id : this.EditingToken.Id
            };
            Globals.ProjectManager.Tokens.Add(item);
            this.Load();            
            AppLayout.Refs.DesignSystem.Borders && AppLayout.Refs.DesignSystem.Borders.Reload();
            return item;
        }        
        else {
        }                
    }   
    UpdateSelectedToken() {
        if (this.EditingToken) {
            const SelectedToken = Utils.Find(this.tokens, (token) => {return token.id === this.EditingToken.Id});        
            const token = Globals.ProjectManager.Tokens.Token(this.EditingToken.Id);
            const item = GetBorderRadiusTokenValue(token, this.EditingToken.Id, this.props.StateArray);
            if (SelectedToken)
                SelectedToken.style = item.style;
            AppLayout.Refs.DesignSystem.Borders && AppLayout.Refs.DesignSystem.Borders.Reload();
        }
    }
    GetStyleName() {
        return Utils.BorderStyles.propRadius(this.SelectedSide);
    }
    renderTokenAliaseListHeader() {
        return (
            <GroupTitle 
                title='SEMANTIC TOKENS' 
                hasSearch={{onSearch : this.SearchAliase}}
                hasAdd={{onAdd : this.AddTokenAlias}}
                addOn={(
                    <SC.FRow>
                        <ListCardView 
                            onListView={this.SetAliaseListMode.bind(this, true)}
                            onCardView={this.SetAliaseListMode.bind(this, false)}
                            isListView={this.state.isAliaseListView}
                            isCardView={!this.state.isAliaseListView}
                            style={{marginRight : '4px'}}
                            compact
                        />
                    </SC.FRow>                    
                )}
                style={{padding : 0, paddingLeft : '4px', marginBottom : '4px'}}
            />
        )
    }

    renderTokenAliases() {
        return (
            <SC.FCol style={{fontSize : '11px'}}>                        
                {
                    this.aliases.map((aliase, i) => {
                        if (aliase.filtered)
                            return null;
                        return (
                            <BorderRadiusTokenItem
                                key={aliase.id}
                                radius={aliase.tokenItem}
                                name={aliase.name}
                                isListView={this.state.isAliaseListView}
                                onSelect={this.SelectToken.bind(this, aliase.id)}
                                selected={aliase.id === this.GetSelectedTokenId()}
                                onPreview={{
                                    onMouseEnter : this.PreviewToken.bind(this, true, aliase.id, this.props.propName),
                                    onMouseLeave : this.PreviewToken.bind(this, false, aliase.id, this.props.propName)
                                }}
                            />   
                        )
                    })
                }
            </SC.FCol>
        )     
    }
    renderTokenList() {        
        return (
            <SC.FCol style={{fontSize : '11px'}}>                        
                {
                    this.tokens.map((radius, i) => {
                        if (radius.filtered)
                            return null;
                        return (
                            <BorderRadiusTokenItem
                                key={radius.id}
                                radius={radius}
                                isListView={this.state.isListView}
                                size={this.RadiusItemSize}
                                onSelect={this.SelectToken.bind(this, radius.id)}
                                selected={radius.id === this.GetSelectedTokenId()}
                                onPreview={{
                                    onMouseEnter : this.PreviewToken.bind(this, true, radius.id, this.props.propName),
                                    onMouseLeave : this.PreviewToken.bind(this, false, radius.id, this.props.propName)
                                }}
                            />   
                        )
                    })
                }
            </SC.FCol>
        )       
    }
    GetSelectedTokenId() {
        if (this.props.propName === 'tokenId')
            return this.props.tokenId;
        return Utils.JustGet(this.RadiusValues, null, this.GetStyleName(), 'TokenId');
    }
    renderTokenEditor() {        
        return (
            <BorderRadiusEditor 
                id={this.EditingToken.Id}
                newModel={this.EditingToken.IsNew ?  this.EditingToken.Model : null}  
                onClose={this.onCancelAddToken}
                onPreviewChange={this.EditingToken.IsNew ? this.PreviewNewToken : null}                
            />                
        )        
    }
    renderSelectedToken() {
                
    }    
    SearchTokenValue(token, filterText) {
        return token.value.toString().toUpperCase().indexOf(filterText) < 0;
    }
    SearchAliaseValue(aliase, filterText) {
        if (aliase.tokenItem) {
            return aliase.tokenItem.value.toString().toUpperCase().indexOf(filterText) < 0;
        }        
        return true;
    }
}