import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppLayout,
    Links,
    Events,
    Loading,
    MetaData,
    Globals
} from '../../../../../../importer';

import {TokenItemBox, TokenPanelHeader, TokenItemDragHandle, TokenItemBoxHoveredName} from '../common';
import { LeftScrollPanel } from '../../common';
import { SortableList, SortableListItem } from '../../../../../../components/SortableContainer';
import FilterTokenEditor from './item';
import BaseTokenList from '../BaseTokenList';
import {Droppable, Draggable} from 'react-beautiful-dnd';
import { StatefulTokenListItemName, StatefulTokenMarker, TokenLockedMarker } from '../colors';
import { GRANT_TYPES } from '../../../../manager';

export default class Filters extends BaseTokenList
{
    constructor(props) {
        super(props);

        this.title = 'FILTERS';
        this.moduleName = 'Filters';
        this.tokenType = Globals.ProjectManager.Tokens.Types.Filters;
        this.hasGroups = true;
        
        this.Ref_FilterEdit = React.createRef();
        this.onEditFilter = this.onEditFilter.bind(this);
        this.onDeleteFilter = this.onDeleteFilter.bind(this);
        this.onCloneFilter = this.onCloneFilter.bind(this);
        this.onCancelAddFilter = this.onCancelAddFilter.bind(this);
        this.onSubmitNewFilter = this.onSubmitNewFilter.bind(this);

        this.RemoveStateOverride = this.RemoveStateOverride.bind(this);        
        this.MoveValueToParentState = this.MoveValueToParentState.bind(this);
        this.SwitchStateToTokensOverriden = this.SwitchStateToTokensOverriden.bind(this);
        this.ApplyValueToCurrentState = this.ApplyValueToCurrentState.bind(this);        

        AppLayout.Refs.DesignSystem.Filters = this;

        super.LoadOptions();
        if (this.expanded || this.props.singleView) {
            this.expanded = true;
            this.Load(this.state.isGroupView);
        }
    }
    componentWillUnmount() {
        super.componentWillUnmount();
        AppLayout.Refs.DesignSystem.Filters = null;
    }
    Load(groupView) {        
        this.tokens = GetFilterTokenList();        
        if (groupView)
            this.groups = super.GetGroupedTokenList(this.tokens)
    }        
    SearchToken(filter) {
        let filterText;
        let filteredCount = 0;
        if (Utils.IsNotNullOrEmpty(filter))
            filterText = filter.toUpperCase();
        Utils.ForEach(this.tokens, (token, i) => {
            delete token.filtered;
            if (filterText) {
                if (token.name.toUpperCase().indexOf(filterText) < 0) {                    
                    token.filtered = true;
                }
            }
        });
        this.RenderId = Utils.Id();
        this.RCThrottledUpdate_1();
    }  
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.filterText !== nextProps.filterText) {
            setTimeout(() => {
                this.SearchToken(nextProps.filterText);
            }, 1);
            return false;
        }
        return super.shouldComponentUpdate(nextProps, nextState);
    }
    AddTokenToGroup(group) {
        if (!Globals.ProjectManager.CheckGrant(GRANT_TYPES.EDIT_TOKEN.ALL)) {
            return;
        }

        this.AddingToGroup = group;
        this.IsNew = true;
        this.EditFilterId = Utils.Id();
        this.EditFilterModel = {
            name : 'New Filter',
            items : {}
        };
        
        this.props.onPanelOverlay({
            show : true,
            render : (props) => {
                return (
                    <SC.FCol fw fh>
                        <TokenPanelHeader 
                            title='NEW FILTER' 
                            notBackClosable
                            hasAddCancel 
                            onClose={this.onCancelAddFilter} 
                            onCancel={this.onCancelAddFilter} 
                            onAdd={this.onSubmitNewFilter} 
                        />
                        <LeftScrollPanel>
                            <FilterTokenEditor 
                                ref={this.Ref_FilterEdit}
                                newModel={this.EditFilterModel}
                                GlobalState={props.GlobalState}
                                offline
                            />
                        </LeftScrollPanel>                        
                    </SC.FCol>                    
                )
            }
        })
    }
    RemoveStateOverride() {
        if (this.ValueState.ValueState && !this.ValueState.ValueState.inherited) {
            Globals.ProjectManager.Tokens.DeleteValue({
                id : this.EditFilterId,
                state : this.ValueState.ValueState.state
            })            
            delete this.ValueState.ValueState;
            Globals.ProjectManager.Tokens.GetStateValue({Id : this.EditFilterId, info: this.ValueState});
            this.tokenValueId = Utils.Id();
            Events.BCE(Events.GLOBAL.TOKENS_CHANGED);
        }        
    }
    MoveValueToParentState() {
        if (this.ValueState.ValueState) {
            Globals.ProjectManager.Tokens.MoveValueToState({
                id : this.EditFilterId,
                fromState : this.ValueState.ValueState.state
            });
            delete this.ValueState.ValueState;
            Globals.ProjectManager.Tokens.GetStateValue({Id : this.EditFilterId, info: this.ValueState});
            this.tokenValueId = Utils.Id();
            this.props.onUpdate();
        }
    }
    ApplyValueToCurrentState() {
        if (this.ValueState.ValueState) {
            Globals.ProjectManager.Tokens.MoveValueToState({
                id : this.EditFilterId,
                fromState : this.ValueState.ValueState.state,
                toState : Globals.ProjectManager.CurrentState
            });
            delete this.ValueState.ValueState;
            Globals.ProjectManager.Tokens.GetStateValue({Id : this.EditFilterId, info: this.ValueState});
            this.tokenValueId = Utils.Id();
            this.props.onUpdate();
        }
    }
    SwitchStateToTokensOverriden() {
        const ToState = this.ValueState.ValueState.state;
        delete this.ValueState.ValueState;
        Globals.ProjectManager.States.SetStateFromLabel(ToState);
    }
    onEditFilter(id) {        
        if (!Globals.ProjectManager.CheckGrant(GRANT_TYPES.EDIT_TOKEN.ALL)) {
            return;
        }
        
        this.EditFilterId = id;

        this.ValueState = {
            onRemoveOverride : this.RemoveStateOverride,
            onMoveToParent : this.MoveValueToParentState,
            onApplyToCurrentState : this.ApplyValueToCurrentState,
            onSwitchToOverridenState : this.SwitchStateToTokensOverriden
        };
        Globals.ProjectManager.Tokens.GetStateValue({Id : this.EditFilterId, info: this.ValueState});

        if (Globals.ProjectManager.AuditManager) {
            this.WillEditToken = Utils.DeepClone(Globals.ProjectManager.Tokens.Token(id));
        }

        this.props.onPanelOverlay({
            show : true,
            render : (props) => {
                if (props.GlobalState !== this.props.GlobalState) {
                    if (this.ValueState)
                        delete this.ValueState.ValueState;
                    Globals.ProjectManager.Tokens.GetStateValue({Id : this.EditFilterId, info: this.ValueState});
                }
                return (
                    <SC.FCol fw fh>
                        <TokenPanelHeader 
                            title='EDIT FILTER' 
                            hasAddCancel 
                            onClose={this.onSubmitNewFilter} 
                            onCancel={this.onCancelAddFilter} 
                            onAdd={this.onSubmitNewFilter} 
                            onDelete={this.onDeleteFilter} 
                            onClone={this.onCloneFilter}
                            stateValue={this.ValueState}
                        />
                        <LeftScrollPanel>
                            <FilterTokenEditor 
                                ref={this.Ref_FilterEdit}
                                id={id}
                                renderId={this.tokenValueId}
                                GlobalState={props.GlobalState}
                                GlobalStateId={props.GlobalStateId}
                                onCancel={this.onCancelAddFilter}
                                onChanged={() => {
                                    const ValueState = Utils.Get(this.ValueState, {
                                        state : Globals.ProjectManager.CurrentState
                                    }, 'ValueState');
                                    ValueState.inherited = false;
                                    this.props.onUpdate();
                                }}
                                onClosed={() => {
                                    const tokencurrent = Globals.ProjectManager.Tokens.Token(id);
                                    
                                    if (this.WillEditToken && tokencurrent) { 
                                        const diff = Utils.GetFlattenedDiff(this.WillEditToken, tokencurrent);
                                        if (diff.length > 0) {
                                            Globals.ProjectManager.AuditManager && Globals.ProjectManager.AuditManager.TokenChanged({TokenId : id, Change : {
                                                changes : diff
                                            }});
                                        }                                                                        
                                    }
                                    delete this.WillEditToken;
                                }}
                            />
                        </LeftScrollPanel>                        
                    </SC.FCol>                    
                )
            }
        })
    }    
    onCloneFilter() {
        const cloneId = Globals.ProjectManager.Tokens.Clone(this.EditFilterId, Globals.ProjectManager.Tokens.Types.Filters);
        this.Load(this.props);
        this.onEditFilter(cloneId);        
    }
    onDeleteFilter() {
        if (Globals.ProjectManager.Tokens.Delete(Globals.ProjectManager.Tokens.Types.Filters, this.EditFilterId)) {
            this.Load(this.props);
            this.onCancelAddFilter();
        }
    }
    onCancelAddFilter() {
        delete this.IsNew;
        delete this.EditFilterModel;
        delete this.EditFilterId;

        this.props.onPanelOverlay({close : true});
        this.Load(this.state.isGroupView);
        this.RCUpdate();
    }
    onSubmitNewFilter() {
        if (this.IsNew) {
            
            const FilterItem = AddNewFilterToken(this.EditFilterModel, this.EditFilterId);
            this.tokens.push(FilterItem);

            if (this.AddingToGroup) {
                this.AddingToGroup.tokens.push(FilterItem);
                Globals.ProjectManager.Tokens.AddTokenToGroup({type : this.tokenType, groupid : this.AddingToGroup.id, tokenid : FilterItem.id})
                delete this.AddingToGroup;
            } 

            this.WillScrollTo = this.EditFilterId;
            this.selectedId = this.EditFilterId;

            Events.BCE(Events.GLOBAL.TOKENS_CHANGED);
        }        
        else {
            const token = Globals.ProjectManager.Tokens.Token(this.EditFilterId);
            if (token) {
                const localpattern = Utils.Find(this.tokens, (item) => {return item.id === this.EditFilterId});
                localpattern.name = token.name;
                Events.BCE(Events.GLOBAL.TOKEN_VALUE_CHANGING);
            }            
        }
                
        this.onCancelAddFilter();
    }    
    renderTokenList(tokens, gorupId) {
        let style;
        if (this.state.isListView) {
            style = {
                marginLeft : '10px', marginRight : '10px', marginTop : '6px', 
                ...SC.Styles.Flex.Column
            }
        }
        else {
            style = {
                margin : '10px', marginTop : '4px', marginBottom : 0,
                ...SC.Styles.Flex.Column
            }
        }

        const FilterItems = [];
        if (this.state.isListView) {
            Utils.ForEach(tokens, (filter, i) => {                   
                !filter.filtered && FilterItems.push(
                    <SortableListItem
                        key={filter.id}
                        draggableId={filter.id}
                        index={i}
                        ItemBoxType={FilterListItem}
                        isDragDisabled={!this.HasEditGrant}
                        BoxProps={{
                            filter : filter,
                            onSelect : this.onEditFilter.bind(this, filter.id)
                        }}                
                    />
                )
            });            
        }
        else {               
            Utils.ForEach(tokens, (filter, i) => {                   
                !filter.filtered && FilterItems.push(
                    <SortableListItem
                        key={filter.id}
                        draggableId={filter.id}
                        index={i}
                        ItemBoxType={FilterCardItem}
                        isDragDisabled={!this.HasEditGrant}
                        BoxProps={{
                            filter : filter,
                            onClick : this.onEditFilter.bind(this, filter.id)
                        }}                
                    />
                )
            });
        }

        return (
            <Droppable 
                droppableId={gorupId}
                type={'TOKENS'}
                direction={this.state.isListView ? 'vertical' : 'horizontal'}
            >
                {
                    (provided, snapshot) => (
                        <div
                            {...provided.droppableProps} 
                            ref={provided.innerRef} 
                            style={{
                                ...provided.droppableProps.style,
                                ...style,
                                border : '1px dashed',                                                    
                                borderColor : snapshot.isDraggingOver ? SC.CurrentTheme.theme.color_brand : 'transparent',  
                            }}
                        >
                            {FilterItems}
                            {provided.placeholder}
                        </div>
                    )
                }
            </Droppable>
        )  
    }
}

export const AddNewFilterToken = (model, id) => {
    Globals.ProjectManager.Tokens.Add({
        type : Globals.ProjectManager.Tokens.Types.Filters,
        name : model.name,
        value : {
            items : model.items
        },
        id : id
    })
    let Filtervalue = Utils.GetFilterCss(model.filters, MetaData.Properties.filter); 
    return {
        id : id,
        name : model.name,
        value : Filtervalue
    };
}

export const FilterCardItem = ({filter, listView, onClick, sortableProps, onPreview, selected, small, style, ...rest}) => {    
    const size = '40px';
    const box = '60px';
    const style_box = {
        cursor : 'pointer',
        backgroundColor : SC.CurrentTheme.theme.back,            
        width : small ? box : '100%', height : small ? box : '120px', marginBottom : small ? 0 : '4px', ...SC.Styles.Flex.Cell, overflow : 'hidden',
        position : 'relative'
    };
    if (small) {
        style_box.backgroundImage = 'unset';
    }
    else {
        if (selected) {
            style_box.borderLeft = '2px solid';
            style_box.borderLeftColor = SC.CurrentTheme.theme.color_brand;
        }
    }
    let preview = (
        <SC.GridBackground small {...Utils.JustGet(sortableProps, {}, 'handleProps')} style={style_box} dark={!SC.CurrentTheme.theme.isLight} {...rest} onClick={onClick} {...onPreview}>
            <div 
                style={{
                    width : size, height : size, borderRadius : '3px', backgroundColor : 'rgba(184, 109, 240)', position : 'relative', 
                    transformOrigin : 'center', 
                    filter : filter.value,
                    // transform : small ? 'scale(0.3)' : 'scale(0.7)'
                }} 
            />
            {
                !listView && filter.valueState && 
                <StatefulTokenMarker
                    style={{
                        position : 'absolute',
                        top : '4px',
                        left : '4px'
                    }}
                    {...filter.valueState}
                />
            }
            {
                filter.locked && <TokenLockedMarker overlay />
            }
        </SC.GridBackground>
    )
    return preview;   
}

export const FilterListItem = ({onSelect, notDraggable, style, sortableProps, filter, name, onPreview, selected, ...rest}) => {
    if (!filter)
        return null;
    const style_box = {
        ...style,
        padding : '0px', paddingLeft : '6px',
        ...SC.Styles.Flex.RowAlcJsb
    }
    if (selected) {
        style_box.borderLeft = '1px solid';
        style_box.borderLeftColor = SC.CurrentTheme.theme.color_brand;
    }
    return (
        <TokenItemBox onClick={onSelect} style={style_box} {...onPreview} {...rest} selected={selected}>
            <SC.FRow f1 alc overflowHidden>
                <StatefulTokenListItemName 
                    name={name || filter.name}
                    sortableProps={sortableProps}
                    ValueState={filter.valueState}
                    locked={filter.locked}
                /> 
            </SC.FRow>                
            <FilterCardItem
                filter={filter}
                key={filter.id} 
                notDraggable={notDraggable}
                small
                listView
            />   
        </TokenItemBox>
    )
}

export const GetFilterTokenList = (StateArray) => {
    const tokenids = Globals.ProjectManager.Tokens.Filters();
    return GetFilterTokenListOfIds(tokenids, StateArray);
}

export const GetFilterTokenListOfIds = (tokenids, StateArray) => {
    const items = [];

    Utils.ForEach(tokenids, (id, i) => {
        items.push(GetFilterTokenItem(id, StateArray));
    });  
    return items;
}


export const GetFilterTokenItem = (id, StateArray) => {
    const token = Globals.ProjectManager.Tokens.Token(id);
    if (!token)
        return {};
    const info = {};
    const filter = Globals.ProjectManager.Tokens.ValueOf({model : token, statearray : StateArray, info : info});
    const filters = Utils.JustGet(filter, [], 'items');
    let filvalue = Utils.GetFilterCss(filters, MetaData.Properties.filter);        
    return {
        id : id,
        name : token.name,
        value : filvalue,
        valueState : info.ValueState,
        locked : token.locked
    }
}
