import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Strings,
    AppLayout,
    Events,
    Globals,
    MetaData
} from '../../../../../../../importer';
import { ItemNameEditor, GroupTitle, TokenItemBox, TokenGalleryView, DropFileArea, DropAreaContainer, TokenPanelHeader, PanelLabel } from '../../common';
import Switch from '../../../../../../../components/editors/Switch';
import { SortableList, SortableListItem } from '../../../../../../../components/SortableContainer';
import DataTypes from '../../../../../../../../toolabs-modules/toolabs-metaui/datatypes';
import { DraggableEditorBox, DraggableEditorHandle } from '../../../../../../../components/editors/transform/transformItemEditor';
import DropDownSelect from '../../../../../../../components/editors/enum_dropdown';
import TextValue from '../../../../../../../components/editors/textvalue';


export default class CustomTokenTypeEditor extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.SaveName = this.SaveName.bind(this);
        this.SaveDescription = this.SaveDescription.bind(this);
        
        this.GetItems = this.GetItems.bind(this);

        this.Ref_Name = React.createRef();    

        this.AddField = this.AddField.bind(this);
        this.onSortField = this.onSortField.bind(this);
        this.SaveFields = this.SaveFields.bind(this);        
        this.Load(this.props);        

    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.GlobalState !== nextProps.GlobalState || this.props.GlobalStateId !== nextProps.GlobalStateId || this.props.id !== nextProps.id) {
            if (!nextProps.newModel) {
                this.Load(nextProps);
                if (!this.token) {
                    this.props.onCancelAddToken();
                    return false;
                }
            }            
        }
        return true;
    }
    GetItems() {
        return Globals.ProjectManager.Tokens.TokenList(Globals.ProjectManager.Tokens.Types.CustomTokenTypes);
    }        
    Load(props = this.props) {
        if (props.newModel) {
            this.token = props.newModel;
        }
        else {
            this.token = Globals.ProjectManager.Tokens.Token(props.id);
        }
        
        this.fields = [];

        Utils.ForEach(this.token.fieldIds, (fieldId, i) => {
            const field = Utils.JustGet(this.token, null, 'fields', fieldId);
            if (field) {
                this.fields.push({
                    id : fieldId,
                    model : field
                });
            }            
        });
    }
    onSortField(oldIndex, newIndex) {
        Utils.ChangePlace(this.token.fieldIds, oldIndex, newIndex);
        Utils.ChangePlace(this.fields, oldIndex, newIndex);
        Globals.ProjectManager.Tokens.UpdateProp({id : this.props.id, name : 'fieldIds', value : this.token.fieldIds});
        this.RCUpdate();
    }
    onEditField(id) {

    }
    onDeleteField(id) {
        const fieldIds = Utils.Get(this.token, [], 'fieldIds');
        Utils.RemoveEquals(fieldIds, id);
        Utils.Remove(this.fields, (item) => {return item.id === id});
        Utils.UnSet(this.token, 'fields', id);        
        this.SaveFields();
        if (this.props.id) {
            const tokenIds = Globals.ProjectManager.Tokens.CustomTokenIds(this.props.id);
            Utils.ForEach(tokenIds, (tokenId, i) => {
                Globals.ProjectManager.Tokens.DeleteProp({id : tokenId, name : id});
            });

        }        
        this.RCUpdate();
    }
    AddField() {
        const id = Utils.Id();

        const model = {
            name : 'New Field',
            dataType : MetaData.DataTypes.string
        }
        this.fields.push({
            id : id,
            model : model
        });
        const fieldIds = Utils.Get(this.token, [], 'fieldIds');
        fieldIds.push(id);
        Utils.Set(this.token, model, 'fields', id);
        this.SaveFields();
        this.FillFocusToField = id;
        this.RCUpdate();
    }
    SaveFields() {
        Globals.ProjectManager.Tokens.UpdateProp({id : this.props.id, name : 'fieldIds', value : this.token.fieldIds});
        Globals.ProjectManager.Tokens.UpdateProp({id : this.props.id, name : 'fields', value : this.token.fields});
    }
    SaveName(name) {
        if (this.props.newModel) {
            this.props.newModel.name = name;
            this.nameChanged = true;
        }
        else
            Globals.ProjectManager.Tokens.ChangeTokenName(this.props.id, name);
        this.RCUpdate();
    }    
    SaveDescription(value) {
        if (this.props.newModel) {
            this.props.newModel.description = value;
        }
        else
            Globals.ProjectManager.Tokens.UpdateProp({id : this.props.id, name : 'description', value : value});
        this.RCUpdate();
    }
    SaveValue() {
        if (this.props.newModel) {
            this.props.newModel.value = this.tokenvalue;       
        }
        else {            
            Globals.ProjectManager.Tokens.SetValue({
                id : this.props.id,
                value : this.tokenvalue
            })
        }        
    }        
    renderCustom() {        
        const fieldItems = [];

        
        Utils.ForEach(this.fields, (field, i) => {                               
            fieldItems.push(
                <SortableListItem
                    key={field.id}
                    draggableId={field.id}
                    index={i}
                    ItemBoxType={TokenTypeFieldDefinition}
                    isTranfsormed
                    BoxProps={{
                        id : field.id,
                        model : field.model,
                        willFocus : field.id === this.FillFocusToField,
                        notFirst : i > 0,
                        onClick : this.onEditField.bind(this, field.id),
                        onDelete : this.onDeleteField.bind(this, field.id),
                        onUpdate : this.SaveFields,
                        GetFields : () => {
                            return this.fields
                        }
                    }}                
                />
            )
        });
        
        return (
            <SC.FCol fw fh style={{backgroundColor : SC.CurrentTheme.theme.back, padding : '10px', boxSizing : 'border-box'}}>
                <ItemNameEditor
                    noMargin
                    fontSize='12px'                        
                    name={this.token.name}
                    onSaveName={this.SaveName}
                    onGetItems={this.GetItems}
                    model={this.token}
                />
                <PanelLabel style={{marginBottom : '4px', marginTop : '12px'}}>DESCRIPTION</PanelLabel>
                <TextValue 
                    notF1
                    multiline 
                    dark
                    minHeight={80}
                    value={this.token.description}
                    onBlured={this.SaveDescription}
                    onChange={(value) => {
                        this.token.description = value;
                        this.RCUpdate();
                    }}
                />
                <SC.FCol f1>
                    <GroupTitle 
                        title='Fields' 
                        subGroup
                        hasAdd={{onAdd : this.AddField}}     
                        style={{padding : 0, marginTop : '16px'}}                
                    />
                    <SortableList
                        style={{
                            marginTop : '4px',
                            marginLeft : '-12px', marginRight : '-8px',
                            transition : 'all 0.2s ease',
                            fontSize : '11px'
                        }}
                        onSort={this.onSortField}
                    >
                        {fieldItems}
                    </SortableList>
                </SC.FCol>                
            </SC.FCol>
        )
    }
}


class TokenTypeFieldDefinition extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }

        this.SaveName = this.SaveName.bind(this);
        this.onChangeType = this.onChangeType.bind(this);
        this.onChangeProp = this.onChangeProp.bind(this);        
    }
    componentDidUpdate(prevProps, prevState) {
        this.state.ShouldUpdate = false;
    }
    onChangeType(type) {
        this.props.model.dataType = type;
        this.setState({ShouldUpdate : true})
        this.props.onUpdate();
    }
    onChangeProp(prop, value) {
        this.props.model[prop] = value;
        this.setState({ShouldUpdate : true})
        this.props.onUpdate();
    }
    SaveName(name) {
        this.props.model.name = name;
        this.props.onUpdate();
    }
    render() { 
        const {model} = this.props;
        const dataTypeItems = [
            {id : DataTypes.string, label : DataTypes.Labels[DataTypes.string]},
            {id : DataTypes.boolean, label : DataTypes.Labels[DataTypes.boolean]},
            {id : DataTypes.number, label : DataTypes.Labels[DataTypes.number]},
            // {id : 'token', label : 'Token'}
        ];

        let dataTypeProperties;

        if (model.dataType === 'token') {
            dataTypeProperties = (
                <React.Fragment>
                    <div>Token Type</div>
                    <DropDownSelect 
                        hasBorder            
                        autoHeight
                        xsmall
                        toRight
                        fitWidth
                        style={{flex : 1}}
                        items={[
                            {id : Globals.ProjectManager.Tokens.Types.COLOR, label : 'Colors'},
                            {id : Globals.ProjectManager.Tokens.Types.Shadows, label : 'Shadows'},
                            // {id : Globals.ProjectManager.Tokens.Types.Fonts, label : 'Font'},
                            {id : Globals.ProjectManager.Tokens.Types.TextPatterns, label : 'Text Style'},
                            {id : Globals.ProjectManager.Tokens.Types.Spacings, label : 'Spacing'},
                            {id : Globals.ProjectManager.Tokens.Types.Borders, label : 'Borders'},
                            {id : Globals.ProjectManager.Tokens.Types.BorderRadiuses, label : 'Radius'},
                            {id : Globals.ProjectManager.Tokens.Types.Icons, label : 'Iconography'},
                            {id : Globals.ProjectManager.Tokens.Types.Images, label : 'Images'},
                            {id : Globals.ProjectManager.Tokens.Types.Transitions, label : 'Motion'},
                            {id : Globals.ProjectManager.Tokens.Types.Transforms, label : 'Transforms'},
                            {id : Globals.ProjectManager.Tokens.Types.Filters, label : 'Filters'},
                            {id : Globals.ProjectManager.Tokens.Types.Sounds, label : 'Sounds'},
                            {id : Globals.ProjectManager.Tokens.Types.Booleans, label : 'Booleans'},
                            {id : Globals.ProjectManager.Tokens.Types.ContentTexts, label : 'Microcopy (UX Texts)'},   
                        ]}
                        value={model.tokenType}
                        onChange={this.onChangeProp.bind(this, 'tokenType')}
                    />
                </React.Fragment>
            )
        }

        return (  
            <DraggableEditorBox notFirst={this.props.notFirst}>
                <DraggableEditorHandle {...this.props.sortableProps.handleProps} style={{paddingLeft : '8px', paddingRight : '4px'}} ><SC.Icons.DragIcon xsmall /></DraggableEditorHandle>
                <SC.FCol f1>
                    <SC.FRow justifyEnd>
                        
                    </SC.FRow>
                    <div style={{
                        display : 'grid',
                        gridTemplateColumns : '1fr 2fr',
                        gridGap : '8px',
                        flex : 1,
                        padding : '8px',
                        alignItems : 'center'
                    }}>
                        <div>Name</div>
                        <SC.FRow alc >
                            <ItemNameEditor
                                noMargin
                                noLabel
                                dark
                                autoFocus={this.props.willFocus}
                                InputType={SC.Form_Input}
                                fontSize='12px'                        
                                name={model.name}
                                onSaveName={this.SaveName}
                                onGetItems={this.props.GetFields}
                                model={model}
                                themeId={AppLayout.ThemeId}
                            />  
                            <SC.Icons.Icon_Button hasFill style={{cursor : 'pointer', marginRight : '-8px'}} onClick={this.props.onDelete} >
                                <SC.Icons.Icon_Delete size={16}/>
                            </SC.Icons.Icon_Button>
                        </SC.FRow>                        
                        <div>Data Type</div>
                        <DropDownSelect 
                            hasBorder            
                            autoHeight
                            xsmall
                            toRight
                            fitWidth
                            style={{flex : 1}}
                            items={dataTypeItems}
                            value={model.dataType}
                            onChange={this.onChangeType}
                        />
                        {dataTypeProperties}
                    </div>
                </SC.FCol>                
            </DraggableEditorBox>
        );
    }
}