import React from 'react';
import ReactDOM from 'react-dom';
import {
    Utils,
    AppLayout
} from '../../importer';

export default class LeaderLineProxy extends React.Component {
    constructor(props) {
        super(props);

        this.remove = this.remove.bind(this);
        this.drawLine = this.drawLine.bind(this);
        this.updatePosition = this.updatePosition.bind(this);
        this.stopTimer = this.stopTimer.bind(this);
    }
    componentDidMount() {
        setTimeout(() => {
            this.waitWhenRefIsReady();
        }, this.props.delay || 200);
        // scroll and resize listeners could be assigned here

        this.Id = Utils.Id();
        AppLayout.Refs.LeaderLines[this.Id] = this;   
    }

    componentWillUnmount() {
        this.willUnmount = true;
        delete AppLayout.Refs.LeaderLines[this.Id];
        this.stopTimer();
        if (this.line) {
            this.remove();
        }        
    }
    shouldComponentUpdate(nextProps, nextState) {
        const that = this;
        if (that.line) {
            if (!nextProps.start || !nextProps.end || !nextProps.start.current || !nextProps.end.current) {
                this.remove();
                delete this.line;
                that.waitWhenRefIsReady();
            }
            else {
                if (nextProps.startid !== that.props.startid && nextProps.start && nextProps.start.current) {
                    that.line.start = window.LeaderLine.pointAnchor(ReactDOM.findDOMNode(nextProps.start.current), {x : '0%', y : '50%'})
                }
                if (nextProps.endid !== that.props.endid && nextProps.end && nextProps.end.current) {
                    that.line.end = window.LeaderLine.pointAnchor(ReactDOM.findDOMNode(nextProps.end.current), {x : '100%', y : '50%'});
                }
                that.line.position();
            }            
        }
        else if (!this.timer) {
            if ((!this.props.start || !this.props.end) && nextProps.start && nextProps.end) {
                setTimeout(() => {
                    this.waitWhenRefIsReady();
                }, this.props.delay || 200);
            }                
        }
        // you should disable react render at all
        return false;
    }

    stopTimer() {        
        clearInterval(this.timer);
        delete this.timer;
    }
    waitWhenRefIsReady(props = this.props) {
        // refs are generated via mutations - wait for them
        if (!this.timer && props.start && props.end) {
            const that = this;
            that.timer = setInterval(() => {
                if (!that.props.start || !that.props.end)
                    that.stopTimer();
                else if (that.props.start.current && that.props.end.current) {
                    that.stopTimer();
                    that.drawLine(that.props.start.current, that.props.end.current);
                }
            }, 5);
        }
    }

    remove() {
        if (this.line) {
            const line = this.line;
            line.hide('draw');
            setTimeout(() => {
                line.remove();
            }, 200);            
        }
    }

    updatePosition() {
        if (this.line)
            this.line.position();
    }
    drawLine(start, end) {
        const startnode = ReactDOM.findDOMNode(start);
        const endnode = ReactDOM.findDOMNode(end);
        if (!startnode || !endnode)
            return;
        const that = this;

        setTimeout(() => {
            if (that.willUnmount)
                return;

            that.line = new window.LeaderLine({
                start: startnode,
                end: endnode,
                size: 2,
                color: that.props.color || 'rgba(115, 202, 212, 0.7)',
                startSocket: that.props.startSocket || 'left',
                startPlugSize: 1,
                endSocket: that.props.endSocket || 'right',
                startPlug: that.props.startPlug || 'disc',
                endPlug: that.props.endPlug || 'disc',
                dash : that.props.dash,
                animOptions: {duration: 50, timing: 'linear'},
                path : that.props.path || 'fluid',
                endLabel: that.props.endLabel ? window.LeaderLine.captionLabel(that.props.endLabel, {color : '#f5f5f5', outlineColor : '#222326', fontSize : '14'}) : null,
                style: {
                    'z-index': 9999999999
                },
                hide : true
            });
            that.line.show('draw');
        }, 1);
        
    }
    render() {
        return null;
    }
}