import React from 'react';
import ReactDOM from 'react-dom';

import {
    MetaData,
    Utils,
    UIUtils,
    SC,
    Strings,
    AppLayout
} from '../../../../../../importer';

import BaseGeneric from './base';
import Generic_Button from './button';
import Generic_Text from './text';
import Generic_Toggle from './toggle';
import Generic_Checkbox from './checkbox';
import Generic_TextInput from './textinput';
import Generic_Textarea from './textarea';
import Generic_RangeInput from './rangeinput';
import Generic_ProgressBar from './progressbar';
import Generic_SelectBox from './selectbox';
import Generic_DateInput from './dateinput';
import Generic_Table from './table';
import Generic_Carousel from './carousel';
import Generic_Tabs from './tabs';
import Generic_Accordion from './accordion';

export default (props) => {

    let ItemType = BaseGeneric;
    if (props.GenericType === MetaData.Components.Generic.Button.Type)
        ItemType = Generic_Button;
    else if (props.GenericType === MetaData.Components.Generic.Text.Type)
        ItemType = Generic_Text;
    else if (props.GenericType === MetaData.Components.Generic.Toggle.Type)
        ItemType = Generic_Toggle;
    else if (props.GenericType === MetaData.Components.Generic.RangeInput.Type)
        ItemType = Generic_RangeInput;
    else if (props.GenericType === MetaData.Components.Generic.ProgressBar.Type)
        ItemType = Generic_ProgressBar;
    else if (props.GenericType === MetaData.Components.Generic.Input.Type)
        ItemType = Generic_TextInput;
    else if (props.GenericType === MetaData.Components.Generic.Textarea.Type)
        ItemType = Generic_Textarea;
    else if (props.GenericType === MetaData.Components.Generic.Checkbox.Type)
        ItemType = Generic_Checkbox;
    else if (props.GenericType === MetaData.Components.Generic.Select.Type)
        ItemType = Generic_SelectBox;
    else if (props.GenericType === MetaData.Components.Generic.DateInput.Type)
        ItemType = Generic_DateInput;
    else if (props.GenericType === MetaData.Components.Generic.Table.Type)
        ItemType = Generic_Table;
    else if (props.GenericType === MetaData.Components.Generic.Carousel.Type)
        ItemType = Generic_Carousel;
    else if (props.GenericType === MetaData.Components.Generic.Tab.Type)
        ItemType = Generic_Tabs;
    else if (props.GenericType === MetaData.Components.Generic.Accordion.Type)
        ItemType = Generic_Accordion;
    return (
        <ItemType 
            {...props}
        />
    )
}