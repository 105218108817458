import {
    Utils
} from '../../../../importer';


export const ResizerIds = {
    TOPLEFT : 0,
    TOP : 1,
    TOPRIGHT : 2,
    LEFT : 3,
    RIGHT : 4,
    BOTTOMLEFT : 5,
    BOTTOM : 6,
    BOTTOMRIGHT : 7,
    ALL : 8,
    FREE : 9
}

const HANDLE_SIZE_HALF = 4;
const SPACER_OFFSET = -11;

export default {
    top : {
        id : ResizerIds.TOP,
        name : 'top',
        height : -1,        
        width : 0,
        style : {
            bottom : '100%',
            left : '50%',
            right : '50%',
            transform : 'translateX(-50%) translateY(50%)',
            cursor : 'n-resize',
        },        
        margin : 'marginTop',
        padding : 'paddingTop',
        cursor_spacer : 'row-resize'
    },
    bottom : {
        id : ResizerIds.BOTTOM,
        name : 'bottom',
        height : 1,
        width : 0,
        style : {
            top : '100%',
            left : '50%',
            right : '50%',
            transform : 'translateX(-50%) translateY(-50%)',
            cursor : 'n-resize'
        },        
        margin : 'marginBottom',
        padding : 'paddingBottom',
        cursor_spacer : 'row-resize'
    },
    left : {
        id : ResizerIds.LEFT,
        name : 'left',
        width : -1,
        height : 0,
        style : {
            top : '50%',
            bottom : '50%',
            left : 0,
            transform : 'translateX(-50%) translateY(-50%)',
            cursor : 'e-resize'
        },        
        margin : 'marginLeft',
        padding : 'paddingLeft',
        cursor_spacer : 'col-resize'
    },
    right : {
        id : ResizerIds.RIGHT,
        name : 'right',
        width : 1,
        height : 0,
        style : {
            top : '50%',
            bottom : '50%',
            right : 0,
            transform : 'translateX(50%) translateY(-50%)',
            cursor : 'e-resize'
        },        
        margin : 'marginRight',
        padding : 'paddingRight',
        cursor_spacer : 'col-resize'
    },
    topLeft : {
        id : ResizerIds.TOPLEFT,
        width : -1,
        height : -1,
        style : {
            bottom : '100%',
            right : '100%',
            cursor : 'nwse-resize',
            transform : 'translateX(50%) translateY(50%)'
        }
    },
    topRight : {
        id : ResizerIds.TOPRIGHT,
        width : 1,
        height : -1,
        style : {
            bottom : '100%',
            left : '100%',
            cursor : 'nesw-resize',
            transform : 'translateX(-50%) translateY(50%)'
        }
    },
    bottomLeft : {
        id : ResizerIds.BOTTOMLEFT,
        width : -1,
        height : 1,
        style : {
            top : '100%',
            right : '100%',
            cursor : 'nesw-resize',
            transform : 'translateX(50%) translateY(-50%)'
        }
    },
    bottomRight : {
        id : ResizerIds.BOTTOMRIGHT,
        width : 1,
        height : 1,
        style : {
            top : '100%',
            left : '100%',
            cursor : 'nwse-resize',
            transform : 'translateX(-50%) translateY(-50%)'
        }
    }
}