import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    UIUtils,
    Globals
} from '../../../../../../importer';

import styled, {css} from 'styled-components';
import { GroupTitle, TokenPanelHeader, TokenGroup, TokenItemBox } from '../../designsystem/common';
import { LeftScrollPanel, StyleInputName } from '../../common';
import { InfoPanel, InfoLine } from '../../../../../../components/info';
import Input_Name from '../../../../../../components/editors/input_name';
import { SortableList, SortableListItem } from '../../../../../../components/SortableContainer';
import { motion } from 'framer-motion';
import MediaStates from './mediastates';

export default class SystemStateEditor extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.AddState = this.AddState.bind(this);
        this.DeleteState = this.DeleteState.bind(this);
        this.onSortStates = this.onSortStates.bind(this);
        
        this.GetStateModel = this.GetStateModel.bind(this);
        this.SkipIntro = this.props.skipIntro;
    }
    AddState() {
        if (this.props.global) {
            this.NewStateId = Globals.ProjectManager.States.AddState('New State');
        }
        else {
            this.NewStateId = this.props.GetComponentManager().States.AddState('New State');
        }
        this.RCUpdate();
    }
    componentDidMount() {
        super.componentDidMount();
        if (this.props.global) {
            this.OriginalStates = Utils.DeepClone(Globals.ProjectManager.States.Get());
        }

    }
    componentWillUnmount() {
        if (this.OriginalStates && Globals.ProjectManager.AuditManager) {
            const NewStates = Globals.ProjectManager.States.Get();
            const diff = Utils.GetFlattenedDiff(this.OriginalStates, NewStates);
            delete this.OriginalStates;
    
            if (diff.length > 0) {
                Globals.ProjectManager.AuditManager.InsertLog({
                    EntityId : 'GlobalState', 
                    Type : 'System',
                    Data : { changes : diff } 
                });
            }
        }        

        super.componentWillUnmount();        
    }
    componentDidUpdate(prevProps, prevState) {
        delete this.NewStateId;
        this.props.onUpdateLayout && this.props.onUpdateLayout();
    }
    DeleteState(StateId) {
        if (this.props.global) {
            Globals.ProjectManager.States.SetSelectedVariation(StateId);
            Globals.ProjectManager.States.Delete(StateId);
        }
        else {
            this.props.GetComponentManager().States.Delete(StateId);
        }
        this.RCUpdate();
    }
    onSortStates(oldIndex, newIndex) {
        if (this.props.global) {
            Globals.ProjectManager.States.ChangeOrder(oldIndex, newIndex);
        }
        else {
            this.props.GetComponentManager().States.ChangeOrder(oldIndex, newIndex);
        }
        this.RCUpdate();
    }
    GetStateModel(id) {
        if (this.props.global) {
            return Globals.ProjectManager.States.GetState(id);        
        }
        else {
            return this.props.GetComponentManager().States.GetState(id);
        }
    } 
    renderCustom() {
        let states, currentVariations;
        if (this.props.global) {
            states = Globals.ProjectManager.States.Get().Order || [];
            currentVariations = Globals.ProjectManager.States.GetSelectedVariation();
        }
        else {
            states = this.props.GetComponentManager().States.Order();
            currentVariations = this.props.GetComponentManager().States.GetSelectedVariation();
        }

        const stateitems = [];
        let hasStates = false;
        let index = 0;
        states.map((stateid, i) => {
            const stateModel = this.GetStateModel(stateid);
            if (stateModel.CustomSelector || stateModel.SystemState && !this.props.preview && !this.props.systemStates) {

            }
            else {
                hasStates = true;
                stateitems.push(
                    <SortableListItem 
                        key={stateid}
                        draggableId={stateid}
                        index={index++}
                        ItemBoxType={StateEditor}
                        isTranfsormed
                        BoxProps={{
                            global : this.props.global,
                            vertical : true,
                            id : stateid,
                            isNew : stateid === this.NewStateId,
                            key : stateid,
                            stateModel : stateModel,
                            SelectedVariation : currentVariations,
                            onSetStateVariation : this.SetStateVariation,
                            GetStateModel : this.GetStateModel,
                            preview : this.props.preview,
                            compact : true,
                            GetComponentManager : this.props.GetComponentManager,
                            onDeleteState : this.DeleteState.bind(this, stateid),
                            onUpdateLayout : this.props.onUpdateLayout
                        }}
                    />                   
                );    
            }                                                        
        }) 

        let content;

        if (hasStates || this.SkipIntro) {
            if (this.props.global) {
                content = (
                    <SC.FCol fw fh style={{ boxSizing : 'border-box'}}>
                        {
                            !this.props.noBreakpoints && 
                            <MediaStates 
                                {...this.props}
                            />
                        }                        
                        <GroupTitle title='STATES' 
                            hasAdd={{onAdd : this.AddState, title : 'Add New State'}}
                            style={{paddingLeft : '12px', paddingBottom : '8px', flex : 'unset'}}
                        >                            
                        </GroupTitle>
                        <SortableList
                            type='STATES'
                            onSort={this.onSortStates}
                        >
                            {stateitems}
                        </SortableList>                      
                    </SC.FCol>  
                )
            }    
            else {
                content = (
                    <SC.FCol fw fh style={{ boxSizing : 'border-box'}}>                        
                        <GroupTitle 
                            title={this.props.hasCloseTitle ? <SC.BackClosableTitle title={'EDITING STATES'} onClose={this.props.onClose} style={{flex:1}}/> : 'STATES'}
                            hasAdd={{onAdd : this.AddState}}
                            style={{paddingLeft : this.props.hasCloseTitle ? '4px' : '12px', paddingBottom : '8px'}}
                        />
                        <SortableList
                            onSort={this.onSortStates}
                        >
                            {stateitems}
                        </SortableList>                      
                    </SC.FCol>  
                )
            }        
        }
        else {
            content = (
                <SC.FCol f1>
                    <SC.Buttons.RoundButton style={{
                        alignSelf : 'center',
                        margin : '16px',
                        width : 'auto',
                        paddingLeft : '16px',
                        paddingRight : '16px',
                        minHeight : '30px',
                        height : 'auto'
                    }} xsmall onClick={() => {this.SkipIntro = true; this.RCUpdate();}}>
                        Start adding states
                    </SC.Buttons.RoundButton>
                    <StarterInfoPanel />
                </SC.FCol>                
            )
        }
        
        if (this.props.justContent)
            return content;

        return (
            <SC.FCol fw fh style={{position : 'relative'}}>
                <TokenPanelHeader title={this.props.global ? 'EDITING SYSTEM STATES' : 'COMPONENT STATES'} onClose={this.props.onClose} />
                <SC.FCol fw fh style={{backgroundColor : SC.CurrentTheme.theme.back}}>
                    <LeftScrollPanel>
                        {content}                  
                    </LeftScrollPanel>
                </SC.FCol>                                        
            </SC.FCol>            
        )
    }
}

class StateEditor extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.AddVariation = this.AddVariation.bind(this);
        this.DeleteVariation = this.DeleteVariation.bind(this);
        this.SaveStateName = this.SaveStateName.bind(this);
        this.SaveDefaultStateName = this.SaveDefaultStateName.bind(this);
        this.SaveStateModel = this.SaveStateModel.bind(this);

        this.onSortStates = this.onSortStates.bind(this);

        this.Ref_Name = React.createRef();
        this.Ref_DefaultName = React.createRef();
    }
    onSortStates(oldIndex, newIndex) {
        if (this.props.global) {
            Globals.ProjectManager.States.ChangeVariationOrder(this.props.id, oldIndex, newIndex);
        }
        else {
            this.props.GetComponentManager().States.ChangeVariationOrder(this.props.id, oldIndex, newIndex);
        }
        this.RCUpdate();
    }
    componentDidUpdate(prevProps, prevState) {
        delete this.NewVariationId;
        this.props.onUpdateLayout && this.props.onUpdateLayout();
    }
    AddVariation() {
        if (this.props.global) {
            this.NewVariationId = Globals.ProjectManager.States.AddStateVariation(this.props.id, 'New Variant');
        }
        else {
            this.NewVariationId = this.props.GetComponentManager().States.AddStateVariation(this.props.id, 'New Variant');
        }
        this.RCUpdate();
    }
    DeleteVariation(id) {
        if (this.props.global) {
            Globals.ProjectManager.States.DeleteStateVariation(this.props.id, id);
        }
        else {
            this.props.GetComponentManager().States.DeleteStateVariation(this.props.id, id);
        }
        this.RCUpdate();
    }
    SaveStateName() {
        if (this.Ref_Name.current) {
            const name = this.Ref_Name.current.GetValue();
            if (this.props.global) {
                Globals.ProjectManager.States.UpdateStateProp(this.props.id, 'name', name);
            }
            else {
                this.props.GetComponentManager().States.UpdateStateProp(this.props.id, 'name', name);
            }
            Events.BCE(Events.DESIGNER.COMPONENT.STATE.CHANGED);
            this.RCUpdate();
        } 
    }
    SaveDefaultStateName() {
        if (this.Ref_DefaultName.current) {
            const name = this.Ref_DefaultName.current.GetValue();
            if (this.props.global) {
                Globals.ProjectManager.States.UpdateStateProp(this.props.id, 'defaultName', name);
            }
            else {
                this.props.GetComponentManager().States.UpdateStateProp(this.props.id, 'defaultName', name);
            }
            Events.BCE(Events.DESIGNER.COMPONENT.STATE.CHANGED);
            this.RCUpdate();
        } 
    }
    SaveStateModel() {
        if (this.props.global) {
            Globals.ProjectManager.States.UpdateState(this.props.id, this.props.stateModel);
        }
        else {
            this.props.GetComponentManager().States.UpdateState(this.props.id, this.props.stateModel);
        }
        this.RCUpdate();
    }
    renderCustom() {
        const {stateModel} = this.props;
        let variations;

        if (stateModel.SingleVariation) {

        }
        else {
            variations = [];
            stateModel.Variations && stateModel.Variations.Order && Utils.ForEach(stateModel.Variations.Order, (VariationId, i) => {
                const variation = stateModel.Variations[VariationId];
                variations.push(
                    <SortableListItem 
                        key={VariationId}
                        draggableId={VariationId}
                        index={i}
                        ItemBoxType={StateVariationItem}
                        isTranfsormed
                        BoxProps={{
                            model : variation,
                            isNew : VariationId === this.NewVariationId,
                            onDelete : this.DeleteVariation.bind(this, VariationId),
                            onSave : this.SaveStateModel
                        }}
                    />
                )
            });
        }

        return (
            <motion.div
                style={{margin : '8px', marginTop : 0}}
                initial={{y : -8}}
                animate={{y : 0}}                
            >
                <SC.InnerPanelGroup style={{padding : 0, flexDirection : 'column', flex : 'unset'}} >
                    <SC.FRow style={{padding : '4px', marginBottom : '8px'}} alc jsb>
                        <SC.DragBox {...this.props.sortableProps.handleProps} first style={{cursor:'move', height : 'unset', marginRight :'4px', border : 'none'}} >
                            <SC.Icons.DragIcon xsmall />
                        </SC.DragBox>      
                        <SC.FRow f1 jsb alc >
                            <Input_Name 
                                ref={this.Ref_Name}
                                InputType={StyleInputName}
                                styleProps={{
                                    placeholder : 'State Name',
                                    style : {                                    
                                        marginLeft : '4px'
                                    },
                                    onBlur : this.SaveStateName
                                }}
                                onValidate={this.ValidateStateName}                                            
                                value={stateModel.name}
                                required
                                onChange={this.onChangeStateName}                                            
                                autoFocus={this.props.isNew}
                                autoSelect={this.props.isNew}
                            />
                            <SC.Icons.Icon_Button hasFill hasCursor style={{marginLeft : '4px'}} onClick={this.props.onDeleteState}>
                                <SC.Icons.Icon_Delete size={16} />
                            </SC.Icons.Icon_Button>
                        </SC.FRow>
                    </SC.FRow>
                    <SC.FCol style={{
                        marginBottom : '4px'
                    }}>
                        <GroupTitle title='Variants' hasAdd={{onAdd : this.AddVariation, title : 'Add New Variant'}} style={{paddingLeft : '12px'}}/>
                        <SC.InnerPanel style={{
                            margin : '8px',
                            marginTop : '4px',
                            padding : '4px',
                            fontSize: '10px'
                        }}>
                            <SC.FRow alc justifyCenter>
                                <div>Default</div>
                                {
                                    !stateModel.SingleVariation && 
                                    <Input_Name 
                                        ref={this.Ref_DefaultName}
                                        InputType={StyleInputName}
                                        styleProps={{
                                            placeholder : 'Default Variant Name',
                                            style : {                                    
                                                marginLeft : '8px'
                                            },
                                            onBlur : this.SaveDefaultStateName
                                        }}
                                        onValidate={this.ValidateStateName}
                                        value={Utils.JustGet(stateModel, 'Default', 'defaultName')}
                                    />
                                }                                  
                            </SC.FRow>
                        </SC.InnerPanel>   
                        <SortableList
                            style={{
                                paddingLeft : '8px',
                                paddingRight : '8px',
                            }}
                            type='VARIATIONS'
                            onSort={this.onSortStates}
                        >
                            {variations}
                        </SortableList>             
                    </SC.FCol>  
                </SC.InnerPanelGroup>
            </motion.div>            
        )     
    }
}

class StateVariationItem extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.Ref_Name = React.createRef();
        this.SaveStateName = this.SaveStateName.bind(this);
    }
    SaveStateName() {
        const {model} = this.props;
        if (this.Ref_Name.current) {
            model.name = this.Ref_Name.current.GetValue();
            this.props.onSave();
        }
    }
    renderCustom() {
        const {model} = this.props;
        return (
            <SC.InnerPanel style={{
                marginBottom : '4px',
                padding : '4px'
            }}>
                <SC.FRow f1 jsb alc >
                    <SC.FRow f1 alc>
                        <SC.DragBox {...this.props.sortableProps.handleProps} first style={{cursor:'move', height : 'unset', marginRight :'4px', border : 'none'}} >
                            <SC.Icons.DragIcon xsmall />
                        </SC.DragBox>    
                        <Input_Name 
                            ref={this.Ref_Name}
                            InputType={StyleInputName}
                            styleProps={{
                                placeholder : 'State Name',
                                style : {                                    
                                    marginLeft : '4px'
                                },
                                onBlur : this.SaveStateName
                            }}
                            onValidate={this.ValidateStateName}                                            
                            value={model.name}
                            required
                            onChange={this.onChangeStateName}                                            
                            autoFocus={this.props.isNew}
                            autoSelect={this.props.isNew}
                        />                        
                    </SC.FRow>
                    
                    <SC.Icons.Icon_Button hasFill hasCursor style={{marginLeft : '4px'}} onClick={this.props.onDelete}>
                        <SC.Icons.Icon_Delete size={16} />
                    </SC.Icons.Icon_Button>
                </SC.FRow>
            </SC.InnerPanel>  
        )
    }
}

const Triangle = (props) => {
    return (
        <SC.Svg height={20} viewBox="0 0 46 40" style={props.style}>
            <g fill={props.color || '#d1d1d1'}>
                {props.filled ? <path d="M41.68 37.5H4.32L23 5.02 41.68 37.5z"/> : null}
                <path d="M23 10.03L8.64 35h28.72L23 10.03M23 0l23 40H0L23 0z"/>
            </g>
        </SC.Svg>
    )
}

const Circle = styled.div`
    width : 20px;
    height : 20px;
    border-radius : 50%;
    background-color : ${props => props.filled ? (props.color || '#d1d1d1') : 'unset'};
    box-sizing : border-box;
    border : 3px solid ${props => props.color || '#d1d1d1'};
`;

const Square = styled.div`
    width : 20px;
    height : 20px;
    background-color : ${props => props.filled ? (props.color || '#d1d1d1') : 'unset'};
    box-sizing : border-box;
    border : 3px solid ${props => props.color || '#d1d1d1'};
`;

const ColorBox = styled.div`
    width : 20px;
    border-radius : 4px;
    height : 16px;
    background-color : ${props => props.color};
    margin-right : 14px;
`;

export const StarterInfoPanel = (props) => {
    const colors = [SC.CurrentTheme.theme.colorinfo_1, SC.CurrentTheme.theme.colorinfo_2, SC.CurrentTheme.theme.colorinfo_3];
    const types = [Triangle, Circle, Square];
    const permutations =  [];
    Utils.ForEach(types, (ItemType, i) => {
        Utils.ForEach(colors, (color, c) => {
            permutations.push(
                <ItemType key={(i+1)*10+c} color={color} />
            );
            permutations.push(
                <ItemType key={(i+1)*200+c} color={color} filled/>
            );
        });
    });
    return (
        <SC.FCol f1>           
            <SC.FCol style={{padding : '8px', paddingTop : 0}}>
                <InfoPanel>
                    <InfoLine>States let us easily manage multiple variations of our designs.</InfoLine>
                    <InfoLine last>Dividing variations into distinct sets results n-ary Cartesian product and all possible permutations.</InfoLine>
                </InfoPanel>
                <InfoPanel>
                    <InfoLine>States with single variation are for <span style={{color : SC.CurrentTheme.theme.font_hover, fontWeight : 'bold'}}>On/Off</span> like binary sets.</InfoLine>
                </InfoPanel>      
                <div style={{padding : '4px'}}>EXAMPLE</div>
                <InfoPanel>
                    <div style={{
                        display : 'grid',
                        gridTemplateColumns : '1fr 2fr',
                        gridRowGap : '16px',
                        padding : '8px'
                    }}>
                        <div>Shape</div>
                        <SC.FRow>
                            <Triangle filled style={{marginRight : '12px'}}/>
                            <Circle filled style={{marginRight : '12px'}}/>
                            <Square filled/>
                        </SC.FRow>
                        <div>Color</div>
                        <SC.FRow style={{paddingLeft : '1px'}}>
                            {
                                colors.map((col, i) => <ColorBox key={i} color={col} />)
                            }
                        </SC.FRow>
                        <div>Filled</div>
                        <SC.FRow>
                            Yes/No
                        </SC.FRow>
                    </div>
                </InfoPanel>
                <InfoPanel>
                    <SC.FCol>
                        <SC.FRow justifyCenter style={{paddingTop : '8px', paddingBottom : '8px', fontSize : '13px'}}>
                            {'{ Shapes } X { Colors } X { Is Filled ? }'}
                        </SC.FRow>
                        <div style={{
                            flex : 1,
                            display : 'grid',
                            gridTemplateColumns : 'repeat( auto-fill, minmax(30px, 1fr) )',
                            gridGap :'16px 10px',
                            justifyItems : 'center',
                            alignItems : 'center',
                            padding : '8px'
                        }}>
                            {permutations}
                        </div>
                    </SC.FCol>                    
                </InfoPanel>
            </SC.FCol>            
        </SC.FCol> 
    )
}