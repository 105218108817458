import React from 'react';
import {
    SC,
    Globals,
    Utils,
} from '../../../../../../../../importer';

import AppLayout from '../../../../../../../../appstate/AppLayout';
import {AddNewEaseCurveToken, GetEaseCurveTokenItem} from '../../../../../left/designsystem/motion/easing';
import EaeseCurveEditor from '../../../../../left/designsystem/motion/easing/item';
import BaseSelectToken from '../../BaseSelectToken';
import { GetEaseCurveTokenList, EasePattern } from '../../../../../left/designsystem/motion/easing';
import { GetTokenAliaseList } from '../../../../../left/designsystem/aliases';
import { GroupTitle } from '../../../../../left/designsystem/common';
import { ListCardView } from '../../../../../../../../components/light_dark_bar';


export default class SelectEaseCurveToken extends BaseSelectToken
{
    constructor(props) {
        super(props);

        this.titleNewToken = 'NEW EASE CURVE';
        this.moduleName = 'EaseCurves';
        this.tokenType = Globals.ProjectManager.Tokens.Types.Motion;        

        this.hasAliases = !this.props.forAliase;
        this.state.isAliaseListView = true;

        this.Ref_ImageEdit = React.createRef();

        this.Load(this.props);
    }
    IsTokenPropChanged(nextProps, nextState) {
        if (Utils.HasAnyChange(this.props, nextProps, 'GlobalState')) {
            this.Load(nextProps);
            return true;
        }        
    }
    GetTokens(props) {
        const result = GetEaseCurveTokenList(props.StateArray);

        this.aliases = [];
        const aliases = GetTokenAliaseList(Globals.ProjectManager.Tokens.Types.MotionEase);        
        
        if (this.props.excludeIds) {
            this.props.excludeIds.map((excludeId) => {
                Utils.Remove(aliases, (item) => {return item.id === excludeId});
            })            
        }

        Utils.ForEach(aliases, (aliase, i) => { 
            if (aliase.tokenId) {
                let tokenItem;

                if (aliase.isAbsolute) {
                    tokenItem = GetEaseCurveTokenItem(aliase.tokenId,props.StateArray);
                }
                else {
                    tokenItem = Utils.Find(result, (item) => {return item.id === aliase.tokenId});
                    
                }                
                
                if (tokenItem) {
                    aliase.tokenItem = tokenItem;
                    this.aliases.push(aliase);
                }
            }
        });

        return result;
    }            
    GetNewModel(Id) {
        const Model = {
            name : 'New Ease Curve',
            items : [
                {
                    id : Utils.Id(),
                    type : 'scale',
                    locked : true
                }    
            ],
        };
        AddNewEaseCurveToken(Model, Id);
        this.FireSelectToken(Id);
        return Model;
    }
    SaveToken() {
        if (this.EditingToken.IsNew) {
            const EaseCurveItem = AddNewEaseCurveToken(this.EditingToken.Model, this.EditingToken.Id);
            AppLayout.Refs.DesignSystem.EaseCurves && AppLayout.Refs.DesignSystem.EaseCurves.Reload();
            return EaseCurveItem;
        }        
        else {
        }                
    }  
    onCloseTokenEditor() {
        if (this.EditingToken && this.EditingToken.IsNew) {
            delete this.EditingToken;            
            this.RCUpdate();
            return;
        }
        super.onCloseTokenEditor();
    }
    onCancelAddToken() {
        const willDeleteId = this.EditingToken.Id;        
        this.FireSelectToken(this.LastBoundTokenId);
        delete this.LastBoundTokenId;
        super.onCancelAddToken();
        Globals.ProjectManager.Tokens.Delete(Globals.ProjectManager.Tokens.Types.Motion, willDeleteId);
    } 
    UpdateSelectedToken() {
        if (this.EditingToken) {
            const SelectedToken = Utils.Find(this.tokens, (token) => {return token.id === this.EditingToken.Id});
            const token = Globals.ProjectManager.Tokens.Token(this.EditingToken.Id);
            const EaseCurve = Globals.ProjectManager.Tokens.ValueOf({model : token, statearray : this.props.StateArray});
        }
        // SelectedToken.value = Utils.GetTransitionCss(transforms, transform.originX, transform.originY, Globals.ProjectManager.Tokens.ValueOfId); 
    }
    GetStyleName() {
        return 'easeId';
    }

    renderTokenAliaseListHeader() {
        return (
            <GroupTitle 
                title='SEMANTIC TOKENS' 
                hasSearch={{onSearch : this.SearchAliase}}
                hasAdd={{onAdd : this.AddTokenAlias}}
                addOn={(
                    <SC.FRow>
                        <ListCardView 
                            onListView={this.SetAliaseListMode.bind(this, true)}
                            onCardView={this.SetAliaseListMode.bind(this, false)}
                            isListView={this.state.isAliaseListView}
                            isCardView={!this.state.isAliaseListView}
                            style={{marginRight : '4px'}}
                            compact
                        />
                    </SC.FRow>                    
                )}
                style={{padding : 0, paddingLeft : '4px', marginBottom : '4px'}}
            />
        )
    }
    renderTokenAliases() {
        let content;
        const styleName = this.GetStyleName();
        if (this.state.isAliaseListView) {
            const shadows = [];

            Utils.ForEach(this.aliases, (aliase, i) => {
                shadows.push(
                    <EasePattern
                        key={aliase.id}
                        notDraggable
                        name={aliase.name}
                        pattern={aliase.tokenItem}
                        listView
                        onClick={this.SelectToken.bind(this, aliase.id)}
                        selected={aliase.id === this.GetSelectedTokenId()}
                        onPreview={{
                            onMouseEnter : this.PreviewToken.bind(this, true, aliase.id, styleName),
                            onMouseLeave : this.PreviewToken.bind(this, false, aliase.id, styleName)
                        }}
                    />
                )
            });

            content = (
                <SC.FCol>
                    {shadows}
                </SC.FCol>
            )
        }
        else {
            const shadows = [];
            Utils.ForEach(this.aliases, (aliase, i) => {
                shadows.push(
                    <EasePattern
                        pattern={aliase.tokenItem}
                        notDraggable
                        key={aliase.id} 
                        onClick={this.SelectToken.bind(this, aliase.id)}
                        selected={aliase.id === this.GetSelectedTokenId()}
                        onPreview={{
                            onMouseEnter : this.PreviewToken.bind(this, true, aliase.id, styleName),
                            onMouseLeave : this.PreviewToken.bind(this, false, aliase.id, styleName)
                        }}
                    />
                )
            });

            content = (
                <SC.FRow style={{flexWrap : 'wrap'}}>
                    {shadows}
                </SC.FRow>
            )
        }

        return content; 
    }
    renderTokenList() {
        let content;
        const styleName = this.GetStyleName();
        const easeCurves = [];

        Utils.ForEach(this.tokens, (easeCurve, i) => {
            easeCurves.push(
                <EasePattern
                    key={easeCurve.id}
                    notDraggable
                    listView={this.state.isListView}
                    pattern={easeCurve}
                    onClick={this.SelectToken.bind(this, easeCurve.id)}
                    selected={easeCurve.id === this.GetSelectedTokenId()}
                    onPreview={{
                        onMouseEnter : this.PreviewToken.bind(this, true, easeCurve.id, styleName),
                        onMouseLeave : this.PreviewToken.bind(this, false, easeCurve.id, styleName)
                    }}
                />
            )
        });

        content = (
            <SC.FCol>
                {easeCurves}
            </SC.FCol>
        )

        return (
            <SC.CustomScrollbars hideTracksWhenNotNeeded autoHide>
                {content}
            </SC.CustomScrollbars>                             
        )       
    }
    renderTokenEditor() {
        return (
            <EaeseCurveEditor 
                noHeader
                id={this.EditingToken.Id}
                newModel={this.EditingToken.IsNew ?  this.EditingToken.Model : null}  
                GlobalState={Globals.ProjectManager.GlobalState}                      
                offline={this.EditingToken.IsNew}
                onPreviewChange={this.EditingToken.IsNew ? this.PreviewNewToken : null}
            />                 
        )        
    }
    GetSelectedTokenId() {
        return this.props.easeId || this.props.tokenId;
    }
    renderSelectedToken() {
        if (this.GetSelectedTokenId()) {
            return this.renderSelectedTokenHeader();
        }
    }
}
