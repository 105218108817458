import {
    AppState,
    Events,
    Strings,
    Utils,
    Globals
} from '../../../../importer';
import MetaData from '../../../../../toolabs-modules/toolabs-metaui';

const MetaItemStyle = {    
    GetGridSize(size, defUnit) {
        if (!size)
            return null;
        if (size && size.minMax) {
            return `minmax(${this.GetGridSize(size.minMax.min)}, ${this.GetGridSize(size.minMax.max)}) `;
        }
        if (size && size.value === 'auto')
            return 'auto ';

        return Utils.px(size.value, size.unit || defUnit) ;
    },
    GetGridItems(model, grid_style, modelName, templateName) {
        const columnsModel = Utils.Get(model, {}, modelName);
        const metaColumns = Utils.Get(columnsModel, [], 'Items');
        let gridTemplateColumns = '';
        Utils.ForEach(metaColumns,(col, i) => {
            if (col.Single && !col.hidden) {
                const size = this.GetGridSize(col.Single.size);
                if (col.Repeat > 1) {                                
                    gridTemplateColumns += ` repeat(${col.Repeat}, ${size}) `;
                }
                else 
                    gridTemplateColumns += ' ' + size;                
            }            
        });

        if (columnsModel.DynamicColumn) {
            if (gridTemplateColumns.indexOf('fr') < 0) {
                const size_Min = this.GetGridSize(Utils.JustGet(columnsModel.DynamicColumn, {unit:{unit : 'px'}, value :  200}, 'minWidth'));
                const size_Max = this.GetGridSize(Utils.JustGet(columnsModel.DynamicColumn, {unit:{unit : 'fr'}, value :  1}, 'maxWidth'));
                gridTemplateColumns += ` repeat(${columnsModel.DynamicColumn.type === 'autoFit' ? 'auto-fit' : 'auto-fill'}, minmax(${size_Min}, ${size_Max})) `;
            }            
        }

        grid_style[templateName] = gridTemplateColumns;
    },
    GetCssGridStyle(GridModel, isPlaceHolder, JustifyItems, AlignItems, justifyContent, alignContent, rowGap, columnGap ) {
        const grid_style = {
            display : 'grid',
        }                
        if (!GridModel)
            return grid_style;

        this.GetGridItems(GridModel, grid_style, 'Columns', 'gridTemplateColumns');
        this.GetGridItems(GridModel, grid_style, 'Rows', 'gridTemplateRows');
        this.GetGridItems(GridModel, grid_style, 'AutoColumns', 'gridAutoColumns');
        this.GetGridItems(GridModel, grid_style, 'AutoRows', 'gridAutoRows');

        if (!isPlaceHolder) {
            if (JustifyItems)
                grid_style.justifyItems = MetaData.Properties.layout.values.Flex.convertValue(JustifyItems);
            if (AlignItems)
                grid_style.alignItems = MetaData.Properties.layout.values.Flex.convertValue(AlignItems);            
        }        
        if (rowGap) 
            grid_style.gridRowGap = Utils.px(rowGap);
        if (columnGap) 
            grid_style.gridColumnGap = Utils.px(columnGap);
        if (justifyContent)
            grid_style.justifyContent = justifyContent;
        if (alignContent)
            grid_style.alignContent = alignContent;          
        return grid_style;
    }
};

export default MetaItemStyle;