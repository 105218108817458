import Utils from '../../../../toolabs-utils';

export default (MetaItem, GenericType, Name) => {
    const RootId = Utils.Id();
    MetaItem.name = Name;

    const Component = {            
        RootItem : {
            Id : RootId
        },
        MetaItems : {},
        PreviewSize : {
            width : 120,
            height : 32
        },
        Default: {
            'Designboard': {
                'type': 'grid'
            },
            'height': {
                'value': 32
            },                
            'width': {
                'value': 120
            }
        }
            
    };
    Component.MetaItems[RootId] = MetaItem;

    Component.PseudoStates = {
        Order : ['hover'],        
        hover : {
            SingleVariation : true,
            pseudo : true,
            name : 'Hover'
        }
    };

    Component.Handlers = {
        
    };

    Component.Models = {
        List : {
            '8ejo9_1Cs': {
                dataType: 'string',
                name: 'Text',
                value : 'text'
            }
        },
        Order: ['8ejo9_1Cs']
    };        
    
    Utils.MergeTo(MetaItem, {        
        Generic : {
            Type : GenericType
        },
        Models : {
            Default: {
                Default: {
                    textContent : {
                        Id : '8ejo9_1Cs'
                    }
                }
            }            
        },
        Property: {
            Default: {
                Default: {
                    text: {
                        value: ''
                    },
                    color: {
                        value: 'unset'
                    },
                    fontSize: {
                        value: 14
                    }
                }
            }
        }
    });

    return Component;
}