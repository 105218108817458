import ReactDOM from 'react-dom';
import {
    AppState,
    Events,
    Strings,
    MetaData,
    Utils,
    AppLayout,
    Globals,
    UIUtils
} from '../../../../importer';
import { SizeStyles } from '../../../../../toolabs-modules/toolabs-metaui/styles';
import {GetDataApi} from '../../../../appstate/AppState';
import GridStyleManager from './gridmanager';
import { GetItemType } from '../designer/itemdesigners';
import { LOG_TYPES } from '../../../../appstate/historymanager/base';
import ComponentRelationManager from './relations';
import ComponentStateManager from './statemanager';
import ComponentActionManager from './actionManager';
import ComponentModelManager from './modelManager';
import { TextItemStyleNames } from '../../panels/right/iteminspector/styleitems/text';
import { FlexStyleNames, GridChildStyleNames, GridStyleNames } from '../../panels/right/iteminspector/styleitems/layout';
import MetaItemStyle from './getItemStyle';
// import {scale, rotate, translate, compose, toCSS, applyToPoint, rotateDEG} from 'transformation-matrix';

let ClipboardItems = [];

const GetProjectManager = () => Globals.ProjectManager;

export default  class ComponentDesignManager {
    constructor(props = {GlobalState : 'Default', ComponentState : 'Default'}) {        

        this.ManagerId = Utils.Id();

        this.RelationManager = new ComponentRelationManager(this);
        this.States = new ComponentStateManager(this);
        this.Actions = new ComponentActionManager(this);                
        this.Models = new ComponentModelManager(this);

        this.GetMetaItem = this.GetMetaItem.bind(this);        

        this.StyleState = [];        
        this.GlobalStateArray = ['Default'];
        this.GlobalState = 'Default';
        this.ComponentState = 'Default';
        this.StyleState.push({
            Global : this.GlobalState,
            Component : this.ComponentState
        })

        // this.SetState(props.GlobalState || 'Default', props.ComponentState || 'Default');
        
        const that_manager = this;

        this.DataManager = {            
            SetPublishedId(Id, ChangeId) {
                if (!ChangeId)
                    return;
                GetDataApi().update_data_component(GetProjectManager().Id, Id, 'PublishedId', ChangeId);
            },
            GetId() {
                return that_manager.PrototypeViewId || that_manager.Id;
            },
            GetData() {
                return that_manager.Data;
            },
            PrototypeViewId : null,
            Replace(NewData) {
                if (that_manager.PrototypeViewId) {
                    Utils.Set(that_manager.PrototypeData, NewData, 'Views', that_manager.PrototypeViewId, 'Design');
                    if (that_manager.IsDocument)
                        GetDataApi().update_data_document(GetProjectManager().Id, that_manager.PrototypeId, ['Views', that_manager.PrototypeViewId,'Design'], NewData);
                    else
                        GetDataApi().update_data_prototype(GetProjectManager().Id, that_manager.PrototypeId, ['Views', that_manager.PrototypeViewId,'Design'], NewData);
                }
                else {
                    that_manager.Data = NewData;
                    GetDataApi().update_data_component(GetProjectManager().Id, that_manager.Id, '', NewData);
                }            
            },            
            Set(Value, ...Path) {                    
                const ChangeId = Utils.Id();
                if (that_manager.PrototypeViewId) {
                    Utils.Set(that_manager.PrototypeData, ChangeId, 'ChangeId');
                    Utils.Set(that_manager.PrototypeData, Utils.UseUndefined(Value, null), 'Views', that_manager.PrototypeViewId, 'Design', ...Path);
                    if (that_manager.IsDocument) {
                        GetDataApi().update_data_document(GetProjectManager().Id, that_manager.PrototypeId, 'ChangeId', ChangeId);
                        GetDataApi().update_data_document(GetProjectManager().Id, that_manager.PrototypeId, Utils.Concat(['Views', that_manager.PrototypeViewId,'Design'], Path), Utils.UseUndefined(Value, null));
                    }
                    else {
                        GetDataApi().update_data_prototype(GetProjectManager().Id, that_manager.PrototypeId, 'ChangeId', ChangeId);
                        GetDataApi().update_data_prototype(GetProjectManager().Id, that_manager.PrototypeId, Utils.Concat(['Views', that_manager.PrototypeViewId,'Design'], Path), Utils.UseUndefined(Value, null));
                    }
                }
                else {
                    if (!that_manager.Data)
                        return;    
                    Utils.Set(that_manager.Data, Utils.UseUndefined(Value, null), ...Path);
                    Utils.Set(that_manager.Data, ChangeId, 'ChangeId');
                    GetDataApi().update_data_component(GetProjectManager().Id, that_manager.Id, 'ChangeId', ChangeId);
                    GetDataApi().update_data_component(GetProjectManager().Id, that_manager.Id, Path, Utils.UseUndefined(Value, null));
                }
            },
            Delete(...Path) {          
                const ChangeId = Utils.Id();  
                if (that_manager.PrototypeViewId) {
                    Utils.UnSet(that_manager.PrototypeData, 'Views', that_manager.PrototypeViewId, 'Design', ...Path);
                    if (that_manager.IsDocument) {
                        GetDataApi().update_data_document(GetProjectManager().Id, that_manager.PrototypeId, 'ChangeId', ChangeId);
                        GetDataApi().delete_data_document(GetProjectManager().Id, that_manager.PrototypeId, Utils.Concat(['Views', that_manager.PrototypeViewId,'Design'], Path));
                    }
                    else {
                        GetDataApi().update_data_prototype(GetProjectManager().Id, that_manager.PrototypeId, 'ChangeId', ChangeId);
                        GetDataApi().delete_data_prototype(GetProjectManager().Id, that_manager.PrototypeId, Utils.Concat(['Views', that_manager.PrototypeViewId,'Design'], Path));
                    }
                }
                else {
                    if (!that_manager.Data)
                        return;
                    Utils.UnSet(that_manager.Data, ...Path);
                    Utils.Set(that_manager.Data, ChangeId, 'ChangeId');
                    GetDataApi().update_data_component(GetProjectManager().Id,that_manager.Id, 'ChangeId', ChangeId);
                    GetDataApi().delete_data_component(GetProjectManager().Id,that_manager.Id, Path);
                }
            },
            Get(DefValue, ...Path) {
                if (that_manager.PrototypeViewId) {
                    return Utils.Get(that_manager.PrototypeData, DefValue, 'Views', that_manager.PrototypeViewId, 'Design', ...Path);
                }
                else if (that_manager.Data) {
                    return Utils.Get(that_manager.Data, DefValue, ...Path);
                }            
            },
            GetForComponent(DefValue, ...Path) {
                if (that_manager.Data) {
                    return Utils.Get(that_manager.Data, DefValue, ...Path);
                }            
            },
            Prototype : {
                Set(Value, ...Path) {                    
                    const ChangeId = Utils.Id();
                    Utils.Set(that_manager.PrototypeData, ChangeId, 'ChangeId');
                    Utils.Set(that_manager.PrototypeData, Utils.UseUndefined(Value, null), ...Path);
                    if (that_manager.IsDocument) {
                        GetDataApi().update_data_document(GetProjectManager().Id, that_manager.Id, 'ChangeId', ChangeId);
                        GetDataApi().update_data_document(GetProjectManager().Id, that_manager.Id, Path, Utils.UseUndefined(Value, null));
                    }
                    else {
                        GetDataApi().update_data_prototype(GetProjectManager().Id, that_manager.Id, 'ChangeId', ChangeId);
                        GetDataApi().update_data_prototype(GetProjectManager().Id, that_manager.Id, Path, Utils.UseUndefined(Value, null));
                    }
                },
                Delete(...Path) {          
                    const ChangeId = Utils.Id();  
                    Utils.UnSet(that_manager.PrototypeData,  ...Path);
                    if (that_manager.IsDocument) {
                        GetDataApi().update_data_document(GetProjectManager().Id, that_manager.Id, 'ChangeId', ChangeId);
                        GetDataApi().delete_data_document(GetProjectManager().Id, that_manager.Id, Path);
                    }
                    else {
                        GetDataApi().update_data_prototype(GetProjectManager().Id, that_manager.Id, 'ChangeId', ChangeId);
                        GetDataApi().delete_data_prototype(GetProjectManager().Id, that_manager.Id, Path);
                    }
                },
                Get(DefValue, ...Path) {
                    return Utils.Get(that_manager.PrototypeData, DefValue, ...Path);            
                },
            },
            Item: {
                Set(Id, Value, Path) {
                    if (!Id) {
                        console.log('ASSERT : Data.Compnent.Item.Set : Null ID');
                        return;
                    }
                    if (!that_manager.Data && !that_manager.PrototypeViewId)
                        return;
                    const ChangeId = Utils.Id();
                    if (that_manager.PrototypeViewId) {
                        Utils.SetPathArray(that_manager.PrototypeData, ChangeId, 'ChangeId');
                        Utils.SetPathArray(that_manager.PrototypeData, Utils.UseUndefined(Value, null), Utils.Concat(['Views', that_manager.PrototypeViewId,'Design','MetaItems', Id], Path));
                        if (that_manager.IsDocument) {
                            GetDataApi().update_data_document(GetProjectManager().Id, that_manager.PrototypeId, 'ChangeId', ChangeId);
                            GetDataApi().update_data_document(GetProjectManager().Id, that_manager.PrototypeId, Utils.Concat(['Views', that_manager.PrototypeViewId,'Design','MetaItems', Id], Path), Utils.UseUndefined(Value, null));
                        }
                        else {
                            GetDataApi().update_data_prototype(GetProjectManager().Id, that_manager.PrototypeId, 'ChangeId', ChangeId);
                            GetDataApi().update_data_prototype(GetProjectManager().Id, that_manager.PrototypeId, Utils.Concat(['Views', that_manager.PrototypeViewId,'Design','MetaItems', Id], Path), Utils.UseUndefined(Value, null));
                        }
                    }
                    else {
                        Utils.Set(that_manager.Data, ChangeId, 'ChangeId');
                        Utils.SetPathArray(that_manager.Data, Utils.UseUndefined(Value, null), Utils.Concat(['MetaItems', Id], Path));
                        GetDataApi().update_data_component(GetProjectManager().Id, that_manager.Id, 'ChangeId', ChangeId);
                        GetDataApi().update_data_component_item(GetProjectManager().Id, that_manager.Id, Id, Path, Utils.UseUndefined(Value, null));
                    }
                },
                Get(Id, Path) {                
                    if (that_manager.PrototypeViewId) {
                        return Utils.GetPathArray(that_manager.PrototypeData, null, Utils.Concat(['Views', that_manager.PrototypeViewId,'Design', 'MetaItems', Id], Path));
                    }
                    else {
                        if (!that_manager.Data)
                            return;
                        return Utils.GetPathArray(that_manager.Data, null, Utils.Concat(['MetaItems', Id], Path));
                    }
                },
                Delete(Id, Path) {                
                    const ChangeId = Utils.Id();
                    if (that_manager.PrototypeViewId) {
                        Utils.SetPathArray(that_manager.PrototypeData, ChangeId, 'ChangeId');
                        Utils.UnSetPathArray(that_manager.PrototypeData, Utils.Concat(['Views', that_manager.PrototypeViewId,'Design','MetaItems', Id], Path));
                        if (that_manager.IsDocument) {
                            GetDataApi().update_data_document(GetProjectManager().Id, that_manager.PrototypeId, 'ChangeId', ChangeId);
                            GetDataApi().delete_data_document(GetProjectManager().Id, that_manager.PrototypeId, Utils.Concat(['Views', that_manager.PrototypeViewId, 'Design', 'MetaItems', Id], Path));
                        }
                        else {
                            GetDataApi().update_data_prototype(GetProjectManager().Id, that_manager.PrototypeId, 'ChangeId', ChangeId);
                            GetDataApi().delete_data_prototype(GetProjectManager().Id, that_manager.PrototypeId, Utils.Concat(['Views', that_manager.PrototypeViewId, 'Design', 'MetaItems', Id], Path));
                        }
                    }
                    else {
                        if (!that_manager.Data)
                            return;
                        Utils.Set(that_manager.Data, ChangeId, 'ChangeId');
                        Utils.UnSetPathArray(that_manager.Data, Utils.Concat(['MetaItems', Id], Path));
                        GetDataApi().update_data_component(GetProjectManager().Id, that_manager.Id, 'ChangeId', ChangeId);
                        GetDataApi().delete_data_component_item(GetProjectManager().Id,that_manager.Id, Id, Path);
                    }
                }
            },
            History: {
                Log(Change) {
                    GetDataApi().log_component(that_manager.Id, Utils.Id(), Change);
                },
                GetLast() {
                    return GetDataApi().log_component_undo(that_manager.Id);
                },
                Undo(LogId) {
                    GetDataApi().log_component_commit_undo(that_manager.Id, LogId);
                }
            }        
        };
    }

    static Active() {
        return ActiveComponentManager;
    }

    Destruct() {
        if (ActiveComponentManager.ComponentManager && ActiveComponentManager.ComponentManager.ManagerId === this.ManagerId)
            ActiveComponentManager.SetActiveComponentManager(null);

        if (this.PrototypeViewId) {
            Events.DropListen(this.PrototypeViewId, this);
            if (this.PrototypeViewId &&!ActiveComponentManager.All[this.PrototypeViewId]) {
                ActiveComponentManager.All[this.PrototypeViewId] = {
                    Managers : {}
                };
            }
    
            if (this.PrototypeViewId)
                delete ActiveComponentManager.All[this.PrototypeViewId].Managers[this.ManagerId];
        }
        else {
            Events.DropListen(this.RootOwnerId, this);
            if (this.RootOwnerId && !ActiveComponentManager.All[this.RootOwnerId]) {
                ActiveComponentManager.All[this.RootOwnerId] = {
                    Managers : {}
                };
            }
    
            if (this.RootOwnerId)
                delete ActiveComponentManager.All[this.RootOwnerId].Managers[this.ManagerId];
        }        
    }
    
    GetProperty(Id, Name, DefaultValue, SetModelValue) {
        const ItemStyles = Utils.JustGet(this.StateStyles, {},  Id, this.GlobalStateArray + this.ComponentState);
        if (ItemStyles.Model && ItemStyles.Model[Name]) {
            let useValue;
            if (SetModelValue)
                useValue = SetModelValue(ItemStyles.Model[Name]);
            if (Utils.IsNull(useValue))
                useValue = this.GetModelValue({ModelId : ItemStyles.Model[Name]});
            return useValue;
        }
        return Utils.JustGet(ItemStyles, DefaultValue, 'Props', Name);
    }
    SetPropertyToStatic({MetaItem, StyleObject, PropName, StyleState, StyleName, ParentNames, DefaultValue, ConvertValue}) {
        const value = this.GetItemPropertyStateValue({
            MetaItem : MetaItem, 
            PropertyName : PropName, 
            ParentNames : ParentNames || [],
            GlobalState : StyleState.Global, 
            ComponentState : StyleState.Component,
            DefaultValue : DefaultValue
        });
        if (Utils.IsNotNull(value)) {
            if (ConvertValue) {
                const result = ConvertValue(value);
                if (!result.handled) {
                    StyleObject[StyleName || PropName] = result.value;
                }
            }                    
            else
                StyleObject[StyleName || PropName] = value;
            return value;
        }
    }; 
    LoadItemStyles({StyleState, ReverseStyleState, MetaItem, Id, ParentStyles, ReversedGlobalStateArray}) {  
        if (!MetaItem) {
            return;
        }
        

        const SetPropertyToStatic = ({StyleObject, PropName, StyleState, StyleName, ParentNames, DefaultValue, ConvertValue}) => {
            return this.SetPropertyToStatic({MetaItem : MetaItem, StyleObject : StyleObject, PropName : PropName, StyleState : StyleState, StyleName : StyleName, ParentNames : ParentNames, DefaultValue : DefaultValue, ConvertValue : ConvertValue});
        };        
        const SetToken = ({StyleObject, PropName, StyleState, StyleName, ParentNames, ConvertValue}) => {
            const TokenId = this.GetItemStyleStateToken({
                MetaItem : MetaItem, StyleName : PropName, ParentNames : ParentNames,
                GlobalState : StyleState.Global, ComponentState : StyleState.Component
            });
            if (TokenId) {
                Token[StyleName || PropName] = TokenId;
             
                const tokenValue = Globals.ProjectManager.Tokens.GetSpecisicStateValue({Id : TokenId, state : StyleState.Global});
                if (Utils.IsNotNull(tokenValue)) {
                    if (ConvertValue) {
                        const result = ConvertValue(tokenValue);
                        if (!result.handled) {
                            StyleObject[StyleName || PropName] = result.value;
                        }                        
                    }
                    else {
                        StyleObject[StyleName || PropName] = tokenValue;
                    }
                }                                                        
            }
        }

        const SetModel = ({StyleObject, PropName, StyleState, StyleName, ParentNames, ConvertValue}) => {
            const ModelId = this.GetItemStyleStateModel({
                MetaItem : MetaItem, PropertyName : PropName, ParentNames : ParentNames,
                GlobalState : StyleState.Global, ComponentState : StyleState.Component
            });
            if (ModelId) {
                Model[StyleName || PropName] = ModelId;
                
                const modelValue = this.Models.GetValue(ModelId);
                if (Utils.IsNotNull(modelValue)) {
                    if (ConvertValue) {
                        const result = ConvertValue(modelValue);
                        if (!result.handled) {
                            StyleObject[StyleName || PropName] = result.value;
                        }                        
                    }
                    else {
                        StyleObject[StyleName || PropName] = modelValue;
                    }
                }                                                        
            }
        }       
        
        const StateStyle = Utils.Get(this.StateStyles, {           
        }, Id, StyleState.Global + StyleState.Component);

        const Statics = Utils.Get(StateStyle, {}, 'Static');
        const Props = Utils.Get(StateStyle, {}, 'Props');
        const Compound = Utils.Get(StateStyle, {}, 'Compound');
        const Model = Utils.Get(StateStyle, {}, 'Model');
        const Token = Utils.Get(StateStyle, {}, 'Token');
        const Resets = Utils.Get(StateStyle, {}, 'Reset');


        const ItemModels = Utils.JustGet(MetaItem, null, 'Models', StyleState.Global, StyleState.Component);
        if (ItemModels) {
            Utils.ForEach(ItemModels, (propModel, propName) => {
                SetModel({StyleObject : Props, PropName : propName, StyleState : StyleState});
            });
        }

        // Props
        SetPropertyToStatic({StyleObject : Props, PropName : 'hidden', StyleState : StyleState});
        SetPropertyToStatic({StyleObject : Props, PropName : 'SizeLocked', StyleState : StyleState});
        SetModel({StyleObject : Props, PropName : 'hidden', StyleState : StyleState});

        SetPropertyToStatic({StyleObject : Props, PropName : 'textContent', StyleState : StyleState});
        SetToken({StyleObject : Props, PropName : 'textContent', StyleState : StyleState});
        SetModel({StyleObject : Props, PropName : 'textContent', StyleState : StyleState});

        if (Token.textContent) {
            const tokenModel = Globals.ProjectManager.Tokens.Token(Token.textContent);
            if (tokenModel.parameters) {
                const textContentParameters = this.GetItemPropertyStateValue({
                    MetaItem : MetaItem, 
                    PropertyName : 'textContent', 
                    GlobalState : StyleState.Global, 
                    ComponentState : StyleState.Component
                });
                if (Utils.IsNotNull(textContentParameters)) {
                    const parameters = Utils.JustGet(textContentParameters, null, 'Parameters');            
                    if (parameters) {
                        Utils.ForEach(parameters, (paramvalue, paramid) => {
                            const param = Utils.Find(tokenModel.parameters, (p) => {return p.id === paramid});
                            if (param) {
                                Props.textContent = Props.textContent.replace('${' + param.name + '}', paramvalue.value);
                            }
                        });
                    }
                }
            }
            
        }
        

        // Styles

        ['flex', 'alignSelf', 'justifySelf', 'zIndex', 'overflowX', 'overflowY', 'backgroundColor'].map((propName) => {
            const value = SetPropertyToStatic({StyleObject : Statics, PropName : propName, StyleState : StyleState});
            if (!Utils.IsNotNullOrEmpty(value)) {
                const isReset = this.GetItemPropertyStateValue({MetaItem : MetaItem, PropertyName : propName, ValueName : 'Reset', GlobalState : StyleState.Global, ComponentState : StyleState.Component});
                if (isReset)
                    Resets[propName] = true;
            }
        });
        
        Utils.SizeProps.map((propName) => {
            let isAligned = false;
            if (Statics.alignSelf === 'stretch') {
                isAligned = ((ParentStyles.display === 'grid' || ParentStyles.flexDirection === 'row' || ParentStyles.flexDirection === 'row-reverse') && propName === 'height') || ((ParentStyles.display === 'grid' || ParentStyles.flexDirection === 'column' || ParentStyles.flexDirection === 'column-reverse') && propName === 'width');
            }
            if (!isAligned) {
                const isReset = this.GetItemPropertyStateValue({MetaItem : MetaItem, PropertyName : propName, ValueName : 'Reset', GlobalState : StyleState.Global, ComponentState : StyleState.Component});
                if (!isReset) {
                    const value = this.GetItemPropertyStateValue({
                        MetaItem : MetaItem, 
                        PropertyName : propName, 
                        GlobalState : StyleState.Global, 
                        ComponentState : StyleState.Component
                    });                    
                    if (Utils.IsNotNull(value)) {
                        const unit = this.GetItemPropertyStateValue({
                            MetaItem : MetaItem, 
                            PropertyName : propName, 
                            GlobalState : StyleState.Global, 
                            ComponentState : StyleState.Component,
                            ValueName : 'Unit'
                        });
    
                        Statics[propName] = Utils.px(value, unit);
                    }
                }    
                else {
                    Resets[propName] = true;
                }            
            }            
            else {
                Statics[propName] = 'auto';
                // Resets[propName] = true;
            }
        });         
        
        // if (ParentStyles.display === 'grid') 
        {
            GridChildStyleNames.map((gridPos) => {
                SetPropertyToStatic({StyleObject : Statics, PropName : gridPos, StyleState : StyleState, ParentNames : ['GridChild']});
            })
        }

        TextItemStyleNames.map((propName) => {
            SetPropertyToStatic({StyleObject : Statics, PropName : propName, StyleState : StyleState});
        });        

        SetPropertyToStatic({StyleObject : Statics, PropName : 'opacity', StyleState : StyleState, ConvertValue : (value) => {
            return {value : Math.min(1, Math.max(0, value / 100))};
        }});
        SetModel({StyleObject : Statics, PropName : 'opacity', StyleState : StyleState, ConvertValue : (modelValue) => {
            return {value : Math.min(1, Math.max(0, modelValue / 100))};
        }});

        ['backgroundColor', 'color', 'fill'].map((tokenProp) => {
            SetToken({StyleObject : Statics, PropName : tokenProp, StyleState : StyleState});
            SetModel({StyleObject : Statics, PropName : tokenProp, StyleState : StyleState});
        });           
        SetToken({StyleObject : Statics, PropName : 'gradient', StyleName : 'background',  StyleState : StyleState, ConvertValue : (tokenValue) => {
            if (!tokenValue.gradient)
                return {};
            return {
                value : Utils.GetGradientCss(tokenValue.gradient.colors, tokenValue.type, Utils.Get(tokenValue.gradient, 0, 'path', 'angle'), Globals.ProjectManager.Tokens.ValueOfId)
            }
        }});

        SetToken({StyleObject : Statics, PropName : 'backgroundImage',  StyleState : StyleState, ConvertValue : (tokenValue) => {
            return {
                value : tokenValue.url
            }
        }});
        SetModel({StyleObject : Statics, PropName : 'backgroundImage', StyleState : StyleState});

        SetToken({StyleObject : Props, PropName : 'icon',  StyleState : StyleState});
        SetModel({StyleObject : Props, PropName : 'icon', StyleState : StyleState});

        SetToken({StyleObject : Statics, PropName : 'transform',  StyleState : StyleState, ConvertValue : (transform) => {
            if (transform.matrix) {                    
                Statics.transform = transform.matrix;
                Statics.transformOrigin = `${transform.originX || 'center'} ${transform.originY || 'center'}`;                    
            }
            else {
                const transformStyle = Utils.GetTransformCssAll(Utils.Get(transform, [], 'items'), transform.originX, transform.orignY, true);
                Statics.transform = transformStyle.transform;
                Statics.transformOrigin = transformStyle.transformOrigin;
            }  
            return {
                handled : true
            }
        }});

        SetToken({StyleObject : Statics, PropName : 'filter',  StyleState : StyleState, ConvertValue : (filter) => {
            const filterStyle = Utils.GetFilterCss(Utils.Get(filter, [], 'items'), MetaData.Properties.filter);
            if (Utils.IsNotNullOrEmpty(filterStyle))
                Statics.filter = filterStyle;
            return {
                handled : true
            }
        }});

        let positionX = SetPropertyToStatic({StyleObject : Compound, PropName : 'positionX', StyleState : StyleState});
        let positionY = SetPropertyToStatic({StyleObject : Compound, PropName : 'positionY', StyleState : StyleState});

        if (positionX === 'custom') {
            let modelPositionX = SetPropertyToStatic({StyleObject : Compound, PropName : 'customPositionX', StyleState : StyleState});
            SetModel({StyleObject : Compound, PropName : 'customPositionX', StyleState : StyleState, ConvertValue : (modelValue) => {
                modelPositionX = modelValue;
                return { handled : true };
            }});            
            if (Utils.IsNotNullOrEmpty(modelPositionX) && Utils.IsNumber(modelPositionX))
                positionX = Utils.px(modelPositionX, '%');
            else
                positionX = 'center';
        }
        if (positionY === 'custom') {
            let modelPositionY = SetPropertyToStatic({StyleObject : Compound, PropName : 'customPositionY', StyleState : StyleState});
            SetModel({StyleObject : Compound, PropName : 'customPositionY', StyleState : StyleState, ConvertValue : (modelValue) => {
                modelPositionY = modelValue;
                return { handled : true };
            }});
            if (Utils.IsNotNullOrEmpty(modelPositionY) && Utils.IsNumber(modelPositionY))
                positionY = Utils.px(modelPositionY, '%');
            else
                positionY = 'center';
        }
        if (positionX || positionY)
            StateStyle.Static.backgroundPosition = `${positionX || 'center'} ${positionY || 'center'}`;

        const backgroundSize = SetPropertyToStatic({StyleObject : Compound, PropName : 'backgroundSize', StyleState : StyleState});
        if (Utils.IsNotNull(backgroundSize)) {
            if (backgroundSize === 'custom') {
                let backgroundWidth = SetPropertyToStatic({StyleObject : Compound, PropName : 'backgroundWidth', StyleState : StyleState});
                if (backgroundWidth && backgroundWidth !== 'auto')
                    backgroundWidth += '%';                    
                let backgroundHeight = SetPropertyToStatic({StyleObject : Compound, PropName : 'backgroundHeight', StyleState : StyleState});
                if (backgroundHeight && backgroundHeight !== 'auto')
                    backgroundHeight += '%';
                
                StateStyle.Static.backgroundSize = backgroundWidth + ' ' + backgroundHeight;
            }
            else
                StateStyle.Static.backgroundSize = backgroundSize || 'auto';
        }
        

            
        const tokenId_textPattern = this.GetItemStyleStateToken({
            MetaItem : MetaItem, StyleName : 'textPattern', GlobalState : StyleState.Global, ComponentState : StyleState.Component
        });
        if (tokenId_textPattern) {
            Token.textPattern = tokenId_textPattern;
            const PatternModel = Globals.ProjectManager.Tokens.TypePatterns.GetPattern(tokenId_textPattern);
            const style_pattern = Globals.ProjectManager.Tokens.TypePatterns.GetPatternStyle(PatternModel, null, StyleState.Global);
            Globals.ProjectManager.Tokens.TypePatterns.ApplyPatternStyle(style_pattern, StateStyle.Static);
        }

        const tokenId_shadow = this.GetItemStyleStateToken({
            MetaItem : MetaItem, StyleName : 'boxShadow', GlobalState : StyleState.Global, ComponentState : StyleState.Component
        });
        if (tokenId_shadow) {
            const shadow = this.Get_Shadow(tokenId_shadow);
            if (shadow) {
                Token.boxShadow = tokenId_shadow;
                const shadowStyle = Utils.GetShadowCss(Utils.Get(shadow, [], 'values'), false, Globals.ProjectManager.Tokens.ValueOfId);
                StateStyle.Static.boxShadow = shadowStyle;
            }
        }

        const tokenId_textShadow = this.GetItemStyleStateToken({
            MetaItem : MetaItem, StyleName : 'textShadow', GlobalState : StyleState.Global, ComponentState : StyleState.Component
        });
        if (tokenId_textShadow) {
            const shadow = this.Get_Shadow(tokenId_textShadow);
            if (shadow) {
                Token.textShadow = tokenId_textShadow;
                const shadowStyle = Utils.GetShadowCss(Utils.Get(shadow, [], 'values'), true, Globals.ProjectManager.Tokens.ValueOfId);
                StateStyle.Static.textShadow = shadowStyle;
            }
        }

        Utils.BorderStyles.Sides.map((side) => {
            const propStyle = Utils.BorderStyles.propStyle(side);
            const propSize = Utils.BorderStyles.propSize(side);
            const propColor = Utils.BorderStyles.propColor(side);

            const tokenId_borderStyle = this.GetItemStyleStateToken({
                MetaItem : MetaItem, StyleName : propStyle, GlobalState : StyleState.Global, ComponentState : StyleState.Component
            });
            if (tokenId_borderStyle) {
                // Utils.Set(StateStyle, tokenId_borderStyle, 'BorderStyle', side);                    
                Token[propStyle] = tokenId_borderStyle;

                const tokenValue = Globals.ProjectManager.Tokens.GetSpecisicStateValue({Id : tokenId_borderStyle, state : StyleState.Global});
                if (Utils.IsNotNull(tokenValue)) {
                    StateStyle.Static[propStyle] = tokenValue.style;
                    const value = Utils.UseNull(tokenValue.value, 0);
                    const unit = tokenValue.Unit || Globals.ProjectManager.Units.Default();
                    StateStyle.Static[propSize] = Utils.px(value, unit);
                }                        
                    
            }
            SetToken({StyleObject : Statics, PropName : propColor, StyleState : StyleState});           

            const propPadding = Utils.BorderStyles.propPadding(side);            
            const tokenId_padding = this.GetItemStyleStateToken({MetaItem : MetaItem, Id : Id, StyleName : propPadding, GlobalState : StyleState.Global, ComponentState : StyleState.Component});
            if (tokenId_padding) {
                Token[propPadding] = tokenId_padding;
                const PatternModel = Globals.ProjectManager.Tokens.SpacePatterns.GetPattern(tokenId_padding);                
                const patternvalue = Globals.ProjectManager.Tokens.SpacePatterns.GetSpaceSize(PatternModel, null, StyleState.Global);
                const patternUnit = Globals.ProjectManager.Tokens.SpacePatterns.GetSpaceUnit(PatternModel, StyleState.Global);
                StateStyle.Static[propPadding] = Utils.px(patternvalue, patternUnit);
                // if (side === '') {
                //     Utils.BorderStyles.Sides.map((side) => {
                //         StateStyle.Static[Utils.BorderStyles.propPadding(side)] = Utils.px(patternvalue);
                //     });
                // }
            }
            
            const propMargin = Utils.BorderStyles.propMargin(side);            
            const tokenId_margin = this.GetItemStyleStateToken({MetaItem : MetaItem, Id : Id, StyleName : propMargin, GlobalState : StyleState.Global, ComponentState : StyleState.Component});
            if (tokenId_margin) {
                Token[propMargin] = tokenId_margin;
                const PatternModel = Globals.ProjectManager.Tokens.SpacePatterns.GetPattern(tokenId_margin);                
                const patternvalue = Globals.ProjectManager.Tokens.SpacePatterns.GetSpaceSize(PatternModel, null, StyleState.Global);
                const patternUnit = Globals.ProjectManager.Tokens.SpacePatterns.GetSpaceUnit(PatternModel, StyleState.Global);
                StateStyle.Static[propMargin] = Utils.px(patternvalue, patternUnit);
                // if (side === '') {
                //     Utils.BorderStyles.Sides.map((side) => {
                //         StateStyle.Static[Utils.BorderStyles.propMargin(side)] = Utils.px(patternvalue);
                //     });
                // }
            }
        })  

        Utils.BorderStyles.Corners.map((side) => {
            const propRadius = Utils.BorderStyles.propRadius(side);            

            const tokenId_borderRadius = this.GetItemStyleStateToken({MetaItem : MetaItem, Id : Id, StyleName : propRadius, GlobalState : StyleState.Global, ComponentState : StyleState.Component});
            if (tokenId_borderRadius) {
                Token[propRadius] = tokenId_borderRadius;
                const tokenValue = Globals.ProjectManager.Tokens.GetSpecisicStateValue({Id : tokenId_borderRadius, state : StyleState.Global});
                if (Utils.IsNotNull(tokenValue)) {
                    const value = Utils.UseNull(tokenValue.value, 0);
                    const unit = tokenValue.Unit || Globals.ProjectManager.Units.Default();
                    StateStyle.Static[propRadius] = Utils.px(value, unit);
                } 
            }  
        })  
        
        const position = SetPropertyToStatic({StyleObject : StateStyle.Static, PropName : 'position', StyleState : StyleState});
        ['top', 'left', 'right', 'bottom'].map((prop) => {
            const isReset = this.GetItemPropertyStateValue({MetaItem : MetaItem, PropertyName : prop, ValueName : 'Reset', GlobalState : StyleState.Global, ComponentState : StyleState.Component});
            if (!isReset) {
                const posValue = this.GetItemPropertyStateValue({MetaItem : MetaItem, PropertyName : prop, GlobalState : StyleState.Global, ComponentState : StyleState.Component});
                if (Utils.IsNotNull(posValue)) {
                    const unit = this.GetItemPropertyStateValue({MetaItem : MetaItem, PropertyName : prop, ValueName : 'Unit', GlobalState : StyleState.Global, ComponentState : StyleState.Component});
                    StateStyle.Static[prop] = Utils.px(posValue, unit);
                }
            }            
            else {
                StateStyle.Reset[prop] = true;
            }
        })
        const SendParentOptions = {};

        if (MetaItem.Type === 'Div' || MetaItem.Type === MetaData.Components.Content.Type) {
            SendParentOptions.layout = SetPropertyToStatic({StyleObject : StateStyle.Static, PropName : 'display', StyleState : StyleState});

            if (SendParentOptions.layout === 'grid') {
                this.ComputeGridStyle({MetaItem : MetaItem, Id : Id, GridProps : StateStyle.Props, StyleState : StyleState});                                
            }
            else {
                FlexStyleNames.map((flexName) => {
                    SetPropertyToStatic({StyleObject : StateStyle.Static, PropName : flexName, StyleState : StyleState});
                })
                SendParentOptions.flexDirection = StateStyle.Static.flexDirection;
                SendParentOptions.alignItems = StateStyle.Static.alignItems;
            }
        }
        else if (MetaItem.Type === MetaData.Components.Component.Type) {
            const ComponentInfo = this.GetSubComponentInfo({MetaItem : MetaItem, Id : Id, ReversedStyleState : ReverseStyleState});
            if (ComponentInfo && ComponentInfo.Id) {
                Props.Id = ComponentInfo.Id;
                Props.Models = Utils.DeepClone(ComponentInfo.Models);
                Props.Variations = Utils.DeepClone(ComponentInfo.Variations);
            }
        }
        else if (MetaItem.Type === MetaData.Components.Widget.Type) {
            const WidgetType = Utils.Get(MetaItem, null, 'Widget', 'Type');
            if (WidgetType === MetaData.Components.Widget.WidgetTypes.Video.Type) {
                SetPropertyToStatic({StyleObject : Props, PropName : 'VideoUrl', StyleState : StyleState});
                SetModel({StyleObject : Props, PropName : 'VideoUrl', StyleState : StyleState});                
            }
            else if (WidgetType === MetaData.Components.Widget.WidgetTypes.Lottie.Type) {
                SetPropertyToStatic({StyleObject : Props, PropName : 'LottieUrl', StyleState : StyleState});
                SetModel({StyleObject : Props, PropName : 'LottieUrl', StyleState : StyleState});                
                SetPropertyToStatic({StyleObject : Props, PropName : 'speed', StyleState : StyleState});
                SetModel({StyleObject : Props, PropName : 'speed', StyleState : StyleState});
            }
            else if (WidgetType === MetaData.Components.Widget.WidgetTypes.MapBox.Type) {
                Utils.ForEach(MetaData.Components.Widget.WidgetTypes.MapBox.Options, (option, propName) => {
                    SetPropertyToStatic({StyleObject : Props, PropName : propName, StyleState : StyleState});
                    SetModel({StyleObject : Props, PropName : propName, StyleState : StyleState});                
                });
            }
            else if (WidgetType === MetaData.Components.Widget.WidgetTypes.ApexCharts.Type) {
                Utils.ForEach(MetaData.Components.Widget.WidgetTypes.ApexCharts.Options, (option, propName) => {
                    SetPropertyToStatic({StyleObject : Props, PropName : propName, StyleState : StyleState});
                    SetModel({StyleObject : Props, PropName : propName, StyleState : StyleState});                
                });
            }
            else if (WidgetType === MetaData.Components.Widget.WidgetTypes.eCharts.Type) {
                Utils.ForEach(MetaData.Components.Widget.WidgetTypes.eCharts.Options, (option, propName) => {
                    SetPropertyToStatic({StyleObject : Props, PropName : propName, StyleState : StyleState});
                    SetModel({StyleObject : Props, PropName : propName, StyleState : StyleState});                
                });
            }
        }
        else if (MetaItem.Generic) {
            if (MetaItem.Generic.Type === MetaData.Components.Generic.Checkbox.Type) {
                SetToken({StyleObject : Props, PropName : 'checkFill', StyleState : StyleState, ParentNames : ['GenericOptions']});
                ['checkStrokeWidth', 'roundCap', 'checkSize', 'checkOpacity'].map((propName) => {
                    SetPropertyToStatic({StyleObject : Props, PropName : propName, StyleState : StyleState, ParentNames : ['GenericOptions']});
                })
            }
            else if (MetaItem.Generic.Type === MetaData.Components.Generic.Tab.Type) {                
                ['orientation'].map((propName) => {
                    SetPropertyToStatic({StyleObject : Props, PropName : propName, StyleState : StyleState, ParentNames : ['GenericOptions']});
                })
            }
        }

        // if (MetaItem.SubItems) {         
        //     const ParentStateStyle = this.GetItemStateStyle({
        //         MetaItem : MetaItem,
        //         Id : Id,
        //         GlobalState : StyleState.Global, 
        //         ComponentState : StyleState.Component,
        //         ReversedStyleState : ReverseStyleState,
        //         ReversedGlobalStateArray : this.ReversedGlobalStateArray
        //     });
        //     const ComputedParentStyle = Utils.JustGet(this.StateStyles, {}, Id, 'Computed', StyleState.Global + StyleState.Component, 'style');
        //     Utils.ForEach(MetaItem.SubItems, (SubItem) => {
        //         if (SubItem) {
        //             const SubMetaItem = this.GetMetaItem(SubItem.Id);
        //             if (SubMetaItem) 
        //                 this.LoadItemStyles(StyleState, ReverseStyleState, SubMetaItem, SubItem.Id, ComputedParentStyle);
        //         }
        //     });
        // };

        // console.log(`[${Id}][${StyleState.Global}-${StyleState.Component}] : ${JSON.stringify(StateStyle, null, 2)}`);
    }
    ComputeGridStyle({MetaItem, Id, StyleState, GridProps}) {
        const GridModel = this.SetPropertyToStatic({MetaItem : MetaItem, StyleObject : GridProps, ParentNames : ['Grid'], PropName : 'model', StyleName : 'GridModel', StyleState : StyleState});
        if (GridModel) {
            const GridStyles = {};
            GridStyleNames.map((name) => {
                this.SetPropertyToStatic({MetaItem : MetaItem, StyleObject : GridStyles, PropName : name, ParentNames : ['Grid'], StyleState : StyleState});                        
                if (!Utils.IsNotNullOrEmpty(GridStyles[name])) {
                    GridStyles[name] = Utils.JustGet(GridProps, null, 'GridStyle', name);
                }
            });         
            const GridStateStyle = GridStyleManager.GetCssGridStyle(GridModel, false, GridStyles.justifyItems, GridStyles.alignItems, GridStyles.justifyContent, GridStyles.alignContent, GridStyles.rowGap, GridStyles.columnGap);
            // Utils.ForEach(GridStateStyle, (value, prop) => {
            //     if (Utils.IsNotNull(value)) {
            //         console.log(`[State  :${StyleState.Component}].${prop} = ${value}`);
            //         Utils.Set(GridProps, value, 'GridStyle', prop);
            //     }
            // });            
            GridProps.GridStyle = Utils.Merge(GridProps.GridStyle, GridStateStyle);
            
            GridProps.ShowGridLines = this.GetGridProperty(Id, 'showGridLines', false);
            GridProps.Computed = {
                fixedColumns : 0,
                fixedRows : 0,
            };
            const Columns = Utils.Get(GridModel, null, 'Columns');
            const Rows = Utils.Get(GridModel, null, 'Rows');                

            GridProps.Computed.ColumnGap = GridStyles.columnGap;
            GridProps.Computed.RowGap = GridStyles.rowGap;

            const HiddenColumns = Utils.Get(GridProps.Computed, [], 'HiddenColumns');
            const HiddenRows = Utils.Get(GridProps.Computed, [], 'HiddenRows');
            
            Utils.ForEach(Columns.Items,(item, i) => {
                if (!item.hidden) {
                    if (item.Repeat > 1)
                        GridProps.Computed.fixedColumns += item.Repeat;
                    else
                        GridProps.Computed.fixedColumns++; 
                }            
                else {                
                    let count = 1;
                    if (item.Repeat > 1)
                        count = item.Repeat;
                    for (let c=0; c<count; c++) {
                        HiddenColumns.push(i + c + 1);
                    }
                }
            });
            if (Columns.DynamicColumn) {                                                    
                GridProps.Computed.autoCellCount = Utils.JustGet(Columns.DynamicColumn, 0, 'CellCount');
            }
            Utils.ForEach(Rows.Items,(item, i) => {
                if (!item.hidden) {
                    if (item.Repeat > 1)
                        GridProps.Computed.fixedRows += item.Repeat;
                    else
                        GridProps.Computed.fixedRows++;  
                }     
                else {                
                    let count = 1;
                    if (item.Repeat > 1)
                        count = item.Repeat;
                    for (let c=0; c<count; c++) {
                        HiddenRows.push(i + c + 1);
                    }
                }       
            });
        }  
    }
    UpdateItemStyleTokens({tokenId, propName, StyleObject, PropsObject, GlobalState, ReversedGlobalStateArray}) {
        if (propName === 'textPattern') {
            const PatternModel = Globals.ProjectManager.Tokens.TypePatterns.GetPattern(tokenId);
            const style_pattern = Globals.ProjectManager.Tokens.TypePatterns.GetPatternStyle(PatternModel, null, GlobalState, ReversedGlobalStateArray);
            Globals.ProjectManager.Tokens.TypePatterns.ApplyPatternStyle(style_pattern, StyleObject);
        }
        else if (Utils.BorderStyles.isSpacingName(propName)) {
            const PatternModel = Globals.ProjectManager.Tokens.SpacePatterns.GetPattern(tokenId);                
            const patternvalue = Globals.ProjectManager.Tokens.SpacePatterns.GetSpaceSize(PatternModel, null, GlobalState, ReversedGlobalStateArray);
            const patternUnit = Globals.ProjectManager.Tokens.SpacePatterns.GetSpaceUnit(PatternModel, GlobalState, ReversedGlobalStateArray);
            if (Utils.IsNotNull(patternvalue)) {
                StyleObject[propName] = Utils.px(patternvalue, patternUnit);
            }
        }
        else {
            const tokenValue = Globals.ProjectManager.Tokens.GetStateValue({Id : tokenId, StateArray : ReversedGlobalStateArray});
            if (Utils.IsNotNull(tokenValue)) {
                if (Utils.BorderStyles.isBorderRadius(propName)) {                                            
                    const value = Utils.UseNull(tokenValue.value, 0);
                    StyleObject[propName] = Utils.px(value, tokenValue.Unit);
                }
                else if (Utils.BorderStyles.isBorderName(propName)) {
                    const propStyle = propName;
                    const propSize = propName.replace('Style', 'Width');
                    StyleObject[propStyle] = tokenValue.style;
                    const value = Utils.UseNull(tokenValue.value, 0);
                    StyleObject[propSize] = Utils.px(value, tokenValue.Unit);
                }
                else if (propName === 'backgroundImage') {                            
                    StyleObject.backgroundImage = Utils.url(tokenValue.url);
                }
                else if (propName === 'background' || propName === 'gradient') {
                    if (tokenValue.gradient)
                        StyleObject.background = Utils.GetGradientCss(tokenValue.gradient.colors, tokenValue.type, Utils.Get(tokenValue.gradient, 0, 'path', 'angle'), Globals.ProjectManager.Tokens.ValueOfId);
                    else
                        StyleObject.backgroundColor = tokenValue;
                }
                else if (propName === 'icon') {                            
                    PropsObject.icon = tokenValue;
                }
                else if (propName === 'transform') {                            
                    if (tokenValue.matrix) {
                        StyleObject.transform = tokenValue.matrix;
                    }
                    else {
                        const transformStyle = Utils.GetTransformCssAll(Utils.Get(tokenValue, [], 'items'), tokenValue.originX, tokenValue.orignY, true);
                        StyleObject.transform = transformStyle.transform;                        
                    }  
                    StyleObject.transformOrigin = Utils.GetTransformOrigin(tokenValue);
                }
                else if (propName === 'filter') {                            
                    const filterStyle = Utils.GetFilterCss(Utils.Get(tokenValue, [], 'items'), MetaData.Properties.filter);
                    if (Utils.IsNotNullOrEmpty(filterStyle))
                        StyleObject.filter = filterStyle;
                }
                else if (propName === 'boxShadow') {                            
                    const shadow = this.Get_Shadow(tokenId);
                    if (shadow) {
                        const shadowStyle = Utils.GetShadowCss(Utils.Get(shadow, [], 'values'), false, Globals.ProjectManager.Tokens.ValueOfId);
                        StyleObject.boxShadow = shadowStyle;
                    }           
                }
                else if (propName === 'textShadow') {                 
                    const shadow = this.Get_Shadow(tokenId);
                    if (shadow) {
                        const shadowStyle = Utils.GetShadowCss(Utils.Get(shadow, [], 'values'), true, Globals.ProjectManager.Tokens.ValueOfId);
                        StyleObject.textShadow = shadowStyle;
                    }           
                }
                else if (propName === 'textContent') {                 
                    PropsObject.textContent = tokenValue;         
                }
                else
                    StyleObject[propName] = tokenValue;
            }    
        }  
    }
    ResetItemStyle(Id) {
        delete this.StateStyles[Id];
    }
    ResetItemAndChildStyle(Id) {
        delete this.StateStyles[Id];
        const MateItem = this.GetMetaItem(Id);
        const SubItems = this.GetChildItems(Id);
        SubItems && Utils.ForEach(SubItems, (SubItem, i) => {
            delete this.StateStyles[SubItem.Id];
        });
    }
    GetItemStateStyle({MetaItem, Id, GlobalState, ComponentState, StateArray, ReverseStyleState, ReversedGlobalStateArray, ParentStyle = {}}) {
        let style = {};
        let props = {};
        let models = {};
        let tokens = {};
        let renderedTokens = {};
        let borderTokens = {};
        let ComputedStyle;

        if (MetaItem) {
            const GetStateStyle = (StyleState, ReverseStyleState) => {
                let ItemStateStyle = Utils.JustGet(this.StateStyles, null, Id, StyleState.Global + StyleState.Component);
                if (!ItemStateStyle) 
                {
                    this.LoadItemStyles({
                        StyleState : StyleState, 
                        ReverseStyleState : ReverseStyleState, 
                        MetaItem : MetaItem, 
                        Id : Id, 
                        ParentStyles : ParentStyle,
                        ReversedGlobalStateArray : ReversedGlobalStateArray
                    });
                }
                return Utils.JustGet(this.StateStyles, null, Id, StyleState.Global + StyleState.Component);
            }

            // if (GlobalState !== 'Default' || ComponentState !== 'Default') {
            //     const DefaultStateStyle = Utils.JustGet(this.StateStyles, null, Id, 'DefaultDefault');
            //     if (!DefaultStateStyle) {
            //         this.GetItemStateStyle({
            //             MetaItem : MetaItem, Id : Id, GlobalState : 'Default', ComponentState : 'Default', 
            //             StateArray :  [{Component : 'Default', Global : 'Default'}], 
            //             ReverseStyleState : [{Component : 'Default', Global : 'Default'}], 
            //             ReversedGlobalStateArray : ReversedGlobalStateArray, 
            //             ParentStyle : ParentStyle
            //         });
            //     }
            // }            


            const ParentFlexDirection = Utils.JustGet(ParentStyle, null, 'flexDirection');

            let ComputedStyle = Utils.JustGet(this.StateStyles, null, Id, 'Computed', GlobalState + ComponentState);
            if (ComputedStyle) {            
                const StyleInstance = Utils.DeepClone(ComputedStyle);
                if (this.StateStyles.PreviewNewToken) {
                    Utils.ForEach(StateArray, (StyleState, i) => {
                        const ItemStateStyle = GetStateStyle(StyleState, ReverseStyleState);
                        if (ItemStateStyle) {
                            Utils.ForEach(ItemStateStyle.Token, (tokenId, propName) => {
                                if (tokenId === this.StateStyles.PreviewNewToken) {                                
                                    this.UpdateItemStyleTokens({
                                        tokenId : tokenId, 
                                        propName : propName, 
                                        StyleObject : StyleInstance.style, 
                                        PropsObject : StyleInstance.props, 
                                        GlobalState : GlobalState,
                                        ReversedGlobalStateArray : ReversedGlobalStateArray
                                    });
                                }                                                                
                            });   
                        }
                    });
                }
                else {
                    Utils.ForEach(StateArray, (StyleState, i) => {
                        const ItemStateStyle = GetStateStyle(StyleState, ReverseStyleState);
                        if (ItemStateStyle) {            
                            const statetokens = Utils.JustGet(ComputedStyle.tokens, {}, StyleState.Global + StyleState.Component);
                            Utils.ForEach(statetokens, (propNames, tokenId) => {
                                if (propNames) {
                                    Utils.ForEach(propNames, (val, propName) => {
                                        this.UpdateItemStyleTokens({
                                            tokenId : tokenId, 
                                            propName : propName, 
                                            StyleObject : ComputedStyle.style, 
                                            PropsObject : ComputedStyle.props, 
                                            ReversedGlobalStateArray : ReversedGlobalStateArray,
                                            GlobalState : GlobalState
                                        });
                                    });
                                }
                            });
                        }
                    });

                    return ComputedStyle;
                }
                // console.log(`${Id} : Computed`);
                return StyleInstance;
            }
            else {
                // console.log(`${Id} : Build`);
            }
            
            const ParentStateValues = {};
            
            Utils.ForEach(StateArray, (StyleState, i) => {
                const ItemStateStyle = GetStateStyle(StyleState, ReverseStyleState);
                
                if (ItemStateStyle) {
                    Utils.ForEach(ItemStateStyle.Static, (value, propName) => {
                        if (Utils.IsNotNull(value)) {
                            style[propName] = value;
                        }                    
                    });
                    Utils.ForEach(ItemStateStyle.Props, (value, propName) => {
                        if (Utils.IsNotNull(value))
                            props[propName] = value;
                    });
                    Utils.ForEach(ItemStateStyle.Reset, (isReset, propName) => {
                        delete style[propName];
                    });

                    if (style.alignSelf === 'stretch') {
                        if ((ParentFlexDirection === 'row' || ParentFlexDirection === 'row-reverse') && style.height)
                            style.height = 'auto';
                        if ((ParentFlexDirection === 'column' || ParentFlexDirection === 'column-reverse') && style.width)
                            style.width = 'auto';
                    }

                    ['Token', 'PreviewToken'].map((TokenType) => {
                        Utils.ForEach(ItemStateStyle[TokenType], (tokenId, propName) => {
                            Utils.Set(tokens, true, StyleState.Global + StyleState.Component, tokenId, propName);
                            Utils.Set(renderedTokens, tokenId, propName);
                            this.UpdateItemStyleTokens({
                                tokenId : tokenId,
                                propName : propName,
                                StyleObject : style,
                                PropsObject : props,
                                GlobalState : GlobalState,
                                ReversedGlobalStateArray : ReversedGlobalStateArray
                            });                                                   
                        });     
                    })
                                
                    Utils.ForEach(ItemStateStyle.Model, (modelId, propName) => {
                        models[propName] = {
                            modelId : modelId,
                            defaultValue : this.GetModelValue({ModelId : modelId})
                        }
                        if (Utils.IsOneOf(propName, 'hidden', 'textContent'))
                            props[propName] = models[propName].defaultValue;
                        else if (propName === 'backgroundImage') {
                            if (models.backgroundImage.defaultValue)
                                style.backgroundImage = Utils.url(models.backgroundImage.defaultValue.url);
                        }
                        else if (propName === 'icon') {
                            if (models.icon.defaultValue)
                                props.backgroundImage = models.icon.defaultValue;
                        }
                    });

                    if (style.backgroundImage) {
                        let positionX = Utils.JustGet(ItemStateStyle.Compound, ParentStateValues.positionX || 'center', 'positionX');
                        let positionY = Utils.JustGet(ItemStateStyle.Compound, ParentStateValues.positionY || 'center', 'positionY');
                        if (positionX === 'custom') {
                            positionX = Utils.JustGet(ItemStateStyle.Compound, 'center', 'customPositionX');
                            if (positionX !== 'center')
                                positionX += '%';                      
                        }
                        ParentStateValues.positionX = positionX;
                        if (positionY === 'custom') {
                            if (Utils.IsNotNullOrEmpty(models.customPositionY))
                                positionY = models.customPositionY.defaultValue;
                            else
                                positionY = Utils.JustGet(ItemStateStyle.Compound, 'center', 'customPositionY');
                            if (positionY !== 'center')
                                positionY += '%';                      
                        }
                        ParentStateValues.positionY = positionY;
                        style.backgroundPosition = `${positionX || 'center'} ${positionY || 'center'}`;

                        let backgroundSize = Utils.JustGet(ItemStateStyle.Compound, ParentStateValues.backgroundSize || 'cover', 'backgroundSize');
                        ParentStateValues.backgroundSize = backgroundSize;
                        if (backgroundSize === 'custom') {
                            let backgroundWidth = Utils.JustGet(ItemStateStyle.Compound, ParentStateValues.backgroundWidth || 'auto', 'backgroundWidth');
                            if (backgroundWidth !== 'auto')
                                backgroundWidth += '%';                    
                            ParentStateValues.backgroundWidth = backgroundWidth;
                            let backgroundHeight = Utils.JustGet(ItemStateStyle.Compound, ParentStateValues.backgroundHeight || 'auto', 'backgroundHeight');
                            if (backgroundHeight !== 'auto')
                                backgroundHeight += '%';
                            ParentStateValues.backgroundHeight = backgroundHeight;
                            style.backgroundSize = backgroundWidth + ' ' + backgroundHeight;
                        }
                        else
                            style.backgroundSize = backgroundSize || 'auto';

                        style.backgroundRepeat = 'no-repeat';
                    } 

                    if (style.display === 'grid') {
                        this.ComputeGridStyle({MetaItem : MetaItem, Id : Id, StyleState : StyleState, GridProps : props});
                    }
                }

                if (MetaItem && MetaItem.SubItems) {                     
                    Utils.ForEach(MetaItem.SubItems, (SubItem) => {
                        if (SubItem) {
                            const SubMetaItem = this.GetMetaItem(SubItem.Id);
                            if (SubMetaItem) 
                                this.GetItemStateStyle({
                                    MetaItem : SubMetaItem,
                                    Id : SubItem.Id,
                                    GlobalState : GlobalState,
                                    ComponentState : ComponentState,
                                    StateArray : StateArray,
                                    ReverseStyleState : ReverseStyleState,
                                    ReversedGlobalStateArray : ReversedGlobalStateArray,
                                    ParentStyle : style
                                });
                                // this.LoadItemStyles(StyleState, ReverseStyleState, SubMetaItem, SubItem.Id, style);
                        }
                    });
                };
            });

            if (MetaItem.Type === MetaData.Components.Component.Type) {                    
                const ComponentInfo = this.GetSubComponentInfo({MetaItem : MetaItem, Id : Id, ReversedStyleState : ReverseStyleState});                    
                if (ComponentInfo) {
                    if (ComponentInfo.Id)
                        props.Id = ComponentInfo.Id;
                    if (ComponentInfo.Models)
                        props.Models = Utils.DeepClone(ComponentInfo.Models);
                    if (ComponentInfo.Variations)
                        props.Variations = Utils.DeepClone(ComponentInfo.Variations);

                    if (props.Models) {
                        Utils.ForEach(props.Models, (SubComponentModelValue, SubComponentModelId) => {
                            if (SubComponentModelValue &&SubComponentModelValue.ModelId) {
                                models[SubComponentModelId] = {
                                    modelId : SubComponentModelValue.ModelId,
                                    defaultValue : this.GetModelValue({ModelId : SubComponentModelValue.ModelId})
                                }
                            }
                        });
                    }
                }
            }            
        }
        

        ComputedStyle = {
            style : style,
            props : props,
            models : models,
            tokens : tokens,
            renderedTokens : renderedTokens,
            borderTokens
        };

        Utils.Set(this.StateStyles, ComputedStyle, Id, 'Computed', GlobalState + ComponentState);
        

        return ComputedStyle;
    }
    





    Load(Id) {
        this.Id = Id;
        this.RootOwnerId = Id;
        this.Data = Globals.ProjectManager.GetComponentData(Id);        
        this.SelectedItems = [];

        if (!ActiveComponentManager.All[Id]) {
            ActiveComponentManager.All[Id] = {
                Managers : {}
            };
        }
        
        ActiveComponentManager.All[Id].Managers[this.ManagerId] = this;

        this.StateStyles = {};

        Events.Listen(this.RootOwnerId, this);
        return true;
    }
    LoadPrototype(Id, ViewId) {
        const Data = Globals.ProjectManager.GetPrototypeData(Id);
        if (Data) {
            if (!ViewId)
                ViewId = Data.MainViewId;
            this.PrototypeMainViewId = Data.MainViewId;
            this.PrototypeViewId = ViewId;
            this.PrototypeData = Data;
            let UseData = Utils.JustGet(Data, null, 'Views', ViewId, 'Design');
            if (!UseData) {
                this.PrototypeViewId = Data.MainViewId;
                UseData = Utils.JustGet(Data, null, 'Views', this.PrototypeViewId, 'Design');
            }
            if (!UseData) {
                if (Data.Views && Utils.Keys(Data.Views).length > 0) {
                    this.PrototypeViewId = Utils.Keys(Data.Views)[0];
                    UseData = Utils.JustGet(Data, null, 'Views', this.PrototypeViewId, 'Design');
                }
                if (!UseData)
                    return;
            }

            if (!ActiveComponentManager.All[ViewId]) {
                ActiveComponentManager.All[ViewId] = {
                    Managers : {}
                };
            }

            ActiveComponentManager.All[ViewId].Managers[this.ManagerId] = this;

            this.StateStyles = {};
            this.SelectedItems = [];
            this.Data = UseData;
            this.PrototypeId = Id;
            this.Id = ViewId;
            this.RootOwnerId = this.PrototypeViewId;
        };
    }    

    OnEvent(Event, Params) {
        if (Params[0] === Events.PARAMS.REFRESH) {
            this.onUpdate && this.onUpdate();
        }
        else if (Params[0] === Events.DESIGNER.ITEM.RENDERRESET && Params[1] !== this.ManagerId) {
            this.UpdateItemRenderStyle({...Params[2], BroadCasted : true});
        }
        else if (Params[0] === Events.DESIGNER.ITEM.CHANGED.STYLE && Params[1] !== this.ManagerId) {
            this.UpdateItemDesigner(Params[2], true /*BroadCasted*/ );
        }     
    }
    UpdateAll() {
        this.UpdateRenderers();
    }
    
    SetEditing(editing) {
        const temp = this.Editing;        
        this.Editing = editing;
        if (temp !== editing) {
            if (this.OnEditingChanged)
                this.OnEditingChanged();
        }
    }
    GetGenericType() {
        return this.Data.GenericType;
    }

    GetRootId() {        
        if (this.PrototypeData)
            return this.GetViewRootId(this.PrototypeViewId);
        return Utils.JustGet(this.Data, null, 'RootItem', 'Id');;
    }
    GetViewRootId(ViewId) {        
        return Globals.ProjectManager.GetPrototypeViewData(this.PrototypeId, ViewId).RootId;
    }
    GetRootMetaItem() {
        const RootId = this.GetRootId();
        if (RootId)
            return this.GetMetaItem(RootId);
    }

    GetMetaItem(Id) {
        const MetaItem = Utils.Get(this.Data, null, 'MetaItems', Id);
        return MetaItem;
    }
    GetChildItems(Id) {
        const MetaItem = this.GetMetaItem(Id);
        if (MetaItem)
            return MetaItem.SubItems;
        return [];
    }

    Log(Info) {
        Globals.ProjectManager.HistoryManager.Log(Info, this.GetLogData());
    }
    GetLogData() {
        return {
            IsComponent : true,
            Id : this.Id,
            ViewId : this.PrototypeViewId,
            Data : this.Data
        };
    }
    GetStatefulOption(PropName, ParentNames, DefaultValue) {        
        let value;
        Utils.ForEach(this.StyleState, (StyleState, i) => {
            let statevalue = Utils.JustGet(this.Data, DefaultValue, 'Options', ...ParentNames, PropName, this.GlobalState, StyleState.Component || Strings.DEFAULT);
            if (Utils.IsNotNull(statevalue))
                value = statevalue;
        });
        return value;
    }
    SetStatefulOption(PropName, ParentNames, Value) {
        this.DataManager.Set(Value, 'Options', ...ParentNames, PropName, this.GlobalState, this.ComponentState);
    }
    //#region Items related
    
    GenericStateValue({Model, GetinheritedValue}) {
        const GetValue = (GlobalState, ComponentState) => {
            return Utils.JustGet(Model, null, 'value', GlobalState, ComponentState, 'value');
        }
        return this.GetGenericStateValue({GetValue : GetValue, GetinheritedValue : GetinheritedValue});
    }
    GetGenericStateValue({GetValue, GetinheritedValue}) {
        let value = GetValue(this.GlobalState, this.ComponentState);
        if (!GetinheritedValue) 
            return value;
        if (Utils.IsNull(value)) {
            if (this.GlobalState !== 'Default' || this.ComponentState !== 'Default') {
                Utils.ForEach(this.ReversedStyleState, (StyleState, i) => {
                    const stateValue = GetValue(StyleState.Global, StyleState.Component);
                    if (!Utils.IsNull(stateValue)) {
                        value = stateValue;
                        if (Utils.IsObject(GetinheritedValue)) {
                            GetinheritedValue.Source = 'ParentState';
                            GetinheritedValue.GlobalState = StyleState.Global;
                            GetinheritedValue.ComponentState = StyleState.Component;
                        }                        
                        return false;
                    }
                });
            }
        }
        else {
            if (Utils.IsObject(GetinheritedValue)) {
                GetinheritedValue.Source = 'ActiveState';
                GetinheritedValue.GlobalState = this.GlobalState;
                GetinheritedValue.ComponentState = this.ComponentState;
            }  
        }
        return value;
    }

    //#endregion

    //#region MetaItem - State Value Get & Set

    GetItemStateValue({GetValue, GetinheritedValue}) {
        return this.GetGenericStateValue({GetValue : GetValue, GetinheritedValue : GetinheritedValue});
    }

    //#endregion

    //#region Variables Related
    
    Variables() {
        return Utils.JustGet(this.Data, {}, Strings.MODELS);
    }
    VariablesOfType(DataType) {
        const list = this.Variables();
        if (list && list.Order && list.Order[DataType]) {
            return list.Order[DataType];
        }
    }
    GetModel(Id) {
        const models = Utils.JustGet(this.Data, {}, Strings.MODELS);
        if (models)
            return models[Id];
    }
    SaveModel({ModelId, Model}) {        
        this.DataManager.Set(Model, Strings.MODELS, ModelId);
    }
    ChangeName(id, name) {
        this.DataManager.Set(name, Strings.MODELS, id, 'name');
    }
    
    
    //#endregion

    //#region Tokens Value Related        
    Get_Shadow(Id) {
        const PreviewToken = Utils.JustGet(GetProjectManager().Tokens, null, 'PreviewTokens', Id);
        if (PreviewToken)
            return PreviewToken;
        const token = GetProjectManager().Tokens.Token(Id);
        if (token) {
            const value = GetProjectManager().Tokens.GetStateValue({Id : Id, StateArray : this.ReversedGlobalStateArray});
            if (value) {
                return {
                    textShadow : token.textShadow,
                    values : Utils.JustGet(value, [], 'values')
                }
            }
        }
    }   
   
    GetPublishedComponent(Id) {
        return AppState.Data.Publish.Component(Id);
    }
    
    //#endregion

    //#region MetaItem Design Related

    AddRootItem({MetaItem, Id, isCopy, DoNotSelect}) {
        this.Log({
            Target : LOG_TYPES.ADD_ROOT,
            Desc : 'Add Component Root'
        });
        this.DataManager.Set({
            Id: Id
        }, 'RootItem');
        this.DataManager.Delete('GenericType');
        Utils.Set(MetaItem, Globals.ProjectManager.Units.FullSize(), 'Property', 'Default', 'Default', 'width',);
        Utils.Set(MetaItem, Globals.ProjectManager.Units.FullSize(), 'Property', 'Default', 'Default', 'height');            
        delete MetaItem.ParentId;
        this.AddComponentMetaItem(MetaItem, Id, isCopy);
        delete MetaItem.ParentId;
        Events.BCE(Events.DESIGNER.ITEM.ROOTITEM.TYPE, Events.DESIGNER.ITEM.ROOTITEM.ADDED);
        if (!DoNotSelect)
            this.SelectItem(Id, true);
    }
    GetMetaItems() {
        return Utils.Get(this.Data, {}, 'MetaItems');
    }

    AddComponentMetaItem(MetaItem, Id, isCopy) {
        if (MetaItem) {
            const Component_MetaItems = this.GetMetaItems();
            if (!Component_MetaItems[Id]) {                
                this.DataManager.Item.Set(Id, MetaItem, []);
                let name = null;

                if (MetaItem.Type === MetaData.Components.Component.Type) {                    
                    const ComponentInfo = this.GetItemPropertyValue({
                        MetaItem : MetaItem, 
                        PropertyName : Strings.COMPONENT,
                    });
                    if (ComponentInfo && ComponentInfo.Id) {
                        name = Globals.ProjectManager.GetComponentName(ComponentInfo.Id);
                        const RelId = Globals.ProjectManager.RelationManager.AddRelation(this.Id, Id, ComponentInfo.Id, {
                            OwnerType : Globals.ProjectManager.RelationManager.Types.COMPONENT,
                            ConsumerType : Globals.ProjectManager.RelationManager.Types.METAITEM,
                            TargetType : Globals.ProjectManager.RelationManager.Types.COMPONENT,
                            ViewId : this.PrototypeViewId
                        });
                        this.DataManager.Item.Set(Id, RelId, [Strings.PROPERTY, Strings.DEFAULT, Strings.DEFAULT, Strings.COMPONENT, Strings.VALUE, Strings.RELATION]);
                        if (!isCopy) {
                            this.InitializeSubcomponent({MetaItem : MetaItem, Id : Id, ComponentId : ComponentInfo.Id});
                        }
                        else {
                            const Manager = ComponentDesignManager.Active().GetDesignManager(ComponentInfo.Id);
                            Utils.ForEach(ComponentInfo.Models, (ModelInfo, ModelId) => {
                                if (ModelInfo && ModelInfo.MockupId) {
                                    const Model = Manager.Models.GetModel(ModelId);
                                    if (Model.DataId) {
                                        const MockupId = GetProjectManager().Mockups.GetRandomRecordId(Model.DataId);
                                        this.DataManager.Item.Set(Id, MockupId, [Strings.PROPERTY, this.GlobalState, this.ComponentState, Strings.COMPONENT, 'value', 'Models', ModelId, 'MockupId']);
                                    }
                                }
                            });
                        }                              
                    }
                }
                else if (MetaItem.Type === MetaData.Components.Token.Type) {
                    name = Utils.JustGet(MetaItem, '', 'Widget', 'Type');
                }
                else if (MetaItem.Type === MetaData.Components.SvgIcon.Type) {
                    const tokenId_icon = this.GetItemStyleToken({MetaItem : MetaItem, Id : Id, StyleName : 'icon'});
                    if (tokenId_icon && tokenId_icon.TokenId) {
                        const RelId = Globals.ProjectManager.RelationManager.AddRelation(this.Id, Id, tokenId_icon.TokenId, 
                            { 
                                OwnerType : Globals.ProjectManager.RelationManager.Types.COMPONENT, 
                                ConsumerType : Globals.ProjectManager.RelationManager.Types.METAITEM,
                                ViewId : this.PrototypeViewId
                            });
                        this.DataManager.Item.Set(Id, RelId, ['Tokens', Strings.DEFAULT, Strings.DEFAULT, 'icon', Strings.RELATION]);
                    }

                    if (!isCopy) {
                        const TemplateId = Globals.ProjectManager.Templates.DefaultTemplateId(Globals.ProjectManager.Templates.Types.Icon);
                        const TokenIdFill = Globals.ProjectManager.Templates.GetStateValue({Id : TemplateId, name : 'fill'});
                        if (TokenIdFill) {
                            this.SetItemToken({MetaItem : MetaItem, Id : Id, StyleName : 'fill', TokenId : TokenIdFill});
                        }
                        const width = Globals.ProjectManager.Templates.GetStateValue({Id : TemplateId, name : 'width'});
                        if (width) {
                            this.DataManager.Item.Set(Id, width, [Strings.PROPERTY, Strings.DEFAULT, Strings.DEFAULT, 'width']);
                        }
                        const height = Globals.ProjectManager.Templates.GetStateValue({Id : TemplateId, name : 'height'});
                        if (height) {
                            this.DataManager.Item.Set(Id, width, [Strings.PROPERTY, Strings.DEFAULT, Strings.DEFAULT, 'height']);
                        }
                    }
                }
                else if (MetaItem.Type === MetaData.Components.Image.Type) {
                    const tokenId_image = this.GetItemStyleToken({MetaItem : MetaItem, Id : Id, StyleName : 'backgroundImage'});
                    if (tokenId_image && tokenId_image.TokenId) {
                        const RelId = Globals.ProjectManager.RelationManager.AddRelation(this.Id, Id, tokenId_image.TokenId, 
                            { 
                                OwnerType : Globals.ProjectManager.RelationManager.Types.COMPONENT, 
                                ConsumerType : Globals.ProjectManager.RelationManager.Types.METAITEM,
                                ViewId : this.PrototypeViewId
                            });
                        this.DataManager.Item.Set(Id, RelId, ['Tokens', Strings.DEFAULT, Strings.DEFAULT, 'backgroundImage', Strings.RELATION]);
                    }
                }
                else if (MetaItem.Type === MetaData.Components.Text.Type) {
                    const tokenId_textPattern = this.GetItemStyleToken({MetaItem : MetaItem, Id : Id, StyleName : 'textPattern'});
                    if (tokenId_textPattern && tokenId_textPattern.TokenId) {
                        const RelId = Globals.ProjectManager.RelationManager.AddRelation(this.Id, Id, tokenId_textPattern.TokenId, 
                            { 
                                OwnerType : Globals.ProjectManager.RelationManager.Types.COMPONENT, 
                                ConsumerType : Globals.ProjectManager.RelationManager.Types.METAITEM,
                                ViewId : this.PrototypeViewId
                            });
                        this.DataManager.Item.Set(Id, RelId, ['Tokens', Strings.DEFAULT, Strings.DEFAULT, 'textPattern', Strings.RELATION]);
                    }

                    if (!isCopy) {
                        const TemplateId = Globals.ProjectManager.Templates.DefaultTemplateId(Globals.ProjectManager.Templates.Types.Text);
                        ['color', 'textPattern'].map((prop) => {
                            let skip;
                            if (prop === 'textPattern' && tokenId_textPattern && tokenId_textPattern.TokenId)
                                skip = true;
                            if (!skip) {
                                const TokenId = Globals.ProjectManager.Templates.GetStateValue({Id : TemplateId, name : prop});
                                if (TokenId) {
                                    this.SetItemToken({MetaItem : MetaItem, Id : Id, StyleName : prop, TokenId : TokenId, DoNotLog : true});
                                }
                            }                            
                        });
                        TextItemStyleNames.map((prop) => {
                            const value = Globals.ProjectManager.Templates.GetStateValue({Id : TemplateId, name : prop});
                            if (value) {
                                this.SetItemPropertyValue({MetaItem : MetaItem, Id : Id, PropertyName : prop, Value : value, DoNotLog : true});
                            }
                        })
                    }
                }
                else if (MetaItem.Type === MetaData.Components.Repeater.Type) {
                    const RepaterItemId = Utils.Id();
                    const RepaterItem = MetaData.ItemInitializer.FromType('Div');
                    RepaterItem.ParentId = Id;
                    RepaterItem.SystemContainer = true;
                    RepaterItem.Property.Default.Default.flex = {
                        value : 1
                    };
                    RepaterItem.Property.Default.Default.alignSelf = {
                        value : 'stretch'
                    };
                    const SubItems = Utils.Get(MetaItem, [], 'SubItems');
                    SubItems.push({Id : RepaterItemId});
                    this.AddComponentMetaItem(RepaterItem, RepaterItemId);
                    this.DataManager.Item.Set(Id, SubItems, ['SubItems']);
                    
                }
                else if (MetaItem.Type === MetaData.Components.Div.Type) {
                    if (!isCopy) {
                        this.SetItemTemplateStyles(MetaItem, Id);
                    }
                }
                else if (MetaItem.Type === MetaData.Components.Widget.Type) {
                    if (!isCopy) {
                        name = MetaData.Components.Widget.WidgetTypes[MetaItem.Widget.Type].Label;
                    }
                }

                this.DataManager.Item.Set(Id, this.GetNewItemName(MetaItem.Type, name), ['name']);

                let ParentLayout = {
                    layout : this.GetItemRenderProperty(MetaItem.ParentId, 'display')
                };
                this.LoadItemStyles({
                    StyleState : {Global : 'Default', Component : 'Default'}, 
                    ReverseStyleState : {Global : 'Default', Component : 'Default'}, 
                    MetaItem : MetaItem, 
                    Id : Id, 
                    ParentStyles : ParentLayout
                });

                return true;
            }
        }
        return false;
    }
    SetItemTemplateStyles(MetaItem, Id) {
        if (MetaItem.Type === MetaData.Components.Div.Type) {
            const TemplateId = Globals.ProjectManager.Templates.DefaultTemplateId(Globals.ProjectManager.Templates.Types.Container);
            ['backgroundColor', 'color'].map((prop) => {
                const TokenId = Globals.ProjectManager.Templates.GetStateValue({Id : TemplateId, name : prop});
                if (TokenId) {
                    this.SetItemToken({MetaItem : MetaItem, Id : Id, StyleName : prop, TokenId : TokenId, DoNotLog : true});
                }
            });
            FlexStyleNames.map((flexProp) => {
                const value = Globals.ProjectManager.Templates.GetStateValue({Id : TemplateId, name : flexProp});
                if (value) {
                    this.SetItemPropertyValue({MetaItem : MetaItem, Id : Id, PropertyName : flexProp, Value : value, DoNotLog : true});
                }
            })
        }
    }
    AddChildItem({ParentId, MetaItem, Id, ChildIndex, Position, Silent, LogOptions, UseNewId, isCopy, MockupFieldId}) {
        const ParentItem = this.GetMetaItem(ParentId);
        if (ParentItem) {
            LogOptions && !LogOptions.Logged && this.Log({
                Target : LOG_TYPES.ADD_CHILD,
                ParentId : ParentId,
                Desc : isCopy ? `Clone ${MetaItem.Type}` : `Add ${MetaItem.Type}`
            });

            if (LogOptions)
                LogOptions.Logged = true;

            let ParentLayout = this.GetItemRenderValue({MetaItem : ParentItem, PropertyName : 'display', DefaultValue : 'flex'});
            const SubItems = Utils.Get(ParentItem, [], 'SubItems');
            let NewId = Id;
            let isNewItem = false;
            if (!NewId) {
                isNewItem = true;
                NewId = UseNewId || Utils.Id();
            }

            let InsertItem = {
                Id: NewId
            };
            if (ChildIndex > -1 && ChildIndex < SubItems.length) {
                SubItems.splice(ChildIndex, 0, InsertItem);
            }
            else
                SubItems.push(InsertItem);
            this.DataManager.Item.Set(ParentId, SubItems, ['SubItems']);
            MetaItem.ParentId = ParentId;
            if (this.AddComponentMetaItem(MetaItem, NewId, isCopy)) {
                // new MetaItem
                this.DataManager.Item.Set(NewId, MetaItem, []);
            }
            else {
                this.DataManager.Item.Set(NewId, ParentId, ['ParentId']);
            }
            // if (Move)
            const childPosition = this.GetItemPropertyValue({MetaItem : MetaItem, PropertyName : 'position'});
            if ((childPosition === 'absolute' || ParentLayout === 'Free') && Position) {
                this.SetItemPropertyValue({MetaItem : MetaItem, Id : NewId, PropertyName : 'left', Value : Position.x});
                this.SetItemPropertyValue({MetaItem : MetaItem, Id : NewId, PropertyName : 'top', Value : Position.y});
            }
            else if (childPosition !== 'absolute') {
                ['left', 'right', 'top', 'bottom', 'position'].map((prop) => {
                    this.RemoveItemProperty({MetaItem : MetaItem, PropertyName : prop, Id : NewId, DoNotLog : true});
                })
            }

            if (ParentLayout === 'flex') {
                const alignItems = this.GetItemRenderValue({MetaItem : ParentItem, PropertyName : MetaData.Properties.FlexValues.FLEXALIGN, DefaultValue : 'center'});
                if (alignItems === MetaData.Properties.FlexValues.FLEXSTRETCH) {
                    const ParentFlexDirection = this.GetItemRenderValue({MetaItem : ParentItem, PropertyName : MetaData.Properties.FlexValues.FLEXDIRECTION, DefaultValue : 'row'});                    
                    if (ParentFlexDirection === 'column' || ParentFlexDirection === MetaData.Properties.FlexValues.COLUMNREVERSE)
                        this.RemoveItemProperty({MetaItem : MetaItem, PropertyName : 'width', Id : NewId, DoNotLog : true});
                    else
                        this.RemoveItemProperty({MetaItem : MetaItem, PropertyName : 'height', Id : NewId, DoNotLog : true});
                }
            }
            else if (ParentLayout === 'grid') {
                this.RemoveItemProperty({MetaItem : MetaItem, PropertyName : 'width', Id : NewId, DoNotLog : true});
                this.RemoveItemProperty({MetaItem : MetaItem, PropertyName : 'height', Id : NewId, DoNotLog : true});
            }

            if (MockupFieldId) {
                const MockupField = GetProjectManager().Mockups.Field(MockupFieldId);
                if (MockupField) {                    
                    const mockupValue = GetProjectManager().Mockups.GetRandomValue(MockupFieldId);
                    const Model = {
                        name : MockupField.name,
                        dataType : MockupField.dataType,
                        value : mockupValue
                    };
                    const ModelId = this.Models.Add(Model);
                    this.Models.SetFakeDataId(ModelId, MockupFieldId);
                    let PropName;
                    
                    if (MetaItem.Type === MetaData.Components.Image.Type) {
                        PropName = 'backgroundImage';                        
                    }
                    else if (MetaItem.Type === MetaData.Components.Text.Type) {
                        PropName = 'textContent';
                        this.DataManager.Item.Set(NewId, MockupField.name, Utils.Concat(['Property', Strings.DEFAULT, Strings.DEFAULT, 'text', 'value']));
                    }

                    if (PropName) {
                        const RelId = this.RelationManager.AddRelation(this.Id, NewId, ModelId,
                        {
                            OwnerType : Globals.ProjectManager.RelationManager.Types.COMPONENT,
                            ConsumerType : Globals.ProjectManager.RelationManager.Types.METAITEM,
                            ItemType : MetaItem.Type,
                            Property : PropName,
                            GlobalState : Strings.DEFAULT,
                            State : Strings.DEFAULT,
                            ViewId : this.PrototypeViewId
                        });
                        

                        Utils.Set(MetaItem, RelId, 'Models', Strings.DEFAULT, Strings.DEFAULT, PropName, 'RelationId');
                        Utils.Set(MetaItem, ModelId, 'Models', Strings.DEFAULT, Strings.DEFAULT, PropName, 'Id');
                        this.DataManager.Item.Set(NewId, RelId, Utils.Concat(['Models', Strings.DEFAULT, Strings.DEFAULT, PropName, 'RelationId']));
                        this.DataManager.Item.Set(NewId, ModelId, Utils.Concat(['Models', Strings.DEFAULT, Strings.DEFAULT, PropName, 'Id']));

                        const Manager = ComponentDesignManager.Active().DesignManagers[this.Id];
                        Manager && Utils.ForEach(Manager.Renderers, (renderer, id) => {
                            renderer.LoadMockupValues();
                        });
                    }                    
                }
                
            }

            delete this.StateStyles[NewId];

            !Silent && this.UpdateItemStyle(NewId);            
            
            if (isNewItem && !Silent)
                this.SelectItem(NewId, true);
                
            return NewId;
        }
    }
    CloneItem(SourceMetaItem, ArtBoard, SourceId, CloneId, UseSourceId, CloneRelations, TargetManager) {
        const UseTargetManager = TargetManager || this;
        const MetaItem = Utils.DeepClone(SourceMetaItem);
        if (CloneRelations && SourceId && CloneId) {
            const RelationMap = Globals.ProjectManager.RelationManager.CloneConsumer(this.Id, SourceId, CloneId);
            if (RelationMap && Object.keys(RelationMap).length > 0) {
                Utils.DeepReplace(SourceMetaItem, MetaItem, RelationMap, false, true);
            }

            const RelationMapComponent = this.RelationManager.CloneConsumer(this.Id, SourceId, CloneId);
            if (RelationMapComponent && Object.keys(RelationMapComponent).length > 0) {
                Utils.DeepReplace(SourceMetaItem, MetaItem, RelationMapComponent, false, true);
            }
        }
        Utils.TraverseSubItems(MetaItem,
            (Item) => {
                return Item.SubItems;
            },
            (SubItem, ParentItem, ParentId, ChildIndex) => {
                const SubMetaItem = this.GetMetaItem(SubItem.Id);
                if (SubMetaItem) {
                    let NewId = Utils.Id();
                    if (UseSourceId)
                        NewId = SubItem.Id;
                    let SubMetaItemClone = this.CloneItem(SubMetaItem, ArtBoard, SubItem.Id, NewId, UseSourceId, CloneRelations, TargetManager);
                    if (SubMetaItemClone) {
                        SubMetaItemClone.ParentId = ParentId;
                        UseTargetManager.AddComponentMetaItem(SubMetaItemClone, NewId, true);
                        ParentItem.SubItems[ChildIndex] = {
                            Id: NewId
                        };
                        if (SourceMetaItem.Type === MetaData.Components.Component.Type) {
                            Utils.ForEach(SourceMetaItem.Slots, (id, slotId) => {
                                if (id === SubItem.Id) {
                                    MetaItem.Slots[slotId] = NewId;
                                    return false;
                                }
                            });
                        }
                        return NewId
                    }
                }
            }, CloneId);
        return MetaItem;
    }
    RemoveItem(ItemId) {
        // Events.BCE(ItemId, Events.DESIGNER.ITEM.DELETED);
    }
    DeleteChildItem(ParentId, ChildId, Silent) {
        const ParentItem = this.GetMetaItem(ParentId);
        if (ParentItem) {
            if (ParentItem.SubItems) {
                const removed = Utils.Remove(ParentItem.SubItems, (SubItem) => {
                    return SubItem.Id === ChildId;
                })
                const ParentLayout = this.GetItemPropertyValue({
                    MetaItem : ParentItem,
                    PropertyName : 'display',
                });
                if (ParentLayout === MetaData.Properties.layout.values.CssGrid.Id) {
                    
                }
                if (removed && removed.length > 0) {
                    this.DataManager.Item.Set(ParentId, ParentItem.SubItems, 'SubItems');
                    const ItemDesigners = this.GetDesigners();
                    if (ItemDesigners[ChildId]) {
                        delete ItemDesigners[ChildId];
                        return true;
                    }
                }
            }
            !Silent && Events.BCE(ParentId, Events.DESIGNER.ITEM.CHANGED.CHILD.DELETED);
        }
    }
    SaveSubItemPositions(ParentId) {
        const ParentItem = this.GetMetaItem(ParentId);
        if (ParentItem) {
            if (ParentItem.SubItems) {
                this.DataManager.Item.Set(ParentId, ParentItem.SubItems, 'SubItems');
            }
        }
    }
    ArrangeItemPosition(ItemId, Direction) {
        const MetaItem = this.GetMetaItem(ItemId);
        const ParentItem = this.GetMetaItem(MetaItem.ParentId);
        if (ParentItem) {
            const index = Utils.FindIndex(ParentItem.SubItems, (item) => {return item.Id === ItemId});
            ParentItem.SubItems.splice(index, 1);
            if (Direction === 'back')
                ParentItem.SubItems.splice(0, 0, {Id : ItemId});
            else if (Direction === 'front')
                ParentItem.SubItems.push({Id : ItemId});
            else if (Direction === 'backward') {
                let newIndex = Math.max(0, index - 1);
                ParentItem.SubItems.splice(newIndex, 0, {Id : ItemId});
            }
            else if (Direction === 'forward') {
                let newIndex = Math.min(ParentItem.SubItems.length, index + 1);
                ParentItem.SubItems.splice(newIndex, 0, {Id : ItemId});
            }
            this.DataManager.Item.Set(MetaItem.ParentId, ParentItem.SubItems, 'SubItems');
            this.ResetItemAndChildStyle(MetaItem.ParentId);
            this.UpdateRenderers();
        }
        
    }
    DeleteSelectedItems() {
        const LoopItems = this.GetSelectedItems().slice();

        if (LoopItems.length > 0) {
            this.Log({
                Target : LOG_TYPES.DELETE,
                Id : LoopItems,
                Desc : `Delete Items`
            });

            let willSelectParentId;
            Utils.ForEach(LoopItems, (Id) => {
                willSelectParentId = this.DeleteItem(Id, true);
            });
            Events.BCE(Events.DESIGNER.COMPONENT.HISTORY.CHANGED, false);
            this.UpdateRenderers();
            if (willSelectParentId)
                this.SelectItem(willSelectParentId, true);
        }
    }
    DeleteSubMetaItems(ItemId, JustChildren) {
        const MetaItem = this.GetMetaItem(ItemId);
        if (MetaItem) {
            if (MetaItem.SubItems) {
                Utils.ForEach(MetaItem.SubItems, (SubItem) => {
                    SubItem && this.DeleteSubMetaItems(SubItem.Id);
                });
            };
        }
        if (!JustChildren) {
            this.DataManager.Delete('MetaItems', ItemId);
            Globals.ProjectManager.RelationManager.DeleteConsumer(this.Id, ItemId);
            Globals.ProjectManager.RelationManager.DeleteOwner(ItemId);

            this.RelationManager.DeleteConsumer(this.Id, ItemId);
            this.RelationManager.DeleteOwner(ItemId);
        }
    }
    DeleteItem(ItemId, Silent) {
        const MetaItem = this.GetMetaItem(ItemId);
        if (MetaItem) {
            if (this.PrototypeViewId) {
                if (ItemId === this.GetRootId())
                    return;
            }

            !Silent && this.Log({
                Target : LOG_TYPES.DELETE,
                Id : ItemId,
                Desc : `Delete ${MetaItem.name || MetaItem.Type}`
            });

            Globals.ProjectManager.RelationManager.DeleteConsumer(this.Id, ItemId);
            Globals.ProjectManager.RelationManager.DeleteOwner(ItemId);

            this.RelationManager.DeleteConsumer(this.Id, ItemId);
            this.RelationManager.DeleteOwner(ItemId);

            if (MetaItem.Internal)
                return;

            this.DeleteSubMetaItems(ItemId, false);

            if (ItemId == this.GetRootId()) {
                this.RemoveComponentRoot();
            }
            else {

            }
            this.DataManager.Item.Delete(ItemId, []);

            // Delete Relations
            if (MetaItem.Type === MetaData.Components.Component.Type) {
                if (MetaItem.ContentItems) {
                    Utils.ForEach(MetaItem.ContentItems, (ContentItemId) => {
                        this.DeleteItem(ContentItemId, Silent);
                    });
                }
            }
            else if (MetaItem.Type === MetaData.Components.Image.Type) {
                Utils.ForEach(MetaItem.Property, (stateprops, state) => {
                    const urlId = Utils.JustGet(stateprops, null, 'MetaImage', 'UrlId', 'value');
                    if (urlId) {
                        AppState.DesignData.Board.Storage.Delete(urlId);
                    }
                });
            }

            if (MetaItem.ParentId) {
                this.DeleteChildItem(MetaItem.ParentId, ItemId, Silent);
            }


            AppLayout.Refs.SelectionDesigner && AppLayout.Refs.SelectionDesigner.UnRegisterItem(ItemId);

            if (!Silent) {
                Utils.RemoveEquals(this.SelectedItems, ItemId);
                if (MetaItem.ParentId)
                    this.SelectItem(MetaItem.ParentId, true);
                else
                    this.SelectItem(null);
                this.UpdateRenderers();                
            }
            else {
                Utils.RemoveEquals(this.SelectedItems, ItemId);
                return MetaItem.ParentId;
            }
        }
    }
    RemoveComponentRoot() {
        this.DataManager.Delete('RootItem');
        Events.BCE(Events.DESIGNER.ITEM.ROOTITEM.TYPE, Events.DESIGNER.ITEM.ROOTITEM.DELETED);
    }

    GetNewItemName(Type, seedName) {
        let Items = this.GetMetaItems();

        let count = 0;
        const names = [];            
        let name = `${seedName || MetaData.Components[Type].Name} 1`;
        if (Items) {
            Utils.ForEach(Items, (item, id) => {
                if (item.Type === Type) {
                    count++;
                    names.push(item.name);
                }
            });
        }
        for (let i=0; i<count+1; i ++) {
            name = `${seedName || MetaData.Components[Type].Name} ${i+1}`;
            if (names.indexOf(name) < 0)
                return name;
        }
        return name;

    }

    WrapItemsIntoContainer(ParentId, indexold, ChildItemIds, direction, IsRootItem) {
        this.Log({
            Target : LOG_TYPES.WRAP,
            Desc : `Wrap in Container`
        });

        const NewParentItem =  MetaData.ItemInitializer.FromType('Div');
        delete NewParentItem.Property.Default.Default.width;
        delete NewParentItem.Property.Default.Default.height;
        if (!IsRootItem)
            delete NewParentItem.Property.Default.Default.backgroundColor;
        NewParentItem.Property.Default.Default.flexDirection.value = direction;
        const NewParentId = Utils.Id();
        const ChildItems = Utils.Get(NewParentItem, [], 'SubItems');
        let index = 999;
        const ItemsToRemove = [];
        let ParentItemId;
        Utils.ForEach(ChildItemIds, (ChildId, i) => {
            ChildItems.push({Id : ChildId});
            const ChildItem = this.GetMetaItem(ChildId);
            if (ChildItem && ChildItem.ParentId) {                    
                if (!ParentItemId) 
                    ParentItemId = ChildItem.ParentId;
                    
                ItemsToRemove.push(ChildId);                                        
            }
            ChildItem.ParentId = NewParentId;
            this.DataManager.Item.Set(ChildId, NewParentId , ['ParentId']);
        });
        if (ParentItemId) {
            const ParentItem = this.GetMetaItem(ParentItemId);
            Utils.ForEach(ItemsToRemove, (Id, i) => {
                index = Math.min(Utils.FindIndex(ParentItem.SubItems, (Item) => { return Item.Id === Id} ), index);
                Utils.Remove(ParentItem.SubItems, (Item) => { return Item.Id === Id} );
            });
            this.DataManager.Item.Set(ParentItemId, ParentItem.SubItems, ['SubItems']);
        }                        

        if (IsRootItem) {
            this.AddRootItem({
                MetaItem : NewParentItem,
                Id : NewParentId
            });
        }
        else {            
            this.AddChildItem({
                ParentId : ParentId, 
                MetaItem : NewParentItem, 
                Id : NewParentId, 
                ChildIndex : index, 
                Position : {}
            });
            this.UpdateItemDesigner(ParentId);
        }        

        setTimeout(() => {
            this.SelectItem(NewParentId, true);
        }, 200);

    }
    GetItemRenderedBounds(Id) {
        const ItemDesigner = this.GetItemDesigner(Id);
        return UIUtils.Utils.GetBounds(ItemDesigner);
    }
    WrapItemIntoRepeater(ParentId, ItemId, RepeatOptions) {
        this.Log({
            Target : LOG_TYPES.WRAP,
            Desc : `Wrap in Repeater`
        });

        const ItemToRepeat = this.GetMetaItem(ItemId);


        const ItemBounds = this.GetItemRenderedBounds(ItemId);
        const RepeaterWidth = ItemBounds.width;
        const RepeaterHeight = ItemBounds.height * RepeatOptions.count;

        const RepeaterId = Utils.Id();
        const RepeaterItem =  MetaData.ItemInitializer.FromType(MetaData.Components.Repeater.Type);
        Utils.Set(RepeaterItem, {value : MetaData.Properties.FlexValues.FLEXSTRETCH}, 'Property', 'Default', 'Default', 'alignItems');
        RepeaterItem.ParentId = ParentId;
        Utils.Set(RepeaterItem.Property.Default.Default, Globals.ProjectManager.Units.PixelValue(RepeaterWidth), 'width');
        Utils.Set(RepeaterItem.Property.Default.Default, Globals.ProjectManager.Units.PixelValue(RepeaterHeight), 'height');            
        Utils.Set(RepeaterItem, RepeatOptions.count, 'Property', 'Default', 'Default', 'count', 'value');
        const RepeaterContainer_Id = Utils.Id();
        Utils.Get(RepeaterItem, [], 'SubItems').push({Id : RepeaterContainer_Id} );
        const RepeaterContainer =  MetaData.ItemInitializer.FromType(MetaData.Components.Div.Type);
        RepeaterContainer.ParentId = RepeaterId;
        [ItemToRepeat, RepeaterContainer].map((item) => {
            delete item.Property.Default.Default.width;
            delete item.Property.Default.Default.height;
            Utils.Set(item.Property.Default.Default, 1, 'flex', 'value');
        });
        Utils.Set(ItemToRepeat, {value : MetaData.Properties.FlexValues.FLEXSTRETCH}, 'Property', 'Default', 'Default', 'alignSelf');
        
        Utils.Get(RepeaterContainer, [], 'SubItems').push({Id : ItemId});
        ItemToRepeat.ParentId = RepeaterContainer_Id;
        
        const ParentItem = this.GetMetaItem(ParentId);
        let index = Utils.FindIndex(ParentItem.SubItems, (Item) => {return Item.Id === ItemId});
        ParentItem.SubItems.splice(index, 1, {Id : RepeaterId});

        this.DataManager.Item.Set(ItemId, ItemToRepeat, []);
        this.DataManager.Item.Set(ParentId, ParentItem.SubItems, ['SubItems']);

        const Component_MetaItems = this.GetMetaItems();
        this.DataManager.Item.Set(RepeaterId, RepeaterItem, []);
        Component_MetaItems[RepeaterId] = RepeaterItem;
        this.DataManager.Item.Set(RepeaterContainer_Id, RepeaterContainer, []);
        Component_MetaItems[RepeaterContainer_Id] = RepeaterContainer;

        Events.BCE(ParentId, Events.DESIGNER.ITEM.RENDERRESET);

        setTimeout(() => {
            this.SelectItem(RepeaterId, true);
        }, 200);

    }
    SelectAll(Select) {
        this.SelectedItems = [];
        Utils.ForEach(this.Designers, (ItemData, Id) => {
            if (Select)
            this.SelectedItems.push(Id);
            Events.BCE(Id, Events.DESIGNER.ITEM.SELECTION, Select);
        });
        Events.BCE(Events.DESIGNER.ITEM.SELECTION);
    }
    SetItemSelectState(Id, Selected) {
        const ItemDesigner = this.GetItemDesigner(Id); 
        if (ItemDesigner) {
            ItemDesigner.SetSelectionState(Selected);
        }
    }
    SelectItem(ItemId, Select, Multiple, WillFocus) {
        Globals.WillFocusToSelectedItem = WillFocus;
        const Manager = this;
        if (ActiveComponentManager.ComponentManager && Manager.ManagerId !== ActiveComponentManager.ComponentManager.ManagerId || !ActiveComponentManager.ComponentManager) {
            ComponentDesignManager.Active().SetActiveComponentManager(Manager);
        }
        if (AppState.Designer.ClosePickerPopup) {
            AppState.Designer.ClosePickerPopup();
        }

        if (AppState.Designer.EditingLayoutItem || AppState.Designer.DisableDragDrop)
            return;
        if (!ItemId) {
            if (Manager.SelectedItems && Manager.SelectedItems.length > 0) {                
                Manager.SelectedItems = [];
                Events.BCE(Events.DESIGNER.ITEM.SELECTION);
                Manager.onItemSelectionChanged && Manager.onItemSelectionChanged();
            }
            return;
        }
        const MetaItem = Manager.GetMetaItem(ItemId);
        if (MetaItem && MetaItem.Internal)
            return;
        if (!MetaItem) {
            const i = Manager.SelectedItems.indexOf(ItemId);
            if (i > -1) {
                Manager.SelectedItems.splice(i, 1);
                Events.BCE(Events.DESIGNER.ITEM.SELECTION);
                Manager.onItemSelectionChanged && Manager.onItemSelectionChanged();
            }
            return;
        }


        if (Multiple) {
            const i = Manager.SelectedItems.indexOf(ItemId);
            if (i < 0) {
                Manager.SelectedItems.push(ItemId);
                Events.BCE(ItemId, Events.DESIGNER.ITEM.SELECTION, true);
                Events.BCE(Events.DESIGNER.ITEM.SELECTION);
                Manager.onItemSelectionChanged && Manager.onItemSelectionChanged();
            }
            else {
                Manager.SelectedItems.splice(i, 1);
                Events.BCE(ItemId, Events.DESIGNER.ITEM.SELECTION, false);
                Events.BCE(Events.DESIGNER.ITEM.SELECTION);
                Manager.onItemSelectionChanged && Manager.onItemSelectionChanged();
            }
        }
        else {
            let KeepSelected = false;
            if (Manager.SelectedItems.length > 0) {
                Manager.SelectedItems.map((Id) => {
                    if (Select && ItemId == Id)
                        KeepSelected = true;
                    else {
                        Events.BCE(Id, Events.DESIGNER.ITEM.SELECTION, false);
                    }
                })
                Manager.SelectedItems = [];
                if (KeepSelected) {
                    Manager.SelectedItems.push(ItemId);
                    Events.BCE(Events.DESIGNER.ITEM.SELECTION);
                    Manager.onItemSelectionChanged && Manager.onItemSelectionChanged();
                    return;
                }
            }

            if (Select) {
                Manager.SelectedItems.push(ItemId);
                Events.BCE(ItemId, Events.DESIGNER.ITEM.SELECTION, true);
            }
            Events.BCE(Events.DESIGNER.ITEM.SELECTION);
            Manager.onItemSelectionChanged && Manager.onItemSelectionChanged();
        }
    }
    IsSelected(ItemId) {
        return Utils.Includes(this.GetSelectedItems(), ItemId);
    }
    GetSelectedItemId() { 
        const SelectedItems = this.GetSelectedItems();
        if (SelectedItems.length > 0) {
            return SelectedItems[0];
        }
    }
    GetSelectedItems() {
        return this.SelectedItems || [];
    }
    UseManager(callback) {
        if (AppState.MainComponentManager && AppState.MainComponentManager !== this &&  AppState.MainComponentManager.Id === this.Id) {
            return callback(AppState.MainComponentManager);
        }
        else
            return callback(this);
    }
    SetSelectedItems(items) {
        this.SelectedItems = items || [];
    }
    IsSelectionMultiple() {
        return this.GetSelectedItems().length > 1;
    }
    ReplaceItem(Id, MetaItem) {
        this.DataManager.Item.Set(Id, MetaItem);
        this.UpdateItemStyle(Id);
    }
    SetItemProp(Id, Value, ...Path) {
        this.DataManager.Item.Set(Id, Value, Path);
        this.UpdateItemStyle(Id);
    }
    GetItemProp(Id, ...Path) {
        return this.DataManager.Item.Get(Id, Path);
    }
    SetRootItemToFullHeight(Id) {
        const GlobalState = this.GlobalState;
        const State = this.ComponentState;
        this.DataManager.Item.Delete(Id, [Strings.PROPERTY, GlobalState, State, 'height', 'Reset']);
        this.DataManager.Item.Set(Id, 100, [Strings.PROPERTY, GlobalState, State, 'height', 'value']);
        this.DataManager.Item.Set(Id, '%', [Strings.PROPERTY, GlobalState, State, 'height', 'Unit']);
        this.UpdateRendererProperty(Id, 'height', '100%', false, true);
    }
    SetItemSize({Id, MetaItem, isWidth, width, width_unit, isFullWidth, isHeight, height, height_unit, isFullHeight, IsRootItem}) {
        const GlobalState = this.GlobalState;
        const State = this.ComponentState;

        this.Log({
            Target : LOG_TYPES.SET_STYLE,
            Id : Id,
            Desc : `Change Size`
        });

        const alignSelf = this.GetItemPropertyValue({MetaItem : MetaItem, PropertyName : 'alignSelf'});
        const flex = this.GetItemPropertyValue({MetaItem : MetaItem, PropertyName : 'flex'});
        const ParentLayout = this.GetItemRenderProperty(MetaItem.ParentId, 'flexDirection');

        let willRemoveFlex = false;
        let willRemoveAlignSelf = false;

        if (isWidth) {
            this.DataManager.Item.Delete(Id, [Strings.PROPERTY, GlobalState, State, 'width', 'Reset']);
            if (isFullWidth) {                
                this.DataManager.Item.Set(Id, 100, [Strings.PROPERTY, GlobalState, State, 'width', 'value']);
                this.DataManager.Item.Set(Id, '%', [Strings.PROPERTY, GlobalState, State, 'width', 'Unit']);
                this.UpdateRendererProperty(Id, 'width', '100%', false, true);
            }
            else {
                this.DataManager.Item.Set(Id, width, [Strings.PROPERTY, GlobalState, State, 'width', 'value']);
                this.DataManager.Item.Set(Id, width_unit || Utils.Defaults.Unit(), [Strings.PROPERTY, GlobalState, State, 'width', 'Unit']);
                this.UpdateRendererProperty(Id, 'width', Utils.px(width, width_unit), false, true);

                willRemoveFlex = flex > 0 && Utils.IsFlexRow(ParentLayout);
                willRemoveAlignSelf = alignSelf === 'stretch' && Utils.IsFlexColumn(ParentLayout);        
            }            
        }

        if (isHeight) {
            this.DataManager.Item.Delete(Id, [Strings.PROPERTY, GlobalState, State, 'height', 'Reset']);
            if (isFullHeight) {
                this.DataManager.Item.Set(Id, 100, [Strings.PROPERTY, GlobalState, State, 'height', 'value']);
                this.DataManager.Item.Set(Id, '%', [Strings.PROPERTY, GlobalState, State, 'height', 'Unit']);
                this.UpdateRendererProperty(Id, 'height', '100%', false, true);
            }
            else {                
                this.DataManager.Item.Set(Id, height, [Strings.PROPERTY, GlobalState, State, 'height', 'value']);
                this.DataManager.Item.Set(Id, height_unit || Utils.Defaults.Unit(), [Strings.PROPERTY, GlobalState, State, 'height', 'Unit']);
                this.UpdateRendererProperty(Id, 'height', Utils.px(height, height_unit), false, true);

                willRemoveFlex = flex > 0 && Utils.IsFlexColumn(ParentLayout);
                willRemoveAlignSelf = alignSelf === 'stretch' && Utils.IsFlexRow(ParentLayout);
            }            
        }

        if (willRemoveFlex) {
            this.DataManager.Item.Delete(Id, [Strings.PROPERTY, GlobalState, State, 'flex',]);
            this.UpdateRendererProperty(Id, 'flex', null, true, true);
        }
        if (willRemoveAlignSelf) {
            this.DataManager.Item.Set(Id, 'center', [Strings.PROPERTY, GlobalState, State, 'alignSelf',]);
            this.UpdateRendererProperty(Id, 'alignSelf', 'center', false, true);
        }
        
        this.UpdateRenderers();
        if (IsRootItem) {
            if (this.ActiveRenderer && this.ActiveRenderer.onRootItemResized) {
                this.ActiveRenderer.onRootItemResized();
            }            
        }
    }
    Set_Name(MetaItem, Id, Name) {
        this.DataManager.Item.Set(Id, Name, ['name']);
        Events.BCE(Id, Events.DESIGNER.ITEM.CHANGED.NAME, Name);
    }
    ApplyStyleToSelectedItems({styles, props}) {
        const SelectedItems = this.GetSelectedItems();
        if (SelectedItems) {
            if (SelectedItems.length > 1) {
                const SourceItemId = SelectedItems[0];
                const SourceMetaItem = this.GetMetaItem(SourceItemId);

                const StyleValues = {};

                Utils.ForEach(styles, (styleName, i) => {
                    const value = this.GetItemRenderValue({MetaItem : SourceMetaItem, PropertyName : styleName});
                    Utils.Set(StyleValues, value, styleName, 'value');
                    if (Utils.Includes(SizeStyles, styleName)) {
                        const unit = this.GetItemPropertyValue({MetaItem : SourceMetaItem, PropertyName : styleName, ValueName : 'Unit'});
                        Utils.Set(StyleValues, unit, styleName, 'Unit');
                    }
                });
                this.Log({
                    Target : LOG_TYPES.SET_STYLE,
                    Id : SourceItemId,
                    Desc : `Apply styles`
                });
                Utils.ForEach(SelectedItems, (Id) => {
                    if (Id !== SourceItemId) {
                        let MetaItem = this.GetMetaItem(Id);
                        if (MetaItem) {
                            Utils.ForEach(StyleValues, ({value, unit}, style) => {
                                this.SetItemPropertyValue({MetaItem : MetaItem, Id : Id, PropertyName : style, Value : value});
                                if (unit)
                                    this.SetItemPropertyValue({MetaItem : MetaItem, Id : Id, PropertyName : style, Value : unit, ValueName : 'Unit'});                                
                            });
                        }
                        this.UpdateItemDesigner(Id);
                    }                        
                });
            }
        }        
    }
    MoveStylesToParentState(GlobalState, ComponentState) {
        const SelectedItems = this.GetSelectedItems();
        Utils.ForEach(SelectedItems, (Id, i) => {
            const MetaItem = this.GetMetaItem(Id);
            if (MetaItem) {
                ['Property', 'Tokens', 'Models', 'Style'].map((propGroup) => {
                    const Properties = Utils.JustGet(MetaItem, {}, propGroup, this.GlobalState, this.ComponentState);
                    Utils.ForEach(Properties, (Property, PropName) => {
                        let skip = false;
                        if (propGroup === 'Tokens' && !Property.Id)
                            skip = true;

                        if (!skip) {
                            Utils.Set(MetaItem, Property, propGroup, GlobalState, ComponentState, PropName);
                            this.DataManager.Item.Set(Id, Property, [propGroup, GlobalState, ComponentState, PropName]);                    
                            this.DataManager.Item.Delete(Id, [propGroup, this.GlobalState, this.ComponentState, PropName]);                                         
                        }                        
                        // const ParentValue = Utils.JustGet(MetaItem, {}, 'Property', GlobalState, th.ComponentState);
                    });    
                })
                
                this.UpdateItemStyle(Id);
            }
        });
    }
    CopySelectedItems() {
        ClipboardItems = [];
        const SelectedItems = this.GetSelectedItems();
        Utils.ForEach(SelectedItems, (Id) => {
            let MetaItem = this.GetMetaItem(Id);
            if (MetaItem) {
                let clone = this.CloneItem(MetaItem, false, Id, null, false, false);
                const StoreItem = {
                    MetaItem: clone,
                    Id : Id
                };
                ClipboardItems.push(StoreItem);
            }
        });
    }
    Paste() {
        if (ClipboardItems.length > 0) {
            const SelectedItems = this.GetSelectedItems();
            if (SelectedItems.length === 1) {
                const ParentItem = this.GetMetaItem(SelectedItems[0]);
                if (MetaData.Components.IsContainer(ParentItem)) {
                    Utils.ForEach(ClipboardItems, (Item) => {
                        const NewId = Utils.Id();
                        const MetaItem = this.CloneItem(Item.MetaItem, false, Item.Id, NewId, false, true);                        
                        this.AddChildItem({
                            ParentId : SelectedItems[0], 
                            MetaItem : MetaItem, 
                            Id : NewId, 
                            ChildIndex : 0, 
                            Position : {x : 0, y : 0}, 
                            LogOptions : {}, 
                            isCopy : true
                        });
                        this.UpdateItemDesigner(SelectedItems[0], true)
                    });
                }
            }
        }
    }
    //#endregion

    //#region Item Designers
    GetDesigners() {        
        return Utils.Get(this, {}, 'ItemDesigners');
    }
    Register_ItemDesigner(Id, Designer) {
        const ItemDesigners = this.GetDesigners();
        ItemDesigners[Designer.props.Id] = {
            Designer: Designer
        };      
    }
    UnRegister_ItemDesigner(Id, Designer) {
        const ItemDesigners = this.GetDesigners();
        if (ItemDesigners[Id] && ItemDesigners[Id] === Designer) {
            delete ItemDesigners[Id];
            return true;
        }        
    }
    UpdateItemDesigner(Id, BroadCasted) {    
        this.UpdateRenderers();    
        // if (ActiveComponentManager.All[this.RootOwnerId]) {
        //     Utils.ForEach(ActiveComponentManager.All[this.RootOwnerId].Managers, (manager, ) => {
        //         const ItemDesigner = manager.GetItemDesigner(Id);
        //         if (ItemDesigner) {
        //             ItemDesigner.RefreshItem();
        //         }   
        //     });
        // }
    }
    UpdateItemRenderStyle({Id, Styles, BroadCasted}) {
        const ItemDesigner = this.GetItemDesigner(Id); 
            if (ItemDesigner) {
                ItemDesigner.UpdateRenderedStyle({Styles : Styles});
                if (!BroadCasted)
                    Events.BroadcastThrottle(this.Id, Events.DESIGNER.ITEM.RENDERRESET, this.ManagerId, {Id : Id, Styles : Styles});             
            }
    }
    UpdateItemPreviews(Id, Show) {
        AppLayout.Refs.SelectionDesigner && AppLayout.Refs.SelectionDesigner.TogglePreview(Show);
        if (ActiveComponentManager.All[this.RootOwnerId]) {
            Utils.ForEach(ActiveComponentManager.All[this.RootOwnerId].Managers, (manager, ) => {
                const ItemDesigner = manager.GetItemDesigner(Id);
                if (ItemDesigner) {
                    // ItemDesigner.RefreshPreviewStyle(Show);
                }   
            });
        }
    }
    SetItemPreviewStyle({Id, style, BroadCasted}) {
        if (ActiveComponentManager.All[this.RootOwnerId]) {
            Utils.ForEach(ActiveComponentManager.All[this.RootOwnerId].Managers, (manager, ) => {
                const ItemDesigner = manager.GetItemDesigner(Id);
                if (ItemDesigner) {
                    ItemDesigner.SetPreviewStyle({style : style});
                }   
            });
        } 
        else {
            const ItemDesigner = this.GetItemDesigner(Id); 
            if (ItemDesigner) {
                ItemDesigner.SetPreviewStyle({style : style});
            }
        }                
    }
    CancelPreviewStyle({Id}) {
        const ItemDesigner = this.GetItemDesigner(Id); 
        if (ItemDesigner) {
            ItemDesigner.CancelPreviewStyle();
        }
    }
    GetItemDesigner(Id) {
        const ItemDesigners = this.GetDesigners();
        let Designer;
        if (ItemDesigners[Id])
            Designer = ItemDesigners[Id].Designer;
        return Designer;
    }
    GetItemCurrentBackgroundcolor(Id) {
        const ItemDesigner = this.GetItemDesigner(Id);
        if (ItemDesigner) {
            if (ItemDesigner.Ref_Item && ItemDesigner.Ref_Item.current) {
                const DN = ReactDOM.findDOMNode(ItemDesigner.Ref_Item.current);
                if (DN) {
                    const style = window.getComputedStyle(DN);
                    if (style.backgroundColor && style.backgroundColor !== 'rgba(0, 0, 0, 0)')
                        return style.backgroundColor;
                    const MetaItem = this.GetMetaItem(Id);        
                    if (MetaItem && MetaItem.ParentId)
                        return this.GetItemCurrentBackgroundcolor(MetaItem.ParentId);
                }
            }
        }
        else {
            const MetaItem = this.GetMetaItem(Id);
        }
    }
    IsLocked(Id) {
        const ItemDesigner = this.GetItemDesigner(Id);
        if (ItemDesigner && ItemDesigner.IsLocked)
            return ItemDesigner.IsLocked();
    }
    MakeLocked(Id, Locked, Toggle) {
        const MetaItem = this.GetMetaItem(Id);
        if (MetaItem) {
            if (Toggle) {
                Locked = !MetaItem.Locked;
            }
            if (Locked)
                MetaItem.Locked = true;
            else
                delete MetaItem.Locked;
            this.DataManager.Item.Set(Id, MetaItem.Locked, ['Locked']);
            Events.BCE(Id, Events.DESIGNER.ITEM.CHANGED.LOCK, Locked);
            Events.BCE(Events.DESIGNER.ITEM.CHANGED.LOCK);
        }
    }
    //#endregion Item Designers

    UpdateItemStyle(Id, GlobalState, State) {
    }

    GetItemType(ItemType, Draggable, GenericType) {
        return GetItemType(ItemType, Draggable, GenericType);
    }

    //#region New Item Style Handling
    GetItemStyleToken({MetaItem, Id, StyleName, CurrentState, ParentNames = []}) {
        const Result = {
            DefaultTokenId : this.GetItemStyleStateToken({MetaItem : MetaItem, ParentNames : ParentNames, StyleName : StyleName, GlobalState : Strings.DEFAULT, ComponentState : Strings.DEFAULT})
        }

        let PreviewTokenId = Utils.JustGet(MetaItem, null, 'Tokens', this.GlobalState, this.ComponentState, ...ParentNames, StyleName, 'PreviewTokenId');
        if (PreviewTokenId) {            
            if (StyleName === 'textPattern') {
                Result.TokenId = PreviewTokenId;
            }
            else {
                const PreviewValue = Globals.ProjectManager.Tokens.GetStateValue({Id : PreviewTokenId, StateArray : this.ReversedGlobalStateArray});
                if (Utils.IsNotNullOrEmpty(PreviewValue)) {
                    Result.TokenId = PreviewTokenId;
                }
            }            
        }
            

        if (CurrentState)
            Result.TokenId = this.GetItemStyleStateToken({MetaItem : MetaItem, ParentNames : ParentNames, StyleName : StyleName, GlobalState : this.GlobalState, ComponentState : this.ComponentState});
        else {
            if (this.GlobalState !== Strings.DEFAULT || this.ComponentState !== Strings.DEFAULT) {
                Utils.ForEach(this.ReversedStyleState, (StyleState, i) => {
                    PreviewTokenId = Utils.JustGet(MetaItem, null, 'Tokens', StyleState.Global, StyleState.Component, ...ParentNames, StyleName, 'PreviewTokenId');
                    if (PreviewTokenId) {
                        Result.TokenId = PreviewTokenId;
                        return false;
                    }
                    const StateTokenId = this.GetItemStyleStateToken({MetaItem : MetaItem, ParentNames : ParentNames, StyleName : StyleName, GlobalState : StyleState.Global, ComponentState : StyleState.Component});
                    if (StateTokenId) {
                        Result.TokenId = StateTokenId;
                        if (StyleState.Global !== this.GlobalState || StyleState.Component !== this.ComponentState) {
                            Result.InheritedState = {
                                Global : StyleState.Global,
                                Component : StyleState.Component
                            }
                        }
                        return false;
                    }
                });
            }  
            else
                Result.TokenId = Result.TokenId || Result.DefaultTokenId;          
        }

        return Result;
    }
    GetItemStyleStateToken({MetaItem, StyleName, ParentNames = [], GlobalState, ComponentState}) {
        return Utils.JustGet(MetaItem, null, 'Tokens', GlobalState, ComponentState, ...ParentNames, StyleName, 'Id');
    }
    GetItemStyleStateTokenRelationId({MetaItem, StyleName, ParentNames = [], GlobalState, ComponentState}) {
        return Utils.JustGet(MetaItem, null, 'Tokens', GlobalState, ComponentState, ...ParentNames, StyleName, 'RelationId');
    }
    GetItemStyleParentStateToken({MetaItem, ParentNames = [], StyleName}) {    
        let currentStateFound = false;
        let TokenId;
        Utils.ForEach(this.ReversedStyleState, (StyleState, i) => {
            if (!currentStateFound && StyleState.Global === this.GlobalState && StyleState.Component === this.ComponentState) 
                currentStateFound = true;
            else if (currentStateFound) {
                TokenId = this.GetItemStyleStateToken({MetaItem : MetaItem, ParentNames : ParentNames, StyleName : StyleName, GlobalState : StyleState.Global, ComponentState : StyleState.Component});
                if (TokenId) {
                    return false;
                }
            }
        });
        return TokenId;
    }
    DeletePreviewToken(MetaItem, ParentNames = [], StyleName) {
        this.SetItemToken({MetaItem : MetaItem, ParentNames : ParentNames, StyleName : StyleName, Preview : true, SetPreview : false});
    }
    SetItemToken({MetaItem, Id, TokenId, ParentNames = [], StyleName, Preview, SetPreview, SetToDefaultStateIfNull, DoNotLog, ToGlobalState, ToComponentState}) {
        if (!MetaItem)
            return;
        let UseStateGlobal = ToGlobalState || this.GlobalState;
        let UseStateComponent = ToComponentState || this.ComponentState;
        let SetToDefaultStateAsWell;

        if (SetToDefaultStateIfNull && (this.GlobalState !== Strings.DEFAULT || this.ComponentState !== Strings.DEFAULT)) {
            const DefaultTokenId = this.GetItemStyleStateToken({MetaItem : MetaItem, ParentNames : ParentNames, StyleName : StyleName, GlobalState : Strings.DEFAULT, ComponentState : Strings.DEFAULT})
            if (!DefaultTokenId) {
                SetToDefaultStateAsWell = true;
            }
        }

        if (Preview) {
            if (SetPreview)
                Utils.Set(MetaItem, TokenId, 'Tokens', UseStateGlobal, UseStateComponent, ...ParentNames, StyleName, 'PreviewTokenId');
            else {
                Utils.UnSet(MetaItem, 'Tokens', UseStateGlobal, UseStateComponent, ...ParentNames, StyleName, 'PreviewTokenId');
            }

            this.UpdateRendererTokenPreview(Id, StyleName, TokenId, !SetPreview);
            return;
        }
        Utils.UnSet(MetaItem, 'Tokens', UseStateGlobal, UseStateComponent, ...ParentNames, StyleName, 'PreviewTokenId');
        const CurrentTokenId = this.GetItemStyleStateToken({MetaItem : MetaItem, ParentNames : ParentNames, StyleName : StyleName, GlobalState : UseStateGlobal, ComponentState : UseStateComponent});
        if (CurrentTokenId && CurrentTokenId === TokenId)
            return;        
        
        const modelId = this.GetItemStyleModel({MetaItem : MetaItem, Id : Id, ParentNames : ParentNames, PropertyName : StyleName}).ModelId;
        if (modelId) {
            if (this.Models.SetTokenId(modelId, TokenId))
                return;
        }
        
        !DoNotLog && this.Log({
            Target : LOG_TYPES.SET_STYLE,
            Id : Id,
            Desc : `Bind to Token ` + StyleName
        });

        Utils.Set(MetaItem, TokenId, 'Tokens', UseStateGlobal, UseStateComponent, ...ParentNames, StyleName, 'Id');

        this.DataManager.Item.Set(Id, TokenId, Utils.Concat(['Tokens', UseStateGlobal, UseStateComponent], ParentNames, [StyleName, 'Id']));
                
        if (CurrentTokenId) {
            const OldRelationId = this.GetItemStyleStateTokenRelationId({MetaItem : MetaItem, ParentNames : ParentNames, StyleName : StyleName, GlobalState : UseStateGlobal, ComponentState : UseStateComponent});            
            OldRelationId && Globals.ProjectManager.RelationManager.DeleteRelationId(OldRelationId);
        }                

        const RelId = Globals.ProjectManager.RelationManager.AddRelation(this.Id, Id, TokenId,
        {
            OwnerType : Globals.ProjectManager.RelationManager.Types.COMPONENT,
            ConsumerType : Globals.ProjectManager.RelationManager.Types.METAITEM,
            ItemType : MetaItem.Type,
            Property : StyleName,
            GlobalState : UseStateGlobal,
            State : UseStateComponent,
            ViewId : this.PrototypeViewId
        });
        Utils.Set(MetaItem, RelId, 'Tokens', UseStateGlobal, UseStateComponent, ...ParentNames, StyleName, 'RelationId');
        this.DataManager.Item.Set(Id, RelId, Utils.Concat(['Tokens', UseStateGlobal, UseStateComponent], ParentNames, [StyleName, 'RelationId']));
        
        if (SetToDefaultStateAsWell && !Preview) {
            this.SetItemToken({
                MetaItem : MetaItem, 
                Id : Id, 
                TokenId : TokenId, 
                ParentNames : ParentNames, 
                StyleName : StyleName, 
                SetToDefaultStateIfNull : false, 
                DoNotLog : true,
                ToGlobalState : Strings.DEFAULT,
                ToComponentState : Strings.DEFAULT,
            });
        }

        this.UpdateRendererToken(Id, StyleName, TokenId, false);
    }
    UpdateRendererTokenPreview(Id, PropName, TokenId, Delete) {
        const Manager = ComponentDesignManager.Active().DesignManagers[this.Id];
        const State = this.GlobalState + this.ComponentState;
        if (Manager) {
            const ItemStateStyles = Utils.Get(Manager.StateStyles, {}, Id);
            if (Delete)
                Utils.UnSet(ItemStateStyles, (State), 'PreviewToken', PropName );            
            else
                Utils.Set(ItemStateStyles, TokenId, (State), 'PreviewToken', PropName );            
        }
        Utils.UnSet(this.StateStyles, Id, 'Computed');
        this.UpdateRenderers();
    }
    UpdateRendererNewTokenPreview(Id, TokenId) {
        Utils.UnSet(this.StateStyles, Id, 'Computed');   
        this.UpdateRenderers();
    }
    UpdateRendererToken(Id, PropName, TokenId, Delete) {
        const Manager = ComponentDesignManager.Active().DesignManagers[this.Id];
        const State = this.GlobalState + this.ComponentState;
        if (Manager) {
            const ItemStateStyles = Utils.Get(Manager.StateStyles, {}, Id);
            if (Delete)
                Utils.UnSet(ItemStateStyles, (State), 'Token', PropName );            
            else
                Utils.Set(ItemStateStyles, TokenId, (State), 'Token', PropName );            
        }
                
        Utils.UnSet(this.StateStyles, Id, 'Computed');   
        this.UpdateRenderers();
    }
    UpdateRendererModel(Id, PropName, ModelId, Delete, Preview) {
        const Manager = ComponentDesignManager.Active().DesignManagers[this.Id];
        const State = this.GlobalState + this.ComponentState;
        let TypeName = Preview ? 'PreviewModel' : 'Model';
        if (Manager) {
            const ItemStateStyles = Utils.Get(Manager.StateStyles, {}, Id);
            if (Delete)
                Utils.UnSet(ItemStateStyles, (State), TypeName, PropName );            
            else
                Utils.Set(ItemStateStyles, ModelId, (State), TypeName, PropName );            
        }
        Utils.UnSet(this.StateStyles, Id, 'Computed');   
        this.UpdateRenderers();
    }
    RemoveItemToken({MetaItem, Id, ParentNames = [], StyleName}) {
        if (!MetaItem)
            return;
        let UseStateGlobal = this.GlobalState;
        let UseStateComponent = this.ComponentState;

        const CurrentTokenId = this.GetItemStyleStateToken({MetaItem : MetaItem, ParentNames : ParentNames, StyleName : StyleName, GlobalState : this.GlobalState, ComponentState : this.ComponentState});
        if (!CurrentTokenId) {
            this.DataManager.Item.Set(Id, true, Utils.Concat(['Tokens', this.GlobalState, this.ComponentState], ParentNames, [StyleName, 'Reset']));
            this.UpdateRendererToken(Id, StyleName, null, true);
            return;            
        }            
            
        this.UpdateRendererToken(Id, StyleName, null, true);
        
        this.Log({
            Target : LOG_TYPES.SET_STYLE,
            Id : Id,
            Desc : `Remove Token ` + StyleName
        });

        Utils.UnSet(MetaItem, 'Tokens', UseStateGlobal, UseStateComponent, ...ParentNames, StyleName, 'Id');
        this.DataManager.Item.Delete(Id, Utils.Concat(['Tokens', UseStateGlobal, UseStateComponent], ParentNames, [StyleName, 'Id']));
        const RelationId = this.GetItemStyleStateTokenRelationId({MetaItem : MetaItem, ParentNames : ParentNames, StyleName : StyleName, GlobalState : UseStateGlobal, ComponentState : UseStateComponent});            
        RelationId && Globals.ProjectManager.RelationManager.DeleteRelationId(RelationId);
        this.DataManager.Item.Delete(Id, Utils.Concat(['Tokens', UseStateGlobal, UseStateComponent], ParentNames, [StyleName, 'RelationId']));
    }
    GetItemBackgroundColorForTextContrast({MetaItem, Id}) {
        let UseMetaItem = MetaItem;
        let tokenId = Utils.JustGet(this.GetItemStyleToken({MetaItem : MetaItem, Id : Id, StyleName : 'backgroundColor'}), null, 'TokenId');        
        if (!tokenId) {
            while (UseMetaItem.ParentId) {
                const ParentId = UseMetaItem.ParentId;
                UseMetaItem = this.GetMetaItem(ParentId);
                if (UseMetaItem) {
                    tokenId = Utils.JustGet(this.GetItemStyleToken({MetaItem : UseMetaItem, ParentId : Id, StyleName : 'backgroundColor'}), null, 'TokenId');        
                    if (tokenId)
                        break;
                }
                else
                    break;
            }            
        }
        if (tokenId)
            return Globals.ProjectManager.Tokens.GetStateValue({Id : tokenId, StateArray : this.ReversedGlobalStateArray});
    }
    
    // New Item Model Bindings

    GetItemStyleModel({MetaItem, Id, PropertyName, ParentNames = [], CurrentState}) {
        const Result = {
            DefaultModelId : this.GetItemStyleStateModel({MetaItem : MetaItem, ParentNames : ParentNames, PropertyName : PropertyName, GlobalState : Strings.DEFAULT, ComponentState : Strings.DEFAULT})
        }

        let PreviewModelId = Utils.JustGet(MetaItem, null, 'Models', this.GlobalState, this.ComponentState, ...ParentNames, PropertyName, 'PreviewModelId');
        if (PreviewModelId) {            
            if (PropertyName === 'textPattern') {
                Result.ModelId = PreviewModelId;
            }
            else {
                const PreviewValue = Globals.ProjectManager.Models.GetStateValue({Id : PreviewModelId, StateArray : this.ReversedGlobalStateArray});
                if (Utils.IsNotNullOrEmpty(PreviewValue)) {
                    Result.ModelId = PreviewModelId;
                }
            }            
        }
            

        if (CurrentState)
            Result.ModelId = this.GetItemStyleStateModel({MetaItem : MetaItem, ParentNames : ParentNames, PropertyName : PropertyName, GlobalState : this.GlobalState, ComponentState : this.ComponentState});
        else {
            if (this.GlobalState !== Strings.DEFAULT || this.ComponentState !== Strings.DEFAULT) {
                Utils.ForEach(this.ReversedStyleState, (StyleState, i) => {
                    PreviewModelId = Utils.JustGet(MetaItem, null, 'Models', StyleState.Global, StyleState.Component, ...ParentNames, PropertyName, 'PreviewModelId');
                    if (PreviewModelId) {
                        Result.ModelId = PreviewModelId;
                        return false;
                    }
                    const StateModelId = this.GetItemStyleStateModel({MetaItem : MetaItem, ParentNames : ParentNames, PropertyName : PropertyName, GlobalState : StyleState.Global, ComponentState : StyleState.Component});
                    if (StateModelId) {
                        Result.ModelId = StateModelId;
                        if (StyleState.Global !== this.GlobalState || StyleState.Component !== this.ComponentState) {
                            Result.InheritedState = {
                                Global : StyleState.Global,
                                Component : StyleState.Component
                            }
                        }
                        return false;
                    }
                });
            }  
            else
                Result.ModelId = Result.ModelId || Result.DefaultModelId;          
        }

        return Result;
    }
    GetItemStyleStateModel({MetaItem, PropertyName, ParentNames = [], GlobalState, ComponentState}) {
        return Utils.JustGet(MetaItem, null, 'Models', GlobalState, ComponentState, ...ParentNames, PropertyName, 'Id');
    }
    GetItemStyleStateModelRelationId({MetaItem, PropertyName, ParentNames = [], GlobalState, ComponentState}) {
        return Utils.JustGet(MetaItem, null, 'Models', GlobalState, ComponentState, ...ParentNames, PropertyName, 'RelationId');
    }
    GetItemStyleParentStateModel({MetaItem, PropertyName, ParentNames}) {    
        let currentStateFound = false;
        let ModelId;
        Utils.ForEach(this.ReversedStyleState, (StyleState, i) => {
            if (!currentStateFound && StyleState.Global === this.GlobalState && StyleState.Component === this.ComponentState) 
                currentStateFound = true;
            else if (currentStateFound) {
                ModelId = this.GetItemStyleStateModel({MetaItem : MetaItem, ParentNames : ParentNames, PropertyName : PropertyName, GlobalState : StyleState.Global, ComponentState : StyleState.Component});
                if (ModelId) {
                    return false;
                }
            }
        });
        return ModelId;
    }
    DeletePreviewModel(MetaItem, PropertyName, ParentNames = []) {
        this.SetItemModel({MetaItem : MetaItem, ParentNames : ParentNames, PropertyName : PropertyName, Preview : true, SetPreview : false});
    }
    SetItemModel({MetaItem, Id, ModelId, ParentNames = [], PropertyName, Preview, SetPreview, SetToDefaultStateIfNull, DoNotLog, ToGlobalState, ToComponentState}) {
        if (!MetaItem)
            return;
        let UseStateGlobal = ToGlobalState || this.GlobalState;
        let UseStateComponent = ToComponentState || this.ComponentState;
        let SetToDefaultStateAsWell;

        if (SetToDefaultStateIfNull && (this.GlobalState !== Strings.DEFAULT || this.ComponentState !== Strings.DEFAULT)) {
            const DefaultModelId = this.GetItemStyleStateModel({MetaItem : MetaItem, ParentNames : ParentNames, PropertyName : PropertyName, GlobalState : Strings.DEFAULT, ComponentState : Strings.DEFAULT})
            if (!DefaultModelId) {
                SetToDefaultStateAsWell = true;
            }
        }

        if (Preview) {
            if (SetPreview)
                Utils.Set(MetaItem, ModelId, 'Models', UseStateGlobal, UseStateComponent, ...ParentNames, PropertyName, 'PreviewModelId');
            else
                Utils.UnSet(MetaItem, 'Models', UseStateGlobal, UseStateComponent, ...ParentNames, PropertyName, 'PreviewModelId');

            this.UpdateRendererModel(Id, PropertyName, ModelId, Preview && !SetPreview, Preview);
            return;
        }
        Utils.UnSet(MetaItem, 'Models', UseStateGlobal, UseStateComponent, ...ParentNames, PropertyName, 'PreviewModelId');
        const CurrentModelId = this.GetItemStyleStateModel({MetaItem : MetaItem, ParentNames : ParentNames, PropertyName : PropertyName, GlobalState : UseStateGlobal, ComponentState : UseStateComponent});
        if (CurrentModelId && CurrentModelId === ModelId)
            return;        
        
        const tokenId = Utils.Get(MetaItem, null, 'Tokens', UseStateGlobal, UseStateComponent, ...ParentNames, PropertyName, 'Id');
        if (tokenId) {
            Utils.UnSet(MetaItem, 'Tokens', UseStateGlobal, UseStateComponent, ...ParentNames, PropertyName, 'Id');
            this.DataManager.Item.Delete(Id, Utils.Concat(['Tokens', UseStateGlobal, UseStateComponent], ParentNames, [PropertyName, 'Id']));        
            const RelationId = this.GetItemStyleStateTokenRelationId({MetaItem : MetaItem, ParentNames : ParentNames, StyleName : PropertyName, GlobalState : UseStateGlobal, ComponentState : UseStateComponent});            
            RelationId && Globals.ProjectManager.RelationManager.DeleteRelationId(RelationId);
            this.DataManager.Item.Delete(Id, Utils.Concat(['Tokens', UseStateGlobal, UseStateComponent], ParentNames, [PropertyName, 'RelationId']));
        }
        
        !DoNotLog && this.Log({
            Target : LOG_TYPES.SET_STYLE,
            Id : Id,
            Desc : `Bind to Model ` + PropertyName
        });

        this.UpdateRendererModel(Id, PropertyName, ModelId);

        Utils.Set(MetaItem, ModelId, 'Models', UseStateGlobal, UseStateComponent, ...ParentNames, PropertyName, 'Id');

        this.DataManager.Item.Set(Id, ModelId, Utils.Concat(['Models', UseStateGlobal, UseStateComponent], ParentNames, [PropertyName, 'Id']));
                
        if (CurrentModelId) {
            const OldRelationId = this.GetItemStyleStateModelRelationId({MetaItem : MetaItem, ParentNames : ParentNames, PropertyName : PropertyName, GlobalState : UseStateGlobal, ComponentState : UseStateComponent});            
            OldRelationId && Globals.ProjectManager.RelationManager.DeleteRelationId(OldRelationId);
        }        

        const RelId = this.RelationManager.AddRelation(this.Id, Id, ModelId,
        {
            OwnerType : Globals.ProjectManager.RelationManager.Types.COMPONENT,
            ConsumerType : Globals.ProjectManager.RelationManager.Types.METAITEM,
            ItemType : MetaItem.Type,
            Property : PropertyName,
            GlobalState : UseStateGlobal,
            State : UseStateComponent,
            ViewId : this.PrototypeViewId
        });
        Utils.Set(MetaItem, RelId, 'Models', UseStateGlobal, UseStateComponent, ...ParentNames, PropertyName, 'RelationId');
        this.DataManager.Item.Set(Id, RelId, Utils.Concat(['Models', UseStateGlobal, UseStateComponent], ParentNames, [PropertyName, 'RelationId']));

        if (SetToDefaultStateAsWell && !Preview) {
            this.SetItemModel({
                MetaItem : MetaItem, 
                Id : Id, 
                ModelId : ModelId, 
                ParentNames : ParentNames, 
                PropertyName : PropertyName, 
                SetToDefaultStateIfNull : false, 
                DoNotLog : true,
                ToGlobalState : Strings.DEFAULT,
                ToComponentState : Strings.DEFAULT,
            });
        }
    }
    RemoveItemModel({MetaItem, Id, PropertyName, ParentNames = [], DoNotLog, Silent}) {
        if (!MetaItem)
            return;
        let UseStateGlobal = this.GlobalState;
        let UseStateComponent = this.ComponentState;

        const CurrentModelId = this.GetItemStyleStateModel({MetaItem : MetaItem, ParentNames : ParentNames, PropertyName : PropertyName, GlobalState : this.GlobalState, ComponentState : this.ComponentState});
        if (!CurrentModelId)
            return;        
        
        !DoNotLog && this.Log({
            Target : LOG_TYPES.SET_STYLE,
            Id : Id,
            Desc : `Remove Model ` + PropertyName
        });

        !Silent && this.UpdateRendererModel(Id, PropertyName, null, true);

        Utils.UnSet(MetaItem, 'Models', UseStateGlobal, UseStateComponent, ...ParentNames, PropertyName, 'Id');
        this.DataManager.Item.Delete(Id, Utils.Concat(['Models', UseStateGlobal, UseStateComponent], ParentNames, [PropertyName, 'Id']));
        const RelationId = this.GetItemStyleStateModelRelationId({MetaItem : MetaItem, ParentNames : ParentNames, PropertyName : PropertyName, GlobalState : UseStateGlobal, ComponentState : UseStateComponent});            
        RelationId && Globals.ProjectManager.RelationManager.DeleteRelationId(RelationId);
        this.DataManager.Item.Delete(Id, Utils.Concat(['Models', UseStateGlobal, UseStateComponent], ParentNames, [PropertyName, 'RelationId']));
        Utils.UnSet(MetaItem, 'Models', UseStateGlobal, UseStateComponent, ...ParentNames, PropertyName, 'RelationId');
    }
    GetModelValue({ModelId}) {
        if (Utils.Has(this.ModelValues, ModelId)) {            
            return Utils.JustGet(this.ModelValues, null, ModelId, 'value');
        }
        return this.Models.GetValue(ModelId);
    }

    // Item Properties
    GetItemPropertyStateValue({MetaItem, PropertyName, ParentNames = [], DefaultValue, GlobalState, ComponentState, ValueName = 'value', GroupName = Strings.PROPERTY}) {
        return Utils.JustGet(MetaItem, DefaultValue, GroupName, GlobalState, ComponentState, ...ParentNames, PropertyName, ValueName);
    }
    IsStateValueReset({MetaItem, PropertyName, ParentNames = [], DefaultValue, GlobalState, ComponentState, ValueName = 'value', GroupName = Strings.PROPERTY}) {
        return Utils.JustGet(MetaItem, false, GroupName, GlobalState, ComponentState, ...ParentNames, PropertyName, 'Reset');
    }
    GetItemPropertyOfState({MetaItem, Result = {}, PropertyName, ParentNames = [], DefaultValue, ReversedStyleState, ValueName = 'value', GroupName = Strings.PROPERTY}) {
        Utils.ForEach(ReversedStyleState, (StyleState, i) => {                   
            const StateValue = this.GetItemPropertyStateValue({MetaItem : MetaItem, PropertyName : PropertyName, ParentNames : ParentNames, DefaultValue : null, GlobalState : StyleState.Global, ComponentState : StyleState.Component, ValueName : ValueName, GroupName : GroupName});
            const isReset = this.IsStateValueReset({MetaItem : MetaItem, PropertyName : PropertyName, ParentNames : ParentNames, DefaultValue : null, GlobalState : StyleState.Global, ComponentState : StyleState.Component, ValueName : ValueName, GroupName : GroupName});
            if (isReset) {
                delete Result.DefaultValue;
                delete Result.Value;
                return false;                        
            }
            if (Utils.IsNotNull(StateValue)) {
                Result.Value = StateValue;
                if (StyleState.Global !== this.GlobalState || StyleState.Component !== this.ComponentState) {
                    Result.InheritedState = {
                        Global : StyleState.Global,
                        Component : StyleState.Component
                    }
                }
                return false;
            }
        });
        return Result;
    }
    GetItemProperty({MetaItem, PropertyName, ParentNames = [], DefaultValue, CurrentState, ValueName = 'value', GroupName = Strings.PROPERTY, NotStatefull}) {
        if (NotStatefull) {
            return {
                Value : Utils.UseNullOrEmpty(Utils.JustGet(MetaItem, null, GroupName, ...ParentNames, PropertyName, ValueName), DefaultValue)
            }
        }
        const Result = {
            DefaultValue : this.GetItemPropertyStateValue({MetaItem : MetaItem, PropertyName : PropertyName, ParentNames : ParentNames, DefaultValue : DefaultValue, GlobalState : Strings.DEFAULT, ComponentState : Strings.DEFAULT, ValueName : ValueName, GroupName : GroupName})
        }
       
        if (CurrentState)
            Result.Value = this.GetItemPropertyStateValue({MetaItem : MetaItem, PropertyName : PropertyName, ParentNames : ParentNames, DefaultValue : DefaultValue, GlobalState : this.GlobalState, ComponentState : this.ComponentState, ValueName : ValueName, GroupName : GroupName});
        else {
            if (this.GlobalState !== Strings.DEFAULT || this.ComponentState !== Strings.DEFAULT) {
                this.GetItemPropertyOfState({
                    MetaItem : MetaItem,
                    PropertyName : PropertyName,
                    ParentNames : ParentNames,
                    DefaultValue : DefaultValue,
                    ValueName : ValueName,
                    GroupName : GroupName,
                    ReversedStyleState : this.ReversedStyleState,
                    Result : Result
                });
            }  
            else
                Result.Value = Result.Value || Result.DefaultValue;          
        }

        return Result;
    }
    GetItemPropertyValue({MetaItem, PropertyName, ParentNames = [], DefaultValue, CurrentState, ValueName = 'value', GroupName = Strings.PROPERTY, NotStatefull}) {
        const Result = this.GetItemProperty({MetaItem : MetaItem, PropertyName : PropertyName, ParentNames : ParentNames, DefaultValue : DefaultValue, CurrentState : CurrentState, ValueName : ValueName, GroupName : GroupName, NotStatefull : NotStatefull});
        return Utils.UseNull(Result.Value, Result.DefaultValue);
    }
    SetItemPropertyValue({
        MetaItem, Id,  PropertyName, ParentNames = [], Value, Preview, SetPreview, 
        ValueName = 'value', DoNotLog, GroupName = Strings.PROPERTY, 
        NotStatefull, SetToDefaultStateIfNull, Offline, Silent,
        ComputedValue, ToGlobalState, ToComponentState
    }) {
        if (!MetaItem)
            return;

        if (NotStatefull) {
            !DoNotLog && this.Log({
                Target : LOG_TYPES.SET_STYLE,
                Id : Id,
                Desc : `Set Property ` + PropertyName
            });
            Utils.Set(MetaItem, Value, GroupName, ...ParentNames, PropertyName, ValueName);
            if (!Offline)
                this.DataManager.Item.Set(Id, Value, Utils.Concat([GroupName], ParentNames, [PropertyName, ValueName]));       
            
            this.UpdateActiveRenderer();

            return;
        }

        if (!Silent) {
            this.UpdateRendererProperty(Id, PropertyName, ComputedValue || Value);
        }

        let UseStateGlobal = ToGlobalState || this.GlobalState;
        let UseStateComponent = ToComponentState || this.ComponentState;
        let SetToDefaultStateAsWell;

        if (SetToDefaultStateIfNull && !NotStatefull && (this.GlobalState !== Strings.DEFAULT || this.ComponentState !== Strings.DEFAULT)) {
            const DefaultValue = this.GetItemPropertyValue({MetaItem : MetaItem, PropertyName : PropertyName, ParentNames : ParentNames,  GlobalState : Strings.DEFAULT, ComponentState : Strings.DEFAULT, ValueName : ValueName, GroupName : GroupName})
            if (Utils.IsNull(DefaultValue)) {
                SetToDefaultStateAsWell = true;
            }
        }

        if (Preview) {
            if (SetPreview)
                Utils.Set(MetaItem, Value, GroupName, UseStateGlobal, UseStateComponent, ...ParentNames, PropertyName, 'PreviewValue');
            else
                Utils.UnSet(MetaItem, GroupName, UseStateGlobal, UseStateComponent, ...ParentNames, PropertyName, 'PreviewValue');

            return;
        }

        Utils.UnSet(MetaItem, GroupName, UseStateGlobal, UseStateComponent, ...ParentNames, PropertyName, 'PreviewValue');
        // const CurrentValue = this.GetItemPropertyValue({MetaItem : MetaItem, PropertyName : PropertyName, ParentNames : ParentNames, GlobalState : this.GlobalState, ComponentState : this.ComponentState, ValueName : ValueName, GroupName : GroupName});
        // if (Utils.IsEqual(CurrentValue, Value))
        //     return;        

        const modelId = this.GetItemStyleModel({MetaItem : MetaItem, Id : Id, ParentNames : ParentNames, PropertyName : PropertyName}).ModelId;
        if (modelId) {
            this.Models.SetValue(modelId, Value);
            return;
        }
        
        !DoNotLog && this.Log({
            Target : LOG_TYPES.SET_STYLE,
            Id : Id,
            Desc : `Set Property ` + PropertyName
        });

        Utils.Set(MetaItem, Value, GroupName, UseStateGlobal, UseStateComponent, ...ParentNames, PropertyName, ValueName);

        if (!Offline) {
            this.DataManager.Item.Set(Id, Value, Utils.Concat([GroupName, UseStateGlobal, UseStateComponent], ParentNames, [PropertyName, ValueName]));       
        }    
        
        if (SetToDefaultStateAsWell && !Preview) {
            this.SetItemPropertyValue({
                MetaItem : MetaItem, 
                Id : Id, 
                Value : Value, 
                ParentNames : ParentNames, 
                PropertyName : PropertyName, 
                SetToDefaultStateIfNull : false, 
                DoNotLog : true,
                ToGlobalState : Strings.DEFAULT,
                ToComponentState : Strings.DEFAULT,
            });
        }
    }
    RemoveItemProperty({MetaItem, Id, PropertyName, ParentNames=[], DoNotLog, RemoveFullProp, GroupName = Strings.PROPERTY, NotStatefull, Silent}) {
        if (!MetaItem)
            return;
                
        let UseStateGlobal = this.GlobalState;
        let UseStateComponent = this.ComponentState;

        !Silent && this.UpdateRendererProperty(Id, PropertyName, null, true);
        !DoNotLog && this.Log({
            Target : LOG_TYPES.SET_STYLE,
            Id : Id,
            Desc : `Remove Style ` + PropertyName
        });

        if (NotStatefull) {
            if (RemoveFullProp) {
                Utils.UnSet(MetaItem, GroupName, ...ParentNames, PropertyName);
                this.DataManager.Item.Delete(Id, Utils.Concat([GroupName], ParentNames, [PropertyName]));
            }
            else {
                Utils.UnSet(MetaItem, GroupName, ...ParentNames, PropertyName, 'value');
                this.DataManager.Item.Delete(Id, Utils.Concat([GroupName], ParentNames, [PropertyName, 'value']));
            }
            return;
        }

        if (RemoveFullProp) {
            Utils.UnSet(MetaItem, GroupName, UseStateGlobal, UseStateComponent, ...ParentNames, PropertyName);
            this.DataManager.Item.Delete(Id, Utils.Concat([GroupName, UseStateGlobal, UseStateComponent], ParentNames, [PropertyName]));
        }
        else {
            Utils.UnSet(MetaItem, GroupName, UseStateGlobal, UseStateComponent, ...ParentNames, PropertyName, 'value');
            this.DataManager.Item.Delete(Id, Utils.Concat([GroupName, UseStateGlobal, UseStateComponent], ParentNames, [PropertyName, 'value']));
        }
        if (this.GlobalState !== Strings.DEFAULT || this.ComponentState !== Strings.DEFAULT) {
            Utils.Set(MetaItem, true, GroupName, UseStateGlobal, UseStateComponent, ...ParentNames, PropertyName, 'Reset');
            this.DataManager.Item.Set(Id, true, Utils.Concat([GroupName, UseStateGlobal, UseStateComponent], ParentNames, [PropertyName, 'Reset']));
        }
    }
    GetItemRenderProperty(Id, PropName) {
        const Manager = ComponentDesignManager.Active().DesignManagers[this.Id];
        if (Manager) {
            return  Utils.Get(Manager.StateStyles, null, Id, this.GlobalState + this.ComponentState, 'Static', PropName);;            
        }
    }
    UpdateRendererProperty(Id, PropName, Value, Delete, Silent) {
        const Manager = ComponentDesignManager.Active().DesignManagers[this.Id];
        const State = this.GlobalState + this.ComponentState;
        if (Manager) {
            const ItemStateStyles = Utils.Get(Manager.StateStyles, {}, Id);
            if (Utils.IsOneOf(PropName, 'positionX', 'positionY', 'customPositionX', 'customPositionY', 'backgroundSize', 'backgroundWidth', 'backgroundHeight')) {
                if (Delete)
                    Utils.UnSet(ItemStateStyles, (State), 'Compound', PropName );
                else {
                    Utils.Set(ItemStateStyles, Value, (State), 'Compound', PropName );            
                }
            }
            if (Utils.IsOneOf(PropName, 'hidden', 'textContent', 'SizeLocked',
                'VideoUrl', 
                'LottieUrl', 'speed', 
                'mapTheme', 'latitude', 'longitude', 'mapzoom', 'mapinteractive',
                'ChartType', 'ChartSubType', 'ChartTheme', 'DataSource', 'Sparkline', 'Animated'
            )) {
                if (Delete)
                    Utils.UnSet(ItemStateStyles, (State), 'Props', PropName );
                else {
                    Utils.Set(ItemStateStyles, Value, (State), 'Props', PropName );            
                }
            }
            else {
                if (Delete)
                    Utils.UnSet(ItemStateStyles, (State), 'Static', PropName );            
                else {
                    let useValue = Value;
                    if (PropName === 'opacity') {
                        useValue = Math.min(1, Math.max(0, Value / 100))
                    }
                    Utils.Set(ItemStateStyles, useValue, (State), 'Static', PropName );            
                }                    
            }            
        }
        Utils.UnSet(this.StateStyles, Id, 'Computed');
        !Silent && this.UpdateRenderers();
    }
    SetItemTextContent({ Id, Value}) {    
        const Computed = Utils.Get(this.StateStyles, null, Id, 'Computed', this.GlobalState + this.ComponentState);
        if (Computed) {
            Utils.Set(this.StateStyles, Value, Id, 'Computed', this.GlobalState + this.ComponentState, 'props', 'textContent');    
            const modelValue = Utils.JustGet(Computed, null, 'models', 'textContent');
            if (modelValue && modelValue.modelId) {
                const model = this.Models.GetModel(modelValue.modelId);
                model.value = Value;
            }                
        }
        Utils.Set(this.StateStyles, Value, Id, this.GlobalState + this.ComponentState, 'Props', 'textContent');                     
        
        this.UpdateRenderers();
    }
    GetItemGridModel(MetaItem) {
        const model = this.GetItemPropertyValue({
            MetaItem : MetaItem,
            PropertyName : 'model',
            ParentNames : ['Grid']
        });
        return model;
    }
    GetGridProperty(Id, name, defaultValue) {
        return Utils.JustGet(this.GetMetaItem(Id), defaultValue, 'Property', 'Grid', name, 'value');
    }
    GetComputedItemGrid(Id) {
        const Manager = ComponentDesignManager.Active().DesignManagers[this.Id];
        if (Manager) {
            return  Utils.Get(Manager.StateStyles, null, Id,  'Computed',  this.GlobalState + this.ComponentState, 'props', 'Computed');;            
        }
    }
    UpdateGridStyle(Id) {
        const MetaItem = this.GetMetaItem(Id);        
        const GridProps = Utils.Get(this.StateStyles, {}, Id, 'Computed', this.GlobalState + this.ComponentState, 'Props');
        this.ComputeGridStyle({MetaItem : MetaItem, Id : Id, StyleState : {Global : this.GlobalState, Component : this.ComponentState}, GridProps : GridProps});
        this.UpdateRenderers();
    }
    // Textcontent
    GetTextContentParameters({MetaItem, Id, ReversedStyleState = this.ReversedStyleState}) {
        const TextContent = {};
        Utils.ForEach(ReversedStyleState, (StyleState, i) => {                   
            const StateValue = this.GetItemPropertyStateValue({MetaItem : MetaItem, PropertyName : 'textContent', GlobalState : StyleState.Global, ComponentState : StyleState.Component});
            if (StateValue) {
                TextContent.Id = TextContent.Id || StateValue.Id;
                TextContent.Parameters = Utils.Merge(StateValue.Parameters,TextContent.Parameters);
            }
        });
        return TextContent;
    }
    SetTextContentParameters({MetaItem, Id, DataType, ParameterId, ParameterValue, BindToModelId, BindToTokenId, WillDelete}) {
        if (WillDelete) {
            Utils.UnSet(MetaItem, Strings.PROPERTY, this.GlobalState, this.ComponentState, 'textContent', 'value', 'Parameters', ParameterId);
            this.DataManager.Item.Delete(Id, [Strings.PROPERTY, this.GlobalState, this.ComponentState, 'textContent', 'value', 'Parameters', ParameterId]);
        }
        else {
            Utils.Set(MetaItem, DataType, Strings.PROPERTY, this.GlobalState, this.ComponentState, 'textContent', 'value', 'Parameters', ParameterId, 'Type');
            if (BindToModelId) {
                // Remove Token binding if exists
                const tokenId = Utils.Get(MetaItem,null, Strings.PROPERTY, this.GlobalState, this.ComponentState, 'textContent', 'value', 'Parameters', ParameterId, 'TokenId');
                if (tokenId) {
                    const tokenRelationId = Utils.Get(MetaItem,null, Strings.PROPERTY, this.GlobalState, this.ComponentState, 'textContent', 'value', 'Parameters', ParameterId, 'TokenRelationId');
                    if (tokenRelationId) {                        
                        Globals.ProjectManager.RelationManager.DeleteRelationId(tokenRelationId, true);
                    }
                    ['TokenId', 'TokenRelationId'].map((prop) => {
                        Utils.UnSet(MetaItem, Strings.PROPERTY, this.GlobalState, this.ComponentState, 'textContent', 'value', 'Parameters', ParameterId, 'TokenId');
                        this.DataManager.Item.Delete(Id, [Strings.PROPERTY, this.GlobalState, this.ComponentState, 'textContent', 'value', 'Parameters', ParameterId, 'TokenId']);
                    });
                }
                const RelId = this.RelationManager.AddRelation(this.Id, Id, BindToModelId,
                {
                    OwnerType : Globals.ProjectManager.RelationManager.Types.COMPONENT,
                    ConsumerType : Globals.ProjectManager.RelationManager.Types.METAITEM,
                    ItemType : MetaItem.Type,                    
                    GlobalState : this.GlobalState,
                    State : this.ComponentState,
                    ViewId : this.PrototypeViewId
                });
                Utils.Set(MetaItem, BindToModelId, Strings.PROPERTY, this.GlobalState, this.ComponentState, 'textContent', 'value', 'Parameters', ParameterId, 'ModelId');
                Utils.Set(MetaItem, RelId, Strings.PROPERTY, this.GlobalState, this.ComponentState, 'textContent', 'value', 'Parameters', ParameterId, 'RelationId');
                this.DataManager.Item.Set(Id, BindToModelId, [Strings.PROPERTY, this.GlobalState, this.ComponentState, 'textContent', 'value', 'Parameters', ParameterId, 'ModelId']);
                this.DataManager.Item.Set(Id, RelId, [Strings.PROPERTY, this.GlobalState, this.ComponentState, 'textContent', 'value', 'Parameters', ParameterId, 'RelationId']);
            }
            else if (BindToTokenId) {
                // if bind to model, change model value
                const boundToModelId = Utils.Get(MetaItem,null, Strings.PROPERTY, this.GlobalState, this.ComponentState, 'textContent', 'value', 'Parameters', ParameterId, 'ModelId');
                if (boundToModelId) {
                    this.Models.SetTokenId(boundToModelId, BindToTokenId);
                }
                else {
                    const CurrentRelId = Utils.JustGet(MetaItem, null, Strings.PROPERTY, this.GlobalState, this.ComponentState, 'textContent', 'value', 'Parameters', ParameterId, 'TokenRelationId');
                    if (CurrentRelId)
                        Globals.ProjectManager.RelationManager.DeleteRelationId(CurrentRelId);

                    const RelId = Globals.ProjectManager.RelationManager.AddRelation(this.Id, Id, BindToTokenId,
                    {
                        OwnerType : Globals.ProjectManager.RelationManager.Types.COMPONENT,
                        ConsumerType : Globals.ProjectManager.RelationManager.Types.METAITEM,
                        ItemType : MetaItem.Type,
                        GlobalState : this.GlobalState,
                        State : this.ComponentState,
                        ViewId : this.PrototypeViewId
                    });                                        
                    Utils.Set(MetaItem, BindToTokenId, Strings.PROPERTY, this.GlobalState, this.ComponentState, 'textContent', 'value', 'Parameters', ParameterId, 'TokenId');
                    Utils.Set(MetaItem, RelId, Strings.PROPERTY, this.GlobalState, this.ComponentState, 'textContent', 'value', 'Parameters', ParameterId, 'TokenRelationId');
                    this.DataManager.Item.Set(Id, BindToTokenId, [Strings.PROPERTY, this.GlobalState, this.ComponentState, 'textContent', 'value', 'Parameters', ParameterId, 'TokenId']);
                    this.DataManager.Item.Set(Id, RelId, [Strings.PROPERTY, this.GlobalState, this.ComponentState, 'textContent', 'value', 'Parameters', ParameterId, 'RelationId']);
                }                
            }
            else {
                const CurrentModelId = Utils.JustGet(MetaItem, null, Strings.PROPERTY, this.GlobalState, this.ComponentState, 'textContent', 'value', 'Parameters', ParameterId, 'ModelId');
                if (CurrentModelId && ParameterValue) {
                    this.Models.SetValue(CurrentModelId, ParameterValue.value);
                }
                else {
                    Utils.Set(MetaItem, ParameterValue, Strings.PROPERTY, this.GlobalState, this.ComponentState, 'textContent', 'value', 'Parameters', ParameterId);
                    this.DataManager.Item.Set(Id, ParameterValue, [Strings.PROPERTY, this.GlobalState, this.ComponentState, 'textContent', 'value', 'Parameters', ParameterId]);
                }                
            }            
        }
    }
    // Subcomponent    
    SetSubComponentId({MetaItem, Id, ComponentId, Models, Variations}) {
        this.Log({
            Target : LOG_TYPES.SET_PROP,
            Id : Id,
            Desc : 'Select Component'
        });

        const CurrentInfo = this.GetItemPropertyValue({MetaItem : MetaItem, PropertyName : Strings.COMPONENT, CurrentState : true});
        if (CurrentInfo && CurrentInfo.RelationId) {
            Globals.ProjectManager.RelationManager.DeleteRelationId(CurrentInfo.RelationId);
        }

        let UseStateGlobal = this.GlobalState;
        let UseStateComponent = this.ComponentState;

        if (this.GlobalState !== Strings.DEFAULT || this.ComponentState !== Strings.DEFAULT) {
            const DefaultInfo = this.GetItemPropertyValue({MetaItem : MetaItem, PropertyName : Strings.COMPONENT, GlobalState : Strings.DEFAULT, ComponentState : Strings.DEFAULT});
            if (!DefaultInfo) {
                UseStateGlobal = Strings.DEFAULT;
                UseStateComponent = Strings.DEFAULT;     
            }
        }

        if (ComponentId) {
            const RelId = Globals.ProjectManager.RelationManager.AddRelation(this.Id, Id, ComponentId,
            {
                OwnerType : Globals.ProjectManager.RelationManager.Types.COMPONENT,
                ConsumerType : Globals.ProjectManager.RelationManager.Types.METAITEM,
                TargetType : Globals.ProjectManager.RelationManager.Types.COMPONENT,
                ViewId : this.PrototypeViewId               
            });
            const value = {
                Id : ComponentId,
                RelationId : RelId,
                Variations : Variations || {},
                Models : Models || {}
            };
            Utils.Set(MetaItem, value , Strings.PROPERTY, UseStateGlobal, UseStateComponent, Strings.COMPONENT, 'value');
            this.DataManager.Item.Set(Id, value, [Strings.PROPERTY, UseStateGlobal, UseStateComponent, Strings.COMPONENT, 'value']);

            this.InitializeSubcomponent({
                MetaItem : MetaItem,
                ComponentId : ComponentId,
                Id : Id
            })
        }
        else {
            Utils.UnSet(MetaItem, Strings.PROPERTY, UseStateGlobal, UseStateComponent, Strings.COMPONENT );
            this.DataManager.Item.Delete(Id, [Strings.PROPERTY, UseStateGlobal, UseStateComponent, Strings.COMPONENT]);
        }
    }
    GetSubComponentInfo({MetaItem, Id, ReversedStyleState = this.ReversedStyleState}) {
        const ComponentInfo = {};
        Utils.ForEach(ReversedStyleState, (StyleState, i) => {                   
            const StateValue = this.GetItemPropertyStateValue({MetaItem : MetaItem, PropertyName : 'Component', GlobalState : StyleState.Global, ComponentState : StyleState.Component});
            if (StateValue) {
                ComponentInfo.Id = ComponentInfo.Id || StateValue.Id
                ComponentInfo.Variations = Utils.Merge(StateValue.Variations, ComponentInfo.Variations);
                ComponentInfo.Models = Utils.Merge(StateValue.Models, ComponentInfo.Models);
            }            
        });
        return ComponentInfo;
    }
    SetSubComponentVariation({MetaItem, Id, ComponentId, StateId, VariationId}) {
        if (Utils.IsNotNull(VariationId) && VariationId !== Strings.DEFAULT) {            
            Utils.Set(MetaItem, VariationId, Strings.PROPERTY, this.GlobalState, this.ComponentState, Strings.COMPONENT, 'value', 'Variations', StateId);
            this.DataManager.Item.Set(Id, VariationId, [Strings.PROPERTY, this.GlobalState, this.ComponentState, Strings.COMPONENT, 'value', 'Variations', StateId]);
        }
        else {
            Utils.UnSet(MetaItem, Strings.PROPERTY, this.GlobalState, this.ComponentState, Strings.COMPONENT, 'value', 'Variations', StateId);
            this.DataManager.Item.Delete(Id, [Strings.PROPERTY, this.GlobalState, this.ComponentState, Strings.COMPONENT, 'value', 'Variations', StateId]);
        }        
    }
    SetSubComponentModelPreviewTokenId({MetaItem, Id, ModelId, PreviewTokenId}) {
        const ComputedStyle = Utils.Get(this.StateStyles, {}, Id, 'Computed', this.GlobalState + this.ComponentState, 'props', 'Models', ModelId);
        if (ComputedStyle)
            ComputedStyle.PreviewTokenId = PreviewTokenId;
        this.UpdateRenderers();
    }   
    SetSubComponentModels({MetaItem, Id, DataType, ComponentId, ModelId, ModelValue, BindToModelId, BindToTokenId, ValueType, WillDelete, Clear}) {
        if (WillDelete) {
            const tokenId = Utils.Get(MetaItem,null, Strings.PROPERTY, this.GlobalState, this.ComponentState, Strings.COMPONENT, 'value', 'Models', ModelId, 'TokenId');
            if (tokenId) {
                const tokenRelationId = Utils.Get(MetaItem,null, Strings.PROPERTY, this.GlobalState, this.ComponentState, Strings.COMPONENT, 'value', 'Models', ModelId, 'TokenRelationId');
                if (tokenRelationId) {                        
                    Globals.ProjectManager.RelationManager.DeleteRelationId(tokenRelationId, true);
                }
                ['TokenId', 'TokenRelationId'].map((prop) => {
                    Utils.UnSet(MetaItem, Strings.PROPERTY, this.GlobalState, this.ComponentState, Strings.COMPONENT, 'value', 'Models', ModelId, prop);
                    this.DataManager.Item.Delete(Id, [Strings.PROPERTY, this.GlobalState, this.ComponentState, Strings.COMPONENT, 'value', 'Models', ModelId, prop]);
                });
            }
            const modelId = Utils.Get(MetaItem,null, Strings.PROPERTY, this.GlobalState, this.ComponentState, Strings.COMPONENT, 'value', 'Models', ModelId, 'ModelId');
            if (modelId) {
                const modelRelationId = Utils.Get(MetaItem, null, Strings.PROPERTY, this.GlobalState, this.ComponentState, Strings.COMPONENT, 'value', 'Models', ModelId, 'RelationId');
                if (modelRelationId) {                        
                    this.RelationManager.DeleteRelationId(modelRelationId);                        
                }
                ['ModelId', 'RelationId'].map((prop) => {
                    Utils.UnSet(MetaItem, Strings.PROPERTY, this.GlobalState, this.ComponentState, Strings.COMPONENT, 'value', 'Models', ModelId, prop);
                    this.DataManager.Item.Delete(Id, [Strings.PROPERTY, this.GlobalState, this.ComponentState, Strings.COMPONENT, 'value', 'Models', ModelId, prop]);
                });
            }

            Utils.UnSet(MetaItem, Strings.PROPERTY, this.GlobalState, this.ComponentState, Strings.COMPONENT, 'value', 'Models', ModelId);
            this.DataManager.Item.Delete(Id, [Strings.PROPERTY, this.GlobalState, this.ComponentState, Strings.COMPONENT, 'value', 'Models', ModelId]);
        }
        else if (Clear) {
            
        }
        else {
            Utils.Set(MetaItem, DataType, Strings.PROPERTY, this.GlobalState, this.ComponentState, Strings.COMPONENT, 'value', 'Models', ModelId, 'Type');
            this.DataManager.Item.Set(Id, DataType, [Strings.PROPERTY, this.GlobalState, this.ComponentState, Strings.COMPONENT, 'value', 'Models', ModelId, 'Type']);
            Utils.Set(MetaItem, ValueType, Strings.PROPERTY, this.GlobalState, this.ComponentState, Strings.COMPONENT, 'value', 'Models', ModelId, 'ValueType');
            this.DataManager.Item.Set(Id, ValueType, [Strings.PROPERTY, this.GlobalState, this.ComponentState, Strings.COMPONENT, 'value', 'Models', ModelId, 'ValueType']);

            if (BindToModelId) {
                // Remove Token binding if exists
                const tokenId = Utils.Get(MetaItem,null, Strings.PROPERTY, this.GlobalState, this.ComponentState, Strings.COMPONENT, 'value', 'Models', ModelId, 'TokenId');
                if (tokenId) {
                    const tokenRelationId = Utils.Get(MetaItem,null, Strings.PROPERTY, this.GlobalState, this.ComponentState, Strings.COMPONENT, 'value', 'Models', ModelId, 'TokenRelationId');
                    if (tokenRelationId) {                        
                        Globals.ProjectManager.RelationManager.DeleteRelationId(tokenRelationId, true);
                    }
                    ['TokenId', 'TokenRelationId'].map((prop) => {
                        Utils.UnSet(MetaItem, Strings.PROPERTY, this.GlobalState, this.ComponentState, Strings.COMPONENT, 'value', 'Models', ModelId, prop);
                        this.DataManager.Item.Delete(Id, [Strings.PROPERTY, this.GlobalState, this.ComponentState, Strings.COMPONENT, 'value', 'Models', ModelId, prop]);
                    });
                }
                const RelId = this.RelationManager.AddRelation(this.Id, Id, BindToModelId,
                {
                    OwnerType : Globals.ProjectManager.RelationManager.Types.COMPONENT,
                    ConsumerType : Globals.ProjectManager.RelationManager.Types.METAITEM,
                    ItemType : MetaItem.Type,                    
                    GlobalState : this.GlobalState,
                    State : this.ComponentState,
                    ViewId : this.PrototypeViewId
                });
                Utils.Set(MetaItem, BindToModelId, Strings.PROPERTY, this.GlobalState, this.ComponentState, Strings.COMPONENT, 'value', 'Models', ModelId, 'ModelId');
                Utils.Set(MetaItem, RelId, Strings.PROPERTY, this.GlobalState, this.ComponentState, Strings.COMPONENT, 'value', 'Models', ModelId, 'RelationId');
                this.DataManager.Item.Set(Id, BindToModelId, [Strings.PROPERTY, this.GlobalState, this.ComponentState, Strings.COMPONENT, 'value', 'Models', ModelId, 'ModelId']);
                this.DataManager.Item.Set(Id, RelId, [Strings.PROPERTY, this.GlobalState, this.ComponentState, Strings.COMPONENT, 'value', 'Models', ModelId, 'RelationId']);
            }
            else if (BindToTokenId) {
                // if bind to model, change model value
                const boundToModelId = Utils.Get(MetaItem,null, Strings.PROPERTY, this.GlobalState, this.ComponentState, Strings.COMPONENT, 'value', 'Models', ModelId, 'ModelId');
                if (boundToModelId) {
                    const boundRelationId = Utils.Get(MetaItem, null, Strings.PROPERTY, this.GlobalState, this.ComponentState, Strings.COMPONENT, 'value', 'Models', ModelId, 'RelationId');
                    if (boundRelationId) {
                        this.RelationManager.DeleteRelationId(boundRelationId);                        
                    }
                    Utils.UnSet(MetaItem, Strings.PROPERTY, this.GlobalState, this.ComponentState, Strings.COMPONENT, 'value', 'Models', ModelId, 'ModelId');
                    Utils.UnSet(MetaItem, Strings.PROPERTY, this.GlobalState, this.ComponentState, Strings.COMPONENT, 'value', 'Models', ModelId, 'RelationId');
                    this.DataManager.Item.Delete(Id, [Strings.PROPERTY, this.GlobalState, this.ComponentState, Strings.COMPONENT, 'value', 'Models', ModelId, 'ModelId']);
                    this.DataManager.Item.Delete(Id, [Strings.PROPERTY, this.GlobalState, this.ComponentState, Strings.COMPONENT, 'value', 'Models', ModelId, 'RelationId']);
                }

                const CurrentRelId = Utils.JustGet(MetaItem, null, Strings.PROPERTY, this.GlobalState, this.ComponentState, Strings.COMPONENT, 'value', 'Models', ModelId, 'TokenRelationId');
                if (CurrentRelId)
                    Globals.ProjectManager.RelationManager.DeleteRelationId(CurrentRelId);

                const RelId = Globals.ProjectManager.RelationManager.AddRelation(this.Id, Id, BindToTokenId,
                {
                    OwnerType : Globals.ProjectManager.RelationManager.Types.COMPONENT,
                    ConsumerType : Globals.ProjectManager.RelationManager.Types.METAITEM,
                    ItemType : MetaItem.Type,
                    GlobalState : this.GlobalState,
                    State : this.ComponentState,
                    ViewId : this.PrototypeViewId
                });                                        
                Utils.Set(MetaItem, BindToTokenId, Strings.PROPERTY, this.GlobalState, this.ComponentState, Strings.COMPONENT, 'value', 'Models', ModelId, 'TokenId');
                Utils.Set(MetaItem, RelId, Strings.PROPERTY, this.GlobalState, this.ComponentState, Strings.COMPONENT, 'value', 'Models', ModelId, 'TokenRelationId');
                this.DataManager.Item.Set(Id, BindToTokenId, [Strings.PROPERTY, this.GlobalState, this.ComponentState, Strings.COMPONENT, 'value', 'Models', ModelId, 'TokenId']);
                this.DataManager.Item.Set(Id, RelId, [Strings.PROPERTY, this.GlobalState, this.ComponentState, Strings.COMPONENT, 'value', 'Models', ModelId, 'TokenRelationId']);              
            }
            else {
                const CurrentModelId = Utils.JustGet(MetaItem, null, Strings.PROPERTY, this.GlobalState, this.ComponentState, Strings.COMPONENT, 'value', 'Models', ModelId, 'ModelId');
                if (CurrentModelId && ModelValue) {
                    this.Models.SetValue(CurrentModelId, ModelValue.value);
                }
                else {
                    Utils.Set(MetaItem, ModelValue, Strings.PROPERTY, this.GlobalState, this.ComponentState, Strings.COMPONENT, 'value', 'Models', ModelId);
                    this.DataManager.Item.Set(Id, ModelValue, [Strings.PROPERTY, this.GlobalState, this.ComponentState, Strings.COMPONENT, 'value', 'Models', ModelId]);
                }                
            }            
        }
    }
    SetSubComponentModelProps({MetaItem, Id, ModelId, PropName, Value, BindtoModelId, Delete}) {
        if (Delete) {
            Utils.UnSet(MetaItem, Strings.PROPERTY, this.GlobalState, this.ComponentState, Strings.COMPONENT, 'value', 'Models', ModelId, 'Options', PropName);
            this.DataManager.Item.Delete(Id, [Strings.PROPERTY, this.GlobalState, this.ComponentState, Strings.COMPONENT, 'value', 'Models', ModelId, 'Options', PropName]);
        }
        else {
            if (BindtoModelId) {
                Utils.Set(MetaItem, BindtoModelId, Strings.PROPERTY, this.GlobalState, this.ComponentState, Strings.COMPONENT, 'value', 'Models', ModelId, 'Options', PropName, 'ModelId');
                this.DataManager.Item.Set(Id, BindtoModelId, [Strings.PROPERTY, this.GlobalState, this.ComponentState, Strings.COMPONENT, 'value', 'Models', ModelId, 'Options', PropName, 'ModelId']);
            }
            else {
                Utils.Set(MetaItem, Value, Strings.PROPERTY, this.GlobalState, this.ComponentState, Strings.COMPONENT, 'value', 'Models', ModelId, 'Options', PropName, 'value');
                this.DataManager.Item.Set(Id, Value, [Strings.PROPERTY, this.GlobalState, this.ComponentState, Strings.COMPONENT, 'value', 'Models', ModelId, 'Options', PropName, 'value']);
            }            
        }        
    }
    InitializeSubcomponent({MetaItem, Id, ComponentId, LogOptions}) {
        const ComponentData = GetProjectManager().GetComponentData(ComponentId);
        let name;
        if (ComponentData) {
            name = ComponentData.name || '';
            this.DataManager.Item.Set(Id, name, ['name']);
            setTimeout(() => {
                Events.BCE(Id, Events.DESIGNER.ITEM.CHANGED.NAME);
            }, 10);
            let width, height;
            const RootItem = Utils.JustGet(ComponentData, null, 'MetaItems', Utils.JustGet(ComponentData, null, 'RootItem', 'Id'));
            const isFixedWidth = Utils.JustGet(RootItem, null, 'Property', 'Default', 'Default', 'fixedWidth', 'value');
            const isFixedHeight = Utils.JustGet(RootItem, null, 'Property', 'Default', 'Default', 'fixedWidth', 'value');

            
            if (ComponentData.PreviewSize && ComponentData.PreviewSize.width && ComponentData.PreviewSize.height) {
                if (!!isFixedWidth)
                    this.DataManager.Item.Set(Id, ComponentData.PreviewSize.width, [Strings.PROPERTY, Strings.DEFAULT, Strings.DEFAULT, 'width', Strings.VALUE]);
                if (!isFixedHeight)
                    this.DataManager.Item.Set(Id, ComponentData.PreviewSize.height, [Strings.PROPERTY, Strings.DEFAULT, Strings.DEFAULT, 'height', Strings.VALUE]);    
            }
            {                
                if (RootItem) {
                    ['Default', 'Medium', 'Small', 'xSmall'].map((DeviceState) => {
                        width = Utils.JustGet(RootItem, null, 'Property', DeviceState, 'Default', 'width', 'value');
                        if (width !== null) {
                            this.DataManager.Item.Set(Id, width, [Strings.PROPERTY, DeviceState, Strings.DEFAULT, 'width', Strings.VALUE]);
                            const width_unit = Utils.JustGet(RootItem, null, 'Property', DeviceState, 'Default', 'width', 'Unit');
                            this.DataManager.Item.Set(Id, width_unit, [Strings.PROPERTY, DeviceState, Strings.DEFAULT, 'width', 'Unit']);
                        }
                        
                        height = Utils.JustGet(RootItem, null, 'Property', DeviceState, 'Default', 'height', 'value');
                        if (height !== null) {
                            this.DataManager.Item.Set(Id, height, [Strings.PROPERTY, DeviceState, Strings.DEFAULT, 'height', Strings.VALUE]);
                            const height_unit = Utils.JustGet(RootItem, null, 'Property', DeviceState, 'Default', 'height', 'Unit');
                            this.DataManager.Item.Set(Id, height_unit, [Strings.PROPERTY, DeviceState, Strings.DEFAULT, 'height', 'Unit']);
                        }
                    })                                
                }
            }   
            
            if (ComponentData.GenericType === MetaData.Components.Generic.Carousel.Type ||
                ComponentData.GenericType === MetaData.Components.Generic.Tab.Type ||
                ComponentData.GenericType === MetaData.Components.Generic.Accordion.Type) 
                {
                    const slides = {};
                    const slideItems = Utils.Get(slides, [], 'items');
                    for (let i=0; i<3; i++) {
                        const result = this.AddSliderItem({ParentId : Id, LogOptions : {Logged : true}});
                        if (!slides.activeSlide)
                            slides.activeSlide = result.Id;
                        slideItems.push(result);
                    }   
                    this.DataManager.Item.Set(Id, slides, 'Slides');
            }
        }
    }
    AddSliderItem({ParentId, LogOptions}) {
        const result = {};
        const SlotItem = MetaData.ItemInitializer.FromType(MetaData.Components.Div.Type);
        delete SlotItem.Property.Default.Default.backgroundColor;
        SlotItem.ParentId = ParentId;
        result.ItemId = Utils.Id();        
        result.Id = Utils.Id();
              
        this.AddChildItem({
            ParentId : ParentId, 
            MetaItem : SlotItem, 
            Id : result.ItemId, 
            ChildIndex : 0, 
            LogOptions : LogOptions || {},
            Silent : true
        });                
        return result;
    }
    GetSubcomponentMockupValue({Id, ModelId, MockupFieldId, Refresh}) {
        const MetaItem = this.GetMetaItem(Id);
        let MockupId = Utils.JustGet(MetaItem, null, Strings.PROPERTY, Strings.DEFAULT, Strings.DEFAULT, Strings.COMPONENT, 'value', 'Models', ModelId, 'MockupId');
        if (!MockupId || Refresh) {            
            MockupId = Globals.ProjectManager.Mockups.GetRandomRecordId(MockupFieldId);
            const MockupField = Globals.ProjectManager.Mockups.Field(MockupFieldId);
            this.DataManager.Item.Set(Id, MockupField.dataType, [Strings.PROPERTY, Strings.DEFAULT, Strings.DEFAULT, Strings.COMPONENT, 'value', 'Models', ModelId, 'Type']);
            this.DataManager.Item.Set(Id, MockupId, [Strings.PROPERTY, Strings.DEFAULT, Strings.DEFAULT, Strings.COMPONENT, 'value', 'Models', ModelId, 'MockupId']);
            if (Refresh)
                Refresh.MockupId = MockupId;
        }        
        return Globals.ProjectManager.Mockups.GetRecordValue(MockupId, MockupFieldId);
    }
    SetSubcomponentMockupValue({Id, ModelId, MockupId}) {
        this.DataManager.Item.Set(Id, MockupId, [Strings.PROPERTY, Strings.DEFAULT, Strings.DEFAULT, Strings.COMPONENT, 'value', 'Models', ModelId, 'MockupId']);
    }
    DeleteSubcomponentMockupValue({Id, ModelId}) {
        const MetaItem = this.GetMetaItem(Id);
        this.DataManager.Item.Delete(Id, [Strings.PROPERTY, Strings.DEFAULT, Strings.DEFAULT, Strings.COMPONENT, 'value', 'Models', ModelId, 'MockupId']);
    }
    //#endregion New Item Style Handling
    GetItemTextValue({MetaItem, Id, PropertyName}) {
        return this.GetItemRenderValue({MetaItem : MetaItem, Id : Id, PropertyName : PropertyName});
    }
    GetItemRenderValue({MetaItem, Id, PropertyName, ParentNames = [], DefaultValue}) {
        
        const usePropertyName = PropertyName;
        const modelId = this.GetItemStyleModel({MetaItem : MetaItem, Id : Id, ParentNames : ParentNames, PropertyName : usePropertyName}).ModelId;
        let value = '';
        if (modelId) {
            value = this.GetModelValue({ModelId : modelId} );
        }
        else {
            const tokenId = this.GetItemStyleToken({MetaItem : MetaItem, Id : Id, ParentNames : ParentNames, StyleName : usePropertyName}).TokenId;        
            if (tokenId)
                value = Globals.ProjectManager.Tokens.GetStateValue({Id : tokenId, StateArray : this.ReversedGlobalStateArray});
            else
                value = this.GetItemPropertyValue({MetaItem : MetaItem, ParentNames : ParentNames, PropertyName : usePropertyName, DefaultValue : DefaultValue});
        }
        return value;
    }

    UpdateFlexChildrenSizes({ParentId, AlignToStretch}) {
        if (AlignToStretch) {
            const ParentItem = this.GetMetaItem(ParentId);
            if (ParentItem.SubItems && ParentItem.SubItems.length > 0) {
                Utils.ForEach(ParentItem.SubItems, (ChildItem, i) => {
                    const ChildMetaItem = this.GetMetaItem(ChildItem.Id);
                    if (ChildMetaItem) {
                        if (AlignToStretch.value) {                            
                            this.SetItemPropertyValue({
                                MetaItem : ChildMetaItem,
                                Id : ChildItem.Id,
                                PropertyName : 'alignSelf',
                                Value : AlignToStretch.value,
                                DoNotLog : true
                            });                  
                            this.UpdateItemDesigner(ChildItem.Id);                                      
                        }                        
                        else {
                            
                        }                                                
                    }
                });
            }
        }
    }


    // Instance Render Managers
    RegisterRenderer(renderer, rendererId) {
        Utils.Set(this, renderer, 'Renderers', rendererId);
    }
    UnRegisterRenderer(rendererId) {
        if (this.ActiveRenderer && this.ActiveRenderer.RendererId === rendererId) {
            ActiveComponentManager.SetActiveComponentManager(null);
        }
        Utils.UnSet(this, 'Renderers', rendererId);
    }
    UpdateRenderers() {        
        const Manager = ComponentDesignManager.Active().DesignManagers[this.Id];
        Manager && Utils.ForEach(Manager.Renderers, (renderer, id) => {
            renderer.UpdateStyles();
        });
    }
    UpdateActiveRenderer() {
        if (this.ActiveRenderer)
            this.ActiveRenderer.UpdateStyles();
    }
    GetDesignManager() {
        return this;
    }
    SwitchState({GlobalState, ComponentState, Variations, StateArray, GlobalStateArray, ReversedStyleState, ReversedGlobalStateArray}) {
        this.StateVariations = Variations;
        this.GlobalState = GlobalState;
        this.ComponentState = ComponentState;
        this.StyleState = StateArray;
        this.ReversedStyleState = ReversedStyleState;
        this.GlobalStateArray = GlobalStateArray;
        this.ReversedGlobalStateArray = ReversedGlobalStateArray;

        this.onStateChanged && this.onStateChanged();
        AppLayout.Refs.Inspectors.Main && AppLayout.Refs.Inspectors.Main.Update();
    }
}

export const ActiveComponentManager = {
    ComponentManager : new ComponentDesignManager(),
    Get(fn) {
        if (this.ComponentManager && fn)
            fn(this.ComponentManager);
    },
    GetManagerId() {
        if (this.ComponentManager)
            return this.ComponentManager.ManagerId;
        return null;
    },
    SetActiveComponentManager(Manager, Owner) {
        if (this.ComponentManager) {
            if (Manager && this.ComponentManager.ManagerId === Manager.ManagerId)
                return;
            if (Manager && this.ComponentManager.Id === Manager.Id) {
                Manager.SelectedItems = Utils.DeepClone(this.ComponentManager.SelectedItems);
            }
            if (this.ComponentManager.Editing) {
                this.ComponentManager.Editing = false;
                if (this.ComponentManager.OnEditingChanged)
                    this.ComponentManager.OnEditingChanged();
            }
            this.ComponentManager.onActive && this.ComponentManager.onActive(false);
                
            // this.ComponentManager.SelectItem();
        }
        this.ComponentManager = Manager;
        Globals.ComponentManager = Manager;
        if (Manager) {            
            Manager.onActive && Manager.onActive(true);
        }        
        Utils.ForEach(this.ActiveComponentManagerConsuemrs, (consumer, i) => {
            if (consumer !== Owner && consumer.onActiveComponentManagerChanged)
                consumer.onActiveComponentManagerChanged();
        });
    },
    ActiveComponentManagerConsuemrs : [],
    RegisterToActiveComponentManager(consumer) {
        this.ActiveComponentManagerConsuemrs.push(consumer);
    },
    UnRegisterToActiveComponentManager(consumer) {
        Utils.Remove(this.ActiveComponentManagerConsuemrs, consumer);
    },
    All : {

    },
    DesignManagers : {
        
    },
    GetDesignManager(Id, PrototypeId) {
        if (!this.DesignManagers[Id]) {
            const MainManager = new ComponentDesignManager();
            if (PrototypeId)
                MainManager.LoadPrototype(PrototypeId, Id);
            else
                MainManager.Load(Id);            
            this.DesignManagers[Id] = MainManager;
        }
        return this.DesignManagers[Id];
    }
};

Globals.ActiveComponentManager = ActiveComponentManager;