import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    AppLayout,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../../../../importer';

import { PropertyLabel, Seperator } from '../../../../panels/right/iteminspector/styleitems/common';
import SelectDocumentTokens from './selectTokens';
import {GroupTitle, EditableName, TokenPanelHeader, TokenItemBox} from '../../../../panels/left/designsystem/common';
import { AnimatePresence, motion } from 'framer-motion';
import ItemThemeOptions from '../themeoptions';
import MultipleTokenSelector from '../../../../panels/left/designsystem/groupmembers';
import MultipleTokenGroupSelector from '../../../../panels/left/designsystem/groupmembers/groups';

const TokenTypes = Globals.ProjectManager.Tokens.Types;

export default class TokenSettings extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.SelectTokens = this.SelectTokens.bind(this);                
        this.SelectGroups = this.SelectGroups.bind(this);        
        this.SelectAliases = this.SelectAliases.bind(this);
        this.SelectAliaseGroups = this.SelectAliaseGroups.bind(this);
        
        this.UpdateItem = this.UpdateItem.bind(this);        
        this.onSearch = this.onSearch.bind(this);        
        this.CanceSearch = this.CanceSearch.bind(this);        
        this.SetSpecificState = this.SetSpecificState.bind(this);        
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.onAliaseSelectionChanged = this.onAliaseSelectionChanged.bind(this);
        this.onTokenGroupSelectionChanged = this.onTokenGroupSelectionChanged.bind(this);
        this.onAliaseGroupSelectionChanged = this.onAliaseGroupSelectionChanged.bind(this);
    }
    onSearch(filter) {
        this.setState({FilterText : filter});
    }
    CanceSearch() {
        this.setState({FilterText : ''});
    }
    UpdateItem() {
        this.props.onUpdateItem(this.props.showItemSettings.ref);
    }    
    SelectTokens() {
        this.ShowSelectTokens = !this.ShowSelectTokens;
        this.RCUpdate();
    }
    SelectGroups() {
        this.ShowSelectGroups = !this.ShowSelectGroups;
        this.RCUpdate();
    }
    SelectAliaseGroups() {
        this.ShowSelectAliseGroups = !this.ShowSelectAliseGroups;
        this.RCUpdate();
    }
    SelectAliases() {
        this.ShowSelectAlises = !this.ShowSelectAlises;
        this.RCUpdate();
    }    
    onSelectionChanged(selectedTokens) {
        Globals.ProjectManager.GetDocumentManager().SetItemValue(this.props.id, selectedTokens, 'tokens', 'ids');
        this.UpdateItem();
    }
    onAliaseSelectionChanged(selectedTokens) {
        Globals.ProjectManager.GetDocumentManager().SetItemValue(this.props.id, selectedTokens, 'aliases', 'ids');
        this.UpdateItem();
    }
    onTokenGroupSelectionChanged(selectedGroups) {
        Globals.ProjectManager.GetDocumentManager().SetItemValue(this.props.id, selectedGroups, 'groups', 'ids');
        this.UpdateItem();
    }
    onAliaseGroupSelectionChanged(selectedGroups) {
        Globals.ProjectManager.GetDocumentManager().SetItemValue(this.props.id, selectedGroups, 'aliaseGroups', 'ids');
        this.UpdateItem();
    }        
    SetItemProp(prop, value) {
        Globals.ProjectManager.GetDocumentManager().SetItemValue(this.props.id, value, prop);        
        this.UpdateItem();
        this.RCUpdate();
    }
    SetSpecificState(stateId, variationId) {
        Globals.ProjectManager.GetDocumentManager().SetItemValue(this.props.id, variationId, 'states', stateId);        
        this.UpdateItem();
        this.RCUpdate();
    }
    renderCustom() {
        let content, header;

        const data = Globals.ProjectManager.GetDocumentManager().GetItem(this.props.id);
        const tokenType = Utils.JustGet(data.data, TokenTypes.COLOR, 'type');
        const subType = Utils.JustGet(data.data, '', 'subType');
        const themeOption = Utils.JustGet(data.data, 'active', 'theme');

        let contentSelectedTokens;
        if (this.ShowSelectTokens) {
            header = (
                <TokenPanelHeader 
                    title='SELECT TOKENS' 
                    onClose={this.SelectTokens} 
                    style={{backgroundColor : SC.CurrentTheme.theme.back_lighter}}
                >                   
                </TokenPanelHeader>
            )
            const selectedTokens = Utils.JustGet(data, [], 'data', 'tokens', 'ids');
            contentSelectedTokens = (
                <motion.div 
                    style={{...SC.Styles.Absolute, zIndex : 10000, display : 'flex', flexDirection : 'column'}}
                    initial={{opacity : 0.7, x : 24}}
                    animate={{opacity : 1, x : 0}}
                    exit={{opacity : 0, x : 24}}
                    transition={{duration : 0.2}}
                >              
                                             
                   <MultipleTokenSelector 
                        selectedTokens={selectedTokens}
                        tokenType={tokenType}
                        subType={subType}
                        onSelectionChanged={this.onSelectionChanged}                        
                   />
                </motion.div>
            )
        }
        else if (this.ShowSelectAlises) {
            header = (
                <TokenPanelHeader 
                    title='SELECT SEMANTIC ALIASES' 
                    onClose={this.SelectAliases} 
                    style={{backgroundColor : SC.CurrentTheme.theme.back_lighter}}
                >                   
                </TokenPanelHeader>
            )
            const selectedTokens = Utils.JustGet(data, [], 'data', 'aliases', 'ids');
            contentSelectedTokens = (
                <motion.div 
                    style={{...SC.Styles.Absolute, zIndex : 10000, display : 'flex', flexDirection : 'column'}}
                    initial={{opacity : 0.7, x : 24}}
                    animate={{opacity : 1, x : 0}}
                    exit={{opacity : 0, x : 24}}
                    transition={{duration : 0.2}}
                >              
                                             
                   <MultipleTokenSelector 
                        selectedTokens={selectedTokens}
                        tokenType={tokenType}
                        subType={subType}
                        isAliase
                        onSelectionChanged={this.onAliaseSelectionChanged}                        
                   />
                </motion.div>
            )
        }       
        else if (this.ShowSelectGroups) {
            header = (
                <TokenPanelHeader 
                    title='SELECT TOKEN GROUPS' 
                    onClose={this.SelectGroups} 
                    style={{backgroundColor : SC.CurrentTheme.theme.back_lighter}}
                >                   
                </TokenPanelHeader>
            )
            const selectedGroups = Utils.JustGet(data, [], 'data', 'groups', 'ids');
            contentSelectedTokens = (
                <motion.div 
                    style={{...SC.Styles.Absolute, zIndex : 10000, display : 'flex', flexDirection : 'column'}}
                    initial={{opacity : 0.7, x : 24}}
                    animate={{opacity : 1, x : 0}}
                    exit={{opacity : 0, x : 24}}
                    transition={{duration : 0.2}}
                >              
                                             
                   <MultipleTokenGroupSelector 
                        selectedGroups={selectedGroups}
                        tokenType={tokenType}
                        subType={subType}
                        onSelectionChanged={this.onTokenGroupSelectionChanged}                        
                   />
                </motion.div>
            )
        }
        else if (this.ShowSelectAliseGroups) {
            header = (
                <TokenPanelHeader 
                    title='SELECT ALIASE GROUPS' 
                    onClose={this.SelectAliaseGroups} 
                    style={{backgroundColor : SC.CurrentTheme.theme.back_lighter}}
                >                   
                </TokenPanelHeader>
            )

            const selectedGroups = Utils.JustGet(data, [], 'data', 'aliaseGroups', 'ids');
            contentSelectedTokens = (
                <motion.div 
                    style={{...SC.Styles.Absolute, zIndex : 10000, display : 'flex', flexDirection : 'column'}}
                    initial={{opacity : 0.7, x : 24}}
                    animate={{opacity : 1, x : 0}}
                    exit={{opacity : 0, x : 24}}
                    transition={{duration : 0.2}}
                >              
                                             
                   <MultipleTokenGroupSelector 
                        selectedGroups={selectedGroups}
                        tokenType={tokenType}
                        subType={subType}
                        isAliase
                        onSelectionChanged={this.onAliaseGroupSelectionChanged}                        
                   />
                </motion.div>
            )
        }
        else {
            header = (
                <TokenPanelHeader 
                    title='TOKEN SETTINGS' 
                    onClose={this.props.onClose} 
                    style={{backgroundColor : SC.CurrentTheme.theme.back_lighter}}
                >                   
                </TokenPanelHeader>
            );

            // TODO : if theme selection is "specific", show token values in specific state

            content = (
                <React.Fragment>                    
                    <SC.FCol style={{padding : '8px'}}>        
                        <ItemThemeOptions {...this.props} />
                        <Seperator inner />                        
                        <SelectDocumentTokens 
                            {...this.props}
                            data={data}
                            onSelectTokens={this.SelectTokens}
                            onSelectGroups={this.SelectGroups}
                            onSelectAliases={this.SelectAliases}
                            onAliaseGroups={this.SelectAliaseGroups}
                        />
                    </SC.FCol>                    
                </React.Fragment>
            )
        }

        return (
            <SC.FCol f1 style={{backgroundColor : SC.CurrentTheme.theme.back}}>
                {header}
                <SC.CustomScrollbars>
                    <AnimatePresence>
                        {contentSelectedTokens}
                    </AnimatePresence>
                    {content}                      
                </SC.CustomScrollbars>            
            </SC.FCol>
        )
    }
}