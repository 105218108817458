import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    AppLayout,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../../../../importer';
import styled, { css} from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import SystemStatePanel from '../../../../panels/left/systemstates';
import { TokenItemBox } from '../../../../panels/left/designsystem/common';
import { TokenTypes } from '../../../../manager/tokens';
import { ColorTokenListItem, GetColorTokenItem } from '../../../../panels/left/designsystem/colors';
import { GetTextPatternTokenItem, TextPattern } from '../../../../panels/left/designsystem/typography/patterns';
import { GetShadowTokenItem, ShadowListItem } from '../../../../panels/left/designsystem/shadows';
import { CopyExportTokenCodeBox } from '../../../../tokens/export/tokengroup';
import { GenerateTokenExoprts } from '../../../../tokens/export/generateTokens';
import DropDownSelect from '../../../../../../components/editors/enum_dropdown';
import Switch from '../../../../../../components/editors/Switch';
import LightDarkBar from '../../../../../../components/light_dark_bar';
import { TabButton } from '../../../../panels/right/compnent/header';
import { FigmaLayoutListItem, GetFigmaLayoutTokenItem } from '../../../../../../apps/figmaplugin/designsystem/autolayouts';
import { AliaseTokenItemReferance } from '../../../../panels/left/designsystem/aliases/aliaseGroup';
import { BorderRadiusTokenItem, BorderStyleTokenItem, GetBorderRadiusTokenList, GetBorderRadiusTokenValue, GetBorderStyleTokenValue } from '../../../../panels/left/designsystem/borders';

export default class DocumentItem_FigmaComponent extends React.Component
{
    constructor(props) {
        super(props);

        this.ChangeTemplate = this.ChangeTemplate.bind(this);        
        this.Download = this.Download.bind(this);
        this.SetThemeState = this.SetThemeState.bind(this);

        this.state = {
            loaded : false,
            tokenView : 'tokens'
        };            
    }    
    componentDidMount() {
        this.Load(this.props.pageId, this.props.id);
    }
    shouldComponentUpdate(nextProps, nextState) {

        if (this.props.autoFocus !== nextProps.autoFocus)
            return true;
        if (this.state.ShouldUpdate !== nextState.ShouldUpdate)
            return true;
        if (this.state.loaded !== nextState.loaded)
            return true;
        if (this.state.tokenView !== nextState.tokenView)
            return true;
            

        if (this.props.RenderId !== nextProps.RenderId) {
            this.Load(nextProps.pageId, nextProps.id);
            return false;
        }        
        if (Utils.HasAnyChange(this.props, nextProps, 'preview', 'GlobalThemeId', 'GlobalState', 'StyleId', 'themeId')) {
            
            if (this.props.GlobalState !== nextProps.GlobalState) {
                if (this.figmaItem) {
                    let svg = Utils.JustGet(this.figmaItem, '', 'svg', this.LocalGlobaslState || nextProps.GlobalState);
                    this.figmaItem.svgImage = window.btoa(svg);
                }
            }
            return true;
        }
        
        return false;
    }
    async Load(pageId, id) {                

        this.data = Globals.ProjectManager.GetDocumentManager().GetPageItem(pageId, id);
        const cid = Utils.JustGet(this.data.data, null, 'id');
        
        this.themeType = Utils.JustGet(this.data.data, 'active', 'theme');
        this.systemStates = null;
        if (this.themeType === 'specific') {
            this.systemStates = Utils.JustGet(this.data.data, {}, 'states');

            const result = Utils.States.BuildSystemStateLabelFromVariations({
                States : Globals.ProjectManager.States.Get(),
                StateOrder : Globals.ProjectManager.States.GetOrder(),
                StateVariation : this.systemStates || {}
            })
            this.LocalGlobaslState = result.State;
            const result_array = Utils.States.GenerateStateArray({GlobalStateLabel : result.State});
            this.StyleState = result_array.GlobalStateArray;
            this.ReversedStyleState = Utils.Reverse(Utils.DeepClone(this.StyleState));

            this.CurrentTheme = Globals.ProjectManager.CalculateThemeValues(this.StyleState, this.ReversedStyleState, result.NonMediaState);
        }
        else if (this.themeType === 'custom') {
            this.filteredThemeStates = false;

            this.SpecificStateVariation = {

            };
            this.LocalGlobaslState = 'Default';
            this.StyleState = ['Default'];
            this.ReversedStyleState = ['Default'];

            const onlySelectedStates = Utils.JustGet(this.data.data, false, 'filterStates');
            if (onlySelectedStates) {
                this.filteredThemeStates = Utils.JustGet(this.data.data, [], 'filteredStates');
            }

            this.CurrentTheme = Globals.ProjectManager.CalculateThemeValues(this.StyleState, this.ReversedStyleState);
        }

        const exportModels = Globals.ProjectManager.GetExportModels();
        this.exportModelItems = [];
        this.templateId = Globals.ProjectManager.GetDocumentManager().GetPageItemValue(this.props.id, this.props.pageId, null, 'templateId');

        Utils.ForEach(exportModels, (model, i) => {
            if (!this.templateId)
                this.templateId = model.id;
            this.exportModelItems.push({id : model.id, label : model.name});
        });
         

        await Globals.ProjectManager.FigmaManager.Load();
        const figmaData = Globals.ProjectManager.FigmaManager.Component(cid);
        if (figmaData) {
            this.figmaItem = {
                id : cid,
                name : figmaData.name,                
                width : figmaData.width,
                height : figmaData.height,
                svg : figmaData.svg,
                tokens : []
            }

            let svg = Utils.JustGet(figmaData, '', 'svg', this.LocalGlobaslState || this.props.GlobalState);
            this.figmaItem.svgImage = window.btoa(svg);

            const tokens = Utils.JustGet(figmaData, null, 'tokens', 'tokens');
            if (tokens) {
                this.tokenTypeItems = {};
                Utils.ForEach(tokens, (tokenUsage, tokenId) => {
                    const token = Globals.ProjectManager.Tokens.Token(tokenId);
                    if (token) {
                        let type = token.type;
                        if (token.aliase) {
                            const aliaseTokenId = Globals.ProjectManager.Tokens.AliaseTokenId(tokenId);
                            const targetToken = Globals.ProjectManager.Tokens.Token(aliaseTokenId);
                            if (targetToken)
                                type = targetToken.type;
                        }

                        const tokenItem = {
                            id : tokenId,
                            name : token.name,
                            type : type,
                            aliase : token.aliase
                        };

                        this.figmaItem.tokens.push(tokenItem);

                        const typeItems = Utils.Get(this.tokenTypeItems, [], type);
                        typeItems.push(tokenItem);
                    }
                });

                this.GenerateExportCode();
            }
            this.setState({loaded : Utils.Id()});
        }        
    }
    GenerateExportCode() {
        this.ExportCodes = [];
        this.CodeId = Utils.Id();
        const exportDefinition = Globals.ProjectManager.GetExportModel(this.templateId).definition;       

        Utils.ForEach(this.tokenTypeItems, (tokens, type) => {

            let useTokenType = type;
            if (type === TokenTypes.TextPatterns)
                useTokenType = TokenTypes.Fonts;

            const tokenIds = [];
            const aliaseIds = [];
            tokens.map((tokenItem) => {
                if (tokenItem.aliase)
                    aliaseIds.push(tokenItem.id);
                else
                    tokenIds.push(tokenItem.id)
            });
          
            let exportGroupData;
            if (exportDefinition.groupData) {            
                Utils.ForEach(exportDefinition.groupData, (exportGroup, id) => {
                    if (exportGroup.tokenType === useTokenType) {
                        exportGroupData = exportGroup;
                        return false;
                    }
                });
            }

            const ExportOptions = {
                type : useTokenType,
                tokenIds : tokenIds,
                aliaseIds : aliaseIds,
                target : exportDefinition.target,
                nameFormat : exportDefinition.nameFormat,
                GlobalState : this.LocalGlobaslState || this.props.GlobalState,
                TokenOptions : exportGroupData || {},
                onlyModifiedTokens : false,
                generateDataSchema : false,
                namePrefix : exportDefinition.prefix,
                StateArray : this.ReversedStyleState || Globals.ProjectManager.ReversedStyleState,
                onlyTokens : true
            };

            // const ExportOptions = {
            //     type : type,
            //     tokenIds : tokenIds,
            //     aliaseIds : aliaseIds,
            //     target : 'css',
            //     nameFormat : 'kebabCase',
            //     GlobalState : this.props.GlobalState,
            //     TokenOptions : exportGroupData || {},
            //     onlyModifiedTokens : false,
            //     generateDataSchema : false,
            //     namePrefix : '',
            //     StateArray : Globals.ProjectManager.ReversedStyleState,
            //     onlyTokens : true
            // };

            const ExportResult = GenerateTokenExoprts(ExportOptions);
            if (ExportResult) {
                const codes = Utils.Get(this, [], 'ExportCodes');
                codes.push(ExportResult.exportedCode);
            }
            
        });
    }
    componentDidUpdate(prevProps, prevState) {
        this.state.ShouldUpdate = false;
    }
    
    Update() {
        this.setState({
            ShouldUpdate : true
        })
    }    
    ChangeTemplate(id) {
        Globals.ProjectManager.GetDocumentManager().SetItemValue(this.props.id, id, 'templateId');
        this.templateId = id;
        this.Load(this.props.pageId, this.props.id);
        this.Update();
    }
    Download() {
        DownloadTokens({
            target : this.target,
            type : this.props.type,
            exportedCode : this.ExportResult.exportedCode,
            downloadAsFile : this.downloadAsFile,
            tokenItems : this.ExportResult.tokenItems
        })
    }
    SetThemeState(stateId, variationId) {
        Utils.Set(this.SpecificStateVariation, variationId, stateId);
        if (!this.props.preview) {
            Globals.ProjectManager.GetDocumentManager().SetItemValue(this.props.id, variationId, 'states', stateId);
        }

        const result = Utils.States.BuildSystemStateLabelFromVariations({
            States : Globals.ProjectManager.States.Get(),
            StateOrder : Globals.ProjectManager.States.GetOrder(),
            StateVariation : this.SpecificStateVariation
        })

        const result_array = Utils.States.GenerateStateArray({GlobalStateLabel : result.State});
        this.LocalGlobaslState = result.State;
        this.StyleState = result_array.GlobalStateArray;
        this.ReversedStyleState = Utils.Reverse(Utils.DeepClone(this.StyleState));

        this.CurrentTheme = Globals.ProjectManager.CalculateThemeValues(this.StyleState, this.ReversedStyleState, result.NonMediaState);

        if (this.figmaItem) {
            let svg = Utils.JustGet(this.figmaItem, '', 'svg', this.LocalGlobaslState || this.props.GlobalState);
            this.figmaItem.svgImage = window.btoa(svg);
        }

        this.GenerateExportCode();

        this.setState({
            ShouldUpdate : true
        })
    }
    render() {        

        this.showTemplateSelector = Globals.ProjectManager.GetDocumentManager().GetPageItemValue(this.props.id, this.props.pageId, true, 'showTemplateSelector');
        const alignment = Globals.ProjectManager.GetDocumentManager().GetPageItemValue(this.props.id, this.props.pageId, 'flex-start', 'alignment');
        const isLightDark = Globals.ProjectManager.GetDocumentManager().GetPageItemValue(this.props.id, this.props.pageId, false, 'isDarkBackground');
        const codes = Utils.Get(this, [], 'ExportCodes');

        let CodeId = this.CodeId;

        const codeStyle = {
            height : '100%'
        };

        if (SC.CurrentTheme.theme.isLight) {
            codeStyle.filter = 'invert(1)';
            CodeId += 'Light';
        }

        let expotTemplateSelector;
                
        if (!this.props.preview || this.showTemplateSelector) {
            expotTemplateSelector = (        
                <React.Fragment>
                    <div>Format</div>
                    <DropDownSelect 
                        items={this.exportModelItems}
                        style={{width : 'auto', minWidth : '120px', marginLeft : '8px', backgroundColor : SC.CurrentTheme.theme.back}}
                        value={this.templateId}
                        onChange={this.ChangeTemplate}
                        hasBorder         
                        noBackColor   
                        autoHeight
                        xsmall
                        toRight
                    />
                </React.Fragment>                    
            )
        }    

        let globalStateEditor;

        if (this.themeType === 'custom') {
            globalStateEditor = (
                <SC.FCol style={{alignSelf : 'flex-start', marginBottom : '8px'}}>
                    <div>Theme</div>
                    <SystemStatePanel 
                        contentStyle={{margin : 0, minWidth : '200px', backgroundColor : this.props.textStyles.backgroundHighlight}}
                        justContent
                        onSetStateVariation={this.SetThemeState}
                        currentVariations={this.SpecificStateVariation}                        
                        filteredStates={this.filteredThemeStates}
                        themeId={this.props.themeId}
                    /> 
                </SC.FCol>
            )
        }

        return (
            <SC.FCol>
                {
                    !this.state.loaded && <SC.Loading_Icon />
                }
                {
                    this.state.loaded && this.figmaItem && 
                    <React.Fragment>
                        {globalStateEditor}
                        <SC.FRow alc jsb>
                            <SC.TextDivAbbr>
                                {this.figmaItem.name}
                            </SC.TextDivAbbr>     
                            <SC.FRow>               
                                <LightDarkBar 
                                    small 
                                    Dark={isLightDark}
                                    medium
                                    onChange={(e) => {
                                        Globals.ProjectManager.GetDocumentManager().SetItemValue(this.props.id, !isLightDark, 'isDarkBackground');
                                        this.Update();
                                    }}
                                />
                            </SC.FRow>
                        </SC.FRow>                        
                        <SC.GridBackground style={{
                            marginBottom : '16px',
                            marginTop : '8px',
                            ...SC.Styles.Flex.Column,
                            alignItems : alignment,
                            padding : '16px'
                        }} dark={isLightDark}>
                            <div style={{
                                width : `clamp(5px, ${this.figmaItem.width}px, 100%)`,
                                height : `clamp(5px, ${this.figmaItem.height}px, 100%)`,  
                                minHeight : `clamp(120px, ${this.figmaItem.height}px, 800px)`,
                                display : 'flex'
                            }}>
                                <div style={{
                                    backgroundImage : `url(data:image/svg+xml;base64,${this.figmaItem.svgImage})`,
                                    backgroundSize : `100% 100%`,
                                    backgroundPosition : 'center',
                                    backgroundRepeat : 'no-repeat',
                                    width : '100%',
                                    minHeight : '100%'                                    
                                    // width : Utils.px(this.figmaItem.width),
                                    // height : Utils.px(this.figmaItem.height)                                    '
                                }} />
                            </div>
                        </SC.GridBackground>
                        <div style={{
                            display : 'grid',
                            gridTemplateColumns : 'repeat(2, minMax(140px, auto))',
                            justifyContent : 'start',
                            gridTemplateRows : '32px',
                            gridGap : '4px',
                            paddingLeft : '8px',
                            marginBottom : '16px',
                            borderBottom : SC.CurrentTheme.theme.border_seperator
                        }}>
                            {
                                [{id : 'tokens', label : 'TOKENS'},
                                {id : 'tokenExport', label : 'EXPORT TOKENS TO CODE'}
                                // {id : 'pushToGitLab', label : 'PUSH TO GITLAB'}
                            ].map(({id, label},i) => {
                                    return (
                                        <TabButton key={i} style={{paddingLeft : '12px', paddingRight : '12px'}} selected={this.state.tokenView === id} onClick={() => this.setState({tokenView : id})}>{label}</TabButton>
                                    )
                                })
                            }
                        </div>
                        {
                            this.state.tokenView === 'tokens' &&
                            <SC.FCol>
                                {
                                    this.figmaItem.tokens.map(({id, name, type}) => {
                                        return (
                                            <FigmaComponentToken 
                                                key={id}
                                                id={id}
                                                name={name}
                                                type={type}
                                                StateArray={this.ReversedStyleState}
                                                ContextState={this.LocalGlobaslState}
                                            />
                                        )
                                    })
                                }
                                {
                                    this.figmaItem.tokens.length === 0 &&
                                    <div>No tokens used in this component!</div>
                                }     
                            </SC.FCol>
                        }
                        {
                            this.state.tokenView === 'tokenExport' &&
                            <div> 
                                {
                                    (!this.props.preview || this.showTemplateSelector) &&
                                    <SC.FRow
                                        alc
                                        style={{
                                            backgroundColor : SC.CurrentTheme.theme.back_lighter,
                                            borderbottom : SC.CurrentTheme.theme.border_seperator,                        
                                            padding : '8px',
                                            ...SC.Styles.Font()
                                        }}
                                    >                    
                                        {expotTemplateSelector}
                                        {
                                            !this.props.preview &&
                                            <React.Fragment>                            
                                                <div style={{marginRight : '8px', marginLeft : '8px'}}>Hide Format Selection in Preview</div>
                                                <Switch round value={!this.showTemplateSelector} onChange={(value) => {
                                                    Globals.ProjectManager.GetDocumentManager().SetItemValue(this.props.id, !value, 'showTemplateSelector');
                                                    this.Update();
                                                }} />
                                            </React.Fragment>
                                        }
                                    </SC.FRow>
                                }
                                {
                                    codes.map((code, i) => {
                                        return (
                                            <CopyExportTokenCodeBox 
                                                RenderId={this.CodeId}
                                                codeClassName={this.props.codeClassName}
                                                exportedCode={code}
                                                onDownload={this.Download}
                                                inlineDownloadButtons
                                                style={{...codeStyle, overflow : 'auto'}}
                                                toolbarStyle={{
                                                    top : '8px'
                                                }}
                                            />
                                        )
                                    })
                                }         
                            </div>
                        }                                                  
                    </React.Fragment>
                }   
            </SC.FCol>            
        )        
    }
}

class FigmaComponentToken extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        const token = Globals.ProjectManager.Tokens.Token(this.props.id);
        let aliasedName;
        if (token.aliase) {
            const aliaseTokenId = Globals.ProjectManager.Tokens.AliaseTokenId(this.props.id, this.props.StateArray);
            if (aliaseTokenId) {
                const aliaesdToken = Globals.ProjectManager.Tokens.Token(aliaseTokenId);
                if (aliaesdToken) {
                    aliasedName = aliaesdToken.name;
                }                
            }
        }
        if (this.props.type === TokenTypes.COLOR) {
            let color = GetColorTokenItem({id : this.props.id, StateArray : this.props.StateArray, contextState : this.props.ContextState});

            return (
                <ColorTokenListItem 
                    color = {color}                     
                    aliasedName={aliasedName}
                    noLock
                />
            )
        }
        else if (this.props.type === TokenTypes.TextPatterns) {
            const pattern = GetTextPatternTokenItem({id : this.props.id, contextState : this.props.ContextState});
        
            return (
                <TextPattern 
                    pattern={pattern.pattern} 
                    listView
                    aliasedName={aliasedName}
                    StateArray={this.props.StateArray}
                    contextState={this.props.ContextState}
                    noLock
                />
            )
        }
        else if (this.props.type === TokenTypes.Shadows) {
            const token = GetShadowTokenItem({id : this.props.id});
            return (
                <ShadowListItem 
                    shadow={token} 
                    textShadow={token.textShadow}
                />
            )
        }
        else if (this.props.type === TokenTypes.Borders) {
            const border = GetBorderStyleTokenValue(token, this.props.id, this.props.StateArray, this.props.ContextState);
            return (
                <BorderStyleTokenItem 
                    border={border}
                    isListView
                />
            )
        }
        else if (this.props.type === TokenTypes.BorderRadiuses) {
            const radius = GetBorderRadiusTokenValue(token, this.props.id, this.props.StateArray, this.props.ContextState);
            return (
                <BorderRadiusTokenItem 
                    radius={radius}
                    isListView
                />
            )
        }
        else if (this.props.type === TokenTypes.FigmaAutoLayouts) {
            const FigmaLayout = GetFigmaLayoutTokenItem(this.props.id, this.props.StateArray, this.props.ContextState);
            if (FigmaLayout) {
                return (
                    <FigmaLayoutListItem
                        key={FigmaLayout.id}
                        notDraggable
                        name={FigmaLayout.name}
                        FigmaLayout={FigmaLayout}
                        aliasedName={aliasedName}
                        style={{marginBottom : '2px'}}
                    />
                )
            }            
        }
        return (  
            <TokenItemBox style={{marginBottom : '2px'}}>
                {this.props.name}
            </TokenItemBox>
        );
    }
}
 