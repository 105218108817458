import React from 'react';
import ReactDOM from 'react-dom';

import {
    MetaData,
    Utils,
    Strings,
    SC
} from '../../../../../importer';

import { onConstructItem, onItemShouldUpdate, onItemDidMount, onItemWillUnMount, onItemDidUpdate, onGetItemProps, onGetDomProps } from './common';

export default class Icon extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }

        onConstructItem(this, props);

        this.UpdateStyle(this.props);
    }
    UpdateStyle(props) {
        this.renderData = props.GetComponentManager().GetItemStyle(props.Id);
    }       
    componentDidMount() {
        onItemDidMount.call(this);
    }
    componentWillUnmount() {
        onItemWillUnMount.call(this);
    }
    componentDidUpdate(prevProps, prevState) {
        onItemDidUpdate.call(this, prevProps, prevState);
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (onItemShouldUpdate.call(this, nextProps, nextState)) {
            return true;
        }                
                    
        return false;
    }  
    GetMaxSize() {        
        return this.props.GetParentSize();
    }    
    render() { 
        if (Utils.IsTrue(this.renderData.props.hidden))
            return null;
            
        let outerStyle = {};
        let otuerProps = {};
        
        if (this.props.IsRootItem && this.props.IsRootItem.SubComponent) {
            outerStyle =  this.props.IsRootItem.SubComponent.style;       
            otuerProps.onMouseDown = this.props.Preview ? this.props.IsRootItem.SubComponent.onMouseDown : this.onMouseDown;
            otuerProps.onMouseUp = this.props.Preview ? this.props.IsRootItem.SubComponent.onMouseUp : this.onItemClick;
            otuerProps.onDoubleClick = this.props.IsRootItem.SubComponent.onDoubleClick;
        }

        const style = {
            ...this.renderData.style            
        };
        if (this.isSelected) {
            style.transition = 'all 0.2s ease';
        }

        if (this.isDragging) {
            style.opacity = 0.5;
        }

        const props = onGetDomProps({designer : this, props : otuerProps, style : style});

        let MetaIcon = Utils.JustGet(this.renderData.props, '', 'icon');
        
        if (MetaIcon) {
            const icon = (
                <RenderItemIcon 
                    MetaIcon={MetaIcon}
                    onClick={this.Select}
                    outerProps={props}
                    style={style}
                />
            )         
            if (icon)   
                return icon;
        }

        return (                                
            <SC.Div_Flex_Cell style={style} {...props}>
                <SC.Div_Flex_Cell
                    style={{
                        border : '1px dashed #636469',
                        minWidth : '44px',
                        height : '44px'
                    }}
                    cursorPointer
                >
                    {!this.HasPreviewStyle && <SC.Icons.Icon_Icon empty={true} viewMode={0} size={24} />            }
                </SC.Div_Flex_Cell>
            </SC.Div_Flex_Cell>
        )
    }
}
 
export const RenderItemIcon = ({MetaIcon, onClick, outerProps, style}) => {
    if (MetaIcon) {
        if (MetaIcon.provider === Strings.ICON_GOOGLE_MATERIAL) {
            if (MetaIcon.paths) {
                return (
                    <div onClick={onClick} {...outerProps} style={style}>
                        <svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' viewBox={`0 0 24 24`} >
                            {
                                MetaIcon.paths.map((p, i) => {
                                    return (
                                        <path key={i} d={p} />
                                    )
                                })
                            }
                        </svg>
                    </div>                
                )
            }
        }
        
        else {
            let backgroundImage = null;
            if (MetaIcon.PreviewUrl) {
                backgroundImage = `url(${MetaIcon.PreviewUrl})`;
            }
            else if (MetaIcon.provider === 'Figma') {
                backgroundImage = `url(data:image/svg+xml;base64,${window.btoa(MetaIcon.svg)})`;
            }
            else if (MetaIcon.data) {
                if (MetaIcon.provider === Strings.ICONFINDER) {
                    backgroundImage = `url(data:image/svg+xml;base64,${MetaIcon.data})`
                }
                else if (MetaIcon.provider === Strings.CUSTOM) {
                    backgroundImage = `url(${MetaIcon.data})`;
                }
                else if (MetaIcon.provider === 'svg') {
                    backgroundImage = `url(data:image/svg+xml;base64,${window.btoa(MetaIcon.data)})`;
                }
                
            }
            if (backgroundImage) {                                        
                return (
                    <div {...outerProps} style={{
                        ...style,
                        backgroundImage : backgroundImage,
                        backgroundSize : 'contain',
                        backgroundRepeat : 'no-repeat',
                        backgroundPosition : 'center'
                    }}>
                        
                    </div>                
                )
            }
        }
    }
    return null;
}