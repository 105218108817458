import {
    AppState,
    Events,    
    Utils,
    Globals,
    MetaData,
    Strings
} from '../../../importer';


const GetData = () => {
    return Utils.Get(Globals.ProjectManager.Data, {}, 'Data');
}

const MockupDataManager =  {
    IsInitialized() {
        return Utils.JustGet(GetData(), false, 'mockupdata',  'initialized');
    },
    Collections() {
        return Utils.JustGet(GetData(), [], 'mockupdata',  'collections', 'order');
    },
    Collection(id) {
        return Utils.JustGet(GetData(), null, 'mockupdata', 'collections', 'list', id);
    },
    AddCollection({name, fields, rows}) {
        const useId = Utils.Id();
        const order = this.Collections();
        if (order.indexOf(useId) < 0) {
            order.push(useId);
            Globals.ProjectManager.DataManager.Set(order, 'mockupdata', 'collections', 'order');
        }            
        
        const model = {
            name : name,
            fields : fields || [],
            rows : rows || []
        };
        Globals.ProjectManager.DataManager.Set(model, 'mockupdata', 'collections', 'list', useId);
        return useId;
    },
    SaveCollection(id, model) {
        Globals.ProjectManager.DataManager.Set(model, 'mockupdata', 'collections', 'list', id);
    },
    SetCollectionProp(id, prop, value) {
        Globals.ProjectManager.DataManager.Set(value, 'mockupdata', 'collections', 'list', id, prop);
    },
    DeleteCollection(collectionId) {
        const collection = this.Collection(collectionId);
        const fields = Utils.Get(collection, [], 'fields');
        Utils.ForEach(fields, (fieldId, ) => {
            this.DeleteField(fieldId);
        });
        const rows = Utils.Get(collection, [], 'rows');
        Utils.ForEach(rows, (rowId, ) => {
            this.DeleteRow(rowId);
        });
        
        const order = this.Collections();
        Utils.RemoveEquals(order, collectionId);
        Globals.ProjectManager.DataManager.Set(order, 'mockupdata', 'collections', 'order');
        Globals.ProjectManager.DataManager.Delete('mockupdata', 'collections', 'list', collectionId);
        this.SetChanged();
        return true;
    },
    ChangeCollectionOrder(oldIndex, newIndex) {
        const order = this.Collections();
        Utils.ChangePlace(order, oldIndex, newIndex);
        Globals.ProjectManager.DataManager.Set(order, 'mockupdata', 'collections', 'order');
    },
    ChangeFieldOrder(collectionId, oldIndex, newIndex) {
        const collection = this.Collection(collectionId);
        const fields = Utils.Get(collection, [], 'fields');
        Utils.ChangePlace(fields, oldIndex, newIndex);
        Globals.ProjectManager.DataManager.Set(fields, 'mockupdata', 'collections', 'list', collectionId, 'fields');
    },
    Field(id) {
        return Utils.JustGet(GetData(), null, 'mockupdata', 'fields', id);
    },
    AddField({name, dataType, collectionId, fakeType}) {
        const id = Utils.Id();
        const model = {
            name : name,
            dataType : dataType,
            collectionId : collectionId,
            fake : fakeType
        }
        Globals.ProjectManager.DataManager.Set(model, 'mockupdata', 'fields', id);
        const collection = this.Collection(collectionId);
        const fields = Utils.Get(collection, [], 'fields');
        fields.push(id);
        Globals.ProjectManager.DataManager.Set(fields, 'mockupdata', 'collections', 'list', collectionId, 'fields');
        this.SetChanged();
        return id;
    },
    SetChanged() {
        AppState.GetDataApi().update_path_project_data(Globals.ProjectManager.Id, ['Changes', 'Mokcup', 'Web'], Utils.Id());
    },
    SaveField({id, prop, value}) {
        Globals.ProjectManager.DataManager.Set(value, 'mockupdata', 'fields', id, prop);
        this.SetChanged();
    },
    DeleteField(id) {
        const field = this.Field(id);
        if (field) {
            const collection = this.Collection(field.collectionId);
            const fields = Utils.Get(collection, [], 'fields');
            Utils.RemoveEquals(fields, id);
            Globals.ProjectManager.DataManager.Set(fields, 'mockupdata', 'collections', 'list', field.collectionId, 'fields');
            Globals.ProjectManager.DataManager.Delete('mockupdata', 'fields', id);

            const rows = Utils.Get(collection, [], 'rows');
            Utils.ForEach(rows, (rowId, ) => {
                Globals.ProjectManager.DataManager.Delete('mockupdata', 'rows', rowId, id);
            });

            this.SetChanged();
        }        
    },

    Row(id) {
        return Utils.JustGet(GetData(), null, 'mockupdata', 'rows', id);
    },
    AddRow({collectionId, data}) {
        const id = Utils.Id();        
        Globals.ProjectManager.DataManager.Set(data || {}, 'mockupdata', 'rows', id);
        const collection = this.Collection(collectionId);
        const rows = Utils.Get(collection, [], 'rows');
        rows.push(id);
        Globals.ProjectManager.DataManager.Set(rows, 'mockupdata', 'collections', 'list', collectionId, 'rows');
        this.SetChanged();
        return id;
    },
    DeleteRow(id, collectionId) {
        const row = this.Row(id);
        const collection = this.Collection(collectionId);
        const rows = Utils.Get(collection, [], 'rows');
        Utils.RemoveEquals(rows, id);
        Globals.ProjectManager.DataManager.Set(rows, 'mockupdata', 'collections', 'list', collectionId, 'rows');
        Globals.ProjectManager.DataManager.Delete('mockupdata', 'rows', id);
        this.SetChanged();
    },
    SetRowValue(id, fieldId, value, state) {
        Globals.ProjectManager.DataManager.Set(value, 'mockupdata', 'rows', id, fieldId, state || 'Default', 'Value');
        this.SetChanged();
    },
    GetRandomRecordId(FieldId) {
        const Field = this.Field(FieldId);
        if (Field) {
            const Collection = this.Collection(Field.collectionId);
            if (Collection && Collection.rows && Collection.rows.length > 0) {
                const index = Math.floor(Math.random() * Collection.rows.length);
                const RecordId = Collection.rows[index];
                return RecordId;
            }
        }
    },
    GetRandomValue(FieldId) {
        const RecordId = this.GetRandomRecordId(FieldId);
        return this.GetRecordValue(RecordId, FieldId);                
    },
    GetRecordValue(RecordId, FieldId) {
        if (RecordId) {
            const Record = MockupDataManager.Row(RecordId);
            return this.GetRecordValueOf(Record, FieldId);
        }
    },
    GetRecordValueOf(Record, FieldId) {
        if (Record) {
            const StateArray = Globals.ProjectManager.ReversedStyleState;
            let value;
            Utils.ForEach(StateArray, (GlobalState, i) => {
                let statevalue = Utils.JustGet(Record, null, FieldId, GlobalState, 'Value');
                if (Utils.IsNotNullOrEmpty(statevalue)) {
                    value = statevalue;
                    return false;
                }
            });
            return value;
        }
    },
    GetRecordPublishValue({ComponentId, RecordId, ModelId}) {
        const ComponentData = Globals.ProjectManager.GetComponentData(ComponentId);
        if (ComponentData) {
            const Model = Utils.JustGet(ComponentData, null, Strings.MODELS, Strings.LIST, ModelId);
            if (Model && Model.DataId) {
                const value = MockupDataManager.GetRecordValue(RecordId, Model.DataId);
                if (Model.dataType === 'image') {
                    return {
                        url : Utils.JustGet(value, '', 'url')
                    };
                }
                return value;
            }
        }
    },
    locale : 'en',
    GetFaker(locale) {
        return new Promise((resolve, reject) => {
            if (locale !== this.locale) {
                delete this.fakerAPI;
            }
            this.locale = locale || 'en';
            if (!this.fakerAPI) {
                if (!this.fakerAPIPromise)
                    this.fakerAPIPromise = [import('faker/locale/' + this.locale)];
                Promise.all(this.fakerAPIPromise).then((r) => {
                    this.fakerAPI = r[0].default;
                    resolve(this.fakerAPI);
                })
            }
            else
                resolve(this.fakerAPI);
        });        
    }
}

export default MockupDataManager;