import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    AppState,
    Utils,
    Events,
    Loading,
    Strings,
    MetaData,
    Links,
    UIUtils
} from '../../../../../importer';
import VisibilitySensor from 'react-visibility-sensor';
import styled, { css } from 'styled-components';
import DragSource from '../../../../../components/dragdrop/HoCDragSource';
import PanelHeader from '../../header';
import { API_Unsplash } from '../../../../../integrations/unsplash';
import { ImageThumbnail } from '../../../../../integrations/onlineImages';


const DragThumbnail = DragSource(ImageThumbnail);
var ScrollPosition = 0;

class Select_Token_Image extends ReactBaseComponent {
    constructor(props) {
        super(props);
        this.Select = this.Select.bind(this);
        
        this.LoadPage = this.LoadPage.bind(this);
        this.refreshIndex = 0;
        this.Ref_Scroll = React.createRef();
        this.ImageItemType = ImageThumbnail;
        if (this.props.dragSource)
            this.ImageItemType = DragThumbnail;
    }
    componentDidMount() {
        super.componentDidMount();
        this.LoadPage();     
    }    
    //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
    componentDidUpdate(prevProps, prevState) {
        if (prevState.Search !== this.state.Search ) {
            delete this.images;
            setTimeout(() => {
                if (this.Ref_Scroll.current) {
                    UIUtils.Utils.SmoothScrollTo(this.Ref_Scroll.current, ScrollPosition, 0, 0.3);
                }
            }, 0);  
            this.LoadPage();
        }
    }
    
    LoadPage() {
        const that = this;
        if (API_Unsplash.IsLoading())
            return;
        API_Unsplash.Search({
            SearchText : this.state.Search
        }).then((result) => {
            this.total = result.total;
            const images = Utils.Get(that, [[],[]], 'images');
            let tempIndex = images[0].length;
            Utils.ForEach(result.images, (img, i) => {
                images[i%2].push(img);
            });
            this.refreshIndex = tempIndex;
            that.RCUpdate();            
        })       
    }
    PreviewPattern(show, url, previewUrl) {       
        
        if (show) {
            const imageId = Utils.Id();
            this.WillShowPreview = {            
                show : show,
                url : previewUrl,
                downloadURL : url,
                id : imageId
            }
            
            const downloadimage = this.DownloadPreviewImage.bind(this, imageId, url);
            downloadimage();
        }
        else if (this.WillShowPreview)
            this.WillShowPreview.show = false;
        setTimeout(() => {
            if (this.WillShowPreview) {
                if (this.WillShowPreview.show)
                    this.props.onPreviewChange(this.WillShowPreview.show, this.WillShowPreview.url);                
                else {
                    this.props.onPreviewChange(false);
                    delete this.WillShowPreview.show;
                }
            }                        
        }, 200);        
    }
    DownloadPreviewImage(id, url) {
        var img = document.createElement("img");

        img.setAttribute("Id", id);
        const that = this;
        img.onload = function() {
            if (that.WillShowPreview && that.WillShowPreview.show && that.WillShowPreview.id === id) {
                that.WillShowPreview.url = that.WillShowPreview.downloadURL;
                that.props.onPreviewChange(that.WillShowPreview.show, that.WillShowPreview.downloadURL);
            }
            const el = document.getElementById(id);
            el && el.parentNode && el.parentNode.removeChild(el);
        }
        img.src = url;
    }
    Select(img) {        
        const {downloadData, ...imageData} = img;
        API_Unsplash.TriggerDownload(downloadData);
        this.props.onSelect(imageData);
    }    
    
    renderCustom() {
        let content = null;
        let Search = null;

        if (Utils.IsNotNullOrEmpty(this.state.Search)) {
            Search = this.state.Search.toUpperCase();
        }
        
        if (this.images) {
            content = (
                <SC.FCol f1 style={{marginLeft : '-4px'}}>
                    {
                        !this.props.noInfo && 
                        <SC.FRow>
                            { Utils.IsNotNullOrEmpty(this.state.Search) ? `${this.total} images found` : 'listing popular images' }
                        </SC.FRow>
                    }                                
                    <SC.CustomScrollbars hideTracksWhenNotNeeded autoHide ScrollRef={this.Ref_Scroll} >
                        <div style={{
                            display : 'grid',
                            gridGap : '4px',
                            gridTemplateColumns : '1fr 1fr',
                            paddingTop : 0
                        }}>
                            {
                                this.images.map((col, i) => {
                                    return (
                                        <SC.FCol key={i}>
                                            {
                                                col.map((img, j) => {
                                                    return (
                                                        <this.ImageItemType 
                                                            key={img.id} 
                                                            url={img.preview} 
                                                            authorName={img.authorName}
                                                            authorLink={img.authorLink}
                                                            last={j===this.refreshIndex}
                                                            onLoadPage={this.LoadPage}
                                                            hasSelect={
                                                                this.props.dragSource ? null : {
                                                                    onSelect : this.Select.bind(this, img)
                                                                }
                                                            }
                                                            onMouseEnter={this.props.onPreviewChange && this.PreviewPattern.bind(this, true, img.url, img.preview)}
                                                            onMouseLeave={this.props.onPreviewChange && this.PreviewPattern.bind(this, false, img.url)}
                                                            DragItem={this.props.dragSource ? {
                                                                Type : Strings.TOOLBARITEM,
                                                                Item : {
                                                                    MetaItem : {
                                                                        Type : MetaData.Components.Image.Type,
                                                                        Id : img.id,
                                                                        Provider : {
                                                                            Provider : 'unsplash',
                                                                            id : img.id,
                                                                            preview : img.preview,
                                                                            url : img.url,
                                                                            width : img.width,
                                                                            height : img.height
                                                                        }
                                                                    }
                                                                } 
                                                            } : null}
                                                        />
                                                    )
                                                })
                                            }                                            
                                        </SC.FCol>
                                    )
                                })
                            }
                        </div>                                             
                    </SC.CustomScrollbars>                    
                </SC.FCol>
            )
        }  
        else {
            content = (
                <SC.Div_Flex_Cell fw fh>
                    <SC.Loading_Icon />
                </SC.Div_Flex_Cell>
            )
        }      


        return  (
            <React.Fragment>
                <PanelHeader
                    noSort
                    noTags
                    Search={this.state.Search}
                    forComponent
                    style={{marginBottom: '8px'}}
                    onSearch={(s) => this.RCSetStateValue('Search', s)} onCancel={() => this.RCSetStateValue('Search', '')}
                />
                {this.props.children}
                {content}
            </React.Fragment>
        )
    }
}

export default Select_Token_Image;

const ImageItem = styled.img`
    margin-bottom : 8px;
    cursor : pointer;
    width : 100%;
    height : auto;
    transition : all 0.2s ease;
    outline : 1px solid transparent;
    outline-offset : 1px;
    box-sizing : border-box;
    &:hover {
        transform : scale(0.98);        
    }
`;