import React from 'react';
import ReactDOM from 'react-dom';

import {
    MetaData,
    Utils,
    UIUtils,
    SC,
    Strings,
    AppLayout
} from '../../../../../../../importer';

import BaseGeneric from '../base';
import { GenericTable_SubTypeIds } from '../../../../../../../../toolabs-modules/toolabs-metaui/components/generic/initialize/table';

export default class Generic_Table extends BaseGeneric {
    constructor(props) {
        super(props);        
    }
    UpdateStyle(props) {
        this.renderData = props.GetComponentManager().GetItemStyle(props.Id);        

        const MetaItem = this.props.GetComponentManager().GetMetaItem(this.props.Id);
        this.renderDataSubTypeStyles = {};
        GenericTable_SubTypeIds.map((SubType) => {
            const SubTypeId = Utils.JustGet(MetaItem.Generic, null, SubType.Id);         
            this.renderDataSubTypeStyles[SubType.Id] = props.GetComponentManager().GetItemStyle(SubTypeId).style;
        })

    }
    renderContent({style, props}) {        

        let textContent = Utils.JustGet(this.renderData.props, '', 'textContent');
        return (
            <div {...props} style={{...style}}>
                <table style={{width : '100%'}}>
                    <thead style={{...this.renderDataSubTypeStyles.Header, marginBottom : '8px'}}>
                        <tr>
                            <th style={{...this.renderDataSubTypeStyles.HeaderColumn}}>COL A</th>
                            <th style={{...this.renderDataSubTypeStyles.HeaderColumn}}>COL B</th>
                            <th style={{...this.renderDataSubTypeStyles.HeaderColumn}}>COL C</th>
                        </tr>
                    </thead>
                    <tbody style={{...this.renderDataSubTypeStyles.Body}}>
                        {
                            [1, 2, 3, 4, 5].map((rowid, i) => {
                                const even_odd_style = (i%2) === 0 ? this.renderDataSubTypeStyles.RowEvens : this.renderDataSubTypeStyles.RowOdds;
                                return (
                                    <tr key={rowid} style={{...this.renderDataSubTypeStyles.Row, ...even_odd_style}}>
                                        <td style={{...this.renderDataSubTypeStyles.Column}}>{rowid}.A</td>
                                        <td style={{...this.renderDataSubTypeStyles.Column}}>{rowid}.B</td>
                                        <td style={{...this.renderDataSubTypeStyles.Column}}>{rowid}.C</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}