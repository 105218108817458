import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    Events,
    AppLayout,
    Globals
} from '../../../../../../../../importer';
       
import {ItemNameEditor, TokenItemBox} from '../../../common';
import SizeEditor from '../../../../../right/iteminspector/styleitems/size';
import { Seperator, ToggleButton } from '../../../../../right/iteminspector/styleitems/common';
import NumberInput  from '../../../../../../../../components/editors/input_number';
import Slider from '../../../../../../../../components/editors/slider';
import { CalculateScaleForValue } from '../../../../../../manager/tokens';

export default class SpacePatternCard extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.SaveName = this.SaveName.bind(this);
        this.SelectSpaceScale = this.SelectSpaceScale.bind(this);

        this.pattern = this.props.pattern;
        this.pattern = Globals.ProjectManager.Tokens.SpacePatterns.GetPattern(this.props.id);
    }
    componentWillUnmount() {
        this.props.onClosed && this.props.onClosed();
        super.componentWillUnmount();        
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.GlobalStateId !== nextProps.GlobalStateId || this.props.id !== nextProps.id || this.props.renderId !== nextProps.renderId) {
            this.pattern = Globals.ProjectManager.Tokens.SpacePatterns.GetPattern(nextProps.id);
            if (!this.pattern) {
                this.props.onClose();
                return false;
            }
            return true;
        }
        return super.ShouldUpdate(nextProps, nextState);
    }     
    SetScaleType(isCustom) {
        if (isCustom) {
            Globals.ProjectManager.Tokens.SetValue({id : this.props.id, value : 1, name : 'scaleFactor'});    
        }
        else
            Globals.ProjectManager.Tokens.DeleteValue({id : this.props.id, name : 'scaleFactor'});

        this.props.onSave();
        Globals.ProjectManager.UpdateTokenValues({});
        if (this.props.isNew)
            this.props.onPreviewChange && this.props.onPreviewChange(this.pattern);
        else {
            Events.BroadcastThrottle_50(Events.GLOBAL.TOKEN_VALUE_CHANGING, [
                {
                    Id : this.props.id,
                    Type : Globals.ProjectManager.Tokens.Types.Spacings,
                    value : Globals.ProjectManager.Tokens.SpacePatterns.GetSpaceSize(this.pattern)
                }
            ]);
        }      
        this.RCUpdate();
    }
    SelectSpaceScale(id, value) {
        !this.props.isNew && Globals.ProjectManager.LogTokenChange({Desc : 'Change Pattern'});

        Globals.ProjectManager.Tokens.SetValue({id : this.props.id, value : id, name : 'scaleIndex'});
        if (id === 'Custom') {            
            Globals.ProjectManager.Tokens.SetValue({id : this.props.id, value : {
                value : value,
                Unit : Utils.Defaults.Unit()
            }, name : 'customSize'});
        }
        else {
            Globals.ProjectManager.Tokens.DeleteValue({id : this.props.id, name : 'customSize'});
        }
                
        this.props.onSave();
        Globals.ProjectManager.UpdateTokenValues({});
        if (this.props.isNew)
            this.props.onPreviewChange && this.props.onPreviewChange(this.pattern);
        else {
            Events.BroadcastThrottle_50(Events.GLOBAL.TOKEN_VALUE_CHANGING, [
                {
                    Id : this.props.id,
                    Type : Globals.ProjectManager.Tokens.Types.Spacings,
                    value : Globals.ProjectManager.Tokens.SpacePatterns.GetSpaceSize(this.pattern)
                }
            ]);
        }      
        this.RCUpdate();      
    }
    SaveName(name) {
        this.pattern.name = name;
        Globals.ProjectManager.Tokens.ChangeTokenName(this.props.id, name);
        this.RCUpdate();
    }
    onChangeSize(prop, newSize) {
        !this.props.isNew && Globals.ProjectManager.LogTokenChange({Desc : 'Change Pattern'});
        const size = Utils.DeepClone(Globals.ProjectManager.Tokens.ValueOf({model : this.pattern, name : prop})) || {};
        size.value = newSize.value;
        size.Unit = newSize.unit;
        Globals.ProjectManager.Tokens.SetValue({id : this.props.id, value : size, name : prop});
        this.props.onSave();
        Globals.ProjectManager.UpdateTokenValues({});
        if (this.props.isNew)
            this.props.onPreviewChange && this.props.onPreviewChange(this.pattern);
        else {
            Events.BroadcastThrottle_50(Events.GLOBAL.TOKEN_VALUE_CHANGING, [
                {
                    Id : this.props.id,
                    Type : Globals.ProjectManager.Tokens.Types.Spacings,
                    value : Globals.ProjectManager.Tokens.SpacePatterns.GetSpaceSize(this.pattern)
                }
            ]);
        }
    }    
    DeleteSize(prop) {
        !this.props.isNew && Globals.ProjectManager.LogTokenChange({Desc : 'Change Pattern'});        
        Globals.ProjectManager.Tokens.DeleteValue({id : this.props.id,  name : prop});
        this.props.onSave();
        Globals.ProjectManager.UpdateTokenValues({});
        if (this.props.isNew)
            this.props.onPreviewChange && this.props.onPreviewChange(this.pattern);
        else {
            Events.BroadcastThrottle_50(Events.GLOBAL.TOKEN_VALUE_CHANGING, [
                {
                    Id : this.props.id,
                    Type : Globals.ProjectManager.Tokens.Types.Spacings,
                    value : Globals.ProjectManager.Tokens.SpacePatterns.GetSpaceSize(this.pattern)
                }
            ]);
        }
    }
    onChangeCustomFactor(prop, value) {
        !this.props.isNew && Globals.ProjectManager.LogTokenChange({Desc : 'Change Pattern'});
        Globals.ProjectManager.Tokens.SetValue({id : this.props.id, value : value, name : prop});
        this.props.onSave();
        Globals.ProjectManager.UpdateTokenValues({});
        if (this.props.isNew)
            this.props.onPreviewChange && this.props.onPreviewChange(this.pattern);
        else {
            Events.BroadcastThrottle_50(Events.GLOBAL.TOKEN_VALUE_CHANGING, [
                {
                    Id : this.props.id,
                    Type : Globals.ProjectManager.Tokens.Types.Spacings,
                    value : Globals.ProjectManager.Tokens.SpacePatterns.GetSpaceSize(this.pattern)
                }
            ]);
        }
    }        
    renderCustom() {

        const pattern = this.pattern;
        const scaleIndex = Globals.ProjectManager.Tokens.ValueOf({model : pattern, name : 'scaleIndex'});
        const customSize = Globals.ProjectManager.Tokens.ValueOf({model : pattern, name : 'customSize'});
        let content_left, content_right;

        return (
            <SC.FCol fw fh style={{padding : '10px', position : 'relative', backgroundColor : SC.CurrentTheme.theme.back, boxSizing : 'border-box'}}>
                <ItemNameEditor
                    noMargin
                    fontSize='12px'                        
                    name={pattern.name}
                    onSaveName={this.SaveName}
                    onGetItems={this.props.onGetItems}
                    model={pattern}
                    readOnly={this.pattern.locked}
                    lockable={{
                        isLocked : this.pattern.locked,
                        onTokenLock : () => {
                            this.pattern.locked = Globals.ProjectManager.Tokens.ToggleTokenLock(this.props.id);                                    
                            this.RCUpdate();
                        }
                    }}                    
                />          
                <PatternScaleSelector 
                    compact
                    title='SPACE SIZE'
                    customSize={customSize}                    
                    onChangeCustom={this.onChangeSize.bind(this, 'customSize')}
                    onDeleteCustom={this.DeleteSize.bind(this, 'customSize')}
                    scaleFactor={Globals.ProjectManager.Tokens.ValueOf({model : pattern, name : 'scaleFactor'})}
                    scaleDiff={Globals.ProjectManager.Tokens.ValueOf({model : pattern, name : 'scaleDiff'}) || 0}
                    onChangeCustomFactor={this.onChangeCustomFactor.bind(this, 'scaleFactor')}
                    onChangeCustomDiff={this.onChangeCustomFactor.bind(this, 'scaleDiff')}
                    scaleIndex={scaleIndex}
                    baseValue={this.props.baseSize}
                    ratio={this.props.ratio}
                    onSelect={this.SelectSpaceScale}
                    onSetModularScale={this.SetScaleType.bind(this, false)}
                    onSetCustomScale={this.SetScaleType.bind(this, true)}
                    scalesId={this.scalesId}
                    locked={this.pattern.locked}
                />                         
            </SC.FCol>
        )       
    }
}

export class PatternScaleSelector extends ReactBaseComponent {
    SelectScale(id) {
        this.props.onSelect(id);
    }    
    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }        
    SetCustomValue(value) {
        this.props.onSelect('Custom', value);
    }
    SetScaledValue() {
        const {customSize} = this.props;
        
        if (customSize && Utils.IsNotNull(customSize.value)) {
            const result = {scale : 0, diff : 0};
            const {scale, diff} = CalculateScaleForValue({result : result, baseValue : this.props.baseValue, ratio : this.props.ratio, targetValue : customSize.value});                       
            this.props.onSelect(scale);
            this.props.onChangeCustomDiff(diff);
        }
        else
            this.props.onSelect(0);
    }
    renderCustom() { 
        const {customSize} = this.props;
        let editor;
        let isCustom = false;
        let unit = this.props.timeScale ? 'ms' : 'px';

        let value = this.props.baseValue;

        if (customSize && Utils.IsNotNull(customSize.value)) {
            isCustom = true;
            unit = Utils.JustGet(customSize, null, 'Unit');
            if (this.props.timeScale)
                unit = 'ms';
            editor = (
                <SizeEditor 
                    autoFocus                    
                    value={Utils.JustGet(customSize, null, 'value')}
                    unit={unit}
                    onChange={this.props.onChangeCustom}
                    onChanging={this.props.onChangeCustom}
                    onDelete={this.props.onDeleteCustom}
                    fontSize={this.props.fontSize}
                    timeUnits={this.props.timeScale}
                    noNone
                />
            )            
        }
        else {            
            
            let factor = this.props.scaleFactor;
            let diff = this.props.scaleDiff;
            
            let isCustomScale = Utils.IsNotNullOrEmpty(factor);

            let scale = this.props.scaleIndex;

            const scaleProps = {};

            const useDiff = Utils.UseNullOrEmpty(diff, 0);

            if (isCustomScale) {
                scale = factor;
                value = value * (factor || 1) + (useDiff);
                scaleProps.numeralDecimalScale = 3;                
            }
            else {
                value = Math.round(value * (Math.pow(this.props.ratio, scale)) + (useDiff));
                
            }

            const onScaleChange = isCustomScale ? this.props.onChangeCustomFactor : this.props.onSelect;


            editor = (
                <div
                    style={{
                        display : 'grid',
                        gridTemplateColumns : 'auto',
                        gridGap : '16px',
                        alignItems : 'center',
                        justifyItems : 'center',
                        marginTop : '16px'
                    }}
                >
                    <SC.FRow style={{...SC.Styles.FontStyles.Monospace}} alc>
                        <span>[ Base Value = </span>
                        <span style={{fontSize : '16px', marginLeft : '4px'}}>{this.props.baseValue}</span>
                        <span> ] TIMES</span>
                    </SC.FRow>                    
                    <SC.FCol style={{justifySelf : 'stretch'}}>
                        <SC.FCol>
                            <SC.FRow alc style={{marginTop : '8px', marginBottom : '8px', overflow : 'visible', fontSize : '11px', justifyContent : 'center'}}>
                                <ToggleButton selected={!isCustomScale} onClick={this.props.onSetModularScale} style={{marginRight : '4px'}}>Scale Step {this.props.ratio}<span style={{marginBottom : '8px', marginLeft : '2px'}}>x</span></ToggleButton>
                                <ToggleButton selected={isCustomScale} onClick={this.props.onSetCustomScale}>Custom</ToggleButton>
                                <NumberInput 
                                    value={scale} 
                                    onChange={onScaleChange}
                                    {...scaleProps}
                                    boxStyle={{
                                        width : '38px',
                                        height : '28px',
                                        marginLeft : '8px',
                                        backgroundColor : SC.CurrentTheme.theme.input_back
                                    }}
                                    style={{                                    
                                        width : '100%',
                                        textAlign : 'center',
                                        fontSize : '14px',                
                                        padding : 0,
                                        ...SC.Styles.FontStyles.Monospace
                                    }}
                                />
                            </SC.FRow>
                        </SC.FCol>
                        {
                            !isCustomScale && 
                            <Slider 
                                value={this.props.scaleIndex}
                                min={-10}
                                max={10}
                                onChange={onScaleChange}
                                onChanging={onScaleChange}
                                themeId={AppLayout.ThemeId}
                            />
                        }                        
                    </SC.FCol>
                    <SC.FRow style={{...SC.Styles.FontStyles.Monospace}} alc>             
                        <span style={{ marginRight : '8px', fontSize : '24px'}}>{value - useDiff}</span>           
                        <span style={{fontSize : '24px'}}>+</span>
                        <NumberInput 
                            value={diff} 
                            onChange={this.props.onChangeCustomDiff}
                            onChanging={this.props.onChangeCustomDiff}
                            boxStyle={{
                                width : '38px',
                                height : '28px',
                                marginLeft : '8px',
                                backgroundColor : SC.CurrentTheme.theme.input_back
                            }}
                            style={{                                    
                                width : '100%',
                                textAlign : 'center',
                                fontSize : '14px',                
                                padding : 0,
                                ...SC.Styles.FontStyles.Monospace
                            }}
                        />
                        <span style={{marginLeft : '6px', marginRight : '8px', fontSize : '24px'}}>=</span>
                        <span style={{fontSize : '28px'}}>{value}</span>
                        <span style={{fontSize : '18px', marginLeft : '6px'}}>{unit}</span>
                    </SC.FRow> 
                </div>
            )
        }

        return (
            <SC.FCol style={this.props.compact ? {marginTop : '16px', pointerEvents : this.props.locked ? 'none' : 'all'} : {marginLeft : '8px', marginRight : '8px', pointerEvents : this.props.locked ? 'none' : 'all'}}>
                <div style={{paddingLeft : '4px', marginBottom : '6px'}}>{this.props.title}</div>                        
                <div style={{
                    display : 'grid', gridTemplateColumns : isCustom ? '1fr 1fr auto' : '1fr 1fr', gridGap : '4px'
                }}>
                    <ToggleButton selected={!isCustom} onClick={this.SetScaledValue.bind(this)}>Scaled</ToggleButton>
                    <ToggleButton selected={isCustom} onClick={this.SetCustomValue.bind(this, value)}>Custom</ToggleButton>
                    {isCustom && editor}
                </div>
                {!isCustom && editor}
            </SC.FCol>
        )
    }
}
 