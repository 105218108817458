import MetaData from '../toolabs-metaui';
import Utils from '../toolabs-utils';
import Strings from './strings';
import ConvertValueDef, {ConvertUnit} from './convertvalue';

const FlexValues = MetaData.Properties.FlexValues;

const GetGridItems = function(model, grid_style, modelName, templateName, gapName) {
    const columnsModel = Utils.Get(model, {}, modelName);

    const metaColumns = Utils.Get(columnsModel, [], 'Items');
    let gridTemplateColumns = '';
    Utils.ForEach(metaColumns,(col, i) => {
        if (!col.hidden) {
            if (col.Single) {
                const size = GetGridSize(col.Single.size);
                if (col.Repeat > 1) {                                
                    gridTemplateColumns += ` repeat(${col.Repeat}, ${size}) `;
                }
                else 
                    gridTemplateColumns += ' ' + size;
            }
        }    
        else {
            
        }                
    });

    if (columnsModel.DynamicColumn) {
        if (gridTemplateColumns.indexOf('fr') < 0) {
            const size_min = GetGridSize(columnsModel.DynamicColumn.minWidth);
            const size_max = GetGridSize(columnsModel.DynamicColumn.maxWidth);
            gridTemplateColumns += ` repeat(${columnsModel.DynamicColumn.type === 'autoFit' ? 'auto-fit' : 'auto-fill'}, minmax(${size_min}, ${size_max ||'1fr'})) `;
        }            
    }

    grid_style[templateName] = gridTemplateColumns;
    if (gapName) {
        if (!grid_style[gapName]) {
            const colGap = Utils.Get(columnsModel, null, 'Gap');
            if (colGap) 
                grid_style[gapName] = GetGridSize(colGap, 'px');
        }        
    }    

    if (model.AlignContent)
        grid_style.alignContent = model.AlignContent;
    if (model.JustifyContent)
        grid_style.justifyContent = model.JustifyContent;
};

const GetGridSize = function(size, defUnit) {
    if (!size)
        return '';
    if (size && size.minMax) {
        return `minmax(${GetGridSize(size.minMax.min, 'px')}, ${GetGridSize(size.minMax.max)}) `;
    }
    if (size && size.value === 'auto')
        return 'auto ';
    
    const unit = size.unit || defUnit || 'px';
    return Utils.px(Utils.ToInteger(size.value), unit) ;
}

const ConvertStyleObject = ({PublishContext, GlobalState, State, ConvertedItem, StyleObject, UseCssName, StyleName, RootItem, IsElementTemplate}) => {
    let skip = false;
    if (RootItem || IsElementTemplate) {
        if (Utils.IsOneOf(StyleName, 'left', 'right', 'top', 'bottom'))
            skip = true;
    }
    if (StyleObject && !skip) {       
        const StyleDef = MetaData.Styles[StyleName];                 
        let ConvertedName = StyleName;
        if (UseCssName && StyleDef.css)                        
            ConvertedName = StyleDef.css;
        if (StyleObject.Reset || StyleObject.Unset) {
            Utils.Set(ConvertedItem.Styles, {Reset : true}, 'Options', GlobalState, State, ConvertedName);     
        }
        else {
            if (StyleName === MetaData.Styles.backgroundImage.name) {
                if (Utils.IsObject(StyleObject)) {
                    if (StyleObject.SizeType === 'size') {
                        if (StyleObject.width) {
                            let width_value = ConvertValueDef({type : MetaData.DataTypes.size}, StyleObject.width, PublishContext);
                            if (Utils.IsNotNull(width_value)) {
                                if (width_value.ModelId) {
                                    Utils.Set(ConvertedItem, width_value.ModelId,  'Props', 'Variable', GlobalState, State, 'backgroundSize', 'width'); 
                                }
                                else if (width_value.TokenId) {
                                    Utils.Set(ConvertedItem, width_value.TokenId, 'Props', 'System', GlobalState, State, 'backgroundSize', 'width'); 
                                }
                                else {
                                    Utils.Set(ConvertedItem, width_value.value, 'Props', 'Static', GlobalState, State, 'backgroundSize', 'width'); 
                                }
                            }
                        }                                        
                        if (StyleObject.height) {
                            let height_value = ConvertValueDef({type : MetaData.DataTypes.size}, StyleObject.height, PublishContext);
                            if (Utils.IsNotNull(height_value)) {
                                if (height_value.ModelId) {
                                    Utils.Set(ConvertedItem, height_value.ModelId, 'Props', 'Variable', GlobalState, State, 'backgroundSize', 'height');     
                                }
                                else if (height_value.TokenId) {
                                    Utils.Set(ConvertedItem, height_value.TokenId, 'Props', 'System', GlobalState, State, 'backgroundSize', 'height'); 
                                }
                                else {
                                    Utils.Set(ConvertedItem, height_value.value, 'Props', 'Static', GlobalState, State, 'backgroundSize', 'height');      
                                }
                            }
                        }
                        
                    }
                    else {
                        Utils.Set(ConvertedItem.Styles.Static, StyleObject.SizeType, GlobalState, State, 'backgroundSize');                                        
                    }

                    if (StyleObject.Repeat)
                        Utils.Set(ConvertedItem.Styles.Static, StyleObject.Repeat, GlobalState, State, 'backgroundRepeat');                                            
                    if (StyleObject.Clip)
                        Utils.Set(ConvertedItem.Styles.Static, StyleObject.Clip, GlobalState, State, 'backgroundClip');
                    if (StyleObject.Origin)
                        Utils.Set(ConvertedItem.Styles.Static, StyleObject.Origin, GlobalState, State, 'backgroundOrigin');
                    if (StyleObject.Attachment)
                        Utils.Set(ConvertedItem.Styles.Static, StyleObject.Attachment, GlobalState, State, 'backgroundAttachment');
                    if (StyleObject.PositionX)
                        Utils.Set(ConvertedItem.Styles.Static, StyleObject.PositionX, GlobalState, State, 'backgroundPositionX');
                    if (StyleObject.PositionY)
                        Utils.Set(ConvertedItem.Styles.Static, StyleObject.PositionY, GlobalState, State, 'backgroundPositionY');
                }                                
            }
            else {
                let Value = ConvertValueDef(StyleDef, StyleObject, PublishContext);
                if (Utils.IsNotNull(Value)) {
                    if (Value.ModelId) {
                        Utils.Set(ConvertedItem.Styles.Variable, Value.ModelId, GlobalState, State, ConvertedName);     

                        if (StyleDef.type === 'size') {
                            Utils.Set(ConvertedItem, {
                                unit : ConvertUnit(StyleObject.Unit)
                            }, 'ModelMaps', ConvertedName);
                        }

                    }
                    else if (Value.TokenId) {
                        Utils.Set(ConvertedItem.Styles.System, Value.TokenId, GlobalState, State, ConvertedName);                             
                    }
                    else {
                        let value = Value.value;
                        if (StyleDef.type === MetaData.DataTypes.flex)
                            value =  MetaData.Properties.layout.values.Flex.convertValue(value)
                        Utils.Set(ConvertedItem.Styles.Static, value, GlobalState, State, ConvertedName);     
                    }
                }    
            }                                                                                
        }                        
    }
}

const ConvertPropertyObject = ({PublishContext, MetaItem, GlobalState, State, ConvertedItem, PropObject, UseCssName, PropName, RootItem, IsElementTemplate}) => {
    let ConvertedName = PropName;
    const PropDef = MetaData.Properties[PropName] || {};

    if (UseCssName && PropDef.css)                        
        ConvertedName = PropDef.css;    
    const IsNotDefaultState = GlobalState !== Strings.DEFAULT || State !== Strings.DEFAULT;
    
    if (PropDef.type === MetaData.DataTypes.ElementTemplate) {
        let ElementId = PropObject.Id && PropObject.Id.value;
        if (!ElementId && IsNotDefaultState)
            ElementId = Utils.Get(MetaItem.Property, null, Strings.DEFAULT, 'Element', 'Id', Strings.VALUE);
        if (ElementId) {
            if (!Utils.Includes(PublishContext.ConsumedElements, ElementId))
                PublishContext.ConsumedElements.push(ElementId);
            Utils.Set(ConvertedItem, ElementId, Strings.PROPS, State, 'Element', Strings.VALUE, 'Id');
            if (PropObject.Variations && PropObject.Variations.value) {
                Utils.ForEach(PropObject.Variations.value, (Var, StateId) => {
                    Utils.Set(ConvertedItem, Var.value, Strings.PROPS, State, 'Element', Strings.VALUE, 'Variations', StateId);
                });
            }
        }
    }
    else if (PropDef.type === MetaData.DataTypes.SubComponent) {
        if (PropObject.value) {
            let ComponentId = PropObject.value.Id;
            if (ComponentId !== PublishContext.Id) {
                if (!ComponentId && IsNotDefaultState)
                    ComponentId = Utils.JustGet(MetaItem.Property, null, Strings.DEFAULT, Strings.DEFAULT, Strings.COMPONENT, Strings.VALUE, 'Id');
                if (ComponentId) {                
                    Utils.Set(ConvertedItem, ComponentId, 'Props', 'Static', GlobalState, State, 'Id');                
                }
            }
            if (PropObject.value.Variations) {
                Utils.ForEach(PropObject.value.Variations, (Var, StateId) => {
                    Utils.Set(ConvertedItem, Var, Strings.PROPS, 'Static', GlobalState, State, 'Variations', StateId);
                });
            }
                        
            if (PropObject.value.Models) {
                Utils.ForEach(PropObject.value.Models, (ModelValue, ModelId) => {
                    if (ModelValue) {
                        if (ModelValue.MetaImage) {
                            if (ModelValue.MetaImage.ModelId) {
                                Utils.Set(ConvertedItem, ModelValue.MetaImage.ModelId, Strings.PROPS, 'Variable', GlobalState, State, 'Models', ModelId);
                            }
                            else {
                                const source = Utils.Get(ModelValue.MetaImage, null, 'Source', 'value');
                                if (source === 'unsplash'  || source === 'url') {
                                    const url = Utils.Get(ModelValue.MetaImage, '', 'url', 'value');
                                    Utils.Set(ConvertedItem, url, Strings.PROPS, 'Static', GlobalState, State, 'Models', ModelId);
                                }                        
                                else if (source === 'token') {
                                    const TokenId = Utils.Get(ModelValue.MetaImage, null, 'TokenId', 'value');
                                    Utils.Set(ConvertedItem, TokenId, Strings.PROPS, 'System', GlobalState, State, 'Models', ModelId);                                                
                                }
                            }                                            
                        }
                        else if (ModelValue.MetaIcon) {
                            if (ModelValue.MetaIcon.ModelId) {
                                Utils.Set(ConvertedItem, ModelValue.MetaIcon.ModelId, Strings.PROPS, 'Variable', GlobalState, State, 'Models', ModelId);
                            }
                            else {
                                const source = Utils.Get(ModelValue.MetaIcon, null, 'Source', 'value');
                                if (source === 'token') {
                                    const TokenId = Utils.Get(ModelValue.MetaIcon, null, 'TokenId', 'value');
                                    Utils.Set(ConvertedItem, TokenId, Strings.PROPS, 'System', GlobalState, State, 'Models', ModelId);                                                
                                }
                                else {
                                    const Info = Utils.Get(ModelValue.MetaIcon, '', 'Info', 'value');
                                    Utils.Set(ConvertedItem, Info, Strings.PROPS, 'Static', GlobalState, State, 'Models', ModelId);
                                }    
                            }                                            
                        }
                        else {
                            if (ModelValue.ModelId)
                                Utils.Set(ConvertedItem, ModelValue.ModelId, Strings.PROPS, 'Variable', GlobalState, State, 'Models', ModelId);
                            else if (ModelValue.TokenId)
                                Utils.Set(ConvertedItem, ModelValue.TokenId, Strings.PROPS, 'System', GlobalState, State, 'Models', ModelId);
                            else {
                                if (Utils.IsObject(ModelValue) && ModelValue.Unit) {
                                    const value_converted = ConvertValueDef({type : MetaData.DataTypes.size}, ModelValue, PublishContext);
                                    if (value_converted)
                                        Utils.Set(ConvertedItem, value_converted.value, Strings.PROPS, 'Static', GlobalState, State, 'Models', ModelId);
                                    else
                                        Utils.Set(ConvertedItem, ModelValue.value, Strings.PROPS, 'Static', GlobalState, State, 'Models', ModelId);
                                }                                
                                else
                                    Utils.Set(ConvertedItem, ModelValue.value, Strings.PROPS, 'Static', GlobalState, State, 'Models', ModelId);
                            }
                        }
                    }                                        
                });
            }     
            if (MetaItem.ContentItems) {
                ConvertedItem.ContentItems = MetaItem.ContentItems;
            }      
        }          
    }
    else if (PropDef.type === MetaData.DataTypes.BoxShadow) {        
        if (Utils.IsNotNull(PropObject)) {
            if (PropObject.ModelId) {
                Utils.Set(ConvertedItem, PropObject.ModelId, 'Styles', 'Variable', GlobalState, State, ConvertedName);     
            }
            else if (PropObject.TokenId) {
                Utils.Set(ConvertedItem, PropObject.TokenId, 'Styles', 'System', GlobalState, State, ConvertedName);     
            }
            else {
                Utils.Set(ConvertedItem, PropObject.value, 'Styles', 'Static', GlobalState, State, ConvertedName);     
            }
        }
    }
    else if (PropDef.type === MetaData.DataTypes.TextShadow) {        
        if (Utils.IsNotNull(PropObject)) {
            if (PropObject.ModelId) {
                Utils.Set(ConvertedItem, PropObject.ModelId, 'Styles', 'Variable', GlobalState, State, ConvertedName);     
            }
            else if (PropObject.TokenId) {
                Utils.Set(ConvertedItem, PropObject.TokenId, 'Styles', 'System', GlobalState, State, ConvertedName);     
            }
            else {
                Utils.Set(ConvertedItem, PropObject.value, 'Styles', 'Static', GlobalState, State, ConvertedName);     
            }
        }
    }
    else if (PropDef.type === MetaData.DataTypes.Transform) {                        
        Utils.ForEach(PropObject.value, (TransformValue, TransformProp) => {
            const TransformDef = PropDef.subProps[TransformProp];
            if (TransformDef) {
                let Value = ConvertValueDef(TransformDef, TransformValue, PublishContext); 
                
                if (Utils.IsNotNull(Value)) {
                    if (Value.ModelId) {
                        Utils.Set(ConvertedItem, Value.ModelId, 'Props', 'Variable', GlobalState, State, 'transform', TransformProp);     
                    }
                    else if (Value.TokenId) {
                        Utils.Set(ConvertedItem, Value.TokenId, 'Props', 'System', GlobalState, State, 'transform', TransformProp);     
                    }
                    else {
                        Utils.Set(ConvertedItem, Value.value, 'Props', 'Static', GlobalState, State, 'transform', TransformProp);     
                    }
                }    
            }                           
        });
        if (PropObject.value.originX)
            Utils.Set(ConvertedItem, PropObject.value.originX, Strings.STYLES, GlobalState, State, 'transform', 'originX');       
        if (PropObject.value.originY)
            Utils.Set(ConvertedItem, PropObject.value.originY, Strings.STYLES, GlobalState, State, 'transform', 'originY');       
    }
    else if (PropDef.type === MetaData.DataTypes.Filter) {                        
        Utils.ForEach(PropObject.value, (FilterValue, FilterProp) => {
            const FilterDef = PropDef.subProps[FilterProp];
            if (FilterDef) {
                let Value = ConvertValueDef(FilterDef, FilterValue, PublishContext); 
                
                if (Utils.IsNotNull(Value)) {
                    if (Value.ModelId) {
                        Utils.Set(ConvertedItem, Value.ModelId, 'Props', 'Variable', GlobalState, State, 'filter', FilterProp);     
                    }
                    else if (Value.TokenId) {
                        Utils.Set(ConvertedItem, Value.TokenId, 'Props', 'System', GlobalState, State, 'filter', FilterProp);     
                    }
                    else {
                        Utils.Set(ConvertedItem, Value.value, 'Props', 'Static', GlobalState, State, 'filter', FilterProp);     
                    }
                }


            }                           
        });
    }
    else if (PropDef.type === MetaData.DataTypes.Provider) {
        Utils.Set(ConvertedItem, PropObject.value, Strings.PROPS, GlobalState, State, 'Provider', Strings.VALUE);
    }
    else if (PropDef.type === MetaData.DataTypes.WidgetOptions) {
        if (Utils.IsObject(PropObject)) {
            Utils.ForEach(PropObject, (subpropval, prop) => {
                let Value = ConvertValueDef(PropDef, subpropval, PublishContext); 
                if (Utils.IsNotNull(Value)) {
                    if (Value.ModelId) {
                        Utils.Set(ConvertedItem, Value.ModelId, 'Props', 'Variable', GlobalState, State, 'WidgetOptions', prop);     
                    }
                    else if (Value.TokenId) {
                        Utils.Set(ConvertedItem, Value.TokenId, 'Props', 'System', GlobalState, State, 'WidgetOptions', prop);     
                    }
                    else {
                        Utils.Set(ConvertedItem, Value.value, 'Props', 'Static', GlobalState, State, 'WidgetOptions', prop);     
                    }
                }
            });
        }
    }
    else if (PropDef.type === MetaData.DataTypes.TokenOptions) {
        if (Utils.IsObject(PropObject)) {            
            Utils.ForEach(PropObject, (subpropval, prop) => {
                let Value = ConvertValueDef(PropDef, subpropval, PublishContext); 
                if (Utils.IsNotNull(Value)) {
                    if (Value.ModelId) {
                        Utils.Set(ConvertedItem, Value.ModelId, 'Props', 'Variable', GlobalState, State, 'TokenOptions', prop);     
                    }
                    else if (Value.TokenId) {
                        Utils.Set(ConvertedItem, Value.TokenId, 'Props', 'System', GlobalState, State, 'TokenOptions', prop);     
                    }
                    else {
                        Utils.Set(ConvertedItem, Value.value, 'Props', 'Static', GlobalState, State, 'TokenOptions', prop);     
                    }
                }
            });
            // let allTokens = true;
            // const tokens = [];
            // if (MetaItem.Widget.Type === MetaData.Components.Token.TokenTypes.Color.Type) {
            //     Utils.ForEach(PublishContext.BoardData.Data.Variables.Colors, (TokenId, i) => {
            //         const model = Utils.JustGet(PublishContext.BoardData.Data.Variables.List, null, TokenId);
            //         if (allTokens)
            //             tokens.push(model);
            //         else {
            //             // TODO : Filter options
            //         }
            //     });
            // }
            // Utils.Set(ConvertedItem, tokens, 'Props', 'Static', State, 'TokenOptions', 'Tokens');
        }
    }
    else if (PropDef.type === MetaData.DataTypes.GenericOptions) {
        if (Utils.IsObject(PropObject)) {            
            Utils.ForEach(PropObject, (subpropval, prop) => {
                let Value = ConvertValueDef(PropDef, subpropval, PublishContext); 
                if (Utils.IsNotNull(Value)) {
                    if (Value.ModelId) {
                        Utils.Set(ConvertedItem, Value.ModelId, 'Props', 'Variable', GlobalState, State, 'GenericOptions', prop);     
                    }
                    else if (Value.TokenId) {
                        Utils.Set(ConvertedItem, Value.TokenId, 'Props', 'System', GlobalState, State, 'GenericOptions', prop);     
                    }
                    else {
                        Utils.Set(ConvertedItem, Value.value, 'Props', 'Static', GlobalState, State, 'GenericOptions', prop);     
                    }
                }
            });
        }
    }
    else if (PropDef.type === MetaData.DataTypes.MetaImage) {
        if (Utils.IsObject(PropObject)) {
            const ImageType = Utils.Get(MetaItem, null, 'Property', GlobalState, State, 'ImageType', 'value');
            if (ImageType === 'gradient') {
                if (PropObject.TokenId) {
                    Utils.Set(ConvertedItem, PropObject.TokenId.value, Strings.STYLES, 'System', GlobalState, State, 'background');
                }
            }
            else {
                if (PropObject.ModelId) {
                    Utils.Set(ConvertedItem, PropObject.ModelId, Strings.PROPS, 'Variable', GlobalState, State, 'url');
                }
                else {
                    const source = Utils.JustGet(PropObject, null, 'Source', 'value');
                    if (source === 'token' && PropObject.TokenId) {
                        Utils.Set(ConvertedItem, PropObject.TokenId, Strings.PROPS, 'System', GlobalState, State, 'url');
                    }
                    else {
                        const url = Utils.JustGet(PropObject, null, 'url', 'value');
                        Utils.Set(ConvertedItem, url, Strings.PROPS, 'Static', GlobalState, State, 'url');
                    }
                }

                const SizeType = Utils.JustGet(PropObject, null, 'SizeType', 'value');
                if (SizeType === 'size') {
                    if (PropObject.Width) {
                        let width_value = ConvertValueDef({type : MetaData.DataTypes.size}, PropObject.Width, PublishContext);
                        if (Utils.IsNotNull(width_value)) {
                            if (width_value.ModelId) {
                                Utils.Set(ConvertedItem, width_value.ModelId,  'Props', 'Variable', GlobalState, State, 'backgroundSize', 'width'); 
                            }
                            else if (width_value.TokenId) {
                                Utils.Set(ConvertedItem, width_value.TokenId, 'Props', 'System', GlobalState, State, 'backgroundSize', 'width'); 
                            }
                            else {
                                Utils.Set(ConvertedItem, width_value.value, 'Props', 'Static', GlobalState, State, 'backgroundSize', 'width'); 
                            }
                        }
                    }
                    if (PropObject.Height) {
                        let height_value = ConvertValueDef({type : MetaData.DataTypes.size}, PropObject.Height, PublishContext);
                        if (Utils.IsNotNull(height_value)) {
                            if (height_value.ModelId) {
                                Utils.Set(ConvertedItem, height_value.ModelId,  'Props', 'Variable', GlobalState, State, 'backgroundSize', 'height'); 
                            }
                            else if (height_value.TokenId) {
                                Utils.Set(ConvertedItem, height_value.TokenId, 'Props', 'System', GlobalState, State, 'backgroundSize', 'height'); 
                            }
                            else {
                                Utils.Set(ConvertedItem, height_value.value, 'Props', 'Static', GlobalState, State, 'backgroundSize', 'height'); 
                            }
                        }
                    }
                }
                else if (SizeType) {
                    Utils.Set(ConvertedItem, SizeType, 'Styles', 'Static', GlobalState, State, 'backgroundSize');
                }
                
                const positionX = Utils.JustGet(PropObject, null, 'PositionX', 'value');
                if (positionX) {
                    Utils.Set(ConvertedItem, positionX, 'Styles', 'Static', GlobalState, State, 'backgroundPositionX');                                    
                }
                const positionY = Utils.JustGet(PropObject, null, 'PositionY', 'value');
                if (positionY) {
                    Utils.Set(ConvertedItem, positionY, 'Styles', 'Static', GlobalState, State, 'backgroundPositionY');                                    
                }
                const attachment = Utils.JustGet(PropObject, null, 'Attachment', 'value');
                if (attachment) {
                    Utils.Set(ConvertedItem, attachment, 'Styles', 'Static', GlobalState, State, 'backgroundAttachment');                                    
                }
                const clip = Utils.JustGet(PropObject, null, 'Clip', 'value');
                if (clip) {
                    Utils.Set(ConvertedItem, clip, 'Styles', 'Static', GlobalState, State, 'backgroundClip');                                    
                }
                const origin = Utils.JustGet(PropObject, null, 'Origin', 'value');
                if (origin) {
                    const originDef = MetaData.Styles.backgroundOrigin.values[origin];
                    if (originDef) 
                        Utils.Set(ConvertedItem, originDef.css || originDef.Id, 'Styles', 'Static', GlobalState, State, 'backgroundOrigin');
                }                                
                const repeat = Utils.JustGet(PropObject, null, 'Repeat', 'value');
                if (repeat) {
                    const repeatDef = MetaData.Styles.backgroundRepeat.values[repeat];
                    if (repeatDef) 
                        Utils.Set(ConvertedItem, repeatDef.css || repeatDef.Id, 'Styles', 'Static', GlobalState, State, 'backgroundRepeat');
                }  
            }                                                                                                                
        }
    }
    else if (PropDef.type === MetaData.DataTypes.MetaIcon) {
        if (Utils.IsObject(PropObject)) {
            if (PropObject.ModelId) {
                Utils.Set(ConvertedItem, PropObject.ModelId, Strings.PROPS, 'Variable', GlobalState, State, 'icon');
            }
            else {
                const source = Utils.JustGet(PropObject, null, 'Source', 'value');
                if (source === 'token' || PropObject.TokenId) {
                    const tokenId = Utils.JustGet(PropObject, null, 'TokenId');
                    Utils.Set(ConvertedItem, tokenId, Strings.PROPS, 'System', GlobalState, State, 'icon');
                }
                else if (source === 'online') {
                    const value = Utils.JustGet(PropObject, null, 'Info', 'value');
                    if (value) {
                        Utils.Set(ConvertedItem, value.provider, Strings.PROPS, 'Static', GlobalState, State, 'icon', 'provider');
                        if (value.provider === 'Iconfinder') {
                            Utils.Set(ConvertedItem, value.data, Strings.PROPS, 'Static', GlobalState, State, 'icon', 'data');                                            
                        }
                        else if (value.paths) {
                            Utils.Set(ConvertedItem, value.paths, Strings.PROPS, 'Static', GlobalState, State, 'icon', 'paths');                                            
                        }
                    }
                }
                else {
                 
                }
            }   
        }
    }
    else {
        if (!Utils.IsOneOf(ConvertedName, 'layout', FlexValues.FLEXDIRECTION, FlexValues.FLEXJUSTIFY, FlexValues.FLEXALIGN, FlexValues.FLEXALIGNCONTENT, FlexValues.FLEXWRAP)) {
            let Value = ConvertValueDef(PropDef, PropObject, PublishContext); 
            
            if (Utils.IsNotNull(Value)) {
                if (Value.ModelId) {
                    Utils.Set(ConvertedItem, Value.ModelId, 'Props', 'Variable', GlobalState, State, ConvertedName);     
                }
                else if (Value.TokenId) {
                    Utils.Set(ConvertedItem, Value.TokenId, 'Props', 'System', GlobalState, State, ConvertedName);     
                }
                else {
                    Utils.Set(ConvertedItem, Value.value, 'Props', 'Static', GlobalState, State, ConvertedName);     
                }
            }
        }                        
    }   
}

const PublishMetaItem = function({MetaItem, Id, PublishContext, RootItem, ParentOptions = {}, IsElementTemplate}) {
    if (!MetaItem  || !MetaItem.Type) {
        // TODO : Error
        return null;
    }
    let ConvertedItem = {
        Type : MetaItem.Type,
        Id : Id,
        Generic : MetaItem.Generic,
        Name : MetaItem.name,
        Styles : {
            Static : {                            
            },
            System : {

            },
            Variable : {

            }
        }
    };
    if (MetaItem.Type === 'Generic') {
        ConvertedItem.GenericType = MetaItem.GenericType;
    }

    if (MetaItem.Widget) {
        Utils.Set(ConvertedItem, MetaItem.Widget, 'Props', 'Static', 'Default', 'Default');
    }

    // TODO : do followings for each state defined in component
    const UseCssName = MetaData.Platforms.UseCssName(PublishContext.Platform);

    // Styles
    if (false)
    {
        if (false && MetaData.Components[MetaItem.Type]) {
            const MetaStyle = MetaData.Components[MetaItem.Type].Styles;
            if (MetaStyle) {
                Utils.ForEach(MetaItem.Style, (SystemStateStyle, SystemState) => {
                    Utils.ForEach(SystemStateStyle,( ItemStateStyle, State ) => {
                        if (ItemStateStyle) {
                            if (State === 'States') {
                                    
                            }
                            else {
                                MetaStyle.map((StyleName) => {       
                                    if (Utils.Has(ItemStateStyle, StyleName)) {                    
                                        const StyleObject =  Utils.JustGet(ItemStateStyle, null, StyleName);
                                        if (StyleObject) {
                                            ConvertStyleObject({
                                                State : State,
                                                GlobalState : SystemState,
                                                PublishContext : PublishContext,
                                                ConvertedItem : ConvertedItem,
                                                UseCssName : UseCssName,
                                                StyleObject : StyleObject,
                                                StyleName : StyleName,
                                                RootItem : RootItem,
                                                IsElementTemplate : IsElementTemplate
                                            });
                                        }
                                    }          
                                });                
                            }             
            
                            const StateStyles = Utils.JustGet(ConvertedItem.Styles, {}, 'Static', SystemState, State);
                            if (StateStyles) {
                                if (StateStyles.margin === '0px') {
                                    if (Utils.HasAny(StateStyles, 'marginLeft', 'marginTop', 'marginBottom', 'marginRight')) {
                                        delete StateStyles.margin;
                                    }
                                }
                                if (StateStyles.padding === '0px') {
                                    if (Utils.HasAny(StateStyles, 'paddingLeft', 'paddingTop', 'paddingBottom', 'paddingRight')) {
                                        delete StateStyles.padding;
                                    }
                                }      
                                if (StateStyles.position === 'absolute') {
                                    if (Utils.IsNotNull(StateStyles.left) && Utils.IsNotNull(StateStyles.right))
                                        delete StateStyles.width;
                                    if (Utils.IsNotNull(StateStyles.top) && Utils.IsNotNull(StateStyles.bottom))
                                        delete StateStyles.height;
                                }
                            }                          
                        }
                    });   
                });                     
            }
        }
        
        
        let isFlexContainer = false;
        let isGridContainer;
        Utils.ForEach(MetaItem.Property, (SystemStateStyle, SystemState) => {
            Utils.ForEach(SystemStateStyle, ( ItemStateProperties, State ) => {
                if (State !== 'States') {
                    const layout = Utils.JustGet(MetaItem, null, Strings.PROPERTY, SystemState, State, 'layout', 'value');                                
                    if (layout === MetaData.Properties.layout.values.Flex.Id)             
                    {            
                        isFlexContainer = true;
                        Utils.Set(ConvertedItem.Styles.Static, 'flex', SystemState, State, 'display');                              
                    }  
                    if (isFlexContainer) {
                        [FlexValues.FLEXDIRECTION, FlexValues.FLEXJUSTIFY, FlexValues.FLEXALIGN, FlexValues.FLEXALIGNCONTENT, FlexValues.FLEXWRAP]
                        .map((flexProp) => {
                            let flexValue = Utils.Get(MetaItem, null, Strings.PROPERTY, SystemState, State, flexProp, Strings.VALUE);
                            if (flexValue) 
                                Utils.Set(ConvertedItem.Styles.Static, MetaData.Properties.layout.values.Flex.convertValue(flexValue), SystemState, State, flexProp);
                        })
                    }
            
                    if (!RootItem) {
                        const alignSelf = MetaData.Properties.layout.values.Flex.convertValue(Utils.Get(MetaItem, null, Strings.PROPERTY, SystemState, State, FlexValues.FLEXALIGNSELF, Strings.VALUE));
                        if (alignSelf) {
                            Utils.Set(ConvertedItem.Styles.Static, alignSelf, SystemState, State, FlexValues.FLEXALIGNSELF);
                        }
                        const justifySelf = MetaData.Properties.layout.values.Flex.convertValue(Utils.Get(MetaItem, null, Strings.PROPERTY, SystemState, State, FlexValues.FLEXJUSTIFYSELF, Strings.VALUE));
                        if (justifySelf) {
                            Utils.Set(ConvertedItem.Styles.Static, justifySelf, SystemState, State, FlexValues.FLEXJUSTIFYSELF);
                        }
            
                        const GridPosition = Utils.Get(MetaItem, null, Strings.PROPERTY, SystemState, State, 'GridPosition', Strings.VALUE);    
                        if (GridPosition) {
                            Utils.Set(ConvertedItem.Styles.Static, GridPosition.ColStart, SystemState, State, 'gridColumnStart');
                            Utils.Set(ConvertedItem.Styles.Static, GridPosition.ColEnd, SystemState, State, 'gridColumnEnd');
                            Utils.Set(ConvertedItem.Styles.Static, GridPosition.RowStart, SystemState, State, 'gridRowStart');
                            Utils.Set(ConvertedItem.Styles.Static, GridPosition.RowEnd, SystemState, State, 'gridRowEnd');
                        }
                    }        
                    if (!layout || layout === MetaData.Properties.layout.values.CssGrid.Id) {            
                        const MetaLayout = Utils.Get(MetaItem, null, Strings.PROPERTY, SystemState, State, 'MetaLayout', Strings.VALUE);    
                        if (MetaLayout) {
                            if (MetaLayout.baseType === 'Grid' && MetaLayout.GridModel) {          
                                if (!isGridContainer) {
                                    isGridContainer = {};
                                }   
                                Utils.Set(ConvertedItem.Styles.Static, 'grid', SystemState, State, 'display');
                
                                GetGridItems(MetaLayout.GridModel, ConvertedItem.Styles.Static[SystemState][State], 'Columns', 'gridTemplateColumns', 'gridColumnGap');
                                GetGridItems(MetaLayout.GridModel, ConvertedItem.Styles.Static[SystemState][State], 'Rows', 'gridTemplateRows', 'gridRowGap');
                                GetGridItems(MetaLayout.GridModel, ConvertedItem.Styles.Static[SystemState][State], 'AutoColumns', 'gridAutoColumns');
                                GetGridItems(MetaLayout.GridModel, ConvertedItem.Styles.Static[SystemState][State], 'AutoRows', 'gridAutoRows');

                                const GridCellItems = Utils.JustGet(MetaLayout, [], 'Items');
                                const ConvertedCellItems = Utils.Get(isGridContainer, [], 'Items', SystemState, State);
                                Utils.ForEach(GridCellItems, (GridCell, i) => {
                                    ConvertedCellItems.push({
                                        ItemId : GridCell.ItemId,
                                        col_start : GridCell.col_start,
                                        col_end : GridCell.col_end,
                                        row_start : GridCell.row_start,
                                        row_end : GridCell.row_end
                                    })
                                });
                
                                ConvertedItem.Styles.Static[SystemState][State].justifyContent = Utils.Get(MetaLayout.GridModel, 'stretch', 'JustifyContent');
                                ConvertedItem.Styles.Static[SystemState][State].alignContent = Utils.Get(MetaLayout.GridModel, 'stretch', 'AlignContent');
                                ConvertedItem.Styles.Static[SystemState][State].justifyItems = MetaData.Properties.layout.values.Flex.convertValue(Utils.Get(MetaLayout.GridModel, 'stretch', 'justifyItems'));
                                ConvertedItem.Styles.Static[SystemState][State].alignItems = MetaData.Properties.layout.values.Flex.convertValue(Utils.Get(MetaLayout.GridModel, 'stretch', 'alignItems'));
                            }
                        }
                    }
                    
            
                    // Properties
                    if (ItemStateProperties) {
            
                        if (ItemStateProperties.Gradient) {
                            const gradientId = Utils.JustGet(ItemStateProperties.Gradient, null, 'TokenId', 'value');
                            if (gradientId) {
                                Utils.Set(ConvertedItem, gradientId, Strings.PROPS, 'System', SystemState, State, 'Gradient');
                            }
                        }                    
                        
                        Utils.ForEach(ItemStateProperties, (PropObject, PropName) => {
                            if (PropName !== 'MetaLayout') {
                                ConvertPropertyObject({
                                    State : State,
                                    GlobalState : SystemState,
                                    PublishContext : PublishContext,
                                    ConvertedItem : ConvertedItem,
                                    MetaItem : MetaItem,
                                    UseCssName : UseCssName,
                                    PropObject : PropObject,
                                    PropName : PropName,
                                    RootItem : RootItem,
                                    IsElementTemplate : IsElementTemplate
                                });
                            }
                        }); 
                    }
                }
                
            });   
        });  

        
            

        // Events        
        const Events = Utils.JustGet(MetaItem, [], 'Events');
        if (Array.isArray(Events)) {
            Events.map((EventDef, i) => {            
                if (EventDef.triggerId) {
                    const ConvertedEvent = {
                        name : MetaData.Events.GetHandler(EventDef.triggerId, PublishContext.Platform)
                    };
                    if (EventDef.disabled) {
                        Utils.ForEach(EventDef.disabled, (SystemStateValue, SystemState) => {
                            Utils.ForEach(SystemStateValue, (StateValue, State) => {
                                Utils.Set(ConvertedEvent, StateValue.value, 'disabled', SystemState, State);
                            });
                        });
                    }
                    if (EventDef.conditional && EventDef.conditionItems) {
                        ConvertedEvent.conditional = true;
                        const fnConvertCondition = (condition) => {
                            const convertedCondition = {};
                            if (condition) {             
                                if (condition.conditionId) {
                                    Utils.ForEach(condition.conditionId, (SystemStateValue, SystemState) => {
                                        Utils.ForEach(SystemStateValue, (StateValue, State) => {
                                            Utils.Set(convertedCondition, StateValue.value, 'ConditionId', SystemState, State);
                                        });
                                    });
                                }   
                                if (condition.runActionId) {
                                    Utils.ForEach(condition.runActionId, (SystemStateValue, SystemState) => {
                                        Utils.ForEach(SystemStateValue, (StateValue, State) => {
                                            Utils.Set(convertedCondition, StateValue.value, 'ActionId', SystemState, State);
                                        });
                                    });
                                }
                            }
                            return convertedCondition;
                        };
                        ConvertedEvent.conditionItems = {};
                        ConvertedEvent.conditionItems.if = fnConvertCondition(EventDef.conditionItems.if);
                        Utils.ForEach(EventDef.conditionItems.elseifs, (elseif, i) => {
                            const elseifs = Utils.Get(ConvertedEvent.conditionItems, [], 'elseifs');
                            if (elseif)
                                elseifs.push(fnConvertCondition(elseif));
                        });
                        ConvertedEvent.conditionItems.else = fnConvertCondition(EventDef.conditionItems.else);
                    }
                    else {
                        Utils.ForEach(EventDef.runActionId, (SystemStateValue, SystemState) => {
                            Utils.ForEach(SystemStateValue, (StateValue, State) => {
                                Utils.Set(ConvertedEvent, StateValue.value, 'ActionId', SystemState, State);
                            });
                        });
                    }
                    Utils.Set(ConvertedItem, ConvertedEvent, 'Events', EventDef.triggerId);
                }
            })
        }
        // Utils.ForEach(MetaItem.Events,( ItemStateEvents, State ) => {
        //     if (ItemStateEvents) {
        //         Utils.ForEach(ItemStateEvents, (ItemEvent, EventName) => {
        //             if (!ItemEvent.disabled) {
        //                 const eventData = {
        //                     name : MetaData.Events.GetHandler(EventName, PublishContext.Platform)
        //                 };
        
        //                 if (ItemEvent.conditional && ItemEvent.conditionItems) {
        //                     eventData.conditional = true;
        //                     eventData.conditionItems = ItemEvent.conditionItems;                    
        //                 }
        //                 else {
        //                     if (ItemEvent.runActionId) {
        //                         eventData.runActionId = ItemEvent.runActionId;                        
        //                     }  
        //                     else {
        //                         if (ItemEvent.fireEventId) {
        //                             eventData.fireEventId = ItemEvent.fireEventId;                            
        //                         }
        //                     }
        //                 }
        //                 Utils.Set(ConvertedItem, eventData, 'Events', State, EventName);
        //             }                
        //         })
        //     }
        // });

        let State = Strings.DEFAULT;

        // if (MetaItem.Type === MetaData.Components.Svg.Type) {                                                    
        //     const MetaSvg = Utils.Get(MetaItem, null, Strings.PROPERTY, State, 'Svg', 'MetaData', Strings.VALUE);
        //     if (MetaSvg) {
        //         const ConvertedMetaSvg = {
        //             RootItem : PublishSvgItem(
        //                 MetaSvg.MetaItems[MetaSvg.RootItem],
        //                 MetaSvg.RootItem,
        //                 {
        //                     Platform : PublishContext.Platform,
        //                     MetaItems : MetaSvg.MetaItems,
        //                     Handlers : PublishContext.Handlers,
        //                     ConsumedEvents : PublishContext.ConsumedEvents
        //                 }
        //             )
        //         };
                
        //         Utils.Set(ConvertedItem, ConvertedMetaSvg, Strings.PROPS, State, 'MetaSvg', Strings.VALUE);                           
        //     }
        // }
        // else 
        if (MetaItem.Type === MetaData.Components.Component.Type && false) {
            const MetaComponent = Utils.Get(MetaItem, null, Strings.PROPERTY, State, Strings.COMPONENT);
            if (MetaComponent) {
                if (MetaComponent.Id && MetaComponent.Id.value) {
                    Utils.Set(ConvertedItem, MetaComponent.Id.value, Strings.PROPS, State, Strings.COMPONENT, Strings.VALUE, 'Id');
                    if (MetaComponent.Variations && MetaComponent.Variations.value) {
                        Utils.ForEach(MetaComponent.Variations.value, (Var, StateId) => {
                            Utils.Set(ConvertedItem, Var.value, Strings.PROPS, State, Strings.COMPONENT, Strings.VALUE, 'Variations', StateId);
                        });
                    }
                }
            }
        }

        if (MetaItem.Patterns) {
            Utils.ForEach(MetaItem.Patterns, (SystemStatePatterns, SystemState) => {
                Utils.ForEach(SystemStatePatterns, (StatePatterns, State) => {
                    Utils.ForEach(StatePatterns, (StatePattern, PatternProp) => {
                        Utils.Set(ConvertedItem, StatePattern.Id, 'Patterns', SystemState, State, PatternProp);
                    });        
                });
            });
            
        }

        if (false && MetaItem.SubItems && PublishContext.MetaItems) {
            Utils.ForEach(MetaItem.SubItems, (SubItem, i) => {
                if (SubItem) {
                    const MetaSubItem = PublishContext.MetaItems[SubItem.Id];
                    if (MetaSubItem) {
                        const ConvertedSubItem = PublishMetaItem(MetaSubItem, SubItem.Id, PublishContext);
                        if (ConvertedSubItem) {
                            if (!ConvertedItem.SubItems)
                                ConvertedItem.SubItems = [];
                            ConvertedItem.SubItems.push(ConvertedSubItem);

                            if (isGridContainer) {
                                if (isGridContainer.Items) {
                                    Utils.ForEach(isGridContainer.Items, (ItemStates, SystemState) => {
                                        Utils.ForEach(ItemStates, (ItemStateCells, State) => {
                                            const ItemCellPosition = Utils.Find(ItemStateCells, (ItemCell) => {return ItemCell.ItemId === SubItem.Id});
                                            if (ItemCellPosition) {
                                                const Style = Utils.Get(ConvertedSubItem.Styles, {}, 'Static', SystemState, State);
                                                Utils.Set(Style, Utils.UseNull(ItemCellPosition.use_col_start, ItemCellPosition.col_start), 'gridColumnStart');
                                                Utils.Set(Style, Utils.UseNull(ItemCellPosition.use_col_end, ItemCellPosition.col_end), 'gridColumnEnd');
                                                Utils.Set(Style, Utils.UseNull(ItemCellPosition.use_row_start, ItemCellPosition.row_start), 'gridRowStart');
                                                Utils.Set(Style, Utils.UseNull(ItemCellPosition.use_row_end, ItemCellPosition.row_end), 'gridRowEnd');
                                            }
                                        });
                                    });
                                }
                            }
                        }                    
                    }
                }                        
            })
        }

        if (MetaItem.Generic) {
            // if (MetaItem.Type === 'RangeInput') {
            //     ConvertedItem.ThumbId = MetaItem.ThumbId;
            // }
        }
    }

    const isGridContainer = false;
    let isFlexContainer = false;
    
    const SetTokenId = (PropObject, PropName, SystemState, State, UsePropName) => {
        const Token = Utils.JustGet(PropObject, null, PropName);
        if (Token && Token.Id)
            Utils.Set(ConvertedItem, Token.Id, 'Tokens', SystemState, State, UsePropName || PropName);
    }
    const SetModelId = (PropObject, PropName, SystemState, State, UsePropName) => {
        const Model = Utils.JustGet(PropObject, null, PropName);
        if (Model && Model.Id) {
            Utils.Set(ConvertedItem, Model.Id, 'Models', SystemState, State, UsePropName || PropName);
            return Model.Id;
        }
    }
    const GetPropertyValue = (PropObject, PropName, SystemState, State, UsePropName) => {
        const Value = Utils.JustGet(PropObject, null,  PropName);
        if (Value) {
            return Value.value;
        }
    };
    const SetPropertyToStaticStyle = (PropObject, PropName, SystemState, State, UsePropName) => {
        const Value = Utils.JustGet(PropObject, null,  PropName);
        if (Value) {
            if (Value.value) {
                Utils.Set(ConvertedItem, Value.value, 'Styles', 'Static', SystemState, State, UsePropName || PropName);
                return Value.value;
            }
        }
    }
    const SetPropertyToStaticSize = (PropObject, PropName, SystemState, State, UsePropName) => {
        const Value = Utils.JustGet(PropObject, null,  PropName);
        if (Value) {
            if (Utils.IsNotNull(Value.value)) {
                const unit = Utils.JustGet(Value, 'px', 'Unit');
                Utils.Set(ConvertedItem, Utils.px(Value.value, unit), 'Styles', 'Static', SystemState, State, UsePropName || PropName);
            }
            if (Value.Reset) {
                Utils.Set(ConvertedItem, true, 'Reset', SystemState, State, UsePropName || PropName);
            }
        }
    }
    const SetPropertyToStaticProp = (PropObject, PropName, SystemState, State, UsePropName) => {
        const Value = Utils.JustGet(PropObject, null,  PropName);
        if (Value) {
            if (Utils.IsNotNull(Value.value)) {
                Utils.Set(ConvertedItem, Value.value, 'Props', 'Static', SystemState, State, UsePropName || PropName);
            }
        }
    }

    const isContainer = MetaData.Components.IsContainer(MetaItem);
    let ParentFlexDierction = {};
    let ParentDisplay = {};
    let hasBackgroundImage;
    
    Utils.ForEach(MetaItem.Tokens, (SystemStateStyle, SystemState) => {
        Utils.ForEach(SystemStateStyle, ( ItemStateProperties, State ) => {
            if (State !== 'States') {
                if (ItemStateProperties) {
                    SetTokenId(ItemStateProperties, 'backgroundColor', SystemState, State);
                    SetTokenId(ItemStateProperties, 'gradient', SystemState, State);
                    SetTokenId(ItemStateProperties, 'textGradient', SystemState, State);
                    SetTokenId(ItemStateProperties, 'color', SystemState, State);
                    SetTokenId(ItemStateProperties, 'fill', SystemState, State);
                    SetTokenId(ItemStateProperties, 'boxShadow', SystemState, State);
                    SetTokenId(ItemStateProperties, 'textShadow', SystemState, State);
                    SetTokenId(ItemStateProperties, 'textPattern', SystemState, State);
                    SetTokenId(ItemStateProperties, 'textContent', SystemState, State, 'text');
                    SetTokenId(ItemStateProperties, 'transform', SystemState, State);
                    SetTokenId(ItemStateProperties, 'filter', SystemState, State);

                    if (ItemStateProperties.backgroundImage && ItemStateProperties.backgroundImage.Id) {
                        Utils.Set(ConvertedItem, ItemStateProperties.backgroundImage.Id, 'Tokens', SystemState, State, 'url');                        
                        hasBackgroundImage = true;
                    }                    
                    if (ItemStateProperties.icon && ItemStateProperties.icon.Id) {
                        Utils.Set(ConvertedItem, ItemStateProperties.icon.Id, 'Tokens', SystemState, State, 'icon');
                    }
                    if (ItemStateProperties.textPattern && ItemStateProperties.textPattern.Id) {
                        Utils.Set(ConvertedItem, ItemStateProperties.textPattern.Id, 'Patterns', SystemState, State, 'text');
                    }          
                    
                    Utils.BorderStyles.Sides.map((side) => {
                        const propStyle = Utils.BorderStyles.propStyle(side);
                        const propColor = Utils.BorderStyles.propColor(side);
                        SetTokenId(ItemStateProperties, propStyle, SystemState, State);
                        SetTokenId(ItemStateProperties, propColor, SystemState, State);

                        const propPadding = Utils.BorderStyles.propPadding(side);
                        const propMargin = Utils.BorderStyles.propMargin(side);
                        SetTokenId(ItemStateProperties, propPadding, SystemState, State);
                        SetTokenId(ItemStateProperties, propMargin, SystemState, State);

                    });
                    Utils.BorderStyles.Corners.map((side) => {
                        const propRadius = Utils.BorderStyles.propRadius(side);
                        SetTokenId(ItemStateProperties, propRadius, SystemState, State);
                    });   

                    if (ItemStateProperties.GenericOptions) {
                        Utils.ForEach(ItemStateProperties.GenericOptions, (GenericValue, GenericProp) => {
                            if (GenericValue && GenericValue.Id)
                                Utils.Set(ConvertedItem, GenericValue.Id, 'Tokens', SystemState, State, 'GenericOptions', GenericProp);
                        });
                    }

                    if (ItemStateProperties.transitions) {
                        Utils.ForEach(ItemStateProperties.transitions, (transitionValue, transitionProp) => {
                            if (transitionValue && transitionValue.Id)
                                Utils.Set(ConvertedItem, transitionValue.Id, 'Tokens', SystemState, State, 'Transitions', transitionProp);
                        });
                    }
                }
            }
        });
    
    });

    // Model Bound Styles
    Utils.ForEach(MetaItem.Models, (SystemStateStyle, SystemState) => {
        Utils.ForEach(SystemStateStyle, ( ItemStateProperties, State ) => {
            if (ItemStateProperties) {                           
                SetModelId(ItemStateProperties, 'textContent', SystemState, State, 'text');
                if (SetModelId(ItemStateProperties, 'backgroundImage', SystemState, State)) {
                    hasBackgroundImage = true;
                }
                SetModelId(ItemStateProperties, 'icon', SystemState, State);
                SetModelId(ItemStateProperties, 'opacity', SystemState, State);
                SetModelId(ItemStateProperties, 'hidden', SystemState, State);
                SetModelId(ItemStateProperties, 'backgroundColor', SystemState, State);
                SetModelId(ItemStateProperties, 'color', SystemState, State);

                if (MetaItem.Type === MetaData.Components.Widget.Type) {
                    if (MetaItem.Widget) {
                        if (MetaItem.Widget.Type === MetaData.Components.Widget.WidgetTypes.MapBox.Type) {
                        }
                        else if (MetaItem.Widget.Type === MetaData.Components.Widget.WidgetTypes.Video.Type) {
                            SetModelId(ItemStateProperties, 'VideoUrl', SystemState, State);
                        }
                        else if (MetaItem.Widget.Type === MetaData.Components.Widget.WidgetTypes.Lottie.Type) {
                            SetModelId(ItemStateProperties, 'LottieUrl', SystemState, State);
                        }
                    }
                }
                else if (Utils.IsOneOf(MetaItem.Type, MetaData.Components.Div.Type, MetaData.Components.Image.Type)) {
                    ['customPositionX', 'customPositionY', 'backgroundWidth', 'backgroundHeight'].map((propName) => {
                        SetModelId(ItemStateProperties, propName, SystemState, State);
                    })                    
                }
            }
        });    
    });

    // Static Styles
    let CurrentParentFlexDirection, currentParentDisplay, currentDisplay;
    let currentFlex, currentAlignSelf;
    Utils.ForEach(MetaItem.Property, (SystemStateStyle, SystemState) => {
        Utils.ForEach(SystemStateStyle, ( ItemStateProperties, State ) => {
            if (State !== 'States') {
                if (ItemStateProperties) {       
                    if (ParentOptions.ParentFlexDierction) {
                        const flexDirection = Utils.JustGet(ParentOptions.ParentFlexDierction, null, SystemState, State);
                        if (flexDirection)
                            CurrentParentFlexDirection = Utils.IsFlexRow(flexDirection) ? 'row' : 'column';
                    }
                    if (ParentOptions.ParentDisplay) {
                        const ParentDisplay = Utils.JustGet(ParentOptions.ParentDisplay, null, SystemState, State); 
                        if (ParentDisplay)
                            currentParentDisplay = ParentDisplay;
                    }
                    if (isContainer) {
                        currentDisplay = Utils.UseNullOrEmpty(SetPropertyToStaticStyle(ItemStateProperties, 'display', SystemState, State) || currentDisplay, 'flex');
                        
                        if (currentDisplay === 'flex') {
                            isFlexContainer = true;
                            ['justifyContent', 'alignItems', 'flexWrap'].map((propName) => {
                                SetPropertyToStaticStyle(ItemStateProperties, propName, SystemState, State);        
                            })

                            
                            const ParentStateFlexDierction = SetPropertyToStaticStyle(ItemStateProperties, 'flexDirection', SystemState, State);        
                            if (ParentStateFlexDierction) {
                                Utils.Set(ParentFlexDierction, ParentStateFlexDierction, SystemState, State);
                            }
                        }
                        else if (currentDisplay === 'grid') {
                            const GridProp =  Utils.JustGet(ItemStateProperties, null, 'Grid');
                            const GridModel =  Utils.JustGet(GridProp, null, 'model', 'value');
                            if (GridModel) {
                                const grid_style = Utils.Get(ConvertedItem.Styles.Static, {}, SystemState, State);
                                GetGridItems(GridModel, grid_style, 'Columns', 'gridTemplateColumns');
                                GetGridItems(GridModel, grid_style, 'Rows', 'gridTemplateRows');
                                GetGridItems(GridModel, grid_style, 'AutoColumns', 'gridAutoColumns');
                                GetGridItems(GridModel, grid_style, 'AutoRows', 'gridAutoRows');
    
                                SetPropertyToStaticSize(GridProp, 'rowGap', SystemState, State);
                                SetPropertyToStaticSize(GridProp, 'columnGap', SystemState, State);
                                ['justifyContent', 'alignContent', 'justifyItems', 'alignItems'].map((propName) => {
                                    SetPropertyToStaticStyle(GridProp, propName, SystemState, State);        
                                })                                
                            }                            
                        }
                    }
                    

                    ['zIndex', 'overflowX', 'overflowY', 'backgroundColor'].map((propName) => {
                        SetPropertyToStaticStyle(ItemStateProperties, propName, SystemState, State);
                    })

                    const flex = SetPropertyToStaticStyle(ItemStateProperties, 'flex', SystemState, State);                    

                    currentAlignSelf = SetPropertyToStaticStyle(ItemStateProperties, 'alignSelf', SystemState, State) || currentAlignSelf;
                    SetPropertyToStaticStyle(ItemStateProperties, 'justifySelf', SystemState, State);

                    const opacity = Utils.JustGet(ItemStateProperties, null,  'opacity', 'value');
                    if (Utils.IsNotNullOrEmpty(opacity) && Utils.IsNumber(opacity)) {
                        Utils.Set(ConvertedItem, Math.min(1, Math.max(0, opacity / 100)), 'Styles', 'Static', SystemState, State, 'opacity');
                    }

                    const GridChildProps = Utils.JustGet(ItemStateProperties, null, 'GridChild');
                    if (GridChildProps) {
                        ['gridColumnStart', 'gridColumnEnd', 'gridRowStart', 'gridRowEnd'].map((gridChildPosition) => {
                            SetPropertyToStaticStyle(GridChildProps, gridChildPosition, SystemState, State);
                        })
                    }                    

                    MetaData.Styles.Paddings.map((paddingProp) => {
                        SetPropertyToStaticStyle(ItemStateProperties, paddingProp, SystemState, State);
                    })
                    MetaData.Styles.Margins.map((marginProp) => {
                        SetPropertyToStaticStyle(ItemStateProperties, marginProp, SystemState, State);
                    })

                    
                    SetPropertyToStaticProp(ItemStateProperties, 'fixedHeight', SystemState, State);
                    SetPropertyToStaticProp(ItemStateProperties, 'fixedWidth', SystemState, State);

                    SetPropertyToStaticProp(ItemStateProperties, 'hidden', SystemState, State);
                    SetPropertyToStaticProp(ItemStateProperties, 'textContent', SystemState, State, 'text');

                    Utils.SizeProps.map((sizeName) => {
                        SetPropertyToStaticSize(ItemStateProperties, sizeName, SystemState, State);
                    });

                    if (currentAlignSelf === 'stretch') {
                        if (CurrentParentFlexDirection === 'row' || currentParentDisplay === 'grid') {
                            Utils.Set(ConvertedItem, 'auto','Styles', 'Static', SystemState, State, 'height');
                        }
                        else if (CurrentParentFlexDirection === 'column' || currentParentDisplay === 'grid') {
                            Utils.Set(ConvertedItem, 'auto', 'Styles', 'Static', SystemState, State, 'width');        
                        }
                    }
                    if (flex) {
                        if (CurrentParentFlexDirection === 'row') {
                            Utils.Set(ConvertedItem, 'auto', 'Styles', 'Static', SystemState, State, 'width');        
                        }
                        else if (CurrentParentFlexDirection === 'column') {
                            Utils.Set(ConvertedItem, 'auto','Styles', 'Static', SystemState, State, 'height');
                        }
                    }
                    
                    ['textAlign', 'fontStyle', 'textDecoration', 'textTransform'].map((prop) => {
                        SetPropertyToStaticStyle(ItemStateProperties, prop, SystemState, State);
                    })

                    Utils.BorderStyles.Sides.map((side) => {
                        const propStyle = Utils.BorderStyles.propStyle(side);
                        const propSize = Utils.BorderStyles.propSize(side);
                        const propColor = Utils.BorderStyles.propColor(side);
                        SetPropertyToStaticStyle(ItemStateProperties, propStyle, SystemState, State);
                        SetPropertyToStaticStyle(ItemStateProperties, propColor, SystemState, State);
                        SetPropertyToStaticSize(ItemStateProperties, propSize, SystemState, State);
                    });
                    Utils.BorderStyles.Corners.map((side) => {
                        const propRadius = Utils.BorderStyles.propRadius(side);
                        SetPropertyToStaticSize(ItemStateProperties, propRadius, SystemState, State);
                    });

                    const position = SetPropertyToStaticStyle(ItemStateProperties, 'position', SystemState, State);
                    // if (position && (position === 'absolute' || position === 'fixed')) 
                    {
                        ['top', 'left', 'right', 'bottom'].map((prop) => {
                            SetPropertyToStaticSize(ItemStateProperties, prop, SystemState, State);
                        })
                    }

                    if (MetaItem.Type === MetaData.Components.Widget.Type) {
                        if (MetaItem.Widget) {
                            if (MetaItem.Widget.Type === MetaData.Components.Widget.WidgetTypes.MapBox.Type) {
                                Utils.ForEach(MetaData.Components.Widget.WidgetTypes.MapBox.Options, (option, name) => {
                                    SetPropertyToStaticProp(ItemStateProperties, name, SystemState, State);
                                });
                            }
                            else if (MetaItem.Widget.Type === MetaData.Components.Widget.WidgetTypes.Video.Type) {
                                SetPropertyToStaticProp(ItemStateProperties, 'VideoSource', SystemState, State);
                                SetPropertyToStaticProp(ItemStateProperties, 'VideoUrl', SystemState, State);
                            }
                            else if (MetaItem.Widget.Type === MetaData.Components.Widget.WidgetTypes.Lottie.Type) {
                                SetPropertyToStaticProp(ItemStateProperties, 'speed', SystemState, State);
                                SetPropertyToStaticProp(ItemStateProperties, 'LottieUrl', SystemState, State);
                            }
                            else if (MetaItem.Widget.Type === MetaData.Components.Widget.WidgetTypes.ApexCharts.Type) {
                                Utils.ForEach(MetaData.Components.Widget.WidgetTypes.ApexCharts.Options, (option, name) => {
                                    SetPropertyToStaticProp(ItemStateProperties, name, SystemState, State);
                                });
                            }
                            else if (MetaItem.Widget.Type === MetaData.Components.Widget.WidgetTypes.eCharts.Type) {
                                Utils.ForEach(MetaData.Components.Widget.WidgetTypes.eCharts.Options, (option, name) => {
                                    SetPropertyToStaticProp(ItemStateProperties, name, SystemState, State);
                                });
                            }
                        }
                    }
                    else if (MetaItem.Type === MetaData.Components.Component.Type) {
                        const ComponentInfo = Utils.JustGet(ItemStateProperties, {}, 'Component', 'value');
                        if (ComponentInfo.Id) {
                            Utils.Set(ConvertedItem, ComponentInfo.Id, 'Props', 'Static', SystemState, State, 'Id');                            

                            if (MetaItem.Slots) {
                                Utils.Set(ConvertedItem, Utils.DeepClone(MetaItem.Slots), 'Slots');
                            }
                            if (MetaItem.Slides) {
                                Utils.Set(ConvertedItem, Utils.DeepClone(MetaItem.Slides), 'Slides');
                            }
                        }
                        if (ComponentInfo.Models) {
                            Utils.ForEach(ComponentInfo.Models, (modelValue, modelId) => {
                                Utils.Set(ConvertedItem, modelValue.Type, 'Props', 'Static', SystemState, State, 'Models', modelId, 'Type');
                                if (modelValue.ModelId) {
                                    Utils.Set(ConvertedItem, modelValue.ModelId, 'Props', 'Static', SystemState, State, 'Models', modelId, 'ModelId');
                                }
                                else if (modelValue.TokenId) {
                                    Utils.Set(ConvertedItem, modelValue.TokenId, 'Props', 'Static', SystemState, State, 'Models', modelId, 'TokenId');
                                }
                                else if (Utils.IsNotNull(modelValue.value)) {
                                    Utils.Set(ConvertedItem, modelValue.value, 'Props', 'Static', SystemState, State, 'Models', modelId, 'value');
                                }
                                else if (modelValue.MockupId) {
                                    if (PublishContext.GetMockupValue) {                                        
                                        Utils.Set(ConvertedItem, 
                                            PublishContext.GetMockupValue({
                                                ComponentId : ComponentInfo.Id, 
                                                RecordId : modelValue.MockupId, 
                                                ModelId : modelId                                               
                                            }), 
                                            'Props', 'Static', SystemState, State, 'Models', modelId, 'value');
                                    }                                    
                                }

                                if (modelValue.Options) {
                                    Utils.Set(ConvertedItem, modelValue.Options, 'Props', 'Static', SystemState, State, 'Models', modelId, 'options');
                                }
                            });
                        }
                        if (ComponentInfo.Variations) {
                            Utils.Set(ConvertedItem, Utils.DeepClone(ComponentInfo.Variations), 'Props', 'Static', SystemState, State, 'Variations');
                        }
                        
                    }
                    else if (MetaItem.Type === MetaData.Components.Text.Type) {
                        const TextContent = Utils.JustGet(ItemStateProperties, {}, 'textContent', 'value', 'Parameters');
                        // TODO
                    }
                    else if (ItemStateProperties.GenericOptions) {
                        Utils.ForEach(ItemStateProperties.GenericOptions, (GenericValue, GenericProp) => {
                            if (Utils.IsNotNull(GenericValue.value))
                            Utils.Set(ConvertedItem, GenericValue.value, 'Props', 'Static', SystemState, State, 'GenericOptions', GenericProp);
                        });
                    }

                    if (hasBackgroundImage) {
                        let positionX = GetPropertyValue(ItemStateProperties, 'positionX', SystemState, State) || 'center';
                        let positionY = GetPropertyValue(ItemStateProperties, 'positionY', SystemState, State) || 'center';
                        if (positionX === 'custom') {
                            positionX = GetPropertyValue(ItemStateProperties, 'customPositionX', SystemState, State) || 'center';
                            if (positionX !== 'center')
                                positionX += '%';                      
                        }
                        if (positionY === 'custom') {
                            positionY = GetPropertyValue(ItemStateProperties, 'customPositionY', SystemState, State) || 'center';
                            if (positionY !== 'center')
                                positionY += '%';                      
                        }
                        Utils.Set(ConvertedItem, positionX, 'Props', 'Static', SystemState, State, 'positionX');
                        Utils.Set(ConvertedItem, positionY, 'Props', 'Static', SystemState, State, 'positionY');

                        const backgroundSize = GetPropertyValue(ItemStateProperties, 'backgroundSize', SystemState, State) || 'cover';
                        if (backgroundSize === 'custom') {
                            let backgroundWidth = GetPropertyValue(ItemStateProperties, 'backgroundWidth', SystemState, State) || 'auto';
                            if (backgroundWidth !== 'auto')
                                backgroundWidth += '%';                    
                            let backgroundHeight = GetPropertyValue(ItemStateProperties, 'backgroundHeight', SystemState, State) || 'auto';                            
                            if (backgroundHeight !== 'auto')
                                backgroundHeight += '%';
                            
                            Utils.Set(ConvertedItem, backgroundWidth, 'Props', 'Static', SystemState, State, 'backgroundWidth');
                            Utils.Set(ConvertedItem, backgroundHeight, 'Props', 'Static', SystemState, State, 'backgroundHeight');
                        }
                        else
                            Utils.Set(ConvertedItem, backgroundSize || 'auto', 'Styles', 'Static', SystemState, State, 'backgroundSize');
                    }
                }
            }
        });    
    });

    // Static Styles : These styles will be read from MetaItem.Property
    Utils.ForEach(MetaItem.Style, (SystemStateStyle, SystemState) => {
        Utils.ForEach(SystemStateStyle, ( ItemStateProperties, State ) => {
            if (State !== 'States') {
                if (ItemStateProperties) {                           

                }
            }
        });    
    });    

    // Utils.Set(ConvertedItem, 40, 'Props', 'Static', 'Default', 'Default', 'height');
    // Utils.Set(ConvertedItem, 200, 'Props', 'Static', 'Default', 'Default', 'width');
    
    if (MetaItem.SubItems && PublishContext.MetaItems) {
        Utils.ForEach(MetaItem.SubItems, (SubItem, i) => {
            if (SubItem) {
                const MetaSubItem = PublishContext.MetaItems[SubItem.Id];
                if (MetaSubItem) {
                    const ConvertedSubItem = PublishMetaItem({
                        MetaItem : MetaSubItem, 
                        Id : SubItem.Id, 
                        PublishContext : PublishContext,
                        ParentOptions : {
                            ParentFlexDierction : ParentFlexDierction,
                            ParentDisplay : ParentDisplay
                        }

                    });
                    if (ConvertedSubItem) {
                        if (!ConvertedItem.SubItems)
                            ConvertedItem.SubItems = [];
                        ConvertedItem.SubItems.push(ConvertedSubItem);

                        if (isGridContainer) {
                            if (isGridContainer.Items) {
                                Utils.ForEach(isGridContainer.Items, (ItemStates, SystemState) => {
                                    Utils.ForEach(ItemStates, (ItemStateCells, State) => {
                                        const ItemCellPosition = Utils.Find(ItemStateCells, (ItemCell) => {return ItemCell.ItemId === SubItem.Id});
                                        if (ItemCellPosition) {
                                            const Style = Utils.Get(ConvertedSubItem.Styles, {}, 'Static', SystemState, State);
                                            Utils.Set(Style, Utils.UseNull(ItemCellPosition.use_col_start, ItemCellPosition.col_start), 'gridColumnStart');
                                            Utils.Set(Style, Utils.UseNull(ItemCellPosition.use_col_end, ItemCellPosition.col_end), 'gridColumnEnd');
                                            Utils.Set(Style, Utils.UseNull(ItemCellPosition.use_row_start, ItemCellPosition.row_start), 'gridRowStart');
                                            Utils.Set(Style, Utils.UseNull(ItemCellPosition.use_row_end, ItemCellPosition.row_end), 'gridRowEnd');
                                        }
                                    });
                                });
                            }
                        }
                        else {
                        }
                    }                    
                }
            }                        
        })
    }


    return ConvertedItem;

    
}

export default PublishMetaItem;