import React from 'react';
import BaseItem from './BaseItem';
import {Utils} from '../importer';

export default class Slot extends BaseItem
{
    constructor(props) {
        super(props);
    }    
    renderItem({style, props, events, rest}) {        
        let content;
        if (this.props.renderSlot)
            content = this.props.renderSlot({slotId : this.props.Id, containerStyle : style});
        return (
            <div 
                ref={(r) => this.Ref_Instance = r}
                style={style}
                {...events}
                {...rest}
            >
                {content}
            </div>
        );
    }
}