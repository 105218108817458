import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    AppState,
    Events,
    SC,
    Loading,
    Globals,
    Strings,
    Links,
    Utils,
    UIUtils,
    MetaData,
    AppLayout
} from '../../../../../../../importer';

import { TokenPanelHeader, ItemNameEditor } from '../../../../left/designsystem/common';
import { Seperator, StyleGroupTokenItem, StyleGroupTitle } from '../../styleitems/common';
import ContentAlignments from '../../styleitems/layout/grid/designer/contentAlignment';
import ItemAlignments from '../../styleitems/layout/grid/designer/itemAlignmentAndGap';
import TemplateDimension from '../../styleitems/layout/grid/designer/templateDimension';
import Switch from '../../../../../../../components/editors/Switch';
import { GridStyleNames, NotStatefullGridStyleNames } from '../../styleitems/layout';
import GridTemplatelist from './templates';
import { AnimatePresence, motion } from 'framer-motion';

export default class GridDesignerPanel extends ReactBaseComponent
{
    constructor(props) {
        super(props);    

        this.SttartFromScratch = this.SttartFromScratch.bind(this);
        this.StartFromTemplate = this.StartFromTemplate.bind(this);

        this.SetProperty = this.SetProperty.bind(this);
        this.GetProperty = this.GetProperty.bind(this);
        this.GetOption = this.GetOption.bind(this);
        this.SaveModel = this.SaveModel.bind(this);
        this.ToggleShowAdvanced = this.ToggleShowAdvanced.bind(this);

        this.ShowTemplates = this.ShowTemplates.bind(this);
        this.SaveAsTemplate = this.SaveAsTemplate.bind(this);
        this.GetTemplateItems = this.GetTemplateItems.bind(this);
        this.ApplyTemplate = this.ApplyTemplate.bind(this);

        AppLayout.Refs.Inspectors.GridDesigner = this;

        let isInitialized = this.GetOption('initialized', false);
        if (!isInitialized) {
            const templateIds = Globals.ProjectManager.Templates.GridTemplates();        
            if (templateIds.length === 0) {
                this.SttartFromScratch();
            }
        }
        if (!this.GridModel) 
            this.Load(this.props);
    }    
    componentWillUnmount() {
        super.componentWillUnmount();
        AppLayout.Refs.Inspectors.GridDesigner = null;
    }
    Load(props) {
        this.GridModel = {
        };
        GridStyleNames.map((styleName) => {
            this.GridModel[styleName] = this.GetProperty(styleName);
        });
        this.GridModel.model = this.GetProperty('model', {});
    }
    Refresh() {
        this.Load(this.props);
        this.RCUpdate();
    }
    ToggleShowAdvanced() {
        this.showAdvancedEditors = !this.showAdvancedEditors;
        this.RCUpdate();
    }
    GetProperty(prop, defaultValue, NotStatefull) {
        return Utils.DeepClone(this.props.GetComponentManager().GetItemPropertyValue({
            MetaItem : this.props.GetMetaItem(),
            PropertyName : prop,
            ParentNames : ['Grid'],
            DefaultValue : defaultValue,
            NotStatefull : NotStatefull
        }));
    }
    GetOption(prop, defaultValue) {
        return Utils.JustGet(this.props.gridOptions, defaultValue, prop, 'Value');
    }
    SetProperty(prop, value, NotStatefull) {
        this.GridModel[prop] = value;

        this.props.GetComponentManager().SetItemPropertyValue({
            MetaItem : this.props.GetMetaItem(),
            Id : this.props.Id,
            PropertyName : prop,
            Value : value,
            ParentNames : ['Grid'],
            NotStatefull : NotStatefull
        });
        AppLayout.Refs.SelectedGridDesigner && AppLayout.Refs.SelectedGridDesigner.UpdateGridStyle(true);
        this.RCUpdate();
    }
    SttartFromScratch() {
        this.props.onSetPropertyValues([
            {
                PropertyName : 'initialized',
                Value : true,
                NotStatefull : true,
                PropParentNames : ['gridOptions'],
                ParentNames : ['Grid'],
            },
            {
                PropertyName : 'showGridLines',
                Value : true,
                NotStatefull : true,
                PropParentNames : ['gridOptions'],
                ParentNames : ['Grid'],
            },
            {
                PropertyName : 'model',
                PropParentNames : ['gridOptions'],
                ParentNames : ['Grid'],
                Value : MetaData.ItemInitializer.GetGridLayout().GridModel
            },
            {
                PropertyName : 'rowGap',
                Value : 20,
                PropParentNames : ['gridOptions'],
                ParentNames : ['Grid'],
            },
            {
                PropertyName : 'columnGap',
                Value : 20,
                PropParentNames : ['gridOptions'],
                ParentNames : ['Grid'],
            },
        ]);
        this.Load(this.props);
    }
    StartFromTemplate(templateId) {
        const templateModel = Globals.ProjectManager.Templates.Template(templateId);
        let template = Utils.JustGet(templateModel, null, 'value', 'Default', 'value');
        if (!template)
            template = MetaData.ItemInitializer.GetGridLayout().GridModel;

        const changes = [
            {
                PropertyName : 'initialized',
                Value : true,
                NotStatefull : true,
                PropParentNames : ['gridOptions'],
                ParentNames : ['Grid'],
            },
            {
                PropertyName : 'showGridLines',
                Value : true,
                NotStatefull : true,
                PropParentNames : ['gridOptions'],
                ParentNames : ['Grid'],
            },
            {
                PropertyName : 'model',
                PropParentNames : ['gridOptions'],
                ParentNames : ['Grid'],
                Value : template.model
            }
        ];
        this.GridModel = {
            model : template.model
        }

        GridStyleNames.map((styleName) => {
            if (Utils.IsNotNull(template[styleName])) {
                this.GridModel[styleName] = template[styleName];
                changes.push({
                    PropertyName : styleName,
                    Value : template[styleName],
                    PropParentNames : ['gridOptions'],
                    ParentNames : ['Grid']
                })
            }
        })
        
        this.props.onSetPropertyValues(changes);
    }
    SaveModel() {
        this.SetProperty('model', this.GridModel.model);        
    }
    SetOption(name, value, NotStatefull) {
        this.props.onSetPropertyValues([{
            PropertyName : name,
            Value : value,
            NotStatefull : NotStatefull,
            PropParentNames : ['gridOptions'],
                ParentNames : ['Grid'],
        }]);
    }

    ShowTemplates() {
        if (this.ShowTemplateList) {
            delete this.ShowTemplateList;
        }
        else {
            this.ShowTemplateList = {

            };
        }        
        this.RCUpdate();
    }
    ApplyTemplate(templateId) {
        this.StartFromTemplate(templateId);
        delete this.ShowTemplateList;
        this.RCUpdate();
    }
    SaveAsTemplate() {
        if (!this.ShowSaveAsTemplate) {
            this.ShowSaveAsTemplate = {
                step : 1
            };
        }
        else {
            if (this.ShowSaveAsTemplate.step === 1) {
                Globals.ProjectManager.Templates.Add({
                    type : Globals.ProjectManager.Templates.Types.Grid,
                    name : this.ShowSaveAsTemplate.name,
                    value : this.GridModel
                });
                delete this.ShowSaveAsTemplate;
            }
        }
        
        this.RCUpdate();
    }
    GetTemplateItems() {
        return []; // Return Grid Templates
    }

    renderCustom() {
        let isInitialized = this.GetOption('initialized', false);
        const templateIds = Globals.ProjectManager.Templates.GridTemplates();        
        const hasTemplates = templateIds.length > 0;

        if (!isInitialized) {
            return (
                <SC.FCol f1>
                    <TokenPanelHeader title={'GRID DESIGNER'} onClose={this.props.onClose} style={{padding : '2px'}} />
                    <SC.FCol f1 style={{backgroundColor : SC.CurrentTheme.theme.back, padding : '8px'}}>
                        <SC.Buttons.RoundButton xsmall onClick={this.SttartFromScratch} style={{width : 'unset', minWidth : '80px', paddingLeft : '8px', paddingRight : '8px', fontSize : '11px', margin : '8px'}}>
                            DESIGN FROM SCRATCH
                        </SC.Buttons.RoundButton>
                        <SC.FCol alc style={{fontSize : '11px', lineHeight : '24px'}}>
                            <div>OR</div>                            
                            <div>SELECT FROM TEMPLATES BELOW</div>    
                        </SC.FCol>
                        <Seperator />
                        <SC.CustomScrollbars hideTracksWhenNotNeeded autoHide>
                            <GridTemplatelist 
                                onSelect={this.StartFromTemplate}
                            />
                        </SC.CustomScrollbars>
                    </SC.FCol>
                </SC.FCol>
            )
        }
        let showTemplates;
        if (this.ShowTemplateList) {
            showTemplates = (
                <SC.FCol f1>
                    <TokenPanelHeader title={'SELECT GRID TEMPLATE'} onClose={this.ShowTemplates} style={{padding : '2px'}} />
                    <SC.FCol f1 style={{backgroundColor : SC.CurrentTheme.theme.back, padding : '8px'}}>
                        <SC.CustomScrollbars hideTracksWhenNotNeeded autoHide>
                            <GridTemplatelist 
                                onSelect={this.ApplyTemplate}
                            />
                        </SC.CustomScrollbars>
                    </SC.FCol>
                </SC.FCol>
            )
        }

        const ShowGridLines = this.GetProperty('showGridLines', true, true);
        return (
            <SC.FCol f1>
                <TokenPanelHeader title={'GRID DESIGNER'} onClose={this.props.onClose} style={{padding : '2px'}} />
                <SC.FCol f1 style={{backgroundColor : SC.CurrentTheme.theme.back, padding : '8px'}}>
                    <SC.CustomScrollbars hideTracksWhenNotNeeded autoHide>
                        <SC.FCol>
                            <SC.FRow alc jsb>
                                <div style={{marginRight : '8px', fontSize : '11px'}}>Show Grid Lines on Canvas</div>
                                <Switch small value={ShowGridLines} onChange={this.SetOption.bind(this, 'showGridLines', !ShowGridLines, true)} />
                            </SC.FRow>
                            <ContentAlignments 
                                onChange={this.SetProperty}
                                justifyContent={this.GridModel.justifyContent}
                                alignContent={this.GridModel.alignContent}
                            />
                            <ItemAlignments 
                                onChange={this.SetProperty}
                                onDelete={this.DeleteProperty}
                                justifyItems={this.GridModel.justifyItems}
                                alignItems={this.GridModel.alignItems}
                                rowGap={this.GridModel.rowGap}
                                columnGap={this.GridModel.columnGap}
                            />
                            <SC.FRow alc justifyEnd style={{marginTop : '8px'}}>
                                <div style={{marginRight : '8px', fontSize : '11px'}}>Advanced Grid Options</div>
                                <Switch small value={this.showAdvancedEditors} onChange={this.ToggleShowAdvanced} />
                            </SC.FRow>
                            <Seperator />
                            <TemplateDimension templateName='Columns' singleLabel='Column' headerLabel='COLUMNS' model={this.GridModel.model} SaveModel={this.SaveModel} advancedOptions={this.showAdvancedEditors} />
                            <Seperator />
                            <TemplateDimension templateName='Rows' noMargin={!this.showAdvancedEditors} singleLabel='Row' headerLabel='ROWS' model={this.GridModel.model} SaveModel={this.SaveModel} />            
                            
                            {
                                this.showAdvancedEditors && 
                                <React.Fragment>
                                    <Seperator />
                                    <TemplateDimension auto noMargin templateName='AutoColumns' singleLabel='Column' headerLabel='AUTO COLUMNS' model={this.GridModel.model} SaveModel={this.SaveModel} />
                                    <Seperator />
                                    <TemplateDimension auto templateName='AutoRows' singleLabel='Row' headerLabel='AUTO ROWS' model={this.GridModel.model} SaveModel={this.SaveModel} />
                                </React.Fragment>
                            }
                        </SC.FCol>
                    </SC.CustomScrollbars>       
                    {
                        !this.ShowSaveAsTemplate &&
                        <SC.FCol>
                            <SC.Buttons.RoundButton xsmall onClick={this.SaveAsTemplate} style={{width : 'unset', minWidth : '80px', paddingLeft : '8px', paddingRight : '8px', fontSize : '11px', marginTop : '8px'}}>
                                SAVE AS GRID TEMPLATE
                            </SC.Buttons.RoundButton>
                            {
                                hasTemplates && 
                                <SC.Buttons.RoundButton xsmall onClick={this.ShowTemplates} style={{width : 'unset', minWidth : '80px', paddingLeft : '8px', paddingRight : '8px', fontSize : '11px', marginTop : '8px'}}>
                                    REPLACE WITH TEMPLATE
                                </SC.Buttons.RoundButton> 
                            }                            
                        </SC.FCol>                        
                    }                            
                    {
                        this.ShowSaveAsTemplate && 
                        <SC.FCol style={{margin : '-8px', marginTop : '8px', backgroundColor : SC.CurrentTheme.theme.back_lighter}}>
                            <TokenPanelHeader 
                                notBackClosable
                                title={<div style={{paddingLeft : '4px'}}>SAVE AS TEMPLATE</div>} 
                                hasAddCancel
                                onClose={this.onCancelAddToken} 
                                onCancel={() => {
                                    delete this.ShowSaveAsTemplate;
                                    this.RCUpdate();
                                }} 
                                onAdd={this.SaveAsTemplate} 
                            />
                            <SC.FCol style={{padding : '8px', paddingBottom : '24px', backgroundColor : SC.CurrentTheme.theme.back}}>
                                <ItemNameEditor
                                    noMargin
                                    fontSize='12px'                        
                                    name={this.ShowSaveAsTemplate.name}
                                    onSaveName={(name) => {this.ShowSaveAsTemplate.name = name; this.RCUpdate();}}
                                    model={this.ShowSaveAsTemplate}
                                    onGetItems={this.GetTemplateItems} 
                                />  
                            </SC.FCol>
                        </SC.FCol>
                    }     
                </SC.FCol>
                <AnimatePresence>
                    {
                        this.ShowTemplateList && 
                        <motion.div 
                            style={{...SC.Styles.Absolute, zIndex : 10000, backgroundColor : SC.CurrentTheme.theme.back_lighter, display : 'flex', flexDirection : 'column'}}
                            initial={{opacity : 0.7, x : 24}}
                            animate={{opacity : 1, x : 0}}
                            exit={{opacity : 0, x : 24}}
                            transition={{duration : 0.2}}
                        >
                            {showTemplates}
                        </motion.div>
                    }
                </AnimatePresence>
            </SC.FCol>
        )
    }
}