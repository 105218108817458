import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    AppLayout,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../../../../../importer';

import { GetShadowTokenList, GetShadowTokenListOfIds, GetShadowTokenItem } from '../../../../../panels/left/designsystem/shadows';
import { GetTokenAliaseListOfIds, GetTokenAliaseList } from '../../../../../panels/left/designsystem/aliases';


export default class DocumentToken_Colors extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {

        };    
    }
    shouldComponentUpdate(nextProps, nextState) {

        if (this.state.ShouldUpdate !== nextState.ShouldUpdate)
            return true;
        
        if (Utils.HasAnyChange(this.props, nextProps, 'GlobalThemeId', 'GlobalState', 'StyleId', 'RenderId', 'LoadId', 'themeType', 'states', 'ReversedStyleState', 'isAliase'))
            return true;
        
        return false;
    }
    componentDidUpdate(prevProps, prevState) {
        this.state.ShouldUpdate = false;
    }
    render() {       
        let shadows; 
        if (this.props.isAliase) {
            const aliases = this.props.selection.type === 'selected' ? GetTokenAliaseListOfIds(this.props.selection.tokenIds, this.props.ReversedStyleState) : GetTokenAliaseList(Globals.ProjectManager.Tokens.Types.Shadows , this.props.ReversedStyleState);
            shadows = { All : [] };
            Utils.ForEach(aliases, (aliase, i) => {
                const tokenItem = GetShadowTokenItem({
                    id : aliase.tokenId,
                    token : Globals.ProjectManager.Tokens.Token(aliase.tokenId)                    
                })
                shadows.All.push({
                    id : aliase.id,
                    name : aliase.name,
                    value : tokenItem.value,
                    textShadow : tokenItem.textShadow
                })
            });
        }
        else {
            shadows = this.props.selection.type === 'selected' ? GetShadowTokenListOfIds(this.props.selection.tokenIds, this.props.ReversedStyleState) : GetShadowTokenList(this.props.ReversedStyleState);
        }

        const style_shadow = {
            marginBottom : '8px', padding : '32px', flex : 1, borderRadius : '2px', fontSize : '24px', color : '#fff', textAlign : 'center', lineHeight : '40px', fontWeight : 500, maxWidth : AppLayout.Media.Small ? 'unset' : '200px'
        }
        const shadowItems = [];
        Utils.ForEach(shadows.All, (shadow, ) => {
            shadowItems.push(
                <React.Fragment>                    
                    {
                        shadow.textShadow ? 
                        <div style={{...style_shadow, textShadow : shadow.value}}>
                            Text Shadow
                        </div> : 
                        <div style={{...SC.GetGridBackgroundStyle(false, shadow.dark),...style_shadow}}>
                            <div style={{backgroundColor : shadow.dark ? '#2d2e32' : '#fafafa', flex : 1, borderRadius : '4px', boxShadow : shadow.value, width : '100%', height : '80px'}} />
                        </div>       
                    }
                    <SC.FCol style={{marginBottom : '24px'}}>
                        <div style={{fontFamily : 'monospace'}}>{shadow.name}</div>
                        <div style={{fontFamily : 'monospace'}}>{shadow.value}</div>    
                    </SC.FCol>          
                </React.Fragment>
            )

            if (this.props.forTemplateSelect && shadowItems.length === 2) {
                return false;
            }
        });          

        return (
            <div style={{
                display : 'grid',
                gridColumnGap : '48px',
                gridTemplateColumns : AppLayout.Media.Small ? 'repeat(auto-fill, minmax(280px, 1fr))' : '240px  auto',
                gridAutoRows : 'auto',
                justifyContent : 'start',
                alignItems : 'start',
                alignSelf : this.props.alignment,
            }}
            >
                {shadowItems}
            </div>
        )  
    }
}