import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    AppLayout,
    Utils,
    Events,
    Strings,
    Globals
} from '../../../../../../../../importer';
       
import {ItemNameEditor, TokenItemBox} from '../../../common';
import LightDarkBar from '../../../../../../../../components/light_dark_bar';
import Slider from '../../../../../../../../components/editors/slider';
import SizeEditor from '../../../../../right/iteminspector/styleitems/size';
import { Seperator, SmallButtonGroup } from '../../../../../right/iteminspector/styleitems/common';
import { PatternScaleSelector } from '../../../spacing/patterns/item';
import { SliderScaleInput } from '../..';
import Switch from '../../../../../../../../components/editors/Switch';
import { FontSelectItem } from '../../fonts';
import DropDownSelect from '../../../../../../../../components/editors/enum_dropdown';

const GetFontStyle = (font) => {
    return Globals.ProjectManager.Tokens.Get_FontFamilyOf(font);
}

export const FontSelectItemOld = (props) => {
    const font = props.font || {};        
    const style = GetFontStyle(font);
    style.fontSize = '16px';
    style.marginLeft  = '4px';
    style.overflow = 'hidden';
    let variant = font.variant;
    if (variant && variant.indexOf('italic') > -1) {
        variant = variant.replace('italic', '');
    }
    let textFamily = font.family;
    if (font.provider === Strings.FONT_WEBSAE)
        textFamily = font.name;

    let textVariant = variant;
    if (font.provider === Strings.FONT_WEBSAE) {
        textVariant = font.weight ||'normal';
    }
    return (
        <SC.FRow f1 style={style}>
            <SC.TextSpanAbbr>{textFamily} {textVariant}</SC.TextSpanAbbr>            
        </SC.FRow>
    )
}

export default class TextPatternCard extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.SaveName = this.SaveName.bind(this);
        this.renderFontItem = this.renderFontItem.bind(this);
        this.renderScaleItem = this.renderScaleItem.bind(this);        
        this.SelectFontScale = this.SelectFontScale.bind(this);

        this.pattern = Globals.ProjectManager.Tokens.TypePatterns.GetPattern(this.props.id);

        this.state.fontsExpanded = Globals.ProjectManager.Options.Get(true, 'LeftPanel', 'DesignSystem', 'Typography', 'PatternFontsExpanded');
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.GlobalStateId !== nextProps.GlobalStateId || this.props.GlobalThemeId !== nextProps.GlobalThemeId || this.props.id !== nextProps.id || this.props.renderId !== nextProps.renderId) {
            this.pattern = Globals.ProjectManager.Tokens.TypePatterns.GetPattern(nextProps.id);
            if (!this.pattern) {
                this.props.onClose();
                return false;
            }
            return true;
        }
        if (this.state.fontsExpanded !== nextState.fontsExpanded)
            return true;

        return super.ShouldUpdate(nextProps, nextState);
    }
    componentWillUnmount() {
        this.props.onClosed && this.props.onClosed();
        super.componentWillUnmount();        
    }
    renderFontItem(item, selected, onClick) {
        const token = Globals.ProjectManager.Tokens.Font(item.id);
        const font = Globals.ProjectManager.Tokens.ValueOf({model : token}) || {};
        return (
            <FontSelectItem 
                font={font}
                name={token.name}
                selected={selected}
                onClick={onClick}
            />
        )
    }
    renderScaleItem({id, scale}) {
        if (id === 'Custom') {
            return (
                <div style={{
                    fontSize : `14px`,
                    fontWeight : 500
                }}>Custom</div>  
            )
        }
        return (
            <SC.FRow>
                <div style={{
                    fontSize : `14px`,
                    fontWeight : 500
                }}>{scale.px} <span style={{fontSize:'12px', fontWeight : 300}}>px</span></div>                
            </SC.FRow>
        )
    }
    SelectFont(id) {        
        
        !this.props.isNew && Globals.ProjectManager.LogTokenChange({Desc : 'Change Pattern'});
        Globals.ProjectManager.Tokens.SetValue({
            id : this.props.id, 
            value : id, 
            name : 'fontId',
            state : this.props.isNew ? Strings.DEFAULT : this.props.GlobalState
        });

        this.BroadcastChange();            
        this.RCUpdate();
    }
    SetScaleType(isCustom) {
        if (isCustom) {
            Globals.ProjectManager.Tokens.SetValue({
                id : this.props.id, 
                value : 1, 
                name : 'scaleFactor',
                state : this.props.isNew ? Strings.DEFAULT : this.props.GlobalState
            });
        }
        else {
            Globals.ProjectManager.Tokens.DeleteValue({
                id : this.props.id, 
                name : 'scaleFactor',
                state : this.props.isNew ? Strings.DEFAULT : this.props.GlobalState
            });
        }

        this.BroadcastChange();  
        this.RCUpdate();
    }
    SelectFontScale(id, value) {
        !this.props.isNew && Globals.ProjectManager.LogTokenChange({Desc : 'Change Pattern'});

        Globals.ProjectManager.Tokens.SetValue({
            id : this.props.id, 
            value : id, 
            name : 'scaleIndex',
            state : this.props.isNew ? Strings.DEFAULT : this.props.GlobalState
        });

        if (id === 'Custom') {          
            Globals.ProjectManager.Tokens.SetValue({
                id : this.props.id, 
                value : {
                    value : Utils.UseNullOrEmpty(value, 0),
                    Unit : Utils.Defaults.Unit()
                }, 
                name : 'customSize',
                state : this.props.isNew ? Strings.DEFAULT : this.props.GlobalState
            });
        }
        else {
            Globals.ProjectManager.Tokens.DeleteValue({
                id : this.props.id, 
                name : 'customSize',
                state : this.props.isNew ? Strings.DEFAULT : this.props.GlobalState
            });
        }
                
        this.BroadcastChange();    
        this.RCUpdate();      
    }
    SaveName(name) {
        this.pattern.name = name;
        Globals.ProjectManager.Tokens.ChangeTokenName(this.props.id, name);
        this.RCUpdate();
    }
    onChangeSize(prop, newSize) {
        !this.props.isNew && Globals.ProjectManager.LogTokenChange({Desc : 'Change Pattern'});        
        Globals.ProjectManager.Tokens.SetValue({
            id : this.props.id, 
            value : {
                value : newSize.value,
                Unit : newSize.unit
            }, 
            name : prop,
            state : this.props.isNew ? Strings.DEFAULT : this.props.GlobalState
        });
        
        this.BroadcastChange();
    }
    onToggleCustomValue(prop, checked) {
        if (!checked) {
            !this.props.isNew && Globals.ProjectManager.LogTokenChange({Desc : 'Change Text Pattern'});        
            Globals.ProjectManager.Tokens.DeleteValue({
                id : this.props.id,                 
                name : prop,
                state : this.props.isNew ? Strings.DEFAULT : this.props.GlobalState
            });

            this.BroadcastChange();
        }
        else {
            const scaleModel = Globals.ProjectManager.Tokens.Typescale.Get();
            let value;
            if (prop === 'letterSpacing')
                value = scaleModel.letterSpaceFactor;
            else
                value = scaleModel.wordSpacingFactor;

            this.onSliderChangeSize(prop, false, 'em', value);
        }
    } 
    onToggleLineHeight(lineHeight, checked) {
        if (!checked) {
            this.DeleteSize('lineHeight');
        }
        else {
            this.onChangeSize('lineHeight', lineHeight);            
        }
    }
    onSliderChangeSize(prop, scaled, unit, newSize) {
        !this.props.isNew && Globals.ProjectManager.LogTokenChange({Desc : 'Change Text Pattern'});        
        Globals.ProjectManager.Tokens.SetValue({
            id : this.props.id, 
            value : {
                value : scaled ? Number.parseFloat(((newSize / 100)).toFixed(3)) : newSize,
                Unit : unit
            }, 
            name : prop,
            state : this.props.isNew ? Strings.DEFAULT : this.props.GlobalState
        });

        this.BroadcastChange();
    }    
    BroadcastChange() {
        Globals.ProjectManager.UpdateTokenValues({});
        if (this.props.isNew)
            this.props.onPreviewChange && this.props.onPreviewChange(this.pattern);
        else {
            const value = Globals.ProjectManager.GetThemeFontStyle(this.props.id);
            const changingTokens =  [{
                Id : this.props.id,
                Type : Globals.ProjectManager.Tokens.Types.Fonts,
                value : value

            }]
            if (this.pattern.aliases) {
                this.pattern.aliases.map((aliaseId) => {
                    changingTokens.push({
                        Id : aliaseId,
                        Type : Globals.ProjectManager.Tokens.Types.Fonts,
                        value : value
                    })
                })
            }

            Events.BroadcastThrottle_50(Events.GLOBAL.TOKEN_VALUE_CHANGING, changingTokens);
        }
    }
    DeleteSize(prop) {
        !this.props.isNew && Globals.ProjectManager.LogTokenChange({Desc : 'Change Text Pattern'});        
        Globals.ProjectManager.Tokens.DeleteValue({id : this.props.id,  name : prop});
        Globals.ProjectManager.Tokens.DeleteValueOf({id : this.pattern,  name : prop});
        this.BroadcastChange();
    }
    onChangeCustomFactor(prop, value) {
        !this.props.isNew && Globals.ProjectManager.LogTokenChange({Desc : 'Change Text Pattern'});
        
        Globals.ProjectManager.Tokens.SetValue({
            id : this.props.id, 
            value : value, 
            name : prop,
            state : this.props.isNew ? Strings.DEFAULT : this.props.GlobalState
        });

        this.BroadcastChange();
        this.RCUpdate();
    }    
    onChangeTextDecoration(decoration) {
        const pattern = this.pattern;        
        const textDecoration = Globals.ProjectManager.Tokens.ValueOf({model : pattern, name : 'textDecoration'});

        Globals.ProjectManager.Tokens.SetValue({
            id : this.props.id, 
            value : decoration === textDecoration ? 'null' : decoration, 
            name : 'textDecoration',
            state : this.props.isNew ? Strings.DEFAULT : this.props.GlobalState
        });
        this.BroadcastChange();
        this.RCUpdate();
    }
    renderCustom() {
        
        const pattern = this.pattern;
        const fontStyle = Globals.ProjectManager.Tokens.TypePatterns.GetPatternStyle(pattern);

        const scaleIndex = Globals.ProjectManager.Tokens.ValueOf({model : pattern, name : 'scaleIndex'});
        const customSize = Globals.ProjectManager.Tokens.ValueOf({model : pattern, name : 'customSize'});
        const textDecoration = Globals.ProjectManager.Tokens.ValueOf({model : pattern, name : 'textDecoration'});
        
        let content_left, content_right;

        let hasLineHeight = true;
        let lineHeight = Globals.ProjectManager.Tokens.ValueOf({model : pattern, name : 'lineHeight'});        
        if (!lineHeight) {
            hasLineHeight = false;
            lineHeight = Utils.parseSize(fontStyle.lineHeight);
        }

        let hasLetterSpacing = true;
        let letterSpacingValue, letterSpacingUnit;
        let letterSpacing = Globals.ProjectManager.Tokens.ValueOf({model : pattern, name : 'letterSpacing'});
        if (!letterSpacing) {
            hasLetterSpacing = false;
            letterSpacing = Utils.parseSize(fontStyle.letterSpacing);
            letterSpacingValue = letterSpacing.value;
            letterSpacingUnit = letterSpacing.unit;
        }
        else {
            letterSpacingValue = Utils.JustGet(letterSpacing, null, 'value');
            letterSpacingUnit = Utils.JustGet(letterSpacing, null, 'Unit');
        }

        let paragraphSpacing = Globals.ProjectManager.Tokens.ValueOf({model : pattern, name : 'paragraphSpacing'}) || 0;

        const wordSpacingValue = Utils.JustGet(Globals.ProjectManager.Tokens.ValueOf({model : pattern, name : 'wordSpacing'}), null, 'value');

        const scaleModel = Globals.ProjectManager.Tokens.Typescale.Get();

        const tokens = Globals.ProjectManager.Tokens.Fonts();
        const fonts = [{id : 'DefaultFont'} ];
        tokens.map((fid, i) => {
            if (fid !== 'DefaultFont')
                fonts.push({id : fid});
        });

        const style_preview = {borderRadius : '2px'};
        if (pattern.LightBackround || SC.CurrentTheme.theme.isLight) {
            style_preview.backgroundColor = '#D1D1D1';
            style_preview.color = '#1e1e1e';
        }
        else {
            style_preview.backgroundColor = '#1b1b1b';
        }
        let previewItem;

        if (this.props.compact) {
            style_preview.minHeight = '120px';
            style_preview.marginTop = '8px';
            style_preview.justifyContent = 'center';
            previewItem = (
                <SC.InnerPanel jsb alc f1 style={{ ...style_preview, overflow : 'hidden' }}>
                    <div style={{maxWidth : '100%', overflow : 'hidden'}}>
                        <SC.TextDivAbbr style={{...fontStyle}}>{pattern.name}</SC.TextDivAbbr>
                    </div>                        
                </SC.InnerPanel>  
            )
        }
        else {
            previewItem = (
                <SC.FRow jsb alc f1 style={{...fontStyle, ...style_preview }}>                        
                    <div>{pattern.name}</div>
                </SC.FRow>  
            )
        }

        const fontId = Globals.ProjectManager.Tokens.ValueOf({model : pattern, name : 'fontId'});
        const lightbar = (
            <LightDarkBar 
                small 
                Dark={!pattern.LightBackround}
                medium
                onChange={(e) => {
                    pattern.LightBackround = !pattern.LightBackround;
                    this.props.onSave();
                }}
            />
        );
        content_left = (
            <SC.FCol style={{padding : '10px', position : 'relative'}}>
                <ItemNameEditor
                    noMargin
                    autoFocus={this.props.isNew}
                    fontSize='12px'                        
                    name={pattern.name}
                    onSaveName={this.SaveName}
                    onGetItems={this.props.onGetItems}
                    model={pattern}
                    readOnly={this.pattern.locked}
                    lockable={{
                        isLocked : this.pattern.locked,
                        onTokenLock : () => {
                            this.pattern.locked = Globals.ProjectManager.Tokens.ToggleTokenLock(this.props.id);                                    
                            this.RCUpdate();
                        }
                    }}
                />
                {
                    this.props.compact && false ?
                    <SC.FRow alc justifyEnd style={{marginTop : '12px'}}>
                        {lightbar}
                    </SC.FRow> : null
                }
                <SC.FRow alc style={{position : 'absolute', right : '8px', top : '8px'}}>
                    {
                        this.props.compact ? null : 
                        <React.Fragment>
                            {lightbar}
                            <SC.SortAndFilter.StylePanel_Total_Border style={{marginLeft : '12px'}} />
                            <SC.Buttons.RoundButton small style={{width : '32px', border : 'none', marginLeft : 0, marginRight : 0}} onClick={this.props.onDelete}>
                                <SC.Icons.Icon_Delete size={20} />
                            </SC.Buttons.RoundButton>
                        </React.Fragment>
                    }                        
                </SC.FRow>
                {previewItem}                  
            </SC.FCol>
        )


        let content_right_content = (
            <SC.FCol style={{pointerEvents : this.pattern.locked ? 'none' : 'all'}}>
                <SC.FCol f1>
                    <SC.FRow alc jsb style={{marginBottom : '4px'}}>
                        <SC.FRow alc style={{
                            cursor : 'pointer',
                            marginLeft : '-4px'
                        }} onClick={() => {
                            Globals.ProjectManager.Options.Set(!this.state.fontsExpanded, 'LeftPanel', 'DesignSystem', 'Typography', 'PatternFontsExpanded');
                            this.setState({
                                fontsExpanded : !this.state.fontsExpanded
                            })
                        }}>
                            <SC.Icons.Icon_Button hasFill>
                                <SC.Icons.Icon_Arrow_Filled expanded={this.state.fontsExpanded} />
                            </SC.Icons.Icon_Button>
                            <div>TYPEFACE</div>
                        </SC.FRow>                        
                        <SC.Icons.Icon_Button hasFill style={{paddingLeft : '8px', cursor : 'pointer'}} onClick={this.props.onSelectFont}>
                            <SC.Icons.Icon_Plus size={20} />
                        </SC.Icons.Icon_Button>
                    </SC.FRow>  
                    {/* <DropDownSelect
                        fullwidth
                        autoHeight
                        dropDownSize={10}
                        useTheme={this.props.theme}
                        placeholder='Select Module'
                        style={{minWidth : '300px', maxHeight : '28px', marginRight : '16px', borderRadius : '2px', border : SC.CurrentTheme.theme.border_ondark}}
                        popupItemStyle={{
                            minHeight : '32px',
                            maxHeight : '32px',
                        }}
                        renderLabelItem={(item) => {
                            return this.renderFontItem(item, false, this.SelectFont.bind(this, item));                            
                        }}
                        renderItem={(item, selected) => {
                            return this.renderFontItem(item.id, selected, this.SelectFont.bind(this, item.id));    
                        }}
                        items={fonts}
                        onChange={(id) => {
                            SelectFont.bind(id)
                        }}
                        value={fontId || 'Defaultfont'}
                        boxStyle={{}}
                        style={{minHeight : '28px', height : '28px', width : '340px'}}
                        popupStyle={{minWidth : 'unset', zIndex : 999999999, top : 0, marginTop : 0}}
                        popupInnerStyle={{paddingTop : '4px', paddingBottom : '4px'}}
                        popupFromDown
                    /> */}
                    {
                        fonts.map((font) => {
                            const selected =  font.id === (fontId || 'Defaultfont')
                            if (!this.state.fontsExpanded && !selected)
                                return null;
                            return (
                                <React.Fragment>
                                    {this.renderFontItem(font, selected, this.SelectFont.bind(this, font.id))}
                                </React.Fragment>
                            )
                            return (
                                <TokenItemBox 
                                    key={font.id} 
                                    style={selected ? {borderLeft : SC.CurrentTheme.theme.border_brand} : {}}
                                    onClick={this.SelectFont.bind(this, font.id)}
                                >
                                    {this.renderFontItem(font)}
                                </TokenItemBox>
                            )
                        })
                    }                                                                          
                </SC.FCol>
                <PatternScaleSelector 
                    compact
                    title='FONT SIZE'
                    customSize={customSize}
                    onChangeCustom={this.onChangeSize.bind(this, 'customSize')}
                    onDeleteCustom={this.DeleteSize.bind(this, 'customSize')}
                    scaleFactor={Globals.ProjectManager.Tokens.ValueOf({model : pattern, name : 'scaleFactor'})}
                    scaleDiff={Globals.ProjectManager.Tokens.ValueOf({model : pattern, name : 'scaleDiff'}) || 0}
                    onChangeCustomFactor={this.onChangeCustomFactor.bind(this, 'scaleFactor')}
                    onChangeCustomDiff={this.onChangeCustomFactor.bind(this, 'scaleDiff')}
                    scaleIndex={scaleIndex}
                    baseValue={this.props.baseSize}
                    ratio={this.props.ratio}
                    onSelect={this.SelectFontScale}
                    onSetModularScale={this.SetScaleType.bind(this, false)}
                    onSetCustomScale={this.SetScaleType.bind(this, true)}
                    scalesId={this.scalesId}     
                    fontSize                   
                />                    
                <Seperator />
                <SC.FCol style={{fontSize : '11px'}}>
                    <SC.FRow alc jsb style={{marginBottom : '4px'}}>
                        <div>Line Height</div>
                        <SC.FRow alc>
                            <Switch style={{marginRight : '8px'}} round value={hasLineHeight} onChange={this.onToggleLineHeight.bind(this, lineHeight)} />
                            <SizeEditor 
                                title={hasLineHeight ? '' : 'Inherited from base line height'}
                                noPercent
                                readOnly={!hasLineHeight}
                                value={Utils.JustGet(lineHeight, null, 'value')}
                                unit={Utils.JustGet(lineHeight, null, 'Unit')}
                                onChange={this.onChangeSize.bind(this, 'lineHeight')}
                                onChanging={this.onChangeSize.bind(this, 'lineHeight')}
                                onDelete={this.DeleteSize.bind(this, 'lineHeight')}
                                noNone
                                fontSize
                            />
                        </SC.FRow>                         
                    </SC.FRow>
                    <SC.FCol style={{ marginTop : '4px'}}>
                        <SC.FRow alc jsb style={{alignItems : 'baseline', marginBottom : 0}}>
                            <div>Letter Spacing</div>
                            <SC.FRow alc>
                                <Switch style={{marginRight : '8px'}} round value={hasLetterSpacing} onChange={this.onToggleCustomValue.bind(this, 'letterSpacing')} />
                                <SizeEditor 
                                    readOnly={!hasLetterSpacing}
                                    value={letterSpacingValue}
                                    unit={letterSpacingUnit}
                                    onChange={this.onChangeSize.bind(this, 'letterSpacing')}
                                    onChanging={this.onChangeSize.bind(this, 'letterSpacing')}
                                    onDelete={this.DeleteSize.bind(this, 'letterSpacing')}
                                    numeralDecimalScale={2}
                                    noNone
                                    fontSize
                                />
                                {/* <SliderScaleInput 
                                    largeInput
                                    value={Utils.UseNullOrEmpty(letterSpacingValue, scaleModel.letterSpaceFactor)}
                                    readOnly={Utils.IsNull(letterSpacingValue)}
                                    onChange={this.onSliderChangeSize.bind(this, 'letterSpacing', false, 'em')}
                                    onChanging={this.onSliderChangeSize.bind(this, 'letterSpacing', false, 'em')}
                                    postfix={(
                                        <span style={{fontSize : '11px', alignSelf : 'center', paddingRight : '4px', paddingLeft : '4px'}}>EM</span>
                                    )}
                                /> */}
                            </SC.FRow>                            
                        </SC.FRow>
                        {
                            hasLetterSpacing && 
                            <Slider 
                                value={letterSpacingUnit === 'em' ? ((letterSpacingValue || 0.1) * 100) : letterSpacingValue}
                                min={0}
                                max={letterSpacingUnit === 'em' ? 100 : 40}
                                onChange={this.onSliderChangeSize.bind(this, 'letterSpacing', letterSpacingUnit === 'em', letterSpacingUnit)}
                                onChanging={this.onSliderChangeSize.bind(this, 'letterSpacing', letterSpacingUnit === 'em', letterSpacingUnit)}
                                themeId={AppLayout.ThemeId}
                            />                  
                        }                        
                    </SC.FCol>
                    <SC.FCol style={{overflow : 'hidden', marginTop : '4px'}}>
                        <SC.FRow alc jsb style={{alignItems : 'baseline', marginBottom : '-2px'}}>
                            <div>Word Spacing</div>
                            <SC.FRow alc>
                                <Switch style={{marginRight : '8px'}} round value={Utils.IsNotNull(wordSpacingValue)} onChange={this.onToggleCustomValue.bind(this, 'wordSpacing')} />
                                <SliderScaleInput 
                                    largeInput
                                    value={Utils.UseNullOrEmpty(wordSpacingValue, scaleModel.wordSpacingFactor)}
                                    readOnly={Utils.IsNull(wordSpacingValue)}
                                    onChange={this.onSliderChangeSize.bind(this, 'wordSpacing', false, 'em')}
                                    onChanging={this.onSliderChangeSize.bind(this, 'wordSpacing', false, 'em')}
                                    postfix={(
                                        <span style={{fontSize : '11px', alignSelf : 'center', paddingRight : '4px', paddingLeft : '4px'}}>EM</span>
                                    )}
                                />
                            </SC.FRow>
                        </SC.FRow>
                        {
                            Utils.IsNotNull(wordSpacingValue) && 
                            <Slider 
                                value={((wordSpacingValue || 0.1) * 100)}
                                min={0}
                                max={100}
                                onChange={this.onSliderChangeSize.bind(this, 'wordSpacing', true, 'em')}
                                onChanging={this.onSliderChangeSize.bind(this, 'wordSpacing', true, 'em')}
                                themeId={AppLayout.ThemeId}
                            />                  
                        }                 
                    </SC.FCol>
                    <SC.FRow alc jsb style={{marginTop : '8px'}}>
                        <div>Paragraph Spacing</div>
                        <SC.FRow alc>
                            <SizeEditor 
                                noPercent
                                value={Utils.JustGet(paragraphSpacing, 0, 'value')}
                                unit={Utils.JustGet(paragraphSpacing, 'px', 'Unit')}
                                onChange={this.onChangeSize.bind(this, 'paragraphSpacing')}
                                onChanging={this.onChangeSize.bind(this, 'paragraphSpacing')}
                                onDelete={this.DeleteSize.bind(this, 'paragraphSpacing')}
                                noNone
                                fontSize
                            />
                        </SC.FRow>                         
                    </SC.FRow>
                    <SC.FRow alc jsb style={{marginTop : '8px'}}>
                        <div>Text Decoration</div>
                        <SC.FRow>
                            <SmallButtonGroup hasBorders first grouped selected={textDecoration === 'underline'} onClick={this.onChangeTextDecoration.bind(this, 'underline')} title='Underline' style={{width : '27px', marginRight : '1px'}}>
                                <path fill="none" d="M0 0h24v24H0z"/>
                                <path fill={ textDecoration === 'underline' ? SC.CurrentTheme.theme.color_brand : null} d="M12 17a6 6 0 0 0 6-6V3h-2.5v8a3.5 3.5 0 0 1-7 0V3H6v8a6 6 0 0 0 6 6zm-7 2v2h14v-2H5z"/>
                            </SmallButtonGroup>
                            <SmallButtonGroup hasBorders last grouped selected={textDecoration === 'line-through'} onClick={this.onChangeTextDecoration.bind(this, 'line-through')} title='Overline' style={{width : '27px'}}>
                                <path fill="none" d="M0 0h24v24H0z"/>
                                <path fill={ textDecoration === 'line-through' ? SC.CurrentTheme.theme.color_brand : null} d="M10 19h4v-3h-4v3zM5 4v3h5v3h4V7h5V4H5zM3 14h18v-2H3v2z"/>
                            </SmallButtonGroup>
                        </SC.FRow>
                    </SC.FRow>
                </SC.FCol>                                     
            </SC.FCol>
        )

        if (this.props.compact) {
            content_right = (
                <SC.FCol style={{padding : '10px', paddingTop : 0, backgroundColor : SC.CurrentTheme.theme.back}}>
                    {content_right_content}
                </SC.FCol>
            )
        }
        else {
            content_right = (
                <SC.FRow f1 jsb >
                    {content_right_content}
                </SC.FRow>
            )
        }

        return (
            <SC.FCol fw fh style={{backgroundColor : SC.CurrentTheme.theme.back}}>
                {content_left}
                {content_right}       
            </SC.FCol>
        )
    }
}