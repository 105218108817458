import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    AppLayout,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../../../../../importer';
import styled, { css} from 'styled-components';
import {DOCUMENT_ITEMTYPES, DOCUMENT_ITEMTYPE_TITLES, DOCUMENT_TOKENTYPES} from '../../../../../manager/documents';
import DesignSystemComponentsPanel from '../../../../../panels/left/components';
import { SearchInput } from '../../../../../searchbar';
import { TokenItemBox } from '../../../../../panels/left/designsystem/common';
import { DocWidgetIcon_ButtonBox } from '../../../widget';

export default class FigmaComponents extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.onSearch = this.onSearch.bind(this);        
        this.CanceSearch = this.CanceSearch.bind(this);        
        this.figmaComponentItems = [];
    }
    
    SelectWidgetTemplate(id, pageId) {
        Globals.ProjectManager.GetDocumentManager().CloneFromWidgetTemplate(id, pageId, this.props.id);
        if (this.props.forLayout)
            this.props.onNewItemAdded();
        else
            this.props.onUpdate();
    }
    onSearch(filterText) {
        this.FilterItems(filterText);
    }
    CanceSearch() {
        this.FilterItems();
    }
    DeleteTemplateWidget(id) {
        Globals.ProjectManager.GetDocumentManager().ToggleFavoriteWidget(id);
        this.RCUpdate();
    }
    componentDidMount() {
        super.componentDidMount();

        Globals.ProjectManager.FigmaManager.Reload().then(() => {
            this.FilterItems();        
        })        
    }
    FilterItems(filter) {
        const components = Globals.ProjectManager.FigmaManager.Components();
            
        let filterText = filter;
        if (Utils.IsNotNullOrEmpty(filterText))
            filterText = filterText.toUpperCase();

        this.figmaComponentItems = [];
        Utils.ForEach(components, ({name, svg, width, height}, id) => {  

            let filtered = false;
            if (filterText && name) {
                if (name.toString().toUpperCase().indexOf(filterText) < 0) {                    
                    filtered = true;
                }
            }

            if (!filtered && svg) {

                let svgState = Utils.JustGet(svg, '', this.props.GlobalState);
                const svgImage = window.btoa(svgState);

                this.figmaComponentItems.push({
                    id : id,                
                    name : name,
                    width : width,
                    height : height,
                    svgImage : svgImage,
                    svg : svg
                })
            }                
        });

        this.setState({loaded : true, filterText : filter})       
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.GlobalState !== nextProps.GlobalState) {
            this.figmaComponentItems.map((component) => {
                let svgState = Utils.JustGet(component, '', 'svg', nextProps.GlobalState);
                component.svgImage = window.btoa(svgState);
            })
        }
        return true;
    }
    SelectComponent(id) {
        Globals.ProjectManager.GetDocumentManager().SetItemType(this.props.id, DOCUMENT_ITEMTYPES.figmaComponent);
        Globals.ProjectManager.GetDocumentManager().SetItemValue(this.props.id, id, 'id');
        if (this.props.forLayout)
            this.props.onNewItemAdded();
        else
            this.props.onUpdate();
    }
    renderCustom() {

        return (
            <SC.FCol style={{
                lineHeight : 'unset',
                ...SC.Styles.Font(),
                ...this.props.style,
                marginTop : '16px'
            }}>
                <SC.FRow alc jsb>
                    {
                        this.props.forSelect ? 
                        <SC.BackClosableTitle title='SELECT FIGMA COMPONENT' onClose={this.props.onClose} /> :
                        <SC.GroupLabel title='FIGMA COMPONENTS' style={{fontSize : '14px'}} onClose={this.props.onClose}>FIGMA COMPONENTS</SC.GroupLabel>
                    }                    
                    <SearchInput
                        FilterText={this.state.filterText}
                        Search={this.onSearch}
                        CancelSearch={this.CanceSearch}
                    /> 
                </SC.FRow>

                {
                    !this.state.loaded ? <SC.Loading_Icon /> : 
                    <div style={{
                        display : 'grid',
                        gridTemplateColumns : '1fr',
                        gridAutoRows : 'auto',
                        gridGap : '16px',
                        marginTop : '8px'
                    }}>
                        {
                            this.figmaComponentItems.map(({id, name, width, height, svgImage}) => {
                                return (
                                    <SC.FCol onClick={this.SelectComponent.bind(this, id)}>
                                        {name}
                                        <TokenItemBox alc style={{
                                            padding : '8px',
                                            position : 'relative',
                                            marginTop : '4px'
                                        }}>
                                            <div key={id} style={{
                                                backgroundImage : `url(data:image/svg+xml;base64,${svgImage})`,
                                                backgroundSize : `clamp(5px, ${width}px, 90%) 90%`,
                                                backgroundPosition : 'center',
                                                backgroundRepeat : 'no-repeat',
                                                height : Utils.px(height),
                                                maxHeight : '200px' 
                                            }} />
                                            
                                        </TokenItemBox>
                                    </SC.FCol>                                
                                )
                            })
                        }
                    </div>
                }
                                
            </SC.FCol>
        )
    }
}