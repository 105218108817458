import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    UIUtils,
    Globals
} from '../../../../../../../importer';
import { StyleGroupTokenItem, SmallButtonGroup, StyleToken_Color, StyleGroupTitle, PropertyLabel } from '../common';
import TextValue from '../../../../../../../components/editors/textvalue';

export default class TextParameters extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        const tokenModel = Globals.ProjectManager.Tokens.Token(this.props.textContentId);
        this.itemParameters = [];
        const currentValues = this.props.GetComponentManager().GetDesignManager().GetTextContentParameters({
            MetaItem : this.props.GetComponentManager().GetMetaItem(this.props.Id),
            Id : this.props.Id
        });
        Utils.ForEach(tokenModel.parameters, (parameter, i) => {
            const currentvalue = Utils.JustGet(currentValues, {}, 'Parameters', parameter.id);
            this.itemParameters.push({
                parameter : parameter,
                value : currentvalue
                
            })
        });
    }
    onChangeParamValue(param, value) {
        this.props.GetComponentManager().GetDesignManager().SetTextContentParameters({
            MetaItem : this.props.GetComponentManager().GetMetaItem(this.props.Id),
            Id : this.props.Id,
            ParameterId : param.parameter.id,
            ParameterValue : {
                value : value
            }
        });
        param.value.value = value;
    }
    renderCustom() {
        return (
            <SC.FCol>
                <div>Parameters</div>
                {
                    this.itemParameters.map((param) => {
                        return (
                            <SC.FRow>
                                {param.parameter.name}
                                <TextValue
                                    value={param.value.value}            
                                    onChange={this.onChangeParamValue.bind(this, param)}
                                />
                            </SC.FRow>
                        )
                    })
                }
            </SC.FCol>
        )
    }
}