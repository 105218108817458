import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    AppLayout,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../../../../../importer';

import { GetTextContentTokenList, GetTextContentTokenListOfIds } from '../../../../../panels/left/designsystem/textContent';


export default class DocumentToken_Texts extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {

        };    
    }
    shouldComponentUpdate(nextProps, nextState) {

        if (this.state.ShouldUpdate !== nextState.ShouldUpdate)
            return true;
        
        if (Utils.HasAnyChange(this.props, nextProps, 'GlobalThemeId', 'GlobalState', 'StyleId', 'RenderId', 'LoadId', 'themeType', 'states', 'ReversedStyleState'))
            return true;
        
        return false;
    }
    componentDidUpdate(prevProps, prevState) {
        this.state.ShouldUpdate = false;
    }
    render() {        
        const tokens = this.props.selection.type === 'selected' ? GetTextContentTokenListOfIds(this.props.selection.tokenIds, this.props.ReversedStyleState) : GetTextContentTokenList(this.props.ReversedStyleState);
        const tokenItems = [];

        Utils.ForEach(tokens, (token, ) => {
            tokenItems.push(
                <React.Fragment>
                    <div style={{fontFamily : 'monospace'}}>{token.name}</div>                                        
                    <div >{token.value}</div>
                </React.Fragment>
            )
        });

        return (
            <div style={{
                display : 'grid',
                gridGap : '48px',
                gridRowGap : '16px',
                gridTemplateColumns : 'minMax(auto, 300px) 1fr',
                gridAutoRows : 'auto',
                justifyContent : 'start',
                alignItems : 'start'
            }}
            >
                <div>NAME</div>                
                <div>TEXT</div>
                {tokenItems}
            </div>
        )  
    }
}