import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    AppLayout,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../../../../importer';
import styled, { css} from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';

import {Toolabs_Engine} from '../../../../../../toolabs-importer';
import {DOCUMENT_ITEMTYPES, DOCUMENT_TOKENTYPES} from '../../../../manager/documents';
import DropDownSelect from '../../../../../../components/editors/enum_dropdown';
import { PropertyLabel } from '../../../../panels/right/iteminspector/styleitems/common';
import SystemStatePanel from '../../../../panels/left/systemstates';
import { ComponentStateConfigurator } from '../../../../panels/right/iteminspector/styleitems/component/stateConfigurator';
import { GetSubcomponentStateAndModels } from '../../../../panels/right/iteminspector/styleitems/component';

import {
    LiveProvider,
    LiveEditor,
    LiveError,
    LivePreview
  } from 'react-live';

let ComponentId;
let DesignProps;
let ModelProps;

export default class DocumentItem_Tokens extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {

        };    

        this.ChangeComponent = this.ChangeComponent.bind(this);        
        this.SetThemeState = this.SetThemeState.bind(this);

        this.GetStateModel = this.GetStateModel.bind(this);
        this.SetStateVariation = this.SetStateVariation.bind(this);

        this.Load(this.props.pageId, this.props.id);
    }
    GetComponentMetaData(Id) {
        return Globals.ProjectManager.Publisher.Component(Id);
    }
    shouldComponentUpdate(nextProps, nextState) {

        if (this.props.autoFocus !== nextProps.autoFocus)
            return true;
        if (this.state.ShouldUpdate !== nextState.ShouldUpdate)
            return true;
        
        if (this.props.RenderId !== nextProps.RenderId) {
            this.Load(nextProps.pageId, nextProps.id);
            return true;
        }        
        if (Utils.HasAnyChange(this.props, nextProps, 'preview', 'GlobalThemeId', 'GlobalState', 'StyleId', 'themeId'))
            return true;
        
        return false;
    }
    Load(pageId, id) {
        this.data = Globals.ProjectManager.GetDocumentManager().GetPageItem(pageId, id);
        const cid = Utils.JustGet(this.data.data, null, 'id');        
        const ComponentMetaData = Globals.ProjectManager.GetComponentData(cid);
        if (ComponentMetaData) {
            this.componentId = cid;                
            ComponentId = this.componentId;
            this.variations = Utils.JustGet(this.data.data, {}, 'variations');
            this.modelValues = {};        
            const modelValues =  Utils.JustGet(this.data.data, null, 'models');
            this.PreviewCode = '<' + Utils.ToPascalCase(ComponentMetaData.name) + '\r\n';        
            if (modelValues) {
                Utils.ForEach(modelValues, (modelValue, modelId) => {
                    const ModelName = Utils.JustGet(ComponentMetaData, null, 'Models', 'List', modelId, 'name');
                    this.modelValues[modelId] = Utils.JustGet(modelValue, null, 'value');
                    if (ModelName) {
                        this.PreviewCode += '  ' + Utils.ToCamelCase(ModelName) + `="${this.modelValues[modelId]}"\r\n`;
                    }
                });
            }
            this.PreviewCode += '/>';
    
            this.showStateSelector = Utils.JustGet(this.data.data, false, 'showStateSelector');
            this.showPropEditor = Utils.JustGet(this.data.data, false, 'showPropEditor');
    
            if (this.showStateSelector) {
                this.states = [];
                this.stateModels = {};
                this.models = [];
                const result = GetSubcomponentStateAndModels(this.componentId, modelValues);
                if (result.succuess) {
                    this.states = result.states;
                    this.stateModels = result.stateModels;
                    this.models = result.models;
                }
            }
    
    
            this.themeType = Utils.JustGet(this.data.data, 'active', 'theme');
            this.systemStates = null;
            if (this.themeType === 'specific') {
                this.systemStates = Utils.JustGet(this.data.data, {}, 'states');
    
                const result = Utils.States.BuildSystemStateLabelFromVariations({
                    States : Globals.ProjectManager.States.Get(),
                    StateOrder : Globals.ProjectManager.States.GetOrder(),
                    StateVariation : this.systemStates || {}
                })
                this.LocalGlobaslState = result.State;
                const result_array = Utils.States.GenerateStateArray({GlobalStateLabel : result.State});
                this.StyleState = result_array.GlobalStateArray;
                this.ReversedStyleState = Utils.Reverse(Utils.DeepClone(this.StyleState));
    
                this.CurrentTheme = Globals.ProjectManager.CalculateThemeValues(this.StyleState, this.ReversedStyleState, result.NonMediaState);
            }
            else if (this.themeType === 'custom') {
                this.filteredThemeStates = false;
    
                this.SpecificStateVariation = {
    
                };
                this.LocalGlobaslState = 'Default';
                this.StyleState = ['Default'];
                this.ReversedStyleState = ['Default'];
    
                const onlySelectedStates = Utils.JustGet(this.data.data, false, 'filterStates');
                if (onlySelectedStates) {
                    this.filteredThemeStates = Utils.JustGet(this.data.data, [], 'filteredStates');
                }
    
                this.CurrentTheme = Globals.ProjectManager.CalculateThemeValues(this.StyleState, this.ReversedStyleState);
            }
        }        
    }
    componentDidUpdate(prevProps, prevState) {
        this.state.ShouldUpdate = false;
    }
    
    Update() {
        this.setState({
            ShouldUpdate : true
        })
    }
    ChangeComponent(id) {
        this.componentId = id;
        Utils.Set(this.data.data, id, 'id');
        Globals.ProjectManager.GetDocumentManager().SetItem(this.props.id, this.data);
        this.Update();
    }
    SetThemeState(stateId, variationId) {
        Utils.Set(this.SpecificStateVariation, variationId, stateId);
        if (!this.props.preview) {
            Globals.ProjectManager.GetDocumentManager().SetItemValue(this.props.id, variationId, 'states', stateId);
        }

        const result = Utils.States.BuildSystemStateLabelFromVariations({
            States : Globals.ProjectManager.States.Get(),
            StateOrder : Globals.ProjectManager.States.GetOrder(),
            StateVariation : this.SpecificStateVariation
        })

        const result_array = Utils.States.GenerateStateArray({GlobalStateLabel : result.State});
        this.LocalGlobaslState = result.State;
        this.StyleState = result_array.GlobalStateArray;
        this.ReversedStyleState = Utils.Reverse(Utils.DeepClone(this.StyleState));

        this.CurrentTheme = Globals.ProjectManager.CalculateThemeValues(this.StyleState, this.ReversedStyleState, result.NonMediaState);

        this.setState({
            ShouldUpdate : true
        })
    }
    GetStateModel(id) {
        return this.stateModels[id];
    }
    SetStateVariation(stateId, variationId) {
        if (variationId) {
            if (!this.props.preview)
                Globals.ProjectManager.GetDocumentManager().SetItemValue(this.props.id, variationId, 'variations', stateId);
            Utils.Set(this.variations, variationId, stateId);
        }
        else {
            if (!this.props.preview)
                Globals.ProjectManager.GetDocumentManager().DeleteItemValue(this.props.id, 'variations', stateId);
            Utils.UnSet(this.variations, stateId);
        }
        
        this.setState({
            ShouldUpdate : true
        })
    }
    render() {        

        let content;

        if (this.componentId) {
            const previewSize = Globals.ProjectManager.GetComponentPreviewSize(this.componentId);

            let Theme, ThemeId;
            if (this.CurrentTheme) {
                Theme = this.CurrentTheme.Theme[this.LocalGlobaslState] || this.CurrentTheme.Theme.Default || {};
                ThemeId = this.CurrentTheme.ThemeId;
            }
            else {
                Theme = Utils.JustGet(Globals.ProjectManager.CurrentTheme.Theme, {}, Globals.ProjectManager.CurrentState);
                ThemeId = Globals.ProjectManager.CurrentTheme.ThemeId;
            }
            
            DesignProps = {
                ActiveTheme : {
                    Theme : Theme,
                    ThemeId : ThemeId,
                    SystemStateArray : this.StyleState,
                    ReversedSystemStateArray : this.ReversedStyleState,
                },
                ThemeId : ThemeId,
                GlobalVariations : this.LocalGlobaslState || this.props.GlobalState,
                Variations : Utils.DeepClone(this.variations),
                Models : Utils.DeepClone(this.modelValues)
            };
            content = (
                <React.Fragment>                    
                    <div
                        style={{
                            display : 'grid',
                            gridTemplateColumns : 'minMax(' + Utils.px(previewSize.width) + ', 100%)',
                            maxWidth : '100%'                        
                        }}
                    >
                        <Toolabs_Engine.Component
                            Id={this.componentId} 
                            Store={this}
                            {...DesignProps}
                        />
                    </div>   
                    {/* <ComponentCodePreview 
                        ThemeId={ThemeId}
                        backgroundColor={this.props.textStyles.backgroundHighlight}
                        ComponentName={Utils.ToPascalCase(Globals.ProjectManager.GetComponentName(this.componentId))}
                        code={this.PreviewCode}
                    /> */}
                    {/* <SC.GroupLabel style={{fontSize : '12px'}}>{Globals.ProjectManager.GetComponentName(this.componentId)}</SC.GroupLabel> */}
                </React.Fragment>
                             
            )
        }
        else {
            if (this.props.preview) {
                return null;
            }
            content = (
                <SC.LinkText onClick={this.props.onSelectComponent} style={{padding : '8px'}}>
                    Click to Select Component
                </SC.LinkText>
            )
        }

        const alignment = Globals.ProjectManager.GetDocumentManager().GetPageItemValue(this.props.id, this.props.pageId, 'flex-start', 'alignment');

        let stateEditor;

        if (this.showStateSelector) {
            stateEditor = (
                <SC.FCol style={{minWidth : '200px'}}>
                    <div>Copmonent States</div>
                    <ComponentStateConfigurator 
                        states={this.states}
                        variations={this.variations}
                        GetStateModel={this.GetStateModel}
                        onChangeState={this.SetStateVariation}
                    />
                </SC.FCol>                 
            )
        }

        let globalStateEditor;

        if (this.themeType === 'custom') {
            globalStateEditor = (
                <SC.FCol>
                    <div>Theme</div>
                    <SystemStatePanel 
                        contentStyle={{margin : 0, minWidth : '200px', backgroundColor : this.props.textStyles.backgroundHighlight}}
                        justContent
                        onSetStateVariation={this.SetThemeState}
                        currentVariations={this.SpecificStateVariation}                        
                        filteredStates={this.filteredThemeStates}
                        themeId={this.props.themeId}
                    /> 
                </SC.FCol>
            )
        }

        let configurator;

        if (stateEditor || globalStateEditor) {
            configurator = (
                <div
                    style={{
                        display : 'grid',
                        gridGap : '16px',
                        marginBottom : '16px',
                        alignSelf : 'stretch',
                        gridTemplateColumns : 'repeat(auto-fill, minmax(200px, 1fr))',
                        ...SC.Styles.Font()
                    }}
                >
                    {globalStateEditor}
                    {stateEditor}
                </div>
            )
        }

        return (
            <SC.FCol>
                <motion.div
                    style={{
                        ...SC.Styles.Flex.Column,
                        alignItems : alignment
                    }}
                    layoutTransition={{
                        duration : 0.2
                    }}
                >     
                    {configurator}             
                    {content}
                </motion.div>
            </SC.FCol>            
        )
    }
}

class PreviewComponent extends React.Component
{
    constructor(props) {
        super(props);
    }
    GetComponentMetaData(Id) {
        return Globals.ProjectManager.Publisher.Component(Id);
    }
    render() {
        const {style, options,...restProps} = this.props;
        return (
            <Toolabs_Engine.Component
                Id={ComponentId} 
                Store={this}   
                style={style}             
                {...DesignProps}
                {...Utils.Merge(options, restProps)}
            />
        )
    }
}

export class ComponentCodePreview extends ReactBaseComponent
{
    constructor(props) {
        super(props);
    }
    renderCustom() {
        const scope = {
            DesignProps : DesignProps
        };
        scope[this.props.ComponentName] = PreviewComponent
        
        return (
            <SC.FCol f1 style={{marginTop : '24px', alignSelf : 'stretch'}}>
                <LiveProvider 
                    // code={ `<${this.props.ComponentName} />` }
                    code={this.props.code}
                    scope={scope}
                    style={{flex : 1, }}
                >
                    <SC.FCol fw fh>
                        <LivePreview  style={{flex : 2, ...SC.GetGridBackgroundStyle(false, true), padding : '40px'}} />
                        <LiveEditor style={{flex : 1, backgroundColor : this.props.backgroundColor, marginTop : '16px'}} />    
                        <LiveError style={{height : '200px', backgroundColor : '#ff5555', color : '#f8f8f2', padding : '8px', fontSize : '14px'}}/>
                    </SC.FCol>
                </LiveProvider>
            </SC.FCol>
            
        )
    }
}