import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    AppLayout,
    Globals
} from '../../../../../../importer';

import styled from 'styled-components';
import {TokenGroup, TokenItemBox, TokenPanelHeader} from '../common';
import { ListCardView } from '../../../../../../components/light_dark_bar';
import { motion } from 'framer-motion';
import { BaseSizeSlider, ScaleDropdown } from '../typography';
import EasingPatterns from './easing';
import TransitionPatterns from './transitions';
import TimePatterns from './patterns';
import { LeftScrollPanel } from '../../common';
import { GRANT_TYPES } from '../../../../manager';

export default class Motion extends ReactBaseComponent
{
    constructor(props) {
        super(props);        
        
        this.PanelMode = this.props.isSelectList ? 'RightPanel' : 'LeftPanel';

        this.onChangeBaseSize = this.onChangeBaseSize.bind(this);
        this.onChangingBaseSize = this.onChangingBaseSize.bind(this);
        this.SelectExactScale = this.SelectExactScale.bind(this);
        this.onChangeCustomScale = this.onChangeCustomScale.bind(this);

        this.AddDurationToken = this.AddDurationToken.bind(this);
        this.onDeletePattern = this.onDeletePattern.bind(this);
        this.onCancelAddPattern = this.onCancelAddPattern.bind(this);
        this.onSubmitNewPattern = this.onSubmitNewPattern.bind(this);

        this.BroadcastThemeChangeThrottled = this.BroadcastThemeChangeThrottled.bind(this);
        this.BroadcastThemeChange = Utils.Throttle(this.BroadcastThemeChangeThrottled, 250);

        this.model = Utils.DeepClone(Globals.ProjectManager.Tokens.TimeScale.Get());

        AppLayout.Refs.DesignSystem.Motion = this;

        this.scaleValue = (this.model.ratio - 1) * 1000;
        this.onToggleExpand = this.onToggleExpand.bind(this);
        this.expanded = this.props.singleView || Globals.ProjectManager.Options.Get(false, this.PanelMode, 'DesignSystem', 'Motion', 'Expanded');

        this.HasEditGrant = Globals.ProjectManager.CheckGrant(GRANT_TYPES.EDIT_TOKEN.ALL);
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.GlobalState !== nextProps.GlobalState || this.props.GlobalStateId !== nextProps.GlobalStateId) {
            this.model = Utils.DeepClone(Globals.ProjectManager.Tokens.TimeScale.Get());
            this.scaleValue = (this.model.ratio - 1) * 1000;
        }
        return true;
    }
    onToggleExpand() {
        this.expanded = !this.expanded;
        Globals.ProjectManager.Options.Set(this.expanded, this.PanelMode, 'DesignSystem', 'Motion', 'Expanded');
        this.RCUpdate();
    }    
    onChangeBaseSize(value) {
        this.ChangeLogged = false;
        Globals.ProjectManager.Offline = false;
        this.ChangeBaseSize(value);
    }
    onChangingBaseSize(value) {
        if (!this.ChangeLogged) {
            Globals.ProjectManager.LogTokenChange({Desc : 'Change Base Time'});
            this.ChangeLogged = true;
        }
        Globals.ProjectManager.Offline = true;
        this.ChangeBaseSize(value);        
    }
    ChangeBaseSize(value) {
        const scaleModel = this.model;
        scaleModel.baseSize = value;
        Globals.ProjectManager.Tokens.TimeScale.SetScale(scaleModel);
        this.spacingId = Utils.Id();
        this.RCUpdate();
        this.BroadcastThemeChange();
    }        
    SelectExactScale(id, item) {
        Globals.ProjectManager.LogTokenChange({Desc : 'Change Base Time'});
        this.model.scaleType = item.id;
        this.model.ratio = item.value;        
        Globals.ProjectManager.Tokens.TimeScale.SetScale(this.model);
        this.scaleValue = item.value;
        this.BroadcastThemeChange();
        this.spacingId = Utils.Id();
        this.RCUpdate();
    }
    onChangeCustomScale(value) {
        Globals.ProjectManager.LogTokenChange({Desc : 'Change Time Scale'});
        this.model.scaleType = 'custom';
        this.model.ratio = value;
        Globals.ProjectManager.Tokens.TimeScale.SetScale(this.model);
        this.BroadcastThemeChange();
        this.spacingId = Utils.Id();
        this.RCUpdate();
    }
    BroadcastThemeChangeThrottled() {
        Globals.ProjectManager.UpdateTokenValues({motionTimes : true});
        const patterns = Globals.ProjectManager.Tokens.TimePatterns.Get();
        const changes = [];
        Utils.ForEach(patterns, (pattern, i) => {
            changes.push({
                Id : pattern.id,
                Type : Globals.ProjectManager.Tokens.Types.Motion
            })
        });    
        Events.BCE(Events.GLOBAL.TOKEN_VALUE_CHANGING, changes);        
    }
    AddDurationToken() {
        this.IsNew = true;
        this.EditPatternId = Utils.Id();        
        this.EditPatternModel = Globals.ProjectManager.Tokens.TimePatterns.NewPattern(this.EditPatternId);
        this.EditPatternModel.scaleIndex = 0;
        this.EditPatternModel.name = 'New Duration';

        this.EditToken(this.EditPatternModel);
    }    
    onCancelAddPattern() {
        delete this.IsNew;
        delete this.EditPatternModel;
        delete this.EditPatternId;

        this.props.onPanelOverlay({close : true});
        this.RCUpdate();
    }
    onSubmitNewPattern() {
        if (this.IsNew) {
            Globals.ProjectManager.LogTokenChange({Desc : 'Add Duration Token'});
            const patterns = Globals.ProjectManager.Tokens.TimePatterns.Get();
            patterns.push(this.EditPatternModel);
            Globals.ProjectManager.Tokens.TimePatterns.Set(patterns);
            this.WillScrollTo = this.EditPatternId;
            this.selectedId = this.EditPatternId;
        }        
        else {
            const patterns = Globals.ProjectManager.Tokens.TimePatterns.Get();
            Globals.ProjectManager.Tokens.TimePatterns.Set(patterns);
        }
        this.spacingId = Utils.Id();
        this.onCancelAddPattern();
    }
    onDeletePattern() {
        const result = Globals.ProjectManager.Tokens.TimePatterns.DeletePattern(this.EditPatternId);
        if (result) {
            this.spacingId = Utils.Id();
            this.onCancelAddPattern();
        }
    }    
    renderCustom() {
        const scaleIndex = Utils.FindIndex(Globals.ProjectManager.Tokens.Utils.ScalePresets, (preset) => {return preset.id === this.model.scaleType});        
        const scalePreset = Globals.ProjectManager.Tokens.Utils.ScalePresets[scaleIndex];
        
        let content = (this.expanded || this.props.singleView) && (
            <motion.div
                initial={{opacity : 0.5, y : -4}}
                animate={{opacity : 1, y : 0}}
                transition={{duration : 0.2}}
                style={{paddingLeft : '2px', paddingRight : '2px'}}
            >
                <SC.FCol style={{padding : '8px', paddingLeft : '12px', fontSize : '11px'}}>
                    <BaseSizeSlider 
                        title='Base Time'
                        unit='ms'
                        min={50}
                        max={500}
                        largeInput
                        baseSize={this.model.baseSize} 
                        onChangeBaseSize={this.onChangeBaseSize} 
                        onChangingBaseSize={this.onChangingBaseSize} 
                        readOnly={!this.HasEditGrant}
                    />
                    <ScaleDropdown title='Time Scale'
                        label={scalePreset.label} 
                        value={this.model.scaleType}                        
                        scaleValue={this.model.ratio}
                        selectedIndex={scaleIndex-1}
                        SelectPreset={this.SelectExactScale}
                        onChangeCustom={this.onChangeCustomScale}
                        readOnly={!this.HasEditGrant}
                    />
                </SC.FCol>
                <TimePatterns 
                    hasEditGrant={this.HasEditGrant}
                    onPanelOverlay={this.props.onPanelOverlay} 
                    spacingId={this.spacingId}
                    baseSize={this.model.baseSize}
                    ratio={this.model.ratio}
                    onAddPattern={this.AddDurationToken}
                    GlobalStateId={this.props.GlobalStateId}
                    filterText={this.props.filterText}
                    onUpdate={() => {
                        this.state.ShouldUpdate = true;
                        this.props.onUpdate();
                    }}
                />
                <EasingPatterns 
                    hasEditGrant={this.HasEditGrant}
                    onPanelOverlay={this.props.onPanelOverlay}
                    GlobalState={this.props.GlobalState}
                    GlobalStateId={this.props.GlobalStateId}
                    filterText={this.props.filterText}
                    onUpdate={() => {
                        this.state.ShouldUpdate = true;
                        this.props.onUpdate();
                    }}
                />
                <TransitionPatterns 
                    hasEditGrant={this.HasEditGrant}
                    ReloadId={this.spacingId}
                    onPanelOverlay={this.props.onPanelOverlay}
                    GlobalState={this.props.GlobalState}
                    GlobalStateId={this.props.GlobalStateId}
                    RefToolbar={this.props.RefToolbar}
                    filterText={this.props.filterText}
                    onUpdate={() => {
                        this.state.ShouldUpdate = true;
                        this.props.onUpdate();
                    }}
                />
            </motion.div>
        )
        

        if (this.props.singleView) {
            if (!this.props.noScrolls) {
                content = (
                    <LeftScrollPanel>
                        {content}
                    </LeftScrollPanel>
                )
            }            
        }
        else {
            content = (
                <TokenGroup 
                    title='MOTION' 
                    hideTitle={this.props.singleView}
                    last={this.props.last} 
                    expandable={{expanded : this.expanded,  onExpand : this.onToggleExpand }}
                >
                    {content}                
                </TokenGroup>
            )
        }
        return (
            <React.Fragment>
                {this.props.children}
                {content}
            </React.Fragment>
        )
    }
}