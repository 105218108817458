import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppLayout,
    Links,
    Events,
    MetaData,
    Strings,
    Globals
} from '../../../../../../importer';

import {TokenGroup, TokenItemBox, GroupTitle, TokenItemDragHandle, TokenItemBoxHoveredName} from '../common';
import { ListCardView } from '../../../../../../components/light_dark_bar';
import { motion } from 'framer-motion';
import IconEditor from './item';
import IconSizeEditor from './size';
import Dropzone from 'react-dropzone';
import {IconBox} from './item';
import DragSource from '../../../../../../components/dragdrop/HoCDragSource';
import { SortableList, SortableListItem } from '../../../../../../components/SortableContainer';
import BaseTokenList from '../BaseTokenList';
import {Droppable, Draggable} from 'react-beautiful-dnd';
import { LeftScrollPanel } from '../../common';
import { StatefulTokenListItemName, StatefulTokenMarker, TokenLockedMarker } from '../colors';
import { GRANT_TYPES } from '../../../../manager';

export default class Icons extends BaseTokenList
{
    constructor(props) {
        super(props);

        this.title = 'ICONOGRAPHY';
        this.moduleName = 'Icons';
        this.tokenType = Globals.ProjectManager.Tokens.Types.Icons;
        this.hasGroups = true;

        this.Ref_IconEdit = React.createRef();
        this.onEditIcon = this.onEditIcon.bind(this);
        this.onShowMenu = this.onShowMenu.bind(this);
        this.EditIcon = this.EditIcon.bind(this);
        this.onDeleteIcon = this.onDeleteIcon.bind(this);
        this.onCancelAddIcon = this.onCancelAddIcon.bind(this);
        this.onSubmitNewIcon = this.onSubmitNewIcon.bind(this);
        this.PreviewChange = this.PreviewChange.bind(this);

        this.onSubmitIconSize = this.onSubmitIconSize.bind(this);
        this.onCancelIconSize = this.onCancelIconSize.bind(this);
        this.onDeleteIconSize = this.onDeleteIconSize.bind(this);
        this.AddSize = this.AddSize.bind(this);

        this.onDrop = this.onDrop.bind(this);

        AppLayout.Refs.DesignSystem.Icons = this;

        super.LoadOptions();
        if (this.expanded || this.props.singleView) {
            this.expanded = true;
            this.Load(this.state.isGroupView);
        }
    }
    componentWillUnmount() {
        super.componentWillUnmount();
        AppLayout.Refs.DesignSystem.Icons = null;
    }
    Load(groupView) {
        this.tokens = GetIconTokenList();
        this.isEmpty = this.tokens.length === 0;
        this.iconSizes = GetIconSizeList();
        this.maxIconSize = 0;
        this.iconSizes = Utils.Sort(this.iconSizes, (item) => {
            this.maxIconSize = Math.max(this.maxIconSize, item.value);
            return item.value
        });

        if (groupView)
            this.groups = super.GetGroupedTokenList(this.tokens)
    }
    SearchToken(filter) {
        let filterText;
        let filteredCount = 0;
        if (Utils.IsNotNullOrEmpty(filter))
            filterText = filter.toUpperCase();
        Utils.ForEach(this.tokens, (token, i) => {
            delete token.filtered;
            if (filterText) {
                if (token.name.toUpperCase().indexOf(filterText) < 0) {                    
                    token.filtered = true;
                }
            }
        });
        this.RenderId = Utils.Id();
        this.RCThrottledUpdate_1();
    }  
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.filterText !== nextProps.filterText) {
            setTimeout(() => {
                this.SearchToken(nextProps.filterText);
            }, 1);
            return false;
        }
        if (super.shouldComponentUpdate(nextProps, nextState)) {
            return true;
        }
        if (this.props.dragSource !== nextProps.dragSource)
            return true;
        return false;
    }           
    AddTokenToGroup(group) {
        if (!Globals.ProjectManager.CheckGrant(GRANT_TYPES.EDIT_TOKEN.ALL)) {
            return;
        }

        this.AddingToGroup = group;
        this.IsNew = true;
        this.EditIconId = Utils.Id();
        this.EditIconModel = {
            name : 'New Icon'
        };
        
        this.props.onPanelOverlay({
            show : true,
            render : (props) => {
                return (
                    <IconEditor 
                        ref={this.Ref_IconEdit}
                        newModel={this.EditIconModel}
                        onCancelAddIcon={this.onCancelAddIcon}
                        onSubmitNewIcon={this.onSubmitNewIcon}
                        offline
                    />                 
                )
            }
        })
    }
    onEditIcon(id, e) {   
        if (this.props.onSelect) {
            this.props.onSelect(id, e);
            return;
        }
        this.EditIcon(id);
    }
    onShowMenu(id, e) {
        this.props.onShowMenu && this.props.onShowMenu(id, e);
    }
    EditIcon(id) {        
        this.EditIconId = id;

        if (!Globals.ProjectManager.CheckGrant(GRANT_TYPES.EDIT_TOKEN.ALL)) {
            return;
        }

        if (Globals.ProjectManager.AuditManager) {
            this.WillEditToken = Utils.DeepClone(Globals.ProjectManager.Tokens.Token(id));
        }

        this.props.onPanelOverlay({
            show : true,
            render : (props) => {
                return (
                    <IconEditor 
                        ref={this.Ref_IconEdit}
                        id={id}
                        onCancelAddIcon={this.onSubmitNewIcon}
                        onSubmitNewIcon={this.onSubmitNewIcon}
                        onDeleteIcon={this.onDeleteIcon}
                        GlobalStateId={props.GlobalStateId}
                        onPreviewChange={this.PreviewChange}
                        onClosed={() => {
                            const tokencurrent = Globals.ProjectManager.Tokens.Token(id);
                            
                            if (this.WillEditToken && tokencurrent) { 
                                const diff = Utils.GetFlattenedDiff(this.WillEditToken, tokencurrent);
                                if (diff.length > 0) {

                                    this.Load(this.state.isGroupView);
                                    this.RCUpdate();
                                    Globals.ProjectManager.AuditManager && Globals.ProjectManager.AuditManager.TokenChanged({TokenId : id, Change : {
                                        changes : diff
                                    }});
                                }                                                                        
                            }
                            delete this.WillEditToken;
                        }}
                    />               
                )
            }
        })
    }        
    PreviewChange(show, data) {
        // Events.BroadcastThrottle_50(Events.GLOBAL.TOKEN_VALUE_CHANGING, [{
        //     Id : this.EditIconId,
        //     Type : Globals.ProjectManager.Tokens.Types.Icons,
        //     value : show ? data : null
        // }]);
    }
    onDeleteIcon() {
        if (Globals.ProjectManager.Tokens.Delete(Globals.ProjectManager.Tokens.Types.Icons, this.EditIconId)) {
            this.Load(this.state.isGroupView);
            this.onCancelAddIcon();
        }
    }
    
    onCancelAddIcon() {
        delete this.IsNew;
        delete this.EditIconModel;
        delete this.EditIconId;

        this.props.onPanelOverlay({close : true});
        this.RCUpdate();
    }
    onSubmitNewIcon() {
        if (this.IsNew) {            
            const newItem = AddOrUpdateIconToken({model : this.EditIconModel, isNew : true})
            this.tokens.push(newItem);            

            if (this.AddingToGroup) {
                this.AddingToGroup.tokens.push(newItem);
                Globals.ProjectManager.Tokens.AddTokenToGroup({type : this.tokenType, groupid : this.AddingToGroup.id, tokenid : newItem.id})
                delete this.AddingToGroup;
            }    
            
            Events.BCE(Events.GLOBAL.TOKENS_CHANGED);
        }        
        else {            
            const token = Globals.ProjectManager.Tokens.Token(this.EditIconId);
            if (token) {
                const localpattern = Utils.Find(this.tokens, (item) => {return item.id === this.EditIconId});
                localpattern.name = token.name;
                GetIconTokenValueItem(token, localpattern);   
            }            
        }
              
        this.isEmpty = false;
        this.onCancelAddIcon();
    }    
    onDrop(files) {
        if (files) {
            Utils.ForEach(files,(file, i) => {
                const Id = Globals.ProjectManager.Tokens.Add({
                    type  :Globals.ProjectManager.Tokens.Types.Icons,
                    name : file.name,
                    value : {
                        loading : true,
                        provider : 'File'
                    }
                });
                this.tokens.push({
                    id : Id,
                    name : file.name,
                    loading : true
                })
                this.RCUpdate();
                var reader = new FileReader();
                reader.onload = this.SaveDroppedIcon.bind(this, Id);
                reader.readAsDataURL(file);
            });
        }
    }
    SaveDroppedIcon(Id, e) {
        Globals.ProjectManager.Tokens.SetValue({
            id : Id,
            value : {
                data : e.target.result,
                provider : Strings.CUSTOM
            }
        });
        this.Load(this.state.isGroupView);
        this.RCUpdate();
    }

    renderTokenList(tokens, gorupId) {
        const style = {
            margin : '4px', marginLeft : this.state.isListView ? '10px' : '8px', marginRight : '10px', marginBottom : 0, marginTop : '8px',
            ...SC.Styles.Flex.Column
        } 
        if (!this.state.isListView) {
            style.flexDirection = 'row';
            style.flexWrap = 'wrap';
        }
        return (
            <Droppable 
                droppableId={gorupId}
                type={'TOKENS'}
                direction={this.state.isListView ? 'vertical' : 'horizontal'}
            >
                {
                    (provided, snapshot) => (
                        <div
                            {...provided.droppableProps} 
                            ref={provided.innerRef} 
                            style={{
                                ...provided.droppableProps.style,
                                ...style,
                                border : '1px dashed',                                                    
                                borderColor : snapshot.isDraggingOver ? SC.CurrentTheme.theme.color_brand : 'transparent',     
                            }}
                        >
                            <IconsTokenList 
                                dragSource={this.props.dragSource}
                                sortable
                                isListView={this.state.isListView}
                                onSelect={this.onEditIcon}
                                onShowMenu={this.onShowMenu}
                                icons={tokens}
                            />
                            {provided.placeholder}
                        </div>
                    )
                }
            </Droppable>
        )  
    }
    renderPreTokenList() {
        return (
            <React.Fragment>
                <GroupTitle 
                title='Sizes' 
                subGroup
                hasAdd={{onAdd : this.AddSize}}                     
            />
            <GroupTitle 
                title='Colors' 
                subGroup
                hasAdd={{onAdd : this.AddSize}}                     
            />
            </React.Fragment>
        )
    }
    AddSize() {
        this.IsNew = true;
        this.EditIconModel = {

        }
        this.props.onPanelOverlay({
            show : true,
            render : (props) => {
                return (
                    <IconSizeEditor 
                        ref={this.Ref_IconEdit}
                        newModel={this.EditIconModel}
                        onCancel={this.onCancelIconSize}
                        onSubmit={this.onSubmitIconSize}
                    />                 
                )
            }
        })
    }
    onEditIconSize(iconsize) {
        this.EditSizeId = iconsize.id;
        this.props.onPanelOverlay({
            show : true,
            render : (props) => {
                return (
                    <IconSizeEditor 
                        ref={this.Ref_IconEdit}
                        id={iconsize.id}
                        onCancel={this.onCancelIconSize}
                        onSubmit={this.onSubmitIconSize}
                        onDelete={this.onDeleteIconSize}
                        offline
                    />                 
                )
            }
        })
    }
    onCancelIconSize() {
        delete this.IsNew;
        delete this.EditIconModel;
        delete this.EditSizeId;

        this.props.onPanelOverlay({close : true});
        this.RCUpdate();
    }
    onDeleteIconSize() {
        if (Globals.ProjectManager.Tokens.Delete(Globals.ProjectManager.Tokens.Types.IconSize, this.EditSizeId)) {
            this.Load(this.state.isGroupView);
            this.onCancelIconSize();
        }
    }
    onSubmitIconSize() {
        if (this.IsNew) {    
            const newid = Globals.ProjectManager.Tokens.Add({
                type : Globals.ProjectManager.Tokens.Types.IconSize,
                name : this.EditIconModel.name,
                value : this.EditIconModel.value
            });         
            this.iconSizes.push({
                id : newid,
                name : 'new size',
                value : this.EditIconModel.value
            });              
        }        
        else {            
            const token = Globals.ProjectManager.Tokens.Token(this.EditIconId);
            if (token) {
                const localpattern = Utils.Find(this.tokens, (item) => {return item.id === this.EditIconId});
                localpattern.name = token.name;
                GetIconTokenValueItem(token, localpattern);   
            }            
        }
                
        this.onCancelIconSize();
    }
    
    renderCustom() {      
        return (
            <Dropzone
                onDrop={this.onDrop}
                style={{}}
                accept="image/*"
                activeStyle={{}}
                onClick={(e) => {e.preventDefault()}}
                inputProps={{
                    style : {width : '10px'}
                }}
            >
            {({ isDragActive, getRootProps, getInputProps }) => {
                return (
                    <SC.FCol 
                        style={{
                            border : '1px dashed',
                            borderColor :isDragActive ? SC.CurrentTheme.theme.color_brand : 'transparent',

                            flex : 1
                        }}
                        {...getRootProps()}
                    >                
                        {super.renderCustom()}                                
                    </SC.FCol>                    
                )
            }}                
            </Dropzone>            
        )
    }
}



export const IconListItem = ({name, url, loading, dark, icon, sortableProps, selected, valueState, locked, ...rest}) => {
    const style_box = {
        padding : 0,
        paddingLeft : '6px',
        ...SC.Styles.Flex.RowAlcJsb        
    };
    if (selected) {
        style_box.borderLeft = '1px solid';
        style_box.borderLeftColor = SC.CurrentTheme.theme.color_brand;
    }

    return (
        <TokenItemBox {...rest} style={style_box} selected={selected}>
            <SC.FRow f1 alc overflowHidden>
                <StatefulTokenListItemName 
                    name={name}
                    sortableProps={sortableProps}
                    ValueState={valueState}
                    locked={locked}
                /> 
            </SC.FRow>     
            <SC.GridBackground small dark={dark} style={{margin : '2px', borderRadius : '2px', overflow : 'hidden'}} >
                {icon}                     
            </SC.GridBackground>        
        </TokenItemBox>
    )
}

const DraggablePattern = DragSource(IconListItem);
const DraggableIconBox = DragSource(IconBox);

export class IconsTokenList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    onEditIcon(id, e) {
        this.props.onSelect(id, e);
    }
    onShowMenu(id, e) {
        this.props.onShowMenu(id, e);
    }
    PreviewPattern(show, imageId, image) {
        this.WillShowPreview = {
            show : show,
            imageId : imageId
        };
        setTimeout(() => {
            if (this.WillShowPreview) {
                this.props.onPreview && this.props.onPreview(this.WillShowPreview.show, this.WillShowPreview.imageId);
            }
        }, 200);        
    }
    render() { 
        const {isListView, dragSource, sortable, icons, onPreview} = this.props;

        const iconItems = [];
        const icon_style = isListView ? {
            width : '60px', height : '60px'
        } : {};

        let IconType = IconBox;
        let ListItemType = IconListItem;
        if (dragSource)  {
            if (isListView)
                ListItemType = DraggablePattern;
            else
                IconType = DraggableIconBox;
        }
        Utils.ForEach(icons, (icon, i) => {                                
            if (!icon.filtered) {
                const props = {
                    onMouseEnter : onPreview ? this.PreviewPattern.bind(this, true, icon.id, icon) : null,
                    onMouseLeave : onPreview ? this.PreviewPattern.bind(this, false, icon.id, icon) : null,
                    sortable : sortable,
                    key : icon.id,
                    onClick : this.onEditIcon.bind(this, icon.id),
                    onContextMenu : this.onShowMenu.bind(this, icon.id),
                    dark : icon.dark,
                    valueState : icon.ValueState
                };
                
                if (dragSource) {
                    props.DragItem = {
                        Type : Strings.TOOLBARITEM,
                        Item : {
                            MetaItem : {
                                Type: MetaData.Components.SvgIcon.Type,
                                Tokens : {
                                    Default : {
                                        Default : {
                                            icon : {
                                                Id : icon.id
                                            }
                                        }
                                    }
                                }
                            }
                        } 
                    };
                }
                let iconitem; 
                const selected = this.props.selectedId === icon.id;
                            
                if (Utils.IsNotNullOrEmpty(icon.url)) {
                    iconitem = (
                        <IconType                         
                            url={icon.url}
                            style={{...icon_style, border : selected && !isListView ? SC.CurrentTheme.theme.border_brand : 'none'}}
                            selected={selected && !isListView}
                            {...props}
                            title={icon.name}                        
                        />
                    )                     
                }    
                else if (icon.paths) {
                    iconitem = (
                        <IconType                        
                            style={{...icon_style, border : selected && !isListView ? SC.CurrentTheme.theme.border_brand : 'none'}}
                            selected={selected && !isListView}
                            {...props}
                            title={icon.name}
                        >
                            <SC.Icons_Preview compact  xmlns='http://www.w3.org/2000/svg' viewBox={`0 0 24 24`} width='80%' dark={!icon.dark} >
                                {icon.paths}
                            </SC.Icons_Preview>    
                        </IconType>
                    )
                }                
    
                let iconcontent;
                
                
    
                if (sortable) {
                    if (isListView) {
                        iconItems.push(                    
                            <SortableListItem
                                key={icon.id}
                                draggableId={icon.id}
                                index={i}
                                ItemBoxType={ListItemType}
                                isDragDisabled={!this.HasEditGrant}
                                BoxProps={{
                                    name : icon.name,
                                    locked : icon.locked,
                                    url : icon.url,
                                    icon : iconitem,
                                    selected : selected,
                                    ...props
                                }}                
                            />  
                        )
                    }
                    else {
                        iconItems.push(
                            <SC.GridBackground key={icon.id} small dark={icon.dark} style={{position : 'relative'}} >
                                {iconitem}
                                {
                                    icon.ValueState &&
                                    <StatefulTokenMarker 
                                        style={{
                                            position : 'absolute',
                                            top : '4px',
                                            left : '4px'
                                        }}
                                        {...icon.ValueState}
                                    />
                                }
                                {
                                    icon.locked &&
                                    <TokenLockedMarker overlay />
                                }
                            </SC.GridBackground>
                        );
                    }
                }
                else {
                    if (isListView) {
                        iconItems.push(                    
                            <ListItemType 
                                url={icon.url} 
                                name={icon.name} 
                                icon={iconitem}
                                selected={selected}
                                {...props}
                            >
                                
                            </ListItemType>
                        )
                    }
                    else {
                        iconItems.push(
                            <SC.GridBackground small dark={icon.dark} >
                                {iconitem}                                
                            </SC.GridBackground>
                        );
                    }
                }
            }            
        });
                

        const ContainerProps = {
            style : isListView ? 
            {
                padding : '4px', paddingLeft  :'8px', paddingRight : '8px',
                transition : 'all 0.2s ease',
                ...this.props.style
            } :
            {
                display : 'grid',
                gridGap : '4px',
                gridTemplateColumns : 'repeat(auto-fill, minmax(60px, 1fr))',
                gridAutoRows : '60px',
                transition : 'all 0.2s ease',
                flex : 1,
                ...this.props.style
            }            
        }

        
        if (sortable && isListView) {
            return iconItems;
        }
        return (
            <SC.FCol {...ContainerProps}>
                {iconItems}
            </SC.FCol>
        )

    }
}

export const GetIconTokenList = (StateArray) => {
    const tokenids = Globals.ProjectManager.Tokens.Icons();
    return GetIconTokenListOfIds(tokenids, StateArray);
}
export const GetIconTokenListOfIds = (tokenids, StateArray) => {    
    const tokens = Globals.ProjectManager.Tokens.TokenList(Globals.ProjectManager.Tokens.Types.Icons);
    const icons = [];

    Utils.ForEach(tokenids, (id, i) => {
        const token = tokens[id];
        if (token) {            
            const tokenitem = {
                id : id,
                name : token.name
            }
            icons.push(tokenitem);
            GetIconTokenValueItem(token, tokenitem, StateArray);            
        }
    });
    return icons;
}

export const GetIconTokenValueItem = (token, tokenitem, statearray) => {
    const tokenvalue = Globals.ProjectManager.Tokens.ValueOf({model : token, statearray : statearray, info : tokenitem}) || {};

    if (tokenvalue.provider === Strings.ICONFINDER) {
        tokenitem.url = `data:image/svg+xml;base64,${tokenvalue.data}`;
    }
    else if (tokenvalue.provider === Strings.FIGMA) {
        tokenitem.url = `data:image/svg+xml;base64,${window.btoa(tokenvalue.svg)}`;
    }
    else if (tokenvalue.provider === Strings.CUSTOM) {
        tokenitem.url = tokenvalue.data;
    }
    else if (tokenvalue.provider === 'svg') {
        tokenitem.url = `data:image/svg+xml;base64,${window.btoa(tokenvalue.data)}`;
    }
    else if (tokenvalue.provider === Strings.ICON_GOOGLE_MATERIAL) {
        
        tokenitem.paths = [];
        tokenvalue.paths.map((p, i) => {
            tokenitem.paths.push(
                <path key={i} d={p} />
            )
        })
    }
    tokenitem.locked = token.locked;
    tokenitem.dark = Utils.UseNull(tokenvalue.dark, !SC.CurrentTheme.theme.isLight);
}

export const AddOrUpdateIconToken = ({model, isNew, newId}) => {
    let useId = newId;
    if (isNew) {
        useId = Globals.ProjectManager.Tokens.Add({
            type : Globals.ProjectManager.Tokens.Types.Icons,
            id : newId,
            name : model.name,
            value : model.value                 
        });   
    }             

    const tokenitem = {
        id : useId,
        name : model.name
    }
    
    if (model.value.provider === Strings.ICONFINDER) {
        tokenitem.url = `data:image/svg+xml;base64,${model.value.data}`;
    }
    else if (model.value.provider === Strings.CUSTOM) {
        tokenitem.url = model.value.data;
    }
    else if (model.value.provider === Strings.ICON_GOOGLE_MATERIAL) {
        
        tokenitem.paths = [];
        model.value.paths.map((p, i) => {
            tokenitem.paths.push(
                <path key={i} d={p} />
            )
        })
    }   
    return tokenitem;
}

export const GetIconSizeList = (StateArray) => {
    const tokenids = Globals.ProjectManager.Tokens.IconSizes();
    const tokens = Globals.ProjectManager.Tokens.TokenList(Globals.ProjectManager.Tokens.Types.IconSize);
    const iconsizes = [];

    Utils.ForEach(tokenids, (id, i) => {
        const token = tokens[id];
        if (token) {            
            const tokenitem = {
                id : id,
                name : token.name
            }
            const VariableValue = Globals.ProjectManager.Tokens.ValueOf({model : token, statearray : StateArray}) || {};
            tokenitem.value = Utils.UseNull(VariableValue.value, 24);
            iconsizes.push(tokenitem);
        }
    });
    return iconsizes;
}