import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    AppLayout,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../../../../importer';

import DropDownSelect from '../../../../../../components/editors/enum_dropdown';
import { PropertyLabel, Seperator } from '../../../../panels/right/iteminspector/styleitems/common';
import {GroupTitle, EditableName, TokenPanelHeader, TokenItemBox} from '../../../../panels/left/designsystem/common';
import { AnimatePresence, motion } from 'framer-motion';
import {ComponentStateConfigurator} from '../../../../panels/right/iteminspector/styleitems/component/stateConfigurator';
import { GetSubcomponentStateAndModels } from '../../../../panels/right/iteminspector/styleitems/component';
import Switch from '../../../../../../components/editors/Switch';
import ComponentPreviewPropItem from '../../../../panels/right/iteminspector/styleitems/component/previewPropItem';
import ItemThemeOptions from '../themeoptions';

export default class ComponentSettings extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.UpdateItem = this.UpdateItem.bind(this);        
        this.GetStateModel = this.GetStateModel.bind(this);
        this.SetStateVariation = this.SetStateVariation.bind(this);
        
        this.Load(this.props.id);
    }
    UpdateItem() {
        this.props.onUpdateItem(this.props.showItemSettings.ref);
    }    
    SetItemProp(prop, value) {
        Globals.ProjectManager.GetDocumentManager().SetItemValue(this.props.id, value, prop);        
        this.UpdateItem();
        this.RCUpdate();
    }
    
    GetStateModel(id) {
        return this.stateModels[id];
    }
    SetStateVariation(stateId, variationId) {
        if (variationId) {
            Globals.ProjectManager.GetDocumentManager().SetItemValue(this.props.id, variationId, 'variations', stateId);
            Utils.Set(this.variations, variationId, stateId);
        }
        else {
            Globals.ProjectManager.GetDocumentManager().DeleteItemValue(this.props.id, 'variations', stateId);
            Utils.UnSet(this.variations, stateId);
        }
        
        this.UpdateItem();
        this.RCUpdate();
    }
    SetModelValue(modelId, value) {
        Globals.ProjectManager.GetDocumentManager().SetItemValue(this.props.id, value, 'models', modelId, 'value');
        this.UpdateItem();
        this.RCUpdate();
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.id !== this.props.id) {
            this.Load(nextProps.id);
        }
        return true;
    }
    Load(itemId) {
        const data = Globals.ProjectManager.GetDocumentManager().GetItem(itemId);
        const id = Utils.JustGet(data.data, null, 'id');
        const models = Utils.JustGet(data.data, null, 'models');

        this.states = [];
        this.stateModels = {};
        this.models = [];
        this.variations = Utils.JustGet(data.data, {}, 'variations');
        const result = GetSubcomponentStateAndModels(id, models);
        if (result.succuess) {
            this.ComponentId = id;
            this.states = result.states;
            this.stateModels = result.stateModels;
            this.models = result.models;
        }
        
        this.Loaded = true;
    }
    renderCustom() {
        if (!this.Loaded) {
            return null;
        }
        let content, header, overlayContent;

        const data = Globals.ProjectManager.GetDocumentManager().GetItem(this.props.id);
        const showStateSelector = Utils.JustGet(data.data, false, 'showStateSelector');
        const showPropEditor = Utils.JustGet(data.data, false, 'showPropEditor');

        header = (
            <TokenPanelHeader 
                title='COMPONENT SETTINGS' 
                onClose={this.props.onClose} 
                style={{backgroundColor : SC.CurrentTheme.theme.back_lighter}}
            >                   
            </TokenPanelHeader>
        );


        content = (
            <React.Fragment>                    
                <SC.FCol style={{padding : '8px', paddingTop : 0}}>                            
                    <SC.GroupLabel >COMPONENT STATES</SC.GroupLabel>
                    <SC.FRow alc jsb style={{padding : '8px', paddingLeft : 0, cursor : 'pointer'}} onClick={this.SetItemProp.bind(this, 'showStateSelector', !showStateSelector)}>
                        <PropertyLabel>Show State Selector in document</PropertyLabel>
                        <Switch round value={showStateSelector} />
                    </SC.FRow>
                    <ComponentStateConfigurator 
                        states={this.states}
                        variations={this.variations}
                        GetStateModel={this.GetStateModel}
                        onChangeState={this.SetStateVariation}
                    />                    
                </SC.FCol> 
                <Seperator inner/>                   
                <SC.FCol style={{padding : '8px'}}>                            
                    <SC.GroupLabel>PROPERTIES</SC.GroupLabel>
                    <SC.FRow alc jsb style={{padding : '8px', paddingLeft : 0, cursor : 'pointer'}} onClick={this.SetItemProp.bind(this, 'showPropEditor', !showPropEditor)}>
                        <PropertyLabel>Show Property Editor in document</PropertyLabel>
                        <Switch round value={showPropEditor} />
                    </SC.FRow>
                    {
                        (this.models && this.models.length > 0) ? 
                        <div style={{
                            fontSize : '11px',
                            display : 'grid',
                            gridTemplateColumns : 'auto 1fr',
                            gridGap : '8px'
                        }}>
                            {
                                this.models.map((model, i) => {
                                    return (    
                                        <ComponentPreviewPropItem 
                                            model={model}
                                            dataType={model.model.dataType}
                                            name={model.model.name}
                                            value={Utils.JustGet(model.Value, null, 'Value')}
                                            onChange={this.SetModelValue.bind(this, model.id)}
                                        />
                                    )
                                })
                            }
                        </div> : 
                        <div style={{fontSize : '11px'}}>Has no public properties</div>
                    }
                </SC.FCol> 
            </React.Fragment>
        )

        return (
            <SC.FCol f1 style={{backgroundColor : SC.CurrentTheme.theme.back}}>
                {header}
                <SC.CustomScrollbars>         
                    <SC.FCol style={{padding : '8px', paddingBottom : 0}}>
                        <ItemThemeOptions {...this.props} />    
                    </SC.FCol>                    
                    <Seperator inner />           
                    {content}        
                    <AnimatePresence>
                        {overlayContent}
                    </AnimatePresence>              
                </SC.CustomScrollbars>            
            </SC.FCol>
        )
    }
}