import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    AppLayout,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../../../../../importer';

import { GetBorderStyleTokenList, GetBorderStyleTokenListOfIds, GetBorderStyleTokenValue } from '../../../../../panels/left/designsystem/borders';
import { GetTokenAliaseListOfIds, GetTokenAliaseList } from '../../../../../panels/left/designsystem/aliases';

export default class DocumentToken_BorderStyles extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {

        };    
    }
    shouldComponentUpdate(nextProps, nextState) {

        if (this.state.ShouldUpdate !== nextState.ShouldUpdate)
            return true;
        
        if (Utils.HasAnyChange(this.props, nextProps, 'GlobalThemeId', 'GlobalState', 'StyleId', 'RenderId', 'LoadId', 'themeId', 'themeType', 'states', 'ReversedStyleState', 'isAliase'))
            return true;
        
        return false;
    }
    componentDidUpdate(prevProps, prevState) {
        this.state.ShouldUpdate = false;
    }
    render() {        
        let borders;
        if (this.props.isAliase) {
            const aliases = this.props.selection.type === 'selected' ? GetTokenAliaseListOfIds(this.props.selection.tokenIds, this.props.ReversedStyleState) : GetTokenAliaseList(Globals.ProjectManager.Tokens.Types.Borders , this.props.ReversedStyleState);
            borders = [];
            Utils.ForEach(aliases, (aliase, i) => {
                const tokenItem = GetBorderStyleTokenValue(Globals.ProjectManager.Tokens.Token(aliase.tokenId), aliase.tokenId);
                borders.push({
                    id : aliase.id,
                    model : {
                        name : aliase.name
                    },
                    width : tokenItem.width,
                    style : tokenItem.style,
                    color : tokenItem.color
                })
            });
        }
        else {
            borders = this.props.selection.type === 'selected' ? GetBorderStyleTokenListOfIds(this.props.selection.tokenIds, this.props.ReversedStyleState) : GetBorderStyleTokenList(this.props.ReversedStyleState);
        }

        const borderItems = [];

        Utils.ForEach(borders, (border, ) => {                
            borderItems.push(
                <React.Fragment>
                    <div style={{
                        display : 'grid',
                        gridTemplateColumns : '1fr 1fr 1fr',
                        gridGap : '8px',
                        marginTop : '8px',
                    }}>
                        <div style={{fontFamily : 'monospace'}}>{border.model.name}</div>
                        <div style={{fontFamily : 'monospace'}}>{border.style}</div>
                        <div style={{fontFamily : 'monospace'}}>{border.width}</div>
                    </div>
                    <div style={{borderBottomStyle : border.style, borderBottomWidth : border.width, borderBottomColor : border.color, height : '50%', marginTop : '8px', marginBottom : '8px'}} />
                </React.Fragment>
            )

            if (this.props.forTemplateSelect && borderItems.length === 3) {
                return false;
            }
        });

        return (
            <div style={{
                display : 'grid',
                gridColumnGap : '48px',
                gridTemplateColumns : AppLayout.Media.Small ? 'repeat(auto-fit, minmax(280px, 1fr))' : 'repeat(auto-fit, minmax(400px, 600px))',
                gridAutoRows : 'auto',
                justifyContent : 'start',
                alignItems : 'center',
                alignSelf : this.props.alignment,
                width : this.props.size ? Utils.px(this.props.size, '%') : 'auto'
            }}
            >
                <div style={{
                    display : 'grid',
                    gridTemplateColumns : '1fr 1fr 1fr',
                    gridGap : '8px',
                }}>
                    <div>NAME</div>
                    <div>STYLE</div>
                    <div>WIDTH</div>
                </div>
                <div />
                {borderItems}
            </div>
        )     
    }
}