import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    AppLayout,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../../../importer';

import styled, { css} from 'styled-components';
import { motionValue, motion } from 'framer-motion';
import { OutsideCloseDotMenuContainer } from '../../../panels/left/common';

export default class DocumentDesigner extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.AddItem = this.AddItem.bind(this);        
        this.AddItemBefore = this.AddItemBefore.bind(this);
        this.CloneItemBefore = this.CloneItemBefore.bind(this);
        this.CloneItemAfter = this.CloneItemAfter.bind(this);
                
        
        this.onShowMenu = this.onShowMenu.bind(this);        
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);                

        this.Ref_Widget = React.createRef();

        this.isFavorite = Globals.ProjectManager.DocumentManager.IsWidgetFavorite(Utils.JustGet(this.props.context, null, 'id'));
    }    
    componentDidMount() {
        super.componentDidMount();
        this.props.context.widgetRef = this;
        this.DN = ReactDOM.findDOMNode(this.Ref_Widget.current);
        
    }
    componentWillUnmount() {
        super.componentWillUnmount();
        if (this.props.context.widgetRef === this)
            this.props.context.widgetRef = null;
    }
    AddItem() {
        this.props.onAddItem(this.props.parentId, this.props.id);
    }
    AddItemBefore() {
        this.props.onAddItemBefore(this.props.parentId, this.props.id);
    }
    CloneItemBefore() {
        this.props.onCloneItemBefore(this.props.parentId, this.props.id);
    }
    CloneItemAfter() {
        this.props.onCloneItemAfter(this.props.parentId, this.props.id);
    }

    onShowMenu() {
        this.setState({
            showMenu : true
        })
    }
    onMouseLeave() {
        if (this.props.context.dragging || this.mousedown)
            return;
        this.props.context.disabled = false;
    }
    onMouseEnter(e) {
        e.stopPropagation();
        this.props.context.disabled = true;
    }
    onMouseUp() {
        this.mousedown = false;
        document.removeEventListener('mouseup', this.onMouseUp);
    }
    onMouseDown() {
        this.mousedown = true;   
        document.addEventListener('mouseup', this.onMouseUp);
    }
    UpdatePosition(offset) {
        // this.DN.style.transition = 'tranform 0.2s ease';
        // this.DN.style.transform = `translateY(${offset}px)`;
    }
    MoveItemUp() {
        this.props.onMoveUp(this.props.index);
    }
    MoveItemDown() {
        this.props.onMoveDown(this.props.index);
    }
    ToggleFavoriteWidget() {
        const id  = Utils.JustGet(this.props.context, null, 'id');
        if (id) {
            Globals.ProjectManager.DocumentManager.ToggleFavoriteWidget(id);
        }
        
        this.isFavorite = !this.isFavorite;
        this.RCUpdate();
    }
    renderCustom() {        
        const style = {
            position : 'sticky',
            top : '40px', 
            height : 'auto',
            paddingRight : '16px',
            alignItems : 'stretch',
            display : 'flex',                    
            zIndex : 999999,
            width : '100%',
            maxWidth : this.props.width,
            marginLeft : 'auto',
            marginRight : 'auto'
        };

        if (this.props.wizard) {
            style.right = 'unset';
            style.left = 'min(calc(((100% - 822px) / 2) + 24px), ' + this.props.wizard.left + ')';
            style.transform = 'translateY(-50%) translateX(calc(-100% - 24px))'
        }

        const style_button = {
            boxShadow : SC.CurrentTheme.theme.popup_shadow,  
            width : '28px', height : '28px'
        }

        return (
            <div style={style} 
                onMouseEnter={this.onMouseEnter} 
                onMouseLeave={this.onMouseLeave}
                onMouseDown={this.onMouseDown}
                onClick={(e) => e.stopPropagation()}
            >
                <div style={{
                    position : 'absolute',
                    top : '4px',
                    right : this.props.tabItem ? '100%' : 'calc(100% - 24px)',
                    // borderRight : SC.CurrentTheme.theme.border_seperator,
                    display : 'flex',
                    flexDirection : 'column',
                    justifyContent : 'flex-start'
                }}>
                    <div style={{
                        position : 'absolute',
                        bottom : '100%',
                        left : '50%',
                        transform : 'translateY(-14px) translateX(-50%)'
                    }}>
                        <DocWidgetIcon_ButtonBox 
                            title={this.isFavorite ? 'Remove from favorite widgets' : 'Add to favorite widgets'}
                            hasFill fill={this.isFavorite ? SC.CurrentTheme.theme.color_brand_text : null} style={style_button} onClick={this.ToggleFavoriteWidget.bind(this)}>
                            <SC.Icons.FavoriteStar  size={18} />
                        </DocWidgetIcon_ButtonBox>
                    </div>
                    <div style={{                    
                        display : 'grid',
                        gridTemplateColumns : 'auto auto',
                        gridGap : '4px',
                        alignContent : 'center',
                        // transform : 'translateY(-50%)'
                    }} ref={this.Ref_Widget}>
                        <DocWidgetIcon_ButtonBox hasFill style={style_button} onClick={this.CloneItemBefore} title='Insert duplicate above'>
                            <SC.Icons.Icon_Clone size={18} />
                        </DocWidgetIcon_ButtonBox>
                        <DocWidgetIcon_ButtonBox hasFill style={style_button} onClick={this.AddItemBefore} title='Add new block above'>
                            <SC.Icons.Icon_Plus />
                        </DocWidgetIcon_ButtonBox>
                    </div>
                    <div style={{                    
                        display : 'grid',
                        gridTemplateColumns : 'auto auto',
                        gridGap : '4px',
                        alignContent : 'center',
                        paddingTop : '4px',
                        paddingBottom : '4px'
                    }} ref={this.Ref_Widget}>
                        {
                            this.props.index > 0 ?
                            <SC.Icons.Icon_ButtonBox hasFill style={{ ...style_button, cursor : 'pointer'}} onClick={this.MoveItemUp.bind(this)}>
                                <SC.Icons.Icon_Arrow expanded size={12} />
                            </SC.Icons.Icon_ButtonBox> : <div />
                        }                        
                        <div />
                        <SC.Icons.Icon_ButtonBox  {...this.props.dragProps}  hasFill style={style_button}>
                            <SC.Icons.DragIcon size={18} />
                        </SC.Icons.Icon_ButtonBox>
                        <DocWidgetIcon_ButtonBox hasFill style={style_button} onClick={this.props.onDelete}>
                            <SC.Icons.Icon_Delete  size={18}/>
                        </DocWidgetIcon_ButtonBox>
                        {
                            !this.props.last && 
                            <SC.Icons.Icon_ButtonBox hasFill style={{ ...style_button, cursor : 'pointer'}} onClick={this.MoveItemDown.bind(this)}>
                                <SC.Icons.Icon_Arrow size={12} />
                            </SC.Icons.Icon_ButtonBox>
                        }                        
                    </div>
                    <div style={{                    
                        display : 'grid',
                        gridTemplateColumns : 'auto auto',
                        gridGap : '4px',
                        alignContent : 'center',
                        // transform : 'translateY(50%)'
                    }} ref={this.Ref_Widget}>
                        <DocWidgetIcon_ButtonBox hasFill style={style_button} onClick={this.CloneItemAfter} title='Insert duplicate below'>
                        <SC.Icons.Icon_Clone size={18} />
                    </DocWidgetIcon_ButtonBox>
                    <DocWidgetIcon_ButtonBox hasFill style={style_button} onClick={this.AddItem} title='Add new block above'>
                        <SC.Icons.Icon_Plus />
                    </DocWidgetIcon_ButtonBox>
                    </div>
                </div>
            </div>
        );
       
    }
}

export const DocWidgetIcon_ButtonBox = styled.div`
    display : flex;
    justify-content : center;
    align-items : center;
    cursor : pointer;
    fill : ${props => props.hasFill ? (props.disabled || props.selected || props.fill || (props.light ? props.theme.icon_light : props.theme.icon) ) : 'none'};
    stroke : ${props => props.hasStroke ? (props.disabled || props.selected || props.fill || props.theme.icon) : 'none'};
    background-color : ${props => props.theme.back_lighter};
    min-width : 24px;
    min-height : 24px;
    border-radius : 2px;    
    &:hover {
        fill : ${props => props.hasFill ? (props.hoverFill || props.theme.icon_hover || '#AEAEAE') : 'none'};
        stroke : ${props => props.hasStroke ? (props.hoverStroke|| props.theme.icon_hover || '#AEAEAE') : 'none'};
        background-color : ${props => props.theme.back_lightest};
    }
`