import React from 'react';
import ReactDOM from 'react-dom';
import {Redirect} from 'react-router-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    AppLayout,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../../importer';

import { AnimatePresence, motion } from 'framer-motion';
import LeftPanel from '../designer/leftpanel';
import DocumentItem from '../designer/item';
import {LightDarkToggle} from '../../../header';
import PageHero from '../designer/hero';

import SignIn from '../../../auth';

export default class SharedDocumentPreview extends ReactBaseComponent
{
    constructor(props) {
        super(props);        

        this.LoadPage = this.LoadPage.bind(this);
        this.Ref_Scroll = React.createRef();

        this.ToggleLightDarkMode = this.ToggleLightDarkMode.bind(this);
        super.ListenEvents(
            Events.SESSION,
            Events.GLOBAL.MEDIASIZE.CHANGED, 
            Events.GLOBAL.STATE_CHANGED
        );

        this.GlobalStateId = Utils.Id(); 
        this.GlobalThemeId = Utils.Id();     

        this.Reload = this.Reload.bind(this);        
        this.ReloadThrottled = Utils.Throttle(this.Reload, 200);
        this.ProjectId = Utils.JustGet(this.props, null, 'match', 'params', 'bid');        
        this.ThemeOption = 'all';
        this.Load(this.ProjectId);
        
    }
    ToggleLightDarkMode() {
        if (SC.CurrentTheme.theme.isLight) 
            AppLayout.Theme.Current = AppLayout.Theme.Dark;
        else
            AppLayout.Theme.Current = AppLayout.Theme.Light;
        
        
        let LocalOptions;
        try {
            LocalOptions = JSON.parse(localStorage.ToolabsSharedDSDocument);            
        } catch (error) {
            if (error) {

            }
        }
        if (!LocalOptions || !Utils.IsObject(LocalOptions))
            LocalOptions = {};
        LocalOptions.LightTheme = !SC.CurrentTheme.theme.isLight;
        if (localStorage)
            localStorage.ToolabsSharedDSDocument = JSON.stringify(LocalOptions);    
        
        Events.BCE(Events.GLOBAL.THEME.CHANGED);
    }  
    OnEvent() {
        if (Events.SESSION) {
            [this.Authenticated] = Params;
            this.RCUpdate();
        }
        else {
            Globals.ProjectManager.GetDocumentManager().BuildStyles(this, SC.CurrentTheme.theme.isLight);
            this.RCUpdate();
        }        
    }
    Load(BoardId, props = this.props) {
        if (BoardId === 'custom') {            
            this.linkId = Utils.JustGet(props, null, 'match', 'params', 'pageId');
            Globals.ProjectManager.GetDocumentManager().GetLinkData(this.linkId).then((result) => {
                if (result && result.value) {
                    this.ProjectId = result.value.projectId;
                    this.Load(this.ProjectId, props);
                }
            })
            return;
        }
        Globals.ProjectManager.Load({
            ProjectId : BoardId,
            Readonly : true,
            SharedDocument : true
        }).then((result) => {
            if (result) {                                 
                Globals.ProjectManager.GetDocumentManager().Load('Default').then((result) => {                    
                    if (this.linkId) {
                        const linkData = Globals.ProjectManager.GetDocumentManager().GetSharedLink(this.linkId);
                        if (linkData && linkData.data) {
                            this.ThemeOption = Utils.JustGet(linkData.data, 'all', 'theme');
                            if (this.ThemeOption === 'light' && AppLayout.Theme.Current !== 'Light') {
                                AppLayout.Theme.Current = 'Light';   
                                Events.BCE(Events.GLOBAL.THEME.CHANGED);
                            }
                            else if (this.ThemeOption === 'dark' && AppLayout.Theme.Current === 'Light') {
                                AppLayout.Theme.Current = 'Dark';   
                                Events.BCE(Events.GLOBAL.THEME.CHANGED);
                            }
                            if (linkData.data.type === 'fixedTheme') {
                                Globals.ProjectManager.States.ToState(Utils.JustGet(linkData.data, {}, 'states'));                                
                            }       
                            
                            if (linkData.data.authenticate) {
                                this.AskForAuthenticate = true;
                            }
                        }                    
                    }   
                    else {
                        const options = Globals.ProjectManager.GetDocumentManager().GetShareOptions();    
                        this.ThemeOption = Utils.JustGet(options, 'all', 'theme');
                        if (this.ThemeOption === 'light') {
                            AppLayout.Theme.Current = 'Light';   
                            Events.BCE(Events.GLOBAL.THEME.CHANGED);
                        }
                        this.AskForAuthenticate = Utils.JustGet(options, false, 'authenticate');;
                    }                    
                    this.Loaded = true;
                    if (this.AskForAuthenticate && AppState.UserInfo) {
                        this.Authenticated = true;
                    }
                    Globals.ProjectManager.GetDocumentManager().BuildStyles(this, SC.CurrentTheme.theme.isLight);
                    this.RCUpdate();
                    
                    // if (!this.isChangeListenerBounds) {
                    //     this.isChangeListenerBounds = true;
                    //     Globals.ProjectManager.GetDocumentManager().ListenChanges(() => {
                    //         this.ReloadThrottled();
                    //     })
                    // }                    
                })              
            }       
            else {
                super.RedirectTo('/');
            }         
        });
    }
    Reload() {
        Globals.ProjectManager.Load({
            ProjectId : this.ProjectId
        }).then((result) => {
            if (result) {                    
                Globals.ProjectManager.GetDocumentManager().Load('Default').then((result) => {                    
                    Globals.ProjectManager.GetDocumentManager().BuildStyles(this, SC.CurrentTheme.theme.isLight);
                    this.RenderId = Utils.Id();
                    this.RCUpdate();
                })              
            }       
            else {
                super.RedirectTo('/');
            }         
        });
    }
    LoadPage(pageId) {
        if (this.linkId) {
            this.WillLoadPage = '/custom/'+ this.linkId + '/'+pageId;
        }
        else {
            this.WillLoadPage = '/'+ Globals.ProjectManager.Id + '/'+pageId;
        }        
        this.RCUpdate();
        return;
        super.RedirectTo('/'+ Globals.ProjectManager.Id + '/'+pageId);
        return;
        Globals.ProjectManager.GetDocumentManager().SelectPage(pageId);
        
        if (this.Ref_Scroll.current)
            this.Ref_Scroll.current.scrollTop(0);

        this.RCUpdate();
    }
    componentDidUpdate(prevProps, prevState) {
        delete this.WillLoadPage;
    }
    shouldComponentUpdate(nextProps, nextState) {
        const id = Utils.JustGet(nextProps, null, 'match', 'params', 'bid');
        if (id === 'custom') {
            const linkId = Utils.JustGet(nextProps, null, 'match', 'params', 'pageId');
            if (this.linkId !== linkId) {
                this.Load('custom', nextProps);
                return false;
            }
        }
        else if (!this.linkId || id !== 'custom') {            
            if (id !== this.ProjectId) {
                this.ProjectId = id;
                this.Load(this.ProjectId, nextProps);
                return false;
            }
            const pageId = Utils.JustGet(nextProps, null, 'match', 'params', 'pageId');
            if (pageId && pageId !== Globals.ProjectManager.GetDocumentManager().CurrentPageId) {
                Globals.ProjectManager.GetDocumentManager().SelectPage(pageId);
                if (this.Ref_Scroll.current)
                    this.Ref_Scroll.current.scrollTop(0);
                return true;
            }
        }
        else {
            const pageId = Utils.JustGet(nextProps, null, 'match', 'params', 'customId');
            if (pageId && pageId !== Globals.ProjectManager.GetDocumentManager().CurrentPageId) {
                Globals.ProjectManager.GetDocumentManager().SelectPage(pageId);
                if (this.Ref_Scroll.current)
                    this.Ref_Scroll.current.scrollTop(0);
                return true;
            }
        }
        
        if (this.props.themeId !== nextProps.themeId) {
            Globals.ProjectManager.GetDocumentManager().BuildStyles(this, SC.CurrentTheme.theme.isLight);
        }
        return true;
    }
    renderCustom() {
        let content;

        if (this.Loaded) {

            if (this.AskForAuthenticate && !this.Authenticated) {
                return (
                    <SignIn onLoggedIn={() => {
                        this.Authenticated = true;
                        this.RCUpdate();
                    }} />
                )
            }

            const GlobalState = Globals.ProjectManager.States.CurrentStateVariation;

            const itemIds = Globals.ProjectManager.GetDocumentManager().GetItemIds();

            const contentWidth = `min(calc(100% - 100px), calc(${this.DocumentStyles.width}%))`;

            content = (
                <React.Fragment>
                    {
                        this.ThemeOption === 'all' && 
                        <div style={{
                            position : 'fixed',
                            top : '0', right : '0', zIndex : 99999,
                            width : '60px',
                            height : '48px',
                            cursor : 'pointer',
                            ...SC.Styles.Flex.Cell
                        }} onClick={this.ToggleLightDarkMode}>
                            <LightDarkToggle dark={!SC.CurrentTheme.theme.isLight} style={{marginRight : 0}} />
                        </div>
                    }
                    
                    <LeftPanel 
                        key='LeftPanel'
                        loaded={this.Loaded}
                        onSelectPage={this.LoadPage}
                        selectedPageId={Globals.ProjectManager.GetDocumentManager().CurrentPageId}
                        GlobalThemeId={this.props.GlobalThemeId}
                        GlobalState={this.props.GlobalState}
                        panelStyle={this.textStyles.leftPanel}
                        backgroundHighlight={this.textStyles.backgroundHighlight}
                        themeId={this.props.themeId}
                        preview
                        shared
                        mobile={AppLayout.Media.Small}
                        RenderId={this.RenderId}
                    />                
                    <SC.FCol f1 style={{position : 'relative'}}>
                        <SC.CustomScrollbars 
                            big
                            ScrollRef={this.Ref_Scroll}
                            onScrollStop={this.onScrollFrame}
                        >
                            <PageHero 
                                preview
                                heroId={Globals.ProjectManager.GetDocumentManager().GetHeroId()}
                                ItemProps={{
                                    textStyles : this.textStyles,
                                    context : {},
                                    GlobalThemeId : this.GlobalThemeId,
                                    GlobalState : GlobalState,
                                    mobile : AppLayout.Media.Mobile,
                                    RenderId : this.RenderId,
                                    preview : true,
                                    width : AppLayout.Media.Mobile ? '100%' : contentWidth
                                }}
                            />     
                            <div style={{paddingTop : Utils.px(Math.max(20, Utils.Get(this.DocumentStyles, 80, 'topOffset')) + 20)}} />
                            <SC.FCol style={{
                                color : this.style_document_body.textColor,                                
                                paddingBottom : '90px',
                                minHeight : '100vh',
                                ...this.textStyles.body,
                                backgroundColor : this.style_document_body.backgroundColor,
                            }}>          
                                     
                                {
                                    itemIds.map((itemId, index) => {
                                        const isLayoutItem = Globals.ProjectManager.GetDocumentManager().GetItemValue(itemId, false, 'layoutId');
                                        if (isLayoutItem) 
                                            return null;
                                        return (
                                            <DocumentItem 
                                                key={itemId}
                                                id={itemId}
                                                index={index}
                                                width={AppLayout.Media.Mobile ? '100%' : contentWidth}
                                                textStyles={this.textStyles}
                                                context={{}}
                                                GlobalThemeId={this.GlobalThemeId}
                                                GlobalState={GlobalState}
                                                themeId={this.props.themeId}
                                                preview
                                                shared
                                                mobile={AppLayout.Media.Mobile}
                                                RenderId={this.RenderId}
                                                ItemProps={{
                                                    textStyles : this.textStyles,
                                                    context : {},
                                                    GlobalThemeId : this.GlobalThemeId,
                                                    GlobalState : GlobalState,    
                                                    preview : true,
                                                    shared : true                                   
                                                }}
                                            /> 
                                        )
                                    })
                                }                  
                            </SC.FCol>                  
                        </SC.CustomScrollbars>                                        
                    </SC.FCol>  
                </React.Fragment>
            )
        }

        return (
            <React.Fragment>
                { this.WillLoadPage && <Redirect push to={this.WillLoadPage} /> }
                <AnimatePresence>
                    {
                        !this.Loaded && 
                        <SC.Div_Flex_Cell style={{...SC.Styles.Absolute}}>
                            <SC.Loading_Icon />
                        </SC.Div_Flex_Cell>
                    }                   
                    {
                        this.Loaded && 
                        <motion.div
                            style={{
                                width : '100%',
                                height : '100%',
                                backgroundColor : this.style_document_body.backgroundColor,
                                display : 'flex',
                                alignItems : 'stretch',
                                userSelect : 'text'
                            }}
                            initial={{opacity : 0.}}
                            animate={{opacity : 1}}
                            exit={{opacity : 0}}
                            transition={{duration : 0.6}}
                        >
                            {content}
                        </motion.div>
                    }
                </AnimatePresence>
            </React.Fragment>            
        )
    }
}