import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Events
} from '../../importer';

import styled, { css } from 'styled-components';

const FormInputContainer = styled(SC.FCol) `
    height:75px;
    position:relative;
`;
const Label = styled.div`
    opacity : ${(props) => props.value ? 1 : 0}; 
    margin-bottom : 4px;
    font-size : 13px;

`;
const ClearButton = styled.div`
    opacity : ${(props) => props.value ? 1 : 0}; 
    position : absolute;
    text-align: left;
    right : 0px;
    top : 2px;
`;
const Invalid = styled.div`
    display:flex;
    align-items: center;
    justify-content: flex-end;
    color: ${props => props.theme.card_input_error_border_color};
    font-size : 11px;
    font-weight:400;
    padding-top: 3px;
`;
const Input = styled.input`
    background-color : ${props => props.theme.input_back};
    font-size : 14px;
    border-radius : 4px;
    ::placeholder {
        font-style: italic;
        font-weight:300;
    }
    &:focus {
        border : ${props => props.theme.border_brand};
    }
    ${
    (props) => {
        if (props.isInvalid) {
            return css`
                    border: 1px solid ${props.theme.card_input_error_border_color};
                `
        }
        else {
            return css`
                border : ${props => props.theme.border_onlight_line};
            `
        }
    }
    }
    min-height : 42px;
    padding-left : 12px;
    padding-right : 12px;
    color : ${props => props.theme.font};
    letter-spacing : 0.7px;
    box-sizing : border-box;
`;


class FormInput extends ReactBaseComponent {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.OnKeyDown = this.OnKeyDown.bind(this);
        this.OnKeyUp = this.OnKeyUp.bind(this);
        this.state =
            {
                value: this.props.value ? this.props.value : '',
                error: "",
                showHideText: "show"
            };
        super.ListenEvents(Events.GLOBAL.MEDIASIZE.CHANGED);
    }
    OnEvent() {
        this.RCUpdate();
    }
    componentDidMount() {
        super.componentDidMount();
        if (this.props.autoFocus) {
            setTimeout(() => {
                this.Ref_Email && this.Ref_Email.focus();
            }, 10);
        }
    }
    OnKeyDown(e) {
        e.stopPropagation();
        return true;
    }
    OnKeyUp(e) {
        e.stopPropagation();
        if (e.charCode === 13 || e.keyCode === 13) {
            this.validate();
            this.props.onSubmit();
        }
        else if (this.props.onCancel && e.keyCode === 27) {

            this.RCUpdate();
        }

        return true;
    }
    focus()
    {
        if(this.Ref_Email)
            this.Ref_Email.focus();
    }
    onBlur() {
        this.validate();
    }
    onChange(e) {
        this.state.error = "";
        this.state.value = e.target.value;
        this.setState(this.state);
        if(this.props.onChange)
            this.props.onChange(e.target.value);
        this.RCUpdate();
    }
    validate() {
        this.state.error = "";
        if (this.props.type == "password" && !this.props.passwordConfirm) {
            if (!this.state.value || this.state.value && this.state.value.length < 6)
                this.state.error = "Password must be minimum of 6 character";
        }
        if (this.props.passwordConfirm) {
            if (this.props.password && this.state.value !== this.props.password)
                this.state.error = "Password's doesn't match";
        }
        else if (this.props.type == "name") {
            if (this.state.value.length === 0)
                this.state.error = "Name is required";
            if (this.state.value.length < 3)
                this.state.error = "Name is too short";
        }
        else if (this.props.type == "email") {
            if (this.state.value.length === 0)
                this.state.error = "Email is required";
            else if (this.state.value.length < 3 || this.state.value.indexOf("@") < 1) //  || this.state.value.indexOf(".") < 2
                this.state.error = "Email is invalid";
        }
        this.setState(this.state);
        return this.state.error.length > 0 ? false : true;
    }
    getValue() {
        return this.state.value;
    }
    showHidePassword = () => {
        var input = ReactDOM.findDOMNode(this.refs.input);
        if (input.type == "password") {
            input.type = "";
            this.setState({ showHideText: "hide" });
        }
        else {
            input.type = "password";
            this.setState({ showHideText: "show" });
        }
    }
    renderCustom() {
        var clearButton = null;
        var passworShowIcon = <SC.Svg id="Layer_1"  width="14px" height="14px" viewBox={"0 0 17 11"}>
            <path fill="#7B7C7D" d="M16.3 5.1C14.7 2 11.7.8 8.5.8S2.3 2 .7 5.1l-.2.4.2.4c1.6 3.1 4.6 4.3 7.8 4.3s6.2-1.2 7.8-4.3l.2-.4-.2-.4zm-14.7.5c1-1.3 2.2-2.5 3.6-3.4-.7.9-1.1 2-1.1 3.1-.1 1.3.3 2.6 1.1 3.6-1.4-.9-2.6-2-3.6-3.3zm6.9 3.9c-1.8 0-3.3-1.8-3.3-4s1.5-4 3.3-4c1.8 0 3.3 1.8 3.3 4s-1.5 4-3.3 4zm3.3-.6c.8-1 1.2-2.3 1.1-3.6 0-1.1-.4-2.2-1.1-3.1 1.4.9 2.6 2.1 3.6 3.4-1 1.3-2.2 2.4-3.6 3.3z" />
            <path fill="#7B7C7D" d="M.5 11l-.5-.7L16.5 0l.5.   7z" />
        </SC.Svg>;

        var passworHideIcon = <SC.Svg id="Layer_1" width="14px" height="14px" viewBox={"0 0 16 9.4"}>
            <path fill="#A6A9AE" d="M15.8 4.3C14.2 1.2 11.2 0 8 0S1.8 1.2.2 4.3l-.2.4.2.4C1.8 8.2 4.8 9.4 8 9.4s6.2-1.2 7.8-4.3l.2-.4-.2-.4zM8 8.7c-1.8 0-3.3-1.8-3.3-4S6.2.7 8 .7s3.3 1.8 3.3 4-1.5 4-3.3 4zm-6.8-4C2 3.6 3.1 2.7 4.4 2c-.4.7-.6 1.6-.6 2.4-.2 1.3.3 2.5 1.2 3.3-1.5-.6-2.8-1.7-3.8-3zm10.1 3.4c.8-1 1.2-2.3 1.1-3.6 0-1.1-.4-2.2-1.1-3.1 1.4.9 2.6 2.1 3.6 3.4-1 1.3-2.2 2.4-3.6 3.3z" />
        </SC.Svg>;
        if (this.props.type == "password") {
            clearButton = (
                <SC.FRow onClick={() => this.showHidePassword()}
                    style={{ color: this.state.showHideText == "show" ? "#A6A9AE" : "#7B7C7D", cursor: "pointer", position: "absolute", top: 2, right: 0, fontSize: 11 }} >
                    <div style={{textAlign:"right",marginRight:22}}>{this.state.showHideText}</div>
                    <div style={{ position: "absolute", right: 5,top:0 }}>
                        {this.state.showHideText == "show" ? passworHideIcon : passworShowIcon}
                    </div>
                </SC.FRow>
            )
        }
        return (
            <FormInputContainer style={this.props.style} >
                <Label value={this.state.value}>{this.props.label}</Label>
                <ClearButton value={this.state.value}>
                    {clearButton}
                </ClearButton>
                <Input
                    ref={"input"} 
                    value={this.state.value}
                    isInvalid={this.state.error}
                    onKeyDown={this.OnKeyDown}
                    onKeyUp={this.OnKeyUp}
                    onBlur={this.onBlur}
                    onChange={this.onChange}
                    placeholder={this.props.label}
                    type={this.props.type}
                />
                {this.state.error && <Invalid isInvalid={this.state.error}>{this.state.error}</Invalid>}
            </FormInputContainer>
        );
    }
}

export default FormInput;