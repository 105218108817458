import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Strings,
    UIUtils
} from '../../importer';

import Scrollbars from 'react-custom-scrollbars';
import {ImageThumbnail} from '../onlineImages';

import get from 'simple-get';
import { resolve } from 'url';

let UnsplashJs = null;
let Unsplash = null;
let ToJson = null;
export const LoadUnsplashJs = (callback) => {
    return new Promise((resolve) => {
        if (!UnsplashJs) {
            const p = [import('unsplash-js')];
            Promise.all(p).then((r) => {
                UnsplashJs = r[0].default;
                ToJson = r[0].toJson;
                Unsplash = new UnsplashJs({
                    accessKey: "7c4b8fbb3b76b4350db3610cf9b2f580ca1d1a61c06012da028b7d787af2325c",
                    secret: "74d36919aa77d2124f5803b41e5c8a685b7026f66310c8e24f66f22d56599c07",
                    callbackUrl: "http://http://localhost:3000/#/"
                  });
                  resolve();
            })
        }
        else    
            resolve();        
    })
    
}


let API_Loading = false;
let API_LoadingPage = 0;
let API_LoadingPromise;
let API_SearchOptions = {

};

const ConvertImageJosn = (imgdata) => {
    return {
        id : imgdata.id,
        preview : imgdata.urls.thumb,
        url : imgdata.urls.regular,
        width : imgdata.width,
        height : imgdata.height,
        authorName : Utils.JustGet(imgdata.user, '', 'name'),
        authorLink : Utils.JustGet(imgdata.user, '', 'links', 'html'),
        downloadData : imgdata
    }
};

export const API_Unsplash = {
    IsLoading() {
        return API_Loading;
    },
    Random : ({filter, count, proxy}) => {
        return new Promise((resolve) => {
            LoadUnsplashJs().then(() => {
                Unsplash.photos.getRandomPhoto({ query: filter, count : count }).then(
                    (res) => {
                        if (res) {
                            if (res.status === 403) {
                                API_Loading = false;
                                API_LoadingPromise = null;                                                           
                            }
                            else {
                                ToJson(res).then(
                                    json => resolve({
                                        proxy : proxy,
                                        value : json
                                    })
                                )                                 
                            }                                    
                        }
                        else {
                            API_Loading = false;
                            API_LoadingPromise = null;                          
                        }
                    }
                );
            });
        })
    },    
    TriggerDownload : (downloadData) => {
        LoadUnsplashJs().then(() => {
            Unsplash.photos.downloadPhoto(downloadData).then(
                (res) => {
                    if (res) {
                                                            
                    }                    
                }
            );
        });
    },
    Search : (options) => {                
        if (API_LoadingPromise) {
            return API_LoadingPromise;
        }
        if (!Utils.IsEqual(API_SearchOptions, options)) {
            API_LoadingPage = 0;
            API_SearchOptions = Utils.DeepClone(options);
        }
        API_Loading = true;
        API_LoadingPage += 1;
        const that = this;

        API_LoadingPromise = new Promise((resolve, reject) => {
            LoadUnsplashJs().then(() => {
                let unsplash_promise = null;
                const query = Utils.Get(API_SearchOptions, '', 'SearchText');
                const handle = (json) => {
                    if (json) {
                        let result = {
                            query : query,
                            total : json.total,
                            total_page : json.total_pages,                                
                            images : []
                        }
                        Utils.ForEach(json.results, (imgdata, i) => {
                            imgdata.urls && result.images.push(ConvertImageJosn(imgdata));
                        });

                        API_Loading = false;
                        API_LoadingPromise = null;
                        resolve(result);
                    }
                }
                if (Utils.IsNotNullOrEmpty(query)) {
                    Unsplash.search.photos(query, API_LoadingPage || 1, 20)
                        .then(
                            (res) => {
                                if (res) {
                                    if (res.status === 403) {
                                        API_Loading = false;
                                        API_LoadingPromise = null;
                                        reject({});                                    
                                    }
                                    else {
                                        ToJson(res).then(
                                            json => handle(json)
                                        )                                 
                                    }                                    
                                }
                                else {
                                    API_Loading = false;
                                    API_LoadingPromise = null;
                                    reject({});                                    
                                }
                            }
                        )
                }                    
                else {
                    Unsplash.photos.listPhotos(API_LoadingPage ||1, 20)
                        .then(ToJson)
                        .then(json => {
                            let result = {                                
                                total_mesasge : 'listing curated photos',                     
                                images : []
                            }
                            Utils.ForEach(json, (imgdata, i) => {
                                imgdata.urls && result.images.push(ConvertImageJosn(imgdata));                                
                            });
    
                            API_Loading = false;
                            API_LoadingPromise = null;
                            resolve(result);
                        });
                    }
            })                        
        });
        return API_LoadingPromise;        
    }
};

export class SelectedImageList extends ReactBaseComponent
{
    constructor(props) {
        super(props);
    }
    Update(images) {
        this.images = images;
        this.RCUpdate();
    }
    renderCustom() {    
        let selection = '';
        const images = this.images || this.props.images || [];
        if (images === 0)
            return null;
        
        return (
            <SC.FCol f1 fh style={{marginLeft : '-8px', marginRight : '-8px'}}>                 
                <SC.CustomScrollbars hideTracksWhenNotNeeded autoHide>
                    <SC.FCol style={{paddingLeft : '8px', paddingRight : '8px'}}>
                        {
                            images.map((img) => {
                                return (
                                    <SC.FCol
                                        alc
                                        key={img.id}
                                        style={{
                                            width : '100%', height : '150px', 
                                            overflow : 'hidden', cursor : 'pointer', 
                                            marginBottom : '8px',
                                            backgroundColor : SC.CurrentTheme.theme.back,
                                            padding : '4px',
                                            boxSizing : 'border-box',
                                            cursor : 'pointer',
                                            position : 'relative'
                                        }}
                                        onClick={() => this.props.onSelect(img)}
                                    >
                                        <img 
                                            draggable={false}
                                            style={{
                                                height : '100%',
                                                marginBottom : '-20px' 
                                            }}
                                            src={img.preview}
                                        />
                                        <SC.Overlay>
                                            Click to deselect
                                        </SC.Overlay>
                                    </SC.FCol>
                                )
                            })
                        }
                    </SC.FCol>
                </SC.CustomScrollbars>
            </SC.FCol>
        )
    }
}
class UnsplashGallery extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.refreshIndex = 0;
        this.LoadPage = this.LoadPage.bind(this);
        this.Search = this.Search.bind(this);
        this.CancelSearch = this.CancelSearch.bind(this);        
        this.Select = this.Select.bind(this);

        this.FilterText = this.props.filterText;
    }
    componentDidMount() {
        super.componentDidMount();
        this.LoadPage();     
    } 
    Select(img) {
        const {downloadData, ...imageData} = img;
        API_Unsplash.TriggerDownload(downloadData);

        if (this.props.options.single) {
            this.props.options.onSelect(imageData);
            this.props.onClose && this.props.onClose();
            return;
        }
        const selectedImages = Utils.Get(this, {}, 'selectedImages');
        if (selectedImages[imageData.id])
            delete selectedImages[imageData.id];
        else
            selectedImages[imageData.id] = imageData;
        
        if (this.props.onUpdateSelection)
            this.props.onUpdateSelection();
        this.RCUpdate();
    }
    GetSelectedImages() {
        const selectedimages = [];
        Utils.ForEach(this.selectedImages, (img, id) => {
            selectedimages.push(img);
        });
        return selectedimages;
    }
    shouldComponentUpdate = (nextProps, nextState) => {
        if (nextProps.filterText !== this.props.filterText) {
            this.Search(nextProps.filterText);
        }
        return true;
    }
    
    Search(s) {
        if (s !== this.FilterText) {
            this.FilterText = s;
            this.LoadPage();
        }        
    }
    CancelSearch() {
        if (Utils.IsNotNullOrEmpty(this.FilterText)) {
            delete this.images;
            this.FilterText = '';
            this.LoadPage();
        }        
    }
    LoadPage() {

        const that = this;
        if (API_Unsplash.IsLoading())
            return;
        if (this.FilterText !== this.LastFilter)
            delete this.images;
        this.LastFilter = this.FilterText;
        
        API_Unsplash.Search({
            SearchText : this.FilterText
        }).then((result) => {
            if (Utils.UseNullOrEmpty(result.query, '') !== Utils.UseNullOrEmpty(that.LastFilter, '')) {
                delete that.images;
                that.LoadPage();
                return;
            }
            this.total = result.total;
            const images = Utils.Get(that, this.props.options.single? [[],[],[],[],[]] : [[],[],[],[],[]] , 'images');
            let tempIndex = images[0].length;
            Utils.ForEach(result.images, (img, i) => {
                images[i % (this.props.options.single ? 5 : 5)].push(img);
            });
            this.refreshIndex = tempIndex;
            that.RCUpdate();            
        })      
    }

    renderCustom() {        
        const selectedimages = [];
        Utils.ForEach(this.selectedImages, (img, id) => {
            selectedimages.push(img);
        });
        // let selection = null;
        // if (selectedimages.length > 0) {
        //     selection = (
        //         <SC.FRow style={{fontSize : '16px', cursor:'pointer'}} onClick={
        //             () => {                            
        //                 this.props.options.onSelect(selectedimages);
        //                 this.props.onClose();
        //             }
        //         }>
        //             <SC.FRow>
        //                 {`${selectedimages.length} images selected`}
        //             </SC.FRow>
        //             <SC.Div_Flex_Cell pl={20} >
        //                 <SC.Icons.Card_Icon_Save selected hoverColor={SC.CurrentTheme.theme.card_editicon_hover} size={18} />
        //             </SC.Div_Flex_Cell>    
        //         </SC.FRow>
        //     )
        // }
        let imageItems;

        if (this.images) {
            imageItems = this.images.map((col, i) => {
                return (
                    <SC.FCol key={i}>
                        {
                            col.map((img, j) => {
                                return (
                                    <ImageThumbnail 
                                        key={img.id} 
                                        url={img.preview} 
                                        authorName={img.authorName}
                                        authorLink={img.authorLink}
                                        last={j===this.refreshIndex}
                                        onLoadPage={this.LoadPage}
                                        hasSelect={{
                                            onSelect : this.Select.bind(this, img),
                                            selected : Utils.Get(this, null, 'selectedImages', img.id) ? true : false
                                        }}
                                    />
                                )
                            })
                        }                                            
                    </SC.FCol>
                )
            })
        }
        else {
            imageItems = [];
            for (let i=0; i<20; i++) {
                imageItems.push(
                    <div key={i} style={{minHeight: '100px', backgroundColor:'rgba(0,0,0,0.1)'}} />
                )
            }
        }
        return (
            <SC.FCol f1 fw fh>            
                <SC.FRow f1>
                    <SC.FCol style={{flex:5}}>
                        <SC.CustomScrollbars hideTracksWhenNotNeeded autoHide>
                            <SC.FCol>
                            <div style={{
                                display : 'grid',
                                gridGap : '4px',
                                paddingLeft : '8px',
                                paddingRight : '16px',
                                gridTemplateColumns : `repeat(${this.props.options.single ? 5 : 5}, 1fr)`
                            }}>
                                {imageItems}
                            </div>                                             
                            </SC.FCol>
                        </SC.CustomScrollbars>                    
                    </SC.FCol> 
                </SC.FRow>
            </SC.FCol>
        )
    }
}

export default UnsplashGallery;