import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Strings,
    Loading,
    Events,
    Globals
} from '../../../../../../importer';
import styled, {css} from 'styled-components';
import { ItemNameEditor, TokenItemBox, TokenGalleryView, DropFileArea, DropAreaContainer, TokenPanelHeader } from '../common';
import { LeftScrollPanel } from '../../common';
import TextValue from '../../../../../../components/editors/textvalue';
import Parameters from './parameters';


export default class TextContentEditor extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.SaveName = this.SaveName.bind(this);
        this.GetItems = this.GetItems.bind(this);

        this.Ref_Name = React.createRef();
        this.onChangeContent = this.onChangeContent.bind(this);
        this.onChangingContent = this.onChangingContent.bind(this);


        if (this.props.newModel) {
            this.token = this.props.newModel;
            this.tokenvalue = this.props.defaultValue || '';
        }
        else {
            this.token = Globals.ProjectManager.Tokens.Token(this.props.id);
            this.tokenvalue = Utils.UseNullOrEmpty(Globals.ProjectManager.Tokens.ValueOf({model : this.token}), '');
        }

        this.parameters = Utils.JustGet(this.token, [], 'parameters');
        this.SaveParameters = this.SaveParameters.bind(this);
    }
    componentWillUnmount() {
        this.props.onClosed && this.props.onClosed();
        super.componentWillUnmount();        
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.GlobalState !== nextProps.GlobalState || this.props.GlobalStateId !== nextProps.GlobalStateId || this.props.id !== nextProps.id || this.props.renderId !== nextProps.renderId) {
            if (!nextProps.newModel) {
                this.token = Globals.ProjectManager.Tokens.Token(nextProps.id);
                if (!this.token) {
                    this.props.onCancelAddToken();
                    return false;
                }
                this.tokenvalue = Globals.ProjectManager.Tokens.ValueOf({model : this.token}) || {};                
            }            
        }
        return true;
    }
    GetItems() {
        return Globals.ProjectManager.Tokens.TokenList(Globals.ProjectManager.Tokens.Types.ContentTexts);
    }        
    
    SaveName(name) {
        if (this.props.newModel) {
            this.props.newModel.name = name;
            this.nameChanged = true;
        }
        else
            Globals.ProjectManager.Tokens.ChangeTokenName(this.props.id, name);
        this.RCUpdate();
    }        
    onChangeContent(value) {
        if (this.props.newModel) {
            this.props.newModel.value = value;
            this.props.onPreviewChange && this.props.onPreviewChange(value);
        }
        else {
            Globals.ProjectManager.Tokens.SetValue({id : this.props.id, value : value});
            Events.BroadcastThrottle(Events.GLOBAL.TOKEN_VALUE_CHANGING);
            this.props.onChanged && this.props.onChanged();
        }  
        this.tokenvalue = value;
        this.RCUpdate();
    }
    onChangingContent(value) {
        this.tokenvalue = value;
        if (this.props.newModel) {
            this.props.newModel.value = value;
            this.props.onPreviewChange && this.props.onPreviewChange(value);
        }
        else {
            Globals.ProjectManager.Tokens.SetValue({id : this.props.id, value : value});

            Events.BroadcastThrottle_50(Events.GLOBAL.TOKEN_VALUE_CHANGING, [{
                Id : this.props.id,
                Type : Globals.ProjectManager.Tokens.Types.ContentTexts,
                value : value
            }]);
            this.props.onChanged && this.props.onChanged();
        }
        this.RCUpdate();
    }
    SaveParameters() {
        if (this.props.newModel) {
            this.props.newModel.parameters = this.parameters;
        }
        else
            Globals.ProjectManager.Tokens.UpdateProp({id : this.props.id, name : 'parameters', value : this.parameters} );
    }
    renderCustom() {        
        return (
            <SC.FCol fw fh style={{backgroundColor : SC.CurrentTheme.theme.back, padding : '10px', boxSizing : 'border-box', pointerEvents : this.token.locked ? 'none' : 'all'}}>
                <ItemNameEditor
                    noMargin
                    fontSize='12px'                        
                    name={this.token.name}
                    onSaveName={this.SaveName}
                    onGetItems={this.GetItems}
                    model={this.token}
                    readOnly={this.token.locked}
                    lockable={{
                        isLocked : this.token.locked,
                        onTokenLock : () => {
                            this.token.locked = Globals.ProjectManager.Tokens.ToggleTokenLock(this.props.id);                                    
                            this.RCUpdate();
                        }
                    }}
                />
                <SC.FCol style={{marginTop : '16px'}}>
                    <TextValue 
                        multiline 
                        dark
                        value={this.tokenvalue} 
                        onChange={this.onChangeContent} 
                        onChanging={this.onChangingContent} 
                        style={{fontSize : '12px'}}
                    />                            
                </SC.FCol>                                                                               
                <Parameters 
                    items={this.parameters}
                    onSave={this.SaveParameters}
                />
            </SC.FCol>
        )
    }
}
