import MetaData from '../toolabs-metaui';
import Utils from '../toolabs-utils';
var camelCase = require('lodash/camelCase');

function jsUcfirst(string) 
{
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const Package_React = function(board_data, data_components, component_names) {
    const package_files = {        
        components : [

        ]
    };

    package_files.style_board_js = {
        name : 'style-board.js',
        content : 'export default \r\n' + JSON.stringify(board_data, null, '  ')
    };

    package_files.index_js = {
        name : 'index.js',
        content : `
import React, { Component } from 'react';
import * as Engine from '@toolabs/beta-engine-react';
import MetaComponents from './components';
import BoardData from './style-board';

const Loader = {
    GetComponentMetaData(Id) {
        return this.LoadComponent(Id);
    },
    LoadComponent(Id) {
        return new Promise((resolve, reject) => {            
            resolve(
                {
                    BoardData : BoardData,
                    MetaData : MetaComponents[Id]
                }
            );
        })     
    }    
};

class ToolabsComponent extends Component
{
    constructor(props) {
        super(props);
        this.GetSlot = this.GetSlot.bind(this);
    }
    GetSlot(Id, Name) {
        const {slots} = this.props;
        let slot = null;
        slot = this.props[Name];
        if (!slot) {
            slot = this.props[Id];
            if (!slot && slots) {
                slot = slots[Name];
                if (!slot)
                    slot = slots[Id];
            }
        }

        if (slot) {
            if (typeof slot === 'function')
                return slot();
            return slot;
        }
        return this.props.children;
    }
    render() {
        const Props = {
            GetCustomContent : this.GetSlot,
            PublicProps : this.props.options || {},
            onStateChanged : this.props.onStateChanged,
            onModelValueChange : this.props.onModelChanged,
            ...this.props            
        }
        if (this.props.live) {
            return (
                <Engine.LiveComponent
                    id={this.props.id}
                    {...Props}
                />
            );
        }
        return (
            <Engine.Component
                Id={this.props.id}
                Store={Loader}
                {...Props}
            />
        )      
    }
}`
    }

    if (board_data.Components) {
        if (!component_names)
            component_names = GetComponentNames(board_data.Components);        

        if (data_components) {

            let code_components_index_import = '';
            let code_components_index_export = '\r\nexport default {\r\n';
            let first = true;
            for (let cid in component_names) {
                if (data_components[cid]) {
                    package_files.components.push({
                        name : `${cid}.js`,
                        content : `export default \r\n` + JSON.stringify(data_components[cid], null, '  ')
                    });

                    code_components_index_import += `import ${component_names[cid].name} from './${cid}.js';\r\n`;
                    code_components_index_export += `${first ? '' : ',\r\n'}\t"${cid}" : ${component_names[cid].name}`;

                    package_files.index_js.content += `

export const ${component_names[cid].name} = (props) => {
    return (
        <ToolabsComponent
            id='${cid}'
            {...props}
        />
    )
}                                    
                                    `;

                    first = false;
                }                
            }

            package_files.components.push({
                name : 'index.js',
                content : code_components_index_import + code_components_index_export + '\r\n}'
            });
        }        
    }

    return package_files;
}

const GetComponentDeclare = function(platform, indent, embedded, ComponentId, Name, ComponentData, Variations, ModelValues, Options = {}) {   
    let variationcodes = '';
            
    if (ComponentData && Variations) {
        Utils.ForEach(Variations,(varid, stateid) => {                    
            if (varid) {
                const statemodel = ComponentData.States[stateid];
                if (statemodel) {
                    if (variationcodes !== '') {
                        variationcodes += ',\r\n';
                    }
                    if (statemodel.SingleVariation) {
                        variationcodes += `${indent}  '${statemodel.name}' : true`;
                    }
                    else {
                        if (statemodel.Variations && statemodel.Variations[varid]) {
                            variationcodes += `${indent}  '${statemodel.name}' : '${statemodel.Variations[varid].name}'`;
                        }                                
                    }
                }
                                        
            }
        });
    }

    if (ComponentData && ModelValues && ComponentData.Models) {
        let firstLine = true;
        Utils.ForEach(ModelValues,(modelvalue, modelid) => {                    
            if (modelid) {
                const model = ComponentData.Models[modelid];
                if (model && Utils.IsNotNull(modelvalue)) {
                    if (variationcodes !== '') {
                        variationcodes += ',\r\n';
                    }
                    variationcodes += `${firstLine && variationcodes != '' ? '  ' : ''}${indent}  '${model.name}' : '${modelvalue}'`;
                    firstLine = false;
                }                
            }
        });
    }
    if (embedded) {
        let cname = jsUcfirst(camelCase(Name || `component_name`));
        if (MetaData.Platforms.IsReact(platform)) {
            if (Options.noId && !Utils.IsNotNullOrEmpty(variationcodes)) {
                return`${indent}<${Options.NameSpace ? Options.NameSpace+'.' : ''}${cname} />`;
            }
            else {
return`${indent}<${Options.NameSpace ? Options.NameSpace+'.' : ''}${cname}${Options.noId ? '' : `
  ${indent}id='${ComponentId}'`}${!Utils.IsNotNullOrEmpty(variationcodes) ? '' : `
  ${indent}options={{
  ${variationcodes}
  ${indent}}}`}  
${indent}>
${indent}</${Options.NameSpace ? Options.NameSpace+'.' : ''}${cname}>`;                
            }        
        }
        else if (MetaData.Platforms.IsPReact(platform)) {
            if (Options.noId && !Utils.IsNotNullOrEmpty(variationcodes)) {
                return`${indent}<${Options.NameSpace ? Options.NameSpace+'.' : ''}${cname} />`;
            }
            else {
return`${indent}<${Options.NameSpace ? Options.NameSpace+'.' : ''}${cname}${Options.noId ? '' : `
  ${indent}id='${ComponentId}'`}${!Utils.IsNotNullOrEmpty(variationcodes) ? '' : `
  ${indent}options={{
  ${variationcodes}
  ${indent}}}`}  
${indent}>
${indent}</${Options.NameSpace ? Options.NameSpace+'.' : ''}${cname}>`;                
            }        
        }
        else if (MetaData.Platforms.IsVue(platform)) {
            if (Options.noId && !Utils.IsNotNullOrEmpty(variationcodes)) {
                return`${indent}<toolabs-vue id='${ComponentId}'/>`;
            }
            else {
        return `${indent}<toolabs-vue 
  ${indent}id='${ComponentId}'
  ${indent}:options="{
${variationcodes}        
  ${indent}}"
${indent}>
${indent}</toolabs-vue>`;
            }   
        }
    }
    else {
        if (MetaData.Platforms.IsReact(platform)) {
        return`${indent}<ToolabsEngine.LiveComponent 
  ${indent}id='${ComponentId}' 
  ${indent}options={{
${variationcodes}
  ${indent}}}
${indent}>
${indent}</ToolabsEngine.LiveComponent>`;
        }
        else if (MetaData.Platforms.IsPReact(platform)) {
        return`${indent}<ToolabsEngine.LiveComponent 
    ${indent}id='${ComponentId}' 
    ${indent}options={{
${variationcodes}
    ${indent}}}
${indent}>
${indent}</ToolabsEngine.LiveComponent>`;
        }
        else if (MetaData.Platforms.IsVue(platform)) {
        return `${indent}<toolabs-vue 
  ${indent}id='${ComponentId}'
  ${indent}:options="{
${variationcodes}        
  ${indent}}"
${indent}>
${indent}</toolabs-vue>`;
        }        
    }
    
    return '';
}

const GetComponentNames = (components) => {
    const component_names = {

    };
    
    const usednames = {};
    for (var cid in components) {
        const cmodel = components[cid];
        if (cmodel) {
            let cname = jsUcfirst(camelCase(cmodel.name || `component_${0}`));
            let i = 1;
            let newname = cname;
            while (usednames[newname]) {
                newname = cname + `_${i}`;
                i++;
            }
            usednames[newname] = true;
            component_names[cid] = {
                name : newname,
                label : cmodel.name
            }
        }   
    }

    return component_names;
}

export default {
    React : Package_React,
    DeclareComponent :GetComponentDeclare,
    GetComponentNames : GetComponentNames
}