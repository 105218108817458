import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    UIUtils,
    Globals,
    AppLayout
} from '../../../../../../../importer';
import styled from 'styled-components';
import { motion, AnimatePresence  } from 'framer-motion';
import { StyleGroupTokenItem, StyleToken_Color, StyleGroupTitle } from '../common';
import VariableBinder from '../variable';
import { DotMenuContainerBox } from '../../../../left/common';
import { SpinBox } from '../../../../../../../components/editors/input_number';

export default class SizeEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            focused : false,
            value : this.props.value,
            unit : this.props.unit
        }        
            
        this.Ref_Input = React.createRef();
        
        this.ToggleUnits = this.ToggleUnits.bind(this);

        this.onFocused = this.onFocused.bind(this);
        this.onBlured = this.onBlured.bind(this);
        this.onChange = this.onChange.bind(this);
        this.OnKeyUp = this.OnKeyUp.bind(this);
        this.OnKeyDown = this.OnKeyDown.bind(this);
        this.Increment = this.Increment.bind(this);
        this.Decrement = this.Decrement.bind(this);

        this.LoadUnits(this.props);        
    }
    LoadUnits(props) {
        if (props.fontSize) {
            this.units = ['px','em', 'rem'];
        }
        else if (this.props.timeUnits) {
            this.units = ['ms'];
        }
        else {
            this.units = ['px', '%', '100%', 'em', 'rem', 'vw', 'vh'];
            if (props.noPercent)
                this.units.splice(1, this.props.hasFull ? 1 : 2);        
            if (props.hasGridFragment) {
                this.units.splice(0, 0, 'fr');
            }            
        }    
        if (props.hasAuto) {
            this.units.push('auto');
        }    
    }
    onFocused() {
        this.setState({focused : true});
    }
    onBlured() {        
        this.setState({focused : false});
        if (this.isValueChanged) {
            this.props.onChange && this.props.onChange({value : this.state.value, unit : this.state.unit, oldUnit : this.state.unit});
        }
        this.isValueChanged = false;
    }
    onChange(e) {
        let value = !Utils.IsNotNullOrEmpty(e.target.value) ? null : Utils.ToNumber(e.target.value);
        let purevalue = e.target.value;
        if (this.state.unit === 'fr') {
            value = Math.max(1, value);
            purevalue = value;
        }
            
        if (value === this.props.value)
            return;
        if (Utils.IsNotNullOrEmpty(value)) {
            this.isValueChanged = value !== this.props.value;
            this.setState({value : purevalue});
            this.props.onChanging && this.props.onChanging({value : value, unit : this.state.unit, oldUnit : this.state.unit});
        }        
        else {
            this.state.value = null;
            this.props.onDelete && this.props.onDelete();
        }
    }
    onChangeUnit(unit, e) {        
        e && e.stopPropagation();
        this.state.showUnits = false;
        if (unit === '100%') {
            this.state.value = 100;
            this.props.onChange({value : 100, unit : '%', oldUnit : this.state.unit, full : true});        
            this.isValueChanged = false;
        }
        else {
            if (unit !== 'auto' && !Utils.IsNotNullOrEmpty(this.state.value))
                this.state.value = 0;
            this.props.onChange({value : this.state.value, unit : unit, oldUnit : this.state.unit});        
            this.isValueChanged = false;
        }
        
        this.willFocusonUpdate = true;

        return false;
    }
    OnKeyDown(e) {
        e.stopPropagation();
        if (e.keyCode === 38 || e.keyCode === 40) {
            e.preventDefault();            
        }
        else if (Utils.IsDeleteCode(e.keyCode)) {
            e.preventDefault();
            this.state.value = null;
            this.props.onDelete && this.props.onDelete();
            return false;
        }
        return true;
    }
    OnKeyUp(e) {                
        e.stopPropagation();
        if (e.keyCode === Utils.KeyCodes.TAB)
            return;

        if (this.props.onSubmit && (e.charCode === 13 || e.keyCode === 13)) {
            this.props.onChange && this.props.onChange({value : this.state.value, unit : this.state.unit, oldUnit : this.state.unit});
            this.props.onSubmit();
            this.isValueChanged = false;
        }        
        else if (this.props.onCancel && e.keyCode === 27) {
            this.props.onCancel();
        }        
        else if (e.keyCode === 38 || e.keyCode === 40) {
            e.preventDefault();
            let increment = 1;
            if (this.props.numeralDecimalScale) {
                if (this.state.unit === 'em' || this.state.unit === 'rem') {
                    increment = 0.1;
                }
            }
            let value = Number(this.state.value);
            if (e.keyCode === 38)
                value = (value || 0) + (increment);
            else
                value = (value || 0) - (increment);

            if (this.state.unit === 'fr')
                value = Math.max(1, value);

            this.setState({value : value});
            this.props.onChange && this.props.onChange({value : value, unit : this.state.unit, oldUnit : this.state.unit});
            this.isValueChanged = false;
        }    

        this.LastKeyCode = e.keyCode;
        
        return true;
    }
    Increment() {
        let increment = 1;
        if (this.props.numeralDecimalScale) {
            if (this.state.unit === 'em' || this.state.unit === 'rem') {
                increment = 0.1;
            }
        }
        let value = Utils.ToNumber(Utils.UseNull(this.props.value, 0));
        value += increment;
        this.props.onChange && this.props.onChange({value : value, unit : this.state.unit, oldUnit : this.state.unit});
        this.isValueChanged = false;
    }
    Decrement() {
        let increment = 1;
        if (this.props.numeralDecimalScale) {
            if (this.state.unit === 'em' || this.state.unit === 'rem') {
                increment = 0.1;
            }
        }
        let value = Utils.ToNumber(Utils.UseNull(this.props.value, 0));
        value -= increment;
        if (this.state.unit === 'fr')
            value = Math.max(1, value);
        this.props.onChange && this.props.onChange({value : value, unit : this.state.unit, oldUnit : this.state.unit});
        this.isValueChanged = false;
    }
    ToggleUnits() {        
        this.setState({
            showUnits : !this.state.showUnits
        })
    }
    shouldComponentUpdate(nextProps, nextState) {        
        if (Utils.HasAnyChange(this.props, nextProps, 'noPercent', 'hasAuto', 'hasGridFragment')) {
            this.LoadUnits(nextProps);
        }
        if (!this.state.focused && (this.state.value !== nextProps.value || this.state.unit !== nextProps.unit)) {
            this.state.value = nextProps.value;
            this.state.unit = nextProps.unit;
            return true;
        }        
        return true;
    }    
    componentDidUpdate(prevProps, prevState) {
        if (this.state.focused !== prevState.focused) {
            setTimeout(() => {
                AppState.Designer.SetEditingText(this.state.focused ? 'size editor' : false);
            }, 100);            
        }     

        if (this.willFocusonUpdate) {
            this.willFocusonUpdate = false;
            if (this.Ref_Input.current) {
                this.Ref_Input.current.Focus();
            }
        }
    }
    SetChangingValue({value, unit, ended}) {
        this.setState({
            ChangingValue : ended ? null : {
                value : value,
                unit : unit
            }
        })
    }
    render() { 
        let isEmpty = Utils.IsNull(this.state.value);
        const style_box = {            
            height : '28px',
            ...SC.Styles.Flex.Row,
            width : '60px',
            position : 'relative',
            overflow : 'unset',
            borderRadius : '2px',
            ...this.props.style_box
        };
        if (!isEmpty || this.state.focused) {
            style_box.backgroundColor = SC.CurrentTheme.theme.back;
        }
        if (this.props.readOnly) {
            delete style_box.backgroundColor;
            style_box.pointerEvents = 'none';
        }

        let decimalDefault = 0;
        if (this.state.unit === 'em' || this.state.unit === 'rem' || this.state.unit === '%' || this.state.unit === 'fr')
            decimalDefault = 2;
            
        const numericOptions = {
            numeral : true,
            numeralThousandsGroupStyle : 'none',
            numeralDecimalScale : Utils.UseNull(this.props.numeralDecimalScale, decimalDefault),
            numeralPositiveOnly : this.props.numeralPositiveOnly
        };
        

        let modelId;
        let hideunit = this.state.unit === 'auto' || this.state.unit === 'none' || this.props.auto;
        let value = this.state.ChangingValue ? this.state.ChangingValue.value : this.state.value;
        let unit = this.props.auto ? 'AUTO' : (this.state.ChangingValue ? this.state.ChangingValue.unit : Utils.UseNullOrEmpty(this.state.unit, 'px'));
        if (!Utils.IsNotNullOrEmpty(value)) {
            if (this.props.noNone) {
                value = '';   
                hideunit = false;             
            }
            else {
                hideunit = true;
                unit = 'NONE';
            }
        }

        let spinner;
        if (this.props.hasSpinner && unit === 'fr') {
            spinner = (
                <SpinBox 
                    onIncrement={this.Increment}
                    onDecrement={this.Decrement}
                />
            )
        }

        return (  
            <SC.FRow style={{...this.props.style}} title={this.props.title || (this.props.readOnly ? 'Fixed' : null)}>
                <StyleGroupTokenItem style={style_box} focused={this.state.focused}>
                    {
                        !hideunit &&
                        <ValueInput 
                            ref={this.Ref_Input}
                            autoFocus={this.props.autoFocus}
                            autoSelect={this.props.autoSelect}
                            onFocus={this.onFocused}
                            onBlur={this.onBlured}
                            onUnmount={() => {
                                setTimeout(() => {
                                    AppState.Designer.SetEditingText(false);    
                                }, 200);                                
                            }}
                            onChange={this.onChange}
                            onKeyUp={this.OnKeyUp}
                            onKeyDown={this.OnKeyDown}
                            onMouseDown={(e) => e.stopPropagation()}
                            options={numericOptions}
                            value={value}
                            placeholder={'_ _'}
                            readOnly={this.props.readOnly}
                            style={{
                                backgroundColor : (isEmpty && !this.state.focused || this.props.readOnly) ? 'transparent' : SC.CurrentTheme.theme.input_back,
                                fontSize : '14px',
                                paddingRight : '2px',
                                borderTopLeftRadius : '2px',
                                borderBottomLeftRadius : '2px',
                                ...SC.Styles.FontStyles.Monospace
                            }}
                        />
                    }                    
                    {spinner}
                    {
                        !this.props.noUnit && 
                        <React.Fragment>
                            <UnitBox style={{
                                    ...SC.Styles.Flex.Cell, ...SC.Styles.FontStyles.Monospace, flex : hideunit ? 1 : 'unset',
                                    borderTopRightRadius : '2px',
                                    borderBottomRightRadius : '2px',
                                }} onClick={this.ToggleUnits}
                            >
                                {unit}
                            </UnitBox>
                            {
                                this.state.showUnits &&
                                <UnitMenu style={{left : 0}} 
                                    onClose={() => {
                                        setTimeout(() => {
                                            if (this.state.showUnits)
                                                this.setState({showUnits : false})
                                        }, 150);                                
                                    }}
                                >
                                    <PopupContainer>
                                        {this.units.map((unit) => {                                                                        
                                            return (
                                                <SC.PopupItem 
                                                    key={unit} 
                                                    style={{textAlign : 'right', textTransform : 'uppercase'}} 
                                                    onClick={this.onChangeUnit.bind(this, unit)}                                            
                                                >
                                                    {unit}
                                                </SC.PopupItem>
                                            )
                                        })}
                                    </PopupContainer>
                                </UnitMenu>
                            }
                        </React.Fragment>
                    }                    
                </StyleGroupTokenItem>  
                {
                    this.props.hasVariable && 
                    <VariableBinder 
                        modelId={modelId}
                        onSelect={this.SelectModel}
                    />
                }                                  
            </SC.FRow>                                 
        );
    }
}

class ValueInput extends React.Component {
    constructor(props) {
        super(props);
        this.Ref_Cleave = React.createRef();
        this.state = {  }
    }
    componentDidMount() {
        if (this.props.autoFocus) {
            setTimeout(() => {
                this.setState({willFocus : true})
            }, 200);
        }
    }
    componentWillUnmount() {
        this.props.onUnmount();
    }
    Focus() {
        this.Ref_Cleave.current && this.Ref_Cleave.current.Focus();
    }
    render() { 
        const {onUnmount, ...rest} = this.props;
        return (  
            <SC.CleaveInput {...rest} autoFocus={this.state.willFocus} autoSelect={this.props.autoSelect} ref={this.Ref_Cleave} />
        );
    }
}

const UnitBox = styled.div`
    padding-left : 4px;
    padding-right : 4px;
    min-width : 22px;
    padding-top : 2px;
    box-sizing : border-box;
    text-transform : uppercase;
    &: hover{
        background-color : ${props => props.theme.back_lighter};
    }
`;

const UnitMenu = UIUtils.OutSideClickHandler(DotMenuContainerBox);

class PopupContainer extends React.Component {
    constructor(props) {
        super(props);
        this.onMouseDown = this.onMouseDown.bind(this);
    }
    componentDidMount() {
        const DN = ReactDOM.findDOMNode(this);
        DN.addEventListener('mousedown', this.onMouseDown)
    }
    componentWillUnmount() {
        const DN = ReactDOM.findDOMNode(this);
        DN.removeEventListener('mousedown', this.onMouseDown)
    }
    onMouseDown(e) {
        e.stopPropagation();
        e.preventDefault();
        return false;
    }
    render() { 
        return (  
            <SC.FCol>
                {this.props.children}
            </SC.FCol>
        );
    }
}
 