import React from 'react';
import ReactGA from 'react-ga';
import '../App.css';
import { Route, Redirect, IndexRoute, Link, HashRouter, Switch } from 'react-router-dom';
import ShortId from 'shortid';
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

import {
  ReactBaseComponent,
  SC,
  Utils,
  AppState,
  Links,
  Events,
  AppLayout,
  Globals
} from '../importer';

import Logo from '../views/logo/brand_logo';
import SharedDocumentPreview from '../views/project/documents/sharedPreview';
import packageJSON from '../../../package.json';
// import SharedDocumentPreview from '../views/project/documents/sharedPreview/animated';

let ErrorBoundary = null;
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev code
} else {
    Bugsnag.start({
        apiKey: 'b9abbed92a1b17f25d2c7ff8ba618c35',
        plugins: [new BugsnagPluginReact()],
        appVersion: packageJSON.config.studio.version,
      });
    ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);
    // Sentry.configureScope(function(scope) {
    //     scope.setTag("studio_version", "30.05.2020");
    // });
}


Utils.Id = () => {
    return ShortId();
}
Utils.platform = Utils.Get(window.navigator, '', 'platform');

window.Utils = Utils;

ReactGA.initialize('UA-113091495-2');

if (window.matchMedia) {
    const mq = window.matchMedia("(min-width: 768px)");
    mq.addListener(WidthChange);
    WidthChange(mq);
}

function WidthChange(mq) {
    AppLayout.Media.Small = !mq.matches;
    AppLayout.Media.Mobile = window.innerWidth <= 400;
    Events.BCE(Events.GLOBAL.MEDIASIZE.CHANGED);

    // const msg = (mq.matches ? "more" : "less") + " than 1000 pixels";
    // console.log(msg);
}


export default class App extends ReactBaseComponent {
    constructor(props) {
        super(props);
        AppLayout.ThemeId = Utils.Id();

        super.ListenEvents(
            Events.SESSION,
            Events.GLOBAL.THEME.CHANGED
        );

        try {
            const LocalOptions = JSON.parse(Utils.JustGet(localStorage, '', 'ToolabsUser')) || {};
            if (LocalOptions.LightTheme)
                AppLayout.Theme.Current = 'Light';   
        } catch (error) {
            
        }        
    }
    componentDidMount() {
        super.componentDidMount();
    }
    componentWillUnmount() {
        super.componentWillUnmount();
        Events.DropListenAll(this);
    }
    OnEvent(Event, Params) {
        if (Event === Events.SESSION) {
            this.Loaded = true;
            [this.LoggedIn] = Params;
            this.RCUpdate();

        }
        else if (Event === Events.GLOBAL.THEME.CHANGED) {        
            AppLayout.ThemeId = Utils.Id();            
            this.RCUpdate();
        }
    }    
    renderCustom() {

        // if (this.Loaded)
        //     return (
        //         <TestView />
        //     );

        let content;

        if (!this.Loaded) {
            content = (
                <SC.Div_Full_Center>
                    <SC.Loading_Icon />
                </SC.Div_Full_Center>
            )
        }
        else {
            content = (
                <HashRouter>
                    <Switch>
                        <Route path="/" exact
                            render={(props) => {
                                return (
                                    <SC.FCol fw fh>
                                        <SC.FCol f1 alc style={{paddingTop : '80px', boxSizing : 'border-box', margin : '80px', backgroundColor : SC.CurrentTheme.theme.back, boxShadow : SC.CurrentTheme.theme.card_shadow, borderRadius : '8px'}}>
                                            <Logo isLight={SC.CurrentTheme.theme.isLight} size={180} />
                                            <div style={{fontSize : '36px', fontWeight : 400, marginTop : '16px'}}>
                                                Design System Documentation
                                            </div>                                                            
                                            <div style={{fontSize : '22px', maxWidth : '600px', textAlign : 'center', lineHeight : '32px', marginTop : '96px', marginBottom : '48px'}}>
                                                You can access to documentation of design systems only with the link shared with you.
                                            </div>                                            
                                            <a href='https://xdplugin.toolabs.com/'>
                                                <div style={{fontSize : '22px', maxWidth : '600px', textAlign : 'center', lineHeight : '32px', marginBottom : '48px'}}>
                                                    Please check your url or visit <SC.LinkText style={{
                                                        fontWeight : 'bold'
                                                    }}>www.toolabs.com</SC.LinkText> to build your own design system and share the documentation.
                                                </div>
                                            </a>
                                            
                                        </SC.FCol>
                                    </SC.FCol>
                                )
                            }}
                        />
                        <Route path="/:bid" exact   
                            render={(props) => {
                                return (
                                    <SharedDocumentPreview themeId={AppLayout.ThemeId} {...props} />
                                )
                            }}
                        />
                        <Route path="/:bid/:pageId"  exact
                            render={(props) => {
                                return (
                                    <SharedDocumentPreview themeId={AppLayout.ThemeId} {...props} />
                                )
                            }}
                        />
                        <Route path="/:bid/:pageId/:customId"  exact
                            render={(props) => {
                                return (
                                    <SharedDocumentPreview themeId={AppLayout.ThemeId} {...props} />
                                )
                            }}
                        />                        
                    </Switch>
                </HashRouter>
            )
        }
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            return (
                <SC.AppTheme themeName={AppLayout.Theme.Current}>
                    {content}
                </SC.AppTheme>
            )
        }
        return (
            <ErrorBoundary>
                <SC.AppTheme themeName={AppLayout.Theme.Current}>
                    {content}
                </SC.AppTheme>
            </ErrorBoundary>
        )
        
    }
}
