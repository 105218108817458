import Platforms from './Platforms';
import MetaComponents from './components';
import ItemInitializer from './Initializer';
import Styles from './styles';
import Properties from './props';
import Events from './events';
import Actions, {MetaConditions} from './actions';
import DataTypes from './datatypes';
import Svg from './svg';
import DataContentManager from './data';

const Sounds = [
    {
        id : 'S19Z206Pz', name : 'Buttons and Navigation',
        sounds : [
            {id : 'rklc-n0Tvf', name : 'Button 1'},
            {id : 'Skbqb2CpDM', name : 'Button 2'},
            {id : 'rJf9-3RpvG', name : 'Button 3'},
            {id : 'BJX5Z3CpPG', name : 'Button 4'},
            {id : 'rJE5-3Rpvf', name : 'Button 5'},
            {id : 'HyH9WhCpPM', name : 'Button 6'},
            {id : 'H18q-3AaPz', name : 'Button 7'},
            {id : 'SJDcZ2CpPM', name : 'Collapse'},
            {id : 'B1d9bhCavG', name : 'Expand'},
            {id : 'ryF5W20Twz', name : 'Tab 1'},
            {id : 'By59WhAawf', name : 'Tab 2'},
            {id : 'HJo5ZnR6vM', name : 'Tab 3'},
        ]                
    },
    {
        id : 'B1D3nRawf', name : 'Complete and Success',
        sounds : [
            {id : 'S1lvhn0pDM', name : 'Complete 1'},
            {id : 'BJWwhnRTvz', name : 'Complete 2'},
            {id : 'BJfD3hC6Df', name : 'Complete 3'},
            {id : 'Symwh3ApDz', name : 'Success 1'},
            {id : 'ryNP3306vf', name : 'Success 2'},
            {id : 'rJHPh2C6DG', name : 'Success 3'}
        ]
    },
    {
        id : 'Hkc16RpDz', name : 'Errors and Cancel',
        sounds : [
            {id : 'H1g5JpAaPz', name : 'Cancel 1'},
            {id : 'HyZcJpRpPz', name : 'Cancel 2'},
            {id : 'r1MqJ6RaDM', name : 'Error 1'},
            {id : 'SkXc1TA6DM', name : 'Error 2'},
            {id : 'rkEcJTCavz', name : 'Error 3'},
            {id : 'SJHcyT0pvf', name : 'Error 4'},
            {id : 'S1I9kp0avf', name : 'Error 5'}
        ]
    },
    {
        id : 'Bk3ZaC6vM', name : 'Notifications and Alerts',
        sounds : [
            {id : 'HJxhZTA6PM', name : 'Alert 1'},
            {id : 'SJbhbaR6Df', name : 'Alert 2'},
            {id : 'HJz3Z6CaDz', name : 'Alert 3'},
            {id : 'rJm3ZpRTPM', name : 'Alert 4'},
            {id : 'SyEh-pRpPz', name : 'Alert 5'},
            {id : 'ByHh-pRpvf', name : 'Notification 1'},
            {id : 'HkU2ZTA6DG', name : 'Notification 2'},
            {id : 'HJvhWTAaPM', name : 'Notification 3'},
            {id : 'r1_2b60TDG', name : 'Notification 4'},
            {id : 'ByK3W6R6vf', name : 'Notification 5'},
            {id : 'ry5n-TA6DG', name : 'Notification 6'},
            {id : 'S1i3ZaA6Df', name : 'Notification 7'},
            {id : 'Bk32WpR6PM', name : 'Notification 8'},
            {id : 'H16hbTATPG', name : 'Notification 9'}
        ]
    }
];
const MetaData = {
    Platforms,
    Components : MetaComponents,    
    Svg,
    ItemInitializer,
    Styles,
    Properties,
    Events,
    Actions,
    MetaConditions,
    DataTypes,
    Sounds,
    DataContentManager
};

export default MetaData;
