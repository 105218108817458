import React from 'react';
import ReactDOM from 'react-dom';

import {
    MetaData,
    Utils,
    AppLayout,
    AppState,
    UIUtils,
    SC
} from '../../../../../importer';
import DragDropManager from '../../../../../components/dragdrop/DragDropManager';
import { onConstructItem, onItemShouldUpdate, onMouseDownOnItem, onItemClick, onItemDidMount, onItemWillUnMount, onItemDidUpdate, onGetItemProps, onGetDomProps } from './common';
import ItemTextEditor from './common/texteditor';
import { LOG_TYPES } from '../../../../../appstate/historymanager/base';

export default class Text extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }

        this.onChange = this.onChange.bind(this);
        this.onChanging = this.onChanging.bind(this);
        this.onDoubleClick = this.onDoubleClick.bind(this);
        this.onMouseDownTex = this.onMouseDownTex.bind(this);
        this.onMouseUpTex = this.onMouseUpTex.bind(this);

        this.hasCustomSelection = true;

        onConstructItem(this, props);

        this.UpdateStyle(this.props);
    }
    UpdateStyle(props) {
        this.renderData = props.GetComponentManager().GetItemStyle(props.Id);
    }   
    RefreshItem() {
        this.UpdateStyle(this.props);
        this.setState({ShouldUpdate : true});
    }
    componentDidMount() {
        onItemDidMount.call(this);
    }
    componentWillUnmount() {
        onItemWillUnMount.call(this);
    }
    componentDidUpdate(prevProps, prevState) {
        onItemDidUpdate.call(this, prevProps, prevState);              
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.Editing !== this.state.Editing)

            AppState.Designer.SetEditingText(nextState.Editing ? 'text designer' : false);

        if (onItemShouldUpdate.call(this, nextProps, nextState)) {
            if (this.state.Editing) {            
                if (nextProps.Preview || !this.isSelected && this.wasSelected)
                    this.state.Editing = false;
            }
            return true;
        }    
        
        if (this.state.Editing !== nextState.Editing) {
            if (AppLayout.Refs.SelectionDesigner) {
                if (nextState.Editing)
                    AppLayout.Refs.SelectionDesigner.UnRegisterItem(this.props.Id, this);
                else {                    
                    if (this.isSelected)
                        AppLayout.Refs.SelectionDesigner.RegisterItem(this.props.Id, this);
                }
            }            
            return true;
        }
                    
        return false;
    }    
    onItemClick(e) 
    {
        return onItemClick.call(this, e);
    }    
    GetMaxSize() {        
        return this.props.GetParentSize();
    }
    GetItemSize() {
        const bounds = UIUtils.Utils.GetBounds(this);
        return {
            width : bounds.width,
            height : bounds.height
        }
    }
    onChange(value) {
        this.IsChangeLogged = false;
        const modelId = Utils.JustGet(this.renderData.models, null, 'textContent', 'modelId');
        if (modelId && this.props.isSubComponentChild && this.props.isSubComponentChild.onChangeModelValue) {
            this.props.isSubComponentChild.onChangeModelValue(modelId, value);
        }
        else {
            this.props.GetComponentManager().GetDesignManager().SetItemPropertyValue({
                MetaItem : this.props.GetComponentManager().GetMetaItem(this.props.Id),
                Id : this.props.Id,
                PropertyName : 'textContent',
                Value : value
            });
        }        
    }
    onChanging(value) {
        // if (!this.IsChangeLogged) {
        //     this.props.GetComponentManager().GetDesignManager().Log({
        //         Target : LOG_TYPES.SET_PROP,
        //         Id : this.props.Id,
        //         Desc : `Text Change`
        //     });
        //     this.IsChangeLogged = true;
        // }
        const modelId = Utils.JustGet(this.renderData.models, null, 'textContent', 'modelId');
        if (modelId && this.props.isSubComponentChild && this.props.isSubComponentChild.onChangingModelValue) {
            this.props.isSubComponentChild.onChangingModelValue(modelId, value);
        }
        else {
            this.props.GetComponentManager().GetDesignManager().SetItemTextContent({            
                Id : this.props.Id,
                Value : value
            });
        }                
    }
    onMouseDownTex(e) {
        e.stopPropagation();
    }
    onMouseUpTex(e) {
        e.stopPropagation();
    }
    onDoubleClick(e) {
        e && e.stopPropagation();
        
        if (!this.isSelected) {
            this.isSelected = true;            
        }        
        this.setState({Editing : true});
    }
    render() { 
        if (Utils.IsTrue(this.renderData.props.hidden))
            return null;
        let textContent = Utils.JustGet(this.renderData.props, '', 'textContent');

        let outerStyle = {};
        let otuerProps = {};
        
        if (this.props.IsRootItem && this.props.IsRootItem.SubComponent) {
            outerStyle =  this.props.IsRootItem.SubComponent.style;       
            otuerProps.onMouseDown = this.props.Preview ? this.props.IsRootItem.SubComponent.onMouseDown : this.onMouseDown;
            otuerProps.onMouseUp = this.props.Preview ? this.props.IsRootItem.SubComponent.onMouseUp : this.onItemClick;
            otuerProps.onDoubleClick = this.props.IsRootItem.SubComponent.onDoubleClick;
        }

        const style = {
            ...this.renderData.style,
            ...outerStyle,
            whiteSpace : 'pre-line'
        };
        if (!this.isSelected) {
            style.transition = '0.2s ease';
        }
        if (this.isDragging) {
            style.opacity = 0.5;
        }

        const props = onGetDomProps({designer : this, props : otuerProps, style : style});

        if (!this.props.Preview) {        
            props.onDoubleClick = this.state.Editing ? null : this.onDoubleClick;
        }

        let textItem = textContent;

        if (this.state.Editing) {            
            textItem = (
                <ItemTextEditor 
                    value={textItem} 
                    onChanging={this.onChanging}
                    onChange={this.onChange}
                    readOnly={!this.state.Editing}
                />
            )                       
        }
        else {            
            if (props.truncate) {
                textItem = (
                    <SC.TextDivAbbr>
                        {textContent}
                    </SC.TextDivAbbr>
                )
            }            
        }

        if (!this.props.Preview && !Utils.IsNotNullOrEmpty(textItem)) {
            style.opacity = 0.5;
            textItem = 'Text';
        }

            
        
        return (
            <div style={style} {...props}>{textItem}</div>
        )
       
    }
}
 