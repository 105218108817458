import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    AppLayout,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../../../../importer';
import styled, { css} from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';

import SlateEditor from '../text/editor';
import { DOCUMENT_ITEMTYPES } from '../../../../manager/documents';
import DocumentItem_GistFrame from './gistFrame';

export default class DocumentItem_Iframe extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {

        };            

        this.onChangeSubtitle = this.onChangeSubtitle.bind(this);
        this.onReloadSubtitle = this.onReloadSubtitle.bind(this);
        
        this.Load(this.props.id);
    }    
    shouldComponentUpdate(nextProps, nextState) {

        if (this.props.autoFocus !== nextProps.autoFocus)
            return true;
        if (this.state.ShouldUpdate !== nextState.ShouldUpdate)
            return true;
        
        if (this.props.RenderId !== nextProps.RenderId) {
            this.Load(nextProps.id);
            return true;
        }        
        if (Utils.HasAnyChange(this.props, nextProps, 'preview', 'GlobalThemeId', 'GlobalState', 'StyleId', 'themeId'))
            return true;
        
        return false;
    }
    Load(id) {        
    }
    componentDidUpdate(prevProps, prevState) {
        this.state.ShouldUpdate = false;
    }
    Update() {
        this.setState({
            ShouldUpdate : true
        })
    }    
    onChangeSubtitle(value) {
        Globals.ProjectManager.GetDocumentManager().SetItemValue(this.props.id, Utils.DeepClone(value), 'content');
        this.setState({
            value : value,
            ShouldUpdate : true
        })             
    }
    onReloadSubtitle(value) {
        this.EditorId = Utils.Id();
        this.onChangeSubtitle(value);
    }
    render() {        

        let content;

        const alignment = Globals.ProjectManager.GetDocumentManager().GetPageItemValue(this.props.id, this.props.pageId, 'center', 'alignment');
        let url = Utils.UseNullOrEmpty(Globals.ProjectManager.GetDocumentManager().GetPageItemValue(this.props.id, this.props.pageId, '', 'url'), 'https://www.toolabs.com');
        const size = (alignment !== 'fullwidth' && alignment !== 'stretch') ? Globals.ProjectManager.GetDocumentManager().GetPageItemValue(this.props.id, this.props.pageId, 100, 'size') : null;
        const height = Globals.ProjectManager.GetDocumentManager().GetPageItemValue(this.props.id, this.props.pageId, 600, 'height')

        const style = {
            border : 'none',
            alignSelf : alignment,
            width : size ? Utils.px(size, '%') : 'auto',
            height : Utils.px(height)
        };

        if (this.props.type === DOCUMENT_ITEMTYPES.figmaLiveEmbed) {
            const URL_REGEX = /https:\/\/([\w\.-]+\.)?figma.com\/(file|proto)\/([0-9a-zA-Z]{22,128})(?:\/.*)?$/
            const match = url.match(URL_REGEX);
            if (!match) {
                return (
                    <SC.FCol style={{lineHeight : '28px'}}>
                        <div>Invalid Figma File Url!</div>
                        <div>Click the <span style={{fontWeight:'bold'}}>Share</span> button inside a Figma file and copy the link of the file.</div>
                    </SC.FCol>
                )
            }
            url = `https://www.figma.com/embed?embed_host=toolabs&url=${url}`;
            content = (
                <iframe                   
                    src={url} 
                    style={style}
                    allowfullscreen
                />  
            )
        }
        else if (this.props.type === DOCUMENT_ITEMTYPES.storybookEmbed) {
            if (url.indexOf('iframe.html') < 0) {
                return (
                    <SC.FCol style={{lineHeight : '28px'}}>
                        <div>Invalid Storybook File Url!</div>                        
                    </SC.FCol>
                )
            }
            else {
                content = (
                    <iframe                   
                        src={url} 
                        style={style}
                        allowfullscreen
                    />  
                )
            }
        }
        else if (url.indexOf('https://gist.github.com/') === 0) {
            content = (
                <DocumentItem_GistFrame url={url} style={style} />
            )
        }
        else {
            content = (
                <iframe                   
                    src={url} 
                    style={style}
                    allowfullscreen
                />  
            )
        }
        // <script src="https://gist.github.com/applinist/90dd410f9261cd21d5c82a6fec6929d5.js"></script>

        return (
            <SC.FCol>
                <motion.div
                    style={{
                        ...SC.Styles.Flex.Column
                    }}
                    layoutTransition={{
                        duration : 0.2
                    }}
                >
                    {content}                              
                </motion.div>
            </SC.FCol>            
        )
    }
}