import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Events,
    AppState,
    MetaData,
    Utils,
    Strings,
    ReactPureComponent
} from '../../../../../../../../importer';

import styled, {css} from 'styled-components';
import { SmallButtonGroup, PropertyLabel } from '../../common';
import ComponentDesignManager from '../../../../../../component/manager';

const FlexValues = MetaData.Properties.FlexValues;

export default class FlexLayout extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.ChangeJustifyContent = this.ChangeJustifyContent.bind(this);
        this.ChangeAlignItems = this.ChangeAlignItems.bind(this);
    }
    ChangeJustifyContent(justifyContent) {
        this.props.onSetPropertyValues([{
            PropertyName : 'justifyContent',
            Value : justifyContent,
            PropParentNames : ['flexOptions']
        }]);
    }
    ChangeAlignItems(alignItems) {
        this.props.onSetPropertyValues([{
            PropertyName : 'alignItems',
            Value : alignItems,
            PropParentNames : ['flexOptions']
        }]);
        ComponentDesignManager.Active().ComponentManager.UpdateFlexChildrenSizes({
            ParentId : this.props.Id,
            AlignToStretch : {
                value : alignItems
            }
        })
    }
    SetFlexWrap(flexWrap) {
        this.props.onSetPropertyValues([{
            PropertyName : 'flexWrap',
            Value : 'wrap',
            Delete : !flexWrap,
            PropParentNames : ['flexOptions']
        }]);
    }
    renderCustom() {

        const {flexDirection, flexOptions} = this.props;
        let  alignItems, justifyContent;        
        justifyContent = Utils.JustGet(flexOptions, 'flex-start', 'justifyContent', 'Value');
        alignItems = Utils.JustGet(flexOptions, 'flex-start', 'alignItems', 'Value');
        const vertical = flexDirection === 'column' || flexDirection === 'column-reverse';
        const flexWrap = Utils.JustGet(flexOptions, null, 'flexWrap', 'Value') === 'wrap';
        return (
            <SC.FCol style={{marginTop : '8px'}}>
                <SC.FRow jsb>
                    <PropertyLabel>Justify</PropertyLabel>
                    <SC.FRow>
                        <FlexJustifyItems 
                            fd={flexDirection}
                            alit={alignItems}
                            value={justifyContent}
                            vertical={vertical}
                            onChange={this.ChangeJustifyContent}
                        />
                    </SC.FRow>                    
                </SC.FRow>                
                <SC.FRow alc jsb style={{marginTop : '8px'}}>
                    <PropertyLabel>Align</PropertyLabel>
                    <SC.FRow jsb style={{width : '185px'}}>
                        <SC.FRow jsb>
                            <FlexAlignItems 
                                fd={flexDirection}
                                jct={justifyContent}
                                value={alignItems}
                                vertical={vertical}
                                onChange={this.ChangeAlignItems}
                            />
                        </SC.FRow>                        
                        <SmallButtonGroup 
                            hasBorders size={20} 
                            first last
                            onClick={this.SetFlexWrap.bind(this, !flexWrap)} 
                            selected={flexWrap} 
                            title={`Flex Wrap`}
                            style={{width : '30px', height : '28px'}}
                        >
                            <path fill={ flexWrap ? SC.CurrentTheme.theme.color_brand : null} d="M9.29289322,18 L5.5,18 C4.11928813,18 3,16.8807119 3,15.5 L3,9.5 C3,8.11928813 4.11928813,7 5.5,7 L9.5,7 C9.77614237,7 10,7.22385763 10,7.5 C10,7.77614237 9.77614237,8 9.5,8 L5.5,8 C4.67157288,8 4,8.67157288 4,9.5 L4,15.5 C4,16.3284271 4.67157288,17 5.5,17 L9.29289322,17 L7.14644661,14.8535534 C6.95118446,14.6582912 6.95118446,14.3417088 7.14644661,14.1464466 C7.34170876,13.9511845 7.65829124,13.9511845 7.85355339,14.1464466 L10.8535534,17.1464466 C11.0488155,17.3417088 11.0488155,17.6582912 10.8535534,17.8535534 L7.85355339,20.8535534 C7.65829124,21.0488155 7.34170876,21.0488155 7.14644661,20.8535534 C6.95118446,20.6582912 6.95118446,20.3417088 7.14644661,20.1464466 L9.29289322,18 Z M13.5,8 C13.2238576,8 13,7.77614237 13,7.5 C13,7.22385763 13.2238576,7 13.5,7 L20.5,7 C20.7761424,7 21,7.22385763 21,7.5 C21,7.77614237 20.7761424,8 20.5,8 L13.5,8 Z M13.5,13 C13.2238576,13 13,12.7761424 13,12.5 C13,12.2238576 13.2238576,12 13.5,12 L20.5,12 C20.7761424,12 21,12.2238576 21,12.5 C21,12.7761424 20.7761424,13 20.5,13 L13.5,13 Z M13.5,18 C13.2238576,18 13,17.7761424 13,17.5 C13,17.2238576 13.2238576,17 13.5,17 L20.5,17 C20.7761424,17 21,17.2238576 21,17.5 C21,17.7761424 20.7761424,18 20.5,18 L13.5,18 Z"/>
                        </SmallButtonGroup>
                    </SC.FRow>
                </SC.FRow>
            </SC.FCol>
        )
    }
}

export const FlexBoxActive = styled.div`
    height : 36px;
    width : 36px;
    padding : 1px;
    box-sizing : border-box;
    border :${props => props.theme.border_seperator};
    border-radius : 2px;
    cursor : pointer;
    transition : all 0.3s ease;
    background-color : ${props => props.selected ? props.theme.back : 'unset'};
    &:hover {
        background-color : ${props => props.theme.buttongroup_back_hover};
    }
`;

export const FlexJustifyItems = (props) => {
    const justifyValues = [
        FlexValues.FLEXSTART,
        FlexValues.FLEXCENTER,
        FlexValues.FLEXSPACEEVENLY,
        FlexValues.FLEXSPACEAROUND,
        FlexValues.FLEXSPACEBETWEEN,
        FlexValues.FLEXEND
    ];    

    return justifyValues.map((jv, i) => {
        return (
            <SmallButtonGroup
                key={jv}
                first={i===0}
                last={i===5}
                noSvg
                hasBorders                
                onClick={() => {
                    props.onChange(jv);
                }}
                title={MetaData.Properties.FlexLabels[jv]}
                style={{width : '30px', height : '28px', padding : '2px', marginRight : i < 5 ? '1px' : 0}}
                selected={jv === props.value}
            >
                <FlexBoxOption
                    fd={props.fd}
                    jct={jv}
                    alit={props.alit}
                    selected={jv === props.value}                                                            
                    vertical={props.vertical}
                />
            </SmallButtonGroup>
        )
    })
};

export const FlexAlignItems = (props) => {
    const alignItems = [
        FlexValues.FLEXSTART,
        FlexValues.FLEXCENTER,
        FlexValues.FLEXEND,
        FlexValues.FLEXSTRETCH
    ];

    return alignItems.map((alit, i) => {
        return (
            <SmallButtonGroup
                key={alit}
                first={i===0}
                last={i===3}
                noSvg
                hasBorders                
                onClick={() => {
                    props.onChange(alit);
                }}
                title={MetaData.Properties.FlexLabels[alit]}
                style={{width : '30px', height : '28px', padding : '2px', marginRight : i < 3 ? '1px' : 0}}
                selected={alit === props.value}
            >
                <FlexBoxOption
                    fd={props.fd}
                    jct={props.jct}
                    alit={alit}
                    selected={alit === props.value}                                                            
                    vertical={props.vertical}
                    forAlignment
                />
            </SmallButtonGroup>          
        )
    })
}

export const FlexBoxOption = (props) => {    
    return (
        <Div_FlexBox {...props}>
            <FlexItem selected={props.selected} vertical={props.vertical} stretch={props.alit == 'stretch'}/>
            <FlexItem selected={props.selected} vertical={props.vertical} stretch={props.alit == 'stretch'}/>
            <FlexItem selected={props.selected} vertical={props.vertical} stretch={props.alit == 'stretch'}/>
        </Div_FlexBox>
    )
}

const Div_FlexBox = styled.div`    
    display : flex;
    width : 100%;
    height : 100%;
    flex-direction : ${props => props.fd};
    justify-content : ${props => props.jct};
    align-items : ${props => props.alit};
`;

const FlexItem = styled.div`
    display : flex;
    justify-content : center;
    align-items : center;
    width : ${props => props.vertical ? 10 : 1}px;
    height : ${props => props.vertical ? 1 : 10}px;
    border-radius : 1px;
    background-color : ${props => props.selected ? props.theme.color_brand : props.theme.icon};
    margin : 1px;
    ${
        props => {
            if (props.stretch) {
                if (props.vertical)
                    return css`width : unset; align-self : stretch;`;
                else
                    return css`height : unset; align-self : stretch;`;
            }
                
        }
    }
`;