import React from 'react';
import {
    ReactBaseComponent,
    AppState,
    SC,
    Events,
    AppLayout,
    Links
} from '../../importer';

import styled, { css } from 'styled-components';
import Logo from '../logo/brand_logo';
import FormInput from './FormInput';

import Loadable from 'react-loadable';

const TermsAndPrivacy = Loadable({
    loader: () => import('./TermsAndPrivacy'),
    loading: () => <div>Loading...</div>,
});

export const MottoText = styled.div`
    font-size : 22px;
    letter-spacing : 0.08em;
    text-align : center;
`
export const AuthErrorBox = styled.div`
    display:flex;
    align-items: center;
    opacity : ${(props) => props.isInvalid ? 1 : 0}; 
    color:#d01313;
    justify-content: center;
    min-height : 28px;
    font-size : 12px;
    font-weight:300;
    padding: 3px 3px 3px 10px;
`;
export const SubmitButton = styled.button`
    background-color : ${props => props.theme.color_brand};
    height : 42px;
    color : #fff;    
    border : none;
    border-radius : 4px;
    font-size : 17px;
    font-weight:500;
    cursor : pointer;
    transition : all 0.2s ease;
    &:hover {        
        background-color : ${props => props.theme.button_back_hover_primary};
    }
`;

export const SubmitButton_Google = styled.button`
    position: relative;
    background-color : #f3f3f3;
    height : 42px;
    color : #444;
    border : none;
    border-radius : 4px;
    font-size : 17px;
    font-weight:500;
    cursor : pointer;
    transition : all 0.2s ease;
    &:hover {
        color : #666;
        background-color : #e6e6e6;
    }
    margin-bottom:10px;
`;

export const SubmitButton_Twitter = styled.button`
    position: relative;
    background-color : #55ACEE;
    height : 42px;
    color : #fff;
    border : none;
    border-radius : 4px;
    font-size : 17px;
    font-weight:500;
    cursor : pointer;
    transition : all 0.2s ease;
    &:hover {
        color : #fff;
        background-color : #74bbf1;
        ${props => props.hoverStyle};
    }
`;

export const SignUpButton = styled(SC.Div_Flex_Cell)`
    background-color : ${props => props.theme.button_back_primary};
    border :  ${props => props.theme.border_onlight_line};
    border-radius : 4px;
    color : white;
    height : 30px;
    margin-left : 8px;
    padding : 8px;
    box-sizing : border-box;
    &:hover {
        background-color : ${props => props.theme.button_back_hover_primary};
    }
`;
export const SignIn_SocialMedia_Icon = styled(SC.Div_Flex_Cell)`
    height: 100%;
    position: absolute;
    left:8px;
    top:0;
`;

export const SignIn_SocialMedia = (props) => {
    return (
        <SC.FCol style={{marginTop : '-10px', boxSizing : 'border-box', ...props.style}}>
            <span style={{ textAlign: 'center',  padding: '8px' }}>
                OR
            </span>
            <SubmitButton_Google onClick={props.SignInGoogle} >
                <SignIn_SocialMedia_Icon>
                    <SC.Icons.SocialMedia_Google size={24} />
                </SignIn_SocialMedia_Icon>
                {props.signUp ? 'Sign up' : 'Login'} with Google
            </SubmitButton_Google>
            <SubmitButton_Twitter onClick={props.SignInTwitter} >
                <SignIn_SocialMedia_Icon>
                    <SC.Icons.SocialMedia_Twitter size={24} />
                </SignIn_SocialMedia_Icon>
                {props.signUp ? 'Sign up' : 'Login'} with Twitter
            </SubmitButton_Twitter>
        </SC.FCol>
    )
}

export const Terms_Link = styled.div`
    text-align:center;
    color :#888888;
    font-size:12px;
    font-weight:300;
    width:100%;
    letter-spacing: -0.2px;
    line-height: 21.63px;
    margin-top:10px;
    a:link {
        color: #D4D4D4;
        font-weight:400;
    }
    a:visited {
        color: #D4D4D4;
        font-weight:400;
    }
    a:hover {
        color: #D4D4D4;
        font-weight:400;
    }
    a:active {
        color: #D4D4D4;
        font-weight:400;
    }
`;
export const LoginBox = (props) => (
    <SC.FCol f1 alignStretch style={{ maxWidth: '440px', zIndex: 10, padding: '36px', ...props.style }} justifyStart >
        <SC.Div_Flex_Cell pb={16}>
            <Logo isLight={SC.CurrentTheme.theme.isLight} size={140} />
        </SC.Div_Flex_Cell>
        <SC.Div_Flex_Cell style={{color : SC.CurrentTheme.theme.isLight ? '#4C5765' : 'unset'}}>
            <MottoText>{props.title}</MottoText>
        </SC.Div_Flex_Cell>
        <SC.FCol alc={AppLayout.Media.Small} style={{ marginTop: '24px' }}>
            {props.children}
        </SC.FCol>
    </SC.FCol>
)

class SignIn extends ReactBaseComponent {
    constructor(props) {
        super(props);
        this.Submit = this.Submit.bind(this);
        this.SignInGoogle = this.SignInGoogle.bind(this);
        this.SignInTwitter = this.SignInTwitter.bind(this);
        this.showTerms = this.showTerms.bind(this);
        this.showPrivacyPolicy = this.showPrivacyPolicy.bind(this);

        super.ListenEvents(Events.GLOBAL.MEDIASIZE.CHANGED);

        this.ErrorMessage = "";
        if (props.location) {
            const params = new URLSearchParams(props.location.search);
            if (params.get('email')) {
                this.Email = params.get('email');
            }
        }
        

        // this.Email = 'appplinist3@gmail.com';
        // this.Password = '2pGS25';
    }
    OnEvent() {
        this.RCUpdate();
    }
    componentDidMount() {
        super.componentDidMount();
        if (this.props.signup) {
            if (this.Ref_UserName)
                this.Ref_UserName.focus();
        }
        else {
            if (this.Ref_Email)
                this.Ref_Email.focus();
        }
    }

    ResetPassword() {
        var isValid = this.Ref_Email.validate();
        if (isValid) {
            AppState.ResetPassword(this.Ref_Email.getValue())
            .then(
                (result) => {
                    if (result.error) {
                        this.ErrorMessage = result.errorMessage;
                        this.RCUpdate();
                    }
                    else {
                        alert("Password Reset Email Sent! Check your Inbox.");
                    }
                }
            );
        }
    }
    Submit() {        
        if (this.props.isAuthenticated) {
            super.RedirectTo(Links.Main());
        }
        if (this.props.signup) {
            var isValid = this.Ref_Password.validate() & this.Ref_Email.validate() & this.Ref_UserName.validate();

            if (isValid) {
                AppState.SignUp({
                    email : this.Ref_Email.getValue(), 
                    password : this.Ref_Password.getValue(), 
                    user : this.Ref_UserName.getValue(),
                    reguestId : this.requestId, 
                    appSource : 'DSM', 
                    callback : ({userid, error})=>{
                        if (error) {
                            this.ErrorMessage = error.message;
                            this.RCUpdate();
                        }
                        else if (userid)
                            this.LoginSuccessful({uid: userid});
                    }
                });
                    // (Result) => {
                    //     if (!Result.Error)
                    //         this.props.OnLogin();
                    //     else {
                    //         this.ErrorMessage = Result.Message;
                    //         this.RCUpdate();
                    //     }
                    // }).then(
                    //     //this.Submit();
                    //     (userid) => {
                    //         this.ErrorMessage = "";
                    //         super.RedirectTo(Links.Login());
                    //     },
                    //     (error) => {
                    //         this.ErrorMessage = error.message;
                    //         this.RCUpdate();
                    //     }
                    // );
            }
        }
        else {
            var isValid = this.Ref_Password.validate() & this.Ref_Email.validate();
            if (isValid) {
                this.setState({
                    processing : true
                });
                AppState.Login(this.Ref_Email.getValue(), this.Ref_Password.getValue(),
                    (result) => {                        
                        if (result && result.Error) {
                            this.ErrorMessage = result.Message;                            
                            this.setState({
                                processing : false
                            });
                        } else {
                            this.setState({
                                processing : false
                            });
                            this.LoginSuccessful(result.User.user);
                        }
                    });
            }
        }
    }
    SignInGoogle() {
        AppState.LoginWithGoogle({appSource : 'DSM'}).then(
            (userid) => {
                this.ErrorMessage = "";
                super.RedirectTo(Links.Login());
            },
            (error) => {
                this.ErrorMessage = error.message;
                this.RCUpdate();
            }
        );
    }
    SignInTwitter() {
        AppState.LoginWithTwitter({appSource : 'DSM'}).then(
            (userid) => {
                this.ErrorMessage = "";
                super.RedirectTo(Links.Login());
            },
            (error) => {
                this.ErrorMessage = error.message;
                this.RCUpdate();
            }
        );
    }    
    LoginSuccessful(user) {
        if (this.requestId) {
            AppState.RegisterApiAuthentication(this.requestId, user || AppState.User)
                .then(
                    (result) => {
                        this.ErrorMessage = "";
                        this.RCUpdate();
                    }
                );
        }
        else {
            if (this.props.onLoggedIn) {
                this.props.onLoggedIn();
            }
            else {
                super.RedirectTo(Links.Main());
            }            
        }
    }
    showTerms(e) {
        this.OverlayManager = AppState.OverlayManager.Show(
            <SC.Div_Full_Center style={{ position: "relative" }}>
                <div style={{ position: 'absolute', color: '#454545', fontSize: "16px", right: 'calc((100vw / 2) - 400px)', top: '20px', cursor: 'pointer', padding: '10px' }}
                    onClick={() => { AppState.OverlayManager.Close(this.OverlayManager); }}
                >
                    <SC.Icons.Icon_Button hasFill>
                        <SC.Icons.Icon_Close size={40} />
                    </SC.Icons.Icon_Button>
                </div>
                <TermsAndPrivacy terms onClose={() => { AppState.OverlayManager.Close(this.OverlayManager); }} />
            </SC.Div_Full_Center>, { NoClose: true }
        );
        e.preventDefault();
    }
    showPrivacyPolicy(e) {
        this.OverlayManager = AppState.OverlayManager.Show(
            <SC.Div_Full_Center style={{ position: "relative" }}>
                <div style={{ position: 'absolute', color: '#454545', fontSize: "16px", right: 'calc((100vw / 2) - 400px)', top: '20px', cursor: 'pointer', padding: '10px' }}
                    onClick={() => { AppState.OverlayManager.Close(this.OverlayManager); }}
                >
                    <SC.Icons.Icon_Button hasFill>
                        <SC.Icons.Icon_Close size={40} />
                    </SC.Icons.Icon_Button>
                </div>
                <TermsAndPrivacy onClose={() => { AppState.OverlayManager.Close(this.OverlayManager); }} />
            </SC.Div_Full_Center>, { NoClose: true }
        );
        e.preventDefault();
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.signup !== nextProps.signup) {
            delete this.ErrorMessage;
            this.state.processing = false;
        }
        return true;
    }
    renderCustom() {
        if (this.props.showBrowserNotice) {
            return (
                <SC.Div_Flex_Cell>
                    <MottoText>Component Design Studio</MottoText>
                </SC.Div_Flex_Cell>
            )
        }

        let content = null;
        if (this.props.signup) {
            content = (
                <LoginBox title='Design Platform'>
                    <SC.FCol alignStretch style={AppLayout.Media.Small ? { width: '100%', maxWidth: '400px' } : {}}>
                        <SC.FRow justifyEnd alc mb={16}>
                            Already have an account?
                            <Links.Link To={Links.Login()}>
                                <SignUpButton>LOGIN</SignUpButton>
                            </Links.Link>
                        </SC.FRow>
                        <SC.FCol alignStretch f1>
                            <FormInput
                                ref={(r) => this.Ref_UserName = r}
                                label="Name"
                                type='name'
                                value={this.UserName}
                                onSubmit={this.Submit}
                            />
                        </SC.FCol>
                        <SC.FCol alignStretch f1>
                            <FormInput
                                key='email'
                                ref={(r) => this.Ref_Email = r}
                                type='email'
                                label="Email"
                                value={this.Email}
                                onSubmit={this.Submit}
                            />
                        </SC.FCol>
                        <SC.FCol alignStretch f1 >
                            <FormInput
                                key='password'
                                ref={(r) => this.Ref_Password = r}
                                label="Password"
                                type='password'
                                value={this.Password}
                                onSubmit={this.Submit}
                            />
                        </SC.FCol>
                        {/* <SC.FCol alignStretch f1>
                            <FormInput
                                label="Repeat password"
                                type='password'
                                value={this.PasswordRepeat}
                                onSubmit = {this.Submit}
                            />
                        </SC.FCol> */}
                        <SC.FCol alignStretch mt={20} mb={10}>
                            <SubmitButton onClick={this.Submit} >SIGN UP</SubmitButton>
                            {this.ErrorMessage && this.ErrorMessage.length > 0 && <AuthErrorBox isInvalid={this.ErrorMessage && this.ErrorMessage.length > 0 ? true : false}>
                                {this.ErrorMessage}
                            </AuthErrorBox>
                            }
                        </SC.FCol>
                        <SignIn_SocialMedia signUp SignInGoogle={this.SignInGoogle} SignInTwitter={this.SignInTwitter} />
                        <Terms_Link >
                            By signing up you agree to our <a href="#" onClick={this.showTerms}>  Terms & Conditions </a> and <a href="#" onClick={this.showPrivacyPolicy}>  Privacy Policy </a>
                        </Terms_Link>
                    </SC.FCol>
                </LoginBox>
            )
        }
        else {
            content = (
                <LoginBox title='Design Platform'>
                    <SC.FCol alignStretch style={AppLayout.Media.Small ? { width: '100%', maxWidth: '400px' } : {}}>
                        <SC.FRow justifyEnd alc mb={16}>
                            Don't have an account?
                            <Links.Link To={Links.SignUp()}>
                                <SignUpButton>Get One</SignUpButton>
                            </Links.Link>
                        </SC.FRow>
                        <SC.FCol alignStretch f1>

                            <FormInput
                                key='email'
                                ref={(r) => this.Ref_Email = r}
                                autoFocus
                                label="Email"
                                type='email'
                                value={this.Email}
                                onSubmit={this.Submit}
                            />
                        </SC.FCol>
                        <SC.FCol alignStretch f1 mb={4}>
                            <FormInput
                                key='password'
                                ref={(r) => this.Ref_Password = r}
                                label="Password"
                                type='password'
                                value={this.Password}
                                onSubmit={this.Submit}
                            />
                        </SC.FCol>
                        <SC.FRow justifyEnd>
                            <SC.Div_Styled cursorPointer onClick={() => this.ResetPassword()}>
                                Forgot your password?
                            </SC.Div_Styled>
                        </SC.FRow>
                        <SC.FCol alignStretch mt={20} mb={10}>
                            <SubmitButton onClick={this.Submit}>LOGIN</SubmitButton>
                            {this.ErrorMessage && this.ErrorMessage.length > 0 && <AuthErrorBox isInvalid={this.ErrorMessage && this.ErrorMessage.length > 0 ? true : false}>
                                {this.ErrorMessage}
                            </AuthErrorBox>
                            }
                        </SC.FCol>
                        <SignIn_SocialMedia SignInGoogle={this.SignInGoogle} SignInTwitter={this.SignInTwitter} />
                    </SC.FCol>
                </LoginBox>
            )
        }

        return (
            <SC.Div_Full_Center>
                <StyledMain>
                    <SC.FRow f1 alc justifyCenter fw fh style={{ position: 'relative', alignSelf: 'stretch' }}>
                        {
                            AppLayout.Media.Small ?
                                content :
                                <SC.FRow f1 style={{ alignSelf: 'stretch' }}>
                                    {content}
                                </SC.FRow>
                        }
                    </SC.FRow>                    
                    {
                        !this.props.isAuthenticated && this.state.processing &&
                        <SC.AbsoluteOverlay style={{...SC.Styles.Flex.Cell, backgroundColor : 'rgba(0,0,0,0.5)', zIndex : 100}}>
                            <SC.Loading_Icon />
                        </SC.AbsoluteOverlay>
                    }
                </StyledMain>
            </SC.Div_Full_Center>
        );
    }
}

export default SignIn;

const StyledMain = styled.div`
    width : 400px;    
    max-width : 400px;
    border-radius : 8px;
    background-color : ${props => props.theme.back_lighter};
    display : flex;
    box-shadow : ${props => props.theme.card_shadow};
    overflow : hidden;
`;