import React from 'react';
import ReactBase from '../ReactBase';
import ReactDOM from 'react-dom';
import Utils from '../../toolabs-utils';
import MetaData from '../../toolabs-metaui';

export const BuildItemStyles = (MetaItem, props) => {
    const ItemData = {
        style : {
            position : 'relative',        
        },
        props : {
            
        },
        tokens : {},
        models : {},
        transitions : {}
    };

    const SetTokenBinding = (owner, tokenId, propName) => {
        const bindings = Utils.Get(owner, [], 'tokens', tokenId);
        bindings.push(propName);
    }
    const SetModelBinding = (owner, modelId, propName) => {
        const bindings = Utils.Get(owner, [], 'models', modelId);
        bindings.push(propName);
    }

    Utils.ForEach(props.StyleState, (StyleState, i) => {
        ItemData.style = Utils.Merge(ItemData.style, Utils.JustGet(MetaItem, {}, 'Styles', 'Static', StyleState.Global, StyleState.Component));
        ItemData.props = Utils.Merge(ItemData.props, Utils.JustGet(MetaItem, {}, 'Props', 'Static', StyleState.Global, StyleState.Component));

        const Resets = Utils.JustGet(MetaItem, {}, 'Reset', StyleState.Global, StyleState.Component);
        Utils.ForEach(Resets, (isReset, propName) => {
            if (isReset) {
                delete ItemData.style[propName];
                delete ItemData.props[propName];
            }
        });
    });


    if (props.StyleState) {
        const GetTokenId = (SystemState, ComponentState, StyleName) => {
            return Utils.JustGet(MetaItem, null, 'Tokens', SystemState, ComponentState, StyleName);
        }
        const GetModelId = (SystemState, ComponentState, StyleName) => {
            return Utils.JustGet(MetaItem, null, 'Models', SystemState, ComponentState, StyleName);
        }
        const SetTokenStyle = (GlobalState, ComponentState, PropName, StyleName) => {
            if (!HandledStyles[PropName]) {
                const tokenId = GetTokenId(GlobalState, ComponentState, PropName);
                if (tokenId && props.Theme[tokenId]) {
                    ItemData.style[StyleName || PropName] = props.Theme[tokenId];
                    HandledStyles[PropName] = true;
                    SetTokenBinding(ItemData, tokenId, StyleName || PropName);
                }
            }
        }
        const SetTokenProp = (GlobalState, ComponentState, PropName, StyleName) => {
            if (!HandledStyles[PropName]) {
                const tokenId = GetTokenId(GlobalState, ComponentState, PropName);
                if (tokenId && props.Theme[tokenId]) {
                    ItemData.props[StyleName || PropName] = props.Theme[tokenId];
                    HandledStyles[PropName] = true;
                    SetTokenBinding(ItemData, tokenId, StyleName || PropName);
                }
            }
        }
        const SetModelProp = (GlobalState, ComponentState, PropName, StyleName) => {
            if (!HandledStyles[PropName]) {
                const modelId = GetModelId(GlobalState, ComponentState, PropName);
                if (modelId && Utils.IsNotNull(props.Models[modelId])) {
                    ItemData.props[StyleName || PropName] = props.Models[modelId];                    
                    HandledStyles[PropName] = true;
                    SetModelBinding(ItemData, modelId, StyleName || PropName);
                }
            }
        }
        const SetModelStyle = (GlobalState, ComponentState, PropName, StyleName, convertValue) => {
            if (!HandledStyles[PropName]) {
                const modelId = GetModelId(GlobalState, ComponentState, PropName);
                if (modelId && Utils.IsNotNull(props.Models[modelId])) {
                    let modelValue = props.Models[modelId];
                    if (convertValue)
                        modelValue = convertValue(modelValue);
                    if (Utils.IsNotNull(modelValue)) {
                        ItemData.style[StyleName || PropName] = modelValue;                        
                    }
                    SetModelBinding(ItemData, modelId, StyleName || PropName);
                }
            }
        }
        const HandledStyles = {};
        Utils.ForEach(props.ReverseStyleState, (StyleState, i) => {    
            SetModelStyle(StyleState.Global, StyleState.Component, 'backgroundColor');
            SetTokenStyle(StyleState.Global, StyleState.Component, 'backgroundColor');
            SetTokenStyle(StyleState.Global, StyleState.Component, 'gradient', 'backgroundImage');
            SetTokenStyle(StyleState.Global, StyleState.Component, 'textGradient', 'backgroundImage');
            SetModelStyle(StyleState.Global, StyleState.Component, 'color');
            SetTokenStyle(StyleState.Global, StyleState.Component, 'color');
            SetTokenStyle(StyleState.Global, StyleState.Component, 'fill');
            SetTokenStyle(StyleState.Global, StyleState.Component, 'boxShadow');
            SetTokenStyle(StyleState.Global, StyleState.Component, 'textShadow');    
            SetModelStyle(StyleState.Global, StyleState.Component, 'opacity', 'opacity', (opacity) => {
                if (Utils.IsNumber(opacity)) {
                    return Math.min(1, Math.max(0, opacity / 100));
                }
                return null;
            });   

            SetModelProp(StyleState.Global, StyleState.Component, 'hidden')

            if (!HandledStyles.transform) {
                const tokenIdTransform = GetTokenId(StyleState.Global, StyleState.Component, 'transform');
                if (tokenIdTransform && props.Theme[tokenIdTransform] && Utils.IsObject(props.Theme[tokenIdTransform])) {
                    ItemData.style.transform = props.Theme[tokenIdTransform].transform;
                    ItemData.style.transformOrigin = props.Theme[tokenIdTransform].transformOrigin;
                    HandledStyles.transform = true;
                    SetTokenBinding(ItemData, tokenIdTransform, 'transform');
                }
            }

            if (!HandledStyles.filter) {
                const tokenIdFilter = GetTokenId(StyleState.Global, StyleState.Component, 'filter');
                if (tokenIdFilter && props.Theme[tokenIdFilter]) {
                    ItemData.style.filter = props.Theme[tokenIdFilter];
                    HandledStyles.filter = true;
                    SetTokenBinding(ItemData, tokenIdFilter, 'filter');
                }
            }

            SetModelProp(StyleState.Global, StyleState.Component, 'text', 'text'); 
            SetTokenProp(StyleState.Global, StyleState.Component, 'text', 'text'); 

            


            const modelIdImage = GetModelId(StyleState.Global, StyleState.Component, 'backgroundImage');
            const imageStyle = {};

            const modelIdpositionX = GetModelId(StyleState.Global, StyleState.Component, 'customPositionX');
            if (modelIdpositionX) {
                ItemData.props.positionX = Utils.px(props.Models[modelIdpositionX], '%');
                SetModelBinding(ItemData, modelIdpositionX, 'customPositionX');
            }
            else {
                ItemData.props.positionX = ItemData.props.positionX;
            }

            const modelIdpositionY = GetModelId(StyleState.Global, StyleState.Component, 'customPositionY');
            if (modelIdpositionY) {
                ItemData.props.positionY = Utils.px(props.Models[modelIdpositionY], '%');
                SetModelBinding(ItemData, modelIdpositionY, 'customPositionY');
            }
            else {
                ItemData.props.positionY = ItemData.props.positionY;
            }
                    
            const modelBackgroundWidth = GetModelId(StyleState.Global, StyleState.Component, 'backgroundWidth');
            if (modelBackgroundWidth) {
                ItemData.props.modelBackgroundWidth = Utils.px(props.Models[modelBackgroundWidth], '%');
                SetModelBinding(ItemData, modelBackgroundWidth, 'backgroundWidth');
            }
            else {
                ItemData.props.backgroundWidth = ItemData.props.backgroundWidth;
            }

            const modelBackgroundHeight = GetModelId(StyleState.Global, StyleState.Component, 'backgroundHeight');
            if (modelBackgroundHeight) {
                ItemData.props.backgroundHeight = Utils.px(props.Models[modelBackgroundHeight], '%');
                SetModelBinding(ItemData, modelBackgroundHeight, 'backgroundHeight');
            }
            else {
                ItemData.props.backgroundHeight = ItemData.props.backgroundHeight;
            }

            if (modelIdImage && Utils.IsNotNull(props.Models[modelIdImage])) {
                const modelValue = props.Models[modelIdImage];                
                imageStyle.backgroundImage = Utils.url(modelValue.url);
                if (modelValue.backgroundSize) {
                    imageStyle.backgroundSize = modelValue.backgroundSize;
                }
                else {

                }
                if (modelValue.backgroundPosition) {
                    imageStyle.backgroundPosition = modelValue.backgroundPosition;
                }
                else {
                    
                }
                
                SetModelBinding(ItemData, modelIdImage, 'backgroundImage');
            }   
            else {
                const tokenIdImage = GetTokenId(StyleState.Global, StyleState.Component, 'url');
                if (tokenIdImage) {
                    SetTokenBinding(ItemData, tokenIdImage, 'backgroundImage');
                    imageStyle.backgroundImage = Utils.url(props.Theme[tokenIdImage]);
                }
            }  
            
            
            if (imageStyle.backgroundImage) {
                if (!imageStyle.backgroundSize) {
                    if (ItemData.props.backgroundWidth || ItemData.props.backgroundHeight) {
                        imageStyle.backgroundSize = `${ItemData.props.backgroundWidth || 'auto'} ${ItemData.props.backgroundHeight || 'auto'}`;
                    }
                }
                if (!imageStyle.backgroundPosition) {
                    imageStyle.backgroundPosition = `${ItemData.props.positionX || 'center'} ${ItemData.props.positionY || 'center'}`;
                }
                
                ItemData.style = Utils.Merge(ItemData.style, {
                    backgroundRepeat : 'no-repeat',
                    boxSizing : 'border-box'
                }, imageStyle)
            }            
            

            Utils.BorderStyles.Sides.map((side) => {
                const propStyle = Utils.BorderStyles.propStyle(side);
                const propSize = Utils.BorderStyles.propSize(side);
                const propColor = Utils.BorderStyles.propColor(side);
                if (!HandledStyles[propStyle]) {
                    const tokenId = GetTokenId(StyleState.Global, StyleState.Component, propStyle);
                    if (tokenId && props.Theme[tokenId]) {
                        if (props.Theme[tokenId].borderStyle)
                            ItemData.style[propStyle] = props.Theme[tokenId].borderStyle;
                        if (props.Theme[tokenId].borderWidth)
                            ItemData.style[propSize] = props.Theme[tokenId].borderWidth;
                        
                        HandledStyles[propStyle] = true;
                        SetTokenBinding(ItemData, tokenId, 'borderStyle');
                    }
                }                

                SetTokenStyle(StyleState.Global, StyleState.Component, propColor);

                const spacings = [Utils.BorderStyles.propPadding(side), Utils.BorderStyles.propMargin(side)];
                spacings.map((prop) => {
                    if (!HandledStyles[prop]) {
                        const tokenId = GetTokenId(StyleState.Global, StyleState.Component, prop);
                        if (tokenId) {
                            const themeValue = Utils.JustGet(props.Theme, null, tokenId);
                            if (themeValue) {
                                ItemData.style[prop] = themeValue;
                                HandledStyles[prop] = true;
                                SetTokenBinding(ItemData, tokenId, 'spacing');
                            }                            
                        }
                    }
                })                
            });
            Utils.BorderStyles.Corners.map((side) => {
                const propRadius = Utils.BorderStyles.propRadius(side);
                SetTokenStyle(StyleState.Global, StyleState.Component, propRadius);
            });  

            if (MetaItem.Type === MetaData.Components.Text.Type) {
                if (!HandledStyles.text) {
                    const textvalue = Utils.JustGet(MetaItem, undefined, 'Props', 'Static', StyleState.Global, StyleState.Component, 'text');
                    if (textvalue !== undefined) {
                        ItemData.props.text = textvalue;
                        HandledStyles.text = true;
                    }
                }                    
            }
            else if (MetaItem.Type === 'Widget') {
                if (ItemData.props.Type === 'Video') {
                    SetModelProp(StyleState.Global, StyleState.Component, 'VideoUrl'); 
                }
                if (ItemData.props.Type === 'Lottie') {
                    SetModelProp(StyleState.Global, StyleState.Component, 'LottieUrl'); 
                }
            }

            if (!HandledStyles.textPattern) {
                const token_textPattern = GetTokenId(StyleState.Global, StyleState.Component, 'textPattern');
                if (token_textPattern) {                    
                    const textStyle = props.Theme[token_textPattern];
                    if (textStyle) {
                        ItemData.style = Utils.Merge(ItemData.style, textStyle);
                        HandledStyles.textPattern = true;
                        SetTokenBinding(ItemData, token_textPattern, 'textPattern');
                    }                        
                }
            }    
            
            if (!HandledStyles.icon) {
                const modelId_icon = GetModelId(StyleState.Global, StyleState.Component, 'icon')
                let icon;
                if (modelId_icon) {
                    icon = props.Models[modelId_icon];
                }
                if (!icon) {
                    const token_icon = GetTokenId(StyleState.Global, StyleState.Component, 'icon');
                    if (token_icon) {   
                        icon = props.Theme[token_icon];                        
                    }    
                }

                if (icon) {   
                    ItemData.props.icon = icon;
                    HandledStyles.icon = true;
                }
            }

            if (!HandledStyles.hover) {
                BuildItemTransitions({
                    MetaItem : MetaItem,
                    StyleState : StyleState,
                    props : props,
                    transitions : ItemData.transitions
                })                
            }
            
        });      
    }

    if (props.RootItem && props.RootItem.onGetPseudoStates) {
        const RootItemPseudoState = props.RootItem.onGetPseudoStates();

        if (RootItemPseudoState) {

            Utils.ForEach(props.StyleState, (StyleState, i) => {
                Utils.ForEach(RootItemPseudoState, (pseudoState) => {
                    ItemData.style[`:${pseudoState}`] = Utils.Merge(ItemData.style[`:${pseudoState}`],
                        Utils.JustGet(MetaItem, {}, 'Styles', 'Static', StyleState.Global, pseudoState)
                    );

                    const pseudoCurrentState = `${StyleState.Component},${pseudoState}`;
                        ItemData.style[`:${pseudoState}`] = Utils.Merge(ItemData.style[`:${pseudoState}`],
                        Utils.JustGet(MetaItem, {}, 'Styles', 'Static', StyleState.Global, pseudoCurrentState)
                    );

                    const SetTokens = (Tokens) => {
                        Utils.ForEach(Tokens, (tokenId, PropName) => {
                            if (tokenId && props.Theme[tokenId]) {
                                if (PropName == 'borderStyle') {
                                    ItemData.style[`:${pseudoState}`].borderStyle = props.Theme[tokenId].borderStyle;
                                    ItemData.style[`:${pseudoState}`].borderWidth = props.Theme[tokenId].borderWidth;
                                }
                                else if (PropName == 'transform') {
                                    ItemData.style[`:${pseudoState}`].transform = props.Theme[tokenId].transform;
                                    ItemData.style[`:${pseudoState}`].transformOrigin = props.Theme[tokenId].transformOrigin;
                                }
                                else if (PropName == 'filter') {
                                    ItemData.style[`:${pseudoState}`].filter = props.Theme[tokenId];
                                }
                                else
                                    ItemData.style[`:${pseudoState}`][PropName] = props.Theme[tokenId];
                            }
                        });
                    }

                    const Tokens_Pseudo = Utils.JustGet(MetaItem, {}, 'Tokens', StyleState.Global, pseudoState);
                    SetTokens(Tokens_Pseudo);                    
                    const Tokens_PseudoCurrent = Utils.JustGet(MetaItem, {}, 'Tokens', StyleState.Global, pseudoCurrentState);
                    SetTokens(Tokens_PseudoCurrent);
                });                
            });
      
        }
    }

    return ItemData;
}

export const BuildItemTransitions = ({MetaItem, StyleState, props, transitions}) => {
    const Transitions = Utils.JustGet(MetaItem, null, 'Tokens', StyleState.Global, StyleState.Component, 'Transitions');

    if (Transitions && Transitions.hover) {
        if (props.Theme[Transitions.hover]) {
            const easeToken = props.Theme[props.Theme[Transitions.hover].easeId];
            const durationToken = Utils.JustGet(props.Theme, 200, props.Theme[Transitions.hover].durationId) / 1000;
            const transformToken = Utils.JustGet(props.Theme[props.Theme[Transitions.hover].transformId], {}, 'transforms');
            const transformOrigin = Utils.JustGet(props.Theme[props.Theme[Transitions.hover].transformId], 'center center', 'transformOrigin');
            const changes = {
                ...transformToken
            };
            const opacity = props.Theme[Transitions.hover].opacity;
            if (Utils.UseNullOrEmpty(opacity)) {
                changes.opacity = opacity;

            }

            Utils.Set(transitions, {
                transformOrigin : transformOrigin,
                transition : {
                    ease : easeToken || [0.42, 0, 0.58, 1],
                    duration : Utils.UseNullOrEmpty(durationToken, 0.2),
                    restSpeed : 1
                },                   
                changes : changes                                          
            }, 'hover');
        }
    }
}

export default class BaseItem extends ReactBase
{
    constructor(props) {
        super(props);

        this.LoadItem(this.props);        
    }    
    LoadItem(props) {
        
        this.StateStatics = BuildItemStyles(props.MetaItem, props);
        this.PostStyle(props);
    }
    PostStyle(props) {
        const MergedStyle = Utils.Merge(this.StateStatics.style, props.OuterStyle);
        this.StateStatics.style = MergedStyle;
        this.StateStatics.style.boxSizing = 'border-box';

        if ((this.StateStatics.style.background || this.StateStatics.style.backgroundImage) && this.props.MetaItem.Type === 'Text') {
            this.StateStatics.style['-webkit-background-clip'] = 'text';
            this.StateStatics.style['-webkit-text-fill-color'] = 'transparent';
        }

    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.StateId !== nextProps.StateId || this.props.ThemeId !== nextProps.ThemeId) {
            this.LoadItem(nextProps);
            return true;
        }
        if (!Utils.IsEqual(this.props.Models, nextProps.Models)) {
            const modelbindings = Utils.JustGet(this.StateStatics, null, 'models');
            if (modelbindings) {
                Utils.ForEach(modelbindings, (bindings, modelId) => {
                    const modelValue = nextProps.Models[modelId];
                    if (Utils.IsNotNull(modelValue)) {
                        Utils.ForEach(bindings, (propName, i) => {                        
                            if (propName === 'backgroundImage') {
                                this.StateStatics.style.backgroundImage = Utils.url(modelValue.url);
                                if (modelValue.backgroundSize) {
                                    this.StateStatics.style.backgroundSize = modelValue.backgroundSize;
                                }
                                if (modelValue.backgroundPosition) {
                                    this.StateStatics.style.backgroundPosition = modelValue.backgroundPosition;
                                }
                            }
                            else if (Utils.IsOneOf(propName, 'text')) {
                                this.StateStatics.props[propName] = modelValue;
                            }
                            else if (Utils.IsOneOf(propName, 'customPositionX', 'customPositionY')) {                                
                                if (propName === 'customPositionX') {
                                    this.StateStatics.props.positionX = Utils.px(modelValue, '%');
                                }
                                else if (propName === 'customPositionY')
                                    this.StateStatics.props.positionY = Utils.px(modelValue, '%');

                                this.StateStatics.style.backgroundPosition = `${this.StateStatics.props.positionX || 'center'} ${this.StateStatics.props.positionY || 'center'}`;
                            }
                            else if (Utils.IsOneOf(propName, 'backgroundWidth', 'backgroundHeight')) {
                                let backgroundWidth = this.StateStatics.props.backgroundWidth;
                                let backgroundHeight = this.StateStatics.props.backgroundHeight;
                                if (propName === 'backgroundWidth')
                                    backgroundWidth = Utils.px(modelValue, '%');
                                else if (propName === 'backgroundHeight')
                                    backgroundHeight = Utils.px(modelValue, '%');
                                this.StateStatics.style.backgroundSize = `${backgroundWidth || 'auto'} ${backgroundHeight || 'auto'}`;
                            }
                            else if (propName === 'opacity') {
                                if (Utils.IsNumber(modelValue)) {
                                    this.StateStatics.style.opacity = Math.min(1, Math.max(0, modelValue / 100));
                                }
                            }
                            else {
                                this.StateStatics.props[propName] = modelValue;
                            }
                        });
                    }
                });
            }
            return true;
        }
        return false;
    }
    render() {
        const {MetaItem} = this.props;

        if (Utils.IsTrue(Utils.Get(this.StateStatics, false, 'props', 'hidden'))) {
            return null;
        }

        if (this.renderItem) {

            let style = Utils.DeepClone(this.StateStatics.style);
            let props = Utils.DeepClone(this.StateStatics.props);

            if (this.stateHover) {
                style = Utils.Merge(style, Utils.JustGet(this.StateStatics, null, 'states', 'Hover', 'style'));
                props = Utils.Merge(props, Utils.JustGet(this.StateStatics, null, 'states', 'Hover', 'props'));
            }

            let rest = {};
            if (this.props.Design) {
                style = Utils.Merge(style, this.props.Design.style);
                rest = Utils.Merge(rest, this.props.Design.props);
            }

            // if (Utils.IsOneOf(MetaItem.Type, 'Div', 'Button', 'Input', 'Repeater')) 
            {
                if (this.StateStatics.props.Gradient)
                    style.backgroundImage = this.StateStatics.props.Gradient;
                if (this.StateStatics.props.url) {
                    style = Utils.Merge(
                        {
                            backgroundSize : 'cover',
                            backgroundRepeat : 'no-repeat',
                            backgroundPosition : 'center',
                            boxSizing : 'border-box'
                        },
                        style
                    );
                    if (this.StateStatics.props.backgroundSize) {
                        const {width, height} = this.StateStatics.props.backgroundSize;
                        style.backgroundSize = `${width ? width : 'auto'} ${height ? height : 'auto'}`;
                    }
                    if (style.backgroundImage)
                        style.backgroundImage += ', ' + Utils.url(this.StateStatics.props.url);
                    else
                        style.backgroundImage = Utils.url(this.StateStatics.props.url);
                }
            }

            let events = this.StateStatics.events;

            if (this.StateStatics.states && this.StateStatics.states.Hover) {
                events = Utils.Merge(events, {
                    onMouseEnter : this.onMouseEnter.bind(this),
                    onMouseLeave : this.onMouseLeave.bind(this)
                });
            }

            return this.renderItem({
                style : style,
                props : props,
                events : events,
                transitions : this.StateStatics.transitions,
                rest : rest
            })
        }
        return (
            <div style={this.LastStyle}>
                {MetaItem.Type}
            </div>
        )
    }
}