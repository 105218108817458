import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    Strings
} from '../../importer';
import * as Styled from './styles';
import InputForm from './input_form';
import Switch from '../../components/editors/Switch';
import Input_Name from '../../components/editors/input_name';
import { SignIn_SocialMedia_Icon, SubmitButton_Twitter } from '../auth';
import { PanelLabel } from '../project/panels/left/designsystem/common';
import { Input_ProjectName } from '../projectlist/list';
import { GetDataApi, GetUserName } from '../../appstate/AppState';
import firebase from 'firebase';
import { InfoPanel } from '../../components/info';
import { Seperator } from '../project/panels/right/iteminspector/styleitems/common';


export default class Account_Settings extends ReactBaseComponent {
    constructor(props) {
        super(props);

        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onClickResetPassword = this.onClickResetPassword.bind(this);
        this.onClickDeleteAccount = this.onClickDeleteAccount.bind(this);
        this.onEmailNotificationChanged = this.onEmailNotificationChanged.bind(this);
        this.onPushNotificationChanged = this.onPushNotificationChanged.bind(this);
        this.onPlaySoundsChanged = this.onPlaySoundsChanged.bind(this);
        this.LinkToEmail = this.LinkToEmail.bind(this);
        

        this.loaded = false;
        this.name = AppState.User.displayName;
        this.emailNotification = true;
        this.pushNotification = false;
        this.playSounds = true;
    }
    componentDidMount() {
        super.componentDidMount();
        this.load();
    }
    load() {
        this.loaded = false;
        this.name = AppState.User.displayName;     
        const user = firebase.auth().currentUser;

        if (user) {
            this.state.name = user.displayName;
            this.state.email = user.email;

            if (user.providerData && user.providerData.length > 0) {
                this.provider = user.providerData[0];
                this.providerId = user.providerData[0].providerId;
            }             
        }

        AppState.DesignData.Admin.UserInfo(AppState.User.uid).then(
            (result) => {
                if (result && result.value) {
                    if (result.value.settings) {
                        this.emailNotification = result.value.settings.email_notification;
                        this.pushNotification = result.value.settings.push_notification;
                        this.playSounds = result.value.settings.play_sounds;                        
                    }
                    // this.state.name = GetUserName();
                    // this.state.email = result.value.email;
                    this.userInfo = result.value;
                    // this.provider = result.value.provider;
                }
                this.loaded = true;                
                this.RCUpdate();
            }
        );

    }
    
    onEmailNotificationChanged(emailNotification) {

        this.emailNotification = emailNotification;

    }
    onPushNotificationChanged(pushNotification) {
        this.pushNotification = pushNotification;
        if (pushNotification) {
            AppState.RequestNotificationPermission();
        }
    }
    onPlaySoundsChanged(playSounds) {
        this.playSounds = playSounds;
    }
    onSave() {
        var userSettings =
        {
            email_notification: this.emailNotification,
            push_notification: this.pushNotification,
            play_sounds: this.playSounds
        };
        AppState.UpdateUserSettings({uid : AppState.User.uid, name : this.name, setting : userSettings});
        //Events.AlertSimple(Strings.SAVED_SUCCESS, Events.GLOBAL.NOTIFICATION.TYPES.SUCCESS);
        Events.Notify(Strings.SAVED_SUCCESS, Events.GLOBAL.NOTIFICATION.TYPES.SUCCESS, true);
    }
    onCancel() {
        this.props.onClose && this.props.onClose();
    }
    onClickResetPassword() {
        AppState.ResetPassword(this.state.email)
        .then(
            (result) => {
                if (result.error) {
                    this.ErrorMessage = result.error.errorMessage;
                    this.RCUpdate();
                }
                else {
                    Events.AlertSimple(Strings.PASSWORD_RESET_MAIL_SENT, Events.GLOBAL.NOTIFICATION.TYPES.INFO, "Change your password", "Please check your inbox!", SC.Icons.Icon_Email_Sent);
                }
            }
        );
    }
    onClickDeleteAccount() {
        Events.Ask_YesNo(Strings.Delete_Account(this.state.email), Events.GLOBAL.NOTIFICATION.TYPES.WARNING, "Delete your account?", "Your account will be removed permanantly!", SC.Icons.Icon_Delete_Warning, true).then((result) => {
            if (result === Events.GLOBAL.NOTIFICATION.RESULT.YES) {
                AppState.DeleteUser(AppState.User.uid).then(function () {
                    AppState.LogOut();
                }).catch(function (error) {

                });
            }
        });
    }

    LinkToEmail() {
        if (!Utils.IsNotNullOrEmpty(this.state.password)) {
            alert('Password cannot be empty!');
            return;
        }
        var credential = firebase.auth.EmailAuthProvider.credential(this.state.newEmail, this.state.password);
        const user = firebase.auth().currentUser;
        user.unlink(this.providerId).then(() => {
            user.linkWithCredential(credential);
            if (user.providerData && user.providerData.length > 0) {
                this.provider = user.providerData[0];
                this.providerId = user.providerData[0].providerId;
            } 
            this.setState({showChangeProvider : false});            
        })

    }

    renderCustom() {
        if (!this.loaded)
            return (
                <Loading />
            );


        let content, title;

        if (this.state.showChangeProvider) {
            title = 'Account Settings / Change to Email'
            content = (
                <SC.FCol style={{
                    width : '400px',
                    padding : '8px'
                }}>
                    <PanelLabel>NEW EMAIL</PanelLabel>
                    <Input_Name
                        InputType={Input_ProjectName}
                        required
                        value={this.state.newEmail}
                        styleProps = {{
                            placeholder : 'New Email',
                            style : {
                                fontSize : '16px',
                                lineHeight : '26px'
                            },
                            onBlur : () => {
                                
                                // Globals.ProjectManager.SaveName(this.state.name);
                                this.RCUpdate();
                            }
                        }}
                        onChange={(name) => {
                            this.setState({newEmail : name})
                        }}
                        onCancel={this.Cancel}
                        onSubmit={(name) => {
                            // Globals.ProjectManager.SaveName(this.state.name);
                            this.RCUpdate();
                        }}
                        showError
                    />
                    <PanelLabel style={{marginTop : '24px'}}>PASSWORD</PanelLabel>
                    <Input_Name
                        InputType={Input_ProjectName}
                        required
                        value={this.state.password}
                        styleProps = {{                            
                            style : {
                                fontSize : '16px',
                                lineHeight : '26px'
                            }
                        }}
                        onChange={(password) => {
                            this.setState({password : password})
                        }}
                        showError
                    />               
                    <div style={{
                        display : 'grid',
                        gridTemplateColumns : 'auto auto',
                        gridGap : '16px',
                        marginTop : '24px',
                        justifyContent : 'end'
                    }}>
                        <SC.Buttons.RoundButton style={{width : 'auto', paddingLeft : '16px', paddingRight : '16px'}} onClick={() => this.setState({showChangeProvider : false})}>
                            Cancel
                        </SC.Buttons.RoundButton>  
                        <SC.Buttons.RoundButton style={{width : 'auto', paddingLeft : '16px', paddingRight : '16px'}} onClick={this.LinkToEmail}>
                            Change Account to Email
                        </SC.Buttons.RoundButton>  
                    </div>                                  
                </SC.FCol>
            )
        }
        else {
            title = 'Account Settings'

            let provider, canChangeProvider;

            if (this.providerId === 'twitter.com') {
                canChangeProvider = true;
                provider = (
                    <div style={{ 
                        display : 'grid',
                        gridTemplateColumns : 'auto 1fr',
                        gridGap : '16px',
                        alignItems : 'center',
                        flex : 1,
                        justifyItems : 'start',
                        fontSize : '16px',
                        padding : '8px 16px',
                        backgroundColor : '#55ACEE',
                        color : '#fff'
                    }} 
                        hoverStyle={{
                            backgroundColor : 'inherit'
                        }}
                    >
                        <SC.Icons.SocialMedia_Twitter size={24} />
                        Twitter
                    </div>
                )
            }
            else if (this.providerId === 'google.com') {
                canChangeProvider = true;
                provider = (
                    <div style={{ 
                        display : 'grid',
                        gridTemplateColumns : 'auto 1fr',
                        gridGap : '16px',
                        alignItems : 'center',
                        flex : 1,
                        justifyItems : 'start',
                        fontSize : '16px',
                        padding : '8px 16px',
                        backgroundColor : '#f3f3f3',
                        color : '#444'
                    }} 
                        hoverStyle={{
                            backgroundColor : 'inherit'
                        }}
                    >
                        <SC.Icons.SocialMedia_Google size={24} />
                        Google
                    </div>
                )
            }
            else {
                provider = (
                    <SC.FCol>                        
                        <SC.LinkText style={{
                            padding : '8px',
                            color : SC.CurrentTheme.theme.color_brand_text,
                            fontSize : '14px'
                        }} onClick={this.onClickResetPassword}>                            
                            <div style={{
                                color : SC.CurrentTheme.theme.font,
                                marginBottom : '8px'
                            }}>Your account is created with Email & Password</div>    
                            <div>Reset Password</div>
                        </SC.LinkText>
                        <Styled.Password_Description alc>
                            <SC.Icons.ErrorIcons.info size={24} color={"#AAAAAA"} />
                            <div style={{ marginLeft: 5, marginTop: 5, lineHeight: '16px' }}>Your passwords are not stored in our database but are secure in Google Authentication. </div>
                        </Styled.Password_Description>
                    </SC.FCol>
                    
                )
            }

            content = (
                <SC.FCol style={{
                    width : '400px',
                    padding : '8px'
                }}>
                    <PanelLabel>NAME</PanelLabel>
                    <Input_Name
                        InputType={Input_ProjectName}
                        required
                        value={this.state.name}
                        styleProps = {{
                            placeholder : 'Display Name',
                            style : {
                                fontSize : '16px',
                                lineHeight : '26px'
                            },
                            onBlur : () => {
                                GetDataApi().api.UpdateUserName(this.state.name);
                                // Globals.ProjectManager.SaveName(this.state.name);
                                this.RCUpdate();
                            }
                        }}
                        onChange={(name) => {
                            this.setState({name : name})
                        }}
                        onCancel={this.Cancel}
                        onSubmit={(name) => {
                            // Globals.ProjectManager.SaveName(this.state.name);
                            this.RCUpdate();
                        }}
                        showError
                    />
                    <PanelLabel style={{marginTop : '24px'}}>EMAIL</PanelLabel>
                    <Input_Name
                        InputType={Input_ProjectName}
                        required
                        value={this.state.email}
                        styleProps = {{
                            placeholder : 'Email',
                            readOnly : true,
                            style : {
                                fontSize : '16px',
                                lineHeight : '26px'
                            },
                            onBlur : () => {
                                // Globals.ProjectManager.SaveName(this.state.name);
                                this.RCUpdate();
                            }
                        }}
                        onChange={(email) => {
                            this.setState({email : email})
                        }}
                        onCancel={this.Cancel}
                        onSubmit={(name) => {
                            // Globals.ProjectManager.SaveName(this.state.name);
                            this.RCUpdate();
                        }}
                        showError
                    />
                    <PanelLabel style={{marginTop : '24px', marginBottom : '16px'}}>PROVIDER</PanelLabel>
                    <div style={{
                        display : 'grid',
                        gridTemplateColumns : '1fr auto',
                        gridGap : '16px',
                        alignItems : 'center',
                        marginBottom : '24px'
                    }}>
                        {provider}
                        {
                            canChangeProvider && 
                            <SC.LinkText style={{
                                padding : '8px',
                                color : SC.CurrentTheme.theme.color_brand_text,
                                fontSize : '14px'
                            }} onClick={() => this.setState({showChangeProvider : true, newEmail : this.state.email})}>Change to Email</SC.LinkText>
                        }                        
                    </div>    
                    <Seperator />
                    <SC.LinkText style={{
                        
                    }} onClick={this.onClickDeleteAccount}>
                        Delete account
                    </SC.LinkText>                                          
                </SC.FCol>
            )

        }

        

        return (
            <SC.CustomScrollbars
                hideTracksWhenNotNeeded
                autoHide
            >
                <div style={{
                    // maxWidth : '1440px',
                    width : '100%',
                    display : 'flex',
                    flexDirection : 'column',
                    alignItems : 'sretch',
                    marginLeft : 'auto',
                    marginRight : 'auto',
                    padding : '60px',
                    boxSizing : 'border-box'
                }}>
                    <div style={{
                        display : 'grid',
                        gridTemplateColumns : '1fr auto auto',
                        // gridGap : '16px',
                        alignItems : 'center',
                        marginBottom : '80px'
                    }}>
                        <div  style={{fontSize : '32px', paddingLeft : '4px', fontWeight : 500, letterSpacing : '1.2px', color : SC.CurrentTheme.theme.text_card_title}}>
                            <div>{title}</div>
                        </div>                        
                    </div>
                    {content}
                </div>
            </SC.CustomScrollbars>
        )
    }
}

