import React, { useCallback, useMemo, useState } from "react";
import styled, { css} from 'styled-components';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    AppLayout,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../../../../importer';
import { TextAlignLines } from "../../../../panels/right/iteminspector/styleitems/text";
import TextInput from "../../../../../../components/editors/textinput";
import AddCancelBar from "../../../../../../components/AddCancelBar";
import CompactColorPicker from '../../../../../../components/editors/color/compact';
import { SLATE_ELEMENT_TYPES } from "./editor";
import ColorEditor, {Input_Hex, Input_RGB, FormatRGB} from '../../../../../../components/editors/color';
import RadioGroup from '../../../../../../components/editors/radiogroup';
import { motion } from "framer-motion";
import Switch from "../../../../../../components/editors/Switch";

export const DocumentToolbarBox = ({style, ...rest}) => {
	return (
		<div
			style={{
				border : SC.CurrentTheme.theme.border_popup,
				borderRadius : '2px',
				boxSizing : 'border-box',
				backgroundColor : SC.CurrentTheme.theme.back_lighter,
				boxShadow : SC.CurrentTheme.theme.popup_shadow,  
				...SC.Styles.Font(),
				fontSize : '11px',
				display : 'flex',
				alignItems : 'center',
				...style
			}}
			title=''
			{...rest}
		>
			{rest.children}
		</div>
	)
}

export const DocumentToolbarButton = styled.div`
    display : flex;
    justify-content : center;
    align-items : center;
    cursor : pointer;
    fill : ${props => props.hasFill ? (props.disabled || props.selected || props.fill || (props.active ? props.theme.color_brand_text : (props.light ? props.theme.icon_light : props.theme.icon)) ) : 'none'};
    stroke : ${props => props.hasStroke ? (props.disabled || props.selected || props.fill || props.theme.icon) : 'none'};
	background-color : ${props => (props.active || props.dark) ? props.theme.back : props.theme.back_lighter};
	border : ${props => (props.active || props.dark) ? props.theme.border_onlight_line : '1px solid transparent'};
	color : ${props => props.active ? props.theme.color_brand_text : props.theme.font};
	font-weight : ${props => props.active ? 400 : 500};
    min-width : 24px;
    min-height : 24px;
	border-radius : 2px;
	padding-left : 2px;
	padding-right : 2px;
    &:hover {
        fill : ${props => props.hasFill ? (props.hoverFill || props.theme.icon_hover || '#AEAEAE') : 'none'};
        stroke : ${props => props.hasStroke ? (props.hoverStroke|| props.theme.icon_hover || '#AEAEAE') : 'none'};
        background-color : ${props => props.theme.back_lightest};
    }
`

export const DocumentToolbarSeperator = ({style}) => <div style={{alignSelf : 'stretch', borderLeft : SC.CurrentTheme.theme.border_ondark2, ...style}} />

export class DocumentToolbarColorPicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }

        this.SaveColor = this.SaveColor.bind(this);		
		this.ShowColorPicker = this.ShowColorPicker.bind(this);
    }
    ShowColorPicker(e) {
		// this.props.onShowColorEditor();
		// return;
        this.props.onToolbarEditing && this.props.onToolbarEditing(this.props.id);

		this.props.onStoreColorNode && this.props.onStoreColorNode();
		e && e.preventDefault();
		e && e.stopPropagation();
        if (this.state.showEditor) {
			this.SaveColor();
			return;
		}
            
        this.setState({showEditor : !this.state.showEditor, color : this.props.color || '#fff'})
	}
	onChangingColor(color) {
		this.setState({color : color});
		this.props.onChangeColor(color);
	}
	onChangeColor(color) {
		this.setState({color : color});
		this.props.onChangeColor(color);
	}
	SaveColor(color) {
		this.props.onSaveColor(this.state.color);
		this.setState({showEditor : false, color : null})
	}
	onColorChangeComplete() {
        this.props.onChangeColor(this.state.color);
    }
    render() { 
        return (  
            <React.Fragment>
                <DocumentToolbarSeperator style={{marginLeft : '4px', marginRight : '4px'}} />
				<SC.GridBackground 
                    small 
                    dark={!SC.CurrentTheme.theme.isLight} 
                    style={{
                        // height : '100%', 
                        boxSizing : 'border-box',
                        borderRadius : '2px', 
                        overflow : 'hidden', 
                        cursor : 'pointer',                        
                        border : SC.CurrentTheme.theme.border_seperator,
						borderWidth : '2px',
						minWidth : '28px'
                    }}
                >
                    <div 
                        onMouseDown={this.ShowColorPicker} 
                        style={{backgroundColor : this.state.color || this.props.color || '#fff', minWidth : '28px', minHeight : '20px', height : '100%', borderRadius : '2px'}} 
                    />
                </SC.GridBackground> 
				{
                    (this.state.showEditor || false) &&
                    <CompactColorPicker 
                        onClose={this.SaveColor}
                        color={this.state.color || '#fff'}
                        onChanging={this.onChangingColor.bind(this)}
                        onChange={this.onChangeColor.bind(this)}
						onComplete={this.onColorChangeComplete.bind(this)}
						style={{
							right : 0, left : 'unset', width : '240px', top : '100%'
						}}
                    />
                }
				{
					false &&
					<motion.div
						style={{
							position : 'absolute', 
							top : '100%',
							right : 0,
							left : 0,
							marginTop : '4px',
							padding : '8px',
							backgroundColor : SC.CurrentTheme.theme.dropdown_back_dark,
							border : SC.CurrentTheme.theme.border_popup,
							borderRadius : '2px',
							boxShadow : SC.CurrentTheme.theme.popup_shadow,
							pointerEvents : 'all',
							zIndex : 10000,
							right : 0, left : 'unset', width : '240px', top : '100%',
							...SC.Styles.Flex.Column
						}}
						initial={{y : -8, x : '-50%'}}
						animate={{y : 0}}
						exit={{y : -8, opacity : 0.7}}
						transition={{duration : 0.2}}                           
					>
						<RadioGroup 
							hasBorder
							noBackColor
							autoHeight
							xsmall
							toRight
							fullwidth
							small
							items={[
								{id : 'custom', label : 'Custom'},
								{id : 'token', label : 'Token'},
							]}
							value={this.state.colorType}
							onSelect={this.onChangeColorType}
							style={{padding : '8px'}}
						/>
						<ColorEditor 
							color={this.state.color || '#fff'}
							onChanging={this.onChangingColor.bind(this)}
							onChange={this.onChangeColor.bind(this)}
							onComplete={this.onColorChangeComplete.bind(this)}
						/> 
						<SC.FRow alc>
							<div>HEX</div>
							<Input_Hex 
								ref={this.Ref_Hex}
								id={this.props.id}
								color={this.state.color || '#fff'}
								style={{
									border : SC.CurrentTheme.theme.border_ondark,
									backgroundColor : SC.CurrentTheme.theme.back,
									borderRadius : '2px',
									width : '100%',
									padding : '4px',
									boxSizing : 'border-box',
									textTransform : 'uppercase',
									flex : 1
								}}
								onChange={this.onChangeColor.bind(this)}
							/>
						</SC.FRow>
					</motion.div>
                }
            </React.Fragment>
        );
    }
}


export const RenderDocumentVisibleBar = ({hidden, onChange}) => {
	return (
		<React.Fragment>
			<DocumentToolbarButton active={hidden} onMouseDown={onChange} title='Hide in view mode'>
				<SC.Icons.Icon_Button hasFill hasCursor>
					<SC.Icons.Icon_Visibility visible={!hidden} />
				</SC.Icons.Icon_Button>
			</DocumentToolbarButton>
			<DocumentToolbarSeperator style={{alignSelf : 'stretch', height : 'unset', marginLeft : '4px', marginRight : '4px'}} />
		</React.Fragment>
	)
}

export default class SlateToolbar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {  
		}		
	}
	ToggleText(type, e) {
		e.preventDefault();
		this.props.onToggleText(type);
	}
	ToggleLink(e) {
		e && e.preventDefault();
		e && e.stopPropagation();	

		AppLayout.Designer.SuspendDocumentDesigner = true;
		// this.props.onSaveLink(prompt('Enter Link Url', this.props.isLink));

		// return;

		this.state.showLinkInput = true;
		this.state.link = Utils.UseNullOrEmpty(this.props.isLink, '');
		this.state.openInNewWindow = Utils.IsTrue(this.props.openInNewWindow);
		this.props.onToolbarEditing && this.props.onToolbarEditing(this.props.id);
		this.props.onStoreLinkNode();
		return;
		this.setState({showLinkInput : true, link : Utils.UseNullOrEmpty(this.props.isLink, '')});

		return;
				
	}
	onToggleAlign(align, e) {
		e.preventDefault();
		this.props.onToggleAlign(align);		
	}
	SetLink(link) {
		this.setState({link : link});		
	}
	RemoveLink() {
		this.props.onRemoveLink();
		this.props.onToolbarEditing && this.props.onToolbarEditing(false);
		this.setState({showLinkInput : false, link : null});
		AppLayout.Designer.SuspendDocumentDesigner = false;		
	}
	SaveLink() {
		this.props.onSaveLink(this.state.link, this.state.openInNewWindow);
		this.props.onToolbarEditing && this.props.onToolbarEditing(false);
		this.setState({showLinkInput : false, link : null});
		AppLayout.Designer.SuspendDocumentDesigner = false;
	}
	componentWillUnmount() {
		this.props.onToolbarEditing && this.props.onToolbarEditing(false);
	}
	
	render() { 		
		let linkBar;	
		if (this.state.showLinkInput) {
			linkBar = (
				<SC.AbsoluteOverlay style={{display : 'flex', alignItems : 'center', paddingLeft : '8px', paddingRight : '4px', zIndex : 1, backgroundColor : SC.CurrentTheme.theme.back_lighter}}>
					<div>Link</div>
					<TextInput 
						themeId={this.props.themeId}
						placeholder='Enter Url'
						dark
						autoFocus
						value={this.state.link}
						onChanging={this.SetLink.bind(this)}
						onChange={this.SetLink.bind(this)}
						style={{
							flex : 1,
							marginLeft : '8px',
							marginRight : '4px',
							height : '30px',
						}}
						onSubmit={this.SaveLink.bind(this)}
                        onCancel={this.RemoveLink.bind(this)}
					/>
					<div style={{marginLeft : '8px', marginRight : '4px'}}>Open in New Window</div>
                    <Switch round value={this.state.openInNewWindow} onChange={(isChecked) => {
						this.setState({openInNewWindow : isChecked}, () => {
							this.props.onSaveLink(this.state.link, this.state.openInNewWindow);
						});						
					}} />
					<AddCancelBar 
						onCancel={this.RemoveLink.bind(this)}
						onAdd={this.SaveLink.bind(this)}
					/>
				</SC.AbsoluteOverlay>
			)
		}
		const columnCount = this.props.fixedType ? 13 : 22;
		return (  
			<div 
				style={{
					padding : '4px',
					display : 'grid',
					gridTemplateColumns : 'repeat(' + columnCount + ', auto)',
					gridGap : '4px',
					alignContent : 'start',
					justifyContent : 'start',
					position : 'relative',
					minWidth : this.props.fixedType ? 'unset' : '630px',
					...SC.Styles.Font()
				}}
				onMouseDown={(e) => {
					e.stopPropagation(); 
					e.preventDefault(); 
				}}
			>
				<RenderDocumentVisibleBar hidden={this.props.hidden} onChange={this.props.onChangeHidden} />
				{
					!this.props.fixedType && 
					<React.Fragment>
						<DocumentToolbarButton active={this.props.d1} style={{paddingLeft : '4px', paddingRight : '4px'}} onMouseDown={this.ToggleText.bind(this, SLATE_ELEMENT_TYPES.d1.type)}>Title</DocumentToolbarButton>
						<DocumentToolbarButton active={this.props.h1} onMouseDown={this.ToggleText.bind(this, SLATE_ELEMENT_TYPES.h1.type)}>H1</DocumentToolbarButton>
						<DocumentToolbarButton active={this.props.h2} onMouseDown={this.ToggleText.bind(this, SLATE_ELEMENT_TYPES.h2.type)}>H2</DocumentToolbarButton>
						<DocumentToolbarButton active={this.props.h3} onMouseDown={this.ToggleText.bind(this, SLATE_ELEMENT_TYPES.h3.type)}>H3</DocumentToolbarButton>
						<DocumentToolbarButton active={this.props.paragraph} style={{paddingLeft : '4px', paddingRight : '4px'}} onMouseDown={this.ToggleText.bind(this, SLATE_ELEMENT_TYPES.paragraph.type)}>
							<SC.TextDivAbbr>Paragraph</SC.TextDivAbbr>
						</DocumentToolbarButton>
						<DocumentToolbarButton hasFill active={this.props.isCode} onMouseDown={this.ToggleText.bind(this, 'code')}>
							<SC.Icons.Icon_Code />
						</DocumentToolbarButton>
						<DocumentToolbarButton hasFill active={this.props.isBulletList} onMouseDown={this.ToggleText.bind(this, 'bulleted-list')}>
							<SC.Icons.Icon_TextList_Bullet />
						</DocumentToolbarButton>
						<DocumentToolbarButton hasFill active={this.props.isNumberList} onMouseDown={this.ToggleText.bind(this, 'numbered-list')}>
							<SC.Icons.Icon_TextList_Number />
						</DocumentToolbarButton>
						<DocumentToolbarSeperator />
					</React.Fragment>
				}
				<DocumentToolbarButton active={this.props.bold} onMouseDown={this.props.onToggleBold}>B</DocumentToolbarButton>
				<DocumentToolbarButton style={{fontStyle : 'italic'}} active={this.props.italic} onMouseDown={this.props.onToggleItalic}>I</DocumentToolbarButton>
				<DocumentToolbarButton style={{textDecoration : 'underline'}} active={this.props.underline} onMouseDown={this.props.onToggleUnderline}>U</DocumentToolbarButton>
				<DocumentToolbarSeperator />
				<DocumentToolbarButton active={this.props.alignLeft} onMouseDown={this.onToggleAlign.bind(this, 'left')}>
					<TextAlignLines selected={this.props.alignLeft} align='flex-start' />
				</DocumentToolbarButton>
				<DocumentToolbarButton active={this.props.alignCenter} onMouseDown={this.onToggleAlign.bind(this, 'center')}>
					<TextAlignLines selected={this.props.alignCenter} align='center' />
				</DocumentToolbarButton>
				<DocumentToolbarButton active={this.props.alignRight} onMouseDown={this.onToggleAlign.bind(this, 'right')}>
					<TextAlignLines selected={this.props.alignRight} align='flex-end' />
				</DocumentToolbarButton>
				<DocumentToolbarColorPicker 
					onShowColorEditor={this.props.onShowColorEditor}
					onChangeColor={this.props.onChangeColor}
					onSaveColor={this.props.onSaveColor}
					color={this.props.color}
					onStoreColorNode={this.props.onStoreColorNode}
					onToolbarEditing={this.props.onToolbarEditing}
					id={this.props.id}
				/>
				
				<DocumentToolbarSeperator />
				<DocumentToolbarButton hasFill active={this.props.isLink} onMouseDown={this.ToggleLink.bind(this)}>
					{this.props.isLink ? <SC.Icons.Icon_Unlink /> : <SC.Icons.Icon_Link />}
				</DocumentToolbarButton>
				{linkBar}
			</div>
		);
	}
}
