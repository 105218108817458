import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    UIUtils,
    Globals,
    AppLayout
} from '../../../../../../importer';

import styled from 'styled-components';
import {TokenGroup, TokenItemBox, TokenPanelHeader, GroupTitle, TokenItemDragHandle, TokenItemBoxHoveredName} from '../common';
import { ListCardView } from '../../../../../../components/light_dark_bar';
import { motion } from 'framer-motion';
import { SortableList, SortableListItem } from '../../../../../../components/SortableContainer';
import { LeftScrollPanel } from '../../common';
import BorderEditor from './border';
import BorderRadiusEditor from './radius';
import { StatefulTokenListItemName, StatefulTokenMarker, TokenLockedMarker } from '../colors';
import { GRANT_TYPES } from '../../../../manager';

export default class Borders extends ReactBaseComponent
{
    constructor(props) {
        super(props);
        
        this.PanelMode = this.props.isSelectList ? 'RightPanel' : 'LeftPanel';

        this.state.isListViewBorders = Globals.ProjectManager.Options.Get(true, this.PanelMode, 'DesignSystem', 'Borders', 'ListView');        
        this.state.isListViewRadius = Globals.ProjectManager.Options.Get(true, this.PanelMode, 'DesignSystem', 'Radius', 'ListView');
        this.onToggleExpand = this.onToggleExpand.bind(this);
        this.expanded = Globals.ProjectManager.Options.Get(false, this.PanelMode, 'DesignSystem', 'Borders', 'Expanded');
        this.onSortStyle = this.onSortStyle.bind(this);

        this.EditBorderRadius = this.EditBorderRadius.bind(this);        
        this.EditBorder = this.EditBorder.bind(this);
        this.AddBorder = this.AddBorder.bind(this);
        this.AddBorderRadius = this.AddBorderRadius.bind(this);
        this.onDeleteBorder = this.onDeleteBorder.bind(this);
        this.onDeleteBorderRadius = this.onDeleteBorderRadius.bind(this);
        this.onCancelAddPattern = this.onCancelAddPattern.bind(this);
        this.onSubmitNewBorder = this.onSubmitNewBorder.bind(this);
        this.onSubmitNewBorderRadius = this.onSubmitNewBorderRadius.bind(this);

        this.RemoveStateOverride = this.RemoveStateOverride.bind(this);        
        this.MoveValueToParentState = this.MoveValueToParentState.bind(this);
        this.SwitchStateToTokensOverriden = this.SwitchStateToTokensOverriden.bind(this);
        this.ApplyValueToCurrentState = this.ApplyValueToCurrentState.bind(this);        

        AppLayout.Refs.DesignSystem.Borders = this;

        this.HasEditGrant = Globals.ProjectManager.CheckGrant(GRANT_TYPES.EDIT_TOKEN.ALL);

        if (this.expanded || this.props.singleView) {
            this.expanded = true;
            this.Load(this.props);
        } 
    }
    Load(props) {
        this.BorderModels = GetBorderStyleTokenList();
        const Radiuses = GetBorderRadiusTokenList();
        this.RadiusModels = Radiuses.items;
        this.RadiusItemSize = Radiuses.maxRadiusSize;
    }
    Reload() {
        this.Load();
        this.RCUpdate();
    }
    SearchToken(filter) {
        let filterText;
        if (Utils.IsNotNullOrEmpty(filter))
            filterText = filter.toUpperCase();
        Utils.ForEach(this.BorderModels, (token, i) => {
            delete token.filtered;
            if (filterText) {
                if (token.model.name.toUpperCase().indexOf(filterText) < 0) {                    
                    token.filtered = true;
                }                
            }
        });
        Utils.ForEach(this.RadiusModels, (token, i) => {
            delete token.filtered;
            if (filterText) {
                if (token.model.name.toUpperCase().indexOf(filterText) < 0) {                    
                    token.filtered = true;
                }                
            }
        });
        this.RenderId = Utils.Id();
        this.RCThrottledUpdate_1();
    }  
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.filterText !== nextProps.filterText) {
            setTimeout(() => {
                this.SearchToken(nextProps.filterText);
            }, 1);
            return false;
        }
        if (this.props.showSearchBar !== nextProps.showSearchBar)
            return true;
        if (this.props.GlobalState !== nextProps.GlobalState || this.props.GlobalStateId !== nextProps.GlobalStateId) {
            this.spacingId = Utils.Id();
            this.Load(nextProps);
            return true;
        }
        if (Utils.HasAnyChange(this.state, nextState, 'isListViewBorders', 'isListViewRadius', 'ShouldUpdate'))
            return true;
        if (this.props.themeId !== nextProps.themeId)
            return true;
        if (this.props.singleView !== nextProps.singleView) {
            if (nextProps.singleView && !this.expanded) {
                this.expanded = true;
                this.Load(nextProps);
            }            
            return true;
        }
        return false;
    }    
    onToggleExpand() {
        this.expanded = !this.expanded;
        Globals.ProjectManager.Options.Set(this.expanded, this.PanelMode, 'DesignSystem', 'Borders', 'Expanded');
        if (this.expanded)
            this.Load(this.props);
        this.RCUpdate();
    }
    onSortStyle(isRadius, oldIndex, newIndex) {
        if (isRadius) {
            Globals.ProjectManager.Tokens.ChangeOrder(Globals.ProjectManager.Tokens.Types.BorderRadiuses, oldIndex, newIndex);
            Utils.ChangePlace(this.RadiusModels, oldIndex, newIndex);
        }
        else {
            Globals.ProjectManager.Tokens.ChangeOrder(Globals.ProjectManager.Tokens.Types.Borders, oldIndex, newIndex);
            Utils.ChangePlace(this.BorderModels, oldIndex, newIndex);
        }        
        this.RCUpdate();
    }
    AddBorder() {
        if (!this.HasEditGrant) {
            return;
        }

        this.IsNew = true;
        this.EditPatternModel = {
            id : Utils.Id(),
            model : {                                
                name : 'New Border'
            }
        };
        this.EditBorder(this.EditPatternModel);
    }
    AddBorderRadius() {
        if (!this.HasEditGrant) {
            return;
        }

        this.IsNew = true;
        this.EditPatternModel = {
            id : Utils.Id(),
            model : {                                
                name : 'New Border Radius'
            }
        };
        this.EditBorderRadius(this.EditPatternModel);
    }
    onEditBorderRadius(model, e) {        
        if (this.props.onSelectRadius && !this.IsNew) {
            this.props.onSelectRadius(model, e);
            return;
        }
        this.EditBorderRadius(model);
    }
    onShowMenuRadius(model, e) {
        this.props.onShowMenuRadius && this.props.onShowMenuRadius(model, e);
    }
    RemoveStateOverride() {
        if (this.ValueState.ValueState && !this.ValueState.ValueState.inherited) {
            Globals.ProjectManager.Tokens.DeleteValue({
                id : this.EditPatternId,
                state : this.ValueState.ValueState.state
            })            
            delete this.ValueState.ValueState;
            Globals.ProjectManager.Tokens.GetStateValue({Id : this.EditPatternId, info: this.ValueState});
            this.borderLoadId = Utils.Id();
            Events.BCE(Events.GLOBAL.TOKENS_CHANGED);
        }        
    }
    MoveValueToParentState() {
        if (this.ValueState.ValueState) {
            Globals.ProjectManager.Tokens.MoveValueToState({
                id : this.EditPatternId,
                fromState : this.ValueState.ValueState.state
            });
            delete this.ValueState.ValueState;
            Globals.ProjectManager.Tokens.GetStateValue({Id : this.EditPatternId, info: this.ValueState});    
            this.borderLoadId = Utils.Id();
            this.props.onUpdate();
        }
    }
    ApplyValueToCurrentState() {
        if (this.ValueState.ValueState) {
            Globals.ProjectManager.Tokens.MoveValueToState({
                id : this.EditPatternId,
                fromState : this.ValueState.ValueState.state,
                toState : Globals.ProjectManager.CurrentState
            });
            delete this.ValueState.ValueState;
            Globals.ProjectManager.Tokens.GetStateValue({Id : this.EditPatternId, info: this.ValueState});    
            this.borderLoadId = Utils.Id();
            this.props.onUpdate();
        }
    }
    SwitchStateToTokensOverriden() {
        const ToState = this.ValueState.ValueState.state;
        delete this.ValueState.ValueState;
        Globals.ProjectManager.States.SetStateFromLabel(ToState);
    }
    EditBorderRadius(model) {
        if (!this.HasEditGrant) {
            return;
        }
        this.ValueState = {
            onRemoveOverride : this.RemoveStateOverride,
            onMoveToParent : this.MoveValueToParentState,
            onApplyToCurrentState : this.ApplyValueToCurrentState,
            onSwitchToOverridenState : this.SwitchStateToTokensOverriden
        };        
        this.EditPatternId = model.id;
        this.EditPatternModel = model.model;
        
        Globals.ProjectManager.Tokens.GetStateValue({Id : this.EditPatternId, info: this.ValueState});

        if (Globals.ProjectManager.AuditManager) {
            this.WillEditToken = Utils.DeepClone(Globals.ProjectManager.Tokens.Token(this.EditPatternId));
            this.WillEditTokenId = this.EditPatternId;
        }

        this.props.onPanelOverlay({
            show : true,
            render : (props) => {
                if (props.GlobalState !== this.props.GlobalState) {
                    if (this.ValueState)
                        delete this.ValueState.ValueState;
                    Globals.ProjectManager.Tokens.GetStateValue({Id : this.EditPatternId, info: this.ValueState});
                }
                return (
                    <SC.FCol fw fh>
                        <TokenPanelHeader title='EDIT BORDER RADIUS' 
                            hasAddCancel 
                            notBackClosable={this.IsNew}
                            onClose={this.onCancelAddPattern} 
                            onCancel={this.onCancelAddPattern} 
                            onAdd={this.onSubmitNewBorderRadius} 
                            onDelete={!this.IsNew && this.onDeleteBorderRadius}
                            stateValue={this.ValueState}
                            onLight
                        />
                        <LeftScrollPanel>
                            <BorderRadiusEditor 
                                id={this.EditPatternId}
                                renderId={this.borderLoadId}
                                newModel={this.IsNew ? this.EditPatternModel : null}
                                GlobalState={props.GlobalState}
                                GlobalStateId={props.GlobalStateId}
                                onClose={this.onCancelAddPattern} 
                                onChanged={() => {
                                    const ValueState = Utils.Get(this.ValueState, {
                                        state : Globals.ProjectManager.CurrentState
                                    }, 'ValueState');
                                    ValueState.inherited = false;
                                    this.props.onUpdate();
                                }}
                                onClosed={() => {
                                    const tokencurrent = Globals.ProjectManager.Tokens.Token(this.WillEditTokenId);
                                    
                                    if (this.WillEditToken && tokencurrent) { 
                                        const diff = Utils.GetFlattenedDiff(this.WillEditToken, tokencurrent);
                                        if (diff.length > 0) {
                                            Globals.ProjectManager.AuditManager && Globals.ProjectManager.AuditManager.TokenChanged({TokenId : this.EditPatternId, Change : {
                                                changes : diff
                                            }});
                                        }                                                                        
                                    }
                                    delete this.WillEditToken;
                                    delete this.WillEditTokenId;
                                }}
                            />
                        </LeftScrollPanel>                        
                    </SC.FCol>                    
                )
            }
        }) 
    }
    onEditBorder(model, e) {
        if (this.props.onSelectBorder && !this.IsNew) {
            this.props.onSelectBorder(model, e);
            return;
        }
        this.EditBorder(model);
    }
    onShowMenuBorder(model, e) {
        this.props.onShowMenuBorder && this.props.onShowMenuBorder(model, e);
    }
    EditBorder(model) {
        if (!this.HasEditGrant) {
            return;
        }
        this.ValueState = {
            onRemoveOverride : this.RemoveStateOverride,
            onMoveToParent : this.MoveValueToParentState,
            onApplyToCurrentState : this.ApplyValueToCurrentState,
            onSwitchToOverridenState : this.SwitchStateToTokensOverriden
        };    
        this.EditPatternId = model.id;
        this.EditPatternModel = model.model;
        
        Globals.ProjectManager.Tokens.GetStateValue({Id : this.EditPatternId, info: this.ValueState});

        if (Globals.ProjectManager.AuditManager) {
            this.WillEditToken = Utils.DeepClone(Globals.ProjectManager.Tokens.Token(this.EditPatternId));
            this.WillEditTokenId = this.EditPatternId;
        }

        this.props.onPanelOverlay({
            show : true,
            render : (props) => {
                if (props.GlobalState !== this.props.GlobalState) {
                    if (this.ValueState)
                        delete this.ValueState.ValueState;
                    Globals.ProjectManager.Tokens.GetStateValue({Id : this.EditPatternId, info: this.ValueState});
                }
                return (
                    <SC.FCol fw fh>
                        <TokenPanelHeader title='EDIT BORDER' 
                            hasAddCancel 
                            notBackClosable={this.IsNew}
                            onClose={this.onCancelAddPattern} 
                            onCancel={this.onCancelAddPattern} 
                            onAdd={this.onSubmitNewBorder} 
                            onDelete={!this.IsNew && this.onDeleteBorder}
                            stateValue={this.ValueState}
                            onLight
                        />
                        <LeftScrollPanel>
                            <BorderEditor 
                                id={this.EditPatternId}
                                renderId={this.borderLoadId}
                                newModel={this.IsNew ? this.EditPatternModel : null}
                                GlobalState={props.GlobalState}
                                GlobalStateId={props.GlobalStateId}
                                onClose={this.onCancelAddPattern} 
                                RefToolbar={this.props.RefToolbar}
                                onChanged={() => {
                                    const ValueState = Utils.Get(this.ValueState, {
                                        state : Globals.ProjectManager.CurrentState
                                    }, 'ValueState');
                                    ValueState.inherited = false;
                                    this.props.onUpdate();
                                }}
                                onClosed={() => {
                                    const tokencurrent = Globals.ProjectManager.Tokens.Token(this.WillEditTokenId);
                                    
                                    if (this.WillEditToken && tokencurrent) { 
                                        const diff = Utils.GetFlattenedDiff(this.WillEditToken, tokencurrent);
                                        if (diff.length > 0) {
                                            Globals.ProjectManager.AuditManager && Globals.ProjectManager.AuditManager.TokenChanged({TokenId : this.EditPatternId, Change : {
                                                changes : diff
                                            }});
                                        }                                                                        
                                    }
                                    delete this.WillEditToken;
                                    delete this.WillEditTokenId;
                                }}
                            />
                        </LeftScrollPanel>                        
                    </SC.FCol>                    
                )
            }
        })   
    }
    onCancelAddPattern() {
        delete this.IsNew;
        delete this.EditPatternModel;
        delete this.EditPatternId;

        this.props.onPanelOverlay({close : true});
        this.Load(this.props);
        this.RCUpdate();
    }
    onSubmitNewBorder() {
        if (this.IsNew) {
            Globals.ProjectManager.Tokens.Add({
                type : Globals.ProjectManager.Tokens.Types.Borders,
                name : this.EditPatternModel.name,
                value : this.EditPatternModel.value,
                id : this.EditPatternId
            });
            Events.BCE(Events.GLOBAL.TOKENS_CHANGED);
        }        
        else {
            // Globals.ProjectManager.Tokens.SetValue({id : this.EditPatternId, value : this.EditPatternModel.value});
        }
        this.Load();
        this.onCancelAddPattern();
    }
    onDeleteBorder() {
        if (!Globals.ProjectManager.CheckGrant(GRANT_TYPES.DELETE_TOKEN.ALL)) {
            return;
        }

        const result = Globals.ProjectManager.Tokens.Delete(Globals.ProjectManager.Tokens.Types.Borders, this.EditPatternId);
        if (result) {            
            this.Load();
            this.onCancelAddPattern();
        }
    }
    onSubmitNewBorderRadius() {
        if (this.IsNew) {
            Globals.ProjectManager.Tokens.Add({
                type : Globals.ProjectManager.Tokens.Types.BorderRadiuses,
                name : this.EditPatternModel.name,
                value : this.EditPatternModel.value,
                id : this.EditPatternId
            });
            if (this.props.onSelectRadius)
                this.props.onSelectRadius(this.EditPatternId);
            Events.BCE(Events.GLOBAL.TOKENS_CHANGED);
        }        
        else {
            // Globals.ProjectManager.Tokens.SetValue({id : this.EditPatternId, value : this.EditPatternModel.value});
        }
        this.Load();
        this.onCancelAddPattern();
    }
    onDeleteBorderRadius() {
        if (!Globals.ProjectManager.CheckGrant(GRANT_TYPES.DELETE_TOKEN.ALL)) {
            return;
        }

        const result = Globals.ProjectManager.Tokens.Delete(Globals.ProjectManager.Tokens.Types.BorderRadiuses, this.EditPatternId);
        if (result) {            
            this.Load();
            this.onCancelAddPattern();
        }
    }
    SetListModeBorder(isListView) {        
        Globals.ProjectManager.Options.Set(isListView, this.PanelMode, 'DesignSystem', 'Borders', 'ListView');
        this.setState({
            isListViewBorders : isListView
        })
    }
    SetListModeRadius(isListView) {        
        Globals.ProjectManager.Options.Set(isListView, this.PanelMode, 'DesignSystem', 'Radius', 'ListView');
        this.setState({
            isListViewRadius : isListView
        })
    }
    renderCustom() {

        let content;

        if (this.expanded) {                        
            content = (
                <SC.FCol fw fh>       
                    {this.props.infoMessage}             
                    <GroupTitle 
                        title='Styles' 
                        subGroup
                        hasAdd={this.HasEditGrant ? {onAdd : this.AddBorder} : null} 
                        style={{paddingLeft : '12px', marginTop : '8px'}}
                        addOn={(
                            <ListCardView 
                                onListView={this.SetListModeBorder.bind(this, true)}
                                onCardView={this.SetListModeBorder.bind(this, false)}
                                isListView={this.state.isListViewBorders}
                                isCardView={!this.state.isListViewBorders}
                                compact
                            />
                        )}
                    />
                    {
                        this.BorderModels.length > 0 && 
                        <SortableList
                            style={{padding : '8px', paddingTop : '6px', fontSize : '11px', paddingBottom : 0}}
                            onSort={this.onSortStyle.bind(this, false)}
                        >
                            {
                                this.BorderModels.map((border, i) => {
                                    if (border.filtered)
                                        return null;
                                    return (
                                        <SortableListItem
                                            key={border.id}
                                            draggableId={border.id}
                                            index={i}
                                            ItemBoxType={BorderStyleTokenItem}
                                            isDragDisabled={!this.HasEditGrant}
                                            BoxProps={{
                                                border : border,
                                                isListView : this.state.isListViewBorders,
                                                onSelect : this.onEditBorder.bind(this, border),
                                                onMenu : this.onShowMenuBorder.bind(this, border)
                                            }}                
                                        />                                       
                                    )
                                })
                            }
                        </SortableList>
                    }                    
                    <GroupTitle 
                        title='Corner Radii' 
                        subGroup
                        hasAdd={this.HasEditGrant ? {onAdd : this.AddBorderRadius} : null} 
                        style={{paddingLeft : '12px', marginTop : '16px'}}
                        addOn={(
                            <ListCardView 
                                onListView={this.SetListModeRadius.bind(this, true)}
                                onCardView={this.SetListModeRadius.bind(this, false)}
                                isListView={this.state.isListViewRadius}
                                isCardView={!this.state.isListViewRadius}                                
                                compact
                            />
                        )}
                    />
                    {
                        this.RadiusModels.length > 0 && 
                        <SC.FCol style={{padding : '8px', paddingTop : '6px', fontSize : '11px', paddingBottom : '4px'}}>
                            {
                                this.RadiusModels.map((radius, i) => {
                                    if (radius.filtered)
                                        return null;
                                    return (
                                        <BorderRadiusTokenItem
                                            key={radius.id}
                                            radius={radius}
                                            isListView={this.state.isListViewRadius}
                                            size={this.RadiusItemSize}
                                            onSelect={this.onEditBorderRadius.bind(this, radius)}
                                            onMenu={this.onShowMenuRadius.bind(this, radius)}
                                        />                                       
                                    )
                                })
                            }
                        </SC.FCol>                   
                    }
                </SC.FCol>
            )
            
        }        

        return (
            <React.Fragment>
                {this.props.children}
                <TokenGroup 
                    title='BORDERS & RADII' 
                    hideTitle={this.props.singleView}
                    last={this.props.last}
                    expandable={this.props.singleView ? null : {expanded : this.expanded,  onExpand : this.onToggleExpand}}
                    style={{height : '100%', width : '100%'}}
                    emptyHeader={this.props.singleView}
                >
                    {
                        this.expanded && 
                        <motion.div
                            initial={{opacity : 0.5, y : -4}}
                            animate={{opacity : 1, y : 0}}
                            transition={{duration  :0.2}}
                            style={{height : '100%', width : '100%', paddingLeft : '2px', paddingRight : '2px', boxSizing : 'border-box'}}                            
                        >
                            {
                                this.props.singleView && !this.props.noScrolls ? 
                                <LeftScrollPanel>                                    
                                    {content}
                                </LeftScrollPanel> : content
                            }
                        </motion.div>
                    }                
                </TokenGroup>
            </React.Fragment>                
        )
    }
}

export const GetBorderStyleTokenList = (statearray) => {
    const borderids = Globals.ProjectManager.Tokens.Borders();
    return GetBorderStyleTokenListOfIds(borderids, statearray);
}
export const GetBorderStyleTokenListOfIds = (borderids, statearray) => {    
    const borders = Globals.ProjectManager.Tokens.TokenList(Globals.ProjectManager.Tokens.Types.Borders);
    const borderItems = [];

    Utils.ForEach(borderids, (id, i) => {
        const token = borders[id];
        if (token) {            
            borderItems.push(GetBorderStyleTokenValue(token, id, statearray));
        }
    }); 

    return borderItems;
}

export const GetBorderStyleTokenValue = (token, id, statearray, contextState) => {
    const info = {
        contextState : contextState
    };
    const VariableValue = Globals.ProjectManager.Tokens.ValueOf({model : token, statearray : statearray, info : info}) || {};
    let value = Utils.UseNull(VariableValue.value, 0);
    let borderColor = SC.CurrentTheme.theme.color_brand;
    if (VariableValue.colorId) {
        borderColor = Globals.ProjectManager.Tokens.GetStateValue({Id : VariableValue.colorId, StateArray : statearray});
    }
    return {
        model : token,
        id : id,
        name : token.name,
        width : Utils.px(value, VariableValue.Unit),
        style : VariableValue.style,
        colorId : VariableValue.colorId,        
        color : borderColor,
        valueState : info.ValueState,
        locked : token.locked
    };
}

export const GetBorderRadiusTokenList = (statearray) => {
    const borderRadiusIds = Globals.ProjectManager.Tokens.BorderRadiuses();
    return GetBorderRadiusTokenListOfIds(borderRadiusIds, statearray);
}
export const GetUnsortedBorderRadiusTokenList = (statearray) => {
    const borderRadiusIds = Globals.ProjectManager.Tokens.BorderRadiuses();
    return GetUnsortedBorderRadiusTokenListOfIds(borderRadiusIds, statearray);
}
export const GetUnsortedBorderRadiusTokenListOfIds = (borderRadiusIds, statearray) => {    
    const borderRadiuses = Globals.ProjectManager.Tokens.TokenList(Globals.ProjectManager.Tokens.Types.BorderRadiuses);
    const items = [];;
    Utils.ForEach(borderRadiusIds, (id, i) => {
        const token = borderRadiuses[id];
        if (token) {            
            const tokenValue = GetBorderRadiusTokenValue(token, id, statearray);            
            items.push(tokenValue);
        }
    });

    return items;
}
export const GetBorderRadiusTokenListOfIds = (borderRadiusIds, statearray) => {    
    const borderRadiuses = Globals.ProjectManager.Tokens.TokenList(Globals.ProjectManager.Tokens.Types.BorderRadiuses);
    
    const result = {
        items : [],
        maxRadiusSize : 0
    }
    let maxRadius = 0;
    Utils.ForEach(borderRadiusIds, (id, i) => {
        const token = borderRadiuses[id];
        if (token) {            
            const tokenValue = GetBorderRadiusTokenValue(token, id, statearray);
            if (tokenValue.unit !== '%' && tokenValue.value > maxRadius && tokenValue.value < 40)
                maxRadius = tokenValue.value;
            result.items.push(tokenValue);
        }
    });

    result.maxRadiusSize = Utils.px(Math.min(Math.max((maxRadius * 2) + maxRadius / 2, 48), 80));
    result.items = Utils.Sort(result.items, (item) => {return item.value});

    return result;
}

export const GetBorderRadiusTokenValue = (token, id, statearray, contextState) => {
    const info = {
        contextState: contextState
    };
    const VariableValue = Globals.ProjectManager.Tokens.ValueOf({model : token, statearray : statearray, info : info}) || {};
    let value = Utils.ToNumber(Utils.UseNull(VariableValue.value, 0));
    return {
        model : token,
        name : token.name,
        id : id,
        value : value,
        unit : VariableValue.Unit,
        style : Utils.px(value, VariableValue.Unit),
        valueState : info.ValueState,
        locked : token.locked
    };
}
export const BorderStyleTokenItem = ({border, name, onSelect, onMenu, isListView, onPreview, selected, sortableProps, style, ...rest}) => {
    const style_box = {...SC.Styles.Flex.RowAlcJsb, alignItems : 'center', ...style};
    if (selected) {
        style_box.borderLeft = '1px solid';
        style_box.borderLeftColor = SC.CurrentTheme.theme.color_brand;
    }
    
    if (!border) {
        return null;    
    }
    const style_value = (
        <SC.TextSpanAbbr style={{fontWeight : 'bold',...SC.Styles.FontStyles.Monospace}}>
            {border.width} {border.style}
        </SC.TextSpanAbbr> 
    );

    if (border.style === 'none' || isListView) {
        return (
            <TokenItemBox id={border.id} onClick={onSelect} onContextMenu={onMenu} {...onPreview} {...rest} selected={selected} style={style_box}>
                <StatefulTokenListItemName 
                    name={name || border.model.name}
                    sortableProps={sortableProps}
                    ValueState={border.valueState}
                    locked={border.locked}
                />               
                {border.style === 'none' ? <div>NONE</div> : style_value}
                <div style={{
                    borderTopStyle : border.style,
                    borderTopWidth : border.width,
                    borderTopColor : border.color,
                    flex : 1,
                    transition : 'all 0.3s ease',
                    maxWidth : '48px',
                    marginLeft : '8px'
                }} />  
            </TokenItemBox>    
        )
    }
    
    return (
        <TokenItemBox id={border.id} onClick={onSelect} onContextMenu={onMenu} {...onPreview} {...rest} selected={selected} style={style_box}>                            
            {
                sortableProps && 
                <TokenItemDragHandle {...sortableProps.handleProps} />
            }
            <TokenItemBoxHoveredName style={sortableProps ? {flex : 1} : {transform : 'translateX(0)', flex : 1}}>
                <SC.FCol style={{minHeight : '32px', marginRight : '8px'}}>
                    <SC.FRow alc style={{marginBottom : '4px'}}>
                        {border.valueState && <StatefulTokenMarker {...border.valueState} />}
                        {border.locked && <TokenLockedMarker /> }
                        <SC.TextDivAbbr style={{flex : 1}}>
                            {name || border.model.name}{name}
                        </SC.TextDivAbbr>
                    </SC.FRow>                    
                    {style_value}    
                </SC.FCol>                    
            </TokenItemBoxHoveredName>
            <div style={{
                borderStyle : border.style,
                borderWidth : border.width,
                borderColor : border.color,
                height : '32px',
                width : '48px',
                transition : 'all 0.3s ease',
                boxSizing : 'border-box'
            }} />                                
        </TokenItemBox>
    )
}

export const BorderRadiusTokenItem = ({radius, name, onSelect, onMenu, isListView, size, sortableProps,  onPreview, selected, ...rest}) => {
    const style_box = {...SC.Styles.Flex.RowAlcJsb, alignItems : 'flex-start'};
    if (selected) {
        style_box.borderLeft = '1px solid';
        style_box.borderLeftColor = SC.CurrentTheme.theme.color_brand;
    }
    return (
        <TokenItemBox id={radius.id} onClick={onSelect} onContextMenu={onMenu} {...onPreview} {...rest} selected={selected} style={style_box}>            
            <SC.FCol f1 style={{alignSelf : 'stretch', overflow : 'hidden', marginRight : '8px', justifyContent : 'space-around'}}>
                <SC.FRow alc>
                    <StatefulTokenListItemName 
                        name={name || radius.model.name}
                        sortableProps={sortableProps}
                        ValueState={radius.valueState}
                        locked={radius.locked}
                    /> 
                </SC.FRow>                
                {
                    !isListView && 
                    <SC.TextSpanAbbr style={{fontWeight : 'bold',...SC.Styles.FontStyles.Monospace}}>
                        {radius.style}
                    </SC.TextSpanAbbr> 
                }                    
            </SC.FCol>
            <SC.FRow alc justifyEnd>
                {!isListView && 
                <div style={{
                    border : '1px solid',
                    borderColor : SC.CurrentTheme.theme.color_brand,
                    borderRadius : radius.style,
                    boxSizing : 'border-box',
                    height : size,
                    width : size
                }} />}
                {isListView && <div style={{width : '40px', textAlign : 'right', fontWeight : 500, marginRight : '2px', fontWeight : 'bold',...SC.Styles.FontStyles.Monospace}}>
                    {radius.style}
                </div>    }
            </SC.FRow>                                      
        </TokenItemBox>
    )
}