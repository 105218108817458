import Utils from '../toolabs-modules/toolabs-utils';
import UIUtils from '../toolabs-modules/toolabs-ui-utils';
import MetaData from '../toolabs-modules/toolabs-metaui';
import Publish from '../toolabs-modules/toolabs-publish';
import FontLoader from '../toolabs-modules/toolabs-font-manager';
import Packager from '../toolabs-modules/toolabs-packager';

import EngineDataLoader from '../toolabs-modules/toolabs-render-base'
import {Component as Toolabs_Component, Prototype as Toolabs_Prototype} from '../toolabs-modules/engine-react/index';

// import {Utils} from '@applinist/test-toolabs-modules';
// import {MetaData} from '@applinist/test-toolabs-modules';
// import {Publish} from '@applinist/test-toolabs-modules';
// import {EngineReact} from '@applinist/test-toolabs-modules';
const ToolabsEngine = Toolabs_Component;
const ToolabsPrototype = Toolabs_Prototype;

const Toolabs_Engine = {
    Component : Toolabs_Component
}

export {Utils};
export {UIUtils};
export {MetaData};
export {Publish};
export {ToolabsEngine};
export {ToolabsPrototype};
export {EngineDataLoader}
export {FontLoader};
export {Packager}
export {Toolabs_Engine};

export default {
    Utils,
    UIUtils,
    MetaData,
    Publish,
    ToolabsEngine,
    ToolabsPrototype,
    EngineDataLoader,
    FontLoader,
    Packager,
    Toolabs_Engine
}
