import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    UIUtils,
    Globals
} from '../../../../../../importer';

import { GroupTitle, TokenPanelHeader, TokenGroup, TokenItemBox } from '../../designsystem/common';
import { StyleInputName } from '../../common';
import Input_Name from '../../../../../../components/editors/input_name';
import NumberInput  from '../../../../../../components/editors/input_number';
import Switch  from '../../../../../../components/editors/Switch';

export default class SystemMediaStates extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }

        this.AddBreakpoint = this.AddBreakpoint.bind(this);
        
        this.breakpoints = Globals.ProjectManager.States.GetResponsiveBreakpoints();
    }
    componentDidUpdate(prevProps, prevState) {
        this.state.ShouldUpdate = false;
    }
    RCUpdate() {
        this.setState({ShouldUpdate : true});
    }
    AddBreakpoint() {
        const newBreakpoint = {
            id : Utils.Id(),
            name : 'New Breakpoint',
            width : this.breakpoints[0].width - 100
        };
        this.breakpoints.splice(0, 0, newBreakpoint);
        Globals.ProjectManager.States.SetResponsiveBreakpoints(this.breakpoints);
        this.RCUpdate();        
    }
    Delete(index) {
        this.breakpoints.splice(index, 1);
        Globals.ProjectManager.States.SetResponsiveBreakpoints(this.breakpoints);
        this.RCUpdate();        
    }
    onChangeName(breakpoint, name) {
        breakpoint.name = name;
        Globals.ProjectManager.States.SetResponsiveBreakpoints(this.breakpoints);
        this.RCUpdate();
    }
    onChangeWidth(breakpoint, width) {
        breakpoint.width = width;
        Globals.ProjectManager.States.SetResponsiveBreakpoints(this.breakpoints);
        this.RCUpdate();
    }
    onDisableBreakpoint(breakpoint, enabled) {
        breakpoint.disabled = !enabled;
        Globals.ProjectManager.States.SetResponsiveBreakpoints(this.breakpoints);
        this.RCUpdate();
    }
    render() { 
        
        return (  
            <SC.FCol style={{marginBottom : '8px', borderBottom : SC.CurrentTheme.theme.border_ondark}}>
                <GroupTitle 
                    title='RESPONSIVE BREAKPOINTS'
                    expandable={{
                        expanded : this.state.expanded,
                        onExpand : () => this.setState({expanded : !this.state.expanded})
                    }}
                    // hasAdd={{onAdd : this.AddBreakpoint}}
                    style={{paddingLeft : '8px', paddingTop : '4px', paddingBottom : '4px'}}
                /> 
                {
                    this.state.expanded && 
                    <SC.FCol style={{padding : '8px'}}>
                        {
                            this.breakpoints.map((breakpoint, i) => {
                                return (
                                    <SC.FRow key={i} alc style={{marginBottom : '4px',}}>
                                        <Input_Name 
                                            ref={this.Ref_Name}
                                            InputType={StyleInputName}
                                            styleProps={{
                                                placeholder : 'State Name',
                                                style : {                                    
                                                    marginLeft : '4px',
                                                    fontSize : '11px'
                                                },
                                            }}
                                            value={breakpoint.name}
                                            required
                                            onChange={this.onChangeName.bind(this, breakpoint)}                                            
                                        />
                                        <Switch round value={!breakpoint.disabled} onChange={this.onDisableBreakpoint.bind(this, breakpoint)} />
                                        <NumberInput 
                                            value={breakpoint.width} 
                                            onChange={this.onChangeWidth.bind(this, breakpoint)}
                                            numeralPositiveOnly
                                            boxStyle={{
                                                width : '80px',
                                                height : '24px',
                                                marginLeft: '8px',
                                                backgroundColor : SC.CurrentTheme.theme.input_back
                                            }}
                                            style={{                                    
                                                width : '100%',
                                                textAlign : 'right',
                                                fontSize : '12px',                
                                                // padding : 0,
                                                paddingRight : '4px',
                                                ...SC.Styles.FontStyles.Monospace
                                            }}
                                        />                                
                                        {/* <SC.Icons.Icon_Button hasFill hasCursor style={{marginLeft : '8px'}} onClick={this.Delete.bind(this, i)}>
                                            <SC.Icons.Icon_Delete size={16} />
                                        </SC.Icons.Icon_Button> */}
                                    </SC.FRow>                                
                                )
                            })
                        }
                    </SC.FCol> 
                }                                  
            </SC.FCol>
        );
    }
}
 
