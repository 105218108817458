export default {
  json : `  "images" : [
{{#eachJson tokens}}
  "name" : "{{formattedName}}",
      "url" : "{{value}}"
{{/eachJson}}
\n  ]`,
jsonLookup : `{
  "images" : {
  {{#each tokens}}
    "{{formattedName}}" : {      
      "url" : "{{value}}"
    },
  {{/each}}
  }
}`,
      css : `/* images */
:root {
{{#each tokens}}
  --{{formattedName}}: url({{value}});
{{/each}}
}
`,
js : `/* images */
{{#each tokens}}
const {{formattedName}} = "{{value}}";
{{/each}}
`,
less : `/* images */
{{#each tokens}}
@{{formattedName}}: "{{value}}";
{{/each}}
`,
sass : `/* images */
{{#each tokens}}
\${{formattedName}}: "{{{value}}}";
{{/each}}
`,
yaml : `images:
{{#each tokens}}
{{formattedName}}: "{{value}}"
{{/each}}
`
};