import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    UIUtils,
    Globals
} from '../../../../../../../importer';
import { StyleGroupTokenItem} from '../common';

import StateSelector, { StateSelectorsBox } from '../../../../../selectors/state/stateselector';

export const ComponentStateConfigurator = (props) => {
    if (props.states && props.states.length > 0) {
        const Variations = Utils.JustGet(props, {}, 'variations');
        return props.states.map((sid, i) => {
            return (    
                <StyleGroupTokenItem key={sid} style={{ marginTop : i === 0 ? '8px' : '4px', overflow : 'unset'}}>
                    <StateSelectorsBox style={{padding : '8px', paddingTop : '2px', paddingBottom : '2px'}}>
                        <StateSelector                                             
                            id={sid}
                            style={{backgroundColor:'unset', border : 'none', padding : 0}}
                            GetStateModel={props.GetStateModel}
                            SelectedVariation={Variations}
                            onSetStateVariation={props.onChangeState}
                            vertical
                            compact
                        />
                    </StateSelectorsBox>                                         
                </StyleGroupTokenItem>                                    
            )
        });
    }
    return null;
}