import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    AppLayout,
    Globals
} from '../../importer';
import TextInput from '../../components/editors/textinput';
import { GetDataApi } from '../../appstate/AppState';
import { Seperator } from '../project/panels/right/iteminspector/styleitems/common';
import DropDownSelect from '../../components/editors/enum_dropdown';
import { DotMenu } from '../project/panels/left/common';

export default class Settings extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.state.view = 'account';
        this.userName = AppState.User.displayName;
    }
    componentDidMount() {
        super.componentDidMount();
    }    
    SaveName(name) {
        if (name !== AppState.User.displayName) {
            AppState.UpdateUserSettings({uid : AppState.User.uid, name : name, onNameChanged : () => this.RCUpdate});
        }        
    }
    ResetPassword() {
        AppState.ResetPassword(AppState.User.email)
        .then(
            (result) => {
                if (result.error) {
                    this.ErrorMessage = result.error.errorMessage;
                    this.RCUpdate();
                }
                else {
                    Events.AlertSimple(Strings.PASSWORD_RESET_MAIL_SENT, Events.GLOBAL.NOTIFICATION.TYPES.INFO, "Change your password", "Please check your inbox!", SC.Icons.Icon_Email_Sent);
                }
            }
        );
    }
    
    renderCustom() {
        let content;

        if (this.state.view === 'account') {

            let canResetPassword = Utils.JustGet(AppState.User, '', 'providerData', 0, 'providerId') === 'password';           
            

            content = (
                <div style={{
                    display : 'grid',
                    gridTemplateColumns : '400px 1fr',
                    gridGap : '24px'
                }}>
                    <SC.FCol>
                        <GroupPanel title='ACCOUNT INFO' style={{
                            display : 'grid',
                            gridTemplateColumns : 'minMax(auto, 80px) 1fr',
                            gridGap : '16px',
                            alignItems : 'center',
                            alignContent : 'flex-start',
                        }}>
                            <div>Name</div>
                            <TextInput 
                                value={AppState.User.displayName}
                                style={{backgroundColor : SC.CurrentTheme.theme.back, fontSize : '14px', lineHeight : '24px', padding : '8px', height : 'auto'}}
                                themeId={AppLayout.ThemeId}
                                onChange={this.SaveName.bind(this)}
                            />
                            {
                                canResetPassword && 
                                <React.Fragment>
                                    <div />
                                    <SC.Buttons.RoundButton onClick={this.ResetPassword.bind(this)} style={{width : 'auto'}}>Reset Password</SC.Buttons.RoundButton>
                                </React.Fragment>
                            }  
                        </GroupPanel>
                    </SC.FCol>
                    <SC.FCol>
                        <MyTeam />
                        <MemberOfTeams />              
                    </SC.FCol>                    
                </div>
            )
        }

        return (
            <SC.FCol f1>
                <SC.FRow alc style={{padding : '8px'}}>
                    <SC.BackClosableTitle title='ACCOUNT SETTINGS' onClose={this.props.onClose} />
                </SC.FRow>
                <SC.InnerPanel style={{flex : 1, margin : '8px', marginTop : 0}}>
                    <SC.CustomScrollbars>
                        <SC.FCol style={{padding : '16px'}}>
                            {content}
                        </SC.FCol>
                    </SC.CustomScrollbars>                            
                </SC.InnerPanel>    
            </SC.FCol>
        )
    }
}

 const GroupPanel = (props) => {
     return (
         <React.Fragment>
             <SC.HeaderTitle style={{fontSize : '12px'}}>{props.title}</SC.HeaderTitle>
            <div style={{                
                padding : '16px',
                backgroundColor : SC.CurrentTheme.theme.back_lighter,
                borderRadius : '4px',
                border : SC.CurrentTheme.theme.border_seperator,                
                fontSize : '14px',
                marginTop : '8px',
                ...props.style
            }}>
                {props.children}
            </div>
         </React.Fragment>        
     )
 }

 export class TeamEditor extends ReactBaseComponent {
     constructor(props) {
         super(props);

         this.state.newMemberType = 'edit';
     }
     componentDidMount() {
        super.componentDidMount();
        this.LoadTeam();
    }
    LoadTeam() {
        GetDataApi().get_user_ownedTeam().then((result) => {
            if (result.id) {
                this.ownedTeam = result;                
            }
            this.state.teamLoaded = true;
            this.RCUpdate();
        })
    }
    CreateTeam() {
        if (this.state.createTeam) {
            this.setState({teamLoaded : false}, () => {
                GetDataApi().create_team({
                    name : Utils.UseNullOrEmpty(this.state.teamName, AppState.User.displayName + '\'s Team')
                }).then((result) => {
                    this.LoadTeam();
                });
                
            })
        }
        else {
            this.setState({createTeam : true, teamName : AppState.User.displayName + '\'s Team'});
        }                        
    }
    SaveName(name) {
        if (this.ownedTeam.name !== name) {
            this.ownedTeam.name = name;
            GetDataApi().api.set_path(`${GetDataApi().GetPaths().team_data(this.ownedTeam.id)}/name/`, name);
            this.RCUpdate();
        }        
    }
    InviteMember() {
        GetDataApi().invite_team_member({
            id : this.ownedTeam.id,
            email : this.state.newMemberEmail,
            type : this.state.newMemberType
        }).then(() => {
            this.state.newMemberEmail = '';
            this.LoadTeam();
        })
    }
    ChangeMemberType(member, type) {
        member.type = type;
        GetDataApi().api.set_path(`${GetDataApi().GetPaths().team_data(this.ownedTeam.id)}/members/` + member.id + '/type', type);        
        this.RCUpdate();
    }
    RemoveMember(member) {
        GetDataApi().api.delete_path(`${GetDataApi().GetPaths().team_data(this.ownedTeam.id)}/members/` + member.id);
        if (member.invitationId) {
            GetDataApi().api.delete_path(`${GetDataApi().GetPaths().team_data('invites')}/` + member.invitationId);
        }
        if (member.uid) {
            GetDataApi().api.delete_path(`${GetDataApi().GetPaths().user_teams(member.uid)}/invited/` + this.ownedTeam.id);
        }
        Utils.Remove(this.ownedTeam.members, (m) => {return m.id === member.id});
        this.RCUpdate();
    }
    renderCustom() { 
        let teamContent;

        if (this.state.teamLoaded) {
            if (this.ownedTeam) {
                teamContent = (
                    <SC.FCol>
                        <div style={{
                            display : 'grid',
                            gridTemplateColumns : 'auto 1fr',
                            alignItems : 'center',
                            gridGap : '16px'
                        }}>
                            <div>Team Name</div>
                            <TextInput 
                                value={this.ownedTeam.name}
                                style={{backgroundColor : SC.CurrentTheme.theme.back, fontSize : '14px', lineHeight : '24px', padding : '8px', height : 'auto'}}
                                themeId={AppLayout.ThemeId}
                                onChange={this.SaveName.bind(this)}
                            />
                        </div>
                        <Seperator />
                        <SC.HeaderTitle style={{fontSize : '12px', marginTop : '8px'}}>MEMBERS</SC.HeaderTitle>
                        <div style={{
                            marginTop : '16px',
                            display : 'grid',
                            gridTemplateColumns : 'auto auto auto 24px',
                            gridGap : '16px',
                            alignItems : 'center',
                            marginBottom : '8px'
                        }}>
                            {
                                this.ownedTeam.members.map((member) => {
                                    return (
                                        <TeamMember 
                                            key={member.id} 
                                            member={member} 
                                            onChangeType={this.ChangeMemberType.bind(this, member)}
                                            onRemove={this.RemoveMember.bind(this, member)}
                                        />
                                    )
                                })
                            }                            
                        </div>
                        <Seperator />
                        <SC.HeaderTitle style={{fontSize : '12px', marginTop : '8px'}}>INVITE MEMBER</SC.HeaderTitle>
                        <div style={{
                            display : 'grid',
                            gridTemplateColumns : '1fr 140px 140px',
                            alignItems : 'center',
                            gridGap : '16px',
                            marginTop : '16px'
                        }}>
                            <SC.Div_Relative>
                                {SC.RenderValueError(this.newEmailError, 'Not Unique')}
                                <TextInput 
                                    value={this.state.newMemberEmail}
                                    style={{backgroundColor : SC.CurrentTheme.theme.back, fontSize : '14px', lineHeight : '24px', paddingLeft : '8px', height : 'auto'}}
                                    themeId={AppLayout.ThemeId}
                                    onChange={(email) => this.setState({newMemberEmail : email})}
                                    placeholder='Member Email'
                                />
                            </SC.Div_Relative>
                            <MemberTypeSelector 
                                style={{height : '34px', maxHeight : '34px'}} 
                                value={this.state.newMemberType}
                                onChange={(type) => this.setState({newMemberType : type})}
                            />
                            <SC.Buttons.RoundButton small style={{width : 'auto'}} onClick={this.InviteMember.bind(this)}>
                                Send
                            </SC.Buttons.RoundButton>
                        </div>
                    </SC.FCol>                        
                )
            }
            else {
                if (this.state.createTeam) {
                    teamContent = (
                        <div style={{
                            display : 'grid',
                            gridTemplateColumns : 'auto 1fr 120px',
                            alignItems : 'center',
                            gridGap : '16px'
                        }}>
                            <div>Team Name</div>
                            <TextInput 
                                value={this.state.teamName}
                                style={{backgroundColor : SC.CurrentTheme.theme.back, fontSize : '14px', lineHeight : '24px', padding : '8px', height : 'auto'}}
                                themeId={AppLayout.ThemeId}
                                onChange={(name) => {
                                    this.setState({teamName : name})
                                }}
                            />
                            <SC.Buttons.RoundButton small style={{width : 'auto'}} onClick={this.CreateTeam.bind(this)}>Create</SC.Buttons.RoundButton>
                        </div>
                    )
                }
                else {
                    teamContent = (
                        <SC.Buttons.RoundButton small onClick={this.CreateTeam.bind(this)}>Create Team</SC.Buttons.RoundButton>
                    )
                }                
            }       
        }
        else {
            teamContent = <SC.Loading_Icon />
        }
        return (  
            <GroupPanel title='MY TEAM' style={{marginBottom : '24px'}}>
                {teamContent}
            </GroupPanel>
        );
    }
}

class TeamMember extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }

        this.ShowMenu = this.ShowMenu.bind(this);
    }
    ShowMenu() {
        this.setState({showMenu : !this.state.showMenu});
    }
    render() { 
        const {member} = this.props
        return (
            <React.Fragment >
                {member.active && <div>{member.name}</div>}
                {!member.active && <div style={{color : SC.CurrentTheme.theme.color_brand}}>Pending Invitation</div>}
                <div>{member.email}</div>                
                <MemberTypeSelector value={member.type} onChange={this.props.onChangeType} />
                <DotMenu onClick={this.ShowMenu} isOpen={this.state.showMenu} onClose={this.ShowMenu}>
                    {
                        this.state.showMenu &&
                        <SC.FCol style={{minWidth : '160px'}}>
                            <SC.PopupItem onClick={this.EditStates} onClick={this.props.onRemove}>
                                Remove from Team
                            </SC.PopupItem>
                            <SC.PopupItem onClick={this.ResetToDefault}>
                                Reset to Default 
                            </SC.PopupItem>
                        </SC.FCol>
                    }
                </DotMenu>  
            </React.Fragment>
        )
    }
}
 
const MemberTypeSelector = (props) => {
    return (
        <DropDownSelect 
            style={{backgroundColor : SC.CurrentTheme.theme.back, ...props.style}}
            hasBorder                                     
            noBackColor   
            autoHeight
            value={props.value}
            onChange={props.onChange}
            items={[
                AppState.Team.EditorTypes.edit,
                AppState.Team.EditorTypes.view,
            ]}
        />
    )
}

class MemberOfTeams extends ReactBaseComponent
{
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        super.componentDidMount();
        this.Load();
    }
    Load() {
        GetDataApi().get_user_teams().then((result) => {
            this.teams = [];
            this.invites = [];
            if (result.teams) {
                Utils.ForEach(result.teams, (team, tid) => {
                    if (team.active) {
                        this.teams.push({
                            id : tid,
                            name : team.name,
                            memberId : team.memberId
                        })
                    }     
                    else {
                        this.invites.push({
                            id : tid,
                            name : team.name,
                            memberId : team.memberId
                        })
                    }               
                });
            }
            this.loaded = true;
            this.RCUpdate();
        })
    }
    AcceptInvitation(team) {
        GetDataApi().accept_team_invitaion(team.id, team.memberId, team.invitationId).then(() => {
            this.Load();
        })
    }
    LeaveTeam(team) {
        GetDataApi().leave_team(team.id, team.memberId);
        Utils.Remove(this.teams, (t) => {return t.id === team.id});
        this.RCUpdate();
    }
    renderCustom() {
        let teamsMemberOf, teamsInvited;

        if (this.loaded) {
            if (this.teams.length > 0) {
                teamsMemberOf = (
                    <div style={{
                        display : 'grid', gridTemplateColumns : '1fr auto',
                        gridGap : '16px'
                    }}>
                        {
                            this.teams.map((team) => {
                                return (
                                    <React.Fragment>
                                        <div>{Utils.UseNullOrEmpty(team.name, 'Unnamed Team')}</div>
                                        <SC.LinkText selected onClick={this.LeaveTeam.bind(this, team)}>Leave Team</SC.LinkText>
                                    </React.Fragment>
                                )
                            })
                        }
                    </div>
                )
            }
            else {
                teamsMemberOf = (
                    <div>No Team</div>
                )
            }

            if (this.invites.length > 0) {
                teamsInvited = (
                    <div style={{
                        display : 'grid', gridTemplateColumns : '1fr auto',
                        gridGap : '16px'
                    }}>
                        {
                            this.invites.map((team) => {
                                return (
                                    <React.Fragment>
                                        <div>{Utils.UseNullOrEmpty(team.name, 'Unnamed Team')}</div>
                                        <SC.LinkText selected onClick={this.AcceptInvitation.bind(this, team)}>Accept</SC.LinkText>
                                    </React.Fragment>
                                )
                            })
                        }
                    </div>
                )
            }
            else {
                teamsInvited = (
                    <div>No Team</div>
                )
            }
        }
        else {
            teamsMemberOf = <SC.Loading_Icon />
            teamsInvited = <SC.Loading_Icon />
        }
        return (  
            <React.Fragment>
                <GroupPanel title='TEAMS I AM INVITED TO' style={{marginBottom : '24px'}}>
                    {teamsInvited}
                </GroupPanel>
                <GroupPanel title='TEAMS I AM MEMBER OF' style={{}}>
                    {teamsMemberOf}
                </GroupPanel>
            </React.Fragment>            
        );
    }
}