import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    UIUtils
} from '../../importer';

import * as Styled from './styles';

class Input_Name extends ReactBaseComponent {
    constructor(props) {
        super(props);
        this.value = Utils.UseNull(this.props.value, '');
        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.OnKeyDown = this.OnKeyDown.bind(this);
        this.OnKeyUp = this.OnKeyUp.bind(this);
        this.state = {
            focus: false
        }
    }
    componentDidMount() {
        super.componentDidMount();
        if (this.props.autoFocus)
            setTimeout(() => {
                this.Ref_Input && this.Ref_Input.focus();
            }, 10);

    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.value) {
            this.value = nextProps.value;
        }
        if (nextProps.focused && !this.props.focused) {
            this.Ref_Input.focus();
        }
        if (!nextProps.showError && this.props.showError && this.Error)
            delete this.Error;
    }
    OnKeyDown(e) {
        e.stopPropagation();
        return true;
    }
    OnKeyUp(e) {
        e.stopPropagation();
        if (this.props.onSubmit && (e.charCode === 13 || e.keyCode === 13)) {
            this.props.onSubmit();
        }
        else if (this.props.onCancel && e.keyCode === 27) {
            this.props.onCancel();
        }

        return true;
    }
    onBlur(e) {
        if (this.Error) {
            if (this.Error.validValue !== this.value) {
                this.value = this.Error.validValue;                
                this.props.onChange && this.props.onChange(this.value);
                delete this.Error;
                this.RCUpdate();
            }
        }
        this.setState({ focus: false });
        this.props.onChanged && this.props.onChanged(this.value);
        this.props.styleProps && this.props.styleProps.onBlur && this.props.styleProps.onBlur(e);
    }
    onFocus(e) {
        this.setState({ focus: true });
    }
    onChange(e) {
        this.Validate(e.target.value);
        this.value = e.target.value;
        this.RCUpdate();
        this.props.onChange && this.props.onChange(this.value);
    }
    Validate(value, display) {
        delete this.Error;
        if (this.props.required && !Utils.IsNotNullOrEmpty(value)) {
            this.Error = {
                error: true,
                message: "It's required",
                validValue: this.value
            };
        }
        else if (this.props.onValidate) {
            const result = this.props.onValidate(value);
            if (result && result.error) {
                this.Error = result;
                this.Error.validValue = this.value;
            }
        }
        if (display) {
            this.RCUpdate();
        }
        return !this.Error;
    }
    
    ClearError() {
        if (this.Error) {
            delete this.Error;
            this.RCUpdate();
        }
    }
    GetValue() {
        return this.value;
    }
    SetValue(v) {
        this.value = v;
        this.RCUpdate();
    }
    renderCustom() {
        let InputType = this.props.InputType;
        if (!InputType)
            InputType = SC.Input_Generic;
        let label = null;
        if (this.props.label) {
            let LabelType = this.props.LabelType;
            if (!LabelType)
                LabelType = Styled.Input_Form_Label;
            label = (
                <LabelType focus={this.state.focus}>
                    {this.props.label}
                </LabelType>
            )
        }
        var input = null;
        
        if (this.props.textArea)
            input = (<Styled.TextArea_Form
                ref={(r) => this.Ref_Input = r}
                {...this.props.styleProps}
                focus={this.state.focus}
                onBlur={this.onBlur}
                onFocus={this.onFocus}
                value={this.value || ''}
                onChange={this.onChange}
                error={this.Error}
                onKeyDown={this.OnKeyDown}
                onKeyUp={this.OnKeyUp}
                placeholder={this.props.placeholder}
            />);
        else
                input = (<Styled.Input_Form
                    ref={(r) => this.Ref_Input = r}
                    {...this.props.styleProps}
                    focus={this.state.focus}
                    onBlur={this.onBlur}
                    onFocus={this.onFocus}
                    value={this.value || ''}
                    onChange={this.onChange}
                    error={this.Error}
                    onKeyDown={this.OnKeyDown}
                    onKeyUp={this.OnKeyUp}
                    placeholder={this.props.placeholder}
                />);
        return (
            <div style={this.props.style || { position: 'relative', width: this.props.width ? this.props.width : 'auto' }}>
                {this.Error && SC.RenderValueError(this.Error, this.Error.message)}
                <div style={{ overflow: 'hidden' }}>
                    {label}
                    <SC.FRow alc>
                        {input}
                        {
                            this.props.showIcon && 
                            <SC.Icons.Icon_Edit size={22} style={{ marginLeft: -35 }}
                            selected={this.state.focus} hoverColor={SC.CurrentTheme.theme.color_brand} />
                        }
                    </SC.FRow>
                </div>
            </div>
        );
    }
}

export default Input_Name;