import React from 'react';
import {
    SC,
    Globals,
    Utils,
} from '../../../../../../../importer';

import AppLayout from '../../../../../../../appstate/AppLayout';
import TransformList, { TransformCardItem, TransformListItem, AddNewTransformToken, GetTransformTokenList } from '../../../../left/designsystem/transforms';
import TransformTokenEditor from '../../../../left/designsystem/transforms/item';
import BaseSelectToken from '../BaseSelectToken';
import { TokenPanelHeader } from '../../../../left/designsystem/common';

import TokenSelectList from '../../../../left/designsystem';

// export default (props) => {
//     return (
//         <React.Fragment>
//             <TokenSelectList 
//                 isSelectList
//                 onRightPanel
//                 SelectTokenType={Globals.ProjectManager.Tokens.Types.Transforms}
//                 onClose={props.onClose}
//                 onSelect={(tokenId, ...rest) => {
//                     if (props.onSelectForItem)
//                         props.onSelectForItem(props.Id, tokenId, [],...rest);
//                     else 
//                         props.onSelect(tokenId, ...rest);
//                     props.onClose();
//                 }}
//             />
//         </React.Fragment>        
//     )
// }

export default class SelectTransformTokenOld extends BaseSelectToken
{
    constructor(props) {
        super(props);

        this.titleNewToken = 'NEW TRANSFORM';
        this.moduleName = 'Transforms';
        this.tokenType = Globals.ProjectManager.Tokens.Types.Transforms;        

        this.Load(this.props);
    }
    IsTokenPropChanged(nextProps, nextState) {
        if (Utils.HasAnyChange(this.props, nextProps, 'GlobalState')) {
            this.Load(nextProps);
            return true;
        }        
    }
    GetTokens(props) {
        return GetTransformTokenList(props.StateArray);
    }            
    GetNewModel(Id) {
        const Model = {
            name : 'New Transform',
            items : [
                {
                    id : Utils.Id(),
                    type : 'scale',
                    locked : true
                }    
            ],
        };
        AddNewTransformToken(Model, Id);
        this.FireSelectToken(Id);
        return Model;
    }
    SaveToken() {
        if (this.EditingToken.IsNew) {
            const transformItem = AddNewTransformToken(this.EditingToken.Model, this.EditingToken.Id);
            AppLayout.Refs.DesignSystem.Transforms && AppLayout.Refs.DesignSystem.Transforms.Reload();
            return transformItem;
        }        
        else {
        }                
    }  
    onCloseTokenEditor() {
        if (this.EditingToken && this.EditingToken.IsNew) {
            delete this.EditingToken;            
            this.RCUpdate();
            return;
        }
        super.onCloseTokenEditor();
    }
    onCancelAddToken() {
        const willDeleteId = this.EditingToken.Id;        
        this.FireSelectToken(this.LastBoundTokenId);
        delete this.LastBoundTokenId;
        super.onCancelAddToken();
        Globals.ProjectManager.Tokens.Delete(Globals.ProjectManager.Tokens.Types.Transforms, willDeleteId);
    } 
    UpdateSelectedToken() {
        if (this.EditingToken) {
            const SelectedToken = Utils.Find(this.tokens, (token) => {return token.id === this.EditingToken.Id});
            const token = Globals.ProjectManager.Tokens.Token(this.EditingToken.Id);
            const transform = Globals.ProjectManager.Tokens.ValueOf({model : token, statearray : this.props.StateArray});
            const transforms = Utils.JustGet(transform, [], 'items');
            SelectedToken.value = Utils.GetTransformCss(transforms, transform.originX, transform.originY, Globals.ProjectManager.Tokens.ValueOfId); 
            SelectedToken.value.transformOrigin = Utils.GetTransformOrigin(transform);
        }
    }
    GetStyleName() {
        return 'transform';
    }
    renderTokenList() {
        let content;
        const styleName = this.GetStyleName();
        if (this.state.isListView) {
            const transforms = [];

            Utils.ForEach(this.tokens, (transform, i) => {
                transforms.push(
                    <TransformListItem
                        key={transform.id}
                        notDraggable
                        transform={transform}
                        onSelect={this.SelectToken.bind(this, transform.id)}
                        selected={transform.id === this.GetSelectedTokenId()}
                        onPreview={{
                            onMouseEnter : this.PreviewToken.bind(this, true, transform.id, styleName),
                            onMouseLeave : this.PreviewToken.bind(this, false, transform.id, styleName)
                        }}
                    />
                )
            });

            content = (
                <SC.FCol>
                    {transforms}
                </SC.FCol>
            )
        }
        else {
            const transforms = [];
            Utils.ForEach(this.tokens, (transform, i) => {
                transforms.push(
                    <TransformCardItem
                        transform={transform}
                        notDraggable
                        key={transform.id} 
                        onClick={this.SelectToken.bind(this, transform.id)}
                        selected={transform.id === this.GetSelectedTokenId()}
                        onPreview={{
                            onMouseEnter : this.PreviewToken.bind(this, true, transform.id, styleName),
                            onMouseLeave : this.PreviewToken.bind(this, false, transform.id, styleName)
                        }}
                    />
                )
            });

            content = (
                <SC.FRow style={{flexWrap : 'wrap'}}>
                    {transforms}
                </SC.FRow>
            )
        }

        return content; 
    }
    renderTokenEditor() {
        return (
            <TransformTokenEditor 
                id={this.EditingToken.Id}
                newModel={this.EditingToken.IsNew ?  this.EditingToken.Model : null}  
                GlobalState={Globals.ProjectManager.GlobalState}                      
                offline={this.EditingToken.IsNew}
                onPreviewChange={this.EditingToken.IsNew ? this.PreviewNewToken : null}
            />                 
        )        
    }
    GetSelectedTokenId() {
        return this.props.transform;
    }
    renderSelectedToken() {
        if (this.GetSelectedTokenId()) {
            return this.renderSelectedTokenHeader();
        }
    }
}
