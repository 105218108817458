import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    UIUtils,
    Globals
} from '../../../../../../../../importer';

import { ItemNameEditor, TokenPanelHeader, TokenGalleryView } from '../../../common';
import BezierEditor from '../bezierEditor';
import PresetList from '../bezierEditor/presetlist';
import { LeftScrollPanel } from '../../../../common';
import { motion, AnimatePresence } from 'framer-motion';

export default class EaseTokenEditor extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.SaveName = this.SaveName.bind(this);
        this.GetItems = this.GetItems.bind(this);
        this.onChangeCurve = this.onChangeCurve.bind(this);
        this.ApplyPreset = this.ApplyPreset.bind(this);
        this.SelectPreset = this.SelectPreset.bind(this);

        this.RemoveStateOverride = this.RemoveStateOverride.bind(this);        
        this.MoveValueToParentState = this.MoveValueToParentState.bind(this);
        this.SwitchStateToTokensOverriden = this.SwitchStateToTokensOverriden.bind(this);
        this.ApplyValueToCurrentState = this.ApplyValueToCurrentState.bind(this);        

        this.Ref_Name = React.createRef();

        if (this.props.newModel) {
            this.token = this.props.newModel;
        }
        else {
            this.Load(this.props.id);            
        }
    }
    RemoveStateOverride() {
        if (this.ValueState.ValueState && !this.ValueState.ValueState.inherited) {
            Globals.ProjectManager.Tokens.DeleteValue({
                id : this.props.id,
                state : this.ValueState.ValueState.state
            });
            Globals.ProjectManager.Tokens.DeleteValue({
                id : this.props.id,
                state : this.ValueState.ValueState.state,
                name : 'duration'
            });

            this.Load(this.props.id);
            Events.BCE(Events.GLOBAL.TOKENS_CHANGED);
        }        
    }
    MoveValueToParentState() {
        if (this.ValueState.ValueState) {
            Globals.ProjectManager.Tokens.MoveValueToState({
                id : this.props.id,
                fromState : this.ValueState.ValueState.state
            });
            this.Load(this.props.id);
            this.RCUpdate();
        }
    }
    ApplyValueToCurrentState() {
        if (this.ValueState.ValueState) {
            Globals.ProjectManager.Tokens.MoveValueToState({
                id : this.props.id,
                fromState : this.ValueState.ValueState.state,
                toState : Globals.ProjectManager.CurrentState
            });
            this.Load(this.props.id);
            this.RCUpdate();
        }
    }
    SwitchStateToTokensOverriden() {
        const ToState = this.ValueState.ValueState.state;
        delete this.ValueState.ValueState;
        Globals.ProjectManager.States.SetStateFromLabel(ToState);
    }
    componentWillUnmount() {
        this.props.onClosed && this.props.onClosed();
        super.componentWillUnmount();        
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.id !== this.props.id || this.props.GlobalStateId !== nextProps.GlobalStateId) {
            this.Load(nextProps.id);
            if (!this.token) {
                this.props.onCancelAddPattern();
                return false;
            }
        }
        return true;
    }
    Load(id) {
        this.token = Globals.ProjectManager.Tokens.Token(id);

        this.ValueState = {
            onRemoveOverride : this.RemoveStateOverride,
            onMoveToParent : this.MoveValueToParentState,
            onApplyToCurrentState : this.ApplyValueToCurrentState,
            onSwitchToOverridenState : this.SwitchStateToTokensOverriden
        };
        Globals.ProjectManager.Tokens.ValueOf({model : this.token, info: this.ValueState});
        Globals.ProjectManager.Tokens.ValueOf({model : this.token, name : 'duration', info: this.ValueState});
    }
    GetItems() {
        return Globals.ProjectManager.Tokens.TokenList(Globals.ProjectManager.Tokens.Types.Motion);
    }        
    onChangeCurve(curve) {
        if (!this.props.newModel) {
            Globals.ProjectManager.LogTokenChange({Desc : 'Change Ease Curve'});
            Globals.ProjectManager.Tokens.SetValue({
                id : this.props.id,
                value : curve,
                type : Globals.ProjectManager.Tokens.Types.Motion
            }); 
            Events.BCE(Events.GLOBAL.TOKENS_CHANGED);

            const ValueState = Utils.Get(this.ValueState, {
                state : Globals.ProjectManager.CurrentState
            }, 'ValueState');
            ValueState.inherited = false;
        }
        else {            
            Globals.ProjectManager.Tokens.SetValueOf({
                id : this.props.id,
                value : curve,
                model : this.token
            });
        }
                
        this.RCUpdate();
    }    
    SelectPreset() {
        this.ShowPresets = !this.ShowPresets; 
        this.RCUpdate();
    }
    ApplyPreset(i,j,path, name) {
        this.Selected1 = i;
        this.Selected2 = j;
        if (this.props.newModel) {
            this.props.newModel.name = name;
        }

        this.onChangeCurve(Utils.DeepClone(path));

        this.ShowPresets = false;
        this.RCUpdate();
    }    
    SaveName(name) {
        if (this.props.newModel)
            this.props.newModel.name = name;
        else
            Globals.ProjectManager.Tokens.ChangeTokenName(this.props.id, name);
        this.RCUpdate();
    }
    renderCustom() {
        
        return (
            <SC.FCol fw fh >
                {
                    !this.ShowPresets && 
                    <React.Fragment>
                        {
                            !this.props.noHeader && 
                            <TokenPanelHeader title={this.props.newModel ? 'NEW EASE' : 'EDIT EASE'} 
                                hasAddCancel 
                                notBackClosable={this.props.newModel ? true : false}
                                onClose={this.props.onCancelAddPattern} 
                                onCancel={this.props.onCancelAddPattern} 
                                onAdd={this.props.onSubmitNewPattern} 
                                onDelete={this.props.onDeletePattern}
                                onClone={this.props.onClonePattern}
                                stateValue={this.ValueState}
                            />
                        }                        
                        <LeftScrollPanel>
                            <SC.FCol fw fh f1 style={{padding : '10px', boxSizing : 'border-box', backgroundColor : SC.CurrentTheme.theme.back, pointerEvents : this.token.locked ? 'none' : 'all'}}>
                                <SC.FCol style={{marginBottom : '16px'}}>
                                    <ItemNameEditor
                                        noMargin
                                        fontSize='12px'                        
                                        name={this.token.name}
                                        onSaveName={this.SaveName}
                                        onGetItems={this.GetItems}
                                        model={this.token}
                                        readOnly={this.token.locked}
                                        lockable={{
                                            isLocked : this.token.locked,
                                            onTokenLock : () => {
                                                this.token.locked = Globals.ProjectManager.Tokens.ToggleTokenLock(this.props.id);                                    
                                                this.RCUpdate();
                                            }
                                        }}
                                    />
                                </SC.FCol>    
                                <BezierEditor 
                                    model={this.token} 
                                    onChangeCurve={this.onChangeCurve} 
                                    onSelectPreset={this.SelectPreset} 
                                    noDuration
                                    onPreset={this.ApplyPreset}
                                />
                            </SC.FCol>        
                        </LeftScrollPanel>
                    </React.Fragment>
                }
                {
                    this.ShowPresets && 
                    <TokenGalleryView 
                        title='SELECT EASE PRESET' 
                        onClose={this.SelectPreset}
                        style={{backgroundColor : SC.CurrentTheme.theme.back}}
                    >
                        <PresetList 
                            onApplyPreset={this.ApplyPreset}
                        />
                    </TokenGalleryView>
                }
            </SC.FCol>            
        )
    }
}