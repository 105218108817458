import React from 'react';
import ReactDOM from 'react-dom';

import {
    MetaData,
    Utils,
    UIUtils,
    SC,
    Strings,
    AppLayout
} from '../../../../../../../importer';

import BaseGeneric from '../base';
import {RenderItemIcon} from '../../icon';
import { GenericCarousel_SubTypeIds } from '../../../../../../../../toolabs-modules/toolabs-metaui/components/generic/initialize/carousel';
import { motion } from 'framer-motion';

export default class Generic_Carousel extends BaseGeneric {
    constructor(props) {
        super(props);        
        
        this.NavigateTo = this.NavigateTo.bind(this);

        if (this.props.GenericDesigner) {
            this.slides = {
                items : [
                    {Id : 1, name : 'Slide 1'},
                    {Id : 2, name : 'Slide 2'}
                ]
            };

            this.state.active = 0;
            this.state.previous = this.GetPrevious(this.slides.items.length, this.state.active);
        }        
    }
    componentDidMount() {
        super.componentDidMount();
        this.loaded = true;
    }
    UpdateStyle(props) {
        this.renderData = props.GetComponentManager().GetItemStyle(props.Id);        

        const MetaItem = this.props.GetComponentManager().GetMetaItem(this.props.Id);
        
        this.renderDataSubTypeStyles = {};
        
        if (MetaItem) {
            GenericCarousel_SubTypeIds.map((SubType) => {
                const SubTypeId = Utils.JustGet(MetaItem.Generic, null, SubType.Id);         
                this.renderDataSubTypeStyles[SubType.Id] = props.GetComponentManager().GetItemStyle(SubTypeId);
            })
        }
        if (this.props.GenericDesigner) {

        }
        else {
            this.slides = Utils.JustGet(this.props, {items : []}, 'Slides');
            if (!this.slides.items)
                this.slides.items = [];
            const index = Math.max(0, Utils.FindIndex(this.slides.items, (slide) => {return slide.Id === this.slides.activeSlide}));
            this.state.active = index || 0;
            this.state.next = this.GetNext(this.slides.items.length, this.state.active);
            this.state.previous = this.GetPrevious(this.slides.items.length, this.state.active);
        }

    }
    shouldComponentUpdate(nextProps, nextState) {
        if (!this.props.isDropTarget && nextProps.isDropTarget || this.props.isDropTarget && !nextProps.isDropTarget) {                     
            return true;
        }
        if (nextState.active !== this.state.active)
            return true;
        if (Utils.HasAnyChange(this.props, nextProps, 'Slides')) {
            this.slides = Utils.JustGet(nextProps, {items : []}, 'Slides');
            if (!this.slides.items)
                this.slides.items = [];
            const index = Math.max(0, Utils.FindIndex(this.slides.items, (slide) => {return slide.Id === this.slides.activeSlide}));
            this.state.previous = this.state.active;
            this.state.fromRight = index > this.state.active
            this.state.active = index || 0;

            return true;
        }
        return super.shouldComponentUpdate(nextProps, nextState);
    }
    GetNext(count, current) {
        return (current + 1) % count;
    }
    GetPrevious(count, current) {
        let previous = (current + -1) % count;
        if (previous < 0)
            previous = count - 1;
        return previous;
    }
    MoveSlide(offset) {
        if (!this.slides)
            return;
            
        const active = offset > 0 ? this.GetNext(this.slides.items.length, this.state.active) : this.GetPrevious(this.slides.items.length, this.state.active);                
        
        if (this.props.GenericDesigner) {

        }
        else {
            const slide = this.slides.items[active];
            this.props.Slides.activeSlide = slide.Id;
            this.props.GetComponentManager().isSubComponent.GetManager().GetDesignManager().SetItemProp(this.props.GetComponentManager().isSubComponent.ItemId, this.props.Slides, 'Slides');
        }

        this.setState({
            active : active,
            previous : this.state.active,
            fromRight : offset > 0
        });
    }
    NavigateTo(index) {
        if (!this.slides)
            return;

        if (this.props.GenericDesigner) {

        }
        else {
            const slide = this.slides.items[index];
            this.props.Slides.activeSlide = slide.Id;
            this.props.GetComponentManager().isSubComponent.GetManager().GetDesignManager().SetItemProp(this.props.GetComponentManager().isSubComponent.ItemId, this.props.Slides, 'Slides');
        }

        this.setState({
            active : index,
            previous : this.state.active,
            fromRight : index > this.state.active
        });
    }
    renderContent({style, props}) {        

        let textContent = Utils.JustGet(this.renderData.props, '', 'textContent');
        let style_item = {
            ...style,
            overflow : 'hidden'
        };
        
        

        let content;
        if (this.props.GenericDesigner) {
            style_item = {
                ...style, flex : 1, height : 'unset', width : 'unset', alignSelf : 'stretch', position : 'relative', overflow : 'hidden'
            }
        }
        else {
            style_item.display = 'flex';                        
        }

        const prev = (this.state.previous > -1 && this.state.previous < this.slides.items.length) ? this.slides.items[this.state.previous] : null;
        const active = (this.state.active > -1 && this.state.active < this.slides.items.length) ? this.slides.items[this.state.active] : null;

        const transformTemplate = ({x}) => {
            const t = `translateX(${x})`;
            return t;
        };

        content = (
            <SC.FRow f1 style={{alignItems : 'stretch'}}>
                {
                    prev && 
                    <motion.div
                        key={prev.Id + 'prev'}
                        initial={this.loaded ? {x : 0} : false}
                        animate={{x : this.state.fromRight ? '-100%' : '100%'}}
                        transition={{
                            duration : 0.4
                        }}
                        transformTemplate={transformTemplate}
                        style={{
                            ...SC.Styles.Absolute,
                            ...SC.Styles.Flex.Cell,  
                        }}
                    >
                        {this.props.renderSlide && this.props.renderSlide(prev)}
                    </motion.div>     
                }
                {
                    active &&        
                    <motion.div
                        key={active.Id + 'active'}
                        initial={this.loaded ? {x : this.state.fromRight ? '100%' : '-100%'} : false}
                        animate={{x : 0}}
                        transition={{
                            duration : 0.4
                        }}
                        transformTemplate={transformTemplate}
                        style={{
                            ...SC.Styles.Absolute,
                            ...SC.Styles.Flex.Cell,  
                        }}
                    >
                        {this.props.renderSlide && this.props.renderSlide(active)}
                    </motion.div>     
                }
            </SC.FRow>
        )

        return (
            <SC.Div_Relative {...props} style={style_item}>
                {content}
                <NavigationArrow  
                    left                   
                    options={this.renderDataSubTypeStyles.ArrowPrev}                                        
                    onMove={this.MoveSlide.bind(this, -1)}
                />
                <NavigationArrow
                    right 
                    options={this.renderDataSubTypeStyles.ArrowNext}
                    onMove={this.MoveSlide.bind(this,  1)}
                />
                <NavigationBar 
                    options={this.renderDataSubTypeStyles.Navigator}
                    count={this.slides.items.length}
                    index={this.state.active}
                    onNavigateTo={this.NavigateTo}
                />
            </SC.Div_Relative>
        )
    }
}
class NavigationArrow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        const {options} = this.props;
        if (options.props.hidden)
            return null;

        let MetaIcon = Utils.JustGet(options.props, null, 'icon');
        if (!MetaIcon) {
            MetaIcon = {
                provider : Strings.ICON_GOOGLE_MATERIAL,
                paths : this.props.left ? ['M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z'] : ['M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z']
            }
        }
        let icon;
        if (MetaIcon) {
            icon = (
                <RenderItemIcon 
                    MetaIcon={MetaIcon}
                    style={{
                        width : '40px',
                        height : '40px',
                    }}
                />
            )            
        }
        const style = {
            position : 'absolute',
            top : 0,
            bottom : 0,
            padding : '8px',
            backgroundColor : 'rgba(0,0,0,0.3)',
            ...SC.Styles.Flex.Cell,
            cursor : 'pointer'
        };
        if (this.props.right) {
            style.right = 0;
        }
        else {
            style.left = 0;
        }
        return (  
            <div
                style={{...style, ...options.style}}
                onClick={this.props.onMove}
            >
                {icon}
            </div>
        );
    }
}

class NavigationBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    onNavigateTo(index, e) {
        e.stopPropagation();
        this.props.onNavigateTo(index);
    }
    render() { 
        const buttons = [];
        for (let i=0; i<this.props.count; i++) {
            buttons.push(
                <div
                    key={i}
                    style={{
                        padding : '8px',
                        borderRadius : '99px',
                        margin : '8px',
                        backgroundColor : this.props.index === i ? 'rgba(0,0,200,0.3)' : 'rgba(0,0,0,0.3)',
                        cursor : 'pointer'
                    }}
                    onClick={this.onNavigateTo.bind(this, i)}
                >

                </div>
            )
        }
        return (  
            <div
                style={{
                    position : 'absolute',
                    bottom : 0,
                    left : '50%',
                    transform : 'translateX(-50%)',
                    ...SC.Styles.Flex.RowAlcJsb
                }}
            >
                {buttons}
            </div>
        );
    }
}
