import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    AppState,
    Utils,
    Events,
    Loading,
    Strings,
    MetaData,
    Links,
    UIUtils
} from '../../../../../importer';
import PanelHeader from '../../header';
import { API_IconFinder, IconThumbnail, DraggedIconThumbnail } from '../../../../../integrations/iconfinder';
import GoogleMaterialIcons from '../../../../../integrations/iconfinder/googlematerialicons';
import DropDownSelect from '../../../../../components/editors/enum_dropdown';

var Global_IconSource = Strings.ICON_GOOGLE_MATERIAL;

export class OnlineIconSelect extends ReactBaseComponent
{
    constructor(props) {
        super(props);
        this.LoadPage = this.LoadPage.bind(this);
        this.LoadIconBeforeDrag = this.LoadIconBeforeDrag.bind(this);
        this.refreshIndex = 0;
    }    
    componentDidMount() {
        super.componentDidMount();
        this.LoadPage();     
    }    
    //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
    componentDidUpdate(prevProps, prevState) {
        if (prevState.Search !== this.state.Search || this.props.provider !== prevProps.provider) {
            delete this.images;
            this.LoadPage();
        }
    }
    
    LoadIconBeforeDrag(item) {
        return new Promise((resolve) => {
            API_IconFinder.DownloadSVG(item.Item.MetaItem.Provider.downloadUrl).then(({data}) => {
                try {
                    item.Item.MetaItem.Provider.data = window.btoa(data);
                    resolve();    
                } catch (error) {
                    
                }                
            })
        })
    }
    LoadPage() {
       if(Global_IconSource === Strings.ICONFINDER){
            const that = this;
            if (API_IconFinder.IsLoading())
                return;
            API_IconFinder.Search({
                SearchText : this.state.Search
            }).then((result) => {
                this.total = result.total;
                const images = Utils.Get(that, [], 'images');
                let tempIndex = images.length;
                Utils.ForEach(result.images, (img, i) => {
                    images.push(img);
                });
                this.refreshIndex = tempIndex;
                that.RCUpdate();            
            })
        }       
    }
    PreviewPattern(show, url) {
        this.WillShowPreview = {
            show : show,
            url : url
        }
        setTimeout(() => {
            if (this.WillShowPreview) {
                this.props.onPreviewChange(this.WillShowPreview.show, {PreviewUrl : this.WillShowPreview.url});
                delete this.WillShowPreview;
            }
            
        }, 200);         
    }
    Select(icon) {
        this.props.onSelect && this.props.onSelect(icon, Strings.ICONFINDER);
    }
    renderCustom() {
        let content = null;

        let IconType = IconThumbnail;
        if (this.props.dragSource) {
            IconType = DraggedIconThumbnail;
        }

        if (Global_IconSource === Strings.ICONFINDER) {
            if (this.images) {
                content = (
                    <div style={{
                        display : 'grid',
                        gridGap : '4px',
                        gridTemplateColumns : 'repeat(auto-fit, minmax(70px, 1fr))',
                        gridAutoRows : '80px',
                    }}>
                        {
                            this.images.map((img, i) => {
                                return (
                                    <IconType 
                                        key={img.id} 
                                        url={img.preview} 
                                        downloadUrl ={img.downloadUrl}
                                        last={i===this.refreshIndex}
                                        onLoadPage={this.LoadPage}
                                        asyncBeforeDrag={this.LoadIconBeforeDrag}
                                        dark={this.props.dark}
                                        hasSelect={this.props.dragSource ? null : {
                                            onSelect : this.Select.bind(this, img)
                                        }}
                                        onMouseEnter={this.props.onPreviewChange && this.PreviewPattern.bind(this, true, img.preview)}
                                        onMouseLeave={this.props.onPreviewChange && this.PreviewPattern.bind(this, false, img.preview)}
                                        DragItem={this.props.dragSource ? {
                                            Type : Strings.TOOLBARITEM,
                                            Item : {
                                                MetaItem : {
                                                    Type :  MetaData.Components.SvgIcon.Type,
                                                    Id : img.id,
                                                    Provider : {
                                                        provider : this.props.provider || Strings.ICONFINDER,
                                                        id : img.id,
                                                        preview : img.preview,
                                                        url : img.url,
                                                        downloadUrl : img.downloadUrl
                                                    }
                                                }
                                            } 
                                        } : null}
                                    />
                                )
                            })
                        }
                    </div>                                    
                )
            }
        }
        else {
            content = (
                <GoogleMaterialIcons 
                    dragsource={this.props.dragSource}
                    justContent
                    filter={this.state.Search}
                    Dark={this.props.dark}
                    onSelect={this.props.onSelect}
                    onPreviewChange={this.props.onPreviewChange}
                />
            )
        } 

        return  (
            <React.Fragment>                
                <PanelHeader
                    noSort
                    noTags
                    Search={this.state.Search}
                    forComponent
                    onSearch={(s) => this.RCSetStateValue('Search', s)} onCancel={() => this.RCSetStateValue('Search', '')}
                />
                {this.props.children}
                <SC.FRow style={{marginTop : '8px', marginBottom : '8px'}}>
                    <DropDownSelect 
                        fullwidth
                        noBackColor
                        hasBorder
                        items={[
                            {id : Strings.ICON_GOOGLE_MATERIAL},
                            {id : Strings.ICONFINDER}
                        ]}
                        autoHeight
                        value={Global_IconSource || Strings.ICON_GOOGLE_MATERIAL}
                        onChange={(val) => { Global_IconSource = val; this.LoadPage(); this.RCUpdate();}}
                    />
                </SC.FRow>
                <SC.FCol f1>          
                    <SC.CustomScrollbars hideTracksWhenNotNeeded autoHide>
                        {content}
                    </SC.CustomScrollbars>  
                </SC.FCol>                       
            </React.Fragment>
        )
    }
}

export default OnlineIconSelect;