import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    AppLayout,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../../../../importer';
import styled, { css} from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import SlateEditor from '../text/editor';
import { DEFAULT_LOTTIE_FILE } from '../wizard';
let LottiePlayer;

export default class DocumentItem_Lottie extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {

        };    

        this.Ref_Player = React.createRef();

        this.onChangeSubtitle = this.onChangeSubtitle.bind(this);
        this.onReloadSubtitle = this.onReloadSubtitle.bind(this);    
        this.ResizePlayer = this.ResizePlayer.bind(this);
        this.ThrottledResizePlayer = Utils.Throttle(this.ResizePlayer, 200);
    }    
    componentDidMount() {
        LottiePlayer = window.bodymovin;

        if (!LottiePlayer) {            
            const script = document.createElement("script");
            script.src = "https://cdnjs.cloudflare.com/ajax/libs/bodymovin/5.6.5/lottie.min.js";
            script.async = true;
            script.onload = this.HeaderLoaded.bind(this, true);
            document.head.appendChild(script);
        }
        else
            this.Load();
    }
    componentWillUnmount() {
        if (this.PlayerInstance)
            this.PlayerInstance.destroy();
    }
    HeaderLoaded(scriptLoaded, styleLoaded) {
        if (scriptLoaded) {
            this.scriptLoaded = true;
        }
        if (styleLoaded)
            this.styleLoaded = true;
        if (this.scriptLoaded && this.styleLoaded) {
            LottiePlayer = window.bodymovin;
            
            if (LottiePlayer) {
                this.Load();
            }                    
        }
    }
    shouldComponentUpdate(nextProps, nextState) {

        if (this.props.autoFocus !== nextProps.autoFocus)
            return true;
        if (this.state.ShouldUpdate !== nextState.ShouldUpdate)
            return true;
        
        if (this.props.RenderId !== nextProps.RenderId) {
            this.Load(nextProps.id);
            return true;
        }        
        if (Utils.HasAnyChange(this.props, nextProps, 'preview', 'GlobalThemeId', 'GlobalState', 'StyleId', 'themeId'))
            return true;
        
        return false;
    }    
    Load(id) {
        const url = Globals.ProjectManager.GetDocumentManager().GetPageItemValue(this.props.id, this.props.pageId, DEFAULT_LOTTIE_FILE, 'url');
        if (this.Ref_Player.current && LottiePlayer && Utils.IsNotNullOrEmpty(url)) {

            const DN = ReactDOM.findDOMNode(this.Ref_Player.current);
            
            this.PlayerInstance = new LottiePlayer.loadAnimation({
                container: DN,
                path: url,
                renderer: 'svg/canvas/html',
                loop: true, // Optional
                autoplay: true, // Optional
                rendererSettings : {
                    progressiveLoad: true,
                }

            });
            
            const speed = Globals.ProjectManager.GetDocumentManager().GetPageItemValue(this.props.id, this.props.pageId, 10, 'speed') / 10
            this.PlayerInstance.setSpeed(speed || 1);

            this.setState({ShouldUpdate : true});
        }        
    }
    componentDidUpdate(prevProps, prevState) {
        this.state.ShouldUpdate = false;
        // if (this.props.RenderId !== prevProps.RenderId && this.PlayerInstance) {
        //     this.ThrottledResizePlayer();
        // }
    }
    ResizePlayer() {
        setTimeout(() => {
            this.PlayerInstance && this.PlayerInstance.resize();    
        }, 100);        
    }
    Update() {
        this.setState({
            ShouldUpdate : true
        })
    }    
    onChangeSubtitle(value) {
        Globals.ProjectManager.GetDocumentManager().SetItemValue(this.props.id, Utils.DeepClone(value), 'content');
        this.setState({
            value : value,
            ShouldUpdate : true
        })             
    }
    onReloadSubtitle(value) {
        this.EditorId = Utils.Id();
        this.onChangeSubtitle(value);
    }
    render() {        

        let content;

        const alignment = Globals.ProjectManager.GetDocumentManager().GetPageItemValue(this.props.id, this.props.pageId, 'center', 'alignment');
        const size = (alignment !== 'fullwidth' && alignment !== 'stretch') ? Globals.ProjectManager.GetDocumentManager().GetPageItemValue(this.props.id, this.props.pageId, 100, 'size') : null;
        const height = Globals.ProjectManager.GetDocumentManager().GetPageItemValue(this.props.id, this.props.pageId, 400, 'height')
        const subtitle = Globals.ProjectManager.GetDocumentManager().GetPageItemValue(this.props.id, this.props.pageId, [
            {
                type: 'subtitle',
                children: [{ text: '' }]
            }
        ], 'content');

        const url = Globals.ProjectManager.GetDocumentManager().GetPageItemValue(this.props.id, this.props.pageId, '', 'url');
        const isEmpty = !Utils.IsNotNullOrEmpty(url);

        return (
            <SC.FCol>
                <motion.div
                    style={{
                        ...SC.Styles.Flex.Column
                    }}
                    layoutTransition={{
                        duration : 0.2
                    }}
                >                    
                    <SC.Div_Relative 
                        ref={this.Ref_Player} 
                        style={{
                            alignSelf : alignment,
                            width : size ? Utils.px(size, '%') : 'auto',
                            height : Utils.px(height),
                            minHeight : '100px',
                            overflow : 'hidden'
                        }}
                    >
                        {
                            isEmpty && !this.props.preview &&                          
                            <a href='https://lottiefiles.com/featured' target='_blank'
                                style={{
                                    width : '100%', height : '100%',                                    
                                }}
                            >
                                <SC.Div_Flex_Cell fw fh 
                                    style={{
                                        backgroundColor : this.props.textStyles.backgroundHighlight,
                                        fontSize : '14px'
                                    }}
                                >
                                    Click here to browse Lottie animation files
                                </SC.Div_Flex_Cell>
                                
                            </a>
                        }
                    </SC.Div_Relative>
                    <SC.Div_Relative
                        style={{
                            alignSelf : alignment,
                            width : size ? Utils.px(size, '%') : 'auto',
                            marginTop : '8px'
                        }}
                    >
                        <SlateEditor 
                            key={this.EditorId}
                            placeholder='Subtitle'
                            onChange={this.onChangeSubtitle}
                            onReloadValue={this.onReloadSubtitle}
                            textStyles={this.props.textStyles}
                            StyleId={this.props.StyleId}
                            value={subtitle}
                            fixedType={'subtitle'}
                            preview={this.props.preview}
                        />
                    </SC.Div_Relative>                    
                </motion.div>
            </SC.FCol>            
        )
    }
}