import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    AppLayout,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../../../../../importer';

import { GetFilterTokenList, GetFilterTokenListOfIds } from '../../../../../panels/left/designsystem/filters';


export default class DocumentToken_Transforms extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {

        };    
    }
    shouldComponentUpdate(nextProps, nextState) {

        if (this.state.ShouldUpdate !== nextState.ShouldUpdate)
            return true;
        
        if (Utils.HasAnyChange(this.props, nextProps, 'GlobalThemeId', 'GlobalState', 'StyleId', 'RenderId', 'LoadId', 'themeId', 'themeType', 'states', 'ReversedStyleState'))
            return true;
        
        return false;
    }
    componentDidUpdate(prevProps, prevState) {
        this.state.ShouldUpdate = false;
    }
    render() {                
        const tokens = this.props.selection.type === 'selected' ? GetFilterTokenListOfIds(this.props.selection.tokenIds, this.props.ReversedStyleState) : GetFilterTokenList(this.props.ReversedStyleState);
        const tokenItems = [];

        const size = '60px';

        Utils.ForEach(tokens, (token, ) => {
            tokenItems.push(
                <React.Fragment>
                    <div style={{...SC.GetGridBackgroundStyle(false, true), ...SC.Styles.Flex.Cell, padding : '24px', flex : 1, borderRadius : '2px', marginBottom : '8px'}}>
                        <div 
                            style={{
                                width : size, height : size, borderRadius : '3px', backgroundColor : 'rgba(184, 109, 240)', position : 'relative', 
                                transformOrigin : 'center', 
                                filter : token.value,
                                // transform : small ? 'scale(0.3)' : 'scale(0.7)'
                            }} 
                        />
                    </div>
                    <SC.FCol style={{marginBottom : '24px'}}>
                        <div style={{fontFamily : 'monospace'}}>{token.name}</div>
                        <div style={{fontFamily : 'monospace'}}>{token.value}</div>
                    </SC.FCol> 
                </React.Fragment>
            )

            if (this.props.forTemplateSelect && tokenItems.length === 2) {
                return false;
            }
        });

        return (
            <div style={{
                display : 'grid',
                gridColumnGap : '48px',
                gridTemplateColumns : AppLayout.Media.Small ? 'repeat(auto-fit, minmax(280px, 1fr))' : 'repeat(auto-fit, minmax(400px, 1fr))',
                gridAutoRows : 'auto',
                justifyContent : 'start',
                alignItems : 'start',
                alignSelf : this.props.alignment,
                width : this.props.size ? Utils.px(this.props.size, '%') : 'auto'
            }}
            >
                {tokenItems}
            </div>
        )  
    }
}