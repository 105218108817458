import React from 'react';
import ReactDOM from 'react-dom';
import {
    AppState,
    AppLayout,
    Events,
    Strings,
    ReactBaseComponent,
    MetaData,
    SC,
    Utils,
    UIUtils
} from '../../../../importer';

import styled from 'styled-components';
import Handles, { ResizerIds } from './handles';


export const COLOR_HANDLE = '#1592E6';
export const COLOR_HANDLE_SUBCOMPONENT = '#e252d7';

const COLOR_MARGIN = 'rgba(21, 146, 230, 0.2)';
const COLOR_MARGIN_HOVER = 'rgba(21, 146, 230, 0.4)';
const COLOR_MARGIN_HANDLE = 'rgba(21, 146, 230, 0.8)';
const COLOR_PADDING = 'rgba(230, 21, 31, 0.2)';
const COLOR_PADDING_HOVER = 'rgba(230, 21, 31, 0.4)';
const COLOR_PADDING_HANDLE = 'rgba(230, 21, 31, 0.8)';



export class SpacingBox extends React.Component {
    MouseDown_Margin(handle, e) {
        e.stopPropagation();
        this.props.onMarginStarted(handle, e.clientX, e.clientY, e);
    }  
    MouseLeave(e) {
        this.props.onMouseLeave();
    }
    MouseEnter(handle) {
        this.props.onMouseEnter(handle, this.props.isMargin, !this.props.isMargin);
    }
    render() {
        const {top, left, right, bottom, isMargin, justArea, showInfo} = this.props;
        const color = isMargin ? COLOR_MARGIN : COLOR_PADDING;
        const color_handle = isMargin ? COLOR_MARGIN_HANDLE : COLOR_PADDING_HANDLE;
        const size = 1;

        const style_handlebox = {
            position : 'absolute',
            display : 'flex',
            justifyContent : 'center',
            alignItems : 'center',
            boxSizing : 'border-box'
        }

        const isMarginTop = isMargin && top >= 0;
        const isMarginLeft = isMargin && left >= 0;
        const isMarginRight = isMargin && right >= 0;
        const isMarginBottom = isMargin && bottom >= 0;

        let hasHorizontal = true, hasVertical = true;
        if (this.props.compact) {
            if (this.props.compact.width)
                hasHorizontal = false;
            if (this.props.compact.height)
                hasVertical = false;
        }
        const handleprops = {
            color : isMargin ? COLOR_MARGIN : COLOR_PADDING,
            color_hover : isMargin ? COLOR_MARGIN_HOVER : COLOR_PADDING_HOVER,
        }

        let infoItem_top, infoItem_bottom, infoItem_left, infoItem_right, infoItem_center;
    
        if (showInfo) {
            let value;
            const style_info = {
                position : 'absolute',
                // fontSize : Utils.px(12/AppLayout.Designer.Zoom),
                fontWeight : 300,
                fontFamily : `'Montserrat', sans-serif`,
                backgroundColor : 'rgba(0, 0, 0, 0.7)',
                padding : '4px',
                borderRadius : '2px',                
                color : '#fff',                
            };
            const transform_scale = `scale(${1/size}) `;
            if (showInfo.all) {
                infoItem_center = (
                    <div style={{...style_info, top : '50%', left : '50%', transform : `${transform_scale}translateX(-50%) translateY(-50%)`}}>{top}</div>
                )
            }
            else if (showInfo.mutual) {
                infoItem_center = (
                    <div style={{...style_info, top : '50%', left : '50%', transform : `${transform_scale}translateX(-50%) translateY(-50%)`}}>{(showInfo.side === ResizerIds.LEFT || showInfo.side === ResizerIds.RIGHT) ? left : bottom}</div>
                )
            }
            else if (showInfo.side === ResizerIds.TOP) {
                infoItem_top = (
                    <div style={{...style_info, top : isMargin ? 'unset' : '100%', bottom : isMargin ? '100%' : 'unset', transform : transform_scale}}>{top}</div>
                )
            }
            else if (showInfo.side === ResizerIds.BOTTOM){
                infoItem_bottom = (
                    <div style={{...style_info, bottom : isMargin ? 'unset' : '100%', top : isMargin ? '100%' : 'unset', transform : transform_scale}}>{bottom}</div>
                )
            }
            else if (showInfo.side === ResizerIds.LEFT){
                infoItem_left = (
                    <div style={{...style_info, left : isMargin ? 'unset' : '100%', right : isMargin ? '100%' : 'unset', transform : transform_scale}}>{left}</div>
                )
            }
            else if (showInfo.side === ResizerIds.RIGHT){
                infoItem_right = (
                    <div style={{...style_info, right : isMargin ? 'unset' : '100%', left : isMargin ? '100%' : 'unset', transform : transform_scale}}>{right}</div>
                )
            }

            
        }

        const bottomAbs = Math.abs(bottom);
        const topAbs = Math.abs(top);
        const leftAbs = Math.abs(left);
        const rightAbs = Math.abs(right);

        let items_horizontal = !justArea && hasHorizontal ? [
            <div
                key='top' 
                style={{
                    ...style_handlebox,
                    top : 0,
                    paddingTop : isMarginTop ? '2px' : 0,
                    paddingBottom : isMarginTop ? 0 : '2px',
                    marginTop : Utils.px((isMargin ? -1 : 1)*top),
                    left : '50%',
                    marginLeft : '0px',
                    cursor : 'row-resize',                    
                    height : 'px',
                    width : '19px',
                    transform : isMarginTop ? `scale(${size}) translateX(-50%) translateY(-100%)` : `scale(${size}) translateX(-50%)`,
                    transformOrigin : '0 0'
                }}
                onMouseDown={this.MouseDown_Margin.bind(this, Handles.top)}
                onMouseLeave={this.MouseLeave.bind(this, Handles.top)}
                onMouseEnter={this.MouseEnter.bind(this, Handles.top)}
                title={isMargin ? 'Margin' : 'Padding'}
            >                    
                <HalfCircle style={{backgroundColor : color_handle}} />
                <HalfCircleShadow {...handleprops} style={{
                        borderRadius : isMarginTop ? '2px 2px 0 0' : '0 0 2px 2px',
                        top : isMarginTop && this.props.OverHandle && this.props.OverHandle.isMargin && this.props.OverHandle.id === Handles.top.id ? Utils.px(topAbs > 6 ? 0 : (topAbs - 6)) : 0,
                        bottom : !isMarginTop && this.props.OverHandle && this.props.OverHandle.isPadding && this.props.OverHandle.id === Handles.top.id ? Utils.px(topAbs > 6 ? 0 : (topAbs - 6)) : 0
                    }} />
                {infoItem_top}
            </div>,
            <div
                key='bottom' 
                style={{
                    ...style_handlebox,
                    top : '100%',
                    paddingBottom : isMarginBottom ? '2px' : 0,
                    paddingTop : isMarginBottom ? 0 : '2px',
                    marginTop : Utils.px((isMargin ? 1 : -1) * bottom),
                    left : '50%',
                    marginLeft : '0px',
                    cursor : 'row-resize',
                    height : '7px',
                    width : '19px',
                    transform : isMarginBottom ? `scale(${size}) translateX(-50%)` : `scale(${size}) translateX(-50%) translateY(-100%)`,
                    transformOrigin : '0 0'
                }}
                onMouseDown={this.MouseDown_Margin.bind(this, Handles.bottom)}
                onMouseOut={this.MouseLeave.bind(this, Handles.bottom)}
                onMouseEnter={this.MouseEnter.bind(this, Handles.bottom)}
                title={isMargin ? 'Margin' : 'Padding'}
            >                    
                <HalfCircle style={{backgroundColor : color_handle}} />
                <HalfCircleShadow {...handleprops} style={{borderRadius : isMarginBottom ? '0 0 2px 2px' : '2px 2px 0 0',
                     bottom : isMarginBottom && this.props.OverHandle && this.props.OverHandle.isMargin && this.props.OverHandle.id === Handles.bottom.id ? Utils.px(bottomAbs > 6 ? 0 : (bottomAbs - 6)) : 0,
                     top : !isMarginBottom && this.props.OverHandle && this.props.OverHandle.isPadding && this.props.OverHandle.id === Handles.bottom.id ? Utils.px(bottomAbs > 6 ? 0 : (bottomAbs - 6)) : 0
                     }} />
                {infoItem_bottom}
            </div>
        ] : null;
        let items_vertical = !justArea && hasVertical ? [            
            <div
                key='left' 
                style={{
                    ...style_handlebox,
                    paddingLeft : isMarginLeft ? '2px' : 0,
                    paddingRight : isMarginLeft ? 0 : '2px',
                    top : '50%',
                    left : 0,
                    marginLeft : Utils.px((isMargin ? -1 : 1) * left),
                    cursor : 'col-resize',
                    width : '7px',
                    height : '19px',
                    transform : isMarginLeft ? `scale(${size}) translateX(-100%) translateY(-50%)` : `scale(${size}) translateY(-50%)`,
                    transformOrigin : '0 0'
                }}
                onMouseDown={this.MouseDown_Margin.bind(this, Handles.left)}
                onMouseOut={this.MouseLeave.bind(this, Handles.left)}
                onMouseEnter={this.MouseEnter.bind(this, Handles.left)}
                title={isMargin ? 'Margin' : 'Padding'}
            >                    
                <HalfCircle style={{backgroundColor : color_handle}} />
                <HalfCircleShadow {...handleprops} style={{
                    borderRadius : isMarginLeft ? '2px 0 0 2px' : '0 2px 2px 0',
                    left : isMarginLeft && this.props.OverHandle && this.props.OverHandle.isMargin && this.props.OverHandle.id === Handles.left.id ? Utils.px(leftAbs > 6 ? 0 : (leftAbs - 6)) : 0,
                    right : !isMarginLeft && this.props.OverHandle && this.props.OverHandle.isPadding && this.props.OverHandle.id === Handles.left.id ? Utils.px(leftAbs > 6 ? 0 : (leftAbs - 6)) : 0
                }} />
                {infoItem_left}
            </div>,
            <div
                key='right' 
                style={{
                    ...style_handlebox,
                    paddingRight : isMarginRight ? '2px' : 0,
                    paddingLeft : isMarginRight ? 0 : '2px',
                    top : '50%',
                    right : 0,
                    marginRight : Utils.px((isMargin ? -1 : 1) * right),
                    cursor : 'col-resize',
                    width : '7px',
                    height : '19px',
                    transform : isMarginRight ? `scale(${size}) translateX(100%) translateY(-50%)` : `scale(${size}) translateY(-50%)`,
                    transformOrigin : '7px 0'
                }}
                onMouseDown={this.MouseDown_Margin.bind(this, Handles.right)}
                onMouseOut={this.MouseLeave.bind(this, Handles.right)}
                onMouseEnter={this.MouseEnter.bind(this, Handles.right)}
                title={isMargin ? 'Margin' : 'Padding'}
            >                    
                <HalfCircle style={{backgroundColor : color_handle}} />
                <HalfCircleShadow {...handleprops}style={{
                    borderRadius :isMarginRight ? '0 2px 2px 0' : '2px 0 0 2px',
                    right : isMarginRight && this.props.OverHandle && this.props.OverHandle.isMargin && this.props.OverHandle.id === Handles.right.id ? Utils.px(rightAbs > 6 ? 0 : (rightAbs - 6)) : 0,
                    left : !isMarginRight && this.props.OverHandle && this.props.OverHandle.isPadding && this.props.OverHandle.id === Handles.right.id ? Utils.px(rightAbs > 6 ? 0 : (rightAbs - 6)) : 0
                }} />
                {infoItem_right}
            </div>,
        ] : null;

       
        return (
            <React.Fragment>
                <SC.AbsoluteOverlay
                    style={{
                        pointerEvents : 'none',
                        borderColor : color,
                        top : isMargin ? Utils.px(Math.min(-1*top * AppLayout.Designer.Zoom, 0)) : 0,
                        left : isMargin ? Utils.px(Math.min(-1*left * AppLayout.Designer.Zoom, 0)) : 0,
                        right : isMargin ? Utils.px(Math.min(-1*right * AppLayout.Designer.Zoom, 0)) : 0,
                        bottom : isMargin ? Utils.px(Math.min(-1*bottom * AppLayout.Designer.Zoom, 0)) : 0,
                        borderStyle : 'solid',
                        borderTopWidth : Utils.px(Math.abs(top * AppLayout.Designer.Zoom)),
                        borderLeftWidth : Utils.px(Math.abs(left * AppLayout.Designer.Zoom)),
                        borderRightWidth : Utils.px(Math.abs(right * AppLayout.Designer.Zoom)),
                        borderBottomWidth : Utils.px(Math.abs(bottom * AppLayout.Designer.Zoom)),
                        transition : 'border-width 0.1s ease'
                    }}
                >
                    
                </SC.AbsoluteOverlay>
                <SC.AbsoluteOverlay
                    style={{
                        pointerEvents : 'none'
                    }}
                >
                    {items_horizontal}  
                    {items_vertical}  
                    {infoItem_center}
                </SC.AbsoluteOverlay>
            </React.Fragment>
        )
    }
}

export class ResizeHandle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            SpacersVisible : false
        };

        this.Ref_Sizer = React.createRef();

        this.MouseDown = this.MouseDown.bind(this);        
        this.MouseClick = this.MouseClick.bind(this);        
        this.MouseEnter = this.MouseEnter.bind(this);
        this.MouseLeave = this.MouseLeave.bind(this);
    }
    componentDidMount() {
        const DN = ReactDOM.findDOMNode(this.Ref_Sizer.current);
        DN.addEventListener('mousedown', this.MouseDown);        
        DN.addEventListener('mouseover', this.MouseEnter);
        DN.addEventListener('mouseout', this.MouseLeave);
        DN.addEventListener('click', this.MouseClick);        
    }
    componentWillUnmount() {
        const DN = ReactDOM.findDOMNode(this);
        DN.removeEventListener('mousedown', this.MouseDown);        
        DN.removeEventListener('click', this.MouseClick);
        DN.removeEventListener('mouseover', this.MouseEnter);
        DN.removeEventListener('mouseout', this.MouseLeave);
    }
    MouseClick(e) {
        if (e.button !== 0)
            return;
        e.stopPropagation();
    }
    MouseDown(e) {        
        if (e.button !== 0)
            return;
        e.stopPropagation();
        this.props.onResizeStarted(this.props.handle, e.clientX, e.clientY, e);            
    }    
    MouseEnter(e) {
        this.props.onMouseEnter(this.props.handle, true, true);
    }
    MouseLeave(e) {
        this.props.onMouseLeave(this.props.handle, true, true);
    }
    render() {
        const {handle, ativeMode, size} = this.props;
        let ishidden = false;
        
        if (ativeMode) {
            if (ativeMode.handle.id !== handle.id)
                ishidden = true;            
        }

        return (
            <React.Fragment>
                <StyledResizeHandle_Corner  
                    style={{
                        ...handle.style,
                        transform : `${handle.style.transform} scale(${size})`,
                        opacity : ishidden ? 0 : 1
                    }}                 
                    ref={this.Ref_Sizer}
                    subcomponent={this.props.subcomponent}
                >                    
                </StyledResizeHandle_Corner>                
            </React.Fragment>
            
        )
    }
}

const StyledResizeHandle_Corner = styled.div`
    position : absolute;
    width : 9px;
    height : 9px;
    border-radius : 50%;
    border : 1px;
    border-style : solid;
    border-color : ${props => props.subcomponent ? COLOR_HANDLE_SUBCOMPONENT : COLOR_HANDLE};
    background-color : #e1e1e1;
    box-sizing : border-box;
    pointer-events : all;
    transition : box-shadow 0.2s ease;
    &:hover {
        background-color : ${props => props.subcomponent ? COLOR_HANDLE_SUBCOMPONENT : COLOR_HANDLE};
        // box-shadow : 0 0 0 4px rgba(21, 146, 230, 0.20);
    }
`;

const HalfCircle = styled.div`
    height : 3px;
    width : 3px;
    border-radius : 50%;
    background-color : ${props => COLOR_MARGIN};
    opacity : 1;
    transition : opacity 0.2s ease;    
    pointer-events : all;
`;
const HalfCircleShadow = styled.div`
    position : absolute;
    top : 0;
    left : 0;
    right : 0;
    bottom : 0;
    border-radius : 2px 2px 0 0;
    background-color : ${props => props.color};
    opacity : 1;
    transition : all 0.2s ease;    
    pointer-events : all;
    &:hover {
        background-color : ${props => props.color_hover};
    }
    ${StyledResizeHandle_Corner}:hover &{
        bottom : -6px;
    }
`;