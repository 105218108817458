import { Utils } from '../toolabs-importer';

const Events = {
    Listeners : {},
    Listen(Event, Consumer) {
        if (!Consumer)
            return;
        if (!this.Listeners[Event]) {
            this.Listeners[Event] = {
                Consumers : []
            };
        }
        this.Listeners[Event].Consumers.push(Consumer);
    },
    ListenEvents(Consumer, EventIds) {
        if (Array.isArray(EventIds))
            EventIds.map((e) => this.Listen(e, Consumer));
    },
    DropListen(Event, Consumer) {
        if (!Consumer)
            return;
        if (this.Listeners[Event]) {
            Utils.Remove(this.Listeners[Event].Consumers, Consumer);
            if (this.Listeners[Event].Consumers.length == 0)
                delete this.Listeners[Event];
        }
    },
    DropListenAll(Consumer) {
        Utils.ForEach(this.Listeners, (o, e) => {
            this.DropListen(e, Consumer);    
        });
    },      
    BCE(Event, ...Params) {        
        if (this.SuspendBroadCast)
            return;

        // if (Event !== Events.DESIGNER.IFRAME.BEGINDRAG)
        //     console.log(`Change ${Event} : ${JSON.stringify(Params)}`);
        
        if (this.Listeners[Event]) {
            // console.log(`[${Event}] : ${this.Listeners[Event].Consumers.length} `);
            this.Listeners[Event].Consumers.map((Consumer) => {
                Consumer && Consumer.OnEvent && !Consumer.SuspendEventConsumer && Consumer.OnEvent(Event, Params);                    
                // setTimeout(() => {
                    
                // },1);
            })
        }
    },
    BCEFrom(Event, Source, ...Params) {
        if (this.SuspendBroadCast)
            return;

        // console.log(`Change ${Event} : ${JSON.stringify(Params)}`);
        if (this.Listeners[Event]) {
            // console.log(`[${Event}] : ${this.Listeners[Event].Consumers.length} `);
            this.Listeners[Event].Consumers.map((Consumer) => {
                if (Consumer !== Source) {
                    Consumer && Consumer.OnEvent && Consumer.OnEvent(Event, Params);                    
                }                
            })
        }
    },
    AlertSimpleAsync(Message, Type, Title, MessageHeader, ContentIcon) {
        return new Promise((resolve) => {
            this.BCE(this.GLOBAL.NOTIFICATION.ALERT, Type, {
                message : Message,
                title:Title,
                messageHeader : MessageHeader,
                contentIcon : ContentIcon
            }, (result) => {
                resolve(result);
            });
        });   
    },
    AlertSimple(Message, Type, Title, MessageHeader, ContentIcon) {
        this.BCE(this.GLOBAL.NOTIFICATION.ALERT, Type, {message : Message,title:Title,messageHeader : MessageHeader,contentIcon : ContentIcon});
    },
    Ask_YesNo(Message, Type = this.GLOBAL.NOTIFICATION.TYPES.WARNING, Title, MessageHeader, ContentIcon, DefaultIsNo, Rest) {
        return new Promise((resolve) => {
            this.BCE(this.GLOBAL.NOTIFICATION.YESNO, Type, {
                message : Message,
                title:Title,
                messageHeader : MessageHeader,
                contentIcon : ContentIcon,
                DefaultIsNo : DefaultIsNo,
                ...Rest
            }, (result) => {
                resolve(result || this.GLOBAL.NOTIFICATION.RESULT.NO);
            });

        });        
    },
    Ask_YesNoCancel(Message, Type = this.GLOBAL.NOTIFICATION.TYPES.WARNING, Title, MessageHeader, ContentIcon, DefaultIsNo) {
        return new Promise((resolve) => {
            this.BCE(this.GLOBAL.NOTIFICATION.YESNOCANCEL, Type, {
                message : Message,
                title:Title,
                messageHeader : MessageHeader,
                contentIcon : ContentIcon,
                DefaultIsNo : DefaultIsNo
            }, (result) => {
                resolve(result || this.GLOBAL.NOTIFICATION.RESULT.NO);
            });

        });        
    },
    Ask_Delete(Message, Type = this.GLOBAL.NOTIFICATION.TYPES.WARNING) {
        return new Promise((resolve) => {
            this.BCE(this.GLOBAL.NOTIFICATION.DELETE, Type, {message : Message}, (result) => {
                resolve(result || this.GLOBAL.NOTIFICATION.RESULT.CANCEL);
            });

        });        
    },
    Notify(Message, Type , AutoClose = false, CloseOthers) {
        this.BCE(this.GLOBAL.NOTIFICATION.NOTIFY, Type, {message : Message , autoClose : AutoClose, closeOthers : CloseOthers});
    },
    SESSION : 0,
    ACCOUNT : {
        SETTINGS : 'ACCSTT'
    },
    DESIGNER : {
        LAYOUT_CHANGED : 'DLC',        
        DESIGNERRESIZE : 'DDRS',
        RIGHT_PANEL : 'DRP',
        HISTORY : {
            FIRE : 'DHURFIRE',
            UNDO : 'DHUNDO',
            REDO : 'DHREDO',
            LIST : 'DHLIST'
        },        
        BOARD : {
            LOADED : 'DBL',
            ITEM : {
                DETAILS : 'DBID',
                CHANGED : 'DBIC',
                SELECT_COMPONENT : 'DBISCOMP',
                SELECT_VIEW : 'DBISPROTOVIEW',
                SELECT_ICON : 'DBISICON',
                SELECT_SHADOW : 'DBISSHADOW',
                SELECT_SOUND : 'DBISSOUND',
                SELECT_FONT : 'DBISFONT',
                SELECT_IMAGE : 'DBISIMAGE',
                SELECT_TAG : 'DBIST',
                SELECT_TRANSITION : 'DBISTRANS',
                SELECT_CONTENT_TEXT : 'DBISTEXT',
                SELECT_CURSOR : 'DBISCURSOR',
                SELECT_DOC_TOKEN_TEMPLATE : 'DBISDOCTOKTEMP',
                SELECT_PATTERN_TEXT : 'DBUSPTTRTEXT',
                SELECT_DOC_TOKEN_ITEMS : 'DBISDOCTOKITEMS',
                SELECT_TEXTCOLOR : 'DBISTC',
                SELECT_TOKEN : 'DBISTT',
                SELECT_MODEL : 'DBISM'                
                
            },
            IMAGE : {
                UPLOADING : 'DBIUP',
                UPLOADED : 'DBIUPF',
            },
            ICON : {
                UPLOADING : 'DBICUP',
                UPLOADED : 'DBICUPF',
            },
            TAGS : {
                SHOW : 'DBTS',
                BINDING : 'DBTB',
                CHANGED : 'DBTC'
            },
            FILTER : {
                SHOW : 'DBFS',
                CHANGED : 'DBFC',
                FILTERBYSTATEMODIFIED : {
                    TYPE :'DBFMSM',
                    FILTERED : false
                },
                TAGS : {
                    TYPE : 'DBFTT',
                    FILTERED : {

                    },
                    FILTERBYTYPE : {
                        TYPE : 'DBFTBT',                        
                    }
                }
            },
            SINGLESTYLEMODE : {
                TYPE : 'DBSTSM',
                Active : false
            },
            MOCKUPDATA : {
                EDIT : 'MCDEDIT'
            },
            TRANSITION : {
                VARIATIONS : 'MTRSEDIT'
            },
            SCROLLTOSTYLE : 'DBSTS'
        }, 
        OVERLAYBOARD : 'DO', // OverlayBoard
        SCROLLED : 'DSCRL',
        ARTBOARD : {
            FULSCREEN : 'DAFS',
            CHANGED : 'DAC',
            ITEM_ADDED : 'DAC_A',
            ITEM_REMOVED : 'DAC_R',
            SELECTION_CHANGED : 'DASC', // ArtBoard.ItemSelection.Changed
            DEVICE : {
                SIZE : 'DAD_S',
                OPTIONS : 'DAD_O',
                REFRESH : 'DAD_REF'
            },
            ITEM : {
                SELECTED : 'DABIS',
                SELECTEDGUIDE : 'DBASGS'
            }
        },
        PROTOTYPE : {
            MASTERVIEW : 'DPMV'
        },
        SCROLLTOITEM : 'DSCRLTOITEM',
        COMPONENT : {
            TYPE : 'DTCMP',
            GENERICEDITOR : 'DTCGE',
            STATE : {
                CHANGED : 'DCSC', // Design.Component.State,
                CHANGED_VARIATION : 'DCSCV',
                MANAGER : 'DCSCM',
                CHANGED_STYLE : 'DCSCS',
                INSTANCECHANGED : 'DCSIC',
                SELECTOR : {
                    visible : false,
                    TOGGLE : 'DCSST'
                },
                CHANGED_INSTANCE : 'DCSCIS'
            },
            DATA : {
                MANAGER : 'DCD_DM'
            },
            CHANGED : {
                NAME : 'DTCC_NAME',
                ACTIVE : 'DTCACM'
            },
            ZOOM : 'DTCZ',
            ZOOM_CHANGING : 'DTCZC',
            ZOOM_REFRESHDISPLAY : 'DTCZR',
            ACTIONS : {
                MANAGER : 'DCAM'
            },
            RECENTLIST : 'DCRL',
            MODELS : {
                MANAGEr : 'DCVM',
                EDIT : 'DCVME'
            },            
            PANELS : {
                RIGHT : {
                    ITEM : {
                        EDIT : 'DCPRIE'
                    },
                    CLOSEOVERLAY : 'DCPRCO'
                },
                LEFT : {
                    COMPACT : {
                        CHANGED : 'DCLPCC'
                    },
                    SECOND : {
                        CLOSE : 'DCLPSC'
                    }
                }
            },
            CLIPBOARD : {
                CHANGED : 'DCCBC'
            },
            HISTORY : {
                CHANGED : 'DCHISTC',
                LOG : 'DCHISTL',
                SHOW : 'DCHISTS',
                CLOSE : 'DCHISTCL',
                CLOSED : 'DCHISTCD'
            },
            INPLACE : {
                EDIT : 'DCIE',
                CLOSE : 'DCIC'
            },
            GRIDEDITOR : {
                EDIT : 'DCGE_EDT',
                EMPTYCELL : 'DCGE_EMC',
                SHOW : 'DCGE_SH',
                SELECTCELL : 'DCGEL_SC',
                OPTIONS : 'DCGEL_OPS',                
                HIDEITEMS : 'DCGEL_HI',
                EDITMODE : 'DCGEL_EM'
            },
            VIEWPORT : {
                SIZE : 'DCVS',
                RESIZE : 'DCVRS'
            },
            LIST : {
                TYPE : 'DCL_TYPE',
                EDIT : 'DCL_EDIT',
                MAIN : 'DCL_MAIN',
                VARIABLES : 'DCL_VAR',
                EVENTS : 'DCL_EVENT',
                ACTIONS : 'DCL_ACT',
                CONDITIONS : 'DCL_CON',
            }
        },
        ITEM : {
            SELECTION : 'DIS',
            DELETE : 'DITEM',
            DELETED : 'DID',
            RENDERRESET : 'DIRR',
            DRAGDROP : {
                EVENT : 'DDEVENT',
                DRAGGINGON : {
                    DESIGNER : 'DIDDOC',
                    LAYERS : 'DIDDOL'
                },
                DRAGGINGON_DESIGNER : 'DIDDPD',
                BEGINDRAG : 'DIDDBD',
                ENDDRAG : 'DIDDED'
            },
            CALCULATESTYLE : 'DICIS',
            MODE : {
                EDIT_GRID : 'DICEG'
            },
            CHANGED : {
                SIZE : 'DICSIZE',
                STYLE : 'DICS',
                STYLESET : 'DICSS',
                NAME : 'DICSN',
                PROPERTY : 'DICPROP',
                EVENT : 'DICEVENT',
                POSITION : 'DICP',
                VISIBILITY : 'DICV',
                LOCK : 'DICLOCK',
                HOVER_ONOFF : 'DICHONOFF',
                DESIGNGUIDE : 'DICDG',
                LAYOUT : 'DICL',
                CHILD : {
                    ADDED : 'DICCA',
                    DELETED : 'DICCD',
                    ORDER : 'DICCO'
                }
            },
            CHANGING : {
                SIZE : 'DICSIZE',
                STYLE : 'DICGSTYLE',
                SIZE : 'DICGS',
                POSITION : 'DICGP',
            },
            BIND : {
                BOARDSTYLE : 'DIB_BS',
                COMPONENT : 'DIB_BSC',
                LAYOUT : 'DIB_BSL',
                COMPONENT_SELECT : 'DIB_BSCS',
                ELEMENT_SELECT : 'DIB_BSES',
                EVENT : 'DIV_BSEV',
                ACTION : 'DIB_ACT',                
                CONDITION : 'DIB_COND',
                STYLESET : 'DIB_SSET',
                CURSOR : 'DIB_CURSOR',
                TOKENVALUE : 'DUB_TOKEN',
                VARIABLES : 'DUB_VARIABLE',
                TRANSITION : 'DUB_TRANSITION',
                DATA_ITEM : {
                    SELECT : 'DIB_DIS'
                },
                WIDGET : {
                    SELECT : 'DIB_WS'
                },
                TOKEN_WIDGET : {
                    SELECT : 'DIB_TWS'
                }
            },
            ROOTITEM : {
                TYPE : 'DIB_RI',
                DELETED : 'DIB_RID',
                ADDED : 'DIB_RIA',
                SIZE : 'DIB_RIS',
                CHANGED : 'DUB_RIC',
                MOUNTED : 'DUB_RIM'
            },
            MOVE : {
                KEYBOARD : 'DIMK'
            }
        },
        MODELVIEW : 'DMV',
        ACTIONVIEW : 'DAV',
        CONDITIONVIEW : 'DCV',
        TEMPLATES : {
            CUSTOMBLOCK : 'GTBITEM'
        },
        IFRAME : {
            BEGINDRAG : 'DCIFBD',
            DISABLEMOUSE : 'DCIFDM'
        },
        PREVIEW : {
            TOKENVALUES : {
                CHANGED : 'DPTC'
            }
        }
    },
    GLOBAL : {
        STATES : {
            SELECTOR : {
                visible : false,
                TOGGLE : 'GSST'
            }
        },
        STATE_CHANGED : 'GSC',   
        TOKENS_CHANGED : 'GSTC',
        TOKENS_ORDER_CHANGED : 'GSTOC',
        TOKENS_CHANGED_FROMWEB : 'GSTCFW',
        TOKEN_VALUE_CHANGING : 'GSTVC',
        TOKEN_NAME_CHANGED : 'GSTNC',
        DEFAULT_TOKENS_CHANGED : 'DGSTC',
        STATE_CHANGED_VARIATION : 'GSCV',
        MOCKUPS_CHANGED : 'GSMC',
        THEME : {
            CHANGED : 'GTC'
        },
        TEAM : {
            SHOWPANEL : 'GTMSP',
            ACTIVEUSERSCHANGED  :'GTMAUC',
            MESSAGES : 'GTMNM'
        },
        TOURGUIDE : {
            START : 'GTS',
            BOARD_COLOR : 'GTBC',
            BOARD_SIZE : 'GTBS',
            COMPONENTDESIGNER : 'GTCD',
            CDMODULE : 'GTCDM',
            BOARD_STYLES : 'GTBST',
            ACTIONS :{
                CreateContainer : 1,
                ChangeSize : 2,
                ChangeLayout : 3
            }

        },
        ROUTE : {
            ROUTES : {
                HOME : 'GRT_H',
                PROJECT_MAIN : 'GRT_MAIN',
                PROJECT_SETTINGS : 'GRT_SETTINGS',
                TOKENS : 'GRT_TOKENS',                
                ELEMENTS : 'GRT_ELEMENTS',
                LAYOUTS: 'GRT_LAYOUTS',
                API : 'GRT_API',
                DOCUMENTS : 'GRT_DOCS',
                DOCUMENT : 'GRT_DOCUMENT_EDIT',
                DOCUMENT_PREVIEW : 'GRT_DOCUMENT_PREVIEW',
                COMPONENTS : 'GRT_COMPONENTS',
                COMPONENT : 'GRT_COMPONENT_EDIT',
                COMPONENT_PREVIEW : 'GRT_COMPONENT_PREVIEW',
                PROTOTYPES : 'GRT_PROTOTYPES',
                PROTOTYPE : 'GRT_PROTOTYPE_EDIT',
                PROTOTYPE_VIEW : 'GRT_PROTOTYPE_VIEW_EDIT',
                PROTOTYPE_VIEW_PREVIEW : 'GRT_PROTOTYPE_VIEW_PREVIEW',
                PLAYGROUND : 'GRT_PLAYGROUND',
                PUBLISH : 'GRT_PUBLISH'
            },
            CURRENT : {
                ID : 'GRT_H',
                Title : ''
            },
            TYPE : 'GRT',
            UPDATE : 'GRUPT'
        },
        MEGAMENU : {
            SHOW : 'GMGS',
            CUSTOMCONTENT : {
                SHOW : 'GMGC',
                SHUTTERSTOCK : 'GMGC_SS',
                UNSPLASHIMAGES : 'GMGC_USIMG',
                ICONFINDER : 'GMGC_ICONF',
                ACCOUNT : 'GMGC_ACCOUNT',
                SUPPORT : 'GMGC_SUPPORT'
            }
        },
        TOOLTIP : {
            SHOW : 'GTTS',
            HIDE : 'GTTH',
            KILL : 'GTTK'
        },
        MEDIASIZE : {
            CHANGED : 'GMSC',
            SIZE : {
                SMALL : 'GMS_SMALL'
            }
        },
        NOTIFICATION : {
            NOTIFY : 'GNTF',            
            ALERT : 'GNTA',
            YESNO : 'GNTYN',
            YESNOCANCEL : 'GNTYNC',
            DELETE : 'GNTDEL',
            SHOW : 'GNS',
            HIDE : 'GNH',
            RESULT : {
                YES : 'GNTRY',
                NO : 'GNTRN',
                CANCEL : 'GNTRC',
                DELETE : 'GNTRD'
            },
            TYPES : {
                INFO : 'info',
                WARNING : 'warning',
                SUCCESS : 'success',
                ERROR : 'error'
            },
            SOUNDS :
            {
                info : process.env.PUBLIC_URL + `/assets/sounds/Complete and Success/Complete 3.m4a`,
                warning : process.env.PUBLIC_URL + `/assets/sounds/Complete and Success/Complete 1.m4a`,
                success : process.env.PUBLIC_URL + `/assets/sounds/Complete and Success/Success 1.m4a`,
                error : process.env.PUBLIC_URL + `/assets/sounds/Errors and Cancel/Error 2.m4a`,
            }
        }
    },
    PARAMS : {
        SHOW : 'EP_SHOW',
        HIDE : 'EP_HIDE',
        UPDATE : 'EP_UPDATE',
        REFRESH : 'EP_REFRESH'
    }

};

Events.BroadcastThrottle = Utils.Throttle(Events.BCE, 100);
Events.BroadcastThrottle_50 = Utils.Throttle(Events.BCE, 50);
Events.BroadcastThrottle_200 = Utils.Throttle(Events.BCE, 200);
Events.BroadcastThrottle_400 = Utils.Throttle(Events.BCE, 400);
Events.BroadcastThrottle_500 = Utils.Throttle(Events.BCE, 500);

export default Events;