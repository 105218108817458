import {
    Utils,
    AppState,
    SC,
    Strings,
    AppLayout,
    Globals
} from '../../../importer';
import { TokenTypes, DEFAULT_PRIMARY_FONT, DEFAULT_SECONDARY_FONT } from './tokens';
import {FontLoader} from '../../../toolabs-importer';
import { TEAM_MESSAGE_TYPES } from './sessionmanager';

export default  class DocumentManager {
    constructor(Manager) {
        this.Manager = Manager;

    }
    GetList() {
        return AppState.GetDataApi().document_list(this.Manager.Id);
    }
    DefaultData(documentData) {
        const pages = [];
        const items = {};
        const itemdata = {};

        const InsertText = ({pageId, title, tag = 'h1', ...rest}) => {
            const id_title = Utils.Id();
            Utils.Set(itemdata, {
                type : 'text',
                data : {
                    tag : tag,
                    content : [{
                        type : tag,
                        children : [{text : title}]
                    }],
                    ...rest
                }
            }, pageId, id_title);
            return id_title;
        };

        Utils.ForEach(DOCUMENT_TOKENTYPES, (tokenType, i) => {
            const pageId = Utils.Id();
            pages.push({id : pageId, title : tokenType.label});
            const id_title = InsertText({pageId : pageId, title : tokenType.label, bottom : '48'});
            const id_token = Utils.Id();            
            

            if (tokenType.id === TokenTypes.Fonts) {
                const id_fonts = Utils.Id();

                Utils.Set(itemdata, {
                    type : 'token',                    
                    data : {
                        type : tokenType.id,
                        subType : 'fonts',
                        bottom : 48
                    }
                }, pageId, id_fonts);

                const id_typescaleTitle = InsertText({pageId : pageId, title : 'Typescale', tag : 'h2'});
                const id_typescale = Utils.Id();
                Utils.Set(itemdata, {
                    type : 'token',                    
                    data : {
                        type : tokenType.id,
                        subType : 'scale',
                        bottom : 64
                    }
                }, pageId, id_typescale);

                Utils.Set(itemdata, {
                    type : 'token',
                    data : {
                        type : tokenType.id,
                        subType : 'patterns',
                        ...tokenType.options
                    }
                }, pageId, id_token);

                const id_textStylesTitle = InsertText({pageId : pageId, title : 'Text Styles', tag : 'h2'});

                items[pageId] = [id_title, id_fonts, id_typescaleTitle, id_typescale, id_textStylesTitle, id_token];
            }
            else if (tokenType.id === TokenTypes.Motion) {
                const id_timescale = Utils.Id();                
                Utils.Set(itemdata, {
                    type : 'token',                    
                    data : {
                        type : tokenType.id,
                        subType : 'scale'
                    }
                }, pageId, id_timescale);

                const id_durations = Utils.Id();                
                Utils.Set(itemdata, {
                    type : 'token',                    
                    data : {
                        type : tokenType.id,
                        subType : 'durations',
                        bottom : 64,
                    }
                }, pageId, id_durations);
                
                const id_ease_title = InsertText({pageId : pageId, title : 'Ease Curves', tag : 'h2'});                
                const id_ease = Utils.Id();
                Utils.Set(itemdata, {
                    type : 'token',                    
                    data : {
                        type : tokenType.id,
                        subType : 'ease',
                        bottom : 64
                    }
                }, pageId, id_ease);
                
                const id_transitions_title = InsertText({pageId : pageId, title : 'Transitions', tag : 'h2'});                
                const id_transitions = Utils.Id();
                Utils.Set(itemdata, {
                    type : 'token',                    
                    data : {
                        type : tokenType.id,
                        subType : 'transitions',
                    }
                }, pageId, id_transitions);

                items[pageId] = [id_title, id_timescale, id_durations, id_ease_title, id_ease, id_transitions_title, id_transitions];
            }
            else if (tokenType.id === TokenTypes.Spacings) {
                const id_scale = Utils.Id();
                Utils.Set(itemdata, {
                    type : 'token',                    
                    data : {
                        type : tokenType.id,
                        subType : 'scale'
                    }
                }, pageId, id_scale);

                Utils.Set(itemdata, {
                    type : 'token',
                    data : {
                        type : tokenType.id,
                        ...tokenType.options
                    }
                }, pageId, id_token);

                items[pageId] = [id_title, id_scale, id_token];
            }
            else {
                items[pageId] = [id_title, id_token];

                Utils.Set(itemdata, {
                    type : 'token',
                    data : {
                        type : tokenType.id,
                        ...tokenType.options
                    }
                }, pageId, id_token);
            }
            
        });
        documentData.folders = [{id : 'Default', title : 'Tokens', pages : pages}];
        documentData.items = items;
        documentData.itemdata = itemdata;
    }
    Load(id) {
        return new Promise((resolve) => {
            AppState.GetDataApi().get_document(this.Manager.Id, id).then((result) => {
                this.Id = id;
                this.Data = Utils.DeepClone(Utils.Get(result, null, 'value'));
                if (!this.Data) {
                    this.Data = {
                    };
                    this.DefaultData(this.Data);
                    AppState.GetDataApi().update_path_document_data(this.Manager.Id, this.Id, [], this.Data);
                }

                const fonts = Utils.JustGet(this.Data, [], 'styles', 'fonts');
                Utils.ForEach(fonts, (font, ) => {
                    if (font.provider === Strings.FONT_GOOGLE) {
                        FontLoader.Load(font.family, font.variant, font.url);                            
                    }
                    else if (font.provider === Strings.CUSTOM) {
                        this.Manager.Tokens.LoadCustomFont(font.fontId);
                    }
                });

                this.SelectPage();
                resolve();
            })
        }); 
        
        // return {
        //     items : [
        //         {
        //             id : 1,
        //             type : 'text',
        //             content : 'Title'
        //         },
        //         {
        //             id : 2,
        //             type : 'text',
        //             content : 'Sub Title'
        //         },
        //         {
        //             id : 3,
        //             type : 'text',
        //             content : 'This is the section for description of color tokens. This is the section for description of color tokens. This is the section for description of color tokens. This is the section for description of color tokens. '
        //         }
        //     ]
        // }
    }
    ListenChanges(onChanged) {
        AppState.GetDataApi().listen_document_change(this.Manager.Id, this.Id, onChanged);
        AppState.GetDataApi().listen_tokens_changes(this.Manager.Id, onChanged);
    }
    GetStyles() {
        let styles = Utils.JustGet(this.Data, null, 'styles');
        if (!styles) {
            const fontId_Primary = Utils.Id();
            const fontId_Secondary = Utils.Id();
            styles = {
                width : 60,
                topOffset: 80,
                backgroundColor : {
                    light : SC.themes.Light.back,
                    dark : SC.themes[AppLayout.Theme.Dark].back
                },                
                textColor : {
                    light : SC.themes.Light.font,
                    dark : SC.themes[AppLayout.Theme.Dark].font
                },                
                leftPanel : {
                    fontSize : 14,
                    lineHeight : 30,
                    backgroundColor : {
                        light : SC.themes.Light.back_lighter,
                        dark : SC.themes[AppLayout.Theme.Dark].back_lighter
                    }
                },
                body : {
                    fontId : fontId_Primary,
                    fontSize : 12,
                },
                d1 : {
                    fontSize : 40,
                    fontId : fontId_Secondary,
                },
                h1 : {
                    fontSize : 30,
                    fontId : fontId_Secondary,
                },
                h2 : {
                    fontSize : 24
                },
                h3 : {
                    fontSize : 20
                },
                paragraph : {
                    fontSize : 16
                },
                code : {
                    fontSize : 12
                },
                fonts : [
                    {                        
                        ...DEFAULT_PRIMARY_FONT,
                        id : fontId_Primary
                    },
                    {
                        ...DEFAULT_SECONDARY_FONT,
                        id : fontId_Secondary
                    },
                ]
            };

            this.SetStyles(styles);
        }
        return Utils.DeepClone(styles);
    }
    SetStyles(styles) {
        Utils.Set(this.Data, styles, 'styles');
        AppState.GetDataApi().update_path_document_data(this.Manager.Id, this.Id, ['styles'], styles);
    }
    SetPageSize(size) {
        Utils.Set(this.Data, size, 'styles', 'width');
        AppState.GetDataApi().update_path_document_data(this.Manager.Id, this.Id, ['styles', 'width'], size);        
    }
    SetPageTopOffset(size) {
        Utils.Set(this.Data, size, 'styles', 'topOffset');
        AppState.GetDataApi().update_path_document_data(this.Manager.Id, this.Id, ['styles', 'topOffset'], size);        
    }
    SetLogo(value, offline) {
        const existingId = Utils.JustGet(this.Data, null, 'logo', 'storageId');
        if (existingId && value.storageId !== existingId) {
            this.Manager.DeleteStorageImage(existingId);   
        }
        Utils.Set(this.Data, value, 'logo');
        if (!offline) {
            AppState.GetDataApi().update_path_document_data(this.Manager.Id, this.Id, ['logo'], value);
        }        
    }
    GetLogoUrl() {
        return Utils.JustGet(this.Data, null, 'logo', 'url');
    }
    GetLogoData() {
        return Utils.JustGet(this.Data, {}, 'logo');
    }    
    DeleteLogo() {
        const existingId = Utils.JustGet(this.Data, null, 'logo', 'storageId');
        if (existingId) {
            this.Manager.DeleteStorageImage(existingId);   
        }
        Utils.UnSet(this.Data, 'logo');
        AppState.GetDataApi().update_path_document_data(this.Manager.Id, this.Id, ['logo'], this.GetLogoData());
    }
    SetLogoProp(prop, value) {
        Utils.Set(this.Data, value, 'logo', prop);
        if (prop === 'link') {
            Utils.Set(this.Data, 'url', 'logo', 'source');
            const existingId = Utils.JustGet(this.Data, null, 'logo', 'storageId');
            if (existingId) {
                this.Manager.DeleteStorageImage(existingId);   
            }
        }
        else {

        }
        
        AppState.GetDataApi().update_path_document_data(this.Manager.Id, this.Id, ['logo'], this.GetLogoData());
    }    
    GetOption(...prop) {
        return Utils.JustGet(this.Data, null, 'options', ...prop);
    }
    SetOption(value, ...prop) {
        Utils.Set(this.Data, value, 'options', ...prop);
        AppState.GetDataApi().update_path_document_data(this.Manager.Id, this.Id, Utils.Concat(['options'], prop), Utils.UseUndefined(value, null));
    }
    DeleteOption(...prop) {
        Utils.UnSet(this.Data, 'options',  ...prop);
        AppState.GetDataApi().delete_path_document_data(this.Manager.Id, this.Id, Utils.Concat(['options'], prop));
    }
    BuildStyles(Context, lightTheme) {
        const colorTheme = lightTheme ? 'light' : 'dark';

        Context.DocumentStyles = this.GetStyles();
        
        let color_base = Utils.JustGet(Context.DocumentStyles.textColor, SC.CurrentTheme.theme.font, colorTheme);
        const color_baseTokenId = Context.DocumentStyles.textColorTokenId;
        if (color_baseTokenId) {
            color_base = this.Manager.Tokens.ValueOfId(color_baseTokenId);
        }
        
        let backgroundColor_base = Utils.JustGet(Context.DocumentStyles.backgroundColor, SC.CurrentTheme.theme.back, colorTheme);
        const backgroundColor_baseTokenId = Context.DocumentStyles.backgroundColorTokenId;
        if (backgroundColor_baseTokenId) {
            backgroundColor_base = this.Manager.Tokens.ValueOfId(backgroundColor_baseTokenId);
        }

        let dividerColor = Utils.JustGet(Context.DocumentStyles.dividerColor, SC.CurrentTheme.theme.back, colorTheme);
        const dividerColorTokenId = Context.DocumentStyles.dividerColorTokenId;
        if (dividerColorTokenId) {
            dividerColor = this.Manager.Tokens.ValueOfId(dividerColorTokenId);
        }

        const fonts = Utils.JustGet(Context.DocumentStyles, [], 'fonts');
        let defaultFontId;
        let defaultFont;
        if (fonts.length > 0) {
            defaultFontId = fonts[0].id;
            defaultFont = fonts[0];
            
        }
        const bodyFontSize = Utils.JustGet(Context.DocumentStyles, 12, 'body', 'fontSize');
        Context.textStyles = {
            backgroundHighlight : lightTheme ? 'rgba(0,0,0,0.05)' : 'rgba(255,255,255,0.05)',
            backgroundHighlightHover : lightTheme ? 'rgba(0,0,0,0.1)' : 'rgba(255,255,255,0.15)',
            dividerColor : dividerColor,
            color : color_base,
            backgroundColor : backgroundColor_base,
        };            
        [
            {id : 'body'},
            {id : 'd1'},
            {id : 'h1'},
            {id : 'h2'},
            {id : 'h3'},
            {id : 'paragraph'},
            {id : 'code'},
            {id : 'list'},
            {id : 'leftPanel'}
        ].map((textStyle) => {

            let color = Utils.JustGet(Context.DocumentStyles, color_base, textStyle.id, 'color', colorTheme);
            const colorTokenId = Utils.JustGet(Context.DocumentStyles, null, textStyle.id, 'colorTokenId');
            if (colorTokenId) {
                color = this.Manager.Tokens.ValueOfId(colorTokenId);
            }

            let fontStyle = {};
            
            const textTokenId = Utils.JustGet(Context.DocumentStyles, null, textStyle.id, 'textTokenId');
            if (textTokenId) {
                const style = this.Manager.Tokens.TypePatterns.GetPatternStyleFromId(textTokenId);
                Context.textStyles[textStyle.id] = {                    
                    color : color,
                    ...style
                };
            }
            else {
                const fontSize = Utils.JustGet(Context.DocumentStyles, bodyFontSize, textStyle.id, 'fontSize');            
                const fontId = Utils.JustGet(Context.DocumentStyles, defaultFontId, textStyle.id, 'fontId');
                if (fontId) {
                    
                    const font = Utils.Find(fonts, (fontItem) => {return fontItem.id === fontId}) || defaultFont;
                    if (font) {
                        fontStyle = this.Manager.Tokens.Get_FontFamilyOf(font);
                        
                    }
                }            
    
                const letterSpacing =  Utils.JustGet(Context.DocumentStyles, null, textStyle.id, 'letterSpacing');
                Context.textStyles[textStyle.id] = {                    
                    color : color,
                    fontSize : Utils.px(fontSize),
                    lineHeight : Utils.px(Utils.JustGet(Context.DocumentStyles, fontSize * 1.75, textStyle.id, 'lineHeight')),
                    letterSpacing : letterSpacing ? Utils.px(letterSpacing, 'em') : 'inherit',                    
                    ...fontStyle,
                };
            }
                        

            if (textStyle.id === 'leftPanel') {
                Context.textStyles[textStyle.id].backgroundColor = Utils.JustGet(Context.DocumentStyles, SC.C, textStyle.id, 'backgroundColor', colorTheme);
                const backcolorTokenId = Utils.JustGet(Context.DocumentStyles, null, textStyle.id, 'backgroundColorTokenId');
                if (backcolorTokenId) {
                    Context.textStyles[textStyle.id].backgroundColor = this.Manager.Tokens.ValueOfId(backcolorTokenId);
                }
            }
            
        })
        Context.style_document_body = {
            backgroundColor : backgroundColor_base,
            color : color_base,
            paddingTop : Utils.px(Context.DocumentStyles.topOffset),
            paddingBottom : '90px',
            minHeight : '100vh',
            ...Context.textStyles.body
        }
    }
    
    GetFolders() {
        let folders = Utils.Get(this.Data, null, 'folders');
        if (!folders) {
            this.DefaultData(this.Data);
        }
        return this.Data.folders;
    }
    ChangeFolderOrder(oldIndex, newIndex) {
        const folders = Utils.Get(this.Data, null, 'folders');
        Utils.ChangePlace(folders, oldIndex, newIndex);
        AppState.GetDataApi().update_path_document_data(this.Manager.Id, this.Id, ['folders'], folders);
    }
    ChangePageOrder(oldFolderId, newFolderId, oldIndex, newIndex) {
        const folders = this.GetFolders();
        if (oldFolderId === newFolderId) {
            const folder = Utils.Find(folders, (item) => {return item.id === oldFolderId});
            if (folder) {
                const pages = Utils.Get(folder, [], 'pages');
                Utils.ChangePlace(pages, oldIndex, newIndex);                
            }
        }
        else {
            const sourceFolder = Utils.Find(folders, (item) => {return item.id === oldFolderId});
            const sourcepages = Utils.Get(sourceFolder, [], 'pages');
            const sourcePage = sourcepages[oldIndex];
            sourcepages.splice(oldIndex, 1);

            const targetFolder = Utils.Find(folders, (item) => {return item.id === newFolderId});
            const targetPages = Utils.Get(targetFolder, [], 'pages');
            targetPages.splice(newIndex, 0, sourcePage);
            
        }
        AppState.GetDataApi().update_path_document_data(this.Manager.Id, this.Id, ['folders'], folders);
    }
    SelectPage(id) {
        this.CurrentPageId = id;
        if (!this.CurrentPageId) {
            const folders = this.GetFolders();
            if (folders.length > 0) {
                Utils.ForEach(folders, (folder, ) => {
                    const pages = Utils.Get(folder, [], 'pages');
                    if (pages.length > 0) {
                        this.CurrentPageId = pages[0].id;
                        return false;
                    }
                });
            }            
            if (!this.CurrentPageId) {
                if (folders.length > 0) {
                    this.CurrentPageId = this.AddPage(folders[0].id);
                }
                else {
                    this.CurrentPageId = this.AddFolder().pageId;
                }
            }
        }        
    }    
    AddFolder() {
        const folders = this.GetFolders();
        const folder = {
            id : Utils.Id(),
            title : 'New Folder',
            pages : []
        };
        const pageId = Utils.Id();
        folder.pages.push({
            id : pageId,
            title : 'New Page'
        });
        folders.push(folder);
        AppState.GetDataApi().update_path_document_data(this.Manager.Id, this.Id, ['folders'], folders);

        Globals.ProjectManager.SessionManager && Globals.ProjectManager.SessionManager.SendChangeMessage({
            type : TEAM_MESSAGE_TYPES.DOCUMENT_FOLDER_ADDED,
            id : folder.id
        });

        return {
            id : folder.id,
            pageId : pageId
        };
    }
    DeleteFolder(id) {
        const folders = this.GetFolders();
        const folderIndex = Utils.FindIndex(folders, (item) => {return item.id === id});        
        if (folderIndex > -1) {
            const folder = folders[folderIndex];
            Utils.ForEach(folder.pages, (page, ) => {
                const pageItemIds = Utils.Get(this.Data, [], 'items', page.id);
                Utils.ForEach(pageItemIds, (pageItemId, ) => {
                    this.ClearItemReferances(page.id, pageItemId);
                });
                AppState.GetDataApi().delete_path_document_data(this.Manager.Id, this.Id, ['items', page.id]);
                AppState.GetDataApi().delete_path_document_data(this.Manager.Id, this.Id, ['itemdata', page.id]);
                Utils.UnSet(this.Data, 'items', page.id);
                Utils.UnSet(this.Data, 'itemdata', page.id);
            });            
            folders.splice(folderIndex, 1);
            AppState.GetDataApi().update_path_document_data(this.Manager.Id, this.Id, ['folders'], folders);            

            Globals.ProjectManager.SessionManager && Globals.ProjectManager.SessionManager.SendChangeMessage({
                type : TEAM_MESSAGE_TYPES.DOCUMENT_FOLDER_DELETED,
                id : id
            });
        }        
    }
    AddPage(folderId) {
        const folders = this.GetFolders();
        const pageId = Utils.Id();

        Globals.ProjectManager.SessionManager && Globals.ProjectManager.SessionManager.SendChangeMessage({
            type : TEAM_MESSAGE_TYPES.DOCUMENT_PAGE_ADDED,
            id : pageId
        });

        if (folderId) {
            const folder = Utils.Find(folders, (item) => {return item.id === folderId});
            if (folder) {                
                const pages = Utils.Get(folder, [], 'pages');
                pages.push({
                    id : pageId,
                    title : 'New Page'
                });
                AppState.GetDataApi().update_path_document_data(this.Manager.Id, this.Id, ['folders'], folders);
                return pageId;
            }
        }    
        else {
            folders.push({
                id : pageId,
                page : true,
                title : 'New Page'
            });
            AppState.GetDataApi().update_path_document_data(this.Manager.Id, this.Id, ['folders'], folders);
            return pageId;
        }    
    }
    ClonePage(folderId, pageId) {
        const folders = this.GetFolders();
        const newPageId = Utils.Id();

        Globals.ProjectManager.SessionManager && Globals.ProjectManager.SessionManager.SendChangeMessage({
            type : TEAM_MESSAGE_TYPES.DOCUMENT_PAGE_ADDED,
            id : newPageId
        });

        if (folderId) {
            const folder = Utils.Find(folders, (item) => {return item.id === folderId});
            if (folder) {                
                const pages = Utils.Get(folder, [], 'pages');
                const pageIndex = Utils.FindIndex(pages, (p) => {return p.id === pageId});
                if (pageIndex > -1) {
                    pages.splice(pageIndex + 1, 0, {
                        id : newPageId,
                        title : 'Copy of ' + pages[pageIndex].title
                    });
                    AppState.GetDataApi().update_path_document_data(this.Manager.Id, this.Id, ['folders'], folders);
                }
            }    
        }
        else {
            const pageIndex = Utils.FindIndex(folders, (p) => {return p.id === pageId});
            if (pageIndex > -1) {
                folders.splice(pageIndex + 1, 0, {
                    id : newPageId,
                    page : true,
                    title : 'Copy of ' + folders[pageIndex].title
                });
                AppState.GetDataApi().update_path_document_data(this.Manager.Id, this.Id, ['folders'], folders);
            }
        }
        const sourceItemIds = Utils.Get(this.Data, [], 'items', pageId);
        const sourceItems = Utils.Get(this.Data, [], 'itemdata', pageId);

        const cloneItems = {};
        const cloneItemIds = [];
        const layoutIdsMap = {};
        Utils.ForEach(sourceItemIds, (sourceItemId, ) => {
            let cloneItemId = Utils.Id();
            const cloneItemData = Utils.DeepClone(sourceItems[sourceItemId]);

            if (cloneItemData.type === DOCUMENT_ITEMTYPES.layout) {
                if (!layoutIdsMap[sourceItemId])
                    layoutIdsMap[sourceItemId] = cloneItemId;
                else 
                    cloneItemId = layoutIdsMap[sourceItemId];

                const item1 = Utils.JustGet(cloneItemData, null, 'data', 'item1');
                const item2 = Utils.JustGet(cloneItemData, null, 'data', 'item2');                
                if (item1) {
                    let item1Id = layoutIdsMap[item1];
                    if (!item1Id) {
                        item1Id = Utils.Id();
                        layoutIdsMap[item1] = item1Id;
                    }
                    Utils.Set(cloneItemData, item1Id, 'data', 'item1');
                }
                if (item2) {
                    let item2Id = layoutIdsMap[item2];
                    if (!item2Id) {
                        item2Id = Utils.Id();
                        layoutIdsMap[item2] = item2Id;
                    }
                    Utils.Set(cloneItemData, item2Id, 'data', 'item2');
                }
            }
            else if (cloneItemData.data && cloneItemData.data.layoutId) {
                if (layoutIdsMap[sourceItemId])
                    cloneItemId = layoutIdsMap[sourceItemId];
                if (!layoutIdsMap[cloneItemData.data.layoutId]) {
                    layoutIdsMap[cloneItemData.data.layoutId] = Utils.Id();
                }
                cloneItemData.data.layoutId = layoutIdsMap[cloneItemData.data.layoutId];
            }

            cloneItemIds.push(cloneItemId);
            cloneItems[cloneItemId] = cloneItemData;
        });

        AppState.GetDataApi().update_path_document_data(this.Manager.Id, this.Id, ['items', newPageId], cloneItemIds);
        AppState.GetDataApi().update_path_document_data(this.Manager.Id, this.Id, ['itemdata', newPageId], cloneItems);
        Utils.Set(this.Data, cloneItemIds, 'items', newPageId);
        Utils.Set(this.Data, cloneItems, 'itemdata', newPageId);


        return newPageId;
    }
    SaveFolderName(folderId, name) {
        this.SaveFolderProp(folderId, 'name', name);
    }
    SaveFolderProp(folderId, name, value) {
        const folders = this.GetFolders();
        const folderIndex = Utils.FindIndex(folders, (item) => {return item.id === folderId});        
        if (folderIndex > -1) {
            const folder = folders[folderIndex];
            folder[name] = value;
            AppState.GetDataApi().update_path_document_data(this.Manager.Id, this.Id, ['folders', folderIndex, name], value);
        }
    }
    SavePageName(folderId, pageId, name) {
        this.SavePageProp(folderId, pageId, 'title', name);
    }
    SavePageProp(folderId, pageId, name, value) {
        const folders = this.GetFolders();
        const folderIndex = Utils.FindIndex(folders, (item) => {return item.id === folderId});        
        if (folderIndex > -1) {
            const folder = folders[folderIndex];
            const pageIndex = Utils.FindIndex(folder.pages, (item) => {return item.id === pageId});        
            const page = folder.pages[pageIndex];
            page[name] = value;
            AppState.GetDataApi().update_path_document_data(this.Manager.Id, this.Id, ['folders', folderIndex, 'pages', pageIndex, name], value);
        }
    }
    DeletePage(folderId, pageId) {

        Globals.ProjectManager.SessionManager && Globals.ProjectManager.SessionManager.SendChangeMessage({
            type : TEAM_MESSAGE_TYPES.DOCUMENT_PAGE_DELETED,
            id : pageId
        });

        let willSelectPageId;
        const folders = this.GetFolders();
        if (folderId) {
            const folderIndex = Utils.FindIndex(folders, (item) => {return item.id === folderId});        
            if (folderIndex > -1) {
                const folder = folders[folderIndex];
                const pages = Utils.Get(folder, [], 'pages');
                const pageIndex = Utils.FindIndex(pages, (page) => {return page.id === pageId});            
                pages.splice(pageIndex, 1);
                AppState.GetDataApi().update_path_document_data(this.Manager.Id, this.Id, ['folders', folderIndex, 'pages'], pages);
                if (pages.length > 0) {
                    if (pageIndex > 0)
                        willSelectPageId = pages[pageIndex-1].id;
                    else if (pageIndex < pages.length)
                        willSelectPageId = pages[pageIndex].id;
                }
            }
        }
        else {
            const folderIndex = Utils.FindIndex(folders, (item) => {return item.id === pageId});        
            if (folderIndex > -1) {              
                folders.splice(folderIndex, 1);
                AppState.GetDataApi().update_path_document_data(this.Manager.Id, this.Id, ['folders'], folders);            
            }   
        }
        
        const pageItemIds = Utils.Get(this.Data, [], 'items', pageId);
        Utils.ForEach(pageItemIds, (pageItemId, ) => {
            this.ClearItemReferances(pageId, pageItemId);
        });

        AppState.GetDataApi().delete_path_document_data(this.Manager.Id, this.Id, ['items', pageId]);
        AppState.GetDataApi().delete_path_document_data(this.Manager.Id, this.Id, ['itemdata', pageId]);        
        Utils.UnSet(this.Data, 'items', pageId);
        Utils.UnSet(this.Data, 'itemdata', pageId);


        return willSelectPageId;
    }
    GetItemIds(PageId = this.CurrentPageId) {        
        return Utils.Get(this.Data, [], 'items', PageId);
    }
    AddItem({afterId, beforeId, clone, data,  pageId = this.CurrentPageId}) {
        const id = Utils.Id();                

        const ids = this.GetItemIds(pageId);
        let cloned;
        if (afterId) {
            const index = ids.indexOf(afterId);
            ids.splice(index + 1, 0, id);
            if (clone)
                cloned = this.CloneItem(afterId);
        }
        else if (beforeId) {
            const index = ids.indexOf(beforeId);
            ids.splice(index, 0, id);
            if (clone)
                cloned = this.CloneItem(beforeId);
        }
        else
            ids.push(id);
        AppState.GetDataApi().update_path_document_data(this.Manager.Id, this.Id, ['items', pageId], ids);


        Globals.ProjectManager.SessionManager && Globals.ProjectManager.SessionManager.SendChangeMessage({
            type : TEAM_MESSAGE_TYPES.DOCUMENT_ITEM_ADDED,
            id : id
        });
        if (cloned) {
            this.SetItem(id, cloned, pageId);
        }
        else if (data) {
            this.SetItem(id, data, pageId);
        }
        return id;
    }
    CloneItem(Id) {
        return this.ClonePageItem(Id, this.CurrentPageId);
    }
    ClonePageItem(Id, pageId = this.CurrentPageId) {
        const data = this.GetPageItem(pageId, Id);
        if (data) {
            const clonedData = Utils.DeepClone(data);
            if (data.type === DOCUMENT_ITEMTYPES.layout) {
                const item1 = Utils.JustGet(data, null, 'data', 'item1');
                const item2 = Utils.JustGet(data, null, 'data', 'item2');
                if (item1) {
                    const item1Clone = this.AddItem({afterId : item1, clone : true});
                    Utils.Set(clonedData, item1Clone, 'data', 'item1');                    
                }
                if (item2) {
                    const item2Clone = this.AddItem({afterId : item2, clone : true});
                    Utils.Set(clonedData, item2Clone, 'data', 'item2');                    
                }
            }
            else if (data.type === DOCUMENT_ITEMTYPES.tab) {
                const tabs = Utils.JustGet(data, null, 'data', 'items');
                const clonedTabs = [];
                if (tabs && Array.isArray(tabs)) {
                    tabs.map((tabItem) => {
                        const clonedTabItem = Utils.DeepClone(tabItem);
                        clonedTabItem.id = Utils.Id();

                        const tabItemIds = Utils.Get(this.Data, [], 'items', tabItem.id);
                        const clonedTabItemIds = [];
                        if (tabItemIds && Array.isArray(tabItemIds)) {
                            tabItemIds.map((tabItemId) => {
                                const tabItemData = this.ClonePageItem(tabItemId, pageId);
                                const useTabItemId = Utils.Id();
                                this.SetItem(useTabItemId, tabItemData);
                                clonedTabItemIds.push(useTabItemId);
                            })
                        }

                        AppState.GetDataApi().update_path_document_data(this.Manager.Id, this.Id, ['items', clonedTabItem.id], clonedTabItemIds);
                        Utils.Set(this.Data, clonedTabItemIds, 'items', clonedTabItem.id);
                        
                        clonedTabs.push(clonedTabItem);
                    })
                }
                Utils.Set(clonedData, clonedTabs, 'data', 'items');
            }
            return clonedData;
        }
    }
    GetItem(id) {
        return this.GetPageItem(this.CurrentPageId, id);
    }
    GetPageItem(pageId, id) {
        return Utils.Get(this.Data, {}, 'itemdata', pageId || this.CurrentPageId, id);
    }
    SetItem(id, data) {
        Utils.Set(this.Data, data, 'itemdata', this.CurrentPageId, id);
        AppState.GetDataApi().update_path_document_data(this.Manager.Id, this.Id, ['itemdata', this.CurrentPageId, id], Utils.UseUndefined(data, null));        
    }
    SetItemType(id, type) {
        Utils.Set(this.Data, type, 'itemdata', this.CurrentPageId, id, 'type');
        AppState.GetDataApi().update_path_document_data(this.Manager.Id, this.Id, ['itemdata', this.CurrentPageId, id, 'type'], type);      
    }
    SetItemValue(id, value, ...path) {
        Utils.Set(this.Data, value, 'itemdata', this.CurrentPageId, id, 'data', ...path);
        AppState.GetDataApi().update_path_document_data(this.Manager.Id, this.Id, Utils.Concat(['itemdata', this.CurrentPageId, id, 'data'], path), Utils.UseUndefined(value, null));
    }
    SetItemValueOffline(id, value, ...path) {
        Utils.Set(this.Data, value, 'itemdata', this.CurrentPageId, id, 'data', ...path);        
    }
    DeleteItemValue(id, ...path) {
        Utils.UnSet(this.Data, 'itemdata', this.CurrentPageId, id, 'data', ...path);
        AppState.GetDataApi().delete_path_document_data(this.Manager.Id, this.Id, Utils.Concat(['itemdata', this.CurrentPageId, id, 'data'], path));
    }
    GetItemValue(id, defaultValue, ...path) {
        return Utils.JustGet(this.Data, defaultValue, 'itemdata', this.CurrentPageId, id, 'data', ...path);
    }
    GetPageItemValue(id, pageId, defaultValue, ...path) {
        return Utils.JustGet(this.Data, defaultValue, 'itemdata', pageId || this.CurrentPageId, id, 'data', ...path);
    }
    DeleteItem(id, pageId = this.CurrentPageId) {        

        this.ClearItemReferances(pageId, id)
        const data = this.GetItem(id);
        if (data.type === DOCUMENT_ITEMTYPES.layout) {
            const item1 = Utils.JustGet(data, null, 'data', 'item1');
            const item2 = Utils.JustGet(data, null, 'data', 'item2');
            item1 && this.DeleteItem(item1, pageId);
            item2 && this.DeleteItem(item2, pageId);
        }
        else if (data.type === DOCUMENT_ITEMTYPES.tab) {
            const tabs = Utils.JustGet(data, null, 'data', 'items');
            if (tabs && Array.isArray(tabs)) {
                tabs.map((tabItem) => {
                    this.DeleteTabItems(pageId, tabItem.id);
                })
            }
        }
        Utils.UnSet(this.Data, 'itemdata', this.CurrentPageId, id);
        const ids = this.GetItemIds(pageId);        
        Utils.RemoveEquals(ids, id);
        AppState.GetDataApi().update_path_document_data(this.Manager.Id, this.Id, ['items', pageId], ids);
        AppState.GetDataApi().delete_path_document_data(this.Manager.Id, this.Id, ['itemdata', this.CurrentPageId, id]);     
        Utils.UnSet(this.Data, 'items', pageId, id);   

        Globals.ProjectManager.SessionManager && Globals.ProjectManager.SessionManager.SendChangeMessage({
            type : TEAM_MESSAGE_TYPES.DOCUMENT_ITEM_DELETED,
            id : id
        });
    }
    DeleteTabItems(pageId, tabItemId) {
        const tabItamItemIds = this.GetItemIds(tabItemId);
        tabItamItemIds && tabItamItemIds.map((tabItamItemId) => {
            this.DeleteItem(tabItamItemId, pageId);
        });
        Utils.UnSet(this.Data, 'items', tabItemId);
        AppState.GetDataApi().delete_path_document_data(this.Manager.Id, this.Id, ['items', tabItemId]);     
    }
    ClearItemReferances(pageId, id) {
        const ItemData = Utils.JustGet(this.Data, {}, 'itemdata', pageId, id);
        if (ItemData) {
            if (ItemData.type === DOCUMENT_ITEMTYPES.image) {
                const storageId = Utils.JustGet(ItemData, null, 'data', 'storageId');
                if (storageId) {
                    this.Manager.DeleteStorageImage(storageId);
                }
            }
            else if (ItemData.type === DOCUMENT_ITEMTYPES.tab) {
                const tabItems = Utils.JustGet(ItemData, null, 'data', 'items');
                if (tabItems && Array.isArray(tabItems)) {
                    tabItems.map((tabItem) => {
                        this.DeleteTabItems(pageId, tabItem.id);
                    })
                }
            }
        }

        const widgets = this.GetFavoriteWidgetes();
        const i = Utils.FindIndex(widgets, (fw) => {return fw.id === id});
        if (i > -1) {
            widgets.splice(i, 1);
            AppState.GetDataApi().update_path_document_data(this.Manager.Id, this.Id, ['favoriteWidgets'], widgets);
        }        
    }
    ChangeOrder(oldIndex, newIndex, pageId = this.CurrentPageId) {
        const ids = this.GetItemIds(pageId);
        Utils.ChangePlace(ids, oldIndex, newIndex);
        AppState.GetDataApi().update_path_document_data(this.Manager.Id, this.Id, ['items', pageId], ids);
    }
    AddHero() {
        const heros = Utils.Get(this.Data, {}, 'heros');
        const hero_id = Utils.Id();
        heros[this.CurrentPageId] = {
            id : hero_id
        };
        
        AppState.GetDataApi().update_path_document_data(this.Manager.Id, this.Id, ['heros'], heros);
        
        return hero_id;        
    }
    MakeGlobalHero(heroId) {
        let value;
        if (heroId) {
            value = {
                pageId : this.CurrentPageId,
                heroId : heroId
            };
        }
        Utils.Set(this.Data, value, 'globalHero');
        AppState.GetDataApi().update_path_document_data(this.Manager.Id, this.Id, ['globalHero'], value);
    }
    GetGlobalHero() {
        return Utils.JustGet(this.Data, null, 'globalHero');
    }
    GetHeroId() {
        return Utils.JustGet(this.Data, null, 'heros', this.CurrentPageId, 'id');
    }
    SetHeroProp(prop, value) {
        Utils.Set(this.Data, value, 'heros', this.CurrentPageId, prop);
        AppState.GetDataApi().update_path_document_data(this.Manager.Id, this.Id, ['heros', this.CurrentPageId, prop], value);
    }
    GetHeroData(pageId) {
        return Utils.JustGet(this.Data, {}, 'heros', pageId || this.CurrentPageId);
    }
    RemoveHero() {
        const HeroData = this.GetHeroData();
        const pageId = HeroData.id;
        const pageItemIds = Utils.Get(this.Data, [], 'items', pageId);
        Utils.ForEach(pageItemIds, (pageItemId, ) => {
            this.ClearItemReferances(pageId, pageItemId);
        });

        AppState.GetDataApi().delete_path_document_data(this.Manager.Id, this.Id, ['items', pageId]);
        AppState.GetDataApi().delete_path_document_data(this.Manager.Id, this.Id, ['itemdata', pageId]);        
        Utils.UnSet(this.Data, 'items', pageId);
        Utils.UnSet(this.Data, 'itemdata', pageId);

        Utils.UnSet(this.Data, 'heros', this.CurrentPageId);
        AppState.GetDataApi().delete_path_document_data(this.Manager.Id, this.Id, ['heros', this.CurrentPageId]);
    }
    DeleteHeroStorageImage() {
        const storageId = Utils.JustGet(this.GetHeroData(), null, 'storageId');
        if (storageId) {
            this.Manager.DeleteStorageImage(storageId);
            this.SetHeroProp('storageId', null);
        }
    }
    AddHeroItem(heroId, data) {
        

    }
    // Shared Links
    GetShareOptions() {
        return Utils.JustGet(this.Data, {}, 'shareOptions');
    }
    SetShareOptions(data) {
        Utils.Set(this.Data, data, 'shareOptions');
        AppState.GetDataApi().update_path_document_data(this.Manager.Id, this.Id, ['shareOptions'], data);
    }
    GetSharedLinks() {
        return Utils.JustGet(this.Data, [], 'sharedLinks');
    }
    GetSharedLink(id) {
        const links = this.GetSharedLinks();
        let foundLink;
        Utils.ForEach(links, (link, ) => {
            if (link.id === id) {
                foundLink = link;
                return false;
            }
        });
        return foundLink;
    }
    GetLinkData(linkId) {
        return AppState.GetDataApi().api.get_path_documents(`/documents/links/${linkId}`);
    }
    AddSharedLink(link) {
        const links = this.GetSharedLinks();
        if (link.id === 'new') {
            link.id = Utils.Id();
            AppState.GetDataApi().api.set_path_documents(`/documents/links/${link.id}`, {projectId : this.Manager.Id, documentId : this.Id});
            links.push({
                id : link.id,
                data : link.data
            });
            Utils.Set(this.Data, links, 'sharedLinks');
        }                                    
        AppState.GetDataApi().update_path_document_data(this.Manager.Id, this.Id, ['sharedLinks'], links);
    }
    DeleteSharedLink(id) {
        AppState.GetDataApi().api.delete_path_documents(`/documents/links/${id}`);
        const links = this.GetSharedLinks();
        Utils.Remove(links, (link) => {return link.id === id});        
        AppState.GetDataApi().update_path_document_data(this.Manager.Id, this.Id, ['sharedLinks'], links);
    }

    // Favorite Widgets
    GetFavoriteWidgetes() {
        const widgets = Utils.JustGet(this.Data, [], 'favoriteWidgets');
        return widgets;
    }
    GetFavoriteWidgeteItemInfos() {
        const widgets = Utils.JustGet(this.Data, [], 'favoriteWidgets');
        const widgetItems = [];

        widgets.map(({id, pageId}) => {
            const folders = this.GetFolders();
            let foldertitle, pagetitle;
            Utils.ForEach(folders, (folder, f) => {
                let folderFound;
                Utils.ForEach(folder.pages, (page, p) => {
                    if (page.id === pageId) {
                        folderFound = true;
                        pagetitle = page.title;
                        return false;
                    }                    
                });
                if (folderFound) {
                    foldertitle = folder.title;                    
                    return false;
                }
            });
            const type = Utils.JustGet(this.Data, '', 'itemdata', pageId || this.CurrentPageId, id, 'type');
            const data = this.GetPageItemValue
            widgetItems.push({
                id : id,
                pageId : pageId,
                type : type,
                title : `${foldertitle} / ${pagetitle}`
            })
        })

        return widgetItems;
    }
    IsWidgetFavorite(id) {
        const widgets = this.GetFavoriteWidgetes();
        const i = Utils.FindIndex(widgets, (fw) => {return fw.id === id});
        return i > -1;
    }
    ToggleFavoriteWidget(id) {
        const widgets = this.GetFavoriteWidgetes();
        const i = Utils.FindIndex(widgets, (fw) => {return fw.id === id});
        if (i > -1) {
            widgets.splice(i, 1);
        }
        else {
            widgets.push({
                id : id,
                pageId : this.CurrentPageId
            });
        }
        Utils.Set(this.Data, widgets, 'favoriteWidgets');
        AppState.GetDataApi().update_path_document_data(this.Manager.Id, this.Id, ['favoriteWidgets'], widgets);
    }
    CloneFromWidgetTemplate(templateId, templatePageId, id) {
        const clone = this.ClonePageItem(templateId, templatePageId);
        this.SetItem(id, clone);
    }
}

export const DOCUMENT_ITEMTYPES = {
    themeConfigurator : 'themeConfigurator',
    text : 'text',
    code : 'code',
    image : 'image',
    video : 'video',
    token : 'token',
    customToken : 'customToken',
    accessibility : 'accessibility',
    layout : 'layout',
    tab : 'tab',
    iframe : 'iframe',    
    storybookEmbed : 'storybookEmbed',
    lottie : 'lottie',
    carousel : 'carousel',
    linkcard : 'linkcard',
    divider : 'divider',
    space : 'space',
    component : 'component',
    favorites : 'favorites',
    figma : 'figma',
    figmaLiveEmbed : 'figmaLiveEmbed',
    figmaComponent : 'figmaComponent'
}

export const DOCUMENT_ITEMTYPE_TITLES = {
    themeConfigurator : 'Theme Switcher',
    text : 'Text',
    code : 'Code',
    image : 'Image',
    video : 'Video',
    token : 'Token Widget',
    accessibility : 'Accessiblity Widget',
    layout : 'Two Columns Row',
    tab : 'Tabs',
    iframe : 'IFrame',
    figmaLiveEmbed : 'Figma Live Embed',
    storybookEmbed : 'storybookEmbed',
    lottie : 'Lottie Animation',
    carousel : 'carousel',
    linkcard : 'Link Block',
    divider : 'Divider',
    space : 'Spacer',
    component : 'Component'
}

export const DOCUMENT_TOKENTYPES = [
    {id : TokenTypes.COLOR, label : 'Colors', options : {view : 'card'}},
    {id : TokenTypes.Gradients, label : 'Gradients'},
    {id : TokenTypes.Shadows, label : 'Shadows'},    
    {id : TokenTypes.Fonts, label : 'Typography'},
    {id : TokenTypes.Motion, label : 'Motion'},
    {id : TokenTypes.Borders, label : 'Borders'},
    {id : TokenTypes.BorderRadiuses, label : 'Border Radii'},
    {id : TokenTypes.Spacings, label : 'Spacing'},    
    {id : TokenTypes.Transforms, label : 'Transforms'},
    {id : TokenTypes.Filters, label : 'Filters'},
    {id : TokenTypes.Sounds, label : 'Sounds'},
    {id : TokenTypes.ContentTexts, label : 'Microcopy (UX Texts)'},
    {id : TokenTypes.Icons, label : 'Icons'},    
];