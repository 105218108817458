import Platforms from '../Platforms';
import DataType from '../datatypes';
import Utils from '../../toolabs-utils';

const Events = {
    GetHandler(EventName, Platform) {
        if (Platform === Platforms.React.Name)
            return 'on' + EventName[0].toUpperCase() + EventName.slice(1);
        else if (Platform === Platforms.VueJs.Name)
            return EventName.toLowerCase();
        else if (Platform === Platforms.ReactNative.Name) {
            if (this[EventName]) {
                return Utils.JustGet(this[EventName], EventName, 'Platform', Platform);
            }
        }
        return EventName;
    },
    abort : {
        name : 'abort',
        label : 'Abort',
        description : ''
        },
    AnimationEnd : {
        name : 'AnimationEnd',
        label : 'AnimationEnd',
        description : ''
        },
    AnimationIteration : {
        name : 'AnimationIteration',
        label : 'AnimationIteration',
        description : ''
        },
    AnimationStart : {
        name : 'AnimationStart',
        label : 'AnimationStart',
        description : ''
        },
    blur : {
        name : 'blur',
        label : 'Blur',
        description : ''
        },
    CanPlay : {
        name : 'CanPlay',
        label : 'CanPlay',
        description : ''
        },
    CanPlayThrough : {
        name : 'CanPlayThrough',
        label : 'CanPlayThrough',
        description : ''
        },
    change : {
        name : 'change',
        label : 'Change',
        description : ''
        },
    click : {
        name : 'click',
        label : 'Mouse Click',
        Platform : {
            ReactNative : 'onPress'
        },
        description : ''
        },
    CompositionEnd : {
        name : 'CompositionEnd',
        label : 'CompositionEnd',
        description : ''
        },
    CompositionStart : {
        name : 'CompositionStart',
        label : 'CompositionStart',
        description : ''
        },
    CompositionUpdate : {
        name : 'CompositionUpdate',
        label : 'CompositionUpdate',
        description : ''
        },
    ContextMenu : {
        name : 'ContextMenu',
        label : 'ContextMenu',
        description : ''
        },
    Copy : {
        name : 'Copy',
        label : 'Copy',
        description : ''
        },
    Cut : {
        name : 'Cut',
        label : 'Cut',
        description : ''
        },
    DoubleClick : {
        name : 'DoubleClick',
        label : 'DoubleClick',
        description : ''
        },
    Drag : {
        name : 'Drag',
        label : 'Drag',
        description : ''
        },
    DragEnd : {
        name : 'DragEnd',
        label : 'DragEnd',
        description : ''
        },
    DragEnter : {
        name : 'DragEnter',
        label : 'DragEnter',
        description : ''
        },
    DragExit : {
        name : 'DragExit',
        label : 'DragExit',
        description : ''
        },
    DragLeave : {
        name : 'DragLeave',
        label : 'DragLeave',
        description : ''
        },
    DragOver : {
        name : 'DragOver',
        label : 'DragOver',
        description : ''
        },
    DragStart : {
        name : 'DragStart',
        label : 'DragStart',
        description : ''
        },
    Drop : {
        name : 'Drop',
        label : 'Drop',
        description : ''
        },
    DurationChange : {
        name : 'DurationChange',
        label : 'DurationChange',
        description : ''
        },
    Emptied : {
        name : 'Emptied',
        label : 'Emptied',
        description : ''
        },
    Encrypted : {
        name : 'Encrypted',
        label : 'Encrypted',
        description : ''
        },
    Ended : {
        name : 'Ended',
        label : 'Ended',
        description : ''
        },
    Error : {
        name : 'Error',
        label : 'Error',
        description : ''
        },
    focus : {
        name : 'focus',
        label : 'Focus',
        description : ''
        },
    Input : {
        name : 'Input',
        label : 'Input',
        description : ''
        },
    Invalid : {
        name : 'Invalid',
        label : 'Invalid',
        description : ''
        },
    KeyDown : {
        name : 'KeyDown',
        label : 'KeyDown',
        description : ''
        },
    KeyPress : {
        name : 'KeyPress',
        label : 'KeyPress',
        description : ''
        },
    KeyUp : {
        name : 'KeyUp',
        label : 'KeyUp',
        description : ''
        },
    Load : {
        name : 'Load',
        label : 'Load',
        description : ''
        },
    LoadedData : {
        name : 'LoadedData',
        label : 'LoadedData',
        description : ''
        },
    LoadedMetadata : {
        name : 'LoadedMetadata',
        label : 'LoadedMetadata',
        description : ''
        },
    LoadStart : {
        name : 'LoadStart',
        label : 'LoadStart',
        description : ''
        },    
    MouseDown : {
        name : 'MouseDown',
        label : 'Mouse Down',
        description : ''
        },
    MouseMove : {
        name : 'MouseMove',
        label : 'Mouse Move',
        description : ''
        },
    MouseOut : {
        name : 'MouseOut',
        label : 'Mouse Out',
        description : ''
        },
    MouseOver : {
        name : 'MouseOver',
        label : 'Mouse Over',
        description : ''
        },
    MouseEnter : {
        name : 'MouseEnter',
        vue : 'mouseenter',
        label : 'Mouse Enter',
        description : ''
        },
    MouseLeave : {
        name : 'MouseLeave',
        vue : 'mouseleave',
        label : 'Mouse Leave',
        description : ''
        },
    MouseUp : {
        name : 'MouseUp',
        label : 'Mouse Up',
        description : ''
        },
    Paste : {
        name : 'Paste',
        label : 'Paste',
        description : ''
        },
    Pause : {
        name : 'Pause',
        label : 'Pause',
        description : ''
        },
    Play : {
        name : 'Play',
        label : 'Play',
        description : ''
        },
    Playing : {
        name : 'Playing',
        label : 'Playing',
        description : ''
        },
    Progress : {
        name : 'Progress',
        label : 'Progress',
        description : ''
        },
    RateChange : {
        name : 'RateChange',
        label : 'RateChange',
        description : ''
        },
    Reset : {
        name : 'Reset',
        label : 'Reset',
        description : ''
        },
    Scroll : {
        name : 'Scroll',
        label : 'Scroll',
        description : ''
        },
    Seeked : {
        name : 'Seeked',
        label : 'Seeked',
        description : ''
        },
    Seeking : {
        name : 'Seeking',
        label : 'Seeking',
        description : ''
        },
    SelectionChange : {
        name : 'SelectionChange',
        label : 'SelectionChange',
        description : ''
        },
    Stalled : {
        name : 'Stalled',
        label : 'Stalled',
        description : ''
        },
    Submit : {
        name : 'Submit',
        label : 'Submit',
        description : ''
        },
    Suspend : {
        name : 'Suspend',
        label : 'Suspend',
        description : ''
        },
    TextInput : {
        name : 'TextInput',
        label : 'TextInput',
        description : ''
        },
    TimeUpdate : {
        name : 'TimeUpdate',
        label : 'TimeUpdate',
        description : ''
        },
    TouchCancel : {
        name : 'TouchCancel',
        label : 'TouchCancel',
        description : ''
        },
    TouchEnd : {
        name : 'TouchEnd',
        label : 'TouchEnd',
        description : ''
        },
    TouchMove : {
        name : 'TouchMove',
        label : 'TouchMove',
        description : ''
        },
    TouchStart : {
        name : 'TouchStart',
        label : 'TouchStart',
        description : ''
        },
    TransitionEnd : {
        name : 'TransitionEnd',
        label : 'TransitionEnd',
        description : ''
        },
    VolumeChange : {
        name : 'VolumeChange',
        label : 'VolumeChange',
        description : ''
        },
    Waiting : {
        name : 'Waiting',
        label : 'Waiting',
        description : ''
        },
    Wheel : {
        name : 'Wheel',
        label : 'Wheel',
        description : ''
    },
    change : {
        name : 'change',
        label : 'Value Change'
    },
    // Component Events
    Init : {
        name : 'init',
        label : 'Init'
    },
    Load : {
        name : 'load',
        label : 'Load'
    }
};

export default Events;

export const CommonEvents = [
    Events.click.name,
    Events.focus.name,
    Events.blur.name,
    Events.MouseOver.name,
    Events.MouseOut.name,
    Events.MouseEnter.name,
    Events.MouseLeave.name,
    Events.MouseDown.name,
    Events.MouseUp.name    
];
