import React from 'react';
import {
    ReactBaseComponent,
    SC,
} from '../../importer';
import styled, {css} from 'styled-components';

export const Account_Main = styled.div`
    display : flex;
    width : 900px;
    justfy-content : flex-start;
    align-items : flex-start;
    flex-direction : column;
`;

export const Button_ResetPassword = styled.div`

    width: 330px;
    height : 45px;
    box-sizing: border-box;
    padding : 8px;
    padding-left : 15px;
    font-size: 16px;
    font-weight: 600;
    border : 1px solid ${props => props.theme.color_brand};
    color: ${props => props.theme.color_brand};
    display : flex;
    justify-content : flex-start;
    align-items : center;
    cursor : pointer;
    margin-right : 8px;
    &:hover {
        background-color : #1b1b1d;
    }
    
`;

export const Button = styled.div`

    height : 44px;
    box-sizing: border-box;
    padding : 12px 25px 12px 25px;
    margin-left : 15px;
    font-size: 18px;
    font-weight: 600;
    border : 1px solid ${props => props.positive ? props.theme.color_brand : '#98999c'};
    border-radius : 4px;
    background-color : ${props => props.positive ? props.theme.color_brand : '#98999c'};
    color: #27282B;
    display : flex;
    justify-content : center;
    align-items : center;
    cursor : pointer;
    &:hover {
        background-color : ${props => props.positive ? "#79ADB3" : '#C2BFBF'};
    }
`;

export const Delete_Account = styled.div`
    font-size: 13px;
    font-weight: 400;
    text-decoration: underline;
    color: #6B6B6B;
    cursor : pointer;
    text-underline-position : under;

`;
export const Notification_Row = styled(SC.FRow) `

    justify-content : space-between;
    margin-top : 20px;
    margin-bottom : 5px;
    font-size : 16px;
`;

export const Password_Description = styled(SC.FRow) `

    width: 330px;
    justify-content : space-between;
    margin-top : 10px;
    margin-bottom : 5px;
    font-size : 12px;
    color : #AAAAAA;
`;

export const Divider = styled.div`
    margin-top : 45px;
    margin-bottom : 10px;
    width: 100%;
    height : 1px;
    border-top : 1px solid #3C3C3E;
`;

export const Form_Title = styled(SC.Div_Flex_Cell) `
    font-size : 38px;
    font-weight : 400;
    letter-spacing : 1.2px;
    color : #FFFFFF;
`;

export const Form_Description = styled(SC.Div_Flex_Cell) `
    font-size : 16px;
    line-height : 24px;    
`;
export const Form_GroupTitle = styled(SC.Div_Flex_Cell) `
    font-size : 16px;
    font-weight : 600;
    color :${props => props.theme.color_brand};   
    margin-top : 5px;
    margin-bottom : 5px;
`;
export const Input_Form_Label = styled.div`
    font-size : 12px;
    font-weight : 500;
    margin-left : 2px;
    margin-top : 10px;
    margin-bottom : 10px;
`;

export const Input_Form = styled.input`
    background-color : ${props => props.theme.back};
    border : ${props => props.theme.border_onlight_line};
    height : 50px;
    width : 100%;
    box-sizing : border-box;
    font-size : 16px;
    text-align : left;
    padding-left : 10px;
    padding-right : 30px;            
    color : ${props => props.theme.sizecard_input_color};
    caret-color : ${props => props.theme.color_brand};
    &:focus {
        border : ${props => props.theme.border_brand};
    }
    font-style : ${props => props.isNew ? 'italic' : 'normal'};
`;
export const TextArea_Form = styled.textarea`
    background-color : ${props => props.theme.input_back};
    border : ${props => props.theme.border_onlight_line};
    height : 160px;
    width : 100%;
    resize: vertical;
    box-sizing : border-box;
    font-size : 16px;
    text-align : left;
    padding-left : 10px;
    padding-right : 30px;  
    padding-top : 10px;          
    color : ${props => props.theme.sizecard_input_color};
    caret-color : ${props => props.theme.color_brand};
    font-style : ${props => props.isNew ? 'italic' : 'normal'};
    &:focus {
        border : ${props => props.theme.border_brand};
    }        
`;

export const Feedback_Main = styled.div`
    display : flex;
    justify-content : flex-start;
    align-items : flex-start;
    flex-direction : column;
    max-width : 960px;
    height : 100%;
`;

export const AttachButton = styled.div`
    margin : 5px 5px 5px 0px;
    height : 28px;
    box-sizing: border-box;
    padding : 2px 0px 2px 0px;
    display : flex;
    flex-direction : row;
    justify-content : center;
    align-items : center;
    cursor : pointer;
`;
export const FileName = styled.div`
    margin : 6px 5px 5px 0px;
    height : 26px;
    box-sizing: border-box;
    padding : 2px 0px 2px 5px;
    font-size: 12px;
    font-weight: 300;
    border : ${props => props.theme.border_onlight_line};
    background-color : #202124;
    color: #757575;
    display : flex;
    justify-content : center;
    align-items : center;
`;